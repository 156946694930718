import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IconSVG } from "../../assets/icon/icons";
import {
    defaultSpacingTemplate,
    IPageWorksheetNew,
    ISpacingTemplate,
    PageWorksheetNew,
} from "../../shared/models/pageWorksheetNew";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../shared/models/questionActivity";
import {
    AttributesResourceItem,
    IResourceItemNew,
    ResourceItemNew,
    TextAttribute,
} from "../../shared/models/resourceItemNew";
import { IWorksheet } from "../../shared/models/worksheet";
import {
    Activity,
    IActivity,
    IWorkSheetCreator,
    SizeActivity,
    WorkSheetCreator,
} from "../../shared/models/WorkSheetCreator";
import Config from "../../shared/utils/config";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import { SizeA4 } from "../../shared/utils/sizeA4";
import undoHistoryStore from "../../syncExternalStore/undoHistoryStore";
import {
    generateIdFromDateTime,
    getConfigByActivity,
    getGapBetweenItem,
    getPageWsResources,
    isResourceBackground,
    moveElementFromIndexToIndex,
    updateIndexInPageWorksheet,
    wordListBlockResource,
} from "../../utils";
import {
    deleteUnusedPages,
    getFontFamily,
    getPositionCrosswordByLayout,
    getTextWidthHeightTool,
    newPageResource,
    resourceBackgroundDefault,
    resourceBorderDefault,
    updateCrosswordItemsPosition,
    updateLocationByIndex,
} from "../../utils/draw";
import { requestInitPageWs, requestSavePageWs } from "../async/createWorksheet";
import { updateFillTheBlankSentences } from "../async/fillTheBlankThunk";
import { SelectedText } from "../../shared/models/textStyle";
import moment from 'moment';
import { IFont } from '../../shared/models/module';
import { getDataShape } from '../../utils/canvalData';

export interface IAnswerField {
    fieldName?: string;
    parentField?: string;
    fieldDescription?: string;
    fieldIndex?: number;
    parentFieldIndex?: number;
}

interface ITemplateTag {
    id: number;
    name: string;
    parent: number;
    category: number;
    categoryName: string;
}

interface ITemplateCollection {
    collection: number;
    template: number;
    positionIndex: number;
    collectionMainTemplate: boolean;
}

export interface ITemplate {
    id: number;
    name: string;
    originalImageUrl: string;
    activity: string;
    note: string;
    avoidDuplicationNote: string;
    noteForDesigner: string;
    testIdeaTitle: string;
    testIdeaSubjects: string;
    testIdeaGrades: string;
    testSkillDetails: string;
    testTheme: string;
    testAIResponse: string;
    testAnswerKey: string;
    orientation: string;
    answerFields: IAnswerField[];
    tags: number[];
    tagsData: ITemplateTag[];
    collections: ITemplateCollection[];
    active: boolean;
    autoAddHeadline: boolean;
    autoAddFrame: boolean;
    autoAddName: boolean;
    imageUrl: string;
    fieldsFormat: any;
    variantName: string;
    originalVariantTemplate: number;
    store: number;
    isUsingInIdea: boolean;
    templateAnswerSheet: number;
    answerSheetForm: string;
    type: string;
    pixelArtData: string;
    lastSaveTime: string;
    lastExportTime: string;
    spacing: ISpacingTemplate;
    width: number;
    height: number;
    locked: boolean;
}

export const initialTemplate: ITemplate = {
    id: null,
    name: null,
    originalImageUrl: null,
    activity: null,
    note: null,
    avoidDuplicationNote: null,
    noteForDesigner: null,
    testIdeaTitle: null,
    testIdeaSubjects: null,
    testIdeaGrades: null,
    testSkillDetails: null,
    testTheme: null,
    testAIResponse: null,
    testAnswerKey: null,
    orientation: null,
    answerFields: [],
    tags: [],
    tagsData: [],
    collections: [],
    active: false,
    autoAddHeadline: true,
    autoAddFrame: true,
    autoAddName: true,
    imageUrl: null,
    fieldsFormat: null,
    variantName: null,
    originalVariantTemplate: null,
    store: null,
    isUsingInIdea: false,
    templateAnswerSheet: null,
    answerSheetForm: null,
    type: 'normal',
    pixelArtData: null,
    lastSaveTime: null,
    lastExportTime: null,
    spacing: defaultSpacingTemplate,
    width: null,
    height: null,
    locked: false,
}

interface IIdeaAnswerSheetPage {
    id: string;
    template: number;
    templateData: ITemplate;
    ideaPageIndexes: number[];
    imageUrl: string;
}

interface IIdeaAnswerKeyPage {
    id: string;
    template: number;
    templateData: ITemplate;
    ideaPageIndexes: number[];
    answerKeys: string[];
    imageUrl: string;
}

interface IDataSetDesignRow {
    id: string;
    template: number;
    tinyTapID: string;
    aiResponse: object;
}

interface IIdeaThumbnail {
    id: string;
    idea: number;
    template: number;
    templateData: ITemplate;
    title: string;
    subtitle: string;
    subtitle2: string;
    subtitle3: string;
    subtitle4: string;
    subtitle5: string;
    type: string;
    preview1Url: string;
    preview2Url: string;
    preview3Url: string;
    preview4Url: string;
    totalPages: string;
    imageUrl: string;
    storeIconUrl: string;
}

interface IIdeaTemplate {
    id: string;
    templateData: ITemplate;
    imageUrl: string;
    store: number;
    pageIndex: number;
    mathZones: number[];
    iconZones: object;
    ideaPage: number;
    tags: number[];
}

type initialStateType = {
    ratio: number;
    pagesWorksheet: IPageWorksheetNew[];
    currentActivity: IActivity | null;
    activities: IActivity[];
    worksheetCreator: IWorkSheetCreator | null;
    isOnEditText: boolean;
    isOnDrag: boolean;
    loading: boolean;
    pageIndex: number;
    forceUpdate: boolean;
    // worksheet: IWorksheet | null;
    openWarningReplaceTemplate: boolean;
    selectedWorksheetId: string;
    isShowAnswerKey: boolean;
    isHideTextShape: boolean;
    isResize: boolean;
    isOnEditBorder: boolean;
    numberRenderResource: number;
    resourceItemSelected: IResourceItemNew[];
    currentElementId: string;
    initStaticMathField: string;
    staticMathFieldFontSize: number;
    isFirstSetOriginPage: boolean;
    selectedText: SelectedText;
    template: ITemplate;
    ideaTemplate: IIdeaTemplate;
    ideaAnswerSheetPage: IIdeaAnswerSheetPage;
    ideaAnswerKeyPage: IIdeaAnswerKeyPage;
    ideaThumbnail: IIdeaThumbnail;
    dataSetDesignRow: IDataSetDesignRow;
    title: string;
    content: object;
    isIframe: boolean;
    fontSizeResourceItemsGroup: object;
    lastChangedTime: string;
    lastSavedTime: string;
    templateChanged: boolean;
    designChanged: boolean;
    pageType: string;
    autoSave: boolean;
    templateZoneDisplayMode: string;
    tempTemplateZones: IResourceItemNew[];
    fontFamilyList: IFont[],
    missingFontFamilyList: string[],
    currentStore: number;
    activityPermissions: object;
    disabledSaveButton: boolean;
    allFontsLoaded: boolean;
    allImagesLoaded: boolean;
    autoResizeDone: boolean;
    wordsCountDone: boolean;
    disableAjustZonePositionWithKeyboard: boolean;
    seasonIconGroups: object;
    currentElementFocus: boolean;
};

const initialState: initialStateType = {
    ratio: 1,
    pageIndex: -1,
    currentActivity: null,
    pagesWorksheet: [],
    worksheetCreator: new WorkSheetCreator(),
    activities: [],
    isOnEditText: false,
    isOnDrag: false,
    loading: true,
    forceUpdate: false,
    // worksheet: null,
    openWarningReplaceTemplate: false,
    selectedWorksheetId: "",
    isShowAnswerKey: true,
    isHideTextShape: false,
    isResize: false,
    isOnEditBorder: false,
    numberRenderResource: 0,
    resourceItemSelected: [],
    currentElementId: "",
    initStaticMathField: "",
    staticMathFieldFontSize: 24,
    isFirstSetOriginPage: true,
    selectedText: { endOffset: -1, startOffset: -1 },
    template: null,
    ideaTemplate: null,
    ideaAnswerSheetPage: null,
    ideaAnswerKeyPage: null,
    ideaThumbnail: null,
    dataSetDesignRow: null,
    title: null,
    content: {},
    isIframe: false,
    fontSizeResourceItemsGroup: {},
    lastChangedTime: null,
    lastSavedTime: null,
    templateChanged: false,
    designChanged: false,
    pageType: null,
    autoSave: false,
    templateZoneDisplayMode: 'zoneOnly',
    tempTemplateZones: [],
    fontFamilyList: [],
    missingFontFamilyList: [],
    currentStore: null,
    activityPermissions: {},
    disabledSaveButton: true,
    allFontsLoaded: false,
    allImagesLoaded: false,
    autoResizeDone: true,
    wordsCountDone: false,
    disableAjustZonePositionWithKeyboard: false,
    seasonIconGroups: {},
    currentElementFocus: false,
};

export const createWorksheetSlice = createSlice({
    name: "createWorksheet",
    initialState,
    reducers: {
        setTitle: (
            state,
            action: PayloadAction<{
                title: string;
            }>
        ) => {
            state.title = action.payload.title;
        },
        setTemplate: (
            state,
            action: PayloadAction<ITemplate>
        ) => {
            state.template = action.payload;
            state.templateChanged = true;
            state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        setContent: (
            state,
            action: PayloadAction<{
                data: object;
            }>
        ) => {
            state.content = action.payload.data;
        },
        setIdeaTemplate: (
            state,
            action: PayloadAction<IIdeaTemplate>
        ) => {
            state.ideaTemplate = action.payload;
        },
        setIdeaAnswerSheetPage: (
            state,
            action: PayloadAction<IIdeaAnswerSheetPage>
        ) => {
            state.ideaAnswerSheetPage = action.payload;
        },
        setIdeaAnswerKeyPage: (
            state,
            action: PayloadAction<IIdeaAnswerKeyPage>
        ) => {
            state.ideaAnswerKeyPage = action.payload;
        },
        setIdeaThumbnail: (
            state,
            action: PayloadAction<IIdeaThumbnail>
        ) => {
            state.ideaThumbnail = action.payload;
        },
        setDataSetDesignRow: (
            state,
            action: PayloadAction<IDataSetDesignRow>
        ) => {
            state.dataSetDesignRow = action.payload;
        },
        saveWorksheet: (
            state,
        ) => {
            const now = moment().format('YYYY-MM-DD HH:mm:ss');
            state.lastSavedTime = now;
            state.designChanged = false;
        },
        setIsIframe: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.isIframe = action.payload;
        },
        setTemplateZoneDisplayMode: (
            state,
            action: PayloadAction<string>
        ) => {
            state.templateZoneDisplayMode = action.payload;
        },
        setPageType: (
            state,
            action: PayloadAction<{
                pageType: string;
            }>
        ) => {
            state.pageType = action.payload.pageType;
        },
        setAutoSave: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.autoSave = action.payload;
        },
        setFontFamilyList: (
            state,
            action: PayloadAction<IFont[]>
        ) => {
            state.fontFamilyList = action.payload;
        },
        setMissingFontFamilyList: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.missingFontFamilyList = action.payload;
        },
        addMissingFontFamilyList: (
            state,
            action: PayloadAction<string>
        ) => {
            state.missingFontFamilyList.push(action.payload);
        },
        changePageSize: (
            state,
            action: PayloadAction<{pageIndex: number, width: number, height: number, spacing: ISpacingTemplate}>
        ) => {
            state.pagesWorksheet[action.payload.pageIndex].width = action.payload.width;
            state.pagesWorksheet[action.payload.pageIndex].height = action.payload.height;
            state.pagesWorksheet[action.payload.pageIndex].spacing = action.payload.spacing;
        },
        updateWordList: (
            state,
            action: PayloadAction<{
                resourceItems: IResourceItemNew[];
                pageIndex: number;
                isNeedNewPage: boolean;
                showDirections: number;
            }>
        ) => {
            let pageIndex = action.payload.pageIndex;
            let resourceItems = action.payload.resourceItems;
            let isNeedNewPage = action.payload.isNeedNewPage;
            let showDirections = action.payload.showDirections;
            if (isNeedNewPage) {
                if (pageIndex >= state.pagesWorksheet.length) {
                    let prevIndex = pageIndex - 1;
                    let wordListTitle = state.pagesWorksheet[
                        prevIndex
                    ].resourceItems.find((el) =>
                        el.idType?.includes("word_list_title")
                    );
                    let wordDirection = state.pagesWorksheet[
                        prevIndex
                    ].resourceItems.find((el) =>
                        el.idType?.includes("word_direction")
                    );
                    if (wordListTitle) {
                        wordListTitle.isShow = false;
                        wordListTitle.activityId = "";
                    }
                    if (wordDirection) {
                        wordDirection.isShow = false;
                        wordDirection.activityId = "";
                    }
                    state.pagesWorksheet[prevIndex].resourceItems =
                        state.pagesWorksheet[prevIndex].resourceItems.filter(
                            (el) => el.type !== ConstantsTool.TYPE_WORD_LIST
                        );
                    let wsGenerator = state.pagesWorksheet[
                        prevIndex
                    ].resourceItems.find((el) => el.idType === "ws_generator");
                    state.pagesWorksheet.push(
                        new PageWorksheetNew({
                            id: "page_ws_" + state.pagesWorksheet.length,
                            width: ConstantsTool.WIDTH_A4,
                            height: ConstantsTool.HEIGHT_A4,
                            resourceItems: [
                                ...newPageResource(
                                    pageIndex,
                                    new ResourceItemNew({
                                        ...wsGenerator,
                                        id: generateIdFromDateTime(
                                            "ws_generator"
                                        ),
                                        pageIndex: pageIndex,
                                    })
                                ),
                            ],
                        })
                    );
                }
            } else {
                let wordListTitle = state.pagesWorksheet[
                    pageIndex
                ].resourceItems.find((el) =>
                    el.idType?.includes("word_list_title")
                );
                let wordDirection = state.pagesWorksheet[
                    pageIndex
                ].resourceItems.find((el) =>
                    el.idType?.includes("word_direction")
                );
                if (wordListTitle) {
                    wordListTitle.isShow = true;
                    wordListTitle.activityId = state.currentActivity.id;
                }
                if (wordDirection) {
                    if (showDirections) {
                        wordDirection.isShow = true;
                    }
                    wordDirection.activityId = state.currentActivity.id;
                }
                // edited page
                if (!state.pagesWorksheet[pageIndex].isEdited) {
                    state.pagesWorksheet[pageIndex].isEdited = true;
                }
            }
            state.pagesWorksheet[pageIndex].resourceItems = [
                ...state.pagesWorksheet[pageIndex].resourceItems.filter(
                    (el) => el.type !== ConstantsTool.TYPE_WORD_LIST
                ),
                ...resourceItems,
            ];

            for (let i = pageIndex + 1; i < state.pagesWorksheet.length; i++) {
                let checkLength = state.pagesWorksheet[i].resourceItems.filter(
                    (el) => !wordListBlockResource(el)
                ).length;
                if (checkLength === newPageResource(pageIndex).length) {
                    state.pagesWorksheet.splice(i, 1);
                }
            }
        },
        updateSizeModule: (
            state,
            action: PayloadAction<{
                size: SizeActivity;
                width: number;
                height: number;
                showWordDirection: number;
            }>
        ) => {
            const { width, height, size, showWordDirection } = action.payload;
            let currentActivityId = state.currentActivity.id;
            let resourceExit = null;
            let wordList = null;
            let wordDirection = null;
            for (let i = 0; i < state.pagesWorksheet.length; i++) {
                let resourceItems = state.pagesWorksheet[i].resourceItems;
                if (!resourceExit) {
                    resourceExit = resourceItems.find(
                        (el) =>
                            el.type === ConstantsTool.TYPE_RESOURCE_MODULE &&
                            el.activityId === currentActivityId
                    );
                }
                if (!wordList) {
                    wordList = resourceItems.find(
                        (el) =>
                            el.idType?.includes("word_list_title") &&
                            el.activityId === currentActivityId
                    );
                }
                if (!wordDirection) {
                    wordDirection = resourceItems.find(
                        (el) =>
                            el.type === ConstantsTool.TYPE_WORD_DIRECTION &&
                            el.activityId === currentActivityId
                    );
                }
            }
            // let resourceDescription =
            //     state.pagesWorksheet[0].resourceItems.find((el) =>
            //         el.idType?.includes("ws_description")
            //     );
            let ySizeLarge = 180 + 50;
            if (resourceExit) {
                resourceExit.id = generateIdFromDateTime();
                resourceExit.width = width;
                resourceExit.height = height;
                resourceExit.x = ConstantsTool.WIDTH_A4 / 2 - width / 2;
                if (size === "Large") {
                    resourceExit.y = ySizeLarge;
                } else {
                    resourceExit.y = ConstantsTool.DEFAULT_Y_MODULE;
                }
            }
            if (wordList && wordList?.width > 0) {
                if (resourceExit.pageIndex === wordList.pageIndex) {
                    if (size === "Large") {
                        wordList.y = height + 10 + ySizeLarge;
                    } else {
                        if (size === "Medium") {
                            wordList.y =
                                height + 30 + ConstantsTool.DEFAULT_Y_MODULE;
                        } else {
                            wordList.y = 750;
                        }
                    }
                    if (showWordDirection && wordDirection) {
                        wordDirection.y = wordList.y + 40;
                    }
                }
            }

            state.currentActivity.size = size;
        },
        updateCurrentToResourceItem: (
            state,
            action: PayloadAction<{
                currentElement: IResourceItemNew;
                pageIndex: number;
                isAddHistory?: boolean;
            }>
        ) => {
            let {
                currentElement,
                pageIndex,
                isAddHistory = false,
            } = action.payload;
            let resourceItem = state.pagesWorksheet[
                pageIndex
            ].resourceItems.find((el) => currentElement.id == el.id);
            if (resourceItem) {
                if (isAddHistory)
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                resourceItem.x = currentElement.x;
                resourceItem.y = currentElement.y;
                resourceItem.width = currentElement.width;
                resourceItem.height = currentElement.height;
                resourceItem.height = currentElement.height;
                resourceItem.rotate = currentElement.rotate;
                resourceItem.fieldName = currentElement.fieldName;
                resourceItem.fieldIndex = currentElement.fieldIndex;
                resourceItem.fieldDescription = currentElement.fieldDescription;
                resourceItem.staticValue = currentElement.staticValue;
                resourceItem.parentField = currentElement.parentField;
                resourceItem.parentFieldIndex = currentElement.parentFieldIndex;
                resourceItem.zoneGroup = currentElement.zoneGroup;
                resourceItem.validators = currentElement.validators;
                resourceItem.shuffle = currentElement.shuffle;
                resourceItem.type = currentElement.type;
                resourceItem.isLocked = currentElement.isLocked;
                resourceItem.linkedZoneFieldName = currentElement.linkedZoneFieldName;
                resourceItem.linkedTemplate = currentElement.linkedTemplate;

                if (resourceItem.imageAttribute) {
                    resourceItem.imageAttribute = {
                        ...currentElement.imageAttribute,
                    };
                }
                if (resourceItem.textAttribute) {
                    resourceItem.textAttribute = {
                        ...currentElement.textAttribute,
                    };
                }
                if (resourceItem.tableAttribute) {
                    resourceItem.tableAttribute = {
                        ...currentElement.tableAttribute,
                    };
                }
                state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
                state.designChanged = true;
            }
        },
        updateResourceItem: (
            state,
            action: PayloadAction<{
                currentElementId: string;
                attrValues: { attr: any; value: any }[];
                pageIndex: number;
                isChangeEidtPage?: boolean;
                isAddHistory?: boolean;
            }>
        ) => {
            let {
                pageIndex,
                currentElementId,
                isAddHistory = true,
            } = action.payload;

            const elementUpdate = state.pagesWorksheet[
                pageIndex
            ].resourceItems.find((el) => el.id === currentElementId);

            if (elementUpdate) {
                if (isAddHistory)
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                for (let i = 0; i < action.payload.attrValues.length; i++) {
                    let attrValue = action.payload.attrValues[i];
                    elementUpdate[attrValue.attr] = attrValue.value;
                }
                let check = action.payload.isChangeEidtPage
                    ? action.payload.isChangeEidtPage
                    : true;
                if (
                    state.pagesWorksheet[state.pageIndex].isEdited == false &&
                    check
                ) {
                    state.pagesWorksheet[state.pageIndex].isEdited = true;
                }
                state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
                state.designChanged = true;
            }
        },
        resetTempTemplatesZones: (
            state,
            action: PayloadAction<{}>
        ) => {
            state.tempTemplateZones = [];
        },
        replaceZoneByLinkedText: (
            state,
            action: PayloadAction<{
                zoneID: string;
                pageIndex: number;
                linkedText: IResourceItemNew;
                isMathFormula: boolean;
            }>
        ) => {
            const index = state.pagesWorksheet[action.payload.pageIndex].resourceItems.findIndex(item => item.id === action.payload.zoneID);
            const zone = state.pagesWorksheet[action.payload.pageIndex].resourceItems[index];
            const linkedText = action.payload.linkedText;
            if (state.tempTemplateZones.find(zone => zone.id === action.payload.zoneID) === undefined) {
                state.tempTemplateZones.push(zone);
            }
            if (action.payload.isMathFormula) {
                linkedText.type = ConstantsTool.TYPE_RESOURCE_MATH_FORMULA;
            }
            state.pagesWorksheet[action.payload.pageIndex].resourceItems[index] = linkedText;
        },
        replaceLinkedTextByZone: (
            state,
            action: PayloadAction<{
                linkedTextID: string;
                pageIndex: number;
                zone: IResourceItemNew;
            }>
        ) => {
            const index = state.pagesWorksheet[action.payload.pageIndex].resourceItems.findIndex(item => item.id === action.payload.linkedTextID);
            state.pagesWorksheet[action.payload.pageIndex].resourceItems[index] = action.payload.zone;
        },
        updateTempTemplateZone: (
            state,
            action: PayloadAction<{
                zoneID: string;
                zone: IResourceItemNew;
            }>
        ) => {
            const index = state.tempTemplateZones.findIndex(zone => zone.id === action.payload.zoneID);
            state.tempTemplateZones[index] = action.payload.zone;
        },
        updateStudentInfo: (
            state,
            action: PayloadAction<IResourceItemNew[]>
        ) => {
            state.pagesWorksheet[0].resourceItems = [
                ...state.pagesWorksheet[0].resourceItems.filter(
                    (el) => !el.idType?.includes("student")
                ),
                ...action.payload,
            ];
            // edited page
            if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                state.pagesWorksheet[state.pageIndex].isEdited = true;
            }
        },
        updateGenerator: (
            state,
            action: PayloadAction<{
                newWidth: number;
                newX: number;
                newContent: string;
            }>
        ) => {
            let newWidth = action.payload.newWidth;
            let newX = action.payload.newX;
            let newContent = action.payload.newContent;

            for (let i = 0; i < state.pagesWorksheet.length; i++) {
                let generatorText: IResourceItemNew = state.pagesWorksheet[
                    i
                ].resourceItems.find((el) =>
                    el.idType?.includes("ws_generator")
                );

                generatorText.textAttribute.content = newContent;
                generatorText.width = newWidth;
                generatorText.x = newX;
            }
        },
        addResourceItems: (
            state,
            action: PayloadAction<{
                pageIndex: number;
                resourceItems: IResourceItemNew[];
                isAddHistory?: boolean;
            }>
        ) => {
            let { isAddHistory = true } = action.payload;
            if (action.payload.resourceItems.length) {
                if (isAddHistory) {
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                }
                let pageIndex = action.payload.pageIndex;
                let resources = action.payload.resourceItems.map(
                    (resourceItem) => {
                        return new ResourceItemNew({
                            ...resourceItem,
                        });
                    }
                );
                resources.forEach((resource) => {
                    if (
                        resource.type ===
                        ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                    ) {
                        state.pagesWorksheet[pageIndex].resourceItems =
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.filter(
                                (el) =>
                                    (el.type !==
                                        ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
                                        el.type !==
                                            ConstantsTool.RESOURCE_BACKGROUND &&
                                        el.type !==
                                            ConstantsTool.TYPE_RESOURCE_BORDER) ||
                                    el.idType?.includes("background_ws_pdf_")
                            );
                        let existElementWsPdf = state.pagesWorksheet[
                            pageIndex
                        ].resourceItems.filter(
                            (el) =>
                                el.type ===
                                    ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
                                el.idType?.includes("background_ws_pdf_")
                        );
                        if (existElementWsPdf.length > 0) {
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(1, 0, resource);
                        } else {
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(0, 0, resource);
                        }
                    } else {
                        if (
                            resource.type ===
                            ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
                        ) {
                            state.pagesWorksheet[pageIndex].resourceItems =
                                state.pagesWorksheet[
                                    pageIndex
                                ].resourceItems.filter(
                                    (el) =>
                                        el.type !==
                                            ConstantsTool.TYPE_RESOURCE_BORDER &&
                                        el.type !==
                                            ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
                                );
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(1, 0, resource);
                        } else {
                            state.pagesWorksheet[pageIndex].resourceItems.push(
                                resource
                            );
                        }
                    }
                });
                if (!state.pagesWorksheet[pageIndex].isEdited) {
                    state.pagesWorksheet[pageIndex].isEdited = true;
                }
                state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
                state.designChanged = true;
            }
        },
        updateIsOnEditText: (state, action: PayloadAction<any>) => {
            state.isOnEditText = action.payload;
        },
        updateIsOnDrag: (state, action: PayloadAction<boolean>) => {
            state.isOnDrag = action.payload;
        },
        selectElements: (
            state,
            action: PayloadAction<{
                pageIndex: number;
                resourceSelected: IResourceItemNew[];
            }>
        ) => {
            let pageIndex = action.payload.pageIndex;

            state.resourceItemSelected = action.payload.resourceSelected;
        },
        removeWordList: (state) => {
            for (let i = 0; i < state.pagesWorksheet.length; i++) {
                let existWordListBlockResource = state.pagesWorksheet[
                    i
                ].resourceItems.filter((el) =>
                    wordListBlockResource(el)
                ).length;
                if (existWordListBlockResource) {
                    state.pagesWorksheet[i].resourceItems =
                        state.pagesWorksheet[i].resourceItems.filter((item) => {
                            return item.type !== ConstantsTool.TYPE_WORD_LIST;
                        });
                }
                // after remove word list, check current page if the page is blank, then delete it
                let checkLength = state.pagesWorksheet[i].resourceItems.filter(
                    (el) => !wordListBlockResource(el)
                ).length;
                if (checkLength === newPageResource(0).length) {
                    state.pagesWorksheet.splice(i, 1);
                }
            }
        },
        setShowWordList: (state, action: PayloadAction<number>) => {
            let showWordList = action.payload;
            let wordListTitleResource = null;
            let wordListResource = null;
            for (let i = 0; i < state.pagesWorksheet.length; i++) {
                let resourceItems = state.pagesWorksheet[i].resourceItems;
                if (!wordListResource) {
                    wordListResource = resourceItems.find(
                        (el) =>
                            el.type === ConstantsTool.TYPE_WORD_LIST &&
                            el.activityId === state.currentActivity.id
                    );
                }

                if (!wordListTitleResource) {
                    wordListTitleResource = resourceItems.find(
                        (el) =>
                            el.idType?.includes("word_list_title") &&
                            el.activityId === state.currentActivity.id
                    );
                }
            }
            if (showWordList) {
                if (wordListTitleResource) {
                    let wordListTitleTextAttr =
                        wordListTitleResource?.textAttribute;
                    wordListTitleResource.width = getTextWidthHeightTool({
                        textValue: wordListTitleTextAttr?.content,
                        includePadding: true,
                        fontSize: wordListTitleTextAttr?.fontSize,
                        fontFamily: wordListTitleTextAttr?.fontFamily,
                    }).width;
                    if (wordListResource) {
                        wordListResource.isShow = true;
                    }
                    wordListTitleResource.isShow = true;
                }
            } else {
                if (wordListResource) {
                    wordListResource.isShow = false;
                }
                if (wordListTitleResource) {
                    wordListTitleResource.isShow = false;
                }
            }
        },
        updateRatio: (state, action: PayloadAction<number>) => {
            state.ratio = action.payload;
        },
        updateDirections: (
            state,
            action: PayloadAction<{
                showDirections: number;
                valueDirections: number;
                // pageIndex: number;
            }>
        ) => {
            let showDirection = action.payload.showDirections;
            let valueDirections = action.payload.valueDirections;
            let currentActivityId = state.currentActivity.id;
            let wordListResource = null;
            let wordDirection = null;
            let wordListTitleResource = null;

            for (let i = 0; i < state.pagesWorksheet.length; i++) {
                let resourceItems = state.pagesWorksheet[i].resourceItems;
                if (!wordDirection) {
                    wordDirection = resourceItems.find(
                        (el) =>
                            el.type === ConstantsTool.TYPE_WORD_DIRECTION &&
                            el.activityId === currentActivityId
                    );
                }
                if (!wordListTitleResource) {
                    wordListTitleResource = resourceItems.find(
                        (el) =>
                            el.idType?.includes("word_list_title") &&
                            el.activityId === state.currentActivity.id
                    );
                }
                if (!wordListResource) {
                    wordListResource = resourceItems.find(
                        (el) =>
                            el.type === ConstantsTool.TYPE_WORD_LIST &&
                            el.activityId === state.currentActivity.id
                    );
                }
            }
            if (wordDirection) {
                if (!showDirection) {
                    wordDirection.isShow = false;
                } else {
                    let textWidth = getTextWidthHeightTool({
                        textValue: ConstantsTool.TEXT_WORD_DIRECTION,
                        includePadding: true,
                        fontSize: 24,
                    }).width;
                    switch (valueDirections) {
                        case 0:
                            wordDirection.width =
                                100 +
                                textWidth +
                                ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                            wordDirection.imageAttribute.svgContent =
                                IconSVG.WORD_DIRECTION_IMAGE_0;
                            break;
                        case 1:
                            wordDirection.width =
                                130 +
                                textWidth +
                                ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                            wordDirection.imageAttribute.svgContent =
                                IconSVG.WORD_DIRECTION_IMAGE_1;
                            break;
                        case 2:
                            wordDirection.width =
                                160 +
                                textWidth +
                                ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                            wordDirection.imageAttribute.svgContent =
                                IconSVG.WORD_DIRECTION_IMAGE_2;
                            break;
                        case 3:
                            wordDirection.width = 240 + textWidth + 10;
                            wordDirection.imageAttribute.svgContent =
                                IconSVG.WORD_DIRECTION_IMAGE_3;
                            break;
                    }
                    wordDirection.x =
                        ConstantsTool.WIDTH_A4 / 2 - wordDirection.width / 2;
                    wordDirection.isShow = true;
                    if (wordListTitleResource) {
                        wordDirection.y =
                            wordListTitleResource.y +
                            wordListTitleResource.height +
                            ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                    }
                    if (wordListResource?.isShow) {
                        wordListResource.y =
                            wordDirection.y +
                            wordDirection.height +
                            ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                    }
                    // edited page
                    if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                        state.pagesWorksheet[state.pageIndex].isEdited = true;
                    }
                }
            }
        },
        removeResourceItems: (
            state,
            action: PayloadAction<{
                resourceIds: string[];
                pageIndex: number;
                isAddHistory?: boolean;
            }>
        ) => {
            let { isAddHistory = true } = action.payload;
            if (action.payload.resourceIds.length) {
                if (isAddHistory)
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                let pageIndex = action.payload.pageIndex;
                action.payload.resourceIds.forEach((resourceId) => {
                    let resourceIndex = state.pagesWorksheet[
                        pageIndex
                    ].resourceItems.findIndex((el) => el.id === resourceId);
                    if (resourceIndex > -1) {
                        let resourceDelete =
                            state.pagesWorksheet[pageIndex].resourceItems[
                                resourceIndex
                            ];
                        if (
                            (resourceDelete.type ===
                                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE ||
                                resourceDelete.type ===
                                    ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES) &&
                            resourceDelete.type ===
                                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                            // && (!state.worksheet ||
                            //     state.worksheet.originUrl ===
                            //     Config.USER_CREATED_WORD_SEARCH)
                        ) {
                            let checkHaveFrames = state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.find(
                                (el) =>
                                    (el.type ===
                                        ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES ||
                                        el.type ===
                                            ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE) &&
                                    resourceDelete.id !== el.id
                            );
                            if (!checkHaveFrames) {
                                state.pagesWorksheet[
                                    pageIndex
                                ].resourceItems.push(resourceBorderDefault());
                            }
                        }
                        if (
                            resourceDelete.type ===
                            ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                        ) {
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(resourceIndex, 1);

                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(
                                0,
                                0,
                                resourceBackgroundDefault()
                            );
                        } else {
                            // if (
                            //     resourceDelete.idType?.includes("ws_title")
                            //     //  ||  resourceDelete.idType?.includes("ws_description")
                            // ) {
                            //     state.pagesWorksheet[pageIndex].resourceItems[
                            //         resourceIndex
                            //     ].isShow = false;
                            // } else {
                            state.pagesWorksheet[
                                pageIndex
                            ].resourceItems.splice(resourceIndex, 1);
                            // }
                        }
                        if (!state.pagesWorksheet[pageIndex].isEdited) {
                            state.pagesWorksheet[pageIndex].isEdited = true;
                        }
                    }
                });
                state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
                state.designChanged = true;
            }
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateWorkSheetCreator: (
            state,
            action: PayloadAction<{
                worksheetCreator?: WorkSheetCreator;
                activity?: Activity;
                isAddHistory?: boolean;
            }>
        ) => {
            let { isAddHistory = true } = action.payload;
            if (isAddHistory)
                undoHistoryStore.update({
                    pagesWorksheet: state.pagesWorksheet,
                    currentActivity: state.currentActivity,
                });
            if (action.payload.worksheetCreator) {
                const worksheetCreator = action.payload.worksheetCreator;
                if (worksheetCreator) {
                    Object.keys(worksheetCreator).forEach((key) => {
                        if (
                            state.worksheetCreator &&
                            worksheetCreator[key] != undefined
                        ) {
                            state.worksheetCreator[key] = worksheetCreator[key];
                        }
                    });
                }
            }
            if (action.payload.activity && state.currentActivity) {
                let key = Object.keys(action.payload.activity)[0];

                if (key) {
                    state.currentActivity[key] = action.payload.activity[key];
                    let index = state.activities.findIndex(
                        (el) => el.id === state.currentActivity.id
                    );
                    if (index > -1) {
                        state.activities[index][key] =
                            action.payload.activity[key];
                    }
                }
            }
            state.forceUpdate = false;
        },
        updateActivities: (
            state,
            action: PayloadAction<{
                activities: Activity[];
            }>
        ) => {
            if (action.payload.activities) {
                state.activities = action.payload.activities;
            }
        },
        updatePageIndex: (
            state,
            action: PayloadAction<{
                pageIndex: number;
            }>
        ) => {
            state.pageIndex = action.payload.pageIndex ?? state.pageIndex;
        },
        setCurrentActivity: (
            state,
            action: PayloadAction<{
                activity: IActivity;
            }>
        ) => {
            state.currentActivity = action.payload.activity;
        },
        updatePageWSS: (
            state,
            action: PayloadAction<{
                pageWSS: IPageWorksheetNew[];
            }>
        ) => {
            const pageWSS = action.payload.pageWSS;
            state.pagesWorksheet = pageWSS;
        },
        updateCurrentActivityToListActivity: (
            state,
            action: PayloadAction<{
                activity: Activity;
            }>
        ) => {
            const activity = action.payload.activity;

            const index = state.activities.findIndex(
                (a) => a.id === activity.id
            );

            if (index > -1) {
                state.activities[index] = activity;
            }
        },
        updateResourceWordBank: (
            state,
            action: PayloadAction<{
                resourceItem: ResourceItemNew;
            }>
        ) => {
            let resourceItemNew = action.payload.resourceItem;
            let pageIndex = resourceItemNew.pageIndex;
            let resourceExit = state.pagesWorksheet[
                pageIndex
            ].resourceItems.find((el) => el.id === resourceItemNew.id);
            resourceExit.height = resourceItemNew.height;
            resourceExit.wordBanks = [...resourceItemNew.wordBanks];
            // edited page
            if (!state.pagesWorksheet[pageIndex].isEdited) {
                state.pagesWorksheet[pageIndex].isEdited = true;
            }
        },
        updateManyResourceByType: (
            state,
            action: PayloadAction<{
                pagesWs: IPageWorksheetNew[];
                type: number;
                resourceUpdateId?: string;
            }>
        ) => {
            let pagesWs = action.payload.pagesWs;
            let type = action.payload.type;
            let maxLength =
                pagesWs.length > state.pagesWorksheet.length
                    ? pagesWs.length
                    : state.pagesWorksheet.length;
            if (pagesWs.length) {
                for (let i = 0; i < maxLength; i++) {
                    if (i >= state.pagesWorksheet.length) {
                        let wsGenerator =
                            state.pagesWorksheet[0].resourceItems.find(
                                (el) => el.idType === "ws_generator"
                            );
                        ConstantsTool.changeOrientation(
                            ConstantsTool.ORIENTATION.PORTRAIT
                        );
                        state.pagesWorksheet.push(
                            new PageWorksheetNew({
                                id: "page_ws_" + state.pagesWorksheet.length,
                                width: SizeA4.WIDTH_A4,
                                height: SizeA4.HEIGHT_A4,
                                resourceItems: [
                                    ...newPageResource(
                                        i,
                                        new ResourceItemNew({
                                            ...wsGenerator,
                                            id: generateIdFromDateTime(
                                                "ws_generator"
                                            ),
                                            pageIndex: i,
                                        })
                                    ),
                                    ...(pagesWs[i]?.resourceItems ?? []),
                                ],
                            })
                        );
                    } else {
                        let pageWs = state.pagesWorksheet[i];
                        let newResources = [
                            ...pageWs.resourceItems.filter(
                                (el) => el.type !== type
                            ),
                            ...(pagesWs[i]?.resourceItems ?? []),
                        ];
                        if (newResources.length === newPageResource(0).length) {
                            state.pagesWorksheet.splice(i, 1);
                            if (
                                state.pageIndex >
                                state.pagesWorksheet.length - 1
                            ) {
                                state.pageIndex =
                                    state.pagesWorksheet.length - 1;
                            }
                        } else {
                            state.pagesWorksheet[i].resourceItems = [
                                ...newResources,
                            ];
                        }
                    }
                }
            }
            if (pagesWs.length < state.pagesWorksheet.length) {
                for (
                    let i = state.pagesWorksheet.length - 1;
                    i >= pagesWs.length;
                    i--
                ) {
                    let pageWs = state.pagesWorksheet[i];
                    let newResources = [
                        ...pageWs.resourceItems.filter(
                            (el) => el.type !== type
                        ),
                        ...(pagesWs[i]?.resourceItems ?? []),
                    ];
                    if (newResources.length === newPageResource(0).length) {
                        state.pagesWorksheet.splice(i, 1);
                        if (state.pageIndex > state.pagesWorksheet.length - 1) {
                            state.pageIndex = state.pagesWorksheet.length - 1;
                        }
                    } else {
                        state.pagesWorksheet[i].resourceItems = [
                            ...newResources,
                        ];
                    }
                }
            }
            if (action.payload.resourceUpdateId) {
                for (let i in state.pagesWorksheet) {
                    for (let j in state.pagesWorksheet[i].resourceItems) {
                        if (
                            action.payload.resourceUpdateId ===
                            state.pagesWorksheet[i].resourceItems[j]?.id
                        ) {
                            state.resourceItemSelected = [
                                state.pagesWorksheet[i].resourceItems[j],
                            ];
                            state.pageIndex = parseInt(i);
                            return;
                        }
                    }
                }
            }
            state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
            state.designChanged = true;
        },
        backwardResourceAction: (
            state,
            action: PayloadAction<{
                pageIndex: number;
                resourceId: string;
                action: string;
            }>
        ) => {
            let pageIndex = action.payload.pageIndex;
            let resourceId = action.payload.resourceId;
            let resourceIndex = state.pagesWorksheet[
                pageIndex
            ].resourceItems.findIndex((el) => el.id === resourceId);
            let resourceItem = state.pagesWorksheet[
                pageIndex
            ].resourceItems.find((el) => el.id === resourceId);
            if (isResourceBackground(resourceItem.type)) {
                moveElementFromIndexToIndex(
                    state.pagesWorksheet[pageIndex].resourceItems,
                    resourceIndex,
                    0
                );
            } else {
                let toPosIndex: number = null;
                if (action.payload.action === 'ncw_send_to_back') {
                    toPosIndex = 0;
                } else if (resourceIndex > 0) {
                    toPosIndex = resourceIndex - 1;
                }
                moveElementFromIndexToIndex(
                    state.pagesWorksheet[pageIndex].resourceItems,
                    resourceIndex,
                    toPosIndex
                );
            }
        },
        // initWorksheetInfo: (state, action: PayloadAction<IWorksheet>) => {
        //     state.worksheet = action.payload;
        // },
        forwardResourceAction: (
            state,
            action: PayloadAction<{
                pageIndex: number;
                resourceId: string;
                action: string;
            }>
        ) => {
            let pageIndex = action.payload.pageIndex;
            let resourceId = action.payload.resourceId;
            let resourceIndex = state.pagesWorksheet[
                pageIndex
            ].resourceItems.findIndex((el) => el.id === resourceId);
            undoHistoryStore.update({
                pagesWorksheet: state.pagesWorksheet,
                currentActivity: state.currentActivity,
            });
            let toPosIndex: number = null;
            if (action.payload.action === 'ncw_bring_to_front') {
                toPosIndex = state.pagesWorksheet[pageIndex].resourceItems.length - 1;
            } else if (resourceIndex < state.pagesWorksheet[pageIndex].resourceItems.length - 1) {
                toPosIndex = resourceIndex + 1;
            }
            if (toPosIndex !== null) {
                moveElementFromIndexToIndex(
                    state.pagesWorksheet[pageIndex].resourceItems,
                    resourceIndex,
                    toPosIndex,
                );
            }
        },
        updateQuestion: (
            state,
            action: PayloadAction<{
                question: IQuestionActivity;
            }>
        ) => {
            const type = state.currentActivity.type;
            const { attribute } = getConfigByActivity(type);
            const question = new QuestionActivity({
                ...action.payload.question,
                type,
            });

            const index = state.currentActivity.questions.findIndex(
                (s) => s.id === question.id
            );

            if (index > -1) {
                const fontFamily = getFontFamily({
                    fontName: question[attribute].fontFamily,
                    fontStyle: question[attribute].fontStyle,
                    textStyle: question[attribute].textStyle,
                });
                const newResourceItemIds = [];
                state.pagesWorksheet.forEach((page) => {
                    page.resourceItems.forEach((r) => {
                        if (
                            question[attribute].resourceItemIds.includes(r.id)
                        ) {
                            const id = generateIdFromDateTime();
                            newResourceItemIds.push(id);
                            r.id = id;
                            r.textAttribute.autoFill =
                                question[attribute].autoFill;
                            r.textAttribute.fontFamily = fontFamily;
                            r.textAttribute.color = question[attribute].color;
                            r.textAttribute.content = question.question;
                            r.textAttribute.fontColor =
                                question[attribute].fontColor;
                        }
                    });
                });
                question[attribute].resourceItemIds = newResourceItemIds;
                state.currentActivity.questions[index] = question;
                let activityIndex = state.activities.findIndex(
                    (el) => el.id === state.currentActivity.id
                );
                state.activities[activityIndex] = new Activity({
                    ...state.currentActivity,
                });
                // edited page
                if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                    state.pagesWorksheet[state.pageIndex].isEdited = true;
                }
            }
        },
        addQuestion: (
            state,
            action: PayloadAction<{
                question: IQuestionActivity;
            }>
        ) => {
            const type = state.currentActivity.type;
            const { attribute, resourceType, width, x, y_first } =
                getConfigByActivity(type);
            const question = action.payload.question;
            const questions = state.currentActivity.questions;
            const newHeight =
                ConstantsTool.LINE_SPACING[question[attribute].lineSpacing];
            const resourceId = generateIdFromDateTime();
            const newResource = new ResourceItemNew({
                id: resourceId,
                idType: resourceId,
                activityId: state.currentActivity.id,
                type: resourceType,
                width,
                height: newHeight + getGapBetweenItem(newHeight),
                textAttribute: {
                    fontFamily: getFontFamily({
                        fontName: question[attribute].fontFamily,
                        fontStyle: question[attribute].fontStyle,
                        textStyle: question[attribute].textStyle,
                    }),
                    content: question.question,
                    color: question[attribute].color,
                    fontSize: newHeight,
                    fontColor: question[attribute].fontColor,
                },
                x,
                y: y_first,
            });

            state.pagesWorksheet[0].resourceItems.push(newResource);
            question[attribute].resourceItemIds.push(resourceId);
            questions.push(question);

            updateLocationByIndex({
                questions,
                index: questions.length - 1,
                pagesWorksheet: state.pagesWorksheet,
                activity: type,
            });

            let activityIndex = state.activities.findIndex(
                (el) => el.id === state.currentActivity.id
            );
            state.activities[activityIndex] = new Activity({
                ...state.currentActivity,
            });
            // edited page
            if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                state.pagesWorksheet[state.pageIndex].isEdited = true;
            }
        },
        deleteQuestion: (
            state,
            action: PayloadAction<{
                questionId: string;
                activityItemIdSelected: string;
            }>
        ) => {
            const type = state.currentActivity.type;
            const { attribute } = getConfigByActivity(type);
            const questionId = action.payload.questionId;
            const questions = state.currentActivity.questions;
            const index = questions.findIndex((s) => s.id === questionId);

            if (index > -1) {
                const deleteQuestion = questions.splice(index, 1)[0];
                const resourceItemIds =
                    deleteQuestion[attribute].resourceItemIds;
                state.pagesWorksheet.forEach((page) => {
                    page.resourceItems = page.resourceItems.filter(
                        (r) => !resourceItemIds.includes(r.id)
                    );
                });
                updateLocationByIndex({
                    questions,
                    index,
                    pagesWorksheet: state.pagesWorksheet,
                    activity: type,
                });

                let activityIndex = state.activities.findIndex(
                    (el) => el.id === state.currentActivity.id
                );
                state.activities[activityIndex] = new Activity({
                    ...state.currentActivity,
                });
                // edited page
                if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                    state.pagesWorksheet[state.pageIndex].isEdited = true;
                }
            }
        },
        updateLineSpacing: (
            state,
            action: PayloadAction<{
                question: IQuestionActivity;
            }>
        ) => {
            const type = state.currentActivity.type;
            const question = action.payload.question;
            const index = state.currentActivity.questions.findIndex(
                (s) => s.id === question.id
            );

            if (index > -1) {
                state.currentActivity.questions[index] = question;
                updateLocationByIndex({
                    questions: state.currentActivity.questions,
                    index,
                    pagesWorksheet: state.pagesWorksheet,
                    activity: type,
                });
                let activityIndex = state.activities.findIndex(
                    (el) => el.id === state.currentActivity.id
                );
                state.activities[activityIndex] = new Activity({
                    ...state.currentActivity,
                });
            }
        },
        updateNumberOfLine: (
            state,
            action: PayloadAction<{
                question: IQuestionActivity;
            }>
        ) => {
            const type = state.currentActivity.type;
            const { attribute, resourceType, width, x, y_first } =
                getConfigByActivity(type);
            // FILTER
            const question = new QuestionActivity({
                ...action.payload.question,
                type,
            });
            const numberOfLines = question[attribute].numberOfLines;
            if (question[attribute].resourceItemIds.length > numberOfLines) {
                const resourceItemIds = [
                    ...question[attribute].resourceItemIds,
                ];
                const filterResourceItemIds =
                    resourceItemIds.splice(numberOfLines);
                question[attribute].resourceItemIds = resourceItemIds;
                state.pagesWorksheet.forEach((page) => {
                    page.resourceItems = page.resourceItems.filter(
                        (r) => !filterResourceItemIds.includes(r.id)
                    );
                });
            } else {
                const totalNewItem =
                    numberOfLines - question[attribute].resourceItemIds.length;
                const newHeight =
                    ConstantsTool.LINE_SPACING[question[attribute].lineSpacing];
                for (let i = 0; i < totalNewItem; i++) {
                    const resourceId = generateIdFromDateTime();
                    const newResource = new ResourceItemNew({
                        id: resourceId,
                        idType: resourceId,
                        activityId: state.currentActivity.id,
                        type: resourceType,
                        width,
                        height: newHeight + getGapBetweenItem(newHeight),
                        textAttribute: {
                            fontFamily: getFontFamily({
                                fontName: question[attribute].fontFamily,
                                fontStyle: question[attribute].fontStyle,
                                textStyle: question[attribute].textStyle,
                            }),
                            content: question.question,
                            color: question[attribute].color,
                            fontSize: newHeight,
                            autoFill: question[attribute].autoFill,
                            fontColor: question[attribute].fontColor ?? "#000",
                        },
                        x,
                        y: y_first,
                    });
                    question[attribute].resourceItemIds = [
                        ...question[attribute].resourceItemIds,
                        resourceId,
                    ];
                    state.pagesWorksheet[0].resourceItems.push(newResource);
                }

                // edited page
                if (!state.pagesWorksheet[state.pageIndex].isEdited) {
                    state.pagesWorksheet[state.pageIndex].isEdited = true;
                }
            }

            const index = state.currentActivity.questions.findIndex(
                (s) => s.id === question.id
            );

            if (index > -1) {
                state.currentActivity.questions[index] = question;
                updateLocationByIndex({
                    questions: state.currentActivity.questions,
                    index,
                    pagesWorksheet: state.pagesWorksheet,
                    activity: type,
                });
                let activityIndex = state.activities.findIndex(
                    (el) => el.id === state.currentActivity.id
                );
                state.activities[activityIndex] = new Activity({
                    ...state.currentActivity,
                });
            }
        },
        updateOrientation: (
            state,
            action: PayloadAction<{
                orientation: number;
                isPerfectConfig?: boolean;
            }>
        ) => {
            const orientation = action.payload.orientation;
            // Change config
            ConstantsTool.changeOrientation(orientation);

            // Update ratio !! have to change this to trigger update
            const DIVIDE_NUMBER =
                orientation === ConstantsTool.ORIENTATION.LANDSCAPE
                    ? 980
                    : 1124;
            state.ratio = ((window.innerHeight - 90) * 1) / DIVIDE_NUMBER;

            // Update width height item
            state.pagesWorksheet.forEach((page) => {
                page.width = ConstantsTool.WIDTH_A4;
                page.height = ConstantsTool.HEIGHT_A4;
                page.resourceItems.forEach((item) => {
                    if (item.idType?.includes("ws_generator")) {
                        item.x = ConstantsTool.WIDTH_A4 - item.width - 18; // Minus width and padding
                        item.y = ConstantsTool.HEIGHT_A4 - 33;
                    }
                    if (item.idType?.includes("logo")) {
                        item.x = ConstantsTool.BORDER_X;
                        item.y = ConstantsTool.HEIGHT_A4 - 33;
                    }
                    if (item.type === ConstantsTool.TYPE_RESOURCE_BORDER) {
                        item.width = ConstantsTool.BORDER_WIDTH;
                        item.height = ConstantsTool.BORDER_HEIGHT;
                    }
                    if (
                        [
                            ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE,
                            ConstantsTool.RESOURCE_BACKGROUND,
                            ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES,
                        ].includes(item.type)
                    ) {
                        item.width = ConstantsTool.WIDTH_A4;
                        item.height = ConstantsTool.HEIGHT_A4;
                    }
                    if (item.idType?.includes("ws_title_")) {
                        item.width = ConstantsTool.WIDTH_A4 - 80;
                    }
                    if (item.idType?.includes("ws_student_value_")) {
                        const index = parseInt(item.idType.slice(-1));

                        const portraitValue =
                            ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.PORTRAIT[
                                index
                            ];
                        const landscapeValue =
                            ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.LANDSCAPE[
                                index
                            ];
                        if (index === 0) {
                            item.width = ConstantsTool.STUDENT_INFO_1_WIDTH;
                            if (
                                orientation ===
                                    ConstantsTool.ORIENTATION.PORTRAIT &&
                                item.textAttribute.content === landscapeValue
                            ) {
                                item.textAttribute.content = portraitValue;
                            } else if (
                                orientation ===
                                    ConstantsTool.ORIENTATION.LANDSCAPE &&
                                item.textAttribute.content === portraitValue
                            ) {
                                item.textAttribute.content = landscapeValue;
                            }
                        } else if (index === 1) {
                            item.width = ConstantsTool.STUDENT_INFO_2_WIDTH;
                            item.x =
                                ConstantsTool.STUDENT_INFO_1_WIDTH +
                                ConstantsTool.STUDENT_INFO_START_X;

                            if (
                                orientation ===
                                    ConstantsTool.ORIENTATION.PORTRAIT &&
                                item.textAttribute.content === landscapeValue
                            ) {
                                item.textAttribute.content = portraitValue;
                            } else if (
                                orientation ===
                                    ConstantsTool.ORIENTATION.LANDSCAPE &&
                                item.textAttribute.content === portraitValue
                            ) {
                                item.textAttribute.content = landscapeValue;
                            }
                        }
                    }
                });
            });
            if (
                state.currentActivity.type ===
                Config.ACTIVITY_TYPE.NAME_TRACING.TYPE
            ) {
                updateLocationByIndex({
                    questions: state.currentActivity.questions,
                    index: 0,
                    pagesWorksheet: state.pagesWorksheet,
                    activity: state.currentActivity.type,
                });

                // DELETE PAGE WHEN MEET PERFECT CONFIG
                const DEFAULT_ITEMS_CAN_NOT_DELETE = newPageResource(0).length;
                if (
                    action?.payload?.isPerfectConfig &&
                    state.pagesWorksheet?.length === 2 &&
                    state.pagesWorksheet[1].resourceItems?.length ===
                        DEFAULT_ITEMS_CAN_NOT_DELETE
                ) {
                    state.pagesWorksheet = [state.pagesWorksheet[0]];
                }
            }
            state.currentActivity.orientation = orientation;
            let activityIndex = state.activities.findIndex(
                (el) => el.id === state.currentActivity.id
            );
            state.activities[activityIndex] = new Activity({
                ...state.currentActivity,
            });
        },
        addPageWorksheet: (
            state,
            action: PayloadAction<{
                resourceItems: IResourceItemNew[] | null;
                isAddHistory?: boolean;
                pageIndex?: number;
            }>
        ) => {
            let {
                resourceItems,
                isAddHistory = true,
                pageIndex,
            } = action.payload;
            if (isAddHistory)
                undoHistoryStore.update({
                    pagesWorksheet: state.pagesWorksheet,
                    currentActivity: state.currentActivity,
                });
            let index = pageIndex ?? state.pageIndex;
            let pageWorksheet = new PageWorksheetNew({
                id: generateIdFromDateTime(),
                width: SizeA4.WIDTH_A4,
                height: SizeA4.HEIGHT_A4,
                resourceItems: resourceItems ?? [],
                sourcePage: Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH,
            });
            state.pagesWorksheet.splice(index + 1, 0, pageWorksheet);
            updateIndexInPageWorksheet(state.pagesWorksheet);
            if (index + 1 < state.pagesWorksheet.length)
                state.pageIndex = index + 1;
        },
        removeUnusedPages: (state) => {
            const maxPageIndex = deleteUnusedPages({
                pages: state.pagesWorksheet,
            });

            state.pageIndex = Math.max(0, maxPageIndex);
        },
        updateOpenWarningReplaceTemplate: (state) => {
            state.openWarningReplaceTemplate =
                !state.openWarningReplaceTemplate;
        },
        updateSelectedWorksheetId: (
            state,
            action: PayloadAction<{
                worksheetId: string;
            }>
        ) => {
            state.selectedWorksheetId = action.payload.worksheetId;
        },
        upDateShowAnswerKey: (state, action: PayloadAction<boolean>) => {
            state.isShowAnswerKey = action.payload;
        },
        upDateHideTextShape: (state, action: PayloadAction<boolean>) => {
            state.isHideTextShape = action.payload;
        },
        upDateResize: (state, action: PayloadAction<boolean>) => {
            state.isResize = action.payload;
        },
        upDateOnEditBorder: (state, action: PayloadAction<boolean>) => {
            state.isOnEditBorder = action.payload;
        },
        changePageIndex: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload;
        },
        updateCrosswordItems: (
            state,
            action: PayloadAction<{
                layout: string;
                showWordBank: boolean;
                updateImage?: boolean;
            }>
        ) => {
            const activityId = state.currentActivity.id;
            const config = action.payload;
            const isUpdateImage = config.updateImage;
            const crosswordItems = [];

            state.pagesWorksheet.forEach((page) => {
                const activityItems = [];
                const otherItems = [];
                const imageItem = [];
                page.resourceItems.forEach((item) => {
                    if (item.idType?.includes("crossword_")) {
                        if (item.idType?.includes("_image")) {
                            imageItem.push(item);
                        } else {
                            activityItems.push(item);
                        }
                    } else {
                        otherItems.push(item);
                    }
                });

                if (isUpdateImage) {
                    crosswordItems.push(...imageItem);
                } else {
                    otherItems.push(...imageItem);
                }

                page.resourceItems = otherItems;
                crosswordItems.push(...activityItems);
            });

            updateCrosswordItemsPosition({
                config,
                pages: state.pagesWorksheet,
                activityId,
                oldCrosswordItems: crosswordItems,
                isUpdateImage,
            });
        },
        updateLayouItemsCrossword: (
            state,
            action: PayloadAction<{ layout: string }>
        ) => {
            const activityId = state.currentActivity.id;
            const layout = action.payload.layout;
            const crosswordItems = [];

            state.pagesWorksheet.forEach((page) => {
                const activityItems = [];
                const otherItems = [];
                page.resourceItems.forEach((item) => {
                    if (item.idType?.includes("crossword_")) {
                        activityItems.push(item);
                    } else {
                        otherItems.push(item);
                    }
                });
                page.resourceItems = otherItems;
                crosswordItems.push(...activityItems);
            });

            state.pagesWorksheet[0].resourceItems.push(
                ...getPositionCrosswordByLayout(
                    layout,
                    activityId,
                    crosswordItems
                )
            );
        },
        updateWordbankCrosswordItem: (
            state,
            action: PayloadAction<{ status: boolean }>
        ) => {
            const status = action.payload.status;

            state.pagesWorksheet.forEach((page) => {
                page.resourceItems.forEach((item) => {
                    if (item.idType?.includes("crossword_word_bank")) {
                        const wordbankElement = document.querySelector(
                            "#container_crossword_word_bank"
                        );
                        if (wordbankElement) {
                            item.height = status
                                ? wordbankElement.clientHeight
                                : 0;
                        }
                    }
                });
            });
        },
        updateNumberRenderResource: (state, action: PayloadAction<number>) => {
            if (action.payload) {
                state.numberRenderResource = action.payload;
            } else {
                state.numberRenderResource += 1;
            }
        },
        deletePageWorksheet: (state) => {
            undoHistoryStore.update({
                pagesWorksheet: state.pagesWorksheet,
                currentActivity: state.currentActivity,
            });
            let index = state.pageIndex;
            //remove template id
            state.pagesWorksheet[index].sourcePage =
                Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH;
            state.pagesWorksheet[index].isOriginPage = false;
            state.worksheetCreator.templateIds.splice(index, 1);
            state.worksheetCreator.activities.splice(index, 1);
            if (state.pagesWorksheet.length === 1) {
                // reset width, height
                ConstantsTool.changeOrientation(
                    ConstantsTool.ORIENTATION.PORTRAIT
                );
                state.pagesWorksheet[0].resourceItems = newPageResource(0);
                state.pagesWorksheet[0].width = ConstantsTool.WIDTH_A4;
                state.pagesWorksheet[0].height = ConstantsTool.HEIGHT_A4;
            } else {
                state.pagesWorksheet.splice(index, 1);
            }
            updateIndexInPageWorksheet(state.pagesWorksheet);
            if (index === 0) {
                state.pageIndex = 0;
            } else {
                state.pageIndex = index - 1;
            }
        },
        duplicatePageWorksheet: (state) => {
            let index = state.pageIndex;
            undoHistoryStore.update({
                pagesWorksheet: state.pagesWorksheet,
                currentActivity: state.currentActivity,
            });
            let pageWorksheetCopy = JSON.parse(
                JSON.stringify(state.pagesWorksheet[index])
            );
            let resourceItemsGroup = pageWorksheetCopy.resourceItems.filter(
                (resource) => resource.isGroup
            );

            pageWorksheetCopy.resourceItems.forEach((resourceItem) => {
                if (
                    resourceItem.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    resourceItem.id += Math.random().toString(6);
                    let ids = [];
                    resourceItem.resourceIds.forEach((id) => {
                        let element = resourceItemsGroup.find(
                            (e) => e.id === id
                        );
                        if (element) {
                            element.id += Math.random().toString(6);
                            ids.push(element.id);
                        }
                    });
                    resourceItem.resourceIds = ids;
                }
                if (!resourceItem.isGroup) {
                    resourceItem.id += Math.random().toString(6);
                }
            });
            pageWorksheetCopy.isEdited = false;
            pageWorksheetCopy.id = generateIdFromDateTime();
            state.pagesWorksheet.splice(index + 1, 0, pageWorksheetCopy);
            updateIndexInPageWorksheet(state.pagesWorksheet);
            state.pageIndex = index + 1;
            // add templateId, activity
            let templateIdsLen = state.worksheetCreator.templateIds.length;
            let activityLen = state.worksheetCreator.activities.length;
            state.worksheetCreator.templateIds.splice(
                index + 1,
                0,
                state.worksheetCreator.templateIds[templateIdsLen - 1]
            );
            state.worksheetCreator.activities.splice(
                index + 1,
                0,
                state.worksheetCreator.activities[activityLen - 1]
            );
        },
        movePageWorksheet: (state, action: PayloadAction<"up" | "down">) => {
            undoHistoryStore.update({
                pagesWorksheet: state.pagesWorksheet,
                currentActivity: state.currentActivity,
            });
            let currentIndex = state.pageIndex,
                newIndex = currentIndex + 1;
            if (action.payload === "up") {
                newIndex = currentIndex - 1;
            }
            const movedItem = state.pagesWorksheet.splice(currentIndex, 1)[0];
            state.pagesWorksheet.splice(newIndex, 0, movedItem);
            updateIndexInPageWorksheet(state.pagesWorksheet);
            state.pageIndex = newIndex;
        },
        updateIsEditedPage: (
            state,
            action: PayloadAction<{ isEidited: boolean; pageIndex?: number }>
        ) => {
            let index = action.payload.pageIndex ?? state.pageIndex;
            if (index < state.pagesWorksheet.length) {
                state.pagesWorksheet[index].isEdited = action.payload.isEidited;
            }
        },
        updateResourceItems: (
            state,
            action: PayloadAction<{
                pageIndex: number;
                attributesResourceItems?: AttributesResourceItem[];
                resourceItems?: IResourceItemNew[];
                isChangeEidtPage?: boolean;
                isAddHistory?: boolean;
            }>
        ) => {
            let {
                pageIndex,
                isAddHistory = true,
                isChangeEidtPage = true,
            } = action.payload;
            if (action.payload.attributesResourceItems) {
                if (isAddHistory)
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                action.payload.attributesResourceItems.forEach((element) => {
                    let currentElementId = element.resourceItemId;

                    const elementUpdate = state.pagesWorksheet[
                        pageIndex
                    ].resourceItems.find((el) => el.id === currentElementId);

                    if (elementUpdate) {
                        for (let i = 0; i < element.attrValues.length; i++) {
                            let attrValue = element.attrValues[i];
                            elementUpdate[attrValue.attribute] =
                                attrValue.value;
                        }
                    }
                });
            } else if (action.payload.resourceItems) {
                if (isAddHistory)
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                action.payload.resourceItems.map((resourceItem) => {
                    const elementUpdate = state.pagesWorksheet[
                        pageIndex
                    ].resourceItems.find((el) => el.id === resourceItem.id);
                    if (elementUpdate) {
                        let attrValues = Object.keys(resourceItem).map(
                            (key) => {
                                if (key !== "id") {
                                    return {
                                        attribute: key,
                                        value: resourceItem[key],
                                    };
                                }
                            }
                        );
                        for (let i = 0; i < attrValues.length; i++) {
                            let attrValue = attrValues[i];
                            if (attrValue) {
                                elementUpdate[attrValue.attribute] =
                                    attrValue.value;
                            }
                        }
                    }
                });
            }
            if (
                state.pagesWorksheet[state.pageIndex].isEdited == false &&
                isChangeEidtPage
            ) {
                state.pagesWorksheet[state.pageIndex].isEdited = true;
            }
            state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
            state.designChanged = true;
        },
        deleteResourceItemTmp: (state) => {
            let items = [];
            state.pagesWorksheet.forEach((page, i) =>
                page.resourceItems.forEach((el, j) => {
                    if (el.id?.includes("tmp-")) {
                        items.push({ page: i, index: j });
                    }
                })
            );
            items.forEach((item) => {
                state.pagesWorksheet[item.page].resourceItems.splice(
                    item.index,
                    1
                );
            });
        },
        updateCreateWS: (
            state,
            action: PayloadAction<{
                pagesWorksheet: IPageWorksheetNew[];
                currentActivity: IActivity;
            }>
        ) => {
            if (state.pageIndex >= action.payload.pagesWorksheet.length)
                state.pageIndex = action.payload.pagesWorksheet.length - 1;
            state.pagesWorksheet = action.payload.pagesWorksheet;
            state.currentActivity = action.payload.currentActivity;
        },
        removeResourceItemsByActivityId: (
            state,
            action: PayloadAction<{
                id: string;
            }>
        ) => {
            state.pagesWorksheet = state.pagesWorksheet.map((page) => {
                const newResourceItems = page.resourceItems.filter(
                    (resource) => resource.activityId !== action.payload.id
                );
                page.resourceItems = newResourceItems;
                return page;
            });
        },
        updateInitStaticMathField: (
            state,
            action: PayloadAction<{ latex: string }>
        ) => {
            state.initStaticMathField = action.payload.latex;
        },
        updatecurrentElementId: (
            state,
            action: PayloadAction<{ id: string }>
        ) => {
            state.currentElementId = action.payload.id;
        },
        updateStaticMathFieldFontSize: (
            state,
            action: PayloadAction<{ staticMathFieldFontSize: number }>
        ) => {
            state.staticMathFieldFontSize =
                action.payload.staticMathFieldFontSize;
        },
        updateDefaultBackgroundColor: (
            state,
            action: PayloadAction<{
                defaultBackgroundColor: string;
                isAddHistory?: boolean;
            }>
        ) => {
            let { defaultBackgroundColor, isAddHistory = true } =
                action.payload;
            if (isAddHistory)
                undoHistoryStore.update({
                    pagesWorksheet: state.pagesWorksheet,
                    currentActivity: state.currentActivity,
                });

            state.pagesWorksheet[state.pageIndex].resourceItems =
                state.pagesWorksheet[state.pageIndex].resourceItems.filter(
                    (e) =>
                        e.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                );
            let existBg = false;
            state.pagesWorksheet[state.pageIndex].resourceItems.forEach((e) => {
                if (
                    e.type === ConstantsTool.RESOURCE_BACKGROUND &&
                    e.backgroundColor != defaultBackgroundColor
                ) {
                    e.backgroundColor = defaultBackgroundColor;
                    existBg = true;
                }
            });
            if (!existBg) {
                state.pagesWorksheet[state.pageIndex].resourceItems.unshift(
                    new ResourceItemNew({
                        id: "bg_1",
                        idType: "bg_1",
                        width: ConstantsTool.WIDTH_A4,
                        height: ConstantsTool.HEIGHT_A4,
                        type: ConstantsTool.RESOURCE_BACKGROUND,
                        x: 0,
                        y: 0,
                        backgroundColor: defaultBackgroundColor,
                    })
                );
            }

            if (defaultBackgroundColor !== "#FFFFFF") {
                state.pagesWorksheet[state.pageIndex].resourceItems =
                    state.pagesWorksheet[state.pageIndex].resourceItems.filter(
                        (e) => e.type !== ConstantsTool.TYPE_RESOURCE_BORDER
                    );
            } else {
                state.pagesWorksheet[state.pageIndex].resourceItems.push(
                    new ResourceItemNew({
                        id: "3",
                        width: ConstantsTool.BORDER_WIDTH,
                        height: ConstantsTool.BORDER_HEIGHT,
                        type: ConstantsTool.TYPE_RESOURCE_BORDER,
                        x: ConstantsTool.BORDER_X,
                        y: ConstantsTool.BORDER_Y,
                    })
                );
            }
        },
        changeStatusIsOriginPage: (state) => {
            state.pagesWorksheet = state.pagesWorksheet.map((page) => {
                page["isOriginPage"] = true;
                return page;
            });
            state.isFirstSetOriginPage = false;
        },
        updateSelectedText: (state, action: PayloadAction<SelectedText>) => {
            if (action.payload) {
                state.selectedText = action.payload;
            } else {
                state.selectedText = { startOffset: -1, endOffset: -1 };
            }
        },
        setFontSizeResourceItemsGroup: (state, action) => {
            const { pageIndex, items } = action.payload;

            let initialGroups = state.fontSizeResourceItemsGroup[pageIndex] ? state.fontSizeResourceItemsGroup[pageIndex] : {};

            state.fontSizeResourceItemsGroup[pageIndex] = items.reduce((groups : object, item : IResourceItemNew) => {
                const acceptedTypes = [ConstantsTool.RESOURCE_TEXT_MODULE, ConstantsTool.RESOURCE_TEXT, ConstantsTool.TYPE_RESOURCE_ZONE];
                if (item['zoneGroup'] !== null && acceptedTypes.includes(item['type'])) {
                    if (!groups[item.zoneGroup]) {
                        groups[item.zoneGroup] = item['textAttribute']['fontSize'];
                    };
                    if (item['textAttribute']['fontSize'] < groups[item.zoneGroup]['fontSize']) {
                        groups[item.zoneGroup] = item['textAttribute']['fontSize'];
                    };
                };
                return groups;
            }, initialGroups);
        },
        updateFontSizeResourceItemsGroup: (state, action) => {
            const { pageIndex, zoneGroup, fontSizeValue } = action.payload;
            state.fontSizeResourceItemsGroup[pageIndex][zoneGroup] = fontSizeValue;
        },
        updateResourceItemsAuto: (state, action) => {
            const { uniqueFields, pageIndex } = action.payload;
            let zonesTemp = [];
            uniqueFields.map(item => {
                const parentFieldItem = uniqueFields.find(itemU => itemU.fieldName === item.parentField);
                for (let index = 1; index <= item.nbFields; index ++) {
                    zonesTemp.push({
                        fieldName: item.fieldName,
                        fieldIndex: parentFieldItem ? Math.ceil(index/parentFieldItem.nbFields): index,
                        fieldDescription: item.fieldDescription,
                        parentField: item.parentField,
                        ...(parentFieldItem ? {parentFieldIndex: index%parentFieldItem.nbFields === 0 ? parentFieldItem.nbFields : index%parentFieldItem.nbFields} : {})
                    })
                }
            })
            const newResourceItems = []
            state.pagesWorksheet[pageIndex].resourceItems.forEach(item => {
                if (item.fieldName) {
                    const findZone = zonesTemp.find(itemZ =>
                        itemZ.fieldName === item.fieldName
                        && itemZ.fieldIndex && item.fieldIndex ? itemZ.fieldIndex === item.fieldIndex : true
                        && (itemZ.parentField && item.parentField
                            ? itemZ.parentField === item.parentField
                            && itemZ.parentFieldIndex === item.parentFieldIndex : true) )
                    if (findZone) {
                        newResourceItems.push(item)
                    }
                } else {
                    newResourceItems.push(item)
                }
            })

            let indexShape = 0;
            zonesTemp.forEach((item, index) => {
                const zoneExist = state.pagesWorksheet[pageIndex].resourceItems.find(itemR =>
                    itemR.fieldName == item.fieldName
                    && (itemR.fieldIndex && item.fieldIndex ? itemR.fieldIndex === item.fieldIndex : true)
                    && (itemR.parentField && item.parentField ? itemR.parentField === item.parentField && itemR.parentFieldIndex === item.parentFieldIndex : true))
                    if (!zoneExist) {
                        let textAttribute = new TextAttribute({
                            fontSize: 16,
                            fontFamily: "Nunito",
                            align: "center",
                        });
                        const dataShape = getDataShape();

                        const newR = new ResourceItemNew({
                            ...item,
                            id: generateIdFromDateTime(),
                            x: 0,
                            y: 64*indexShape,
                            width: 64,
                            height: 64,
                            pageIndex: pageIndex,
                            type: ConstantsTool.TYPE_RESOURCE_ZONE,
                            textAttribute: textAttribute,
                            shapeAttribute: dataShape[0]
                        })
                        newResourceItems.push(newR)
                        indexShape += 1;
                    }
            })

            state.pagesWorksheet[pageIndex].resourceItems = newResourceItems;
        },
        setTemplateChanged: (state, action) => {
            state.templateChanged = action.payload;
            state.lastChangedTime = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        saveTemplate: (state) => {
            const now = moment().format('YYYY-MM-DD HH:mm:ss');
            state.lastSavedTime = now;
            state.templateChanged = false;
        },
        setCurrentStore: (state, action) => {
            state.currentStore = action.payload;
        },
        setActivityPermissions: (state, action) => {
            state.activityPermissions = action.payload;
        },
        setDisabledSaveButton: (state, action: PayloadAction<boolean>) => {
            state.disabledSaveButton = action.payload;
        },
        setAllFontsLoaded: (state, action: PayloadAction<boolean>) => {
            state.allFontsLoaded = action.payload;
        },
        setAllImagesLoaded: (state, action: PayloadAction<boolean>) => {
            state.allImagesLoaded = action.payload;
        },
        setWordsCountDone: (state, action: PayloadAction<boolean>) => {
            state.wordsCountDone = action.payload;
        },
        setCurrentElementFocus: (state, action: PayloadAction<boolean>) => {
            state.currentElementFocus = action.payload;
        },
        setSeasonIconGroups: (state, action: PayloadAction<{iconName: string, iconUrl: string}>) => {
            state.seasonIconGroups[action.payload.iconName] = action.payload.iconUrl;
        },
        setDisableAjustZonePositionWithKeyboard: (state, action: PayloadAction<boolean>) => {
            state.disableAjustZonePositionWithKeyboard = action.payload;
        },
        setAutoResizeDone: (state, action: PayloadAction<boolean>) => {
            state.autoResizeDone = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(
            requestInitPageWs.fulfilled,
            (
                state,
                action: PayloadAction<{
                    ratio: number;
                    pageWs: IPageWorksheetNew[];
                    activities: Activity[];
                    worksheetCreator: WorkSheetCreator;
                    forceUpdate: boolean;
                    isRelaceAllPage: boolean;
                    isAddNewPage: boolean;
                }>
            ) => {
                let pageIndex = Math.max(0, state.pageIndex);
                if (state.pagesWorksheet.length) {
                    undoHistoryStore.update({
                        pagesWorksheet: state.pagesWorksheet,
                        currentActivity: state.currentActivity,
                    });
                }
                if (
                    state.currentActivity?.type !==
                        Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE ||
                    action.payload.activities[0].type !==
                        Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE
                ) {
                    undoHistoryStore.reset();
                }
                state.ratio = action.payload.ratio;
                if (action.payload.isRelaceAllPage) {
                    state.pagesWorksheet = action.payload.pageWs;
                    pageIndex = 0;
                } else {
                    if (action.payload.isAddNewPage) {
                        pageIndex += 1;
                    } else {
                        state.pagesWorksheet.splice(pageIndex, 1);
                    }
                    state.pagesWorksheet.splice(
                        pageIndex,
                        0,
                        ...action.payload.pageWs
                    );
                    updateIndexInPageWorksheet(state.pagesWorksheet);
                }

                state.loading = true;
                state.pageIndex = pageIndex;
                state.activities = action.payload.activities;
                state.forceUpdate = action.payload.forceUpdate;
                state.currentActivity = action.payload.activities[0];
                state.worksheetCreator = action.payload.worksheetCreator;
                state.numberRenderResource = 0;
            }
        );
        builder.addCase(requestInitPageWs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(requestInitPageWs.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(
            updateFillTheBlankSentences.fulfilled,
            (state, action) => {}
        ),
            [requestSavePageWs.fulfilled].map((actionFullFilled) => {
                return builder.addCase(
                    actionFullFilled,
                    (
                        state,
                        action: PayloadAction<{
                            wsCreator: IWorkSheetCreator;
                            resourceItems: IResourceItemNew[];
                            worksheet: IWorksheet;
                        }>
                    ) => {
                        let wsCreator = action.payload.wsCreator;
                        let resourceItems = action.payload.resourceItems;
                        let activities = wsCreator.activities.map(
                            (el) =>
                                new Activity({
                                    ...el,
                                })
                        );
                        getPageWsResources(resourceItems, state.pagesWorksheet);
                        state.worksheetCreator = wsCreator;
                        state.activities = activities;
                        state.currentActivity = activities[0];
                    }
                );
            });
    },
});
export const {
    setTitle,
    setTemplate,
    setContent,
    setIdeaTemplate,
    setIdeaAnswerSheetPage,
    setIdeaAnswerKeyPage,
    setIdeaThumbnail,
    setDataSetDesignRow,
    setIsIframe,
    setTemplateZoneDisplayMode,
    saveWorksheet,
    setPageType,
    setAutoSave,
    setFontFamilyList,
    setMissingFontFamilyList,
    addMissingFontFamilyList,
    updateCurrentToResourceItem,
    updateResourceItem,
    resetTempTemplatesZones,
    replaceZoneByLinkedText,
    replaceLinkedTextByZone,
    updateTempTemplateZone,
    updateIsOnDrag,
    updateIsOnEditText,
    selectElements,
    addResourceItems,
    removeResourceItems,
    updateSizeModule,
    changePageSize,
    updateWordList,
    removeWordList,
    updateStudentInfo,
    updateDirections,
    setShowWordList,
    updateCurrentActivityToListActivity,
    removeResourceItemsByActivityId,

    updateRatio,
    updateGenerator,

    // updateFillTheBlankSentencesV2,
    updateResourceWordBank,
    updateManyResourceByType,

    updateQuestion,
    addQuestion,
    deleteQuestion,

    updateLineSpacing,
    updateNumberOfLine,

    setCurrentActivity,
    setLoading,
    updateWorkSheetCreator,
    updateActivities,
    updatePageIndex,
    backwardResourceAction,
    forwardResourceAction,

    updateOrientation,
    // initWorksheetInfo,
    changePageIndex,
    addPageWorksheet,
    removeUnusedPages,
    updateOpenWarningReplaceTemplate,
    updateSelectedWorksheetId,
    upDateShowAnswerKey,
    upDateHideTextShape,
    upDateResize,
    upDateOnEditBorder,
    updateCrosswordItems,
    updateLayouItemsCrossword,
    updateNumberRenderResource,
    updateWordbankCrosswordItem,
    deletePageWorksheet,
    duplicatePageWorksheet,
    movePageWorksheet,
    updateIsEditedPage,
    updateResourceItems,
    deleteResourceItemTmp,
    updateCreateWS,
    updatePageWSS,
    updateInitStaticMathField,
    updatecurrentElementId,
    updateStaticMathFieldFontSize,
    updateDefaultBackgroundColor,
    changeStatusIsOriginPage,
    updateSelectedText,
    setFontSizeResourceItemsGroup,
    updateFontSizeResourceItemsGroup,
    updateResourceItemsAuto,
    setTemplateChanged,
    saveTemplate,
    setCurrentStore,
    setActivityPermissions,
    setDisabledSaveButton,
    setAllFontsLoaded,
    setAllImagesLoaded,
    setWordsCountDone,
    setCurrentElementFocus,
    setSeasonIconGroups,
    setDisableAjustZonePositionWithKeyboard,
    setAutoResizeDone,
} = createWorksheetSlice.actions;
