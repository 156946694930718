import { immerable } from "immer";
import { IResourceItemNew, ResourceItemNew } from "./resourceItemNew";
import { IWorksheet } from "./worksheet";

// import { ITemplate, Template } from "./template";
export const defaultSpacingTemplate = {
    "paddingTop": 0,
    "paddingRight": 0,
    "paddingBottom": 0,
    "paddingLeft": 0,
}
export interface ISpacingTemplate {
    paddingTop: number;
    paddingRight: number;
    paddingLeft: number;
    paddingBottom: number;
}

export interface IPageWorksheetNew {
    id: string;
    resourceItemIds: string[];

    width: number;
    height: number;
    resourceItems: IResourceItemNew[];
    resourceItemId: string;
    worksheet?: string | IWorksheet;
    resourceItemSelected: IResourceItemNew[];
    isEdited: boolean;
    // loadingHintButton: boolean;
    sourcePage: number;
    isOriginPage: boolean;
    spacing: ISpacingTemplate;
}
export class PageWorksheetNew implements IPageWorksheetNew {
    id: string;
    resourceItemIds: string[];
    width: number;
    height: number;
    resourceItems: IResourceItemNew[];
    resourceItemId: string;
    worksheet?: string;
    resourceItemSelected: IResourceItemNew[];
    isEdited: boolean;
    // loadingHintButton: boolean;
    sourcePage: number;
    isOriginPage: boolean;
    spacing: ISpacingTemplate;

    constructor(object: any = {}) {
        this[immerable] = true;
        // this.image = object.image ?? "";
        this.width = object.width ?? (210 * 96) / 25.4;
        this.height = object.height ?? (297 * 96) / 25.4;
        this.resourceItems = object.resourceItems
            ? object.resourceItems.map(
                  (a: IResourceItemNew) => new ResourceItemNew(a)
              )
            : [];
        this.resourceItemId = object.resourceItemId ?? null;
        this.resourceItemIds = object.resourceItemIds ?? [];
        this.id = object.id ?? "";
        this.isEdited = object.isEdited ?? false;
        // this.resourceItemSelected = object.resourceItemSelected ?? [];
        // this.loadingHintButton = object.loadingHintButton ?? false;
        this.sourcePage = object.sourcePage;
        this.isOriginPage = object.isOriginPage ?? false;
        this.spacing = object.spacing ?? defaultSpacingTemplate;
    }
}
