import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import ConstantsResource from "../../../utils/ConstantsResource";

const ThemeLayout = ({
    imgRef,
    style,
    isMainPrint,
    nonPosition,
}: {
    imgRef: any;
    style?: any;
    isMainPrint?: boolean;
    nonPosition?: boolean;
}) => {
    const themeRef: any = useRef();
    const themes = useSelector(
        (state: ResourceAppState) => state.gameResourceState?.themes
    );
    const listTheme = themes?.listTheme;
    const indexSelected = themes?.indexSelected;
    const src = listTheme[indexSelected];

    let urlSrc = ConstantsResource.HOST_THEME + src;
    if (isMainPrint) {
        urlSrc = ConstantsResource.HOST_THEME + "print/" + src;
    }
    const copyStylesFromBToA = () => {
        if (imgRef.current && themeRef.current) {
            themeRef.current.width = imgRef.current.width;
            themeRef.current.height = imgRef.current.height;
            // if (nonPosition) {
            //     themeRef.current.style.marginTop =
            //         -imgRef.current.height + "px";
            //     themeRef.current.style.position = "relative";
            //     themeRef.current.style.opacity = "1";
            // }
        }
    };
    useEffect(() => {
        if (
            themeRef?.current?.src &&
            imgRef?.current?.width &&
            imgRef?.current?.height &&
            imgRef?.current?.src
        ) {
            copyStylesFromBToA();
        }
    }, [
        indexSelected,
        imgRef?.current?.width,
        imgRef?.current?.height,
        themeRef?.current?.src,
        imgRef?.current?.src,
    ]);

    return (
        <img
            src={urlSrc}
            ref={themeRef}
            alt="theme"
            width={"100%"}
            height={"100%"}
            style={
                style ?? {
                    zIndex: 10,
                    position: "absolute",
                }
            }
        />
    );
};

export default ThemeLayout;
