import "./index.scss";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../../../shared/models/questionActivity";
import { LandscapeIcon, PortraitIcon } from "../../../../assets/icon";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { useAppDispatch } from "../../../../redux/hook";
import {
    updateLineSpacing,
    updateNumberOfLine,
    updateOrientation,
} from "../../../../redux/reducers/createWorksheet";
import { ConstantsNameTracing } from "../util/constant";
import Config from "../../../../shared/utils/config";

const LIST_ORIENTATION = [
    {
        id: ConstantsTool.ORIENTATION.PORTRAIT,
        icon: <PortraitIcon />,
        title: "Portrait",
    },
    {
        id: ConstantsTool.ORIENTATION.LANDSCAPE,
        icon: <LandscapeIcon />,
        title: "Landscape",
    },
];

const Orientaion = ({
    question,
    orientation,
}: {
    question: IQuestionActivity;
    orientation: number;
}) => {
    const dispatch = useAppDispatch();
    const handleChangeOrientation = async (id: number) => {
        if (orientation === id) {
            return;
        }
        let isPerfectConfig = false;
        let { numberOfLines, lineSpacing } = question.nameTracingAttribute;
        const PORTRAIT_CONFIG = ConstantsNameTracing.PORTRAIT_CONFIG;
        const LANDSCAPE_CONFIG = ConstantsNameTracing.LANDSCAPE_CONFIG;
        if (ConstantsTool.ORIENTATION.PORTRAIT === orientation) {
            if (
                numberOfLines === PORTRAIT_CONFIG.NUMBER_OF_LINES &&
                lineSpacing === PORTRAIT_CONFIG.LINE_SPACING
            ) {
                const newQuestion = new QuestionActivity({
                    ...question,
                    type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
                });
                newQuestion.nameTracingAttribute.numberOfLines =
                    LANDSCAPE_CONFIG.NUMBER_OF_LINES;
                newQuestion.nameTracingAttribute.lineSpacing =
                    LANDSCAPE_CONFIG.LINE_SPACING;
                dispatch(
                    updateLineSpacing({
                        question: newQuestion,
                    })
                );
                dispatch(
                    updateNumberOfLine({
                        question: newQuestion,
                    })
                );
                isPerfectConfig = true;
            }
        } else if (ConstantsTool.ORIENTATION.LANDSCAPE === orientation) {
            if (
                numberOfLines === LANDSCAPE_CONFIG.NUMBER_OF_LINES &&
                lineSpacing === LANDSCAPE_CONFIG.LINE_SPACING
            ) {
                const newQuestion = new QuestionActivity({
                    ...question,
                    type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
                });
                newQuestion.nameTracingAttribute.numberOfLines =
                    PORTRAIT_CONFIG.NUMBER_OF_LINES;
                newQuestion.nameTracingAttribute.lineSpacing =
                    PORTRAIT_CONFIG.LINE_SPACING;
                dispatch(
                    updateLineSpacing({
                        question: newQuestion,
                    })
                );
                dispatch(
                    updateNumberOfLine({
                        question: newQuestion,
                    })
                );
                isPerfectConfig = true;
            }
        }

        dispatch(
            updateOrientation({
                orientation: id,
                isPerfectConfig,
            })
        );
    };

    return (
        <div className={`orientation-section`}>
            <div className="title">Orientation</div>
            <div className="img-group">
                {LIST_ORIENTATION.map(({ icon, id, title }) => (
                    <div
                        className={`group ${
                            id === orientation ? "active" : ""
                        }`}
                        key={id}
                        onClick={() => {
                            handleChangeOrientation(id);
                        }}
                    >
                        <div className={`img-btn`}>{icon}</div>

                        <div className="title-item">{title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Orientaion;
