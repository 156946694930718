import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
    QuotaResourceTypes,
    QuotaResourceUserAction,
    updateLoadingQuotaAction,
    updateQuotaDownloadReducerAction,
    updateQuotaResourceAction,
    updateQuotaUserSuccessAction,
} from "../action/quotaResourceUser.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import {
    getQuotaResourceByUserIdApi,
    increaseQuotaResourceByUserIdApi,
    updateQuotaDownloadApi,
    updateQuotaUserApi,
    updateTimeNextDownloadByUserIdApi,
    updateUserSharePinApi,
} from "../repositories/quotaResourceUser";

function* updateQuotaDownloadSaga(action: QuotaResourceUserAction) {
    const userId = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data.id
    );

    const timesDownload = yield select(
        (state: ResourceAppState) => state.quotaResourceUserState.data.times
    );

    let res = yield call(updateQuotaDownloadApi, {
        userId,
        numberDownload: action.numberDownload,
        times: timesDownload,
    });
    let remainQuotaDownLoad = res.remainQuotaDownLoad;

    if (remainQuotaDownLoad > -1) {
        yield put(updateQuotaDownloadReducerAction(remainQuotaDownLoad));
    }
}

function* getQuotaResourceSaga(action: QuotaResourceUserAction) {
    const userId = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data.id
    );

    yield put(updateLoadingQuotaAction({ loading: true }));
    let res = yield call(getQuotaResourceByUserIdApi, { userId });
    yield put(updateQuotaResourceAction(res, userId));
    yield put(updateLoadingQuotaAction({ loading: false }));
}

function* increaseQuotaDownloadSaga(action: QuotaResourceUserAction) {
    const userId = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    const times = yield select(
        (state: ResourceAppState) => state?.quotaResourceUserState?.data?.times
    );
    const timeNextDownload = yield select(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.timeNextDownload
    );
    const timeClaimed = yield select(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.timeClaimed
    );

    let res = yield call(increaseQuotaResourceByUserIdApi, {
        userId,
        times,
        timeNextDownload,
        timeClaimed,
    });
    let remainQuotaDownLoad = res.remainQuotaDownLoad;

    if (remainQuotaDownLoad > -1) {
        yield put(updateQuotaDownloadReducerAction(remainQuotaDownLoad));
    }
}

function* updateTimeNextDownloadSaga(action: QuotaResourceUserAction) {
    yield call(updateTimeNextDownloadByUserIdApi, {
        userId: action.userId,
        timeNextDownload: action.timeNextDownload,
        times: action.times,
    });
}
function* updateQuotaDownloadUserSaga(action: QuotaResourceUserAction) {
    let { params } = action;
    if (params?.userId) {
        let result = yield call(updateQuotaUserApi, { params });
        if (result) {
            yield put(updateQuotaUserSuccessAction({ params: result }));
        }
    }
}
function* updateUserSharePinSaga(action: QuotaResourceUserAction) {
    let { links } = action;
    const userId = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data.id
    );
    if (links?.length) {
        let result = yield call(updateUserSharePinApi, {
            links: links,
            userId,
        });
        console.log("result", result);
    }
}

function* listenQuotaResourceUser() {
    yield takeLatest(
        QuotaResourceTypes.UPDATE_QUOTA_DOWNLOAD,
        updateQuotaDownloadSaga
    );
    yield takeLatest(
        QuotaResourceTypes.GET_QUOTA_RESOURCE,
        getQuotaResourceSaga
    );
    yield takeLatest(
        QuotaResourceTypes.INCREASE_QUOTA_RESOURCE,
        increaseQuotaDownloadSaga
    );
    yield takeLatest(
        QuotaResourceTypes.UPDATE_TIME_NEXT_DOWNLOAD,
        updateTimeNextDownloadSaga
    );
    yield takeLatest(
        QuotaResourceTypes.UPDATE_QUOTA_USER,
        updateQuotaDownloadUserSaga
    );
    yield takeLatest(
        QuotaResourceTypes.UPDATE_USER_SHARE_PIN,
        updateUserSharePinSaga
    );
}

export const quotaResourceUserSaga = [fork(listenQuotaResourceUser)];
