import Button from 'react-bootstrap/Button';
import { ITemplate, setTemplate } from '../../../../redux/reducers/createWorksheet';
import { updateDataAPI } from '../../../../utils/query';
import { useDispatch } from 'react-redux';

const PublishTemplateButton = ({template}: {template: ITemplate}) => {
    const dispatch = useDispatch();

    const handleClick = async() => {
        await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
            active: !template.active
        });
        dispatch(setTemplate({...template, active: !template.active}));
    }

    return (
        <Button variant={template.active ? 'danger' : 'success'} onClick={handleClick} size="sm">
            {template.active ? 'Unpublish' : 'Publish'}
        </Button>
    )
}

export default PublishTemplateButton;