import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import { genDisplay } from "../../../utils";

const WordBank = ({
    resourceItem,
    ratio,
}: {
    resourceItem: IResourceItemNew;
    ratio?: number;
}) => {
    const color = resourceItem.textAttribute.fontColor;
    const words = useSelector((state: ResourceAppState) =>
        state.crosswordState.words.filter((word) => word.onGrid)
    );
    const showWordBank = useSelector(
        (state: ResourceAppState) => state.crosswordState.showWordBank
    );

    return (
        <div
            className="crossword_word_bank"
            id={"container_" + resourceItem.idType}
            style={{
                display: genDisplay(showWordBank && words.length > 0),
                color,
                fontSize: `${16 * ratio}px`,
            }}
        >
            <span className="title">WORD BANK:</span>{" "}
            {words.map((word, index) => {
                const isLastWord = words.length - 1 === index;
                const isCorrect =
                    word.status === ConstantsTool.CROSSWORD_STATUS.CORRECT;
                return (
                    <span
                        key={word.index}
                        className={
                            "word " +
                            (isCorrect ? ` ${ConstantsTool.LINE_THROUGH} ` : "")
                        }
                        style={{
                            fontSize: `${14 * ratio}px`,
                        }}
                    >
                        {" "}
                        {word.value}
                        {isLastWord ? "" : ","}
                    </span>
                );
            })}
        </div>
    );
};

export default WordBank;
