import { immerable } from "immer";
import ConstantsTool from "../utils/ConstantsTool";
import {
    IAnswerActivity,
    IQuestionActivity,
    QuestionActivity,
} from "./questionActivity";
import { IWorksheet } from "./worksheet";
import { IActivity } from "./WorkSheetCreator";
import { IShapeAttribute, ShapeAttribute } from "./shapeAttribute";
import { ITableAttribute, TableAttribute } from "./tableAttribute";
import { ISubText, SubText, ITextStyle } from "./textStyle";
// import { ICommand } from "abc-react-mathquill";

export type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type FlexWrapType = 'nowrap' | 'wrap' | 'wrap-reverse';

export type AlignItemsType = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';

export type JustifyContentType = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

export class Command {
    id: string;
    content: string;
    constructor(obj: any = {}) {
        this[immerable] = true;
        this.id = obj.id ?? 0;
        this.content = obj.content ?? "";
    }
}
export type WordAnswer = {
    word: string;
    status: number;
};

export type ValidatorZone = {
    id: number;
    validator: number;
    validatorName: string;
    zone: number;
    metaData: any;
};

export interface ICurrentInfo {
    resourceIds: string[];
    resourceId: string;
    resourceIdTypes?: string;
    isGroup: boolean;
    x: number;
    y: number;
    width: number;
    height: number;
    rotate: number;
    pageIndex: number;
    type: number;
    textAttribute?: ITextAttribute;
    imageAttribute?: IImageAttribute;
    idType?: string;
    shapeAttribute?: IShapeAttribute;
    opacity?: number;
}
export interface ITextAttribute extends ITextStyle {
    content: string;
    offset: number;
    direction: number;
    blur: number;
    transparency: number;
    intensity: number;
    thickness: number;
    roundness: number;
    spread: number;
    color: string;
    align: any;
    verticalAlign: string;
    justifyContent?: JustifyContentType;
    alignItems?: AlignItemsType;
    flexDirection?: FlexDirectionType;
    flexWrap?: FlexWrapType;
    textEffectId?: number;
    autoFill?: boolean;
    lineHeight?: number;
    cmd?: any;
    subTexts: ISubText[];
    letterSpacing: number;
    fontColors: string[];
    textCase: string;
}
export interface IWordBank {
    fillBankId: string;
    answers: IAnswerActivity[];
}

export class WordBank implements IWordBank {
    fillBankId: string;
    answers: IAnswerActivity[];
    constructor(obj: any = {}) {
        this[immerable] = true;
        this.fillBankId = obj.fillBankId ?? "";
        this.answers = obj.answers ?? [];
    }
}
export interface IImageAttribute {
    url: string;
    svgContent: string;
    changeColor: {
        [key: string]: string;
    };
}
export class ImageAttribute implements IImageAttribute {
    url: string;
    svgContent: string;
    changeColor: {
        [key: string]: string;
    };
    constructor(obj: any) {
        this.url = obj.url ?? "";
        this.svgContent = obj.svgContent ?? "";
        this.changeColor = obj.changeColor ?? {};
    }
}
export class TextAttribute implements ITextAttribute {
    offset: number;
    direction: number;
    content: string;
    blur: number;
    transparency: number;
    intensity: number;
    thickness: number;
    roundness: number;
    spread: number;
    color: string;
    textEffectId?: number;
    fontFamily: string;
    align: any;
    verticalAlign: string;
    justifyContent?: JustifyContentType;
    alignItems?: AlignItemsType;
    flexDirection?: FlexDirectionType;
    flexWrap?: FlexWrapType;
    isBold: boolean;
    isAppliedAutoFontSize?: boolean;
    isAutoResizeDone?: boolean;
    isItalic: boolean;
    isStroke: boolean;
    strokeColor: string;
    strokeWidth: number;
    fontColor: string;
    fontSize: number;
    // content: string;
    autoFill: boolean;
    lineHeight?: number;
    underline: boolean;
    cmd?: any;
    subTexts: ISubText[];
    letterSpacing: number;
    fontColors: string[];
    textCase: string;

    constructor(obj: any = {}) {
        this.offset = obj.offset ?? 0;
        this.direction = obj.direction ?? 0;
        this.blur = obj.blur ?? 0;
        this.transparency = obj.transparency ?? 0;
        this.intensity = obj.intensity ?? 0;
        this.thickness = obj.thickness ?? 0;
        this.roundness = obj.roundness ?? 0;
        this.spread = obj.spread ?? 0;
        this.color = obj.color ?? "#212121";
        this.fontFamily = obj.fontFamily ?? null;
        this.fontSize = obj.fontSize ? parseInt(obj.fontSize) : 24;
        this.fontColor = obj.fontColor ?? "#212121";
        this.align = obj.align ?? "left";
        this.verticalAlign = obj.verticalAlign ?? 'middle';
        this.justifyContent = obj.justifyContent ?? 'space-between';
        this.alignItems = obj.alignItems ?? 'center';
        this.flexDirection = obj.flexDirection ?? 'column';
        this.flexWrap = obj.flexWrap ?? 'wrap';
        this.isBold = obj.isBold ?? false;
        this.isItalic = obj.isItalic ?? false;
        this.isStroke = obj.isStroke ?? false;
        this.strokeWidth = obj.strokeWidth ? parseInt(obj.strokeWidth) : 1;
        this.strokeColor = obj.strokeColor ?? "#212121";
        this.isAppliedAutoFontSize = obj.isAppliedAutoFontSize ?? true;
        this.isAutoResizeDone = obj.isAutoResizeDone ?? false;
        this.content = obj.content ?? "";
        this.textEffectId = obj.textEffectId ?? 0;
        this.autoFill = obj.autoFill ?? false;
        this.lineHeight = obj.lineHeight
            ? obj.lineHeight > 3
                ? obj.lineHeight / this.fontSize
                : obj.lineHeight
            : ConstantsTool.LINE_HEIGHT_DEFAULT;
        this.underline = obj.underline ?? false;
        this.cmd = obj.cmd ?? new Command({ id: 0, content: "" });
        this.subTexts = obj.subTexts
            ? obj.subTexts.map((e) => {
                  return new SubText(e);
              })
            : [];
        this.letterSpacing = obj.letterSpacing ?? 0;
        this.fontColors = obj.fontColors ?? [];
        this.textCase = obj.textCase ?? "";
    }

    get nbLinkedZonesInContainer() {
        return (this.content ?? '').split('~').length
    }
}
export interface IResourceItemNew {
    id: string;
    activityId: IActivity | string;
    idType: string;
    pageIndex: number;
    name?: string;
    x: number;
    y: number;
    type: number;
    width: number;
    height: number;
    rotate: number;
    textAttribute?: ITextAttribute;
    question?: IQuestionActivity;
    imageAttribute?: IImageAttribute;
    wordBanks?: string[];
    wordList?: WordAnswer[][];
    isShow: boolean;
    url?: string;
    urls?: string[];
    listStyle?: number;
    shapeAttribute?: IShapeAttribute;
    opacity: number;
    moduleId: string;
    layout?: string;
    isGroup: boolean;
    resourceIds: string[];
    tableAttribute?: ITableAttribute;
    backgroundColor?: string;
    customZoneID?: string;
    zoneGroup?: string;
    isHeadline?: boolean;
    isNameText?: boolean;
    fieldName?: string;
    linkedZoneFieldName?: string;
    linkedTemplate?: number;
    fieldIndex?: number;
    fieldDescription?: string;
    staticValue?: string;
    parentField?: string;
    parentFieldIndex?: number;
    originalID?: string;
    isMathFormula?: boolean;
    validators?: ValidatorZone[];
    store?: number[];
    shuffle?: boolean;
    isLocked?: boolean;
}

export class ResourceItemNew implements IResourceItemNew {
    id: string;
    idType: string;
    activityId: IActivity | string;
    pageIndex: number;
    name?: string;
    x: number;
    y: number;
    type: number;
    worksheet?: IWorksheet | string;
    width: number;
    height: number;
    rotate: number;
    question?: IQuestionActivity;
    textAttribute?: ITextAttribute;
    imageAttribute?: IImageAttribute;
    wordBanks?: string[];
    wordList?: WordAnswer[][];
    isShow: boolean;
    url?: string;
    urls?: string[];
    shapeAttribute?: IShapeAttribute;
    opacity: number;
    moduleId: string;
    layout?: string;
    isGroup: boolean;
    resourceIds: string[];
    tableAttribute?: ITableAttribute;
    backgroundColor?: string;
    customZoneID?: string;
    zoneGroup?: string;
    isHeadline?: boolean;
    isNameText?: boolean;
    fieldName?: string;
    linkedZoneFieldName?: string;
    linkedTemplate?: number;
    fieldIndex?: number;
    fieldDescription?: string;
    staticValue?: string;
    parentField?: string;
    parentFieldIndex?: number;
    originalID?: string;
    isMathFormula?: boolean;
    validators?: ValidatorZone[];
    store?: number[];
    shuffle?: boolean;
    isLocked?: boolean;

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.id = obj.id ?? undefined;
        this.activityId = obj.activityId ?? "";
        this.pageIndex = obj.pageIndex ?? 0;
        this.worksheet = obj.worksheet ?? "";
        // this.templateId = obj.templateId ?? null;
        this.idType = obj.idType ?? "";
        this.y = obj.y ?? 0;
        this.x = obj.x ?? 0;
        this.type = obj.type ?? ConstantsTool.RESOURCE_TEXT;
        this.width = obj.width ?? 0;
        this.height = obj.height ?? null;
        this.rotate = obj.rotate ?? 0;
        this.isShow = obj.isShow ?? true;
        this.url = obj.url;
        this.urls = obj.urls;

        if (
            obj.type === ConstantsTool.RESOURCE_TEXT ||
            obj.type === ConstantsTool.RESOURCE_TEXT_MODULE ||
            obj.type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
            obj.type === ConstantsTool.TYPE_WORD_LIST ||
            obj.type === ConstantsTool.TYPE_STUDENT_INFO ||
            obj.type === ConstantsTool.TYPE_RESOURCE_HANDWRITING ||
            obj.type === ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES ||
            obj.type === ConstantsTool.TYPE_RESOURCE_NAME_TRACING ||
            obj.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_MODULE ||
            obj.type === ConstantsTool.TYPE_WORD_DIRECTION ||
            obj.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK ||
            obj.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST ||
            obj.type === ConstantsTool.TYPE_RESOURCE_MATH ||
            obj.type === ConstantsTool.TYPE_WORD_BANK ||
            obj.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA ||
            obj.type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_ZONE
        ) {
            this.textAttribute = new TextAttribute({
                ...(obj.textAttribute ?? {}),
            });
        }
        if (
            obj.type === ConstantsTool.RESOURCE_IMAGE ||
            obj.type === ConstantsTool.TYPE_WORD_DIRECTION ||
            obj.type === ConstantsTool.TYPE_LOGO ||
            obj.type === ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES ||
            obj.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE ||
            obj.type === ConstantsTool.TYPE_WORD_BANK ||
            obj.type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
            obj.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
        ) {
            this.imageAttribute = new ImageAttribute({
                ...(obj.imageAttribute ?? {}),
            });
        }
        if (obj.question) {
            this.question = new QuestionActivity(obj.question);
        }
        if (obj.type === ConstantsTool.TYPE_WORD_BANK) {
            this.wordBanks = obj.wordBanks ?? [];
        }
        if (obj.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID) {
            this.layout = obj.layout ?? ConstantsTool.LAYOUT.DEFAULT;
        }
        this.wordList = obj.wordList ?? [];
        this.shapeAttribute =
            obj.shapeAttribute && new ShapeAttribute(obj.shapeAttribute);
        this.opacity = obj.opacity ?? 1;
        this.moduleId = obj.moduleId ?? "";
        this.isGroup = obj.isGroup ?? false;
        this.resourceIds = obj.resourceIds ?? [];
        if (obj.tableAttribute) {
            this.tableAttribute = new TableAttribute(obj.tableAttribute);
        }
        this.backgroundColor = obj.backgroundColor ?? "#FFFFFF";
        this.zoneGroup = obj.fieldName ?? null;
        this.name = obj.name ?? null;
        this.customZoneID = obj.customZoneID ?? null;
        this.isHeadline = obj.isHeadline ?? false;
        this.isNameText = obj.isNameText ?? false;
        this.fieldName = obj.fieldName ?? null;
        this.linkedZoneFieldName = obj.linkedZoneFieldName ?? null;
        this.linkedTemplate = obj.linkedTemplate ?? null;
        this.fieldDescription = obj.fieldDescription ?? null;
        this.staticValue = obj.staticValue ?? null;
        this.fieldIndex = obj.fieldIndex ?? null;
        this.parentField = obj.parentField ?? null;
        this.parentFieldIndex = obj.parentFieldIndex ?? null;
        this.originalID = obj.originalID ?? null;
        this.isMathFormula = obj.isMathFormula ?? false;
        this.validators = obj.validators ?? [];
        this.store = obj.store ?? [];
        this.shuffle = obj.shuffle ?? false;
        this.isLocked = obj.isLocked ?? false;
    }
}

export class AttributesResourceItem {
    resourceItemId: string;
    attrValues: { attribute: any; value: any }[];
    constructor(
        resourceItemId: string,
        attrValues: { attribute: any; value: any }[]
    ) {
        this.resourceItemId = resourceItemId;
        this.attrValues = attrValues;
    }
}
