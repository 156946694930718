export default class ConstantsWordScramble {
    static LIST_STYLE = {
        NONE: 1,
        NUMBER: 2,
        DOTTED: 3,
        DASHED: 4,
    };

    static STROKE_STYLE = {
        DASHED: 1,
        SOLID: 2,
        DOTTED: 3,
        SHAPE: 4,
        HANDWRITING: 5,
    };

    static LETTER_STYLE = {
        NONE: 1,
        UPPERCASE: 2,
        LOWERCASE: 3,
    };

    static SEPARATOR_STYLE = {
        SLASH: 1,
        SPACE: 2,
        DASHED: 3,
        SHAPE: 4,
    };

    static START_Y_FIRST_PAGE = 170;
    static START_Y_OTHER_PAGE = 50;
    static WORD_SCRAMBLE_PREFIX = "word_scramble_";

    static GAP_SENTENCE = 8;
    static MIN_WIDTH_INPUT_SENTENCE = 120;
}
