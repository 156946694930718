import axios from 'axios';
import { useEffect, useState } from 'react';
import { Accordion, Button, Form, ListGroup, Modal } from 'react-bootstrap';

import './styles.scss';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { addResourceItems, setLoading } from '../../../../redux/reducers/createWorksheet';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import { updateDataAPI } from '../../../../utils/query';

function TabTinyTapPage({}) {
    const { dataSetDesignRow } = useAppSelector(state => state.createWorksheetState);
    const [id, setID] = useState(dataSetDesignRow?.tinyTapID);
    const [iframeSource, setIframeSource] = useState(null);
    const [slides, setSlides] = useState([]);
    const [showNewImageTypeModal, setShowNewImageTypeModal] = useState(false);
    const [newImage, setNewImage] = useState(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setIframeSource(null);
        setSlides([]);
    }, [id]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, []);

    const fetchData = async() => {
        if (id) {
            const res = await axios.get('https://www.tinytap.com/store/api/album/structure/?unique_id=' + id);
            console.log(res.data);

            const iframeString = res.data['data']['album_embed_code'];
            const srcMatch = iframeString.match(/src=['"]([^'"]+)['"]/);
            const src = srcMatch ? srcMatch[1] : null;
            setIframeSource(src);

            const slides = res.data['data']['structure']['slides'];
            setSlides(slides);
        }
    }

    const addImage = async() => {
        if (newImage === null) {
            return;
        }

        const url = 'https://content.tinytap.it/' + id + '/unzipped/' + newImage['id'];

        dispatch(setLoading(true));

        const res = await axios.post(ConstantsTool.API_URL + 'resources/upload-data-set-design-row-icon/', {
            url,
            imgType: newImage['type'],
            dataSetDesignRowID: dataSetDesignRow?.id,
            canvasWidth: ConstantsTool.HEIGHT_A4,
            canvasHeight: ConstantsTool.WIDTH_A4,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
            },
        });

        const originalWidth = res.data['width'];
        const originalHeight = res.data['height'];

        let imgWidth: number, imgHeight: number, imgX: number, imgY: number, isLocked: boolean;

        if (newImage['type'] === 'background') {
            imgWidth = ConstantsTool.HEIGHT_A4;
            imgHeight = ConstantsTool.WIDTH_A4;
            imgX = 0;
            imgY = 0;
            isLocked = true;
        } else {
            imgWidth = originalWidth;
            imgHeight = originalHeight;
            imgX = 0;
            imgY = 0;
            isLocked = false;
            // if (originalWidth >= ConstantsTool.HEIGHT_A4) {
            //     imgWidth
            //     if (originalWidth / originalHeight > ConstantsTool.HEIGHT_A4 / ConstantsTool.WIDTH_A4) {
            //         imgWidth = ConstantsTool.HEIGHT_A4;
            //         imgHeight = Math.floor(imgWidth * originalHeight / originalWidth);
            //         imgX = 0;
            //         imgY = Math.floor((ConstantsTool.WIDTH_A4 - imgHeight) / 2);
            //     } else {
            //         imgHeight = ConstantsTool.WIDTH_A4;
            //         imgWidth = Math.floor(imgHeight * originalWidth / originalHeight);
            //         imgX = Math.floor((ConstantsTool.HEIGHT_A4 - imgWidth) / 2);
            //         imgY = 0;
            //     }
            // } else {
            //     imgWidth = res.data['width'];
            //     imgHeight = res.data['height'];
            //     // if (originalWidth / originalHeight > ConstantsTool.WIDTH_A4 / ConstantsTool.HEIGHT_A4) {
            //     //     imgWidth = ConstantsTool.HEIGHT_A4;
            //     //     imgHeight = Math.floor(imgWidth * originalHeight / originalWidth);
            //     // } else {
            //     //     imgHeight = ConstantsTool.WIDTH_A4;
            //     //     imgWidth = Math.floor(imgHeight * originalWidth / originalHeight);
            //     // }
            // }
        }

        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: [{
                    ...ConstantsTool.DEFAULT_TEXT_LINKED_IMAGE,
                    id: Math.floor(Math.random() * 999999999).toString() + '_' + Math.floor(Math.random() * 999999999).toString(),
                    width: imgWidth,
                    height: imgHeight,
                    x: imgX,
                    y: imgY,
                    imageAttribute: {
                        svgContent: '',
                        changeColor: {},
                        url: res.data['url']
                    },
                    isLocked,
                }],
            })
        );

        dispatch(setLoading(false));

        setShowNewImageTypeModal(false);
        setNewImage(null);
    }

    const addAudio = async(url: string) => {
        dispatch(setLoading(true));
        await updateDataAPI('PATCH', 'resources/data-set-design-rows/' + dataSetDesignRow.id + '/', {
            aiResponse: {...dataSetDesignRow.aiResponse, audio: url}
        });
        dispatch(setLoading(false));
    }

    return (
        <div className="tab-tinytap-page">
            <Form.Group>
                <Form.Label><strong>ID</strong></Form.Label>
                <Form.Control value={id} onChange={e => setID(e.target.value)} as="textarea" rows={3} />
            </Form.Group>
            <Button className="mt-3" onClick={fetchData}>Fetch</Button>

            <hr />
            {iframeSource && (
                <div>
                    <a href={iframeSource} target="_blank" rel="noopener,noreferrer">IFRAME</a>
                </div>
            )}
            {slides.length > 0 && (
                <div className="mt-3">
                    <Accordion defaultActiveKey="0" flush>
                        {slides.map((slide, slideIndex) => (
                            <Accordion.Item eventKey={slideIndex.toString()}>
                                <Accordion.Header>Slide {slideIndex + 1}</Accordion.Header>
                                <Accordion.Body>
                                    <div className="slide-body">
                                        <img src={'https://content.tinytap.it/' + id + '/unzipped/' + slide['filePathImage']} onClick={() => {
                                            setNewImage({id: slide['filePathImage'], type: 'background'});
                                            setShowNewImageTypeModal(true);
                                        }} />

                                        <ListGroup>
                                            {slide['activities'].map((activity, activityIndex) => (
                                                <ListGroup.Item key={activityIndex}>
                                                    <div className="audio">
                                                        <a href={'https://content.tinytap.it/' + id + '/unzipped/' + activity['filePathIntroRecording']} target="_blank" rel="noopener,noreferrer">Audio</a>
                                                        <Button size="sm" onClick={() => addAudio('https://content.tinytap.it/' + id + '/unzipped/' + activity['filePathIntroRecording'])}>add</Button>
                                                    </div>
                                                    <div className="shapes">
                                                        {activity['shapes'].map((shape, shapeIndex) => (
                                                            <div key={shapeIndex}>
                                                                <img src={'https://content.tinytap.it/' + id + '/unzipped/' + shape['filePathThumb']}
                                                                    onClick={() => {
                                                                        setNewImage({id: shape['filePathThumb'], type: 'image'});
                                                                        setShowNewImageTypeModal(true);
                                                                    }} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            )}

            {showNewImageTypeModal && (
                <Modal show={true} onHide={() => setShowNewImageTypeModal(false)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Select value={newImage['type']} onChange={e => setNewImage({...newImage, type: e.target.value})}>
                            <option value="background">Background</option>
                            <option value="image">Image</option>
                        </Form.Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={addImage}>Add</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default TabTinyTapPage;