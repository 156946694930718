import ClassroomConfig from "../utils/classroom";
import Config from "../utils/config";
import { IUserClass, UserClass } from "./userClass";

export interface IUserSurvey {
    email: string;
    role: string[];
    teachGrade: string[];
    topicTeach: string[];
    purpose: string[];
    userLookingFor?: string;
}

export class UserSurvey implements IUserSurvey {
    email: string;
    role: string[];
    teachGrade: string[];
    topicTeach: string[];
    purpose: string[];
    userLookingFor: string;
    constructor(object: any = {}) {
        this.email = object.email ?? "";
        this.role = object.role ?? [];
        this.teachGrade = object.teachGrade ?? [];
        this.topicTeach = object.topicTeach ?? [];
        this.purpose = object.purpose ?? [];
        this.userLookingFor = object.userLookingFor ?? "";
    }
}
interface IUserInfo {
    id: any;
    name: string;
    avatar: string;
    email?: string;
    sentEmail?: boolean;
    isVerify?: boolean;
    // phoneNumber: string;
    // password: string;
    uuId: string;
    // provider: number;
    // facebookId: string;
    // googleId: string;
    // appleId: string;
    // birthday: string;
    gender: string;
    // registerDate: number;
    token: string;
    loginStatus: number;
    // role: string;
    // state: string;
    // fakeId: string;
    // userRela: IUserRela[];
    createdDate?: Date;
    avgRating: number;
    categoryScore: any[];
    // userRela: IUserRela[];
    grades: string[];
    address: string;
    worksheetRatings: number[];
    totalWorksheet: number;
    role: number;
    returnUser?: number;
    survey?: IUserSurvey;
    loginDate?: Date;
    classId?: string;
    code?: string;
    status?: number;
    joinDate?: string;
    userClassId?: string;
    typeLogin?: string;

    userClass?: IUserClass | null;
    teacherLogin?: boolean;
    isDoneSurveyRating?: string[];

    country?: string;
    dateOfBirth?: Date;
    doneSurvey?: number;
    dateCheckIn?: Date;
}
export class UserInfo implements IUserInfo {
    id: any;
    name: string;
    avatar: string;
    email?: string;
    sentEmail?: boolean;
    isVerify?: boolean;
    // phoneNumber: string;
    // password: string;
    uuId: string;
    categoryScore: any[];
    // provider: number;
    // facebookId: string;
    // googleId: string;
    // appleId: string;
    // birthday: string;
    gender: string;
    // registerDate: number;
    token: string;
    loginStatus: number;
    // role: string;
    // state: string;
    // fakeId: string;
    avgRating: number;
    // userRela: IUserRela[];
    grades: string[];
    address: string;
    createdDate: Date;
    worksheetRatings: number[];
    totalWorksheet: number;
    role: number;
    classId?: string;
    returnUser?: number;
    survey?: IUserSurvey;
    loginDate: Date;
    code?: string;
    status?: number;
    joinDate?: string;
    userClassId?: string;
    typeLogin?: string;

    userClass?: IUserClass | null;
    teacherLogin?: boolean;
    isDoneSurveyRating?: string[];
    quotaUserResources?: any[];

    country?: string;
    dateOfBirth?: Date;
    doneSurvey?: number;
    dateCheckIn?: Date;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.name = object.name ?? "";
        this.avatar = object.avatar ?? "";
        this.email = object.email ?? "";
        this.sentEmail = object.sentEmail ?? false;
        this.isVerify = object.isVerify ?? false;
        // this.phoneNumber = object.phoneNumber ?? "";
        // this.password = object.password ?? "";
        this.uuId = object.uuId ?? "";
        this.categoryScore = object.categoryScore ?? [];
        // this.facebookId = object.facebookId ?? "";
        // this.googleId = object.googleId ?? "";
        // this.appleId = object.appleId ?? "";
        // this.birthday = object.birthday ?? "";
        this.gender = object.gender ?? "";
        // this.registerDate = object.registerDate ?? null;
        this.token = object.token ?? "";
        // this.role = object.role ?? "user";
        // this.state = object.state ?? "";
        // this.fakeId = object.fakeId ?? "";
        this.loginStatus = object.loginStatus ?? 0;
        this.avgRating = object.avgRating ?? 0;
        this.grades = object.grades ?? null;
        this.address = object.address ?? "";
        this.createdDate = object.createdDate ?? null;
        this.worksheetRatings = object.worksheetRatings ?? [];
        this.totalWorksheet = object.totalWorksheet ?? 0;
        this.role = object.role ?? Config.USER_ROLE_NORMAL;
        this.returnUser = object.returnUser ?? Config.NOT_RETURN;
        this.survey = object.survey ?? new UserSurvey();
        this.loginDate = object.loginDate ?? null;
        this.classId = object.classId ?? "";
        this.code = object.code ?? "";
        this.status = object.status ?? ClassroomConfig.USER_NOT_JOINED;
        this.joinDate = object.joinDate ?? "";
        this.userClassId = object.userClassId ?? "";
        this.typeLogin = object.typeLogin ?? "";
        if (object.userClass) {
            this.userClass = new UserClass(object.userClass);
        }
        this.teacherLogin = object.teacherLogin ?? false;
        this.isDoneSurveyRating = object.isDoneSurveyRating ?? [];
        // for (var i = 0; i <= 5; i++) {
        //     if (!this.worksheetRatings[i]) {
        //         this.worksheetRatings[i] = 0;
        //     }
        // }
        this.country = object?.country ?? "";
        this.dateOfBirth = object?.dateOfBirth ?? "";
        this.doneSurvey =
            object.doneSurvey ?? Config.STATUS_DONE_SURVEY_USER.NOT_DONE;
        if (object?.dateCheckIn) {
            this.dateCheckIn = object.dateCheckIn;
        }
    }
}

export const isRoleAdmin = (user: IUserInfo): boolean => {
    return user?.role == Config.USER_ROLE_ADMIN;
};

export const isRoleContentManager = (user: IUserInfo): boolean => {
    return user?.role == Config.USER_ROLE_CONTENT_MANAGER;
};
export const isRoleUserNormal = (user: IUserInfo): boolean => {
    return user?.role == Config.USER_ROLE_NORMAL;
};

export type { IUserInfo };
