import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "../../../../../../../assets/icon/deleteIcon";
import { useAppDispatch } from "../../../../../../../redux/hook";
import {
    chooseCorrectAnswerMC,
    deleteAnswerMC,
    updateAnswerMC,
    updateAnswersMC,
} from "../../../../../../../redux/async/multipleChoiceThunk";
import { setMessage } from "../../../../../../../redux/reducers/snackbar";
import { useDebounce } from "../../../../../../../resource-user/components/common/use-debounce";
import { IActivity } from "../../../../../../../shared/models/WorkSheetCreator";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../../shared/models/questionActivity";
import ConstantsTool from "../../../../../../../shared/utils/ConstantsTool";
import { getTextWidth } from "../../../../../../../utils/draw";
import { handleTabIndex, listAnswerKey } from "../../../../util";

const Answer = ({
    activity,
    answer,
    index,
    question,
    questionIndex,
    chosenAnswer,
    setChosenAnswer,
    userChangeCol,
    setUserChangeCol,
}: {
    activity: IActivity;
    answer: IAnswerActivity;
    index: number;
    question: IQuestionActivity;
    questionIndex: number;
    chosenAnswer: number;
    setChosenAnswer?: Function;
    userChangeCol: boolean;
    setUserChangeCol?: Function;
}) => {
    const dispatch = useAppDispatch();
    const listQuestion = activity.questions;
    const listAnswer: IAnswerActivity[] = question.answers;
    const nextQuestion: IQuestionActivity = activity.questions.find(
        (ele, index) => index == questionIndex + 1
    );
    const numberCol: number = question.multipleChoicesAttribute.numberCol;
    const [content, setContent] = useState<string>(answer?.text);
    const debouncedAnswer = useDebounce(content, 50);
    const [changeAnswerHeight, setChangeAnswerHeight] = useState({
        isChange: false,
        newCol: 4,
    });
    const answersKey = useRef(listAnswerKey()).current;

    useEffect(() => {
        const tabEvent = (e: any) => {
            if (e.keyCode === 9 || e.key === "Tab") {
                setTimeout(() => {
                    let answerIndex: any = Number(
                        document.activeElement.id[
                            document.activeElement.id.length - 1
                        ]
                    );
                    setChosenAnswer(answerIndex);
                }, 100);
            }
        };
        document.addEventListener("keydown", tabEvent);
        if (document.getElementById(`option_${chosenAnswer}`)) {
            document.getElementById(`option_${chosenAnswer}`).focus();
        }
        return () => {
            document.removeEventListener("keydown", tabEvent);
        };
    }, []);
    useEffect(() => {
        if (
            changeAnswerHeight.isChange &&
            (changeAnswerHeight.newCol != numberCol ||
                changeAnswerHeight.newCol == 1)
        ) {
            dispatch(
                updateAnswersMC({
                    question: question,
                    nextQuestion: nextQuestion,
                    numberCol: changeAnswerHeight.newCol,
                })
            );
            setChangeAnswerHeight({
                isChange: false,
                newCol: changeAnswerHeight.newCol,
            });
        }
    }, [changeAnswerHeight]);
    useEffect(() => {
        handleDebounce();
    }, [debouncedAnswer]);

    const handleDebounce = () => {
        if (chosenAnswer == index) {
            const answerIndex: number = index;
            let answerContent: string = content;
            dispatch(
                updateAnswerMC({
                    answer,
                    answerIndex,
                    questionIndex,
                    answerContent,
                    question,
                })
            );
            const newCol = handleFindCol();
            if (newCol != numberCol && !userChangeCol) {
                setChangeAnswerHeight({ isChange: true, newCol: newCol });
            }
        }
    };
    const handleFindCol = () => {
        let listNumberCol = [];
        let newCol;
        listAnswer.forEach((answer, index) => {
            let tempCol;
            const contentWidth = getTextWidth({
                text: `${answersKey[index]}. ${answer.text}`,
                font: "normal 26px Nunito",
            });
            for (let i = 1; i <= 4; i++) {
                if (
                    ConstantsTool.MULTIPLE_CHOICES_WIDTH / i -
                        ConstantsTool.GAP_ELEMENT_FILL_THE_BLANK * (i - 1) >=
                    contentWidth
                ) {
                    tempCol = i;
                }
            }
            if (tempCol) {
                listNumberCol.push(tempCol);
            } else {
                listNumberCol.push(numberCol);
            }
        });
        listNumberCol.sort((a, b) => a - b);
        newCol = listNumberCol[0];
        return newCol;
    };

    const handleChoseCorrectAnswer = (answer: IAnswerActivity) => {
        let listAnswerCorrect: IAnswerActivity[] = listAnswer.filter(
            (answer) => answer.isCorrect
        );
        let isTicked: IAnswerActivity = listAnswerCorrect.find(
            (answerInList: IAnswerActivity) => answerInList.id == answer.id
        );
        if (listAnswerCorrect.length + 1 < listAnswer.length || isTicked) {
            dispatch(
                chooseCorrectAnswerMC({
                    question: question,
                    answer: answer,
                })
            );
        } else {
            dispatch(
                setMessage({
                    severity: "error",
                    message: "All the answers can't be correct",
                })
            );
        }
    };
    const handleClickAnswer = (answerIndex: number) => {
        setTimeout(() => {
            setChosenAnswer(answerIndex);
        }, 100);
    };
    const handleAnswerContent = (content) => {
        setContent(content);
    };

    const checkAbleDelete = (answerActivity) => {
        const notFullCorrect = listAnswer.filter((answer) => !answer.isCorrect);
        if (
            ((notFullCorrect.length === 1 && answerActivity.isCorrect) ||
                notFullCorrect.length > 1) &&
            listAnswer.length > 2
        ) {
            return {
                reason: "",
                isAble: true,
            };
        }
        return {
            isAble: false,
            reason:
                listAnswer.length == 2
                    ? "Question must have at least 2 answers"
                    : "Question must have at least one incorrect answer",
        };
    };

    const handleDeleteAnswer = (answerActivity) => {
        let isAbleDelete = checkAbleDelete(answerActivity);
        if (isAbleDelete.isAble) {
            dispatch(
                deleteAnswerMC({
                    question: question,
                    answer: answerActivity,
                    numberCol:
                        listAnswer.length == numberCol
                            ? numberCol - 1
                            : numberCol,
                })
            );
            if (listAnswer.length - 1 < numberCol) {
                dispatch(
                    updateAnswersMC({
                        question: question,
                        nextQuestion: nextQuestion,
                        numberCol: numberCol - 1,
                    })
                );
            } else {
                if (nextQuestion) {
                    dispatch(
                        updateAnswersMC({
                            question: question,
                            nextQuestion: nextQuestion,
                            numberCol: numberCol,
                        })
                    );
                }
            }
            setUserChangeCol(true);
        } else {
            dispatch(
                setMessage({
                    severity: "error",
                    message: isAbleDelete.reason,
                })
            );
        }
    };

    return (
        <div className="answer-option">
            <div
                className="answer-box"
                onClick={() => handleChoseCorrectAnswer(answer)}
                style={{
                    border: answer.isCorrect ? "2px solid #62D4AB" : "",
                }}
            >
                {answersKey[index]}
            </div>
            <TextField
                multiline
                sx={{
                    "& fieldset": { border: "none" },
                }}
                inputProps={{
                    sx: {
                        "&::placeholder": {
                            fontStyle: "italic",
                        },
                    },
                }}
                id={`option_${index}`}
                className="answer-content"
                tabIndex={handleTabIndex(listQuestion, answer)}
                placeholder={`Option ${answersKey[index]}`}
                value={content}
                onChange={(e) => {
                    handleAnswerContent(e.target.value);
                }}
                onClick={() => handleClickAnswer(index)}
                style={{
                    border: answer.isCorrect
                        ? "1px solid #62D4AB"
                        : content
                        ? "1px solid #ee8d8d"
                        : "",
                }}
            />
            <div
                className="answer-remove-icon"
                onClick={() => handleDeleteAnswer(answer)}
            >
                <DeleteIcon />
            </div>
        </div>
    );
};

export default Answer;
