import { useEffect, useState } from "react";

import { requestInitPageWs } from "../../../../redux/async/createWorksheet";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { updateOpenWarningReplaceTemplate } from "../../../../redux/reducers/createWorksheet";
import {
    changeActivityToReplace,
    toggleShowListActivities,
} from "../../../../redux/reducers/leftData";
import { setMessage } from "../../../../redux/reducers/snackbar";
import Config from "../../../../shared/utils/config";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import "./index.scss";
import Routes from "../../../../resource-user/utils/routes";
import Cookies from "../../../../resource-user/utils/cookies";
import {
    ActiveAdditionIcon,
    ActiveCrosswordIcon,
    ActiveDivisionIcon,
    ActiveFillInTheBlankIcon,
    ActiveHandwritingIcon,
    ActiveMatchingIcon,
    ActiveMathCrosswordIcon,
    ActiveMultipleChoiceIcon,
    ActiveMultiplicationIcon,
    ActiveNametracingIcon,
    ActiveScratchIcon,
    ActiveSubtractionIcon,
    ActiveWordScrambleIcon,
    ActiveWordsearchIcon,
    AdditionIcon,
    ArrowDown,
    ArrowRightIcon,
    ArrowUp,
    ComingSoonIcon,
    CrosswordIcon,
    DivisionIcon,
    FillInTheBlankIcon,
    HandwritingIcon,
    MakeInteractiveIcon,
    MatchingIcon,
    MathCrosswordIcon,
    MultipleChoiceIcon,
    MultiplicationIcon,
    NametracingIcon,
    ScratchIcon,
    SubtractionIcon,
    WordScrambleIcon,
    WordsearchIcon,
} from "../../../../assets/icon/newActivitiesIcon";

const LeftListActivitiesV2 = () => {
    const dispatch = useAppDispatch();
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const numberRenderResource = useAppSelector(
        (state) => state.createWorksheetState.numberRenderResource
    );
    const [currentHover, setCurrentHover] = useState<string>();

    const leftListActivities = [
        {
            nameGroup: "General",
            activities: [
                {
                    title: "Convert PDF to Digital",
                    icon: <MakeInteractiveIcon />,
                    activeBackground:
                        "linear-gradient(#FFF 0 0) padding-box, linear-gradient(to bottom, #81BAE3, #7888DD) border-box",
                    border: "2px dashed #FFF",
                    link: Routes.WORKSHEET_MAKER + Routes.HASH_TOOL_V1,
                    height: "130px",
                    gaEvent: "choose_convert_pdf_to_digital_on_left_menu",
                },
                {
                    title: "Create From Scratch",
                    icon: <ScratchIcon />,
                    activeIcon: <ActiveScratchIcon />,
                    activeBackground:
                        "linear-gradient(#FFF 0 0) padding-box, linear-gradient(to bottom, #81BAE3, #7888DD) border-box",
                    border: "2px dashed #FFF",
                    type: Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE,
                    height: "130px",
                    gaEvent: "create_from_scratch",
                },
                {
                    title: "Fill In The Blank",
                    icon: <FillInTheBlankIcon />,
                    activeIcon: <ActiveFillInTheBlankIcon />,
                    activeBackground: "#F8F0FF",
                    border: "4px solid #C6BFF3",
                    type: Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE,
                    height: "111px",
                    gaEvent: "choose_fill_in_the_blank",
                },
                {
                    title: "Multiple Choice",
                    icon: <MultipleChoiceIcon />,
                    activeIcon: <ActiveMultipleChoiceIcon />,
                    activeBackground: "#EAFCFF",
                    border: "4px solid #B1EBF4",
                    type: "Coming soon",
                    // type: Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE,
                    height: "111px",
                    // gaEvent: "multiple_choice",
                },
            ],
        },
        {
            nameGroup: "Reading ",
            activities: [
                {
                    title: "Wordsearch",
                    icon: <WordsearchIcon />,
                    activeIcon: <ActiveWordsearchIcon />,
                    activeBackground: "#EBF5E5",
                    border: "4px solid #CDEABB",
                    type: Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
                    height: "111px",
                    gaEvent: "choose_word_search",
                },
                {
                    title: "Crossword",
                    icon: <CrosswordIcon />,
                    activeIcon: <ActiveCrosswordIcon />,
                    activeBackground: "#FFEBEB",
                    border: "4px solid #F9D3D3",
                    type: Config.ACTIVITY_TYPE.CROSSWORD.TYPE,
                    height: "111px",
                    gaEvent: "choose_crossword",
                },
                {
                    title: Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TITLE,
                    icon: <WordScrambleIcon />,
                    activeIcon: <ActiveWordScrambleIcon />,
                    activeBackground: "#F5C3E73D",
                    border: "4px solid #F5C3E7",
                    type: Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE,
                    height: "111px",
                    gaEvent: "choose_word_scramble_on_left_menu",
                },
                {
                    title: Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TITLE,
                    icon: <WordScrambleIcon />,
                    activeIcon: <ActiveWordScrambleIcon />,
                    activeBackground: "#F5C3E73D",
                    border: "4px solid #F5C3E7",
                    type: Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE,
                    height: "111px",
                    gaEvent: "choose_sentence_scramble_on_left_menu",
                },
                {
                    title: "Matching",
                    icon: <MatchingIcon />,
                    activeIcon: <ActiveMatchingIcon />,
                    activeBackground: "#F1F6FF",
                    border: "4px solid #C3D6F5",
                    type: "Coming soon",
                    height: "111px",
                    gaEvent: "choose_matching",
                },
            ],
        },
        {
            nameGroup: "Math",
            activities: [
                {
                    title: "Multiplication",
                    icon: <MultiplicationIcon />,
                    activeIcon: <ActiveMultiplicationIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE,
                    height: "111px",
                    gaEvent: "choose_multiplication",
                },
                {
                    title: "Addition",
                    icon: <AdditionIcon />,
                    activeIcon: <ActiveAdditionIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: Config.ACTIVITY_TYPE.ADDITION.TYPE,
                    height: "111px",
                    gaEvent: "choose_addition",
                },
                {
                    title: "Subtraction",
                    icon: <SubtractionIcon />,
                    activeIcon: <ActiveSubtractionIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: Config.ACTIVITY_TYPE.SUBTRACTION.TYPE,
                    height: "111px",
                    gaEvent: "choose_subtraction",
                },
                {
                    title: "Division",
                    icon: <DivisionIcon />,
                    activeIcon: <ActiveDivisionIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: Config.ACTIVITY_TYPE.DIVISION.TYPE,
                    height: "111px",
                    gaEvent: "choose_division",
                },
                {
                    title: "Math Crossword",
                    icon: <MathCrosswordIcon />,
                    activeIcon: <ActiveMathCrosswordIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: "Coming soon",
                    height: "111px",
                    gaEvent: "choose_math_crossword",
                },
            ],
        },
        {
            nameGroup: "Handwriting",
            activities: [
                {
                    title: "Handwriting",
                    icon: <HandwritingIcon />,
                    activeIcon: <ActiveHandwritingIcon />,
                    activeBackground: "#FEF9F5",
                    border: "4px solid #F9DACD",
                    type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
                    height: "111px",
                    gaEvent: "choose_handwriting",
                },
                {
                    title: "Name Tracing",
                    icon: <NametracingIcon />,
                    activeIcon: <ActiveNametracingIcon />,
                    type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
                    gaEvent: "choose_name_tracing",
                },
            ],
        },
    ];

    const [listExpandGroup, setListExpandGroup] = useState([]);
    const handleExpandGroup = (el) => {
        let newList = [];
        if (listExpandGroup.includes(el.nameGroup)) {
            newList = listExpandGroup.filter(
                (groupName) => groupName !== el.nameGroup
            );
            setListExpandGroup(newList);
        } else {
            newList = [...listExpandGroup, el.nameGroup];
            setListExpandGroup(newList);
        }
    };

    useEffect(() => {
        const groupToExpand = leftListActivities.find((group) =>
            group.activities.some(
                (activity) => activity.type === currentActivity.type
            )
        );
        setListExpandGroup((prev) => [...prev, groupToExpand.nameGroup]);
    }, [currentActivity]);

    const handleChangeActivity = (el) => {
        if (el.type === "Coming soon") {
            dispatch(
                setMessage({
                    message: "New feature coming soon!",
                    severity: "success",
                })
            );
        } else {
            if (el.link) {
                Cookies.set("fromToolV2", "yes");
                window.open(el.link, "_self");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else if (el.type == currentActivity?.type) {
                if (el.type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE) {
                    dispatch(changeActivityToReplace(el.type));
                    if (
                        numberRenderResource > ConstantsTool.INIT_NUMBER_ACTION
                    ) {
                        dispatch(updateOpenWarningReplaceTemplate());
                    } else {
                        dispatch(
                            requestInitPageWs({
                                activityToInit: el.type,
                                sourcePage:
                                    Config.SOURCE_USER_BEHAVIOR
                                        .CREATE_FROM_SCRATCH,
                            })
                        );
                    }
                } else {
                    dispatch(toggleShowListActivities(false));
                }
            } else {
                dispatch(changeActivityToReplace(el.type));
                if (numberRenderResource > ConstantsTool.INIT_NUMBER_ACTION) {
                    dispatch(updateOpenWarningReplaceTemplate());
                } else {
                    if (el.type !== Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE) {
                        dispatch(toggleShowListActivities(false));
                    }
                    dispatch(
                        requestInitPageWs({
                            activityToInit: el.type,
                            sourcePage:
                                Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH,
                        })
                    );
                }
            }
        }
    };

    return (
        <div className="list-group-activities">
            {leftListActivities.map((el, index) => (
                <div className="group-activities" key={el.nameGroup + index}>
                    <div
                        className="header-group"
                        onClick={() => handleExpandGroup(el)}
                    >
                        <p>{el.nameGroup}</p>
                        <div className="expand-icon">
                            {listExpandGroup.includes(el.nameGroup) ? (
                                <ArrowUp />
                            ) : (
                                <ArrowDown />
                            )}
                        </div>
                    </div>
                    {listExpandGroup.includes(el.nameGroup) && (
                        <div className="list-activities">
                            {el.activities.map((activity, i) => (
                                <div
                                    key={activity.type + i}
                                    className={`activity ${
                                        currentActivity?.type === activity.type
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleChangeActivity(activity)
                                    }
                                >
                                    {currentActivity?.type === activity.type
                                        ? activity.activeIcon
                                        : activity.icon}
                                    <p>{activity.title}</p>
                                    <div className="arrow-right">
                                        {activity.type === "Coming soon" ? (
                                            <ComingSoonIcon />
                                        ) : (
                                            <ArrowRightIcon />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
export default LeftListActivitiesV2;
