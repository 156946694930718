import { Button } from "@mui/material";
import { requestInitPageWs } from "../../../../redux/async/createWorksheet";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { updateOpenWarningReplaceTemplate } from "../../../../redux/reducers/createWorksheet";
import {
    changeActivityToReplace,
    toggleShowListActivities,
} from "../../../../redux/reducers/leftData";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import Config from "../../../../shared/utils/config";
import "./index.scss";
import { event } from "../util";

const TABS = [
    {
        id: Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE,
        name: Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TITLE,
    },
    {
        id: Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE,
        name: Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TITLE,
    },
];

const TabSection = ({ type }: { type: string }) => {
    const dispatch = useAppDispatch();
    const numberRenderResource = useAppSelector(
        (state) => state.createWorksheetState.numberRenderResource
    );

    const handleChangeTab = (id: string) => {
        event(
            type,
            id === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE
                ? "tab_word"
                : "tab_sentence"
        );
        dispatch(changeActivityToReplace(id));
        if (numberRenderResource > ConstantsTool.INIT_NUMBER_ACTION) {
            dispatch(updateOpenWarningReplaceTemplate());
        } else {
            dispatch(toggleShowListActivities(false));
            dispatch(
                requestInitPageWs({
                    activityToInit: id,
                    sourcePage: Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH,
                })
            );
        }
    };

    return (
        <div className="word-scramble-btn-group">
            {TABS.map(({ name, id }) => {
                return (
                    <Button
                        key={name + id}
                        className={`btn ${id === type ? "active" : "disable"}`}
                        fullWidth
                        onClick={() => {
                            if (id !== type) {
                                handleChangeTab(id);
                            }
                        }}
                        disableRipple
                    >
                        {name.replace(" Scramble", "")}
                    </Button>
                );
            })}
        </div>
    );
};

export default TabSection;
