import Config from "../utils/config";
import { IResourceItemNew } from "./resourceItemNew";

export interface ITransformData {
    x: number;
    y: number;
    type: number;
    width: number;
    height: number;
}

export class TransformData implements ITransformData {
    x: number;
    y: number;
    type: number;
    width: number;
    height: number;

    constructor(obj: any = {}) {
        this.x = obj.x ?? 0;
        this.y = obj.y ?? 0;
        this.width = obj.width ?? 0;
        this.height = obj.height ?? 0;
        this.type = obj.type ?? 0;
    }
}

export interface IEraserLines {
    points: number[];
}

export interface IEffectAttributeData {
    offset: number;
    direction: number;
    blur: number;
    transparency: number;
    intensity: number;
    thickness: number;
    roundness: number;
    spread: number;
    color: string;
}

export class EffectAttributeData implements IEffectAttributeData {
    offset: number;
    direction: number;
    blur: number;
    transparency: number;
    intensity: number;
    thickness: number;
    roundness: number;
    spread: number;
    color: string;

    constructor(obj: any = {}) {
        this.offset = obj.offset ?? 0;
        this.direction = obj.direction ?? 0;
        this.blur = obj.blur ?? 0;
        this.transparency = obj.transparency ?? 0;
        this.intensity = obj.intensity ?? 0;
        this.thickness = obj.thickness ?? 0;
        this.roundness = obj.roundness ?? 0;
        this.spread = obj.spread ?? 0;
        this.color = obj.color ?? "#212121";
    }
}

export interface IResourceItem {
    id: string;
    // templateId: string;
    tagIds: string[];
    categoryIds: string[];
    worksheetId: string;
    x: number;
    y: number;
    type: number;
    width: number;
    height: number;
    svgContent: string;
    transformData?: ITransformData;
    eraserLines?: IEraserLines[];
    usingToolClear?: boolean;
    originalSvgContent?: string;
    scaleX?: number;
    scaleY?: number;
    resourceId?: string;
    page?: number;
    image?: string;
    fontFamily?: string;
    fontSize?: number;
    align?: string;
    userId?: string;
    url?: string;
    colors?: string[];
    opacity?: number;
    rotation?: number;
    fontColor?: string;
    name?: string;
    alternativeImages?: any[];
    // bind 2 or more resource items together with the same bindingId for synchronized edit
    // ex: Text and SVG border in handwriting module will have the same height and position when either one is editted
    bindingId?: string;
    autoFillOn?: boolean;
    isOnFirstAdd?: boolean;
    isBold?: boolean;
    isItalic?: boolean;
    isUnderline?: boolean;
    isAppliedAutoFontSize?: boolean;
    isStrikeThrough?: boolean;
    vertical?: boolean;
    isDivision?: boolean;
    numDigits?: number;
    numProblems?: number;
    isBackgroundSize?: boolean;
    orientation?: string;
    textEffectId?: number;
    effectAttrData?: EffectAttributeData;
    moduleId?: string;
}

export class ResourceItem implements IResourceItem {
    id: string;
    svgContent: string;
    // templateId: string;
    tagIds: string[];
    x: number;
    y: number;
    categoryIds: string[];
    worksheetId: string;
    type: number;
    width: number;
    height: number;
    fontFamily: string;
    fontSize: number;

    eraserLines?: IEraserLines[];
    usingToolClear?: boolean;
    originalSvgContent?: string;
    transformData?: ITransformData;
    resourceId?: string;
    childrenContent: IResourceItemNew[];
    bindingId?: string;
    align?: string;
    page?: number;
    userId?: string;
    url?: string;
    colors?: string[];
    opacity?: number;
    image?: string;
    rotation?: number;
    fontColor?: string;
    name?: string;
    scaleX?: number;
    scaleY?: number;
    alternativeImages?: any[];
    autoFillOn?: boolean;
    isOnFirstAdd?: boolean;
    isBold?: boolean;
    isItalic?: boolean;
    isUnderline?: boolean;
    isAppliedAutoFontSize?: boolean;
    isStrikeThrough?: boolean;
    vertical?: boolean;
    isDivision?: boolean;
    numDigits?: number;
    numProblems?: number;
    isBackgroundSize?: boolean;
    orientation?: string;
    textEffectId?: number;
    effectAttrData?: EffectAttributeData;
    isChangeRotatePosition?: boolean;
    moduleId?: string;

    constructor(obj: any = {}) {
        this.id = obj.id ?? undefined;
        this.svgContent = obj.svgContent ?? null;
        // this.templateId = obj.templateId ?? null;
        this.tagIds = obj.tagIds ?? [];
        this.y = obj.y ?? 0;
        this.x = obj.x ?? 0;
        this.categoryIds = obj.categoryIds ? [...obj.categoryIds] : [];
        this.type = obj.type ?? Config.RESOURCE_TEMPLATE;
        this.width = obj.width ?? 0;
        this.height = obj.height ?? null;
        this.fontFamily = obj.fontFamily ?? null;
        this.fontSize = obj.fontSize ? parseInt(obj.fontSize) : 14;

        this.originalSvgContent = obj.originalSvgContent ?? null;
        this.userId = obj.userId ?? null;
        this.url = obj.url ?? null;
        this.colors = obj.colors ? [...obj.colors] : [];
        this.opacity = obj.opacity ?? 100;
        this.image = obj.image ?? "";
        this.rotation = obj.rotation ?? 0;
        this.fontColor = obj.fontColor ?? "";
        this.name = obj.name ?? "";
        this.page = obj.page ?? 0;
        this.resourceId = obj.resourceId ?? obj.id;
        this.bindingId = obj.bindingId ?? null;
        this.scaleY = obj.scaleY ?? 1;
        this.scaleX = obj.scaleX ?? 1;
        this.align = obj.align ?? "center";
        this.alternativeImages = obj.alternativeImages ?? null;
        if (obj.transformData) {
            this.transformData = new TransformData(obj.transformData);
        }
        this.worksheetId = obj.worksheetId ?? "";
        this.usingToolClear = obj.usingToolClear ?? false;
        this.eraserLines = obj.eraserLines ?? [];
        this.autoFillOn = obj.autoFillOn ?? null;
        this.isOnFirstAdd = obj.isOnFirstAdd ?? false;
        this.isBold = obj.isBold ?? false;
        this.isItalic = obj.isItalic ?? false;
        this.isAppliedAutoFontSize = obj.isAppliedAutoFontSize ?? true;
        this.isUnderline = obj.isUnderline ?? false;
        this.isStrikeThrough = obj.isStrikeThrough ?? false;
        this.vertical = obj.vertical ?? true;
        this.isDivision = obj.isDivision ?? false;
        this.numDigits = obj.numDigits ?? 2;
        this.numProblems = obj.numProblems ?? 12;
        this.isBackgroundSize = obj.isBackgroundSize ?? false;
        this.orientation = obj.orientation ?? "";
        this.textEffectId = obj.textEffectId ?? 0;
        if (obj.effectAttrData) {
            this.effectAttrData = new EffectAttributeData(obj.effectAttrData);
        }
        this.isChangeRotatePosition = obj.isChangeRotatePosition ?? false;
        this.moduleId = obj.moduleId ?? "";
        this.childrenContent = obj.childrenContent ?? [];
    }
}
