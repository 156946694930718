import { CellTable, ITableAttribute } from "../../shared/models/tableAttribute";

export const getSizeCellTable = ({
    tableAttribute,
    col,
    row,
    isRenderBackground = false,
}: {
    tableAttribute: ITableAttribute;
    row: number;
    col: number;
    isRenderBackground?: boolean;
}) => {
    let { data, gap } = tableAttribute;
    let cell = data[row][col];
    const borderWidth = cell.borderWidth;
    let cells = data[row];
    let dGap = gap + (gap > 0 ? borderWidth / 2 : 0);
    let left = 0,
        top = 0;
    let width = cell.width + borderWidth / 2 - dGap;
    let height = cell.height + borderWidth / 2 - dGap;
    if (!isRenderBackground) {
        for (let i = col + 1; i < cell.colspan + col; i++) {
            width += cells[i].width;
        }
        for (let i = row + 1; i < cell.rowspan + row; i++) {
            height += data[i][col].height;
        }
    }
    for (let i = 0; i < col; i++) {
        left += cells[i].width;
    }
    if (col > 0 && gap === 0) left -= borderWidth / 2;
    if (col > 0 && col < cells.length - 1 && gap === 0)
        width += borderWidth / 2;

    for (let i = 0; i < row; i++) {
        top += data[i][data[i].length - 1].height;
    }
    if (row > 0 && gap === 0) top -= borderWidth / 2;
    if (row > 0 && row < data.length - 1 && gap === 0)
        height += borderWidth / 2;
    const textWidth = width - 2 * borderWidth - dGap,
        textHeight = height - 2 * borderWidth - dGap;
    return {
        top,
        left,
        width,
        height,
        dGap,
        textWidth,
        textHeight,
    };
};

export const makePathCell = ({
    width,
    height,
    strokeWidth,
    top,
    left,
}: {
    width: number;
    height: number;
    strokeWidth: number;
    top: number;
    left: number;
}) => {
    const d = strokeWidth / 2;
    let x = width + left;
    let y = height + top;
    let AB = `M${left} ${top + d}L${x} ${top + d} `;
    let BC = `M${x - d} ${top}L${x - d} ${y} `;
    let CD = `M${left} ${y - d}L${x} ${y - d} `;
    let DA = `M${left + d} ${top}L${left + d} ${y} `;
    return {
        path: AB + BC + CD + DA,
        pathTop: AB,
        pathRight: BC,
        pathBottom: CD,
        pathLeft: DA,
        pathVertical: BC + DA,
        pathHorizontal: AB + CD,
    };
};

export const getDisplayPathCell = ({
    data,
    row,
    col,
}: {
    data: CellTable[][];
    row: number;
    col: number;
}) => {
    let cell = data[row][col];
    let currentZIndex = cell.zIndex;
    let all = true,
        top = true,
        left = true,
        right = true,
        bottom = true;

    const check = (zIndex: number, index: number) => {
        return (
            zIndex > currentZIndex ||
            (zIndex === currentZIndex && index % 2 !== 0)
        );
    };

    if (row > 0) {
        if (check(data[row - 1][col].zIndex, row)) {
            top = false;
            all = false;
        }
    }
    if (row < data.length - 1) {
        if (check(data[row + 1][col].zIndex, row)) {
            bottom = false;
            all = false;
        }
    }
    if (col > 0) {
        if (check(data[row][col - 1].zIndex, col)) {
            left = false;
            all = false;
        }
    }
    if (col < data[0].length - 1) {
        if (check(data[row][col + 1].zIndex, col)) {
            right = false;
            all = false;
        }
    }
    if (cell.positionParent) {
        let { row: rowP, col: colP } = cell.positionParent;
        let cellParent = data[cell.positionParent.row][cell.positionParent.col];
        const { colspan, rowspan } = cellParent;
        if (row < rowP + rowspan - 1) bottom = false;
        if (col < colP + colspan - 1) right = false;
        if (row > rowP) top = false;
        if (col > colP) left = false;
    }
    if(cell.rowspan > 1) bottom = false
    if(cell.colspan > 1) right = false
    return {
        all,
        top,
        bottom,
        left,
        right,
        vertical: right && left,
        horizontal: top && bottom,
    };
};

export const makeStrokeDasharray = ({
    strokeWidth,
    strokeDasharray,
    length,
}: {
    strokeWidth: number;
    strokeDasharray: number;
    length: number;
}) => {
    if (strokeDasharray === 0) return "";
    let dStart = strokeDasharray * strokeWidth;
    let dNext = strokeWidth;
    let d = (length + dNext) / (dStart + dNext);
    let count = Math.round(d);
    dNext = (length + dNext - dStart * count) / count;
    return `${dStart},${dNext}`;
};
