import ConstantsTool from "../utils/ConstantsTool";
import Config from "../utils/config";

export interface IPosition {
    row: number;
    column: number;
}

export interface IWord {
    value: string;
    clue: string;
    originValue?: string;
    userAnswer?: string;
    status?: number;
    onGrid?: boolean;
    position?: IPosition;
    direction?: number;
    index?: number;
    indexPrefix?: number;
    place?: boolean;
}

export class Word implements IWord {
    value: string;
    clue: string;
    originValue?: string;
    userAnswer?: string;
    onGrid?: boolean;
    index?: number;
    indexPrefix?: number;
    position?: IPosition;
    direction?: number;
    place?: boolean;
    status?: number;

    constructor(obj: IWord) {
        this.value = obj.value ?? "";
        this.clue = obj.clue ?? "";
        this.onGrid = obj.onGrid ?? false;

        if (obj.onGrid) {
            this.indexPrefix = obj.indexPrefix ?? -1;
            this.status = obj.status ?? ConstantsTool.CROSSWORD_STATUS.INIT;
            this.userAnswer =
                ConstantsTool.EMPTY_LETTER.repeat(this.value.length) ?? "";
            this.position = obj.position ?? {
                row: -1,
                column: -1,
            };
            this.direction = obj.direction ?? ConstantsTool.DIRECTIONS.ACROSS;
        }

        if (typeof obj.index === "number") {
            this.index = obj.index ?? -1;
        }

        if (typeof obj.place === "boolean") {
            this.place = obj.place ?? false;
        }

        if (obj.originValue) {
            this.originValue = obj.originValue ?? "";
        }
    }
}

export interface ICrossword {
    words: IWord[];
    grid: string[][];
    showWordBank?: boolean;
    showAnswerKey?: boolean;
    layout?: string;
}

export class Crossword implements ICrossword {
    words: IWord[];
    grid: string[][];
    showWordBank?: boolean;
    showAnswerKey?: boolean;
    layout?: string;

    constructor(obj: ICrossword) {
        this.showAnswerKey = obj.showAnswerKey ?? false;
        this.showWordBank = obj.showWordBank ?? false;
        this.layout = obj.layout ?? ConstantsTool.LAYOUT.DEFAULT;
        this.words = obj.words ?? [];
        this.grid = obj.grid ?? [];
    }
}
