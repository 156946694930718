import {
    IUserInfo,
    UserInfo,
    UserSurvey,
} from "../../../shared/models/userInfo";
import { UserInfoResourceTypes } from "../action/userInfoResource.action";

export interface IUserInfoResourceState {
    data: IUserInfo;
    loading: boolean;
    error: any;
    logout?: boolean;
}

const initialState: IUserInfoResourceState = {
    data: new UserInfo(),
    loading: false,
    error: null,
    logout: false,
};

function userInfoResourceState(
    state: IUserInfoResourceState = initialState,
    action: any
): IUserInfoResourceState {
    switch (action.type) {
        case UserInfoResourceTypes.LOGIN:
            return {
                ...state,
                loading: true,
            };
        case UserInfoResourceTypes.USER_LOGIN_SUCCESS:
            const newUserInfo = action.userInfo ?? new UserInfo();
            if (!action.userInfo.survey) {
                newUserInfo.survey = new UserSurvey();
            }
            return {
                ...state,
                loading: false,
                data: newUserInfo,
            };
        case UserInfoResourceTypes.LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                data: action.userInfo ?? new UserInfo(),
                error: action.error,
            };
        case UserInfoResourceTypes.REGISTER:
            return {
                ...state,
                data: action.userInfo ?? new UserInfo(),
                loading: true,
            };
        case UserInfoResourceTypes.REGISTER_FACEBOOK:
            return {
                ...state,
                data: action.userInfo ?? new UserInfo(),
                loading: true,
            };
        case UserInfoResourceTypes.REGISTER_GOOGLE:
            return {
                ...state,
                data: action.userInfo ?? new UserInfo(),
                loading: true,
            };
        case UserInfoResourceTypes.REGISTER_SUCCESS:
            return {
                ...state,
                data: action.userInfo ?? new UserInfo(),
                loading: false,
            };
        case UserInfoResourceTypes.REGISTER_FAILED:
            return {
                ...state,
                loading: false,
                data: action.userInfo ?? new UserInfo(),
                error: action.error,
            };
        case UserInfoResourceTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                logout: true,
            };
        case UserInfoResourceTypes.UPDATE_USER_EMAIL_SUCCESS:
            const email = action?.userInfo?.email ?? "";
            return {
                ...state,
                data: {
                    ...state.data,
                    email,
                },
            };
        case UserInfoResourceTypes.LOGIN_EMAIL:
            return {
                ...state,
            };
        case UserInfoResourceTypes.UPDATE_USER_SURVEY:
            const survey = action.survey ?? new UserSurvey();
            return {
                ...state,
                data: {
                    ...state.data,
                    survey,
                },
            };
        case "userInfo/loginSuccess":
            let { userInfo } = action.payload;

            return {
                ...state,
                data: userInfo,
            };
        case UserInfoResourceTypes.UPDATE_USERINFO_RESOURCE:
            return {
                ...state,
                data: action.userInfo,
            };

        default:
            return state;
    }
}

export default userInfoResourceState;
