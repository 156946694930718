import { useAppSelector } from '../../../redux/hook';
import SaveButton from './save-button';
import UndoRedo from './UndoRedo';
import Zoom from './Zoom';
import './index.scss';
import SelectZoneDisplayMode from './select-zone-display-mode';
import ResetDesignButton from './reset-linked-texts-in-page';
import PublishTemplateButton from './publish-template-button';
import ShowAnswerKeyButton from './show-answer-key-button';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import ConstantsTool from '../../../shared/utils/ConstantsTool';
import { isPageType } from '../../../utils';

export interface IUndoRedo {
    canUndo: boolean;
    undoFunction: Function;
    canRedo: boolean;
    redoFunction: Function;
}

export interface IZoom {
    ratio: number;
    updateRatio: Function;
    defaultValue?: number[];
}

const MainHeader = ({
    height = 52,
    widthLeftHeader = 500,
    handleClickNewWorksheet,
    undoRedoPayload,
    zoomPayload,
    handleSaveWorksheet,
    typeActivity,
    isToolV2 = false,
    isToolV1 = false,
    isEdited,
}: {
    height?: number;
    widthLeftHeader?: number;
    handleClickNewWorksheet?: Function;
    undoRedoPayload?: IUndoRedo;
    zoomPayload?: IZoom;
    handleSaveWorksheet?: (setOpenPopup: Function) => void;
    typeActivity?: string;
    isToolV2?: boolean;
    isToolV1?: boolean;
    isEdited?: boolean;
}) => {
    const {pageType, title, isIframe, template, templateZoneDisplayMode, disabledSaveButton, allFontsLoaded, allImagesLoaded, wordsCountDone, autoResizeDone } = useAppSelector(state => state.createWorksheetState);

    const [latestVersion, setLatestVersion] = useState(null);

    useEffect(() => {
        fetchVersion();
    }, []);

    const fetchVersion = async() => {
        const res = await getDataFromAPI('resources/versions/');
        if (res.data.length > 0) {
            setLatestVersion(res.data[0]['worksheetMakerVersion']);
        }
    }

    return (
        <div className="header-bar-container" style={{ height: (isIframe ? 0 : height) + "px", gridTemplateColumns: `${widthLeftHeader}px 1fr`, }} >
            <div className="left-header">
                <div>
                    <img className="logo" src="/images/logo.jpeg" />
                    <div className="version">
                        v{process.env.REACT_APP_VERSION}
                        {latestVersion && latestVersion === process.env.REACT_APP_VERSION && '- latest'}
                    </div>
                </div>
                <h1>{title}</h1>
            </div>

            <div className="right-header">
                <div className="functions">
                    <UndoRedo undoRedoPayload={undoRedoPayload} />
                    {zoomPayload && <Zoom zoomPayload={zoomPayload} />}
                </div>
                <div className="other-function">
                    {(
                        pageType === ConstantsTool.PAGE_TYPES['page'] ||
                        pageType === ConstantsTool.TEMPLATE_TYPES['template'] ||
                        pageType === ConstantsTool.TEMPLATE_TYPES['answerSheet'] ||
                        pageType === ConstantsTool.PAGE_TYPES['answerSheet'] ||
                        pageType === ConstantsTool.PAGE_TYPES['dataSetDesignRow'] ||
                        pageType === ConstantsTool.PAGE_TYPES['answerKey'] ||
                        pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail'] ||
                        pageType === ConstantsTool.PAGE_TYPES['thumbnail']) && (
                        <SelectZoneDisplayMode />
                    )}
                    {Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && template && template.id && (
                        <PublishTemplateButton template={template} />
                    )}
                    {(pageType === 'template' || pageType === 'page') && templateZoneDisplayMode === 'textOnly' && (
                        <ShowAnswerKeyButton />
                    )}
                    {isPageType(pageType)
                        && (
                        <ResetDesignButton />
                    )}
                    {/* <div>
                        #1{disabledSaveButton && <span>Disabled</span>}#
                    </div>
                    <div>
                        #2{allFontsLoaded && <span>font</span>}#
                    </div>
                    <div>
                        #3{allImagesLoaded && <span>image</span>}#
                    </div>
                    <div>
                        #4{wordsCountDone && <span>word count</span>}#
                    </div>
                    <div>
                        #5{autoResizeDone && <span>auto resize</span>}#
                    </div> */}
                    {isPageType(pageType) ? (
                        !disabledSaveButton && allFontsLoaded && allImagesLoaded && wordsCountDone && autoResizeDone && (
                            <SaveButton />
                        )
                    ) : (
                        !disabledSaveButton && !template?.locked && <SaveButton />
                    )}
                </div>
            </div>
        </div>
    );
};
export default MainHeader;
