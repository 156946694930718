import { ITemplate } from '../redux/reducers/createWorksheet';
import { IResourceItemNew } from '../shared/models/resourceItemNew';
import ConstantsTool from '../shared/utils/ConstantsTool';

export const getTemplateFields = (template: ITemplate, resourceItems: IResourceItemNew[], tempTemplateZones: IResourceItemNew[]) => {
    let items = resourceItems.filter(item => item.type === ConstantsTool.TYPE_RESOURCE_ZONE && item.fieldName);
    items = items.concat(tempTemplateZones);
    const fields = [];
    for (let i = 0; i < items.length; i++) {
        const fieldIndex = fields.findIndex(f => f.name === items[i].fieldName);
        if (fieldIndex === -1) {
            fields.push({
                name: items[i].fieldName,
                nb: 1,
                description: items[i].fieldDescription,
                parent: items[i].parentField,
                isAnswer: false,
            });
        } else {
            fields[fieldIndex]['nb']++;
            if (items[i].fieldDescription !== null && items[i].fieldDescription !== '') {
                fields[fieldIndex]['description'] = items[i].fieldDescription;
            }
        }
    }
    if (template.answerFields && template.answerFields.length > 0) {
        for (let i = 0; i < template.answerFields.length; i++) {
            const fieldIndex = fields.findIndex(f => f.name === template.answerFields[i].fieldName);
            if (fieldIndex === -1) {
                fields.push({
                    name: template.answerFields[i].fieldName,
                    nb: 1,
                    description: template.answerFields[i].fieldDescription,
                    parent: template.answerFields[i].parentField,
                    isAnswer: true
                });
            } else {
                fields[fieldIndex]['nb']++;
            }
        }
    }
    return fields;
}
