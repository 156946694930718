import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IInputWord, IPuzzle } from "../../../../../shared/models/puzzle";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import Config from "../../../../../shared/utils/config";
import { calcWithRatio, genDisplay } from "../../../../utils";
import ConstantsResource from "../../../../utils/ConstantsResource";

export const PREFIX_DIRECTIONid = "rect";

const SVGShowWordDirection = ({
    resourceItemData,
    ratioPage = 1,
    isCreatedByToolV2,
}: {
    resourceItemData?: IResourceItemNew;
    ratioPage?: number;
    isCreatedByToolV2?: any;
}) => {
    let inputWords: IInputWord[] = useSelector(
        (state: any) => state.wordSearchState.inputWords
    );
    let puzzle: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    let cellPx = puzzle.puzzleCellPx * ratioPage;
    // const isShow = puzzle.showAnswerKey === Config.SHOW_VALUE;
    // const [isShowAnswer, setShowAnswer] = useState(isShow);
    useEffect(() => {
        setSVGSize();
    }, [puzzle, ratioPage]);

    // useEffect(() => {
    //     if (isShowAnswer) {
    //         setShowAnswer(false);
    //         setTimeout(() => {
    //             setShowAnswer(true);
    //         }, 400);
    //     }
    // }, [puzzle.puzzleSize, puzzle.puzzleShape]);
    // useEffect(() => {
    //     setShowAnswer(puzzle.showAnswerKey === Config.SHOW_VALUE);
    // }, [puzzle.showAnswerKey]);

    const setSVGSize = () => {
        let puzzleGrid = document.getElementById("puzzle_grid");
        let puzzleGridSVG = document.getElementById(
            ConstantsResource.ANSWER_PUZZLEid
        );
        puzzleGridSVG.setAttribute("width", puzzleGrid.offsetWidth.toString());
        puzzleGridSVG.setAttribute(
            "height",
            puzzleGrid.offsetHeight.toString()
        );
    };
    return (
        <svg
            id={ConstantsResource.ANSWER_PUZZLEid}
            style={
                isCreatedByToolV2
                    ? {
                          display: genDisplay(
                              puzzle.showAnswerKey == Config.SHOW_VALUE
                          ),
                          position: "absolute",
                          top: 0,
                      }
                    : {
                          display: genDisplay(
                              puzzle.showAnswerKey == Config.SHOW_VALUE
                          ),
                      }
            }
        >
            {inputWords.map((inputWord, index) => {
                if (inputWord.onGrid === 1) {
                    let param = drawLoop(inputWord, 0, cellPx);
                    return (
                        <rect
                            id={ConstantsResource.PREFIX_DIRECTIONid + index}
                            key={ConstantsResource.PREFIX_DIRECTIONid + index}
                            x={calcWithRatio(param.rectX, ratioPage) + "px"}
                            y={calcWithRatio(param.rectY, ratioPage) + "px"}
                            width={
                                calcWithRatio(param.rectWidth, ratioPage) + "px"
                            }
                            height={
                                calcWithRatio(param.rectHeight, ratioPage) +
                                "px"
                            }
                            rx={
                                calcWithRatio(param.cornerRadius, ratioPage) +
                                "px"
                            }
                            transform={
                                "rotate(" +
                                param.rectRotateDegrees +
                                " " +
                                calcWithRatio(param.rectRotateX, ratioPage) +
                                " " +
                                calcWithRatio(param.rectRotateY, ratioPage) +
                                ")"
                            }
                            style={{
                                fill: "none",
                                strokeWidth: "2",
                                stroke: "rgb(255,0,0)",
                            }}
                        ></rect>
                    );
                }
            })}
        </svg>
    );
};

const drawLoop = (inputWord: IInputWord, ratio?: number, cellPx?: number) => {
    if (!ratio) ratio = 1;
    let cellDimension =
        document?.getElementById("puzzle_cell1-1")?.getBoundingClientRect()
            ?.width ?? 0;
    if (cellPx) {
        cellDimension = cellPx;
    }
    // cellDimension is not right in the first time

    let rectRotateDegrees = 0;
    switch (inputWord.direction) {
        case "left":
            rectRotateDegrees = 0;
            break;
        case "down":
            rectRotateDegrees = 90;
            break;
        case "right":
            rectRotateDegrees = 180;
            break;
        case "up":
            rectRotateDegrees = 270;
            break;
        case "left-down":
            rectRotateDegrees = 45;
            break;
        case "left-up":
            rectRotateDegrees = 315;
            break;
        case "right-down":
            rectRotateDegrees = 135;
            break;
        case "right-up":
            rectRotateDegrees = 225;
            break;
        default:
            //should be never
            rectRotateDegrees = 0;
            break;
    }
    let rectHeight;
    let rectWidth;
    let rectX;
    let rectY;
    let rectRotateX;
    let rectRotateY;
    if (rectRotateDegrees % 90 === 0) {
        rectWidth =
            cellDimension * (inputWord.word.length - 1) +
            cellDimension / Math.SQRT2;
        rectX =
            cellDimension * inputWord.startCell[0] +
            (cellDimension - cellDimension / Math.SQRT2) / 2;
        //rectHeight = cellDimension;
        rectHeight = cellDimension / Math.SQRT2;
        rectRotateX = rectX + rectHeight / 2;
        //rectY = (cellDimension * (startCell[1] -1 ));
        //rectRotateY = rectY + (cellDimension/2);
        rectY =
            cellDimension * inputWord.startCell[1] +
            (cellDimension - cellDimension / Math.SQRT2) / 2;
        rectRotateY =
            cellDimension * inputWord.startCell[1] + cellDimension / 2;

        //half of one square
        //rectRotateX = 1 / (wordLength * 2);
    } else {
        //multiply length-1 by the sqrt2 since that's the diagonal distance, so that the radius of the circle from the center of the letter isn't scaled
        rectWidth =
            cellDimension * (inputWord.word.length - 1) * Math.SQRT2 +
            cellDimension / Math.SQRT2;
        rectX =
            cellDimension * inputWord.startCell[0] +
            (cellDimension - cellDimension / Math.SQRT2) / 2;
        rectHeight = cellDimension / Math.SQRT2;
        rectRotateX = rectX + rectHeight / 2;
        rectY =
            cellDimension * inputWord.startCell[1] +
            (cellDimension - cellDimension / Math.SQRT2) / 2;
        rectRotateY =
            cellDimension * inputWord.startCell[1] + cellDimension / 2;
        //rectRotateX = 1 / (wordLength * 2 * Math.SQRT2);
    }
    //rectRotateY = cellDimension / 2;
    let cornerRadius = cellDimension / 3 / ratio;

    return {
        rectX,
        rectY,
        rectWidth,
        rectHeight,
        cornerRadius,
        rectRotateDegrees,
        rectRotateX,
        rectRotateY,
    };
};

export default SVGShowWordDirection;
