import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateResourceItem } from '../../../../../../redux/reducers/createWorksheet';
import { useAppSelector } from '../../../../../../redux/hook';
import './styles.scss';
import ConstantsTool from '../../../../../../shared/utils/ConstantsTool';
import { TextAttribute } from '../../../../../../shared/models/resourceItemNew';

export type SelectOptionType = {
    value: string,
    label: string,
}

let startLength = 0;
const exampleTextOptions =  Array.from({ length: 8 }, (_, index) => {
    startLength += 5;
    return ({ value: generateRandomText(startLength + 5), label: (startLength + 5) + ' characters' })
});

export function generateRandomText(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        text += characters.charAt(randomIndex);
    }

    return text;
}

const SelectExampleText = ({
    currentElement,
}) => {
    const dispatch = useDispatch();
    const { pageIndex } = useAppSelector((state) => state.createWorksheetState);
    const [selectedExampleText, setSelectedExampleText] = useState<SelectOptionType | ''>('');

    const handleUpdateExampleText = (selectedValue: { label: string, value: string }) => {
        setSelectedExampleText(selectedValue);
        let { value } = selectedValue;
        if (!value) return;

        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER) {
            const textAttribute = new TextAttribute(currentElement.textAttribute);
            const currentNbLinkedZones = textAttribute.nbLinkedZonesInContainer;
            value = Array(currentNbLinkedZones).fill(value).join('~');
        }

        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                pageIndex,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...currentElement.textAttribute,
                            content: value,
                        },
                    }
                ]
            })
        )
    }

    return (
        <div className="select-example-text m-1">
            <Select
                options={exampleTextOptions}
                value={selectedExampleText}
                onChange={handleUpdateExampleText}
            />
        </div>
    )
};

export default SelectExampleText;