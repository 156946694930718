import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { removeResourceItems, updateResourceItems, updateSelectedText } from "../../../../../redux/reducers/createWorksheet";
import CustomText from "../../../../../resource-user/components/common/custom-text";
import {
    IResourceItemNew,
    ITextAttribute,
} from "../../../../../shared/models/resourceItemNew";
import groupElementStore from "../../../../../syncExternalStore/groupElementStore";
import { resourceCanEditText } from "../../../../../utils";
import { getSizeBoxText } from "../../../../../utils/draw";

const CanvasText = ({
    item,
    pageIndex,
    ratio = 1,
    currentElement,
    setCurrentElement,
}: {
    item: IResourceItemNew;
    pageIndex: number;
    ratio?: number;
    currentElement: IResourceItemNew;
    setCurrentElement?: (currentElement: IResourceItemNew) => void;
}) => {
    const isDisplayTextEdit = useAppSelector(
        (state) => state.createWorksheetState.isOnEditText
    );
    const selectedText = useAppSelector(
        (state) => state.createWorksheetState.selectedText
    );
    const inputWords = useAppSelector(
        (state: any) => state.wordSearchState.inputWords
    );
    const fontSizeResourceItemsGroup = useAppSelector((state) => state.createWorksheetState.fontSizeResourceItemsGroup);

    const [fontSizeResourceItem, setFontSizeResourceItem] = useState(item.textAttribute.fontSize);

    const checkLineThroughText = () => {
        if (inputWords) {
            if (item.idType?.includes("word_search")) {
                for (let word of inputWords) {
                    if (item.textAttribute?.content === word.word) {
                        return word.status === 1;
                    }
                }
            }
        }
        return false;
    };
    if (currentElement?.idType?.includes("ws_title_")) {
        console.log(
            "item.textAttribute.underline",
            currentElement.textAttribute.underline
        );
    }
    let isWsGenerator = item?.idType?.includes("ws_generator");
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (
            item.textAttribute.content.length === 0 &&
            currentElement === null
        ) {
            dispatch(
                removeResourceItems({
                    resourceIds: [item.id],
                    pageIndex: item.pageIndex,
                })
            );
        }
    }, [currentElement]);

    const onInput = (textAttribute: ITextAttribute) => {
        let size = getSizeBoxText({
            textAttribute: textAttribute,
            width: currentElement.width,
            type: currentElement.type,
        });
        if (!textAttribute.content.length) {
            size.height = currentElement.height;
            size.width = currentElement.width;
        }
        let newResource = {
            ...currentElement,
            textAttribute,
        };
        let resourceItems: IResourceItemNew[] = [newResource];
        if (item.isGroup) {
            let resourceItem = groupElementStore.resizeParentGroup(newResource);
            if (resourceItem) {
                resourceItems.push(resourceItem);
            }
        }
        dispatch(
            updateResourceItems({
                pageIndex: item.pageIndex,
                resourceItems,
            })
        );
        setCurrentElement(newResource);
    };

    useEffect(() => {
        const configFontSizeOfResourceItem = () => {
            let currentFontSize = item.textAttribute.fontSize;
            if (!item.textAttribute.isAppliedAutoFontSize) {
                currentFontSize = item.textAttribute.fontSize;
            } else {
                if (!!item.zoneGroup && fontSizeResourceItemsGroup[pageIndex]) {
                    currentFontSize = fontSizeResourceItemsGroup[pageIndex][item.zoneGroup];
                } else {
                    currentFontSize = item.textAttribute.fontSize;
                };
            };
            setFontSizeResourceItem(currentFontSize);
        };

        configFontSizeOfResourceItem();
    }, [fontSizeResourceItemsGroup, item?.textAttribute]);

    return (
        <React.Fragment>
            <CustomText
                pageIndex={pageIndex}
                item={item}
                textAttribute={{
                   ...item.textAttribute,
                   fontSize: fontSizeResourceItem
                }}
                isEditable={resourceCanEditText(item.type)}
                selectedText={selectedText}
                onSelectedText={(value) => {
                    dispatch(updateSelectedText(value));
                }}
                isActive={currentElement ? true : false}
                onInput={onInput}
                opacity={currentElement?.opacity ?? item.opacity}
            />
        </React.Fragment>
    );
};

export default CanvasText;
