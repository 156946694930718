const DropdownTool = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M8.70615 11.4137L11.2961 14.0037C11.6861 14.3937 12.3161 14.3937 12.7061 14.0037L15.2961 11.4137C15.9261 10.7837 15.4761 9.70375 14.5861 9.70375H9.40615C8.51615 9.70375 8.07615 10.7837 8.70615 11.4137Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>
);

export default DropdownTool;
