import { AnyAction, createListenerMiddleware } from "@reduxjs/toolkit";
import {
    CrosswordTypes,
    updateCurrentConfigSuccessAction,
} from "../../resource-user/redux/action/crossword.action";
import { IWord } from "../../shared/models/crossword";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import Config from "../../shared/utils/config";
import { requestSavePageWs } from "../async/createWorksheet";
import {
    updateCrosswordItems,
    updateLayouItemsCrossword,
    updateWordbankCrosswordItem,
} from "../reducers/createWorksheet";
import { RootState } from "../store";

const listenCrossword = createListenerMiddleware();

const isWordsDifferent = (words: IWord[], _words: IWord[]) => {
    const wordsOnGrid = words.filter((word) => word.onGrid);
    const _wordsOnGrid = _words.filter((word) => word.onGrid);

    if (wordsOnGrid.length !== _wordsOnGrid.length) {
        return true;
    }

    wordsOnGrid.sort((w, _w) => w?.index - _w?.index);
    _wordsOnGrid.sort((w, _w) => w?.index - _w?.index);

    for (let i = 0; i < wordsOnGrid.length; i++) {
        const word = wordsOnGrid[i];
        const _word = _wordsOnGrid[i];

        const { row, column } = word.position;
        const { row: _row, column: _column } = _word.position;

        if (
            word.index !== _word.index ||
            word.value !== _word.value ||
            word.clue !== _word.clue ||
            row !== _row ||
            column !== _column
        ) {
            return true;
        }
    }

    return false;
};

const isGridsDifferent = (grid: string[][], _grid: string[][]) => {
    const row = grid?.length;
    const column = grid[0]?.length;

    const _row = _grid?.length;
    const _column = _grid[0]?.length;

    if (!grid && !_grid) {
        return false;
    }

    if (row !== _row || column !== _column) {
        return true;
    }

    for (let i = 0; i < row; i++) {
        for (let j = 0; j < column; j++) {
            if (grid?.[row]?.[column] && _grid[_row][_column]) {
                if (grid[row][column] !== _grid[_row][_column]) {
                    return true;
                }
            }
        }
    }

    return false;
};

const checkDifferent = (
    action: AnyAction,
    currentState: RootState,
    previousState: RootState
) => {
    if (
        currentState?.createWorksheetState?.currentActivity?.type ===
        Config.ACTIVITY_TYPE.CROSSWORD.TYPE
    ) {
        const EXCLUDE_ACTIONS = [CrosswordTypes.CHANGE_WORDS];
        if (EXCLUDE_ACTIONS.includes(action.type)) return false;

        const INCLUDE_ACTIONS = [requestSavePageWs.fulfilled.type];
        if (INCLUDE_ACTIONS.includes(action.type)) return true;

        const { words, layout, showAnswerKey, showWordBank, grid } =
            currentState.crosswordState;
        const {
            words: _words,
            layout: _layout,
            showAnswerKey: _showAnswerKey,
            showWordBank: _showWordBank,
            grid: _grid,
        } = previousState.crosswordState;

        return (
            isGridsDifferent(grid, _grid) ||
            isWordsDifferent(words, _words) ||
            layout !== _layout ||
            showAnswerKey !== _showAnswerKey ||
            showWordBank !== _showWordBank
        );
    }

    return false;
};

listenCrossword.startListening({
    predicate: (
        action: AnyAction,
        currentState: RootState,
        previousState: RootState
    ) => {
        return checkDifferent(action, currentState, previousState);
    },
    effect: async (action, listenerApi) => {
        const TIME_DELAY_ELEMENT_LOAD = 10;
        const dispatch = (fn: any) => listenerApi.dispatch(fn);
        const type = action.type;

        if (
            [
                CrosswordTypes.UPDATE_LAYOUT,
                CrosswordTypes.CHANGE_WORDS_SUCCESS,
                CrosswordTypes.INIT_CROSSWORD_GAME_SUCCESS,
                CrosswordTypes.CHANGE_CLUE_CROSSWORD_SUCCESS,
            ].includes(type)
        ) {
            await listenerApi.delay(TIME_DELAY_ELEMENT_LOAD);
            const state = listenerApi.getState() as RootState;
            const { layout, showWordBank } = state.crosswordState;

            const isUpdateLayout = type === CrosswordTypes.UPDATE_LAYOUT;
            if (isUpdateLayout) {
                await dispatch(
                    updateLayouItemsCrossword({
                        layout,
                    })
                );
            }

            dispatch(
                updateCrosswordItems({
                    layout,
                    showWordBank,
                    updateImage: isUpdateLayout,
                })
            );
        }

        if (type === CrosswordTypes.UPDATE_SHOW_WORD_BANK) {
            await listenerApi.delay(TIME_DELAY_ELEMENT_LOAD);
            const state = listenerApi.getState() as RootState;
            const words = state.crosswordState.words;
            dispatch(
                updateWordbankCrosswordItem({
                    status: action.status && words.length > 0,
                })
            );
        }

        if (type === requestSavePageWs.fulfilled.type) {
            dispatch(
                updateCurrentConfigSuccessAction({
                    direction: ConstantsTool.DIRECTIONS.ACROSS,
                    wordSelected: null,
                    position: null,
                })
            );
        }
    },
});

export default listenCrossword;
