import { Fragment, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux/hook";
import Config from "../../../../shared/utils/config";
import ResourceItemComponent from "../ResourceItem";

function TabComponents({
    activeTab,
    parent,
    dataTab,
    listIdBackgroundUsing,
}: {
    dataTab: any[];
    activeTab: number;
    parent: HTMLDivElement;
    listIdBackgroundUsing: string[];
}) {
    const listBackgroundUsing = useAppSelector(
        (state) => state.leftDataState.listBackgroundUsing
    );
    const refSearch = useRef<HTMLDivElement>(null);
    const [heightSearchBar, setHeightSearchBar] = useState(0);
    useEffect(() => {
        setHeightSearchBar(refSearch?.current?.offsetHeight);
    }, []);

    return (
        <Fragment>
            {/* <div
                ref={refSearch}
                style={{
                    position: "fixed",
                    padding: "12px 16px 0 16px",
                    width: parent?.offsetWidth - 16 * 2 - 2, //2: border
                    top: parent?.getBoundingClientRect()?.top,
                    left: parent?.getBoundingClientRect()?.left,
                    zIndex: 4,
                    background: "white",
                }}
            >
                <SearchBar />
            </div>

            <div
                style={{
                    height: heightSearchBar,
                }}
            /> */}
            {listBackgroundUsing.length ?
                (<div className="list-resource-item">
                    {
                        listBackgroundUsing.map((el) => {
                            return (
                                <ResourceItemComponent
                                    key={el.resourceItem.id + "-" + el.pageIndex}
                                    resourceItem={el.resourceItem}
                                    typeResource={activeTab}
                                    classNameItem={
                                        activeTab === Config.RESOURCE_TEMPLATE
                                            ? "template-resource-item"
                                            : "resource-item"
                                    }
                                ></ResourceItemComponent>
                            );
                        })
                    }
                </div>) : (<></>)}
            <div className="list-resource-item">
                {dataTab
                    .filter(resourceItem => !listIdBackgroundUsing.includes(resourceItem.id))
                    .map((resourceItem) => {
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={
                                    activeTab === Config.RESOURCE_TEMPLATE
                                        ? "template-resource-item"
                                        : "resource-item"
                                }
                            ></ResourceItemComponent>
                        );
                    })}
            </div>
        </Fragment>
    );
}

export default TabComponents;
