import { useSelector } from "react-redux";
import { IPuzzle } from "../../../../../shared/models/puzzle";
import {
    checkIsClassrommProject,
    checkIsWebProject,
} from "../../../../../shared/utils";
import Config from "../../../../../shared/utils/config";
import IconLightActive from "../../../../assets/images/icon/icon-light-active.svg";
import IconLight from "../../../../assets/images/icon/icon-light.svg";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import {
    getEndCellPosition,
    getImageSource,
    getWidthHeightPuzzleCell,
    randomRgba,
} from "../../../../utils";
import Constants from "../../../../utils/constants";
import { TypeOfPlacementTooltip } from "../../../common/library/render-tooltip";
import ToolTipCustomize from "../../../common/library/tool-tip";
import "./style.scss";

const GetHintWse = () => {
    let idSvg = Config.ID_SVG_WSE;
    let svgURI = "http://www.w3.org/2000/svg";

    let puzzle: IPuzzle = useSelector(
        (state: ResourceAppState) => state.wordSearchState.puzzle
    );
    const inputWords = useSelector(
        (state: ResourceAppState) => state.wordSearchState.inputWords
    );
    const isFinish = useSelector(
        (state: ResourceAppState) => state.gameResourceState.isFinish
    );

    const ratio = useSelector(
        (state: ResourceAppState) => state.wordSearchState.ratio
    );

    let findInputWord = inputWords.filter(
        (item) => item.status == Config.STATUS_PUZZLE.FOUND
    );
    let initInputWords = inputWords.filter(
        (item) => item.status == Config.STATUS_PUZZLE.INIT
    );
    const active = initInputWords?.length && !isFinish;
    const findInputWordHint =
        initInputWords[Math.floor(Math.random() * initInputWords.length)];
    // let [findInputWordHint, setFindInputWordHint] = useState(randomItemInput);

    const getHintWseFc = () => {
        paintSvg();
    };

    const paintSvg = () => {
        if (findInputWordHint) {
            let svgItem = document.createElementNS(
                svgURI,
                "line"
            ) as HTMLElement;
            let rect = document
                .querySelector(`#${idSvg}`)
                ?.getBoundingClientRect();

            let { widthEle, heightEle } = getWidthHeightPuzzleCell();

            let cellPx = puzzle.puzzleCellPx * ratio;
            let cellX = findInputWordHint.startCell[0];
            let cellY = findInputWordHint.startCell[1];
            try {
                const value = puzzle.puzzleArrNumber[cellY][cellX];
                if (value == 0) {
                    return;
                }
            } catch (e) {
                console.log("Error:", e);
            }

            const id = "hint_" + cellX + "_" + cellY;

            let color = randomRgba({ opacity: 0.3 });
            let width =
                checkIsWebProject() || checkIsClassrommProject()
                    ? widthEle
                    : cellPx;
            let height =
                checkIsWebProject() || checkIsClassrommProject()
                    ? heightEle
                    : cellPx;

            svgItem.style.strokeWidth = (
                height -
                Constants.STROKE.WIDTH * 2 -
                2 * Math.SQRT2
            ).toString();
            svgItem.style.strokeLinecap = "round";
            svgItem.style.stroke = color;
            svgItem.setAttribute("id", id);

            const wordLength = findInputWordHint.word.length - 1;
            document.querySelector(`#${idSvg}`).appendChild(svgItem);

            const x1 = width * cellX + width / 2;
            const y1 = height * cellY + height / 2;
            const endCell = getEndCellPosition({
                startX: cellX,
                startY: cellY,
                direction: findInputWordHint.direction,
                wordLength,
            });
            const x2 = width * endCell[0] + width / 2;
            const y2 = height * endCell[1] + height / 2;

            svgItem.setAttribute("x1", x1.toString());
            svgItem.setAttribute("y1", y1.toString());
            svgItem.setAttribute("x2", x2.toString());
            svgItem.setAttribute("y2", y2.toString());
            setTimeout(() => {
                svgItem.remove();
            }, 200);
            return;
        }
    };
    return (
        <ToolTipCustomize
            title="Give A Hint"
            placement={TypeOfPlacementTooltip.right}
            classes={{ tooltip: "custom-tooltip" }}
            arrow={false}
        >
            <div
                className={"suggest-ans " + (active ? "active" : "")}
                onClick={() => {
                    findInputWord?.length != inputWords?.length &&
                        active &&
                        getHintWseFc();
                }}
            >
                <div
                    className={"suggest-ans-light " + (active ? "active" : "")}
                    style={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                    }}
                >
                    <img
                        src={getImageSource(
                            active ? IconLightActive : IconLight
                        )}
                        alt="light icon"
                        style={{ width: "20px", height: "20px" }}
                    />
                </div>
            </div>
        </ToolTipCustomize>
    );
};

export default GetHintWse;
