import Config from "../../shared/utils/config";

export default class ConstantsResource {
    static ORIGIN_URL_WEB = [
        "driving theory",
        "cna",
        "aws solution architect",
        "comptia security+",
        "comptia network+",
        "comptia a+",
        "hvac basic",
        "epa 608",
        "mblex",
        "cdl",
        "dmv",
    ];
    static HOST_IMAGE_LANDING_CREATE =
        "https://storage.googleapis.com/worksheetzone/images-web/landing-create/";
    static HOST_THEME =
        // "https://storage.googleapis.com/worksheetzone/images-web/themes-halloween/v2/";
        // "https://storage.googleapis.com/worksheetzone/images-web/thanksgiving_theme/";
        "https://storage.googleapis.com/worksheetzone/images-web/christmas_theme/";
    static HOME_BROWSING_PAGEid = "989898";
    static SEARCH_PAGEid = "search";
    static VERSION = "1.0.0";
    static HTTP_REQUEST_TIMEOUT = 300000;
    static HTTP_REQUEST_SUCCESS = 200;
    static TIME_REVALIDATE = 60 * 60; // 60 * 60;
    static BASE_URL_API = "https://api2.worksheetzone.org/api";
    static COOKIE_KEY_DEFAULT_UID = "defaultUid";
    static COOKIE_KEY_WORKSHEET_TOKEN = "worksheet_key_token";
    static DEFAULT_AVATAR = "/images/other_button/default_avatar.svg";
    static LOCAL_STORAGE_KEY_TEACHER_FORM = "is-fill-teacher-form";
    static PAGEid = "103291858875415";
    static MY_COLLECTIONS_TAB = 1;
    static DOWNLOAD_WORKSHEETS_TAB = 2;
    static SUBMIT_WORKSHEETS_TAB = 3;
    static WORKSHEETS_BY_THIS_AUTHOR_TAB = 4;

    static PUZZLE_CELL_PX = 34.5;

    static DOMAIN = "https://worksheetzone.org/";
    static CMS_DOMAIN = "https://cms.worksheetzone.org/wp-json/v1";
    // static MATH_CATEGORYid = "617925b8ab49524248b5f04a";
    // static ENGLISH_CATEGORYid = "617925b8ab49524248b5ed83";
    static ENGLISH_CATEGORYid = "64644d740ce27d1a72db44e8";
    static ANSWER_PUZZLEid = "puzzle_grid_svgid";
    static PREFIX_WORD_LIST_HEADER = "word-list-header";
    static PREFIX_HINT_DIRECTIONid = "hint-direction";
    static PREFIX_WORD_FOUND = "word-found";
    static PREFIX_WORD_LIST = "word-list";
    static PREFIX_LINEid = "done-";
    static PREFIX_DIRECTIONid = "rect";

    static TAB_ACTIVE = {
        WORKSHEET_INFO: "WORKSHEET_INFO",
        WORD_SEARCH: Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
        FILL_IN_BLANK: Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE,
        HANDWRITING: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
        WORD_SCRAMBLE: Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE,
        SENTENCE_SCRAMBLE: Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE,
        MULTIPLE_CHOICES: Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE,
        NAME_TRACING: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
        CROSSWORD: Config.ACTIVITY_TYPE.CROSSWORD.TYPE,
        DIVISION: Config.ACTIVITY_TYPE.DIVISION.TYPE,
        MULTIPLICATION: Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE,
        ADDITION: Config.ACTIVITY_TYPE.ADDITION.TYPE,
        SUBTRACTION: Config.ACTIVITY_TYPE.SUBTRACTION.TYPE,
        MORE_ACTIONS: "MORE_ACTIONS",
    };
    // static SCIENCE_CATEGORYid = "62c7a827a16aa3371fc79253";
    // static ENGLISH_LANGUAGE_ART_CATEGORYid = "617925b8ab49524248b5ef65";
    // static HANDWRITING_CATEGORYid = "62eb3635432c563f64ca5f03";
    // static SOCIAL_SCIENCE_CATEGORYid = "62f5a64bed7fe33536bd09f9";
    // static FINE_ART_CATEGORYid = "62f5a64bed7fe33536bd0a19";
    // static COLORING_PAGES_CATEGORYid = "6323cad7cf44760dce895a7f";
    // static BEHAVIOR_ACTIVITIES_CATEGORYid = "62f5a64aed7fe33536bd084b";
    // static OTHER_CATEGORYid = "6327df3b9e095ed459e956a5";
    // static CATEGORY_CHILDidS = [
    // ConstantsResource.MATH_CATEGORYid,
    // ConstantsResource.HANDWRITING_CATEGORYid,
    // ConstantsResource.ENGLISH_LANGUAGE_ART_CATEGORYid,
    // ConstantsResource.SCIENCE_CATEGORYid,
    // ConstantsResource.COLORING_PAGES_CATEGORYid,
    // ConstantsResource.OTHER_CATEGORYid,
    // ConstantsResource.BEHAVIOR_ACTIVITIES_CATEGORYid,
    // ];

    static WORKSHEET_TYPE = "worksheet_type";
    static LIMIT_PAGE_NUMBER = 500;
    static DEFAULT_OFFSET = 0;
    static LIMIT_WORKSHEET = 30;
    static LIMIT_WORKSHEET_MOBILE = 10;

    static LIMIT_COLLECTION = 5;
    static LIMIT_COLLECTION_MOBILE = 2;

    static USING_CREATE_WS = true;
    static NUMBER_DOWNLOADS = "nbudl";

    static CATEGORY_HOME_PAGE = "home";

    static CLICK_WS_SCORE = 1;
    static PLAY_WS_SCORE = 2;
    static SUBMIT_WS_SCORE = 5;
    static SHARE_WS_SCORE = 7;
    static DOWLOAD_WS_SCORE = 5;
    static TYPE_COLLECTION = "collection";
    static TYPE_CATEGORY = "category";
    static TYPE_FOLDER_COLLECTION = "folder-collection";
    static TYPE_CATEGORY_COLLECTION = "category-collection";
    static LOCAL_STORAGE_LIST_WS_PLAYING = "ws_playing_v2";
    static LOCAL_STORAGE_GA_CONFIG = "ga4-config";
    static LOCAL_STORAGE_COUNT_WS = "url-ws";
    static RECENT_WSidS = "recent-ws-ids";
    static LENGTH_RECENT_WSidS = 5;
    static LOCAL_STORAGE_IS_VERIFY_ACCOUNT = "1";
    static LOCAL_STORAGE_IS_DONE_SURVEY = "false";
    static LIMIT_GET_COLLECTION_NB = 30;
    static UP = "up";
    static NEXT = "next";
    static NEW_SITE_URL = "https://worksheetzone.app/";
    static KEY_HOME_API_VERSION = "home-api-version";
    static HOME_API_VERSION_1 = "1";
    static HOME_API_VERSION_2 = "2"; // with recommendation api
    static INTEREST_TOPIC_LIMIT = 3;

    static SAVE_TO_COLLECTION = "Save to Collection";
    static MOVE_WORKSHEET = "Move Worksheet";
    static DEFAULT_IS_SELECTED = false;
    static DEFAULT_MATCHINGid = -1;
    static DEFAULT_USER_ANSWER = "";
    static FILTER_STATE = "filter-state";
    static LOAD_MORE_WS = "load-more-ws";
    static LOAD_MORE_WS_YOU_MAY_LIKE = "load-more-ws-you-may-like";

    static URL_GOOGLE_ACCOUNT = "https://accounts.google.com/gsi/client";

    static PAGE_TYPE_HOME_BROWSING = 0;
    static PAGE_TYPE_CATEGORY = 1;
    static PAGE_TYPE_WORKSHEET = 2;
    static PAGE_TYPE_COLLECTION = 3;
    static PAGE_TYPE_SEARCH = 4;
    static PAGE_TYPE_INTERACTIVE = 5;
    static PAGE_TYPE_MANAGE_ACCOUNT = 6;
    static PAGE_TYPE_REFER_A_FRIEND = 7;
    static PAGE_TYPE_CLASSROOM_MANAGEMENT = 8;
    static PAGE_TYPE_WORKSHEET_MAKER = 9;

    static EXIT_DOWNLOADED = "exitDownloaded";
    static WORKSHEET_DOWNLOAD = "worksheetDownload";
    static PUZZLE_TITLE_MAX_LENGTH = 65;
    static TAB_PROJECT = {
        YOUR_EDITS: "YOUR_EDITS",
        COLLECTION: "COLLECTION",
        DOWNLOADS: "DOWNLOADS",
    };
    static PROJECT_WORKSHEET_TOOLV2 = ["categoryIds", "description"];

    static PROJECT_COLLECTION_TOOLV2 = [
        "categoryIds",
        "introductionDescription",
    ];
    static PROJECT_DATA_COLLECTION_TOOLV2 = {
        worksheet: this.PROJECT_WORKSHEET_TOOLV2,
        collection: this.PROJECT_COLLECTION_TOOLV2,
    };
    static IS_PLAY_WORKSHEET = "#practice";
    static SIZE_PRINT_WIDTH = 1000;
    static SIZE_PRINT = {
        WIDTH: ConstantsResource.SIZE_PRINT_WIDTH,
        HEIGHT: (ConstantsResource.SIZE_PRINT_WIDTH * 297) / 210,
    };

    static DEFAULT_AVT_USER =
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18648.png";
    // static CLAIM_ACTION = "claim_action";
}
