const UncheckedBox = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M12.4 17.5H7.6C5.64931 17.5 4.41391 17.1068 3.65355 16.3464C2.8932 15.5861 2.5 14.3507 2.5 12.4V7.6C2.5 5.64931 2.8932 4.41391 3.65355 3.65355C4.41391 2.8932 5.64931 2.5 7.6 2.5H12.4C14.3507 2.5 15.5861 2.8932 16.3464 3.65355C17.1068 4.41391 17.5 5.64931 17.5 7.6V12.4C17.5 14.3507 17.1068 15.5861 16.3464 16.3464C15.5861 17.1068 14.3507 17.5 12.4 17.5Z"
                stroke="#212121"
                strokeOpacity="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UncheckedBox;
