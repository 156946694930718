import { useState } from "react";
import CloseIconSvg from "../../../icons/close-icon";
import ButtonDownload from "./button-save";
import ClaimCoinsContent from "./claim-coins";
import DownloadWithAnswerKey from "./down-load-ans-key";
import PriceDetail from "./price-detail";
import "./style.scss";
import UserInfoClaimCoins from "./user-info";
const PopupClaimCoins = ({
    worksheet,
    worksheets,
    onlyDownload,
    wssWorkbook,
    notResetWorksheets,
    closePopupDownload,
}: {
    worksheet?: any;
    wssWorkbook?: any[];
    worksheets?: any[];
    onlyDownload?: boolean;
    notResetWorksheets?: boolean;
    closePopupDownload;
}) => {
    const [downloadWithAnswer, setDownloadWithAnswer] = useState(false);
    return (
        <div className="claim-coins">
            <div
                className="claim-coins-close"
                onClick={() => {
                    closePopupDownload();
                }}
            >
                <CloseIconSvg />
            </div>
            <UserInfoClaimCoins />
            <PriceDetail worksheet={worksheet} worksheets={worksheets} />
            <DownloadWithAnswerKey
                downloadWithAnswer={downloadWithAnswer}
                setDownloadWithAnswer={setDownloadWithAnswer}
                worksheet={worksheet}
            />

            <ButtonDownload
                worksheet={worksheet}
                worksheets={worksheets}
                onlyDownload={onlyDownload}
                downloadWithAnswer={downloadWithAnswer}
                notResetWorksheets={notResetWorksheets}
            />
            <ClaimCoinsContent
                worksheet={worksheet}
                worksheets={worksheets}
                wssWorkbook={wssWorkbook}
            />
        </div>
    );
};

export default PopupClaimCoins;
