import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ICollection } from "../../../../../shared/models/collection";
import threeDots from "../../../../assets/images/icon/three-dots.png";
import { selectGridItemAction } from "../../../../redux/action/resource.action";
import { getImageSource } from "../../../../utils";
import useComponentVisible from "../../../common/click-out-side";
import PopupMoreActionTool from "../popup-more-action-tool";
import "./style.scss";
const MoreActionTool = ({
    collection,
    addGaEventMoreEvent,
}: {
    collection: ICollection;
    addGaEventMoreEvent?: Function;
}) => {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const refPopup = useRef(null);
    const { isComponentVisible } = useComponentVisible(refPopup);

    useEffect(() => {
        if (isComponentVisible) {
            setShowPopup(false);
        }
    }, [isComponentVisible]);
    return (
        <>
            <div ref={refPopup}>
                <div className="more-action-tool">
                    <div>
                        <img
                            src={getImageSource(threeDots)}
                            alt="three-dots"
                            className="three-dots"
                            onClick={() => {
                                setShowPopup(true);
                                dispatch(
                                    selectGridItemAction({
                                        collection: collection,
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
                {showPopup ? (
                    <>
                        <PopupMoreActionTool
                            addGaEventMoreEvent={addGaEventMoreEvent}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default MoreActionTool;
