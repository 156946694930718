import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../../shared/utils/config";
import IconResetActive from "../../../../assets/images/icon/icon-reset-active.png";
import IconReset from "../../../../assets/images/icon/icon-reset.svg";
import imageDisableMobileIcon from "../../../../assets/images/reset_disable_mobile.svg";
import imageMobileIcon from "../../../../assets/images/reset_mobile.svg";
import { resetSvgAction } from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { getImageSource } from "../../../../utils";
import { TypeOfPlacementTooltip } from "../../../common/library/render-tooltip";
import ToolTipCustomize from "../../../common/library/tool-tip";
import PopupCommon from "../../../common/pop-up";
import "./style.scss";

const ResetPaintPuzzle = () => {
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const dispatch = useDispatch();
    let imgIcon = IconReset;
    const [open, setOpen] = useState(false);
    // const [imgIcon, setImgIcon] = useState(IconReset);
    const inputWords = useSelector(
        (state: ResourceAppState) => state.wordSearchState.inputWords
    );
    const isFinish = useSelector(
        (state: ResourceAppState) => state.gameResourceState.isFinish
    );
    let findInputWord = inputWords.find(
        (item) => item.status != Config.STATUS_PUZZLE.INIT
    );
    imgIcon = findInputWord && !isFinish ? IconResetActive : IconReset;

    return (
        <>
            <ToolTipCustomize
                title="Reset The Puzzle"
                placement={TypeOfPlacementTooltip.right}
                classes={{ tooltip: "custom-tooltip" }}
                arrow={false}
            >
                <div
                    className="reset-puzzle"
                    onClick={() => {
                        findInputWord && !isFinish && setOpen(true);
                        // dispatch(resetSvgAction({ resetSvg: true }));
                    }}
                    // onMouseEnter={() => {
                    //     setImgIcon(IconResetActive);
                    // }}
                    // onMouseLeave={() => {
                    //     setImgIcon(IconReset);
                    // }}
                >
                    <div
                        className="reset-puzzle-light"
                        style={{
                            backgroundImage: `url(${getImageSource(
                                isTablet
                                    ? findInputWord
                                        ? imageMobileIcon
                                        : imageDisableMobileIcon
                                    : imgIcon
                            )})`,
                        }}
                    >
                        {/* <img src={getImageSource(imgIcon)} alt="light icon" /> */}
                    </div>
                </div>
            </ToolTipCustomize>
            <>
                {open && (
                    <>
                        <PopupCommon
                            classNameDialog="popup-confirm-reset-puzzle"
                            classNameTitle="popup-confirm-reset-puzzle__title"
                            open={open}
                            setOpen={(value) => {
                                setOpen(value);
                            }}
                            style={{ zIndex: 1300 }}
                            title="Confirm"
                            contentText="Are you sure you want to reset everything and restart?"
                            isResetButton={true}
                            renderAction={() => {
                                return (
                                    <>
                                        {/* <div className="popup-confirm-reset-puzzle__btn-action"> */}
                                        <p
                                            className="btn-action cancel"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </p>
                                        <p
                                            className="btn-action reset"
                                            onClick={() => {
                                                dispatch(
                                                    resetSvgAction({
                                                        resetSvg: true,
                                                    })
                                                );
                                                setOpen(false);
                                            }}
                                        >
                                            Reset
                                        </p>
                                        {/* </div> */}
                                    </>
                                );
                            }}
                        />
                    </>
                )}
            </>
        </>
    );
};

export default ResetPaintPuzzle;
