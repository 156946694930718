import { useEffect } from "react";
import { useAppSelector } from "../../../redux/hook";
import { IQuestionActivity } from "../../../shared/models/questionActivity";
import { scrollToItemId } from "./util";

const ListenSelectText = ({
    questions,
    questionId,
    setQuestionId,
}: {
    questions: IQuestionActivity[];
    questionId: string;
    setQuestionId: Function;
}) => {
    const resourceItemEditing = useAppSelector(
        (state) => state.leftDataState?.resourceItemEditing
    );
    useEffect(() => {
        if (resourceItemEditing) {
            const questionIdByItemSelect = questions.find((q) =>
                q.nameTracingAttribute.resourceItemIds.includes(
                    resourceItemEditing
                )
            )?.id;

            if (
                questionIdByItemSelect &&
                questionIdByItemSelect !== questionId
            ) {
                if (scrollToItemId(questionIdByItemSelect)) {
                    setQuestionId(questionIdByItemSelect);
                }
            }
        }
    }, [resourceItemEditing]);
    return null;
};

export default ListenSelectText;
