import { useDispatch, useSelector } from "react-redux";
import { Collection } from "../../../shared/models/collection";
import { Worksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import { addEventGa } from "../../lib/ga";
import { updateAssignmentAction } from "../../redux/action/assign-digitally.action";
import {
    addActionEventDownloadAction,
    deleteCollectionResourceAction,
    selectActionGridItemAction,
    selectGridItemAction,
    updateActionsGaSuccessAction,
} from "../../redux/action/resource.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import { groupWsFc } from "../../utils";
import DeleteWorksheets from "../collection/collection-detail/worksheet/action/delete-worksheets";
import { ExtractParamType } from "../common/dynamic-component";
import PopupCommon from "../common/pop-up";
import PopupClaimCoins from "../common/pop-up/popup-claim-coins";
import AssignDigitalPopup from "../main-popup-save-wse/content-worksheet/assign-digitally-popup";
import PopupEditCollectionResource from "./edit";
import "./index.scss";
import InviteFriend from "./invite-friend";
import NewSaveToCollection from "./save-to-collection";

const ActionGrid = ({ customClick }: { customClick?: Function }) => {
    const dispatch = useDispatch();
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );

    const profileResourceState = useSelector(
        (state: ResourceAppState) => state.profileResourceState.userInfo
    );
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const haveActionPopup = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.haveActionPopup
    );
    const worksheet = useSelector(
        (state: any) => state.wordSearchState?.worksheet
    );
    const worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.worksheets
    );
    const afterAction = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.afterAction
    );
    const quotaDownload = useSelector(
        (state: ResourceAppState) =>
            state.quotaResourceUserState?.data?.quotaDownload
    );
    const eventsGa = actionGridItem?.eventsGaAfterSuccess;
    const isAuthor = profileResourceState?.id === userInfo?.id;
    const openAction = (value: boolean, doneAction = false) => {
        customClick && customClick();
        if (!value) {
            dispatch(updateAssignmentAction());
            if (eventsGa?.length > 0) {
                dispatch(updateActionsGaSuccessAction({ eventsGa: [] }));
            }
            if (actionGridItem.action) {
                dispatch(
                    selectActionGridItemAction({
                        action: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }
            if (
                actionGridItem?.worksheet?.id ||
                actionGridItem?.collection?.id ||
                actionGridItem?.worksheets?.length
            ) {
                dispatch(
                    selectGridItemAction({
                        collection: new Collection(),
                        worksheet: new Worksheet(),
                        toCollection: true,
                        worksheets:
                            doneAction ||
                            afterAction == Config.ACTION_GRID.CLEAR_WSS
                                ? []
                                : actionGridItem?.worksheets,
                        afterAction: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }
        }
    };
    let title = "Save to Collection";
    switch (actionGridItem?.action) {
        case Config.ACTION_GRID.EDIT:
            title = "Edit this collection";
            break;
        case (Config.ACTION_GRID.MOVE_WORKSHEET,
        Config.ACTION_GRID.MOVE_COLLECTION):
            title = "Move to collection";
            break;
        case (Config.ACTION_GRID.DELETE_WS,
        Config.ACTION_GRID.DELETE_COLLECTION):
            title = "Confirm Delete";
            break;
        default:
            break;
    }

    if (
        [
            Config.ACTION_GRID.DELETE_WS,
            Config.ACTION_GRID.DELETE_COLLECTION,
        ].includes(actionGridItem?.action)
    ) {
        const renderActionPopUp = () => {
            return (
                <div className="action action-del popup-action">
                    <div
                        className="cancel"
                        onClick={(e) => {
                            e.stopPropagation();
                            openAction(false);
                        }}
                    >
                        Cancel
                    </div>
                    <div
                        className="delete"
                        onClick={(e) => {
                            e.stopPropagation();
                            let worksheet = actionGridItem?.worksheet;
                            let collection = actionGridItem?.collection;
                            dispatch(
                                deleteCollectionResourceAction({
                                    worksheet,
                                    collection,
                                })
                            );
                            // openAction(false);
                            // setOpen(false);
                        }}
                    >
                        Delete
                    </div>
                </div>
            );
        };
        return (
            <PopupCommon
                open={true}
                setOpen={(value) => openAction(value)}
                title={"Confirm Delete"}
                contentText={
                    isAuthor
                        ? "Are you sure that you want to permanently delete the selected item?"
                        : "Are you sure that you want to permanently remove the selected item from this collection?"
                }
                renderAction={renderActionPopUp}
                classNameDialog="popup-confirm-delete"
            />
        );
    }
    if (actionGridItem.action == Config.ACTION_GRID.EDIT) {
        return (
            <PopupEditCollectionResource
                setShowPopupEditCollection={(value, doneAction) =>
                    openAction(value, doneAction)
                }
                showPopupEditCollection={true}
                autoComplete={true}
                collection={actionGridItem?.collection}
                titlePopup={title}
                worksheet={actionGridItem?.worksheet}
            />
        );
    }
    if (actionGridItem.action == Config.ACTION_GRID.CREATE_COLLECTION) {
        return (
            <>
                <PopupEditCollectionResource
                    showPopupEditCollection={
                        actionGridItem.action ==
                        Config.ACTION_GRID.CREATE_COLLECTION
                    }
                    setShowPopupEditCollection={(value) => openAction(value)}
                    titlePopup="New Collection"
                    createWs={true}
                    collection={actionGridItem?.toCollection?.data}
                    worksheet={actionGridItem?.worksheet}
                    setShowPopup={(value) => openAction(value)}
                />
            </>
        );
    }
    if (actionGridItem.action == Config.ACTION_GRID.OPEN_ASSIGN_DIGITALLY) {
        return (
            <>
                <AssignDigitalPopup
                    setShowPopup={(value) => openAction(value)}
                    showPopup={true}
                    worksheet={worksheet}
                />
            </>
        );
    }
    if (actionGridItem.action == Config.ACTION_GRID.DELETE_WSS) {
        return (
            <>
                <DeleteWorksheets />
            </>
        );
    }

    if (actionGridItem.action == Config.ACTION_GRID.INVITE_FRIEND) {
        return (
            <>
                {/* <PopupClaimCoins /> */}
                <PopupCommon
                    fullScreen={true}
                    open={true}
                    setOpen={(value) => {
                        dispatch(
                            selectActionGridItemAction({
                                action: Config.ACTION_GRID.DEFAULT,
                            })
                        );
                    }}
                    renderContent={() => {
                        return (
                            <>
                                <InviteFriend />
                            </>
                        );
                    }}
                    title=""
                    classNameDialog="popup-download-invite-friend"
                />
            </>
        );
    }
    if (
        actionGridItem.action == Config.ACTION_GRID.DOWNLOAD_WSS ||
        actionGridItem.action == Config.ACTION_GRID.PRINT_WSS
    ) {
        let groupWs = groupWsFc({ worksheets: worksheets });
        const numberWs = Object.keys(groupWs).length;
        const closePopupDownload = () => {
            if (!haveActionPopup) {
                dispatch(
                    addActionEventDownloadAction({
                        haveActionPopup: false,
                    })
                );
                let params = {
                    action: "close_popup_download",
                    params: {},
                };
                addEventGa(params);
                addEventGa({
                    action:
                        quotaDownload >= numberWs
                            ? "close_popup_download_enough_coin"
                            : "close_popup_download_not_enough_coin",
                });
            }
            dispatch(
                selectActionGridItemAction({
                    action: Config.ACTION_GRID.DEFAULT,
                })
            );
        };
        return (
            <>
                <PopupCommon
                    fullScreen={true}
                    classNameDialog="popup-download-multi-ws"
                    open={
                        actionGridItem.action ==
                            Config.ACTION_GRID.DOWNLOAD_WSS ||
                        actionGridItem.action == Config.ACTION_GRID.PRINT_WSS
                    }
                    setOpen={closePopupDownload}
                    classNameTitle="popup-download-multi-ws-title"
                    title={""}
                    renderContent={() => {
                        return (
                            <>
                                <div className="header-popup">
                                    <div></div>
                                    <p className="title">
                                        <>
                                            {numberWs +
                                                " items selected" +
                                                (actionGridItem.action ==
                                                Config.ACTION_GRID.DOWNLOAD_WSS
                                                    ? ""
                                                    : " to prints")}
                                        </>
                                    </p>
                                    <div></div>
                                </div>
                                <PopupClaimCoins
                                    worksheets={worksheets}
                                    onlyDownload={
                                        actionGridItem.action ==
                                        Config.ACTION_GRID.DOWNLOAD_WSS
                                    }
                                    closePopupDownload={closePopupDownload}
                                />
                            </>
                        );
                    }}
                />
            </>
        );
    }
    return (
        <div>
            <NewSaveToCollection
                setShowPopup={(showValue, doneAction = false) =>
                    openAction(showValue, doneAction)
                }
                showPopup={true}
                titlePopup={title}
            />
        </div>
    );
};

export type ActionGridType = ExtractParamType<typeof ActionGrid>;
export default ActionGrid;
