import IQuotaResourceUser, {
    IParamsQuotaResourceUser,
} from "../../../shared/models/quotaResourceuser";

export enum QuotaResourceTypes {
    GET_QUOTA_RESOURCE = "GET_QUOTA_RESOURCE",
    UPDATE_QUOTA_RESOURCE = "UPDATE_QUOTA_RESOURCE",
    UPDATE_QUOTA_DOWNLOAD = "UPDATE_QUOTA_DOWNLOAD",
    UPDATE_QUOTA_DOWNLOAD_REDUCER = "UPDATE_QUOTA_DOWNLOAD_REDUCER",
    INCREASE_QUOTA_RESOURCE = "INCREASE_QUOTA_RESOURCE",
    UPDATE_INCREASE_QUOTA_RESOURCE = "UPDATE_INCREASE_QUOTA_RESOURCE",
    UPDATE_LOADING_GET_QUOTA = "UPDATE_LOADING_GET_QUOTA",
    UPDATE_TIME_NEXT_DOWNLOAD = "UPDATE_TIME_NEXT_DOWNLOAD",
    CHANGE_UPDATE_QUOTA_SUCCESS = "CHANGE_UPDATE_QUOTA_SUCCESS",

    UPDATE_QUOTA_USER = "UPDATE_QUOTA_USER",
    UPDATE_QUOTA_USER_SUCCESS = "UPDATE_QUOTA_USER_SUCCESS",
    UPDATE_USER_SHARE_PIN = "UPDATE_USER_SHARE_PIN",
}

export interface QuotaResourceUserAction {
    type: QuotaResourceTypes;
    loading?: boolean;
    userId?: string;
    remainQuotaDownLoad?: number;
    numberDownload?: number;
    quotaResourceUser?: IQuotaResourceUser;
    timeNextDownload?: Date;
    times?: number;
    updateSuccess?: boolean;
    params?: IParamsQuotaResourceUser;
    links?: string[];
}

export const updateUserSharePinAction = ({ links }: { links }) => {
    return {
        type: QuotaResourceTypes.UPDATE_USER_SHARE_PIN,
        links,
    };
};

export const updateQuotaUserSuccessAction = ({
    params,
}: {
    params: IParamsQuotaResourceUser;
}) => {
    return {
        type: QuotaResourceTypes.UPDATE_QUOTA_USER_SUCCESS,
        params,
    };
};

export const updateQuotaUserAction = ({
    params,
}: {
    params: IParamsQuotaResourceUser;
}) => {
    return {
        type: QuotaResourceTypes.UPDATE_QUOTA_USER,
        params,
    };
};

export const updateLoadingQuotaAction = ({ loading }: { loading: boolean }) => {
    return {
        type: QuotaResourceTypes.UPDATE_LOADING_GET_QUOTA,
        loading,
    };
};

export const updateQuotaDownloadAction = (numberDownload: number) => {
    return {
        type: QuotaResourceTypes.UPDATE_QUOTA_DOWNLOAD,
        numberDownload,
    };
};

export const updateQuotaDownloadReducerAction = (
    remainQuotaDownLoad: number
) => {
    return {
        type: QuotaResourceTypes.UPDATE_QUOTA_DOWNLOAD_REDUCER,
        remainQuotaDownLoad,
    };
};

export const getQuotaResourceByUserIdAction = (userId: string) => {
    return {
        type: QuotaResourceTypes.GET_QUOTA_RESOURCE,
        userId,
    };
};

export const changeUpdateSuccessAction = (updateSuccess: boolean) => {
    return {
        type: QuotaResourceTypes.CHANGE_UPDATE_QUOTA_SUCCESS,
        updateSuccess,
    };
};

export const updateQuotaResourceAction = (
    quotaResourceUser: IQuotaResourceUser,
    userId: string
) => {
    return {
        type: QuotaResourceTypes.UPDATE_QUOTA_RESOURCE,
        quotaResourceUser,
        userId,
    };
};
export const increaseQuotaResourceAction = (userId: string) => {
    return {
        type: QuotaResourceTypes.INCREASE_QUOTA_RESOURCE,
        userId,
    };
};
export const updateIncreaseQuotaResourceAction = (userId: string) => {
    return {
        type: QuotaResourceTypes.UPDATE_INCREASE_QUOTA_RESOURCE,
        userId,
    };
};

export const upateNextTimeDownloadByUserIdAction = (
    userId: string,
    timeNextDownload: Date,
    times: number
) => {
    return {
        type: QuotaResourceTypes.UPDATE_TIME_NEXT_DOWNLOAD,
        userId,
        timeNextDownload,
        times,
    };
};
