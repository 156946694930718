import React from "react";

const SearchIcon = () => {
    return (
        <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2684 15.0437L10.3013 11.0766L11.0312 10.3467L14.9983 14.3137L14.2684 15.0437Z"
                fill="#868687"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 12.2061C9.8995 12.2061 12.25 9.85555 12.25 6.95605C12.25 4.05656 9.8995 1.70605 7 1.70605C4.10051 1.70605 1.75 4.05656 1.75 6.95605C1.75 9.85555 4.10051 12.2061 7 12.2061ZM7 12.9561C10.3137 12.9561 13 10.2698 13 6.95605C13 3.64235 10.3137 0.956055 7 0.956055C3.68629 0.956055 1 3.64235 1 6.95605C1 10.2698 3.68629 12.9561 7 12.9561Z"
                fill="#868687"
            />
            <path
                d="M12.15 6.95605C12.15 9.80032 9.84427 12.1061 7 12.1061C4.15573 12.1061 1.85 9.80032 1.85 6.95605C1.85 4.11179 4.15573 1.80605 7 1.80605C9.84427 1.80605 12.15 4.11179 12.15 6.95605ZM7 13.0561C10.3689 13.0561 13.1 10.325 13.1 6.95605C13.1 3.58712 10.3689 0.856055 7 0.856055C3.63106 0.856055 0.9 3.58712 0.9 6.95605C0.9 10.325 3.63106 13.0561 7 13.0561Z"
                stroke="#212121"
                strokeOpacity="0.24"
                strokeWidth="0.2"
            />
        </svg>
    );
};

export default SearchIcon;
