export default class ClassNameDontDelete {
    static TRACKING_DONT_DEL = "tracking-dont-delete";
    static PREVIEW_PAGE_IMAGE_WORKSHEET =
        "tracking-dont-delete-preview-worksheet-image";
    static PREVIEW_PAGE_BUTTON_PRACTICE_NOW =
        "tracking-dont-delete-play-now-button";
    static OPEN_SAVE_TO_COLLECTION_POPUP =
        "tracking-dont-delete-open-save-popup";
    static PREVIEW_PAGE_BUTTON_UNSAVE =
        "tracking-dont-delete-unsave-button-preview-page";
    static SAVE_TO_COLLECTION = "tracking-dont-delete-save-to-collection";
    static PREVIEW_PAGE_BUTTON_SHARE = "tracking-dont-delete-open-share-popup";
    static PREVIEW_PAGE_BUTTON_CUSTOMIZE =
        "tracking-dont-delete-customize-worksheet-button";
    static PREVIEW_PAGE_BUTTON_FOLLOW_AUTHOR =
        "tracking-dont-delete-follow-author-button";
    static PREVIEW_PAGE_BUTTON_UNFOLLOW_AUTHOR =
        "tracking-dont-delete-unfollow-author-button";
    static PREVIEW_PAGE_BUTTON_DOWNLOAD =
        "tracking-dont-delete-download-button";
    static PREVIEW_PAGE_BUTTON_DELETE = "tracking-dont-delete-remove-button";
    static PREVIEW_PAGE_BUTTON_MORE_ACTION =
        "tracking-dont-delete-show-menu-button";
    static PREVIEW_PAGE_BUTTON_BACK = "tracking-dont-delete-back-button";

    static SEARCH_TAG_RECENT_SEARCH = "tracking-dont-delete-recent-search-tag";
    static SEARCH_SUGGESTED_TAG = "tracking-dont-delete-suggested-tag";
    static SHARE_TO_FACEBOOK = "tracking-dont-delete-share-to-facebook";
    static SHARE_TO_PINTEREST = "tracking-dont-delete-share-to-pinterest";
    static SHARE_TO_TWITTER = "tracking-dont-delete-share-to-twitter";
    static SHARE_TO_GOOGLE_CLASS_ROOM =
        "tracking-dont-delete-share-to-google-class-room";
    static SHARE_TO_WHATSAPP = "tracking-dont-delete-share-to-whatsapp";
    static SHARE_TO_MICROSOFT_TEAMS =
        "tracking-dont-delete-share-to-microsoft-teams";
    static BUTTON_YOUTUBE_CREATE_WS =
        "tracking-dont-delete-button-youtube-create-ws";
    static BUTTON_YOUTUBE_MAKE_INTERACTIVE_WS =
        "tracking-dont-delete-button-youtube-make-interactive-ws";
    static BUTTON_SHOW_ANSWER_WS = "tracking-dont-delete-button-show-answer-ws";
    static BUTTON_HIDE_ANSWER_WS = "tracking-dont-delete-button-hide-answer-ws";
    static BUTTON_OPEN_POPUP_SEND_RESULT_WS =
        "tracking-dont-delete-button-open-popup-send-result-ws";
    static BUTTON_SEND_RESULT_WS = "tracking-dont-delete-button-send-result-ws";
}
