import { useEffect, useSyncExternalStore } from "react";
import { useAppDispatch } from "../../../redux/hook";
import { IPageWorksheetNew } from "../../../shared/models/pageWorksheetNew";
import { IResourceItemNew } from "../../../shared/models/resourceItemNew";
import groupElementStore from "../../../syncExternalStore/groupElementStore";

function ListenGroup({
    pageWorksheet,
    currentElement,
    pageIndex,
    rotate,
}: {
    currentElement: IResourceItemNew | null;
    pageWorksheet: IPageWorksheetNew;
    pageIndex: number;
    rotate: number;
}) {
    const dispatch = useAppDispatch();
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );

    useEffect(() => {
        if (
            currentElement &&
            currentElement?.id === groupElementState.resourceGroupTmp?.id
        ) {
            let check = false;
            const resourceItem = groupElementState.resourceGroupTmp;
            for (let key of Object.keys(currentElement)) {
                if (resourceItem[key] !== currentElement[key]) {
                    check = true;
                    break;
                }
            }
            if (check || rotate !== resourceItem.rotate) {
                groupElementStore.resizeElements({
                    x: currentElement.x,
                    y: currentElement.y,
                    width: currentElement.width,
                    height: currentElement.height,
                    rotate: rotate ?? currentElement.rotate,
                });
            }
        }
    }, [
        currentElement?.x,
        currentElement?.y,
        currentElement?.width,
        currentElement?.height,
        currentElement?.rotate,
        rotate,
    ]);

    useEffect(() => {
        if (currentElement && currentElement.isGroup) {
            groupElementStore.updateCurrentElement(currentElement);
        }
    }, [currentElement]);
    return null;
}

export default ListenGroup;
