import { createSlice } from "@reduxjs/toolkit";
import CreateWSConfig from "../../utils/create-ws-config";

type initialStateType = {
    listRecentTemplate: any[];
};

const initialState: initialStateType = {
    listRecentTemplate: [],
};

export const recentUseSlice = createSlice({
    name: "recentUse",
    initialState,
    reducers: {
        addTemplateToPersist: (state, action) => {
            const newItem = action.payload;
            if (newItem?.id) {
                const existingItem = state.listRecentTemplate.find(
                    (item) => item.id === newItem?.id
                );

                let newData = state.listRecentTemplate;
                if (existingItem) {
                    newData = state.listRecentTemplate.filter(
                        (item) => item.id !== newItem.id
                    );
                }
                newData.unshift(newItem);
                if (newData.length > CreateWSConfig.LIMIT_RESOURCE_RECENT) {
                    newData.pop();
                }
                state.listRecentTemplate = newData;
            }
        },
    },
});

export const { addTemplateToPersist } = recentUseSlice.actions;
