import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Assignment,
    IAssignment,
} from "../../../../../shared/models/assignment";
import { Worksheet } from "../../../../../shared/models/worksheet";
import Config from "../../../../../shared/utils/config";
import CloseButtonIcon from "../../../../assets/images/icon-assign-digitally/close_24px.png";
import SaveAfterdButtonIcon from "../../../../assets/images/icon-assign-digitally/saveAfter.png";
import SaveBeforeButtonIcon from "../../../../assets/images/icon-assign-digitally/saveBefore.png";
import {
    updateAssignmentAction,
    updateClassesWhenCheckedAction,
    updateClassesWhenNotCheckedAction,
} from "../../../../redux/action/assign-digitally.action";
import { selectGridItemAction } from "../../../../redux/action/resource.action";
import { updateAlertSnackBarAction } from "../../../../redux/action/snackbar.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { updateAssignment } from "../../../../redux/repositories/class";
import { getImageSource } from "../../../../utils";
import LoadingComponent from "../../../common/loading/loading-component";
import CreateIconCollection from "../../../icons/folder/create-icon";
import HintIconSvg from "../../../icons/hint";
import PlusIcon from "../../../icons/plus-icon";
import UnSelectItem from "../../../icons/select/select-worksheet";
import SelectItem from "../../../icons/select/unselect-worksheet";
import "./index.scss";
import PopupCreateClass from "./popup-create-class";

const AssignDigitalPopup = ({
    showPopup,
    setShowPopup,
    worksheet,
}: {
    showPopup: boolean;
    setShowPopup: Function;
    worksheet: any;
}) => {
    // const worksheetInfo = useSelector(
    //     (state: any) => state.wordSearchState?.worksheet
    // );

    const dispatch = useDispatch();
    // dispatch(getAssignmentAction(worksheetInfo.id));
    const isMobile = useMediaQuery("(max-width:768px)");
    const closePopup = (value) => {
        setShowPopup(value);
        dispatch(selectGridItemAction({ worksheet: new Worksheet() }));
        dispatch(updateAssignmentAction());
    };

    if (isMobile) {
        return (
            <Drawer
                className="popup-assign-digital"
                anchor={"bottom"}
                onClose={(e) => {
                    closePopup(false);
                }}
                open={showPopup}
                variant="temporary"
                disableEnforceFocus
            >
                <div className="close-button" onClick={() => closePopup(false)}>
                    <HintIconSvg />
                </div>
                <ContentPopupAssign
                    showPopup={showPopup}
                    setShowPopup={(value) => {
                        if (!value) {
                            closePopup(value);
                        } else {
                            setShowPopup(value);
                        }
                    }}
                    workSheet={worksheet}
                />
            </Drawer>
        );
    }

    return (
        <Dialog
            className="popup-assign-digital"
            onClose={(e: any) => {
                e.stopPropagation();
                closePopup(false);
                dispatch(updateAssignmentAction());
            }}
            open={showPopup}
            disableEnforceFocus
        >
            <ContentPopupAssign
                showPopup={showPopup}
                setShowPopup={(value) => {
                    if (!value) {
                        closePopup(value);
                    } else {
                        setShowPopup(value);
                    }
                }}
                workSheet={worksheet}
            />
        </Dialog>
    );
};

export const ContentPopupAssign = ({
    showPopup,
    setShowPopup,
    workSheet,
}: {
    showPopup: boolean;
    setShowPopup: Function;
    workSheet?: any;
}) => {
    const refClasses = useRef(null);
    const dispatch = useDispatch();
    const [showPopupCreate, setShowPopupCreate] = useState(false);
    const isMobile = useMediaQuery("(max-width:768px)");

    const classes = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState?.classes
    );
    const assignmentsDB = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState?.assignments
    );

    let saveAssignment = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState?.saveAssignments
    );

    let action = useSelector(
        (state: ResourceAppState) => state.resourceState?.actionGridItem?.action
    );
    let loading = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState?.loading
    );
    const currentActivity = useSelector(
        (state: any) => state?.createWorksheetState?.currentActivity?.type
    );
    const isSaveAndAssign = action == Config.ACTION_GRID.SAVE_RESOURCE_ASSIGN;
    classes.forEach((classInfo, index) => {
        const assignments: IAssignment = assignmentsDB.find(
            (as) => as.classId == classInfo.id
        );
        classInfo.assignments = assignments ? [assignments] : null;
        return classInfo;
    });

    const handleSave = async () => {
        let result = await Promise.all(
            saveAssignment.map((assignment) => {
                return updateAssignment(assignment);
            })
        );
        if (
            result?.length == saveAssignment?.length &&
            result?.length > 0 &&
            saveAssignment?.length > 0
        ) {
            const nbCreate = saveAssignment.filter(
                (as) =>
                    as.status == Config.STATUS_PUBLIC &&
                    as.worksheet == workSheet?.id
            );
            const nbDelete = saveAssignment.filter(
                (as) => as.status == Config.STATUS_DELETED
            );

            if (
                (nbCreate.length == 1 || nbCreate?.length == 0) &&
                nbDelete.length == 0
            ) {
                const nameClass = classes.find(
                    (cls) =>
                        cls.id ==
                        (nbCreate[0]?.classId ?? saveAssignment[0]?.classId)
                );

                dispatch(
                    updateAlertSnackBarAction({
                        classAssignment: nameClass,
                        severity: "success",
                        collectionId: nameClass.id,
                    })
                );
            } else if (nbCreate.length == 0 && nbDelete.length == 1) {
                const nameClass = classes.find(
                    (cls) => cls.id == nbDelete[0].classId
                );

                // let message =
                //     "Removed from " +
                //     nameClass.name +
                //     " class";
                dispatch(
                    updateAlertSnackBarAction({
                        collectionId: nbDelete[0].classId,
                        classAssignment: nameClass,
                        message: "",
                        severity: "error",
                    })
                );
            } else {
                let success = true;
                if (nbCreate.length > 1 && nbDelete.length == 0) {
                    success = true;
                } else if (nbCreate.length == 0 && nbDelete.length > 1) {
                    success = false;
                }
                let message = success
                    ? "Saved To All Selected Classes"
                    : "Removed From All Selected Classes";
                dispatch(
                    updateAlertSnackBarAction({
                        collectionId:
                            nbDelete[0]?.classId ??
                            nbCreate[0]?.classId ??
                            saveAssignment[0]?.classId,
                        message,
                        severity: success ? "success" : "error",
                    })
                );
            }
            dispatch(updateAssignmentAction());

            setShowPopup(false);
        }
    };
    useEffect(() => {
        if (refClasses?.current && typeof window != undefined) {
            if (classes?.length == 0 && isSaveAndAssign) {
                refClasses.current.style.height = "auto";
            } else {
                if (window.innerWidth < 768) {
                    refClasses.current.style.height = "310px";
                } else {
                    refClasses.current.style.height = "410px";
                }
            }
        }
    }, [classes, refClasses]);
    return (
        <div className="popup-assign-digital-content xxx">
            {!isSaveAndAssign ? (
                <div className="header">
                    <p>Add To Classroom</p>
                    {!isMobile && (
                        <div className="close-icon">
                            <img
                                // src="/images/icon/close_24px.png"
                                src={getImageSource(CloseButtonIcon)}
                                alt="close"
                                onClick={() => setShowPopup(false)}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
            <div className="content" ref={refClasses}>
                <>
                    {!isSaveAndAssign ? (
                        <div className="title-content">Your group</div>
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    setShowPopupCreate(true);
                                }}
                                className="add-new-class"
                            >
                                <PlusIcon />
                                Add New Class
                            </div>
                        </>
                    )}
                    {loading ? (
                        <>
                            <LoadingComponent />
                        </>
                    ) : (
                        <div>
                            {classes?.map((classInfo, index) => (
                                <RowClass
                                    classInfo={classInfo}
                                    workSheet={workSheet}
                                    index={index}
                                    key={index}
                                />
                            ))}
                        </div>
                    )}
                </>
            </div>
            {action != Config.ACTION_GRID.SAVE_RESOURCE_ASSIGN ? (
                <div className="footer">
                    <div
                        className="new-group"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowPopupCreate(true);
                        }}
                    >
                        <div className="icon">
                            {/* <img
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPopupCreate(true);
                                }}
                                // src="/images/icon/new-group.png"
                                src={getImageSource(NewGroupButtonIcon)}
                                alt="newGroup"
                            /> */}
                            <CreateIconCollection />
                        </div>
                        <div className="label-new-group">New group</div>
                    </div>
                    <div className="save-button ">
                        {saveAssignment.length ? (
                            <img
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSave();
                                    // showMessage();
                                }}
                                // src="/images/icon/saveAfter.png"
                                src={getImageSource(SaveAfterdButtonIcon)}
                                alt="save"
                            />
                        ) : (
                            // </div>
                            <img
                                // src="/images/icon/saveBefore.png"
                                src={getImageSource(SaveBeforeButtonIcon)}
                                alt="save"
                            />
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
            <PopupCreateClass
                showPopupCreate={showPopupCreate}
                worksheet={workSheet}
                setShowPopupCreate={(value) => setShowPopupCreate(value)}
                setShowPopup={(value) => setShowPopup(value)}
            />
        </div>
    );
};
const RowClass = ({ classInfo, index, workSheet }) => {
    const dispatch = useDispatch();
    const [firstTime, setFirstTime] = useState(true);
    const classes = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.classes
    );
    const assignmentsDB = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.assignments
    );
    let userIdState = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );

    let worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.worksheets
    );
    let worksheetState = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.worksheet
    );

    classes.forEach((classInfo, index) => {
        const assignments: IAssignment = assignmentsDB.find(
            (as) => as.classId == classInfo.id
        );
        classInfo.assignments = assignments ? [assignments] : null;
        return classInfo;
    });
    let saved = classInfo.assignments?.length > 0;

    const handleChange = (id) => {
        setFirstTime(false);
        const classInfo = classes?.find((cls) => cls.id == id);
        let wss = workSheet?.id ? workSheet?.worksheets : worksheets;

        let ws = workSheet?.id
            ? workSheet
            : wss?.length
            ? wss[0]
            : worksheetState?.id
            ? worksheetState
            : null;

        if (!classInfo.assignments) {
            const userId = userIdState;
            const classId = id;
            if (wss?.length > 1) {
                let newAssignments = wss.map((ws, i) => {
                    let newAssignment = new Assignment({
                        userId,
                        classId,
                        worksheet: ws.id,
                        title: ws.title,
                        status: Config.STATUS_PUBLIC,
                    });
                    dispatch(
                        updateClassesWhenCheckedAction(classes, newAssignment)
                    );
                    return newAssignment;
                });

                classInfo.assignments = newAssignments;
            } else if (ws) {
                let assignment = new Assignment({
                    userId,
                    classId,
                    worksheet: ws?.id,
                    title: ws?.title,
                    status: Config.STATUS_PUBLIC,
                });
                //let result = await updateAssignment(assignment);
                classInfo.assignments = [assignment];

                dispatch(updateClassesWhenCheckedAction(classes, assignment));
            }
        } else {
            if (wss?.length > 1) {
                let newAssignments = wss.map((ws, i) => {
                    const assignments = assignmentsDB.find(
                        (as) =>
                            as.classId == id &&
                            as.worksheet == ws?.id &&
                            as.userId == userIdState
                    );
                    if (assignments) {
                        assignments.status = Config.STATUS_DELETED;
                        dispatch(
                            updateClassesWhenNotCheckedAction(
                                classes,
                                assignments
                            )
                        );
                    }
                    return assignments;
                });

                classInfo.assignments = newAssignments;
            } else {
                classInfo.assignments = null;
                const assignments = assignmentsDB.find(
                    (as) =>
                        as.classId == id &&
                        as.worksheet == ws?.id &&
                        as.userId == userIdState
                );
                if (assignments) {
                    assignments.status = Config.STATUS_DELETED;
                    //let result = await updateAssignment(assignments);
                    dispatch(
                        updateClassesWhenNotCheckedAction(classes, assignments)
                    );
                }
            }
        }
    };

    return (
        <div
            key={index}
            className="checkbox-container"
            onClick={() => handleChange(classInfo.id)}
        >
            {/* <div>
                {classInfo.assignments?.length ? (
                    <SelectWorksheet active={true} firstTime={firstTime} />
                ) : (
                    <UnselectWorksheet active={true} firstTime={firstTime} />
                )}
            </div> */}
            <div
                className={
                    classInfo.assignments?.length
                        ? firstTime
                            ? "first-v2"
                            : "v2"
                        : firstTime
                        ? "first-v1"
                        : "v1"
                }
            >
                {saved ? (
                    <>
                        <>
                            <SelectItem />
                        </>
                    </>
                ) : (
                    <>
                        <UnSelectItem />
                    </>
                )}
            </div>
            <div className="name">{classInfo.name}</div>
        </div>
    );
};

export default memo(AssignDigitalPopup);
