import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ThemeLayout from "../../common/theme";
import { IWorksheet } from "../../../../shared/models/worksheet";
import { stringifyAndParse } from "../../../../shared/utils";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import { getRatioFromUrlPreview } from "../../../utils";
// import ListGamePrint from "../list-game-print";
const ListGamePrint = React.lazy(
    () => import("../list-game-print")
);
const ImagesGame = ({
    worksheet,
    images,
    setGetBase64,
    printWithAns,
    setDoneLoadImg,
    printImg,
    fullWidth,
}: {
    worksheet: IWorksheet;
    images: string[];
    setGetBase64?: Function;
    printWithAns?: boolean;
    setDoneLoadImg?: Function;
    printImg?: boolean;
    fullWidth?: boolean;
}) => {
    return (
        <>
            {images?.length ? (
                images.map((image, index) => {
                    if (!image) {
                        return <React.Fragment key={index}></React.Fragment>;
                    }
                    if (printImg) {
                        return (
                            <DetailImage
                                image={image}
                                index={index}
                                fullWidth={fullWidth}
                            />
                        );
                    }
                    return (
                        <DetailImageAns
                            image={image}
                            index={index}
                            images={images}
                            setGetBase64={setGetBase64}
                            worksheet={worksheet}
                            printWithAns={printWithAns}
                            setDoneLoadImg={setDoneLoadImg}
                        />
                    );
                })
            ) : worksheet.game.pdfs?.length ? (
                <></>
            ) : null}
        </>
    );
};

const DetailImage = ({
    image,
    index,
    fullWidth,
}: {
    image: string;
    index: number;
    fullWidth: boolean;
}) => {
    return (
        <>
            <img
                width={fullWidth ? "99%" : "90%"}
                style={{
                    objectFit: "cover",
                    margin: "auto",
                }}
                className={"image-game-worksheet"}
                alt={"image"}
                src={image}
            />
        </>
    );
};
const DetailImageAns = ({
    image,
    index,
    setGetBase64,
    worksheet,
    images,
    printWithAns,
    setDoneLoadImg,
    printImg,
}: {
    image: string;
    index: number;
    setGetBase64?: Function;
    worksheet: IWorksheet;
    images: string[];
    printWithAns?: boolean;
    setDoneLoadImg?: Function;
    printImg?: boolean;
}) => {
    const themes = useSelector(
        (state: ResourceAppState) => state.gameResourceState?.themes
    );
    const indexSelected = themes?.indexSelected;
    let listTheme = themes?.listTheme;
    const imgRef: any = useRef();
    const isTablet = useMediaQuery("(max-width:1024px)");
    let [ws, setWs] = useState(stringifyAndParse(worksheet));
    let [ratio, setRatio] = useState(-1);
    let [transition, setTransition] = useState(0);
    let [szImage, setSzImage] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        let timer = setTimeout(() => {
            let printDoc = document.querySelector("#print-id");
            let width = printDoc.getBoundingClientRect().width;
            let imagesRatio = images[index].slice(
                0,
                images[index].indexOf("?t=")
            );
            let { widthImage, heightImage } =
                getRatioFromUrlPreview(imagesRatio);
            setSzImage({
                width: widthImage,
                height: heightImage,
            });
            if (index > 0) {
                let transition = 0;
                for (let i = 0; i < index; i++) {
                    transition += (width * heightImage) / widthImage;
                }
                transition = -transition;
                setTransition(transition);
                ws.game.answers = ws.game.answers?.map((ans) => {
                    ans.transition = transition;
                    return ans;
                });
                setWs(ws);
            }
            if (index == images?.length - 1) {
                setGetBase64(true);
            }

            setRatio(width / widthImage);
        }, 0);
        return () => clearTimeout(timer);
    }, [index]);
    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            if (index == images?.length - 1) {
                setDoneLoadImg(true);
            }
        };

        img.onerror = () => {
            // Xử lý lỗi nếu ảnh không thể tải được
        };
    }, []);
    return (
        <>
            <div
                key={index}
                style={{
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "center",
                    overflow: "hidden",
                    width: isTablet ? "fit-content" : szImage.width * ratio,
                    height: szImage.height * ratio,
                }}
                id={"img-print_" + index}
                className="img"
            >
                <img
                    ref={imgRef}
                    width={"100%"}
                    style={{
                        objectFit: "cover",
                        margin: "auto",
                    }}
                    className={"image-game-worksheet-print"}
                    alt={"image"}
                    src={image}
                />
                <>
                    {listTheme?.length && indexSelected >= 0 ? (
                        // <img
                        //     ref={themeRef}
                        //     src={listTheme[indexSelected]}
                        //     alt="theme"
                        //     style={{
                        //         position: "absolute",
                        //     }}
                        // />
                        <ThemeLayout
                            imgRef={imgRef}
                            style={{
                                zIndex: 10,
                                position: "absolute",
                                left: 0,
                            }}
                            isMainPrint={true}
                        />
                    ) : (
                        <></>
                    )}
                </>

                <>
                    {ws?.game?.answers?.length > 0 &&
                        ratio > 0 &&
                        printWithAns && (
                            <>
                                <Suspense fallback={<div></div>}>
                                    <ListGamePrint
                                        worksheet={ws}
                                        ratio={ratio}
                                        elementImage={"img-print_" + index}
                                        transition={transition}
                                        pageIndex={index}
                                    />
                                </Suspense>
                            </>
                        )}
                </>
            </div>
        </>
    );
};
export default ImagesGame;
