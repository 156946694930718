class APIConfig {
    static LOGIN = "/login";
    static LOGIN_WITH_GOOGLE = "/login-with-google";
    static GET_USER_FROM_TOKEN = "/get-user-from-token";
    static UPLOAD_FILE_PDF = "/upload-file-pdf";
    static UPLOAD_FILE_RESOURCE = "/upload-file-resource";
    static GET_RESOURCE_BY_TYPE = "/get-resource-by-type";
    static CREATE_NEW_WS_FROM_TOOL_V2 = "/create-new-ws-from-tool-v2";
    static GET_WS_FROM_TOOL_V2 = "/get-ws-from-tool-v2";
    static SEARCH_RESOURCE_BY_KEYWORD = "/search-resource-by-keyword";
    static GET_USER_CREATE_WS = "/get-user-create-ws";
    static GET_RESOURCE_BY_WORKSHEETid = "/get-resource-by-worksheet-id";
    static SEARCH_TEMPLATE_BY_KEYWORD = "/search-template-by-keyword";
    static GET_TEMPLATE = "/get-template";
}
export default APIConfig;
