import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { deleteCollectionAction } from "../../../redux/actions/collection.action";
// import DeleteIcon from "../../common/icon/delete-icon";
// import PopupCommon from "../../common/pop-up/index";
import { ICollection } from "../../../../../shared/models/collection";
import Config from "../../../../../shared/utils/config";
import {
    deleteCollectionResourceAction,
    selectActionGridItemAction,
} from "../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import DeleteIcon from "../../../icons/delete-icon";
import "./style.scss";
const DeleteAction = ({
    collection,
    setShowActionDelete,
    setShowAction,
}: {
    collection: ICollection;
    setShowActionDelete?: Function;
    setShowAction?: Function;
}) => {
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isMobile = useMediaQuery("(max-width:768px)");
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState(<DeleteIcon />);
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    let worksheet = actionGridItem?.worksheet;

    const renderActionPopUp = () => {
        return (
            <div className="action action-del ">
                <div
                    className="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                    }}
                >
                    Cancel
                </div>
                <div
                    className="delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                            deleteCollectionResourceAction({
                                worksheet,
                                collection,
                            })
                        );

                        setOpen(false);
                        if (setShowAction) {
                            setShowAction(false);
                        }
                        setShowActionDelete && setShowActionDelete(false);
                    }}
                >
                    Delete
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                className="delete"
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                    dispatch(
                        selectActionGridItemAction({
                            action: Config.ACTION_GRID.DELETE_COLLECTION,
                        })
                    );
                }}
            >
                {isTablet ? (
                    <>
                        <DeleteIcon isActive={true} />
                        Delete
                    </>
                ) : (
                    icon
                )}
            </div>

            {/* <PopupCommon
                open={open}
                setOpen={(value: any) => {
                    setOpen(value);
                    // !value && setShowAction && setShowAction(false);
                }}
                title={"Confirm Delete"}
                contentText={
                    "Are you sure that you want to permanently delete the selected item?"
                }
                renderAction={renderActionPopUp}
                classNameDialog="popup-confirm delete-action"
            /> */}
        </>
    );
};

export default DeleteAction;
