import React from "react";

const HintIconSvg = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg
            width="73"
            height="20"
            viewBox="0 0 73 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="8.5" y="8" width="56" height="4" rx="2" fill="white" />
        </svg>
    );
};

export default HintIconSvg;
