export default class Notification {
    static NOTIFICATION_SUBMIT_TO_TEACHER_TYPE = 0;
    static NOTIFICATION_BONUS_DOWNLOADS_TYPE = 1;
    static NOTIFICATION_BONUS_DOWNLOADS_TYPE_SHARE_PIN = 2;
    static NOTIFICATION_BONUS_DOWNLOADS_TYPE_DAILY_CHECKIN = 3;
    static NOTIFICATION_BONUS_DOWNLOADS_TYPE_INVITE_FRIEND = 4;
    static NOTIFICATION_BONUS_DOWNLOADS_TYPE_ACCEPT_INVITE_FRIEND = 5;
    static STATUS_NOT_READ = 0;
    static STATUS_NOT_READ_DETAIL = 1;
    static STATUS_READ_DETAIL = 2;
}
