import { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import './index.scss';
import { useDispatch } from 'react-redux';
import PromptInteractiveBlock from '../prompt-interactive-block';
import { useAppSelector } from '../../../../../redux/hook';
import { setTemplate } from '../../../../../redux/reducers/createWorksheet';
import { getZoneLabel } from '../../../../../utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddZonePopup from '../add-zone-popup';

const ZonesPopup = (props) => {
    const {
        onClose
    } = props;
    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);

    const [uniqueFields, setUniqueFields] = useState(Array.isArray(props.uniqueFields) ? props.uniqueFields : []);
    const [addZoneModal, setAddZoneModal] = useState(false);
    const [fieldEditting, setFieldEditting] = useState(null);
    const [error, setError] = useState(null)

    useEffect(() => {
        if (uniqueFields.length > 0) return;
        let jsonFields = [];
        resourceItems.forEach(item => {
            if (item.fieldName) {
                const { fieldName, fieldDescription, parentField } = item;
                const key = fieldName;

                if (!jsonFields[key]) {
                    jsonFields[key] = {
                        fieldName: key,
                        nbFields: 1,
                        fieldDescription: fieldDescription,
                        parentField: parentField
                    };
                } else {
                    jsonFields[key].nbFields++;
                }
            }
        });
        setUniqueFields(Object.values(jsonFields))
    }, [resourceItems]);
    
    const onSaveZone = (data) => {
        const checkFieldNameExist = uniqueFields.find(item => item.fieldName === data.fieldName)
        if (checkFieldNameExist) {
            setUniqueFields(prev => prev.map(item => item.fieldName === data.fieldName ? ({...item, ...data}) : item))
        } else {
            setUniqueFields(prev => ([
                data,
                ...prev
            ]))
        }
        setAddZoneModal(false);
        setFieldEditting(null)
    }

    const onDeleteField = (fieldName) => {
        if (window.confirm('Are you sure to remove this field')) {
            setUniqueFields(prev => prev.filter((item, index) => item.fieldName !== fieldName).map(item => item.parentField === fieldName ? {...item, parentField: ''} : item))
        }
    }

    const checkNbFields = () => {
        let jsonPairFieldAndParent = {}
        const randomStr = Date.now();
        for (const item of uniqueFields) {
            const { fieldName, parentField, nbFields } = item;
            if (parentField) {
                if (jsonPairFieldAndParent.hasOwnProperty(`${fieldName}-${randomStr}-${parentField}`) || jsonPairFieldAndParent.hasOwnProperty(`${parentField}-${randomStr}-${fieldName}`)) {
                    setError("The field name and parent field are looping infinitely")
                    return false;
                }
                jsonPairFieldAndParent[`${fieldName}-${randomStr}-${parentField}`] = true;
                const parentItem = uniqueFields.find((parent) => parent.fieldName === parentField);

                if (parentItem && nbFields % parentItem.nbFields !== 0) {
                    setError("The child's number of fields must be divisible by the parent's number of fields")
                    return false;
                }
            }       
        }
        return true;
    }

    useEffect(() => {
        setError(null)
    }, [uniqueFields])
    return (
        <>
            <Modal
                show={true}
                onHide={() => {
                    if (checkNbFields()) {
                        onClose(uniqueFields)
                    }
                }}
                contentLabel="Zones"
                className={`zones-popup ${(addZoneModal || !!fieldEditting) ? 'hide' : ''}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Zones Management
                        <Button className="zones-popup-add-btn" onClick={() => setAddZoneModal(true)}>
                            Add zone
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Form.Text className="text-danger mb-3">{error}</Form.Text>}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Field name</th>
                                <th>Number of fields</th>
                                <th>Field description</th>
                                <th>Parent field name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                uniqueFields.map(item => {
                                    return (
                                    <tr>
                                        <td>{item.fieldName}</td>
                                        <td>{item.nbFields}</td>
                                        <td>{item.fieldDescription}</td>
                                        <td>{item.parentField}</td>
                                        <td>
                                            <div className='zones-popup-action'>
                                                <Button variant="success" onClick={() => setFieldEditting(item)}>
                                                    <EditIcon />
                                                </Button>
                                                <Button variant="danger" onClick={() => onDeleteField(item.fieldName)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            {addZoneModal && <AddZonePopup 
                onClose={() => setAddZoneModal(false)}
                uniqueFields={uniqueFields}
                onSave={onSaveZone}
            />}
            {!!fieldEditting && <AddZonePopup
                onClose={() => setFieldEditting(null)}
                init={fieldEditting}
                uniqueFields={uniqueFields}
                onSave={onSaveZone}
            />}
        </>
    )
}

export default ZonesPopup;