export default class CreateWSConfig {
    static WIDTH = (210 * 96) / 25.4;
    static HEIGHT = (297 * 96) / 25.4; //1122,5
    static WIDTH_STR = (210 * 96) / 25.4;
    static HEIGHT_STR = (297 * 96) / 25.4;
    static SAVE_WS_STATUS_NONE = 0;
    static SAVE_WS_STATUS_START = 1;
    static SAVE_WS_STATUS_FINISH = 2;
    static FONT_SIZE_DEFAULT = 14;
    static COLOR_DEFAULT = "#212121";
    // static CREATE_WORKSHEET_RATIO = (210 * 96) / 25.4 / 1000;
    static CREATE_WORKSHEET_RATIO = 1;
    static RIGHT_PANEL_WIDTH = 120;
    static RIGHT_PANEL_HEIGHT = 174;
    static FUNCTION_CONTENT = 0;
    static FONT_FAMILY_CONTENT = 1;
    static TEXT_EFFECT_CONTENT = 2;

    // static MODE_CREATED = 0;
    // static MODE_PLAY_DEMO = 1;
    // static MODE_POPUP_SAVE = 2;
    static FONT_SIZES = [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72, 96, 100,
        110, 120, 150, 200,
    ];
    static ZOOM_VALUE = [45, 50, 60, 75, 100, 125, 200];
    static RECT_TYPE = 0;
    static CIRCLE_TYPE = 1;
    static LINE_TYPE = 2;
    static GAME_NAMES = [
        "Input",
        "Select",
        "Check-box",
        "Drop down",
        "Drag",
        "Drop",
        "Matching",
        // "Listen",
        // "Speak",
        "Wordsearch",
    ];
    static GAME_IMAGES_ICON = [
        "/images/create-worksheet/games/input_game.svg",
        "/images/create-worksheet/games/select_game.svg",
        "/images/create-worksheet/games/stick_game.svg",
        "/images/create-worksheet/games/drop_down_game.svg",
        "/images/create-worksheet/games/drag_game.svg",
        "/images/create-worksheet/games/drop_game.svg",
        "/images/create-worksheet/games/connector_game.svg",
        // "/images/create-worksheet/games/listen_game.svg",
        // "/images/create-worksheet/games/speak_game.svg",
        "/images/create-worksheet/games/wordsearch_game.svg",
    ];
    static CREATE_WORKSHEET_MODE_EDIT = 0;
    static CREATE_WORKSHEET_MODE_MAKE_INTERACTIVE = 1;
    static CREATE_WORKSHEET_MODE_PLAY_DEMO = 2;
    static FONT_FAMILY_HANDWRITING = ["TeachingPrintDotted", "Verdana"];
    static OPACITY_FONT_FAMILY = "OPACITY_FONT_FAMILY";


    static EFFECT_NONEid = 0;
    static EFFECT_SHADOWid = 1;
    static EFFECT_LIFTid = 2;
    static EFFECT_HOLLOWid = 3;
    static EFFECT_SPLICEid = 4;
    static EFFECT_ECHOid = 5;
    static EFFECT_GLITCHid = 6;
    static EFFECT_NEONid = 7;
    static EFFECT_BACKGROUNDid = 7;

    static EFFECT_DATA = [
        {
            id: CreateWSConfig.EFFECT_NONEid,
            name: "None",
            image: "/images/create-worksheet/text-effect/none_effect.webp",
            attributeSettings: [],
        },
        {
            id: CreateWSConfig.EFFECT_SHADOWid,
            name: "Shadow",
            image: "/images/create-worksheet/text-effect/shadow_effect.webp",
            attributeSettings: [
                { name: "Offset", min: 0, max: 100, initValue: 50 },
                { name: "Direction", min: -180, max: 180, initValue: -45 },
                { name: "Blur", min: 0, max: 100, initValue: 0 },
                { name: "Transparency", min: 0, max: 100, initValue: 40 },
                {
                    name: "Color",
                    initValue: "#808080",
                },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_LIFTid,
            name: "Lift",
            image: "/images/create-worksheet/text-effect/lift_effect.webp",
            attributeSettings: [
                { name: "Intensity", min: 0, max: 100, initValue: 50 },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_HOLLOWid,
            name: "Hollow",
            image: "/images/create-worksheet/text-effect/hollow_effect.webp",
            attributeSettings: [
                { name: "Thickness", min: 0, max: 100, initValue: 50 },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_SPLICEid,
            name: "Splice",
            image: "/images/create-worksheet/text-effect/splice_effect.webp",
            attributeSettings: [
                { name: "Thickness", min: 0, max: 100, initValue: 50 },
                { name: "Offset", min: 0, max: 100, initValue: 50 },
                { name: "Direction", min: -180, max: 180, initValue: -45 },
                {
                    name: "Color",
                    initValue: "#808080",
                },
            ],
        },
        // {
        //     id: CreateWSConfig.EFFECT_ECHOid,
        //     name: "Echo",
        //     image: "/images/create-worksheet/text-effect/echo_effect.webp",
        //     attributeSettings: [
        //         { name: "Offset", min: 0, max: 100, initValue: 50 },
        //         { name: "Direction", min: -180, max: 180, initValue: -45 },
        //         {
        //             name: "Color",
        //             initValue: "#808080",
        //         },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_GLITCHid,
        //     name: "Glitch",
        //     image: "/images/create-worksheet/text-effect/glitch_effect.webp",
        //     attributeSettings: [
        //         { name: "Offset", min: 0, max: 100, initValue: 30 },
        //         { name: "Direction", min: -180, max: 180, initValue: 90 },
        //         // {
        //         //     name: "Color",
        //         //     initValue: "#808080",
        //         // },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_NEONid,
        //     name: "Neon",
        //     image: "/images/create-worksheet/text-effect/neon_effect.webp",
        //     attributeSettings: [
        //         { name: "Intensity", min: 0, max: 100, initValue: 50 },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_BACKGROUNDid,
        //     name: "Background",
        //     image: "/images/create-worksheet/text-effect/background_effect.webp",
        //     attributeSettings: [
        //         { name: "Roundness", min: 0, max: 100, initValue: 50 },
        //         { name: "Spread", min: 0, max: 100, initValue: 50 },
        //         { name: "Transparency", min: 0, max: 100, initValue: 100 },
        //         {
        //             name: "Color",
        //             initValue: "#808080",
        //         },
        //     ],
        // },
    ];

    static A4_SVG_CONTENT =
        '<svg width="595" height="842" viewBox="0 0 595 842" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="595" height="842" fill="#FFFFFF"/></svg>';

    static SPEAK_LANGUAGE_LIST = [
        { name: "Arabic (Algeria)", code: "ar-DZ", id: 0 },
        { name: "Arabic (Bahrain)", code: "ar-BH", id: 1 },
        { name: "Arabic (Egypt)", code: "ar-EG", id: 2 },
        { name: "Arabic (Iraq)", code: "ar-IQ", id: 3 },
        { name: "Arabic (Israel)", code: "ar-IL", id: 4 },
        { name: "Arabic (Jordan)", code: "ar-JO", id: 5 },
        { name: "Arabic (Kuwait)", code: "ar-KW", id: 6 },
        { name: "Arabic (Lebanon)", code: "ar-LB", id: 7 },
        { name: "Arabic (Mauritania)", code: "ar-MR", id: 8 },
        { name: "Arabic (Morocco)", code: "ar-MA", id: 9 },
        { name: "Arabic (Oman)", code: "ar-OM", id: 10 },
        { name: "Arabic (Qatar)", code: "ar-QA", id: 11 },
        { name: "Arabic (Saudi Arabia)", code: "ar-SA", id: 12 },
        { name: "Arabic (State of Palestine)", code: "ar-PS", id: 13 },
        { name: "Arabic (Tunisia)", code: "ar-TN", id: 14 },
        { name: "Arabic (UAE)", code: "ar-AE", id: 15 },
        { name: "Arabic (Yemen)", code: "ar-YE", id: 16 },
        { name: "Bengali (Bangladesh)", code: "bn-BD", id: 17 },
        { name: "Bulgarian (Bulgaria)", code: "bg-BG", id: 18 },
        { name: "Czech (Czech Republic)", code: "cs-CZ", id: 19 },
        { name: "Danish (Denmark)", code: "da-DK", id: 20 },
        { name: "Dutch (Netherlands)", code: "nl-NL", id: 21 },
        { name: "English (Australia)", code: "en-AU", id: 22 },
        { name: "English (India)", code: "en-IN", id: 23 },
        { name: "English (Singapore)", code: "en-SG", id: 24 },
        { name: "English (UK)", code: "en-GB", id: 25 },
        { name: "English (US)", code: "en-US", id: 26 },
        { name: "Finnish (Finland)", code: "fi-FI", id: 27 },
        { name: "French (Canada)", code: "fr-CA", id: 28 },
        { name: "French (France)", code: "fr-FR", id: 29 },
        { name: "German (Germany)", code: "de-DE", id: 30 },
        { name: "Hindi (India)", code: "hi-IN", id: 31 },
        { name: "Indonesian (Indonesia)", code: "id-ID", id: 32 },
        { name: "isiXhosa (South Africa)", code: "xh-ZA", id: 33 },
        { name: "Italian (Italy)", code: "it-IT", id: 34 },
        { name: "Japanese (Japan)", code: "ja-JP", id: 35 },
        { name: "Kannada (India)", code: "kn-IN", id: 36 },
        { name: "Khmer (Cambodia)", code: "km-KH", id: 37 },
        { name: "Kinyarwanda (Rwanda)", code: "rw-RW", id: 38 },
        { name: "Korean (South Korea)", code: "ko-KR", id: 39 },
        { name: "Macedonian (North Macedonia)", code: "mk-MK", id: 40 },
        { name: "Malayalam (India)", code: "ml-IN", id: 41 },
        { name: "Marathi (India)", code: "mr-IN", id: 42 },
        { name: "Norwegian Bokmål (Norway)", code: "no-NO", id: 43 },
        { name: "Polish (Poland)", code: "pl-PL", id: 44 },
        { name: "Portuguese (Brazil)", code: "pt-BR", id: 45 },
        { name: "Portuguese (Portugal)", code: "pt-PT", id: 46 },
        { name: "Romanian (Romania)", code: "ro-RO", id: 47 },
        { name: "Russian (Russia)", code: "ru-RU", id: 48 },
        { name: "Setswana (South Africa)", code: "tn-latn-za", id: 49 },
        { name: "Southern Sotho (South Africa)", code: "st-ZA", id: 50 },
        { name: "Spanish (Spain)", code: "es-ES", id: 51 },
        { name: "Spanish (United States)", code: "es-US", id: 52 },
        { name: "Swati (South Africa)", code: "ss-latn-za", id: 53 },
        { name: "Swedish (Sweden)", code: "sv-SE", id: 54 },
        { name: "Tamil (India)", code: "ta-IN", id: 55 },
        { name: "Telugu (India)", code: "te-IN", id: 56 },
        { name: "Thai (Thailand)", code: "th-TH", id: 57 },
        { name: "Tsonga (South Africa)", code: "ts-ZA", id: 58 },
        { name: "Turkish (Turkey)", code: "tr-TR", id: 59 },
        { name: "Ukrainian (Ukraine)", code: "uk-UA", id: 60 },
        { name: "Venda (South Africa)", code: "ve-ZA", id: 61 },
        { name: "Vietnamese (Vietnam)", code: "vi-VN", id: 62 },
    ];
}
