import {
    Collection,
    ICollection,
    WorksheetItem,
} from "../../../shared/models/collection";
import { IWorksheet } from "../../../shared/models/worksheet";
import { isResourceCollection, stringifyAndParse } from "../../../shared/utils";
import Config from "../../../shared/utils/config";
import { ResourceTypes } from "../action/resource.action";
import { UserTypes } from "../action/user.action";

export interface IResourceState {
    defaultCollections: ICollection[];
    collections: {
        data: ICollection[];
        pageNumber: number;
        numberCollections: number;
        loading: boolean;
    };
    collectionSelected: {
        data: ICollection;
        loading: boolean;
        finishLoadMore: boolean;
        pageNumber: number;
    };
    pageTypeResource: number;
    actionGridItem: {
        worksheet: IWorksheet;
        collection: ICollection;
        toCollection: {
            data: ICollection;
            loading: boolean;
            offset: number;
            limit: number;
            numberSubCollections: number;
            arrayCollection: ICollection[];
            arrayThumbnail: IWorksheet[];
            listCollection: ICollection[];
        };
        worksheets: IWorksheet[];
        action: string;
        previousAction: string;
        afterAction?: string;
        indexRemove?: number[];
        eventsGaAfterSuccess?: any[];
        haveActionPopup?: boolean;
    };

    alert: {
        status: boolean;
        code: number;
        content: string;
        collection: ICollection;
        typeAlert: string;
        thumbnail: string;
        message: string;
    };
    loading: boolean;
    loadingTabInfo: boolean;
}

const initialState: IResourceState = {
    defaultCollections: [],
    collections: {
        data: [],
        pageNumber: 0,
        numberCollections: 0,
        loading: false,
    },
    collectionSelected: {
        data: null,
        loading: false,
        finishLoadMore: false,
        pageNumber: 0,
    },
    pageTypeResource: Config.PAGE_TYPE.PAGE_DEFAULT,
    actionGridItem: {
        worksheet: null,
        collection: null,
        toCollection: {
            data: null,
            loading: false,
            offset: 0,
            limit: 0,
            numberSubCollections: 0,
            arrayCollection: [],
            arrayThumbnail: [],
            listCollection: [],
        },
        worksheets: [],
        action: Config.ACTION_GRID.DEFAULT,
        previousAction: Config.ACTION_GRID.DEFAULT,
        afterAction: Config.ACTION_GRID.DEFAULT,
        indexRemove: [],
        eventsGaAfterSuccess: [],
    },
    alert: {
        status: false,
        code: 0,
        content: "",
        collection: null,
        typeAlert: "",
        thumbnail: "",
        message: "",
    },
    loading: false,
    loadingTabInfo: false,
};

function resourceState(
    state: IResourceState = initialState,
    action: any
): IResourceState {
    switch (action.type) {
        case ResourceTypes.ADD_ACTION_EVENT_DOWNLOAD:
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    haveActionPopup:
                        action?.haveActionPopup ??
                        state?.actionGridItem.haveActionPopup,
                },
            };
        case ResourceTypes.UPDATE_ACTIONS_GA_AFTER_SUCCESS:
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    eventsGaAfterSuccess: action.eventsGa ?? [],
                },
            };
        case ResourceTypes.ADD_IMAGE_WORKSHEET_OF_COLLECTION_SUCCESS:
            let newWorksheets = state?.actionGridItem?.worksheets;
            newWorksheets.map((ws) => {
                if (ws.workbookId === action.collectionId) {
                    const wsEqual = action.worksheets.find(
                        (item) => item.thumbnail === ws.thumbnail
                    );
                    return (ws.game = wsEqual.game);
                }
            });
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    worksheets: newWorksheets,
                },
            };
        case ResourceTypes.SELECT_IMAGES_WORKSHEET:
            const indexRemove = state.actionGridItem.indexRemove;
            const findIndex =
                indexRemove.findIndex((i) => i === action.index) !== -1;
            let newArr = indexRemove;
            if (findIndex) {
                newArr = newArr.filter((i) => i != action.index);
            } else {
                newArr = [...newArr, action.index];
            }
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    indexRemove: newArr,
                },
            };
        case ResourceTypes.SELECT_ALL_IMAGES_WORKSHEET:
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    indexRemove: action.arrIndex ?? [],
                },
            };
        case ResourceTypes.GET_RESOURCE_DEFAULT_SUCCESS:
            return {
                ...state,
                defaultCollections: action?.defaultCollections?.length
                    ? action.defaultCollections
                    : [],
            };
        case ResourceTypes.GET_NUMBER_WS_RESOURCE_DEFAULT_SUCCESS:
            return {
                ...state,
                defaultCollections: action?.defaultCollections?.length
                    ? [...action.defaultCollections]
                    : [],
                loading: false,
            };
        case ResourceTypes.SELECT_COLLECTION_RESOURCE:
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: action.collection,
                    loading: action.loading,
                    finishLoadMore: false,
                    pageNumber: 0,
                },
                pageTypeResource: action.pageTypeResource,
            };
        case ResourceTypes.UPDATE_COLLECTION_RESOURCE:
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: action.collection,
                    loading: action.loading,
                    finishLoadMore: action?.finishLoadMore
                        ? action?.finishLoadMore
                        : state.collectionSelected.finishLoadMore,
                    pageNumber: action?.pageNumber
                        ? action?.pageNumber
                        : state.collectionSelected.pageNumber,
                },
            };
        case ResourceTypes.GET_LIST_COLLECTION_RESOURCE:
            return {
                ...state,
                collections: {
                    ...state.collections,
                    loading: true,
                },
            };
        case ResourceTypes.UPDATE_LIST_COLLECTION_POPUP_SUCCESS:
            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: [...action.collections],
                },
            };
        case ResourceTypes.UPDATE_LIST_COLLECTION_SUCCESS_RESOURCE:
            return {
                ...state,
                collections: {
                    ...state.collections,
                    //data: [...state.collections.data, ...action.collections]
                    data: action.collections,
                    numberCollections: action.numberCollections,
                    pageNumber: action.pageNumber,
                    loading: false,
                },
            };
        case ResourceTypes.SELECT_GRID_ITEM_RESOURCE:
            let collectionActionGrid = action?.collection;
            let worksheetActionGrid = action?.worksheet;
            let toCollectionData = action?.toCollection
                ? {
                      ...state.actionGridItem.toCollection,
                      data: null,
                      arrayCollection: [],
                      arrayThumbnail: [],
                      loading: false,
                      offset: 0,
                      limit: 0,
                      numberSubCollection: 0,
                      listCollection: [],
                  }
                : state.actionGridItem?.toCollection;
            let worksheets = action?.worksheets
                ? stringifyAndParse(action?.worksheets)
                : state?.actionGridItem?.worksheets;
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    collection: collectionActionGrid ?? null,
                    worksheet: worksheetActionGrid ?? null,
                    toCollection: toCollectionData,
                    worksheets: worksheets,
                    afterAction:
                        action?.afterAction ?? state.actionGridItem.afterAction,
                },
                loadingTabInfo: false,
            };
        case ResourceTypes.SELECT_ACTION_GRID_ITEM_RESOURCE:
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    action: action.action ?? Config.ACTION_GRID.DEFAULT,
                    worksheets: state?.actionGridItem?.worksheets ?? [],
                    toCollection:
                        action?.action == Config.ACTION_GRID.DEFAULT
                            ? {
                                  data: null,
                                  loading: false,
                                  offset: 0,
                                  limit: 0,
                                  numberSubCollections: 0,
                                  arrayCollection: [],
                                  arrayThumbnail: [],
                                  listCollection: [],
                              }
                            : state.actionGridItem.toCollection,
                },
            };
        case ResourceTypes.SELECT_COLLECTION_ITEM_RESOURCE:
            let listCollectionState = JSON.parse(
                JSON.stringify(
                    state.actionGridItem.toCollection.listCollection ?? []
                )
            );
            let stringifyCollection = JSON.parse(
                JSON.stringify(action.collection)
            );
            if (action?.listCollection) {
                listCollectionState = action.listCollection;
            } else {
                let findCollection = listCollectionState?.find(
                    (c) => c.id == stringifyCollection?.id
                );
                if (findCollection) {
                    listCollectionState = listCollectionState?.filter(
                        (c) => c.id != stringifyCollection?.id
                    );
                    if (action.isNextLevelCollection) {
                        listCollectionState.push({
                            ...stringifyCollection,
                            isChoose: false,
                        });
                    }
                } else {
                    if (!!action?.collection?.id) {
                        findCollection = listCollectionState?.find((c) => {
                            const selected = c?.isChoose;

                            return (
                                stringifyCollection?.parentIds.includes(
                                    c.id
                                ) && !selected
                            );
                        });
                        if (findCollection) {
                            listCollectionState = listCollectionState.filter(
                                (c) => {
                                    const selected = c?.isChoose;

                                    return (
                                        !stringifyCollection?.parentIds.includes(
                                            c.id
                                        ) && selected
                                    );
                                }
                            );
                        }
                        stringifyCollection.isChoose =
                            !action?.isNextLevelCollection ?? false;
                        listCollectionState.push(stringifyCollection);
                    } else {
                        // listCollectionState.pop();
                        // listCollectionState = listCollectionState?.filter(
                        //     (c) => c.id != action.isSelectCollection?.id
                        // );
                    }
                }
            }
            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: action.collectionsState,
                },
                actionGridItem: {
                    ...state.actionGridItem,
                    toCollection: {
                        ...state.actionGridItem.toCollection,
                        data: stringifyCollection,
                        arrayCollection: action?.arrayCollection,
                        arrayThumbnail: action?.arrayThumbnail,
                        numberSubCollections: action?.numberSubCollections ?? 0,
                        loading: false,
                        listCollection: listCollectionState,
                    },
                },
            };

        case ResourceTypes.EDIT_COLLECTION_RESOURCE_SUCCESS:
            let paramsEdit = action?.params;
            let editCollectionSelectedData = stringifyAndParse(
                state?.collectionSelected?.data
            );

            const findInCollection = (collections) => {
                collections = stringifyAndParse(collections);
                return collections?.map((c) => {
                    if (c?.id == paramsEdit?.collectionId) {
                        c.name =
                            paramsEdit?.name ??
                            editCollectionSelectedData?.name;
                        c.introductionDescription =
                            paramsEdit?.introductionDescription ??
                            editCollectionSelectedData?.introductionDescription;
                        c.sharingStatus =
                            paramsEdit?.sharingStatus ??
                            editCollectionSelectedData?.sharingStatus;

                        return new Collection(c);
                    } else {
                        let arrWs = [];
                        let arrC = [];
                        if (c?.worksheets?.length) {
                            c?.worksheets?.forEach((item) => {
                                if (
                                    item?.typeGrid ==
                                    Config.TYPE_GRID.TYPE_FOLDER_COLLECTION
                                ) {
                                    arrC.push(item);
                                } else {
                                    arrWs.push(item);
                                }
                            });
                        }
                        if (arrC?.length) {
                            arrC = findInCollection(arrC);
                        }

                        let wss = arrC.concat(arrWs);
                        c.worksheets = new Array(wss);
                        return c;
                    }
                });
            };
            let listCollection = [...state.collections?.data];
            let newListCollection = findInCollection(listCollection);
            let listWorksheet = state.collectionSelected?.data?.worksheets
                ?.length
                ? findInCollection(state.collectionSelected?.data?.worksheets)
                : [];
            const isCurrentCollection =
                paramsEdit?.collectionId == state.collectionSelected?.data?.id;
            let oldName = editCollectionSelectedData?.name;
            let oldIntroductionDescription =
                editCollectionSelectedData?.introductionDescription;
            let oldSharingStatus = editCollectionSelectedData?.sharingStatus;
            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: newListCollection,
                },
                collectionSelected: {
                    ...state.collectionSelected,
                    data: {
                        ...editCollectionSelectedData,
                        name: isCurrentCollection
                            ? paramsEdit?.name ?? oldName
                            : oldName,
                        introductionDescription: isCurrentCollection
                            ? paramsEdit?.name ?? oldIntroductionDescription
                            : oldIntroductionDescription,
                        sharingStatus: isCurrentCollection
                            ? paramsEdit?.name ?? oldSharingStatus
                            : oldSharingStatus,
                        worksheets: listWorksheet,
                    },
                },
            };

        case ResourceTypes.UPDATE_NUMBER_WORKSHEET_OF_COLLECTION:
            let { numberOfWs, collectionId } = action;
            let dataCollectionsUpdateNumberWs: any = JSON.stringify(
                state.collections?.data
            );
            dataCollectionsUpdateNumberWs = JSON.parse(
                dataCollectionsUpdateNumberWs
            );
            dataCollectionsUpdateNumberWs = dataCollectionsUpdateNumberWs.map(
                (c) => {
                    if (c.id == collectionId) {
                        c.numberOfWorksheets = !!c.numberOfWorksheets
                            ? c.numberOfWorksheets
                            : 0;
                        c.numberOfWorksheets += numberOfWs;
                    }
                    return c;
                }
            );
            let dataCollectionsDefaultUpdateNumberWs = state.collections?.data;
            dataCollectionsDefaultUpdateNumberWs =
                dataCollectionsDefaultUpdateNumberWs.map((c) => {
                    if (c.id == collectionId) {
                        c.numberOfWorksheets = !!c.numberOfWorksheets
                            ? c.numberOfWorksheets
                            : 0;
                        c.numberOfWorksheets += numberOfWs;
                    }
                    return c;
                });
            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: dataCollectionsUpdateNumberWs,
                },
            };
        case ResourceTypes.REMOVE_WORKSHEET_OF_COLLECTION:
            const { worksheet } = action;
            let listWorksheetRemove = stringifyAndParse([
                ...(state?.collectionSelected.data?.worksheets ?? []),
            ]);
            let listWorksheetItemRemove = stringifyAndParse([
                ...(state?.collectionSelected.data?.worksheetItems ?? []),
            ]);
            let parentCollections: any = JSON.stringify(state.collections.data);
            parentCollections = JSON.parse(parentCollections);
            if (listWorksheetRemove?.length && worksheet) {
                listWorksheetRemove = listWorksheetRemove.filter(
                    (ws) => ws.id != worksheet?.id
                );
                listWorksheetItemRemove = listWorksheetItemRemove.filter(
                    (ws) => ws.worksheetId != worksheet?.id
                );
            }
            if (parentCollections?.length && worksheet) {
                parentCollections = parentCollections?.filter(
                    (ws) => ws.id != worksheet?.id
                );
            }

            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: [...parentCollections],
                },
                collectionSelected: {
                    ...state.collectionSelected,
                    data: {
                        ...state.collectionSelected.data,
                        worksheets: [...listWorksheetRemove],
                        worksheetItems: [...listWorksheetItemRemove],
                    },
                },
            };

        case ResourceTypes.MOVE_WORKSHEET_TO_COLLECTION_RESOURCE_SUCCESS:
            let collectionMoveWs: any = JSON.stringify(
                state.collectionSelected.data
            );
            collectionMoveWs = JSON.parse(collectionMoveWs);

            collectionMoveWs.worksheetItems =
                collectionMoveWs.worksheetItems?.filter(
                    (item) => item?.worksheetId != action?.worksheet?.id
                );
            collectionMoveWs.worksheets = collectionMoveWs.worksheets?.filter(
                (item) => item?.id != action?.worksheet?.id
            );
            let listWsIdMove = collectionMoveWs.worksheets?.map(
                (item) => item?.id
            );
            let parentIdsC = action?.collection?.parentIds;
            let parentIdsCMove = [];
            if (collectionMoveWs?.id) {
                parentIdsCMove = [
                    ...(collectionMoveWs?.parentIds ?? []),
                    collectionMoveWs?.id,
                ];
            }
            if (parentIdsC?.includes(collectionMoveWs?.id)) {
                collectionMoveWs.worksheets = collectionMoveWs.worksheets?.map(
                    (item) => {
                        if (listWsIdMove?.includes(item?.id)) {
                            item.numberOfWorksheets += 1;
                        }
                        return item;
                    }
                );
            }

            let dataCollections: any = state.collections?.data;
            dataCollections = dataCollections?.map((c) => {
                if (c?.id == action?.collection?.id) {
                    if (!parentIdsCMove.includes(c?.id)) {
                        c.numberOfWorksheets += 1;
                    }
                    c.worksheets = [...c.worksheets, action?.worksheet];
                    c.worksheetItems = [
                        ...c.worksheetItems,
                        new WorksheetItem({
                            worksheetId: action?.worksheet?.id,
                        }),
                    ];
                }

                if (c?.id == collectionMoveWs?.id) {
                    if (parentIdsC?.length && !parentIdsC.includes(c?.id)) {
                        c.numberOfWorksheets -= 1;
                    }
                    c.worksheets = c.worksheets?.filter(
                        (ws) => ws?.id != action?.worksheet?.id
                    );
                    c.worksheetItems = c.worksheetItems?.filter(
                        (ws) => ws?.worksheetId != action?.worksheet?.id
                    );
                }
                if (parentIdsC?.length && !parentIdsC.includes(c?.id)) {
                    if (parentIdsC?.includes(c?.id)) {
                        c.numberOfWorksheets += 1;
                    }
                    if (
                        parentIdsCMove
                            .filter((c) => c != collectionMoveWs?.id)
                            ?.includes(c?.id)
                    ) {
                        c.numberOfWorksheets -= 1;
                    }
                }
                return c;
            });
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: collectionMoveWs,
                },
            };
        case ResourceTypes.MOVE_COLLECTION_RESOURCE_SUCCESS:
            let dataCollectionMoveC: any = stringifyAndParse(
                state.collections?.data
            );
            let nbCollection = stringifyAndParse(
                state.collections.numberCollections
            );
            // dataCollectionMoveC = JSON.parse(dataCollectionMoveC);
            let collectionMoveC = stringifyAndParse(
                state?.collectionSelected?.data
            );
            let parentIds = action?.collection?.parentIds;
            let parentIdCollection = action?.collection?.id;
            let ids = dataCollectionMoveC?.map((c) => c.id);
            if (parentIds?.length) {
                parentIdCollection = parentIds?.find((id) => ids?.includes(id));
            }
            dataCollectionMoveC = dataCollectionMoveC?.map((c) => {
                let findCollection = c?.id == parentIdCollection;
                // let findCollection = c?.worksheets?.find(
                //     (ws) => ws?.id == action?.collection?.id
                // );

                if (findCollection) {
                    c.numberOfWorksheets += !!action?.collection
                        ?.numberOfWorksheets
                        ? action?.collection?.numberOfWorksheets
                        : 0;
                }
                if (c?.id == action?.oldParentId) {
                    c.numberOfWorksheets -= !!action?.collection
                        ?.numberOfWorksheets
                        ? action?.collection?.numberOfWorksheets
                        : 0;
                }
                return c;
            });
            if (collectionMoveC?.id) {
                collectionMoveC.worksheets =
                    collectionMoveC?.worksheets?.filter(
                        (item) => item?.id != action?.collection?.id
                    ) ?? [];
            }

            let isParentCollection = dataCollectionMoveC.find(
                (c) => c?.id == action?.collection?.id
            );
            if (isParentCollection) {
                dataCollectionMoveC = dataCollectionMoveC.filter(
                    (c) => c?.id != action?.collection?.id
                );
                nbCollection = nbCollection - 1;
            } else {
                if (action?.collection?.parentIds?.length == 0) {
                    dataCollectionMoveC = [
                        ...dataCollectionMoveC,
                        action?.collection,
                    ];
                    nbCollection = nbCollection + 1;
                }
            }

            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: [...dataCollectionMoveC],
                    numberCollections: nbCollection,
                },
                collectionSelected: {
                    ...state.collectionSelected,
                    data: { ...collectionMoveC },
                },
            };
        case ResourceTypes.SET_LOADING_MORE_WORKSHEET_RESOURCE:
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    loading: action.loading,
                },
            };
        case ResourceTypes.CREATE_ITEM_COLLECTION_RESOURCE_SUCCESS:
            let parentIdsNewCollection = action?.collection?.parentIds;

            if (
                parentIdsNewCollection?.length == 0 ||
                (parentIdsNewCollection?.length == 1 &&
                    isResourceCollection({
                        collectionId: parentIdsNewCollection?.length
                            ? parentIdsNewCollection[0]
                            : action.collection?.parentCollectionName[0]?.id ??
                              "",
                    }))
            ) {
                return {
                    ...state,
                    collections: {
                        ...state.collections,
                        data: new Array(
                            action.collection,
                            ...state.collections.data
                        ),
                    },
                };
            } else {
                let toCollection = state.actionGridItem?.toCollection;

                let newArrayCollections = toCollection?.arrayCollection;
                let lastId = parentIdsNewCollection?.splice(-1)[0] ?? "";

                if (
                    toCollection?.data?.id &&
                    toCollection?.data?.id == lastId
                ) {
                    newArrayCollections.unshift(action.collection);
                }
                return {
                    ...state,
                    collectionSelected: {
                        ...state.collectionSelected,
                        data: {
                            ...state.collectionSelected?.data,
                            worksheets: new Array(
                                action.collection,
                                ...(state.collectionSelected?.data
                                    ?.worksheets ?? [])
                            ),
                        },
                    },
                    actionGridItem: {
                        ...state.actionGridItem,
                        toCollection: {
                            ...state.actionGridItem?.toCollection,
                            arrayCollection: newArrayCollections,
                        },
                    },
                };
            }

        case ResourceTypes.GET_DATA_COLLECTION_RESOURCE_V2:
            // let listCollectionStateV2 = JSON.parse(
            //     JSON.stringify(state.actionGridItem.listCollection ?? [])
            // );
            // console.log("listCollectionStateV2", listCollectionStateV2);

            // const findCollectionV2 = listCollectionStateV2?.find(
            //     (c) => c.id == action.collection?.id
            // );
            // if (findCollectionV2) {
            //     listCollectionStateV2 = listCollectionStateV2.filter(
            //         (c) => c.id == action.collection?.id
            //     );
            // } else {
            //     if (!!action?.collection?.id) {
            //         listCollectionStateV2.push(action.collection);
            //     } else {
            //         listCollectionStateV2.pop();
            //     }
            // }
            // console.log("listCollectionStateV2 2222", listCollectionStateV2);

            return {
                ...state,

                actionGridItem: {
                    ...state.actionGridItem,
                    toCollection: {
                        ...state.actionGridItem.toCollection,
                        data: action.collection,
                        arrayCollection:
                            action?.arrayCollection ??
                            state.actionGridItem.toCollection.arrayCollection,
                        arrayThumbnail:
                            action?.arrayThumbnail ??
                            state.actionGridItem.toCollection.arrayThumbnail,
                        numberSubCollections: action?.numberSubCollections ?? 0,
                        loading: action.loading,
                    },
                },
            };

        case ResourceTypes.UPDATE_STATUS_ALERT_RESOURCE:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    content: action.content,
                    code: action.code,
                    collection: action.collection,
                    status: action.status,
                    typeAlert: action.typeAlert,
                    thumbnail: action.thumbnail,
                    message: action.message,
                },
            };
        case UserTypes.LOGIN_WITH_POPUP_RESOURCE_SUCCESS:
            return {
                ...state,
                collections: {
                    data: [],
                    numberCollections: 0,
                    loading: false,
                    pageNumber: 0,
                },
            };
        case ResourceTypes.UPDATE_COLLECTION_RESOURCE_SUCCESS:
            let listCollectionUpdate = JSON.parse(
                JSON.stringify(state.collections?.data ?? [])
            );
            listCollectionUpdate = listCollectionUpdate?.map((c) => {
                if (c.id == action?.collection?.id) {
                    c = action?.collection;
                }
                return c;
            });
            return {
                ...state,
                collections: {
                    ...state.collections,
                    data: listCollectionUpdate,
                },
                // collectionSelected: {
                //     ...state.collectionSelected,
                //     data: action.collection,
                //     loading: action.loading,
                //     finishLoadMore: action?.finishLoadMore
                //         ? action?.finishLoadMore
                //         : state.collectionSelected.finishLoadMore,
                //     pageNumber: action?.pageNumber
                //         ? action?.pageNumber
                //         : state.collectionSelected.pageNumber,
                // },
            };
        case ResourceTypes.GET_DEFAULT_COLLECTION_RESOURCE: {
            return { ...state, loading: true };
        }
        case ResourceTypes.AFTER_ACTION: {
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    afterAction:
                        action?.afterAction ?? Config.ACTION_GRID.DEFAULT,
                },
            };
        }
        case ResourceTypes.PREVIOUS_ACTION: {
            return {
                ...state,
                actionGridItem: {
                    ...state.actionGridItem,
                    previousAction:
                        action?.previousAction ?? Config.ACTION_GRID.DEFAULT,
                },
            };
        }

        case ResourceTypes.DELETE_WORKSHEET_FROM_COLLECTION_SUCCESS: {
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: {
                        ...state.collectionSelected.data,
                        worksheetItems: action.collection.worksheetItems,
                        worksheets: action.collection.worksheets,
                    },
                },
                actionGridItem: {
                    worksheet: null,
                    collection: null,
                    toCollection: {
                        data: null,
                        loading: false,
                        offset: 0,
                        limit: 0,
                        numberSubCollections: 0,
                        arrayCollection: [],
                        arrayThumbnail: [],
                        listCollection: [],
                    },
                    worksheets: [],
                    action: Config.ACTION_GRID.DEFAULT,
                    previousAction: Config.ACTION_GRID.DEFAULT,
                    afterAction: Config.ACTION_GRID.DEFAULT,
                },
            };
        }

        case ResourceTypes.DELETE_WORKSHEET_FROM_CREATED_OR_DOWNLOADED_WORKSHEET: {
            let listWorksheetsUpdate = JSON.parse(
                JSON.stringify(state.collectionSelected?.data.worksheets ?? [])
            );
            const listId = action.worksheets.map((ws) => ws.id);
            listWorksheetsUpdate = listWorksheetsUpdate.filter(
                (ws) => !listId.includes(ws.id)
            );
            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: {
                        ...state.collectionSelected.data,
                        worksheets: listWorksheetsUpdate,
                    },
                },
                actionGridItem: {
                    worksheet: null,
                    collection: null,
                    toCollection: {
                        data: null,
                        loading: false,
                        offset: 0,
                        limit: 0,
                        numberSubCollections: 0,
                        arrayCollection: [],
                        arrayThumbnail: [],
                        listCollection: [],
                    },
                    worksheets: [],
                    action: Config.ACTION_GRID.DEFAULT,
                    previousAction: Config.ACTION_GRID.DEFAULT,
                    afterAction: Config.ACTION_GRID.DEFAULT,
                },
            };
        }

        case ResourceTypes.ADD_WORKSHEET_TO_COLLECTION: {
            let listWorksheetsUpdate = JSON.parse(
                JSON.stringify(state.collectionSelected?.data.worksheets ?? [])
            );
            listWorksheetsUpdate.unshift(action.worksheet);

            return {
                ...state,
                collectionSelected: {
                    ...state.collectionSelected,
                    data: {
                        ...state.collectionSelected.data,
                        worksheets: listWorksheetsUpdate,
                    },
                },
            };
        }
        case ResourceTypes.UPDATE_LOADING_TAB_INFO: {
            return {
                ...state,
                loadingTabInfo: action.loadingTabInfo,
            };
        }

        default:
            return state;
    }
}

export default resourceState;
