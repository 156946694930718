import React from "react";

const WorksheetzoneIcon = ({
    width,
    height,
}: {
    width?: string;
    height?: string;
}) => {
    return (
        <svg
            style={{
                width: width ?? "unset",
                height: height ?? "unset",
            }}
            width="97"
            height="16"
            viewBox="0 0 97 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.786466 3.18719C0.352113 2.28828 0.743697 1.21041 1.6611 0.779697C2.57849 0.348985 3.6743 0.728533 4.10866 1.62744L9.48678 12.7577C9.92114 13.6566 9.52955 14.7344 8.61215 15.1652C7.69476 15.5959 6.59895 15.2163 6.16459 14.3174L0.786466 3.18719Z"
                fill="rgba(var(--color), 1)"
            />
            <g style={{ mixBlendMode: "luminosity" }}>
                <path
                    d="M13.2514 6.54407C13.6864 5.64546 13.2951 4.56835 12.3774 4.13827C11.4597 3.7082 10.3631 4.08802 9.92809 4.98663L6.16981 12.7504C5.73481 13.649 6.12612 14.7261 7.04384 15.1561C7.96155 15.5862 9.05814 15.2064 9.49314 14.3078L13.2514 6.54407Z"
                    fill="#E31430"
                />
            </g>
            <path
                d="M21.3139 3.18567C21.7489 2.28706 21.3576 1.20995 20.4399 0.779876C19.5222 0.349802 18.4256 0.729624 17.9906 1.62823L12.6227 12.717C12.1877 13.6156 12.579 14.6927 13.4968 15.1228C14.4145 15.5528 15.5111 15.173 15.9461 14.2744L21.3139 3.18567Z"
                fill="#FFB933"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.46536 14.3625C9.47481 14.3445 9.48399 14.3264 9.4929 14.308L13.2532 6.53999C13.6882 5.64138 13.2969 4.56427 12.3792 4.1342C11.4615 3.70412 10.3649 4.08395 9.92992 4.98255L7.82812 9.32436L9.49119 12.7661C9.74536 13.2921 9.71671 13.8795 9.46536 14.3625Z"
                fill="#FF2442"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66121 0.77722C0.743813 1.20793 0.352228 2.2858 0.786581 3.18471L6.16929 14.3244C6.60364 15.2233 7.69945 15.6029 8.61685 15.1722C8.95415 15.0138 9.22038 14.768 9.4006 14.4757C8.91653 15.2509 7.90259 15.5564 7.0449 15.1545C6.12719 14.7244 5.73587 13.6473 6.17088 12.7487L7.82882 9.32374L4.10877 1.62496C3.67442 0.726056 2.57861 0.346508 1.66121 0.77722Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M26.6927 12.2836L28.0586 12.2751L29.1996 8.07236L30.2906 12.2751H31.6649L33.2889 6.37265H32.0313L30.9986 10.9306L29.8826 6.37265H28.5833L27.4006 10.956L26.3762 6.37265H25.127L26.6927 12.2836Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M39.722 9.30698C39.722 7.53962 38.4061 6.28809 36.7487 6.28809C35.108 6.28809 33.7671 7.53962 33.7671 9.30698C33.7671 11.0828 35.108 12.3343 36.7487 12.3343C38.3978 12.3343 39.722 11.0828 39.722 9.30698ZM34.9664 9.30698C34.9664 8.10619 35.691 7.35358 36.7487 7.35358C37.7981 7.35358 38.5227 8.10619 38.5227 9.30698C38.5227 10.5078 37.7981 11.2773 36.7487 11.2773C35.691 11.2773 34.9664 10.5078 34.9664 9.30698Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M43.671 8.20766C43.671 8.74041 43.3795 9.08712 42.7299 9.08712H41.7555V7.35358H42.7299C43.3795 7.35358 43.671 7.68337 43.671 8.20766ZM40.5895 6.37265V12.2751H41.7555V9.97503H42.3052L43.5878 12.2751H44.937L43.5461 9.88201C44.4789 9.62832 44.8703 8.90108 44.8703 8.18229C44.8703 7.19291 44.1707 6.37265 42.7716 6.37265H40.5895Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M49.2064 12.2751H50.6805L48.2153 9.29852L50.6139 6.37265H49.2064L47.0909 9.01947V6.37265H45.9249V12.2751H47.0909V9.64523L49.2064 12.2751Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M55.5118 10.567C55.5118 8.48672 52.5302 9.14631 52.5302 7.96243C52.5302 7.48042 52.8717 7.2521 53.3297 7.26902C53.8294 7.27747 54.1376 7.5819 54.1626 7.97089H55.4452C55.3702 6.90539 54.554 6.28809 53.363 6.28809C52.1637 6.28809 51.3142 6.93076 51.3142 7.99626C51.3059 10.1357 54.3042 9.36617 54.3042 10.6431C54.3042 11.0659 53.9794 11.3619 53.4297 11.3619C52.8883 11.3619 52.5802 11.049 52.5469 10.5754H51.2976C51.3142 11.6917 52.247 12.3343 53.4547 12.3343C54.7789 12.3343 55.5118 11.4972 55.5118 10.567Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M60.1789 12.2751H61.3449V6.37265H60.1789V8.7996H57.6887V6.37265H56.5227V12.2751H57.6887V9.76362H60.1789V12.2751Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M65.8656 6.36419H62.4926V12.2751H65.8656V11.3111H63.6586V9.74671H65.6158V8.80806H63.6586V7.32821H65.8656V6.36419Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M70.2983 6.36419H66.9252V12.2751H70.2983V11.3111H68.0912V9.74671H70.0484V8.80806H68.0912V7.32821H70.2983V6.36419Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M71.0498 7.32821H72.5989V12.2751H73.7649V7.32821H75.314V6.37265H71.0498V7.32821Z"
                fill="rgba(var(--color), 1)"
            />
            <path
                d="M79.9709 6.37265H76.0065V7.37049H78.605L76.0065 11.3619V12.2751H79.9709V11.2773H77.3557L79.9709 7.28593V6.37265Z"
                fill="#FFB933"
            />
            <path
                d="M86.6351 9.30698C86.6351 7.53962 85.3192 6.28809 83.6618 6.28809C82.0211 6.28809 80.6802 7.53962 80.6802 9.30698C80.6802 11.0828 82.0211 12.3343 83.6618 12.3343C85.3109 12.3343 86.6351 11.0828 86.6351 9.30698ZM81.8795 9.30698C81.8795 8.10619 82.6041 7.35358 83.6618 7.35358C84.7112 7.35358 85.4358 8.10619 85.4358 9.30698C85.4358 10.5078 84.7112 11.2773 83.6618 11.2773C82.6041 11.2773 81.8795 10.5078 81.8795 9.30698Z"
                fill="#FFB933"
            />
            <path
                d="M91.3087 6.36419V10.4232L88.6686 6.36419H87.5026V12.2751H88.6686V8.22458L91.3087 12.2751H92.4747V6.36419H91.3087Z"
                fill="#FFB933"
            />
            <path
                d="M97.0001 6.36419H93.627V12.2751H97.0001V11.3111H94.793V9.74671H96.7502V8.80806H94.793V7.32821H97.0001V6.36419Z"
                fill="#FFB933"
            />
        </svg>
    );
};

export default WorksheetzoneIcon;
