import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    updateIsOnDrag,
    updateResourceItem,
} from "../../../../../redux/reducers/createWorksheet";
import { updateShowButtonAnswerKey } from "../../../../../redux/reducers/leftData";
import { TypeOfPlacementTooltip } from "../../../../../resource-user/components/common/library/render-tooltip";
import ToolTipCustomize from "../../../../../resource-user/components/common/library/tool-tip";
import LineView from "../../../../../resource-user/components/common/line-view";
import { getPositionInput } from "../../../../../resource-user/utils/shape";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import {
    IShapeAttribute,
    PositionInput,
} from "../../../../../shared/models/shapeAttribute";
import ConstantsMath from "../../../../../shared/utils/ConstantsResourceMath";
import Config from "../../../../../shared/utils/config";
import { ConstantsApp } from "../../../../../utils/Constants";
import "./index.scss";

const positionInputs = [
    PositionInput.top,
    PositionInput.above,
    PositionInput.center,
    PositionInput.below,
    PositionInput.bottom,
];

function ResourceLineIntercctive({
    resourceItem,
    currentItem,
    setCurrentElement,
}: {
    resourceItem: IResourceItemNew;
    currentItem?: IResourceItemNew;
    setCurrentElement: any;
}) {
    const isHideTextShape = useAppSelector(
        (state) => state.createWorksheetState.isHideTextShape
    );
    const isShowAnswerKey = useAppSelector(
        (state) => state.createWorksheetState.isShowAnswerKey
    );
    const isSave = !useAppSelector((state) => state.wordSearchState.loading);
    const [isMoveDown, setIsMoveDown] = useState(false);
    const [isMoveUp, setIsMoveUp] = useState(false);
    const [isHoverInput, setIsHoverInput] = useState(false);
    const [hideShowAskey, setHideShowAskey] = useState(true);
    const [text, setText] = useState("");
    const [focus, setFocus] = useState(false);
    const dispatch = useAppDispatch();
    const width = currentItem?.width ?? resourceItem.width,
        height = currentItem?.height ?? resourceItem.height,
        shapeAttribute =
            currentItem?.shapeAttribute ?? resourceItem.shapeAttribute,
        color =
            currentItem?.imageAttribute?.changeColor["color"] ??
            resourceItem.imageAttribute.changeColor["color"],
        textAttribute =
            currentItem?.textAttribute ?? resourceItem.textAttribute,
        inputHeight =
            textAttribute.fontSize * 1.2 + 8 > 32
                ? textAttribute.fontSize * 1.2 + 8
                : 32;
    const [inputWidth, setInputWidth] = useState(width);
    const [widthHead, setWidthHead] = useState(0);

    const isMathActivity = resourceItem.idType?.includes(
        ConstantsMath.PREFIX_MATH_ITEM
    );

    useEffect(() => {
        setText(resourceItem.textAttribute.content);
    }, [resourceItem]);
    useEffect(() => {
        if (resourceItem.textAttribute.content !== text) {
            setText(resourceItem.textAttribute.content);
        }
    }, [resourceItem?.textAttribute?.content]);

    useEffect(() => {
        if (!isMathActivity) {
            if (hideShowAskey) {
                setTimeout(() => {
                    if (currentItem) {
                        dispatch(updateShowButtonAnswerKey(false));
                        setHideShowAskey(false);
                    }
                }, 200);
            }
            if (currentItem === null && !hideShowAskey) {
                dispatch(updateShowButtonAnswerKey(true));
                setHideShowAskey(true);
            }
        }
    }, [currentItem]);

    const getTopInput = (inputHeight: number, borderWidth: number) => {
        return getPositionInput(
            {height,
            inputHeight,
            borderWidth,
            position: shapeAttribute.input.position}
        );
    };

    const changeShapeAttribute = (shape: IShapeAttribute) => {
        setCurrentElement({
            ...currentItem,
            shapeAttribute: {
                ...shape,
            },
        });
        dispatch(
            updateResourceItem({
                currentElementId: currentItem.id,
                pageIndex: currentItem.pageIndex,
                attrValues: [
                    {
                        attr: "shapeAttribute",
                        value: {
                            ...shape,
                        },
                    },
                ],
            })
        );
    };

    const changeAnswer = (answer: string) => {
        setText(answer);
        if (isHideTextShape) {
            return;
        }
        setCurrentElement({
            ...currentItem,
            textAttribute: {
                ...currentItem.textAttribute,
                content: answer,
            },
        });
        dispatch(
            updateResourceItem({
                currentElementId: resourceItem.id,
                pageIndex: currentItem.pageIndex,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...resourceItem.textAttribute,
                            content: answer,
                        },
                    },
                ],
            })
        );
    };

    const onMoveUp = () => {
        if (currentItem) {
            if (isMoveUp) {
                setIsMoveUp(false);
            }
            let index = positionInputs.indexOf(shapeAttribute.input.position);
            if (index > 0) {
                changeShapeAttribute({
                    ...shapeAttribute,
                    input: {
                        ...shapeAttribute.input,
                        position: positionInputs[index - 1],
                    },
                });
            }
        } else {
            setIsMoveUp(true);
        }
    };

    const onMoveDown = () => {
        if (currentItem) {
            if (isMoveDown) {
                setIsMoveDown(false);
            }
            let index = positionInputs.indexOf(shapeAttribute.input.position);
            if (index < positionInputs.length - 1) {
                changeShapeAttribute({
                    ...shapeAttribute,
                    input: {
                        ...shapeAttribute.input,
                        position: positionInputs[index + 1],
                    },
                });
            }
        } else {
            setIsMoveDown(true);
        }
    };

    useEffect(() => {
        if (currentItem) {
            if (isMoveUp) {
                onMoveUp();
            }
            if (isMoveDown) {
                onMoveDown();
            }
        }
    }, [currentItem]);

    const spanRef = useRef(null);
    useEffect(() => {
        if (spanRef.current) {
            setTimeout(
                () => {
                    const minWidth = (width - Math.abs(widthHead)) * 0.7;
                    let textWidth = Math.max(
                        minWidth,
                        spanRef.current.offsetWidth + 20
                    );
                    textWidth = Math.min(
                        textWidth,
                        width - Math.abs(widthHead)
                    );
                    setInputWidth(textWidth);
                },
                inputWidth === width ? 100 : 0
            );
        }
    }, [text, currentItem?.width]);

    const left = widthHead > 0 ? -widthHead / 4 : -widthHead;
    return (
        <div className="content-line-interactive">
            <LineView
                width={width}
                height={height}
                line={shapeAttribute}
                color={color}
                setWidthHead={setWidthHead}
                opacity={currentItem?.opacity ?? resourceItem.opacity}
            />
            <div
                className="box-input"
                style={{
                    width: width - Math.abs(widthHead),
                    height: inputHeight,
                    left: left,
                    top:
                        getTopInput(inputHeight, 2) + (isMathActivity ? 10 : 2),
                }}
                onMouseEnter={() => setIsHoverInput(true)}
                onMouseLeave={() => setIsHoverInput(false)}
            >
                <div
                    style={{
                        display: "flex",
                        width: inputWidth,
                        justifyContent:
                            textAttribute.align === "justify"
                                ? "left"
                                : textAttribute.align,
                    }}
                >
                    <span
                        ref={spanRef}
                        id={"text-area-" + currentItem?.id}
                        className="sentence-text-shape"
                        style={{
                            background:
                                isHideTextShape ||
                                !(
                                    resourceItem.shapeAttribute.input
                                        .position === PositionInput.center
                                )
                                    ? "transparent"
                                    : "white",
                            fontSize: textAttribute.fontSize,
                            color: textAttribute.fontColor,
                            fontFamily: textAttribute.fontFamily,
                            fontStyle: textAttribute.isItalic
                                ? "italic"
                                : "normal",
                            fontWeight: textAttribute.isBold ? "bold" : null,
                            textAlign: textAttribute.align,
                            textDecoration: textAttribute.underline
                                ? "underline"
                                : "none",
                            lineHeight: "1.2",
                            padding: "0px 2px 0px 2px",
                            opacity:
                                !focus && currentItem
                                    ? 1
                                    : focus || (!isShowAnswerKey && isSave)
                                    ? 0
                                    : 1,
                        }}
                    >
                        {text}
                    </span>
                </div>
            </div>
            <div
                className={`box-input item-${Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE}`}
                style={{
                    left: (width - Math.abs(widthHead) - inputWidth) / 2 + left,
                    top:
                        getTopInput(inputHeight, 2) + (isMathActivity ? 10 : 0),
                }}
                onMouseEnter={() => setIsHoverInput(true)}
                onMouseLeave={() => setIsHoverInput(false)}
            >
                <input
                    id={"text-area-" + currentItem?.id}
                    className="text-input"
                    type="text"
                    value={text}
                    placeholder={focus ? "Answer" : ""}
                    onChange={(e) => changeAnswer(e.target.value)}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    onMouseDown={() => {
                        if (focus) {
                            setTimeout(() => {
                                if (text !== "") {
                                    dispatch(updateIsOnDrag(false));
                                }
                            }, 10);
                        }
                    }}
                    style={{
                        width: inputWidth,
                        height: inputHeight,
                        resize: "none",
                        fontSize: textAttribute.fontSize,
                        color: focus ? textAttribute.fontColor : "transparent",
                        fontFamily: textAttribute.fontFamily,
                        fontStyle: textAttribute.isItalic ? "italic" : "normal",
                        fontWeight: textAttribute.isBold ? "bold" : null,
                        textAlign: textAttribute.align,
                        textDecoration: textAttribute.underline
                            ? "underline"
                            : "none",
                        lineHeight: "1.2",
                        background:
                            focus &&
                            resourceItem.shapeAttribute.input.position ===
                                PositionInput.center
                                ? "white"
                                : "transparent",
                    }}
                />
                {isHoverInput && !isMathActivity && (
                    <div
                        className="content-btn"
                        style={{
                            left: inputWidth - 15,
                        }}
                    >
                        <ToolTipCustomize
                            title="Move up"
                            style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
                            placement={TypeOfPlacementTooltip.right}
                            arrow
                        >
                            <div
                                className="button-move"
                                onMouseDown={() => onMoveUp()}
                                style={{
                                    opacity:
                                        shapeAttribute.input.position ===
                                        PositionInput.top
                                            ? 0.2
                                            : 1,
                                }}
                            >
                                <div className="move-up" />
                            </div>
                        </ToolTipCustomize>
                        <ToolTipCustomize
                            title="Move down"
                            style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
                            placement={TypeOfPlacementTooltip.right}
                        >
                            <div
                                className="button-move"
                                onMouseDown={() => onMoveDown()}
                                style={{
                                    opacity:
                                        shapeAttribute.input.position ===
                                        PositionInput.bottom
                                            ? 0.2
                                            : 1,
                                }}
                            >
                                <div className="move-down" />
                            </div>
                        </ToolTipCustomize>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ResourceLineIntercctive;
