export class LocalStorage {
    static KEY_COMPUTERid = "di-retupmoc";
    static PREVIOUS_LANGUAGEid = "previous_languageid";
    static NUMBER_OF_DOWNLOAD = "number_of_download";
    static ACTIVE_CLASSid = "active_classid";
    static ACTIVE_TABid = "active_tabid";
    static get(key: string) {
        return localStorage.getItem(key);
    }

    static set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    static remove(key: string) {
        localStorage.removeItem(key);
    }

    static getAndCreateIfNotExitComputerID(uuId?: string) {
        if (uuId) {
            this.set(LocalStorage.KEY_COMPUTERid, uuId);
            return uuId;
        } else {
            let id: any = this.get(LocalStorage.KEY_COMPUTERid);
            if (!id) {
                id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                    /[xy]/g,
                    function (c) {
                        var r = (Math.random() * 16) | 0,
                            v = c === "x" ? r : (r & 0x3) | 0x8;
                        return v.toString(16);
                    }
                );
                this.set(LocalStorage.KEY_COMPUTERid, id);
            }
            return id;
        }
    }
}
