import { useEffect } from "react";
import { useAppSelector } from "../../redux/hook";
import ConstantsResource from "../../resource-user/utils/ConstantsResource";
import { getListUrlFillTheBlank } from "../../utils";
import "./style.scss";
import TabWordSearchView from "./tab-word-search";
const MainActivities = () => {
    const tabActiveActivities = useAppSelector(
        (state) => state.leftDataState.tabActiveActivities
    );

    useEffect(() => {
        if (tabActiveActivities) {
            // Scroll to current active tab
            const containerId = "tab-content-container";
            const element = document.getElementById(tabActiveActivities);
            const container: any = document.querySelector(
                `#${containerId} .simplebar-content-wrapper`
            );
            const listElements: any = document.querySelector(
                `#${containerId} .simplebar-content`
            );

            if (element && container && listElements) {
                let scroll =
                    element.getBoundingClientRect().top -
                    listElements.getBoundingClientRect()?.top;

                container?.scrollTo({
                    top:
                        Math.abs(scroll) -
                        element.getBoundingClientRect().height,
                    behavior: "smooth",
                });
            }
        }
    }, [tabActiveActivities]);

    return (
        <div className="main-activities">
            <TabWordSearchView
                isActive={
                    getListUrlFillTheBlank() ||
                    [
                        ConstantsResource.TAB_ACTIVE.WORD_SEARCH,
                        ConstantsResource.TAB_ACTIVE.FILL_IN_BLANK,
                        ConstantsResource.TAB_ACTIVE.HANDWRITING,
                        ConstantsResource.TAB_ACTIVE.WORD_SCRAMBLE,
                        ConstantsResource.TAB_ACTIVE.SENTENCE_SCRAMBLE,
                        ConstantsResource.TAB_ACTIVE.NAME_TRACING,
                        ConstantsResource.TAB_ACTIVE.MULTIPLE_CHOICES,
                        ConstantsResource.TAB_ACTIVE.CROSSWORD,
                        ConstantsResource.TAB_ACTIVE.SUBTRACTION,
                        ConstantsResource.TAB_ACTIVE.ADDITION,
                        ConstantsResource.TAB_ACTIVE.MULTIPLICATION,
                        ConstantsResource.TAB_ACTIVE.DIVISION,
                    ].includes(tabActiveActivities)
                }
            />
        </div>
    );
};

export default MainActivities;
