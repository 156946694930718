import React from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";

const ResourceRectComponent = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    return (
        <div
            style={{
                width: `${resourceItem.width}px`,
                height: `${resourceItem.height}px`,
            }}
        >
            <div
                style={{
                    border: "1px solid #212121",
                }}
            ></div>
        </div>
    );
};
export default ResourceRectComponent;
