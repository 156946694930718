import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hook";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../shared/models/questionActivity";

const ListenQuestion = ({ activity, setCurrentQuestion, setOpenQuestion }) => {
    const listQuestion: IQuestionActivity[] = activity.questions;
    const editingResourceId = useAppSelector(
        (state) => state.leftDataState.resourceItemEditing
    );
    const [scrollEle, setScrollEle] = useState("");
    const findCurrentQuestionEditing = () => {
        let activeQuestionIndex = -1;
        activeQuestionIndex = listQuestion.findIndex(
            (question: IQuestionActivity) => question.id == editingResourceId
        );
        if (activeQuestionIndex == -1) {
            listQuestion.forEach(
                (question: IQuestionActivity, questionIndex: number) => {
                    const answerEditing = question.answers.find(
                        (answer: IAnswerActivity) =>
                            answer.id == editingResourceId
                    );
                    if (answerEditing) {
                        activeQuestionIndex = questionIndex;
                    }
                }
            );
        }
        return activeQuestionIndex;
    };
    useEffect(() => {
        if (scrollEle) {
            const listQuesBox = document.getElementById("question-container");
            listQuesBox?.scrollTo({
                top: document.getElementById(scrollEle).offsetTop,
                behavior: "smooth",
            });
        }
    }, [scrollEle]);
    useEffect(() => {
        let activeQuestionIndex = findCurrentQuestionEditing();
        if (activeQuestionIndex != -1) {
            setCurrentQuestion(activeQuestionIndex);
            setOpenQuestion(true);
            setScrollEle(`question-${activeQuestionIndex + 1}`);
        }
    }, [editingResourceId]);
    return <></>;
};

export default ListenQuestion;
