import Constants from "./ConstantsResource";

export default class Cookies {
    static _get(key: string, cookie: any) {
        // //
        if (!cookie) {
            return null;
        }
        let name = key + "=";
        let decodedCookie = decodeURIComponent(cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static get(key: string, cookie?: any) {
        if (!cookie && typeof document !== "undefined") {
            cookie = document.cookie;
        }
        return this._get(key, cookie);
    }

    static set(key: string, value: string, expires?: number) {
        let d = new Date();
        if (expires && expires <= 0) {
            d.setTime(0);
        } else {
            d.setTime(
                d.getTime() + (expires ? expires : 30) * 24 * 60 * 60 * 1000
            );
        }
        document.cookie =
            key +
            "=" +
            value +
            ";" +
            ("expires=" + d.toUTCString()) +
            ";path=/";
    }
    static setToken(value: string) {
        this.set(Constants.COOKIE_KEY_WORKSHEET_TOKEN, value);
    }
}
