import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config, {
    collectionIdDefaultList,
} from "../../../../shared/utils/config";

import { useRef, useState } from "react";
import { Collection } from "../../../../shared/models/collection";
import { Worksheet } from "../../../../shared/models/worksheet";
import {
    isModuleApplyForNextJs,
    isModuleForToolV2,
} from "../../../../shared/utils";
import backgroundCollection from "../../../assets/images/collections/background-collection.png";
import deleteIcon from "../../../assets/images/icon/delete-icon.svg";
import editIcon from "../../../assets/images/icon/edit-icon.svg";
import moveIcon from "../../../assets/images/icon/move-icon.svg";
import {
    selectActionGridItemAction,
    selectCollectionResourceAction,
    selectGridItemAction,
} from "../../../redux/action/resource.action";
import DeleteAction from "../../collection/collections-item/delete-action";
import DrawerFolder from "../../collection/collections-item/drawer";
import useComponentVisible from "../../common/click-out-side";
import ThreeDotsFolder from "../../icons/folder/three-dots";
import "./style.scss";

import { getImageSource } from "../../../utils";
import MoreActionTool from "./more-action-tool";
import ConstantsResource from "../../../utils/ConstantsResource";
const NewCollectionView = ({
    collection,
    selectCollectionFc,
    index,
    addGaEventMoreEvent,
}: {
    collection: any;
    selectCollectionFc?: Function;
    index: string;
    addGaEventMoreEvent?: Function;
}) => {
    const isToolV2 = isModuleForToolV2();
    const isReactApp = isModuleApplyForNextJs();
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isMobile = useMediaQuery("(max-width:768px)");
    const dispatch = useDispatch();
    const refCollection = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [clickRight, setClickRight] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [showActionDelete, setShowActionDelete] = useState(false);

    const userInfo = useSelector(
        (state: any) => state.userInfoResourceState.data
    );
    const actionGridItem = useSelector(
        (state: any) => state.resourceState.actionGridItem
    );
    const worksheetAction = actionGridItem?.worksheet;
    const collectionAction = actionGridItem?.collection;

    const haveAction =
        (worksheetAction?.id || collectionAction?.id) &&
        !collectionIdDefaultList.includes(collection?.id) &&
        !isReactApp;
    const isDraftCollection =
        collection.name.toLocaleLowerCase() ===
        Config.COLLECTION_DRAFT.DRAFT_COLLECTION_NAME.toLocaleLowerCase();
    const isAuthor = collection.userId === userInfo.id;
    let idCollection = `profile-collection-${collection.id}`;
    const { isComponentVisible } = useComponentVisible(refCollection);

    const selectActionGridAction = ({ action }: { action: string }) => {
        dispatch(selectActionGridItemAction({ action }));
    };

    useEffect(() => {
        if (document.getElementById(idCollection) && !isToolV2) {
            const innerWidth = window.innerWidth;
            let action = (event) => {
                if (!collectionIdDefaultList.includes(collection?.id)) {
                    collection.introductionDescription =
                        collection.introductionDescription;

                    dispatch(selectGridItemAction({ collection: collection }));
                    setClickRight(false);
                    setPosition({ x: 0, y: 0 });
                    // width: 194
                    let x = event.offsetX;

                    if (innerWidth - 194 < event.clientX) {
                        x = x - 194;
                    }
                    setPosition({ x: x, y: event.offsetY });
                    setClickRight(true);
                }
                event.preventDefault();
            };
            document
                .getElementById(idCollection)
                .addEventListener("contextmenu", (event) => {
                    action(event);
                });

            return () => {
                document
                    ?.getElementById(idCollection)
                    ?.removeEventListener("contextmenu", (event) => {
                        action(event);
                    });
            };
        }
    }, [idCollection, collection]);
    useEffect(() => {
        if (!collectionIdDefaultList.includes(collection?.id) && !isToolV2) {
            if (isComponentVisible) {
                setClickRight(false);
            } else {
                if (!clickRight) {
                    if (actionGridItem.action !== Config.ACTION_GRID.DEFAULT) {
                        dispatch(
                            selectActionGridItemAction({
                                action: Config.ACTION_GRID.DEFAULT,
                            })
                        );
                    }
                    if (worksheetAction?.id || collectionAction?.id) {
                        dispatch(
                            selectGridItemAction({
                                collection: new Collection(),
                                worksheet: new Worksheet(),
                                toCollection: true,
                            })
                        );
                    }
                }
            }
        }
    }, [isComponentVisible]);
    // useEffect(() => {}, [clickRight]);
    if (isDraftCollection && !isAuthor) {
        return null;
    }

    return (
        <div
            key={index}
            id={idCollection}
            ref={refCollection}
            className="item-collection-thumbnail"
        >
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectCollectionFc && selectCollectionFc({ collection });
                    dispatch(
                        selectCollectionResourceAction({
                            collection: collection,
                            pageTypeResource:
                                Config.PAGE_TYPE.PAGE_VIEW_COLLECTION_DETAIL,
                            loading: true,
                            notFetchData: isToolV2 ? false : true,
                            projectData: isToolV2
                                ? ConstantsResource.PROJECT_DATA_COLLECTION_TOOLV2
                                : {},
                        })
                    );
                }}
            >
                <div
                    className={"item-collection-thumbnail__content "}
                    style={{
                        width: isToolV2 ? "100%" : isMobile ? "176px" : "236px",
                    }}
                >
                    <img
                        // src="/images/collections/background-collection.png"
                        src={getImageSource(backgroundCollection)}
                        alt="background-collection"
                        loading="lazy"
                    />
                    <div
                        className={
                            "item-collection-thumbnail__content__text " +
                            (isToolV2 ? " scale-item" : "")
                        }
                    >
                        <p className="collection-name">{collection.name}</p>
                        {isToolV2 ? (
                            <></>
                        ) : (
                            <p className="nb-worksheets">
                                {collection?.numberOfWorksheets ?? 0 + " "}{" "}
                                Worksheets
                            </p>
                        )}
                    </div>
                    {isTablet && (
                        <div
                            className="item-collection-thumbnail__content__action"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowAction(true);
                                dispatch(
                                    selectGridItemAction({
                                        collection: collection,
                                    })
                                );
                            }}
                        >
                            <ThreeDotsFolder />
                        </div>
                    )}
                </div>
            </div>
            {clickRight &&
            !collectionIdDefaultList.includes(collection?.id) &&
            isAuthor &&
            haveAction ? (
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    className={"popup-action-collection collection v2"}
                    style={{
                        top: position.y,
                        left: position.x,
                    }}
                >
                    {collection?.id && (
                        <>
                            <div
                                className="item-action"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    selectActionGridAction({
                                        action: Config.ACTION_GRID.EDIT,
                                    });
                                }}
                            >
                                <img src={getImageSource(editIcon)} alt="" />
                                <span>Edit</span>
                            </div>
                            <div className="split"></div>
                        </>
                    )}
                    {haveAction && (
                        <>
                            <div
                                className="item-action"
                                onClick={() =>
                                    selectActionGridAction({
                                        action: Config.ACTION_GRID
                                            .DELETE_COLLECTION,
                                    })
                                }
                            >
                                <img
                                    src={getImageSource(deleteIcon)}
                                    // src="/images/icon/delete-icon.svg"
                                    alt="delete-icon"
                                />
                                <span>Delete</span>
                            </div>
                            <div className="split"></div>
                        </>
                    )}
                    {haveAction && (
                        <div
                            className="item-action"
                            onClick={() =>
                                selectActionGridAction({
                                    action: Config.ACTION_GRID.MOVE_COLLECTION,
                                })
                            }
                        >
                            <img
                                // src="/images/icon/move-icon.svg"
                                src={getImageSource(moveIcon)}
                                alt="move-icon"
                            />
                            <span> Move to</span>
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}

            {showAction &&
                (worksheetAction?.id || collectionAction?.id) &&
                !isReactApp && (
                    <DrawerFolder
                        setShowAction={(value: boolean) => setShowAction(value)}
                        showAction={showAction}
                        setShowActionDelete={(value: boolean) =>
                            setShowActionDelete(value)
                        }
                        collection={collection}
                    ></DrawerFolder>
                )}
            {showAction && showActionDelete && (
                <DeleteAction
                    collection={collection}
                    setShowActionDelete={(value) => setShowActionDelete(value)}
                />
            )}
            {isToolV2 ? (
                <>
                    <MoreActionTool
                        collection={collection}
                        addGaEventMoreEvent={addGaEventMoreEvent}
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NewCollectionView;
