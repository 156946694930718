import React from "react";

const ArrowNext = ({ dark = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 6 9"
            fill="none"
        >
            <path
                d="M1.00005 0.998576C0.740054 1.25858 0.740054 1.67858 1.00005 1.93858L3.58672 4.52524L1.00005 7.11191C0.740054 7.37191 0.740054 7.79191 1.00005 8.05191C1.26005 8.31191 1.68005 8.31191 1.94005 8.05191L5.00005 4.99191C5.26005 4.73191 5.26005 4.31191 5.00005 4.05191L1.94005 0.991909C1.68672 0.738576 1.26005 0.738576 1.00005 0.998576Z"
                fill={dark ? "#ffffff" : "#212121"}
            />
        </svg>
    );
};

export default ArrowNext;
