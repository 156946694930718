import React from "react";
import { useAppSelector } from "../../../../../../redux/hook";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import { floorNumber } from "../../../../../../utils";
import { ConstantsApp } from "../../../../../../utils/Constants";

const BorderElement = React.memo(
    React.forwardRef<
        HTMLDivElement,
        { currentElement: IResourceItemNew | IResourceItemNew }
    >(({ currentElement }, ref) => {
        const ratio = useAppSelector(
            (state) => state.createWorksheetState.ratio
        );
        return (
            <div
                id={ConstantsApp.ID_BORDER_ELEMENT}
                ref={ref}
                style={{
                    width: `${floorNumber(currentElement.width * ratio)}px`,
                    height: `${floorNumber(currentElement.height * ratio)}px`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: `translate(${floorNumber(
                        currentElement.x * ratio
                    )}px, ${floorNumber(currentElement.y * ratio)}px) rotate(${
                        currentElement.rotate
                    }deg)`,
                }}
                className="not-click"
            >
                <div
                    style={{
                        bottom: "-1px",
                        left: "-1px",
                        position: "absolute",
                        right: "-1px",
                        top: "-1px",
                        border: "2px solid #8b3dff",
                        boxShadow:
                            "0 0 0 1px hsla(0,0%,100%,.07), inset 0 0 0 1px hsla(0,0%,100%,.07)",
                        boxSizing: "border-box",
                    }}
                ></div>
            </div>
        );
    })
);

export default BorderElement;
