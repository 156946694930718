import './styles.scss';
import { useAppSelector } from '../../../../../../redux/hook';

const DimensionInforTooltip = ({
    width,
    height,
} : {
    width: number,
    height: number,
}) => {
    const { ratio } = useAppSelector((state) => state.createWorksheetState);

    return (
        <div className="dimension-infor-tooltip">
            w: {Math.floor(width / ratio)} h: {Math.floor(height / ratio)} ratio: {(width / height).toFixed(1)}
        </div>
    );
};

export default DimensionInforTooltip;