import { useMemo, useRef, useEffect } from 'react';
import { getBoundingTextBox } from '../../../../../resource-user/utils';
import { IResourceItemNew, ITextAttribute, TextAttribute } from '../../../../../shared/models/resourceItemNew';
import { useAppSelector } from '../../../../../redux/hook';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';

export const defaultContainerContent = 'content_1,content_2,conten_3';

export const LinkedZoneComponent = ({
    textAttribute,
    opacity,
    content,
    parentWidth,
    parentHeight,
}: {
    textAttribute: ITextAttribute
    opacity: number,
    content: string,
    parentWidth: number,
    parentHeight: number,
}) => {
    const isHTML = content.startsWith("<");
    const refCurrent = useRef<HTMLDivElement>(null);

    const bounding = getBoundingTextBox(textAttribute);

    useEffect(() => {
        if (refCurrent.current && isHTML) {
            const contentDiv = refCurrent.current.firstChild;

            if (contentDiv instanceof HTMLElement) {
                const contentWidth = contentDiv.scrollWidth;
                // const contentHeight = contentDiv.scrollHeight;

                const scale = parseFloat((parentWidth / contentWidth).toFixed(3));
                // const scaleY = parseFloat((parentHeight / contentHeight).toFixed(3));
                // const scale = Math.min(scaleX, scaleY);

                const translateX = Math.abs((contentWidth - parentWidth) / 2);
                if (contentWidth > parentWidth) {
                    console.log(`translateX(-${translateX}px) scale(${scale})`)
                    contentDiv.style.transform = `translateX(-${translateX}px) scale(${scale})`;
                } else {
                    contentDiv.style.transform = `translateX(${translateX}px) scale(${scale})`;
                }

            }
        }
    }, [content, parentHeight, parentWidth]);

    return (
        <div
            ref={refCurrent}
            contentEditable="false"
            suppressContentEditableWarning={true}
            className={`${textAttribute.textCase === 'Aa' ? 'capitalize-first-letter' : ''}`}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: textAttribute.fontSize,
                color: textAttribute.fontColor ?? "#212121",
                fontFamily: textAttribute.fontFamily,
                fontStyle: textAttribute.isItalic ? "italic" : "normal",
                fontWeight: textAttribute.isBold ? "bold" : null,
                WebkitTextStroke: textAttribute.isStroke ? `${textAttribute.strokeWidth}px ${textAttribute.strokeColor}` : null,
                textAlign: textAttribute.align,
                textDecoration:
                    textAttribute.underline &&
                        !textAttribute.subTexts.length
                        ? "underline"
                        : "none",
                whiteSpace: "pre-line",
                wordBreak: "keep-all",
                outline: "none",
                border: "none",
                WebkitUserModify: focus
                    ? "read-write-plaintext-only"
                    : null,
                opacity,
                lineHeight:
                    textAttribute.lineHeight > 0
                        ? textAttribute.lineHeight
                        : null,
                letterSpacing: textAttribute.letterSpacing + "em",
                paddingLeft: bounding.paddingLeft,
                textTransform: textAttribute.textCase === 'AA' ? 'uppercase' : textAttribute.textCase === 'aa' ? 'lowercase' : textAttribute.textCase === 'Aa' ? 'lowercase' : 'none',
                userSelect: 'none'
            }}
        >
            {isHTML ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: textAttribute.flexDirection,
                        flexWrap: textAttribute.flexWrap,
                        alignItems: textAttribute.alignItems,
                        justifyContent: textAttribute.justifyContent,
                    }}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            ) : (
                <div>{content}</div>
            )}
        </div>
    )
}

const ResourceContainer = ({
    resourceItem,
    currentElement,
}: {
    resourceItem: IResourceItemNew;
    currentElement: IResourceItemNew;
}) => {
    const { pageType } = useAppSelector((state) => state.createWorksheetState);

    const height: number = currentElement?.height ?? resourceItem.height;
    const width: number = currentElement?.width ?? resourceItem.width;
    const textAttribute = currentElement?.textAttribute ?? resourceItem?.textAttribute ?? new TextAttribute();
    const contentZoneContainer = currentElement?.textAttribute?.content ?? resourceItem?.textAttribute?.content;

    const linkedZonesContent = useMemo(() => {
        let result = contentZoneContainer;
        if (!result && Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType)) {
            result = defaultContainerContent;
        }
        return result.split("~");
    }, [pageType, contentZoneContainer]);

    return (
        <div
            className='d-flex'
            contentEditable="false"
            suppressContentEditableWarning={true}
            style={{
                flexDirection: textAttribute.flexDirection,
                flexWrap: textAttribute.flexWrap,
                alignItems: textAttribute.alignItems,
                justifyContent: textAttribute.justifyContent,
                height,
                userSelect: 'none'
            }}
        >
            {linkedZonesContent.map((content) => (
                <LinkedZoneComponent
                    textAttribute={textAttribute}
                    opacity={currentElement?.opacity ?? resourceItem.opacity}
                    content={content}
                    parentWidth={width}
                    parentHeight={height}
                />
            ))}
        </div>
    )
}

export default ResourceContainer;