import { useEffect } from "react";
import { useAppSelector } from "../../../../../../../redux/hook";

const ListenAnswer = ({ listAnswer, setChosenAnswer }) => {
    const editingResourceId = useAppSelector(
        (state) => state.leftDataState.resourceItemEditing
    );
    const findCurrentAnswerEditing = () => {
        const answerActiveIndex: number = listAnswer.findIndex(
            (answer) => answer.id == editingResourceId
        );
        return answerActiveIndex;
    };
    useEffect(() => {
        let answerActiveIndex = findCurrentAnswerEditing();
        if (answerActiveIndex != -1) {
            setChosenAnswer(answerActiveIndex);
        }
    }, [editingResourceId]);
    return <></>;
};

export default ListenAnswer;
