import CreateWSConfig from "../utils/create-ws-config";

const LoadFont = () => {
    const fontFamilies = [
        ...CreateWSConfig.PRINT_FONT_FAMILY,
        ...CreateWSConfig.CURSIVE_FONT_FAMILY,
    ];
    const listElement = [];

    fontFamilies.forEach((font) => {
        for (const property in font) {
            const value = font[property];

            if (
                property === "name" ||
                value?.length === 0 ||
                value?.includes(CreateWSConfig.OPACITY_FONT_FAMILY)
            ) {
                continue;
            }
            listElement.push(
                <span
                    key={value}
                    className="canvas-text"
                    style={{
                        fontFamily: `"${value}"`,
                    }}
                ></span>
            );
        }
    });

    return <div>{listElement}</div>;
};

export default LoadFont;
