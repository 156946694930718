export enum APIConfig {
    GET_NUMBER_WORKSHEETS_DEFAULT_FOLDER = "/get-number-worksheets-default-folder",
    GET_COLLECT_BY_USERid_V2 = "/get-collection-by-user-id-v2",
    GET_DOWNLOADED_WORKSHEET_V2 = "/get-downloaded-worksheet-v2",
    GET_DOWNLOADED_WORKSHEET_BY_USERid = "/get-downloaded-worksheet-by-user-id",
    GET_WORKSHEET_INFO = "/get-worksheets-info",
    GET_WORK_SHEET_BYid = "/get-work-sheet-by-id",
    GET_WORK_SHEET_BYidS = "/get-work-sheet-by-ids",

    SEARCH_WORKSHEET = "/search-work-sheet",
    UPLOAD_RECORD = "/upload-record",
    GET_CATEGORIES_BY_PARENTid = "/get-category-by-parent-id",
    GET_WORKSHEETS_BY_CATEGORYid = "/get-worksheets-by-category-id",
    GET_LATEST_WORKSHEETS = "/get-latest-worksheets",
    GET_NUMBER_OF_WORKSHEET_PAGES = "/get-number-of-worksheets-pages",
    FILTER_WORKSHEET = "/filter-worksheet",
    LOGIN = "/login",
    LOGIN_WITH_GOOGLE = "/login-with-google",
    LOGIN_WITH_EMAIL = "/login-with-email",
    GET_INTEREST_WORKSHEET = "/get-interest-worksheet",
    UPDATE_CATEGORY_SCORE = "/update-category-score",
    GEN_SITEMAP_DAILY = "/gen-sitemap-daily",
    GET_COUNTRY_FROM_IP = "https://api.ipregistry.co/?key=tryout",
    USER_INFO = "/user-info",
    FOLLOW = "/follow",
    GET_WORK_SHEET_BY_USERid = "/get-work-sheet-by-user-id",
    GET_WORK_SHEET_BY_USERid_V2 = "/get-work-sheet-by-user-id-v2",
    GET_COLLECT_BY_USERid = "/get-collection-by-user-id",
    GET_COLLECT_BYid = "/get-collection",
    GET_COLLECTS_BY_WORKSHEETid = "/get-collection-by-work-sheet-id",

    UPDATE_USER_INFO = "/update-user-info",
    GET_USER_FROM_TOKEN = "/get-user-from-token",
    UPDATE_USER_EMAIL = "/update-user-email",
    GET_USER_RATE = "/get-user-rating",
    UPDATE_USER_RATE = "/update-user-rating",
    GET_USER_COLLECTION = "/get-user-collection",
    CREATE_NEW_COLLECTION = "/create-new-collection",
    CLONE_COLLECTION = "/clone-collection",
    ADD_REMOVE_WORKSHEET_TO_COLLECTION = "/add-remove-worksheet-to-collection",
    GET_POPULAR_WORKSHEETS_OF_LANGUAGE = "/get-popular-keyword-of-topic",
    GET_SUBTOPIC_COLLECTION = "/get-subtopic-collection",
    CLONE_WORKSHEET_LIST = "/clone-worksheet-list",
    GET_WORKSHEET_NUMBER_OF_AUTHOR = "/get-worksheet-number-of-author",
    UPDATE_DOWNLOADED_WORKSHEET = "/update-downloaded-worksheet",
    UPDATE_PRACTICE_WORKSHEET = "/update-practice-worksheet",
    UPDATE_PRACTICE_WORKSHEET_V2 = "/update-practice-worksheet-v2",

    GET_DOWNLOADED_WORKSHEET = "/get-downloaded-worksheet",
    GET_PRACTICE_WORKSHEET = "/get-practice-worksheet",
    GET_PRACTICE_WORKSHEET_V2 = "/get-practice-worksheet-v2",
    GET_POPULAR_KEYWORD = "/get-popular-keyword",
    GET_COLLECTION_OF_SUBTOPICid = "/get-collection-of-subtopic-id",
    SEARCH_COLLECTION = "/search-collection",
    GET_ALL_COLLECTION = "/get-all-collection",
    CREATE_COLLECTION = "/create-collection",
    DELETE_COLLECTION = "/delete-collection",
    GET_USER_COLLECTION_OF_CATEGORYid = "/get-user-collection-of-category-id",
    CRAW_DESCRIPTION_TPT = "/crawl-description-tpt",
    GET_COLLECTIONS_BY_WORKSHEETid = "/get-collections-by-worksheet-id",
    GET_BIGGEST_COLLECTIONS_BY_WORKSHEETid = "/get-biggest-collections-by-worksheet-id",
    GET_COLLECTION_TO_INDEX = "/get-collection-to-index",

    GET_POPULAR_WORKSHEETS = "/get-popular-worksheets",
    GET_RECOMMEND_WORKSHEETS = "/get-recommend-worksheets",
    GET_ADDITIONAL_WORKSHEET = "/get-additional-worksheet",

    UPDATE_ITEM_COLLECTION = "/update-item-collection",
    GET_DATA_COLLECTION = "/get-data-collection",
    REMOVE_COLLECTION = "/remove-collection",
    GET_MORE_PROFILE_INFO = "/get-more-profile-info",
    UPDATE_WORKSHEET = "/update-worksheet",
    MOVE_WORKSHEET_TO_COLLECTION = "/move-worksheet-to-collection",

    GET_RANDOM_COLLECTION = "/get-random-collection",
    GET_WORKSHEETS_DRAFT = "/get-worksheet-draft",
    UPLOAD_BASE64_TO_GOOGLE_CLOUD = "/upload-multiple-image-from-base64",
    UPLOAD_AUDIO_TO_GOOGLE_CLOUD = "/upload-audio-to-google-cloud",

    ADD_NEW_KEYWORD = "/add-new-keyword",
    GET_LIST_KEYWORD = "/get-list-keyword",
    ADD_WATERMARK_TO_IMAGE = "/add-watermark-to-image",
    GET_ALL_MANAGEMENT_WORKSHEET = "/get-all-management-worksheet",
    CREATE_WORKSHEET_MANAGEMENT = "/create-worksheet-management",
    DELETE_WORKSHEET = "/delete-worksheet",
    DELETE_WORKSHEET_BY_STATUS = "/delete-worksheet-by-status",
    GET_TEMPLATE_BY_NAMES = "/get-template-by-names",
    GET_PRACTICE_WORKSHEET_BYid = "/get-practice-worksheet-by-id",
    GET_NUMBER_WORKSHEET_CREATED = "/get-number-worksheet-created",
    UPDATE_USER_SURVEY = "/update-user-survey",
    UPDATE_USER_SENT_EMAIL = "/update-user-sent-email",
    GET_SITEMAP_NEW_SITE = "/get-sitemap-new-site",
    GET_SITEMAP_DAILY = "/get-sitemap-daily",
    GET_CATEGORY_DAILY = "/get-category-daily",
    GET_COLLECTION_BY_TREND_KEYWORD = "/get-collection-by-trend-keyword",
    UPDATE_USER_IS_VERIFIED = "/update-user-is-verified",
    GET_POST_CONTENT = "/get-post-content",
    SEND_EMAIL_VERIFY = "/send-email-verify",
    GET_SIMILAR_WORKSHEET = "/get-similar-worsheet",

    GET_DATA_COLLECTION_V2 = "/get-data-collection-v2",

    UPLOAD_MULTIPLE_IMAGE_FROM_BASE64 = "/upload-multiple-image-from-base64",
    UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH = "/update-worksheet-created-by-word-search",
    CREATE_NEW_ASSIGNMENT = "/assignment",
    ASSIGNMENTS_PREFIX = "/assignments",
    GET_KEYWORD_GPT = "/get-keyword-gpt",
    MAKE_RP_BUG = "/make-rp-bug",
    GET_CLASSES_BY_USERID = "/get-classes-by-userId",
    GET_ASSIGNMENT = "/get-assignment",
    UPDATE_ASSIGNMENT = "/update-assignment",
    CREATE_CLASS = "/create-class",
    ASSISTANT_CONVERSATION = "/assistant-conversion",
    MOST_RECENT_CONVERSATION = "/most-recent-conversion",
    UPDATE_MESS_CONVERSATION = "/update-mess-conversion",
    APPEND_LASS_MESS_CONVERSATION = "/append-lass-mess-conversion",
    UPDATE_DONE_SURVEY_RATING = "/update-done-survey-rating",
    UPDATE_QUOTA_DOWNLOAD = "/update-quota-download",
    GET_QUOTA_RESOURCES = "/get-quota-resources",

    GET_SURVEY_WORKSHEET = "/get-survey-worksheet",
    UPDATE_SURVEY_WORKSHEET = "/update-survey-worksheet",
    ADD_DOWNLOADED_WORKSHEET = "/add-downloaded-worksheet",
    DELETE_DOWNLOADED_WORKSHEET = "/delete-downloaded-worksheet",
    GET_RESOURCE_ELEMENT = "/get-ws-from-tool-v2",
    INCREASE_QUOTA_DOWNLOAD = "/increase-quota-download",
    UPDATE_TIMES_NEXT_DOWNLOAD = "/update-times-next-download",
    GET_ALL_WORKSHEET_COLLECTION = "/get-all-worksheet-collection",
    DUPLICATE_WS_FROM_TOOL_V2 = "/duplicate-ws-from-tool-v2",

    CREATE_BONUS_DOWNLOAD_NOTIFICATION = "/create-bonus-download-notification",
    CREATE_SUBMITED_NOTIFICATION = "/create-submited-notification",
    GET_SUBMITED_NOTIFICATION_OF_USER = "/get-submited-notification",
    GET_NUMBER_OF_NOTIFICATIONS = "/get-number-notification",
    SET_ALL_NOTIFICATION_READ_STATUS = "/set-read-status-all-notifications",
    SET_NOTIFICATION_READ_DETAIL = "/set-notification-read-detail",
    CHECK_USER_INVITED = "/check-user-invited",
    GET_SUGGEST_TAGS_POPUP_SAVE = "/get-suggest-tags-popup-save",
    CREATE_NEW_WS_FROM_TOOL_V1 = "/create-new-ws-from-tool-v1",
    GET_DETAIL_SUBMIT_NOTIFICATION = "/get-detail-submit-notificaition",
    UPDATE_QUOTA_DOWNLOAD_USER = "/update-quota-download-user",
    UPDATE_USER_SHARE_PIN = "/update-user-share-pin",
}
