import React, { useState } from "react";
import NumberCol from "./NumberCol";
import AddAnswer from "./AddAnswer";
import ListAnswer from "./ListAnswer";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../shared/models/questionActivity";
import { IActivity } from "../../../../../../shared/models/WorkSheetCreator";

const QuestionDetail = ({
    activity,
    question,
    questionIndex,
}: {
    activity: IActivity;
    question: IQuestionActivity;
    questionIndex: number;
}) => {
    const listAnswer: IAnswerActivity[] =
        activity.questions[questionIndex]?.answers;
    const [chosenAnswer, setChosenAnswer] = useState<number>(-1);
    const [userChangeCol, setUserChangeCol] = useState(false);

    return (
        <div className="question-detail">
            <div className="options-header">
                <p className="option-title">Answer options</p>
                <AddAnswer
                    activity={activity}
                    question={question}
                    setChosenAnswer={setChosenAnswer}
                    setUserChangeCol={setUserChangeCol}
                />
            </div>
            <ListAnswer
                activity={activity}
                listAnswer={listAnswer}
                question={question}
                questionIndex={questionIndex}
                chosenAnswer={chosenAnswer}
                setChosenAnswer={setChosenAnswer}
                userChangeCol={userChangeCol}
                setUserChangeCol={setUserChangeCol}
            />
            <div className="tutorial">
                <p className="text">
                    * Click on the choice to set that is correct
                </p>
            </div>
            <NumberCol
                activity={activity}
                question={question}
                questionIndex={questionIndex}
                setUserChangeCol={setUserChangeCol}
            />
        </div>
    );
};

export default QuestionDetail;
