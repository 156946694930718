import Drawer from "@mui/material/Drawer";
import { ICollection } from "../../../../../shared/models/collection";
import HintIconSvg from "../../../icons/hint";
import DeleteAction from "../delete-action";
import EditAction from "./edit";
import MoveAction from "./move";

const DrawerFolder = ({
    setShowAction,
    showAction,
    setShowActionDelete,
    collection,
}: {
    setShowAction: (value: boolean) => void;
    showAction: boolean;
    setShowActionDelete: (value: boolean) => void;
    collection: ICollection;
}) => {
    return (
        <Drawer
            className="popup-more-actions-collection"
            anchor={"bottom"}
            onClose={() => setShowAction(false)}
            open={showAction}
        >
            <div className="close-button" onClick={() => setShowAction(false)}>
                <HintIconSvg />
            </div>
            <div className="content-popup-more-actions-collection">
                <EditAction collection={collection} />
                <DeleteAction
                    collection={collection}
                    setShowActionDelete={(value) => setShowActionDelete(value)}
                    setShowAction={(value) => setShowAction(value)}
                />
                <MoveAction collection={collection} />
            </div>
        </Drawer>
    );
};
export default DrawerFolder;
