import { IParamsQuotaResourceUser } from "../../../shared/models/quotaResourceuser";
import { APIConfig } from "../../utils/api-config";
import { POST } from "./index";

export const updateQuotaDownloadApi = (args: {
    userId: string;
    numberDownload: number;
    times: number;
}): Promise<any> => {
    return POST({
        url: APIConfig.UPDATE_QUOTA_DOWNLOAD,
        params: {
            userId: args.userId,
            numberDownload: args.numberDownload,
            times: args.times,
        },
    }).then((data: any) => {
        return data;
    });
};

export const getQuotaResourceByUserIdApi = (args: {
    userId: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.GET_QUOTA_RESOURCES,
        params: {
            userId: args.userId,
        },
    }).then((data: any) => {
        return data;
    });
};
export const increaseQuotaResourceByUserIdApi = (args: {
    userId: string;
    times?: number;
    timeNextDownload?: Date;
    timeClaimed?: Date;
}): Promise<any> => {
    return POST({
        url: APIConfig.INCREASE_QUOTA_DOWNLOAD,
        params: {
            userId: args.userId,
            times: args.times,
            timeNextDownload: args.timeNextDownload,
            timeClaimed: args.timeClaimed,
        },
    }).then((data: any) => {
        return data;
    });
};

export const updateTimeNextDownloadByUserIdApi = (args: {
    userId: string;
    timeNextDownload: Date;
    times: number;
}): Promise<any> => {
    return POST({
        url: APIConfig.UPDATE_TIMES_NEXT_DOWNLOAD,
        params: {
            userId: args.userId,
            timeNextDownload: args.timeNextDownload,
            times: args.times,
        },
    }).then((data: any) => {
        return data;
    });
};
export const checkUserInvitedApi = (args: { email: string }): Promise<any> => {
    return POST({
        url: APIConfig.CHECK_USER_INVITED,
        params: {
            email: args.email,
        },
    }).then((data: any) => {
        return data;
    });
};

export const updateQuotaUserApi = (args: {
    params: IParamsQuotaResourceUser;
}): Promise<any> => {
    return POST({
        url: APIConfig.UPDATE_QUOTA_DOWNLOAD_USER,
        params: args.params,
    }).then((data: any) => {
        return data;
    });
};

export const updateUserSharePinApi = (args: {
    links: string[];
    userId: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.UPDATE_USER_SHARE_PIN,
        params: args,
    }).then((data: any) => {
        return data;
    });
};
