import { isResourceBackground } from ".";
import { getSizeCellTable } from "../resource-user/utils/table";
import { IPosition } from "../shared/models/module";
import { IPageWorksheetNew } from "../shared/models/pageWorksheetNew";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../shared/models/resourceItemNew";
import {
    ITableAttribute,
    TableAttribute,
} from "../shared/models/tableAttribute";
import ConstantsTool from "../shared/utils/ConstantsTool";

export enum Alignment {
    top,
    bottom,
    right,
    left,
    centerVertical,
    centerHorizontal,
    centerPageV,
    centerPageH,
    topBottom,
    bottomTop,
    lefRight,
    rightLeft,
    centerLeft,
    centerRight,
    centerTop,
    centerBottom,
    topCenter,
    bottomCenter,
    rightCenter,
    leftCenter,
    alighHorizontalCenter,
    alighVerticalCenter,
}

export class Align {
    top: number;
    left: number;
    vertical: boolean;
    align: Alignment;
    width: number | null;
    constructor(object: {
        top: number;
        left: number;
        vertical: boolean;
        align: Alignment;
        width?: number;
    }) {
        this.top = object.top;
        this.left = object.left;
        this.align = object.align;
        this.vertical = object.vertical;
        this.width = object.width ?? null;
    }
}

export class AlignItem {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    maxX: number;
    maxY: number;
    constructor(object: {
        id: string;
        x: number;
        y: number;
        width: number;
        height: number;
        maxX: number;
        maxY: number;
    }) {
        this.id = object.id;
        this.x = object.x;
        this.y = object.y;
        this.width = object.width;
        this.height = object.height;
        this.maxX = object.maxX;
        this.maxY = object.maxY;
    }
}

export const resourceCanAlign = (
    resourceItem: IResourceItemNew,
    currentElement: IResourceItemNew
) => {
    if (
        resourceItem.type !== ConstantsTool.TYPE_LOGO &&
        resourceItem.type !== ConstantsTool.RESOURCE_BACKGROUND &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_BORDER &&
        resourceItem.id !== currentElement.id &&
        resourceItem.isShow
    ) {
        return true;
    }
    return false;
};

const findCenter = (points: { x: number; y: number }[]) => {
    const sumX = points.reduce((sum, vertex) => sum + vertex.x, 0);
    const sumY = points.reduce((sum, vertex) => sum + vertex.y, 0);
    const centerX = sumX / points.length;
    const centerY = sumY / points.length;
    return { x: centerX, y: centerY };
};
export const convertPointsToBox = (finalPoints: { x: number; y: number }[]) => {
    let minX = finalPoints.reduce((previousElement, currentElement) => {
        if (currentElement.x < previousElement.x) {
            return currentElement;
        }
        return previousElement;
    }, finalPoints[0]).x;
    let maxX = finalPoints.reduce((previousElement, currentElement) => {
        if (currentElement.x > previousElement.x) {
            return currentElement;
        }
        return previousElement;
    }, finalPoints[0]).x;
    let minY = finalPoints.reduce((previousElement, currentElement) => {
        if (currentElement.y < previousElement.y) {
            return currentElement;
        }
        return previousElement;
    }, finalPoints[0]).y;
    let maxY = finalPoints.reduce((previousElement, currentElement) => {
        if (currentElement.y > previousElement.y) {
            return currentElement;
        }
        return previousElement;
    }, finalPoints[0]).y;
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
        points: finalPoints,
    };
};
export const getSizeResourceItem = (
    x: number,
    y: number,
    width: number,
    height: number,
    rotationAngle: number
) => {
    // Function to rotate the quadrilateral around its center
    const rotateQuadrilateral = (
        points: { x: number; y: number }[],
        rotationAngle: number
    ) => {
        const center = findCenter(points);
        const radians = (rotationAngle * Math.PI) / 180;

        // Translate vertices to make center the origin
        const translatedVertices = points.map((vertex) => ({
            x: vertex.x - center.x,
            y: vertex.y - center.y,
        }));

        // Apply rotation transformation
        const rotatedVertices = translatedVertices.map(({ x, y }) => ({
            x: x * Math.cos(radians) - y * Math.sin(radians),
            y: x * Math.sin(radians) + y * Math.cos(radians),
        }));

        // Translate vertices back to their original positions
        const finalVertices = rotatedVertices.map((vertex) => ({
            x: vertex.x + center.x,
            y: vertex.y + center.y,
        }));

        return finalVertices;
    };
    let finalPoints = rotateQuadrilateral(
        [
            { x, y },
            { x: x + width, y },
            { x: x + width, y: y + height },
            { x, y: y + height },
        ],
        rotationAngle
    );
    return convertPointsToBox(finalPoints);
};

export const findOriginalVertices = (
    points: { x: number; y: number }[],
    rotationAngle: number
) => {
    const center = findCenter(points);
    const radians = (-rotationAngle * Math.PI) / 180; // Reverse the rotation

    // Translate vertices to make center the origin
    const translatedVertices = points.map((vertex) => ({
        x: vertex.x - center.x,
        y: vertex.y - center.y,
    }));

    // Apply reverse rotation transformation
    const originalVertices = translatedVertices.map(({ x, y }) => ({
        x: x * Math.cos(radians) - y * Math.sin(radians),
        y: x * Math.sin(radians) + y * Math.cos(radians),
    }));

    // Translate vertices back to their original positions
    const finalOriginalVertices = originalVertices.map((vertex) => ({
        x: vertex.x + center.x,
        y: vertex.y + center.y,
    }));
    return convertPointsToBox(finalOriginalVertices);
};

export const getAlignVerticalItems = (
    verticalItems: AlignItem[],
    d: number
) => {
    let finalItemVertiacal: AlignItem[][] = [];
    verticalItems = verticalItems.sort((a, b) => a.y - b.y);

    for (let i = 1; i < verticalItems.length - 1; i++) {
        let item = verticalItems[i];
        let previousItem = verticalItems[i - 1],
            nextItem = verticalItems[i + 1],
            dyi = item.y - previousItem.maxY,
            dyj = (nextItem.y - previousItem.maxY) / 2 - item.height / 2;

        if (dyi > dyj - d && dyi < dyj + d && dyi > 0) {
            finalItemVertiacal.push([previousItem, item, nextItem]);
        }
    }
    return finalItemVertiacal;
};

export const getAlignHorizontalItems = (
    horizontalItems: AlignItem[],
    d: number
) => {
    let finalItemHorizontal: AlignItem[][] = [];
    horizontalItems = horizontalItems.sort((a, b) => a.x - b.x);
    for (let i = 1; i < horizontalItems.length - 1; i++) {
        let item = horizontalItems[i],
            previousItem = horizontalItems[i - 1],
            nextItem = horizontalItems[i + 1],
            dxi = item.x - previousItem.maxX,
            dxj = (nextItem.x - previousItem.maxX) / 2 - item.width / 2;

        if (dxi > dxj - d && dxi < dxj + d && dxi > 0) {
            finalItemHorizontal.push([previousItem, item, nextItem]);
        }
    }
    return finalItemHorizontal;
};

export const checkPositionElement = ({
    resourceItem,
    pageSizes,
    currentPage,
    ratio,
    newPageIndex,
}: {
    resourceItem: IResourceItemNew;
    currentPage: number;
    pageSizes: { width: number; height: number }[];
    ratio: number;
    newPageIndex?: number;
}) => {
    const d = 38 / ratio;
    let dTop = 0,
        result = getSizeResourceItem(
            resourceItem.x,
            resourceItem.y,
            resourceItem.width,
            resourceItem.height,
            resourceItem.rotate
        ),
        x = result.x,
        y = result.y,
        width = result.width,
        height = result.height,
        maxX = x + width,
        isOutPage = false,
        pageIndex = currentPage,
        dy = y - resourceItem.y;

    for (let i = 0; i <= currentPage; i++) {
        if (i === 0) {
            y += d;
        } else {
            y += pageSizes[i - 1].height + d;
        }
    }

    let maxY = y + height;

    if (maxX < 0) {
        isOutPage = true;
    }
    let newY = y;
    for (let i in pageSizes) {
        let page = pageSizes[i];
        let index = parseInt(i);
        if (newPageIndex) {
            if (index === newPageIndex) {
                pageIndex = newPageIndex;
                isOutPage = false;
                newY = y - (dTop + d);
                break;
            }
        } else {
            if (x > page.width) {
                isOutPage = true;
                break;
            }
            if (y > dTop && maxY < dTop + d) {
                isOutPage = true;
                break;
            }
            if (maxY > dTop + d && y < page.height + dTop + d) {
                pageIndex = index;
                newY = y - (dTop + d);
                if (currentPage === index) {
                    break;
                }
            }
        }
        dTop = page.height + dTop + d;
    }
    newY -= dy;
    let resourceItemNew = new ResourceItemNew({
        ...resourceItem,
        y: newY,
        pageIndex,
    });
    if (isResourceBackground(resourceItem.type)) {
        resourceItemNew.x = 0;
        resourceItemNew.y = 0;
        resourceItemNew.width = pageSizes[pageIndex].width;
        resourceItemNew.height = pageSizes[pageIndex].height;
    }
    return { isOutPage, pageIndex, resourceItemNew };
};
export const getPositionBox = (params: {
    startPoint: { x: number; y: number };
    endPoint: { x: number; y: number };
}) => {
    let { startPoint, endPoint } = params;
    let width = Math.abs(endPoint.x - startPoint.x),
        height = Math.abs(endPoint.y - startPoint.y),
        top = startPoint.y,
        left = startPoint.x;
    if (startPoint.x >= endPoint.x && startPoint.y >= endPoint.y) {
        top = endPoint.y;
        left = endPoint.x;
    }
    if (startPoint.x < endPoint.x && startPoint.y > endPoint.y) {
        top = startPoint.y - height;
        left = startPoint.x;
    }

    if (startPoint.x > endPoint.x && startPoint.y < endPoint.y) {
        top = startPoint.y;
        left = startPoint.x - width;
    }
    return { x: left, y: top, width: width, height: height };
};

export const rotateBox = (
    x: number,
    y: number,
    width: number,
    height: number,
    rotationAngle: number
) => {
    const rotateQuadrilateral = (
        points: { x: number; y: number }[],
        rotationAngle: number
    ) => {
        const radians = (rotationAngle * Math.PI) / 180;
        const finalVertices = points.map(({ x, y }) => ({
            x: x * Math.cos(radians) - y * Math.sin(radians),
            y: x * Math.sin(radians) + y * Math.cos(radians),
        }));
        return finalVertices;
    };
    let finalPoints = rotateQuadrilateral(
        [
            { x, y },
            { x: x + width, y },
            { x: x + width, y: y + height },
            { x, y: y + height },
        ],
        rotationAngle
    );
    return finalPoints;
};

export const rotatePoint = (x: number, y: number, rotationAngle: number) => {
    const radians = (rotationAngle * Math.PI) / 180;
    return {
        x: x * Math.cos(radians) - y * Math.sin(radians),
        y: x * Math.sin(radians) + y * Math.cos(radians),
    };
};

export const getPositionMoveElement = (
    e: any,
    pageWorksheet: IPageWorksheetNew,
    currentElement: IResourceItemNew,
    spaceToTopLeft: { x: number; y: number },
    ratio: number,
    dTop: number
) => {
    const d = 5;
    let pages = document.getElementsByClassName("not-click");
    let position = pages[0].getBoundingClientRect();

    let originX = (e.clientX - position.x - spaceToTopLeft.x) / ratio,
        originY = (e.clientY - 24 - spaceToTopLeft.y + dTop) / ratio,
        width = currentElement.width,
        height = currentElement.height,
        result = getSizeResourceItem(
            originX,
            originY,
            width,
            height,
            currentElement.rotate
        );
    let x = result.x,
        y = result.y;
    width = result.width;
    height = result.height;
    let maxX = x + width,
        maxY = y + height,
        points = result.points,
        dx = 0,
        dy = 0,
        centerX = pageWorksheet.width / 2,
        centerY = pageWorksheet.height / 2;

    if (x + width / 2 > centerX - d && x + width / 2 < centerX + d) {
        let xC = centerX - width / 2;
        dx = xC - x;
    }

    if (x > centerX - d && x < centerX + d) {
        let xC = centerX;
        dx = xC - x;
    }

    if (maxX > centerX - d && maxX < centerX + d) {
        let xC = centerX - width;
        dx = xC - x;
    }

    if (y + height / 2 > centerY - d && y + height / 2 < centerY + d) {
        let yC = pageWorksheet.height / 2 - height / 2;
        dy = yC - y;
    }

    if (y > centerY - d && y < centerY + d) {
        let yC = centerY;
        dy = yC - y;
    }

    if (maxY > centerY - d && maxY < centerY + d) {
        let yC = centerY - height;
        dy = yC - y;
    }
    let alginItem = new AlignItem({
        id: currentElement.id,
        x,
        y,
        width,
        height,
        maxX,
        maxY,
    });
    let horizontalItems: AlignItem[] = [alginItem],
        verticalItems: AlignItem[] = [alginItem];
    const resourceItems = pageWorksheet.resourceItems;
    for (let index in resourceItems) {
        let resourceItem = resourceItems[index];
        if (resourceCanAlign(resourceItem, currentElement)) {
            let newSize = getSizeResourceItem(
                resourceItem.x,
                resourceItem.y,
                resourceItem.width,
                resourceItem.height,
                resourceItem.rotate
            );
            let xE = newSize.x,
                yE = newSize.y,
                widthE = newSize.width,
                heightE = newSize.height,
                maxXE = xE + widthE,
                maxYE = yE + heightE;

            let item = new AlignItem({
                id: resourceItem.id,
                x: xE,
                y: yE,
                width: widthE,
                height: heightE,
                maxX: maxXE,
                maxY: maxYE,
            });
            if (
                (y >= yE && y <= maxYE) ||
                (maxY <= maxYE && maxY >= yE) ||
                (yE >= y && yE <= maxY) ||
                (maxYE <= maxY && maxYE >= y)
            ) {
                horizontalItems.push(item);
            }

            if (
                (x >= xE && x <= maxXE) ||
                (maxX > xE && maxX <= maxXE) ||
                (xE >= x && xE <= maxX) ||
                (maxXE > x && maxXE <= maxX)
            ) {
                verticalItems.push(item);
            }
            if (x > xE - d && x < xE + d) {
                dx = xE - x;
            }
            if (y > yE - d && y < yE + d) {
                dy = yE - y;
            }
            if (maxX > maxXE - d && maxX < maxXE + d) {
                dx = maxXE - width - x;
            }
            if (maxY > maxYE - d && maxY < maxYE + d) {
                dy = maxYE - height - y;
            }
            if (
                x + width / 2 > xE + widthE / 2 - d &&
                x + width / 2 < xE + widthE / 2 + d
            ) {
                dx = xE + widthE / 2 - width / 2 - x;
            }
            if (
                y + height / 2 > yE + heightE / 2 - d &&
                y + height / 2 < yE + heightE / 2 + d
            ) {
                dy = yE + heightE / 2 - height / 2 - y;
            }
            if (x > maxXE - d && x < maxXE + d) {
                dx = maxXE - x;
            }
            if (y > maxYE - d && y < maxYE + d) {
                dy = maxYE - y;
            }
            if (maxX > xE - d && maxX < xE + d) {
                dx = xE - width - x;
            }
            if (maxY > yE - d && maxY < yE + d) {
                dy = yE - height - y;
            }
            if (y + height / 2 > yE - d && y + height / 2 < yE + d) {
                dy = yE - height / 2 - y;
            }
            if (y + height / 2 > maxYE - d && y + height / 2 < maxYE + d) {
                dy = maxYE - height / 2 - y;
            }
            if (x + width / 2 > xE - d && x + width / 2 < xE + d) {
                dx = xE - width / 2 - x;
            }
            if (x + width / 2 > maxXE - d && x + width / 2 < maxXE + d) {
                dx = maxXE - width / 2 - x;
            }

            if (y > yE + heightE / 2 - d && y < yE + heightE / 2 + d) {
                dy = yE + heightE / 2 - y;
            }
            if (maxY > yE + heightE / 2 - d && maxY < yE + heightE / 2 + d) {
                dy = yE + heightE / 2 - height - y;
            }
            if (x > xE + widthE / 2 - d && x < xE + widthE / 2 + d) {
                dx = xE + widthE / 2 - x;
            }
            if (maxX > xE + widthE / 2 - d && maxX < xE + widthE / 2 + d) {
                dx = xE + widthE / 2 - width - x;
            }
        }
    }
    let finalItemHorizontal = getAlignHorizontalItems(horizontalItems, d);

    let alginItemsH = finalItemHorizontal.find((item) =>
        item.find((e) => e.id === currentElement.id)
    );
    if (alginItemsH) {
        let index = alginItemsH.findIndex(
            (item) => item.id === currentElement.id
        );
        switch (index) {
            case 0:
                dx =
                    alginItemsH[1].x -
                    alginItemsH[2].x +
                    alginItemsH[1].maxX -
                    width -
                    x;
                break;
            case 1:
                dx =
                    alginItemsH[0].maxX +
                    (alginItemsH[2].x - alginItemsH[0].maxX) / 2 -
                    width / 2 -
                    x;
                break;
            case 2:
                dx =
                    alginItemsH[1].x -
                    alginItemsH[0].maxX +
                    alginItemsH[1].maxX -
                    x;
                break;
            default:
                break;
        }
    }
    let finalItemVertiacal = getAlignVerticalItems(verticalItems, d);
    let alginItemsV = finalItemVertiacal.find((item) =>
        item.find((e) => e.id === currentElement.id)
    );
    if (alginItemsV) {
        let index = alginItemsV.findIndex(
            (item) => item.id === currentElement.id
        );
        switch (index) {
            case 0:
                dy =
                    alginItemsV[1].y -
                    alginItemsV[2].y +
                    alginItemsV[1].maxY -
                    height -
                    y;
                break;
            case 1:
                dy =
                    alginItemsV[0].maxY +
                    (alginItemsV[2].y - alginItemsV[0].maxY) / 2 -
                    height / 2 -
                    y;
                break;
            case 2:
                dy =
                    alginItemsV[1].y -
                    alginItemsV[0].maxY +
                    alginItemsV[1].maxY -
                    y;
                break;
            default:
                break;
        }
    }

    points.map((point) => {
        point.x += dx;
    });
    let valueX = findOriginalVertices(points, currentElement.rotate);
    originX = valueX.x;

    points.map((point) => {
        point.y += dy;
    });
    let valueY = findOriginalVertices(points, currentElement.rotate);
    originY = valueY.y;
    return { x: originX, y: originY };
};

export const getPositionMoreTable = ({
    tableAttribute,
    positions,
    strokeWidth,
}: {
    tableAttribute: ITableAttribute;
    positions?: IPosition[];
    strokeWidth?: number;
}) => {
    const getPositionMore = (
        postions: { top: number; left: number }[]
    ): { top: number; left: number } => {
        let top = 0,
            left = 0,
            positions = postions.filter((el) => el.left > 0 && el.top > 0);

        if (positions.length) {
            let minLeft = positions.reduce((cur, next) => {
                if (cur.left < next.left) {
                    return cur;
                }
                return next;
            }, positions[0]).left;
            let maxLeft = positions.reduce((cur, next) => {
                if (cur.left > next.left) {
                    return cur;
                }
                return next;
            }, positions[0]).left;
            let minTop = positions.reduce((cur, next) => {
                if (cur.top < next.top) {
                    return cur;
                }
                return next;
            }, positions[0]).top;
            let maxTop = positions.reduce((cur, next) => {
                if (cur.top > next.top) {
                    return cur;
                }
                return next;
            }, positions[0]).top;
            top = minTop + (maxTop - minTop) / 2;
            left = minLeft + (maxLeft - minLeft) / 2;
        }
        return { top, left };
    };
    let table = new TableAttribute(tableAttribute);

    let colValue = { min: 0, max: table.data[0].length - 1 };
    let rowValue = { min: 0, max: table.data.length - 1 };

    if (positions?.length) {
        if (positions.length > 1) {
            colValue.min = positions[0].col;
            colValue.max = positions[1].col;
            rowValue.min = positions[0].row;
            rowValue.max = positions[1].row;
        } else {
            colValue.min = positions[0].col;
            colValue.max = positions[0].col;
            rowValue.min = positions[0].row;
            rowValue.max = positions[0].row;
        }
    } else {
        if (tableAttribute?.currentPositions?.length) {
            colValue = getMaxMinNumbers(
                table.currentPositions.map((a) => a.column)
            );
            rowValue = getMaxMinNumbers(
                table.currentPositions.map((a) => a.row)
            );
        }
    }
    let positionMores: { top: number; left: number }[] = [];
    let x = -1,
        y = -1,
        dwidth = 0,
        dheight = 0,
        // borderWidth = 0,
        gap = table.gap;

    table.data.forEach((cells, row) => {
        cells.forEach((cell, col) => {
            if (
                (row === rowValue.min && col === colValue.min) ||
                (row === rowValue.max && col === colValue.max)
            ) {
                let { top, left, width, height, dGap } = getSizeCellTable({
                    tableAttribute: table,
                    row,
                    col,
                });
                if (x === -1 && y === -1) {
                    y = top;
                    x = left;
                }
                positionMores.push({
                    top: top + height / 2,
                    left: left + width / 2,
                });
                if (row === rowValue.max && col === colValue.max) {
                    let d = 2 * (strokeWidth ?? 0);
                    dheight = top + (height - d) - y;
                    dwidth = left + (width - d) - x;
                    if (positions?.length) {
                        dheight = top + (height - d) - y;
                        dwidth = left + (width - d) - x;
                    }
                }
            }
        });
    });
    let position = getPositionMore(positionMores);
    return {
        top: position.top,
        left: position.left,
        x,
        y,
        width: dwidth,
        height: dheight,
    };
};

export const getMaxMinNumbers = (list: number[]) => {
    let max = list.reduce((cur, next) => {
        if (cur >= next) {
            return cur;
        }
        return next;
    }, list[0]);

    let min = list.reduce((cur, next) => {
        if (cur <= next) {
            return cur;
        }
        return next;
    }, list[0]);
    return { max: max, min: min };
};
