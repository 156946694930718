import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hook';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import Select from 'react-select';
import ToolTipCustomize from '../../common/library/tool-tip';
import { TypeOfPlacementTooltip } from '../../common/library/render-tooltip';
import { ConstantsApp } from '../../../../utils/Constants';
import Table from 'react-bootstrap/Table';

import './styles.scss';
import Validator from '../../../../utils/validator';
import { ValidatorZone } from '../../../../shared/models/resourceItemNew';
import { setCurrentElementFocus } from '../../../../redux/reducers/createWorksheet';
import { useDispatch } from 'react-redux';

const ValidatorModal = ({
    zoneId,
    fieldName,
    hideModal,
    shuffle,
    handleCurrentElementChange,
} : {
    zoneId: string,
    fieldName: string,
    hideModal: () => void,
    shuffle: boolean,
    handleCurrentElementChange: (updatedValues: object) => void,
}) => {
    const dispatch = useDispatch();

    const { pageType } = useAppSelector(
        (state) => state.createWorksheetState
    );

    const [selectedValidator, setSelectedValidator] = useState(null);
    const [zoneValidators, setZoneValidators] = useState<ValidatorZone[]>([]);
    const [validators, setValidators] = useState([]);
    const [isShuffle, setIsShuffle] = useState(shuffle ?? false);

    useEffect(() => {
        fetchValidators();
        fetchZoneValidators();
        dispatch(setCurrentElementFocus(false));
    }, [])

    const fetchValidators = async () => {
        const res = await getDataFromAPI('auto-content/validators');
        setValidators(res.data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        })));
    }

    const fetchZoneValidators = async () => {
        const res = await getDataFromAPI(`auto-content/zone-validators/?zoneID=${zoneId}`);
        handleZoneValidatorsChange(res.data);
    }

    const addZoneValidator = async() => {
        const res = await updateDataAPI('POST', `auto-content/zone-validators/`, {
            zone: zoneId,
            validator: selectedValidator.value,
            metaData: {}
        });
        setSelectedValidator(null);
        const updatedZoneValidators = zoneValidators.concat(res.data);
        handleZoneValidatorsChange(updatedZoneValidators);
        handleZoneValidatorsChange(updatedZoneValidators);
    };

    const updateZoneValidator = async(item) => {
        const res = await updateDataAPI('PATCH', 'auto-content/zone-validators/' + item.id + '/', {
            metaData: item.metaData
        });
        window.alert('Updated');
        const updatedZoneValidators = zoneValidators.map(zv => {
            if (zv.id === item.id) {
                return {...zv, metaData: res.data['metaData']};
            }
            return zv;
        });
        handleZoneValidatorsChange(updatedZoneValidators);
    }

    const removeZoneValidator = async(item) => {
        await updateDataAPI('DELETE', `auto-content/zone-validators/${item.id}/`);
        const updatedZoneValidators = [...zoneValidators].filter(zv => zv.id !== item.id);
        handleZoneValidatorsChange(updatedZoneValidators);
    };

    const onChangeMetaData = (item, field, value) => {
        const updatedZoneValidators = [...zoneValidators].map(itemV => {
            return itemV.id === item.id ? {
                ...itemV,
                metaData: {
                    ...item.metaData,
                    [field]: value
                }
            } : itemV
        });
        handleZoneValidatorsChange(updatedZoneValidators);
    }

    const handleZoneValidatorsChange = (updatedValue: ValidatorZone[]) => {
        setZoneValidators(updatedValue);
        handleCurrentElementChange({ validators: updatedValue });
    }

    const handleDesignItemShuffleChange = (e) => {
        const updatedValue = e.target.checked;
        setIsShuffle(updatedValue);
        handleCurrentElementChange({ shuffle: updatedValue });
    };

    return (
        <Modal show={true} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {pageType === "template"
                        ? "Set Validator"
                        : "Validator " + fieldName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <div className="validator-modal-choose-validate-type-wrapper">
                                <Form.Group>
                                    <Form.Label>
                                        Add New Validator
                                    </Form.Label>
                                    <Select
                                        value={selectedValidator}
                                        onChange={(value) =>
                                            setSelectedValidator(value)
                                        }
                                        options={validators}
                                        isSearchable
                                    />
                                </Form.Group>
                                {selectedValidator && (
                                    <Button onClick={() => addZoneValidator()}>
                                        Add
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <div className="validator-modal-list-validator-title">
                        List Validators
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Validator</th>
                                <th>Other</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {zoneValidators.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="">
                                                {item.validatorName}
                                            </div>
                                        </td>
                                        <td>
                                                {/* {
                                                    item.code === 'zones_relationship'
                                                    &&
                                                    <div>
                                                        {resourceItems && (
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Related zone</Form.Label>
                                                            <Select
                                                                value={item.metaData?.relatedZone ?? ""}
                                                                onChange={(value) =>onChangeMetaData(item,'relatedZone',value)                                                                }
                                                                options={zones}
                                                                isSearchable
                                                            />
                                                        </Form.Group>
                                                    )}
                                                    </div>
                                                } */}
                                                {
                                                    item.validatorName === Validator.WORD_COUNT_LABEL
                                                    && <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Min Words</Form.Label>
                                                                <Form.Control value={item.metaData?.min ?? ""} onChange={e => onChangeMetaData(item,'min',e.target.value)} type="number" min={1} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Max Words</Form.Label>
                                                                <Form.Control value={item.metaData?.max ?? ""} onChange={e => onChangeMetaData(item,'max',e.target.value)} type="number" min={1} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                        </td>
                                        <td>
                                            <ToolTipCustomize title="Update" placement={ TypeOfPlacementTooltip.top }
                                                style={ ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS }
                                            >
                                                <div className={ "set-validator-name-action" }
                                                    onClick={() =>
                                                        updateZoneValidator(item)
                                                    }
                                                >
                                                    <CheckIcon />
                                                </div>
                                            </ToolTipCustomize>
                                            <ToolTipCustomize title="Remove" placement={ TypeOfPlacementTooltip.top }
                                                style={ ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS }
                                            >
                                                <div className={ "set-validator-name-action" }
                                                    onClick={() =>
                                                        removeZoneValidator(item)
                                                    }
                                                >
                                                    <CloseIcon />
                                                </div>
                                            </ToolTipCustomize>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    {pageType === 'template' && (
                        <Row className="mb-2">
                            <Col>
                                <Form>
                                    <Form.Check
                                        id="field-shuffle-checkbox"
                                        type="checkbox"
                                        label="Shuffle"
                                        checked={isShuffle}
                                        onChange={handleDesignItemShuffleChange}
                                    />
                                </Form>
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ValidatorModal;