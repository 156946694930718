import { ScreenSize } from "../../../shared/models/game";
import { IWorksheet, Worksheet } from "../../../shared/models/worksheet";
import { getScreenSizeFromURL } from "../../utils";
import { APIConfig } from "../../utils/api-config";

import { ICollection } from "../../../shared/models/collection";
import { GET, POST } from "./index";
export const getWorkSheetByIdsAPI = (
    ids: string[]
): Promise<IWorksheet[] | null> => {
    return POST({
        url: APIConfig.GET_WORK_SHEET_BYidS,
        params: {
            ids,
        },
    }).then((data: any) => {
        return data
            ? data.map((worksheet) => {
                  let ws = new Worksheet(worksheet);
                  let usingScreenImg = false;
                  if (ws.originUrl?.includes("liveworksheets.com")) {
                      usingScreenImg = true;
                  } else if (
                      ws.originUrl == "user_created_ws" &&
                      ws.game.answers?.length
                  ) {
                      ws.game.answers.forEach((answer) => {
                          if (
                              answer.width > 0 &&
                              answer.left + answer.width >
                                  ws.game.screenSize.width
                          ) {
                              //   console.log("//tràn width ", answer);
                              usingScreenImg = true;
                          } else if (
                              answer.height > 0 &&
                              ws.game.screenSizes[answer.page]?.height &&
                              answer.top + answer.height >
                                  ws.game.screenSizes[answer.page].height
                          ) {
                              //   console.log(" //tràn height ", answer);
                              usingScreenImg = true;
                          }
                      });
                  }
                  try {
                      let time = new Date(ws.createdDate).getTime();
                      let maxTime = new Date(
                          "2022-12-16T00:00:00.000Z"
                      ).getTime();
                      let minTime = new Date(
                          "2022-12-20T12:00:00.000Z"
                      ).getTime();
                      if (time < maxTime && time > minTime) {
                          usingScreenImg = false;
                      }
                      if (time > maxTime) {
                          usingScreenImg = true;
                      }
                  } catch (error) {}
                  if (ws.game.screenSizes.length == 0) {
                      usingScreenImg = true;
                  }
                  if (ws.game.images?.length && usingScreenImg) {
                      let screenSizes = [];
                      ws.game.images.forEach((image) => {
                          let { width, height } = getScreenSizeFromURL(
                              image,
                              []
                          );
                          if (width && height) {
                              screenSizes.push(
                                  new ScreenSize({
                                      width,
                                      height,
                                  })
                              );
                          }
                      });
                      if (screenSizes?.length) {
                          ws.game.screenSizes = screenSizes;
                      }
                  }
                  return ws;
              })
            : null;
    });
};

export const getWorkSheetByUserAPI = (args: {
    id: string;
    worksheetType: number;
    offset: number;
    limit: number;
    arrProject?: any[];
}): Promise<{
    numberOfWorksheets: number;
    worksheets: IWorksheet[];
} | null> => {
    return POST({
        url: APIConfig.GET_WORK_SHEET_BY_USERid_V2 + "/" + args.id,
        params: args,
    }).then((data: any) => {
        //
        return (
            data && {
                worksheets: data.worksheets.map((e: any) => new Worksheet(e)),
                numberOfWorksheets: data.numberOfWorksheets,
            }
        );
    });
};

export const updateWorksheetByIdAPI = (params): Promise<IWorksheet | null> => {
    return POST({
        url: APIConfig.UPDATE_WORKSHEET,
        params,
    }).then((data: any) => {
        return data && new Worksheet(data);
    });
};

export const getWorkSheetDraftByUserIdAPI = (
    userId: string
): Promise<IWorksheet[] | null> => {
    return POST({
        url: APIConfig.GET_WORKSHEETS_DRAFT,
        params: {
            userId,
        },
    }).then((data: any) => {
        return data ? data.map((worksheet) => new Worksheet(worksheet)) : null;
    });
};

export const getPracticeWorksheetByUserIdAPI = (args: {
    userId: string;
    offset?: number;
    limit?: number;
    worksheetType?: number;
}): Promise<{
    worksheets: IWorksheet[];
    numberOfWorksheets: number;
} | null> => {
    return POST({
        url: APIConfig.GET_PRACTICE_WORKSHEET_V2,
        params: args,
    }).then((data: any) => {
        return (
            data && {
                worksheets: data.worksheets.map((e: any) => new Worksheet(e)),
                numberOfWorksheets: data.numberOfWorksheets,
            }
        );
    });
};

export const deleteWorksheetByStatusApi = (args: {
    worksheetId: string;
}): Promise<number | null> => {
    return POST({
        url: APIConfig.DELETE_WORKSHEET_BY_STATUS,
        params: {
            worksheetId: args.worksheetId,
        },
    }).then((data: any) => {
        return data;
    });
};
export const indexCollectionApi = (args: ICollection) => {
    return POST({
        url: APIConfig.ADD_REMOVE_WORKSHEET_TO_COLLECTION,
        params: args,
    });
};

export const getNumberWsDefaultCollectionAPI = (args: {
    userId: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.GET_NUMBER_WORKSHEETS_DEFAULT_FOLDER,
        params: args,
    }).then((data: any) => {
        return data;
    });
};

export const uploadImagesFromBase64API = async (urls: string[]) => {
    const urlLinks = await POST({
        url: APIConfig.UPLOAD_MULTIPLE_IMAGE_FROM_BASE64,
        params: { imagesBase64: urls },
    });
    return urlLinks;
};

export const uploadImageAPI = async ({
    title,
    worksheetId,
    listImageBase64 = [],
    save_prod,
}: {
    title: string;
    worksheetId: string;
    listImageBase64: string[];
    save_prod: string;
}) => {
    let result = [];
    try {
        if (!title?.length) {
            title = "My Word Search Title";
        }
        if (!worksheetId || listImageBase64?.length === 0) {
            throw new Error("Error when upload image");
        }
        const data = new FormData();
        data.append("file", new File([""], ""));
        data.append("title", title);
        data.append("worksheetid", worksheetId);
        data.append("content_type", "image");
        data.append("save_prod", save_prod + "");
        data.append("use_qr", "true");

        const MAX_CHARACTER = 200;
        const fileName = title.slice(0, MAX_CHARACTER);
        data.append("filename", `${fileName}.png`);
        listImageBase64.forEach((imageBase64) => {
            data.append("base64_str_list", imageBase64);
        });

        result = await POST({
            baseURL: "https://detect.worksheetzone.org/",
            url: "/upload-file-v2",
            params: data,
        });
    } catch (error) {
        console.log("error", error);
    }
    return result;
};

export const updateWorksheetCreatedByWordSearchAPI = async ({
    workSheet,
}: {
    workSheet: Worksheet;
}) => {
    const urlLinks = await POST({
        url: APIConfig.UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH,
        params: { workSheet },
    });
    return urlLinks;
};

export const getGPTword = async ({
    topic,
    limitWord,
    limitLength,
}: {
    topic: string;
    limitWord: number;
    limitLength: number;
}) => {
    const urlLinks = await POST({
        url: APIConfig.GET_KEYWORD_GPT,
        params: { topic, limitWord, limitLength },
    });
    return urlLinks;
};

export const getSurveyDataAPI = async (userId: string) => {
    const surveyData = await GET({
        url: APIConfig.GET_SURVEY_WORKSHEET + "/" + userId,
    });
    return surveyData;
};

export const updateSurveyAPI = async ({
    userId,
    subjects,
    grades,
}: {
    userId: string;
    subjects?: string[];
    grades?: string[];
}) => {
    const response = await POST({
        url: APIConfig.UPDATE_SURVEY_WORKSHEET,
        params: {
            userId,
            subjects,
            grades,
        },
    });
    return response;
};

export const addWorksheetToDownloadedAPI = async ({
    userId,
    worksheetId,
}: {
    userId: string;
    worksheetId: string;
}) => {
    const response = await POST({
        url: APIConfig.ADD_DOWNLOADED_WORKSHEET,
        params: {
            userId,
            worksheetId,
        },
    });
    return response;
};

export const deleteWorksheetFromDownloadedAPI = async ({
    userId,
    worksheetIds,
}: {
    userId: string;
    worksheetIds: string[];
}) => {
    const response = await POST({
        url: APIConfig.DELETE_DOWNLOADED_WORKSHEET,
        params: {
            userId,
            worksheetIds,
        },
    });
    return response;
};

export const addImageWorksheetOfCollectionApi = async ({
    collectionId,
}: {
    collectionId: string;
}) => {
    const response = await POST({
        url: APIConfig.GET_ALL_WORKSHEET_COLLECTION,
        params: {
            collectionId,
        },
    });
    return response;
};

export const getSuggestTagsDataAPI = async ({
    typeActivity,
}: {
    typeActivity: string;
}) => {
    const result = await POST({
        url: APIConfig.GET_SUGGEST_TAGS_POPUP_SAVE,
        params: { typeActivity },
    });
    return result;
};

export const uploadBase64ToGoogleCloudAPI = async (args: {
    imagesBase64: string[];
    isAddWatermark?: boolean;
}) => {
    try {
        return POST({
            url: APIConfig.UPLOAD_BASE64_TO_GOOGLE_CLOUD,
            params: {
                imagesBase64: args.imagesBase64,
                isAddWatermark: args.isAddWatermark,
            },
        }).then((data: any) => {
            return data;
        });
    } catch (e) {}
};

export const getWorkSheetAPI = (
    id: string,
    loadFull = true
): Promise<IWorksheet | null> => {
    return POST({
        url: APIConfig.GET_WORK_SHEET_BYid,
        params: {
            id,
            loadFull,
        },
    }).then((data: any) => {
        if (data) {
            data = new Worksheet(data);
        }
        return data;
    });
};

export const updateWorksheetCreatorAPI = (args: {
    worksheetId;
    templateIds;
}): Promise<{
    worksheetId: string;
    templateIds: string[];
} | null> => {
    return POST({
        url: APIConfig.CREATE_NEW_WS_FROM_TOOL_V1,
        params: {
            worksheetId: args.worksheetId,
            templateIds: args.templateIds,
        },
    }).then((data: any) => {
        //
        return data;
    });
};
