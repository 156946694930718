import { IAssignment } from "../../../shared/models/assignment";
import { IClass } from "../../../shared/models/class";
import { IWorksheet } from "../../../shared/models/worksheet";

export enum AssignDigitallyTypes {
    GET_CLASSES = "GET_CLASSES",
    GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS",
    GET_ASSIGNMENT = "GET_ASSIGNMENT",
    GET_ASSIGNMENT_SUCCESS = "GET_ASSIGNMENT_SUCCESS",
    CREATE_CLASS = "CREATE_CLASS",
    CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS",
    UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT",
    UPDATE_CLASSES_WHEN_CHECKED = "UPDATE_CLASSES_WHEN_CHECKED",
    UPDATE_CLASSES_WHEN_NOT_CHECKED = "UPDATE_CLASSES_WHEN_NOT_CHECKED",
}

export interface IAssignDigitallyAction {
    type: string;
    userId: string;
    classes: IClass[];
    assignments: IAssignment[];
    assignment: IAssignment;
    cls?: {
        teacherIds: string[];
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
    name: string;
    classInfo: IClass;
    worksheetId: string;
    worksheet: any;
    worksheets: IWorksheet[];
}

export const getClassesAction = () => {
    return {
        type: AssignDigitallyTypes.GET_CLASSES,
        // userId,
    };
};

export const getClassesSuccessAction = (classes: IClass[]) => {
    return {
        type: AssignDigitallyTypes.GET_CLASSES_SUCCESS,
        classes,
    };
};

export const getAssignmentAction = (worksheetId: string) => {
    return {
        type: AssignDigitallyTypes.GET_ASSIGNMENT,
        worksheetId,
    };
};

export const getAssignmentSuccessAction = (assignments: IAssignment[]) => {
    return {
        type: AssignDigitallyTypes.GET_ASSIGNMENT_SUCCESS,
        assignments,
    };
};

export const createClassAction = (
    name: string,
    worksheet?: any,
    worksheets?: IWorksheet[]
) => {
    return {
        type: AssignDigitallyTypes.CREATE_CLASS,
        name,
        worksheet,
        worksheets,
    };
};

export const createClassSuccessAction = (
    classInfo: IClass,
    assignment: IAssignment
) => {
    return {
        type: AssignDigitallyTypes.CREATE_CLASS_SUCCESS,
        classInfo,
        assignment,
    };
};

export const updateClassesWhenCheckedAction = (
    classes: IClass[],
    assignment: IAssignment
) => {
    return {
        type: AssignDigitallyTypes.UPDATE_CLASSES_WHEN_CHECKED,
        classes,
        assignment,
    };
};

export const updateClassesWhenNotCheckedAction = (
    classes: IClass[],
    assignment: IAssignment
) => {
    return {
        type: AssignDigitallyTypes.UPDATE_CLASSES_WHEN_NOT_CHECKED,
        classes,
        assignment,
    };
};

export const updateAssignmentAction = () => {
    return {
        type: AssignDigitallyTypes.UPDATE_ASSIGNMENT,
    };
};
