import { useDispatch, useSelector } from "react-redux";
import { ICollection } from "../../../../../../../shared/models/collection";
import { IWorksheet } from "../../../../../../../shared/models/worksheet";
import Config from "../../../../../../../shared/utils/config";
import {
    deleteWorksheetFromCollectionResourceAction,
    deleteWorksheetFromCreatedOrDownloadedResourceAction,
    deleteWorksheetFromDownloadedAction,
    selectActionGridItemAction,
} from "../../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../../redux/reducer/root.reducerModule";
import PopupCommon from "../../../../../common/pop-up";
import "./style.scss";
const DeleteWorksheets = () => {
    const dispatch = useDispatch();
    let actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState?.actionGridItem
    );
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    let worksheets = actionGridItem?.worksheets;
    let action = actionGridItem?.action;
    let currentCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.collectionSelected?.data
    );
    const deleteWss = ({
        worksheets,
        collection,
    }: {
        worksheets: IWorksheet[];
        collection: ICollection;
    }) => {
        const listWorksheetId = worksheets.map((ws) => ws.id);
        switch (collection.id) {
            case Config.WORKSHEETS_CREATED_COLLECTIONid:
                dispatch(
                    deleteWorksheetFromCreatedOrDownloadedResourceAction({
                        collection: collection,
                        worksheets: worksheets,
                    })
                );
                break;
            case Config.DOWNLOAD_COLLECTIONid:
                dispatch(
                    deleteWorksheetFromDownloadedAction({
                        userId: userInfo.id,
                        worksheetIds: listWorksheetId,
                    })
                );
                dispatch(
                    deleteWorksheetFromCreatedOrDownloadedResourceAction({
                        collection: collection,
                        worksheets: worksheets,
                    })
                );
                break;

            // My_collections
            default:
                dispatch(
                    deleteWorksheetFromCollectionResourceAction({
                        worksheets: worksheets,
                        collection: collection,
                    })
                );
                break;
        }
    };
    return (
        <PopupCommon
            classNameDialog="popup-delete-worksheets"
            title="Are you sure to delete this/these worksheets?"
            classNameTitle="popup-delete-worksheets-title"
            open={action == Config.ACTION_GRID.DELETE_WSS}
            setOpen={(value) => {
                if (!value) {
                    dispatch(
                        selectActionGridItemAction({
                            action: Config.ACTION_GRID.DEFAULT,
                        })
                    );
                }
            }}
            renderContent={() => {
                return (
                    <div>
                        You can't undo once you delete the selected worksheets
                    </div>
                );
            }}
            renderAction={() => {
                return (
                    <div className="popup-delete-worksheets-actions">
                        <div
                            className="actions-button button-cancel"
                            onClick={() => {
                                dispatch(
                                    selectActionGridItemAction({
                                        action: Config.ACTION_GRID.DEFAULT,
                                    })
                                );
                            }}
                        >
                            Cancel
                        </div>
                        <div
                            className="actions-button button-del"
                            onClick={() => {
                                deleteWss({
                                    worksheets,
                                    collection: currentCollection,
                                });
                            }}
                        >
                            Permanently deleted
                        </div>
                    </div>
                );
            }}
        ></PopupCommon>
    );
};

export default DeleteWorksheets;
