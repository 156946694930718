import { immerable } from "immer";
import ConstantsTool from "../utils/ConstantsTool";
import Config from "../utils/config";
import { IPosition, IWord } from "./crossword";

export interface IAnswerActivity {
    id: string;
    text: string;
    isCorrect: boolean;
    indexWord: number;
}

export class AnswerActivity implements IAnswerActivity {
    id: string;
    text: string;
    isCorrect: boolean;
    indexWord: number;

    constructor(obj: any = {}) {
        this.id = obj.id;
        this.isCorrect = obj.isCorrect;
        this.text = obj.text;
        this.indexWord = obj.indexWord ?? -1;
    }
}

export interface IHandwritingAttribute {
    autoFill: boolean;
    color: string;
    lineSpacing: number;
    numberOfLines: number;
    fontStyle: number;
    textStyle: number;
    fontFamily: string;
    resourceItemIds: string[];
    fontColor: string;
}
export class HandwritingAttribute implements IHandwritingAttribute {
    autoFill: boolean;
    color: string;
    lineSpacing: number;
    numberOfLines: number;
    fontStyle: number;
    textStyle: number;
    fontFamily: string;
    resourceItemIds: string[];
    fontColor: string;

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.autoFill = obj?.autoFill ?? false;
        this.color = obj?.color ?? "#000";
        this.lineSpacing = obj?.lineSpacing ?? 1;
        this.numberOfLines = obj?.numberOfLines ?? 1;
        this.fontStyle = obj?.fontStyle ?? 1;
        this.textStyle = obj?.textStyle ?? ConstantsTool.TEXT_STYLE.DASHED;
        this.fontFamily = obj?.fontFamily ?? "Standard Print";
        this.resourceItemIds = obj?.resourceItemIds ?? [];
        this.fontColor = obj?.fontColor ?? "#000";
    }
}

export interface IMultipleChoicesAttribute {
    numberCol: number;
    resourceItemIds: string[];
}
export class MultipleChoicesAttribute implements IMultipleChoicesAttribute {
    numberCol: number;
    resourceItemIds: string[];

    constructor(obj: any = {}) {
        this.numberCol = obj?.numberCol ?? 4;
        this.resourceItemIds = obj?.resourceItemIds ?? [];
    }
}
export interface INameTracingAttribute {
    autoFill: boolean;
    color: string;
    lineSpacing: number;
    numberOfLines: number;
    fontStyle: number;
    textStyle: number;
    fontFamily: string;
    resourceItemIds: string[];
    fontColor: string;
}

export class NameTracingAttribute implements INameTracingAttribute {
    autoFill: boolean;
    color: string;
    lineSpacing: number;
    numberOfLines: number;
    fontStyle: number;
    textStyle: number;
    fontFamily: string;
    resourceItemIds: string[];
    fontColor: string;

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.autoFill = obj?.autoFill ?? true;
        this.color = obj?.color ?? "#000";
        this.lineSpacing = obj?.lineSpacing ?? 1;
        this.numberOfLines = obj?.numberOfLines ?? 1;
        this.fontStyle = obj?.fontStyle ?? 1;
        this.textStyle = obj?.textStyle ?? ConstantsTool.TEXT_STYLE.DASHED;
        this.fontFamily = obj?.fontFamily ?? "Quicksand";
        this.resourceItemIds = obj?.resourceItemIds ?? [];
        this.fontColor = obj?.fontColor ?? "#000";
    }
}

export type ICrosswordAttribute = Omit<
    IWord,
    "value" | "originValue" | "userAnswer" | "place" | "status"
>;

export class CrosswordAttribute implements ICrosswordAttribute {
    clue: string;
    onGrid?: boolean;
    position?: IPosition;
    direction?: number;
    index?: number;
    indexPrefix?: number;

    constructor(obj: ICrosswordAttribute) {
        this.clue = obj.clue ?? "";
        this.onGrid = obj.onGrid ?? false;
        this.index = obj.index ?? -1;

        if (this.onGrid) {
            this.indexPrefix = obj.indexPrefix ?? -1;
            this.position = obj.position ?? {
                row: -1,
                column: -1,
            };
            this.direction = obj.direction ?? ConstantsTool.DIRECTIONS.ACROSS;
        }
    }
}

export interface IMathAttribute {
    factor1: number;
    factor2: number;
    result: number;
    hiddenNumber?: number;
}

export class MathAttribute implements IMathAttribute {
    factor1: number;
    factor2: number;
    result: number;
    hiddenNumber?: number;

    constructor(obj: IMathAttribute) {
        this.factor1 = obj.factor1 ?? 0;
        this.factor2 = obj.factor2 ?? 0;
        this.result = obj.result ?? 0;
        this.hiddenNumber = obj.hiddenNumber ?? 3;
    }
}

export interface IScrambleAttribute {
    shuffleQuestion: string;
    resourceItemIds: string[];
}

export class ScrambleAttribute implements IScrambleAttribute {
    shuffleQuestion: string;
    resourceItemIds: string[];

    constructor(obj: IScrambleAttribute) {
        this.shuffleQuestion = obj.shuffleQuestion ?? "";
        this.resourceItemIds = obj.resourceItemIds ?? [];
    }
}

export interface IQuestionActivity {
    id: string;
    question: string;
    answers: IAnswerActivity[];
    indexQuestion: number;

    handwritingAttribute?: IHandwritingAttribute;
    multipleChoicesAttribute?: IMultipleChoicesAttribute;
    nameTracingAttribute?: INameTracingAttribute;

    crosswordAttribute?: ICrosswordAttribute;

    mathAttribute?: IMathAttribute;

    scrambleAttribute?: IScrambleAttribute;
}

export class QuestionActivity implements IQuestionActivity {
    id: string;
    question: string;
    answers: IAnswerActivity[];
    numberLetters: number;
    indexQuestion: number;

    handwritingAttribute?: IHandwritingAttribute;
    multipleChoicesAttribute?: IMultipleChoicesAttribute;

    nameTracingAttribute?: INameTracingAttribute;

    crosswordAttribute?: ICrosswordAttribute;

    mathAttribute?: IMathAttribute;

    scrambleAttribute?: IScrambleAttribute;

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.id = obj.id;
        this.question = obj.question;
        this.answers = obj.answers ?? [];
        this.numberLetters = obj.numberLetters ?? 0;
        this.indexQuestion = obj.indexQuestion ?? 0;

        if (obj.type === Config.ACTIVITY_TYPE.HANDWRITING.TYPE) {
            this.handwritingAttribute = new HandwritingAttribute({
                ...(obj.handwritingAttribute ?? {}),
            });
        } else if (obj.type === Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE) {
            this.multipleChoicesAttribute = new MultipleChoicesAttribute({
                ...(obj.multipleChoicesAttribute ?? {}),
            });
        }

        if (obj.type === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE) {
            this.nameTracingAttribute = new NameTracingAttribute({
                ...(obj.nameTracingAttribute ?? {}),
            });
        }

        if (obj.type === Config.ACTIVITY_TYPE.CROSSWORD.TYPE) {
            this.crosswordAttribute = new CrosswordAttribute({
                ...(obj.crosswordAttribute ?? {}),
            });
        }

        if (
            obj.type === Config.ACTIVITY_TYPE.ADDITION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.SUBTRACTION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.DIVISION.TYPE
        ) {
            this.mathAttribute = new MathAttribute({
                ...(obj.mathAttribute ?? {}),
            });
        }

        if (
            obj.type === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE
        ) {
            this.scrambleAttribute = new ScrambleAttribute({
                ...(obj.scrambleAttribute ?? {}),
            });
        }
    }
}
