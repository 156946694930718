import React, { DragEvent, useEffect, useRef, useSyncExternalStore, useState } from "react";
import { requestInitPageWs } from "../../../../redux/async/createWorksheet";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    addResourceItems,
    selectElements,
    setFontSizeResourceItemsGroup,
    updateIsEditedPage,
    updateIsOnDrag,
    updateOpenWarningReplaceTemplate,
    updateSelectedWorksheetId,
} from "../../../../redux/reducers/createWorksheet";
import { toggleShowListActivities } from "../../../../redux/reducers/leftData";
import { addTemplateToPersist } from "../../../../redux/reducers/recentUse";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import Config from "../../../../shared/utils/config";
import groupElementStore from "../../../../syncExternalStore/groupElementStore";
import {
    eventAddResourceElement,
    generateIdFromDateTime,
    getTypeFromOldResourceItem,
    isResourceBackground,
    listResourceNoAction,
} from "../../../../utils";
import { ConstantsApp } from "../../../../utils/Constants";
import ListenGroup from "../../Listen/listenGroup";
import MoreActionComponent from "../ElementWorksheet/MoreAction";
import ResizeElement from "../ElementWorksheet/ResizeElement";
import ActionLayer from "./ActionLayer";
import BorderElement from "./ActionLayer/Border";
import BorderGroup from "./ActionLayer/Border/borderGroup";
import ContentLayer from "./ContentLayer";
import ListElementWorksheet from "./ContentLayer/ListElementWorksheet";
import PopupRelaceWorksheet from "./PopupRelaceWs";
import { DirectionProps } from "../../../../shared/models/directionProps";
import ListBorderGroup from "./ActionLayer/Border/lisBorderGroup";
import { makeResourceItemGroup, resourceCanGroup } from '../../Listen/listenShortcut';

const checkEqual = (
    prevProps: {
        pageIndex: number;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew | null;
        showPageIndex: boolean;
        totalPages: number;
        setSpaceToTopLeft: any;
        rotate: number;
        activeRotate: boolean;
        setActiveRotate: any;
        activeResize: DirectionProps | null;
        setActiveResize: any;
        onDrag: boolean;
        moved: boolean;
        ratio: number;
        isScaled: boolean;
        // hoverElement: IResourceItemNew | null;
        // setHoverElement: (value: IResourceItemNew) => void;
    },
    nextProps: {
        pageIndex: number;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew | null;
        showPageIndex: boolean;
        totalPages: number;
        setSpaceToTopLeft: any;
        rotate: number;
        activeRotate: boolean;
        setActiveRotate: any;
        activeResize: DirectionProps | null;
        setActiveResize: any;
        onDrag: boolean;
        moved: boolean;
        ratio: number;
        isScaled: boolean;
        // hoverElement: IResourceItemNew | null;
        // setHoverElement: (value: IResourceItemNew) => void;
    }
) => {
    return (
        prevProps.pageIndex === nextProps.pageIndex &&
        prevProps.showPageIndex === nextProps.showPageIndex &&
        prevProps.currentElement === nextProps.currentElement &&
        prevProps.totalPages === nextProps.totalPages &&
        prevProps.rotate === nextProps.rotate &&
        prevProps.activeRotate === nextProps.activeRotate &&
        prevProps.activeResize === nextProps.activeResize &&
        prevProps.onDrag === nextProps.onDrag &&
        prevProps.moved === nextProps.moved &&
        prevProps.ratio === nextProps.ratio &&
        prevProps.isScaled === nextProps.isScaled
        // prevProps.hoverElement === nextProps.hoverElement
    );
};

const PageWorksheet = ({
    pageIndex,
    setCurrentElement,
    currentElement,
    showPageIndex,
    totalPages,
    setSpaceToTopLeft,
    rotate,
    activeResize,
    setActiveResize,
    activeRotate,
    setActiveRotate,
    setActiveDarg,
    onDrag,
    moved,
    ratio,
    isScaled,
}: // hoverElement,
// setHoverElement,
{
    pageIndex: number;
    setCurrentElement: (value: IResourceItemNew) => void;
    currentElement: IResourceItemNew | null;
    showPageIndex: boolean;
    totalPages: number;
    setSpaceToTopLeft: any;
    rotate: number;
    activeRotate: boolean;
    setActiveRotate: any;
    activeResize: DirectionProps | null;
    setActiveResize: any;
    setActiveDarg: any;
    onDrag: boolean;
    moved: boolean;
    ratio: number;
    isScaled: boolean;
    // hoverElement: IResourceItemNew | null;
    // setHoverElement: (value: IResourceItemNew) => void;
}) => {
    const dispatch = useAppDispatch();
    const mouseMoveRef = useRef(null);
    const contentRef = useRef(null);
    const actionRef = useRef(null);

    // const ratio = useAppSelector((state) => state.createWorksheetState.ratio);
    const borderRef = useRef(null);

    const openWarningReplaceTemplate = useAppSelector(
        (state) => state.createWorksheetState.openWarningReplaceTemplate
    );

    const dataTab = useAppSelector((state) => state.leftDataState.dataTab);
    const {isIframe, templateZoneDisplayMode} = useAppSelector((state) => state.createWorksheetState);

    const currentPageIndex = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );

    const onEditBorder = useAppSelector(
        (state) => state.createWorksheetState.isOnEditBorder
    );

    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const pageWorksheet = pagesWorksheet[pageIndex];

    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const currentTab = useAppSelector((state) => state.leftDataState.activeTab);
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );

    const [selectedResourceItems, setSelectedResourceItems] = useState([]);

    const handleOnDrop = (event: DragEvent<HTMLDivElement>) => {
        if (event.dataTransfer.getData("resourceItem")) {
            let rect = mouseMoveRef.current?.getBoundingClientRect();
            let resourceItem = JSON.parse(
                event.dataTransfer.getData("resourceItem")
            );
            if (resourceItem?.type) {
                const offsetX = (event.pageX - rect.left) / ratio;
                const offsetY = (event.pageY - rect.top) / ratio;
                let type = getTypeFromOldResourceItem(resourceItem);
                const isBackground =
                    isResourceBackground(type) ||
                    type === ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES;
                let resource = new ResourceItemNew({
                    ...JSON.parse(event.dataTransfer.getData("resourceItem")),
                    x:
                        resourceItem.type !== ConstantsTool.RESOURCE_TEXT
                            ? isBackground
                                ? 0
                                : offsetX - 50
                            : offsetX - 50 + 16,
                    y:
                        resourceItem.type !== ConstantsTool.RESOURCE_TEXT
                            ? isBackground
                                ? 0
                                : offsetY - 50
                            : offsetY - 50 + 16,
                    type,
                    id: generateIdFromDateTime(),
                });
                let newResourceItem = { ...resource, pageIndex };
                dispatch(
                    addResourceItems({
                        pageIndex: pageIndex,
                        resourceItems: [newResourceItem],
                    })
                );
                dispatch(
                    selectElements({
                        pageIndex: pageIndex,
                        resourceSelected: [newResourceItem],
                    })
                );
                eventAddResourceElement(type);
            }
        } else if (event.dataTransfer.getData("worksheet")) {
            let worksheet = JSON.parse(event.dataTransfer.getData("worksheet"));
            dispatch(
                updateSelectedWorksheetId({
                    worksheetId: worksheet.id,
                })
            );
            if (pageWorksheet.isEdited) {
                dispatch(updateOpenWarningReplaceTemplate());
            } else {
                if (currentTab === Config.RESOURCE_TEMPLATE) {
                    dispatch(addTemplateToPersist(worksheet));
                }
                dispatch(
                    requestInitPageWs({
                        worksheetId: worksheet.id,
                        isUserAddTemplate: true,
                        sourcePage: Config.SOURCE_USER_BEHAVIOR.PICK_TEMPLATE,
                    })
                );

                if (worksheet?.type === Config.TEMPLATE_WORKSHEET) {
                    dispatch(toggleShowListActivities(true));
                } else {
                    dispatch(toggleShowListActivities(false));
                }
            }
        }
    };

    useEffect(() => {
        setCurrentElement(null);
    }, [templateZoneDisplayMode]);

    useEffect(() => {
        const generateFontSizeResourceItemsGroup = () => {
            dispatch(setFontSizeResourceItemsGroup({
                pageIndex,
                items: pageWorksheet['resourceItems']
            }));
        };

        generateFontSizeResourceItemsGroup();
    }, [pageWorksheet['resourceItems']])

    useEffect(() => {
        if (currentElement === null) {
            dispatch(updateIsOnDrag(false));
            setActiveResize(null);
            setActiveRotate(false);
        }
    }, [currentElement]);

    const getPaddingBottom = () => {
        const parentContainer = document.getElementById(
            "right-content-container"
        );
        return Math.max(
            0,
            parentContainer.offsetHeight - pageWorksheet.height * 0.45
        );
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(updateIsEditedPage({ isEidited: false, pageIndex }));
        }, 100);
    }, []);

    useEffect(() => {
        const addResourceItemsSelectedWhenDraggingMouse = () => {
            const selectedItems = pageWorksheet.resourceItems.filter((item) => selectedResourceItems.includes(item.id) && resourceCanGroup(item));

            if (groupElementState.pageIndex != pageIndex) {
                groupElementStore.updatePageIndex(pageIndex);
            }

            groupElementStore.addResourceItems(selectedItems);

            if (groupElementState.resourceItemsSelected.length > 1) {
                makeResourceItemGroup(dispatch, pageIndex, setCurrentElement);
            }
        };

        addResourceItemsSelectedWhenDraggingMouse();
    }, [selectedResourceItems]);

    return (
        <>
            {/* <Selecto
                container={document.body}
                dragContainer={'.page-worksheet'}
                selectableTargets={['.resource-item-element']}
                continueSelect={false}
                toggleContinueSelect={"shift"}
                hitRate={100}
                selectByClick={false}
                onSelect={e => {
                    console.log('HEllo', e)
                    e.added.forEach(el => {
                        setSelectedResourceItems(prev => [...prev, el.getAttribute('resource-item-id')]);
                    });
                    if (e.removed.length === selectedResourceItems.length) return;
                    e.removed.forEach(el => {
                        setSelectedResourceItems(prev => prev.filter((item: string) => item !==  el.getAttribute('resource-item-id')))
                    });
                }}
            /> */}
            <div
                className="page-worksheet not-click"
                id={"page-worksheet_" + pageIndex}
                style={{
                    height:
                        pageWorksheet.height * ratio +
                        ConstantsApp.PADDING_PAGES,
                }}
            >
                {!isIframe && (
                    <div
                        style={{
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            height: ConstantsApp.PADDING_PAGES,
                        }}
                    >
                        {showPageIndex ? "Page " + (pageIndex + 1) : ""}
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                    className="not-click"
                    onDrop={handleOnDrop}
                >
                    <ContentLayer
                        pageIndex={pageIndex}
                        pagesWorksheet={pageWorksheet}
                        ratio={ratio}
                        listElementWorksheet={
                            <ListElementWorksheet
                                // hoverElement={hoverElement}
                                // setHover={setHoverElement}
                                ref={contentRef}
                                currentElement={currentElement}
                                canClick={!activeRotate && !onDrag}
                                pageIndex={pageIndex}
                                // setOnDrag={setOnDrag}
                                setSpaceToTopLeft={(value) =>
                                    setSpaceToTopLeft(value)
                                }
                                setCurrentElement={setCurrentElement}
                                setActiveDarg={setActiveDarg}
                            ></ListElementWorksheet>
                        }
                    ></ContentLayer>
                    {/* {currentPageIndex === pageIndex &&
                        displayEditPage(currentActivity?.type) && (
                            <EditPageWorksheet
                                pageIndex={pageIndex}
                                setCurrentElment={setCurrentElement}
                            />
                        )} */}
                    <div
                        id={ConstantsApp.ID_PAGE_ELEMENT + pageIndex}
                        style={{
                            width: `${pageWorksheet.width * ratio}px`,
                            height: `${pageWorksheet.height * ratio}px`,
                            margin: "0px",
                            pointerEvents:
                                ((activeResize || activeRotate) &&
                                    currentElement?.type !==
                                        ConstantsTool.TYPE_RESOURCE_MODULE) ||
                                onDrag ||
                                groupElementState.isOnDragGroup
                                    ? "auto"
                                    : "none",
                            position: "absolute",
                            zIndex: 2,
                            bottom: 0,
                            left: 0,
                            cursor: "auto",
                        }}
                        onDragOver={(event) => event.preventDefault()}
                        ref={mouseMoveRef}
                    >
                        {!onEditBorder && (
                            <ActionLayer
                                currentElement={currentElement}
                                pageIndex={pageIndex}
                                borderElement={
                                    <React.Fragment>
                                        {currentElement !== null && (
                                            <BorderElement
                                                currentElement={currentElement}
                                                ref={borderRef}
                                            ></BorderElement>
                                        )}
                                    </React.Fragment>
                                }
                                moreActionComponent={
                                    onDrag ||
                                    activeResize ||
                                    activeRotate ||
                                    listResourceNoAction(currentElement) ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <MoreActionComponent
                                            currentElement={currentElement}
                                            setCurrentElement={setCurrentElement}
                                        ></MoreActionComponent>
                                    )
                                }
                                resizeElement={
                                    <ResizeElement
                                        ref={actionRef}
                                        activeResize={activeResize}
                                        activeRotate={activeRotate}
                                        setActiveResize={setActiveResize}
                                        setActiveRotate={setActiveRotate}
                                        currentElement={currentElement}
                                        onDrag={onDrag}
                                        setCurrentElement={setCurrentElement}
                                    ></ResizeElement>
                                }
                                activeDrag={moved}
                            ></ActionLayer>
                        )}
                        {pageIndex === currentPageIndex &&
                            groupElementState.startPoint &&
                            groupElementState.endPoint && (
                                <BorderGroup
                                    ratio={ratio}
                                    startPoint={groupElementState.startPoint}
                                    endPoint={groupElementState.endPoint}
                                />
                            )}
                        {pageIndex === currentPageIndex && (
                            <ListBorderGroup
                                resourceItems={
                                    groupElementState.resourceItemsCurrent
                                        .length
                                        ? groupElementState.resourceItemsCurrent
                                        : groupElementState.resourceItemsSelected
                                }
                                ratio={ratio}
                            />
                        )}
                    </div>
                </div>
                {openWarningReplaceTemplate &&
                    pageIndex === currentPageIndex && (
                        <PopupRelaceWorksheet
                            dataTab={dataTab}
                            setCurrentElment={setCurrentElement}
                        />
                    )}
            </div>
            {isScaled && (
                <div
                    style={{
                        height:
                            pageIndex > 0 && pageIndex === totalPages - 1
                                ? getPaddingBottom()
                                : 0,
                    }}
                ></div>
            )}

            <ListenGroup
                pageWorksheet={pageWorksheet}
                currentElement={currentElement}
                pageIndex={currentPageIndex}
                rotate={rotate}
            />
        </>
    );
};
export default React.memo(PageWorksheet, checkEqual);
