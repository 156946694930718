import Button from 'react-bootstrap/Button';
import { updateDataAPI } from '../../../../utils/query';
import { useAppSelector } from '../../../../redux/hook';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import './styles.scss';
import { saveDesignItems } from '../../../../utils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import ResetDesignOptionsModal from './reset-design-options-modal';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';

const ResetDesignButton = ({}) => {
    const dispatch = useDispatch();

    const [showResetDesignOptionsModal, setShowResetDesignOptionsModal] = useState(false);

    const { template, ideaTemplate, ideaAnswerSheetPage, ideaAnswerKeyPage, ideaThumbnail, dataSetDesignRow, pageType, pagesWorksheet, tempTemplateZones } = useAppSelector(state => state.createWorksheetState);

    const reset = async(resetFrame: boolean = true, resetHeadline: boolean = true, resetTexts: boolean = true, resetOthers: boolean = true, resetPageIndex: boolean = true, autoResizeText: boolean = true) => {
        if (pageType === ConstantsTool.PAGE_TYPES['page']) {
            let fields = [];
            if (resetFrame && resetHeadline && resetTexts && resetOthers) {
                fields = ['frame', 'headline', 'texts', 'others'];
            } else {
                // if reset all, no need to save current page's items
                // but otherwise, save before reset
                await saveDesignItems(dispatch, pageType, pagesWorksheet[0]['resourceItems'], template?.id, ideaTemplate?.id, ideaAnswerSheetPage?.id, ideaAnswerKeyPage?.id, ideaThumbnail?.id, dataSetDesignRow?.id, tempTemplateZones);

                if (resetFrame) {
                    fields.push('frame');
                }
                if (resetHeadline) {
                    fields.push('headline');
                }
                if (resetTexts) {
                    fields.push('texts');
                }
                if (resetOthers) {
                    fields.push('others');
                }
                if (resetPageIndex) {
                    fields.push('page_index');
                }
            }

            await updateDataAPI('POST', 'auto-content/reset-design-items/?ideaTemplateID=' + ideaTemplate.id, {
                fields,
                autoResizeText,
            });
        } else if (pageType === ConstantsTool.PAGE_TYPES['thumbnail']) {
            await updateDataAPI('POST', 'resources/reset-thumbnail-design-items/?ideaThumbnailID=' + ideaThumbnail.id);
        } else if (pageType === ConstantsTool.PAGE_TYPES['answerSheet']) {
            await updateDataAPI('POST', 'auto-content/reset-idea-answer-sheet-design-items/?ideaAnswerSheetPageID=' + ideaAnswerSheetPage.id);
        } else if (pageType === ConstantsTool.PAGE_TYPES['answerKey']) {
            await updateDataAPI('POST', 'auto-content/reset-idea-answer-key-design-items/?ideaAnswerKeyPageID=' + ideaAnswerKeyPage.id);
        } else if (pageType === ConstantsTool.PAGE_TYPES['dataSetDesignRow']) {
            await updateDataAPI('POST', 'resources/reset-design-row-design-items/?designRowID=' + dataSetDesignRow.id);
        }
        window.location.reload();
    }

    return (
        <>
            <Dropdown as={ButtonGroup} className="reset-design-button">
                <Button onClick={() => reset()} variant="warning" className="reset-design-btn" id="reset-design-btn">Reset All Design</Button>

                <Dropdown.Toggle split variant="secondary" id="reset-design-button-dropdown-split" />

                <Dropdown.Menu>
                    <Dropdown.Item id="custom-reset-button" onClick={() => setShowResetDesignOptionsModal(true)}>Custom Reset</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {showResetDesignOptionsModal && (
                <ResetDesignOptionsModal
                    hideModal={() => setShowResetDesignOptionsModal(false)}
                    reset={reset} />
            )}
        </>
    )
}

export default ResetDesignButton;