export enum ReferralTypes {
    SET_REFERRERid = "SET_REFERRERid",
    CHECK_AVAILABLE_REFERRAL_BY_EMAIL = "CHECK_AVAILABLE_REFERRAL_BY_EMAIL",
    SENT_REFERRAL_MAIL = "SENT_REFERRAL_MAIL",
    CHECK_REFERRAL_LOGIN = "CHECK_REFERRAL_LOGIN",
    GET_REFERRAL_LIST = "GET_REFERRAL_LIST",
    SET_REFERRAL_LIST = "SET_REFERRAL_LIST",
    UPDATE_RECIPIENT_EMAIL = "UPDATE_RECIPIENT_EMAIL",
    UPDATE_CURRENT_RECIPIENT_EMAIL_STATUS = "UPDATE_CURRENT_RECIPIENT_EMAIL_STATUS",
}
export interface ReferralAction {
    type: ReferralTypes;
    userId?: string;
    validateReferralEmailStatus?: number;
    referrerId?: string;
    recipientEmail?: string;
    recipientEmails?: string[];
    newEmail?: string;
    referralList?: any[];
}

export const setReferrerIdAction = (
    referrerId: string
): ReferralAction => {
    return {
        type: ReferralTypes.SET_REFERRERid,
        referrerId,
    };
};

export const checkAvailableReferralByEmailAction = (
    recipientEmail: string
): ReferralAction => {
    return {
        type: ReferralTypes.CHECK_AVAILABLE_REFERRAL_BY_EMAIL,
        recipientEmail,
    };
};

export const sendReferralMailAction = (
    recipientEmails: string[]
): ReferralAction => {
    return {
        type: ReferralTypes.SENT_REFERRAL_MAIL,
        recipientEmails,
    };
};

export const checkReferralLoginAction = (
    userId: string,
    newEmail: string,
): ReferralAction => {
    return {
        type: ReferralTypes.CHECK_REFERRAL_LOGIN,
        userId,
        newEmail,
    };
};


export const getListReferralAction = (
): ReferralAction => {
    return {
        type: ReferralTypes.GET_REFERRAL_LIST,
    };
};

export const setListReferralAction = (
    referralList: any[]
): ReferralAction => {
    return {
        type: ReferralTypes.SET_REFERRAL_LIST,
        referralList
    };
};

export const updateRecipientEmailAction = (
    referralList: any[]
): ReferralAction => {
    return {
        type: ReferralTypes.UPDATE_RECIPIENT_EMAIL,
        referralList
    };
};

export const updateCurrentRecipientEmailStatusAction = (
    validateReferralEmailStatus: number
): ReferralAction => {
    return {
        type: ReferralTypes.UPDATE_CURRENT_RECIPIENT_EMAIL_STATUS,
        validateReferralEmailStatus
    };
};