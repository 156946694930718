import React from "react";
import Config from "../../../../../../shared/utils/config";
import WorksheetGrid from "../../../../collection/collection-detail/worksheet";
import NewCollectionView from "../../../collection-view";
import CreateNewCollection from "../create-collection";
import "./style.scss";
const NewColumnWorksheet = ({
    ws,
    WIDTH_WS,
    selectCollectionFc,
    selectWorksheetFc,
    addGaEventMoreEvent,
    addGaEventCreateCollection,
    onDragResourceItem,
    onDropResourceItem,
}: {
    ws: any;
    WIDTH_WS: number;
    selectCollectionFc?: Function;
    selectWorksheetFc?: Function;
    addGaEventMoreEvent?: Function;
    addGaEventCreateCollection?: Function;
    onDragResourceItem?: Function;
    onDropResourceItem?: Function;
}) => {
    return (
        <div
            className="grid-column-collection"
            style={{
                width: WIDTH_WS + "px",
            }}
        >
            {ws?.map((worksheet, idx) => {
                if (
                    worksheet?.typeGrid ==
                    Config.TYPE_GRID.TYPE_CREATE_COLLECTION
                ) {
                    return (
                        <React.Fragment key={idx}>
                            <CreateNewCollection
                                addGaEventCreateCollection={
                                    addGaEventCreateCollection
                                }
                            />
                        </React.Fragment>
                    );
                }
                if (
                    worksheet?.typeGrid ==
                    Config.TYPE_GRID.TYPE_FOLDER_COLLECTION
                ) {
                    return (
                        <React.Fragment key={idx}>
                            <NewCollectionView
                                index={idx + ""}
                                collection={worksheet}
                                selectCollectionFc={selectCollectionFc}
                                addGaEventMoreEvent={addGaEventMoreEvent}
                            />
                        </React.Fragment>
                    );
                }
                if (worksheet?.id) {
                    return (
                        <React.Fragment key={idx}>
                            <WorksheetGrid
                                index={idx + ""}
                                worksheet={worksheet}
                                widthColumn={WIDTH_WS}
                                selectWorksheetFc={selectWorksheetFc}
                                onDragResourceItem={onDragResourceItem}
                                onDropResourceItem={onDropResourceItem}
                            />
                        </React.Fragment>
                    );
                }
            })}
        </div>
    );
};

export default NewColumnWorksheet;
