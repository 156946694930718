import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addEventGa } from "../../../../../../lib/ga";
import { addActionEventDownloadAction } from "../../../../../../redux/action/resource.action";
import { ComingSoonIconSvg } from "../../../../../icons/coming-soon";
import { HappyIconSvg } from "../../../../../icons/happy-icon";
import { MoneyIconSvg } from "../../../../../icons/money-icon";
import "./style.scss";
const STATUS_OPEN = {
    non: -1,
    close: 0,
    open: 1,
};
const GetUnLimitedDl = ({ worksheets }: { worksheets?: any[] }) => {
    // const [openComingSoon, setOpenComingSoon] = useState(false);
    const [openComingSoon, setOpenComingSoon] = useState(STATUS_OPEN.non);
    const dispatch = useDispatch();
    useEffect(() => {
        if (openComingSoon == STATUS_OPEN.open) {
            let timeout = setTimeout(() => {
                setOpenComingSoon(STATUS_OPEN.close);
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [openComingSoon]);
    return (
        <>
            <div
                className="btn-unlimited"
                onClick={() => {
                    let params = {
                        action: "click_unlimited_downloads",
                        params: {},
                    };

                    addEventGa(params);
                    worksheets?.length &&
                        dispatch(
                            addActionEventDownloadAction({
                                haveActionPopup: true,
                            })
                        );
                    // LocalStorage.setUserHaveActionClaimDownload();

                    setOpenComingSoon(STATUS_OPEN.open);
                    addEventGa({
                        action: "click_unlimited_downloads",
                        params: {},
                    });
                }}
            >
                <span className="icon">
                    <MoneyIconSvg />
                </span>
                Get Unlimited Downloads
            </div>

            {openComingSoon >= 0 ? (
                <div>
                    <div
                        className={
                            "comming-soon " +
                            (openComingSoon ? " active" : " hidden")
                        }
                    >
                        <div className="icon">
                            <ComingSoonIconSvg />
                        </div>
                        <div className="content-cmsoon">
                            <div className="title">Coming Soon!</div>
                            <div className="desc">
                                Our new feature is on its way!
                                <span className="react">
                                    <HappyIconSvg />
                                </span>
                            </div>
                        </div>

                        {/* <div
                            className="close-btn"
                            onClick={() => {
                                setOpenComingSoon(STATUS_OPEN.close);
                            }}
                        >
                            <ArrowLeft />
                        </div> */}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default GetUnLimitedDl;
