import Config from "../../../shared/utils/config";
import { ReferralAction, ReferralTypes } from "../action/referral.action";

export interface ReferralState {
    validateReferralEmailStatus: number;
    loading: boolean;
    referrerId: string;
    referralList: string[];
    recipientEmails: string[];
}

const initialState: ReferralState = {
    validateReferralEmailStatus: Config.DEFAULT_REFERRAL_STATUS,
    loading: false,
    referrerId: "",
    referralList: [],
    recipientEmails: [],
};

function referralState(
    state: ReferralState = initialState,
    action: ReferralAction
): ReferralState {
    switch (action.type) {
        case ReferralTypes.SET_REFERRERid:
            return {
                ...state,
                referrerId: action.referrerId,
            };
        case ReferralTypes.SET_REFERRAL_LIST:
            return {
                ...state,
                referralList: action.referralList,
                loading: false,
            };
        case ReferralTypes.UPDATE_CURRENT_RECIPIENT_EMAIL_STATUS:
            return {
                ...state,
                validateReferralEmailStatus: action.validateReferralEmailStatus,
            };

        default:
            return state;
    }
}

export default referralState;
