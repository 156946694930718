import React, { MouseEvent } from "react";
import { DirectionProps } from "../../../../../../shared/models/directionProps";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import "./index.scss";
const areEqual = (
    prevProps: {
        anchorOrigin: DirectionProps;
        setActiveResize: (value: DirectionProps) => void;
        isDisplay: boolean;
        isCorner: boolean;
        currentElement?: IResourceItemNew;
    },
    nextProps: {
        anchorOrigin: DirectionProps;
        setActiveResize: (value: DirectionProps) => void;
        isDisplay: boolean;
        isCorner: boolean;
        currentElement?: IResourceItemNew;
    }
) => {
    return (
        prevProps.anchorOrigin === nextProps.anchorOrigin &&
        prevProps.isDisplay === nextProps.isDisplay &&
        prevProps.isCorner === nextProps.isCorner &&
        prevProps.currentElement === nextProps.currentElement
    );
};

const ButtonResize = ({
    anchorOrigin,
    setActiveResize,
    isDisplay,
    isCorner,
    isLine = false,
    setRowAdd,
    setColAdd,
    setCheckAdd,
    currentElement,
}: {
    anchorOrigin: DirectionProps;
    setActiveResize: (value: DirectionProps) => void;
    isDisplay: boolean;
    isCorner: boolean;
    isLine?: boolean;
    setRowAdd: any;
    setColAdd: any;
    setCheckAdd: any;
    currentElement?: IResourceItemNew;
}) => {
    // const memoizedSetActiveResize = useCallback(setActiveResize, []);
    const table = currentElement?.tableAttribute;
    return (
        <div
            style={{
                [anchorOrigin["vertical"]]: "-16px",
                [anchorOrigin["horizontal"]]: "-16px",
                opacity: isDisplay ? 1 : 0.001,
            }}
            className={
                "resize-container " +
                (isCorner
                    ? "corner-" +
                      anchorOrigin.vertical +
                      "-" +
                      anchorOrigin.horizontal
                    : "border-resize border-" +
                      anchorOrigin.horizontal +
                      anchorOrigin.vertical)
            }
            onMouseDown={(event: MouseEvent) => {
                event.stopPropagation();
                setActiveResize(anchorOrigin);
                // event.preventDefault();
            }}
        >
            <div
                className={
                    (isLine
                        ? "circle-cursor "
                        : isCorner
                        ? "circle-cursor "
                        : "border-cursor ") +
                    (isDisplay ? "can-click" : "not-click")
                }
                onMouseEnter={(e) => {
                    if (table) {
                        if (
                            anchorOrigin.vertical === "top" &&
                            anchorOrigin.horizontal === ""
                        ) {
                            setRowAdd(0);
                        }
                        if (
                            anchorOrigin.vertical === "" &&
                            anchorOrigin.horizontal === "left"
                        ) {
                            setColAdd(0);
                        }
                        if (
                            anchorOrigin.vertical === "bottom" &&
                            anchorOrigin.horizontal === ""
                        ) {
                            setRowAdd(table.data.length);
                        }
                        if (
                            anchorOrigin.vertical === "" &&
                            anchorOrigin.horizontal === "right"
                        ) {
                            setColAdd(table.data[0].length);
                        }
                    }
                }}
                onMouseMove={(event) => {
                    if(table){
                        let rect = event.currentTarget.getBoundingClientRect();
                    let { x, y, width, height } = rect;
                    let { clientX, clientY } = event;
                    if (
                        (anchorOrigin.vertical === "top" &&
                            anchorOrigin.horizontal === "") ||
                        (anchorOrigin.vertical === "bottom" &&
                            anchorOrigin.horizontal === "")
                    ) {
                        if (clientX - x <= width / 2) {
                            setCheckAdd(0);
                        } else {
                            setCheckAdd(table.data[0].length - 1);
                        }
                    }
                    if (
                        (anchorOrigin.vertical === "" &&
                            anchorOrigin.horizontal === "left") ||
                        (anchorOrigin.vertical === "" &&
                            anchorOrigin.horizontal === "right")
                    ) {
                        if (clientY - y <= height / 2) {
                            setCheckAdd(0);
                        } else {
                            setCheckAdd(table.data.length - 1);
                        }
                    }
                    }
                }}
                onMouseLeave={(e) => {
                    if (table) {
                        setRowAdd(-1);
                        setColAdd(-1);
                        setCheckAdd(-1);
                    }
                }}
            ></div>
            <div
                className={
                    isLine
                        ? "button-resize circle"
                        : isCorner
                        ? "button-resize circle"
                        : "button-resize border"
                }
            ></div>
        </div>
    );
};
export default React.memo(ButtonResize, areEqual);
