import { useEffect, useState } from "react";

export default function useComponentVisible(ref) {
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    useEffect(() => {
        if (ref) {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsComponentVisible(true);
                } else {
                    setIsComponentVisible(false);
                }
            };
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [ref]);

    return { isComponentVisible };
}
