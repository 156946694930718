import Config from "../../../../../../shared/utils/config";
import CheckBoxIconSvg2 from "../../../../icons/check-box-icon-svg";
import "./style.scss";

const DownloadWithAnswerKey = ({
    downloadWithAnswer,
    setDownloadWithAnswer,
    worksheet,
}) => {
    const isEnableDownloadWithAnswerKey =
        (!!worksheet?.gameSubmitted?.images?.length ||
            worksheet?.game?.answers?.length > 0 ||
            worksheet?.answerLength > 0) &&
        worksheet?.originUrl !== Config.USER_CREATED_TOOL_V2_HAND_WRITING &&
        worksheet?.originUrl !== Config.USER_CREATED_TOOL_V2_NAME_TRACING;
    const handleChange = () => {
        setDownloadWithAnswer(!downloadWithAnswer);
    };
    return (
        <div>
            {isEnableDownloadWithAnswerKey ? (
                <div className="checkbox-option" onClick={handleChange}>
                    <div className="checkbox">
                        <CheckBoxIconSvg2 checked={downloadWithAnswer} />
                    </div>
                    <div className="text">Download/Print With Answer Key</div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DownloadWithAnswerKey;
