import React, { useState } from 'react';
import Header from '../components/Header';
import LeftContent from '../components/LeftContent';
import RightContent from '../components/RightContent';
import AddPageComponent from '../components/RightContent/AddPage';
import { useAppSelector } from '../redux/hook';
import Contact from '../resource-user/main-word-search/report-FAQ';
import Spinner from 'react-bootstrap/Spinner';

const MainPage = () => {
    // const loading = useAppSelector((state) => state.wordSearchState.loading);
    // const isSave = useAppSelector((state) => state.wordSearchState.isSave);
    const {loading} = useAppSelector(state => state.createWorksheetState);

    const [showTabContent, setShowTabContent] = useState(false);
    return (
        <React.Fragment>
            <div className="app_tool">
                {/* {loading && !isSave && ( */}
                    {/* <LoadingComponent usingNewLoading={true} /> */}
                {/* )} */}
                <Header />
                <div
                    style={{
                        overflow: "hidden",
                        // display: showTabContent ? "grid" : "flex",
                        // gridTemplateColumns: showTabContent ? "500px 1fr" : "none",
                        // justifyContent: showTabContent ? "" : "center",
                        // maxWidth: "100vw",
                        display: "flex",
                        maxHeight: "calc(100vh - 52px)",
                    }}
                >
                    <LeftContent
                        showTabContent={showTabContent}
                        setShowTabContent={setShowTabContent}
                    ></LeftContent>

                    <RightContent
                        buttonAddPage={<AddPageComponent></AddPageComponent>}
                    ></RightContent>
                    <Contact />

                    {loading && (
                        <div className="loading-container">
                            <Spinner animation="border" variant="light" />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
export default MainPage;
