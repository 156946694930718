import { getSizeDivText } from "../resource-user/utils";
import { getSizeCellTable } from "../resource-user/utils/table";
import { DirectionProps } from "../shared/models/directionProps";
import {
    CellTable,
    DirectionTable,
    ITableAttribute,
    PositionTable,
    TableAttribute,
} from "../shared/models/tableAttribute";

export const checkHeightCellTable = ({
    tableAttribute,
    col,
    row,
    newHeight,
}: {
    tableAttribute: ITableAttribute;
    row: number;
    col: number;
    newHeight: number;
}) => {
    let { borderWidth, data } = tableAttribute;
    let cell = data[row][col];
    if (cell.rowspan === 1) {
        if (cell.positionParent) {
            row = cell.positionParent.row;
            col = cell.positionParent.col;
        }
        let { textHeight, textWidth } = getSizeCellTable({
            tableAttribute,
            row,
            col,
        });
        let dheight = textHeight - cell.height + newHeight;
        textHeight = getSizeDivText({
            textAttribute: data[row][col].textAttribute,
            width: textWidth,
            test: false,
        }).height;
        if (textHeight > dheight) {
            return false;
        }
    }
    return true;
};

export const getHeightFitTextCellTable = ({
    tableAttribute,
    col,
    row,
}: {
    tableAttribute: ITableAttribute;
    row: number;
    col: number;
}) => {
    let { data } = tableAttribute;
    let cell = data[row][col];
    if (cell.rowspan === 1) {
        if (cell.positionParent) {
            row = cell.positionParent.row;
            col = cell.positionParent.col;
        }
        let { textHeight, textWidth } = getSizeCellTable({
            tableAttribute,
            row,
            col,
        });
        let newTextHeight = getSizeDivText({
            textAttribute: data[row][col].textAttribute,
            width: textWidth,
            test: false,
        }).height;
        if (newTextHeight > textHeight) {
            return newTextHeight - textHeight;
        }
    }
    return 0;
};

export const getMaxHeightRowCell = ({
    tableAttribute,
    row,
}: {
    tableAttribute: ITableAttribute;
    row: number;
}) => {
    let textHeights = tableAttribute.data[row].map((cell, col) => {
        let { textWidth } = getSizeCellTable({
            tableAttribute,
            row,
            col,
        });
        return (
            getSizeDivText({
                textAttribute: cell.textAttribute,
                width: textWidth,
                test: false,
            }).height + cell.borderWidth
        );
    });
    let maxHeightRow = textHeights.reduce((maxHeight, height) => {
        if (maxHeight > height) return maxHeight;
        return height;
    }, textHeights[0]);
    return maxHeightRow;
};

export const checkWidthCellTable = ({
    tableAttribute,
    col,
    row,
    newWidth,
}: {
    tableAttribute: ITableAttribute;
    row: number;
    col: number;
    newWidth: number;
}) => {
    let { data } = tableAttribute;
    let { textHeight, textWidth } = getSizeCellTable({
        tableAttribute,
        row,
        col,
    });
    let height = textHeight;
    let width = textWidth + newWidth - data[row][col].width;
    let cell = data[row][col];
    let newTextHeight = getSizeDivText({
        textAttribute: cell.textAttribute,
        width: width,
        test: false,
    }).height;
    let dHeight = 0;

    let maxHeightRow = getMaxHeightRowCell({ tableAttribute, row });
    let minHeight = cell.originHeight ?? cell.height;
    minHeight = Math.max(maxHeightRow, minHeight);
    if (newTextHeight > height) {
        dHeight = newTextHeight - height;
    }
    if (newTextHeight <= minHeight) {
        dHeight = minHeight - cell.height;
    }
    return dHeight;
};

export function changeFontAttributes({
    field,
    value,
    tableAttribute,
}: {
    field: string;
    value: any;
    tableAttribute: ITableAttribute;
}): { dHeight: number } {
    let dHeight = 0;
    if (!tableAttribute.data[0][0].textAttribute.hasOwnProperty(field))
        return { dHeight };
    let rows = tableAttribute.getRows({ isEmpty: false });
    rows.forEach((el) => {
        let dHeightRow = 0;
        el.cols.forEach((col) => {
            let cell = tableAttribute.data[el.row][col];
            cell.textAttribute[field] = value;
            if (!cell?.positionParent) {
                let row = el.row;
                let { textWidth } = getSizeCellTable({
                    tableAttribute: tableAttribute,
                    row,
                    col,
                });
                if (!cell.originHeight) {
                    tableAttribute.data[row][col].originHeight = cell.height;
                }
                let dHeightTmp = checkWidthCellTable({
                    tableAttribute: tableAttribute,
                    row,
                    col,
                    newWidth: textWidth,
                });

                if (dHeightTmp > 0) {
                    dHeightRow = Math.max(dHeightTmp, dHeightRow);
                }
                if (dHeightTmp < 0) {
                    dHeightRow = Math.min(dHeightTmp, dHeightRow);
                }
            }
        });
        tableAttribute.data[el.row].forEach((_, col) => {
            tableAttribute.data[el.row][col].height += dHeightRow;
        });
        dHeight += dHeightRow;
    });

    return { dHeight };
}

export function unMergeCells(tableAttribute: ITableAttribute): {
    dHeight: number;
} {
    let dHeight = 0;
    if (tableAttribute.currentPositions.length === 1) {
        let position = tableAttribute.currentPositions[0];
        if (position.colspan > 1 || position.rowspan > 1) {
            for (
                let i = position.row;
                i < position.row + position.rowspan;
                i++
            ) {
                for (
                    let j = position.column;
                    j < position.column + position.colspan;
                    j++
                ) {
                    tableAttribute.data[i][j].positionParent = null;
                    tableAttribute.data[i][j].colspan = 1;
                    tableAttribute.data[i][j].rowspan = 1;
                }
            }
        }
        dHeight = getHeightFitTextCellTable({
            tableAttribute: tableAttribute,
            row: position.row,
            col: position.column,
        });
        if (dHeight) {
            tableAttribute.data[position.row].forEach((cell) => {
                cell.height += dHeight;
            });
        }
        tableAttribute.currentPositions = null;
    }
    return { dHeight };
}

export function deleteCells(
    type: DirectionTable,
    tableAttribute: ITableAttribute
): {
    dHeight: number;
    dWidth: number;
    isDeleteTable: boolean;
} {
    let isDeleteTable = false,
        dWidth = 0,
        dHeight = 0;
    if (
        tableAttribute.currentPositions.length ===
        tableAttribute.data.length * tableAttribute.data[0].length
    ) {
        isDeleteTable = true;
        return { isDeleteTable, dWidth, dHeight };
    }
    if (type === DirectionTable.row) {
        const rows = tableAttribute.getRows();
        if (
            tableAttribute.data.length === 1 ||
            rows.length === tableAttribute.data.length
        ) {
            isDeleteTable = true;
            return { isDeleteTable, dWidth, dHeight };
        }
        rows.forEach((e) => {
            let row = rows[0].row;
            let cols = tableAttribute.data[row].map((_, index) => {
                return index;
            });
            let height = 0;
            let listParentRow: { row: number; endRow: number; col: number }[] =
                [];
            cols.forEach((col) => {
                let cell = tableAttribute.data[row][col];
                height = cell.height;
                if (cell.rowspan > 1) {
                    tableAttribute.data[row + 1][col] = new CellTable({
                        ...cell,
                        colspan: cell.colspan,
                        rowspan: cell.rowspan - 1,
                        height: tableAttribute.data[row + 1][col].height,
                    });
                    listParentRow.push({
                        row,
                        endRow: row + cell.rowspan - 1,
                        col,
                    });
                }
                if (cell.positionParent && col === cell.positionParent.col) {
                    tableAttribute.data[cell.positionParent.row][
                        cell.positionParent.col
                    ].rowspan -= 1;
                    listParentRow.push({
                        row: cell.positionParent.row,
                        endRow:
                            cell.positionParent.row +
                            tableAttribute.data[cell.positionParent.row][
                                cell.positionParent.col
                            ].rowspan,
                        col: cell.positionParent.col,
                    });
                }
            });
            tableAttribute.data.splice(row, 1);
            dHeight += -height + tableAttribute.borderWidth;
            if (listParentRow.length) {
                let value = listParentRow[0];
                let height = 0;
                listParentRow.forEach((e) => {
                    if (e.row < tableAttribute.data.length) {
                        let dheightChange = checkWidthCellTable({
                            tableAttribute: tableAttribute,
                            row: e.row,
                            col: 0,
                            newWidth: tableAttribute.data[e.row][0].width,
                        });
                        if (dheightChange > height) {
                            height = dheightChange;
                            value = e;
                        }
                    }
                });
                if (height) {
                    for (let i = value.row; i < value.endRow; i++) {
                        tableAttribute.data[i].forEach((cell) => {
                            cell.height -= dHeight / (value.endRow - value.row);
                        });
                    }
                    dHeight = 0;
                }
            }
            if (row < tableAttribute.data.length) {
                let positions: { row: number; col: number }[] = [];
                for (let i = row; i < tableAttribute.data.length; i++) {
                    tableAttribute.data[i].forEach((cell, col) => {
                        if (cell.rowspan > 1 || cell.colspan > 1) {
                            positions.push({ row: i, col });
                        }
                    });
                }
                positions = positions.filter(
                    (e) =>
                        !listParentRow.find(
                            (find) => find.row === e.row && find.col === e.col
                        )
                );
                positions.forEach((p) => {
                    let cell = tableAttribute.data[p.row][p.col];
                    for (let row = p.row; row < p.row + cell.rowspan; row++) {
                        let cellChild = tableAttribute.data[row][p.col];
                        if (cellChild.positionParent) {
                            cellChild.positionParent!.row -= 1;
                        }
                        if (cell.colspan > 1) {
                            for (
                                let col = p.col + 1;
                                col < p.col + cell.colspan;
                                col++
                            ) {
                                let cellChild = tableAttribute.data[row][col];
                                if (cellChild.positionParent) {
                                    cellChild.positionParent!.row -= 1;
                                }
                            }
                        }
                    }
                });
            }
        });
    } else {
        const cols = tableAttribute.getColumns();
        if (
            tableAttribute.data[0].length === 1 ||
            cols.length === tableAttribute.data[0].length
        ) {
            isDeleteTable = true;
            return { isDeleteTable, dWidth, dHeight };
        }
        cols.forEach(() => {
            let col = cols[0].col;
            let rows = tableAttribute.data.map((_, index) => {
                return index;
            });
            let width = 0;
            let listParentCol: {
                col: number;
                row: number;
                endCol: number;
            }[] = [];
            rows.forEach((row) => {
                let cell = tableAttribute.data[row][col];
                width = cell.width;
                if (cell.colspan > 1) {
                    tableAttribute.data[row][col + 1] = new CellTable({
                        ...cell,
                        colspan: cell.colspan - 1,
                        rowspan: cell.rowspan,
                    });
                    listParentCol.push({
                        col: col,
                        endCol: col + cell.colspan - 1,
                        row: row,
                    });
                }
                if (cell.positionParent && row === cell.positionParent.row) {
                    tableAttribute.data[cell.positionParent.row][
                        cell.positionParent.col
                    ].colspan -= 1;
                    listParentCol.push({
                        col: cell.positionParent.col,
                        endCol:
                            cell.positionParent.col +
                            tableAttribute.data[cell.positionParent.row][
                                cell.positionParent.col
                            ].colspan,
                        row: row,
                    });
                }
            });
            tableAttribute.data.forEach((_, row) => {
                tableAttribute.data[row].splice(col, 1);
            });
            dWidth += tableAttribute.borderWidth - width;
            if (listParentCol.length) {
                let value = listParentCol[0];
                let height = 0;
                listParentCol.forEach((e) => {
                    if (e.col < tableAttribute.data[0].length) {
                        let dH = getHeightFitTextCellTable({
                            tableAttribute: tableAttribute,
                            row: e.row,
                            col: e.col,
                        });
                        if (dH > height) {
                            height = dH;
                            value = e;
                        }
                    }
                });

                if (height) {
                    for (let i = value.col; i < value.endCol; i++) {
                        tableAttribute.data.forEach((_, row) => {
                            tableAttribute.data[row][i].width -=
                                dWidth / (value.endCol - value.col);
                        });
                    }
                    dWidth = 0;
                }
            }
            let maxCol = tableAttribute.data[0].length;
            if (col < maxCol) {
                let positions: { row: number; col: number }[] = [];
                tableAttribute.data.forEach((cells, row) => {
                    for (let i = col; i < maxCol; i++) {
                        let cell = cells[i];
                        if (cell.colspan > 1 || cell.rowspan > 1) {
                            positions.push({ row, col: i });
                        }
                    }
                });
                positions = positions.filter(
                    (e) =>
                        !listParentCol.find(
                            (find) => find.row === e.row && find.col === e.col
                        )
                );
                positions.forEach((p) => {
                    let cell = tableAttribute.data[p.row][p.col];
                    for (let col = p.col; col < p.col + cell.colspan; col++) {
                        let cellChild = tableAttribute.data[p.row][col];
                        if (cellChild.positionParent) {
                            cellChild.positionParent!.col -= 1;
                        }
                        if (cell.rowspan > 1) {
                            for (
                                let row = p.row + 1;
                                row < p.row + cell.rowspan;
                                row++
                            ) {
                                let cellChild = tableAttribute.data[row][col];
                                if (cellChild.positionParent) {
                                    cellChild.positionParent!.col -= 1;
                                }
                            }
                        }
                    }
                });
            }
        });
    }
    tableAttribute.currentPositions = [];
    return { isDeleteTable, dWidth, dHeight };
}

export function mergeCells(
    type: DirectionTable,
    tableAttribute: ITableAttribute
): number {
    let dHeight = 0;
    let rows = tableAttribute.getRows();
    let cols = tableAttribute.getColumns();
    if (type === DirectionTable.row) {
        if (cols.length === 1 && cols[0].rows.length > 1) {
            type = DirectionTable.column;
        }
    } else {
        if (rows.length === 1 && rows[0].cols.length > 1) {
            type = DirectionTable.row;
        }
    }

    if (type === DirectionTable.row) {
        let textContent = "";
        let final: {
            row: number;
            col: number;
            cell: CellTable;
            colspan: number;
        } = null;
        rows.forEach((row) => {
            let cols = row.cols;
            if (cols.length === 1) {
                cols = tableAttribute.data[row.row].map((_, index) => {
                    return index;
                });
            }
            cols = cols.sort((a, b) => a - b);

            cols.forEach((col) => {
                let cell = tableAttribute.data[row.row][col];
                textContent += cell.textAttribute.content;
                tableAttribute.data[row.row][col].textAttribute.content = "";
            });

            let cell = new CellTable(tableAttribute.data[row.row][cols[0]]);

            if (final === null) {
                final = {
                    cell,
                    row: row.row,
                    col: cols[0],
                    colspan: cols.length,
                };
            }
            for (let i in cols) {
                let cell = tableAttribute.data[row.row][cols[i]];
                cell.positionParent = {
                    row: final.row,
                    col: final.col,
                };
                cell.colorCell = final.cell.colorCell;
                cell.textAttribute = final.cell.textAttribute;
                cell.colspan = 1;
                cell.rowspan = 1;
            }
        });
        if (final) {
            tableAttribute.data[final.row][final.col] = new CellTable({
                ...final.cell,
                textAttribute: {
                    ...final.cell.textAttribute,
                    content: textContent,
                },
                colspan: final.colspan,
                rowspan: rows.length,
            });
            let dHeightRow = getHeightFitTextCellTable({
                tableAttribute: tableAttribute,
                row: final.row,
                col: final.col,
            });
            tableAttribute.data[final.row].forEach((cell, col) => {
                cell.height += dHeightRow;
            });
            dHeight = dHeightRow;
            tableAttribute.currentPositions = [
                new PositionTable({
                    row: final.row,
                    column: final.col,
                    colspan: final.colspan,
                    rowspan: rows.length,
                }),
            ];
        }
    } else {
        let textContent = "";
        let final: {
            row: number;
            col: number;
            cell: CellTable;
            rowspan: number;
        } = null;

        cols.forEach((col) => {
            let rows = col.rows;
            if (rows.length === 1) {
                rows = tableAttribute.data.map((_, index) => {
                    return index;
                });
            }
            rows = rows.sort((a, b) => a - b);

            rows.forEach((row) => {
                let cell = tableAttribute.data[row][col.col];
                textContent += cell.textAttribute.content;
                tableAttribute.data[row][col.col].textAttribute.content = "";
            });

            let cell = new CellTable(tableAttribute.data[rows[0]][col.col]);

            if (final === null) {
                final = {
                    cell,
                    row: rows[0],
                    col: col.col,
                    rowspan: rows.length,
                };
            }
            for (let i in rows) {
                let cell = tableAttribute.data[rows[i]][col.col];
                cell.positionParent = {
                    row: final.row,
                    col: final.col,
                };
                cell.colorCell = final.cell.colorCell;
                cell.textAttribute = final.cell.textAttribute;
                cell.colspan = 1;
                cell.rowspan = 1;
            }
        });
        if (final) {
            tableAttribute.data[final.row][final.col] = new CellTable({
                ...final.cell,
                textAttribute: {
                    ...final.cell.textAttribute,
                    content: textContent,
                },
                rowspan: final.rowspan,
                colspan: cols.length,
            });
            tableAttribute.currentPositions = [
                new PositionTable({
                    row: final.row,
                    column: final.col,
                    rowspan: final.rowspan,
                    colspan: cols.length,
                }),
            ];
        }
    }
    return dHeight;
}

export function resizeTable({
    originWidth,
    originHeight,
    newWidth,
    newHeight,
    direction,
    tableAttribute,
}: {
    originWidth: number;
    originHeight: number;
    newWidth: number;
    newHeight: number;
    direction: DirectionProps;
    tableAttribute: ITableAttribute;
}): { isResizeWidth: boolean; isResizeHeight: boolean; dHeight: number } {
    let dwidth = newWidth - originWidth,
        dHeight = newHeight - originHeight;
    const minD = 30;
    const checkIsResize = () => {
        let check = true;
        if (direction.vertical === "" && direction.horizontal === "left") {
            for (let i in tableAttribute.data) {
                if (tableAttribute.data[i][0].width + dwidth < minD) {
                    check = false;
                    break;
                }
            }
        } else if (
            direction.vertical === "" &&
            direction.horizontal === "right"
        ) {
            for (let i in tableAttribute.data) {
                if (
                    tableAttribute.data[i][tableAttribute.data[i].length - 1]
                        .width +
                        dwidth <
                    minD
                ) {
                    check = false;
                    break;
                }
            }
        } else if (
            direction.vertical === "top" &&
            direction.horizontal === ""
        ) {
            for (let i = 0; i < tableAttribute.data[0].length; i++) {
                let newHeight = tableAttribute.data[0][i].height + dHeight;
                if (
                    newHeight < minD ||
                    !checkHeightCellTable({
                        tableAttribute: tableAttribute,
                        row: 0,
                        col: i,
                        newHeight,
                    })
                ) {
                    check = false;
                    break;
                }
            }
        } else if (
            direction.vertical === "bottom" &&
            direction.horizontal === ""
        ) {
            for (
                let i = 0;
                i < tableAttribute.data[tableAttribute.data.length - 1].length;
                i++
            ) {
                let newHeight =
                    tableAttribute.data[tableAttribute.data.length - 1][i]
                        .height + dHeight;
                if (
                    newHeight < minD ||
                    !checkHeightCellTable({
                        tableAttribute: tableAttribute,
                        row: tableAttribute.data.length - 1,
                        col: i,
                        newHeight,
                    })
                ) {
                    check = false;
                    break;
                }
            }
        }
        return check;
    };
    let isResizeWidth = true,
        isResizeHeight = true;
    const isResize = checkIsResize();

    let newDHeight = 0;
    if (isResize) {
        if (direction.vertical === "" && direction.horizontal === "left") {
            for (let i in tableAttribute.data) {
                let newWidth = tableAttribute.data[i][0].width + dwidth;
                let dHeightRow = checkWidthCellTable({
                    tableAttribute: tableAttribute,
                    row: parseFloat(i),
                    col: 0,
                    newWidth,
                });
                tableAttribute.data[i].forEach((cell, col) => {
                    cell.height =
                        tableAttribute.data[i][col].height + dHeightRow;
                });
                newDHeight += dHeightRow;
                tableAttribute.data[i][0].width = newWidth;
            }
        } else if (
            direction.vertical === "" &&
            direction.horizontal === "right"
        ) {
            for (let i in tableAttribute.data) {
                let col = tableAttribute.data[i].length - 1;
                let newWidth = tableAttribute.data[i][col].width + dwidth;
                let dHeightRow = checkWidthCellTable({
                    tableAttribute: tableAttribute,
                    row: parseFloat(i),
                    col,
                    newWidth,
                });
                tableAttribute.data[i].forEach((cell, col) => {
                    cell.height =
                        tableAttribute.data[i][col].height + dHeightRow;
                });
                newDHeight += dHeightRow;
                tableAttribute.data[i][col].width = newWidth;
            }
        } else if (
            direction.vertical === "top" &&
            direction.horizontal === ""
        ) {
            tableAttribute.data[0].forEach((cell, i) => {
                tableAttribute.data[0][i].height += dHeight;
                tableAttribute.data[0][i].originHeight =
                    tableAttribute.data[0][i].height;
            });
        } else if (
            direction.vertical === "bottom" &&
            direction.horizontal === ""
        ) {
            tableAttribute.data[tableAttribute.data.length - 1].forEach(
                (cell, i) => {
                    tableAttribute.data[tableAttribute.data.length - 1][
                        i
                    ].height += dHeight;
                    tableAttribute.data[tableAttribute.data.length - 1][
                        i
                    ].originHeight =
                        tableAttribute.data[tableAttribute.data.length - 1][
                            i
                        ].height;
                }
            );
        } else {
            let { gap, borderWidth, data } = tableAttribute;
            let dw =
                // borderWidth * (data[0].length - 1) +
                gap;
            let dh =
                // borderWidth * (data.length - 1) +
                gap;
            let dHeight = 0;
            let dWidth = 0;
            let ratioWidth = (newWidth + dw) / (originWidth + dw);
            let ratioHeight = (newHeight + dh) / (originHeight + dh);
            let rows: number[] = [];
            let cols: number[] = [];
            tableAttribute.data.forEach((cells, row) => {
                cells.forEach((cell, col) => {
                    let newWidthCell = cell.width * ratioWidth,
                        newHeightCell = cell.height * ratioHeight;
                    if (newWidthCell <= minD && newWidth < originWidth) {
                        if (!cols.includes(col)) {
                            cols.push(col);
                            dWidth += newWidthCell - cell.width;
                        }
                    }
                    if (
                        (newHeightCell <= minD ||
                            !checkHeightCellTable({
                                tableAttribute: tableAttribute,
                                row,
                                col,
                                newHeight: newHeightCell,
                            })) &&
                        newHeight < originHeight
                    ) {
                        if (!rows.includes(row)) {
                            rows.push(row);
                            dHeight += newHeightCell - cell.height;
                        }
                    }
                });
            });
            if (rows.length === tableAttribute.data.length) {
                isResizeHeight = false;
            } else {
                dHeight /= tableAttribute.data.length - rows.length;
            }
            if (cols.length === tableAttribute.data[0].length) {
                isResizeWidth = false;
            } else {
                dWidth /= tableAttribute.data[0].length - cols.length;
            }
            tableAttribute.data.forEach((cells, row) => {
                cells.forEach((cell, col) => {
                    let newWidth = 0;
                    let newHeight = 0;
                    if (!cols.includes(col)) {
                        newWidth = cell.width * ratioWidth + dWidth;
                    } else {
                        newWidth = cell.width;
                    }
                    tableAttribute.data[row][col].width = newWidth;
                    if (!rows.includes(row)) {
                        newHeight = cell.height * ratioHeight + dHeight;
                    } else {
                        newHeight = cell.height;
                    }
                    tableAttribute.data[row][col].height = newHeight;
                    tableAttribute.data[row][col].originHeight = newHeight;
                });
                if (newHeight < originHeight || newWidth < originWidth) {
                    let dHeightRow = checkWidthCellTable({
                        tableAttribute: tableAttribute,
                        row,
                        col: 0,
                        newWidth: tableAttribute.data[row][0].width,
                    });
                    tableAttribute.data[row].forEach((cell, col) => {
                        cell.height += dHeightRow;
                    });
                    newDHeight += dHeightRow;
                }
            });
        }
    } else {
        isResizeWidth = false;
        isResizeHeight = false;
    }
    return { isResizeWidth, isResizeHeight, dHeight: newDHeight };
}

export const changeBorderWidthTable = (
    value: number,
    table: ITableAttribute
) => {
    let tableAttribute = new TableAttribute(table);
    let rows = tableAttribute.getRows();
    let data = tableAttribute.data;

    const change = (
        row: number,
        col: number,
        postion: "top" | "right" | "bottom" | "left"
    ) => {
        let newBorderWidths = data[row][col].borderWidths.slice();
        switch (postion) {
            case "top":
                newBorderWidths[0] = value;
                break;
            case "right":
                newBorderWidths[1] = value;
                break;
            case "bottom":
                newBorderWidths[2] = value;
                break;
            case "left":
                newBorderWidths[3] = value;
                break;
        }
        data[row][col].borderWidths = newBorderWidths;
    };

    if (
        tableAttribute?.currentPositions?.length &&
        tableAttribute?.currentPositions?.length !==
            data.length * data[0].length
    ) {
        let maxIndex = tableAttribute.getMaxIndex() + 1;
        rows.forEach((e) => {
            let row = e.row;
            e.cols.forEach((col) => {
                const { colspan, rowspan } = data[row][col];
                for (let i = row; i < row + rowspan; i++) {
                    for (let j = col; j < col + colspan; j++) {
                        let row = i;
                        let col = j;
                        data[row][col].borderWidth = value;
                        data[row][col].borderWidths = Array.from<number>({
                            length: 4,
                        }).fill(value);
                        data[row][col].zIndex += maxIndex;
                        if (row < data.length - 1) {
                            change(row + 1, col, "top");
                        }
                        if (row > 0) {
                            change(row - 1, col, "bottom");
                        }
                        if (col > 0) {
                            change(row, col - 1, "right");
                        }
                        if (col < data[0].length - 1) {
                            change(row, col + 1, "left");
                        }
                    }
                }
            });
        });
        tableAttribute.setZIndexCells();
    } else {
        data.forEach((cells) => {
            cells.forEach((cell) => {
                cell.borderWidth = value;
                cell.borderWidths = Array.from<number>({ length: 4 }).fill(
                    value
                );
            });
        });
    }
    let dHeight = 0;
    data.forEach((cells, row) => {
        let dHeightRow = checkWidthCellTable({
            tableAttribute,
            row,
            col: 0,
            newWidth: cells[0].width,
        });
        dHeight += dHeightRow;
        cells.forEach((cell) => {
            cell.height += dHeightRow;
        });
    });
    return { tableAttribute, dHeight };
};

export const changeStrokeDasharrayTable = (
    value: number,
    table: ITableAttribute
) => {
    let tableAttribute = new TableAttribute(table);
    let rows = tableAttribute.getRows();
    let data = tableAttribute.data;

    const change = (
        row: number,
        col: number,
        postion: "top" | "right" | "bottom" | "left"
    ) => {
        let newStrokeDasharrays = data[row][col].strokeDasharrays.slice();
        switch (postion) {
            case "top":
                newStrokeDasharrays[0] = value;
                break;
            case "right":
                newStrokeDasharrays[1] = value;
                break;
            case "bottom":
                newStrokeDasharrays[2] = value;
                break;
            case "left":
                newStrokeDasharrays[3] = value;
                break;
        }
        data[row][col].strokeDasharrays = newStrokeDasharrays;
    };
    if (
        tableAttribute?.currentPositions?.length &&
        tableAttribute?.currentPositions?.length !==
            data.length * data[0].length
    ) {
        let maxIndex = tableAttribute.getMaxIndex() + 1;
        rows.forEach((e) => {
            let row = e.row;
            e.cols.forEach((col) => {
                const { colspan, rowspan } = data[row][col];
                for (let i = row; i < row + rowspan; i++) {
                    for (let j = col; j < col + colspan; j++) {
                        let row = i;
                        let col = j;
                        data[row][col].strokeDasharray = value;
                        data[row][col].strokeDasharrays = Array.from<number>({
                            length: 4,
                        }).fill(value);
                        data[row][col].zIndex += maxIndex;
                        if (row < data.length - 1) {
                            change(row + 1, col, "top");
                        }
                        if (row > 0) {
                            change(row - 1, col, "bottom");
                        }
                        if (col > 0) {
                            change(row, col - 1, "right");
                        }
                        if (col < data[0].length - 1) {
                            change(row, col + 1, "left");
                        }
                    }
                }
            });
        });
        tableAttribute.setZIndexCells();
    } else {
        data.forEach((cells) => {
            cells.forEach((cell) => {
                cell.strokeDasharray = value;
                cell.strokeDasharrays = Array.from<number>({ length: 4 }).fill(
                    value
                );
            });
        });
    }
    return tableAttribute;
};
