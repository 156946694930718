import { IconSVG, svgPath } from "../../../../../assets/icon/icons";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import {
    getDataLine,
    getDataShape,
    getDataTable,
    getResourceItemLine,
    getResourceItemShape,
} from "../../../../../utils/canvalData";
import ResourceItemComponent from "../../ResourceItem";
import "./index.scss";
import HStackResource from "./HStackResource";
import { setSession } from "../../../../../resource-user/utils";
import Constants from "../../../../../resource-user/utils/constants";

function TabShapesNormal({
    activeTab,
    setView,
}: {
    activeTab: number;
    setView: any;
}) {
    const dataShape = getDataShape(null, 4);
    const dataLine = getDataLine();
    const dataTable = getDataTable();

    const back = (text: string) => {
        return (
            <div onClick={() => setView(null)} className="back-tab-elment">
                <div className="icon-back">
                    <img src={svgPath(IconSVG.arrowLeft)} />
                </div>
                <span>{text}</span>
            </div>
        );
    };

    const listShape = (
        <div>
            {back("Shapes")}
            <div className="list-resource-item-shape">
                {dataShape.map((value) => {
                    let resourceItem = getResourceItemShape(
                        value,
                        ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                    );
                    return (
                        <ResourceItemComponent
                            key={resourceItem.id}
                            resourceItem={resourceItem}
                            typeResource={activeTab}
                            classNameItem={"resource-item"}
                        ></ResourceItemComponent>
                    );
                })}
            </div>
        </div>
    );

    const listLine = (
        <div>
            {back("Lines")}
            <div className="list-resource-item-line">
                {dataLine.map((value) => {
                    let resourceItem = getResourceItemLine(
                        value,
                        ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
                    );
                    return (
                        <ResourceItemComponent
                            key={resourceItem.id}
                            resourceItem={resourceItem}
                            typeResource={activeTab}
                            classNameItem={"resource-item"}
                        ></ResourceItemComponent>
                    );
                })}
            </div>
        </div>
    );
    const listTable = (
        <div>
            {back("Tables")}
            <div className="list-resource-item-table">
                {dataTable.map((resourceItem) => {
                    return (
                        <ResourceItemComponent
                            key={resourceItem.id}
                            resourceItem={resourceItem}
                            typeResource={activeTab}
                            classNameItem={"resource-item"}
                        ></ResourceItemComponent>
                    );
                })}
            </div>
        </div>
    );

    let count = 0;
    const setIsTester = () => {
        if (count === 0) {
            setTimeout(() => {
                count = 0;
            }, 5000);
        }
        count += 1;
        if (count >= 10) {
            setSession(Constants.TESTER_KEY_SAVE, true);
            window.location.reload();
        }
    };

    return (
        <div className="container-shapes" style={{ position: "relative" }}>
            <HStackResource
                label="Shapes"
                resourceItems={dataShape.map((value) => {
                    return getResourceItemShape(
                        value,
                        ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                    );
                })}
                activeTab={activeTab}
                seeAll={() => setView(listShape)}
                className="scroll-horizontal"
                onClickLabel={setIsTester}
            />

            <HStackResource
                label="Lines"
                resourceItems={dataLine.map((value) => {
                    return getResourceItemShape(
                        value,
                        ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
                    );
                })}
                activeTab={activeTab}
                seeAll={() => setView(listLine)}
                className="scroll-horizontal-line"
            />

            <HStackResource
                label="Tables"
                resourceItems={dataTable}
                activeTab={activeTab}
                seeAll={() => setView(listTable)}
                className="scroll-horizontal-table"
            />
        </div>
    );
}

export default TabShapesNormal;
