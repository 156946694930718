import { useLayoutEffect, useState, useSyncExternalStore } from "react";
import "./index.scss";
import {
    getDataLine,
    getDataShape,
    getResourceItemShape,
} from "../../../../utils/canvalData";
import { useAppDispatch } from "../../../../redux/hook";
import { updateCurentInfo } from "../../../../redux/reducers/leftData";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../shared/models/resourceItemNew";
import {
    updateResourceItem,
    updateResourceItems,
} from "../../../../redux/reducers/createWorksheet";
import {
    getListCommands,
    resizeShape,
    roundPathCorners,
} from "../../../../resource-user/utils/shape";
import ShapeBorder from "../../../../resource-user/components/common/shape/shape-border";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";
import groupElementStore from "../../../../syncExternalStore/groupElementStore";
import { IShapeAttribute } from "../../../../shared/models/shapeAttribute";
import { isResourceLine } from "../../../../utils";

function ListShape({
    activeTab,
    currentInfo,
}: {
    activeTab: number;
    currentInfo: IResourceItemNew;
}) {
    const dispatch = useAppDispatch();
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const [isLine, setIsLine] = useState(false);
    const [dataShape, setDataShape] = useState([]);

    useLayoutEffect(() => {
        let isLine = isResourceLine(currentInfo);

        if (currentInfo.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
            isLine = groupElementState.isChangeLine;
        }
        setIsLine(isLine);
        setDataShape(
            isLine
                ? getDataLine()
                : getDataShape(
                      null,
                      currentInfo.type ===
                          ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                          ? 0
                          : null
                  )
        );
    }, [currentInfo.type, groupElementState.isChangeLine]);

    const onChangeShapeGroup = (shapeAttribute: IShapeAttribute) => {
        let resourceItems = [];
        groupElementState.resourceItemsCurrent.forEach((item) => {
            if (isLine) {
                if (
                    item.type ===
                        ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
                    item.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
                ) {
                    resourceItems.push({
                        ...item,
                        shapeAttribute: {
                            ...shapeAttribute,
                            input: item.shapeAttribute.input,
                            strokeWidth: item.shapeAttribute.strokeWidth,
                        },
                    });
                }
            } else {
                if (
                    item.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
                    item.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                ) {
                    resourceItems.push({
                        ...item,
                        shapeAttribute: {
                            ...shapeAttribute,
                            strokeWidth: item.shapeAttribute.strokeWidth,
                            strokeDasharray:
                                item.shapeAttribute.strokeDasharray,
                        },
                    });
                }
            }
        });
        if (resourceItems.length) {
            dispatch(
                updateResourceItems({
                    pageIndex: currentInfo.pageIndex,
                    resourceItems: resourceItems,
                })
            );
            groupElementStore.updateResourceItems(resourceItems);
        }
    };

    const onClick = (resourceItemNew: ResourceItemNew) => {
        if (currentInfo.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
            onChangeShapeGroup(resourceItemNew.shapeAttribute);
            return;
        }
        let radius =
            currentInfo.shapeAttribute.radius === 0
                ? resourceItemNew.shapeAttribute.radius
                : currentInfo.shapeAttribute.radius;
        if (resourceItemNew.shapeAttribute.name === dataShape[0].name) {
            radius = 0;
        }
        if (currentInfo.shapeAttribute.name === dataShape[1].name) {
            radius = resourceItemNew.shapeAttribute.radius;
        }
        let object = {
            ...currentInfo,
            shapeAttribute: {
                ...resourceItemNew.shapeAttribute,
                radius: radius,
                strokeDasharray: isLine
                    ? resourceItemNew.shapeAttribute.strokeDasharray
                    : currentInfo.shapeAttribute.strokeDasharray,
                strokeWidth: currentInfo.shapeAttribute.strokeWidth,
                input: isLine
                    ? { ...currentInfo.shapeAttribute.input }
                    : resourceItemNew.shapeAttribute.input,
            },
        };
        dispatch(
            updateResourceItem({
                currentElementId: currentInfo.id,
                pageIndex: currentInfo.pageIndex,
                attrValues: [
                    {
                        attr: "shapeAttribute",
                        value: {
                            ...object.shapeAttribute,
                        },
                    },
                ],
            })
        );
        dispatch(updateCurentInfo(object));
    };
    let type = currentInfo.type;
    if (type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
        type = isLine
            ? ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
            : ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE;
    }
    return (
        <div className="container-tabshapes" style={{ paddingBottom: 24 }}>
            <div
                onClick={() => dispatch(updateCurentInfo(null))}
                style={{ padding: "24px 24px 0 24px", display: "flex" }}
            >
                <img src={svgPath(IconSVG.arrowLeft)} />
                <span
                    style={{
                        paddingLeft: 8,
                        fontSize: 16,
                        fontWeight: "700",
                        cursor: "pointer",
                    }}
                >
                    Back
                </span>
            </div>
            <div className="list-resource-item-shape">
                {dataShape.map((value) => {
                    let resourceItem = getResourceItemShape(value, type),
                        shape = resourceItem.shapeAttribute,
                        scale = shape.width / shape.height,
                        width = scale > 1 ? 50 : 50 * scale,
                        height = width / scale,
                        path = resizeShape(
                            width,
                            height,
                            shape,
                            getListCommands(shape.path)
                        );
                    path =
                        shape.name === "Rounded Square"
                            ? roundPathCorners(path, 12, width, height)
                            : path;
                    return (
                        <div className="list-item">
                            <div
                                className="img-container"
                                onClick={() => onClick(resourceItem)}
                                style={{
                                    border:
                                        currentInfo?.shapeAttribute?.name ===
                                        resourceItem.shapeAttribute.name
                                            ? "1px solid #0C9EFF"
                                            : "1px solid #c0c0c0",
                                }}
                            >
                                {isLine ? (
                                    <img
                                        src={resourceItem.shapeAttribute.url}
                                    />
                                ) : (
                                    <ShapeBorder
                                        id={resourceItem.id}
                                        path={path}
                                        width={width}
                                        height={height}
                                        stroke={
                                            currentInfo.type ===
                                            ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                                                ? "#E5E7EB"
                                                : "#000000"
                                        }
                                        strokeWidth={1.5}
                                        strokeDasharray={0}
                                        position="static"
                                        fill={
                                            currentInfo.type ===
                                            ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                                                ? "#E5E7EB"
                                                : "#FFFFFF"
                                        }
                                    />
                                )}

                                {/* <img src={resourceItem.shapeAttribute.url} /> */}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ListShape;
