import React from "react";

const PlusIconFolder = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.9998 36.8334H36.8332V51C36.8332 52.5584 35.5582 53.8334 33.9998 53.8334C32.4415 53.8334 31.1665 52.5584 31.1665 51V36.8334H16.9998C15.4415 36.8334 14.1665 35.5584 14.1665 34C14.1665 32.4417 15.4415 31.1667 16.9998 31.1667H31.1665V17C31.1665 15.4417 32.4415 14.1667 33.9998 14.1667C35.5582 14.1667 36.8332 15.4417 36.8332 17V31.1667H50.9998C52.5582 31.1667 53.8332 32.4417 53.8332 34C53.8332 35.5584 52.5582 36.8334 50.9998 36.8334Z"
                fill="rgba(var(--color), 1)"
            />
        </svg>
    );
};

export default PlusIconFolder;
