export interface ITextStyle {
    fontColor: string;
    underline: boolean;
    isBold: boolean;
    isItalic: boolean;
    isStroke: boolean;
    strokeColor: string;
    strokeWidth: number;
    isAppliedAutoFontSize?: boolean;
    isAutoResizeDone?: boolean;
    fontSize: number;
    fontFamily: string;
}
export interface ISubText extends ITextStyle {
    startOffset: number;
    endOffset: number;
}

export class SubText implements ISubText {
    startOffset: number;
    endOffset: number;
    fontColor: string;
    underline: boolean;
    isAppliedAutoFontSize?: boolean;
    isBold: boolean;
    isItalic: boolean;
    isStroke: boolean;
    strokeColor: string;
    strokeWidth: number;
    fontSize: number;
    fontFamily: string;
    constructor(obj: {
        startOffset: number;
        endOffset: number;
        fontSize: number;
        fontFamily?: string;
        fontColor?: string;
        underline?: boolean;
        isBold?: boolean;
        isItalic?: boolean;
        isStroke?: boolean;
        strokeColor?: string;
        strokeWidth?: number;
    }) {
        this.startOffset = obj.startOffset;
        this.endOffset = obj.endOffset;
        this.fontColor = obj.fontColor ?? "";
        this.underline = obj.underline ?? false;
        this.isBold = obj.isBold ?? false;
        this.isItalic = obj.isItalic ?? false;
        this.isStroke = obj.isStroke ?? false;
        this.strokeColor = obj.strokeColor ?? "";
        this.strokeWidth = obj.strokeWidth;
        this.fontFamily = obj.fontFamily ?? "";
        this.fontSize = obj.fontSize;
    }
}

export class SelectedText {
    startOffset: number;
    endOffset: number;
    constructor(obj: { startOffset: number; endOffset: number }) {
        this.startOffset = obj.startOffset;
        this.endOffset = obj.endOffset;
    }
}
