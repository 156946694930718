import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWorksheet } from "../../../../../../shared/models/worksheet";
import Config from "../../../../../../shared/utils/config";
import iconArrowDown from "../../../../../assets/images/icon/arrow_down.svg";
import iconDailyCheckIn from "../../../../../assets/images/icon/claim-coins/daily-check-in.png";
import iconFreeClaim from "../../../../../assets/images/icon/claim-coins/free-claim.png";
import iconInviteFriend from "../../../../../assets/images/icon/claim-coins/invite-friend.png";
import iconPinterest from "../../../../../assets/images/icon/claim-coins/pinterest.png";
import { addEventGa } from "../../../../../lib/ga";
import { addActionEventDownloadAction } from "../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import { getImageSource, groupWsFc } from "../../../../../utils";
import DailyCheckInMethod from "../item-method/daily-checkin";
import FreeClaimMethod from "../item-method/free-claim";
import InviteFriendMethod from "../item-method/invite-friend";
import SharePinterestMethod from "../item-method/share-pinterest";
import GetUnLimitedDl from "./get-unlimitted";
import "./style.scss";
export const IDS_METHOD = {
    freeClaim: 0,
    dailyCheckIn: 1,
    inviteFriend: 2,
    sharePinterest: 3,
};
const ClaimCoinsContent = ({
    worksheet,
    worksheets,
    wssWorkbook,
}: {
    worksheet?: IWorksheet;
    worksheets?: any[];
    wssWorkbook?: any[];
}) => {
    const LIST_METHOD = [
        {
            id: IDS_METHOD.freeClaim,
            title: "Free Claims",
            description: "Time for <span>+numberCoins</span> coins",
            numberCoins: Config.BONUS_QUOTA_DOWNLOAD_FREE_CLAIM,
            // icon: "/images/icon/claim-coins/free-claim.png",
            icon: iconFreeClaim,
        },
        {
            id: IDS_METHOD.dailyCheckIn,
            title: "Daily Checkin",
            description: "Come back everyday to get reward",
            numberCoins: Config.BONUS_QUOTA_DOWNLOAD_EACH_DAILY_TIME,
            // icon: "/images/icon/claim-coins/daily-check-in.png",
            icon: iconDailyCheckIn,
        },
        {
            id: IDS_METHOD.inviteFriend,
            title: "Invite friend",
            description:
                "Get <span>numberCoins</span> coins for every registered friend",
            numberCoins: Config.BONUS_QUOTA_DOWNLOAD_EACH_INVITE_FRIEND,
            // icon: "/images/icon/claim-coins/invite-friend.png",
            icon: iconInviteFriend,
            tooltip:
                "You will get coins once your friends sign up successfully",
        },
        {
            id: IDS_METHOD.sharePinterest,
            title: "Share Pinterest",
            description:
                "Get <span>numberCoins</span> coins for every public share",
            numberCoins: Config.BONUS_QUOTA_DOWNLOAD_EACH_SHARE_PIN,
            // icon: "/images/icon/claim-coins/pinterest.png",
            icon: iconPinterest,
            tooltip:
                "After you shared a worksheet public, we need time to validate your shares. You will get  the results at most 24 hours!",
        },
    ];
    const dispatch = useDispatch();
    const [moreMethod, showMoreMethod] = useState(false);
    const quotaDownload = useSelector(
        (state: ResourceAppState) =>
            state.quotaResourceUserState?.data?.quotaDownload
    );
    let groupWs = groupWsFc({ worksheets: worksheets });
    let numberWorksheet = worksheets?.length
        ? Object.keys(groupWs).length
        : worksheet?.id
        ? 1
        : 0;
    const ableDownload = quotaDownload >= numberWorksheet;
    useEffect(() => {
        if (!ableDownload) {
            showMoreMethod(true);
        }
    }, [ableDownload]);
    const addActionEventDownloadFc = () => {
        worksheets?.length &&
            dispatch(
                addActionEventDownloadAction({
                    haveActionPopup: true,
                })
            );
    };
    const addEventGaFc = ({ item }) => {
        let actionName = "";
        switch (item.id) {
            case IDS_METHOD.freeClaim:
                if (worksheets?.length) {
                    actionName = "free_claim_multiple";
                } else if (worksheet?.id) {
                    actionName = "free_claim_detail";
                }
                break;
            case IDS_METHOD.dailyCheckIn:
                if (worksheets?.length) {
                    actionName = "daily_checkin_claim_multiple";
                } else if (worksheet?.id) {
                    actionName = "daily_checkin_claim_detail";
                }
                break;
            case IDS_METHOD.sharePinterest:
                if (worksheets?.length) {
                    actionName = "claim_pin_multiple";
                } else if (worksheet?.id) {
                    actionName = "claim_pin_detail";
                }
                break;
            case IDS_METHOD.inviteFriend:
                if (worksheets?.length) {
                    actionName = "claim_invite_friend_multiple";
                } else if (worksheet?.id) {
                    actionName = "claim_invite_friend_detail";
                }
                break;
            default:
                break;
        }

        let params = {
            action: actionName,
            params: {},
        };
        addActionEventDownloadFc();
        // LocalStorage.setUserHaveActionClaimDownload();
        addEventGa(params);
    };
    const clickLabelFc = ({ item }) => {
        let actionName = "";
        switch (item.id) {
            case IDS_METHOD.inviteFriend:
                if (worksheets?.length) {
                    actionName = "invite_friend_multiple";
                } else if (worksheet?.id) {
                    actionName = "invite_friend_detail";
                }
                break;
            case IDS_METHOD.sharePinterest:
                if (worksheets?.length) {
                    actionName = "share_pin_multiple";
                } else if (worksheet?.id) {
                    actionName = "share_pin_detail";
                }
                break;
            default:
                break;
        }

        let params = {
            action: actionName,
            params: {},
        };
        addActionEventDownloadFc();

        // LocalStorage.setUserHaveActionClaimDownload();
        addEventGa(params);
    };
    return (
        <div className="claim-coins-content">
            <div
                className="claim-coins-content-text"
                onClick={() => {
                    let params = {
                        action: "click_claim_more",
                        params: {},
                    };

                    addEventGa(params);
                    addActionEventDownloadFc();
                    // LocalStorage.setUserHaveActionClaimDownload();
                    showMoreMethod(!moreMethod);
                }}
            >
                <p>Claim more coins to download free here</p>
                <img
                    src={getImageSource(iconArrowDown)}
                    alt="iconArrowDown"
                    width={18}
                    height={18}
                    className={"rotate " + (moreMethod ? "rotate-2" : "")}
                />
            </div>
            {moreMethod ? (
                <div className="methods-get-coins">
                    <GetUnLimitedDl worksheets={worksheets} />
                    <div className="list-method">
                        <FreeClaimMethod
                            item={LIST_METHOD[0]}
                            addEventGaFc={addEventGaFc}
                        />
                        <DailyCheckInMethod
                            item={LIST_METHOD[1]}
                            addEventGaFc={addEventGaFc}
                        />
                        <InviteFriendMethod
                            item={LIST_METHOD[2]}
                            addEventGaFc={addEventGaFc}
                            clickLabelFc={clickLabelFc}
                        />
                        <SharePinterestMethod
                            item={LIST_METHOD[3]}
                            wssWorkbook={wssWorkbook}
                            worksheet={worksheet}
                            addEventGaFc={addEventGaFc}
                            clickLabelFc={clickLabelFc}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
export default ClaimCoinsContent;
