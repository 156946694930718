import { IResourceItemNew } from '../../../../../../shared/models/resourceItemNew';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hook';
import { useEffect, useState } from 'react';
import FieldModal from '../../../../../../resource-user/components/main-header/field-modal';
import { getZoneLabel } from '../../../../../../utils';
import { hasPermission } from '../../../../../../resource-user/utils';
import ConstantsTool from '../../../../../../shared/utils/ConstantsTool';
import { setCurrentElementFocus, setDisableAjustZonePositionWithKeyboard } from '../../../../../../redux/reducers/createWorksheet';

function SetFieldAction({
    currentElement,
    handleCurrentElementChange,
}: {
    currentElement: IResourceItemNew;
    handleCurrentElementChange: (updatedValues: object) => void;
}) {
    const dispatch = useAppDispatch();
    const { template, activityPermissions, pageType } = useAppSelector((state) => state.createWorksheetState);
    const { staffInfo } = useAppSelector((state) => state.staffInfoState);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(setDisableAjustZonePositionWithKeyboard(showModal));
    }, [showModal]);

    const save = (fieldName: string, fieldIndex: number, fieldDescription: string, parentField: string, parentFieldIndex: number, staticValue: string, fieldAnswerUserTitle: string, linkedZoneFieldName: string, linkedTemplate: number) => {
        let updatedValues = { fieldName, fieldIndex, fieldDescription, parentField, parentFieldIndex, staticValue, linkedZoneFieldName, linkedTemplate };
        if (staticValue !== null && staticValue !== '') {
            updatedValues['type'] = ConstantsTool.TYPE_RESOURCE_ZONE_STATIC;
        }
        if (fieldName === 'page_index') {
            const newTextAttributes = {
                'textAttribute': {
                    ...currentElement?.textAttribute,
                    'fontSize': 40,
                    'fontFamily': 'KGSecondchanceSolid',
                }
            };

            updatedValues = {
                ...updatedValues,
                ...newTextAttributes,
            };
        }
        handleCurrentElementChange(updatedValues);
        setShowModal(false);
    }

    const handleOpenSetFieldModal = () => {
        if (pageType === 'template') {
            if (template.isUsingInIdea) {
                if (hasPermission(staffInfo['roles'], activityPermissions[ConstantsTool.OPEN_SET_FIELD_ZONE_MODAL_ACTION])) {
                    window.alert(ConstantsTool.WARNING_FOR_MANAGER__WHEN_TEMPLATE_IS_USING_IN_IDEA);
                    setShowModal(true);
                } else {
                    window.alert(ConstantsTool.WARNING_WHEN_TEMPLATE_IS_USING_IN_IDEA);
                }
            } else {
                setShowModal(true);
            };
        } else {
            setShowModal(true);
        }
    }

    return (
        <>
            <div className={'set-field-name-action ' + (currentElement.fieldName && 'active')} onClick={handleOpenSetFieldModal}>
                {currentElement.fieldName ? getZoneLabel(currentElement) : 'Set Field'}
            </div>
            {showModal && (
                <FieldModal
                    type="zone"
                    fieldName={currentElement.fieldName}
                    fieldIndex={currentElement.fieldIndex}
                    fieldDescription={currentElement.fieldDescription}
                    parentField={currentElement.parentField}
                    fieldAnswerUserTitle={null}
                    parentFieldIndex={currentElement.parentFieldIndex}
                    staticValue={currentElement.staticValue}
                    linkedZoneFieldName={currentElement.linkedZoneFieldName}
                    linkedTemplate={currentElement.linkedTemplate}
                    hideModal={() => {
                        setShowModal(false);
                        dispatch(setCurrentElementFocus(true));
                    }}
                    save={save} />
            )}
        </>
    );
}

export default SetFieldAction;
