export default class ClassroomConfig {
    static ROLE_TEACHER = 1;
    static ROLE_STUDENT = 2;
    static ROLE_PARENT = 3;
    static ASSIGNMENT_WORKSHEET = 1;
    static ASSIGNMENT_TEXT = 2;
    static ASSIGNMENT_QUESTION = 3;
    static USER_NOT_JOINED = 1;
    static USER_JOINED = 2;
    static USER_DELETE = -1;
    static CLASS_DELETE = -1;
    static CLASS_ACTIVE = 1;
    static ASSIGNMENT_DELETE = -1;

    static ASSIGNMENT_ACTIVE = 1;

    static LIST_DEFAULT_THUMBNAIL = [
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673596103438.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673596010280.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595948346.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595883871.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595513067.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595488175.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595475620.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595456098.jpeg",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673595404562.jpeg",
    ];

    static AVATAR_DEFAULT_LIST = [
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246665701.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246684326.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246691392.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246697025.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246711031.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246722069.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246730050.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246735598.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246676796.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/1673246703872.png",
    ];

    static AVATAR_DEFAULT_TEACHER = [
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_1.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_2.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18631.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18632.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18633.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18635.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18636.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18638.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18639.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18640.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18641.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18642.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18643.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18644.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18645.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18646.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18647.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18648.png",
    ];

    static AVATAR_DEFAULT_STUDENT = [
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_3.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_4.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18573.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18574.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18575.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18576.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18577.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18578.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18579.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18580.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18581.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18582.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18583.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18584.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18585.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18586.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18587.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18588.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18589.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18590.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18591.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18592.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18593.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18594.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18595.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18596.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18597.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18598.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18599.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18600.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18601.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18602.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18603.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18604.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18605.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18606.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18607.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18608.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18609.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18610.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18611.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18612.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18614.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18615.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18616.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18617.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18618.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18619.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18620.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18623.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18625.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18626.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18627.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18628.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18629.png",
        "https://storage.googleapis.com/worksheetzone/default_thumnail/Group_18630.png",
    ];
}
