import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IPuzzle } from "../../../../../shared/models/puzzle";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { checkIsCreatedByToolV2 } from "../../../../../shared/utils";
import SVGShowWordDirection from "../show-word-direction";
import SvgPaintComponent from "../svg-paint";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";

const ContentWordSearchView = ({
    resourceItem,
    ratioPage,
    answer,
    width,
    height,
    isToolV2,
}: {
    showPaint?: boolean;
    isToolV2?: boolean;
    resourceItem?: IResourceItemNew;
    ratioPage?: number;
    answer?: any;
    width?: number;
    height?: number;
}) => {
    let puzzle: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    let ratio = useSelector((state: any) => state.wordSearchState.ratio);
    let isTablet = useMediaQuery("(max-width: 1024px)");
    let worksheet = useSelector(
        (state: ResourceAppState) => state.gameResourceState.worksheet
    );

    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        if (window) {
            setScreenWidth(window.innerWidth);
        }
    }, []);

    // ratio = 1.3 * ratio;
    const getMobileCellSize = (numberColumn: number) => {
        if (window !== undefined) {
            if (checkIsCreatedByToolV2(worksheet.originUrl)) {
                return width / numberColumn;
            }
            return (screenWidth * 0.8) / numberColumn;
        }
        return 25;
    };

    const getDefaultSize = (numberColumn: number) => {
        if (window) {
            let images = document.getElementsByClassName("image-container");

            if (images.length) {
                let image = images[0];
                let width = image.getBoundingClientRect().width;
                return (width * 0.8) / numberColumn / ratio;
            }
        }
    };

    const getSizeOfBox = (numberColumn: number) => {
        if (isToolV2) {
            return puzzle.puzzleCellPx;
        }
        if (width && height && puzzle) {
            return height / puzzle.puzzleArrChar.length / ratio;
        }
        return getDefaultSize(numberColumn);
    };

    return (
        <div
            id="puzzle_grid_holder"
            style={{
                marginBottom: `${4 * ratio}px`,
                marginTop: `${4 * ratio}px`,
                userSelect: "none",
            }}
        >
            <table
                id="puzzle_grid"
                cellSpacing="0"
                cellPadding="0"
                className="unselectable puzzle_font_size_small puzzle_font_times_new_roman puzzle_letter_spacing_spacious"
                style={{ fontSize: 15.6 * ratio + "px" }}
            >
                <tbody>
                    {puzzle.puzzleArrChar?.map((row, indexRow) => {
                        return (
                            <tr
                                id={"puzzle_row" + indexRow}
                                className="puzzle_row"
                                key={indexRow}
                            >
                                {row.map((col, indexCol) => {
                                    return (
                                        <td
                                            id={
                                                "puzzle_cell" +
                                                indexRow +
                                                "-" +
                                                indexCol
                                            }
                                            style={{
                                                padding: "0px",
                                                // borderRight: "0.1px solid #cdcdcd"
                                            }}
                                            className="puzzle_cell"
                                            key={indexRow + "-" + indexCol}
                                        >
                                            <div
                                                id={
                                                    "cell_char" +
                                                    indexRow +
                                                    "-" +
                                                    indexCol
                                                }
                                                style={{
                                                    width: isTablet
                                                        ? getMobileCellSize(
                                                              row.length
                                                          )
                                                        : getSizeOfBox(
                                                              row.length
                                                          ) *
                                                              ratio +
                                                          "px",
                                                    height: isTablet
                                                        ? getMobileCellSize(
                                                              row.length
                                                          )
                                                        : getSizeOfBox(
                                                              row.length
                                                          ) *
                                                              ratio +
                                                          "px",
                                                    padding: "0px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: resourceItem
                                                        ?.textAttribute
                                                        ?.fontColor,
                                                }}
                                                className="cell_char filled_cell_char"
                                            >
                                                {col}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <SVGShowWordDirection
                isCreatedByToolV2={
                    isToolV2 ? isToolV2 : width && height ? true : false
                }
                ratioPage={ratioPage}
            />
            <SvgPaintComponent
                screenWidth={screenWidth}
                answer={answer}
                isCreatedByToolV2={
                    isToolV2 ? isToolV2 : width && height ? true : false
                }
                ratioPage={ratioPage}
            />
        </div>
    );
};

export default ContentWordSearchView;
