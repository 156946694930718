import React from "react";

const PlusIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect
                    width="48"
                    height="48"
                    rx="24"
                    fill={isActive ? "#212121" : "white"}
                    fillOpacity={isActive ? "0.12" : "0.8"}
                />
                <path
                    d="M32.9025 24.007C32.9028 23.7425 32.7978 23.4887 32.6108 23.3017C32.4238 23.1147 32.17 23.0097 31.9055 23.01L24.997 23.0029L24.997 16.0874C24.9973 15.8229 24.8924 15.5691 24.7053 15.3821C24.5183 15.1951 24.2645 15.0901 24 15.0904C23.4485 15.0904 23.003 15.5359 23.003 16.0874L23.003 23.0029L16.0875 23.0029C15.5359 23.0029 15.0905 23.4484 15.0905 24C15.0905 24.5515 15.5359 24.997 16.0875 24.997L23.003 24.997L23.003 31.9125C23.003 32.464 23.4485 32.9095 24 32.9095C24.5516 32.9095 24.997 32.464 24.997 31.9125V24.997L31.9125 24.997C32.4499 24.997 32.9025 24.5444 32.9025 24.007Z"
                    fill="#221111"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_4654_31050"
                    x="-4"
                    y="-4"
                    width="56"
                    height="56"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4654_31050"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4654_31050"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default PlusIcon;
