import { useEffect, useRef, useSyncExternalStore } from "react";
import { useAppSelector } from "../../../redux/hook";
import { IResourceItemNew } from "../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import {
    resourceCanChangeShape,
    resourceCanEditLine, resourceCanEditTextStyle,
    resourceCanEditTransparency
} from "../../../utils";
import LineEditBar from "../CreateWorksheet/ElementWorksheet/Line/LineEditBar/lineEditBar";
import TextStyleBar from "../CreateWorksheet/ElementWorksheet/canvas-text/text-style-bar";
import EditTransparentBar from "./edit-transparent";
import "./index.scss";
import groupElementStore from "../../../syncExternalStore/groupElementStore";
import ChangeTypeShape from "./edit-shape";
import BorderStyleTableBar from "./edit-table/borderStyleTableBar";

function EditBar({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) {
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const currentTab = useAppSelector((state) => state.leftDataState.activeTab);
    const styleBarRef = useRef(null);
    const resourceItemsSelected = groupElementState.resourceItemsCurrent;
    
    useEffect(() => {
        const container = document.querySelector("#right-content-container");
        const styleBarEle = styleBarRef?.current;
        if (container && styleBarEle) {
            const rectElement = container.getBoundingClientRect();
            styleBarEle.style.width = `${rectElement.width}px`;
            styleBarEle.style.left = `${rectElement.x}px`;
        }
    }, [currentTab, currentElement.id]);

    if (
        !resourceCanChangeShape(currentElement, resourceItemsSelected) &&
        !resourceCanEditLine(currentElement, resourceItemsSelected) &&
        !resourceCanEditTextStyle(currentElement, resourceItemsSelected) &&
        !resourceCanEditTransparency(currentElement, resourceItemsSelected) &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_TABLE
    ) {
        return <div />;
    }

    return (
        <div className="edit-bar-container" ref={styleBarRef}>
            <div className="content">
                {currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE && (
                    <>
                        <BorderStyleTableBar
                            currentElement={currentElement}
                            resoureItems={resourceItemsSelected}
                            setCurrentElement={setCurrentElement}
                        />
                        <div className="divider"></div>
                    </>
                )}
                {resourceCanChangeShape(
                    currentElement,
                    resourceItemsSelected
                ) && (
                    <ChangeTypeShape
                        currentElement={currentElement}
                        resoureItems={resourceItemsSelected}
                        setCurrentElement={setCurrentElement}
                    />
                )}
                {resourceCanEditLine(currentElement, resourceItemsSelected) && (
                    <>
                        <div className="divider"></div>
                        <LineEditBar
                            currentItem={currentElement}
                            setCurrentElement={setCurrentElement}
                            resourceItems={resourceItemsSelected}
                        />
                    </>
                )}
                {resourceCanEditTextStyle(
                    currentElement,
                    resourceItemsSelected
                ) && (
                    <>
                        <div className="divider"></div>
                        <TextStyleBar
                            currentElement={currentElement}
                            setCurrentElement={(value) =>
                                setCurrentElement(value)
                            }
                            resourceItems={resourceItemsSelected}
                        />
                    </>
                )}
                {resourceCanEditTransparency(
                    currentElement,
                    resourceItemsSelected
                ) && (
                    <>
                        <div
                            className="divider"
                            style={{ marginRight: 10 }}
                        ></div>

                        <EditTransparentBar
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default EditBar;
