import React from "react";

const Row3 = () => {
    return (
        <svg
            width="79"
            height="14"
            viewBox="0 0 79 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 6.25C0.585786 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585786 7.75 1 7.75V6.25ZM13.5303 7.53033C13.8232 7.23744 13.8232 6.76256 13.5303 6.46967L8.75736 1.6967C8.46447 1.40381 7.98959 1.40381 7.6967 1.6967C7.40381 1.98959 7.40381 2.46447 7.6967 2.75736L11.9393 7L7.6967 11.2426C7.40381 11.5355 7.40381 12.0104 7.6967 12.3033C7.98959 12.5962 8.46447 12.5962 8.75736 12.3033L13.5303 7.53033ZM1 7.75H13V6.25H1V7.75Z"
                fill="#1F2937"
            />
            <path
                d="M29.75 1C29.75 0.585786 29.4142 0.25 29 0.25C28.5858 0.25 28.25 0.585786 28.25 1L29.75 1ZM28.4697 13.5303C28.7626 13.8232 29.2374 13.8232 29.5303 13.5303L34.3033 8.75736C34.5962 8.46447 34.5962 7.98959 34.3033 7.6967C34.0104 7.40381 33.5355 7.40381 33.2426 7.6967L29 11.9393L24.7574 7.6967C24.4645 7.40381 23.9896 7.40381 23.6967 7.6967C23.4038 7.98959 23.4038 8.46447 23.6967 8.75736L28.4697 13.5303ZM28.25 1L28.25 13L29.75 13L29.75 1L28.25 1Z"
                fill="#1F2937"
            />
            <path
                d="M57 7.75C57.4142 7.75 57.75 7.41421 57.75 7C57.75 6.58579 57.4142 6.25 57 6.25L57 7.75ZM44.4697 6.46967C44.1768 6.76256 44.1768 7.23744 44.4697 7.53033L49.2426 12.3033C49.5355 12.5962 50.0104 12.5962 50.3033 12.3033C50.5962 12.0104 50.5962 11.5355 50.3033 11.2426L46.0607 7L50.3033 2.75736C50.5962 2.46447 50.5962 1.98959 50.3033 1.6967C50.0104 1.40381 49.5355 1.40381 49.2426 1.6967L44.4697 6.46967ZM57 6.25L45 6.25L45 7.75L57 7.75L57 6.25Z"
                fill="#1F2937"
            />
            <path
                d="M72.25 13C72.25 13.4142 72.5858 13.75 73 13.75C73.4142 13.75 73.75 13.4142 73.75 13L72.25 13ZM73.5303 0.46967C73.2374 0.176777 72.7626 0.176777 72.4697 0.46967L67.6967 5.24264C67.4038 5.53553 67.4038 6.01041 67.6967 6.3033C67.9896 6.59619 68.4645 6.59619 68.7574 6.3033L73 2.06066L77.2426 6.3033C77.5355 6.59619 78.0104 6.59619 78.3033 6.3033C78.5962 6.01041 78.5962 5.53553 78.3033 5.24264L73.5303 0.46967ZM73.75 13L73.75 1L72.25 1L72.25 13L73.75 13Z"
                fill="#1F2937"
            />
        </svg>
    );
};

export default Row3;
