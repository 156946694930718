import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import Config from "../../../../../shared/utils/config";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import "./index.scss";
const WordListComponent = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    return (
        <div
            className=""
            style={{
                width: resourceItem.width,
                height: resourceItem.height,
                padding: `${ConstantsTool.PADDING_WORD_LIST}px`,
                fontSize: `${resourceItem.textAttribute.fontSize}px`,
                display: "flex",
                flexDirection: "column",
                rowGap: `${ConstantsTool.MARGIN_ROW_WORD_LIST}px`,
            }}
        >
            {resourceItem.wordList.map((row, index) => {
                return (
                    <div key={index} className="row-word-list">
                        {row.map((word, wordInd) => {
                            return (
                                <div
                                    style={{
                                        color: resourceItem?.textAttribute
                                            ?.fontColor,
                                        marginRight: `${ConstantsTool.MARGIN_WORD_LIST}px`,
                                        textDecoration:
                                            word.status ===
                                            Config.STATUS_PUZZLE.FOUND
                                                ? "line-through"
                                                : "unset",
                                    }}
                                    id={
                                        "word_search_" +
                                        (word.status ===
                                        Config.STATUS_PUZZLE.FOUND
                                            ? "found_"
                                            : "") +
                                        word.word
                                    }
                                    className="word"
                                    key={word + "-" + wordInd}
                                >
                                    {word.word}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            {/* <div
                dangerouslySetInnerHTML={{
                    _html: "",
                }}
            ></div> */}
        </div>
    );
};
export default WordListComponent;
