import Popover from "@mui/material/Popover";
import {
    MouseEvent,
    useEffect,
    useRef,
    useState,
    useSyncExternalStore,
} from "react";
import {
    BackwardIcon,
    BringToFrontIcon,
    DeleteActionIcon,
    DuplicateIcon,
    ForwardActionIcon,
    SendToBackIcon,
    ThreeDotIcon,
    AutoAjustColumnsIcon,
    AutoAjustRowsIcon,
    SettingCustomTableIcon,
    LockIcon,
    UnLockIcon,
} from "../../../../../assets/icon";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    addResourceItems,
    backwardResourceAction,
    forwardResourceAction,
    removeResourceItems,
    updateResourceItems,
    updateCurrentToResourceItem,
    setDisableAjustZonePositionWithKeyboard
} from "../../../../../redux/reducers/createWorksheet";
import { TypeOfPlacementTooltip } from "../../../../../resource-user/components/common/library/render-tooltip";
import ToolTipCustomize from "../../../../../resource-user/components/common/library/tool-tip";
import { hasPermission, removeTooltip } from "../../../../../resource-user/utils";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../../shared/models/resourceItemNew";
import { PositionInput } from "../../../../../shared/models/shapeAttribute";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import groupElementStore from "../../../../../syncExternalStore/groupElementStore";
import {
    canDeleteElement,
    isImageSvg,
    listResourceCanDuplicate,
    resourceCanEditColorOnTop,
} from "../../../../../utils";
import { ConstantsApp } from "../../../../../utils/Constants";
import { getSizeResourceItem } from "../../../../../utils/align";
import { getScreenSizes } from "../../../../../utils/create-ws";
import ChoseColor from "./ChooseColor";
import GroupActions from "./Group";
import "./index.scss";
import PopupDeleteTable from "./PopupDeleteTable";
import MenuEditTable from "../ResourceTable/MenuEditTbale/menuEditTable";
import SetFieldAction from "./SetField";
import SetZoneGroupAction from "./SetZoneGroup";
import SetValidatorAction from "./SetValidatorAction";
import { CellTable, ITableAttribute, TableAttribute } from '../../../../../shared/models/tableAttribute';
import CustomizeTableModal from './CustomizeTableModal';
import SelectExampleText from './SelectExampleText';
import SelectNbLinkedZoneContainer from './SelectNbLinkedZoneContainer';

const LIST_ACTION = [
    {
        icon: <DuplicateIcon></DuplicateIcon>,
        id: ConstantsTool.DUPLICATEid_ACTION,
        tooltip: "Duplicate",
    },
    {
        icon: <DeleteActionIcon></DeleteActionIcon>,
        id: ConstantsTool.DELETEid_ACTION,
        tooltip: "Delete",
    },
];

const LIST_MORE_OPTIONS = [
    {
        icon: <BackwardIcon></BackwardIcon>,
        id: ConstantsTool.SEND_BACKWARDid_ACTION,
        name: "Send Backward",
        // shortcut_icon: <SendBackwardShortcut></SendBackwardShortcut>,
    },
    {
        icon: <SendToBackIcon></SendToBackIcon>,
        id: ConstantsTool.SEND_TO_BACKid_ACTION,
        name: "Send to Back",
        // shortcut_icon: <SendToBackShortcut></SendToBackShortcut>,
    },
    {
        icon: <ForwardActionIcon></ForwardActionIcon>,
        id: ConstantsTool.BRING_FORWARDid_ACTION,
        name: "Bring Forward",
        // shortcut_icon: <BringForwardShorcut></BringForwardShorcut>,
    },
    {
        icon: <BringToFrontIcon></BringToFrontIcon>,
        id: ConstantsTool.BRING_TO_FRONTid_ACTION,
        name: "Bring to front",
        // shortcut_icon: <BringToFrontShortcut></BringToFrontShortcut>,
    },
    {
        icon: <LockIcon></LockIcon>,
        unLockIcon: <UnLockIcon></UnLockIcon>,
        id: ConstantsTool.LOCKid_ACTION,
        name: "Lock",
    },
];

const LIST_MORE_RESOURCE_TABLE_OPTIONS = [
    {
        icon: <AutoAjustColumnsIcon></AutoAjustColumnsIcon>,
        id: ConstantsTool.AUTO_AJUST_COLUMNSid_ACTION,
        name: "Auto ajust columns",
    },
    {
        icon: <AutoAjustRowsIcon></AutoAjustRowsIcon>,
        id: ConstantsTool.AUTO_AJUST_ROWSid_ACTION,
        name: "Auto ajust rows",
    },
    {
        icon: <SettingCustomTableIcon></SettingCustomTableIcon>,
        id: ConstantsTool.SETTING_CUSTOM_TABLEid_ACTION,
        name: "Setting",
    },
];

export const OptionElement = ({
    el,
    handleOnClickAction,
    isLocked = false,
}: {
    el: {
        id: number;
        icon: React.JSX.Element;
        name: string;
        unLockIcon?: React.JSX.Element;
    },
    handleOnClickAction: (event: MouseEvent, id: number) => void,
    isLocked?: boolean,
}) => (
    <div
        className="option"
        onClick={(
            event: MouseEvent
        ) => {
            event.stopPropagation();
            handleOnClickAction(
                event,
                el.id
            );
        }}
        key={el.id}
    >
        <div className="option-icon">
            {el.id === ConstantsTool.LOCKid_ACTION && isLocked ? el.unLockIcon : el.icon}
        </div>
        <div className="option-name">
            {el.id === ConstantsTool.LOCKid_ACTION && isLocked ? 'Unlock' : el.name}
        </div>
        {/* <div className="option-shortcut">
            {el.shortcut_icon}
        </div> */}
    </div>
)

const MoreActionComponent = ({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (currentElement: IResourceItemNew) => void;
}) => {
    const ratio = useAppSelector((state) => state.createWorksheetState.ratio);
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [showMoreOption, setShowMoreOption] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const { pagesWorksheet, pageType, templateZoneDisplayMode, activityPermissions, template } = useAppSelector(
        (state) => state.createWorksheetState
    );
    const { staffInfo } = useAppSelector((state) => state.staffInfoState);
    const [isOpenPopupDelete, setIsOpenPopupDelete] = useState(false);
    const [isOpenMore, setIsOpenMore] = useState(false);
    const refDelete = useRef(null);
    const refMore = useRef(null);
    const isTable = currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE;
    const isPopupEditTable =
        isTable &&
        currentElement?.tableAttribute &&
        currentElement?.tableAttribute?.currentPositions?.length > 0 &&
        currentElement?.tableAttribute?.currentPositions?.length <
        currentElement?.tableAttribute?.data?.length *
        currentElement?.tableAttribute?.data[0]?.length;
    const arrangeIds = (ids: string[], iback?: boolean) => {
        let newIds = [];
        pagesWorksheet[currentElement.pageIndex].resourceItems.forEach(
            (item) => {
                if (ids.includes(item.id)) {
                    newIds.push(item.id);
                }
            }
        );
        if (iback) {
            newIds = newIds.reverse();
        }
        return newIds;
    };
    const [hover, setHorver] = useState(false);
    const [showCustomizeTableModal, setShowCustomizeTableModal] = useState(false);
    const [selectedCustomizeTable, setSelectedCustomizeTable] = useState<ITableAttribute>(null);

    useEffect(() => {
        dispatch(setDisableAjustZonePositionWithKeyboard(showCustomizeTableModal));
    }, [showCustomizeTableModal]);

    const handleOnClickAction = (event: MouseEvent, id: number) => {
        let action = "";
        let table = currentElement?.tableAttribute;
        let tableAttribute = null;
        if (table) {
            tableAttribute = new TableAttribute(table);
        }

        switch (id) {
            case ConstantsTool.DUPLICATEid_ACTION:
                let resourceItems = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    groupElementStore.duplicateElements(currentElement);
                    resourceItems = [...groupElementState.resourceItemsCurrent];
                    let newCurentElement = groupElementState.resourceGroupTmp;
                    if (currentElement.id?.includes("tmp-")) {
                        dispatch(
                            updateResourceItems({
                                pageIndex: currentElement.pageIndex,
                                attributesResourceItems: [
                                    {
                                        resourceItemId: currentElement.id,
                                        attrValues: [
                                            {
                                                attribute: "resourceIds",
                                                value: newCurentElement.resourceIds,
                                            },
                                            {
                                                attribute: "x",
                                                value: newCurentElement.x,
                                            },
                                            {
                                                attribute: "y",
                                                value: newCurentElement.y,
                                            },
                                        ],
                                    },
                                ],
                                isAddHistory: true,
                            })
                        );
                    } else {
                        resourceItems.push(newCurentElement);
                    }
                    setCurrentElement({
                        ...newCurentElement,
                    });
                } else {
                    const { x, y } = currentElement;
                    resourceItems = [
                        new ResourceItemNew({
                            ...currentElement,
                            id:
                                currentElement.id +
                                "_" +
                                Math.floor(Math.random() * 99999).toString(),
                            x: x + 20,
                            y: y + 20,
                        }),
                    ];
                    setCurrentElement({
                        ...currentElement,
                        id: resourceItems[0].id,
                        x: x + 20,
                        y: y + 20,
                    });
                }

                // increase the field index of duplicated item by 1 from the current max field index
                if (resourceItems.filter(item => item.type === ConstantsTool.TYPE_RESOURCE_ZONE).length > 0) {
                    const maxFieldIndexes = {};
                    for (let i = 0; i < resourceItems.length; i++) {
                        if (resourceItems[i].type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                            let currentZonesWithFieldName = pagesWorksheet[currentElement.pageIndex].resourceItems.filter(item => item.fieldName === resourceItems[i].fieldName);
                            currentZonesWithFieldName.sort((a, b) => b.fieldIndex - a.fieldIndex);
                            maxFieldIndexes[resourceItems[i].fieldName] = currentZonesWithFieldName[0].fieldIndex;
                        }
                    }

                    for (let i = 0; i < resourceItems.length; i++) {
                        if (resourceItems[i].type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                            if (maxFieldIndexes[resourceItems[i].fieldName] !== null) {
                                maxFieldIndexes[resourceItems[i].fieldName]++;
                                resourceItems[i].fieldIndex = maxFieldIndexes[resourceItems[i].fieldName];
                            }
                        }
                    }
                }
                dispatch(
                    addResourceItems({
                        resourceItems,
                        pageIndex: currentElement.pageIndex,
                    })
                );
                break;
            case ConstantsTool.SEND_TO_BACKid_ACTION:
                action = "ncw_send_to_back";
                let idsSendToBack = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    idsSendToBack = [...currentElement.resourceIds];

                    if (!currentElement.id?.includes("tmp-")) {
                        idsSendToBack.push(currentElement.id);
                    }
                } else {
                    idsSendToBack.push(currentElement.id);
                }
                idsSendToBack = arrangeIds(idsSendToBack, true);
                idsSendToBack.forEach((id) => {
                    dispatch(
                        backwardResourceAction({
                            resourceId: id,
                            pageIndex: currentElement.pageIndex,
                            action,
                        })
                    );
                });
                break;
            case ConstantsTool.SEND_BACKWARDid_ACTION:
                action = "ncw_send_backward";
                let idsSendBackward = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    idsSendBackward = [...currentElement.resourceIds];

                    if (!currentElement.id?.includes("tmp-")) {
                        idsSendBackward.push(currentElement.id);
                    }
                } else {
                    idsSendBackward.push(currentElement.id);
                }
                idsSendBackward = arrangeIds(idsSendBackward, true);
                idsSendBackward.forEach((id) => {
                    dispatch(
                        backwardResourceAction({
                            resourceId: id,
                            pageIndex: currentElement.pageIndex,
                            action,
                        })
                    );
                });
                break;
            case ConstantsTool.BRING_TO_FRONTid_ACTION:
                action = "ncw_bring_to_front";
                let idsBringToFront = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    idsBringToFront = [...currentElement.resourceIds];
                }
                idsBringToFront.push(currentElement.id);
                idsBringToFront = arrangeIds(idsBringToFront);
                idsBringToFront.forEach((id) => {
                    dispatch(
                        forwardResourceAction({
                            resourceId: id,
                            pageIndex: currentElement.pageIndex,
                            action,
                        })
                    );
                });
                break;
            case ConstantsTool.BRING_FORWARDid_ACTION:
                action = "ncw_bring_forward";
                let idsBringForward = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    idsBringForward = [...currentElement.resourceIds];
                }
                idsBringForward.push(currentElement.id);
                idsBringForward = arrangeIds(idsBringForward);
                idsBringForward.forEach((id) => {
                    dispatch(
                        forwardResourceAction({
                            resourceId: id,
                            pageIndex: currentElement.pageIndex,
                            action,
                        })
                    );
                });
                break;

            case ConstantsTool.DELETEid_ACTION:
                // if (currentElement.idType?.includes("ws_title")) {
                //     dispatch(changeTitlePuzzleAction(""));
                // }
                // if (currentElement.idType?.includes("ws_description")) {
                //     dispatch(changeDescriptionPuzzleAction(""));
                // }
                if (currentElement?.tableAttribute?.currentPositions?.length) {
                    let table = currentElement.tableAttribute;
                    if (
                        table.currentPositions.length !==
                        table.data.length * table.data[0].length
                    ) {
                        setIsOpenPopupDelete(true);
                        break;
                    }
                }
                let resourceIds = [];
                if (
                    currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    resourceIds = [...currentElement.resourceIds];
                    groupElementStore.resetResourceItems();
                }
                resourceIds.push(currentElement.id);
                dispatch(
                    removeResourceItems({
                        resourceIds: resourceIds,
                        pageIndex: currentElement.pageIndex,
                    })
                );
                if (currentElement.isGroup) {
                    groupElementStore.deleteResourceItem(currentElement.id);
                    let item = groupElementStore.makeResourceItemGroup(
                        true,
                        true
                    );
                    if (item) {
                        if (item.resourceIds.length === 1) {
                            dispatch(
                                removeResourceItems({
                                    resourceIds: [item.id],
                                    pageIndex: currentElement.pageIndex,
                                })
                            );
                            dispatch(
                                updateResourceItems({
                                    pageIndex: currentElement.pageIndex,
                                    attributesResourceItems: [
                                        {
                                            resourceItemId: item.resourceIds[0],
                                            attrValues: [
                                                {
                                                    attribute: "isGroup",
                                                    value: false,
                                                },
                                            ],
                                        },
                                    ],
                                })
                            );
                            setCurrentElement(null);
                        } else {
                            dispatch(
                                updateResourceItems({
                                    pageIndex: currentElement.pageIndex,
                                    resourceItems: [item],
                                })
                            );
                            setCurrentElement({ ...item });
                        }
                    }
                } else {
                    setCurrentElement(null);
                }
                break;
            case ConstantsTool.AUTO_AJUST_COLUMNSid_ACTION:
                if (tableAttribute?.data && tableAttribute.data[0]) {
                    const nbCols = tableAttribute.data[0].length;
                    const currentTableWidth = tableAttribute.getTableWidth();
                    const ajustCellWidth = currentTableWidth / nbCols;
                    tableAttribute.data = tableAttribute.data.map((row: CellTable[]) => row.map((cell: CellTable) => ({
                        ...cell,
                        width: ajustCellWidth
                    })));
                    handleCurrentElementChange({
                        tableAttribute
                    })
                }
                break;
            case ConstantsTool.AUTO_AJUST_ROWSid_ACTION:
                if (tableAttribute?.data && tableAttribute.data[0]) {
                    const nbRows = tableAttribute.data.length;
                    const currentTableHeight = currentElement.height;
                    const ajustCellHeight = currentTableHeight / nbRows;
                    tableAttribute.data = tableAttribute.data.map((row: CellTable[]) => row.map((cell: CellTable) => ({
                        ...cell,
                        height: ajustCellHeight,
                        originHeight: ajustCellHeight
                    })));

                    handleCurrentElementChange({
                        tableAttribute
                    })
                }
                break;
            case ConstantsTool.SETTING_CUSTOM_TABLEid_ACTION:
                if (tableAttribute?.data && tableAttribute.data[0]) {
                    setShowCustomizeTableModal(true);
                    setSelectedCustomizeTable(tableAttribute);
                }
                break;
            case ConstantsTool.LOCKid_ACTION:
                const currentStatus = currentElement.isLocked;
                console.log("Locked", currentStatus);
                handleCurrentElementChange({
                    isLocked: !currentStatus
                });
                break;
        }
        return;
    };
    useEffect(() => {
        if (ref?.current) {
            const { clientWidth, clientHeight } = ref.current;
            const { x, y, width, height } = currentElement;
            let dy = 60,
                newSize = getSizeResourceItem(
                    x,
                    y,
                    width,
                    height,
                    currentElement.rotate
                );
            dy += y - newSize.y;
            const translateX = (x + width / 2) * ratio - clientWidth / 2;
            if (
                currentElement.type ===
                ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE
            ) {
                let screens = getScreenSizes("content-layer");
                let maxY = screens[currentElement.pageIndex].height / ratio;
                let positionInput =
                    currentElement.shapeAttribute.input.position;
                if (positionInput === PositionInput.top) {
                    if (y < maxY - dy) {
                        ref.current.style.transform = `translate(${translateX}px, ${(y + height + 50) * ratio
                            }px)`;
                    } else {
                        ref.current.style.transform = `translate(${translateX}px, ${(y -
                            height -
                            (positionInput === PositionInput.top
                                ? currentElement.textAttribute.fontSize *
                                1.3
                                : 0) -
                            dy) *
                            ratio
                            }px)`;
                    }
                } else {
                    if (y < 120) {
                        ref.current.style.transform = `translate(${translateX}px, ${(y +
                            height +
                            (positionInput === PositionInput.bottom
                                ? currentElement.textAttribute.fontSize *
                                1.3
                                : 0) +
                            50) *
                            ratio
                            }px)`;
                    } else {
                        ref.current.style.transform = `translate(${translateX}px, ${(y - height - dy) * ratio
                            }px)`;
                    }
                }
            } else {
                if (y < 120) {
                    ref.current.style.transform = `translate(${translateX}px, ${(y + height) * ratio + dy - clientHeight
                        }px)`;
                } else {
                    ref.current.style.transform = `translate(${translateX}px, ${y * ratio - dy
                        }px)`;
                }
            }

            if (
                currentElement.type ===
                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE ||
                currentElement.type ===
                ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
            ) {
                ref.current.style.transform = `translate(${translateX}px, ${0 * ratio
                    }px)`;
            }
        }
    }, [currentElement, ratio]);

    const onMore = (event: any) => {
        if (isPopupEditTable) {
            setIsOpenMore(true);
            return;
        }
        setAnchorEl(event.currentTarget);
        setShowMoreOption((prev) => !prev);
    };

    const makeView = (tooltip: string, view: any, id?: any) => {
        if (hover)
            return (
                <ToolTipCustomize
                    title={tooltip}
                    placement={TypeOfPlacementTooltip.top}
                    style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
                    key={id}
                >
                    {view}
                </ToolTipCustomize>
            );
        else return <div id={id}>{view}</div>;
    };

    const handleCurrentElementChange = (updatedValues: object = {}) => {
        const newCurrentElement = { ...currentElement, ...updatedValues };
        setCurrentElement(newCurrentElement);
        dispatch(
            updateCurrentToResourceItem({
                currentElement: newCurrentElement,
                pageIndex: currentElement.pageIndex,
                isAddHistory: true,
            })
        );
    };

    return (
        <div
            ref={ref}
            className="more-action-container"
            onMouseEnter={() => setHorver(true)}
        >
            <div className="layer">
                {(isImageSvg(currentElement) ||
                    resourceCanEditColorOnTop(currentElement) ||
                    isTable) && (
                        <div className="list-action">
                            <ChoseColor
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                            />
                        </div>
                    )}
                {(currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT || currentElement.isGroup) && (
                    <GroupActions
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                    />
                )}
                {[ConstantsTool.TYPE_RESOURCE_ZONE, ConstantsTool.RESOURCE_TEXT, ConstantsTool.TYPE_RESOURCE_ZONE_STATIC].includes(currentElement.type) && (
                    <SetFieldAction
                        currentElement={currentElement}
                        handleCurrentElementChange={handleCurrentElementChange}
                    />
                )}
                {currentElement.type === ConstantsTool.RESOURCE_TEXT && currentElement.customZoneID !== null && (
                    <SetZoneGroupAction
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                    />
                )}
                {templateZoneDisplayMode === 'zoneOnly' && (
                    <SetValidatorAction
                        currentElement={currentElement}
                        handleCurrentElementChange={handleCurrentElementChange}
                    />
                )}
                <div className="list-action">
                    {LIST_ACTION.map((el) => {
                        let enableAction = true;
                        let action = "";
                        switch (el.id) {
                            case ConstantsTool.DUPLICATEid_ACTION:
                                action = "ncw_duplicate";
                                enableAction = listResourceCanDuplicate(currentElement);
                                if (enableAction && templateZoneDisplayMode === 'zoneOnly' && !Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType)) {
                                    enableAction = false;
                                }
                                if (currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER) {
                                    enableAction = false;
                                }
                                break;
                            case ConstantsTool.DELETEid_ACTION:
                                action = "ncw_delete";
                                enableAction = canDeleteElement(currentElement);
                                if (enableAction && templateZoneDisplayMode === 'zoneOnly' && !Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType)) {
                                    enableAction = false;
                                }
                                if (template?.isUsingInIdea && !hasPermission(staffInfo['roles'], activityPermissions[ConstantsTool.DELETE_USING_ZONE_IN_TEMPLATE_ACTION])) {
                                    enableAction = false;
                                }
                                break;
                        }

                        return makeView(
                            el.tooltip,
                            <div
                                key={el.id}
                                onClick={(event: MouseEvent) => {
                                    removeTooltip();
                                    if (enableAction) {
                                        handleOnClickAction(event, el.id);
                                    }
                                }}
                                className={
                                    "action " +
                                    (enableAction ? "enable" : "disable")
                                }
                                ref={
                                    el.id === ConstantsTool.DELETEid_ACTION
                                        ? refDelete
                                        : null
                                }
                            >
                                {el.icon}
                            </div>,
                            el.id
                        );
                    })}
                    {!currentElement.isGroup &&
                        makeView(
                            "More",
                            <div
                                ref={refMore}
                                className="action enable"
                                onClick={onMore}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <ThreeDotIcon></ThreeDotIcon>
                                </div>
                                <Popover
                                    open={showMoreOption}
                                    anchorEl={anchorEl}
                                    onClose={() => {
                                        removeTooltip();
                                        handleClose();
                                        setCurrentElement({
                                            ...currentElement,
                                        });
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    <div className="more-option-panel">
                                        {LIST_MORE_OPTIONS.map((el) => {
                                            return (
                                                <OptionElement key={el.id} el={el} handleOnClickAction={handleOnClickAction} isLocked={currentElement.isLocked} />
                                            );
                                        })}
                                        {currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE && (
                                            <>
                                                {LIST_MORE_RESOURCE_TABLE_OPTIONS.map((el) => {
                                                    return (
                                                        <OptionElement key={el.id} el={el} handleOnClickAction={handleOnClickAction} />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                </Popover>
                            </div>
                        )}
                </div>
                {Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && templateZoneDisplayMode === 'textOnly' && (currentElement.type === ConstantsTool.RESOURCE_TEXT || currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER) && (
                    <SelectExampleText currentElement={currentElement}/>
                )}
                {Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && templateZoneDisplayMode === 'textOnly' && (currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER) && (
                    <SelectNbLinkedZoneContainer currentElement={currentElement}/>
                )}
                {currentElement.isLocked && (
                    <div className="lock-status">
                        <LockIcon></LockIcon>
                    </div>
                )}
            </div>
            {isTable && (
                <PopupDeleteTable
                    open={isOpenPopupDelete}
                    setOpen={setIsOpenPopupDelete}
                    currentElement={currentElement}
                    setCurrentElement={setCurrentElement}
                    anchorElement={refDelete.current}
                />
            )}
            {isPopupEditTable && (
                <MenuEditTable
                    setOpen={setIsOpenMore}
                    setCurrentElement={setCurrentElement}
                    currentElement={currentElement}
                    open={isOpenMore}
                    anchorElement={refMore.current}
                />
            )}
            {showCustomizeTableModal && (
                <CustomizeTableModal
                    hideModal={() => {
                        setShowCustomizeTableModal(false);
                        setSelectedCustomizeTable(null);
                    }}
                    tableAttribute={selectedCustomizeTable}
                    currentTableWidth={selectedCustomizeTable?.getTableWidth()}
                    currentTableHeight={currentElement.height}
                    handleCurrentElementChange={handleCurrentElementChange}
                />
            )}
        </div>
    );
};

export default MoreActionComponent;
