import { useDispatch } from "react-redux";
import Config from "../../../../../../shared/utils/config";
import { selectActionGridItemAction } from "../../../../../redux/action/resource.action";
import PlusIconFolder from "../../../../icons/plus-icon-folder";
import "./style.scss";
const CreateNewCollection = ({
    addGaEventCreateCollection,
}: {
    addGaEventCreateCollection?: Function;
}) => {
    const dispatch = useDispatch();
    const handleCreateCollection = () => {
        // setOpen(true);
        addGaEventCreateCollection && addGaEventCreateCollection();
        dispatch(
            selectActionGridItemAction({
                action: Config.ACTION_GRID.CREATE_COLLECTION,
            })
        );
    };

    return (
        <>
            <div
                className="blank-collection"
                onClick={() => {
                    handleCreateCollection();
                }}
            >
                <div className="blank-collection-content">
                    <PlusIconFolder />
                    <p className="text">Add Collection</p>
                </div>
            </div>
        </>
    );
};

export default CreateNewCollection;
