const CircleChecked = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99984 12.8333C10.2215 12.8333 12.8332 10.2217 12.8332 7C12.8332 3.77834 10.2215 1.16666 6.99984 1.16666C3.77818 1.16666 1.1665 3.77834 1.1665 7C1.1665 10.2217 3.77818 12.8333 6.99984 12.8333ZM6.99983 9.99999C8.65668 9.99999 9.99983 8.65684 9.99983 6.99999C9.99983 5.34313 8.65668 3.99999 6.99983 3.99999C5.34297 3.99999 3.99983 5.34313 3.99983 6.99999C3.99983 8.65684 5.34297 9.99999 6.99983 9.99999Z"
            fill="#2183DF"
        />
    </svg>
);
export default CircleChecked;
