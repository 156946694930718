import { useLayoutEffect, useRef, useState } from "react";
import {
    IResourceItemNew,
    ITextAttribute,
} from "../../../../../../../shared/models/resourceItemNew";
import { IconSVG, svgPath } from "../../../../../../../assets/icon/icons";
import PopupModal from "../../../../../../../resource-user/components/common/pop-up/PopupModal";
import SliderInput from "../../../../../EditBar/common/sliderInput";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hook";
import { updateResourceItems } from "../../../../../../../redux/reducers/createWorksheet";
import { getSizeBoxText } from "../../../../../../../utils/draw";
import { ListStyle } from "../../../../../../../shared/models/WorkSheetCreator";
import ConstantsTool from "../../../../../../../shared/utils/ConstantsTool";
import { TableAttribute } from "../../../../../../../shared/models/tableAttribute";
import { changeFontAttributes } from "../../../../../../../utils/table";
import groupElementStore from "../../../../../../../syncExternalStore/groupElementStore";
import { syncTemplateLinkedTextToZone } from "../../../../../../../utils";

function EditSpacingView({
    currentElement,
    setCurrentElement,
    listStyle,
    updateFontStyleGroup,
    isGroup,
    currentTextAttribute,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    listStyle: ListStyle;
    updateFontStyleGroup(field: string, value: any): void;
    isGroup: boolean;
    currentTextAttribute: ITextAttribute;
}) {
    const dispatch = useAppDispatch();
    const {tempTemplateZones} = useAppSelector(state => state.createWorksheetState);

    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [letterSpacing, setLetterSpacing] = useState(0);
    const [lineHeight, setLineHeight] = useState(0);
    const minLetterSpacing = -20;
    const maxLetterSpacing = 90;
    const minLineHeight = 0.1;
    const maxLineHeight = 3;
    const fixedLetterSpacing = 100;
    let textAttribute = currentElement?.textAttribute;
    const isTable = currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE;

    if (isTable) {
        let tableAttribute = new TableAttribute(currentElement.tableAttribute);
        textAttribute = tableAttribute.getTextAtribute();
    }
    if (isGroup) {
        textAttribute = currentTextAttribute;
    }

    useLayoutEffect(() => {
        if (textAttribute?.letterSpacing !== letterSpacing)
            setLetterSpacing(textAttribute?.letterSpacing * fixedLetterSpacing);

        if (textAttribute?.lineHeight !== lineHeight)
            setLineHeight(textAttribute?.lineHeight ?? textAttribute?.fontSize);
    }, [textAttribute?.lineHeight, textAttribute?.letterSpacing]);

    const updateResource = (
        resourceItem: IResourceItemNew,
        field: "letterSpacing" | "lineHeight"
    ) => {
        if (isGroup) {
            let value = letterSpacing / fixedLetterSpacing;
            if (field === "lineHeight") value = lineHeight;
            updateFontStyleGroup(field, value);
            return;
        }
        dispatch(
            updateResourceItems({
                pageIndex: currentElement.pageIndex,
                resourceItems: [
                    {
                        ...resourceItem,
                    },
                ],
            })
        );

        syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {textAttribute: resourceItem.textAttribute});
    };

    const onChangeCurrentElement = (
        textAttribute: ITextAttribute,
        field: "letterSpacing" | "lineHeight",
        value: any
    ) => {
        let size = {
            width: currentElement.width,
            height: currentElement.height,
        };

        let resourceItem: IResourceItemNew = { ...currentElement };
        if (field === "letterSpacing") {
            value = value / fixedLetterSpacing;
        }
        if (isTable) {
            resourceItem = changeSpacingTable(field, value);
        } else if (isGroup) {
            resourceItem = groupElementStore.updateFontStyle(
                field,
                value,
                listStyle,
                currentElement
            ).resourceParent;
        } else {
            if (currentElement.type !== ConstantsTool.TYPE_WORD_BANK)
                size = getSizeBoxText({
                    textAttribute: textAttribute,
                    width: currentElement.width,
                    type: currentElement.type,
                    listStyle,
                });
            resourceItem = {
                ...currentElement,
                ...size,
                textAttribute,
            };
        }
        setCurrentElement({ ...resourceItem });
        return resourceItem;
    };

    const changeSpacingTable = (field: string, value: string | number) => {
        let tableAttribute = new TableAttribute(currentElement.tableAttribute);
        let { dHeight } = changeFontAttributes({
            field,
            value,
            tableAttribute,
        });
        return {
            ...currentElement,
            tableAttribute,
            height: currentElement.height + dHeight,
        };
    };

    const onChange = (
        value: number,
        field: "letterSpacing" | "lineHeight",
        input?: "input"
    ) => {
        if (isNaN(value)) return;
        let newTextAttribute = { ...textAttribute };
        if (field === "letterSpacing") {
            setLetterSpacing(value);
            if (value >= minLetterSpacing && value <= maxLetterSpacing) {
                newTextAttribute.letterSpacing = value / fixedLetterSpacing;
                let resourceItem = onChangeCurrentElement(
                    newTextAttribute,
                    "letterSpacing",
                    value
                );
                if (input) {
                    updateResource(resourceItem, field);
                }
            }
        } else {
            setLineHeight(value);
            if (value >= minLineHeight && value <= maxLineHeight) {
                newTextAttribute.lineHeight = value;
                let resourceItem = onChangeCurrentElement(
                    newTextAttribute,
                    "lineHeight",
                    value
                );
                if (input) {
                    updateResource(resourceItem, field);
                }
            }
        }
    };

    return (
        <div className="edit-spacing-container">
            <div
                ref={ref}
                className={"icon " + (open ? "active" : "")}
                onClick={() => setOpen(true)}
            >
                <img src={svgPath(IconSVG.icon_spacing)} />
            </div>
            <PopupModal
                open={open}
                anchorElement={ref.current}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                top={4}
                left={-88}
                onClose={() => {
                    setOpen(false);
                }}
                classname="edit-spacing-container"
            >
                <div className="popup-edit-bar-container">
                    <SliderInput
                        label={"Letter spacing"}
                        value={letterSpacing}
                        min={minLetterSpacing}
                        max={maxLetterSpacing}
                        onChangeInput={function (value: number): void {
                            onChange(value, "letterSpacing", "input");
                        }}
                        onChangeSlider={(value) => {
                            onChange(value, "letterSpacing");
                        }}
                        onChangeCommitted={() => {
                            setTimeout(() => {
                                updateResource(currentElement, "letterSpacing");
                            }, 10);
                        }}
                    />
                    <SliderInput
                        label={"Line spacing"}
                        value={lineHeight}
                        min={minLineHeight}
                        max={maxLineHeight}
                        step={0.1}
                        onChangeInput={function (value: number): void {
                            onChange(value, "lineHeight", "input");
                        }}
                        onChangeSlider={(value) => {
                            onChange(value, "lineHeight");
                        }}
                        onChangeCommitted={() => {
                            setTimeout(() => {
                                updateResource(currentElement, "lineHeight");
                            }, 10);
                        }}
                    />
                </div>
            </PopupModal>
        </div>
    );
}

export default EditSpacingView;
