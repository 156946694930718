import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStaffInfo } from "../../shared/models/staffInfo";

type initialStateType = {
    staffInfo: IStaffInfo | null;
};

const initialState: initialStateType = {
    staffInfo: null,
};

export const staffInfoSlice = createSlice({
    name: "staffInfo",
    initialState,
    reducers: {
        setStaffInfo: (state, action: PayloadAction<IStaffInfo>) => {
            state.staffInfo = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

export const { setStaffInfo } = staffInfoSlice.actions;
export default staffInfoSlice.reducer;