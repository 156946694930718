import { Activity } from "../../../shared/models/WorkSheetCreator";
import ListCheckBox from "./check-box-list";
import "./index.scss";
import MainSentenceView from "./sentence-list/MainSentence";
import StrokeStyle from "./stroke-style";
const LeftFillInBlankComponent = ({
    activity,
    isActive,
}: {
    activity: Activity;
    isActive?: boolean;
}) => {
    return (
        <div className="wrapper">
            <MainSentenceView activity={activity} isActive={isActive} />
            <div className="box-list-action">
                <StrokeStyle activity={activity} />
                <ListCheckBox activity={activity} />
            </div>
        </div>
    );
};
export default LeftFillInBlankComponent;
