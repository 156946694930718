import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import Switch from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Collection,
    ICollection,
    WorksheetItem,
} from "../../../../shared/models/collection";
import { IWorksheet, Worksheet } from "../../../../shared/models/worksheet";
import { isResourceCollection } from "../../../../shared/utils";
import Config from "../../../../shared/utils/config";
import {
    createNewCollectionResourceSuccessAction,
    editItemCollectionResourceSuccessAction,
    selectActionGridItemAction,
    selectGridItemAction,
    sendEventGaAction,
    updateActionsGaSuccessAction,
    updateAlertResourceAction,
} from "../../../redux/action/resource.action";
import { updateAlertSnackBarAction } from "../../../redux/action/snackbar.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import { updateItemCollectionByIdAPI } from "../../../redux/repositories/collection.repositories";
import LoadingComponent from "../../common/loading/loading-component";
import CloseIconSvg from "../../icons/close-icon";
import HintIconSvg from "../../icons/hint";
import PopUpStepAction from "../../main-login";
import "./index.scss";

const PopupEditCollectionResource = ({
    showPopupEditCollection,
    setShowPopupEditCollection,
    collection,
    createWs,
    titlePopup,
    autoComplete,
    worksheet,
    setShowPopup,
}: {
    showPopupEditCollection: boolean;
    setShowPopupEditCollection: Function;
    collection?: ICollection;
    createWs?: boolean;
    titlePopup?: string;
    autoComplete?: boolean;
    setShowPopup?: Function;
    worksheet?: IWorksheet;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");

    const closePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPopupEditCollection(false);
    };
    if (isMobile) {
        return (
            <Drawer
                className="popup-edit-collection"
                anchor={"bottom"}
                onClose={(e) => closePopup(e)}
                open={showPopupEditCollection}
                variant="temporary"
                disableEnforceFocus
            >
                <div className="close-button" onClick={(e) => closePopup(e)}>
                    <HintIconSvg />
                </div>
                <PopupContent
                    setShowPopupEditCollection={(value) =>
                        setShowPopupEditCollection(value)
                    }
                    collection={collection}
                    titlePopup={titlePopup}
                    autoComplete={autoComplete}
                    setShowPopup={setShowPopup}
                />
            </Drawer>
        );
    }
    return (
        <Dialog
            open={showPopupEditCollection}
            onClose={(event) => {
                closePopup(event);
                // setShowPopupEditCollection(false);
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="popup-edit-collection"
            disableEnforceFocus
        >
            <PopupContent
                setShowPopupEditCollection={(value) =>
                    setShowPopupEditCollection(value)
                }
                collection={collection}
                titlePopup={titlePopup}
                autoComplete={autoComplete}
                setShowPopup={setShowPopup}
            />
        </Dialog>
    );
};

const PopupContent = ({
    setShowPopupEditCollection,
    collection,
    titlePopup,
    autoComplete,
    setShowPopup,
}: {
    setShowPopupEditCollection: Function;
    collection?: ICollection;
    titlePopup?: string;
    autoComplete?: boolean;
    setShowPopup?: Function;
}) => {
    let title = titlePopup ? titlePopup : "Edit this collection";
    const dispatch = useDispatch();
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isMobile = useMediaQuery("(max-width:768px)");

    const [nameCollection, setNameCollection] = useState(collection?.name);
    const [showPopupSurvey, setShowPopupSurvey] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [sharingStatus, setSharingStatus] = useState(
        collection?.id
            ? collection?.sharingStatus == Config.STATUS_PUBLIC
            : true
    );
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );

    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    const eventsGa = actionGridItem?.eventsGaAfterSuccess;

    const toCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.toCollection.data
    );
    const arrayCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.toCollection?.arrayCollection
    );

    const [descriptionCollection, setDescriptionCollection] = useState("");
    const handleClosePopup = (doneAction = true) => {
        setShowPopupEditCollection(false, doneAction);
        if (eventsGa?.length > 0) {
            dispatch(updateActionsGaSuccessAction({ eventsGa: [] }));
        }
        if (actionGridItem?.action == Config.ACTION_GRID.CREATE_COLLECTION) {
            dispatch(
                selectActionGridItemAction({
                    action: actionGridItem.previousAction,
                })
            );
        }
        if (actionGridItem?.worksheet?.id || actionGridItem?.collection?.id) {
            dispatch(
                selectGridItemAction({
                    collection: new Collection(),
                    worksheet: new Worksheet(),
                    toCollection: true,
                })
            );
        }
        if (isTablet) {
            dispatch(
                selectActionGridItemAction({
                    action: Config.ACTION_GRID.DEFAULT,
                })
            );
        }
    };

    const addToCurrentCollection = arrayCollection?.find(
        (c) => c?.id === toCollection?.id
    )
        ? false
        : true;
    const updateCollection = async (name: string, description: string) => {
        let params: any = {};
        if (!!name) {
            params.name = name;
        }
        if (params.name) {
            if (!!userInfo?.email) {
                setError("");
                setLoading(true);
                if (!!description) {
                    params.introductionDescription = description;
                }
                params.sharingStatus = 1;
                if (!sharingStatus) {
                    params.sharingStatus = 0;
                }

                if (
                    params &&
                    actionGridItem.action == Config.ACTION_GRID.EDIT
                ) {
                    params.userId = collection?.userId;
                    params.collectionId = collection?.id;
                    let result = await updateItemCollectionByIdAPI(params);
                    if (result?.id) {
                        dispatch(
                            editItemCollectionResourceSuccessAction({
                                collectionId: collection?.id,
                                introductionDescription:
                                    result.introductionDescription,
                                name: result.name,
                                sharingStatus: result.sharingStatus,
                            })
                        );
                        dispatch(
                            updateAlertResourceAction(
                                true,
                                "Edit success",
                                Config.SUCCESS,
                                Config.TYPE_ALERT.ALERT_ACTION_WEB
                            )
                        );
                        if (eventsGa?.length > 0) {
                            dispatch(sendEventGaAction());
                        }
                        handleClosePopup();
                        setLoading(false);
                    }
                }
                if (
                    params?.name &&
                    actionGridItem.action ==
                        Config.ACTION_GRID.CREATE_COLLECTION
                ) {
                    params.userId = userInfo.id;
                    params.type =
                        userInfo?.role == Config.USER_ROLE_CONTENT_MANAGER
                            ? Config.COLLECTION_TYPE_TEAM_CONTENT
                            : Config.COLLECTION_TYPE_USER_CREATED;
                    let parentCollectionIds = [];
                    let parentCollection =
                        addToCurrentCollection && toCollection?.id
                            ? toCollection
                            : null;
                    if (
                        addToCurrentCollection &&
                        parentCollection?.id &&
                        !isResourceCollection({
                            collectionId: parentCollection?.id,
                        })
                    ) {
                        parentCollectionIds = [
                            ...(parentCollection?.parentIds ?? []),
                            parentCollection?.id,
                        ];
                    }
                    params.parentIds = parentCollectionIds;
                    const isSaveToCollection =
                        !!actionGridItem?.worksheet?.id ||
                        actionGridItem?.worksheets?.length > 0;
                    if (isSaveToCollection) {
                        if (actionGridItem?.worksheets?.length > 0) {
                            const worksheetItems =
                                actionGridItem?.worksheets?.map((item) => {
                                    return new WorksheetItem({
                                        worksheetId: item?.id,
                                        joinedDate: new Date(),
                                    });
                                });
                            params.worksheetItems = worksheetItems;
                        } else if (actionGridItem?.worksheet?.id) {
                            const worksheetItem = new WorksheetItem({
                                worksheetId: actionGridItem?.worksheet?.id,
                                joinedDate: new Date(),
                            });
                            params.worksheetItems = [worksheetItem];
                        }
                    }
                    params.createDate = new Date();
                    let result = await updateItemCollectionByIdAPI(params);
                    if (result?.id) {
                        result.createDate = params.createDate;
                        if (parentCollection?.id) {
                            result.parentIds = [
                                ...(parentCollection?.parentIds ?? []),
                                parentCollection?.id,
                            ];
                            result.parentCollectionName = [
                                ...(parentCollection?.parentCollectionName ??
                                    []),
                                {
                                    id: parentCollection?.id,
                                    name: parentCollection?.name,
                                },
                            ];
                        }
                        if (result?.numberOfWorksheets <= 0) {
                            result.worksheetItems =
                                result?.worksheetItems?.filter(
                                    (value, index, self) =>
                                        index ===
                                        self.findIndex(
                                            (t) =>
                                                t.worksheetId ===
                                                value.worksheetId
                                        )
                                ) ?? [];
                            result.numberOfWorksheets =
                                result?.worksheetItems?.length ?? 0;
                        }

                        dispatch(
                            createNewCollectionResourceSuccessAction(result)
                        );
                        dispatch(
                            selectActionGridItemAction({
                                action: Config.ACTION_GRID.DEFAULT,
                            })
                        );
                        if (!isSaveToCollection) {
                            dispatch(
                                updateAlertResourceAction(
                                    true,
                                    "Create Collection Success",
                                    Config.SUCCESS,
                                    Config.TYPE_ALERT.ALERT_ACTION_WEB
                                )
                            );
                        } else {
                            let wsThumbnail = actionGridItem?.worksheets?.find(
                                (ws) => ws.thumbnail
                            );
                            if (!wsThumbnail) {
                                wsThumbnail = actionGridItem?.worksheets?.find(
                                    (ws) => ws.game?.images[0]
                                );
                            }
                            const thumbnail = wsThumbnail?.thumbnail
                                ? wsThumbnail?.thumbnail
                                : actionGridItem?.worksheet?.thumbnail
                                ? actionGridItem?.worksheet?.thumbnail
                                : actionGridItem?.worksheet?.game?.images[0] ??
                                  "";
                            result.thumbnail = thumbnail ?? "";
                            dispatch(
                                updateAlertSnackBarAction({
                                    message: "",
                                    collectionId: result?.id,
                                    collection: result,
                                    severity: "success",
                                })
                            );
                        }
                        if (actionGridItem?.worksheet?.id) {
                            dispatch(
                                selectGridItemAction({
                                    worksheet: actionGridItem?.worksheet,
                                    worksheets: [],
                                })
                            );
                            dispatch(
                                selectActionGridItemAction({
                                    action: Config.ACTION_GRID.SAVE,
                                })
                            );
                        }
                        if (eventsGa?.length > 0) {
                            dispatch(sendEventGaAction());
                        }
                    }
                    setLoading(false);
                }
                setNameCollection("");
                setDescriptionCollection("");
                isTablet && handleClosePopup();
                setShowPopup && setShowPopup(false);
            } else {
                setLoading(true);
                setShowPopupSurvey(true);
            }
        } else {
            setError("This field is required");
        }
    };
    const handleChangeName = (e) => {
        setNameCollection(e.target.value);
    };
    const handleChangeDescription = (e) => {
        setDescriptionCollection(e.target.value);
    };

    return (
        <>
            <div
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                <div className="popup-content resource">
                    {loading ? (
                        <div
                            style={{
                                height: "273px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <LoadingComponent />
                        </div>
                    ) : (
                        <>
                            <div className="title">
                                <p>{title}</p>
                                {!isMobile && (
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            handleClosePopup();
                                        }}
                                    >
                                        <CloseIconSvg />
                                    </div>
                                )}
                            </div>
                            <div className="content">
                                <div className="field">
                                    <p className="label">Name</p>

                                    <Input
                                        onChange={handleChangeName}
                                        placeholder="Enter a name for your collection"
                                        defaultValue={
                                            autoComplete ? collection.name : ""
                                        }
                                        onFocus={() => setError("")}
                                        onBlur={() =>
                                            !!!nameCollection &&
                                            setError("This field is required")
                                        }
                                    />
                                    {!!error && (
                                        <p
                                            style={{
                                                color: "#FF2442",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                paddingTop: "4px",
                                            }}
                                        >
                                            {error}
                                        </p>
                                    )}
                                </div>
                                <div className="field">
                                    <p className="label">Description</p>
                                    <Input
                                        onChange={handleChangeDescription}
                                        placeholder="Enter a description for your collection"
                                        defaultValue={
                                            autoComplete
                                                ? collection.introductionDescription
                                                : ""
                                        }
                                    />
                                </div>
                                <div className="field status">
                                    <div>
                                        <p>
                                            Keep this collection
                                            {!sharingStatus
                                                ? " private"
                                                : " public"}
                                        </p>
                                        <FormControlLabel
                                            className="switch-form"
                                            control={
                                                <Switch
                                                    sx={{ m: 1 }}
                                                    onClick={() => {
                                                        setSharingStatus(
                                                            !sharingStatus
                                                        );
                                                    }}
                                                    // onChange={() => {
                                                    //     setSharingStatus(!sharingStatus);
                                                    // }}
                                                    checked={!sharingStatus}
                                                    className={
                                                        !sharingStatus
                                                            ? "checked"
                                                            : "check"
                                                    }
                                                />
                                            }
                                            label=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="buttons">
                                <div
                                    className="button cancel"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (
                                            event.target === event.currentTarget
                                        ) {
                                            if (
                                                actionGridItem?.action ==
                                                Config.ACTION_GRID
                                                    .CREATE_COLLECTION
                                            ) {
                                                dispatch(
                                                    selectActionGridItemAction({
                                                        action: actionGridItem.previousAction,
                                                    })
                                                );
                                            }
                                            handleClosePopup(false);
                                        }
                                    }}
                                >
                                    Cancel
                                </div>
                                <div
                                    className="button save ws"
                                    onClick={async (event) => {
                                        event.stopPropagation();
                                        if (
                                            event.target === event.currentTarget
                                        ) {
                                            await updateCollection(
                                                nameCollection,
                                                descriptionCollection
                                            );
                                            dispatch(
                                                selectGridItemAction({
                                                    worksheets: [],
                                                })
                                            );
                                        }
                                    }}
                                >
                                    Save
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showPopupSurvey && (
                <PopUpStepAction
                    setShowFormPopup={(value: any) => {
                        setShowPopupSurvey(value);
                        setLoading(false);
                    }}
                    descriptionLogin={
                        "Log in to seamlessly save and explore all of the resources and features"
                    }
                    isSaveToCollection={true}
                    saveToCollection={() =>
                        updateCollection(nameCollection, descriptionCollection)
                    }
                />
            )}
        </>
    );
};
export default PopupEditCollectionResource;
