import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { updateCurentInfo } from "../../../../redux/reducers/leftData";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import groupElementStore from "../../../../syncExternalStore/groupElementStore";
import ShapeEditBar from "../../CreateWorksheet/ElementWorksheet/shape/shape-edit-bar/shapeEditBar";

function ChangeTypeShape({
    currentElement,
    resoureItems,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    resoureItems: IResourceItemNew[];
    setCurrentElement: (currentElement: IResourceItemNew) => void;
}) {
    const dispatch = useAppDispatch();
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const currentInfo = useAppSelector(
        (state) => state.leftDataState.currentInfo
    );
    const [show, setShow] = useState({ isShape: false, isLine: false });
    useEffect(() => {
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
            let isLine = false;
            let isShape = false;
            resoureItems.forEach((item) => {
                switch (item.type) {
                    case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
                    case ConstantsTool.TYPE_RESOURCE_NORMAL_LINE:
                        isLine = true;
                        break;
                    case ConstantsTool.TYPE_RESOURCE_SHAPE:
                    case ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE:
                        isShape = true;
                }
            });
            setShow({ isLine, isShape });
        } else {
            const isLine =
                currentElement.type ===
                    ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
                currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE;
            setShow({ isShape: !isLine, isLine });
        }
        return () => {
            if (groupElementState.isChangeLine !== null) {
                groupElementStore.updateIsChangeLine(null);
            }
        };
    }, [currentElement.type]);

    const openTab = (isLine: boolean) => {
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
            if (groupElementState.isChangeLine === isLine) {
                dispatch(updateCurentInfo(null));
                groupElementStore.updateIsChangeLine(null);
            } else {
                dispatch(updateCurentInfo(currentElement));
                groupElementStore.updateIsChangeLine(isLine);
            }
        } else {
            dispatch(
                updateCurentInfo(currentInfo === null ? currentElement : null)
            );
        }
    };

    const makeButtonChangeShape = (isLine: boolean) => {
        return (
            <>
                <div className="button" onClick={() => openTab(isLine)}>
                    {isLine ? (
                        <div className="icon-line" />
                    ) : (
                        <img src={svgPath(IconSVG.iconShape)} />
                    )}
                    <span
                        style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            paddingLeft: "2px",
                        }}
                    >
                        {isLine ? "Lines" : "Shapes"}
                    </span>
                </div>
                <ShapeEditBar
                    currentElement={currentElement}
                    setCurrentElement={setCurrentElement}
                    resourceItems={resoureItems}
                    type={isLine ? "line" : "shape"}
                />
            </>
        );
    };
    return (
        <div
            className="shape-bar-container"
            style={{
                paddingRight: 0,
            }}
        >
            {show.isShape && makeButtonChangeShape(false)}
            {show.isLine && show.isShape && (
                <div
                    className="divider"
                    style={{
                        marginRight: 10,
                    }}
                />
            )}
            {show.isLine && makeButtonChangeShape(true)}
        </div>
    );
}

export default ChangeTypeShape;
