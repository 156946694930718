import { IPosition, IWord } from "../../../../shared/models/crossword";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import {
    cleanWords,
    genDefaultGrid,
    getSizeGrid,
    initGrid,
    random,
    removeItemsWithSameKey,
    shuffleArray,
} from "./utils";

// LOGIC để lấy chữ tiếp theo
const getRelatedWord = (usedWords: IWord[], unusedWords: IWord[]) => {
    const postitions = findAllPossiblePositions(usedWords, "", true);
    const strLetters = postitions.map((position) => position.letter).join("");

    const lettersMap = {};
    unusedWords.forEach((word) => {
        const value = word.value;
        for (let i = 0; i < value.length; i++) {
            const currentLetter = value[i];
            const point = lettersMap[currentLetter];
            if (point) {
                if (!value.slice(0, i).includes(currentLetter)) {
                    lettersMap[currentLetter]++;
                }
            } else {
                lettersMap[currentLetter] = 1;
            }
        }
    });

    let maxRelatedLetter: string;
    for (const letter in lettersMap) {
        // Tìm chữ trùng với chữ trên grid và có nhiêu đoạn giao với các chữ không nằm trên grid
        if (
            (usedWords.length === 0 || strLetters.includes(letter)) &&
            (!maxRelatedLetter ||
                lettersMap[letter] > lettersMap[maxRelatedLetter])
        ) {
            maxRelatedLetter = letter;
        }
    }

    const index = unusedWords.findIndex(({ value }) =>
        value.includes(maxRelatedLetter)
    );

    return index === -1 ? unusedWords?.[0] : unusedWords[index];
};

const getNextWord = (words: IWord[], grid: string[][]) => {
    const usedWords = [];
    const unusedWords = [];

    // Lọc chữ chưa được đặt vào grid
    words.forEach((word) => {
        if (!word.onGrid && !word.place) {
            unusedWords.push(word);
        } else {
            usedWords.push(word);
        }
    });

    if (unusedWords.length === 0) {
        return null;
    }

    return getRelatedWord(usedWords, unusedWords);
};

const isFirstWord = (words: IWord[]) => {
    return words.findIndex((word) => word.onGrid) === -1;
};

const isAllWordsPlaced = (words: IWord[]) => {
    return words.findIndex((word) => !word.onGrid) === -1;
};

const findAllPossiblePositions = (
    words: IWord[],
    value: string,
    findWithOutValue: boolean = false
) => {
    let result = [];
    const currentWordValue = value;
    const wordsOnGrid = words.filter((word) => word.onGrid);

    // Tìm vị trí có thể đặt chữ
    wordsOnGrid.forEach((word) => {
        const { value, position, direction } = word;
        for (let i = 0; i < value.length; i++) {
            const letter = value[i];
            if (currentWordValue.includes(letter) || findWithOutValue) {
                let newRow = position.row;
                let newColumn = position.column;
                if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                    newColumn += i;
                } else {
                    newRow += i;
                }
                result.push({
                    direction:
                        direction !== ConstantsTool.DIRECTIONS.ACROSS
                            ? ConstantsTool.DIRECTIONS.ACROSS
                            : ConstantsTool.DIRECTIONS.DOWN,
                    letter,
                    row: newRow,
                    column: newColumn,
                });
            }
        }
    });

    // Lọc vị trí lặp vì xuất hiện 2 vị trí
    result = removeItemsWithSameKey(result);
    shuffleArray(result);
    return result;
};

const canPlaceWord = (
    word: IWord,
    position: IPosition,
    words: IWord[],
    grid: string[][]
) => {
    const currentLetter = grid[position.row][position.column];
    if (!currentLetter) {
        return undefined;
    }

    const indexLetterInWord = word.value.indexOf(currentLetter);
    if (indexLetterInWord === -1) {
        return undefined;
    }

    // lọc các chữ trên grid và có điểm chung trong vị trí
    const relatedPositionWords = words.filter((word) => {
        if (!word.onGrid) return false;

        const { row, column } = word.position;
        return row === position.row || column === position.column;
    });

    // lấy hướng đặt chữ để không trùng với hướng chữ tại vị trí đó
    let possibleDirections = {
        [ConstantsTool.DIRECTIONS.ACROSS]: 1,
        [ConstantsTool.DIRECTIONS.DOWN]: 1,
    };
    relatedPositionWords.forEach((word) => {
        const { value, position: positionCurrentWord, direction } = word;

        for (let i = 0; i < value.length; i++) {
            let newRow = positionCurrentWord.row;
            let newColumn = positionCurrentWord.column;
            if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                newColumn += i;
            } else {
                newRow += i;
            }

            if (newRow === position.row && newColumn === position.column) {
                possibleDirections[direction] = -1;
            }
        }
    });

    let direction = -1;
    for (const key in possibleDirections) {
        if (possibleDirections[key] === 1) {
            direction = parseInt(key);
        }
    }

    if (direction === -1) {
        return undefined;
    }

    // Lấy vị trí đặt chữ
    const placePosition = {
        row: -1,
        column: -1,
    };
    const wordLength = word.value.length;
    const rows = grid.length;
    const columns = grid[0].length;

    // kiểm tra chữ vượt quá giới hạn grid
    const textLengthLeft = wordLength - indexLetterInWord;
    if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
        if (position.column + textLengthLeft - 1 >= columns) {
            return undefined;
        }
    } else {
        if (position.row + textLengthLeft - 1 >= rows) {
            return undefined;
        }
    }

    for (let i = 0; i < wordLength; i++) {
        let newRow = position.row;
        let newColumn = position.column;
        if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
            newColumn += i - indexLetterInWord;
        } else {
            newRow += i - indexLetterInWord;
        }

        const currentLetterInGrid = grid?.[newRow]?.[newColumn];
        const currentLetterInWord = word.value[i];
        if (
            currentLetterInGrid !== currentLetterInWord &&
            currentLetterInGrid !== ""
        ) {
            return undefined;
        }

        if (i === 0) {
            placePosition.row = newRow;
            placePosition.column = newColumn;
        }
    }

    return {
        position: placePosition,
        direction,
    };
};

const placeWord = (
    word: IWord,
    grid: string[][],
    position: IPosition,
    direction: number
) => {
    const { row, column } = position;
    word.onGrid = true;
    word.direction = direction;
    word.position = position;
    word.place = true;

    for (let i = 0; i < word.value.length; i++) {
        let newRow = row;
        let newColumn = column;
        if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
            newColumn += i;
        } else {
            newRow += i;
        }
        try {
            grid[newRow][newColumn] = word.value[i];
        } catch (error) {
            // console.table(grid);
            console.log("error", error);
            // console.log("newRow", newRow);
            // console.log("newColumn", newColumn);
        }
    }
};

const placeWords = (
    words: IWord[],
    grid: string[][],
    bestRecord: {
        words: IWord[];
        grid: string[][];
    }
) => {
    const firstWord = isFirstWord(words);
    const nextWord = getNextWord(words, grid);
    if (!nextWord) {
        return isAllWordsPlaced(words);
    }

    const possiblePositions = findAllPossiblePositions(words, nextWord.value);

    if (firstWord) {
        const wordLength = nextWord.value.length;
        const gridRow = grid.length;
        const gridColumn = grid[0].length;

        const midRow = Math.floor(gridRow * 0.5);
        const midColumn = Math.floor(gridColumn * 0.5);
        const maxRow = gridRow - wordLength;
        const maxColumn = gridColumn - wordLength;
        const position = {
            row: maxRow < midRow ? maxRow : midRow,
            column: maxColumn < midColumn ? maxColumn : midColumn,
        };
        possiblePositions.push(position);
    }

    for (let i = 0; i < possiblePositions.length; i++) {
        const { row, column } = possiblePositions[i];
        let result: { position: IPosition; direction: number } | undefined;

        if (firstWord) {
            result = {
                position: {
                    row,
                    column,
                },
                direction: random(0, 1),
            };
        } else {
            result = canPlaceWord(nextWord, { row, column }, words, grid);
        }

        if (result) {
            const { direction, position } = result;
            placeWord(nextWord, grid, position, direction);

            // Lấy bảng grid đặt được nhiều chữ vào ô grid nhất
            getBestRecord(bestRecord, {
                words,
                grid,
            });

            if (
                isAllWordsPlaced(words) ||
                placeWords(words, grid, bestRecord)
            ) {
                // Nếu tất cả chữ được đặt vào grid hoặc hàm placeWords trả về thành công
                return true;
            }

            removeWordFromPosition(nextWord, words, grid);
        }
    }
    return false;
};

const getBestRecord = (
    currentRecord: { words: IWord[]; grid: string[][] },
    newRecord: { words: IWord[]; grid: string[][] }
) => {
    if (
        newRecord.words.filter((w) => w.onGrid).length >
        currentRecord.words.filter((w) => w.onGrid).length
    ) {
        const parseRecord = JSON.parse(JSON.stringify(newRecord));
        currentRecord.words = parseRecord.words;
        currentRecord.grid = parseRecord.grid;
        return true;
    }

    return false;
};

// Xoá từ khỏi ô và điền lại chữ vào chỗ trống
const removeWordFromPosition = (
    word: IWord,
    words: IWord[],
    grid: string[][]
) => {
    for (let i = 0; i < word.value.length; i++) {
        let newRow = word.position.row;
        let newColumn = word.position.column;
        if (word.direction === ConstantsTool.DIRECTIONS.ACROSS) {
            newColumn += i;
        } else {
            newRow += i;
        }
        grid[newRow][newColumn] = "";
    }
    word.onGrid = false;
    delete word.direction;
    delete word.position;

    fillLettersToGrid(words, grid);
};

const fillLettersToGrid = (words: IWord[], grid: string[][]) => {
    const wordsPlaced = words.filter((word) => word.onGrid);
    wordsPlaced.forEach((word) => {
        const { value, direction, position } = word;
        for (let i = 0; i < value.length; i++) {
            let newRow = position.row;
            let newColumn = position.column;
            if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                newColumn += i;
            } else {
                newRow += i;
            }

            grid[newRow][newColumn] = value[i];
        }
    });
};

// CROP SIZE OF GRID
const cropGridSize = (words: IWord[], grid: string[][]) => {
    let minRow = grid.length;
    let minColumn = grid.length;
    let maxRow = 0;
    let maxColumn = 0;
    for (let row = 0; row < grid.length; row++) {
        for (let column = 0; column < grid[0].length; column++) {
            const currentCell = grid[row][column];
            if (currentCell !== "") {
                if (row < minRow) {
                    minRow = row;
                }
                if (row > maxRow) {
                    maxRow = row;
                }
                if (column < minColumn) {
                    minColumn = column;
                }
                if (column > maxColumn) {
                    maxColumn = column;
                }
            }
        }
    }
    const cropGrid = grid
        .slice(minRow, maxRow + 1)
        .map((row) => row.slice(minColumn, maxColumn + 1));
    const cropWords = words.map((word) => {
        if (word.position) {
            const { row, column } = word.position;
            return {
                ...word,
                position: {
                    row: row - minRow,
                    column: column - minColumn,
                },
            };
        }

        return word;
    });
    return {
        grid: cropGrid,
        words: cropWords,
    };
};

const WORDS_DEBUG = [
    // { value: "Apple", clue: "Fruit with a core" },
    // { value: "Banana", clue: "Yellow fruit" },
    // { value: "Cherry", clue: "Small red fruit" },
    // { value: "Date", clue: "Sweet dried fruit" },
    // { value: "Giraffe", clue: "Tall African mammal" },
    // { value: "Elephant", clue: "Large gray mammal" },
    // { value: "Sunflower", clue: "Yellow flowering plant" },
    // { value: "Mountain", clue: "Tall landform" },
    // { value: "Ocean", clue: "Vast body of water" },
    // { value: "Violin", clue: "Musical instrument" },
    // { value: "Eiffel Tower", clue: "Paris landmark" },
    // { value: "Laptop", clue: "Portable computer" },
    // { value: "Coffee", clue: "Morning pick-me-up" },
    // { value: "Library", clue: "Place for books" },
    // { value: "Doctor", clue: "Medical professional" },
    // { value: "Telescope", clue: "Astronomical instrument" },
    // { value: "Elephant", clue: "Large gray mammal" },
    // { value: "Chocolate", clue: "Sweet treat" },
    // { value: "Diamond", clue: "Precious gem" },
    // { value: "Butterfly", clue: "Colorful insect" },
    // { value: "Soccer", clue: "Popular sport" },
    // { value: "Keyboard", clue: "Typing input device" },
    // { value: "Universe", clue: "Cosmic space" },
    // { value: "Sushi", clue: "Japanese cuisine" },
    // { value: "Hiking", clue: "Outdoor activity" },
    // { value: "Volcano", clue: "Erupting mountain" },
    // { value: "Piano", clue: "Musical instrument" },
    // { value: "Sparrow", clue: "Small bird" },
    // { value: "Cactus", clue: "Desert plant" },
    // { value: "Rainbow", clue: "Colorful meteorological phenomenon" },
    // { value: "Island", clue: "Land surrounded by water" },
    // { value: "Eagle", clue: "Majestic bird of prey" },
    // { value: "Television", clue: "Electronic entertainment" },
    // { value: "Candle", clue: "Wax illumination" },
    // { value: "Guitar", clue: "Stringed musical instrument" },
    // { value: "Moonlight", clue: "Nighttime illumination" },
    // { value: "Orchestra", clue: "Musical ensemble" },
    // { value: "Robot", clue: "Automated machine" },
];

export const generateCrossword = (
    words: IWord[] = WORDS_DEBUG
): { words: IWord[]; grid: string[][] } => {
    const placedWords: IWord[] = JSON.parse(JSON.stringify(words));
    const { numRows, numColumns } = getSizeGrid(placedWords);
    const grid = initGrid(numRows, numColumns);
    shuffleArray(placedWords);

    // lưu trữ grid điền được nhiều từ vào nhất
    const bestRecord = {
        words,
        grid,
    };

    while (true) {
        placeWords(placedWords, grid, bestRecord);

        const placeWordsParse: IWord[] = JSON.parse(
            JSON.stringify(placedWords)
        );
        const isPlaceAll = placeWordsParse.findIndex((w) => !w.place) === -1;
        if (isPlaceAll) {
            break;
        }
    }

    // Cắt các khoảng trống trong ma trận
    const result = cropGridSize(bestRecord.words, bestRecord.grid);
    return {
        words: cleanWords(result.words),
        grid: result.words.length > 0 ? result.grid : genDefaultGrid(),
    };
};
