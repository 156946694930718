import { Button, Form, Modal, Spinner, Container } from 'react-bootstrap';
import { useAppSelector } from '../../../../../redux/hook';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';
import { useEffect, useState } from 'react';

import './styles.scss';
import { convertImagesToBase64 } from '../../../../../resource-user/utils/convertImg';
import { updateDataAPI } from '../../../../../utils/query';
import ImageGrid from './image-grid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TabCreatePixelTemplate = () => {
    const [colors, setColors] = useState({});
    const [cells, setCells] = useState(null);
    const [nbRows, setNbRows] = useState(null);
    const [nbColumns, setNbColumns] = useState(null);
    const [loading, setLoading] = useState(false);
    const [similarColorDistance, setSimilarColorDistance] = useState(30);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [demoImageUrl, setDemoImageUrl] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [pixelArtData, setPixelArtData] = useState(null);

    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);
    const { template } = useAppSelector(state => state.createWorksheetState);

    useEffect(() => {
        if (template.pixelArtData) {
            const pixelArtData = template.pixelArtData;
            setPixelArtData(pixelArtData);
            if (pixelArtData['similarColorDistance']) {
                setSimilarColorDistance(pixelArtData['similarColorDistance']);
            }
            if (pixelArtData['colors']) {
                setColors(pixelArtData['colors']);
            }
            if (pixelArtData['cells']) {
                setCells(pixelArtData['cells']);
            }
            if (pixelArtData['backgroundColor']) {
                setBackgroundColor(pixelArtData['backgroundColor']);
            }
            if (pixelArtData['nbRows']) {
                setNbRows(pixelArtData['nbRows']);
            }
            if (pixelArtData['nbColumns']) {
                setNbColumns(pixelArtData['nbColumns']);
            }
        }
    }, [template]);

    const fetchPixelColors = async () => {
        setLoading(true);
        const table = resourceItems.find(item => item.type === ConstantsTool.TYPE_RESOURCE_TABLE);
        const photo = resourceItems.find(item => item.type === ConstantsTool.RESOURCE_IMAGE);
        if (table && photo) {
            try {
                setNbRows(table.tableAttribute.data.length);
                setNbColumns(table.tableAttribute.data[0].length);
                const cells = document.querySelectorAll('.table-element-view .cell-element');
                const tableCells = [];
                for (let i = 0; i < cells.length; i++) {
                    const cell = cells[i];
                    const pos = cell['style'].transform.replace('translate(', '').replace(')', '').replace(' ', '').replace('px', '').replace('px', '').split(',');
                    tableCells.push({
                        x: parseFloat(pos[0]),
                        y: parseFloat(pos[1]),
                        w: parseFloat(cell['style'].width.replace('px', '')),
                        h: parseFloat(cell['style'].height.replace('px', '')),
                    });
                }
                const imgData = await convertImagesToBase64('content-layer');
                const scale = ConstantsTool.EXPORT_WIDTH_A4 / ConstantsTool.INIT_WIDTH_A4;
                const width = parseFloat(document.querySelector('.resource-table-element').parentElement['style'].width.replace('px', ''));
                const height = parseFloat(document.querySelector('.resource-table-element').parentElement['style'].height.replace('px', ''));
                const res = await updateDataAPI('POST', 'auto-content/fetch-colors-from-pixel-image/', {
                    imageWidth: width,
                    imageHeight: height,
                    tableCells,
                    imgData: imgData[0],
                    tableX: table.x * scale,
                    tableY: table.y * scale,
                    tableWidth: table.width * scale,
                    tableHeight: table.height * scale,
                    similarColorDistance,
                    templateID: template.id,
                });
                const colors = {};
                for (let i = 0; i < res.data['colors'].length; i++) {
                    colors[res.data['colors'][i]] = '';
                }
                setColors(colors);
                setCells(res.data['cells']);
            } catch (err) {
                setLoading(false);
                window.alert('error');
            }
        } else {
            alert('Please add a table and an image to the page');
        }
        setLoading(false);
    }

    const createPixelDemoImage = async (applyImage = false) => {
        setDemoImageUrl(null);
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'auto-content/create-pixel-demo-image/', {
                nbRows,
                nbColumns,
                cells: cells.map(cell => {
                    if (colors[cell.color] !== undefined && colors[cell.color] !== '') {
                        return { ...cell, color: colors[cell.color] };
                    }
                    return cell;
                }),
                colors,
                applyImage,
                templateID: template.id,
                similarColorDistance,
                backgroundColor,
            });
            if (!applyImage) {
                setDemoImageUrl(process.env.REACT_APP_API_URL + res.data['imgPath']);
            } else {
                window.location.reload();
            }

        } catch (err) {
            console.log(err);
            window.alert('error');
        }
        setLoading(false);
    }

    return (
        <div className="tab-create-pixel-template">
            {pixelArtData && (
                <div className="mb-3 info">
                    <div>{nbColumns} columns</div>
                    <div>{nbRows} rows</div>
                </div>
            )}
            <h2>
                <div>Colors</div>
                <div>Replace</div>
            </h2>
            <div className="pixel-template-colors">
                {Object.keys(colors).map(color => (
                    <div key={color}>
                        <div style={{opacity: colors[color] !== '' ? 0.2 : 1}}>
                            <span className="color-block" style={{backgroundColor: color}}></span>
                            <span className="color-code">{color}</span>
                        </div>
                        <div>
                            <span className="color-block" style={{backgroundColor: colors[color]}}></span>
                            <Form.Control type="text" value={colors[color]} onChange={e => setColors({ ...colors, [color]: e.target.value })} />
                        </div>
                    </div>
                ))}
            </div>
            <div><strong>Total: {Object.values(colors).filter(val => val === '').length} colors</strong></div>
            <div className="mt-3">
                <Form>
                    <Form.Group>
                        <Form.Label>Similar Color Distance</Form.Label>
                        <Form.Control value={similarColorDistance} onChange={e => setSimilarColorDistance(parseInt(e.target.value))} type="number" />
                        <Form.Text>big = less colors, small = more colors</Form.Text>
                    </Form.Group>
                    <div className="background-color">
                        <span className="color-block" style={{backgroundColor: backgroundColor}}></span>
                        <Form.Group>
                            <Form.Label>Background Color</Form.Label>
                            <Form.Control value={backgroundColor} onChange={e => setBackgroundColor(e.target.value)} />
                        </Form.Group>
                    </div>
                </Form>
            </div>
            <div className="btns">
                <Button size="sm" variant="info" onClick={fetchPixelColors}>Get Colors</Button>
                {cells !== null && (
                    <Button size="sm" onClick={() => createPixelDemoImage(false)}>View Demo</Button>
                )}
            </div>

            {demoImageUrl && (
                <div className="demo-image">
                    <img src={demoImageUrl} alt="demo" className="demo-img" onClick={() => setShowImageModal(true)} />
                    <Button size="sm" onClick={() => createPixelDemoImage(true)}>Apply Image</Button>
                </div>
            )}

            {showImageModal && demoImageUrl && (
                <Modal show={true} onHide={() => setShowImageModal(false)} fullscreen={true} className="pixel-image-modal">
                    <Modal.Header closeButton>
                        Customize color cells
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="d-flex justify-content-center align-items-center">
                            <Row>
                                <Col>
                                    <h5>Before</h5>
                                    <img src={demoImageUrl} />

                                    <div className="pixel-template-colors mt-3">
                                        {Object.keys(colors).map(color => (
                                            <div key={color}>
                                                <div style={{opacity: colors[color] !== '' ? 0.2 : 1}}>
                                                    <span className="color-block" style={{backgroundColor: color}}></span>
                                                    <span className="color-code">{color}</span>
                                                </div>
                                                <div>
                                                    <span className="color-block" style={{backgroundColor: colors[color]}}></span>
                                                    <Form.Control type="text" value={colors[color]} onChange={e => setColors({ ...colors, [color]: e.target.value })} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                                <Col>
                                    <h5>After</h5>
                                    <ImageGrid cells={cells} setCells={setCells} />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    );
}

export default TabCreatePixelTemplate;