import Config from "../utils/config";
import { SizeActivity } from "./WorkSheetCreator";

export interface IPuzzle {
    id: string;
    title: string;
    description: string;
    creator: string;
    studentInfos: string[];
    puzzleShape: string;
    puzzleSize: SizeActivity;
    wordDirections: number;
    showAnswerKey: number;
    showWordDirection: number;
    showWordList: number;
    puzzleCellPx: number;

    puzzleWidth: number;
    puzzleHeight: number;
    puzzleString: string;
    puzzleFont: string;
    fontSize: string;
    letterSpacing: string;

    puzzleArrChar: string[][];
    puzzleArrNumber: number[][];

    exsistDirections: string[];
}
export class Puzzle implements IPuzzle {
    id: string;
    title: string;
    description: string;
    creator: string;
    studentInfos: string[];
    puzzleShape: string;
    puzzleSize: SizeActivity;
    wordDirections: number;
    showAnswerKey: number;
    showWordDirection: number;
    showWordList: number;

    puzzleWidth: number;
    puzzleHeight: number;
    puzzleString: string;
    puzzleFont: string;
    fontSize: string;
    letterSpacing: string;
    puzzleCellPx: number;
    puzzleArrChar: string[][];
    puzzleArrNumber: number[][];
    exsistDirections: string[];

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.creator = object.creator ?? "";
        this.title = object.title ?? "";
        this.description = object.description ?? "";
        this.studentInfos =
            object.studentInfos ?? Config.DEFAULT_ROW_STUDENT_INFO;
        this.puzzleShape = object.puzzleShape ?? Config.DEFAULT_SHAPE_NAME;
        this.puzzleSize = object.puzzleSize ?? "Small";
        this.wordDirections = object.wordDirections ?? 0;
        this.showAnswerKey = object.showAnswerKey ?? Config.SHOW_VALUE;
        this.showWordDirection = object.showWordDirection ?? Config.SHOW_VALUE;
        this.showWordList = object.showWordList ?? Config.SHOW_VALUE;

        this.puzzleString = object.puzzleString ?? "";
        this.puzzleWidth = object.puzzleWidth ?? 0;
        this.puzzleHeight = object.puzzleHeight ?? 0;
        this.puzzleFont = object.puzzleFont ?? "";
        this.fontSize = object.fontSize ?? "";
        this.letterSpacing = object.letterSpacing ?? "";
        this.puzzleArrChar = object.puzzleArrChar ?? [];
        this.puzzleArrNumber = object.puzzleArrNumber ?? [];
        if (object.puzzleCellPx) {
            this.puzzleCellPx = object.puzzleCellPx;
        }
        this.exsistDirections = object.exsistDirections ?? [];
    }
}

export interface IUserAnswerWordsearch {
    id: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    startCellX: number;
    startCellY: number;
    endCellX: number;
    endCellY: number;
    color: string;
}

export class UserAnaswerWordsearch implements IUserAnswerWordsearch {
    id: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    startCellX: number;
    startCellY: number;
    endCellX: number;
    endCellY: number;
    color: string;

    constructor(object: any = {}) {
        this.id = object.id ?? "";
        this.x1 = object.x1 ?? -1;
        this.y1 = object.y1 ?? -1;
        this.startCellX = object.startCellX ?? -1;
        this.startCellY = object.startCellY ?? -1;
        this.x2 = object.x2 ?? -1;
        this.y2 = object.y2 ?? -1;
        this.endCellX = object.endCellX ?? -1;
        this.endCellY = object.endCellY ?? -1;
        this.color = object.color ?? "";
    }
}

export interface IInputWord {
    word: string;
    onGrid: number;
    wordListText: string;
    direction: string;
    startCell: number[];
    status: number;

    userAnswer?: IUserAnswerWordsearch;
}

export class InputWord implements IInputWord {
    word: string;
    onGrid: number;
    wordListText: string;
    direction: string;
    startCell: number[];
    status: number;

    userAnswer?: IUserAnswerWordsearch;
    constructor(object: any = {}) {
        this.word = object.word ?? "";
        this.onGrid = object.onGrid ?? 0;
        this.wordListText = object.wordListText ?? "";
        this.direction = object.direction ?? "";
        this.startCell = object.startCell ?? [];
        this.status = object.status ?? Config.STATUS_PUZZLE.INIT;

        if (this.status === Config.STATUS_PUZZLE.FOUND) {
            this.userAnswer = new UserAnaswerWordsearch(
                object.userAnswer ?? {}
            );
        }
    }
}
