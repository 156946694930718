import { useSelector } from "react-redux";
import { IWorksheet } from "../../../../../../shared/models/worksheet";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
// import TickedWSIcon from "../../../../icons/tick-ws-icon";
import DynamicComponent from "../../../../common/dynamic-component";
import UnTickWSIcon from "../../../../icons/untick-icon/untick-ws-icon";

const TickSelect = ({
    setIsHoverTickIcon,
    handleAddWS,
    worksheet,
}: {
    setIsHoverTickIcon?: Function;
    handleAddWS?: Function;
    worksheet: IWorksheet;
}) => {
    let listWorksheet = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    let find = listWorksheet?.find((ws) => ws?.id == worksheet?.id);

    return (
        <div
            className="tick-icon"
            onMouseEnter={() => {
                setIsHoverTickIcon(true);
            }}
            onMouseOut={() => setIsHoverTickIcon(false)}
            onClick={(e) => {
                e.stopPropagation();
                handleAddWS();
            }}
        >
            <>
                {find?.id ? (
                    // <TickedWSIcon />
                    <DynamicComponent
                        component={import("../../../../icons/tick-ws-icon")}
                        path="../../../../icons/tick-ws-icon"
                    />
                ) : (
                    <>
                        <UnTickWSIcon />
                    </>
                )}
            </>
        </div>
    );
};

export default TickSelect;
