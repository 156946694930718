import ClassroomConfig from "../utils/classroom";
import { IUserInfo } from "./userInfo";
import { IWorksheet } from "./worksheet";

export interface IAnswerAssignment {
    text: string;
    isCorrect: boolean;
}
export interface IAssignment {
    id: string;
    timeEnd: string;
    worksheetId: string;
    classId: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
    title?: string;
    description?: string;
    question: string;
    type: number;
    answers: IAnswerAssignment[];
    worksheetModel?: IWorksheet;
    userInfo?: IUserInfo;
    totalStudents?: number;
    studentSubmitted?: number;
    status?: number;
    worksheet: string;
}
export class Assignment implements IAssignment {
    id: string;
    timeEnd: string;
    worksheetId: string;
    classId: string;
    createdAt: string;
    updatedAt: string;
    title?: string;
    userId: string;
    description?: string;
    question: string;
    answers: IAnswerAssignment[];
    type: number;
    worksheetModel?: IWorksheet;
    userInfo?: IUserInfo;
    totalStudents?: number;
    studentSubmitted?: number;
    status?: number;
    worksheet: string;

    constructor(object: any = {}) {
        this.id = object?.id ?? undefined;
        this.timeEnd = object?.timeEnd ?? "";
        this.worksheetId = object?.worksheetId ?? "";
        this.classId = object?.classId ?? "";
        this.createdAt = object?.createdAt ?? "";
        this.updatedAt = object?.updatedAt ?? "";
        this.title = object?.title ?? "";
        this.description = object.description ?? "";
        this.userId = object.userId ?? "";
        this.question = object.question ?? "";
        this.answers = object.answers ?? [];
        this.worksheetModel = object.worksheetModel ?? null;
        this.type = object.type ?? 1;
        this.userInfo = object.userInfo ?? null;
        this.totalStudents = object.totalStudents ?? 0;
        this.studentSubmitted = object.studentSubmitted ?? 0;
        this.status = object.status ?? ClassroomConfig.ASSIGNMENT_ACTIVE;
        this.worksheet = object?.worksheet ?? "";
    }
}
