import React from "react";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";

function MakeStrokeDasharrayView({
    onClick,
    value,
    isNone,
    onClickNone,
    isDisplayNone,
}: {
    value: number;
    onClick: (value: number) => void;
    onClickNone: () => void;
    isNone: boolean;
    isDisplayNone: boolean;
}) {
    const makeStrokeDasharray = (
        width1: string,
        width2: string,
        width3: string,
        width4: string,
        width: number
    ) => {
        const makeDiv = (w: string) => {
            return w === "0%" ? (
                ""
            ) : (
                <div style={{ width: w, height: 2, background: "#212121" }} />
            );
        };
        return (
            <div
                className="box-border"
                onClick={() => onClick(width)}
                style={{
                    borderColor:
                        value === width && !isNone ? "black" : "#e2e2e2",
                }}
            >
                {makeDiv(width1)}
                {makeDiv(width2)}
                {makeDiv(width3)}
                {makeDiv(width4)}
            </div>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: 16,
            }}
        >
            {isDisplayNone && (
                <div
                    className="box-border"
                    style={{
                        borderColor: isNone ? "black" : "#e2e2e2",
                    }}
                    onClick={() => onClickNone()}
                >
                    <img src={svgPath(IconSVG.omegaCircle)} />
                </div>
            )}
            {makeStrokeDasharray("80%", "0%", "0%", "0%", 0)}
            {makeStrokeDasharray("40%", "40%", "0%", "0%", 3)}
            {makeStrokeDasharray("25%", "25%", "25%", "0%", 2)}
            {makeStrokeDasharray("15%", "15%", "15%", "15%", 1)}
        </div>
    );
}

export default MakeStrokeDasharrayView;
