export const ComingSoonIconSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
        >
            <path
                d="M18.7881 0C23.9385 0.146873 28.8061 2.4793 32.4319 7.22088C33.9368 9.18942 34.9112 11.4418 35.4954 13.8554C35.981 15.8612 36.1311 17.8955 35.8801 19.9407C35.7541 20.9667 35.4713 21.9739 35.2674 22.9911C35.2258 23.1961 35.1337 23.2015 34.9583 23.15C33.5082 22.7193 32.0548 22.3006 30.6048 21.8698C29.9734 21.6824 29.991 21.6769 30.1565 21.0511C31.3753 16.4278 30.4623 12.2551 27.4426 8.56137C25.508 6.19387 22.9871 4.73172 20.0518 3.94036C18.1315 3.42302 16.1959 3.40548 14.2492 3.72115C10.196 4.37879 7.0163 6.43829 4.60277 9.73526C3.63056 11.0637 3.0058 12.5576 2.51038 14.1042C1.76943 16.4191 1.62037 18.7855 2.07524 21.1804C2.13114 21.4752 2.21444 21.7657 2.29883 22.054C2.35473 22.2425 2.31418 22.34 2.11141 22.385C1.72011 22.4737 1.3321 22.5746 0.946288 22.6842C0.782974 22.7302 0.718307 22.6699 0.660215 22.5285C0.356605 21.7975 0.193291 21.0313 0.112182 20.2487C-0.0883985 18.3131 -0.0281148 16.384 0.358797 14.4736C0.81476 12.2244 1.66202 10.1298 2.9225 8.21063C4.85925 5.26221 7.42733 3.04706 10.6432 1.58491C12.9811 0.527208 15.4341 0.016441 18.7881 0Z"
                fill="#212121"
            />
            <path
                d="M15.7054 19.7348C16.2447 19.2076 16.7204 18.744 17.1939 18.2792C19.0495 16.4576 20.9051 14.6359 22.7586 12.8121C23.2014 12.3769 23.7242 12.1818 24.3314 12.3265C25.5283 12.6115 25.9427 14.0649 25.0702 14.9373C24.1605 15.8471 23.2211 16.7261 22.3015 17.626C20.5873 19.3052 18.8785 20.9898 17.1665 22.6712C17.0623 22.7731 16.9593 22.8772 16.8497 22.9737C16.1394 23.6017 15.2111 23.5864 14.5359 22.9178C13.3171 21.711 12.1004 20.501 10.9035 19.2734C10.6898 19.0542 10.5221 18.7538 10.4267 18.4612C10.1297 17.5471 10.8257 16.599 11.7793 16.5442C12.3679 16.5113 12.8447 16.7075 13.2502 17.1284C14.0032 17.911 14.7672 18.6826 15.5235 19.4619C15.5936 19.5331 15.6364 19.6285 15.7054 19.7348Z"
                fill="#212121"
            />
            <path
                d="M29.212 31.3589C27.7542 31.3425 26.6045 30.1631 26.6253 28.7032C26.6461 27.2695 27.8364 26.1219 29.2821 26.1439C30.7257 26.1658 31.8809 27.3506 31.8568 28.7876C31.8338 30.2311 30.6577 31.3743 29.212 31.3589Z"
                fill="#212121"
            />
            <path
                d="M21.9807 31.1807C23.2149 31.1796 24.2298 32.1923 24.2331 33.4243C24.2364 34.664 23.2039 35.691 21.9632 35.6822C20.729 35.6734 19.7272 34.6585 19.7305 33.421C19.7338 32.1825 20.7389 31.1807 21.9807 31.1807Z"
                fill="#212121"
            />
            <path
                d="M13.3173 35.698C12.2443 35.6991 11.363 34.8189 11.3652 33.7492C11.3674 32.6717 12.2421 31.808 13.3239 31.8135C14.397 31.819 15.2574 32.6761 15.2618 33.7415C15.2661 34.8145 14.3893 35.6969 13.3173 35.698Z"
                fill="#212121"
            />
            <path
                d="M5.01368 30.128C5.01587 29.4561 5.57048 28.9081 6.23689 28.9201C6.90001 28.9311 7.4316 29.4692 7.43379 30.1302C7.43598 30.8032 6.89124 31.3501 6.22045 31.3501C5.54966 31.3501 5.01149 30.8054 5.01368 30.128Z"
                fill="#212121"
            />
        </svg>
    );
};
