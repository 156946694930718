const DeleteIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0067 2.5H5.9933C4.064 2.5 2.5 4.064 2.5 5.9933V10.0067C2.5 11.936 4.064 13.5 5.9933 13.5H10.0067C11.936 13.5 13.5 11.936 13.5 10.0067V5.9933C13.5 4.064 11.936 2.5 10.0067 2.5Z"
                fill="#212121"
                stroke="#F8F9FB"
            />
            <path
                d="M8.91721 8.17443C8.82951 8.08812 8.82789 7.94662 8.91371 7.85841L10.3264 6.40576C10.5182 6.21284 10.5809 5.87518 10.3598 5.65278C10.1386 5.43037 9.7921 5.46663 9.60028 5.65954L8.14375 7.10625C8.05739 7.19201 7.91883 7.19228 7.83221 7.10706L6.39289 5.69093C6.20107 5.49802 5.86721 5.43335 5.64875 5.65305C5.4303 5.87275 5.46823 6.21934 5.66005 6.41225L7.07542 7.85327C7.16178 7.94121 7.16071 8.08271 7.07354 8.16929L5.70875 9.52509C5.51693 9.718 5.46824 10.1276 5.68938 10.35C5.91052 10.5724 6.31757 10.5237 6.50965 10.3306L7.84378 8.9588C7.93067 8.86951 8.07353 8.86924 8.1607 8.95798L9.50397 10.3254C9.69578 10.5183 10.112 10.5806 10.3304 10.3609C10.5489 10.1412 10.487 9.72287 10.2952 9.52969L8.91748 8.17416L8.91721 8.17443Z"
                fill="white"
            />
        </svg>
    );
};

export default DeleteIcon;
