import { IUserNotification } from "../../../shared/models/userNotification";
import Notification from "../../../shared/utils/notification";
import { NotificationTypes } from "../action/notification.action";

export interface INotificationResourceState {
    notificationInfos: IUserNotification[];
    currentNotificationDetail: IUserNotification;
    nbUnreadMessages: number;
    nbNotifications: number;
    currentNotification: string;
    loading: boolean;
}

const initialState: INotificationResourceState = {
    notificationInfos: [],
    currentNotificationDetail: null,
    nbUnreadMessages: 0,
    nbNotifications: 0,
    currentNotification: "",
    loading: false,
};

function notificationResourceState(
    state: INotificationResourceState = initialState,
    action: any
): INotificationResourceState {
    switch (action.type) {
        case NotificationTypes.GET_NUMBER_OF_NOTIFICATIONS: {
            return state;
        }
        case NotificationTypes.GET_NUMBER_OF_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                nbUnreadMessages: action?.nbUnreadMessages,
                nbNotifications: action?.nbNotifications,
            };
        }
        case NotificationTypes.GET_NOTIFICATION_BY_USER: {
            return { ...state, loading: true };
        }
        case NotificationTypes.GET_NOTIFICATION_BY_USER_SUCCESS: {
            const areObjectsEqual = (obj1, obj2) => {
                return obj1.id === obj2.id;
            };
            const isEqual = action?.notificationInfos.filter((obj1) =>
                state.notificationInfos.some((obj2) =>
                    areObjectsEqual(obj1, obj2)
                )
            );

            if (isEqual.length === action?.notificationInfos?.length) {
                return state;
            }

            return {
                ...state,
                notificationInfos: [
                    ...state.notificationInfos,
                    ...action?.notificationInfos,
                ],
                nbUnreadMessages:
                    state.nbUnreadMessages + action?.nbUnreadMessages,
                nbNotifications:
                    state.nbNotifications + action?.nbNotifications,
                loading: false,
            };
        }
        case NotificationTypes.SET_ALL_NOTIFICATION_READ:
            let afterReadNotiInfos = state.notificationInfos.map((item) => {
                return item.status == Notification.STATUS_NOT_READ
                    ? { ...item, status: Notification.STATUS_NOT_READ_DETAIL }
                    : item;
            });
            return {
                ...state,
                notificationInfos: afterReadNotiInfos,
                nbUnreadMessages: 0,
            };
        case NotificationTypes.SET_NOTIFICATION_READ_DETAIL:
            let afterReadDetailNotiList = state.notificationInfos.map(
                (item) => {
                    return item.id == action?.id
                        ? { ...item, status: Notification.STATUS_READ_DETAIL }
                        : item;
                }
            );
            return {
                ...state,
                notificationInfos: afterReadDetailNotiList,
                currentNotification: action?.id,
            };
        case NotificationTypes.CREATE_SUBMITED_NOTIFICATION: {
            return {
                ...state,
                loading: true,
            };
        }
        case NotificationTypes.CREATE_SUBMITED_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case NotificationTypes.GET_NOTIFICATION_DETAIL: {
            return {
                ...state,
                loading: true,
            };
        }
        case NotificationTypes.GET_NOTIFICATION_DETAIL_SUCCESS: {
            return {
                ...state,
                currentNotificationDetail: action.notificationData,
                loading: false,
            };
        }
        default:
            return state;
    }
}

export default notificationResourceState;
