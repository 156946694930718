import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialStateType = {
    message: string;
    severity: AlertColor;
};
const initialState: initialStateType = {
    message: "",
    severity: "success",
};

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<initialStateType>) => {
            state.message = action.payload.message ?? "";
            state.severity = action.payload.severity ?? "success";
        },
        removeMessage: (state, action: PayloadAction) => {
            state.message = "";
        },
    },
});

export const { setMessage, removeMessage } = snackbarSlice.actions;
