import { useState } from "react";
import "./index.scss";

const RangeInput = ({
    min,
    max,
    value,
    onChange,
    onBlur,
    usingMinWidth,
}: {
    min: number;
    max: number;
    value: number;
    onChange: Function;
    onBlur?: Function;
    usingMinWidth?: boolean;
}) => {
    const [currentValue, setCurrentValue] = useState(value);
    const handleChange = (changeValue: string) => {
        let result: any = min;
        if (changeValue) {
            result = changeValue.toString().replace(/[^0-9]/g, "");
            result = result ? result : min;
            result = parseInt(result);
            result = Math.max(result, min);
            result = Math.min(result, max);
        }
        onChange(result);
    };

    return (
        <input
            type="text"
            className="range-input"
            value={value}
            onChange={(e) => {
                handleChange(e.target.value);
            }}
            style={{
                minWidth: usingMinWidth ? "22px" : "unset",
                width: `${value.toString().length}ch`,
                maxWidth: "100%",
                textAlign: "center",
            }}
            onBlur={(e) => {
                if (onBlur && parseInt(e.target.value) !== currentValue) {
                    console.log(e.target.value);
                    console.log(currentValue);

                    onBlur(e);
                }
            }}
            onFocus={(e) => setCurrentValue(value)}
        />
    );
};

export default RangeInput;
