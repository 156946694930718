import React, { useEffect, useState } from 'react';
import {
    IResourceItemNew,
} from "../../../../../../shared/models/resourceItemNew";
import { makeContent } from '../normal-shape';
import { resizeDataImage } from '../../../../../../resource-user/utils/convertTextToIcon';

function ResourceConvertedIconShapeView({
    resourceItem,
    currentItem,
    setCurrentElement,
    pageIndex,
}: {
    resourceItem: IResourceItemNew;
    currentItem?: IResourceItemNew;
    pageIndex: number;
    setCurrentElement: any;
}) {
    const shape = currentItem?.shapeAttribute ?? resourceItem.shapeAttribute;
    const [dataImage, setDataImage] = useState<any>(shape?.url);

    useEffect(() => {
        const handleResizeShape = async () => {
            const resizeDataImageData = await resizeDataImage(shape?.url, shape.width, shape.height);

            setDataImage(resizeDataImageData);
        };

        handleResizeShape();
    }, [shape.url]);

    return (
        <div style={{ opacity: resourceItem.opacity, width: resourceItem.width, height: resourceItem.height }} className="d-flex justify-content-center align-items-center">
            <img src={dataImage} width={shape.width} height={shape.height} alt={resourceItem.id} />
        </div>
    )
    // return makeContent(
    //     shape.width,
    //     shape.height,
    //     currentItem?.imageAttribute?.changeColor["color"] ??
    //     resourceItem?.imageAttribute?.changeColor["color"] ??
    //     "transparent",
    //     currentItem?.imageAttribute?.changeColor["colorBorder"] ??
    //     resourceItem?.imageAttribute?.changeColor["colorBorder"] ??
    //     "#000000",
    //     currentItem ?? resourceItem,
    //     setCurrentElement,
    //     "static"
    // );
};

export default ResourceConvertedIconShapeView;
