import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { getResourceItemById } from "../../../../redux/selector";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import Config from "../../../../shared/utils/config";
import { requestSavePageWs } from "../../../../redux/async/createWorksheet";
import { checkIsTester } from "../../../../resource-user/utils";
import { setStatusSave } from "../../../../resource-user/redux/action/wordsearch.action";

const ListenReducer = ({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew | null;
    setCurrentElement: React.Dispatch<React.SetStateAction<IResourceItemNew>>;
}) => {
    const dispatch = useAppDispatch();
    const resourceItemSelected = useAppSelector(
        (state) => state.createWorksheetState.resourceItemSelected
    );
    const resourceItem = useAppSelector((state) =>
        getResourceItemById()(state, {
            resourceItemId: currentElement?.id,
        })
    );
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const worksheet = useAppSelector(
        (state) => state.wordSearchState.worksheet
    );
    const nextAction = useAppSelector(
        (state) => state.wordSearchState.nextAction
    );
    useEffect(() => {
        if (resourceItemSelected?.length) {
            setCurrentElement({
                ...JSON.parse(JSON.stringify(resourceItemSelected[0])),
            });
        }
    }, [resourceItemSelected]);

    useEffect(() => {
        if (currentElement && resourceItem) {
            if (
                currentElement.id === resourceItem.id &&
                resourceItem.type !== ConstantsTool.TYPE_RESOURCE_MODULE &&
                (currentElement.x !== resourceItem.x ||
                    currentElement.y !== resourceItem.y ||
                    currentElement.width !== resourceItem.width ||
                    currentElement.height !== resourceItem.height ||
                    currentElement.pageIndex !== resourceItem.pageIndex ||
                    currentElement.imageAttribute !==
                    resourceItem.imageAttribute ||
                    currentElement.textAttribute !==
                    resourceItem.textAttribute ||
                    currentElement.rotate !== resourceItem.rotate ||
                    currentElement.shapeAttribute !==
                    resourceItem.shapeAttribute ||
                    currentElement.opacity !== resourceItem.opacity)
            ) {
                setCurrentElement((prevState) => ({
                    ...prevState,
                    id: currentElement?.id,
                    idType: prevState?.idType,
                    x: resourceItem.x,
                    y: resourceItem.y,
                    width: resourceItem.width,
                    height: resourceItem.height,
                    pageIndex: resourceItem.pageIndex,
                    imageAttribute: resourceItem.imageAttribute,
                    textAttribute: resourceItem.textAttribute,
                    shapeAttribute: resourceItem.shapeAttribute,
                    rotate: resourceItem.rotate,
                    opacity: resourceItem.opacity,
                    type: resourceItem.type,
                    fieldName: resourceItem.fieldName,
                    fieldIndex: resourceItem.fieldIndex,
                    fieldDescription: resourceItem.fieldDescription,
                    parentField: resourceItem.parentField,
                    parentFieldIndex: resourceItem.parentFieldIndex,
                }));
            }
        } else {
            if (currentElement && !resourceItem) {
                setCurrentElement(null);
            }
        }
    }, [resourceItem]);

    useEffect(() => {
        if (
            pagesWorksheet?.length > 0 &&
            worksheet?.id?.length &&
            nextAction === Config.NEXT_ACTION_SAVE_WORKSHEET
        ) {
            dispatch(requestSavePageWs()).then((result) => {
                if (checkIsTester()) {
                    dispatch(setStatusSave(true));
                }
            });
        }
    }, [nextAction]);
    return <React.Fragment></React.Fragment>;
};
export default ListenReducer;
