import {
    IShapeAttribute,
    PositionInput,
} from "../../shared/models/shapeAttribute";

export const resizeShape = (
    width: number,
    height: number,
    shape: IShapeAttribute,
    commands: string[][]
): string => {
    let path = "",
        ratioWidth = width / shape.width,
        ratioHeight = height / shape.height;

    if (commands.length !== 0) {
        commands.forEach((element) => {
            element.forEach((value, index) => {
                if (
                    index !== 0 &&
                    element[0] !== "Q" &&
                    element[0] !== "T" &&
                    element[0] !== "Z"
                ) {
                    if (index % 2 === 0) {
                        value = (parseFloat(value) * ratioHeight).toString();
                    } else {
                        value = (parseFloat(value) * ratioWidth).toString();
                    }
                }
                path += value + " ";
            });
        });
    }
    path += "Z";
    return path;
};

export const convertPathToLine = (pathData: string): string => {
    const commands = pathData
        .split(/[A-Za-z]/)
        .filter((item) => item.trim() !== "");
    const types = pathData.match(/[A-Za-z]/g);

    const listPath = commands.map((command, index) => {
        const parts = command.trim().split(/\s+/);
        return [types[index], parts[0], ...parts.slice(1)];
    });
    let path = "";
    let tmpPaths = listPath;
    listPath.forEach((element, index) => {
        if (element[0] === "V") {
            element = [
                "L",
                listPath[index - 1][listPath[index - 1].length - 2],
                element[1],
            ];
            tmpPaths[index] = element;
        }
        if (element[0] === "H") {
            element = [
                "L",
                element[1],
                listPath[index - 1][listPath[index - 1].length - 1],
            ];
            tmpPaths[index] = element;
        }
        element.forEach((value) => {
            path += value + " ";
        });
    });

    return path.trim();
};

export const getListCommands = (pathData: string): Array<Array<string>> => {
    let path = convertPathToLine(pathData);
    const commands = path
        .split(/[A-Za-z]/)
        .filter((item) => item.trim() !== "");
    const types = path.match(/[A-Za-z]/g);
    const commandList = commands.map((command, index) => {
        const parts = command.trim().split(/\s+/);
        return [types[index], parts[0], ...parts.slice(1)];
    });
    return commandList;
};

export const calculateContentHeight = function (ta, scanAmount) {
    let origHeight = ta.style.height,
        height = ta.offsetHeight,
        scrollHeight = ta.scrollHeight,
        overflow = ta.style.overflow;
    /// only bother if the ta is bigger than content
    if (height >= scrollHeight) {
        /// check that our browser supports changing dimension
        /// calculations mid-way through a function call...
        ta.style.height = height + scanAmount + "px";
        /// because the scrollbar can cause calculation problems
        ta.style.overflow = "hidden";
        /// by checking that scrollHeight has updated
        if (scrollHeight < ta.scrollHeight) {
            /// now try and scan the ta's height downwards
            /// until scrollHeight becomes larger than height
            while (ta.offsetHeight >= ta.scrollHeight) {
                ta.style.height = (height -= scanAmount) + "px";
            }
            /// be more specific to get the exact height
            while (ta.offsetHeight < ta.scrollHeight) {
                ta.style.height = height++ + "px";
            }
            /// reset the ta back to it's original height
            ta.style.height = origHeight;
            /// put the overflow back
            ta.style.overflow = overflow;
            return height;
        }
    } else {
        return scrollHeight;
    }
};

export function calculateAngle(A: any, B: any, C: any): number {
    const vectorBA = { x: A.x - B.x, y: A.y - B.y };
    const vectorBC = { x: C.x - B.x, y: C.y - B.y };

    const dotProduct = vectorBA.x * vectorBC.x + vectorBA.y * vectorBC.y;
    const magnitudeAB = Math.sqrt(vectorBA.x ** 2 + vectorBA.y ** 2);
    const magnitudeBC = Math.sqrt(vectorBC.x ** 2 + vectorBC.y ** 2);
    let cosAngle = 0;
    if (magnitudeAB !== 0 && magnitudeBC !== 0) {
        cosAngle = dotProduct / (magnitudeAB * magnitudeBC);
    }
    const angleRad = Math.acos(cosAngle);
    return angleRad;
}

export function roundPathCorners(
    pathString: string,
    radius: number,
    width: number,
    height: number
) {
    if (radius <= 0) {
        return pathString;
    }
    // let d = Math.min(width, height)
    // if (radius * 2 > d) {
    //   radius = d / 2
    // }

    function moveTowardsLength(
        movingPoint: any,
        targetPoint: any,
        amount: number
    ) {
        let width = targetPoint.x - movingPoint.x;
        let height = targetPoint.y - movingPoint.y;

        let distance = Math.sqrt(width * width + height * height);

        return moveTowardsFractional(
            movingPoint,
            targetPoint,
            amount / distance
        );
    }
    function moveTowardsFractional(
        movingPoint: any,
        targetPoint: any,
        fraction: number
    ) {
        return {
            x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
            y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
        };
    }

    // Adjusts the ending position of a command
    function adjustCommand(cmd: any, newPoint: any) {
        if (cmd.length > 2) {
            cmd[cmd.length - 2] = newPoint.x;
            cmd[cmd.length - 1] = newPoint.y;
        }
    }

    // Gives an {x, y} object for a command's ending position
    function pointForCommand(cmd: any) {
        return {
            x: parseFloat(cmd[cmd.length - 2]),
            y: parseFloat(cmd[cmd.length - 1]),
        };
    }

    // calculates the angle

    function coefficient(A: any, B: any, C: any) {
        const angleRad = calculateAngle(A, B, C);
        return (4 / 3) * Math.tan((Math.PI - angleRad) / 4);
    }

    function maxRadius(A: any, B: any, C: any): number {
        const vectorAB = { x: B.x - A.x, y: B.y - A.y };
        const vectorBC = { x: C.x - B.x, y: C.y - B.y };
        const magnitudeAB = Math.sqrt(vectorAB.x ** 2 + vectorAB.y ** 2);
        const magnitudeBC = Math.sqrt(vectorBC.x ** 2 + vectorBC.y ** 2);
        const angle = calculateAngle(A, B, C) / 2;
        const d = Math.min(magnitudeAB, magnitudeBC);
        const r = d / Math.tan(angle);
        return r;
    }

    function findIntersectionWithCircle(
        A: any,
        B: any,
        C: any,
        radius: number
    ) {
        const I = { x: (B.x + C.x) / 2, y: (B.y + C.y) / 2 },
            dAO =
                Math.sqrt((B.x - A.x) ** 2 + (B.y - A.y) ** 2) /
                Math.cos(calculateAngle(B, A, C) / 2),
            dAI = Math.sqrt((I.x - A.x) ** 2 + (I.y - A.y) ** 2),
            O = moveTowardsFractional(A, I, dAO / dAI),
            result = moveTowardsFractional(A, O, (dAO - radius) / dAO);
        return { O, pc: result };
    }

    function findOriginPoint(A: any, B: any, O: any) {
        const I = { x: (B.x + A.x) / 2, y: (B.y + A.y) / 2 },
            vectorAB = { x: B.x - A.x, y: B.y - A.y },
            d = Math.sqrt(vectorAB.x ** 2 + vectorAB.y ** 2) / 2,
            dOI = Math.sqrt((I.x - O.x) ** 2 + (I.y - O.y) ** 2),
            result = moveTowardsFractional(O, I, (dOI + d) / dOI);
        return result;
    }

    function calculatePathC(
        prevPoint: any,
        curPoint: any,
        nextPoint: any,
        r: number
    ): any {
        let curveStart: any, curveEnd: any;
        curveStart = moveTowardsLength(curPoint, prevPoint, r);
        curveEnd = moveTowardsLength(curPoint, nextPoint, r);
        // Adjust the current command and add it
        // The curve control points are halfway between the start/end of the curve and
        // the original point
        let k = coefficient(prevPoint, curPoint, nextPoint);
        let startControl = moveTowardsFractional(curveStart, curPoint, k);
        let endControl = moveTowardsFractional(curveEnd, curPoint, k);
        // Create the curve
        let curveCmd: any = [
            "C",
            startControl.x,
            startControl.y,
            endControl.x,
            endControl.y,
            curveEnd.x,
            curveEnd.y,
        ];
        return { curveCmd, curveStart };
    }

    // Split apart the path, handing concatonated letters and numbers
    let pathParts = pathString
        .split(/[,\s]/)
        .reduce(function (parts: any[], part) {
            let match = part.match("([a-zA-Z])(.+)");
            if (match) {
                parts.push(match[1]);
                parts.push(match[2]);
            } else {
                parts.push(part);
            }

            return parts;
        }, []);
    // Group the commands with their arguments for easier handling
    let commands = pathParts.reduce(function (commands, part) {
        if (parseFloat(part) == part && commands.length) {
            commands[commands.length - 1].push(part);
        } else {
            commands.push([part]);
        }
        return commands;
    }, []);

    if (commands.length >= 2) {
        let endCommands =
            commands[commands.length - 1][0] === "L"
                ? commands[commands.length - 1]
                : commands[commands.length - 2];
        if (
            endCommands[0] === "L" &&
            endCommands[1] === commands[0][1] &&
            endCommands[2] === commands[0][2]
        ) {
            let index = commands.indexOf(endCommands);
            commands.splice(index, 1);
        }
    }
    // The resulting commands, also grouped
    let resultCommands: any = [];

    if (commands.length > 1) {
        let startPoint = pointForCommand(commands[0]);
        // Handle the close path case with a "virtual" closing line
        let virtualCloseLine = null;
        if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
            virtualCloseLine = ["L", startPoint.x, startPoint.y];
            commands[commands.length - 1] = virtualCloseLine;
        }
        let tmpCommands: any = commands.map((command: any) => command.slice());

        // We always use the first command (but it may be mutated)
        resultCommands.push(commands[0]);

        for (let cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
            let prevCmd = resultCommands[resultCommands.length - 1];
            let curCmd = commands[cmdIndex];
            // Handle closing case
            let nextCmd =
                curCmd == virtualCloseLine
                    ? commands[1]
                    : commands[cmdIndex + 1];
            let nextPointTmp =
                curCmd == virtualCloseLine
                    ? tmpCommands[1]
                    : tmpCommands[cmdIndex + 1];
            // Nasty logic to decide if this path is a candidite.
            if (
                nextCmd &&
                prevCmd &&
                prevCmd.length > 2 &&
                curCmd[0] == "L" &&
                nextCmd.length > 2 &&
                nextCmd[0] == "L"
            ) {
                // Calc the points we're dealing with
                let prevPoint = pointForCommand(prevCmd);
                let curPoint = pointForCommand(curCmd);
                let nextPoint = pointForCommand(nextCmd);
                // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
                const angle = calculateAngle(
                    pointForCommand(tmpCommands[cmdIndex - 1]),
                    curPoint,
                    pointForCommand(nextPointTmp)
                );
                let r = Math.min(
                    maxRadius(
                        pointForCommand(tmpCommands[cmdIndex - 1]),
                        curPoint,
                        pointForCommand(nextPointTmp)
                    ) /
                        ((2.5 * (Math.PI / 2)) / angle),
                    radius
                );
                let pathC = calculatePathC(prevPoint, curPoint, nextPoint, r);
                adjustCommand(curCmd, pathC.curveStart);
                curCmd.origPoint = curPoint;
                resultCommands.push(curCmd);
                let curveCmd: any = pathC.curveCmd;
                curveCmd.origPoint = curPoint;
                resultCommands.push(curveCmd);

                // const angle = calculateAngle(pointForCommand(tmpCommands[cmdIndex -1]), curPoint, pointForCommand(nextPointTmp))
                //   if (angle >= Math.PI / 2) {
                //     let r = Math.min(maxRadius(pointForCommand(tmpCommands[cmdIndex -1]), curPoint, pointForCommand(nextPointTmp)) / 2, radius);
                //     let pathC = calculatePathC(prevPoint, curPoint, nextPoint, r)
                //     adjustCommand(curCmd, pathC.curveStart);
                //     curCmd.origPoint = curPoint;
                //     resultCommands.push(curCmd);
                //     let curveCmd: any = pathC.curveCmd
                //     curveCmd.origPoint = curPoint;
                //     resultCommands.push(curveCmd);
                //   } else {
                //     let p1 = moveTowardsLength(curPoint, prevPoint, radius),
                //       p2 = moveTowardsLength(curPoint, nextPoint, radius),
                //       points = findIntersectionWithCircle(curPoint, p1, p2, radius),
                //       O = points.O,
                //       pc = points.pc,
                //       pc1 = findOriginPoint(p1, pc, O),
                //       pc2 = findOriginPoint(pc, p2, O),
                //       curCmd1 = p1,
                //       curCmd2 = p2

                //     adjustCommand(curCmd, p1);
                //     curCmd.origPoint = pc1;
                //     resultCommands.push(curCmd);
                //     let r1 = Math.min(maxRadius(prevPoint, curPoint, nextPoint) / 2, radius);
                //     let pathC1 = calculatePathC(p1, pc1, pc,r1)
                //     let curveCmd1: any = pathC1.curveCmd
                //     curveCmd1.origPoint = pc1;
                //     resultCommands.push(curveCmd1);

                //     let r2 = Math.min(maxRadius(prevPoint, curPoint, nextPoint) / 2, radius);
                //     let pathC2 = calculatePathC(pc, pc2, p2,r2)
                //     let curveCmd2: any = pathC2.curveCmd
                //     curveCmd2.origPoint = pc2;
                //     resultCommands.push(curveCmd2);
                //   }
            } else {
                // Pass through commands that don't qualify
                resultCommands.push(curCmd);
            }
        }

        // Fix up the starting point and restore the close path if the path was orignally closed
        if (virtualCloseLine) {
            let newStartPoint = pointForCommand(
                resultCommands[resultCommands.length - 1]
            );
            resultCommands.push(["Z"]);
            adjustCommand(resultCommands[0], newStartPoint);
        }
    } else {
        resultCommands = commands;
    }
    return resultCommands.reduce(function (str: any, c: any) {
        return str + c.join(" ") + " ";
    }, "");
}

export const getPositionInput = ({
    height,
    inputHeight,
    borderWidth,
    ratio = 1,
    position,
}: {
    height: number;
    inputHeight: number;
    borderWidth: number;
    position: string;
    ratio?: number;
}) => {
    switch (position) {
        case PositionInput.bottom:
            return 8 * ratio + height / 2;
        case PositionInput.top:
            return -inputHeight - 12 * ratio;
        case PositionInput.above:
            return -inputHeight - 2;
        case PositionInput.below:
            return height - 1;
        default:
            return -(inputHeight - height + borderWidth * 2) / 2;
    }
};

export const getSizeLine = (params: {
    width: number;
    height: number;
    line: IShapeAttribute;
}) => {
    let { width, height, line } = params;
    width = Math.max(width, 0);
    height = Math.max(height, 0);
    const getLineWidth = () => {
        if (line.pathEnd && line.pathStart) {
            return width - height;
        }
        if (line.pathEnd || line.pathStart) {
            return width - height / 2;
        }
        return width;
    };
    const lineWidth = getLineWidth();

    const maxX = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 2]) >
                parseFloat(maxElement[maxElement.length - 2])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return element[element.length - 2];
    };

    const minX = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 2]) <
                parseFloat(maxElement[maxElement.length - 2])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return element[element.length - 2];
    };

    const maxY = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 1]) >
                parseFloat(maxElement[maxElement.length - 1])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return parseFloat(element[element.length - 1]);
    };

    const minY = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 1]) <
                parseFloat(maxElement[maxElement.length - 1])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return parseFloat(element[element.length - 1]);
    };

    const getMinXinPath = (commands: string[][]) => {
        let check =
            commands[commands.length - 1][
                commands[commands.length - 1].length - 1
            ] !== commands[0][commands[0].length - 1];
        return check ? minX(commands) : maxX(commands);
    };

    const getMaxXinPath = (commands: string[][]) => {
        let check =
            commands[commands.length - 1][
                commands[commands.length - 1].length - 1
            ] !== commands[0][commands[0].length - 1];
        return check ? maxX(commands) : minX(commands);
    };

    const resizeLine = ({
        pathStart,
        pathEnd,
    }: {
        pathStart?: string;
        pathEnd?: string;
    }) => {
        const commands = getListCommands(line.path);
        let minXpath: string | null = null;
        let maxXpath: string | null = null;
        if (pathStart) {
            const commandsStart = getListCommands(pathStart);
            minXpath = getMinXinPath(commandsStart);
        }
        if (pathEnd) {
            const commandsEnd = getListCommands(pathEnd);
            maxXpath = getMaxXinPath(commandsEnd);
        }

        let path = "",
            ratioWidth = lineWidth / line.width,
            ratioHeight = height / line.strokeWidth;

        commands[0].forEach((value, index) => {
            if (index !== 0) {
                if (index % 2 === 0) {
                    value = (parseFloat(value) * ratioHeight).toString();
                } else {
                    value =
                        minXpath ?? (parseFloat(value) * ratioWidth).toString();
                }
            }
            path += value + " ";
        });

        const d = lineWidth - line.width;

        commands[1].forEach((value, index) => {
            if (index !== 0) {
                if (index % 2 === 0) {
                    value = (parseFloat(value) * ratioHeight).toString();
                } else {
                    value = maxXpath ?? (parseFloat(value) + d).toString();
                }
            }
            path += value + " ";
        });

        return path.trim();
    };

    const resizePathStart = (ratio: number, commands: string[][]): string => {
        let path = "",
            maxWidth = line.strokeWidth / 2,
            maxXpath = minX(commands),
            d = parseFloat(maxXpath) * ratio - maxWidth;

        if (commands.length !== 0) {
            commands.forEach((element) => {
                element.forEach((value, index) => {
                    if (
                        index !== 0 &&
                        element[0] !== "Q" &&
                        element[0] !== "T" &&
                        element[0] !== "Z"
                    ) {
                        if (index % 2 === 0) {
                            value = (parseFloat(value) * ratio).toString();
                        } else {
                            value = (parseFloat(value) * ratio - d).toString();
                        }
                    }
                    path += value + " ";
                });
            });
        }
        return path.trim();
    };

    const resizePathEnd = (ratio: number, commands: string[][]): string => {
        let path = "",
            maxWidth = lineWidth - line.strokeWidth / 2,
            maxXpath = maxX(commands),
            d = parseFloat(maxXpath) * ratio - maxWidth;

        if (commands.length !== 0) {
            commands.forEach((element) => {
                element.forEach((value, index) => {
                    if (
                        index !== 0 &&
                        element[0] !== "Q" &&
                        element[0] !== "T" &&
                        element[0] !== "Z"
                    ) {
                        if (index % 2 === 0) {
                            value = (parseFloat(value) * ratio).toString();
                        } else {
                            value = (parseFloat(value) * ratio - d).toString();
                        }
                    }
                    path += value + " ";
                });
            });
        }
        return path.trim();
    };

    let pathStart = line?.pathStart?.path;
    if (line?.pathStart) {
        const commands = getListCommands(line.pathStart.path);
        pathStart = resizePathStart(height / line.strokeWidth, commands);
    }

    let pathEnd = line?.pathEnd?.path;
    if (line?.pathEnd) {
        const commands = getListCommands(line.pathEnd.path);
        pathEnd = resizePathEnd(height / line.strokeWidth, commands);
    }

    let path = resizeLine({ pathStart: pathStart, pathEnd: pathEnd });

    const resize = (path: string, dx: number, dy: number) => {
        let commands = getListCommands(path);
        let tmpPath = "";
        commands.forEach((element) => {
            element.forEach((value, index) => {
                if (
                    index !== 0 &&
                    element[0] !== "Q" &&
                    element[0] !== "T" &&
                    element[0] !== "Z"
                ) {
                    if (index % 2 === 0) {
                        value = (parseFloat(value) - dy).toString();
                    } else {
                        value = (parseFloat(value) - dx).toString();
                    }
                }
                tmpPath += value + " ";
            });
        });
        return tmpPath.trim();
    };

    const getDx = () => {
        if ((line.pathEnd && line.pathStart) || line.pathStart) {
            return height / 2;
        }
        return 0;
    };

    const minYPathStart = pathStart ? minY(getListCommands(pathStart)) : 0,
        minYPathEnd = pathEnd ? minY(getListCommands(pathEnd)) : 0,
        maxYPathStart = pathStart ? maxY(getListCommands(pathStart)) : 0,
        maxYPathEnd = pathEnd ? maxY(getListCommands(pathEnd)) : 0;
    let dy = Math.min(minY(getListCommands(path)), minYPathStart, minYPathEnd);
    let dx = Math.min(
        parseFloat(minX(getListCommands(path))),
        pathStart ? parseFloat(minX(getListCommands(pathStart))) : 0,
        pathEnd ? parseFloat(minX(getListCommands(pathEnd))) : 0
    );
    dx = (dx < 0 ? dx : 0) - getDx();
    dy = (dy < 0 ? dy : 0) - height / 2;
    path = pathStart || pathEnd ? resize(path, dx, dy) : path;
    pathStart = pathStart ? resize(pathStart, dx, dy) : pathStart;
    pathEnd = pathEnd ? resize(pathEnd, dx, dy) : pathEnd;

    const heightContent = Math.max(
            maxYPathStart - minYPathStart * 2,
            maxYPathEnd - minYPathEnd * 2,
            height
        ),
        topContent =
            pathStart || pathEnd
                ? -Math.max(
                      maxYPathStart - minYPathStart,
                      maxYPathEnd - minYPathEnd,
                      height
                  ) / 2
                : 0;
    return { path, pathStart, pathEnd, topContent, heightContent };
};
