import Slider from "@mui/material/Slider/Slider";
import React, { useLayoutEffect, useState } from "react";
import "./index.scss";

function SliderInput({
    label,
    value,
    min,
    max,
    onChangeInput,
    onMouseDown,
    onChangeCommitted,
    onChangeSlider,
    step,
}: {
    label: string;
    value: number;
    min: number;
    max?: number;
    onChangeInput: (value: number) => void;
    onChangeSlider: (value: number) => void;
    onMouseDown?: () => void;
    onChangeCommitted?: () => void;
    step?: number;
}) {
    const [input, setInput] = useState("");
    const regex = () => {
        if (min < 0) {
            if (step && countDecimalPlaces(step)) {
                return /^[+-]?([0-9]{1,})?(\.)?([0-9]{1,})?$/;
            } else {
                return /^[+-]?([0-9]{1,})?$/;
            }
        }
        if (step && countDecimalPlaces(step)) {
            return /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
        }
        return /^([0-9]{1,})?$/;
    };

    useLayoutEffect(() => {
        if (!value && value !== 0) {
            setInput("");
        } else {
            let inputValue = value.toString();
            if (inputValue.match(regex())) {
                if (input.includes(".") && !inputValue.includes("."))
                    inputValue += ".";
                setInput(inputValue);
            }
        }
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let input = event.target.value;
        if (input.match(regex())) {
            // let value = parseFloat(input);
            // if (value < min) input = min.toString();
            // if (value > max) input = max.toString();
            setInput(input);
            console.log(input);
            value = parseFloat(input);
            onChangeInput(value);
        }
    };
    function countDecimalPlaces(num: number): number {
        const decimalMatch = num
            .toString()
            .match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!decimalMatch) {
            return 0;
        }
        const decimalPart = decimalMatch[1] || "";
        return decimalPart.length;
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let d = step ?? 1;
        let number = value;
        let check = false;
        if (e.key === "ArrowUp") {
            check = true;
            number += d;
        }
        if (e.key === "ArrowDown") {
            check = true;
            number -= d;
        }
        if (e.key === "Enter") {
            let number = parseFloat(input);
            if (number < min) {
                number = min;
                setInput(number.toString());
                onChangeInput(number);
            }
            if (number > max) {
                number = max;
                setInput(number.toString());
                onChangeInput(number);
            }
        }
        let maxNumber = max ?? 100;
        if (check && number >= min && number <= maxNumber) {
            let toFixed = step ? countDecimalPlaces(step) : 0;
            number = parseFloat(number.toFixed(toFixed));
            setInput(number.toString());
            onChangeInput(number);
        }
    };
    return (
        <div className="slider-input">
            <div
                style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <span className="text-border" style={{ padding: "0" }}>
                    {label}
                </span>
                <input
                    className="input-count"
                    value={input}
                    onChange={(e) => {
                        handleInputChange(e);
                    }}
                    onKeyDown={onKeyDown}
                    placeholder="--"
                />
            </div>
            <Slider
                size="small"
                // defaultValue={70}
                aria-label="medium"
                valueLabelDisplay="off"
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={(e, value) => {
                    let input = typeof value === "number" ? value : value[0];
                    setInput(input.toString());
                    onChangeSlider(input);
                }}
                onMouseDown={(e) => {
                    if (onMouseDown) onMouseDown();
                }}
                onChangeCommitted={() => {
                    if (onChangeCommitted) onChangeCommitted();
                }}
                sx={{
                    color: "#0C9EFF",
                    height: 4,
                    "& .MuiSlider-rail": {
                        opacity: 0.5,
                        backgroundColor: "#bfbfbf",
                    },
                    "& .MuiSlider-thumb": {
                        height: 14,
                        width: 14,
                        border: "2px solid currentColor",
                        color: "white",
                        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                            boxShadow: "0 0 0 4px rgba(58, 133, 137, 0.16)",
                        },
                        "&:before": {
                            boxShadow: "1px 2px 4px 2px #d3d3d3",
                        },
                    },
                }}
            />
        </div>
    );
}

export default SliderInput;
