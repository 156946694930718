import { isSafari } from "../../../utils";

function ShapeBorder({
    id,
    path,
    width,
    height,
    strokeWidth,
    strokeDasharray,
    stroke,
    position = "absolute",
    fill = "none",
    zIndex = 5,
}: {
    id: string;
    path: string;
    width: number;
    height: number;
    strokeWidth: number;
    strokeDasharray: number;
    stroke: string;
    fill?: string;
    position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
    zIndex?: number;
}) {
    id = "_id00" + id;
    const d = isSafari() ? 0.3 : 0.1;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${width + d} ${height + d}`}
            style={{
                overflow: "hidden",
                width: width + d,
                height: height + d,
                position: position,
                zIndex: zIndex,
                top: 0,
            }}
        >
            <defs>
                <clipPath id={id}>
                    <path d={path} style={{ transition: "none" }}></path>
                </clipPath>
            </defs>
            <path
                d={path}
                stroke={stroke}
                strokeWidth={Math.min(strokeWidth, Math.max(width, height))}
                strokeLinecap="butt"
                clipPath={`url(#${id})`}
                fill={fill}
                vectorEffect="non-scaling-stroke"
                strokeDasharray={
                    strokeDasharray === 0
                        ? null
                        : `${strokeDasharray * strokeWidth},${strokeWidth}`
                }
                style={{ transition: "none" }}
            ></path>
        </svg>
    );
}

export default ShapeBorder;
