import { useEffect, useLayoutEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    updateManyResourceByType,
    updateResourceItems,
    updateResourceWordBank,
} from "../../../../../../redux/reducers/createWorksheet";
import { getResourceItemsByActivityAndType } from "../../../../../../redux/selector";
import { RootState } from "../../../../../../redux/store";
import {
    IResourceItemNew,
    ImageAttribute,
    ResourceItemNew,
    TextAttribute,
} from "../../../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../../../shared/utils/ConstantsTool";
import { calculateListQuestionFITB } from "../../../../../../utils";
import "./index.scss";

const WordBankComponent = ({
    resourceItem,
    setCurrentElement,
    currentElement,
}: {
    resourceItem: IResourceItemNew;
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) => {
    const dispatch = useAppDispatch();
    const refContent = useRef<HTMLDivElement>(null);
    const heightGap = useAppSelector(
        (state) => state.createWorksheetState.currentActivity.heightGap
    );
    const currentActivity = useAppSelector(
        (state: RootState) => state.createWorksheetState.currentActivity
    );
    const resourceFillTheBlank = useAppSelector((state) =>
        getResourceItemsByActivityAndType()(state, {
            activityId: currentActivity.id,
            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        })
    );
    useEffect(() => {
        if (refContent.current) {
            let heightWordBank = refContent.current.offsetHeight;
            let diffHeight = heightWordBank - resourceItem.height;
            if (diffHeight) {
                dispatch(
                    updateResourceWordBank({
                        resourceItem: new ResourceItemNew({
                            ...resourceItem,
                            height: heightWordBank,
                        }),
                    })
                );
                if (resourceFillTheBlank.length === 1) {
                    dispatch(
                        updateManyResourceByType({
                            pagesWs: calculateListQuestionFITB({
                                resourceFillTheBlank,
                                heightGap: heightGap,
                                originY: resourceItem.y + heightWordBank,
                            }),
                            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                        })
                    );
                }
            }
        }
    }, [resourceItem.wordBanks.length, resourceItem.textAttribute]);

    useEffect(() => {
        if (refContent.current && currentElement) {
            let heightWordBank = refContent.current.offsetHeight;
            let diffHeight = heightWordBank - currentElement.height;
            if (diffHeight) {
                setCurrentElement({
                    ...currentElement,
                    height: heightWordBank,
                });
            }
        }
    }, [
        currentElement?.textAttribute?.letterSpacing,
        currentElement?.textAttribute?.lineHeight,
    ]);

    useLayoutEffect(() => {
        if (currentElement) {
            if (
                (currentElement.height !== resourceItem.height,
                currentElement.wordBanks !== resourceItem.wordBanks)
            ) {
                setCurrentElement({
                    ...resourceItem,
                });
            }
        }
    }, [resourceItem.height, resourceItem?.wordBanks]);

    const checkWordBanks = () => {
        let answers = [];
        resourceFillTheBlank.forEach((e) => {
            if (e.question?.answers?.length) {
                e.question.answers.forEach((anwser) => {
                    answers.push(anwser.text);
                });
            }
        });
        let wordBanks = [...resourceItem.wordBanks];
        let newResourceItem = new ResourceItemNew(resourceItem);

        if (wordBanks?.length) {
            resourceItem.wordBanks.forEach((word) => {
                if (!answers.includes(word)) {
                    let i = wordBanks.findIndex((e) => e === word);
                    if (i >= 0) {
                        wordBanks.splice(i, 1);
                    }
                }
            });
            newResourceItem = { ...newResourceItem, wordBanks: wordBanks };
        }

        if (!resourceItem?.textAttribute?.fontFamily) {
            newResourceItem = {
                ...newResourceItem,
                textAttribute: new TextAttribute({
                    fontSize: 24,
                    fontFamily: "Nunito",
                }),
                imageAttribute: new ImageAttribute({
                    changeColor: {
                        color: "#212121",
                    },
                }),
            };
        }

        dispatch(
            updateResourceItems({
                pageIndex: resourceItem.pageIndex,
                resourceItems: [newResourceItem],
                isAddHistory: false,
            })
        );
        if (currentElement)
            setCurrentElement({
                ...newResourceItem,
            });
    };

    useEffect(() => {
        checkWordBanks();
    }, []);

    const textAttribute =
        currentElement?.textAttribute ?? resourceItem?.textAttribute;
    const { width, height, imageAttribute } = currentElement ?? resourceItem;
    return (
        <div
            className="word-bank"
            style={{
                width: width - 2,
                height: height - 2,
                borderColor: imageAttribute?.changeColor["color"] ?? "#212121",
            }}
        >
            <div
                className="canvas-text word-bank-content"
                ref={refContent}
                style={{
                    gap: `${ConstantsTool.GAP_ELEMENT_WORD_BANK}px`,
                    fontSize: textAttribute.fontSize,
                    fontFamily: textAttribute.fontFamily,
                    fontStyle: textAttribute.isItalic ? "italic" : "normal",
                    fontWeight: textAttribute.isBold ? "bold" : "normal",
                    textAlign: textAttribute.align ?? "justify",
                    color: textAttribute.fontColor ?? "#212121",
                    textDecoration:
                        textAttribute.underline === true
                            ? "underline"
                            : "unset",
                    display: "flex",
                    justifyContent:
                        textAttribute.align === "justify"
                            ? "left"
                            : textAttribute.align,
                    lineHeight: textAttribute.lineHeight,
                    letterSpacing: textAttribute.letterSpacing + "em",
                    wordSpacing:12,
                    whiteSpace: "pre-line",
                    wordBreak: "break-word",
                }}
            >
                {resourceItem.wordBanks.map((e, index) => {
                    if (index > 0) {
                        return " " + e;
                    }
                    return e;
                })}
                {/* {resourceItem.wordBanks?.map((text, index) => {
                    return (
                        <span
                            key={index}
                            className="canvas-text word-bank-currentElement"
                            // style={{
                            //     marginLeft:
                            //         index > 0
                            //             ? textAttribute.letterSpacing > 0
                            //                 ? textAttribute.letterSpacing
                            //                 : null
                            //             : null,
                            // }}
                        >
                            {text}
                        </span>
                    );
                })} */}
            </div>
        </div>
    );
};
export default WordBankComponent;
