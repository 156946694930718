import { IWorksheet } from "../models/worksheet";
import ClassroomConfig from "./classroom";
import Config from "./config";
import ConstantsTool from "./ConstantsTool";

export const isObject = (arg?: any) => {
    return arg && JSON.parse(JSON.stringify(arg)).constructor === Object;
};

export const getEditedTime = (worksheet: IWorksheet) => {
    const editedTime =
        (Date.now() - new Date(worksheet.updatedAt).getTime()) / 1000;
    if (editedTime < 120) {
        return "1 minute";
    } else if (editedTime < 3600) {
        return Math.floor(editedTime / 60) + " minutes";
    } else if (editedTime < 7200) {
        return "1 hour";
    } else if (editedTime < 86400) {
        return Math.floor(editedTime / 3600) + " hours";
    } else if (editedTime < 172800) {
        return "1 day";
    } else if (editedTime < 2592000) {
        return Math.floor(editedTime / 86400) + " days";
    } else if (editedTime < 5184000) {
        return "1 month";
    } else if (editedTime < 31104000) {
        return Math.floor(editedTime / 2592000) + " months";
    } else if (editedTime < 62208000) {
        return "1 year";
    } else {
        return Math.floor(editedTime / 31104000) + " years";
    }
};

export const uppercaseFirstLetter = (text: string) => {
    if (!text) {
        return "";
    }
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
};

export const isModuleApplyForNextJs = () => {
    // return Config.MODULE_APPLY_FOR_NEXT_JS == true;
    return Config.MODULE_NAME == Config.MODULE_NAME_CONFIG.CLASSROOM;
};

export const isModuleForClassroom = () => {
    return Config.MODULE_NAME == Config.MODULE_NAME_CONFIG.CLASSROOM;
};

export const isModuleForWeb = () => {
    return Config.MODULE_NAME == Config.MODULE_NAME_CONFIG.WEB;
};

export const isModuleForToolV1 = () => {
    return Config.MODULE_NAME == Config.MODULE_NAME_CONFIG.TOOL_V1;
};

export const isModuleForToolV2 = () => {
    return Config.MODULE_NAME == Config.MODULE_NAME_CONFIG.TOOL_V2;
};
export const generateAvatarCode = (code: string) => {
    const asciiValue = code.charCodeAt(code.length - 1);
    const generateIndex =
        asciiValue % ClassroomConfig.AVATAR_DEFAULT_STUDENT.length;
    return ClassroomConfig.AVATAR_DEFAULT_STUDENT[generateIndex];
};

export const generateAvatarTeacher = () => {
    const random = Math.floor(
        Math.random() * ClassroomConfig.AVATAR_DEFAULT_TEACHER.length
    );
    return ClassroomConfig.AVATAR_DEFAULT_TEACHER[random];
};

export const isResourceCollection = ({
    collectionId,
}: {
    collectionId: string;
}) => {
    return [
        Config.COLLECTION_WORKSHEETS_CREATED.WORKSHEETS_CREATED_COLLECTIONid,
        Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid,
        Config.COLLECTION_MY_COLLECTIONS.COLLECTION_MY_COLLECTIONSid,
    ].includes(collectionId);
};

export const uppercaseLetter = (string: string) => {
    if (!!!string) {
        return "";
    }
    let arrString = string.split(" ");
    arrString = arrString?.map((item) => {
        return uppercaseFirstLetter(item);
    });
    return arrString.join(" ");
};

export const getListGrade = () => {
    return [
        "Early Childhood",
        "Kindergarten",
        "Grade 1",
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
        "Grade 8",
        "Grade 9",
        "Grade 10",
        "Grade 11",
        "Grade 12",
        "College",
    ];
};
export const stringifyAndParse = (value: any) => {
    if (!value) return;
    return JSON.parse(JSON.stringify(value));
};
export const getPrefixByProjectName = (
    projectName: string,
    modulePrefix: string = ""
) => {
    return projectName === Config.PROJECT_NAME.TOOL_V2
        ? "ncw_" + modulePrefix
        : "wse_";
};

export const getTitleWsFromProjectName = (projectName: string) => {
    return projectName === Config.PROJECT_NAME.TOOL_V2
        ? "Enter Your Title Here..."
        : "My Word Search Title";
};

export const getPageRatio = (currentPageSize: any) => {
    const BASE_WIDTH = ConstantsTool.WIDTH_A4;
    const BASE_HEIGHT = ConstantsTool.HEIGHT_A4;
    let widthRatio = currentPageSize.width / BASE_WIDTH;
    let heightRatio = currentPageSize.height / BASE_HEIGHT;
    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    return ratio;
};

export const checkIsCreatedByToolV2 = (originUrl: string) => {
    // return false;
    return (
        originUrl === Config.USER_CREATED_TOOL_V2_WORD_SEARCH ||
        originUrl === Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK ||
        originUrl === Config.USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET ||
        originUrl === Config.USER_CREATED_TOOL_V2_MULTIPLE_CHOICES ||
        originUrl === Config.USER_CREATED_TOOL_V2_CROSSWORD
        // ||
        // originUrl === Config.USER_CREATED_TOOL_V2_HAND_WRITING
    );
};

export const checkIsWebProject = () => {
    return Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.WEB;
};

export const checkIsToolV1Project = () => {
    return Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.TOOL_V1;
};

export const checkIsClassrommProject = () => {
    return Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.CLASSROOM;
};

const SPECIAL_CHARACTERS: any = {
    À: 0,
    Á: 1,
    Â: 2,
    Ã: 3,
    È: 4,
    É: 5,
    Ê: 6,
    Ì: 7,
    Í: 8,
    Ò: 9,
    Ó: 10,
    Ô: 11,
    Õ: 12,
    Ù: 13,
    Ú: 14,
    Ý: 15,
    à: 16,
    á: 17,
    â: 18,
    ã: 19,
    è: 20,
    é: 21,
    ê: 22,
    ì: 23,
    í: 24,
    ò: 25,
    ó: 26,
    ô: 27,
    õ: 28,
    ù: 29,
    ú: 30,
    ý: 31,
    Ă: 32,
    ă: 33,
    Đ: 34,
    đ: 35,
    Ĩ: 36,
    ĩ: 37,
    Ũ: 38,
    ũ: 39,
    Ơ: 40,
    ơ: 41,
    Ư: 42,
    ư: 43,
    Ạ: 44,
    ạ: 45,
    Ả: 46,
    ả: 47,
    Ấ: 48,
    ấ: 49,
    Ầ: 50,
    ầ: 51,
    Ẩ: 52,
    ẩ: 53,
    Ẫ: 54,
    ẫ: 55,
    Ậ: 56,
    ậ: 57,
    Ắ: 58,
    ắ: 59,
    Ằ: 60,
    ằ: 61,
    Ẳ: 62,
    ẳ: 63,
    Ẵ: 64,
    ẵ: 65,
    Ặ: 66,
    ặ: 67,
    Ẹ: 68,
    ẹ: 69,
    Ẻ: 70,
    ẻ: 71,
    Ẽ: 72,
    ẽ: 73,
    Ế: 74,
    ế: 75,
    Ề: 76,
    ề: 77,
    Ể: 78,
    ể: 79,
    Ễ: 80,
    ễ: 81,
    Ệ: 82,
    ệ: 83,
    Ỉ: 84,
    ỉ: 85,
    Ị: 86,
    ị: 87,
    Ọ: 88,
    ọ: 89,
    Ỏ: 90,
    ỏ: 91,
    Ố: 92,
    ố: 93,
    Ồ: 94,
    ồ: 95,
    Ổ: 96,
    ổ: 97,
    Ỗ: 98,
    ỗ: 99,
    Ộ: 100,
    ộ: 101,
    Ớ: 102,
    ớ: 103,
    Ờ: 104,
    ờ: 105,
    Ở: 106,
    ở: 107,
    Ỡ: 108,
    ỡ: 109,
    Ợ: 110,
    ợ: 111,
    Ụ: 112,
    ụ: 113,
    Ủ: 114,
    ủ: 115,
    Ứ: 116,
    ứ: 117,
    Ừ: 118,
    ừ: 119,
    Ử: 120,
    ử: 121,
    Ữ: 122,
    ữ: 123,
    Ự: 124,
    ự: 125,
    Ỹ: 126,
    Ỳ: 127,
};
const REPLACEMENTS = [
    "A",
    "A",
    "A",
    "A",
    "E",
    "E",
    "E",
    "I",
    "I",
    "O",
    "O",
    "O",
    "O",
    "U",
    "U",
    "Y",
    "a",
    "a",
    "a",
    "a",
    "e",
    "e",
    "e",
    "i",
    "i",
    "o",
    "o",
    "o",
    "o",
    "u",
    "u",
    "y",
    "A",
    "a",
    "D",
    "d",
    "I",
    "i",
    "U",
    "u",
    "O",
    "o",
    "U",
    "u",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "I",
    "i",
    "I",
    "i",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "Y",
    "Y",
];

export function replaceForLink(str: string, regexReplace?: string) {
    if (!!!str) return "";
    let result = "";
    for (let i = 0; i < str.length; i++) {
        let c = str?.charAt(i);
        if (SPECIAL_CHARACTERS[c]) {
            c = REPLACEMENTS[SPECIAL_CHARACTERS[c]];
        }
        result += c;
    }

    return result
        .replace(/ /g, "-")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "-")
        .replace(/-{2,}/g, "-")
        .replace(/---/g, "-")
        .replace(/--/g, "-")
        .replace(/\b/g, "")
        .toLowerCase();
}

export const validateEmail = (email: string) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
};

export const areDatesEqual = (date1: Date, date2: Date) => {
    if (!date1) return false;
    date1 = new Date(date1);

    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
};

export const getAbsoluteValue = (value: number) => {
    if (value < 0) {
        return value * -1;
    }
    return value;
};

export const isS3Link = (link: string = '') => {
    const s3Pattern = /(s3-|s3\.)?(.*)\.amazonaws\.com/;

    // Test the URL against the pattern
    return s3Pattern.test(link);
};

export const isFrameLink = (link: string) => {
    const framesPattern = /\/frames\//i;

    return framesPattern.test(link);
};

export const getOriginalImageURL = (thumbnailURL: string) => {
    return thumbnailURL.replace('/thumbnails/', '/originals/').replace('_thumbnail', '');
};

export const fileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };

    reader.readAsDataURL(file);
    reader.onerror = reject;
});

export const sortResourcesByStore = (array, currentStore: number | null = -1) => {
    return array.sort((a, b) => {
        if (a.store.includes(currentStore) && !b.store.includes(currentStore)) {
            return -1;
        } else if (!a.store.includes(currentStore) && b.store.includes(currentStore)) {
            return 1;
        } else if (a.store.length > 0 && b.store.length === 0) {
            return -1;
        } else if (a.store.length === 0 && b.store.length > 0) {
            return 1;
        } else {
            return 0;
        }
    })
};
