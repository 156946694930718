import mapping from '../../data/textIconMapping.json';

export const getIconWidthAndHeight = (iconName: string) => {
    return mapping[iconName];
}

export const isZoneContentMatching = (zoneContent: string) => {
    const allKeys = Object.keys(mapping);
    for (const key of allKeys) {
        if (key === zoneContent) {
            return mapping[key];
        }
    }
    return false;
}

export const calculateTheSizeOfShapeToFitTheZone = (
    shapeDimenstion: { width: number, height: number },
    zoneDimenstion: { width: number, height: number },
    shouldResizeWhenShapeSmallerThanZone: boolean = false,
) => {
    const { width: zoneWidth, height: zoneHeight } = zoneDimenstion;
    const { width: shapeWidth, height: shapeHeight } = shapeDimenstion;

    if (!shouldResizeWhenShapeSmallerThanZone) {
        shouldResizeWhenShapeSmallerThanZone = shapeWidth > zoneWidth || shapeHeight > zoneHeight;
    }

    if (shouldResizeWhenShapeSmallerThanZone) {
        // Calculate the scaling factor for width and height
        const widthScaleFactor = zoneWidth / shapeWidth;
        const heightScaleFactor = zoneHeight / shapeHeight;

        const scale = Math.min(widthScaleFactor, heightScaleFactor);

        const newWidth = shapeWidth * scale;
        const newHeight = shapeHeight * scale;

        return { width: newWidth, height: newHeight };
    }

    return shapeDimenstion;
};

export const resizeDataImage = (dataImage: string, newWidth: number, newHeight: number) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const resizedDataImage = canvas.toDataURL();

            resolve(resizedDataImage);
        };

        img.onerror = function() {
            reject(new Error('Failed to load the image.'));
        };

        img.src = dataImage;
    });
}
