import { isResourceInteractive } from ".";
import { IWordsearchState } from "../resource-user/redux/reducer/wordSearch.reducer";
import { getOriginUrlFromCurrentActivity } from "../resource-user/utils";
import { Answer, ANSWER_TYPE, IAnswer } from "../shared/models/answer";
import { IPageWorksheetNew } from "../shared/models/pageWorksheetNew";
import { InputWord } from "../shared/models/puzzle";
import { IShapeAttribute } from "../shared/models/shapeAttribute";
import { IWorksheet } from "../shared/models/worksheet";
import Config from "../shared/utils/config";
import ConstantsTool from "../shared/utils/ConstantsTool";

export const createWorksheet = (
    worksheet: IWorksheet,
    pages: IPageWorksheetNew[],
    elementId: string,
    transform: number,
    wordSearchState: IWordsearchState,
    currentActivity?: string
): IWorksheet => {
    let screenSizes = getScreenSizes(elementId),
        answers: IAnswer[] = [];
    answers = answers.filter(
        (answer) => answer.type !== ANSWER_TYPE.FILL_BLANK
    );
    let answerId = getMaxId(answers) + 1;
    let checkIsInteractive = false;
    let isWordSearch = false;
    let originUrl = getOriginUrlFromCurrentActivity(currentActivity);
    let interactiveType = [
        ConstantsTool.TYPE_RESOURCE_SHAPE,
        ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE,
        ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        ConstantsTool.TYPE_RESOURCE_MODULE,
        ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID,
        ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST,
        ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK,
        ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES,
        ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE,
        ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE,
        ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE,
    ];
    pages.forEach((page, index) => {
        page.resourceItems.forEach((resourceItem) => {
            if (isResourceInteractive(resourceItem.type)) {
                let shape = {
                        ...resourceItem.shapeAttribute,
                        rotate: resourceItem.rotate,
                    },
                    // size = getSizeInput(resourceItem.width, resourceItem.height, shape),
                    ratio =
                        (transform * Config.INTERACTIVE_IMAGE_SIZE) /
                        screenSizes[index].width;
                let answer = new Answer({
                    answerId,
                    top: resourceItem.y * ratio,
                    left: resourceItem.x * ratio,
                    width: resourceItem.width * ratio,
                    height: resourceItem.height * ratio,
                    type: getTypeAnswer(resourceItem.type),
                    value: resourceItem.textAttribute.content,
                    background:
                        resourceItem.imageAttribute.changeColor["color"],
                    shapeAttribute: shape,
                    fontSize:
                        resourceItem.textAttribute.fontSize *
                        (resourceItem.type === ConstantsTool.TYPE_RESOURCE_SHAPE
                            ? 1
                            : ratio),
                    page: resourceItem.pageIndex,
                    color: resourceItem.textAttribute.fontColor,
                    opacity: resourceItem.opacity,
                });
                answerId++;
                answers.push(answer);
            }
            if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_MODULE) {
                isWordSearch = true;
            }
            if (interactiveType.includes(resourceItem.type)) {
                checkIsInteractive = true;
            }
        });
    });

    if (isWordSearch) {
        const { inputWords, puzzle, type } =
            wordSearchState as IWordsearchState;
        const parseInputWords = JSON.stringify(
            inputWords.map(
                (word) =>
                    new InputWord({
                        ...word,
                        status: Config.STATUS_PUZZLE.INIT,
                    })
            )
        );
        const answer = new Answer({
            type: ANSWER_TYPE.WORD_SEARCH_BY_TOOL,
            wordSearchValue: puzzle.puzzleArrChar,
            value: parseInputWords,
        });
        answers = [answer];
    }
    return {
        ...worksheet,
        originUrl,
        game: { ...worksheet.game, answers },
        type: checkIsInteractive
            ? Config.WORKSHEET_TYPE_INTERACTIVE
            : Config.WORKSHEET_TYPE_PDF,
    };
};

const getTypeAnswer = (resourceItemType: number) => {
    switch (resourceItemType) {
        case ConstantsTool.TYPE_RESOURCE_SHAPE:
            return ANSWER_TYPE.FILL_SHAPE;
        case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
            return ANSWER_TYPE.FILL_LINE;
    }
};

export const getScreenSizes = (elementId: string) => {
    let nodes = Array.from(
            document.querySelectorAll("#" + elementId)
        ) as HTMLElement[],
        screenSizes: { width: number; height: number }[] = [];
    nodes.forEach((node) => {
        screenSizes.push({
            width: node.offsetWidth,
            height: node.offsetHeight,
        });
    });
    return screenSizes;
};

const getSizeInput = (
    width: number,
    height: number,
    shape: IShapeAttribute
) => {
    let inputWidth = (shape.input.width * width) / shape.width,
        inputHeight = (shape.input.height * height) / shape.height,
        top = (height * shape.input.y) / shape.height,
        left = (width * shape.input.x) / shape.width;
    return { width: inputWidth, height: inputHeight, top: top, left: left };
};

function getMaxId(elements: IAnswer[]): number {
    let maxId = -1;
    for (const element of elements) {
        if (element.answerId > maxId) {
            maxId = element.answerId;
        }
    }
    return maxId;
}

export class ImgSvgContent {
    static data = {};
}
