// import jsPDF from "jspdf";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { IWorksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import { updateQuotaDownloadAction } from "../../redux/action/quotaResourceUser.action";
import {
    selectAllImageAction,
    selectGridItemAction,
} from "../../redux/action/resource.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import { checkIsToolCreate, checkIsToolV2 } from "../../utils";
import Routes from "../../utils/routes";
import { checkRoutes } from "../../utils/tracking";
import { ExtractParamType } from "../common/dynamic-component";
import PrintWithBase64 from "./print-base64";

const MainPrint = ({
    worksheet,
    printWithKey,
    setOpenModal,
    setLoading,
    otherAction,
    numberWorksheet = 1,
    onlyDownload,
    notResetWorksheets,
}: {
    worksheet: IWorksheet;
    setOpenModal: Function;
    printWithKey?: boolean;
    setLoading: Function;
    otherAction?: Function;
    numberWorksheet?: number;
    onlyDownload?: boolean;
    notResetWorksheets?: boolean;
}) => {
    const isTablet = useMediaQuery("(max-width:1024px)");
    const dispatch = useDispatch();
    const componentPrintRef = useRef(null);
    const onBeforeGetContentResolve = useRef(null);

    const reactToPrintContent = useCallback(() => {
        return componentPrintRef?.current;
    }, [componentPrintRef?.current]);

    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    let actionGrid = useSelector(
        (state: ResourceAppState) => state.resourceState?.actionGridItem?.action
    );
    let worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.worksheets
    );
    let listIndexImgDownload = useSelector(
        (state: ResourceAppState) => state.wordSearchState?.listIndexImgDownload
    );
    let playWorksheet = useSelector(
        (state: ResourceAppState) => state.gameResourceState?.worksheet
    );
    const [text, setText] = useState("old boring text");

    useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);
    const ListTrackingLanding = [
        { routes: Routes.BACK_TO_SCHOOL, action: "bts_print_multi_worksheets" },
        {
            routes: Routes.HALLOWEEN_WORKSHEETS,
            action: "hlw_print_multi_worksheets",
        },
        {
            routes: Routes.CHRISTMAS_WORKSHEETS,
            action: "christmas_print_multi_worksheets",
        },
    ];
    const addTrackingLanding = () => {
        ListTrackingLanding.map((item) => {
            if (
                typeof window !== "undefined" &&
                checkRoutes(window.location.pathname, item.routes)
            ) {
            }
        });
    };

    const otherActionAfterPrint = () => {
        if (
            ![
                Config.ACTION_GRID.DOWNLOAD_WSS,
                Config.ACTION_GRID.PRINT_WSS,
            ].includes(actionGrid)
        ) {
            !playWorksheet.id &&
                worksheets?.length &&
                dispatch(selectGridItemAction({ worksheets: [] }));
        }
        // if (!notResetWorksheets) {
        //     worksheets?.length &&
        //         dispatch(selectGridItemAction({ worksheets: [] }));
        // }
        // if (
        //     [
        //         Config.ACTION_GRID.DOWNLOAD_WSS,
        //         Config.ACTION_GRID.PRINT_WSS,
        //     ].includes(actionGrid)
        // ) {
        //     dispatch(
        //         selectActionGridItemAction({
        //             action: Config.ACTION_GRID.DEFAULT,
        //         })
        //     );
        // }
        setOpenModal(false);
        // dispatch(changeUpdateSuccessAction(false));
    };
    const actionAfterPrint = () => {
        otherAction && otherAction();
        // worksheets?.length &&
        //     dispatch(selectGridItemAction({ worksheets: [] }));
        if (
            projectName !== Config.PROJECT_NAME.TOOL_V2 &&
            !checkIsToolCreate()
        ) {
            dispatch(updateQuotaDownloadAction(numberWorksheet ?? 1));
            // if (limitDownload < Config.INFINITY_QUOTA_DOWNLOAD) {
            //     dispatch(
            //         setAlertDownloadWsAction({
            //             alertDownloadWs: Config.STATUS_ALERT_DOWNLOAD.SHOW_SAFE,
            //         })
            //     );
            // }
        }
        dispatch(selectAllImageAction({ arrIndex: [] }));
        addTrackingLanding();
        setLoading(false);
        if (!isTablet && !onlyDownload) {
            otherActionAfterPrint();
            // setOpenModal(false);
        }
    };
    const handleAfterPrint = useCallback(() => {
        if (!(isTablet || onlyDownload)) {
            actionAfterPrint();
        }
    }, []);

    const handleOnBeforeGetContent = useCallback(() => {
        if (!(isTablet || onlyDownload)) {
            setText("Loading new text...");
            return new Promise((resolve: any) => {
                onBeforeGetContentResolve.current = resolve;
                setTimeout(() => {
                    setLoading(false);
                    setText("New, Updated Text!");
                    resolve();
                }, 2000);
            });
        }
    }, [setLoading, setText]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: worksheet?.title ?? "AwesomeFileName",
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true,
        onPrintError: () => {
            console.log("error");
        },
        trigger: () => {
            return (
                <button
                    style={{
                        display: "none",
                    }}
                >
                    Print this out!
                </button>
            );
        },
    });

    let listImage = worksheet.game.images.map(
        (el) => el + "?t=" + new Date().getTime()
    );
    if (printWithKey && worksheet.gameSubmitted?.images?.length) {
        listImage = worksheet.gameSubmitted?.images?.map(
            (el) => el + "?t=" + new Date().getTime()
        );
    }
    if (checkIsToolCreate() || checkIsToolV2()) {
        listImage = listImage.filter((img, index) =>
            listIndexImgDownload.includes(index)
        );
    }
    return (
        <PrintWithBase64
            worksheet={worksheet}
            listImage={listImage}
            actionAfterPrint={actionAfterPrint}
            setLoading={setLoading}
            printWithKey={printWithKey}
            onlyDownload={onlyDownload}
            handlePrint={handlePrint}
            componentPrintRef={componentPrintRef}
            setOpenModal={setOpenModal}
            otherActionAfterPrint={otherActionAfterPrint}
        />
    );
};

export type MainPrintType = ExtractParamType<typeof MainPrint>;

export default MainPrint;
