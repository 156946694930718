import { MouseEvent, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    addResourceItems,
    selectElements,
    updateIsOnDrag,
    updateIsOnEditText,
} from "../../../../../redux/reducers/createWorksheet";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../../shared/models/resourceItemNew";
import Config from "../../../../../shared/utils/config";
import { getTextWidthHeightTool } from "../../../../../utils/draw";
import "./index.scss";
import { generateIdFromDateTime, getPositionResource } from "../../../../../utils";
const TextLeftTabElement = ({
    item,
    index,
}: {
    item: IResourceItemNew;
    index: number;
}) => {
    const ref = useRef();
    const dispatch = useAppDispatch();
    const currentPage = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const pageWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet[currentPage]
    );
    const resourceItems: IResourceItemNew[] =
        currentPage >= 0
            ? useAppSelector(
                  (state) =>
                      state.createWorksheetState.pagesWorksheet[currentPage]
                          .resourceItems
              )
            : [];
    const onClickResource = (e: MouseEvent, item: IResourceItemNew) => {
        let size = getTextWidthHeightTool({
            textValue: item.textAttribute.content,
            includePadding: true,
            fontSize: item.textAttribute.fontSize,
            fontFamily: item.textAttribute.fontFamily,
            textAlign: item.textAttribute.align,
        });
        const width = size.width + 15,
        height = size.height
        const position = getPositionResource(
            width,
            height,
            resourceItems.filter((e) => e.type === Config.RESOURCE_TEXT),
            {width: pageWorksheet.width, height: pageWorksheet.height}
        );
        let x = position.x
        let y = position.y

        let newItem = new ResourceItemNew({
            ...item,
            x: x,
            y: y,
            type: Config.RESOURCE_TEXT,
            width: width,
            height: height,
            id: generateIdFromDateTime(),
            pageIndex: currentPage,
            textAttribute: {
                ...item.textAttribute,
                fontSize: item.textAttribute.fontSize * 1,
            },
        });
        dispatch(
            addResourceItems({
                resourceItems: [newItem],
                pageIndex: currentPage,
            })
        );

        dispatch(
            selectElements({
                resourceSelected: [newItem],
                pageIndex: currentPage,
            })
        );
        dispatch(updateIsOnEditText(true));
        // dispatch(updateIsOnEditText(true));
    };

    const onDragResourceItem = (e, resource) => {
        if (ref.current) {
            let size = getTextWidthHeightTool({
                includePadding: true,
                textValue: item.textAttribute.content,
                fontSize: item.textAttribute.fontSize,
                fontFamily: item.textAttribute.fontFamily,
                textAlign: item.textAttribute.align,
            });
            const width = size.width + 15;
            const height = size.height;
            e.dataTransfer.setDragImage(ref.current, 50, 50);
            e.dataTransfer.setData(
                "resourceItem",
                JSON.stringify({
                    ...resource,
                    width,
                    height,
                    lineHeight: 1.4,
                    id: generateIdFromDateTime(),
                    textAttribute: {
                        ...resource.textAttribute,
                        fontSize: resource.textAttribute.fontSize * 1,
                    },
                })
            );
            dispatch(updateIsOnDrag(true));
            dispatch(updateIsOnEditText(true));
        }
    };

    return (
        <div
            ref={ref}
            key={item.id + "-" + index}
            // style={item.style}
            style={{
                fontSize: item.textAttribute.fontSize,
                fontFamily: item.textAttribute.fontFamily,
            }}
            className="default-text-item"
            draggable="true"
            id={`${item.id}`}
            onClick={(e) => onClickResource(e, item)}
            onDragStart={(ev) => {
                onDragResourceItem(ev, item);
            }}
            onDragEnd={() => dispatch(updateIsOnDrag(false))}
        >
            <div className="text-content">{item.textAttribute.content}</div>
        </div>
    );
};

export default TextLeftTabElement;
