import { ICollection, IWorksheetItem } from "../../../shared/models/collection";
import { IWorksheet } from "./../../../shared/models/worksheet";

export enum ResourceTypes {
    GET_RESOURCE_DEFAULT = "GET_RESOURCE_DEFAULT",
    GET_RESOURCE_DEFAULT_SUCCESS = "GET_RESOURCE_DEFAULT_SUCCESS",
    GET_NUMBER_WS_RESOURCE_DEFAULT_SUCCESS = "GET_NUMBER_WS_RESOURCE_DEFAULT_SUCCESS",
    SELECT_COLLECTION_RESOURCE = "SELECT_COLLECTION_RESOURCE",
    SELECT_COLLECTION_ITEM_RESOURCE = "SELECT_COLLECTION_ITEM_RESOURCE",
    UPDATE_COLLECTION_RESOURCE = "UPDATE_COLLECTION_RESOURCE",
    GET_LIST_COLLECTION_RESOURCE = "GET_LIST_COLLECTION_RESOURCE",
    GET_DEFAULT_COLLECTION_RESOURCE = "GET_DEFAULT_COLLECTION_RESOURCE",
    UPDATE_LIST_COLLECTION_SUCCESS_RESOURCE = "UPDATE_LIST_COLLECTION_SUCCESS_RESOURCE",
    UPDATE_LIST_COLLECTION_POPUP_SUCCESS = "UPDATE_LIST_COLLECTION_POPUP_SUCCESS",
    SELECT_GRID_ITEM_RESOURCE = "SELECT_GRID_ITEM_RESOURCE",
    SELECT_ACTION_GRID_ITEM_RESOURCE = "SELECT_ACTION_GRID_ITEM_RESOURCE",
    UPDATE_ITEM_COLLECTION_RESOURCE = "UPDATE_ITEM_COLLECTION_RESOURCE",
    EDIT_COLLECTION_RESOURCE = "EDIT_COLLECTION_RESOURCE",
    UPDATE_ITEM_COLLECTION_SUCCESS_RESOURCE = "UPDATE_ITEM_COLLECTION_SUCCESS_RESOURCE",
    REMOVE_COLLECTION_SUCCESS_RESOURCE = "REMOVE_COLLECTION_SUCCESS_RESOURCE",
    MOVE_COLLECTION_RESOURCE_SUCCESS = "MOVE_COLLECTION_RESOURCE_SUCCESS",
    MOVE_COLLECTION_RESOURCE = "MOVE_COLLECTION_RESOURCE",
    UPDATE_COLLECTION_RESOURCE_SUCCESS = "UPDATE_COLLECTION_RESOURCE_SUCCESS",
    CREATE_ITEM_COLLECTION_RESOURCE_SUCCESS = "CREATE_ITEM_COLLECTION_RESOURCE_SUCCESS",
    MOVE_WORKSHEET_TO_COLLECTION_RESOURCE_SUCCESS = "MOVE_WORKSHEET_TO_COLLECTION_RESOURCE_SUCCESS",
    EDIT_COLLECTION_RESOURCE_SUCCESS = "EDIT_COLLECTION_RESOURCE_SUCCESS",
    UPDATE_NUMBER_WORKSHEET_OF_COLLECTION = "UPDATE_NUMBER_WORKSHEET_OF_COLLECTION",
    REMOVE_WORKSHEET_OF_COLLECTION = "REMOVE_WORKSHEET_OF_COLLECTION",
    GET_DATA_COLLECTION_RESOURCE_V2 = "GET_DATA_COLLECTION_RESOURCE_V2",
    SET_LOADING_MORE_WORKSHEET_RESOURCE = "SET_LOADING_MORE_WORKSHEET_RESOURCE",
    LOAD_MORE_WORKSHEET_RESOURCE = "LOAD_MORE_WORKSHEET_RESOURCE",
    UPDATE_STATUS_ALERT_RESOURCE = "UPDATE_STATUS_ALERT_RESOURCE",
    REMOVE_COLLECTION_RESOURCE = "REMOVE_COLLECTION_RESOURCE",
    UPDATE_ALERT_RESOURCE = "UPDATE_ALERT_RESOURCE",
    UPDATE_COLLECTION_ITEM_RESOURCE = "UPDATE_COLLECTION_ITEM_RESOURCE",
    DELETE_WORKSHEET_FROM_COLLECTION = "DELETE_WORKSHEET_FROM_COLLECTION",
    DELETE_WORKSHEET_FROM_COLLECTION_SUCCESS = "DELETE_WORKSHEET_FROM_COLLECTION_SUCCESS",
    PREVIOUS_ACTION = "PREVIOUS_ACTION",
    AFTER_ACTION = "AFTER_ACTION",
    DELETE_WORKSHEET_FROM_CREATED_OR_DOWNLOADED_WORKSHEET = "DELETE_WORKSHEET_FROM_CREATED_OR_DOWNLOADED_WORKSHEET",
    ADD_WORKSHEET_TO_COLLECTION = "ADD_WORKSHEET_TO_COLLECTION",
    ADD_WORKSHEET_TO_DOWNLOADED = "ADD_WORKSHEET_TO_DOWNLOADED",
    DELETE_WORKSHEET_FROM_DOWNLOADED = "DELETE_WORKSHEET_FROM_DOWNLOADED",
    SELECT_IMAGES_WORKSHEET = "SELECT_IMAGES_WORKSHEET",
    SELECT_ALL_IMAGES_WORKSHEET = "SELECT_ALL_IMAGES_WORKSHEET",
    UPDATE_LOADING_TAB_INFO = "UPDATE_LOADING_TAB_INFO",
    UPDATE_ACTIONS_GA_AFTER_SUCCESS = "UPDATE_ACTIONS_GA_AFTER_SUCCESS",
    SEND_EVENT_GA = "SEND_EVENT_GA",
    ADD_IMAGE_WORKSHEET_OF_COLLECTION = "ADD_IMAGE_WORKSHEET_OF_COLLECTION",
    ADD_IMAGE_WORKSHEET_OF_COLLECTION_SUCCESS = "ADD_IMAGE_WORKSHEET_OF_COLLECTION_SUCCESS",
    ADD_ACTION_EVENT_DOWNLOAD = "ADD_ACTION_EVENT_DOWNLOAD",
}

export interface ICollectionAction {
    type: ResourceTypes;
    index?: number;
    arrIndex?: number[];
    defaultCollections?: ICollection[];
    userId?: string;
    profileId?: string;
    collection?: ICollection;
    worksheet?: IWorksheet;
    pageTypeResource?: number;
    loading?: boolean;
    collections?: ICollection[];
    numberCollections?: number;
    pageNumber?: number;
    action?: string;
    params?: any;
    worksheets?: IWorksheet[];
    worksheetId?: string;
    worksheetIds?: string[];
    collectionId?: string;
    collectionUpdate?: any;
    numberOfWs?: number;
    arrayCollection?: ICollection[];
    arrayThumbnail?: IWorksheet[];
    numberSubCollections?: number;
    collectionsState?: ICollection[];
    toCollection?: ICollection;
    oldParentId?: string;
    content?: string;
    status?: boolean;
    code?: number;
    added?: boolean;
    typeAlert?: string;
    message?: string;
    thumbnail?: string;
    notFetchData?: boolean;
    offset?: number;
    isSelectCollection?: ICollection;
    isNextLevelCollection?: boolean;
    listCollection?: ICollection[];
    afterAction?: string;
    projectData?: any;
}

export const selectIndexImageAction = ({ index }: { index: number }) => {
    return { type: ResourceTypes.SELECT_IMAGES_WORKSHEET, index };
};

export const addActionEventDownloadAction = ({
    haveActionPopup,
}: {
    haveActionPopup: boolean;
}) => {
    return { type: ResourceTypes.ADD_ACTION_EVENT_DOWNLOAD, haveActionPopup };
};

export const selectAllImageAction = ({ arrIndex }: { arrIndex: number[] }) => {
    return { type: ResourceTypes.SELECT_ALL_IMAGES_WORKSHEET, arrIndex };
};
export const setAfterActionGridAction = ({
    afterAction,
}: {
    afterAction: string;
}) => {
    return { type: ResourceTypes.AFTER_ACTION, afterAction };
};

export const getResourceDefaultsAction = ({
    defaultCollections,
}: {
    defaultCollections: ICollection[];
}) => {
    return { type: ResourceTypes.GET_RESOURCE_DEFAULT };
};
export const previousActionResourceAction = ({
    previousAction,
}: {
    previousAction: string;
}) => {
    return { type: ResourceTypes.PREVIOUS_ACTION, previousAction };
};

export const getResourceDefaultSuccessAction = (
    defaultCollections: ICollection[]
) => {
    return {
        type: ResourceTypes.GET_RESOURCE_DEFAULT_SUCCESS,
        defaultCollections,
    };
};

export const getNumberWsResourceDefaultSuccessAction = (
    defaultCollections: ICollection[]
) => {
    return {
        type: ResourceTypes.GET_NUMBER_WS_RESOURCE_DEFAULT_SUCCESS,
        defaultCollections,
    };
};

export const selectCollectionResourceAction = ({
    collection,
    pageTypeResource,
    loading,
    notFetchData,
    projectData,
}: {
    collection: ICollection;
    pageTypeResource?: number;
    loading: boolean;
    notFetchData?: boolean;
    projectData?: any;
}) => {
    return {
        type: ResourceTypes.SELECT_COLLECTION_RESOURCE,
        collection,
        pageTypeResource,
        loading,
        notFetchData,
        projectData,
    };
};

export const updateCollectionAction = ({
    collection,
    loading,
    pageNumber,
    finishLoadMore,
}: {
    collection: ICollection;
    loading: boolean;
    pageNumber?: number;
    finishLoadMore?: boolean;
}) => {
    return {
        type: ResourceTypes.UPDATE_COLLECTION_RESOURCE,
        collection,
        loading,
        pageNumber,
        finishLoadMore,
    };
};

export const getListCollectionAction = ({
    userId,
    offset,
    projectData,
}: {
    userId: string;
    offset?: number;
    projectData?: any;
}) => {
    return {
        type: ResourceTypes.GET_LIST_COLLECTION_RESOURCE,
        userId,
        offset,
        projectData,
    };
};
export const getCollectionDefaultAction = ({
    userId,
    profileId,
}: {
    userId: string;
    profileId: string;
}) => {
    return {
        type: ResourceTypes.GET_DEFAULT_COLLECTION_RESOURCE,
        userId,
        profileId,
    };
};

export const updateListCollectionAction = ({
    collections,
    numberCollections,
    pageNumber,
}: {
    collections: ICollection[];
    numberCollections: number;
    pageNumber: number;
}) => {
    return {
        type: ResourceTypes.UPDATE_LIST_COLLECTION_SUCCESS_RESOURCE,
        collections,
        numberCollections,
        pageNumber,
    };
};

export const updateListCollectionPopupAction = ({
    collections,
}: {
    collections: ICollection[];
}) => {
    return {
        type: ResourceTypes.UPDATE_LIST_COLLECTION_POPUP_SUCCESS,
        collections,
    };
};

export const selectGridItemAction = ({
    collection,
    worksheet,
    toCollection,
    worksheets,
    afterAction,
}: {
    collection?: ICollection;
    worksheet?: IWorksheet;
    toCollection?: boolean;
    worksheets?: IWorksheet[];
    afterAction?: string;
}) => {
    return {
        type: ResourceTypes.SELECT_GRID_ITEM_RESOURCE,
        collection,
        worksheet,
        toCollection,
        worksheets,
        afterAction,
    };
};

export const selectActionGridItemAction = ({ action }: { action?: string }) => {
    return {
        type: ResourceTypes.SELECT_ACTION_GRID_ITEM_RESOURCE,
        action,
    };
};
export const updateItemCollectionResourceAction = (params: {
    name?: string;
    collectionId?: string;
    parentIds?: string[];
    worksheetItems?: IWorksheetItem[];
    sharingStatus?: number;
    introductionDescription?: string;
}): ICollectionAction => {
    return {
        type: ResourceTypes.UPDATE_ITEM_COLLECTION_RESOURCE,
        params,
    };
};

export const editCollectionResourceAction = (params: {
    name?: string;
    collectionId?: string;
    sharingStatus?: number;
    introductionDescription?: string;
}): ICollectionAction => {
    return {
        type: ResourceTypes.EDIT_COLLECTION_RESOURCE,
        params,
    };
};

export const editItemCollectionResourceSuccessAction = (params: {
    name?: string;
    collectionId?: string;
    sharingStatus?: Number;
    introductionDescription?: string;
}): ICollectionAction => {
    return {
        type: ResourceTypes.EDIT_COLLECTION_RESOURCE_SUCCESS,
        params,
    };
};

export const deleteCollectionResourceSuccessAction = (
    collectionId: string
): ICollectionAction => {
    return {
        type: ResourceTypes.REMOVE_COLLECTION_SUCCESS_RESOURCE,
        collectionId,
    };
};

export const deleteCollectionResourceAction = ({
    worksheet,
    collection,
}: {
    worksheet: IWorksheet;
    collection: ICollection;
}): ICollectionAction => {
    return {
        type: ResourceTypes.REMOVE_COLLECTION_RESOURCE,
        worksheet,
        collection,
    };
};

export const selectCollectionItemResourceAction = ({
    collection,
    collectionsState,
    arrayCollection,
    arrayThumbnail,
    numberSubCollections,
    isSelectCollection,
    isNextLevelCollection,
    listCollection,
}: {
    collection: ICollection;
    collectionsState: ICollection[];
    arrayCollection: ICollection[];
    arrayThumbnail: IWorksheet[];
    numberSubCollections?: number;
    isSelectCollection: ICollection;
    isNextLevelCollection?: boolean;
    listCollection?: ICollection[];
}): ICollectionAction => {
    return {
        type: ResourceTypes.SELECT_COLLECTION_ITEM_RESOURCE,
        collection,
        collectionsState,
        arrayCollection,
        arrayThumbnail,
        numberSubCollections,
        isSelectCollection,
        isNextLevelCollection,
        listCollection,
    };
};

export const moveCollectionResourceSuccessAction = (
    collection: ICollection,
    oldParentId: string
): ICollectionAction => {
    return {
        type: ResourceTypes.MOVE_COLLECTION_RESOURCE_SUCCESS,
        collection,
        oldParentId,
    };
};

export const updateCollectionResourceSuccessAction = (
    collection: ICollection
) => {
    return {
        type: ResourceTypes.UPDATE_COLLECTION_RESOURCE_SUCCESS,
        collection,
    };
};
export const createNewCollectionResourceSuccessAction = (
    collection: ICollection
): ICollectionAction => {
    return {
        type: ResourceTypes.CREATE_ITEM_COLLECTION_RESOURCE_SUCCESS,
        collection,
    };
};

export const moveWorksheetResourceSuccessAction = ({
    worksheet,
    collection,
}: {
    worksheet: IWorksheet;
    collection: ICollection;
}): ICollectionAction => {
    return {
        type: ResourceTypes.MOVE_WORKSHEET_TO_COLLECTION_RESOURCE_SUCCESS,
        worksheet,
        collection,
    };
};

export const updateNumberWorksheetOfCollectionAction = ({
    numberOfWs,
    collectionId,
}: {
    numberOfWs: number;
    collectionId: string;
}): ICollectionAction => {
    return {
        type: ResourceTypes.UPDATE_NUMBER_WORKSHEET_OF_COLLECTION,
        numberOfWs,
        collectionId,
    };
};

export const removeWorksheetOfCollectionAction = ({
    worksheet,
}: {
    worksheet: any;
}): ICollectionAction => {
    return {
        type: ResourceTypes.REMOVE_WORKSHEET_OF_COLLECTION,
        worksheet,
    };
};

export const getDataCollectionAction = ({
    collection,
    userId,
    arrayCollection,
    arrayThumbnail,
    loading,
    isNextLevelCollection,
    listCollection,
}: {
    collection: ICollection;
    userId: string;
    arrayCollection?: ICollection[];
    arrayThumbnail?: IWorksheet[];
    loading?: boolean;
    isNextLevelCollection?: boolean;
    listCollection?: ICollection[];
}): ICollectionAction => {
    return {
        type: ResourceTypes.GET_DATA_COLLECTION_RESOURCE_V2,
        collection,
        userId,
        arrayCollection,
        arrayThumbnail,
        loading,
        isNextLevelCollection,
        listCollection,
    };
};

export const moveCollectionResourceAction = (
    collection: ICollection,
    toCollection: ICollection
): ICollectionAction => {
    return {
        type: ResourceTypes.MOVE_COLLECTION_RESOURCE,
        collection,
        toCollection,
    };
};

export const loadMoreWorksheetResourceAction = (
    collection: ICollection
): ICollectionAction => {
    return {
        type: ResourceTypes.LOAD_MORE_WORKSHEET_RESOURCE,
        collection,
    };
};

export const setLoadingMoreWsResourceAction = (
    loading: boolean
): ICollectionAction => {
    return {
        type: ResourceTypes.SET_LOADING_MORE_WORKSHEET_RESOURCE,
        loading,
    };
};

export const updateAlertResourceAction = (
    status?: boolean,
    content?: string,
    code?: number,
    typeAlert?: string,
    thumbnail?: string,
    message?: string
): ICollectionAction => {
    return {
        type: ResourceTypes.UPDATE_STATUS_ALERT_RESOURCE,
        status,
        content,
        code,
        typeAlert,
        thumbnail,
        message,
    };
};
export const updateStatusAddedResourceAction = ({
    added,
}: {
    added: boolean;
}): ICollectionAction => {
    return {
        type: ResourceTypes.UPDATE_ALERT_RESOURCE,
        added,
    };
};

export const updateCollectionItemResourceAction = ({
    collection,
    loading,
    pageNumber,
    finishLoadMore,
}: {
    collection: ICollection;
    loading: boolean;
    pageNumber?: number;
    finishLoadMore?: boolean;
}) => {
    return {
        type: ResourceTypes.UPDATE_COLLECTION_ITEM_RESOURCE,
        collection,
        loading,
        pageNumber,
        finishLoadMore,
    };
};

export const deleteWorksheetFromCollectionResourceAction = ({
    collection,
    worksheets,
}: {
    collection: ICollection;
    worksheets: IWorksheet[];
}) => {
    return {
        type: ResourceTypes.DELETE_WORKSHEET_FROM_COLLECTION,
        collection,
        worksheets,
    };
};

export const deleteWorksheetFromCollectionResourceSuccessAction = ({
    collection,
}: {
    collection: ICollection;
}) => {
    return {
        type: ResourceTypes.DELETE_WORKSHEET_FROM_COLLECTION_SUCCESS,
        collection,
    };
};

export const deleteWorksheetFromCreatedOrDownloadedResourceAction = ({
    collection,
    worksheets,
}: {
    collection: ICollection;
    worksheets: IWorksheet[];
}) => {
    return {
        type: ResourceTypes.DELETE_WORKSHEET_FROM_CREATED_OR_DOWNLOADED_WORKSHEET,
        collection,
        worksheets,
    };
};

export const addWorksheetToCollectionAction = ({
    collection,
    worksheet,
}: {
    collection: ICollection;
    worksheet: IWorksheet;
}) => {
    return {
        type: ResourceTypes.ADD_WORKSHEET_TO_COLLECTION,
        collection,
        worksheet,
    };
};

export const addWorksheetToDownloadedAction = ({
    userId,
    worksheetId,
}: {
    userId: string;
    worksheetId: string;
}) => {
    return {
        type: ResourceTypes.ADD_WORKSHEET_TO_DOWNLOADED,
        userId,
        worksheetId,
    };
};

export const deleteWorksheetFromDownloadedAction = ({
    userId,
    worksheetIds,
}: {
    userId: string;
    worksheetIds: string[];
}) => {
    return {
        type: ResourceTypes.DELETE_WORKSHEET_FROM_DOWNLOADED,
        userId,
        worksheetIds,
    };
};

export const updateLoadingTabInfo = ({
    loadingTabInfo,
}: {
    loadingTabInfo: boolean;
}) => {
    return {
        type: ResourceTypes.UPDATE_LOADING_TAB_INFO,
        loadingTabInfo,
    };
};

export const updateActionsGaSuccessAction = ({
    eventsGa,
}: {
    eventsGa: any[];
}) => {
    return {
        type: ResourceTypes.UPDATE_ACTIONS_GA_AFTER_SUCCESS,
        eventsGa,
    };
};

export const sendEventGaAction = () => {
    return {
        type: ResourceTypes.SEND_EVENT_GA,
    };
};

export const addImageWorksheetOfCollection = ({ collectionId }) => {
    return {
        type: ResourceTypes.ADD_IMAGE_WORKSHEET_OF_COLLECTION,
        collectionId,
    };
};

export const addImageWorksheetOfCollectionSuccess = ({
    worksheets,
    collectionId,
}) => {
    return {
        type: ResourceTypes.ADD_IMAGE_WORKSHEET_OF_COLLECTION_SUCCESS,
        worksheets,
        collectionId,
    };
};
