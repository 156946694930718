import React, { useEffect, useRef, useState } from "react";
import ToolTipCustomize from "../../../../resource-user/components/common/library/tool-tip";
import { ConstantsApp } from "../../../../utils/Constants";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";
import PopupModal from "../../../../resource-user/components/common/pop-up/PopupModal";
import MakeStrokeDasharrayView from "../common/makeStrokeDasharray";
import SliderInput from "../common/sliderInput";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import {
    changeBorderWidthTable,
    changeStrokeDasharrayTable,
} from "../../../../utils/table";
import { CellTable } from "../../../../shared/models/tableAttribute";
import { useAppDispatch } from "../../../../redux/hook";
import { updateResourceItems } from "../../../../redux/reducers/createWorksheet";

function BorderStyleTableBar({
    currentElement,
    resoureItems,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    resoureItems: IResourceItemNew[];
    setCurrentElement: (currentElement: IResourceItemNew) => void;
}) {
    const dispatch = useAppDispatch();
    const [isShow, setIsShow] = useState(false);
    const [borderWidth, setBorderWidth] = useState(0);
    const [strokeDasharray, setStrokeDasharray] = useState(0);
    const ref = useRef(null);
    const min = 0,
        max = 10;

    useEffect(() => {
        initState();
    }, [currentElement?.id, currentElement?.tableAttribute?.currentPositions]);

    const initState = () => {
        let table = currentElement.tableAttribute;
        let data = table.data;
        let positions = table?.currentPositions;

        const getStrokeDasharray = (cell: CellTable) => {
            const { strokeDasharrays, strokeDasharray } = cell;
            if (table.gap > 0) return strokeDasharray;
            let newStrokeDasharray = 0;
            if (strokeDasharrays.length) {
                newStrokeDasharray = strokeDasharrays[0];
                for (let value of strokeDasharrays) {
                    if (value !== newStrokeDasharray) {
                        newStrokeDasharray = null;
                        break;
                    }
                }
            }
            return newStrokeDasharray;
        };
        const getBoderWidth = (row: number, col: number) => {
            let { borderWidth, zIndex } = data[row][col];
            const currentZIndex = zIndex;
            const check = (cell: CellTable, index: number) => {
                let zIndex = cell.zIndex;
                return (
                    (zIndex > currentZIndex ||
                        (zIndex === currentZIndex && index % 2 !== 0)) &&
                    cell.borderWidth != borderWidth
                );
            };

            if (table.gap === 0) {
                if (row > 0) {
                    if (check(data[row - 1][col], row)) return null;
                }
                if (row < data.length - 1) {
                    if (check(data[row + 1][col], row)) return null;
                }
                if (col > 0) {
                    if (check(data[row][col - 1], col)) return null;
                }
                if (col < data[0].length - 1) {
                    if (check(data[row][col + 1], col)) return null;
                }
            }
            return borderWidth;
        };

        const setState = (
            cells: { cell: CellTable; row: number; col: number }[]
        ) => {
            let value = cells[0];
            let cell = cells[0].cell;
            let borderWidth = getBoderWidth(value.row, value.col);
            let strokeDasharray = getStrokeDasharray(cell);
            cells.forEach((value) => {
                let cell = value.cell;
                if (getStrokeDasharray(cell) !== strokeDasharray)
                    strokeDasharray = null;
                if (getBoderWidth(value.row, value.col) !== borderWidth)
                    borderWidth = null;
            });
            setBorderWidth(borderWidth);
            setStrokeDasharray(strokeDasharray);
        };

        if (positions && positions.length) {
            let cells = positions.map((e) => {
                return {
                    cell: data[e.row][e.column],
                    row: e.row,
                    col: e.column,
                };
            });
            setState(cells);
        } else {
            let cells = [];
            data.forEach((rows, row) => {
                rows.forEach((cell, col) => {
                    cells.push({ cell, row, col });
                });
            });
            setState(cells);
        }
    };

    const updateResource = (resourceItem: IResourceItemNew) => {
        dispatch(
            updateResourceItems({
                pageIndex: resourceItem.pageIndex,
                resourceItems: [resourceItem],
            })
        );
    };

    const onStrokeDasharray = (value: number) => {
        setStrokeDasharray(value);
        let tableAttribute = changeStrokeDasharrayTable(
            value,
            currentElement.tableAttribute
        );
        let resourceItem = { ...currentElement, tableAttribute };
        if (borderWidth === 0) {
            resourceItem = onBorderWidth(1, null, resourceItem);
        }
        setCurrentElement(resourceItem);
        updateResource(resourceItem);
    };

    const onStrokeDasharrayNone = () => {
        onStrokeDasharray(0);
        onBorderWidth(0, "input");
    };

    const onBorderWidth = (
        value: number,
        type?: "input",
        resourceItem?: IResourceItemNew
    ) => {
        resourceItem = resourceItem ?? currentElement;
        if (value >= min && value <= max) {
            setBorderWidth(value);
            let result = changeBorderWidthTable(
                value,
                resourceItem.tableAttribute
            );
            let newResourceItem = {
                ...currentElement,
                tableAttribute: result.tableAttribute,
                height: resourceItem.height + result.dHeight,
            };
            setCurrentElement({ ...newResourceItem });
            if (type === "input") updateResource(newResourceItem);
            return newResourceItem;
        }
    };

    return (
        <React.Fragment>
            <div
                style={{
                    margin: "0px 10px",
                }}
            >
                <ToolTipCustomize
                    title={"Border style"}
                    style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
                >
                    <div
                        ref={ref}
                        className="button-bar-container"
                        onClick={() => setIsShow(!isShow)}
                    >
                        <img src={svgPath(IconSVG.iconBoder)} />
                    </div>
                </ToolTipCustomize>
            </div>
            <PopupModal
                open={isShow}
                anchorElement={ref.current}
                onClose={function (): void {
                    setIsShow(false);
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                top={4}
                left={-2}
            >
                <div className="popup-edit-bar-container">
                    <MakeStrokeDasharrayView
                        value={strokeDasharray}
                        onClick={function (value: number): void {
                            onStrokeDasharray(value);
                        }}
                        onClickNone={onStrokeDasharrayNone}
                        isNone={borderWidth === 0 && strokeDasharray !== null}
                        isDisplayNone={true}
                    />
                    <SliderInput
                        label={"Border weight"}
                        value={borderWidth}
                        min={0}
                        max={10}
                        onChangeInput={function (value: number): void {
                            onBorderWidth(value, "input");
                        }}
                        onChangeSlider={function (value: number): void {
                            onBorderWidth(value);
                        }}
                        onChangeCommitted={() => {
                            updateResource(currentElement);
                        }}
                    />
                </div>
            </PopupModal>
        </React.Fragment>
    );
}

export default BorderStyleTableBar;
