import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import {
    DirectionTable,
    TableAttribute,
} from "../../../../../../shared/models/tableAttribute";
import tableStore from "../../../../../../syncExternalStore/tableStore";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";
import { useAppDispatch } from "../../../../../../redux/hook";
import {
    removeResourceItems,
    updateResourceItems,
} from "../../../../../../redux/reducers/createWorksheet";
import PopupModal from "../../../../../../resource-user/components/common/pop-up/PopupModal";
import { deleteCells } from "../../../../../../utils/table";
import { EventTracking, sendEvent } from "../../../../../../resource-user/utils/event";
enum TypeDeleteTable {
    column,
    row,
    table,
}
function PopupDeleteTable({
    open,
    setOpen,
    currentElement,
    setCurrentElement,
    anchorElement,
}: {
    open: boolean;
    setOpen: any;
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    anchorElement: HTMLDivElement;
}) {
    const tableAttribute = new TableAttribute(currentElement.tableAttribute);
    const rows = tableAttribute.getRows();
    const cols = tableAttribute.getColumns();
    const dispatch = useAppDispatch();

    const onAction = (typeAction: any) => {
        let dHeight = 0,
            dWidth = 0,
            value;
        let { newResourceItem, isDeleteTable } = {
            newResourceItem: { ...currentElement },
            isDeleteTable: false,
        };

        switch (typeAction) {
            case TypeDeleteTable.row:
                value = deleteCells(DirectionTable.row,tableAttribute);
                isDeleteTable = value.isDeleteTable;
                dHeight = value.dHeight;
                sendEvent(EventTracking.ncw_delete_row)
                break;

            case TypeDeleteTable.column:
                value = deleteCells(DirectionTable.column,tableAttribute);
                isDeleteTable = value.isDeleteTable;
                dWidth = value.dWidth;
                sendEvent(EventTracking.ncw_delete_column)
                break;
            case TypeDeleteTable.table:
                isDeleteTable = true;
                break;
        }

        newResourceItem = {
            ...currentElement,
            tableAttribute,
            width: currentElement.width + dWidth,
            height: currentElement.height + dHeight,
        };
        if (isDeleteTable) {
            dispatch(
                removeResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceIds: [currentElement.id],
                })
            );
            setCurrentElement(null);
        } else {
            setCurrentElement(newResourceItem);
            dispatch(
                updateResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceItems: [newResourceItem],
                })
            );
        }
        setOpen(false);
        onMouseLeave();
    };

    const onMouseEnter = (typeAction: any) => {
        if (typeAction === TypeDeleteTable.row) {
            tableStore.updatePosition([
                { row: rows[0].row, col: 0 },
                {
                    row: rows[rows.length - 1].row,
                    col: tableAttribute.data[0].length - 1,
                },
            ]);
        }
        if (typeAction === TypeDeleteTable.column) {
            tableStore.updatePosition([
                { row: 0, col: cols[0].col },
                {
                    row: tableAttribute.data.length - 1,
                    col: cols[cols.length - 1].col,
                },
            ]);
        }
    };

    const onMouseLeave = () => {
        tableStore.resetPosition();
    };

    return (
        <PopupModal
            open={open}
            anchorElement={anchorElement}
            onClose={function (): void {
                setOpen(false);
            }}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            top={8}
            left={-70}
        >
            <div className="popup-edit-table-container">
                {[
                    TypeDeleteTable.column,
                    TypeDeleteTable.row,
                    TypeDeleteTable.table,
                ].map((key, index) => {
                    let value = "";
                    let icon = IconSVG.delete;
                    switch (key) {
                        case TypeDeleteTable.row:
                            if (rows.length === tableAttribute.data.length) {
                                return null;
                            }
                            if (rows.length > 1) {
                                value = "Delete " + rows.length + " rows";
                            } else {
                                value = "Delete row";
                            }
                            break;
                        case TypeDeleteTable.column:
                            if (cols.length === tableAttribute.data[0].length) {
                                return null;
                            }
                            if (cols.length > 1) {
                                value = "Delete " + cols.length + " columns";
                            } else {
                                value = "Delete column";
                            }
                            break;
                        case TypeDeleteTable.table:
                            value = "Delete table";
                            icon = IconSVG.delete_table;
                            break;
                    }

                    return (
                        <div
                            key={value}
                            className="row-item"
                            onClick={() => {
                                onAction(key);
                            }}
                            onMouseEnter={() => onMouseEnter(key)}
                            onMouseLeave={onMouseLeave}
                        >
                            <div className="icon">
                                <img src={svgPath(icon)} />
                            </div>
                            <div className="lable">{value}</div>
                        </div>
                    );
                })}
            </div>
        </PopupModal>
    );
}

export default PopupDeleteTable;
