import { ANSWER_TYPE, Answer, IOffset } from "../../../shared/models/answer";
import { Game, IGame } from "../../../shared/models/game";
import {
    DrawData,
    IDrawData,
    IPracticeWorksheet,
    PracticeWorksheet,
} from "../../../shared/models/practiceWorksheets";
import {
    IUserAssignment,
    UserAssignment,
} from "../../../shared/models/userAssignment";
import { IWorksheet, Worksheet } from "../../../shared/models/worksheet";
import { stringifyAndParse } from "../../../shared/utils";
import Config from "../../../shared/utils/config";
import * as ga from "../../lib/ga";
import { getCanvasWidth, isProdWeb, updateDrawDataByRatio } from "../../utils";
import { GameTypes } from "../action/game.action";
import ConstantsResource from "../../utils/ConstantsResource";

export interface IGameScore {
    totalDone: number;
    totalAnswer: number;
    totalCorrect: number;
}

export interface ITextDrawData {
    textColor?: string;
    textSize?: number;
    isBold?: boolean;
    isItalic?: boolean;
}
export interface IDrawOption {
    drawType?: string;
    brushSize?: number;
    strokeColor?: string;
    eraserSize?: number;
    textData?: ITextDrawData;
}

export interface IPlayWorksheetInfo {
    workSheetId?: string;
    projectName?: string;
    classId?: string;
    studentId?: string;
    role?: number;
    assignmentId: string;
    studentInfo?: any;
}

export interface GameResourceState {
    worksheet: IWorksheet;
    practiceWorksheet: IPracticeWorksheet;
    game: IGame;
    loading: boolean;
    initAnswer: Answer[];
    answers: Answer[];
    isFinish: boolean;
    offsets: IOffset[];
    score: number;
    totalDone: number;
    isDisplayPinterestPopup: boolean;
    isPlayWorksheet: boolean;
    dataDraw: IDrawData;
    showAnswer: boolean;
    loadingGameImage: boolean;
    startAnswer: boolean;
    assignmentId: string;
    isDisplayModalInteractive: boolean;
    drawOption: IDrawOption;
    isDisplayInstruction: boolean;
    playWorksheetInfo: IPlayWorksheetInfo;
    userAssignmentOfStudent: IUserAssignment;
    selectedTextIndex: number;
    selectedLineIndex: number;
    isOnEditText: boolean;
    isSubmited: boolean;
    currentPage: number;
    themes: {
        listTheme?: string[];
        indexSelected?: number;
    };
    currentShapeAnswer: Answer;
}

const initialState: GameResourceState = {
    game: new Game(),
    loading: false,
    worksheet: new Worksheet(),
    practiceWorksheet: new PracticeWorksheet(),
    answers: [],
    initAnswer: [],
    isFinish: false,
    offsets: [],
    score: 0,
    totalDone: 0,
    isDisplayPinterestPopup: false,
    isPlayWorksheet: false,
    showAnswer: false,
    dataDraw: new DrawData(),
    loadingGameImage: false,
    startAnswer: false,
    assignmentId: "",
    isDisplayModalInteractive: false,
    drawOption: {
        drawType: "",
        brushSize: 8,
        strokeColor: "#000000",
        eraserSize: 5,
        textData: {
            textColor: "#000000",
            textSize: 16,
            isBold: false,
            isItalic: false,
        },
    },
    isDisplayInstruction: false,
    playWorksheetInfo: {
        workSheetId: "",
        projectName: "",
        classId: "",
        role: 2,
        assignmentId: "",
        studentInfo: null,
    },
    userAssignmentOfStudent: new UserAssignment(),
    selectedTextIndex: null,
    selectedLineIndex: null,
    isOnEditText: false,
    isSubmited: false,
    currentPage: 0,
    themes: {},
    currentShapeAnswer: null,
};

function gameResourceState(
    state: GameResourceState = initialState,
    action: any
): GameResourceState {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GameTypes.INIT_THEMES: {
            return {
                ...state,
                themes: {
                    indexSelected: action?.indexSelected ?? 0,
                    listTheme: action.listTheme ?? [],
                },
            };
        }
        case GameTypes.SELECT_THEME_ACTION: {
            return {
                ...state,
                themes: {
                    ...state.themes,
                    indexSelected: action?.indexSelected,
                },
            };
        }
        case GameTypes.GAME_INIT_GAME_SUCCESS:
            let worksheet,
                answers,
                isFinish,
                score,
                practiceWorksheet,
                dataDraw,
                startAnswer;
            let assignmentId = state.assignmentId;

            if (action.worksheet) {
                worksheet = stringifyAndParse(action.worksheet);
                if (!worksheet.gameSubmitted) {
                    worksheet.gameSubmitted = new Game();
                }
            }
            if (action.answers) {
                answers = stringifyAndParse(action.answers);
            }
            if (action.isFinish || action.isFinish === false) {
                isFinish = action.isFinish;
            }
            if (action.score || action.score === 0) {
                score = stringifyAndParse(action.score);
            }
            if (action.practiceWorksheet) {
                practiceWorksheet = stringifyAndParse(action.practiceWorksheet);
            } else {
                practiceWorksheet = new PracticeWorksheet();
            }
            if (action.dataDraw) {
                dataDraw = stringifyAndParse(action.dataDraw);
            }
            if (action.startAnswer) {
                startAnswer = stringifyAndParse(action.startAnswer);
            }
            if (action?.assignmentId) {
                assignmentId = stringifyAndParse(action.assignmentId);
            }

            return {
                ...state,
                loading: false,
                showAnswer: false,
                worksheet,
                answers,
                isFinish,
                score,
                practiceWorksheet,
                dataDraw,
                startAnswer,
                assignmentId,
            };
        case GameTypes.GET_GAME_INFO_SUCCESS:
            return {
                ...newState,
                game: action.game ? { ...action.game } : new Game(),
            };
        case GameTypes.UPDATE_ANSWER:
            if (action.answer) {
                for (let i = 0; i < newState.answers.length; i++) {
                    if (
                        newState.answers[i].matchingId ==
                        action.answer.matchingId
                    ) {
                        newState.answers[i].matchingId =
                            ConstantsResource.DEFAULT_MATCHINGid;
                    }
                }
                let index = newState.answers.findIndex(
                    (answer) => answer.answerId == action.answer?.answerId
                );
                newState.answers[index] = new Answer(action.answer);
            }

            return {
                ...newState,
            };
        case GameTypes.UPDATE_ANSWER_FOCUS:
            if (action.answer) {
                let mainCategoryId = null;
                let lastCategoryId = null;
                if (newState.worksheet.categoryIds?.length) {
                    mainCategoryId = newState.worksheet.categoryIds[0] ?? null;
                    lastCategoryId =
                        newState.worksheet.categoryIds[
                            newState.worksheet.categoryIds.length - 1
                        ] ?? null;
                }

                let index = newState.answers.findIndex(
                    (answer) => answer.answerId == action.answer?.answerId
                );
                newState.answers[index] = new Answer(action.answer);
            }

            return {
                ...newState,
            };
        case GameTypes.FINISH_GAME_SUCCESS:
            return {
                ...newState,
                isFinish: true,
                score: action.score ? action.score : 0,
                totalDone: action.totalDone ?? 0,
                answers: action.answers ?? [],
            };
        case GameTypes.RESET_GAME_SUCCESS:
            return {
                ...newState,
                isFinish: false,
                score: 0,
                totalDone: 0,
                answers: [],
                practiceWorksheet: new PracticeWorksheet({}),
                dataDraw: new DrawData(),
                // studentId:
                //     state.playWorksheetInfo?.projectName ==
                //     Config.PROJECT_CLASSROOM
                //         ? state.studentId
                //         : "",
                startAnswer: false,
            };
        case GameTypes.UPDATE_USER_ANSWER:
            let updateSelectGameAns = newState.answers.map((ans) => {
                if (ans.answerId == action.answerId) {
                    return { ...ans, userAnswer: action.userAnswer };
                }
                return ans;
            });
            return {
                ...newState,
                answers: [...updateSelectGameAns],
            };
        case GameTypes.UPDATE_MATCHINGid_AND_POINTS:
            let updateJoinGame = newState.answers.map((ans) => {
                if (ans.answerId.toString() === action.listAnswerId![0]) {
                    // let realPoint = new Offset({
                    //     offsetX: action.listPoints[0]?.offsetX / action.ratio,
                    //     offsetY: action.listPoints[0]?.offsetY / action.ratio,
                    // });
                    return new Answer({
                        ...ans,
                        matchingId: parseInt(action.listAnswerId![1]),
                        point: action.listPoints![0],
                        // realPoint,
                    });
                }
                if (ans.answerId.toString() === action.listAnswerId![1]) {
                    // let realPoint = new Offset({
                    //     offsetX: action.listPoints[1]?.offsetX / action.ratio,
                    //     offsetY: action.listPoints[1]?.offsetY / action.ratio,
                    // });
                    return new Answer({
                        ...ans,
                        matchingId: parseInt(action.listAnswerId![0]),
                        point: action.listPoints![1],
                        // realPoint,
                    });
                }
                return { ...ans };
            });
            return {
                ...newState,
                answers: [...updateJoinGame],
            };
        case GameTypes.UPDATE_DISPLAY_POPUP_STATUS:
            return {
                ...newState,
                isDisplayPinterestPopup: action.isDisplayPinterestPopup,
            };
        case GameTypes.PLAY_WORKSHEET:
            return {
                ...newState,
                isPlayWorksheet: action.isPlayWorksheet,
                dataDraw: new DrawData(),
            };

        case GameTypes.PAINTING:
            for (let dataAction of action.coordsArr) {
                let findCoordsData = newState.dataDraw.coordsDraw.find(
                    (i) =>
                        i.color == dataAction.color &&
                        i.strokeWidth == dataAction.strokeWidth &&
                        i.type == dataAction.type &&
                        i.index == dataAction.index
                );
                if (findCoordsData) {
                    let coords = findCoordsData.coords.find(
                        (e) => e.x == dataAction.x && e.y == dataAction.y
                    );
                    if (!!!coords) {
                        findCoordsData.coords.push({
                            x: dataAction.x,
                            y: dataAction.y,
                            moveX: dataAction.moveX,
                            moveY: dataAction.moveY,
                        });
                    }
                } else {
                    newState.dataDraw.coordsDraw.push({
                        strokeWidth: dataAction.strokeWidth,
                        color: dataAction.color,
                        type: dataAction.type,
                        index: dataAction.index,
                        textValue: dataAction.textValue,
                        textColor: dataAction.textColor,
                        coords: [
                            {
                                x: dataAction.x,
                                y: dataAction.y,
                                moveX: dataAction.moveX,
                                moveY: dataAction.moveY,
                            },
                        ],
                        isBold: dataAction.isBold,
                        isItalic: dataAction.isItalic,
                        width: dataAction.width,
                    });
                }
            }
            if (
                action.coordsArr.length > 0 &&
                newState.dataDraw.coordsDrawHistory
            ) {
                if (newState.dataDraw.historyIndex !== 0) {
                    newState.dataDraw.coordsDrawHistory.splice(
                        0,
                        newState.dataDraw.historyIndex
                    );
                    newState.dataDraw.historyIndex = 0;
                }
                if (newState.dataDraw.coordsDrawHistory?.length < 100) {
                    newState.dataDraw.coordsDrawHistory.unshift(
                        newState.dataDraw.coordsDraw
                    );
                } else {
                    newState.dataDraw.coordsDrawHistory.splice(
                        newState.dataDraw.coordsDrawHistory?.length - 1,
                        1,
                        newState.dataDraw.coordsDraw
                    );
                    newState.dataDraw.coordsDrawHistory.unshift(
                        newState.dataDraw.coordsDraw
                    );
                }
            }

            return {
                ...newState,
            };
        case GameTypes.SET_DATA_DRAW:
            return {
                ...newState,
                dataDraw: {
                    ...newState.dataDraw,
                    coordsDraw: action.coordsDraw,
                    coordsDrawHistory: action.coordsDrawHistory,
                },
            };
        case GameTypes.UNDO_DATA_DRAW:
            let currentHistoryIndex = newState.dataDraw.historyIndex;
            let historyDrawData = newState.dataDraw.coordsDrawHistory;
            let newHistoryIndex = currentHistoryIndex;
            if (currentHistoryIndex < historyDrawData.length - 1) {
                newHistoryIndex = currentHistoryIndex + 1;
            } else {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                dataDraw: {
                    ...newState.dataDraw,
                    coordsDraw: historyDrawData[newHistoryIndex],
                    historyIndex: newHistoryIndex,
                },
            };
        case GameTypes.REDO_DATA_DRAW:
            let currentHistoryRedoIndex = newState.dataDraw.historyIndex;
            let historyDrawRedoData = newState.dataDraw.coordsDrawHistory;
            let newHistoryRedoIndex = currentHistoryRedoIndex;
            if (currentHistoryRedoIndex > 0) {
                newHistoryRedoIndex = currentHistoryRedoIndex - 1;
            } else {
                return {
                    ...state,
                };
            }
            return {
                ...newState,
                dataDraw: {
                    ...newState.dataDraw,
                    coordsDraw: historyDrawRedoData[newHistoryRedoIndex],
                    historyIndex: newHistoryRedoIndex,
                },
            };
        case GameTypes.SET_SCREEN_SIZE_DRAW:
            return {
                ...newState,
                dataDraw: {
                    ...newState.dataDraw,
                    screenSize: action.screenSize,
                },
            };
        // case HomeTypes.SELECT_WORKSHEET_OR_CATEGORY_SUCCESS:
        //     return {
        //        ...newState,
        //         game: new Game(),
        //         isFinish: false,
        //         isPlayWorksheet: false,
        //     };
        case GameTypes.UPDATE_USER_PRACTICE_WORKSHEET_V2:
            if (action.practiceWorksheet) {
                newState.practiceWorksheet = action.practiceWorksheet;
            }
            return { ...newState };
        case GameTypes.USER_SEND_RESULT_FOR_TEACHER:
            if (action.practiceWorksheet) {
                newState.practiceWorksheet = action.practiceWorksheet;
            }
            return { ...newState };
        case GameTypes.SHOW_ANSWER:
            return {
                ...newState,
                showAnswer: action.showAnswer,
            };
        // case GameTypes.SET_STUDENTid:
        //     return {
        //         ...state,
        //         // studentId: action.studentId,
        //         playWorksheetInfo: {
        //             ...state.playWorksheetInfo,
        //             // studentId: action.studentId,
        //         },
        //     };
        case GameTypes.SET_LOADING_GAME_IMAGE:
            return {
                ...newState,
                loadingGameImage: action.loadingGameImage,
            };
        case GameTypes.SET_START_ANSWER:
            if (isProdWeb()) {
                ga.gtag_play_worksheet();
            }
            return {
                ...newState,
                startAnswer: action.startAnswer,
            };
        case GameTypes.SET_ASSIGNMENTid:
            return {
                ...newState,
                assignmentId: action.assignmentId,
            };
        case GameTypes.SET_IS_DISPLAY_MODAL_INTERACTIVE: {
            if (!action.isDisplayModalInteractive) {
                return {
                    ...initialState,
                    themes: state.themes,
                    isDisplayModalInteractive: action.isDisplayModalInteractive,
                };
            }
            return {
                ...newState,
                isDisplayModalInteractive: action.isDisplayModalInteractive,
                playWorksheetInfo: action.playWorksheetInfo
                    ? action.playWorksheetInfo
                    : state.playWorksheetInfo,
                worksheet: action.isDisplayModalInteractive
                    ? state.worksheet
                    : new Worksheet(),
                isSubmited: false,
            };
        }

        case GameTypes.INIT_PLAY_WS_MODULE: {
            return {
                ...newState,
                worksheet: action?.keepWorksheet
                    ? state.worksheet
                    : new Worksheet(),
                loading: action?.keepWorksheet ? true : state.loading,
            };
        }
        case GameTypes.UPDATE_DRAW_OPTION: {
            return {
                ...newState,
                drawOption: {
                    drawType:
                        action.drawOption?.drawType ??
                        newState.drawOption?.drawType,
                    brushSize:
                        action.drawOption?.brushSize ??
                        newState.drawOption?.brushSize,

                    strokeColor:
                        action.drawOption?.strokeColor ??
                        newState.drawOption?.strokeColor,
                    eraserSize:
                        action.drawOption.eraserSize ??
                        newState.drawOption.eraserSize,
                    textData:
                        action.drawOption?.textData ??
                        newState.drawOption?.textData,
                },
            };
        }
        case GameTypes.UPDATE_IS_DISPLAY_INSTRUCTION: {
            return {
                ...newState,
                isDisplayInstruction: action.isDisplayInstruction,
            };
        }

        case GameTypes.UPDATE_DRAW_BY_CANVAS_RATIO: {
            let drawData = newState.dataDraw;
            let canvasDrawRatio = getCanvasWidth() / Config.BASE_CANVAS_WIDTH;
            let newDrawData = updateDrawDataByRatio(canvasDrawRatio, drawData);
            newState.drawData = newDrawData;
            newState.practiceWorksheet.drawData = newDrawData;

            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_TEXT_VALUE: {
            let newCoordsDraw = JSON.parse(
                JSON.stringify(newState.dataDraw.coordsDraw)
            );
            for (let draw of newCoordsDraw) {
                if (draw.index == action.index || draw.id === action.index) {
                    draw.textValue = action.textData.value ?? draw.textValue;
                    draw.strokeWidth =
                        action.textData.strokeWidth ?? draw.strokeWidth;
                    draw.textColor =
                        action.textData.textColor ?? draw.textColor;
                    draw.isBold = action.textData.isBold ?? draw.isBold;
                    draw.isItalic = action.textData.isItalic ?? draw.isItalic;

                    if (
                        draw.coords.length &&
                        (action.textData.top || action.textData.left)
                    ) {
                        draw.coords[0].x =
                            action.textData.left ?? draw.coords[0].x;
                        draw.coords[0].y =
                            action.textData.top ?? draw.coords[0].y;
                    }
                    draw.width = action.textData.width
                        ? action.textData.width
                        : draw.width;
                }
            }
            newState.dataDraw.coordsDraw = newCoordsDraw;
            if (newState.dataDraw.historyIndex !== 0) {
                newState.dataDraw.coordsDrawHistory.splice(
                    0,
                    newState.dataDraw.historyIndex
                );
                newState.dataDraw.historyIndex = 0;
            }
            if (newState.dataDraw.coordsDrawHistory.length < 100) {
                newState.dataDraw.coordsDrawHistory.unshift(newCoordsDraw);
            } else {
                newState.dataDraw.coordsDrawHistory.splice(
                    newState.dataDraw.coordsDrawHistory.length - 1,
                    1,
                    newCoordsDraw
                );
                newState.dataDraw.coordsDrawHistory.unshift(newCoordsDraw);
            }

            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_SELECTED_TEXT_INDEX: {
            newState.selectedTextIndex = action.index;

            return {
                ...newState,
            };
        }

        case GameTypes.DELETE_TEXT_DRAW: {
            let newCoordsDraw = JSON.parse(
                JSON.stringify(newState.dataDraw.coordsDraw)
            );

            if (action.currentIndex) {
                newCoordsDraw = newCoordsDraw.filter((draw) => {
                    return (
                        draw.index !== action.currentIndex ||
                        draw.type != Config.DRAW_TEXT
                    );
                });
            } else {
                newCoordsDraw = newCoordsDraw.filter((draw) => {
                    return (
                        draw.index !== newState.selectedTextIndex ||
                        draw.type != Config.DRAW_TEXT
                    );
                });
                newState.selectedTextIndex = null;
            }
            newState.dataDraw.coordsDraw = newCoordsDraw;
            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_IS_ON_EDIT_TEXT: {
            newState.isOnEditText = action.isOnEditText;
            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_SELECTED_LINE_INDEX: {
            newState.selectedLineIndex = action.index;

            return {
                ...newState,
            };
        }

        case GameTypes.DELETE_LINE_DRAW: {
            let newCoordsDraw = JSON.parse(
                JSON.stringify(newState.dataDraw.coordsDraw)
            );
            newCoordsDraw = newCoordsDraw.filter((draw) => {
                return (
                    (draw.index !== newState.selectedLineIndex &&
                        draw.id !== newState.selectedLineIndex) ||
                    draw.type !== Config.DRAW_LINE
                );
            });
            newState.dataDraw.coordsDraw = newCoordsDraw;
            newState.selectedLineIndex = null;
            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_LINE_DRAW: {
            let newCoordsDraw = JSON.parse(
                JSON.stringify(newState.dataDraw.coordsDraw)
            );
            newCoordsDraw.forEach((drawData, index) => {
                if (
                    drawData.type === Config.DRAW_LINE &&
                    (drawData.id === newState.selectedLineIndex ||
                        drawData.index === newState.selectedLineIndex)
                ) {
                    newCoordsDraw[index].coords[0].x = action.lineData.x;
                    newCoordsDraw[index].coords[0].y = action.lineData.y;
                    newCoordsDraw[index].coords[0].moveX =
                        action.lineData.moveX;
                    newCoordsDraw[index].coords[0].moveY =
                        action.lineData.moveY;
                }
            });
            newState.dataDraw.coordsDraw = newCoordsDraw;
            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_SUBMITED_STATUS: {
            newState.isSubmited = action.isSubmited;
            return {
                ...newState,
            };
        }

        case GameTypes.UPDATE_CURRENT_PAGE_INDEX: {
            newState.currentPage = action.index;

            return {
                ...newState,
            };
        }

        case GameTypes.CREATE_ANSWER_FROM_RESOURCE_ITEM: {
            newState.worksheet.game.answers.push(action.answer);
            newState.answers.push(action.answer);
            return {
                ...newState,
            };
        }

        // case GameTypes.UPDATE_USER_ASSIGNMENT: {
        //     return {
        //         ...state,
        //         userAssignmentOfStudent: action.userAssignmentOfStudent,
        //     };
        // }
        case GameTypes.REMOVE_MATCHINGid_AND_POINTS:
            let updateRemoveJoinGame = newState.answers.map((ans) => {
                if (action.answerIds.includes(ans.answerId.toString())) {
                    return new Answer({
                        ...ans,
                        matchingId: -1,
                        point: null,
                    });
                }
                return { ...ans };
            });
            return {
                ...newState,
                answers: [...updateRemoveJoinGame],
            };
        case GameTypes.REMOVE_ALL_MATCHINGid_AND_POINTS:
            let updateRemoveAllJoinGame = newState.answers.map((ans) => {
                if (ans.type === ANSWER_TYPE.JOIN) {
                    return new Answer({
                        ...ans,
                        matchingId: -1,
                        point: null,
                    });
                }
                return { ...ans };
            });
            return {
                ...newState,
                answers: [...updateRemoveAllJoinGame],
            };

        case GameTypes.UPDATE_CURRENT_SHAPE_ANSWER: {
            return {
                ...newState,
                currentShapeAnswer: action.currentShapeAnswer,
            };
        }
        default:
            return state;
    }
}

export default gameResourceState;
