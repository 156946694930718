import { isProdWeb } from "../utils";
import { getUserInfo } from "../utils/tracking";

export const TRACKINGid_LOCAL = "G-LTT5WBKQ7T";
// export const MEASUREMENTid_LOCAL = "G-9WDTL3Z044";
export const TRACKINGid_PROD = "G-DQ05R20Y4B";
export const TRACKING_GOOGLE_ADS_PROD = "AW-10994674478";
export const pageview = (userid) => {
    if (!window.gtag) return;
    window.gtag("config", isProdWeb() ? TRACKINGid_PROD : TRACKINGid_LOCAL, {
        send_page_view: false,
        userid: userid,
        time: new Date(),
    });
};
// log specific events happening.
export const event = ({ action, params }) => {
    if (typeof window !== "undefined" && window.gtag) {
        const info = getUserInfo();
        window.gtag("event", action, { ...params, time: new Date(), ...info });
    }
};

// log specific events happening.
export const addEventGa = ({ action, params = {} }) => {
    event({ action, params });
};

export const gtag_download_worksheet = (url = undefined) => {
    //ga google ads
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/xKe5CNeliZQYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_play_worksheet = (url = undefined) => {
    //ga google ads
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/-AgzCKijv5QYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_save_to_collection = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-10994674478/_dMTCPb7yJQYEK7W1foo",
            event_callback: callback,
        });
    }
    return false;
};

export const gtag_save_to_collection_word_search = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-10994674478/_nO6COfS6Z8YEK7W1foo",
            event_callback: callback,
        });
    }
    return false;
};

export const gtag_download_answer_key_word_search = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-10994674478/Uhd0COTS6Z8YEK7W1foo",
            event_callback: callback,
        });
    }
    return false;
};

export const gtag_download_word_search = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-10994674478/WXMQCOHS6Z8YEK7W1foo",
            event_callback: callback,
        });
    }
    return false;
};

export const gtag_more_word_search = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-10994674478/JWosCOrS6Z8YEK7W1foo",
            event_callback: callback,
        });
    }
    return false;
};

export const gtag_add_to_my_resource_ws_maker = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/PxVICN_OqrMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_ws_maker = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/ycxGCOLOqrMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_ws_maker = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/9uCZCOXOqrMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_make_interactive = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/Q8usCK_JrbYYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_make_interactive = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/LYcrCLLJrbYYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_make_interactive = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/Wo_KCLXJrbYYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_share_make_interactive = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/Ke-pCJ_M6usYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_with_answer_key_ws_maker = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/tfWfCOjOqrMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_hand_writing = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/wWphCNi7r88YEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_hand_writing = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/nh-KCNu7r88YEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_hand_writing = (url = undefined) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/rVDGCN26r88YEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_fill_blank = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/ouUZCImE2tMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_fill_blank = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/1CnICIyE2tMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_fill_blank = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/QvIiCI-E2tMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_with_answer_fill_blank = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/C4V3CJKE2tMYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_name_tracing = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/r5dHCO-p5NUYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_name_tracing = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/n6noCOyp5NUYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_name_tracing = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/GyeJCOmp5NUYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_share_name_tracing = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/JzwkCJmy7dUYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_new_ws_word_search = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    // landing 4th of july
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/uL4JCMrh27IYEK7W1foo",
            event_callback: callback,
        },
    });
    // landing sumer word search
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/bXcVCJCD77IYEK7W1foo",
            event_callback: callback,
        },
    });
    // landing home
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/kbFKCKme5rIYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_make_interactive_ws = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/GQHJCKOe5rIYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_create_ws = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/sr0tCKae5rIYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_cross_word = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/9-hICKaEneoYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_cross_word = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/LlFXCKmEneoYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_cross_word = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/G7lACKyEneoYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_with_answer_cross_word = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/C_vUCK-EneoYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_Multiplication = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/W08TCLGrwfcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_Multiplication = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/fewmCLSrwfcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_Multiplication = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/hOkkCNGqwfcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_with_answer_Multiplication = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/0anpCK6rwfcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_add_to_my_resource_Addition = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/-lapCPyKwvcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_assign_digitally_Addition = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/Qb_TCP-KwvcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_Addition = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/FHB9CPaKwvcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};

export const gtag_print_with_answer_Addition = (url) => {
    var callback = function () {
        if (typeof url != "undefined") {
            window.location = url;
        }
    };
    event({
        action: "conversion",
        params: {
            send_to: "AW-10994674478/goQhCPmKwvcYEK7W1foo",
            event_callback: callback,
        },
    });
    return false;
};
