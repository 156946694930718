import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ConstantsTool from '../../../../../../shared/utils/ConstantsTool';

interface SelectFrameOrientationProps {
    currentOrientation: string,
    setCurrentOrientation: React.Dispatch<React.SetStateAction<string>>
}

export default function SelectFrameOrientation({
    currentOrientation,
    setCurrentOrientation,
}: SelectFrameOrientationProps) {

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="frame-orientation-group"
                defaultValue={ConstantsTool.FRAME_ORIENTATION_CHOICES['vertical']}
                name="radio-buttons-group"
                style={{ flexDirection: 'row' }}
                value={currentOrientation}
                onChange={(e) => setCurrentOrientation(e.target.value)}
            >
                <FormControlLabel 
                    value={ConstantsTool.FRAME_ORIENTATION_CHOICES['vertical']} 
                    control={<Radio />} 
                    label="Vertical" 
                />
                <FormControlLabel 
                    value={ConstantsTool.FRAME_ORIENTATION_CHOICES['horizontal']} 
                    control={<Radio />} 
                    label="Horizontal" 
                />
            </RadioGroup>
        </FormControl>
    );
}