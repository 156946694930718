const TextCaseIcon = (props) => {
    const {
        value
    } = props;
    const labelByValue = {
        'Aa': 'Aa',
        'AA': 'AA',
        'aa': 'aa'
    }
    return (
        <div style={{
            color: labelByValue[value] ? "#fff" : "#212121"
        }}>
            {labelByValue[value] ?? 'Aa'}
        </div>
    )
}

export default TextCaseIcon;