import {
    IInputWord,
    IPuzzle,
    IUserAnswerWordsearch,
} from "../../../shared/models/puzzle";
import { IWorksheet } from "../../../shared/models/worksheet";
import { IErrorWord } from "../reducer/wordSearch.reducer";

export enum WordSearchTypes {
    GET_WORDSEARCH_DEFAULT = "GET_WORDSEARCH_DEFAULT",
    GET_WORDSEARCH_DEFAULT_SUCCESS = "GET_WORDSEARCH_DEFAULT_SUCCESS",
    CHANGE_PUZZLE_SHAPE = "CHANGE_PUZZLE_SHAPE",
    CHANGE_PUZZLE_SIZE = "CHANGE_PUZZLE_SIZE",
    CHANGE_PUZZLE_FONT = "CHANGE_PUZZLE_FONT",
    CHANGE_PUZZLE_FONT_SIZE = "CHANGE_PUZZLE_FONT_SIZE",
    CHANGE_PUZZLE_LETTER_SPACING = "CHANGE_PUZZLE_LETTER_SPACING",
    CHANGE_PUZZLE_WORD_DIRECTIONS = "CHANGE_PUZZLE_WORD_DIRECTIONS",
    CHANGE_WORD_LIST_ALPHABETIZATION = "CHANGE_WORD_LIST_ALPHABETIZATION",
    SET_WORD = "SET_WORD",
    CHANGE_PUZZLE_CONFIG = "CHANGE_PUZZLE_CONFIG",
    SCRAMBLE_PUZZLE_ACTION = "SCRAMBLE_PUZZLE_ACTION",

    INIT_WORD_SEARCH_GAME = "INIT_WORD_SEARCH_GAME",
    ENTER_NEW_WORD = "ENTER_NEW_WORD",
    UPDATE_PUZZLE = "UPDATE_PUZZLE",
    INIT_WORD_SEARCH_GAME_SUCCESS = "INIT_WORD_SEARCH_GAME_SUCCESS",
    INIT_POPUP_SAVE_WS_SUCCESS = "INIT_POPUP_SAVE_WS_SUCCESS",

    CHANGE_TITLE = "CHANGE_TITLE",
    CHANGE_CREATOR = "CHANGE_CREATOR",
    CHANGE_STUDENT_INFO = "CHANGE_STUDENT_INFO",
    UPDATE_RATIO = "UPDATE_RATIO",

    SAVE_WORKSHEET = "SAVE_WORKSHEET",
    SAVE_WORKSHEET_IMAGES = "SAVE_WORKSHEET_IMAGES",
    SAVE_WORD_SEARCH_FOR_TOOL = "SAVE_WORD_SEARCH_FOR_TOOL",
    UPDATE_STATUS_WORD_PUZZLE = "UPDATE_STATUS_WORD_PUZZLE",
    UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH = "UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH",
    NEXT_ACTION_WORD_SEARCH = "NEXT_ACTION_WORD_SEARCH",
    RESET_NEXT_ACTION = "RESET_NEXT_ACTION",
    RESET_SVG = "RESET_SVG",

    SET_LOADING = "SET_LOADING",

    SET_CALLBACK_SHARED = "SET_CALLBACK_SHARED",
    UPDATE_ERROR_WORDS = "UPDATE_ERROR_WORDS",

    OPEN_POPUP = "OPEN_POPUP",
    OPEN_SURVEY = "OPEN_SURVEY",
    UPDATE_CELL_PX = "UPDATE_CELL_PX",

    REMOVE_ERROR_WORD = "REMOVE_ERROR_WORD",
    KEEP_CONFIG_PUZZLE = "KEEP_CONFIG_PUZZLE",
    UPDATE_TEMP_PUZZLE = "UPDATE_TEMP_PUZZLE",
    GET_KEYWORD_GPT = "GET_KEYWORD_GPT",
    SET_KEYWORD_GPT = "SET_KEYWORD_GPT",
    REMOVE_ALL_WORDS = "REMOVE_ALL_WORDS",
    UPDATE_AUTO_PASTE_INPUT_WORD = "UPDATE_AUTO_PASTE_INPUT_WORD",

    CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION",
    CHANGE_INFO_BOX = "CHANGE_INFO_BOX",
    CHANGE_TITLE_PUZZLE = "CHANGE_TITLE_PUZZLE",
    SET_ON_EDIT_TITLE_WS = "SET_ON_EDIT_TITLE_WS",
    CHANGE_DESCRIPTION_PUZZLE = "CHANGE_DESCRIPTION_PUZZLE",
    UPDATE_OPEN_DOWNLOAD_POPUP = "UPDATE_OPEN_DOWNLOAD_POPUP",
    SET_PROJECT_NAME = "SET_PROJECT_NAME",

    SET_STATUS_SAVE = "SET_STATUS_SAVE",
    SAVE_MORE_INFO = "SAVE_MORE_INFO",
    UPDATE_WORKSHEET = "UPDATE_WORKSHEET",
    RESET_ACTION = "RESET_ACTION",
    SET_IMAGES_SOURCE = "SET_IMAGES_SOURCE",
    SET_IMAGES_ANSWER = "SET_IMAGES_ANSWER",
    CHANGE_STATUS_DOWNLOAD_ANSWER = "CHANGE_STATUS_DOWNLOAD_ANSWER",
    CHANGE_LIST_INDEX_IMG_DOWNLOAD = "CHANGE_LIST_INDEX_IMG_DOWNLOAD",
    CHANGE_WARNING_REQUIRED = "CHANGE_WARNING_REQUIRED",
    SAVE_WORKSHEETid = "SAVE_WORKSHEETid",
    UPDATE_STATUS_DOWNLOAD_IMAGES = "UPDATE_STATUS_DOWNLOAD_IMAGES",
    CHANGE_STATUS_EDIT_POPUP_SAVE = "CHANGE_STATUS_EDIT_POPUP_SAVE",
    INIT_STATUS_POPUP_SAVE = "INIT_STATUS_POPUP_SAVE",
    GET_SUGGEST_TAGS_DATA = "GET_SUGGEST_TAGS_DATA",
    GET_SUGGEST_TAGS_DATA_SUCCESS = "GET_SUGGEST_TAGS_DATA_SUCCESS",
    SET_DEFAULT_SUGGEST_TAG = "SET_DEFAULT_SUGGEST_TAG",
    CHANGE_STATUS_ALERT_SAVE = "CHANGE_STATUS_ALERT_SAVE",
}

export interface IWordSearchAction {
    type: WordSearchTypes;
    title?: string;
    description?: string;
    creator?: string;
    studentInfo?: any[];
    puzzle?: IPuzzle;
    loading?: boolean;
    inputWord?: string;
    indexInputWord?: number;
    worksheetId?: string;
    worksheet?: IWorksheet;
    inputWords?: IInputWord[];
    words?: string[];
    width?: number;
    height?: number;
    ratio?: number;
    nextAction?: string;
    resetSvg?: boolean;
    callbackShared?: number;
    errorWords?: IErrorWord[];
    open: boolean;
    topic?: string;
    limitWord?: number;
    limitLength?: number;
    wordsGPT?: string[];
    autoPaste?: boolean;
    grades?: string[];
    languages?: string[];
    categoryIds?: string[];
    commonCoreIds: string[];
    topicIds: string[];
    selectedCategoryIds: string[];
    onEditTitleWs?: boolean;
    moduleType: string;
    imagesBase64: string[];
    puzzleCellPx?: number;
    elementImageId?: string;
    openDownloadPopup?: boolean;
    projectName?: string;
    isSave?: boolean;
    isDownloadAnswer: boolean;
    listIndexImgDownload: number[];
    warningRequired: string[];
    addWarning: string;
    removeWarning: string;
    isEditPopupSave: boolean;
    isAddTemplate: boolean;
    newTemplate: boolean;
    suggestTagsData: any[];
    typeActivity: string;
    userAnswer: IUserAnswerWordsearch;
    isCustomizeByContent?: boolean;
    isShowAlertSave: boolean;
}

export const initWordSearchGameSuccessAction = ({
    puzzle,
    width,
    height,
    ratio,
    inputWords,
    worksheet,
    onEditTitleWs,
}: {
    puzzle: IPuzzle;
    width?: number;
    height?: number;
    ratio?: number;
    inputWords?: IInputWord[];
    worksheet?: IWorksheet;
    onEditTitleWs?: boolean;
}) => {
    return {
        type: WordSearchTypes.INIT_WORD_SEARCH_GAME_SUCCESS,
        puzzle,
        width,
        height,
        ratio,
        inputWords,
        worksheet,
        onEditTitleWs,
    };
};

export const initWordSearchGameAction = ({
    worksheet,
    worksheetId,
    ratio,
    isAddTemplate,
}: {
    worksheet?: IWorksheet;
    worksheetId?: string;
    ratio?: number;
    isAddTemplate?: boolean;
}) => {
    return {
        type: WordSearchTypes.INIT_WORD_SEARCH_GAME,
        worksheet,
        worksheetId,
        ratio,
        isAddTemplate,
    };
};

export const initPopupSaveWSAction = (
    worksheet: IWorksheet,
    type,
    imagesBase64?,
    elementImageId?: string
) => {
    return {
        type: WordSearchTypes.INIT_POPUP_SAVE_WS_SUCCESS,
        worksheet,
        moduleType: type,
        imagesBase64,
        elementImageId,
    };
};

export const saveWorksheetAction = (
    nextAction?: string,
    isCustomizeByContent?: boolean
) => {
    return {
        type: WordSearchTypes.SAVE_WORKSHEET,
        nextAction,
        isCustomizeByContent,
    };
};

export const saveWorksheetImagesAction = (
    normalImages?: string[],
    answerImages?: string[]
) => {
    return {
        type: WordSearchTypes.SAVE_WORKSHEET_IMAGES,
        normalImages,
        answerImages,
    };
};

export const saveWordSearchForToolAction = () => {
    return { type: WordSearchTypes.SAVE_WORD_SEARCH_FOR_TOOL };
};

export const enterNewWordAction = (words: string[], autoPaste?: boolean) => {
    return { type: WordSearchTypes.ENTER_NEW_WORD, words, autoPaste };
};

export const updatePuzzleAction = (
    puzzle: IPuzzle,
    inputWords: IInputWord[]
) => {
    return { type: WordSearchTypes.UPDATE_PUZZLE, puzzle, inputWords };
};

export const updateTempPuzzleAction = (
    puzzle: IPuzzle,
    inputWords: IInputWord[]
) => {
    return { type: WordSearchTypes.UPDATE_TEMP_PUZZLE, puzzle, inputWords };
};

export const updateAutoPasteInputAction = (inputWords: IInputWord[]) => {
    return { type: WordSearchTypes.UPDATE_AUTO_PASTE_INPUT_WORD, inputWords };
};

export const getResourceDefaultsAction = ({
    defaultWordSearchs,
}: {
    defaultWordSearchs: {};
}) => {
    return { type: WordSearchTypes.GET_WORDSEARCH_DEFAULT };
};

export const getResourceDefaultSuccessAction = (defaultWordSearchs: {}) => {
    return {
        type: WordSearchTypes.GET_WORDSEARCH_DEFAULT_SUCCESS,
        defaultWordSearchs,
    };
};

export const setWordAction = (inputWord: string, indexInputWord: number) => {
    return {
        type: WordSearchTypes.SET_WORD,
        inputWord: inputWord,
        indexInputWord: indexInputWord,
    };
};

export const changePuzzleConfigAction = (puzzle: IPuzzle) => {
    return {
        type: WordSearchTypes.CHANGE_PUZZLE_CONFIG,
        puzzle: puzzle,
    };
};

export const changeTitleAction = (title: string) => {
    return {
        type: WordSearchTypes.CHANGE_TITLE,
        title: title,
    };
};

export const updateRatioAction = (ratio: number) => {
    return {
        type: WordSearchTypes.UPDATE_RATIO,
        ratio,
    };
};
export const changeTitlePuzzleAction = (title: string) => {
    return {
        type: WordSearchTypes.CHANGE_TITLE_PUZZLE,
        title: title,
    };
};

export const changeDescriptionAction = (description: string) => {
    return {
        type: WordSearchTypes.CHANGE_DESCRIPTION,
        description: description,
    };
};

export const changeDescriptionPuzzleAction = (description: string) => {
    return {
        type: WordSearchTypes.CHANGE_DESCRIPTION_PUZZLE,
        description,
    };
};

export const changeCreatorAction = (creator: string) => {
    return {
        type: WordSearchTypes.CHANGE_CREATOR,
        creator: creator,
    };
};

export const changeStudentInfoAction = (studentInfo: any[]) => {
    return {
        type: WordSearchTypes.CHANGE_STUDENT_INFO,
        studentInfo: studentInfo,
    };
};

export const scramblePuzzleAction = () => {
    return {
        type: WordSearchTypes.SCRAMBLE_PUZZLE_ACTION,
    };
};

export const updateStatusWordPuzzleAction = ({
    inputWord,
    userAnswer,
}: {
    inputWord;
    userAnswer?: IUserAnswerWordsearch;
}) => {
    return {
        type: WordSearchTypes.UPDATE_STATUS_WORD_PUZZLE,
        inputWord,
        userAnswer,
    };
};

export const updateWorksheetCreatedByWordSearchAction = ({
    worksheet,
}: {
    worksheet: IWorksheet;
}) => {
    return {
        type: WordSearchTypes.UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH,
        worksheet,
    };
};

export const nextActionWordSearchAction = ({
    nextAction,
}: {
    nextAction: string;
}) => {
    return {
        type: WordSearchTypes.NEXT_ACTION_WORD_SEARCH,
        nextAction,
    };
};

export const resetNextAction = () => {
    return {
        type: WordSearchTypes.RESET_NEXT_ACTION,
    };
};

export const resetSvgAction = ({ resetSvg }: { resetSvg: boolean }) => {
    return {
        type: WordSearchTypes.RESET_SVG,
        resetSvg,
    };
};

export const setLoadingAction = ({ loading }: { loading: boolean }) => {
    return {
        type: WordSearchTypes.SET_LOADING,
        loading,
    };
};

export const setCallbackSharedAction = ({
    callbackShared,
}: {
    callbackShared: number;
}) => {
    return {
        type: WordSearchTypes.SET_CALLBACK_SHARED,
        callbackShared,
    };
};

export const setErrorWords = ({ words }: { words: IErrorWord[] }) => {
    return {
        type: WordSearchTypes.UPDATE_ERROR_WORDS,
        errorWords: words,
    };
};

export const openPopupAction = ({ open }: { open: boolean }) => {
    return {
        type: WordSearchTypes.OPEN_POPUP,
        open,
    };
};

export const openSurveyAction = ({ open }: { open: boolean }) => {
    return {
        type: WordSearchTypes.OPEN_SURVEY,
        open,
    };
};

export const removeErrorWordAction = () => {
    return {
        type: WordSearchTypes.REMOVE_ERROR_WORD,
    };
};

export const keepConfigPuzzledAction = () => {
    return {
        type: WordSearchTypes.KEEP_CONFIG_PUZZLE,
    };
};

export const getKeywordGPTAction = ({
    topic,
    limitWord,
    limitLength,
}: {
    topic: string;
    limitWord: number;
    limitLength: number;
}) => {
    return {
        type: WordSearchTypes.GET_KEYWORD_GPT,
        topic,
        limitWord,
        limitLength,
    };
};

export const setKeywordGPTAction = ({
    wordsGPT,
    loading,
}: {
    wordsGPT: string[];
    loading: boolean;
}) => {
    return {
        type: WordSearchTypes.SET_KEYWORD_GPT,
        wordsGPT,
        loading,
    };
};

export const removeAllWordsAction = () => {
    return {
        type: WordSearchTypes.REMOVE_ALL_WORDS,
    };
};
export const changeInfoBoxAction = ({
    languages,
    grades,
    categoryIds,
    commonCoreIds,
    topicIds,
    selectedCategoryIds,
}: {
    languages?: string[];
    grades?: string[];
    categoryIds?: string[];
    commonCoreIds?: string[];
    topicIds?: string[];
    selectedCategoryIds?: string[];
}) => {
    return {
        type: WordSearchTypes.CHANGE_INFO_BOX,
        languages,
        grades,
        categoryIds,
        commonCoreIds,
        topicIds,
        selectedCategoryIds,
    };
};

export const setOnEditTitleWsAction = () => {
    return {
        type: WordSearchTypes.SET_ON_EDIT_TITLE_WS,
    };
};

export const updateCellPxAction = (puzzleCellPx: number) => {
    return {
        type: WordSearchTypes.UPDATE_CELL_PX,
        puzzleCellPx,
    };
};
export const updateOpenDownloadPopupAction = (openDownloadPopup) => {
    return {
        type: WordSearchTypes.UPDATE_OPEN_DOWNLOAD_POPUP,
        openDownloadPopup,
    };
};
export const setProjectNameAction = (projectName: string) => {
    return {
        type: WordSearchTypes.SET_PROJECT_NAME,
        projectName: projectName,
    };
};

export const setStatusSave = (isSave: boolean) => {
    return {
        type: WordSearchTypes.SET_STATUS_SAVE,
        isSave,
    };
};

export const saveMoreInfoWorksheet = ({
    title,
    description,
    languages,
    grades,
    categoryIds,
    commonCoreIds,
    topicIds,
    selectedCategoryIds,
}: {
    title?: string;
    description?: string;
    languages?: string[];
    grades?: string[];
    categoryIds?: string[];
    commonCoreIds?: string[];
    topicIds?: string[];
    selectedCategoryIds?: string[];
}) => {
    return {
        type: WordSearchTypes.SAVE_MORE_INFO,
        title,
        description,
        languages,
        grades,
        categoryIds,
        commonCoreIds,
        topicIds,
        selectedCategoryIds,
    };
};
export const updateWorksheetAction = (
    worksheet: IWorksheet,
    newTemplate?: boolean
) => {
    return {
        type: WordSearchTypes.UPDATE_WORKSHEET,
        worksheet,
        newTemplate,
    };
};
export const setImageSource = (imagesBase64: string[]) => {
    return {
        type: WordSearchTypes.SET_IMAGES_SOURCE,
        imagesBase64,
    };
};

export const setImageAnswer = (imagesBase64: string[]) => {
    return {
        type: WordSearchTypes.SET_IMAGES_ANSWER,
        imagesBase64,
    };
};

export const changeStatusDownloadAnswer = (isDownloadAnswer: boolean) => {
    return {
        type: WordSearchTypes.CHANGE_STATUS_DOWNLOAD_ANSWER,
        isDownloadAnswer,
    };
};

export const changeListIndexImgDownload = (listIndexImgDownload: number[]) => {
    return {
        type: WordSearchTypes.CHANGE_LIST_INDEX_IMG_DOWNLOAD,
        listIndexImgDownload,
    };
};

export const changeWarningRequired = ({
    warningRequired,
    addWarning,
    removeWarning,
}: {
    warningRequired?: string[];
    addWarning?: string;
    removeWarning?: string;
}) => {
    return {
        type: WordSearchTypes.CHANGE_WARNING_REQUIRED,
        warningRequired,
        addWarning,
        removeWarning,
    };
};

export const saveWorksheetId = ({ id }: { id: string }) => {
    return {
        type: WordSearchTypes.SAVE_WORKSHEETid,
        id,
    };
};
export const updateStatusDownloadImages = (status: boolean) => {
    return {
        type: WordSearchTypes.UPDATE_STATUS_DOWNLOAD_IMAGES,
        status,
    };
};

export const changeStatusEditPopupSave = (isEditPopupSave: boolean) => {
    return {
        type: WordSearchTypes.CHANGE_STATUS_EDIT_POPUP_SAVE,
        isEditPopupSave,
    };
};
export const initStatusPopupSave = () => {
    return {
        type: WordSearchTypes.INIT_STATUS_POPUP_SAVE,
    };
};

export const getSuggestTagsData = () => {
    return {
        type: WordSearchTypes.GET_SUGGEST_TAGS_DATA,
    };
};
export const getSuggestTagsDataSuccess = (suggestTagsData: any[]) => {
    return {
        type: WordSearchTypes.GET_SUGGEST_TAGS_DATA_SUCCESS,
        suggestTagsData,
    };
};

export const setDefaultSuggestTag = (typeActivity: string) => {
    return {
        type: WordSearchTypes.SET_DEFAULT_SUGGEST_TAG,
        typeActivity,
    };
};

export const changeStatusAlertSave = (isShowAlertSave: boolean) => {
    return {
        type: WordSearchTypes.SET_DEFAULT_SUGGEST_TAG,
        isShowAlertSave,
    };
};
