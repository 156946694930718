import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
import { IActivity } from "../../shared/models/WorkSheetCreator";
import Config from "../../shared/utils/config";
import { generateScrambleActivity } from "../../utils/create-word-scramble";
import {
    addPageWorksheet,
    addResourceItems,
    removeResourceItemsByActivityId,
    removeUnusedPages,
    setCurrentActivity,
    updateCurrentActivityToListActivity,
} from "../reducers/createWorksheet";
import { RootState } from "../store";
import { IPageWorksheetNew } from "../../shared/models/pageWorksheetNew";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";
import { newPageResource } from "../../utils/draw";

type TypeDispatch = ThunkDispatch<unknown, unknown, AnyAction>;

const getAction = (action: string) => {
    const THUNK_PREFIX = "wordScramble/";
    return THUNK_PREFIX + action;
};

const updateActivity = (newActivity: IActivity, dispatch: TypeDispatch) => {
    dispatch(updateCurrentActivityToListActivity({ activity: newActivity }));
    dispatch(setCurrentActivity({ activity: newActivity }));
};

const updateResourceItems = (
    pages: IPageWorksheetNew[],
    resourceItems: IResourceItemNew[],
    activityId: string,
    maxPageIndex: number,
    dispatch: TypeDispatch
) => {
    if (activityId !== "") {
        dispatch(removeResourceItemsByActivityId({ id: activityId }));
    }

    for (let i = 0; i <= maxPageIndex; i++) {
        const resourcePageIndex = resourceItems.filter(
            (item) => item.pageIndex === i
        );
        const isPageExist = pages[i];

        if (isPageExist) {
            dispatch(
                addResourceItems({
                    resourceItems: resourcePageIndex,
                    pageIndex: i,
                })
            );
        } else {
            dispatch(
                addPageWorksheet({
                    resourceItems: [
                        ...newPageResource(i),
                        ...resourcePageIndex,
                    ],
                })
            );
        }
    }

    dispatch(removeUnusedPages());
};

const updateToStore = (
    pages: IPageWorksheetNew[],
    resourceItems: IResourceItemNew[],
    activity: IActivity,
    maxPageIndex: number,
    dispatch: TypeDispatch
) => {
    updateResourceItems(
        pages,
        resourceItems,
        activity.id,
        maxPageIndex,
        dispatch
    );
    updateActivity(activity, dispatch);
};

const getResourcesByActivity = (pages: IPageWorksheetNew[], id: string) => {
    const resourceItems = [];

    pages.forEach((page) => {
        const items = page.resourceItems.filter(
            (item) => item.activityId === id
        );

        resourceItems.push(...items);
    });

    return resourceItems;
};

export const updateWordScramble = createAsyncThunk(
    getAction("updateWordScramble"),
    async (
        params: {
            words?: string[];
            updateWord?: { index: number; value: string };
            deleteWordIndex?: number;
            addWord?: boolean;
            addWords?: {
                fromIndex: number;
                words: string[];
            };
        },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { words, updateWord, deleteWordIndex, addWord, addWords } =
                params;

            let _words = currentActivity.questions.map(
                ({ question, scrambleAttribute }) => ({
                    origin: question,
                    shuffleWord: scrambleAttribute.shuffleQuestion,
                })
            );

            if (words) {
                _words = words.map((w) => ({
                    origin: w,
                    shuffleWord: w,
                }));
            } else if (updateWord) {
                const { index, value } = updateWord;
                const isExist = _words?.[index];

                if (isExist) {
                    _words[index] = {
                        origin: value,
                        shuffleWord: value,
                    };
                }
            } else if (deleteWordIndex > -1) {
                _words.splice(deleteWordIndex, 1);
            } else if (addWord) {
                _words.push({ origin: "", shuffleWord: "" });
            } else if (addWords) {
                const { fromIndex, words } = addWords;
                const newWords = words.map((w) => ({
                    origin: w,
                    shuffleWord: w,
                }));
                _words.splice(fromIndex, 1, ...newWords);
            }

            const { questions, resourceItems, pageIndex } =
                generateScrambleActivity({
                    words: _words,
                    activity: currentActivity,
                    regenerate: false,
                    checkDifferent: true,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        currentActivity.id
                    ),
                });

            if (updateWord) {
                const { index } = updateWord;
                questions[index].id = currentActivity.questions[index]?.id;
            }
            updateToStore(
                pagesWorksheet,
                resourceItems,
                { ...currentActivity, questions },
                pageIndex,
                dispatch
            );

            if (addWord) return questions[questions.length - 1].id;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeListStyle = createAsyncThunk(
    getAction("changeListStyle"),
    async (
        params: { list: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { list } = params;
            const newActivity = { ...currentActivity, listStyle: list };
            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: newActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: newActivity,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        newActivity.id
                    ),
                });
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                resourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeStrokeStyle = createAsyncThunk(
    getAction("changeStrokeStyle"),
    async (
        params: { stroke: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { stroke } = params;

            const newActivity = { ...currentActivity, strokeStyle: stroke };
            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: newActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: newActivity,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        newActivity.id
                    ),
                });
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                resourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeLetterStyle = createAsyncThunk(
    getAction("changeLetterStyle"),
    async (
        params: { letterStyle: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { letterStyle } = params;

            const newActivity = { ...currentActivity, letterStyle };
            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: newActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: newActivity,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        newActivity.id
                    ),
                });
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                resourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeSeparatorStyle = createAsyncThunk(
    getAction("changeSeparatorStyle"),
    async (
        params: { separatorStyle: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { separatorStyle } = params;

            const newActivity = { ...currentActivity, separatorStyle };
            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: newActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: newActivity,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        newActivity.id
                    ),
                });
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                resourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeShowTheWordBank = createAsyncThunk(
    getAction("changeShowTheWordBank"),
    async (
        params: { value: boolean },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { value } = params;

            const newActivity = {
                ...currentActivity,
                showWordBank: value ? Config.SHOW_VALUE : Config.HIDE_VALUE,
            };
            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: newActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: newActivity,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        newActivity.id
                    ),
                });

            updateToStore(
                pagesWorksheet,
                resourceItems,
                { ...newActivity, questions },
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeShowAnswerKey = createAsyncThunk(
    getAction("changeShowAnswerKey"),
    async (
        params: { value: boolean },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity } = (getState() as RootState)
                .createWorksheetState;
            const { value } = params;

            updateActivity(
                {
                    ...currentActivity,
                    showAnswerKey: value
                        ? Config.SHOW_VALUE
                        : Config.HIDE_VALUE,
                },
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const scramble = createAsyncThunk(
    getAction("scramble"),
    async (params, { dispatch, getState, rejectWithValue }) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;

            const { resourceItems, pageIndex, questions } =
                generateScrambleActivity({
                    words: currentActivity.questions.map((w) => ({
                        origin: w.question,
                        shuffleWord: w.scrambleAttribute.shuffleQuestion,
                    })),
                    activity: currentActivity,
                    regenerate: true,
                    resources: getResourcesByActivity(
                        pagesWorksheet,
                        currentActivity.id
                    ),
                });
            updateToStore(
                pagesWorksheet,
                resourceItems,
                { ...currentActivity, questions },
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);
