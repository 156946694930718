import React from "react";
import { defaultSpacingTemplate, IPageWorksheetNew } from "../../../../../shared/models/pageWorksheetNew";

const ContentLayer = ({
    pageIndex,
    pagesWorksheet,
    listElementWorksheet,
    ratio
}: // rotationRef,
    {
        pageIndex: number;
        pagesWorksheet: IPageWorksheetNew,
        listElementWorksheet: React.ReactNode;
        ratio: number;
    }) => {
    // const ratio = useAppSelector((state) => state.createWorksheetState.ratio);

    const spacing = pagesWorksheet?.spacing ?? defaultSpacingTemplate;

    return (
        <div
            style={{ overflow: "hidden" }}
            id="content-layer"
        >
            <div
                style={{
                    width: `${pagesWorksheet.width * ratio}px`,
                    height: `${pagesWorksheet.height * ratio}px`,
                }}
                id={"page-worksheet-container-"+pageIndex}
            >
                <div
                    style={{
                        width: `${pagesWorksheet.width}px`,
                        height: `${pagesWorksheet.height}px`,
                        transform: `scale(${ratio})`,
                        backgroundColor: "#fff",
                        position: "relative",
                        transformOrigin: "0 0",
                    }}
                >
                    <div
                        style={{
                            width: `${pagesWorksheet.width}px`,
                            height: `${pagesWorksheet.height}px`,
                            position: "relative",
                            paddingTop: spacing.paddingTop,
                            paddingRight: spacing.paddingRight,
                            paddingBottom: spacing.paddingBottom,
                            paddingLeft: spacing.paddingLeft,
                        }}
                    >
                        {listElementWorksheet}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContentLayer;
