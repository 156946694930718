import React from "react";

const ThreeDotsFolder = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                fill="white"
            />
            <path
                d="M11.9999 10.6899C11.2799 10.6899 10.6899 11.2799 10.6899 11.9999C10.6899 12.7199 11.2799 13.3099 11.9999 13.3099C12.7199 13.3099 13.3099 12.7199 13.3099 11.9999C13.3099 11.2799 12.7199 10.6899 11.9999 10.6899Z"
                fill="#212121"
            />
            <path
                d="M6.99994 10.6899C6.27994 10.6899 5.68994 11.2799 5.68994 11.9999C5.68994 12.7199 6.27994 13.3099 6.99994 13.3099C7.71994 13.3099 8.30994 12.7199 8.30994 11.9999C8.30994 11.2799 7.71994 10.6899 6.99994 10.6899Z"
                fill="#212121"
            />
            <path
                d="M16.9999 10.6899C16.2799 10.6899 15.6899 11.2799 15.6899 11.9999C15.6899 12.7199 16.2799 13.3099 16.9999 13.3099C17.7199 13.3099 18.3099 12.7199 18.3099 11.9999C18.3099 11.2799 17.7199 10.6899 16.9999 10.6899Z"
                fill="#212121"
            />
        </svg>
    );
};

export default ThreeDotsFolder;
