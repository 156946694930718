import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Drawer from "@mui/material/Drawer";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import HintIconSvg from "../../icons/hint";
import { ExtractParamType } from "../dynamic-component";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const PopupCommon = ({
    open,
    setOpen,
    title,
    contentText,
    renderAction,
    renderContent,
    classNameDialog,
    classNameTitle,
    embedPopup,
    header,
    clickOutside = true,
    style,
    onClose,
    fullScreen,
    direction,
    handleOnKeyDown,
    isResetButton,
}: {
    open: boolean;
    setOpen: (value: boolean) => void;
    title: string;
    contentText?: string;
    renderAction?: () => React.ReactElement<any, any>;
    renderContent?: () => React.ReactElement<any, any>;
    classNameDialog?: string;
    classNameTitle?: string;
    embedPopup?: boolean;
    header?: () => React.ReactElement<any, any>;
    clickOutside?: boolean;
    style?: any;
    onClose?: Function;
    fullScreen?: boolean;
    direction?: string;
    handleOnKeyDown?: Function;
    isResetButton?: boolean;
}) => {
    const isTablet = useMediaQuery("(max-width:1024px)");

    if (isTablet) {
        return (
            <Drawer
                onKeyDown={(e) => handleOnKeyDown && handleOnKeyDown(e)}
                className={
                    (classNameDialog ?? "") + " drawer-more-action-ws-panel"
                }
                anchor={"bottom"}
                open={open}
                onClose={(e: any) => {
                    if (clickOutside) {
                        e.stopPropagation();
                        setOpen(false);
                    }
                }}
                variant="temporary"
                disableEnforceFocus
                sx={style}
            >
                <div
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <div
                        className="close-button"
                        style={
                            isResetButton
                                ? { display: "flex", justifyContent: "center" }
                                : {}
                        }
                        onClick={() => setOpen(false)}
                    >
                        <HintIconSvg />
                    </div>
                    <div
                        className="content"
                        style={{
                            maxHeight: isTablet && "60vh",
                            padding: isResetButton ? 16 : 0,
                            // overflow: isTablet && "scroll",
                        }}
                    >
                        {header && <div className="header">{header()}</div>}
                        {title && (
                            <DialogTitle
                                className={
                                    "title-popup" + (classNameTitle ?? "")
                                }
                                style={{
                                    fontSize: "16px",
                                    fontWeight: 700,
                                }}
                            >
                                {title}
                            </DialogTitle>
                        )}
                        <DialogContent>
                            {contentText && (
                                <DialogContentText>
                                    {contentText}
                                </DialogContentText>
                            )}
                            {renderContent && renderContent()}
                        </DialogContent>

                        {renderAction && (
                            <DialogActions>{renderAction()}</DialogActions>
                        )}
                    </div>
                </div>
            </Drawer>
        );
    }
    return (
        <Dialog
            onKeyDown={(e) => handleOnKeyDown && handleOnKeyDown(e)}
            open={open}
            TransitionComponent={
                direction == "down" ? TransitionDown : Transition
            }
            keepMounted
            fullWidth={isTablet}
            fullScreen={fullScreen}
            className={classNameDialog}
            disableEnforceFocus
            onClose={(e: any) => {
                if (clickOutside) {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpen(false);
                }
                if (onClose) {
                    onClose();
                }
            }}
            sx={style}
        >
            <div
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                {header && <div className="header">{header()}</div>}
                {!embedPopup && (
                    <DialogTitle className={classNameTitle}>
                        {title}
                    </DialogTitle>
                )}
                <DialogContent>
                    {contentText && (
                        <DialogContentText>{contentText}</DialogContentText>
                    )}
                    {renderContent && renderContent()}
                </DialogContent>
                {renderAction && (
                    <DialogActions>{renderAction()}</DialogActions>
                )}
            </div>
        </Dialog>
    );
};

export type PopupCommonType = ExtractParamType<typeof PopupCommon>;
export default PopupCommon;
