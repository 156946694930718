import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import { updateResourceItems } from "../../../../../../redux/reducers/createWorksheet";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import { getPositionMoreTable } from "../../../../../../utils/align";
import "./index.scss";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";
import {
    DirectionTable,
    TableAttribute,
} from "../../../../../../shared/models/tableAttribute";
import tableStore from "../../../../../../syncExternalStore/tableStore";
import { getSizeCellTable } from "../../../../../../resource-user/utils/table";
import {
    EventTracking,
    sendEvent,
} from "../../../../../../resource-user/utils/event";

const ExtensionTable = React.forwardRef<
    HTMLDivElement,
    {
        currentElement: IResourceItemNew;
        rowIndex: number;
        colIndex: number;
        setRow: any;
        setCol: any;
        isResize: boolean;
        setIsResize: any;
        setCurrentElement: any;
        rowAdd: number;
        setRowAdd: any;
        colAdd: number;
        setColAdd: any;
        checkAdd: number;
        setCheckAdd: any;
    }
>(
    (
        {
            currentElement,
            rowIndex,
            colIndex,
            setRow,
            setCol,
            setIsResize,
            isResize,
            setCurrentElement,
            rowAdd,
            setRowAdd,
            colAdd,
            setColAdd,
            checkAdd,
            setCheckAdd,
        },
        ref
    ) => {
        const ratio = useAppSelector(
            (state) => state.createWorksheetState.ratio
        );
        const { tableAttribute } = currentElement;
        const { data, gap, borderWidth } = tableAttribute;
        let widthBoxResize = 10;
        let widthBorder = 2;
        const dispatch = useAppDispatch();
        const tableState = useSyncExternalStore(
            tableStore.subscribe,
            tableStore.read
        );

        useEffect(() => {
            let resourceItem = {
                ...currentElement,
                tableAttribute: { ...tableAttribute, currentPositions: [] },
            };
            setCurrentElement(resourceItem);
            dispatch(
                updateResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceItems: [resourceItem],
                    isAddHistory: false,
                })
            );
        }, []);

        const onMouseDown = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
            type: "row" | "column"
        ) => {
            event.stopPropagation();
            setIsResize(true);
        };
        let position = null;
        if (tableState.positions.length) {
            position = getPositionMoreTable({
                tableAttribute,
                positions: tableState.positions,
                strokeWidth: widthBorder / ratio,
            });
        } else {
            position = getPositionMoreTable({
                tableAttribute,
                strokeWidth: widthBorder / ratio,
            });
        }
        const updateResource = (newResourceItem: IResourceItemNew) => {
            setCurrentElement(newResourceItem);
            dispatch(
                updateResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceItems: [newResourceItem],
                })
            );
        };

        const makeAddRowView = (
            top: number,
            left: number,
            row: number,
            col: number,
            isLeft?: boolean
        ) => {
            return (
                <div
                    key={`${top} -add-row-${left}`}
                    className="icon_add_cells can-click"
                    style={{
                        top: top * ratio - 10,
                        left: isLeft ? left + 14 : -28,
                        opacity: rowAdd === row && checkAdd === col ? 1 : 0,
                    }}
                    onMouseEnter={() => {
                        setRowAdd(row);
                        setCheckAdd(col);
                    }}
                    onMouseLeave={() => {
                        setRowAdd(-1);
                        setCheckAdd(-1);
                    }}
                    onClick={() => {
                        let tableAttribute = new TableAttribute(
                            currentElement.tableAttribute
                        );
                        let dHeight = tableAttribute.addCells({
                            type: DirectionTable.row,
                            row,
                        }).dHeight;
                        updateResource({
                            ...currentElement,
                            tableAttribute,
                            height: currentElement.height + dHeight,
                        });
                        sendEvent(EventTracking.ncw_add_row);
                    }}
                >
                    <img
                        src={svgPath(IconSVG.add_cells)}
                        width={18}
                        height={18}
                    />
                </div>
            );
        };

        const makeAddColView = (
            top: number,
            left: number,
            col: number,
            row: number,
            isBottom?: boolean
        ) => {
            return (
                <div
                    key={`${top}-add-col-${left}`}
                    className="icon_add_cells can-click"
                    style={{
                        top: isBottom ? top + 14 : -26,
                        left: left * ratio - 12,
                        opacity: colAdd === col && row === checkAdd ? 1 : 0,
                    }}
                    onMouseEnter={() => {
                        setColAdd(col);
                        setCheckAdd(row);
                    }}
                    onMouseLeave={() => {
                        setColAdd(-1);
                        setCheckAdd(-1);
                    }}
                    onClick={() => {
                        let tableAttribute = new TableAttribute(
                            currentElement.tableAttribute
                        );
                        let dWidth = tableAttribute.addCells({
                            type: DirectionTable.column,
                            col,
                        }).dWidth;
                        updateResource({
                            ...currentElement,
                            tableAttribute,
                            width: currentElement.width + dWidth,
                        });
                        sendEvent(EventTracking.ncw_add_column);
                    }}
                >
                    <img
                        src={svgPath(IconSVG.add_cells)}
                        width={18}
                        height={18}
                    />
                </div>
            );
        };

        return (
            <>
                {position && (
                    <div
                        style={{
                            position: "absolute",
                            top: position.y * ratio,
                            left: position.x * ratio,
                            width: position.width * ratio,
                            height: position.height * ratio,
                            pointerEvents: "none",
                            border: `${widthBorder}px solid ${
                                tableState.positions.length
                                    ? "red"
                                    : "var(--color-border-resize)"
                            }`,
                        }}
                    ></div>
                )}
                {data.map((cells, row) => {
                    return (
                        <div key={row}>
                            {cells.map((cell, col) => {
                                let { top, left, width, height, dGap } =
                                    getSizeCellTable({
                                        tableAttribute,
                                        row,
                                        col,
                                    });
                                width += dGap;
                                height += dGap;
                                if (gap === 0) {
                                    top += cell.borderWidth / 2;
                                    left += cell.borderWidth / 2;
                                }
                                let leftCol =
                                    (left - gap / 2) * ratio -
                                    widthBoxResize / 2;
                                let topRow =
                                    (top - gap / 2) * ratio -
                                    widthBoxResize / 2;
                                return (
                                    <div key={col}>
                                        {col !== 0 && !cell.positionParent && (
                                            <div
                                                key={`${top}-col-${left}`}
                                                className="divider-resize-table-column can-click"
                                                style={{
                                                    left: leftCol,
                                                    top: top * ratio,
                                                    width: widthBoxResize,
                                                    height:
                                                        (height -
                                                            (row ===
                                                            data.length - 1
                                                                ? gap +
                                                                  borderWidth
                                                                : 0)) *
                                                        ratio,
                                                }}
                                                onMouseEnter={() => {
                                                    setColAdd(col);
                                                    if (!isResize) setCol(col);
                                                }}
                                                onMouseMove={(event) => {
                                                    let react =
                                                        event.currentTarget.getBoundingClientRect();
                                                    let d =
                                                        event.clientY -
                                                        react.top;
                                                    if (
                                                        top + d / ratio <
                                                        currentElement.height /
                                                            2
                                                    ) {
                                                        setCheckAdd(0);
                                                    } else {
                                                        setCheckAdd(
                                                            data.length - 1
                                                        );
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    setColAdd(-1);
                                                    setCheckAdd(-1);
                                                    if (!isResize) setCol(-1);
                                                }}
                                                onMouseDown={(e) =>
                                                    onMouseDown(e, "column")
                                                }
                                            >
                                                <div
                                                    ref={
                                                        isResize &&
                                                        colIndex === col
                                                            ? ref
                                                            : null
                                                    }
                                                    className="divider"
                                                    style={{
                                                        background:
                                                            colIndex === col
                                                                ? "var(--color-border-resize)"
                                                                : "transparent",
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {row !== 0 && !cell.positionParent && (
                                            <div
                                                key={`${top}-row-${left}`}
                                                className="divider-resize-table-row can-click"
                                                style={{
                                                    left: left * ratio,
                                                    top: topRow,
                                                    width:
                                                        (width -
                                                            (col ===
                                                            cells.length - 1
                                                                ? gap +
                                                                  borderWidth
                                                                : 0)) *
                                                        ratio,
                                                    height: widthBoxResize,
                                                }}
                                                onMouseEnter={(event) => {
                                                    setRowAdd(row);
                                                    if (!isResize) setRow(row);
                                                }}
                                                onMouseMove={(event) => {
                                                    let react =
                                                        event.currentTarget.getBoundingClientRect();
                                                    let d =
                                                        event.clientX -
                                                        react.left;
                                                    if (
                                                        left + d / ratio <
                                                        currentElement.width / 2
                                                    ) {
                                                        setCheckAdd(0);
                                                    } else {
                                                        setCheckAdd(
                                                            cells.length - 1
                                                        );
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    setRowAdd(-1);
                                                    if (!isResize) setRow(-1);
                                                }}
                                                onMouseDown={(e) =>
                                                    onMouseDown(e, "row")
                                                }
                                            >
                                                <div
                                                    ref={
                                                        isResize &&
                                                        rowIndex === row
                                                            ? ref
                                                            : null
                                                    }
                                                    className="divider"
                                                    style={{
                                                        background:
                                                            rowIndex === row
                                                                ? "var(--color-border-resize)"
                                                                : "transparent",
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {col === 0 &&
                                            makeAddRowView(top, left, row, col)}
                                        {col === 0 &&
                                            row === data.length - 1 &&
                                            makeAddRowView(
                                                top + height,
                                                left,
                                                row + 1,
                                                col
                                            )}
                                        {col === cells.length - 1 &&
                                            makeAddRowView(
                                                top,
                                                leftCol + cell.width * ratio,
                                                row,
                                                col,
                                                true
                                            )}
                                        {col === cells.length - 1 &&
                                            row === data.length - 1 &&
                                            makeAddRowView(
                                                top + height,
                                                leftCol + cell.width * ratio,
                                                row + 1,
                                                col,
                                                true
                                            )}
                                        {row === 0 &&
                                            makeAddColView(top, left, col, row)}
                                        {row === 0 &&
                                            col === cells.length - 1 &&
                                            makeAddColView(
                                                top,
                                                left + width,
                                                col + 1,
                                                row
                                            )}
                                        {row === data.length - 1 &&
                                            makeAddColView(
                                                topRow + cell.height * ratio,
                                                left,
                                                col,
                                                row,
                                                true
                                            )}
                                        {row === data.length - 1 &&
                                            col === cells.length - 1 &&
                                            makeAddColView(
                                                topRow + cell.height * ratio,
                                                left + width,
                                                col + 1,
                                                row,
                                                true
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </>
        );
    }
);

export default ExtensionTable;
