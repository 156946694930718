import { useEffect } from 'react';
import { IResourceItemNew } from '../../../../../../shared/models/resourceItemNew';
import './styles.scss';
import { useState } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ToolTipCustomize from '../../../../../../resource-user/components/common/library/tool-tip';
import { TypeOfPlacementTooltip } from '../../../../../../resource-user/components/common/library/render-tooltip';
import { ConstantsApp } from '../../../../../../utils/Constants';
import ValidatorModal from '../../../../../../resource-user/components/main-header/validator-modal';
import { useAppDispatch } from '../../../../../../redux/hook';
import { setCurrentElementFocus, setDisableAjustZonePositionWithKeyboard } from '../../../../../../redux/reducers/createWorksheet';

function SetValidatorAction({
    currentElement,
    handleCurrentElementChange
}: {
    currentElement: IResourceItemNew;
    handleCurrentElementChange: (updatedValues: object) => void;
}) {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(setDisableAjustZonePositionWithKeyboard(showModal));
    }, [showModal]);

    return (
        <>
            <ToolTipCustomize
                title={"Set Validator"}
                placement={TypeOfPlacementTooltip.top}
                style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
            >
                <div
                    className={
                        "set-validator-name-action " +
                        (currentElement.fieldName && "active")
                    }
                    onClick={() => setShowModal(true)}
                >
                    <TaskAltIcon />
                </div>
            </ToolTipCustomize>
            {showModal && (
                <ValidatorModal
                    zoneId={currentElement.id}
                    fieldName={currentElement.fieldName}
                    hideModal={() => {
                        setShowModal(false);
                        dispatch(setCurrentElementFocus(true));
                    }}
                    shuffle={currentElement.shuffle || false}
                    handleCurrentElementChange={handleCurrentElementChange}
                />
            )}
        </>
    );
}

export default SetValidatorAction;