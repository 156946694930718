import React, { useEffect, useRef, useState } from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import ResourceItemComponent from "../../ResourceItem";
import "./index.scss";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

function HStackResource({
    resourceItems,
    activeTab,
    seeAll,
    className,
    label,
    onClickLabel,
}: {
    resourceItems: IResourceItemNew[];
    activeTab: number;
    seeAll: Function;
    className: string;
    label: string;
    onClickLabel?: any;
}) {
    const ref = useRef(null);
    const [offset, setOffset] = useState(-1);
    useEffect(() => {
        setOffset(0);
    }, []);

    const scorllTo = (
        ref: React.MutableRefObject<HTMLDivElement>,
        toRight: boolean
    ) => {
        if (ref.current) {
            let element = ref.current,
                currentPosition = element.scrollLeft,
                maxwidth = element.scrollWidth,
                width = element.offsetWidth,
                left = Math.min(maxwidth, currentPosition + width);
            if (!toRight) {
                left = Math.max(0, currentPosition - width);
            }
            ref.current.scrollTo({
                left: left,
                top: 0,
                behavior: "smooth",
            });
        }
    };
    const dTop = 18;

    const iconScorll = (
        <NavigateNextRoundedIcon
            sx={{
                width: "18px",
                height: "18px",
                color: "rgba(33, 33, 33, 0.54)",
            }}
        />
    );
    return (
        <div
            className="container-shapes"
            onMouseDown={() => {
                console.log("Click");
            }}
        >
            <div className="label-list">
                <span
                    className="label"
                    onClick={() => {
                        if (onClickLabel) {
                            onClickLabel();
                        }
                    }}
                >
                    {label}
                </span>
                <div className="see-all" onClick={() => seeAll()}>
                    See all
                </div>
            </div>
            <div
                ref={ref}
                className={className + " hide-bar"}
                onScroll={(e) => setOffset(e.currentTarget.scrollLeft)}
            >
                {resourceItems.map((resourceItem, index) => {
                    return (
                        <div>
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={"resource-item"}
                            ></ResourceItemComponent>
                            {label === 'Shapes' && (
                                <p style={{ fontSize: 8, textAlign: 'center' }}>{resourceItem.shapeAttribute.name}</p>
                            )}
                        </div>
                    );
                })}
                {offset != 0 && (
                    <div
                        className="box-arrow-left"
                        onClick={() => scorllTo(ref, false)}
                        style={{
                            height: (ref?.current?.offsetHeight ?? 0) - dTop,
                        }}
                    >
                        <div className="arrow-left">{iconScorll}</div>
                    </div>
                )}
                {offset !=
                    ref?.current?.scrollWidth - ref?.current?.offsetWidth && (
                    <div
                        className="box-arrow-right"
                        onClick={() => scorllTo(ref, true)}
                        style={{
                            height: (ref?.current?.offsetHeight ?? 0) - dTop,
                        }}
                    >
                        <div className="arrow-right">{iconScorll}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HStackResource;
