import {
    call,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import { updateSubmitedStatusAction } from "../action/game.action";
import {
    NotificationAction,
    NotificationTypes,
    getNotificationByUserSuccessAction,
    getNumberOfNotificationsSuccessAction,
    createSubmitedNotificationSuccessAction,
    getNotificationDetailSuccessAction,
} from "../action/notification.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import {
    getNotificationDetailApi,
    getNumberOfNotificationsAPI,
    getSubmittedNotificationAPI,
    setAllNotificationReadStatusAPI,
    setNotificationReadDetailAPI,
} from "../repositories/notification";
import {
    createBonusDownloadNotificationApi,
    createSubmitedNotificationApi,
} from "../repositories/notification.repositories";

function* getNumberOfNotificationsSaga(action: NotificationAction) {
    const { toUserId } = action;
    if (toUserId) {
        let { nbNotifications, nbUnreadMessages } = yield call(
            getNumberOfNotificationsAPI,
            {
                toUserId,
            }
        );
        yield put(
            getNumberOfNotificationsSuccessAction(
                nbNotifications,
                nbUnreadMessages
            )
        );
    }
}

function* getNotificationByUserSaga(action: NotificationAction) {
    const { toUserId, lastUpdated, limit } = action;
    if (toUserId) {
        let notificationInfos = yield call(getSubmittedNotificationAPI, {
            toUserId,
            lastUpdated,
            limit,
        });
        yield put(getNotificationByUserSuccessAction(notificationInfos));
    }
}

function* setAllNotificationsReadStatus(action: NotificationAction) {
    const { toUserId } = action;
    if (toUserId) {
        yield call(setAllNotificationReadStatusAPI, {
            toUserId,
        });
    }
}

function* setNotificationReadDetailSaga(action: NotificationAction) {
    const { id } = action;
    if (id) {
        yield call(setNotificationReadDetailAPI, {
            id,
        });
    }
}
function* createSubmitedNotificationSaga(action: NotificationAction) {
    let practiceWorksheet = yield select(
        (state: ResourceAppState) => state.gameResourceState.practiceWorksheet
    );

    let result = yield call(createSubmitedNotificationApi, {
        fromUserId: action.fromUserId ?? "",
        name: action.name ?? "",
        teacherEmail: action.teacherEmail ?? "",
        worksheetId: action.worksheetId ?? "",
        type: action.notiType ?? 0,
        userPracticeId: practiceWorksheet.id,
    });
    if (result) {
        yield put(createSubmitedNotificationSuccessAction());
        yield put(updateSubmitedStatusAction(true));
    }
}

function* createBonusDownloadNotificationSaga(action: NotificationAction) {
    try {
        let result = yield call(createBonusDownloadNotificationApi, {
            toUserId: action.toUserId,
        });

        if (result) {
            yield put(getNotificationByUserSuccessAction([result], 1, 1));
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}

function* getNotificationDetailSaga(action: NotificationAction) {
    try {
        let result = yield call(getNotificationDetailApi, {
            id: action.id,
        });

        if (result && result.length) {
            yield put(getNotificationDetailSuccessAction(result[0]));
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}

function* listenCreateSubmitedNotification() {
    yield takeLatest(
        NotificationTypes.CREATE_SUBMITED_NOTIFICATION,
        createSubmitedNotificationSaga
    );
}

function* listenGetNotification() {
    yield takeEvery(
        NotificationTypes.GET_NUMBER_OF_NOTIFICATIONS,
        getNumberOfNotificationsSaga
    );
    yield takeLatest(
        NotificationTypes.GET_NOTIFICATION_BY_USER,
        getNotificationByUserSaga
    );
}

function* listenUpdateNotification() {
    yield takeEvery(
        NotificationTypes.SET_ALL_NOTIFICATION_READ,
        setAllNotificationsReadStatus
    );
    yield takeEvery(
        NotificationTypes.SET_NOTIFICATION_READ_DETAIL,
        setNotificationReadDetailSaga
    );
}

function* listenCreateBonusDownloadNotification() {
    yield takeEvery(
        NotificationTypes.CREATE_BONUS_DOWNLOAD_NOTIFICATION,
        createBonusDownloadNotificationSaga
    );
}

function* listenGetNotificationDetail() {
    yield takeEvery(
        NotificationTypes.GET_NOTIFICATION_DETAIL,
        getNotificationDetailSaga
    );
}

export const notificationSaga = [
    fork(listenGetNotification),
    fork(listenUpdateNotification),
    fork(listenCreateSubmitedNotification),
    fork(listenCreateBonusDownloadNotification),
    fork(listenGetNotificationDetail),
];
