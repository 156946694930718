import { IAssignment } from "../../../shared/models/assignment";
import { Class, IClass } from "../../../shared/models/class";
import { stringifyAndParse } from "../../../shared/utils";
import { AssignDigitallyTypes } from "../action/assign-digitally.action";

export interface AssignDigitally {
    classes: IClass[];
    assignments: IAssignment[];
    loading: boolean;
    saveAssignments: IAssignment[];
}

const initialState: AssignDigitally = {
    classes: [],
    assignments: [],
    loading: false,
    saveAssignments: [],
};

function assignDigitallyState(
    state: AssignDigitally = initialState,
    action: any
): AssignDigitally {
    let classes = state.classes;
    switch (action.type) {
        case AssignDigitallyTypes.GET_CLASSES:
            return {
                classes: [],
                assignments: [],
                saveAssignments: [],
                loading: true,
            };
        case AssignDigitallyTypes.GET_CLASSES_SUCCESS:
            if (action.classes?.length > 0) {
                classes = action.classes?.reverse().map((c) => {
                    let assignment = state.assignments.find(
                        (s) => s.classId == c.id
                    );
                    if (assignment) {
                        c.assignments = [assignment];
                    }
                    return c;
                });
            }
            return {
                ...state,
                loading: false,
                classes: action.classes?.reverse(),
            };
        case AssignDigitallyTypes.GET_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                assignments: action.assignments,
            };
        case AssignDigitallyTypes.CREATE_CLASS_SUCCESS:
            let newClass = [...state.classes];
            let newAssignment = [...state.assignments];
            // let newSaveAssignemnt = [...state.saveAssignments];
            if (action.classInfo && action.assignment) {
                newClass.unshift(action.classInfo);
                newAssignment.push(action.assignment);
                // newSaveAssignemnt.push(action.assignment);
            }
            return {
                ...state,
                classes: newClass,
                assignments: newAssignment,
                // saveAssignments: newSaveAssignemnt,
            };
        case AssignDigitallyTypes.UPDATE_CLASSES_WHEN_CHECKED:
            let newAssignments = [...state.assignments];
            let newSaveAssignemnts = [...state.saveAssignments];
            if (action.assignment) {
                newAssignments.push(action.assignment);
                let checkExiste = newSaveAssignemnts.find(
                    (as) =>
                        as.classId == action.assignment.classId &&
                        as.worksheet == action.assignment.worksheet
                );
                if (checkExiste) {
                    // newSaveAssignemnts.find(
                    //     (as) => as.classId == action.assignment.classId
                    // ).status = action.assignment.status;
                    newSaveAssignemnts = newSaveAssignemnts.filter(
                        (as) => as.classId != action.assignment.classId
                    );
                } else {
                    newSaveAssignemnts.push(action.assignment);
                }
                classes = classes.map((c) => {
                    if (c.id == action.assignment.classId) {
                        return new Class({
                            ...c,
                            assignments: [action.assignment],
                        });
                    }
                    return c;
                });
            }
            return {
                ...state,
                classes,
                assignments: newAssignments,
                saveAssignments: newSaveAssignemnts,
            };
        case AssignDigitallyTypes.UPDATE_CLASSES_WHEN_NOT_CHECKED:
            let newSaveAssignment = stringifyAndParse([
                ...state.saveAssignments,
            ]);

            if (action.assignment) {
                let checkExiste = newSaveAssignment.find(
                    (as) =>
                        as.classId == action.assignment.classId &&
                        as.worksheet == action.assignment.worksheet
                );
                if (checkExiste) {
                    // newSaveAssignment.find(
                    //     (as) => as.classId == action.assignment.classId
                    // ).status = action.assignment.status;

                    newSaveAssignment = newSaveAssignment.filter(
                        (as) => as != checkExiste
                    );
                } else {
                    newSaveAssignment.push(action.assignment);
                }
                classes = classes.map((c) => {
                    if (c.id == action.assignment.classId) {
                        return new Class({
                            ...c,
                            assignments: null,
                        });
                    }
                    return c;
                });
            }

            return {
                ...state,
                classes,
                assignments: state.assignments.filter(
                    (as) => as.classId != action.assignment.classId
                ),
                saveAssignments: newSaveAssignment,
            };
        case AssignDigitallyTypes.UPDATE_ASSIGNMENT:
            const newSave = [];
            const assignments = [];
            return {
                ...state,
                classes: [],
                assignments: assignments,
                saveAssignments: newSave,
            };
        default:
            return state;
    }
}
export default assignDigitallyState;
