import { useAppSelector } from "../../redux/hook";
import "./index.scss";
import ListTab from "./ListTab";
import LeftTabContent from "./TabContent";
import { convertImagesToBase64 } from "../../resource-user/utils/convertImg";

const LeftContent = ({
    setShowTabContent,
    showTabContent,
}: {
    setShowTabContent: (value: boolean) => void;
    showTabContent: boolean;
}) => {
    const {isIframe} = useAppSelector(state => state.createWorksheetState);

    // export data and save to div in FE, not push to BE
    // to render faster by bot
    const exportForBot = async() => {
        const imgData = await convertImagesToBase64('content-layer');
        console.log(imgData);
        let divElement = document.getElementById('export-for-bot-content');
        divElement.textContent = imgData[0];
    }

    if (isIframe) {
        return null;
    }

    return (
        <div
            className="left-content-container"
            style={{ width: showTabContent ? "425px" : "auto" }} //fix width leftTabContent = 370
        >
            <ListTab
                showTabContent={showTabContent}
                setShowTabContent={setShowTabContent}
            ></ListTab>

            {showTabContent && (
                <>
                    <div className="line-center"></div>
                    <div
                        style={{
                            maxHeight: "calc(100vh - 52px)",
                            width: "100%",
                        }}
                    >
                        <LeftTabContent setShowTabContent={setShowTabContent} />
                    </div>
                </>
            )}
        </div>
    );
};
export default LeftContent;
