import { MenuItem, Select } from "@mui/material";
import ConstantsWordScramble from "../../../../shared/utils/ConstantsWordScramble";
import {
    ArrowIcon,
    LetterCaseDownIcon,
    LetterCaseUpIcon,
    ListDashedIcon,
    ListDottedIcon,
    ListNumberIcon,
    NoneIcon,
    SeparatorsDashedIcon,
    SeparatorsShapeIcon,
    SeparatorsSlashIcon,
    SeparatorsSpaceIcon,
    StrokeDashedIcon,
    StrokeDottedIcon,
    StrokeHandwritingIcon,
    StrokeShapeIcon,
    StrokeSolidIcon,
    TickedIcon,
} from "../util/icon";
import "./index.scss";
import { IActivity } from "../../../../shared/models/WorkSheetCreator";
import Config from "../../../../shared/utils/config";
import { useAppDispatch } from "../../../../redux/hook";
import {
    changeLetterStyle,
    changeListStyle,
    changeSeparatorStyle,
    changeStrokeStyle,
} from "../../../../redux/async/wordScrambleThunk";
import { event } from "../util";

const LIST_STYLES = [
    {
        id: ConstantsWordScramble.LIST_STYLE.NONE,
        icon: <NoneIcon />,
        action: "list_style_none",
    },
    {
        id: ConstantsWordScramble.LIST_STYLE.NUMBER,
        icon: <ListNumberIcon />,
        action: "list_style_number",
    },
    {
        id: ConstantsWordScramble.LIST_STYLE.DOTTED,
        icon: <ListDottedIcon />,
        action: "list_style_bullet",
    },
    {
        id: ConstantsWordScramble.LIST_STYLE.DASHED,
        icon: <ListDashedIcon />,
        action: "list_style_dash",
    },
];

const LETTER_CASE = [
    {
        id: ConstantsWordScramble.LETTER_STYLE.NONE,
        icon: <NoneIcon />,
        action: "lettercase_none",
    },
    {
        id: ConstantsWordScramble.LETTER_STYLE.UPPERCASE,
        icon: <LetterCaseUpIcon />,
        action: "lettercase_uppercase",
    },
    {
        id: ConstantsWordScramble.LETTER_STYLE.LOWERCASE,
        icon: <LetterCaseDownIcon />,
        action: "lettercase_lowercase",
    },
];

const STROKE_STYLES = [
    {
        id: ConstantsWordScramble.STROKE_STYLE.SOLID,
        icon: <StrokeSolidIcon />,
        name: "Solid",
        action: "stroke_style_solid",
    },
    {
        id: ConstantsWordScramble.STROKE_STYLE.DASHED,
        icon: <StrokeDashedIcon />,
        name: "Dashed",
        action: "stroke_style_dashed",
    },
    {
        id: ConstantsWordScramble.STROKE_STYLE.DOTTED,
        icon: <StrokeDottedIcon />,
        name: "Dotted",
        action: "stroke_style_dotted",
    },
    {
        id: ConstantsWordScramble.STROKE_STYLE.SHAPE,
        icon: <StrokeShapeIcon />,
        name: "Shape",
        action: "stroke_style_shape",
    },
    {
        id: ConstantsWordScramble.STROKE_STYLE.HANDWRITING,
        icon: <StrokeHandwritingIcon />,
        name: "Handwriting",
        action: "stroke_style_handwriting",
    },
];

const SEPARATORS = [
    {
        id: ConstantsWordScramble.SEPARATOR_STYLE.SLASH,
        icon: <SeparatorsSlashIcon />,
        action: "separator_1",
    },
    {
        id: ConstantsWordScramble.SEPARATOR_STYLE.SPACE,
        icon: <SeparatorsSpaceIcon />,
        action: "separator_2",
    },
    {
        id: ConstantsWordScramble.SEPARATOR_STYLE.DASHED,
        icon: <SeparatorsDashedIcon />,
        action: "separator_3",
    },
    {
        id: ConstantsWordScramble.SEPARATOR_STYLE.SHAPE,
        icon: <SeparatorsShapeIcon />,
        action: "separator_4",
    },
];

const StyleSection = ({ activity }: { activity: IActivity }) => {
    const dispatch = useAppDispatch();
    const type = activity.type;
    const isWordScramble = type === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE;
    let stroke = STROKE_STYLES;

    if (!isWordScramble) {
        stroke = stroke.filter(
            (s) => s.id !== ConstantsWordScramble.STROKE_STYLE.SHAPE
        );
    }

    return (
        <div className="word-scramble-style-container">
            <ButtonGroup
                title="List Style"
                value={activity.listStyle}
                items={LIST_STYLES}
                handleChange={(value: number, action: string) => {
                    dispatch(changeListStyle({ list: value }));
                    event(type, action);
                }}
            />
            <Dropdown
                title="Stroke Style"
                defaultValue={activity.strokeStyle}
                items={stroke}
                handleChange={(value, action) => {
                    dispatch(changeStrokeStyle({ stroke: value }));
                    event(type, action);
                }}
            />
            <ButtonGroup
                title="Lettercase"
                value={activity.letterStyle}
                items={LETTER_CASE}
                handleChange={(value, action) => {
                    dispatch(changeLetterStyle({ letterStyle: value }));
                    event(type, action);
                }}
            />
            {!isWordScramble && (
                <Dropdown
                    title="Separators"
                    defaultValue={activity.separatorStyle}
                    items={SEPARATORS}
                    handleChange={(value, action) => {
                        dispatch(
                            changeSeparatorStyle({ separatorStyle: value })
                        );
                        event(type, action);
                    }}
                    left={164}
                    scale={1.15}
                />
            )}
        </div>
    );
};

const ButtonGroup = ({
    title,
    items,
    value,
    handleChange,
}: {
    title: string;
    items: { id: number; icon: any; action?: string }[];
    value: number;
    handleChange: Function;
}) => {
    return (
        <div className={`button-group-container ws-container`}>
            <div className="title">{title}</div>
            <div className="button-group">
                {items.map(({ id, icon, action }, index) => (
                    <div
                        key={id}
                        onClick={() => {
                            if (id !== value) {
                                handleChange(id, action);
                            }
                        }}
                        className={`item ${id === value ? " active " : ""}`}
                    >
                        {icon}
                    </div>
                ))}
            </div>
        </div>
    );
};

const Dropdown = ({
    title,
    items,
    defaultValue,
    handleChange,
    left = 110,
    scale,
}: {
    title: string;
    items: { id: number; name?: string; icon: any; action?: string }[];
    defaultValue: number;
    handleChange: Function;
    left?: number;
    scale?: number;
}) => {
    return (
        <div className="ws-container dropdown-container">
            <div className="title">{title}</div>
            <Select
                className="select"
                value={defaultValue}
                onChange={(e) => {
                    const value = e.target.value as number;
                    if (value !== defaultValue) {
                        const action =
                            items.find((item) => item.id === value)?.action ??
                            "";
                        handleChange(value, action);
                    }
                }}
                MenuProps={{
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    anchorOrigin: {
                        vertical: -8,
                        horizontal: left,
                    },
                    PaperProps: {
                        style: {
                            padding: 0,
                            borderRadius: 6,
                        },
                    },
                    MenuListProps: {
                        style: {
                            padding: 4,
                        },
                    },
                }}
                SelectDisplayProps={{
                    style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    },
                }}
                IconComponent={ArrowIcon}
            >
                {items.map(({ id, name, icon }) => {
                    return (
                        <MenuItem
                            sx={{
                                svg: {
                                    transform: `scale(${scale})`,
                                },
                            }}
                            key={id}
                            value={id}
                            className="word-scramble-dropdown-item"
                        >
                            <div className={`left ${!name ? "no-name" : ""}`}>
                                <div className="tick-icon">
                                    <TickedIcon />
                                </div>
                                {icon}
                            </div>
                            {name && <div className="right">{name}</div>}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export default StyleSection;
