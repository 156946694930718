import "./index.scss";
import { convertStringToComponentID, getPrefixImage, loadFonts } from "../../../../../../../utils";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hook';
import { addMissingFontFamilyList } from '../../../../../../../redux/reducers/createWorksheet';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchFontFamilyBar from './search-font-family-bar';

const FontFamilyPicker = ({
    fontFamily,
    isDisplayFontFamilyDropdown,
    setIsDisplayFontFamilyPanel,
    handleChangeFontFamily,
}: {
    fontFamily: string;
    isDisplayFontFamilyDropdown: boolean;
    setIsDisplayFontFamilyPanel: Function;
    handleChangeFontFamily: Function;
}) => {
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const scrollToCurrentFont = () => {
        const lastChildElement = ref.current?.querySelector(`#${convertStringToComponentID(fontFamily)}`);
        lastChildElement?.scrollIntoView({
            behavior: "smooth",
            block: 'start',
            inline: 'nearest',
        });
    };
    const { fontFamilyList, missingFontFamilyList, currentStore } = useAppSelector((state) => state.createWorksheetState);

    const [fontFamilies, setFontFamilies] = useState(fontFamilyList);
    const [searchKeyword, setSearchKeyword] = useState<string>("");

    useEffect(() => {
        if (!fontFamilyList.find((e) => e.name === fontFamily)) {
            // window.alert(`Font family ${fontFamily} is missing. Please upload this font to server.`);
            if (!missingFontFamilyList.includes(fontFamily)) {
                dispatch(addMissingFontFamilyList(fontFamily));
            }
        }
    }, []);

    useEffect(() => {
        if (!isDisplayFontFamilyDropdown) handleKeywordChange('');
    }, [isDisplayFontFamilyDropdown]);

    const handleKeywordChange = (value: string) => {
        setSearchKeyword(value);
        const result = value === '' ? fontFamilyList : fontFamilyList.filter((font) => font.name.toLowerCase().includes(searchKeyword.toLowerCase()));
        setFontFamilies(result);
    };

    return (
        <>
            <div className="font-family-picker-container">
                <div
                    className="input-font-family"
                    style={{ fontFamily: fontFamily }}
                >
                    {fontFamily?.length ? fontFamily : "Multiple fonts…"}
                </div>
                <div
                    ref={ref}
                    style={{
                        position: "absolute",
                        top: 30,
                        left: 0,
                        zIndex: 2,
                        width: 200,
                        height: 200,
                        overflow: "auto",
                        background: "rgba(33, 33, 33,0.8)",
                        borderRadius: 4,
                        visibility: isDisplayFontFamilyDropdown
                            ? "visible"
                            : "hidden",
                    }}
                >
                    {fontFamilies.map((font, index) => {
                        return (
                            <div
                                id={convertStringToComponentID(font.name)}
                                key={index}
                                className="font-family-item"
                                style={{
                                    fontFamily: font.name,
                                    color: `${font.store.includes(currentStore) ? 'red' : null}`,
                                    backgroundColor: font.name === fontFamily ? '#85daff' : '',
                                }}
                                onClick={async () => {
                                    setIsDisplayFontFamilyPanel(false);
                                    await loadFonts(font);

                                    handleChangeFontFamily(font.name);
                                }}
                            >
                                {font.name}
                                {font.store.includes(currentStore) && (
                                    <FavoriteIcon fontSize="small" className="float-end"/>
                                )}
                            </div>
                        );
                    })}
                    <SearchFontFamilyBar
                        searchKeyword={searchKeyword}
                        handleKeywordChange={handleKeywordChange}
                    />
                </div>
            </div>
            <div
                className="button-change-font-family"
                onClick={() => {
                    setIsDisplayFontFamilyPanel(!isDisplayFontFamilyDropdown);
                    if (!isDisplayFontFamilyDropdown) {
                        scrollToCurrentFont();
                    }
                }}
            >
                <img
                    className="button-change-font-family-image"
                    src={
                        getPrefixImage() +
                        "/images/create-worksheet/item-popover/dropdown_icon.svg"
                    }
                    style={{ height: 10, width: 11 }}
                />
            </div>
        </>
    );
};

export default FontFamilyPicker;
