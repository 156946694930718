import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ConstantsResource from "../../resource-user/utils/ConstantsResource";
import { IResourceItem } from "../../shared/models/resourceItem";
import Config from "../../shared/utils/config";
import {
    dropFileThunk,
    requestGetResourceByType,
    uploadResourceThunk,
    searchResourceByKeyword,
    getTemplate,
    searchTemplateByKeyword,
    getResourceProjectData,
} from "../async/leftData";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";
type BackgroundUsing = {
    pageIndex: number;
    resourceItem: IResourceItemNew;
};
type initialStateType = {
    activeTab: number;
    searchDataValue: any[];
    dataTab: any[];
    loading: boolean;
    isMax: boolean;
    searchKeyword: string;
    tabActiveActivities: string;
    listBackgroundUsing: BackgroundUsing[];
    resourceItemEditing: string;
    currentInfo?: IResourceItemNew;
    isShowButtonAnswerKey: boolean;
    isSearchTemplate: boolean;
    isShowListActivities: boolean;
    activityToReplace: string;
    backgroundOrientation: string;
};
const initialState: initialStateType = {
    activeTab: Config.RESOURCE_ELEMENT,
    searchDataValue: [],
    dataTab: [],
    loading: false,
    isMax: false,
    searchKeyword: "",
    tabActiveActivities: ConstantsResource.TAB_ACTIVE.WORKSHEET_INFO,
    listBackgroundUsing: [],
    resourceItemEditing: "",
    currentInfo: null,
    isShowButtonAnswerKey: true,
    isSearchTemplate: false,
    isShowListActivities: false,
    activityToReplace: "",
    backgroundOrientation: "all",
};

export const leftDataSlice = createSlice({
    name: "leftData",
    initialState,
    reducers: {
        changeActiveTab: (state, action: PayloadAction<number>) => {
            if (state.activeTab !== action.payload) {
                state.activeTab = action.payload;
                state.dataTab = [];
                state.loading = false;
            }
        },
        changeTabActiveActivities: (state, action: PayloadAction<string>) => {
            state.tabActiveActivities = action.payload;
        },
        setResourceItemEditing: (state, action: PayloadAction<string>) => {
            state.resourceItemEditing = action.payload;
        },
        updateCurentInfo: (
            state,
            action: PayloadAction<IResourceItemNew | null>
        ) => {
            state.currentInfo = action.payload;
        },
        updateShowButtonAnswerKey: (state, action: PayloadAction<boolean>) => {
            state.isShowButtonAnswerKey = action.payload;
        },

        changeActivityToReplace: (state, action: PayloadAction<string>) => {
            state.activityToReplace = action.payload;
        },
        toggleShowListActivities: (state, action: PayloadAction<boolean>) => {
            state.isShowListActivities = action.payload;
        },
        changeBackgroundOrientation: (state, action: PayloadAction<string>) => {
            if (state.backgroundOrientation !== action.payload) {
                state.backgroundOrientation = action.payload;
                state.dataTab = [];
            }
        },
        updateTabData: (state, action: PayloadAction<any[]>) => {
            state.dataTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        // [requestGetResourceByType, dropFileThunk].map((el) => {
        //     return builder.addCase(el.pending, (state) => {
        //         state.loading = true;
        //     });
        // });
        builder.addCase(dropFileThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(requestGetResourceByType.pending, (state, action) => {
            state.loading = true;
            // Button clear search-keywords
            // if (action.meta.arg.offset === 1 && state.searchKeyword) {
            if (action.meta.arg.offset === 1) {
                state.dataTab = [];
                state.searchKeyword = "";
                state.isMax = false;
            }
        });
        builder.addCase(searchResourceByKeyword.pending, (state, action) => {
            state.loading = true;
            if (
                action.meta.arg.offset === 1 &&
                action.meta.arg.keyword !== state.searchKeyword
            ) {
                state.dataTab = [];
                state.isMax = false;
            }
            state.searchKeyword = action.meta.arg.keyword;
        });
        builder.addCase(
            requestGetResourceByType.fulfilled,
            (
                state,
                action: PayloadAction<{
                    data: IResourceItem[];
                    typeResource: number[];
                }>
            ) => {
                if (action.payload.data.length === 0) {
                    state.isMax = true;
                }
                action.payload?.data.forEach((el) => {
                    state.dataTab.push(el);
                });
                state.loading = false;
            }
        );
        builder.addCase(
            uploadResourceThunk.fulfilled,
            (state, action: PayloadAction<IResourceItem[]>) => {
                state.dataTab = [...state.dataTab, ...action.payload];
                state.loading = false;
            }
        );
        builder.addCase(
            searchResourceByKeyword.fulfilled,
            (
                state,
                action: PayloadAction<{
                    data: IResourceItem[];
                    typeResource: number[];
                    keyword: string;
                }>
            ) => {
                if (action.payload.data.length === 0) {
                    state.isMax = true;
                }
                state.dataTab = state.dataTab.concat(...action.payload?.data);
                state.loading = false;
                state.searchKeyword = action.payload.keyword;
            }
        );
        builder.addCase(getTemplate.pending, (state, action) => {
            state.loading = true;
            state.isSearchTemplate = false;
            // Button clear search-keywords
            if (action.meta.arg.offset === 0 && state.searchKeyword) {
                state.dataTab = [];
                state.searchKeyword = "";
            }
        });
        builder.addCase(
            getTemplate.fulfilled,
            (
                state,
                action: PayloadAction<{
                    data: any;
                }>
            ) => {
                if (action.payload.data.length === 0) {
                    state.isMax = true;
                }
                action.payload?.data.forEach((el) => {
                    state.dataTab.push(el);
                });
                state.loading = false;
            }
        );
        builder.addCase(searchTemplateByKeyword.pending, (state, action) => {
            state.loading = true;
            if (
                action.meta.arg.offset === 0 &&
                action.meta.arg.keyword !== state.searchKeyword
            ) {
                state.dataTab = [];
                state.isMax = false;
            }
            state.searchKeyword = action.meta.arg.keyword;
        });
        builder.addCase(searchTemplateByKeyword.fulfilled, (state, action) => {
            if (action.payload?.data?.length === 0) {
                state.isMax = true;
            }
            state.searchKeyword
                ? action.payload?.data.forEach((el) => {
                      state.dataTab.push(el);
                  })
                : (state.dataTab = [...action.payload?.data]);
            state.loading = false;
            state.searchKeyword = action.meta.arg.keyword;
            state.isSearchTemplate = true;
        });
        builder.addCase(getResourceProjectData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getResourceProjectData.fulfilled, (state, action) => {
            state.dataTab = action.payload.data;
            state.loading = false;
        });

        [
            uploadResourceThunk.rejected,
            dropFileThunk.rejected,
            requestGetResourceByType.rejected,
            searchResourceByKeyword.rejected,
            getTemplate.rejected,
            searchTemplateByKeyword.rejected,
        ].map((actionRejected) => {
            return builder.addCase(actionRejected, (state) => {
                state.loading = false;
            });
        });
    },
});
export const {
    changeActiveTab,
    changeTabActiveActivities,
    setResourceItemEditing,
    updateCurentInfo,
    updateShowButtonAnswerKey,
    changeActivityToReplace,
    toggleShowListActivities,
    changeBackgroundOrientation,
    updateTabData,
} = leftDataSlice.actions;
