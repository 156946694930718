import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    removeResourceItems,
    selectElements,
    updateManyResourceByType,
    updateResourceWordBank,
} from "../../../../../redux/reducers/createWorksheet";
import { setResourceItemEditing } from "../../../../../redux/reducers/leftData";
import { getResourceItemsByActivityAndType } from "../../../../../redux/selector";
import { AnswerActivity } from "../../../../../shared/models/questionActivity";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import {
    checkWordIsSelected,
    getHeightOfWordBank,
    getTextContentWithStyle,
    handleCalculateWord, removeOneInstanceFromArray,
    shuffleArray
} from "../../../../../utils";
import CreateWSConfig from "../../../../../utils/create-ws-config";
import {
    getSizeBoxText
} from "../../../../../utils/draw";
import "./index.scss";
import WordView from "./wordView";
import { IconSVG, svgPath } from "../../../../../assets/icon/icons";
import { updateFillTheBlankSentences } from "../../../../../redux/async/fillTheBlankThunk";
import {
    EventTracking,
    sendEvent,
} from "../../../../../resource-user/utils/event";
const SentenceItem = ({
    resourceItem,
    index,
    wordBankResource,
    activityId,
}: {
    resourceItem: IResourceItemNew;
    index: number;
    wordBankResource: IResourceItemNew;
    activityId: string;
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [text, setText] = useState("");

    const [firstRender, setFirstRender] = useState(true);
    const resourceItemEditing = useAppSelector(
        (state) => state.leftDataState.resourceItemEditing
    );
    const listStyle = useAppSelector(
        (state) => state.createWorksheetState.currentActivity?.listStyle
    );
    const heightGap = useAppSelector(
        (state) => state.createWorksheetState.currentActivity.heightGap
    );
    const resourceFillTheBlank = useAppSelector((state) =>
        getResourceItemsByActivityAndType()(state, {
            activityId: activityId,
            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        })
    );
    const ref = useRef(null);
    const [focus, setFocus] = useState(false);
    const [hoverIconClear, setHoverIconClear] = useState(false);
    useEffect(() => {
        setText(resourceItem?.question?.question ?? "");
    }, [resourceItem?.id]);

    const dispatch = useAppDispatch();

    const handleEnterKey = (event) => {
        if (event.key === "Enter") {
            onChangeEdited();
        }
    };
    useEffect(() => {
        if (resourceItem.id === resourceItemEditing) {
            setIsEditing(true);
            if (ref?.current) {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        } else {
            if (!resourceItemEditing) {
                setIsEditing(false);
            }
        }
    }, [resourceItemEditing]);
    useEffect(() => {
        const listWords = resourceItem.question?.question.trim().split(" ");
        const newWordBank = resourceItem.question?.answers.map(
            (answer) => listWords[answer.text]
        );
    }, [isEditing]);
    const onChangeEdited = () => {
        if (text.trim().length === 0) {
            handleClearSentence();
        } else {
            const prevText = resourceItem.question?.question ?? "";
            const newListWords = text.trim().split(" ");
            const newResourceItem = new ResourceItemNew({ ...resourceItem });
            let listRemove: string[] = [];
            if (prevText.split(" ").length !== text.split(" ").length) {
                const newAnswer = newResourceItem.question.answers.map((el) => {
                    if (newListWords[el.indexWord] === el.text) {
                        return new AnswerActivity({
                            ...el,
                        });
                    } else {
                        if (wordBankResource) {
                            listRemove.push(el.text);
                        }
                        return null;
                    }
                });
                newResourceItem.question.answers = newAnswer.filter((el) => el);
            }
            let w = ConstantsTool.MAX_WIDTH_TEXT_FILL_IN_BLANK;
            let textEdited = getTextContentWithStyle(
                resourceItem.question.indexQuestion,
                text.trim(),
                listStyle
            );
            newResourceItem.question.question = text.trim();
            newResourceItem.textAttribute.content = textEdited;

            let size = getSizeBoxText({
                textAttribute: newResourceItem.textAttribute,
                width: newResourceItem.width,
                type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                listStyle: listStyle
            });

            newResourceItem.width = size?.width;
            newResourceItem.height = size?.height;
            setIsEditing(false);

            if (listRemove.length && wordBankResource) {
                let wordBanks = removeOneInstanceFromArray(
                    [...wordBankResource.wordBanks],
                    listRemove
                );
                dispatch(
                    updateResourceWordBank({
                        resourceItem: new ResourceItemNew({
                            ...wordBankResource,
                            type: ConstantsTool.TYPE_WORD_BANK,
                            wordBanks: wordBanks,
                        }),
                    })
                );
            }
            dispatch(
                updateFillTheBlankSentences({
                    pageIndex: newResourceItem?.pageIndex ?? 0,
                    resourceItems: [newResourceItem],
                    isAddHistory: true,
                })
            );
        }
    };
    const handleToggleWord = (word: string, wordIndex: number) => {
        let updatedResource = new ResourceItemNew({
            ...resourceItem,
        });
        let isAddNewAnswer = false;
        if (checkWordIsSelected(resourceItem, wordIndex)) {
            updatedResource.question.answers =
                updatedResource.question.answers.filter(
                    (answer) => answer.indexWord !== wordIndex
                );
        } else {
            updatedResource.question.answers = [
                ...updatedResource?.question?.answers,
                new AnswerActivity({
                    id: new Date().getTime().toString(),
                    text: word,
                    isCorrect: true,
                    indexWord: wordIndex,
                }),
            ];

            isAddNewAnswer = true;
        }
        dispatch(
            updateFillTheBlankSentences({
                pageIndex: resourceItem?.pageIndex ?? 0,
                resourceItems: [updatedResource],
                isAddHistory: true,
            })
        );
        if (wordBankResource?.id) {
            let wordBanks: string[] = [...wordBankResource.wordBanks];
            if (isAddNewAnswer) {
                wordBanks.push(word);
            } else {
                let indexRemove = wordBanks.indexOf(word);
                if (indexRemove > -1) {
                    wordBanks.splice(indexRemove, 1);
                }
            }
            if (wordBanks.length > 1) {
                wordBanks = shuffleArray(wordBanks);
            }
            dispatch(
                updateResourceWordBank({
                    resourceItem: new ResourceItemNew({
                        ...wordBankResource,
                        type: ConstantsTool.TYPE_WORD_BANK,
                        wordBanks: wordBanks,
                    }),
                })
            );
        }
    };

    const handleClearSentence = () => {
        let diffHeightWordBank = 0;
        if (wordBankResource) {
            let wordBanks = removeOneInstanceFromArray(
                [...wordBankResource.wordBanks],
                resourceItem.question.answers.map((answer) => answer.text)
            );
            let heightWordBank = getHeightOfWordBank(
                wordBanks,
                wordBankResource
            );
            dispatch(
                updateResourceWordBank({
                    resourceItem: new ResourceItemNew({
                        ...wordBankResource,
                        type: ConstantsTool.TYPE_WORD_BANK,
                        wordBanks: wordBanks,
                    }),
                })
            );
            diffHeightWordBank = heightWordBank - wordBankResource.height;
        }
        dispatch(
            removeResourceItems({
                resourceIds: [resourceItem.id],
                pageIndex: resourceItem.pageIndex,
            })
        );
        dispatch(
            updateManyResourceByType({
                pagesWs: handleCalculateWord({
                    diffHeight: diffHeightWordBank,
                    resourceFillTheBlank: resourceFillTheBlank.filter(
                        (e) => e.id !== resourceItem.id
                    ),
                    listStyle,
                    action: CreateWSConfig.LIST_ACTION
                        .ACTION_ADD_REMOVE_QUESTION,
                    resourceUpdateId: resourceItem.id,
                    heightGap: heightGap,
                }),
                type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
            })
        );
        dispatch(setResourceItemEditing(""));
    };

    return (
        <div ref={ref}>
            {isEditing ? (
                <div
                    className={
                        "box-input-fitb " +
                        (focus ? "box-input-fitb-focus" : "")
                    }
                >
                    <span
                        className="text-index"
                        style={{ opacity: focus ? 1 : 0.24 }}
                    >
                        {index + 1}.
                    </span>
                    <TextField
                        onClick={(e) => e.stopPropagation()}
                        className="input-field"
                        autoFocus
                        multiline
                        onChange={(e: any) => setText(e.target.value)}
                        value={text}
                        onKeyUp={handleEnterKey}
                        onBlur={() => {
                            onChangeEdited();
                            setFocus(false);
                            if (text) {
                                sendEvent(EventTracking.fitb_type_sentence);
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                sendEvent(EventTracking.fitb_enter);
                                sendEvent(EventTracking.fitb_type_sentence);
                                event.preventDefault();
                            }
                            if (event.key === "v" && event.metaKey) {
                                sendEvent(EventTracking.fitb_type_sentence);
                            }
                        }}
                        onFocus={(event) => {
                            setFocus(true);
                            event.preventDefault();
                            const { target } = event;
                            target.focus();
                            target.setSelectionRange(text.length, text.length);
                        }}
                        onMouseLeave={() => setIsHovering(false)}
                    />
                </div>
            ) : (
                <div
                    className="highlighted-sentence"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        className="text-side"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(true);
                            dispatch(setResourceItemEditing(resourceItem.id));
                            dispatch(
                                selectElements({
                                    pageIndex: resourceItem.pageIndex,
                                    resourceSelected: [resourceItem],
                                })
                            );
                        }}
                    >
                        <span className="index">{index + 1}.</span>
                        {text
                            .trim()
                            .split(" ")
                            .map((word, wordIndex) => {
                                let selected =
                                    resourceItem?.question?.answers?.some(
                                        (answer) =>
                                            answer.indexWord === wordIndex
                                    );
                                return (
                                    <WordView
                                        key={wordIndex}
                                        word={word}
                                        wordIndex={wordIndex}
                                        selected={selected}
                                        handleToggleWord={handleToggleWord}
                                    />
                                );
                            })}
                    </div>
                    {isHovering && (
                        <div className="icon-hover">
                            <div
                                className="clear-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearSentence();
                                }}
                                onMouseEnter={() => setHoverIconClear(true)}
                                onMouseLeave={() => setHoverIconClear(false)}
                            >
                                <img
                                    className="icon"
                                    src={
                                        hoverIconClear
                                            ? svgPath(IconSVG.delete_fitb)
                                            : svgPath(IconSVG.delete_fitb_black)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default SentenceItem;
