import React, { useEffect } from "react";
import "./index.scss";
import { IShapeAttribute } from "../../../../shared/models/shapeAttribute";
import { getListCommands, getSizeLine } from "../../../utils/shape";
import { makeStrokeDasharray } from "../../../utils/table";

function LineView({
    width,
    height,
    line,
    color,
    position = "absolute",
    setWidthHead,
    opacity = 1,
}: {
    width: number;
    height: number;
    line: IShapeAttribute;
    color: string;
    position?: "absolute" | "static" | "relative";
    setWidthHead?: any;
    opacity?: number;
}) {
    const maxX = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 2]) >
                parseFloat(maxElement[maxElement.length - 2])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return element[element.length - 2];
    };

    const minX = (commands: string[][]) => {
        const element = commands.reduce((maxElement, currentElement) => {
            if (
                parseFloat(currentElement[currentElement.length - 2]) <
                parseFloat(maxElement[maxElement.length - 2])
            ) {
                return currentElement;
            }
            return maxElement;
        }, commands[0]);
        return element[element.length - 2];
    };

    let { path, pathStart, pathEnd, heightContent, topContent } = getSizeLine({
        width,
        height,
        line,
    });

    useEffect(() => {
        let minXStart = pathStart
                ? parseFloat(minX(getListCommands(pathStart)))
                : 0,
            maxXStart = pathStart
                ? parseFloat(maxX(getListCommands(pathStart)))
                : 0,
            minXend = pathEnd ? parseFloat(minX(getListCommands(pathEnd))) : 0,
            maxXend = pathEnd ? parseFloat(maxX(getListCommands(pathEnd))) : 0;
        if (setWidthHead) {
            setWidthHead(maxXend - minXend - (maxXStart - minXStart));
        }
    }, [height, line]);
    const strokeWidth = height;

    return (
        <div
            style={{ position, height: heightContent, width, top: topContent }}
        >
            <div className="V7MmMA" style={{ opacity }}>
                <svg className="Fe_H_Q" style={{ stroke: color, fill: color }}>
                    <g style={{ transition: "none" }}>
                        <g
                            className="_682gpw"
                            style={{ touchAction: "pan-x pan-y pinch-zoom" }}
                        >
                            <path
                                d={path}
                                strokeLinecap="butt"
                                strokeWidth={strokeWidth}
                                fill="none"
                                pointerEvents="auto"
                                opacity="0"
                                style={{ transition: "none" }}
                            ></path>
                            <path
                                d={path}
                                strokeLinecap="butt"
                                strokeWidth={strokeWidth}
                                fill="none"
                                pointerEvents="auto"
                                strokeDasharray={makeStrokeDasharray({
                                    strokeWidth,
                                    strokeDasharray: line.strokeDasharray,
                                    length: width,
                                })}
                                style={{ transition: "none" }}
                            ></path>
                        </g>
                        {pathStart && (
                            <path
                                fill={line.pathStart!.fill}
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeWidth={strokeWidth}
                                d={pathStart}
                                style={{ transition: "none" }}
                            ></path>
                        )}
                        {pathEnd && (
                            <path
                                fill={line.pathEnd!.fill}
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeWidth={strokeWidth}
                                d={pathEnd}
                                style={{ transition: "none" }}
                            ></path>
                        )}
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default LineView;
