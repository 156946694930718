import { IWord } from "../../../shared/models/crossword";

export const scrollToItemId = (id: string, focusElementSelector?: string) => {
    const element = document.getElementById(id);
    const container: any = document.querySelector(
        "#manual-input-container .simplebar-content-wrapper"
    );
    const listElements: any = document.querySelector(
        "#manual-input-container .simplebar-content"
    );

    if (element && container && listElements) {
        let scroll =
            element.getBoundingClientRect().top -
            listElements.getBoundingClientRect()?.top;

        container?.scrollTo({
            top: Math.abs(scroll) - element.getBoundingClientRect().height,
            behavior: "smooth",
        });

        const focusElement: any = document.querySelector(focusElementSelector);
        if (focusElement) {
            focusElement?.focus({ preventScroll: true });
            setCursorToEnd(focusElement);
        }
        return true;
    }

    return false;
};

export const setCursorToEnd = (element) => {
    if (
        typeof element?.selectionStart === "number" &&
        typeof element?.selectionEnd === "number"
    ) {
        element.selectionStart = element?.value?.length ?? 0;
        element.selectionEnd = element?.value?.length ?? 0;
    }
};

export const convertWordToStr = (inputWordArr: IWord[]) => {
    return inputWordArr
        .map((e) => {
            let str = e.value;

            if (e.clue.length > 0) {
                str += ", " + e.clue;
            }

            return str;
        })
        .join("\n");
};

export const convertStrToWord = (inputWordsStr: string) => {
    const validWords: IWord[] = [];
    const inputWordsStrArr = inputWordsStr.split("\n").filter((item) => {
        return item.trim().length > 0;
    });
    inputWordsStrArr.forEach((item) => {
        let index = item.indexOf(",");
        if (index === -1) {
            index = item.length;
        }
        const value = item.slice(0, index).trim();
        const clue = item.slice(index + 1).trim();

        validWords.push({ value, clue });
    });

    return validWords;
};

export const regexCheckError = (value: string) => {
    const testValue = value.trim();
    const result = {
        error: false,
        valid: value,
        invalid: value,
    };
    const regexInvalidLetter = /[^a-zA-Z ]/g;
    if (regexInvalidLetter.test(testValue)) {
        const validValue = value.replaceAll(regexInvalidLetter, "");
        result.error = true;
        result.valid = validValue;
    }

    return result;
};

export const checkWordsError = (words: IWord[]) => {
    const errorWords: any[] = [];
    const result = words.map((word) => {
        let value = word.value;
        const { error, valid, invalid } = regexCheckError(value);

        if (error) {
            errorWords.push({ invalid: invalid, valid: valid });
            value = valid;
        }

        return {
            ...word,
            value,
        };
    });

    return {
        error: errorWords.length > 0,
        errorWords,
        words: result,
    };
};
