import React from "react";

const NextIconSvg = ({
    active,
    width,
    height,
    arrow,
}: {
    active?: boolean;
    width?: string;
    height?: string;
    arrow?: boolean;
}) => {
    if (arrow) {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.16699 10.0001H15.8337M15.8337 10.0001L10.0003 4.16675M15.8337 10.0001L10.0003 15.8334"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg
            width={width ?? "16"}
            height={height ?? "16"}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.99968 5.41628L8.58634 8.00294L5.99968 10.5896C5.87484 10.7142 5.80469 10.8833 5.80469 11.0596C5.80469 11.236 5.87484 11.4051 5.99968 11.5296C6.25968 11.7896 6.67968 11.7896 6.93968 11.5296L9.99968 8.46961C10.2597 8.20961 10.2597 7.78961 9.99968 7.52961L6.93968 4.46961C6.67968 4.20961 6.25968 4.20961 5.99968 4.46961C5.74634 4.72961 5.73968 5.15628 5.99968 5.41628Z"
                fill="#212121"
                fillOpacity={active ? 1 : 0.52}
            />
        </svg>
    );
};

export default NextIconSvg;
