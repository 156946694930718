const Routes = {
    HOME: "/",
    HOME_BROWSING_WORKSHEETS: "/worksheets",
    PAGE404: "/404",
    COLLECTION: "/collection",
    PRINTABLE_INTERACTIVE: "printable-interactive",

    WS_NO_INDEX: "abcws",

    INTERACTIVE_WORKSHEET_MAKER: "/interactive-worksheet-maker",
    CREATE_WS: "/create-worksheet",
    CREATE_WS_INTERACTIVE: "/interactive-worksheet-maker",
    CREATE_WS_COLLECTION: "/created-worksheet",
    MY_CREATED_WS: "/created-worksheet",
    SEARCH: "/search",
    PROFILE: "/profile",
    UTM_SOURCE_LIVE_WS_CORRECT_ANSWER: "livews_correct_answer",
    INTERACTIVE_WORKSHEET: "/interactive-worksheet",
    DOWNLOAD_WS: "/download",
    VERIFY_STUDENT: "/classroom/verify-student",
    MY_COLLECTIONS: "/my-collections",
    MANAGE_ACCOUNT: "/manage-account",
    REFER_A_FRIEND: "/refer-a-friend",
    BACK_TO_SCHOOL: "/back-to-school-worksheets",
    WORD_SEARCH_MAKER: "/word-search-maker",
    HANDWRITING: "/handwriting-worksheet-generator",
    FILL_IN_BLANK: "/fill-in-the-blank-generator",
    WORD_SEARCH: "/word-search",
    HANDWRITING_WORKSHEET: "/handwriting-worksheet-generator",
    MANAGE_CLASSROOM: "/manage-classroom",
    NAME_TRACING: "/name-tracing-generator",
    MULTIPLE_CHOICES: "/multiple-choices-generator",
    CROSSWORD: "/crossword-puzzle-maker",
    CLASS_ROOM: "/classroom",
    WORKSHEET_MAKER: "/worksheet-maker",
    CUSTOMIZE_WORKSHEET: "/customize-worksheet",
    HALLOWEEN_WORKSHEETS: "/halloween-worksheets",
    CHRISTMAS_WORKSHEETS: "/christmas",
    MULTIPLICATION: "/multiplication-worksheet-generator",
    ADDITION: "/addition-worksheet-generator",
    SUBTRACTION: "/subtraction-worksheet-generator",
    DIVISION: "/division-worksheet-generator",
    WORD_SCRAMBLE: "/word-scramble-generator",
    SENTENCE_SCRAMBLE: "/sentence-scramble-generator",
    HASH_TOOL: "#create",
    REFERRAL_LOGIN: "/referral-login",
    HASH_TOOL_V1: "#convert",
};

export default Routes;
