import { IQuestionActivity } from "../../../../../shared/models/questionActivity";
import QuestionDetail from "./QuestionDetail";
import QuestionHeader from "./QuestionHeader";
import { swapQuestionMC } from "../../../../../redux/async/multipleChoiceThunk";
import QuestionAction from "./QuestionActions/QuestionAction";
import { IActivity } from "../../../../../shared/models/WorkSheetCreator";
import { useAppDispatch } from "../../../../../redux/hook";

const Question = ({
    activity,
    index,
    openQuestion,
    setOpenQuestion,
    question,
    currentQuestion,
    setCurrentQuestion,
}: {
    activity: IActivity;
    index: number;
    openQuestion: boolean;
    setOpenQuestion?: Function;
    question: IQuestionActivity;
    currentQuestion: number;
    setCurrentQuestion?: Function;
}) => {
    const dispatch = useAppDispatch();
    const questions: IQuestionActivity[] = activity.questions;
    const isCurrentQuestion = currentQuestion === index;
    const handleDrag = (event) => {
        const selectedItem = event.target,
            list = selectedItem.parentNode,
            x = event.clientX,
            y = event.clientY;
        let swapItem =
            document.elementFromPoint(x, y).parentElement.parentNode === null
                ? selectedItem
                : document.elementFromPoint(x, y).parentElement.parentNode;
        if (list === swapItem.parentNode) {
            const questionNum = (input) => {
                return input.match(/\d+/g);
            };
            let swapIndex = questionNum(swapItem.id)[0] - 1;
            let selectIndex = questionNum(selectedItem.id)[0] - 1;
            if (swapIndex != selectIndex) {
                let selectQuesActivity = questions[selectIndex];
                let swapQuesAcitivity = questions[swapIndex];
                if (selectIndex != -1 && swapIndex != -1) {
                    dispatch(
                        swapQuestionMC({
                            swapIndex: swapIndex,
                            selectIndex: selectIndex,
                            selectQues: selectQuesActivity,
                            swapQues: swapQuesAcitivity,
                        })
                    );
                }
            }
        }
    };
    const hideQues = (hide = false) => {
        let layer = document.getElementById(`layer-question-${index + 1}`);
        console.log("currentQuestion: ", currentQuestion);
        console.log("index: ", index);
        if (layer) {
            layer.style.display = hide ? "block" : "none";
            layer.style.border = hide ? "1px solid #3b82f6" : "";
            layer.style.backgroundColor = hide ? "white" : "";
        }
    };

    return (
        <div
            className="question-container"
            draggable={!isCurrentQuestion ? true : false}
            onDrag={(e) => {
                if (!isCurrentQuestion) {
                    handleDrag(e);
                }
                hideQues(true);
            }}
            onDragEnd={(e) => {
                if (!isCurrentQuestion) {
                    handleDrag(e);
                }
                hideQues(false);
            }}
            style={{
                backgroundColor: `${isCurrentQuestion ? "#F8F9FB" : ""}`,
                borderRadius: `${isCurrentQuestion ? "6px" : ""}`,
                paddingBottom: `${isCurrentQuestion ? "8px" : ""}`,
                marginBottom: `${
                    index === questions.length - 1 ? "24px" : "20px"
                }`,
                cursor: openQuestion && isCurrentQuestion ? "default" : "",
            }}
            id={`question-${index + 1}`}
        >
            <div className="layer" id={`layer-question-${index + 1}`}></div>
            <QuestionHeader
                isCurrentQuestion={isCurrentQuestion}
                activity={activity}
                index={index}
                openQuestion={openQuestion}
                setOpenQuestion={setOpenQuestion}
                question={question}
                setCurrentQuestion={setCurrentQuestion}
            />
            {openQuestion && isCurrentQuestion && (
                <QuestionDetail
                    activity={activity}
                    question={question}
                    questionIndex={index}
                />
            )}
            <QuestionAction
                activity={activity}
                isAdd={!isCurrentQuestion}
                index={index}
                setOpenQuestion={setOpenQuestion}
                setCurrentQuestion={setCurrentQuestion}
            />
        </div>
    );
};

export default Question;
