import { IWorksheet, Worksheet } from "./worksheet";
import { isObject } from "../utils/index";
export interface IWorksheetIds {
    id: string;
    worksheetId: string;
    date: Date;
    worksheet?: IWorksheet;
}
export class WorksheetIds implements IWorksheetIds {
    id: string;
    worksheetId: string;
    date: Date;

    worksheet?: IWorksheet;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.date = object.date ?? new Date(object.date);
        this.worksheetId = object.worksheetId ?? null;
        this.worksheet = object.worksheet;
        if (isObject(object.worksheetId)) {
            this.worksheet = new Worksheet(object.worksheetId);
            this.worksheetId = this.worksheet.id;
        } else {
            this.worksheetId = object.worksheetId ?? null;
        }
    }
}
export interface IDownloadWorksheet {
    id: any;
    userId: string;
    worksheetIds: IWorksheetIds[];

    worksheets?: IWorksheet[];
}

export class DownloadWorksheet implements IDownloadWorksheet {
    id: any;
    userId: string;
    worksheetIds: IWorksheetIds[];

    worksheets?: IWorksheet[];

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.userId = object.userId ?? null;
        this.worksheetIds = object.worksheetIds ?? [];

        if (object.worksheets) {
            this.worksheets = object.worksheets.map(
                (w: any) => new Worksheet(w)
            );
        } else {
            this.worksheets = [];
        }
    }
}
