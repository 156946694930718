import { POST, UPLOAD_MULTI_FILE } from ".";
import { IResourceItem } from "../shared/models/resourceItem";
import { IWorksheet } from "../shared/models/worksheet";
import APIConfig from "../utils/APIConfig";

export const uploadResourcePdf = async (args: {
    userId: string;
    listImageBase64: string[];
    isBackgroundSize: boolean;
}) => {
    return POST({
        url: APIConfig.UPLOAD_FILE_PDF,
        params: args,
    });
};

export const uploadResourceApi = async (args: {
    files: File[];
    userId: string;
    isBackgroundSize: boolean;
}) => {
    console.log('upload')
    return UPLOAD_MULTI_FILE({
        url: APIConfig.UPLOAD_FILE_RESOURCE,
        files: args.files,
        userId: args.userId,
        isBackgroundSize: args.isBackgroundSize,
    });
};

export const getResourceItemApi = (args: {
    typeResource: Number[];
    offset: number;
    limit: number;
    userId: string;
    activeResourceUpload: boolean;
    orientation?: string;
}): Promise<IResourceItem[] | null> => {
    return POST({
        url: APIConfig.GET_RESOURCE_BY_TYPE,
        params: {
            type: args.typeResource,
            offset: args.offset,
            limit: args.limit,
            userId: args.userId,
            activeResourceUpload: args.activeResourceUpload,
            orientation: args.orientation,
        },
    });
};

export const getTemplateApi = (args: {
    worksheetType: number;
    templateType: string;
    limit: number;
    offset: number;
}): Promise<IWorksheet[] | null> => {
    // get template: worksheetType = Config.TEMPLATE_WORKSHEET
    // get template created by tool: templateType = Config.USER_CREATED_TOOL_V2_WORD_SEARCH or USER_CREATED_TOOL_V2_HAND_WRITING, ...
    // set worksheetType or templateType
    return POST({
        url: APIConfig.GET_TEMPLATE,
        params: {
            worksheetType: args.worksheetType,
            templateType: args.templateType,
            offset: args.offset,
            limit: args.limit,
        },
    });
};

export const searchTemplateApi = (args: {
    worksheetType: Number;
    templateType: string;
    offset: number;
    size: number;
    keyword: string;
}): Promise<IWorksheet[] | null> => {
    // get template: worksheetType = Config.TEMPLATE_WORKSHEET
    // get template created by tool: templateType = Config.USER_CREATED_TOOL_V2_WORD_SEARCH or USER_CREATED_TOOL_V2_HAND_WRITING, ...
    // set worksheetType or templateType
    return POST({
        url: APIConfig.SEARCH_TEMPLATE_BY_KEYWORD,
        params: {
            keyword: args.keyword,
            worksheetType: args.worksheetType,
            templateType: args.templateType,
            offset: args.offset,
            size: args.size,
        },
    });
};
