import { useCallback, useEffect, useRef, useState } from "react";
import "./index.scss";
import { debounce, throttle } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    searchResourceByKeyword,
    searchTemplateByKeyword,
} from "../../../../../redux/async/leftData";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import Config from "../../../../../shared/utils/config";
import {
    getResourceTypeForBackGround,
    getTemplateTypebaseUrl,
} from "../../../../../utils";
const ListTag = ({ setSearchKeyword, setShowClearIcon }) => {
    const listTagName = [
        "Wordsearch",
        "Handwriting",
        "Name Tracing",
        "Fill in the blank",
        "Crossword",
        "Addition",
        "Subtraction",
        "Multiplication",
        "Division",
    ];
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const getMaxScrollRight = (element) => {
        return element ? element.scrollWidth - element.clientWidth : 0;
    };
    const [scrollX, setScrollX] = useState(-1);
    const debounceSetState = useCallback(
        debounce((nextValue: number) => setScrollX(nextValue), 300),
        []
    );
    const handleScroll = useCallback(
        throttle((e) => {
            setScrollX(ref.current.scrollLeft);
        }, 32),
        []
    );
    useEffect(() => {
        if (ref.current) {
            setScrollX(0);
        }
    }, [ref]);

    const loading = useAppSelector((state) => state.leftDataState.loading);
    const activeTab = useAppSelector((state) => state.leftDataState.activeTab);
    const searchKeywordRedux = useAppSelector(
        (state) => state.leftDataState.searchKeyword
    );
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );

    const handleClickTag = (keyword) => {
        setSearchKeyword(keyword);
        setShowClearIcon(true);
        if (!loading && searchKeywordRedux !== keyword) {
            switch (activeTab) {
                case Config.RESOURCE_TEMPLATE:
                    dispatch(
                        searchTemplateByKeyword({
                            templateType: getTemplateTypebaseUrl(),
                            offset: 0,
                            size: ConstantsTool.LIMIT_RESOURCE,
                            keyword: keyword,
                        })
                    );
                    break;
                case Config.RESOURCE_BACKGROUND:
                    let typeResources =
                        getResourceTypeForBackGround(pagesWorksheet);
                    dispatch(
                        searchResourceByKeyword({
                            typeResource: typeResources,
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            offset: 1,
                            keyword: keyword,
                        })
                    );
                    break;
                default:
                    dispatch(
                        searchResourceByKeyword({
                            typeResource: [activeTab],
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            offset: 1,
                            keyword: keyword,
                        })
                    );
                    break;
            }
        }
    };

    return (
        <div className="list-tag-container">
            <div className="list-tag" ref={ref}>
                {listTagName.map((el, index) => (
                    <div
                        className="tag-item"
                        key={index}
                        onClick={() => handleClickTag(el)}
                    >
                        <div className="tag-name">{el}</div>
                    </div>
                ))}
            </div>
            {scrollX > -1 &&
                Math.abs(
                    ref?.current?.scrollLeft - getMaxScrollRight(ref.current)
                ) > 1 && (
                    <div className="arrow-container next-arrow">
                        <div className="test-arrow"></div>
                        <div
                            className="circle-arrow"
                            onClick={() => {
                                if (ref?.current) {
                                    let scrollLeft = ref?.current?.scrollLeft;
                                    let moveTo = Math.min(
                                        (scrollLeft += 160),
                                        getMaxScrollRight(ref.current)
                                    );
                                    ref.current.scrollTo({
                                        top: 0,
                                        left: moveTo,
                                        behavior: "smooth",
                                    });
                                    debounceSetState(moveTo);
                                }
                            }}
                        >
                            <img src={"/images/next-icon.png"}></img>
                        </div>
                    </div>
                )}
            {scrollX > -1 && ref?.current && ref.current.scrollLeft > 0 && (
                <div className="arrow-container prev-arrow">
                    <div className="test-arrow"></div>
                    <div
                        className="circle-arrow"
                        onClick={() => {
                            if (ref?.current) {
                                let scrollLeft = ref.current.scrollLeft;
                                let moveTo = Math.max((scrollLeft -= 160), 0);
                                ref.current.scrollTo({
                                    top: 0,
                                    left: moveTo,
                                    behavior: "smooth",
                                });
                                debounceSetState(moveTo);
                            }
                        }}
                    >
                        <img src={"/images/prev-icon.png"}></img>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ListTag;
