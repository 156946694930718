import React, { useState } from 'react';
import { useAppSelector } from '../../../../../redux/hook';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import './styles.scss';
import { defaultSpacingTemplate } from '../../../../../shared/models/pageWorksheetNew';
import { useDispatch } from 'react-redux';
import { setTemplate, changePageSize } from '../../../../../redux/reducers/createWorksheet';

export const SPACING_FIELDS = [
    {
        value: 'paddingTop',
        label: 'Padding Top: '
    },
    {
        value: 'paddingRight',
        label: 'Padding Right: '
    },
    {
        value: 'paddingBottom',
        label: 'Padding Bottom: '
    },
    {
        value: 'paddingLeft',
        label: 'Padding Left: '
    }
]

const SpacingTemplateSetting = () => {
    const dispatch = useDispatch();

    const { pagesWorksheet, pageIndex, template } = useAppSelector((state) => state.createWorksheetState);
    const pageWorksheet = pagesWorksheet[pageIndex];
    const { width, height } = pageWorksheet;

    const [spacingTemplate, setSpacingTemplate] = useState(template?.spacing);
    const [isFitContentStatus, setIsFitContentStatus] = useState(false);

    const handleSetFitContent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsFitContentStatus(prev => !prev);
        setSpacingTemplate(defaultSpacingTemplate);

        dispatch(setTemplate({ ...template, spacing: defaultSpacingTemplate }));
        dispatch(changePageSize({
            pageIndex: 0,
            width,
            height,
            spacing: defaultSpacingTemplate,
        }))
    };

    const handleUpdateTemplateSpacing = (value: number, spacingField: string = 'paddingTop') => {
        const updatedSpacingTemplate = {
            ...spacingTemplate,
            [spacingField]: value,
        };
        setIsFitContentStatus(false);
        setSpacingTemplate(updatedSpacingTemplate);
        dispatch(setTemplate({
            ...template,
            spacing: updatedSpacingTemplate,
        }));
        dispatch(changePageSize({
            pageIndex: 0,
            width,
            height,
            spacing: updatedSpacingTemplate,
        }));
    };

    const handleChangeDimensionWorksheet = (value: number, fieldName: string) => {
        const currentPageSize = {
            pageIndex,
            width,
            height,
            spacing: spacingTemplate,
        };
        const updatedPageSize = {
            ...currentPageSize,
            [fieldName]: value,
        };
        dispatch(setTemplate({
            ...template,
            width,
            height,
            [fieldName]: value,
        }));
        dispatch(changePageSize(updatedPageSize));
    };

    return (
        <fieldset className="spacing-template-setting">
            <Form>
                <FormGroup>
                    <Form.Check
                        id="fit-content-template-checkbox"
                        label="Fit content"
                        type="checkbox"
                        checked={isFitContentStatus}
                        onChange={handleSetFitContent}
                    />
                </FormGroup>
                <Form.Group className="mb-3" controlId="template.width">
                    <Form.Label>Width: </Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="Width" 
                        min={0}
                        value={width}
                        onChange={(e) => handleChangeDimensionWorksheet(parseInt(e.target.value), 'width')}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="template.height">
                    <Form.Label>Height: </Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="Height" 
                        min={0}
                        value={height}
                        onChange={(e) => handleChangeDimensionWorksheet(parseInt(e.target.value), 'height')}
                    />
                </Form.Group>
                {SPACING_FIELDS.map((item, index) => (
                    <Form.Group key={index}>
                        {spacingTemplate && (
                            <>
                                <Form.Label>{item.label} {spacingTemplate[item.value]}px</Form.Label>
                                <Form.Range
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={spacingTemplate[item.value]}
                                    onChange={(e) => handleUpdateTemplateSpacing(parseInt(e.target.value), item.value)}
                                />
                            </>
                        )}
                    </Form.Group>
                ))}
            </Form>
        </fieldset>
    )
};

export default SpacingTemplateSetting;