import React from "react";
import { IActivity } from "../../../shared/models/WorkSheetCreator";
import TabSection from "./tab-section";
import InputSection from "./input-section";
import StyleSection from "./style-section";
import "./index.scss";
import OtherSection from "./other-section";

const WordScramble = ({ activity }: { activity: IActivity }) => {
    return (
        <div className="word-scramble-container">
            <TabSection type={activity.type} />
            <InputSection activity={activity} />
            <StyleSection activity={activity} />
            <OtherSection activity={activity} />
        </div>
    );
};

export default WordScramble;
