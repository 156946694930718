import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Collection,
    ICollection,
} from "../../../../../shared/models/collection";
import { isResourceCollection } from "../../../../../shared/utils";
import Config from "../../../../../shared/utils/config";
import { selectCollectionResourceAction } from "../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import SlashIconResources from "../../../icons/folder/slash-icon";
import "./style.scss";
const BreadCrumbsCollectionName = ({
    collection,
    selectCollectionFc,
    fetchData,
}: {
    collection: ICollection;
    selectCollectionFc?: Function;
    fetchData?: boolean;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const dispatch = useDispatch();
    const defaultCollections = useSelector(
        (state: ResourceAppState) => state.resourceState.defaultCollections
    );
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const resourceCollection = isResourceCollection({
        collectionId: collection?.id,
    });
    let parentCollectionName = collection?.parentCollectionName ?? [];
    const parentIds = collection?.parentIds ?? [];
    let newArr = [];
    if (
        !resourceCollection &&
        !newArr.find(
            (item) =>
                item.id ==
                Config.COLLECTION_MY_COLLECTIONS.COLLECTION_MY_COLLECTIONSid
        )
    ) {
        newArr.push({
            id: Config.COLLECTION_MY_COLLECTIONS.COLLECTION_MY_COLLECTIONSid,
            name: Config.COLLECTION_MY_COLLECTIONS
                .COLLECTION_MY_COLLECTIONS_NAME,
        });
    }

    if (parentIds?.length > 0) {
        parentIds?.forEach((id) => {
            let collection = parentCollectionName.find((c) => c.id == id);
            if (collection) {
                newArr.push(collection);
            }
        });
    }

    const selectCollection = (item) => {
        let collection: any = new Collection(item);
        if (
            item.id ==
            Config.COLLECTION_MY_COLLECTIONS.COLLECTION_MY_COLLECTIONSid
        ) {
            collection = defaultCollections.find(
                (c) =>
                    c.id ==
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid
            );
            if (!collection?.id) {
                collection = new Collection({
                    id: Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid,
                    name: Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONS_NAME,
                    userId: userInfo.id,
                });
            }
        }
        collection.userId = userInfo.id;
        let parentIdsItem = parentIds.slice(0, parentIds.indexOf(item.id));
        let parentCollectionNameItem = parentCollectionName.slice(
            0,
            parentCollectionName.indexOf(item)
        );
        collection.parentIds = parentIdsItem;
        collection.parentCollectionName = parentCollectionNameItem;

        // dispatch(
        //     changeCollectionDataAction({
        //         collection,
        //         keyword: "",
        //     })
        // );
        selectCollectionFc && selectCollectionFc({ collection });
        let pageTypeResource = Config.PAGE_TYPE.PAGE_COLLECTION_DETAIL;
        dispatch(
            selectCollectionResourceAction({
                collection: collection,
                pageTypeResource,
                loading: true,
                notFetchData: !fetchData,
            })
        );
    };

    return (
        <div
            className={
                "breadcrumbs-collection-name " +
                (resourceCollection ? "resource" : " detail-collection") +
                (newArr?.length > 0 ? " active" : "")
            }
        >
            {newArr?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <div
                            className="collection-name"
                            onClick={() => {
                                selectCollection(item);
                            }}
                        >
                            <p>{item.name}</p>
                        </div>
                        <SlashIconResources />
                    </React.Fragment>
                );
            })}
            {isMobile &&
            isResourceCollection({ collectionId: collection?.id }) ? (
                <></>
            ) : (
                <div className="collection-name active">
                    <p>{collection.name}</p>
                </div>
            )}
        </div>
    );
};

export default BreadCrumbsCollectionName;
