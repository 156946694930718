import resourceState, { IResourceState } from "../reducer/resource.reducer";
import profileResourceState, {
    IProfileResourceState,
} from "../reducer/user.reducer";
import userInfoResourceState, {
    IUserInfoResourceState,
} from "../reducer/userInfoResource.reducer";
import wordSearchState, {
    IWordsearchState,
} from "../reducer/wordSearch.reducer";
import assignDigitallyState, {
    AssignDigitally,
} from "./assign-digitally.reducer";
import conversationState, { IConversationState } from "./conversation.reducer";
import crosswordState, { ICrosswordState } from "./crossword.reducer";
import gameResourceState, { GameResourceState } from "./game.reducer";
import notificationResourceState, {
    INotificationResourceState,
} from "./notification.reducer";
import quotaResourceUserState, {
    QuotaResourceUserState,
} from "./quotaResourceUser.reducer";
import referralState, { ReferralState } from "./referral.reducer";
import snackbarState, { SnackbarState } from "./snackbar.reducer";

export type ResourceAppState = {
    resourceState: IResourceState;
    userInfoResourceState: IUserInfoResourceState;
    profileResourceState: IProfileResourceState;
    wordSearchState: IWordsearchState;
    userInfo: IUserInfoResourceState;
    assignDigitallyState: AssignDigitally;
    gameResourceState: GameResourceState;
    snackbarResourceState: SnackbarState;
    conversationState: IConversationState;
    quotaResourceUserState: QuotaResourceUserState;
    notificationState: INotificationResourceState;
    crosswordState: ICrosswordState;
    referralState: ReferralState;
};

const rootReducerResource = {
    resourceState,
    userInfoResourceState,
    profileResourceState,
    wordSearchState,
    assignDigitallyState,
    userInfo: userInfoResourceState,
    snackbarResourceState: snackbarState,
    gameResourceState,
    conversationState,
    quotaResourceUserState,
    notificationState: notificationResourceState,
    crosswordState,
    referralState,
};

export default rootReducerResource;
