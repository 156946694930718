import Autocomplete from '@mui/material/Autocomplete';
import React, { ChangeEvent, useEffect, useState } from 'react';
import CreateWSConfig from '../../../../../../../utils/create-ws-config';
import Button from 'react-bootstrap/Button';

import './index.scss';
const FontSizePicker = ({
    disableChanged = false,
    fontSize,
    handleChangeFontSize,
    setOpen,
    open,
}: {
    disableChanged?: boolean,
    fontSize: number;
    handleChangeFontSize: Function;
    setOpen: (value: boolean) => void;
    open: boolean;
}) => {
    const [fontSizeData, setFontSizeData] = useState(
        fontSize && fontSize !== 0 ? fontSize.toString() : ""
    );

    useEffect(() => {
        setFontSizeData(fontSize && fontSize !== 0 ? fontSize.toString() : "");
    }, [fontSize]);

    const changeFontSize = dir => {
        let value = fontSize || 14;
        if (dir === 'down' && value > 1) {
            value--;
        } else if (dir === 'up') {
            value++;
        }
        if (value !== fontSize) {
            setFontSizeData(value.toString());
            handleChangeFontSize(value);
        }
    }

    return (
        <div className="font-size-selector">
            <Button size="sm" variant="secondary" onClick={() => changeFontSize('down')}>-</Button>
            <Autocomplete
                disabled={disableChanged}
                key={"font-size-pick-mui-autocomplete"}
                open={open}
                onBlur={() => setOpen(false)}
                options={CreateWSConfig.FONT_SIZES}
                filterOptions={(x) => x}
                getOptionLabel={(size: number) => size.toString()}
                openOnFocus
                renderInput={(params) => {
                    return (
                        <div
                            ref={params.InputProps.ref}
                            className="font-picker"
                        >
                            <div
                                {...params.inputProps}
                                onWheel={(e) => e.preventDefault()}
                                style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    pointerEvents: !disableChanged ? 'auto' : 'none',
                                }}
                                onClick={(e) => {
                                    setOpen(!open);
                                    e.stopPropagation();
                                }}
                            >
                                {fontSizeData}
                            </div>
                        </div>
                    );
                }}
                ListboxProps={{
                    className: "list-item-font-size",
                }}
                onInputChange={(
                    event: React.SyntheticEvent,
                    value: string,
                    reason: string
                ) => {
                    if (reason !== "reset") {
                        setFontSizeData(value);
                    }
                }}
                onKeyDown={(event: any) => {
                    if (event.key == "Enter") {
                        if (!isNaN(parseInt(fontSizeData))) {
                            handleChangeFontSize(parseInt(fontSizeData));
                        }
                    }
                }}
                isOptionEqualToValue={() => true}
                className="container-mui-auto-select"
                onChange={(event: ChangeEvent<HTMLElement>, value: number) => {
                    if (value) {
                        setFontSizeData(value.toString());
                        handleChangeFontSize(value);
                        setOpen(false);
                    }
                }}
            />
            <Button size="sm" variant="secondary" onClick={() => changeFontSize('up')}>+</Button>
        </div>
    );
};

export default FontSizePicker;
