export default class CreateWSConfig {
    static WIDTH = (210 * 96) / 25.4;
    static HEIGHT = (297 * 96) / 25.4; //1122,5
    static WIDTH_STR = (210 * 96) / 25.4;
    static HEIGHT_STR = (297 * 96) / 25.4;
    static SAVE_WS_STATUS_NONE = 0;
    static SAVE_WS_STATUS_START = 1;
    static SAVE_WS_STATUS_FINISH = 2;
    static FONT_SIZE_DEFAULT = 14;
    static COLOR_DEFAULT = "#212121";
    // static CREATE_WORKSHEET_RATIO = (210 * 96) / 25.4 / 1000;
    static CREATE_WORKSHEET_RATIO = 1;
    static RIGHT_PANEL_WIDTH = 120;
    static RIGHT_PANEL_HEIGHT = 174;
    static FUNCTION_CONTENT = 0;
    static FONT_FAMILY_CONTENT = 1;
    static TEXT_EFFECT_CONTENT = 2;

    // static MODE_CREATED = 0;
    // static MODE_PLAY_DEMO = 1;
    // static MODE_POPUP_SAVE = 2;
    static FONT_SIZES = [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72, 96, 100,
        110, 120, 150, 200,
    ];
    static ZOOM_VALUE = [45, 50, 60, 75, 100, 125, 200];
    static RECT_TYPE = 0;
    static CIRCLE_TYPE = 1;
    static LINE_TYPE = 2;
    static GAME_NAMES = [
        "Input",
        "Select",
        "Check-box",
        "Drop down",
        "Drag",
        "Drop",
        "Matching",
        // "Listen",
        // "Speak",
        "Wordsearch",
    ];
    static GAME_IMAGES_ICON = [
        "/images/create-worksheet/games/input_game.svg",
        "/images/create-worksheet/games/select_game.svg",
        "/images/create-worksheet/games/stick_game.svg",
        "/images/create-worksheet/games/drop_down_game.svg",
        "/images/create-worksheet/games/drag_game.svg",
        "/images/create-worksheet/games/drop_game.svg",
        "/images/create-worksheet/games/connector_game.svg",
        // "/images/create-worksheet/games/listen_game.svg",
        // "/images/create-worksheet/games/speak_game.svg",
        "/images/create-worksheet/games/wordsearch_game.svg",
    ];
    static CREATE_WORKSHEET_MODE_EDIT = 0;
    static CREATE_WORKSHEET_MODE_MAKE_INTERACTIVE = 1;
    static CREATE_WORKSHEET_MODE_PLAY_DEMO = 2;
    static FONT_FAMILY_HANDWRITING = ["TeachingPrintDotted", "Verdana"];

    static FONT_FAMILY: string[] = [
        'SVN_Bango',
        'UTM Duepuntozero',
        'BerlinSansFBDemiBold',
        'COMIC',
        'ComicNeueSansID',
        'comicz',
        'Cooper Black Regular',
        'Duepuntozero-ExtraBoldTrial',
        'Duepuntozero-Black-Trial',
        'Duepuntozero-ExtraLight-Trial',
        'Duepuntozero-Bold-Trial',
        'Duepuntozero-Regular-Trial',
        'Kid Knowledge 1',
        'Kid Knowledge 1 outline',
        'Kid Knowledge 2 Clean',
        'GroundHog Demo',
        'Kindness',
        'nu_century_gothic',
        '212 Moon Child Sans',
        'Cute Stitch',
        'CHIPFN__',
        'Halloween Horrors',
        'Halloween Horrors Italic',
        'Schoolkid',
        'BurstMyBubble',
    ];

    static EFFECT_NONEid = 0;
    static EFFECT_SHADOWid = 1;
    static EFFECT_LIFTid = 2;
    static EFFECT_HOLLOWid = 3;
    static EFFECT_SPLICEid = 4;
    static EFFECT_ECHOid = 5;
    static EFFECT_GLITCHid = 6;
    static EFFECT_NEONid = 7;
    static EFFECT_BACKGROUNDid = 7;

    static EFFECT_DATA = [
        {
            id: CreateWSConfig.EFFECT_NONEid,
            name: "None",
            image: "/images/create-worksheet/text-effect/none_effect.webp",
            attributeSettings: [],
        },
        {
            id: CreateWSConfig.EFFECT_SHADOWid,
            name: "Shadow",
            image: "/images/create-worksheet/text-effect/shadow_effect.webp",
            attributeSettings: [
                { name: "Offset", min: 0, max: 100, initValue: 50 },
                { name: "Direction", min: -180, max: 180, initValue: -45 },
                { name: "Blur", min: 0, max: 100, initValue: 0 },
                { name: "Transparency", min: 0, max: 100, initValue: 40 },
                {
                    name: "Color",
                    initValue: "#808080",
                },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_LIFTid,
            name: "Lift",
            image: "/images/create-worksheet/text-effect/lift_effect.webp",
            attributeSettings: [
                { name: "Intensity", min: 0, max: 100, initValue: 50 },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_HOLLOWid,
            name: "Hollow",
            image: "/images/create-worksheet/text-effect/hollow_effect.webp",
            attributeSettings: [
                { name: "Thickness", min: 0, max: 100, initValue: 50 },
            ],
        },
        {
            id: CreateWSConfig.EFFECT_SPLICEid,
            name: "Splice",
            image: "/images/create-worksheet/text-effect/splice_effect.webp",
            attributeSettings: [
                { name: "Thickness", min: 0, max: 100, initValue: 50 },
                { name: "Offset", min: 0, max: 100, initValue: 50 },
                { name: "Direction", min: -180, max: 180, initValue: -45 },
                {
                    name: "Color",
                    initValue: "#808080",
                },
            ],
        },
        // {
        //     id: CreateWSConfig.EFFECT_ECHOid,
        //     name: "Echo",
        //     image: "/images/create-worksheet/text-effect/echo_effect.webp",
        //     attributeSettings: [
        //         { name: "Offset", min: 0, max: 100, initValue: 50 },
        //         { name: "Direction", min: -180, max: 180, initValue: -45 },
        //         {
        //             name: "Color",
        //             initValue: "#808080",
        //         },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_GLITCHid,
        //     name: "Glitch",
        //     image: "/images/create-worksheet/text-effect/glitch_effect.webp",
        //     attributeSettings: [
        //         { name: "Offset", min: 0, max: 100, initValue: 30 },
        //         { name: "Direction", min: -180, max: 180, initValue: 90 },
        //         // {
        //         //     name: "Color",
        //         //     initValue: "#808080",
        //         // },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_NEONid,
        //     name: "Neon",
        //     image: "/images/create-worksheet/text-effect/neon_effect.webp",
        //     attributeSettings: [
        //         { name: "Intensity", min: 0, max: 100, initValue: 50 },
        //     ],
        // },
        // {
        //     id: CreateWSConfig.EFFECT_BACKGROUNDid,
        //     name: "Background",
        //     image: "/images/create-worksheet/text-effect/background_effect.webp",
        //     attributeSettings: [
        //         { name: "Roundness", min: 0, max: 100, initValue: 50 },
        //         { name: "Spread", min: 0, max: 100, initValue: 50 },
        //         { name: "Transparency", min: 0, max: 100, initValue: 100 },
        //         {
        //             name: "Color",
        //             initValue: "#808080",
        //         },
        //     ],
        // },
    ];
    static HEIGHT_ACTIVITIES = {
        WS_INFO: 200,
        WORD_SEARCH: 880,
        CROSSWORD: 720,
        FILL_IN_BLANK: 550,
        HANDWRITING: 564,
        MULTIPLE_CHOICE: "fit-content",
        NAME_TRACING: 880,
    };
    static A4_SVG_CONTENT =
        '<svg width="595" height="842" viewBox="0 0 595 842" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="595" height="842" fill="#FFFFFF"/></svg>';

    static OPACITY_FONT_FAMILY = "OPACITY_FONT_FAMILY";
    static EMPTY_FONT = "";

    static PRINT_FONT_FAMILY = [
        {
            name: "Standard Print",
            regular: "Precursive Regular",
            dashed: "Precursive Dashed",
            stroked: "Precursive Stroked",
        },
        {
            name: "Quicksand",
            regular: "Quicksand Regular",
            dashed: "Quicksand Dashed",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Ecolier",
            regular: "Ecolier Regular",
            dashed: "Ecolier Dashed",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Letters For Learners",
            regular: "LettersForLearners Regular",
            dashed: "LettersForLearners Dashed",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Arial",
            regular: "Arial",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Arial",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Comfortaa",
            regular: "Comfortaa",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Comfortaa",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Comic Sans MS",
            regular: "ComicSanMS",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "ComicSanMS",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Coming Soon",
            regular: "Coming Soon",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Coming Soon",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Delius",
            regular: "Delius",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Delius",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Indie Flower",
            regular: "IndieFlower",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "IndieFlower",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Montserrat",
            regular: "Montserrat",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Montserrat",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Open Sans",
            regular: "Open Sans",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Open Sans",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Oswald",
            regular: "Oswald",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Oswald",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Play",
            regular: "Play",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Play",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Poppins",
            regular: "Poppins",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Poppins",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Questrial",
            regular: "Questrial",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Questrial",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Roboto",
            regular: "Roboto",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Roboto",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Shadows Into Light",
            regular: "Shadows Into Light",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Shadows Into Light",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Tahoma",
            regular: "Tahoma",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Tahoma",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Verdana",
            regular: "Verdana",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Verdana",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
    ];

    static CURSIVE_FONT_FAMILY = [
        {
            name: "Standard Cursive",
            regular: "Cursive Regular",
            dashed: "Cursive Dashed",
            stroked: "Cursive Stroked",
        },
        {
            name: "Ilkokuma",
            regular: "Ilkokuma Regular",
            dashed: "Ilkokuma Dashed",
            stroked: "Ilkokuma Stroked",
        },
        {
            name: "IlkDers",
            regular: "IlkDers Regular",
            dashed: "IlkDers Dashed",
            stroked: "IlkDers Stroked",
        },
        {
            name: "Learning Cursive",
            regular: "LearningCurve Regular",
            dashed: "LearningCurve Dashed",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Santa Christmas",
            regular: "SantaChristmas Regular",
            dashed: "SantaChristmas Dashed",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Dancing Script",
            regular: "Dancing Script",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Dancing Script",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Cookie",
            regular: "Cookie",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Cookie",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Parisienne",
            regular: "Parisienne",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Parisienne",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Imperial Script",
            regular: "Imperial Script",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Imperial Script",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Pinyon Script",
            regular: "Pinyon Script",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Pinyon Script",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Gwendolyn",
            regular: "Gwendolyn",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Gwendolyn",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Mrs Saint Delafield",
            regular: "Mrs Saint Delafield",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Mrs Saint Delafield",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Rochester",
            regular: "Rochester",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Rochester",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Passions Conflict",
            regular: "Passions Conflict",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Passions Conflict",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Dawning of a New Day",
            regular: "Dawning of a New Day",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Dawning of a New Day",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "Meddon",
            regular: "Meddon",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "Meddon",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "League Script",
            regular: "League Script",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "League Script",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
        {
            name: "MonteCarlo",
            regular: "MonteCarlo",
            dashed: CreateWSConfig.OPACITY_FONT_FAMILY + "MonteCarlo",
            stroked: CreateWSConfig.EMPTY_FONT,
        },
    ];
    static LIST_ACTION = {
        ACTION_WORD_BANK: "ACTION_WORD_BANK",
        ACTION_ADD_REMOVE_ANSWER: "ACTION_ADD_ANSWER",
        ACTION_ADD_REMOVE_QUESTION: "ACTION_ADD_REMOVE_QUESTION",
        ACTION_CHANGE_LIST_STYLE: "ACTION_CHANGE_LIST_STYLE",
        ACTION_CHANGE_LINE_HEIGHT: "ACTION_CHANGE_LINE_HEIGHT",
        ACTION_EDIT_TEXT: "ACTION_EDIT_TEXT",
    };
    static LIMIT_RESOURCE_RECENT = 20;
}
