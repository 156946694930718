import { groupWsFc } from "../../../../../utils";
import CoinQty from "../coin-qty";
import "./style.scss";

const PriceDetail = ({
    worksheet,
    worksheets,
}: {
    worksheet?: any;
    worksheets?: any[];
}) => {
    const groupWs = groupWsFc({ worksheets });
    let numberWorksheet = worksheets?.length
        ? Object.keys(groupWs).length
        : worksheet?.id
        ? 1
        : 0;
    console.log(
        "Object.keys(groupWs).length",
        Object.keys(groupWs).length,
        worksheet?.id
    );

    let title = worksheets?.length ? "Items" : "Price";
    return (
        <div className="claim-coins-detail">
            <div className="claim-coins-price">
                <div className="c-price-item">
                    <p className="c-price-item-text">{title}</p>
                    <div className="c-totals">
                        <CoinQty quantity={1} width={16} height={16} />

                        <div className="c-number-ws">
                            <p>x</p>
                            <p>{numberWorksheet}</p>
                        </div>
                    </div>
                </div>
                {worksheets?.length ? (
                    <div className="c-price-item">
                        <div className="c-price-item-text">Totals</div>
                        <div className="c-totals">
                            <CoinQty
                                quantity={numberWorksheet}
                                width={16}
                                height={16}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default PriceDetail;
