import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const SearchInProject = ({ setLastKeyword }: { setLastKeyword: Function }) => {
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const handleSubmit = () => {
        setLastKeyword(searchKeyword?.toLowerCase());
        if (!!searchKeyword) {
            setShowClearIcon(true);
        } else {
            setShowClearIcon(false);
        }
    };
    const handleClear = () => {
        let value = "";
        setSearchKeyword(value);
        setLastKeyword(value);
        setShowClearIcon(false);
    };
    return (
        <div className="search-bar project">
            <TextField
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSubmit();
                    }
                }}
                className="input-mui"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e: any) => {
                    setSearchKeyword(e.target.value);
                }}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment
                            sx={{ cursor: "pointer" }}
                            position="end"
                        >
                            {showClearIcon && (
                                <IconButton onClick={handleClear}>
                                    <ClearIcon></ClearIcon>
                                </IconButton>
                            )}
                            <IconButton onClick={handleSubmit}>
                                <SearchIcon></SearchIcon>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default SearchInProject;
