import { Fragment, useEffect } from 'react';
import CommonSnackbar from './components/CustomizeSnackbar';
import LoadFont from './components/LoadFont';
import MainPage from './pages';
import { requestInitPageWs } from './redux/async/createWorksheet';
import { useAppDispatch, useAppSelector } from './redux/hook';
import RenderToolTipCustomize from './resource-user/components/common/library/render-tooltip';
import CustomizedSnackbars from './resource-user/components/snackbar';
import { setProjectNameAction } from './resource-user/redux/action/wordsearch.action';
import Constants from './resource-user/utils/constants';
import Cookies from './resource-user/utils/cookies';
import Routes from './resource-user/utils/routes';
import ConstantsTool from './shared/utils/ConstantsTool';
import Config from './shared/utils/config';
import { getInitTypeFromUrl, loadFonts } from './utils';
import { LocalStorage } from './utils/LocalStorage';
import { getDataFromAPI } from './utils/query';
import {
    addResourceItems,
    setIsIframe,
    setIdeaTemplate,
    setContent,
    setTitle,
    setTemplate,
    setPageType,
    setLoading,
    setTemplateZoneDisplayMode,
    changePageSize,
    ITemplate,
    setAutoSave,
    setFontFamilyList,
    setCurrentStore,
    initialTemplate,
    setActivityPermissions,
    setIdeaAnswerSheetPage,
    setIdeaThumbnail,
    setIdeaAnswerKeyPage,
    setAllFontsLoaded,
    setDisabledSaveButton,
    setDataSetDesignRow,
} from './redux/reducers/createWorksheet';
import 'bootstrap/dist/css/bootstrap.min.css';
import { changeActiveTab } from './redux/reducers/leftData';
import { sortResourcesByStore } from './shared/utils';
import { getDimensionOfWorksheet } from './resource-user/utils/worksheet.utils';
import { setStaffInfo } from './redux/reducers/staffInfo';
import { IResourceItemNew } from './shared/models/resourceItemNew';
import { IFont } from './shared/models/module';

export const checkLoginFC = async (dispatch) => {
    let key = LocalStorage.getAndCreateIfNotExitComputerID();

    // let userInfo;

    // if (!userInfo) {
    //     let userInfo: any = await new UserRepositoriesWeb().loginAPI({
    //         uuId: key,
    //     });
    //     if (userInfo) {
    //         dispatch(loginSuccess({ userInfo: userInfo }));
    //         dispatch(getQuotaResourceByUserIdAction(userInfo.id));
    //         return userInfo;
    //     }
    // }
    return null;
};

const App = () => {
    Config.MODULE_NAME = Config.MODULE_NAME_CONFIG.TOOL_V2;
    const dispatch = useAppDispatch();
    const { ideaTemplate, template, fontFamilyList } = useAppSelector((state) => state.createWorksheetState);

    useEffect(() => {
        if (typeof window != undefined) {
            checkLoginFC(dispatch);
            const displayMode = (new URLSearchParams(window.location.search)).get('displayMode');
            let ratio: number;
            if (displayMode === 'iframe') {
                ratio = 0.63;
            } else {
                ratio = ((window.innerHeight - 90) * 1) / ConstantsTool.HEIGHT_A4; // minus header height * max height expected
            }
            dispatch(setProjectNameAction(Config.PROJECT_NAME.TOOL_V2));
            let activityToInit = "";
            let worksheetId = "";
            let sourcePage = Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH;
            let pathName = window.location.pathname;

            if (pathName.includes(Routes.WORKSHEET_MAKER) || pathName.includes(Routes.WORD_SEARCH_MAKER)) {
                activityToInit = Cookies.get(Constants.ACTIVITY_ACTIVE_TOOLV2);
                try {
                    let splitValues = window.location.hash.split("&");
                    worksheetId = splitValues[1].split("=")[1]
                } catch (e) { }
            } else {
                activityToInit = getInitTypeFromUrl();
                const params = new URLSearchParams(window.location.search);
                worksheetId = params.get("worksheetId");
            }

            if (worksheetId) {
                activityToInit = ""; // if exist wsId, dont need activityToInit
                sourcePage = Config.SOURCE_USER_BEHAVIOR.CLICK_CUSTOMIZE_WS;
            } else {
                localStorage.setItem(Constants.AUTO_SEARCH_TEMPLATE, "");
            }
            // Cookies.set(Constants.ACTIVITY_ACTIVE_TOOLV2, "");
            dispatch(
                requestInitPageWs({
                    ratio,
                    activityToInit,
                    worksheetId,
                    sourcePage,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            let recentModuleSelected = [Config.MODULE_NAME_CONFIG.TOOL_V2];
            let valueCookie = Cookies.get(Config.RECENT_MODULE_SELECTED);
            if (valueCookie?.length > 0) {
                recentModuleSelected = JSON.parse(valueCookie);
                if (
                    recentModuleSelected[0] != Config.MODULE_NAME_CONFIG.TOOL_V2
                ) {
                    recentModuleSelected = recentModuleSelected.filter(
                        (item) => item !== Config.MODULE_NAME_CONFIG.TOOL_V2
                    );
                    recentModuleSelected.unshift(
                        Config.MODULE_NAME_CONFIG.TOOL_V2
                    );
                }
            }

            Cookies.set(
                Config.RECENT_MODULE_SELECTED,
                JSON.stringify(recentModuleSelected)
            );

            const typeSurvey = getInitTypeFromUrl();

            let recentModuleMaker: any = Cookies.get(
                Config.RECENT_MODULE_MAKER
            );
            if (!recentModuleMaker) {
                recentModuleMaker = [];
            } else {
                recentModuleMaker = JSON.parse(recentModuleMaker);
            }
            if (typeSurvey) {
                recentModuleMaker = recentModuleMaker?.filter(
                    (item) => item !== typeSurvey
                );

                recentModuleMaker.unshift(typeSurvey);
            }
            Cookies.set(
                Config.RECENT_MODULE_MAKER,
                JSON.stringify(recentModuleMaker)
            );
        }
    }, []);

    useEffect(() => {
        const type = (new URLSearchParams(window.location.search)).get('type');
        const storeID = parseInt((new URLSearchParams(window.location.search)).get('storeID'));
        const id = (new URLSearchParams(window.location.search)).get('id');
        const displayMode = (new URLSearchParams(window.location.search)).get('displayMode');
        const token = (new URLSearchParams(window.location.search)).get('token');
        const autoSave = (new URLSearchParams(window.location.search)).get('autoSave');
        const worksheetWidth = parseInt((new URLSearchParams(window.location.search).get('w')));
        const worksheetHeight = parseInt((new URLSearchParams(window.location.search).get('h')));

        console.log('init ws-canvas');

        if (token !== null) {
            localStorage.setItem('_bee1203_learningmaterials_token', token);
        }

        if (type) {
            fetchPermissons();
            fetchStaffData();

            dispatch(setPageType({pageType: type}));

            if (Object.values(ConstantsTool.TEMPLATE_TYPES).includes(type)) {
                dispatch(setDisabledSaveButton(false));
                if (!isNaN(storeID)) {
                    dispatch(setTemplate({ ...initialTemplate, store: storeID }))
                }
                if (id !== null) {
                    fetchTemplate(id, type, worksheetWidth, worksheetHeight);
                } else {
                    window.alert('no id');
                }
                dispatch(changeActiveTab(Config.TEMPLATE_PAGE));
            } else if (id !== null) {
                if (type === ConstantsTool.PAGE_TYPES['page']) {
                    if (displayMode === 'iframe') {
                        // hot fix delay 3s before loading data
                        setTimeout(() => fetchIdeaTemplate(id, type, autoSave !== null), 3000);
                    } else {
                        fetchIdeaTemplate(id, type, autoSave !== null);
                    }
                } else if (type === ConstantsTool.PAGE_TYPES['answerSheet']) {
                    fetchAnswerSheetPage(id, autoSave !== null);
                } else if (type === ConstantsTool.PAGE_TYPES['answerKey']) {
                    fetchAnswerKeyPage(id, autoSave !== null);
                } else if (type === ConstantsTool.PAGE_TYPES['thumbnail']) {
                    fetchIdeaThumbnail(id, autoSave !== null);
                } else if (type === ConstantsTool.PAGE_TYPES['dataSetDesignRow']) {
                    fetchDataSetDesignRow(id);
                }
            }

            if (displayMode === 'iframe') {
                dispatch(setIsIframe(true));
            }
        }
    }, []);

    useEffect(() => {
        const reorderFontFamilyListByStore = () => {
            const currentStore = template?.store || ideaTemplate?.store;
            dispatch(setCurrentStore(currentStore));
            let updatedFontFamilyList = sortResourcesByStore([...fontFamilyList], currentStore);
            if (!updatedFontFamilyList.every((font, index: number) => font === fontFamilyList[index])) {
                dispatch(setFontFamilyList(updatedFontFamilyList));
            }
        };

        reorderFontFamilyListByStore();
    }, [ideaTemplate?.store, template?.store, fontFamilyList]);

    const fetchTemplate = async(id: string, type: string, worksheetWidth: number, worksheetHeight: number) => {
        console.log('set loading');
        let template;
        try {
            const res = await getDataFromAPI(`auto-content/templates/${id}/?additionalFields=isUsingInIdea${type === ConstantsTool.TEMPLATE_TYPES['storeThumbnail'] ? ',storeIcon' : ''}`);
            template = res.data;
            if (template.locked) {
                window.alert('Template Locked, you can view but can not save');
            }
        } catch (err) {
            dispatch(setLoading(false));
            return;
        }
        dispatch(setTemplate(template));
        let title: string;
        if (template.originalVersionTemplate === null) {
            title = 'Template ' + template['id'] + '. ' + template['name'];
        } else {
            title = 'Version ' + template.versionName + ' - Store ' + template.store;
        }
        dispatch(setTitle({title}));
        let templateContent = template['testAIResponse'];
        // if (type === ConstantsTool.TEMPLATE_TYPES['storeThumbnail']) {
        //     const storeIcon = template['storeIcon'];
        //     if (storeIcon) {
        //         const storeIconUrl = storeIcon.colorUrl ?? storeIcon.colorThumbnailUrl ?? storeIcon.bwUrl ?? storeIcon.bwThumbnailUrl;
        //         templateContent = {
        //             ...templateContent,
        //             'icon': storeIconUrl,
        //         }
        //     }
        // }
        dispatch(
            setContent({
                data: templateContent,
            })
        );
        await fetchTemplateDesignItems(template, type);
        const { width, height } = worksheetWidth && worksheetHeight ? { width: worksheetWidth, height: worksheetHeight } : getDimensionOfWorksheet(type, template);
        dispatch(changePageSize({
            pageIndex: 0,
            width,
            height,
            spacing: template.spacing,
        }));
    }

    const fetchIdeaTemplate = async(id: string, type: string, autoSave: boolean) => {
        let resIdeaTemplate = await getDataFromAPI(`auto-content/idea-templates/${id}/?additionalFields=pageIndex,formattedAIResponse`);
        const ideaTemplate = resIdeaTemplate.data;
        const resIdea = await getDataFromAPI(`product-ideas/${ideaTemplate["idea"]}/`);
        const resSetting = await getDataFromAPI('auto-content/idea-settings/?customID=' + resIdea.data['customID']);
        const setting = resSetting.data[0];
        dispatch(setIdeaTemplate({
            ...resIdeaTemplate.data,
            mathZones: resIdeaTemplate.data['formattedAIResponse']?.mathZones,
            iconZones: resIdeaTemplate.data['formattedAIResponse']?.iconZones,
            tags: resIdea.data.tags,
        }));
        dispatch(
            setTitle({
                title: resIdea.data['customID'] + '. ' + (resIdea.data['title'] ? resIdea.data['title'] : resIdea.data['name']) + ' (' + ideaTemplate['id'] + ')',
            })
        );

        dispatch(
            setContent({
                data: ideaTemplate['formattedAIResponse']['content'],
            })
        );

        if (ideaTemplate['formattedAIResponse']['content'] === null || Object.keys(ideaTemplate['formattedAIResponse']['content']).length === 0) {
            dispatch(setDisabledSaveButton(false));
        }
        let switchBGImage = false;
        if (resIdea.data['task']['stepPos'] >= ConstantsTool.COLOR_BY_CODE_USE_WHITE_BG_STEP && resIdea.data.tags.includes(ConstantsTool.COLOR_BY_CODE_TAG)) {
            switchBGImage = true;
        }
        if (resIdea.data.tags.includes(ConstantsTool.MYSTERY_PICTURE_COLOR_TAG)) {
            switchBGImage = true;
        }
        await fetchIdeaTemplateDesignItems(setting, ideaTemplate, switchBGImage);
        setTimeout(() => {
            dispatch(setTemplateZoneDisplayMode('textOnly'));
        }, 1000);
        if (autoSave) {
            setTimeout(() => {
                dispatch(setAutoSave(true));
            }, 1000);
        }
        dispatch(changePageSize({
            pageIndex: 0,
            ...getDimensionOfWorksheet(type, ideaTemplate['templateData'])
        }));
    }

    const fetchTemplateDesignItems = async(template: ITemplate, type: string) => {
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?templateID=${template.id}`);

        let items = res.data.map((item: any) => {
            if (item.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                item.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            return ({
                ...item,
                id: item.id,
            })
        });
        const initItems = [];

        if (type === 'template') {
            if (template.autoAddHeadline && items.filter(item => item.isHeadline).length === 0) {
                let defaultHeadline = ConstantsTool.DEFAULT_HEADLINE;
                if (template.orientation === 'horizontal') {
                    defaultHeadline['x'] = 0;
                    defaultHeadline['width'] = ConstantsTool.HEIGHT_A4;
                }
                initItems.push(defaultHeadline);
            }

            // isNameText
            if (template.autoAddName && template.orientation === 'vertical') {
                initItems.push(ConstantsTool.NAME_TEXT_ITEM);
            }
        }

        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items.concat(initItems),
            })
        );
        fetchFontFamilyList(items.concat(initItems));
        dispatch(setLoading(false));
    }

    const fetchIdeaTemplateDesignItems = async(setting: any = null, ideaTemplate: object, switchBGImage: boolean) => {
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?ideaTemplateID=${ideaTemplate['id']}`);
        let items = [];
        let switched = false;
        for (let i = 0; i < res.data.length; i++) {
            const item = res.data[i];
            item['id'] = res.data[i].id.toString();
            item['isMathFormula'] = res.data[i].isMathFormula;
            if (item.type === ConstantsTool.RESOURCE_TEXT || item.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                item['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            if (item.type === 1 && switchBGImage && ideaTemplate['templateData']['originalImageUrl'] && ideaTemplate['templateData']['tags'].indexOf(ConstantsTool.TEMPLATE_COLOR_BY_CODE_TAG) > -1 && !switched) {
                item['imageAttribute']['url'] = ideaTemplate['templateData']['originalImageUrl'];
                switched = true; // switch only one time for the background image
            }
            if (item.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                item.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            items.push(item);
        }

        const initItems = [];
        if (ideaTemplate['templateData']['autoAddHeadline']) {
            if (items.filter(item => item.isHeadline).length === 0) {
                let headline;
                const headlineItem = items.find(item => item.isHeadline);
                if (headlineItem === undefined) {
                    headline = ConstantsTool.DEFAULT_HEADLINE;
                    if (ideaTemplate['templateData']['orientation'] === 'horizontal') {
                        headline['x'] = 0;
                        headline['width'] = ConstantsTool.HEIGHT_A4;
                    }
                } else {
                    headline = headlineItem;
                }
                headline['textAttribute']['content'] = setting.headline?.toUpperCase();
                initItems.push(headline);
            } else {
                const headlineItem = items.find(item => item.isHeadline);
                headlineItem['textAttribute']['content'] = setting.headline?.toUpperCase();
            }
        }

        if (ideaTemplate['templateData'].autoAddName && ideaTemplate['templateData'].orientation === 'vertical' && items.filter(item => item.isNameText).length === 0) {
            initItems.push(ConstantsTool.NAME_TEXT_ITEM);
        }

        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items.concat(initItems),
            })
        );
        fetchFontFamilyList(items.concat(initItems));
        dispatch(setLoading(false));
    }

    const fetchAnswerSheetPage = async(id: string, autoSave: boolean) => {
        const res = await getDataFromAPI(`auto-content/idea-answer-sheet-pages/${id}/`);
        dispatch(setIdeaAnswerSheetPage(res.data));
        await fetchAnswerSheetTemplateDesignItems(id);
        setTimeout(() => {
            dispatch(setTemplateZoneDisplayMode('textOnly'));
        }, 1000);
        if (autoSave) {
            setTimeout(() => {
                dispatch(setAutoSave(true));
            }, 1000);
        }
        dispatch(changePageSize({
            pageIndex: 0,
            ...getDimensionOfWorksheet('template', res.data['templateData'])
        }));
    }

    const fetchAnswerSheetTemplateDesignItems = async(ideaAnswerSheetPageID: string) => {
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?ideaAnswerSheetPageID=${ideaAnswerSheetPageID}`);

        const items = res.data.map((item: any) => {
            let itemAttributes = item;
            if (itemAttributes.type === ConstantsTool.RESOURCE_TEXT || itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                itemAttributes['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            if (itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                itemAttributes.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            return ({
                ...itemAttributes,
                id: item.id.toString()
            });
        });
        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items,
            })
        );
        fetchFontFamilyList(items);
        dispatch(setLoading(false));
    }

    const fetchAnswerKeyPage = async(id: string, autoSave: boolean) => {
        const resIdeaAnswerKeyPage = await getDataFromAPI(`auto-content/idea-answer-key-pages/${id}/`);
        const resIdea = await getDataFromAPI(`product-ideas/${resIdeaAnswerKeyPage.data['idea']}/`);
        const resSetting = await getDataFromAPI('auto-content/idea-settings/?customID=' + resIdea.data['customID']);
        const setting = resSetting.data[0];
        const resAnswerKeys = await getDataFromAPI(`resources/get-answer-keys-by-page-indexes/?ideaID=${resIdeaAnswerKeyPage.data['idea']}&pageIndexes=${resIdeaAnswerKeyPage.data['ideaPageIndexes'].join(',')}`)
        dispatch(setIdeaAnswerKeyPage({
            ...resIdeaAnswerKeyPage.data,
            answerKeys: resAnswerKeys.data,
        }));
        await fetchAnswerKeyTemplateDesignItems(id, setting);
        setTimeout(() => {
            dispatch(setTemplateZoneDisplayMode('textOnly'));
        }, 1000);
        if (autoSave) {
            setTimeout(() => {
                dispatch(setAutoSave(true));
            }, 1000);
        }
        dispatch(changePageSize({
            pageIndex: 0,
            ...getDimensionOfWorksheet('template', resIdeaAnswerKeyPage.data['templateData'])
        }));
    }

    const fetchAnswerKeyTemplateDesignItems = async(ideaAnswerKeyPageID: string, setting: object) => {
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?ideaAnswerKeyPageID=${ideaAnswerKeyPageID}`);

        const items = res.data.filter((item: any) => {
            if (item.type === ConstantsTool.RESOURCE_TEXT && item.textAttribute.content.startsWith('Name')) {
                return false;
            }
            return true;
        }).map((item: any) => {
            let itemAttributes = item;
            if (itemAttributes.type === ConstantsTool.RESOURCE_TEXT || itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                itemAttributes['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            if (itemAttributes.isHeadline && setting['headline']) {
                itemAttributes['textAttribute']['content'] = setting['headline'];
            }
            if (itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                itemAttributes.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            return ({
                ...itemAttributes,
                id: item.id.toString()
            });
        });
        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items,
            })
        );
        fetchFontFamilyList(items);
        dispatch(setLoading(false));
    }

    const fetchDataSetDesignRow = async(id: string) => {
        const resRow = await getDataFromAPI(`resources/data-set-design-rows/${id}/`);
        const tinyTapID = (new URLSearchParams(window.location.search)).get('tinyTapID');
        if (tinyTapID) {
            dispatch(setDataSetDesignRow({...resRow.data, tinyTapID}));
        } else {
            dispatch(setDataSetDesignRow(resRow.data));
        }
        dispatch(
            setContent({
                data: resRow.data['content'],
            })
        );
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?dataSetDesignRowID=${id}`);
        const items = res.data.map((item: any) => {
            let itemAttributes = item;
            if (itemAttributes.type === ConstantsTool.RESOURCE_TEXT || itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                itemAttributes['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            if (itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                itemAttributes.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            return ({
                ...itemAttributes,
                id: item.id.toString()
            });
        });
        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items,
            })
        );
        dispatch(setLoading(false));
        setTimeout(() => {
            dispatch(setTemplateZoneDisplayMode('textOnly'));
        }, 1000);
        dispatch(changePageSize({
            pageIndex: 0,
            width: ConstantsTool.HEIGHT_A4,
            height: ConstantsTool.WIDTH_A4,
            spacing: {
                paddingTop: 0,
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: 0,
            },
        }));
        fetchFontFamilyList(items);
    }

    const fetchIdeaThumbnail = async(id: string, autoSave: boolean) => {
        const resIdeaThumbnail = await getDataFromAPI(`resources/idea-thumbnails/${id}/`);
        const resIdea = await getDataFromAPI('product-ideas/' + resIdeaThumbnail.data['idea'] + '/?additionalFields=highlightImages');

        let highlightImages = resIdea.data['highlightImages'];

        const colorVersion = (new URLSearchParams(window.location.search)).get('colorVersion');
        if ((resIdea.data['tags'].includes(ConstantsTool.TASKCARD_TAG) && colorVersion !== null) || resIdea.data['tags'].includes(ConstantsTool.COLOR_BY_CODE_TAG)) {
            try {
                const resIdeaColor = await getDataFromAPI('resources/get-color-version-by-customID/?customID=' + resIdea.data['customID']);
                highlightImages = resIdeaColor.data['highlightImages'];
            } catch (err) {
            }
        }

        const resIcon = await getDataFromAPI('resources/icons/?store=' + resIdea.data['store'] + '&tags=' + resIdea.data['tags'].join(','));
        let storeIconUrl = null;
        if (resIcon.data['results'].length > 0) {
            const storeIcon = resIcon.data['results'][Math.floor(Math.random() * resIcon.data['results'].length)];
            storeIconUrl = storeIcon.colorUrl ?? storeIcon.colorThumbnailUrl ?? storeIcon.bwUrl ?? storeIcon.bwThumbnailUrl;
        }

        dispatch(setIdeaThumbnail({
            ...resIdeaThumbnail.data,
            title: resIdea.data['thumbnailTitle'],
            subtitle: resIdea.data['thumbnailSubTitle'],
            subtitle2: resIdea.data['thumbnailSubTitle2'],
            subtitle3: resIdea.data['thumbnailSubTitle3'],
            subtitle4: resIdea.data['thumbnailSubTitle4'],
            subtitle5: resIdea.data['thumbnailSubTitle5'],
            preview1Url: highlightImages[0],
            preview2Url: highlightImages[1],
            preview3Url: highlightImages[2],
            preview4Url: highlightImages[3],
            totalPages: resIdea.data['nbPages'],
            storeIconUrl,
        }));
        await fetchIdeaThumbnailDesignItems(id);
        setTimeout(() => {
            dispatch(setTemplateZoneDisplayMode('textOnly'));
        }, 1000);
        if (autoSave) {
            setTimeout(() => {
                dispatch(setAutoSave(true));
            }, 1000);
        }
        dispatch(changePageSize({
            pageIndex: 0,
            ...getDimensionOfWorksheet('thumbnail', resIdeaThumbnail.data['templateData'])
        }));
    }

    const fetchIdeaThumbnailDesignItems = async(ideaThumbnailID: string) => {
        const res = await getDataFromAPI(`auto-content/get-design-items-mongo/?ideaThumbnailID=${ideaThumbnailID}`);
        const items = res.data.map((item: any) => {
            let itemAttributes = item;
            if (itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                itemAttributes['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            if (itemAttributes.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE) {
                itemAttributes.type = ConstantsTool.TYPE_RESOURCE_ZONE;
            }
            return ({
                ...itemAttributes,
                id: item.id
            });
        });
        dispatch(
            addResourceItems({
                pageIndex: 0,
                resourceItems: items,
            })
        );
        fetchFontFamilyList(items);
        dispatch(setLoading(false));
    }

    const fetchFontFamilyList = async (resourceItems: IResourceItemNew[]) => {
        dispatch(setAllFontsLoaded(false));
        const res = await getDataFromAPI('auto-content/font-families/');

        if (res.data) {
            let listFonts = res.data;
            dispatch(setFontFamilyList(listFonts));
            for (const resourceItem of resourceItems) {
                if (resourceItem?.textAttribute?.fontFamily) {
                    let fontObj = listFonts.find(
                        (e: IFont) => e.name === resourceItem?.textAttribute?.fontFamily
                    );
                    if (fontObj) {
                        await loadFonts(fontObj);
                        listFonts = listFonts.filter((e: IFont) => e.name != resourceItem?.textAttribute?.fontFamily);
                    }
                }
            }
            dispatch(setAllFontsLoaded(true));
            for (const fontObj of listFonts) {
                await loadFonts(fontObj);
            };
        }
    }

    const fetchPermissons = async () => {
        const res = await getDataFromAPI('product-dev/staff-role-permissions/');

        let activityPermissions = {};

        res.data.forEach((permission: {
            type: string;
            action: string;
            allowRoles: string[];
        }) => {
            if (permission.type === 'activity') {
                activityPermissions[permission.action] = permission.allowRoles;
            }
        });

        dispatch(setActivityPermissions(activityPermissions));
    };

    const fetchStaffData = async () => {
        try {
            const res = await getDataFromAPI('product-dev/get-current-staff/');
            dispatch(setStaffInfo(res.data));
        } catch (err) {

        }
    }

    return (
        <Fragment>
            <LoadFont />
            <CustomizedSnackbars />
            <CommonSnackbar></CommonSnackbar>
            <MainPage></MainPage>
            <RenderToolTipCustomize />
        </Fragment>
    );
};

export default App;
