export const ImageItemWorksheet = ({
    src,
    alt,
    className,
    style,
}: {
    src: string;
    alt?: string;
    className?: string;
    style?: any;
}) => {
    if (!src?.length || src === "undefined") {
        return null;
    }
    return (
        <img
            className={className ?? " image"}
            alt={alt ?? "image"}
            src={src}
            style={style}
        ></img>
    );
};
