import React from "react";

const ArrowPrev = ({ dark = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 6 9"
            fill="none"
        >
            <path
                d="M5.00007 8.05123C5.26007 7.79123 5.26007 7.37123 5.00007 7.11123L2.4134 4.52456L5.00007 1.9379C5.26007 1.6779 5.26007 1.2579 5.00007 0.997895C4.74007 0.737895 4.32007 0.737895 4.06007 0.997895L1.00007 4.0579C0.740069 4.3179 0.740069 4.7379 1.00007 4.9979L4.06007 8.0579C4.3134 8.31123 4.74007 8.31123 5.00007 8.05123Z"
                fill={dark ? "#ffffff" : "#212121"}
            />
        </svg>
    );
};

export default ArrowPrev;
