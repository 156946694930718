import {
    Backdrop,
    MenuItem,
    OutlinedInput,
    Select,
    Slider,
    IconButton,
} from "@mui/material";
import { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import { useAppDispatch } from "../../../../redux/hook";
import {
    updateLineSpacing,
    updateNumberOfLine,
    updateQuestion,
} from "../../../../redux/reducers/createWorksheet";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../../../shared/models/questionActivity";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import CreateWSConfig from "../../../../utils/create-ws-config";
import "./index.scss";
import Config from "../../../../shared/utils/config";
import { getListFontFamilies } from "../../name-tracing/util";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";

const TextStyleSection = ({
    question,
    setConfig,
    config,
}: {
    question: IQuestionActivity;
    setConfig: any;
    config: any;
}) => {
    return (
        <>
            <div
                className={`text-style-section-hand-writing ${
                    question ? "show" : "hide"
                }`}
            >
                <div className="cluster">
                    <div className="title-and-color">
                        <div className="title">Font</div>
                        <div className="color">
                            <ChooseColor
                                question={question}
                                field="fontColor"
                            />
                        </div>
                    </div>
                    <CustomSelect
                        question={question}
                        setConfig={setConfig}
                        config={config}
                    />
                </div>
                <div className="cluster">
                    <div className="title-and-color">
                        <div className="title">Line Spacing</div>
                        <div className="color">
                            <ChooseColor question={question} field="color" />
                        </div>
                    </div>
                    <CustomSlider
                        field="lineSpacing"
                        question={question}
                        max={Object.keys(ConstantsTool.LINE_SPACING).length}
                    />
                </div>

                <div className="cluster">
                    <div className="title-and-color">
                        <div className="title">Number of Lines</div>
                    </div>
                    <CustomSlider
                        field="numberOfLines"
                        question={question}
                        max={ConstantsTool.MAX_NUMBER_OF_LINE}
                    />
                </div>
            </div>
        </>
    );
};

const CustomSelect = ({
    question,
    config,
    setConfig,
}: {
    question: IQuestionActivity;
    config: any;
    setConfig: any;
}) => {
    const refInput = useRef(null);
    const dispatch = useAppDispatch();
    const font = question?.handwritingAttribute?.fontFamily ?? "Standard Print";
    const [open, setOpen] = useState(false);
    let fontFamilies = getListFontFamilies(
        question?.handwritingAttribute?.fontStyle,
        font
    );

    const handleChangeFontFamily = (newFont: string) => {
        const payload = new QuestionActivity({
            ...question,
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
        });
        payload.handwritingAttribute.fontFamily = newFont;
        const newFontFamily = fontFamilies?.find((f) => f.name === newFont);
        const isStroked = config.textStyle === ConstantsTool.TEXT_STYLE.STROKED;

        if (isStroked && newFontFamily?.stroked === CreateWSConfig.EMPTY_FONT) {
            payload.handwritingAttribute.textStyle =
                ConstantsTool.TEXT_STYLE.DASHED;
            setConfig({
                ...config,
                textStyle: ConstantsTool.TEXT_STYLE.DASHED,
            });
        }
        dispatch(
            updateQuestion({
                question: payload,
            })
        );
    };
    const MyCustomIcon = () => {
        if (open) {
            return (
                <IconButton>
                    <img src={svgPath(IconSVG.put_up_tool)} alt="" />
                </IconButton>
            );
        } else {
            return (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        refInput.current.focus();
                    }}
                >
                    <img src={svgPath(IconSVG.drop_down_tool)} alt="" />
                </IconButton>
            );
        }
    };
    const handleFocus = () => {
        setOpen(true);
    };

    const handleBlur = () => {
        setOpen(false);
    };

    return (
        <div className="select-font">
            <Select
                inputRef={refInput}
                open={open}
                onOpen={handleFocus}
                onClose={handleBlur}
                IconComponent={MyCustomIcon}
                className="select"
                value={font}
                onChange={(e, x) => {
                    handleChangeFontFamily(e.target.value);
                }}
                input={<OutlinedInput fullWidth />}
                sx={{
                    "& .MuiSelect-select": {
                        fontFamily: `"${
                            fontFamilies.find((f) => f.name === font)?.regular
                        }" !important`,
                        fontSize: 14,
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 180,
                        },
                    },
                    disableScrollLock: true,
                }}
            >
                {fontFamilies.map((font) => {
                    const { name, regular } = font;

                    return (
                        <MenuItem
                            className="font-family-item canvas-text"
                            key={name}
                            value={name}
                            sx={{
                                fontFamily: `"${regular}" !important`,
                                fontSize: "12px",
                                paddingLeft: "10px",
                            }}
                        >
                            {name}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

const ChooseColor = ({
    question,
    field,
}: {
    question: IQuestionActivity;
    field: string;
}) => {
    const dispatch = useAppDispatch();
    const color = question?.handwritingAttribute?.[field] ?? "black";

    const [show, setShow] = useState(false);

    const handleChangeTextColor = (newColor: string) => {
        const newQuestion = new QuestionActivity({
            ...question,
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
        });
        newQuestion.handwritingAttribute[field] = newColor;
        dispatch(
            updateQuestion({
                question: newQuestion,
            })
        );
    };

    return (
        <>
            <div
                className="choose-color"
                style={{
                    backgroundColor: color,
                }}
                onClick={() => {
                    setShow(!show);
                }}
            >
                {show && (
                    <div
                        className="picker-wrapper"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SketchPicker
                            color={color}
                            onChangeComplete={(newColor) => {
                                handleChangeTextColor(newColor.hex);
                            }}
                        />
                    </div>
                )}
            </div>
            {show && (
                <Backdrop
                    sx={{
                        zIndex: 4,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                    }}
                    open={color?.length > 0}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setShow(false);
                    }}
                    invisible={true}
                />
            )}
        </>
    );
};

const CustomSlider = ({
    field,
    question,
    max,
    showLabel = true,
}: {
    field: string;
    question: IQuestionActivity;
    max: number;
    showLabel?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const value = question?.handwritingAttribute?.[field] ?? 1;

    const handleUpdateField = (newValue: number) => {
        const newQuestion = new QuestionActivity({
            ...question,
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
        });
        newQuestion.handwritingAttribute[field] = newValue;
        if (field === "lineSpacing") {
            dispatch(
                updateLineSpacing({
                    question: newQuestion,
                })
            );
        } else if (field === "numberOfLines") {
            dispatch(
                updateNumberOfLine({
                    question: newQuestion,
                })
            );
        }
    };

    return (
        <div className="slider-section">
            <div className="label">{showLabel ? value : ""}</div>
            <Slider
                className="slider-element"
                min={1}
                max={max}
                value={value}
                onChange={(e, newValue: number) => handleUpdateField(newValue)}
                onChangeCommitted={(e, newValue: number) => {}}
            />
        </div>
    );
};

export default TextStyleSection;
