import { ANSWER_TYPE, IAnswer } from "../../shared/models/answer";
import { ConfigWordSearch } from "../../shared/utils/configWordSearch";

export const getSizeScreenWordSearch = (currentHeight: number) => {
    if (currentHeight > ConfigWordSearch.HEIGHT) {
        currentHeight = ConfigWordSearch.HEIGHT;
    }
    let height = currentHeight - 80;
    let ratio = height / ConfigWordSearch.HEIGHT;
    let width = ratio * ConfigWordSearch.WIDTH;
    return {
        width,
        height,
        ratio,
    };
};

export const checkWSInteractive = (answers: IAnswer[]) : boolean => {
    for( let i = 0 ; i < answers.length ; i++ ) {
        if (answers[i].type !== ANSWER_TYPE.WORD_SEARCH_BY_TOOL){
            return true;
        }
    }
    return false;
};