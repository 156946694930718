import React from "react";
import "./index.scss";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";

const WordDirection = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    return (
        <div
            className="word-direction-resource"
            style={
                {
                    width: resourceItem.width,
                    height: resourceItem.height,
                    color: resourceItem?.textAttribute?.fontColor,
                    "--svg-color": resourceItem?.textAttribute?.fontColor,
                } as any
            }
        >
            <div className="text-effect">
                {ConstantsTool.TEXT_WORD_DIRECTION}
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: resourceItem.imageAttribute?.svgContent,
                }}
            ></div>
        </div>
    );
};
export default WordDirection;
