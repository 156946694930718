import { isObject } from "../utils";
import Config from "../utils/config";
import { IUserInfo, UserInfo } from "./userInfo";
import { IWorksheet, Worksheet } from "./worksheet";

export interface ICollection {
    id: string;
    userId: string;
    name: string;
    introductionDescription: string;
    collectionFeatureDescription: string;
    avatar: string;
    createDate: Date;
    worksheetItems: IWorksheetItem[];
    user?: IUserInfo;
    worksheets?: any[];
    worksheetNames?: string[];
    categoryIds?: string[];
    topicIds?: string[];
    commonCoreIds?: string[];
    tagIds?: string[];
    allTags?: any[];
    isManagement?: boolean;
    source?: string;
    status?: string;
    lastSubmited?: Date;
    lastIndexed?: Date;
    sharingStatus?: Number;
    parentIds: string[];
    numberOfWorksheets?: number;
    parentCollectionName: any[];
    thumbnail?: string;
    lock?: boolean;
    typeGrid?: string;
    numberWsInteractive?: number;
    isChoose?: boolean;
    worksheetImages?: IWorksheetImage[];
}

export interface IWorksheetImage {
    id: string;
    tiltle: string;
    thumbnail: string;
}

export interface IWorksheetItem {
    id: string;
    worksheetId: string;
    joinedDate: Date;
    type?: number;

    worksheet?: IWorksheet;
}

export class WorksheetItem implements IWorksheetItem {
    id: string;
    worksheetId: string;
    joinedDate: Date;
    type?: number;

    worksheet?: IWorksheet;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.joinedDate = object.joinedDate && new Date(object.joinedDate);
        this.worksheetId = object.worksheetId ?? null;
        this.worksheet = object.worksheet;
        this.type = object.type ?? Config.PDF_WORKSHEET;
        if (isObject(object.worksheetId)) {
            this.worksheet = new Worksheet(object.worksheetId);
            this.worksheetId = this.worksheet.id;
        } else {
            this.worksheetId = object.worksheetId ?? null;
        }
    }
}

export class Collection implements ICollection {
    id: string;
    userId: string;
    parentIds: string[];
    name: string;
    introductionDescription: string;
    collectionFeatureDescription: string;
    avatar: string;
    createDate: Date;
    worksheetItems: IWorksheetItem[];
    type: number;

    worksheets?: any[];
    worksheetNames?: string[];
    categoryIds?: string[];
    topicIds?: string[];
    commonCoreIds?: string[];
    tagIds?: string[];
    allTags?: any[];
    isManagement?: boolean;
    source?: string;
    status?: string;
    lastSubmited?: Date;
    lastIndexed?: Date;
    sharingStatus?: Number;
    numberOfWorksheets?: number;
    parentCollectionName: any[];
    thumbnail?: string;
    user?: IUserInfo;
    lock?: boolean;
    typeGrid?: string;
    numberWsInteractive?: number;
    isChoose?: boolean;
    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.userId = object.userId ?? null;
        this.name = object.name ?? "";
        this.introductionDescription = object.introductionDescription ?? "";
        this.collectionFeatureDescription =
            object.collectionFeatureDescription ?? "";
        this.avatar = object.avatar ?? "";
        this.createDate = object.createDate && new Date(object.createDate);
        this.worksheetNames = object.worksheetNames ?? [];
        this.categoryIds = object.categoryIds ?? [];
        this.topicIds = object.topicIds ?? [];
        this.commonCoreIds = object.commonCoreIds ?? [];
        this.tagIds = object.tagIds ?? [];
        this.allTags = object.allTags ?? [];
        this.type = object.type ?? Config.COLLECTION_TYPE_NONE;
        if (object.worksheetItems) {
            this.worksheetItems = object.worksheetItems.map(
                (w: any) => new WorksheetItem(w)
            );
        } else {
            this.worksheetItems = [];
        }
        if (object.user) {
            this.user = new UserInfo(object.user);
        }
        if (isObject(object.userId)) {
            this.user = new UserInfo(object.userId);
            this.userId = this.user?.id;
        } else {
            this.userId = object.userId;
        }
        if (object.worksheets) {
            this.worksheets = object.worksheets ?? [];
        } else {
            this.worksheets = [];
        }
        this.isManagement = object.isManagement ?? false;
        this.source = object.source ?? "";
        this.status = object.status ?? "";
        this.lastSubmited =
            object.lastSubmited && new Date(object.lastSubmited);
        this.lastIndexed = object.lastIndexed && new Date(object.lastIndexed);
        this.sharingStatus = object.sharingStatus ?? 0;
        this.parentIds = object.parentIds ?? [];
        this.numberOfWorksheets = object.numberOfWorksheets ?? 0;
        this.parentCollectionName = object.parentCollectionName ?? [];
        this.thumbnail = object.thumbnail ?? "";
        this.lock = object.lock ?? false;
        this.typeGrid = object.typeGrid ?? "";
        this.numberWsInteractive = object.numberWsInteractive ?? 0;
        this.isChoose = object?.isChoose ?? false;
    }
}
