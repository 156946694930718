import { useDispatch, useSelector } from "react-redux";
import { IWord } from "../../../../shared/models/crossword";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { updateCurrentConfigAction } from "../../../redux/action/crossword.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import "./index.scss";
import { sendEvent } from "../../../utils/event";

const ClueCrossword = ({
    resourceItem,
    ratio = 1,
}: {
    resourceItem: IResourceItemNew;
    ratio?: number;
}) => {
    const dispatch = useDispatch();
    const isAcross = resourceItem.idType?.includes("_across");
    const color = resourceItem.textAttribute.fontColor;
    const wordsState = useSelector((state: ResourceAppState) =>
        state.crosswordState.words.filter(
            (word) =>
                word.onGrid &&
                word?.direction ===
                    (isAcross
                        ? ConstantsTool.DIRECTIONS.ACROSS
                        : ConstantsTool.DIRECTIONS.DOWN)
        )
    );
    const direction = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentDirection
    );
    const wordSelected = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentWordSelected
    );
    const position = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentPosition
    );
    const words: IWord[] = JSON.parse(JSON.stringify(wordsState));
    words.sort((w, _w) => w.indexPrefix - _w.indexPrefix);

    const wordOnPosition = words.find((word) => {
        if (position) {
            const { row: minRow, column: minColumn } = word.position;
            const { row, column } = position;
            let maxRow = minRow;
            let maxColumn = minColumn;
            if (word.direction === ConstantsTool.DIRECTIONS.ACROSS) {
                maxColumn += word.value.length - 1;
            } else {
                maxRow += word.value.length - 1;
            }

            if (
                minRow <= row &&
                row <= maxRow &&
                minColumn <= column &&
                column <= maxColumn
            ) {
                return true;
            }
        }

        return false;
    });

    const onClickClue = (word: IWord, e: any) => {
        const isSameWord = wordSelected?.index === word?.index;
        let id: string;

        // Wait for blur trigger first
        setTimeout(() => {
            if (isSameWord) {
                id = `crossword-${position.row}-${position.column}`;
            } else {
                dispatch(
                    updateCurrentConfigAction({
                        direction: word?.direction,
                        wordSelected: word,
                        position: isSameWord ? position : word.position,
                    })
                );
                id = `crossword-${word.position.row}-${word.position.column}`;
            }

            if (id?.length > 0) {
                const nextInput: HTMLInputElement = document.querySelector(
                    "#" + id
                );

                if (nextInput) {
                    nextInput.focus({ preventScroll: true });
                }
            }
        }, 100);

        sendEvent(isAcross ? "crw_across" : "crw_down", {
            value: word.value,
            clue: word.clue,
        });
    };
    return (
        <div
            className="clue_crossword"
            id={"container_" + resourceItem.idType}
            onMouseDown={(e) => e.preventDefault()}
            style={
                {
                    color,
                    "--color-ar": color,
                } as any
            }
        >
            <div
                className="title"
                style={{
                    marginBottom: `${2 * ratio}px`,
                    marginLeft: `${2 * ratio}px`,
                }}
            >
                {isAcross ? "ACROSS" : "DOWN"}
                {isAcross ? (
                    <ArrowRightIcon ratio={ratio} />
                ) : (
                    <ArrowDownIcon ratio={ratio} />
                )}
            </div>
            <div
                className="clues"
                style={{
                    padding: `${2 * ratio}px`,
                }}
            >
                {words.map((word) => {
                    const isSelected = word?.index === wordOnPosition?.index;
                    const isSelectedWithDirection =
                        word?.index === wordOnPosition?.index &&
                        word.direction === direction;
                    const isFilled = !word.userAnswer.includes(
                        ConstantsTool.EMPTY_LETTER
                    );
                    return (
                        <div
                            style={{
                                padding: `${3 * ratio}px`,
                                borderWidth: `${2 * ratio}px`,
                            }}
                            key={word.index}
                            className={
                                "clue " +
                                (isSelected ? " selected " : "") +
                                (isSelectedWithDirection
                                    ? " selected_with_direction "
                                    : "")
                            }
                            onMouseDown={(e) => {
                                onClickClue(word, e);
                            }}
                        >
                            <span
                                className={
                                    isFilled ? ConstantsTool.LINE_THROUGH : ""
                                }
                            >
                                {word.indexPrefix}.{"  "}
                                {word.clue}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const ArrowDownIcon = ({ ratio = 1 }) => {
    return (
        <svg
            width={20 * ratio}
            height={20 * ratio}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 4L12 20M12 20L18 14M12 20L6 14"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const ArrowRightIcon = ({ ratio = 1 }) => {
    return (
        <svg
            width={20 * ratio}
            height={20 * ratio}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M14.4302 5.92993L20.5002 11.9999L14.4302 18.0699"
                stroke="#212121"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.5 12H20.33"
                stroke="#212121"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ClueCrossword;
