import {
    all,
    debounce,
    fork,
    put,
    select,
    takeLatest,
} from "redux-saga/effects";
import { IWord } from "../../../shared/models/crossword";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import { generateCrossword } from "../../components/crossword/logic";
import {
    formatWords,
    genDefaultGrid,
} from "../../components/crossword/logic/utils";
import {
    CrosswordTypes,
    ICrosswordAction,
    changeClueCrosswordSuccessAction,
    changeWordsSuccessAciton,
    initCrosswordGameSuccessAction,
    updateCurrentConfigSuccessAction,
    updateUserAnswerSuccessAction,
} from "../action/crossword.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import { sendEvent } from "../../utils/event";
import Config from "../../../shared/utils/config";
import { Answer } from "../../../shared/models/answer";
import { updateUserAnswer } from "../action/game.action";

function* initCrosswordGameSaga(action: ICrosswordAction) {
    const config = action.config;

    if (config.words.length === 0) {
        config.grid = genDefaultGrid();
    }

    yield put(initCrosswordGameSuccessAction(config));
}

function* changeWordsSaga(action: ICrosswordAction) {
    const { words } = action;

    const { words: newWords, grid } = generateCrossword(formatWords(words));
    yield put(changeWordsSuccessAciton(newWords, grid));
}

function* changeSingleWordsSaga(action: ICrosswordAction) {
    const { value, index } = action;
    const words: IWord[] = yield select(
        (state: ResourceAppState) => state.crosswordState.words
    );
    const wordsParse = JSON.parse(JSON.stringify(words));
    const word = wordsParse?.[index];
    if (word) {
        word.value = value;
    }

    const { words: newWords, grid } = generateCrossword(
        formatWords(wordsParse)
    );
    yield put(changeWordsSuccessAciton(newWords, grid));
}

function* updateUserAnswerSaga(action: ICrosswordAction) {
    const { position, value } = action;
    const words: IWord[] = yield select(
        (state: ResourceAppState) => state.crosswordState.words
    );
    const { row, column } = position;
    const wordChanges = [];
    const wordsUpdate = words.map((word) => {
        if (word.onGrid) {
            const wordValue = word.value.split("").join("").toUpperCase();
            const valueLength = wordValue.length;
            const { row: minRow, column: minColumn } = word.position;
            let maxRow = minRow;
            let maxColumn = minColumn;

            if (word.direction === ConstantsTool.DIRECTIONS.ACROSS) {
                maxColumn += valueLength - 1;
            } else {
                maxRow += valueLength - 1;
            }

            if (
                minRow <= row &&
                row <= maxRow &&
                minColumn <= column &&
                column <= maxColumn
            ) {
                const indexChange = row + column - minRow - minColumn;
                const newUserAnswer = word.userAnswer.split("");
                newUserAnswer[indexChange] = value;
                const newValue = newUserAnswer.join("");
                wordChanges.push({
                    index: word.index,
                    value: newValue,
                });

                return {
                    ...word,
                    status:
                        newValue.toUpperCase() === wordValue.toUpperCase()
                            ? ConstantsTool.CROSSWORD_STATUS.CORRECT
                            : ConstantsTool.CROSSWORD_STATUS.INCORRECT,
                    userAnswer: newValue,
                };
            }
        }

        return word;
    });

    if (Config.MODULE_NAME !== Config.MODULE_NAME_CONFIG.TOOL_V2) {
        const answers: any[] = yield select(
            (state: ResourceAppState) => state.gameResourceState.answers
        );

        const putActions = [];
        wordChanges.forEach(({ index, value }) => {
            const currentAnswer = answers.find((ans) => ans.answerId === index);
            if (currentAnswer) {
                let newAnswer = new Answer(currentAnswer);
                putActions.push(
                    put(
                        updateUserAnswer({
                            ...newAnswer,
                            userAnswer: value,
                        })
                    )
                );
            }
        });

        yield all(putActions);
    }

    yield put(updateUserAnswerSuccessAction(wordsUpdate));
}

function* updateDebounceCurrentConfig() {
    yield debounce(
        10,
        CrosswordTypes.UPDATE_CURRENT_CONFIG,
        updateCurrentConfig
    );
}
function* updateCurrentConfig(action: ICrosswordAction) {
    const currentWord = yield select(
        (state: ResourceAppState) => state.crosswordState.currentWordSelected
    );
    const { direction, wordSelected, position } = action;

    if (wordSelected && currentWord?.value !== wordSelected?.value) {
        sendEvent("crw_play", {
            value: wordSelected?.value,
            clue: wordSelected?.clue,
        });
    }

    yield put(
        updateCurrentConfigSuccessAction({ direction, wordSelected, position })
    );
}

function* scrambleCrosswordSaga() {
    const words = yield select(
        (state: ResourceAppState) => state.crosswordState.words
    );
    const { words: newWords, grid } = generateCrossword(formatWords(words));
    yield put(changeWordsSuccessAciton(newWords, grid));
}

function* changeClueCrosswordSaga(action: ICrosswordAction) {
    const { value, index } = action;
    const words: IWord[] = yield select(
        (state: ResourceAppState) => state.crosswordState.words
    );
    const newWords = JSON.parse(JSON.stringify(words));
    const word: IWord = newWords?.[index];

    if (word) {
        word.clue = value;
    }

    yield put(changeClueCrosswordSuccessAction(newWords));
}

function* listenCrosswordSaga() {
    yield takeLatest(CrosswordTypes.INIT_CROSSWORD_GAME, initCrosswordGameSaga);
    yield takeLatest(CrosswordTypes.CHANGE_WORDS, changeWordsSaga);
    yield takeLatest(CrosswordTypes.CHANGE_SINGLE_WORD, changeSingleWordsSaga);
    yield takeLatest(CrosswordTypes.UPDATE_USER_ANSWER, updateUserAnswerSaga);
    yield takeLatest(CrosswordTypes.SCRAMBLE_CROSSWORD, scrambleCrosswordSaga);
    yield takeLatest(
        CrosswordTypes.CHANGE_CLUE_CROSSWORD,
        changeClueCrosswordSaga
    );

    // Debounce
    yield updateDebounceCurrentConfig();
}

export const crosswordSaga = [fork(listenCrosswordSaga)];
