import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import ConstantsWordScramble from "../../../../../../shared/utils/ConstantsWordScramble";
import Config from "../../../../../../shared/utils/config";
import "./index.scss";
import { genDisplay } from "../../../../../../resource-user/utils";

const getItemContent = (content: string) => {
    return JSON.parse(content);
};

const ResourceWordScramble = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    const showAnswerKey = useSelector(
        (state: RootState) =>
            state.createWorksheetState.currentActivity.showAnswerKey
    );
    const {
        content,
        fontSize,
        fontFamily,
        align,
        fontColor,
        underline,
        isBold,
        isItalic,
    } = resourceItem.textAttribute;
    const { color } = resourceItem.imageAttribute.changeColor;
    const { gridTemplateColumn, prefix, origin, shuffle, strokeStyle, index } =
        getItemContent(content);

    const style = {
        textAlign: align,
        textDecoration: `${underline ? "underline" : "unset"}`,
        fontWeight: `${isBold ? "bold" : "normal"}`,
        fontStyle: `${isItalic ? "italic" : "normal"}`,
        color: fontColor,
        fontFamily,
        bColor: color,
    };

    return (
        <div
            className="resource-word-scramble canvas-text"
            style={
                {
                    fontSize,
                    gridTemplateColumns: gridTemplateColumn,
                    "--height-item": `${resourceItem.height}px`,
                } as any
            }
        >
            <div className="prefix canvas-text" style={style}>
                {prefix}
            </div>
            <div className="shuffle canvas-text" style={style}>
                {shuffle}
            </div>
            <MainInput
                defaultValue={origin}
                strokeStyle={strokeStyle}
                wordIndex={parseInt(index)}
                showAnswerKey={showAnswerKey === Config.SHOW_VALUE}
                style={style}
            />
        </div>
    );
};

const MainInput = ({
    defaultValue,
    strokeStyle,
    wordIndex,
    showAnswerKey,
    style,
}: {
    defaultValue: string;
    strokeStyle: number;
    wordIndex: number;
    showAnswerKey: boolean;
    style: any;
}) => {
    if (strokeStyle === ConstantsWordScramble.STROKE_STYLE.SHAPE) {
        return (
            <ShapeInput
                defaultValue={defaultValue}
                wordIndex={wordIndex}
                showAnswerKey={showAnswerKey}
                style={style}
            />
        );
    }

    return (
        <Input
            defaultValue={defaultValue}
            strokeStyle={strokeStyle}
            showAnswerKey={showAnswerKey}
            style={style}
        />
    );
};

const ShapeInput = ({
    defaultValue,
    wordIndex,
    showAnswerKey,
    style,
}: {
    defaultValue: string;
    wordIndex: number;
    showAnswerKey: boolean;
    style: any;
}) => {
    const letters = defaultValue.split("");
    const [value, setValue] = useState(Array(letters.length).fill(""));

    const handleChangeText = (key: string, index: number) => {
        const tempValue = value.slice();
        tempValue[index] = key;
        setValue(tempValue);
    };

    const handleMove = ({ e, index }: { e?; index?: number }) => {
        const key = e?.key;
        let id: string;

        if (
            [
                "ArrowDown",
                "ArrowUp",
                "ArrowLeft",
                "ArrowRight",
                "Enter",
                "Backspace",
            ].includes(key) ||
            /^[a-zA-Z]$/.test(key)
        ) {
            let _wordIndex = wordIndex;
            let _index = index;

            switch (key) {
                case "ArrowDown":
                    _wordIndex += 1;
                    _index = 0;
                    break;
                case "ArrowUp":
                    _wordIndex -= 1;
                    _index = 0;
                    break;
                case "Backspace":
                case "ArrowLeft":
                    if (key === "Backspace" && value[index]) {
                        handleChangeText("", index);
                    }
                    _index -= 1;
                    if (index === 0) {
                        _wordIndex -= 1;
                        _index = 0;
                    }
                    break;
                case "Enter":
                case "ArrowRight":
                default:
                    _index += 1;
                    if (index === value.length - 1) {
                        _wordIndex += 1;
                        _index = 0;
                    }
                    break;
            }

            id = `word-scramble-${_wordIndex}-${_index}`;
        }

        if (id?.length > 0) {
            const currentInput: HTMLInputElement = document.querySelector(
                e.target.id
            );
            const nextInput: HTMLInputElement = document.querySelector(
                "#" + id
            );

            if (currentInput) {
                setTimeout(() => {
                    currentInput.blur();
                }, 100);
            }

            if (nextInput) {
                nextInput.focus({ preventScroll: true });
                return true;
            }
        }
        return false;
    };

    const handleKeyDown = (e, index: number) => {
        const key = e.key;

        if (/^[a-zA-Z]$/.test(key)) {
            handleChangeText(key, index);
        }
        handleMove({ e, index });
    };

    return (
        <div className={`shape-input`}>
            {letters.map((letter, index) => {
                const isLast = index === letters.length - 1;
                return (
                    <Fragment key={index}>
                        <input
                            id={`word-scramble`}
                            className={`input canvas-text scramble-input ${
                                isLast ? "last" : ""
                            }`}
                            value={letter}
                            readOnly
                            style={{
                                ...style,
                                borderColor: style.bColor,
                                display: genDisplay(showAnswerKey),
                                textAlign: "center",
                            }}
                        />
                        <input
                            id={`word-scramble-${wordIndex}-${index}`}
                            className={`input canvas-text user-scramble-input ${
                                isLast ? "last" : ""
                            }`}
                            value={value[index]}
                            onChange={() => {}}
                            onKeyDown={(e) => {
                                handleKeyDown(e, index);
                            }}
                            style={{
                                ...style,
                                borderColor: style.bColor,
                                display: genDisplay(!showAnswerKey),
                                textAlign: "center",
                            }}
                        />
                    </Fragment>
                );
            })}
        </div>
    );
};

const Input = ({
    defaultValue,
    strokeStyle,
    showAnswerKey,
    style,
}: {
    defaultValue: string;
    strokeStyle: number;
    showAnswerKey: boolean;
    style: any;
}) => {
    const [value, setValue] = useState("");

    const getStrokeStyle = () => {
        const borderColor = style.bColor;
        switch (strokeStyle) {
            case ConstantsWordScramble.STROKE_STYLE.DASHED:
                return {
                    borderBottomStyle: "dashed",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.SOLID:
                return {
                    borderBottomStyle: "solid",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.DOTTED:
                return {
                    borderBottomStyle: "dotted",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.HANDWRITING:
                return {
                    borderTop: `2px solid ${borderColor}`,
                    borderBottom: `2px solid ${borderColor}`,
                    "--after-display": "unset",
                };
            default:
                return {};
        }
    };

    return (
        <div
            className="input-container"
            style={{
                "--after-display": "none",
                ...(getStrokeStyle() as any),
            }}
        >
            <input
                className="input canvas-text scramble-input"
                readOnly
                value={defaultValue}
                style={{ ...style, display: genDisplay(showAnswerKey) }}
            />
            <input
                className="input canvas-text user-scramble-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{ ...style, display: genDisplay(!showAnswerKey) }}
            />
        </div>
    );
};

export default ResourceWordScramble;
