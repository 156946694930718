import { generateIdFromDateTime } from ".";
import { scrambleWord } from "../components/ModulesContent/word-scramble/logic";
import { IActivity } from "../shared/models/WorkSheetCreator";
import {
    QuestionActivity,
    ScrambleAttribute,
} from "../shared/models/questionActivity";
import {
    IResourceItemNew,
    ImageAttribute,
    ResourceItemNew,
} from "../shared/models/resourceItemNew";
import ConstantsTool from "../shared/utils/ConstantsTool";
import ConstantsWordScramble from "../shared/utils/ConstantsWordScramble";
import Config from "../shared/utils/config";
import { getTextWidthHeightTool } from "./draw";

interface IWord {
    origin: string;
    shuffleWord: string;
}

const textAttribute = {
    fontFamily: "Nunito",
    fontSize: 26,
};

const imageAttribute = new ImageAttribute({
    changeColor: {
        color: "#212121",
    },
});

const getMaxWidthPrefix = (words: IWord[], listStyle: number) => {
    let result: string | number = "auto";

    if (listStyle === ConstantsWordScramble.LIST_STYLE.NUMBER) {
        const { width } = getTextWidthHeightTool({
            ...textAttribute,
            textValue: words.length + ".",
        });

        result = width;
    } else if (listStyle === ConstantsWordScramble.LIST_STYLE.NONE) {
        result = 0;
    }

    return result;
};

const getMaxWidthHeight = (words: IWord[]) => {
    const result = words.reduce(
        (prev, word) => {
            const { width, height } = getTextWidthHeightTool({
                ...textAttribute,
                textValue: word.shuffleWord,
            });

            if (width > prev.width) {
                prev.width = width;
            }
            if (height > prev.height) {
                prev.height = height;
            }

            return prev;
        },
        { width: 0, height: 0 }
    );
    result.height += 4;
    return result;
};

const getPrefixContent = (index: number, listStyle: number) => {
    let result = "";
    switch (listStyle) {
        case ConstantsWordScramble.LIST_STYLE.DASHED:
            result = "-";
            break;
        case ConstantsWordScramble.LIST_STYLE.DOTTED:
            result = "•";
            break;
        case ConstantsWordScramble.LIST_STYLE.NUMBER:
            result = `${index + 1}.`;
            break;
        default:
            break;
    }

    return result;
};

const getContent = ({
    gridTemplateColumn,
    prefix,
    origin,
    shuffle,
    strokeStyle,
    index,
    separatorStyle,
    line,
    widthInputSentences,
    heightAnswer,
}: {
    gridTemplateColumn?: string;
    prefix: string;
    origin: string;
    shuffle: string;
    strokeStyle: number;
    index: number;
    separatorStyle?: number;
    line?: number;
    widthInputSentences?: number[];
    heightAnswer?: number;
}) => {
    const result = {
        gridTemplateColumn,
        prefix,
        origin,
        shuffle,
        strokeStyle,
        index,
        separatorStyle,
        line,
        widthInputSentences,
        heightAnswer,
    };
    return JSON.stringify(result);
};

const convertWordByLetterStyle = (words: IWord[], letterStyle: number) => {
    return words.map(({ origin, shuffleWord }) => {
        let _origin = origin;
        let _shuffleWord = shuffleWord;

        if (letterStyle === ConstantsWordScramble.LETTER_STYLE.LOWERCASE) {
            _origin = _origin.toLowerCase();
            _shuffleWord = _shuffleWord.toLowerCase();
        } else if (
            letterStyle === ConstantsWordScramble.LETTER_STYLE.UPPERCASE
        ) {
            _origin = _origin.toUpperCase();
            _shuffleWord = _shuffleWord.toUpperCase();
        }

        return {
            origin: _origin,
            shuffleWord: _shuffleWord,
        };
    });
};

const createWordBankItem = (words: IWord[], activity: IActivity) => {
    textAttribute.fontSize = 20;
    const { width: widthLongestText } = getMaxWidthHeight(words);

    const content = words
        .map((w) => `<div class='canvas-text'>${w.origin}</div>`)
        .join("");
    const htmlContent = `<div style="width: ${
        ConstantsTool.BORDER_WIDTH - 26 * 2
    }px;  word-break: unset; display: grid; column-gap: 6px; grid-template-columns: repeat(auto-fill, minmax(${widthLongestText}px, 1fr));">${content}</div>`;

    const { height } = getTextWidthHeightTool({
        ...textAttribute,
        textValue: htmlContent,
        maxWidth: ConstantsTool.BORDER_WIDTH - 26 * 2,
    });

    const id = generateIdFromDateTime();
    const resource = new ResourceItemNew({
        id,
        idType: id,
        activityId: activity.id,
        type: ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE,
        y: ConstantsWordScramble.START_Y_FIRST_PAGE,
        x: ConstantsTool.BORDER_X + 15,
        height: height + 12 * 2 - 2,
        width: ConstantsTool.BORDER_WIDTH - 15 * 2,
        pageIndex: 0,
        textAttribute: {
            ...textAttribute,
            content: htmlContent,
            align: "left",
        },
        imageAttribute,
    });

    return {
        resource,
        pageIndex: 0,
        y: ConstantsWordScramble.START_Y_FIRST_PAGE + resource.height + 20,
    };
};

const generateWordScramble = (_words: IWord[], activity: IActivity) => {
    const { listStyle, strokeStyle, letterStyle, showWordBank } = activity;
    textAttribute.fontSize = 20;
    const resourceItems: IResourceItemNew[] = [];
    let pageIndex = 0;
    let y = ConstantsWordScramble.START_Y_FIRST_PAGE;

    if (showWordBank === Config.SHOW_VALUE) {
        const {
            resource,
            pageIndex: _pageIndex,
            y: _y,
        } = createWordBankItem(_words, activity);
        resourceItems.push(resource);
        pageIndex = _pageIndex;
        y = _y;
    }

    const words = convertWordByLetterStyle(_words, letterStyle);
    const { width, height } = getMaxWidthHeight(words);
    let maxWidthPrefix = getMaxWidthPrefix(words, listStyle);
    const gridTemplateColumn = `${
        typeof maxWidthPrefix === "string" ? "auto" : maxWidthPrefix + "px"
    } ${width + 10}px 1fr`;

    words.forEach(({ origin, shuffleWord }, index) => {
        const id = generateIdFromDateTime();
        const idType = ConstantsWordScramble.WORD_SCRAMBLE_PREFIX + id;
        const prefix = getPrefixContent(index, listStyle);

        if (
            y + height >=
            ConstantsTool.BORDER_Y + ConstantsTool.BORDER_HEIGHT
        ) {
            y = ConstantsWordScramble.START_Y_OTHER_PAGE;
            pageIndex += 1;
        }

        resourceItems.push(
            new ResourceItemNew({
                id,
                idType,
                activityId: activity.id,
                type: ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE,
                y,
                x: ConstantsTool.BORDER_X + 5,
                height,
                width: ConstantsTool.BORDER_WIDTH - 5 * 2,
                pageIndex,
                textAttribute: {
                    ...textAttribute,
                    content: getContent({
                        gridTemplateColumn,
                        prefix,
                        origin,
                        shuffle: shuffleWord,
                        strokeStyle,
                        index,
                    }),
                },
                imageAttribute,
            })
        );

        y += height + 14;
    });

    return {
        resourceItems,
        pageIndex,
        y,
    };
};

const generateSentenceScramble = (_words: IWord[], activity: IActivity) => {
    const { listStyle, strokeStyle, letterStyle, separatorStyle } = activity;
    const resourceItems: IResourceItemNew[] = [];
    let pageIndex = 0;
    let y = ConstantsWordScramble.START_Y_FIRST_PAGE;

    const width = ConstantsTool.BORDER_WIDTH - 15 * 2; // PADDING

    const words = convertWordByLetterStyle(_words, letterStyle);

    const { height: textHeight } = getTextWidthHeightTool({
        ...textAttribute,
        textValue: "AAA",
    });

    const getAnswerHtmlContent = (shuffleWord: string, prefix: string) => {
        const renderContentShuffler = () => {
            const words = shuffleWord
                .split(" ")
                .filter((w) => w.trim().length > 0);
            const dashed =
                separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.DASHED;
            const slash =
                separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.SLASH;
            const shape =
                separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.SHAPE;

            let shapeStyle = shape
                ? "padding: 0px 8px; border: 2px solid transparent;"
                : "";

            return words
                .map((w, i) => {
                    let result = `<div style="${shapeStyle}">${w}</div>`;

                    if (i !== words.length - 1) {
                        result += `<span>${dashed ? "-" : ""}${
                            slash ? "/" : ""
                        }</span>`;
                    }

                    return result;
                })
                .join("");
        };
        return `<div style="width: ${width}px; display: flex;"><div style="margin-right: 4px">${prefix}</div><div style="display: flex; flex-wrap: wrap; gap: 8px;">${renderContentShuffler()}</div></div>`;
    };

    const getNumLines = (height: number) => {
        let line = 1;
        let currentHeight = textHeight + 4;

        while (currentHeight <= height) {
            line++;
            currentHeight += textHeight;

            if (
                separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.SHAPE
            ) {
                currentHeight += 10;
            } else {
                currentHeight += 4;
            }
        }

        return line;
    };

    words.forEach(({ origin, shuffleWord }, index) => {
        const id = generateIdFromDateTime();
        const idType = ConstantsWordScramble.WORD_SCRAMBLE_PREFIX + id;
        const prefix = getPrefixContent(index, listStyle);

        const { height } = getTextWidthHeightTool({
            ...textAttribute,
            textValue: getAnswerHtmlContent(shuffleWord, prefix),
            maxWidth: width,
        });
        const _height = height * 2 + ConstantsWordScramble.GAP_SENTENCE;
        const numLine = height >= textHeight * 2 ? getNumLines(height) : 1;

        if (
            y + _height >=
            ConstantsTool.BORDER_Y + ConstantsTool.BORDER_HEIGHT
        ) {
            y = ConstantsWordScramble.START_Y_OTHER_PAGE;
            pageIndex += 1;
        }

        resourceItems.push(
            new ResourceItemNew({
                id,
                idType,
                activityId: activity.id,
                type: ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE,
                y,
                x: ConstantsTool.BORDER_X + 15,
                height: _height,
                width,
                pageIndex,
                textAttribute: {
                    ...textAttribute,
                    content: getContent({
                        prefix,
                        origin,
                        shuffle: shuffleWord,
                        strokeStyle,
                        index,
                        separatorStyle,
                        line: numLine,
                    }),
                },
                imageAttribute,
            })
        );

        y += _height + 20;
    });

    return {
        resourceItems,
        pageIndex,
        y,
    };
};

const generateResourceItems = (_words: IWord[], activity: IActivity) => {
    const isSentenceScramble =
        activity.type === Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE;

    if (isSentenceScramble) {
        return generateSentenceScramble(_words, activity);
    }

    return generateWordScramble(_words, activity);
};

const getOldResourceItemsConfig = (
    oldResources: IResourceItemNew[],
    newResource: IResourceItemNew[]
) => {
    return newResource.map((resource) => {
        if (resource.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE) {
            const item = oldResources.find(
                (r) => r.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
            );
            if (item) {
                return {
                    ...resource,
                    textAttribute: {
                        ...item.textAttribute,
                        content: resource.textAttribute.content,
                    },
                    imageAttribute: item.imageAttribute,
                };
            }
            return resource;
        }
        const newContent = JSON.parse(resource.textAttribute.content);

        const foundItemsIndex = oldResources.findIndex((r) => {
            if (r.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE)
                return false;
            const oldContent = JSON.parse(r.textAttribute.content);

            return (
                newContent.origin.toLowerCase() ===
                oldContent.origin.toLowerCase()
            );
        });

        if (foundItemsIndex > -1) {
            const [oldResource] = oldResources.splice(foundItemsIndex, 1);
            return {
                ...oldResource,
                textAttribute: {
                    ...oldResource.textAttribute,
                    content: resource.textAttribute.content,
                },
                height: resource.height,
                y: resource.y,
                x: resource.x,
                pageIndex: resource.pageIndex,
            };
        }

        return resource;
    });
};

export const generateScrambleActivity = ({
    words,
    activity,
    regenerate,
    checkDifferent,
    resources,
}: {
    words: IWord[];
    activity: IActivity;
    regenerate?: boolean;
    checkDifferent?: boolean;
    resources?: IResourceItemNew[];
}) => {
    const isSentenceScramble =
        activity.type === Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE;
    let _words = words ?? [];

    if (checkDifferent) {
        const diff = [];
        const oldWords = activity.questions.map((q) => ({
            origin: q.question,
            shuffleWord: q.scrambleAttribute.shuffleQuestion,
        }));

        _words.forEach((w, i) => {
            const { origin } = w;
            const index = oldWords.findIndex((_w) => _w.origin === origin);

            if (index > -1) {
                const wordFound = oldWords.splice(index, 1)[0];
                w.origin = wordFound.origin;
                w.shuffleWord = wordFound.shuffleWord;
            } else {
                diff.push({
                    origin,
                    index: i,
                });
            }
        });
        const newWords = scrambleWord(
            diff.map((w) => w.origin),
            isSentenceScramble
        ).map((w, index) => ({
            ...w,
            index: diff[index].index,
        }));

        for (let i = 0; i < newWords.length; i++) {
            const { origin, index, shuffleWord } = newWords[i];
            _words[index] = {
                origin,
                shuffleWord,
            };
        }
    } else if (regenerate) {
        _words = scrambleWord(
            words.map((w) => w.origin),
            isSentenceScramble
        );
    }
    let { resourceItems, pageIndex, y } = generateResourceItems(
        _words,
        activity
    );

    resourceItems = resourceItems.filter((r) => {
        if (r.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE)
            return true;

        const { origin } = JSON.parse(r.textAttribute.content);

        return origin.trim().length > 0;
    });

    if (resources) {
        resourceItems = getOldResourceItemsConfig(resources, resourceItems);
    }

    const findResourceItemId = (
        _origin: string,
        _shuffle: string,
        resources: any[]
    ) => {
        const lower = (str: string) => str.toLowerCase();
        const index = resources.findIndex((r) => {
            if (r.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE)
                return false;

            return lower(_origin) === lower(r.origin);
        });

        if (index > -1) {
            const [resource] = resources.splice(index, 1);
            return resource.id;
        }

        return "";
    };

    const tempOldQuestions = activity.questions.map((q) => ({
        id: q.id,
        origin: q.question,
        shuffle: q.scrambleAttribute.shuffleQuestion,
        resourceId: q.scrambleAttribute.resourceItemIds[0],
    }));

    const tempResources = resourceItems.map((r) => {
        if (r.type !== ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE) {
            const { origin, shuffle } = JSON.parse(r.textAttribute.content);

            return {
                origin,
                shuffle,
                id: r.id,
            };
        }

        return r;
    });

    const questions = _words.map(({ origin, shuffleWord }, index) => {
        let id = generateIdFromDateTime();
        const indexQuestions = tempOldQuestions.findIndex(
            (q) => q.origin === origin
        );
        if (indexQuestions > -1) {
            const oldQuestion = tempOldQuestions.splice(indexQuestions, 1);
            id = oldQuestion?.[0]?.id;
        }
        return new QuestionActivity({
            id,
            type: activity.type,
            question: origin,
            indexQuestion: index + 1,
            scrambleAttribute: new ScrambleAttribute({
                shuffleQuestion: shuffleWord,
                resourceItemIds: [
                    findResourceItemId(origin, shuffleWord, tempResources),
                ],
            }),
        });
    });

    return {
        questions,
        resourceItems,
        pageIndex,
        y,
    };
};
