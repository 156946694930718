import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { TemplateAnswerSheetLabel } from '.';

const TemplateAnswerSheetsModal = ({
    hideModal,
    templateAnswerSheets,
    currentTemplateAnswerSheet,
    handleChangeTemplateAnswerSheet,
    loading,
}: {
    hideModal: () => void,
    templateAnswerSheets: any[],
    currentTemplateAnswerSheet: object,
    handleChangeTemplateAnswerSheet: (object) => void,
    loading: boolean,
}) => {

    return (
        <Modal show={true} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Template answer sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    className="select-template"
                    classNamePrefix="select"
                    isLoading={loading}
                    isClearable
                    isSearchable
                    name="template"
                    value={currentTemplateAnswerSheet}
                    options={templateAnswerSheets}
                    formatOptionLabel={(option) => (<TemplateAnswerSheetLabel key={option['id']} option={option} imageWidth={70}/>)}
                    onChange={(selectedValue) => handleChangeTemplateAnswerSheet(selectedValue)}
                />
            </Modal.Body>
        </Modal>
    );
};

export default TemplateAnswerSheetsModal;