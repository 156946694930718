import { useState, useSyncExternalStore } from "react";
import {
    AttributesResourceItem,
    IResourceItemNew,
} from "../../../../../../shared/models/resourceItemNew";
import "./index.scss";
import groupElementStore from "../../../../../../syncExternalStore/groupElementStore";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    removeResourceItems,
    updateResourceItems,
} from "../../../../../../redux/reducers/createWorksheet";
import { convertImagesToBase64 } from "../../../../../../resource-user/utils/convertImg";
import { getDataFromAPI, updateDataAPI } from "../../../../../../utils/query";
import { requestGetResourceByType } from "../../../../../../redux/async/leftData";
import Config from "../../../../../../shared/utils/config";
import ConstantsTool from "../../../../../../shared/utils/ConstantsTool";
import GroupModal from "./group-modal";

export const groupElements = (params: {
    currentElement: IResourceItemNew;
    dispatch: any;
}) => {
    let { currentElement, dispatch } = params;
    let result: { itemsChange: AttributesResourceItem[]; idsRemove: string[] };
    if (currentElement.id?.includes("tmp-")) {
        result = groupElementStore.getResourceItemsGroup();
    } else {
        groupElementStore.updateSelectedId(null);
        result = groupElementStore.getResourceItemsGroup({
            isleave: true,
            resourceItem: currentElement.isGroup ? null : currentElement,
        });
    }

    dispatch(
        updateResourceItems({
            pageIndex: currentElement.pageIndex,
            attributesResourceItems: result.itemsChange,
            isChangeEidtPage: true,
        })
    );

    dispatch(
        removeResourceItems({
            resourceIds: result.idsRemove,
            pageIndex: currentElement.pageIndex,
            isAddHistory: false,
        })
    );
};

const createNewComponent = async(dispatch, name: any, groupContent: any, childrenContent: any, userId) => {
    const res = await getDataFromAPI('auto-content/check-resource-name-exist/?name=' + name + `&type=${Config.RESOURCE_COMPONENTS}`);
    const nameExisted = res.data;
    if (!nameExisted) {
        try {
            const imgData = await convertImagesToBase64('group-item-' + groupContent.id);
            await updateDataAPI('POST', 'auto-content/components/', {
                name,
                groupContent,
                childrenContent,
                url: imgData[0]
            });
        } catch (err) {
            console.log(err);
        }
        dispatch(
            requestGetResourceByType({
                typeResource: [Config.RESOURCE_COMPONENTS],
                limit: ConstantsTool.LIMIT_RESOURCE,
                userId: userId,
                offset: 1,
            })
        );
        window.alert('New component created');
    } else {
        window.alert('Component Name exists, please choose another name.');
    }
}

function GroupActions({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) {
    const [showGroupModal, setShowGroupModal] = useState(false);

    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const dispatch = useAppDispatch();
    const resourceItems = useAppSelector(
        (state) =>
            state.createWorksheetState.pagesWorksheet[currentElement.pageIndex]
                .resourceItems
    );
    const userId = useAppSelector(
        (state) => state.userInfoResourceState.data.id
    );

    return (
        <>
            {!currentElement.id?.includes("tmp-") && (
                <div className="group-action" onClick={() => setShowGroupModal(true)}>
                    {currentElement.fieldName ?? 'Set Group Name'}
                </div>
            )}
            <div className="group-action"
                onClick={() => {
                    let item = resourceItems.find(
                        (resource) =>
                            resource.id === groupElementState.resourceGroupTmp.id
                    );
                    groupElements({
                        currentElement: currentElement,
                        dispatch: dispatch,
                    });

                    setCurrentElement({
                        ...item,
                        id: groupElementState.resourceGroupTmp.id,
                    });
                }}
            >
                {currentElement.id?.includes("tmp-") ? "Group" : "Ungroup"}
            </div>
            {!currentElement.id?.includes("tmp-") && (
                <div className="group-action"
                    onClick={() => {
                        let item = resourceItems.find(resource => resource.id === groupElementState.resourceGroupTmp.id);
                        const childrenItems = resourceItems.filter(resource => item.resourceIds.indexOf(resource.id) > -1);
                        let componentName = window.prompt('Component Name', '');
                        if (componentName != null) {
                            createNewComponent(dispatch, componentName, item, childrenItems, userId);
                        }
                    }}
                >
                    Save as component
                </div>
            )}

            {showGroupModal && (
                <GroupModal
                    currentElement={currentElement}
                    setCurrentElement={setCurrentElement}
                    groupItem={resourceItems.find(resource => resource.id === groupElementState.resourceGroupTmp.id)}
                    hideModal={() => setShowGroupModal(false)}
                />
            )}
        </>
    );
}

export default GroupActions;
