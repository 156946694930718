import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CellTable, ITableAttribute, TableAttribute } from '../../../../../../shared/models/tableAttribute';

const CustomizeTableModal = ({
    hideModal = () => { },
    tableAttribute,
    currentTableWidth,
    currentTableHeight,
    handleCurrentElementChange
}: {
    hideModal: () => void,
    tableAttribute: ITableAttribute,
    currentTableWidth: number;
    currentTableHeight: number;
    handleCurrentElementChange: (object: {}) => void;
}) => {
    const [nbRows, setRows] = useState<number>(1);
    const [nbColumns, setColumns] = useState<number>(1);
    const [width, setWidth] = useState<number>(currentTableWidth);
    const [height, setHeight] = useState<number>(currentTableHeight);

    useEffect(() => {
        const initTableData = () => {
            const data = tableAttribute.data;
            setRows(data.length);
            setColumns(data[0].length);
        };

        initTableData();
    }, [tableAttribute]);

    const handleSaveConfigTable = () => {
        if (nbRows === 0 || nbColumns === 0 || width === 0 || height === 0) return;

        const initialCellTableData = tableAttribute.data[0][0];
        const adjustedCellHeight = height / nbRows;
        const adjustedCellWidth = width / nbColumns;

        let updatedTableAttributes: ITableAttribute = new TableAttribute(tableAttribute);

        const updatedData: CellTable[][] = [];

        for (let i = 0; i < nbRows; i++) {
            const rowData: CellTable[] = [];
            for (let j = 0; j < nbColumns; j++) {
                rowData.push(new CellTable(initialCellTableData));
            }
            updatedData.push(rowData);
        }

        updatedTableAttributes.data = updatedData.map((row) =>
            row.map((cell) => ({
                ...cell,
                height: adjustedCellHeight,
                originHeight: adjustedCellHeight,
                width: adjustedCellWidth,
            }))
        );

        handleCurrentElementChange({
            tableAttribute: updatedTableAttributes,
            height,
            width,
        });

        hideModal();
    };

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Table Setting"
            className="table-setting"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Table Setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="nbColumns" className="mb-3">
                        <Form.Label>Number of Columns</Form.Label>
                        <Form.Control type="number" value={nbColumns} min={1} step={1} onChange={(e) => setColumns(parseInt(e.target.value))} />
                    </Form.Group>
                    <Form.Group controlId="nbRows" className="mb-3">
                        <Form.Label>Number of Rows</Form.Label>
                        <Form.Control type="number" value={nbRows} min={1} step={1} onChange={(e) => setRows(parseInt(e.target.value))} />
                    </Form.Group>
                    <Form.Group controlId="width" className="mb-3">
                        <Form.Label>Width</Form.Label>
                        <Form.Control type="number" value={Math.floor(width)} min={1} step={10} onChange={(e) => setWidth(parseInt(e.target.value))} />
                    </Form.Group>
                    <Form.Group controlId="height">
                        <Form.Label>Height</Form.Label>
                        <Form.Control type="number" value={Math.floor(height)} min={1} step={10} onChange={(e) => setHeight(parseInt(e.target.value))} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveConfigTable}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomizeTableModal;
