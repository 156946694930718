import React from "react";
import { getPositionBox } from "../../../../../../utils/align";

function BorderGroup({
    ratio,
    startPoint,
    endPoint,
}: {
    ratio: number;
    startPoint: { x: number; y: number };
    endPoint: { x: number; y: number };
}) {
    let { x, y, width, height } = getPositionBox({ startPoint, endPoint });
    return (
        <div
            style={{
                position: "absolute",
                border: "1px solid rgba(139, 61, 255, 1)",
                background: "rgba(139, 61, 255, 0.08)",
                width: width * ratio,
                height: height * ratio,
                top: y * ratio,
                left: x * ratio,
            }}
        ></div>
    );
}

export default BorderGroup;
