import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { Collection, ICollection } from "../../../shared/models/collection";
import { Worksheet } from "../../../shared/models/worksheet";
import {
    isModuleApplyForNextJs,
    uppercaseFirstLetter,
} from "../../../shared/utils";
import Config from "../../../shared/utils/config";
import { scrollToTop } from "../../utils";
import {
    ICollectionAction,
    ResourceTypes,
    addImageWorksheetOfCollectionSuccess,
    deleteWorksheetFromCollectionResourceSuccessAction,
    getNumberWsResourceDefaultSuccessAction,
    getResourceDefaultSuccessAction,
    moveCollectionResourceSuccessAction,
    removeWorksheetOfCollectionAction,
    selectActionGridItemAction,
    selectCollectionItemResourceAction,
    selectGridItemAction,
    setLoadingMoreWsResourceAction,
    updateActionsGaSuccessAction,
    updateAlertResourceAction,
    updateCollectionAction,
    updateListCollectionAction,
    updateNumberWorksheetOfCollectionAction,
} from "../action/resource.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import {
    deleteCollectionByIdResourceAPI,
    getDataCollectionResourceV2API,
    updateItemCollectionByIdAPI,
} from "../repositories/collection.repositories";
import {
    getDownloadedWorksheetByUserIdAPI,
    getListCollectionByUserAPI,
} from "../repositories/user.repositories";
import {
    addImageWorksheetOfCollectionApi,
    addWorksheetToDownloadedAPI,
    deleteWorksheetByStatusApi,
    deleteWorksheetFromDownloadedAPI,
    getNumberWsDefaultCollectionAPI,
    getPracticeWorksheetByUserIdAPI,
    getWorkSheetByUserAPI,
    getWorkSheetDraftByUserIdAPI,
    indexCollectionApi,
    updateWorksheetByIdAPI,
    updateWorksheetCreatedByWordSearchAPI,
} from "../repositories/workSheet.repositories";
// import { createNewAssignmentAPI } from "../../../repositories/assignment";

function* getListCollectionSaga(action: ICollectionAction) {
    try {
        let { userId, offset } = action;

        if (userId) {
            let pageNumber: number = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.collections?.pageNumber
            );
            let collections = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.collections?.data
            );
            // if (isNumeric(offset)) {
            //     pageNumber = offset;
            // }
            pageNumber = 0;

            let limit = Config.LIMIT_DATA.LIMIT_WORKSHEET;
            let result = yield call(getListCollectionByUserAPI, {
                userId,
                limit,
                offset: pageNumber * limit,
                parentIds: [],
                projectData: action?.projectData ?? {},
            });
            if (result) {
                // collections = [
                //     ...(collections ?? []),
                //     result.collections ?? [],
                // ];
                collections = collections ?? [];
                if (pageNumber == 0) {
                    collections = result?.collections;
                } else if (result?.collections?.length) {
                    collections = collections.concat(result?.collections);
                }

                let numberCollections = result.nbCollections ?? 0;
                yield put(
                    updateListCollectionAction({
                        collections,
                        numberCollections,
                        pageNumber: pageNumber + 1,
                    })
                );
            }
        }
    } catch (error) {
        console.log("getListCollectionSaga", error);
    }
}

function* getDefaultCollectionSaga(action: ICollectionAction) {
    try {
        let { userId, profileId } = action;

        if (profileId) {
            let subCollections = [];

            subCollections.push(
                new Collection({
                    id: Config.COLLECTION_WORKSHEETS_CREATED
                        .WORKSHEETS_CREATED_COLLECTIONid,
                    name: uppercaseFirstLetter(
                        Config.COLLECTION_WORKSHEETS_CREATED
                            .WORKSHEETS_CREATED_COLLECTION_NAME
                    ),
                    userId: profileId,
                    typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
                })
            );
            if (profileId === userId) {
                let draftCollection = new Collection({
                    id: Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid,
                    name: uppercaseFirstLetter(
                        Config.COLLECTION_DRAFT.DRAFT_COLLECTION_NAME
                    ),
                    userId: profileId,
                    typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
                });
                if (draftCollection?.id) {
                    subCollections.push(draftCollection);
                }
                subCollections.push(
                    new Collection({
                        id: Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid,
                        name: uppercaseFirstLetter(
                            Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTION_NAME
                        ),
                        userId: profileId,
                        typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
                    }),

                    new Collection({
                        id: Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid,
                        name: uppercaseFirstLetter(
                            Config.COLLECTION_PRACTICE.PRACTICE_COLLECTION_NAME
                        ),
                        userId: profileId,
                        typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
                    })
                );
            }

            yield put(getResourceDefaultSuccessAction(subCollections));
            const isReactApp = isModuleApplyForNextJs();

            if (isReactApp) {
                let data = yield call(getNumberWsDefaultCollectionAPI, {
                    userId: userId,
                });

                if (subCollections.length > 0) {
                    let newData = subCollections.map(
                        (collection: ICollection) => {
                            let collectionId = collection.id;

                            let number = data.find(
                                (item: any) => item.type === collectionId
                            );

                            let c = new Collection({
                                ...collection,
                                numberOfWorksheets:
                                    number?.numberWorksheets ?? 0,
                            });
                            return c;
                        }
                    );

                    // setCollections(newData);
                    yield put(getNumberWsResourceDefaultSuccessAction(newData));
                }
            }
        }
    } catch (error) {
        console.log("getDefaultCollectionSaga", error);
    }
}
function* selectCollectionSaga(action: ICollectionAction) {
    try {
        let { collection, notFetchData } = action;
        let limitWorksheets = Config.LIMIT_DATA.LIMIT_WORKSHEET;
        collection = JSON.parse(JSON.stringify(collection));
        if (collection.userId && collection?.id) {
            let pageNumber = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.collectionSelected.pageNumber
            );
            let defaultCollections = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.defaultCollections
            );
            let worksheetItems = collection?.worksheetItems;
            let worksheets = [];
            let finishLoadMore = false;
            let currentCollection = defaultCollections?.find(
                (c) => c?.id == collection?.id
            );
            if (!!currentCollection?.id) {
                collection = currentCollection;
            }
            if (
                collection?.id !=
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid &&
                !notFetchData
            ) {
                switch (collection?.id) {
                    case Config.COLLECTION_WORKSHEETS_CREATED
                        .WORKSHEETS_CREATED_COLLECTIONid:
                        let resultWorksheetCreated = yield call(
                            getWorkSheetByUserAPI,
                            {
                                id: collection.userId,
                                worksheetType: Config.WORKSHEET_TYPE_ALL,
                                offset: 0,
                                limit: limitWorksheets,
                            }
                        );
                        if (resultWorksheetCreated) {
                            worksheets = resultWorksheetCreated.worksheets;
                        }
                        break;

                    case Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid:
                        worksheets = yield call(
                            getDownloadedWorksheetByUserIdAPI,
                            collection.userId,
                            0,
                            limitWorksheets,
                            Config.WORKSHEET_TYPE_ALL
                        );

                        worksheets = worksheets.sort((a, b) => {
                            if (a.id < b.id) {
                                return -1;
                            }
                            if (a.id > b.id) {
                                return 1;
                            }
                            return 0;
                        });

                        break;

                    case Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid:
                        worksheets = yield call(
                            getWorkSheetDraftByUserIdAPI,
                            collection.userId
                        );
                        break;
                    case Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid:
                        let resultWorksheetPractice = yield call(
                            getPracticeWorksheetByUserIdAPI,
                            {
                                userId: collection.userId,
                            }
                        );
                        if (resultWorksheetPractice) {
                            worksheets = resultWorksheetPractice.worksheets;
                        }
                        break;

                    default:
                        let collectionId = collection?.id;

                        let resultWorksheetOfCollection = yield call(
                            getDataCollectionResourceV2API,
                            {
                                userId: collection.userId,
                                collectionId: collectionId,
                            }
                        );

                        let resultCollections =
                            resultWorksheetOfCollection?.dataCollection;
                        let subCollections = resultCollections.filter(
                            (c) => c?.id !== collectionId
                        );

                        subCollections = subCollections
                            .map((item) => {
                                let lastParentId = JSON.parse(
                                    JSON.stringify(item.parentIds)
                                ).slice(-1)[0];
                                if (lastParentId === collectionId) {
                                    let sSubCollections = subCollections.filter(
                                        (c) => c.parentIds.includes(item.id)
                                    );
                                    let sumWs = 0;
                                    sSubCollections?.forEach((element) => {
                                        sumWs += element?.worksheets?.length;
                                    });
                                    item.numberOfWorksheets += sumWs;
                                    item.typeGrid =
                                        Config.TYPE_GRID.TYPE_FOLDER_COLLECTION;
                                    return item;
                                }
                                return null;
                            })
                            .filter((item) => !!item);

                        let currentCollection = resultCollections?.find(
                            (c) => c.id === collectionId
                        );

                        worksheets = currentCollection?.worksheets ?? [];
                        worksheetItems =
                            currentCollection?.worksheetItems ?? [];
                        if (subCollections?.length) {
                            worksheets = [...worksheets, ...subCollections];
                        }
                        collection.parentCollectionName =
                            currentCollection?.parentCollectionName ??
                            collection?.parentCollectionName ??
                            [];
                        collection.parentIds =
                            currentCollection?.parentIds ??
                            collection?.parentIds ??
                            [];
                        break;
                }
            }
            let newCollection = {
                ...collection,
                worksheets: worksheets,
                typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
                worksheetItems: worksheetItems,
            };
            // collection.worksheets = [...worksheets];

            // yield put(updateCollectionAction({ collection, loading }));
            if (worksheets?.length !== limitWorksheets && !finishLoadMore) {
                finishLoadMore = true;
            }
            let loading = false;
            yield put(
                updateCollectionAction({
                    collection: newCollection,
                    loading,
                    pageNumber: pageNumber,
                    finishLoadMore,
                })
            );
            scrollToTop(true);
        }
    } catch (error) {
        console.log("selectCollectionSaga", error);
        // window.location.href = "/";
    }
}

function* editCollectionResourceSaga(action: ICollectionAction) {
    try {
        let { params } = action;

        if (params) {
            let worksheets = [];
            const collection = yield call(updateItemCollectionByIdAPI, params);
            let loading = false;

            yield put(updateCollectionAction({ collection, loading }));
        }
    } catch (error) {
        console.log("editCollectionResourceSaga", error);
    }
}

function* selectCollectionItemResourceSaga(action: ICollectionAction) {
    try {
        let { params } = action;

        if (params) {
            const collection = yield call(updateItemCollectionByIdAPI, params);
            let loading = false;

            // yield put(updateCollectionAction({ collection, loading }));
            // yield put(
            //     updateCollectionItemResourceAction({ collection, loading })
            // );
        }
    } catch (error) {
        console.log("selectCollectionItemResourceSaga", error);
    }
}

function* getDataCollectionItemResourceSaga(action: ICollectionAction) {
    try {
        let {
            userId,
            collection,
            arrayCollection,
            arrayThumbnail,
            isNextLevelCollection,
            listCollection,
        } = action;
        let collectionId = collection?.id;
        if (userId && collectionId) {
            const args = { userId, collectionId };
            let collectionsState = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.collections?.data
            );
            const toCollection = yield select(
                (state: ResourceAppState) =>
                    state.resourceState.actionGridItem?.toCollection
            );
            let numberSubCollections = toCollection?.numberSubCollections ?? 0;
            const result = yield call(getDataCollectionResourceV2API, args);
            const collections = result?.dataCollection;
            const findCollectionInArr = arrayCollection?.find(
                (c) => c?.id == collection?.id
            );
            if (!findCollectionInArr) {
                numberSubCollections = result?.numberSubCollections;
            } else if (numberSubCollections == 0) {
                numberSubCollections = arrayCollection?.length;
            }
            let loading = false;

            if (collections?.length) {
                let newCollection = collections?.find(
                    (c) => c?.id == collectionId
                );

                let subCollections = collections?.filter(
                    (c) => c?.id != collectionId
                );
                let arrayThumbnailResult = newCollection?.worksheets;

                newCollection.worksheets = [
                    ...newCollection?.worksheets,
                    ...subCollections,
                ];
                const findCollectionParentFc = (collections) => {
                    return collections?.map((c) => {
                        if (c?.id == collectionId) {
                            c.worksheets = [...newCollection?.worksheets];
                            return c;
                        } else {
                            findCollectionParentFc(c?.worksheets);
                        }
                        return c;
                    });
                };
                collectionsState = findCollectionParentFc(collectionsState);

                yield put(
                    selectCollectionItemResourceAction({
                        collection: newCollection,
                        collectionsState,
                        arrayCollection: arrayCollection ?? subCollections,
                        arrayThumbnail: arrayThumbnail ?? arrayThumbnailResult,
                        numberSubCollections,
                        isSelectCollection: newCollection,
                        isNextLevelCollection: isNextLevelCollection,
                        listCollection,
                    })
                );
            }
        }
    } catch (error) {
        console.log("getDataCollectionItemResourceSaga", error);
    }
}

function* moveCollectionItemResourceSaga(action: ICollectionAction) {
    try {
        const collection = action.collection;

        let parentIds = [];
        let oldParentId = collection?.id;

        if (collection?.parentIds?.length > 0) {
            oldParentId = collection?.parentIds[0];
        }

        if (action?.toCollection?.id) {
            const parentCollectionSelectedIds =
                action.toCollection.parentIds ?? [];
            parentIds = [
                ...parentCollectionSelectedIds,
                action.toCollection.id,
            ];
        }

        let params: any = {};
        params = {
            collectionId: collection?.id,
            parentIds,
        };

        const result: ICollection = yield call(
            updateItemCollectionByIdAPI,
            params
        );

        if (result.id) {
            result.numberOfWorksheets = collection.numberOfWorksheets ?? 0;
            yield put(moveCollectionResourceSuccessAction(result, oldParentId));
            yield put(
                updateAlertResourceAction(
                    true,
                    "Move success",
                    Config.SUCCESS,
                    Config.TYPE_ALERT.ALERT_ACTION_WEB
                )
            );
        }
    } catch (error) {
        //
        console.log("moveCollectionItemResourceSaga", error);
    }
}

function* loadMoreWorksheetResourceSaga(action: ICollectionAction) {
    try {
        let limitWorksheets = Config.LIMIT_DATA.LIMIT_WORKSHEET;
        // let { collection } = action;
        let collection = yield select(
            (state: ResourceAppState) =>
                state.resourceState.collectionSelected.data
        );
        let pageNumber = yield select(
            (state: ResourceAppState) =>
                state.resourceState.collectionSelected.pageNumber
        );
        let loading = yield select(
            (state: ResourceAppState) =>
                state.resourceState.collectionSelected.loading
        );
        let defaultCollections = yield select(
            (state: ResourceAppState) => state.resourceState.defaultCollections
        );
        if (loading) {
            return;
        } else {
            loading = true;
            yield put(setLoadingMoreWsResourceAction(loading));
            pageNumber += 1;

            if (collection?.id) {
                let worksheets = [];
                let finishLoadMore = false;
                let currentCollection = defaultCollections?.find(
                    (c) => c?.id == collection?.id
                );
                if (!!currentCollection?.id) {
                    collection.numberOfWorksheets =
                        currentCollection.numberOfWorksheets;
                }
                let numberOfWs = collection?.numberOfWorksheets;
                switch (collection?.id) {
                    case Config.COLLECTION_WORKSHEETS_CREATED
                        .WORKSHEETS_CREATED_COLLECTIONid:
                        let resultWorksheetCreated = yield call(
                            getWorkSheetByUserAPI,
                            {
                                id: collection.userId,
                                worksheetType: Config.WORKSHEET_TYPE_ALL,
                                limit: limitWorksheets,
                                offset: pageNumber,
                            }
                        );
                        if (resultWorksheetCreated) {
                            worksheets = resultWorksheetCreated.worksheets;
                        }
                        break;

                    case Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid:
                        worksheets = yield call(
                            getDownloadedWorksheetByUserIdAPI,
                            collection.userId,
                            pageNumber,
                            limitWorksheets,
                            Config.WORKSHEET_TYPE_ALL
                        );
                        worksheets = worksheets.sort((a, b) => {
                            if (a.id < b.id) {
                                return -1;
                            }
                            if (a.id > b.id) {
                                return 1;
                            }
                            return 0;
                        });
                        break;

                    case Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid:
                        worksheets = yield call(
                            getWorkSheetDraftByUserIdAPI,
                            collection.userId
                        );
                        break;
                    case Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid:
                        let resultWorksheetPractice = yield call(
                            getPracticeWorksheetByUserIdAPI,
                            {
                                userId: collection.userId,
                                offset: pageNumber * limitWorksheets,
                                limit: limitWorksheets,
                            }
                        );
                        if (
                            resultWorksheetPractice &&
                            resultWorksheetPractice?.worksheets?.length
                        ) {
                            worksheets = resultWorksheetPractice.worksheets;
                            if (numberOfWs <= 0) {
                                numberOfWs =
                                    collection?.resultWorksheetPractice
                                        ?.numberOfWorksheets;
                            }
                        } else {
                            finishLoadMore = true;
                        }
                        break;

                    default:
                        let collectionId = collection?.id;

                        let resultWorksheetOfCollection = yield call(
                            getDataCollectionResourceV2API,
                            {
                                userId: collection.userId,
                                collectionId: collectionId,
                            }
                        );
                        let resultCollections =
                            resultWorksheetOfCollection?.dataCollection;
                        let subCollections = resultCollections.filter(
                            (c) => c?.id !== collectionId
                        );

                        subCollections = subCollections
                            .map((item) => {
                                if (
                                    item.parentIds.slice(-1)[0] === collectionId
                                ) {
                                    let sSubCollections = subCollections.filter(
                                        (c) => c.parentIds.includes(item.id)
                                    );
                                    let sumWs = 0;
                                    sSubCollections?.forEach((element) => {
                                        sumWs += element?.worksheets?.length;
                                    });
                                    item.numberOfWorksheets += sumWs;
                                    return item;
                                }
                                return null;
                            })
                            .filter((item) => !!item);

                        let currentCollection = resultCollections?.find(
                            (c) => c.id === collectionId
                        );

                        worksheets = currentCollection?.worksheets ?? [];
                        if (subCollections?.length) {
                            worksheets = [...worksheets, ...subCollections];
                        }
                        break;
                }
                let worksheetCollection: any = JSON.parse(
                    JSON.stringify(collection.worksheets)
                );
                collection = new Collection(collection);
                collection.worksheets = [...worksheetCollection, ...worksheets];
                // [
                //     ...,
                //     ...worksheets,
                // ];
                // console.log(
                //     "worksheets?.length",
                //     worksheets?.length,
                //     limitWorksheets
                // );

                if (worksheets?.length !== limitWorksheets && !finishLoadMore) {
                    finishLoadMore = true;
                }
                let loading = false;
                if (!!currentCollection?.id) {
                    collection.numberOfWorksheets =
                        currentCollection.numberOfWorksheets;
                }
                yield put(
                    updateCollectionAction({
                        collection,
                        loading,
                        pageNumber: pageNumber,
                        finishLoadMore,
                    })
                );
            }
        }
    } catch (error) {
        console.log("loadMoreWorksheetResourceSaga", error);
    }
}
function* removeCollectionResourceSaga(action: ICollectionAction) {
    let { worksheet, collection } = action;
    try {
        let actionGridItem = yield select(
            (state: ResourceAppState) => state.resourceState.actionGridItem
        );
        let collectionSelected = yield select(
            (state: ResourceAppState) =>
                state.resourceState.collectionSelected?.data
        );
        const userInfo = yield select(
            (state: ResourceAppState) => state.userInfoResourceState.data
        );
        const collections = yield select(
            (state: ResourceAppState) => state.resourceState.collections
        );
        if (
            worksheet?.id &&
            actionGridItem?.action == Config.ACTION_GRID.DELETE_WS
        ) {
            if (
                collectionSelected?.id ==
                Config.COLLECTION_WORKSHEETS_CREATED
                    .WORKSHEETS_CREATED_COLLECTIONid
            ) {
                const delWs = yield call(deleteWorksheetByStatusApi, {
                    worksheetId: worksheet?.id,
                });
                if (delWs) {
                    let parentIds = collectionSelected?.parentIds;
                    let parentIdCollection = collectionSelected?.id;
                    let ids = collections?.data?.map((c) => c.id);
                    if (parentIds?.length) {
                        parentIdCollection = parentIds?.find((id) =>
                            ids?.includes(id)
                        );
                    }
                    yield put(
                        removeWorksheetOfCollectionAction({
                            worksheet: worksheet?.id
                                ? worksheet
                                : collection?.id
                                ? collection
                                : null,
                        })
                    );
                    yield put(
                        updateAlertResourceAction(
                            true,
                            "Delete success",
                            Config.ERROR,
                            Config.TYPE_ALERT.ALERT_ACTION_WEB
                        )
                    );
                    yield put(
                        updateNumberWorksheetOfCollectionAction({
                            numberOfWs:
                                actionGridItem?.action ==
                                Config.ACTION_GRID.DELETE_WS
                                    ? -1
                                    : collection?.numberOfWorksheets &&
                                      !isNaN(collection?.numberOfWorksheets)
                                    ? -collection?.numberOfWorksheets
                                    : -collection?.worksheetItems?.length,
                            collectionId: parentIdCollection,
                        })
                    );
                }
                // dispatch(deleteWorksheetByStatusAction(worksheet.id));
            } else {
                if (worksheet?.statusHide === Config.STATUS_DRAFT) {
                    // dispatch(deleteDraftWorksheetAction(worksheet.id));

                    const delWsByStatus = yield call(
                        deleteWorksheetByStatusApi,
                        {
                            worksheetId: worksheet?.id,
                        }
                    );
                    if (delWsByStatus) {
                        let parentIds = collectionSelected?.parentIds;
                        let parentIdCollection = collectionSelected?.id;
                        let ids = collections?.data?.map((c) => c.id);
                        if (parentIds?.length) {
                            parentIdCollection = parentIds?.find((id) =>
                                ids?.includes(id)
                            );
                        }
                        yield put(
                            removeWorksheetOfCollectionAction({
                                worksheet: worksheet?.id
                                    ? worksheet
                                    : collection?.id
                                    ? collection
                                    : null,
                            })
                        );
                        yield put(
                            updateAlertResourceAction(
                                true,
                                "Delete success",
                                Config.ERROR,
                                Config.TYPE_ALERT.ALERT_ACTION_WEB
                            )
                        );
                        yield put(
                            updateNumberWorksheetOfCollectionAction({
                                numberOfWs:
                                    actionGridItem?.action ==
                                    Config.ACTION_GRID.DELETE_WS
                                        ? -1
                                        : collection?.numberOfWorksheets &&
                                          !isNaN(collection?.numberOfWorksheets)
                                        ? -collection?.numberOfWorksheets
                                        : -collection?.worksheetItems?.length,
                                collectionId: parentIdCollection,
                            })
                        );
                    }
                } else {
                    let params: any = {};

                    params.worksheetItems =
                        collectionSelected?.worksheetItems.filter(
                            (w) => w.worksheetId != worksheet.id
                        );
                    params.collectionId = collectionSelected.id;
                    if (params.collectionId) {
                        const updateWs = yield call(
                            updateItemCollectionByIdAPI,
                            params
                        );
                        if (updateWs) {
                            yield call(indexCollectionApi, updateWs);
                            let parentIds = collectionSelected?.parentIds;
                            let parentIdCollection = collectionSelected?.id;
                            let ids = collections?.data?.map((c) => c.id);
                            if (parentIds?.length) {
                                parentIdCollection = parentIds?.find((id) =>
                                    ids?.includes(id)
                                );
                            }
                            yield put(
                                removeWorksheetOfCollectionAction({
                                    worksheet: worksheet?.id
                                        ? worksheet
                                        : collection?.id
                                        ? collection
                                        : null,
                                })
                            );
                            yield put(
                                updateAlertResourceAction(
                                    true,
                                    "Delete success",
                                    Config.ERROR,
                                    Config.TYPE_ALERT.ALERT_ACTION_WEB
                                )
                            );
                            yield put(
                                updateNumberWorksheetOfCollectionAction({
                                    numberOfWs:
                                        actionGridItem?.action ==
                                        Config.ACTION_GRID.DELETE_WS
                                            ? -1
                                            : collection?.numberOfWorksheets &&
                                              !isNaN(
                                                  collection?.numberOfWorksheets
                                              )
                                            ? -collection?.numberOfWorksheets
                                            : -collection?.worksheetItems
                                                  ?.length,
                                    collectionId: parentIdCollection,
                                })
                            );
                        }
                    }
                    if (worksheet?.authorId == userInfo.id) {
                        params.worksheetId = worksheet.id;
                        params.statusHide = Config.STATUS_PRIVATE_WORKSHEET;

                        updateWorksheetByIdAPI(params);
                    }
                }
            }
        }

        if (
            collection?.id &&
            actionGridItem?.action == Config.ACTION_GRID.DELETE_COLLECTION
        ) {
            const delC = yield call(deleteCollectionByIdResourceAPI, {
                collectionId: collection?.id,
            });

            if (delC) {
                let parentIds = collectionSelected?.parentIds;
                let parentIdCollection = collectionSelected?.id;
                let ids = collections?.data?.map((c) => c.id);
                if (parentIds?.length) {
                    parentIdCollection = parentIds?.find((id) =>
                        ids?.includes(id)
                    );
                }

                yield put(
                    removeWorksheetOfCollectionAction({
                        worksheet: worksheet?.id
                            ? worksheet
                            : collection?.id
                            ? collection
                            : null,
                    })
                );
                yield put(
                    updateAlertResourceAction(
                        true,
                        "Delete success",
                        Config.ERROR,
                        Config.TYPE_ALERT.ALERT_ACTION_WEB
                    )
                );

                yield put(
                    updateNumberWorksheetOfCollectionAction({
                        numberOfWs:
                            actionGridItem?.action ==
                            Config.ACTION_GRID.DELETE_WS
                                ? -1
                                : collection?.numberOfWorksheets &&
                                  !isNaN(collection?.numberOfWorksheets)
                                ? -collection?.numberOfWorksheets
                                : -collection?.worksheetItems?.length,
                        collectionId: parentIdCollection,
                    })
                );
            }
        }

        yield put(
            selectActionGridItemAction({
                action: Config.ACTION_GRID.DEFAULT,
            })
        );
        yield put(
            selectGridItemAction({
                collection: new Collection(),
                worksheet: new Worksheet(),
                toCollection: true,
            })
        );
    } catch (error) {
        console.log("error removeCollectionResourceSaga", error);
    }
}

function* deleteWorksheetFromCollectionResourceSaga(action: ICollectionAction) {
    let { worksheets, collection } = action;
    try {
        if (collection?.id) {
            collection.worksheetItems = collection.worksheetItems.filter(
                (item) => {
                    let ids = worksheets.map((ws) => ws.id);
                    return !ids.includes(item.worksheetId);
                }
            );
            let params = {
                collectionId: collection?.id,
                worksheetItems: collection.worksheetItems,
            };

            const result: ICollection = yield call(
                updateItemCollectionByIdAPI,
                params
            );
            collection.worksheets = collection.worksheets.filter((item) => {
                let ids = worksheets.map((ws) => ws.id);
                return !ids.includes(item.id);
            });
            yield put(
                deleteWorksheetFromCollectionResourceSuccessAction({
                    collection,
                })
            );
        }
    } catch (error) {}
}

function* deleteWorksheetFromCreatedWSResourceSaga(action: ICollectionAction) {
    let { worksheets, collection } = action;
    if (
        collection.id ===
        Config.COLLECTION_WORKSHEETS_CREATED.WORKSHEETS_CREATED_COLLECTIONid
    ) {
        for (const worksheet of worksheets) {
            let newWorksheet = new Worksheet({
                ...worksheet,
                statusHide: Config.STATUS_DELETED,
            });
            yield call(updateWorksheetCreatedByWordSearchAPI, {
                workSheet: newWorksheet,
            });
        }
    }
}

function* addWorksheetToDownloadedSaga(action: ICollectionAction) {
    let { userId, worksheetId } = action;
    yield call(addWorksheetToDownloadedAPI, {
        userId,
        worksheetId,
    });
}

function* deleteWorksheetFromDownloadedSaga(action: ICollectionAction) {
    let { userId, worksheetIds } = action;
    yield call(deleteWorksheetFromDownloadedAPI, {
        userId,
        worksheetIds,
    });
}

function* sendEventGaSaga(action: ICollectionAction) {
    try {
        let actions = yield select(
            (state: ResourceAppState) =>
                state.resourceState?.actionGridItem?.eventsGaAfterSuccess
        );
        if (actions?.length) {
            yield put(updateActionsGaSuccessAction({ eventsGa: [] }));
        }
    } catch (error) {
        console.log("error sendEventGaSaga", error);
    }
}

function* addImageWorksheetOfCollectionSaga(action: ICollectionAction) {
    try {
        const collection = yield call(addImageWorksheetOfCollectionApi, {
            collectionId: action.collectionId,
        });
        if (collection.worksheets.length > 0) {
            yield put(
                addImageWorksheetOfCollectionSuccess({
                    worksheets: collection.worksheets,
                    collectionId: collection.id,
                })
            );
        }
    } catch (error) {
        console.log("error ", error);
    }
}
function* listenListCollectionSaga() {
    yield takeLatest(
        ResourceTypes.GET_LIST_COLLECTION_RESOURCE,
        getListCollectionSaga
    );
}
function* listenDefaultCollectionSaga() {
    yield takeLatest(
        ResourceTypes.GET_DEFAULT_COLLECTION_RESOURCE,
        getDefaultCollectionSaga
    );
}
function* listenSelectCollectionSaga() {
    yield takeLatest(
        ResourceTypes.SELECT_COLLECTION_RESOURCE,
        selectCollectionSaga
    );
}
function* listenEditCollectionResourceSaga() {
    yield takeLatest(
        ResourceTypes.EDIT_COLLECTION_RESOURCE,
        editCollectionResourceSaga
    );
}
// function* listenSelectCollectionItemResourceSaga() {
//     yield takeLatest(
//         ResourceTypes.SELECT_COLLECTION_ITEM_RESOURCE,
//         selectCollectionItemResourceSaga
//     );
// }
function* listenGetDataCollectionItemResourceSaga() {
    yield takeLatest(
        ResourceTypes.GET_DATA_COLLECTION_RESOURCE_V2,
        getDataCollectionItemResourceSaga
    );
}
function* listenMoveCollectionItemResourceSaga() {
    yield takeLatest(
        ResourceTypes.MOVE_COLLECTION_RESOURCE,
        moveCollectionItemResourceSaga
    );
}
function* listenLoadMoreWorksheetResourceSaga() {
    yield takeLatest(
        ResourceTypes.LOAD_MORE_WORKSHEET_RESOURCE,
        loadMoreWorksheetResourceSaga
    );
}

function* listenRemoveCollectionResourceSaga() {
    yield takeLatest(
        ResourceTypes.REMOVE_COLLECTION_RESOURCE,
        removeCollectionResourceSaga
    );
}
function* listenDeleteWorksheetFromCollectionResourceSaga() {
    yield takeLatest(
        ResourceTypes.DELETE_WORKSHEET_FROM_COLLECTION,
        deleteWorksheetFromCollectionResourceSaga
    );
}
function* listenDeleteWorksheetFromCreatedWSResourceSaga() {
    yield takeLatest(
        ResourceTypes.DELETE_WORKSHEET_FROM_CREATED_OR_DOWNLOADED_WORKSHEET,
        deleteWorksheetFromCreatedWSResourceSaga
    );
}
function* listenAddWorksheetToDownloadedSaga() {
    yield takeLatest(
        ResourceTypes.ADD_WORKSHEET_TO_DOWNLOADED,
        addWorksheetToDownloadedSaga
    );
}
function* listendeleteWorksheetToDownloadedSaga() {
    yield takeLatest(
        ResourceTypes.DELETE_WORKSHEET_FROM_DOWNLOADED,
        deleteWorksheetFromDownloadedSaga
    );
}
function* listenSendEventGaSaga() {
    yield takeLatest(ResourceTypes.SEND_EVENT_GA, sendEventGaSaga);
}
function* listenAddImageWorksheetOfCollectionSaga() {
    yield takeLatest(
        ResourceTypes.ADD_IMAGE_WORKSHEET_OF_COLLECTION,
        addImageWorksheetOfCollectionSaga
    );
}
export const resourceSaga = [
    fork(listenListCollectionSaga),
    fork(listenDefaultCollectionSaga),
    fork(listenSelectCollectionSaga),
    fork(listenEditCollectionResourceSaga),
    // fork(listenSelectCollectionItemResourceSaga),
    fork(listenGetDataCollectionItemResourceSaga),
    fork(listenMoveCollectionItemResourceSaga),
    fork(listenLoadMoreWorksheetResourceSaga),
    fork(listenRemoveCollectionResourceSaga),
    fork(listenDeleteWorksheetFromCollectionResourceSaga),
    fork(listenDeleteWorksheetFromCreatedWSResourceSaga),
    fork(listenAddWorksheetToDownloadedSaga),
    fork(listendeleteWorksheetToDownloadedSaga),
    fork(listenSendEventGaSaga),
    fork(listenAddImageWorksheetOfCollectionSaga),
];
