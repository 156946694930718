import { POST } from ".";
import { IUserInfo, UserInfo } from "../../../shared/models/userInfo";
import { APIConfig } from "../../utils/api-config";

export default class UserRepositoriesWeb {
    loginAPI(object: {
        email?: string;
        password?: string;
        uuId?: string;
        id?: string;
    }) {
        return POST({
            url: APIConfig.LOGIN,
            params: object,
        }).then((data: any) => {
            return data;
        });
    }
    loginWithGoogle(object: {
        email: string;
        avatar: string;
        name: string;
        userId: string;
    }) {
        return POST({
            url: APIConfig.LOGIN_WITH_GOOGLE,
            params: object,
        }).then((data: any) => {
            return data;
        });
    }
    loginWithEmail(object: {
        email: string;
        avatar: string;
        name: string;
        userId: string;
    }) {
        return POST({
            url: APIConfig.LOGIN_WITH_EMAIL,
            params: object,
        }).then((data: any) => {
            return data;
        });
    }
    // updateUserInfo(
    //     id: string,
    //     field: string,
    //     value: string
    // ): Promise<IUserInfo> {
    //     return POST({
    //         url: APIConfig.UPDATE_USER_INFO,
    //         params: {
    //             id,
    //             field,
    //             value,
    //         },
    //     }).then((data: any) => data && new UserInfo(data));
    // }

    loginByToken(token: string): Promise<IUserInfo> {
        return POST({
            url: APIConfig.GET_USER_FROM_TOKEN,
            params: {
                token: token,
            },
        })
            .then((data: any) => data && new UserInfo(data))
            .catch((e: any) => {});
    }
}
