import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { genDisplay } from "../../../../../../resource-user/utils";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import ConstantsWordScramble from "../../../../../../shared/utils/ConstantsWordScramble";
import Config from "../../../../../../shared/utils/config";
import "./index.scss";

const getItemLineHeight = (height: number, line: number) => {
    return (
        (height - (line - 1) * ConstantsWordScramble.GAP_SENTENCE) / line +
        ConstantsWordScramble.GAP_SENTENCE
    );
};

const ResourceSentenceScramble = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    const showAnswerKey = useSelector(
        (state: RootState) =>
            state.createWorksheetState.currentActivity.showAnswerKey
    );
    const { textAttribute, height } = resourceItem;
    const {
        content,
        fontSize,
        fontFamily,
        align,
        fontColor,
        underline,
        isBold,
        isItalic,
    } = textAttribute;
    const { color } = resourceItem.imageAttribute.changeColor;
    const { prefix, origin, shuffle, strokeStyle, separatorStyle, line } =
        JSON.parse(content);
    const _height = `${(height - ConstantsWordScramble.GAP_SENTENCE) / 2}px`;

    const style = {
        textAlign: align,
        textDecoration: `${underline ? "underline" : "unset"}`,
        fontWeight: `${isBold ? "bold" : "normal"}`,
        fontStyle: `${isItalic ? "italic" : "normal"}`,
        color: fontColor,
        fontFamily,
        bColor: color,
    };

    const renderContentShuffler = () => {
        const words = shuffle.split(" ").filter((w) => w.trim().length > 0);
        const dashed =
            separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.DASHED;
        const slash =
            separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.SLASH;
        const shape =
            separatorStyle === ConstantsWordScramble.SEPARATOR_STYLE.SHAPE;

        return words.map((w, i) => (
            <Fragment key={w + i}>
                <div
                    className={`${shape ? "border-scramble" : ""} canvas-text`}
                    style={{ borderColor: style.bColor }}
                >
                    {w}
                </div>
                <span>
                    {i !== words.length - 1 ? (
                        <>
                            {dashed && "-"}
                            {slash && "/"}
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </Fragment>
        ));
    };

    const lineHeight = getItemLineHeight(
        (height - ConstantsWordScramble.GAP_SENTENCE) / 2,
        line
    );
    return (
        <div
            className={`resource-sentence-scramble item-2006-${Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE}`}
            style={{
                fontSize,
            }}
        >
            <div
                className="text-container"
                style={{
                    height: "fit-content",
                }}
            >
                {prefix && (
                    <div className="prefix canvas-text" style={style}>
                        {prefix}
                    </div>
                )}
                <div className="shuffle canvas-text" style={style}>
                    {renderContentShuffler()}
                </div>
            </div>
            <div
                className="type-container"
                style={
                    {
                        height: _height,
                        "--line-height": `${
                            line === 1 ? "unset" : lineHeight + "px"
                        }`,
                        maxWidth: resourceItem.width,
                    } as any
                }
            >
                <Input
                    defaultValue={origin}
                    strokeStyle={strokeStyle}
                    showAnswerKey={showAnswerKey === Config.SHOW_VALUE}
                    line={line}
                    style={style}
                    isSeparatorShape={
                        separatorStyle ===
                        ConstantsWordScramble.SEPARATOR_STYLE.SHAPE
                    }
                />
            </div>
        </div>
    );
};

const Input = ({
    defaultValue,
    strokeStyle,
    showAnswerKey,
    line,
    style,
    isSeparatorShape,
}: {
    defaultValue: string;
    strokeStyle: number;
    showAnswerKey: boolean;
    line?: number;
    style: any;
    isSeparatorShape?: boolean;
}) => {
    const [value, setValue] = useState("");

    const getStrokeStyle = () => {
        const borderColor = style.bColor;
        switch (strokeStyle) {
            case ConstantsWordScramble.STROKE_STYLE.DASHED:
                return {
                    borderBottomStyle: "dashed",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.SOLID:
                return {
                    borderBottomStyle: "solid",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.DOTTED:
                return {
                    borderBottomStyle: "dotted",
                    borderBottomColor: borderColor,
                };
            case ConstantsWordScramble.STROKE_STYLE.HANDWRITING:
                return {
                    borderTop: `2px solid ${borderColor}`,
                    borderBottom: `2px solid ${borderColor}`,
                    "--after-display": "unset",
                };
            default:
                return {};
        }
    };

    return (
        <div className="normal-input">
            <textarea
                rows={line}
                className="input scramble-input canvas-text"
                readOnly
                value={defaultValue}
                style={{
                    ...style,
                    display: genDisplay(showAnswerKey),
                }}
            />
            <textarea
                rows={line}
                className="input user-scramble-input canvas-text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{
                    ...style,
                    display: genDisplay(!showAnswerKey),
                }}
            />
            {Array.from(Array(line)).map((_, i) => {
                return (
                    <div
                        key={i}
                        className="line-item"
                        style={{
                            "--after-display": "none",
                            ...(getStrokeStyle() as any),
                            ...style,
                            marginBottom: isSeparatorShape ? 10 : 4,
                        }}
                    >
                        <div style={{ visibility: "hidden" }}>3112</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ResourceSentenceScramble;
