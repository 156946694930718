export const scrollToItemId = (id: string) => {
    const element = document.getElementById("id" + id);
    const container: any = document.querySelector(
        "#input-wrapper .simplebar-content-wrapper"
    );
    const listElements: any = document.querySelector(
        "#input-wrapper .simplebar-content"
    );

    if (element && container && listElements) {
        let scroll =
            element.getBoundingClientRect().top -
            listElements.getBoundingClientRect()?.top;

        container?.scrollTo({
            top: Math.abs(scroll) - element.getBoundingClientRect().height,
            behavior: "smooth",
        });
        setTimeout(() => {
            element.focus();
        }, 50);
        return true;
    }

    return false;
};
