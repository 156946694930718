import { IPosition } from "../shared/models/module";
import { CellTable } from "../shared/models/tableAttribute";

interface IStore {
    positions: IPosition[];
}

let store: IStore = {
    positions: [],
};

let subscribers: any = new Set();

const tableStore = {
    read() {
        return store;
    },
    subscribe(callback: any) {
        subscribers.add(callback);
        return () => subscribers.delete(callback);
    },
    updatePosition(positions: IPosition[]): void {
        store.positions = positions;
        notifyListener();
    },
    resetPosition: () => {
        if (store.positions.length) {
            store.positions = [];
            notifyListener();
        }
    },
};

function notifyListener() {
    store = { ...store };
    subscribers.forEach((callback: () => any) => {
        callback();
    });
}

export default tableStore;
