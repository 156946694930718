import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { updateFillTheBlankSentences } from "../../../../redux/async/fillTheBlankThunk";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    addResourceItems, updateResourceWordBank,
    updateWorkSheetCreator
} from "../../../../redux/reducers/createWorksheet";
import {
    getResourceItemByActivityAndType,
    getResourceItemsByActivityAndType,
} from "../../../../redux/selector";
import { RootState } from "../../../../redux/store";
import {
    EventTracking,
    sendEvent,
} from "../../../../resource-user/utils/event";
import { Activity } from "../../../../shared/models/WorkSheetCreator";
import {
    AnswerActivity,
    IAnswerActivity,
    QuestionActivity,
} from "../../../../shared/models/questionActivity";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { SizeA4 } from "../../../../shared/utils/sizeA4";
import {
    getPositionTopEnd,
    getTextContentWithStyle,
    makeWorkBank,
} from "../../../../utils";
import { getTextWidthHeightTool } from "../../../../utils/draw";
import "./index.scss";
import SentenceItem from "./sentence/SentenceItem";
import PopupModal from "../../../../resource-user/components/common/pop-up/PopupModal";
import { ConstantsApp } from "../../../../utils/Constants";

const placeholder = [
    "Enter your List of complete sentences and click on words to replace them with the blanks",
    "Type or paste complete sentences",
];

export interface ISentence {
    id: string;
    text: string;
}

const MainSentenceView = ({
    activity,
    isActive,
}: {
    activity: Activity;
    isActive?: boolean;
}) => {
    const pageIndex = useAppSelector(
        (state: RootState) => state.createWorksheetState.pageIndex
    );
    const currentActivity = useAppSelector(
        (state: RootState) => state.createWorksheetState.currentActivity
    );
    const showWordBank = useAppSelector(
        (state) => state.createWorksheetState.currentActivity.showWordBank
    );
    const wordBankResource = useAppSelector((state) =>
        getResourceItemByActivityAndType()(state, {
            activityId: currentActivity.id,
            type: ConstantsTool.TYPE_WORD_BANK,
        })
    );
    const resourceFillTheBlank = useAppSelector((state) =>
        getResourceItemsByActivityAndType()(state, {
            activityId: currentActivity.id,
            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        })
    );
    const [sentenceState, setSentenceState] = useState("");
    const textFieldRef = useRef(null);
    const inputRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const [exampleWords, setExampleWords] = useState<{
        id: string;
        word: string;
        index: number;
    }>(null);
    const listQuestionRef = useRef(null);

    const dispatch = useAppDispatch();
    const pagesWorksheet = useAppSelector(
        (state: RootState) => state.createWorksheetState.pagesWorksheet
    );
    const totalPages = pagesWorksheet.length;
    const [previousTotalResource, setPreviousTotalResource] = useState(0);
    const [openVideo, setOpenVideo] = useState(false);
    const refExample = useRef(null);

    useEffect(() => {
        if (pageIndex > 0) {
            let id = "page-worksheet_" + pageIndex;
            let elem = document.getElementById(id);
            if (!elem) {
                id = "page-worksheet_" + (pageIndex - 1);
                elem = document.getElementById(id);
            }

            if (elem) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [pageIndex]);

    // Handle Enter key press
    const handleKeyDown = (event) => {
        if (event.key === "Enter" && sentenceState.trim() !== "") {
            // setTimeout(() => {
            updateSentenceToResourceItem({
                sentences: sentenceState
                    .split("\n")
                    .map((s: string) => s.replaceAll("\b", ""))
                    .map((el) => el.trim()),
            });
            setSentenceState("");
            setExampleWords(null);
            // }, 50);
        }
    };

    const updateSentenceToResourceItem = ({
        sentences,
        answers = [],
    }: {
        sentences: string[];
        answers?: IAnswerActivity[];
    }) => {
        let indexResource = resourceFillTheBlank.length + 1;
        let listResourceItem: IResourceItemNew[] = [];
        let maxValue = 0;
        let newPageIndex = 0;
        if (sentences.length) {
            let contentText = getTextContentWithStyle(
                indexResource,
                sentences[0],
                currentActivity.listStyle
            );
            let size = getTextWidthHeightTool({
                textValue: contentText,
                includePadding: true,
                maxWidth: ConstantsTool.MAX_WIDTH_TEXT_FILL_IN_BLANK,
                fontFamily: ConstantsTool.DEFAULT_FONT,
                fontSize: ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE,
                listStyle: currentActivity.listStyle,
            });
            let value = getPositionTopEnd({
                pagesWorksheet,
                type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                height: size.height,
            });
            maxValue = value.y + currentActivity.heightGap;
            newPageIndex = value.pageIndex;
        }
        for (let i = 0; i < sentences.length; i++) {
            let sentence = sentences[i].trim();
            if (sentence.length > 0) {
                let contentText = getTextContentWithStyle(
                    indexResource,
                    sentence,
                    currentActivity.listStyle
                );
                let size = getTextWidthHeightTool({
                    textValue: contentText,
                    includePadding: true,
                    maxWidth: ConstantsTool.MAX_WIDTH_TEXT_FILL_IN_BLANK,
                    fontFamily: ConstantsTool.DEFAULT_FONT,
                    fontSize:
                        ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE,
                    listStyle: currentActivity.listStyle,
                });
                if (maxValue + size.height > ConstantsTool.MAX_Y_BORDER) {
                    dispatch(
                        updateFillTheBlankSentences({
                            pageIndex: newPageIndex,
                            resourceItems: listResourceItem,
                            isAddHistory: false,
                        })
                    );
                    listResourceItem = [];
                    newPageIndex += 1;
                    maxValue = ConstantsTool.START_Y_NEW_PAGE_FILL_THE_BLANK;
                }
                if (!maxValue) {
                    maxValue =
                        showWordBank &&
                        wordBankResource.pageIndex === newPageIndex
                            ? wordBankResource?.y +
                              wordBankResource?.height +
                              20
                            : SizeA4.HEIGHT_A4 * 0.15;
                }
                let resourceItem = new ResourceItemNew({
                    id: `fill_the_blank_${new Date().getTime()}_${indexResource}`,
                    idType: `fill_the_blank_${new Date().getTime()}_${indexResource}`,
                    activityId: activity.id,
                    width: size.width,
                    height: size.height,
                    type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                    textAttribute: {
                        fontFamily: ConstantsTool.DEFAULT_FONT,
                        fontSize:
                            ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE,
                        content: contentText,
                        align: "start",
                    },
                    x: 60,
                    y: maxValue,
                    question: new QuestionActivity({
                        question: sentence,
                        indexQuestion: indexResource,
                        answers,
                    }),
                    pageIndex: newPageIndex,
                });
                listResourceItem.push(resourceItem);
                indexResource += 1;
                maxValue += size.height + currentActivity.heightGap;
            }
        }
        dispatch(
            updateFillTheBlankSentences({
                pageIndex: newPageIndex,
                resourceItems: listResourceItem,
                isAddHistory: true,
            })
        );
    };

    const updateWorkBank = (wordBank: string) => {
        if (wordBankResource) {
            dispatch(
                updateResourceWordBank({
                    resourceItem: new ResourceItemNew({
                        ...wordBankResource,
                        type: ConstantsTool.TYPE_WORD_BANK,
                        wordBanks: [...wordBankResource.wordBanks, wordBank],
                    }),
                })
            );
        } else {
            dispatch(
                addResourceItems({
                    pageIndex: 0,
                    isAddHistory: false,
                    resourceItems: [
                        makeWorkBank({
                            activityId: activity.id,
                            wordBanks: [wordBank],
                        }),
                    ],
                })
            );
        }
    };

    const addExample = () => {
        let word = "blank";
        let sentence = "Let's make your own fill in the blank worksheet";
        let answers = [
            new AnswerActivity({
                id: new Date().getTime().toString(),
                text: word,
                isCorrect: true,
                indexWord: 7,
            }),
        ];
         updateSentenceToResourceItem({
            sentences: [sentence],
            answers,
        });
        if (!currentActivity.showWordBank) {
            setTimeout(() => {
                if (!currentActivity.showWordBank)
                    dispatch(
                        updateWorkSheetCreator({
                            activity: { showWordBank: 1 },
                            isAddHistory: false,
                        })
                    );
            }, 10);
        } else {
            updateWorkBank(word);
        }
        setSentenceState("");
        sendEvent(EventTracking.fitb_give_an_example);
    };

    useEffect(() => {
        if (resourceFillTheBlank.length === 0) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const simpleBarInstance = listQuestionRef.current;
            if (
                simpleBarInstance &&
                resourceFillTheBlank.length > previousTotalResource
            ) {
                const scrollElement = simpleBarInstance.getScrollElement();
                scrollElement.scrollTo({
                    top: scrollElement.scrollHeight,
                    behavior: "smooth",
                });
            }
            setPreviousTotalResource(resourceFillTheBlank.length);
        }, 50);
    }, [resourceFillTheBlank.length]);

    return (
        <div>
            <div className="sentence-list-container">
                <div className="header">
                    <div className="title">Word</div>
                    <div
                        ref={refExample}
                        className="title-add-question"
                        onClick={() => {
                            addExample();
                            sendEvent(EventTracking.fitb_give_an_example);
                        }}
                        onMouseEnter={() => setOpenVideo(true)}
                        onMouseLeave={() => setOpenVideo(false)}
                    >
                        Give an example?
                    </div>
                </div>
                <div className="box-fitb">
                    <SimpleBar
                        className="bar-questions"
                        style={{
                            maxHeight: "220px",
                            minHeight: "220px",
                        }}
                        ref={listQuestionRef}
                    >
                        <div
                            className="content-box"
                            onClick={() => {
                                inputRef?.current?.focus();
                            }}
                        >
                            {resourceFillTheBlank.map((resource, index) => (
                                <SentenceItem
                                    key={index}
                                    index={index}
                                    resourceItem={resource}
                                    activityId={currentActivity.id}
                                    wordBankResource={wordBankResource}
                                />
                            ))}
                            <div
                                className={
                                    "box-input-fitb " +
                                    (focus ? "box-input-fitb-focus" : "")
                                }
                            >
                                <span
                                    className="text-index"
                                    style={{ opacity: focus ? 1 : 0.24 }}
                                >
                                    {resourceFillTheBlank.length + 1}.
                                </span>
                                <TextField
                                    inputRef={inputRef}
                                    ref={textFieldRef}
                                    className="input-field"
                                    placeholder={placeholder[1]}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            sendEvent(EventTracking.fitb_enter);
                                            sendEvent(
                                                EventTracking.fitb_type_sentence
                                            );
                                            event.preventDefault();
                                        }
                                        if (
                                            event.key === "v" &&
                                            event.metaKey
                                        ) {
                                            sendEvent(
                                                EventTracking.fitb_type_sentence
                                            );
                                        }
                                    }}
                                    multiline
                                    onKeyUp={(e) => {
                                        handleKeyDown(e);
                                    }}
                                    onChange={(e: any) =>
                                        setSentenceState(e.target.value)
                                    }
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => {
                                        setFocus(false);
                                        if (sentenceState.trim() !== "") {
                                            sendEvent(
                                                EventTracking.fitb_type_sentence
                                            );
                                        }
                                    }}
                                    value={sentenceState}
                                    // sx={{
                                    //     "& .MuiOutlinedInput-notchedOutline": {
                                    //         border: focus
                                    //             ? "1px solid #3B82F6 !important"
                                    //             : null,
                                    //         boxShadow: focus
                                    //             ? "0px 0px 0px 3px rgba(59, 130, 246, 0.12)"
                                    //             : null,
                                    //     },
                                    // }}
                                />
                            </div>
                        </div>
                    </SimpleBar>
                    <div className="note-fitb">
                        *Press Enter then Click on words to replace them with
                        the blanks
                    </div>
                </div>
            </div>
            <PopupModal
                open={openVideo}
                anchorElement={refExample.current}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                onClose={function (): void {
                    setOpenVideo(false);
                }}
                left={18}
                isBackdrop = {false}
            >
                <div
                    style={{
                        border: "2px solid #fff",
                        background: "#fff",
                    }}
                >
                    <video width="338" loop autoPlay muted>
                        <source
                            src={ConstantsApp.LINK_VIDEO_TUTORIAL_FITB}
                            type="video/mp4"
                        />
                    </video>
                </div>
            </PopupModal>
        </div>
    );
};

export default MainSentenceView;
