import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import FieldModal from '../../../../../resource-user/components/main-header/field-modal';
import { useDispatch } from 'react-redux';
import { IAnswerField, setTemplate, setTemplateChanged } from '../../../../../redux/reducers/createWorksheet';
import { useAppSelector } from '../../../../../redux/hook';
import { updateDataAPI } from '../../../../../utils/query';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import './styles.scss';
import { getDataFromAPI } from '../../../../../utils/query';
import TemplateAnswerSheetsModal from './template-answer-sheets-modal';
import { Form } from 'react-bootstrap';

export const TemplateAnswerSheetLabel = ({ option, imageWidth = 70 }) => {
    const imageUrl = option.imageUrl;

    return (
        <div className="d-flex align-items-center">
            <Image
                style={{
                    width: imageWidth,
                    height: 'auto',
                }}
                src={imageUrl}
                alt={option.label}
                rounded
                className="me-2"
            />
            <span>{option.id}. {option.label}</span>
        </div>
    )
};

const AnswerFieldsTable = ({ answerFields }) => {
    const dispatch = useDispatch();

    const template = useAppSelector(state => state.createWorksheetState.template);

    const [showEditFieldModal, setShowEditFieldModal] = useState(false);
    const [editedField, setEditedField] = useState(null);

    const [showAnswerSheetTemplatesModal, setShowAnswerSheetTemplatesModal] = useState(false);
    const [answerSheetTemplates, setAnswerSheetTemplates] = useState([]);
    const [currentTemplateAnswerSheet, setCurrentTemplateAnswerSheet] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTemplateAnswerSheets = async () => {
            setLoading(true);
            const url = 'auto-content/templates/?type=answer_sheet&storeID=' + template.store;

            const res = await getDataFromAPI(url);

            const data = res.data['results'].map((answerSheet) => ({
                ...answerSheet,
                label: answerSheet.id + '. ' + answerSheet.name,
                value: answerSheet.id,
            }));

            console.log(data);

            setAnswerSheetTemplates(data);

            if (template.templateAnswerSheet) {
                setCurrentTemplateAnswerSheet(data.find((templateAnswerSheet) => templateAnswerSheet.id === template.templateAnswerSheet));
            }
            setLoading(false);
        }

        fetchTemplateAnswerSheets();
    }, []);

    const createNewField = async(fieldName: string, fieldIndex: number, fieldDescription: string, parentField: string, parentFieldIndex: number, fieldAnswerUserTitle: string) => {
        let answerFields;
        if (template.answerFields === null) {
            answerFields = [];
        } else {
            answerFields = Object.assign([], template.answerFields);
        }
        if (answerFields.find(field => field.fieldName === fieldName) === undefined) {
            answerFields.push({fieldName, fieldIndex, fieldDescription, parentField, parentFieldIndex, fieldAnswerUserTitle});
        }
        dispatch(setTemplate({...template, answerFields}));
        await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
            answerFields
        });
        await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
            zones: [],
            answers: answerFields,
            templateID: template.id,
        });
        setShowEditFieldModal(false);
    }

    const deleteField = async(fieldName: string) => {
        if (window.confirm('Are you sure want to delete this field?')) {
            const answerFields = template.answerFields.filter(f => f.fieldName !== fieldName);
            dispatch(setTemplate({ ...template, answerFields }));
            await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
                answerFields
            });
            await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                zones: [],
                answers: answerFields,
                templateID: template.id,
            });
        }
    }

    const handleEditField = (fieldIndex: number, fieldValues: IAnswerField = {}) => {
        setEditedField({
            id: fieldIndex,
            ...fieldValues,
        });
    };

    const updateCurrentField = async (fieldName: string, fieldIndex: number, fieldDescription: string, parentField: string, parentFieldIndex: number, fieldAnswerUserTitle: string) => {
        if (fieldName !== '' && editedField?.id != null) {
            let updatedField = {fieldName, fieldIndex, fieldDescription, parentField, parentFieldIndex, fieldAnswerUserTitle};

            try {
                const updatedAnswerFields = template.answerFields.map((field, index) => {
                    if (editedField.id === index) {
                        return updatedField;
                    };
                    return field;
                });

                const res = await updateDataAPI('PATCH', `auto-content/templates/${template.id}/`, {
                    answerFields: updatedAnswerFields
                });

                if (res.data) {
                    dispatch(setTemplate({ ...template, answerFields: updatedAnswerFields }));
                    setEditedField(null);
                    setShowEditFieldModal(false);

                    await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                        zones: [],
                        answers: updatedAnswerFields,
                        templateID: template.id,
                    });
                }
            } catch (e) {
                window.alert(`An error occured when updating font family: ${e.message}`);
            };
        };
    };

    const handleChangeTemplateAnswerSheet = async (value) => {
        const res = await updateDataAPI('PATCH', `auto-content/templates/${template.id}/`, {
            answerSheetTemplate: value?.id
        });

        if (res.data) {
            dispatch(setTemplate({ ...template, templateAnswerSheet: value }));
            setCurrentTemplateAnswerSheet(value);
        }
    };


    return (
        <div className="mt-3 answer-fields-table">
            <Table striped bordered hover>
                <thead>
                    <th>Field</th>
                    <th>Desc</th>
                    <th>Link to</th>
                    <th>User Title</th>
                    <th></th>
                </thead>
                {answerFields && (
                    <tbody>
                        {answerFields.map((field, index) => (
                            <tr key={index}>
                                <td>{field.fieldName}</td>
                                <td className="answer-description">{field.fieldDescription}</td>
                                <td>{field.parentField}</td>
                                <td>{field.fieldAnswerUserTitle}</td>
                                <td className="d-flex align-items-center">
                                    <Edit style={{ fontSize: '20px', color: '#0762eb', marginRight: '5px', cursor: 'pointer' }} onClick={() => {
                                        handleEditField(index, field);
                                        setShowEditFieldModal(true);
                                    }} />
                                    <Delete style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} onClick={() => deleteField(field.fieldName)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>

            <div
                onClick={() => setShowAnswerSheetTemplatesModal(true)}
                className="template-answer-sheet">
                {currentTemplateAnswerSheet?.imageUrl && (
                    <TemplateAnswerSheetLabel option={currentTemplateAnswerSheet} imageWidth={100}/>
                )}
            </div>

            <div className="btns">
                <Button size="sm" onClick={() => setShowEditFieldModal(true)}>Add New Field</Button>
            </div>

            <Form>
                <Form.Group>
                    <Form.Label><strong>Answer Sheet Form</strong></Form.Label>
                    <Form.Select onChange={e => {
                        dispatch(setTemplate({ ...template, answerSheetForm: e.target.value }));
                        dispatch(setTemplateChanged(true));
                    }} value={template?.answerSheetForm}>
                        <option></option>
                        <option value="long">Long</option>
                        <option value="short">Short</option>
                    </Form.Select>
                    <Form.Text>
                        <div>Long has about 4 - 5 answers/page</div>
                        <div>Short has about 10 - 15 answers/page</div>
                    </Form.Text>
                </Form.Group>
            </Form>

            {showEditFieldModal && (
                <FieldModal
                    type="answer"
                    fieldName={editedField?.fieldName || null}
                    fieldIndex={editedField?.fieldIndex || null}
                    fieldDescription={editedField?.fieldDescription || null}
                    parentField={editedField?.parentField || null}
                    parentFieldIndex={editedField?.parentFieldIndex || null}
                    fieldAnswerUserTitle={editedField?.fieldAnswerUserTitle || null}
                    staticValue={null}
                    hideModal={() => setShowEditFieldModal(false)}
                    save={editedField?.id == null ? createNewField : updateCurrentField} />
            )}

            {showAnswerSheetTemplatesModal && (
                <TemplateAnswerSheetsModal
                    hideModal={() => setShowAnswerSheetTemplatesModal(false)}
                    templateAnswerSheets={answerSheetTemplates}
                    currentTemplateAnswerSheet={currentTemplateAnswerSheet}
                    handleChangeTemplateAnswerSheet={handleChangeTemplateAnswerSheet}
                    loading={loading}
                />
            )}

        </div>
    )
}

export default AnswerFieldsTable;