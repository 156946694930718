import React from "react";

const Row2 = () => {
    return (
        <svg
            width="55"
            height="14"
            viewBox="0 0 55 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 6.25C0.585786 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585786 7.75 1 7.75V6.25ZM13.5303 7.53033C13.8232 7.23744 13.8232 6.76256 13.5303 6.46967L8.75736 1.6967C8.46447 1.40381 7.98959 1.40381 7.6967 1.6967C7.40381 1.98959 7.40381 2.46447 7.6967 2.75736L11.9393 7L7.6967 11.2426C7.40381 11.5355 7.40381 12.0104 7.6967 12.3033C7.98959 12.5962 8.46447 12.5962 8.75736 12.3033L13.5303 7.53033ZM1 7.75H13V6.25H1V7.75Z"
                fill="#1F2937"
            />
            <path
                d="M29.75 1C29.75 0.585786 29.4142 0.25 29 0.25C28.5858 0.25 28.25 0.585786 28.25 1L29.75 1ZM28.4697 13.5303C28.7626 13.8232 29.2374 13.8232 29.5303 13.5303L34.3033 8.75736C34.5962 8.46447 34.5962 7.98959 34.3033 7.6967C34.0104 7.40381 33.5355 7.40381 33.2426 7.6967L29 11.9393L24.7574 7.6967C24.4645 7.40381 23.9896 7.40381 23.6967 7.6967C23.4038 7.98959 23.4038 8.46447 23.6967 8.75736L28.4697 13.5303ZM28.25 1L28.25 13L29.75 13L29.75 1L28.25 1Z"
                fill="#1F2937"
            />
            <path
                d="M45.5303 2.22748C45.2374 1.93459 44.7626 1.93459 44.4697 2.22748C44.1768 2.52038 44.1768 2.99525 44.4697 3.28814L45.5303 2.22748ZM53.4853 11.9931C53.8995 11.9931 54.2353 11.6573 54.2353 11.2431L54.2353 4.49309C54.2353 4.07888 53.8995 3.74309 53.4853 3.74309C53.0711 3.74309 52.7353 4.07888 52.7353 4.49309V10.4931H46.7353C46.3211 10.4931 45.9853 10.8289 45.9853 11.2431C45.9853 11.6573 46.3211 11.9931 46.7353 11.9931L53.4853 11.9931ZM44.4697 3.28814L52.955 11.7734L54.0156 10.7128L45.5303 2.22748L44.4697 3.28814Z"
                fill="#1F2937"
            />
        </svg>
    );
};

export default Row2;
