import Config from "../utils/config";
import { IAssignment } from "./assignment";
import { IUserInfo } from "./userInfo";
import { IWorksheet } from "./worksheet";

export interface IUserAssignment {
    id: string;
    assignmentId: string;
    classId: string;
    userId: string | IUserInfo;
    userPracticeId: string;
    score: number;
    textAnswer: string;
    questionAnswer: string[];
    totalSubmit?: number;
    createdAt: string;
    updatedAt: string;
    assignment?: IAssignment;
    worksheet?: IWorksheet;
    userInfo?: IUserInfo;
    status?: number;
}
export class UserAssignment implements IUserAssignment {
    id: string;
    assignmentId: string;
    classId: string;
    userId: string | IUserInfo;
    userPracticeId: string;
    score: number;
    textAnswer: string;
    questionAnswer: string[];
    totalSubmit?: number;
    createdAt: string;
    updatedAt: string;
    assignment?: IAssignment;
    worksheet?: IWorksheet;
    userInfo?: IUserInfo;
    status?: number;

    constructor(object: any = {}) {
        this.id = object?.id ?? "";
        this.assignmentId = object?.assignmentId ?? undefined;
        this.classId = object?.classId ?? undefined;
        this.userId = object?.userId ?? undefined;
        this.userPracticeId = object?.userPracticeId ?? "";
        this.score = object?.score == null ? null : object?.score ?? "";
        this.textAnswer = object.textAnswer ?? "";
        this.questionAnswer = object.questionAnswer ?? [];
        this.createdAt = object?.createdAt ?? "";
        this.updatedAt = object?.updatedAt ?? "";
        this.totalSubmit = object?.totalSubmit ?? 1;
        this.assignment = object?.assignment ?? null;
        this.worksheet = object?.worksheet ?? null;
        this.userInfo = object.userInfo ?? null;
        this.status = object.status ?? Config.STATUS_PUBLIC;
    }
}
