import React from "react";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";

function ListBorderGroup({
    resourceItems,
    ratio,
}: {
    resourceItems: IResourceItemNew[];
    ratio: number;
}) {
    return (
        <React.Fragment>
            {resourceItems.length > 1 &&
                resourceItems.map((resourceItem) => {
                    return (
                        <div
                            style={{
                                touchAction: "pan-x pan-y pinch-zoom",
                                width: `${resourceItem.width * ratio}px`,
                                height: `${resourceItem.height * ratio}px`,
                                cursor: "auto",
                                outline: "1px solid #8b3dff",
                                position: "absolute",
                                transform: `translate(${
                                    resourceItem.x * ratio
                                }px, ${resourceItem.y * ratio}px) rotate(${
                                    resourceItem.rotate
                                }deg)`,
                            }}
                            className={"not-click"}
                        />
                    );
                })}
        </React.Fragment>
    );
}

export default ListBorderGroup;
