import React, { Fragment, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabFontFamilies from './tab-font-families';
import TabFrames from './tab-frames';
import { useAppSelector } from '../../../../redux/hook';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ padding: 10 }}
        >
            {value === index && (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabAssets({
    activeTab,
    parent,
    dataTab,
    listIdBackgroundUsing,
}: {
    dataTab: any[];
    activeTab: number;
    parent: HTMLDivElement;
    listIdBackgroundUsing: string[];
}) {
    const [value, setValue] = React.useState(0);
    const { currentStore } = useAppSelector((state) => state.createWorksheetState);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Frames" {...a11yProps(0)} />
                    <Tab label="Font families" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <TabFrames
                    activeTab={activeTab}
                    parent={parent}
                    dataTab={dataTab}
                    listIdBackgroundUsing={listIdBackgroundUsing}
                    currentStore={currentStore}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <TabFontFamilies currentStore={currentStore}/>
            </CustomTabPanel>
        </Box>
    );
}