import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hook";
import {
    addResourceItems,
    selectElements,
    updateInitStaticMathField,
    updateResourceItem,
} from "../../../../../redux/reducers/createWorksheet";
import {
    Command,
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import { getPositionResource, getPrefixImage } from "../../../../../utils";
import { getMathFieldWidthHeightTool } from "../../../../../utils/draw";
import "./index.scss";

const MATH_FORMULA = [
    {
        eventName: "ncw_use_equation_greek_letters",
        icon: "greek-letters/avatar-function.svg",
        data: [
            {
                icon: "greek-letters/alpha.svg",
                name: "alpha",
                latex: "\\alpha",
            },
            {
                icon: "greek-letters/beta.svg",
                name: "beta",
                latex: "\\beta",
            },
            {
                icon: "greek-letters/gamma.svg",
                name: "gamma",
                latex: "\\gamma",
            },
            {
                icon: "greek-letters/delta.svg",
                name: "delta",
                latex: "\\delta",
            },
            {
                icon: "greek-letters/epsilon.svg",
                name: "epsilon",
                latex: "\\epsilon",
            },
            {
                icon: "greek-letters/varepsilon.svg",
                name: "varepsilon",
                latex: "\\varepsilon",
            },
            {
                icon: "greek-letters/zeta.svg",
                name: "zeta",
                latex: "\\zeta",
            },
            {
                icon: "greek-letters/eta.svg",
                name: "eta",
                latex: "\\eta",
            },
            {
                icon: "greek-letters/theta.svg",
                name: "theta",
                latex: "\\theta",
            },
            {
                icon: "greek-letters/vartheta.svg",
                name: "vartheta",
                latex: "\\vartheta",
            },
            {
                icon: "greek-letters/iota.svg",
                name: "iota",
                latex: "\\iota",
            },
            {
                icon: "greek-letters/kappa.svg",
                name: "kappa",
                latex: "\\kappa",
            },
            {
                icon: "greek-letters/lambda.svg",
                name: "lambda",
                latex: "\\lambda",
            },
            {
                icon: "greek-letters/mu.svg",
                name: "mu",
                latex: "\\mu",
            },
            {
                icon: "greek-letters/nu.svg",
                name: "nu",
                latex: "\\nu",
            },
            {
                icon: "greek-letters/xi.svg",
                name: "xi",
                latex: "\\xi",
            },
            {
                icon: "greek-letters/pi.svg",
                name: "pi",
                latex: "\\pi",
            },
            {
                icon: "greek-letters/varpi.svg",
                name: "varpi",
                latex: "\\varpi",
            },
            {
                icon: "greek-letters/rho.svg",
                name: "rho",
                latex: "\\rho",
            },
            {
                icon: "greek-letters/sigma.svg",
                name: "sigma",
                latex: "\\sigma",
            },
            {
                icon: "greek-letters/varsigma.svg",
                name: "varsigma",
                latex: "\\varsigma",
            },
            {
                icon: "greek-letters/tau.svg",
                name: "tau",
                latex: "\\tau",
            },
            {
                icon: "greek-letters/upsilon.svg",
                name: "upsilon",
                latex: "\\upsilon",
            },
            {
                icon: "greek-letters/phi.svg",
                name: "phi",
                latex: "\\phi",
            },
            {
                icon: "greek-letters/varphi.svg",
                name: "varphi",
                latex: "\\varphi",
            },
            {
                icon: "greek-letters/chi.svg",
                name: "chi",
                latex: "\\chi",
            },
            {
                icon: "greek-letters/psi.svg",
                name: "psi",
                latex: "\\psi",
            },
            {
                icon: "greek-letters/omega.svg",
                name: "omega",
                latex: "\\omega",
            },
            {
                icon: "greek-letters/gamma-up.svg",
                name: "Gamma",
                latex: "\\Gamma",
            },
            {
                icon: "greek-letters/delta-up.svg",
                name: "Delta",
                latex: "\\Delta",
            },
            {
                icon: "greek-letters/theta-up.svg",
                name: "Theta",
                latex: "\\Theta",
            },
            {
                icon: "greek-letters/lambda-up.svg",
                name: "Lambda",
                latex: "\\Lambda",
            },
            {
                icon: "greek-letters/xi-up.svg",
                name: "Xi",
                latex: "\\Xi",
            },
            {
                icon: "greek-letters/pi-up.svg",
                name: "Pi",
                latex: "\\Pi",
            },
            {
                icon: "greek-letters/sigma-up.svg",
                name: "Sigma",
                latex: "\\Sigma",
            },
            {
                icon: "greek-letters/upsilon-up.svg",
                name: "Upsilon",
                latex: "\\Upsilon",
            },
            {
                icon: "greek-letters/phi-up.svg",
                name: "Phi",
                latex: "\\Phi",
            },
            {
                icon: "greek-letters/psi-up.svg",
                name: "Psi",
                latex: "\\Psi",
            },
            {
                icon: "greek-letters/omega-up.svg",
                name: "Omega",
                latex: "\\Omega",
            },
        ],
    },
    {
        eventName: "ncw_use_equation_miscellaneous_operations",
        icon: "equation-relations/avatar-relation.svg",
        data: [
            {
                icon: "equation-relations/times.svg",
                name: "times",
                latex: "\\times",
            },
            {
                icon: "equation-relations/div.svg",
                name: "div",
                latex: "\\div",
            },
            {
                icon: "equation-relations/cdot.svg",
                name: "cdot",
                latex: "\\cdot",
            },
            {
                icon: "equation-relations/pm.svg",
                name: "pm",
                latex: "\\pm",
            },
            {
                icon: "equation-relations/mp.svg",
                name: "mp",
                latex: "\\mp",
            },
            {
                icon: "equation-relations/ast.svg",
                name: "ast",
                latex: "\\ast",
            },
            {
                icon: "equation-relations/star.svg",
                name: "star",
                latex: "\\star",
            },
            {
                icon: "equation-relations/bigcirc.svg",
                name: "bigcirc",
                latex: "\\bigcirc",
            },
            // {
            //     icon: "equation-relations/bullet.svg",
            //     name: "bullet",
            //     latex: "\\bullet"
            // },
            {
                icon: "equation-relations/odot.svg",
                name: "odot",
                latex: "\\odot",
            },
            {
                icon: "equation-relations/ominus.svg",
                name: "ominus",
                latex: "\\ominus",
            },
            {
                icon: "equation-relations/oplus.svg",
                name: "oplus",
                latex: "\\oplus",
            },
            {
                icon: "equation-relations/otimes.svg",
                name: "otimes",
                latex: "\\otimes",
            },
            {
                icon: "equation-relations/oslash.svg",
                name: "oslash",
                latex: "\\oslash",
            },
            {
                icon: "equation-relations/dagger.svg",
                name: "dagger",
                latex: "\\dagger",
            },
            {
                icon: "equation-relations/ddagger.svg",
                name: "ddagger",
                latex: "\\ddagger",
            },
            {
                icon: "equation-relations/vee.svg",
                name: "vee",
                latex: "\\vee",
            },
            {
                icon: "equation-relations/wedge.svg",
                name: "wedge",
                latex: "\\wedge",
            },
            {
                icon: "equation-relations/cap.svg",
                name: "cap",
                latex: "\\cap",
            },
            {
                icon: "equation-relations/cup.svg",
                name: "cup",
                latex: "\\cup",
            },
            {
                icon: "equation-relations/aleph.svg",
                name: "aleph",
                latex: "\\aleph",
            },
            {
                icon: "equation-relations/re.svg",
                name: "Re",
                latex: "\\Re",
            },
            {
                icon: "equation-relations/exists.svg",
                name: "exists",
                latex: "\\exists",
            },
            {
                icon: "equation-relations/foall.svg",
                name: "forall",
                latex: "\\forall",
            },
            {
                icon: "equation-relations/infty.svg",
                name: "infty",
                latex: "\\infty",
            },
            {
                icon: "equation-relations/neg.svg",
                name: "neg",
                latex: "\\neg",
            },
            {
                icon: "equation-relations/partial.svg",
                name: "partial",
                latex: "\\partial",
            },
            {
                icon: "equation-relations/perp.svg",
                name: "perp",
                latex: "\\perp",
            },
            {
                icon: "equation-relations/triangle.svg",
                name: "triangle",
                latex: "\\triangle",
            },
        ],
    },
    {
        eventName: "ncw_use_equation_relations",
        icon: "miscellaneous-operations/avatar-miscellaneous.svg",
        data: [
            {
                icon: "miscellaneous-operations/leq.svg",
                name: "leq",
                latex: "\\leq",
            },
            {
                icon: "miscellaneous-operations/geq.svg",
                name: "geq",
                latex: "\\geq",
            },
            {
                icon: "miscellaneous-operations/prec.svg",
                name: "prec",
                latex: "\\prec",
            },
            {
                icon: "miscellaneous-operations/approx.svg",
                name: "approx",
                latex: "\\approx",
            },
            {
                icon: "miscellaneous-operations/succ.svg",
                name: "succ",
                latex: "\\succ",
            },
            {
                icon: "miscellaneous-operations/preceq.svg",
                name: "preceq",
                latex: "\\preceq",
            },
            {
                icon: "miscellaneous-operations/succeq.svg",
                name: "succeq",
                latex: "\\succeq",
            },
            {
                icon: "miscellaneous-operations/ll.svg",
                name: "ll",
                latex: "\\ll",
            },
            {
                icon: "miscellaneous-operations/gg.svg",
                name: "gg",
                latex: "\\gg",
            },
            {
                icon: "miscellaneous-operations/equiv.svg",
                name: "equiv",
                latex: "\\equiv",
            },
            {
                icon: "miscellaneous-operations/neq.svg",
                name: "neq",
                latex: "\\neq",
            },
            {
                icon: "miscellaneous-operations/asymp.svg",
                name: "asymp",
                latex: "\\asymp",
            },
            {
                icon: "miscellaneous-operations/in.svg",
                name: "in",
                latex: "\\in",
            },
            {
                icon: "miscellaneous-operations/ni.svg",
                name: "ni",
                latex: "\\ni",
            },
            {
                icon: "miscellaneous-operations/notin.svg",
                name: "notin",
                latex: "\\notin",
            },
            {
                icon: "miscellaneous-operations/sim.svg",
                name: "sim",
                latex: "\\sim",
            },
            {
                icon: "miscellaneous-operations/simeq.svg",
                name: "simeq",
                latex: "\\simeq",
            },
            {
                icon: "miscellaneous-operations/subset.svg",
                name: "subset",
                latex: "\\subset",
            },
            {
                icon: "miscellaneous-operations/subseteq.svg",
                name: "subseteq",
                latex: "\\subseteq",
            },
            {
                icon: "miscellaneous-operations/supseteq.svg",
                name: "supseteq",
                latex: "\\supseteq",
            },
        ],
    },
    {
        eventName: "ncw_use_equation_math_operations",
        icon: "math-operations/avatar-math.svg",
        data: [
            {
                icon: "math-operations/frac.svg",
                name: "frac",
                latex: "\\frac{}{}",
            },
            {
                icon: "math-operations/sqrt.svg",
                name: "sqrt",
                latex: "\\sqrt{}",
            },
            {
                icon: "math-operations/sqrtn.svg",
                name: "sqrtn",
                latex: "\\sqrt[]{}",
            },
            {
                icon: "math-operations/powerindex.svg",
                name: "powerindex",
                latex: "x_{}^{}",
            },
            {
                icon: "math-operations/index.svg",
                name: "index",
                latex: "x_{} ",
            },
            {
                icon: "math-operations/power.svg",
                name: "power",
                latex: "x^{}",
            },
            {
                icon: "math-operations/sum.svg",
                name: "sum",
                latex: "\\sum_{}^{}",
            },
            {
                icon: "math-operations/lim.svg",
                name: "lim",
                latex: "\\lim_{a \\rightarrow b} ",
            },
            {
                icon: "math-operations/not.svg",
                name: "not",
                latex: "\\overline{}",
            },
            {
                icon: "math-operations/widehat.svg",
                name: "hat",
                latex: "\\hat{}",
            },
            {
                icon: "math-operations/bigcap.svg",
                name: "bigcap",
                latex: "\\bigcap_{}^{}",
            },
            {
                icon: "math-operations/bigcup.svg",
                name: "bigcup",
                latex: "\\bigcup_{}^{}",
            },
            {
                icon: "math-operations/int.svg",
                name: "int",
                latex: "\\int",
            },
            {
                icon: "math-operations/oint.svg",
                name: "oint",
                latex: "\\oint_{}^{}",
            },
            {
                icon: "math-operations/prod.svg",
                name: "prod",
                latex: "\\prod",
            },
        ],
    },
    {
        eventName: "ncw_use_equation_arrows",
        icon: "equation-arrows/avatar-arrow.svg",
        data: [
            {
                icon: "equation-arrows/downarrow-up.svg",
                name: "Downarrow",
                latex: "\\Downarrow",
            },
            {
                icon: "equation-arrows/downarrow.svg",
                name: "downarrow",
                latex: "\\downarrow",
            },
            {
                icon: "equation-arrows/leftarrow-up.svg",
                name: "Leftarrow",
                latex: "\\Leftarrow",
            },
            {
                icon: "equation-arrows/leftarrow.svg",
                name: "leftarrow",
                latex: "\\leftarrow",
            },
            {
                icon: "equation-arrows/leftrightarrow.svg",
                name: "leftrightarrow",
                latex: "\\leftrightarrow",
            },
            {
                icon: "equation-arrows/rightarrow-up.svg",
                name: "Rightarrow",
                latex: "\\Rightarrow",
            },
            {
                icon: "equation-arrows/rightarrow.svg",
                name: "rightarrow",
                latex: "\\rightarrow",
            },
            {
                icon: "equation-arrows/uparrow-up.svg",
                name: "Uparrow",
                latex: "\\Uparrow",
            },
            {
                icon: "equation-arrows/updownarrow-up.svg",
                name: "Updownarrow",
                latex: "\\Updownarrow",
            },
            {
                icon: "equation-arrows/updownarrow.svg",
                name: "updownarrow",
                latex: "\\updownarrow",
            },
        ],
    },
];

enum TabEquation {
    greek_letters,
    equation_relations,
    miscellaneous_operations,
    math_operations,
    equation_arrows,
}

const Equation = () => {
    const mainRef = useRef(null);

    const dispatch = useDispatch();
    const currentPage = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const pageWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet[currentPage]
    );
    const currentElementId = useAppSelector(
        (state) => state.createWorksheetState.currentElementId
    );
    const resourceItems: IResourceItemNew[] =
        currentPage >= 0
            ? useAppSelector(
                (state) =>
                    state.createWorksheetState.pagesWorksheet[currentPage]
                        .resourceItems
            )
            : [];
    const [currentTab, setCurrentTab] = React.useState(
        MATH_FORMULA[0].eventName
    );
    const [currentData, setCurrentData] = React.useState(MATH_FORMULA[0]);

    useEffect(() => {
        let data = MATH_FORMULA.find(
            (equation) => equation.eventName === currentTab
        );
        setCurrentData(data);
    }, [currentTab]);

    const onClickResource = async (el: any, eventName: string) => {
        let newlatex = el.latex;
        const currentElement = resourceItems.find(
            (e) => e.id === currentElementId
        );
        if (currentElement?.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA) {
            newlatex += currentElement.textAttribute.content;
        }
        dispatch(
            updateInitStaticMathField({
                latex: newlatex,
            })
        );
        const { width, height } = await getMathFieldWidthHeightTool();

        if (currentElement?.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA) {
            dispatch(
                updateResourceItem({
                    currentElementId: currentElement.id,
                    pageIndex: currentElement.pageIndex,
                    attrValues: [
                        {
                            attr: "textAttribute",
                            value: {
                                ...currentElement.textAttribute,
                                cmd: new Command({
                                    id: new Date().getTime(),
                                    content: el.latex
                                }),
                            },
                        },
                        {
                            attr: "height",
                            value: height,
                        },
                        {
                            attr: "width",
                            value: width,
                        },
                    ],
                })
            );
        } else {
            const position = getPositionResource(
                width,
                height,
                resourceItems.filter(
                    (e) => e.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA
                ),
                { width: pageWorksheet.width, height: pageWorksheet.height }
            );
            let x = position.x;
            let y = position.y;

            let newItem = new ResourceItemNew({
                id:
                    "math_formula_" +
                    new Date().getTime().toString() +
                    Math.floor(Math.random() * 9999),
                idType:
                    "math_formula_" +
                    new Date().getTime().toString() +
                    Math.floor(Math.random() * 9999),
                width,
                height,
                pageIndex: currentPage,
                type: ConstantsTool.TYPE_RESOURCE_MATH_FORMULA,
                textAttribute: {
                    content: el.latex,
                },
                x,
                y,
            });
            dispatch(
                addResourceItems({
                    resourceItems: [newItem],
                    pageIndex: currentPage,
                })
            );

            dispatch(
                selectElements({
                    resourceSelected: [newItem],
                    pageIndex: currentPage,
                })
            );
            // dispatch(updateIsOnEditText(true));
        }
    };

    return (
        <div className="equation" ref={mainRef}>
            <div className="entry-feature">
                <div className="entry-header">
                    {MATH_FORMULA.map((equation, index) => (
                        <div
                            key={index}
                            className={
                                "current-choice " +
                                (currentTab === equation.eventName
                                    ? "show-all"
                                    : "")
                            }
                            onClick={() => {
                                setCurrentTab(equation.eventName);
                            }}
                        >
                            <div className="shape">
                                <img
                                    src={
                                        getPrefixImage() +
                                        "/images/equation-icon/" +
                                        equation.icon
                                    }
                                ></img>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="drop-down"
                    style={{
                        top: "100%",
                        display: "flex",
                    }}
                >
                    <div className="show-list">
                        {currentData.data.map((el, index) => {
                            return (
                                <div
                                    style={
                                        currentData.eventName !==
                                            "ncw_use_equation_greek_letters"
                                            ? {
                                                padding: "7px",
                                            }
                                            : {}
                                    }
                                    className="option"
                                    onClick={() =>
                                        onClickResource(
                                            el,
                                            currentData.eventName
                                        )
                                    }
                                    key={el.icon}
                                >
                                    <img
                                        className={currentData.eventName}
                                        src={
                                            getPrefixImage() +
                                            "/images/equation-icon/" +
                                            el.icon
                                        }
                                    ></img>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Equation;
