import { IShapeAttribute, ShapeAttribute } from "./shapeAttribute";

export interface IOffset {
    offsetX: number;
    offsetY: number;
    screenWidth: number;
}
export class Offset implements IOffset {
    offsetX: number;
    offsetY: number;
    screenWidth: number;

    constructor(object?: any) {
        this.offsetX = object?.offsetX ?? 0;
        this.offsetY = object?.offsetY ?? 0;
        this.screenWidth = object?.screenWidth ?? 0;
    }
}
export interface IAnswer {
    answerId: number;
    type: string;
    top: number;
    left: number;
    width: number;
    height: number;
    group: number;
    value: string;
    wordSearchValue: string[][];
    isCorrect: boolean;
    page: number;
    color: string;
    fontSize: number;

    userAnswer?: string; //fill_blank, select_box, open_answer, speaking
    isSelected?: boolean; //multi_choice
    matchingId?: number; //drag_drop, join_arrow
    userWordSearch?: number[][]; //word_search
    userWorkSearchResult?: number[][];
    userAnswerCorrect?: boolean;
    point?: IOffset; // join_arrow
    realPoint?: IOffset; //valueForWeb
    background?: string;
    shapeAttribute?: IShapeAttribute;
    opacity?: number;

    transition?: number;
}
export class Answer implements IAnswer {
    answerId: number;
    type: string;
    top: number;
    left: number;
    width: number;
    height: number;
    group: number;
    value: string;
    wordSearchValue: string[][];
    isCorrect: boolean;
    page: number;
    color: string;
    fontSize: number;

    userAnswer?: string; //fill_blank, select_box, open_answer, speaking
    isSelected?: boolean; //multi_choice
    matchingId?: number; //drag_drop, join_arrow
    userWordSearch?: number[][]; //word_search
    userWorkSearchResult?: number[][];
    userAnswerCorrect?: boolean;
    point?: IOffset;
    realPoint?: IOffset; //valueForWeb
    background?: string;
    shapeAttribute?: IShapeAttribute;
    opacity?: number;

    transition?: number;

    constructor(object: any = {}) {
        this.answerId = object.answerId ?? 0;
        this.top = object?.top ?? 0;
        this.left = object?.left ?? 0;
        this.width = object?.width ?? 0;
        this.height = object?.height ?? 0;
        this.group = object?.group ?? 0;
        this.value = object?.value ?? "";
        this.type = object?.type ?? "";
        this.isCorrect = object?.isCorrect ?? false;
        this.wordSearchValue = object?.wordSearchValue ?? null;
        this.page = object?.page ?? 0;
        this.color = object?.color ?? "";
        this.fontSize = object?.fontSize;
        if (
            this.type == ANSWER_TYPE.JOIN ||
            this.type == ANSWER_TYPE.DRAG ||
            this.type == ANSWER_TYPE.DROP
        ) {
            if (!object.group) {
                try {
                    this.group = parseInt(object.value);
                } catch (error) {}
            }
        }
        if (object.userAnswer) {
            this.userAnswer = object.userAnswer;
        }
        this.isSelected = object.isSelected ?? false;
        if (object.matchingId || object.matchingId === 0) {
            this.matchingId = object.matchingId;
        }
        // if (object.userWorkSearch) {
        //
        this.userWordSearch = object.userWordSearch;
        // }
        this.userWorkSearchResult = object.userWorkSearchResult;
        if (object.userAnswerCorrect) {
            this.userAnswerCorrect = object.userAnswerCorrect;
        }
        if (object.point) {
            this.point = object.point;
        }
        if (object.realPoint) {
            this.realPoint = object.realPoint;
        }
        this.background = object.background;
        this.shapeAttribute = object.shapeAttribute;
        this.opacity = object.opacity;
        if (object?.transition) {
            this.transition = object?.transition;
        }
    }
}

export enum ANSWER_TYPE {
    FILL_BLANK = "fill_blank",
    DROP_DOWN = "choose", //drop_down
    SELECT = "select",
    JOIN = "join",
    DROP = "drop",
    DRAG = "drag",
    LISTEN = "listen",
    SPEAK = "speak",
    WORD_SEARCH = "wordsearch",
    WORD_SEARCH_BY_TOOL = "word_search_by_tool",
    INPUT = "input",
    TICK = "tick",
    FILL_SHAPE = "fill_shape",
    FILL_IN_THE_BLANK = "fill_in_the_blank",
    CROSS_WORD = "cross_word",
    LINK = "link",
    VIDEO = "video",
    FILL_LINE = "fill_line",
}
