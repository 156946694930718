export enum PositionInput {
    top = "top",
    bottom = "bottom",
    center = "center",
    above = "above",
    below = "below"
}

export interface IInputShape {
    x: number;
    y: number;
    width: number;
    height: number;
    position: PositionInput;
}

export class InputShape implements IInputShape {
    x: number;
    y: number;
    width: number;
    height: number;
    position: PositionInput;
    constructor(obj?: any) {
        this.x = obj?.x ?? 0;
        this.y = obj?.y ?? 0;
        this.width = obj?.width ?? 40;
        this.height = obj?.height ?? 40;
        this.position = obj?.position ?? PositionInput.top;
    }
}
export interface IShapeAttribute {
    name: string;
    url: string;
    width: number;
    height: number;
    path: string;
    radius: number;
    stroke: string;
    strokeWidth: number;
    strokeDasharray: number;
    input: InputShape;
    rotate: number;
    pathStart?: IPathLine;
    pathEnd?: IPathLine;
}

interface IPathLine {
    path: string;
    fill: "none" | "inherit";
}

class PathLine implements IPathLine {
    path: string;
    fill: "none" | "inherit";
    constructor(obj: any) {
        this.path = obj.path;
        this.fill = obj.fill;
    }
}

export class ShapeAttribute implements IShapeAttribute {
    name: string;
    url: string;
    width: number;
    height: number;
    path: string;
    radius: number;
    stroke: string;
    strokeWidth: number;
    strokeDasharray: number;
    input: InputShape;
    rotate: number;
    pathStart?: IPathLine;
    pathEnd?: IPathLine;

    constructor(obj: any) {
        this.name = obj.name;
        this.url = obj.url;
        this.width = obj.width;
        this.height = obj.height;
        this.path = obj.path;
        this.radius = obj.radius;
        this.stroke = "#000000";
        this.strokeWidth = obj.strokeWidth;
        this.strokeDasharray = obj.strokeDasharray ?? 0;
        this.input = new InputShape(obj.input);
        this.rotate = 0;
        this.pathStart = obj.pathStart && new PathLine(obj.pathStart);
        this.pathEnd = obj.pathEnd && new PathLine(obj.pathEnd);
    }
}
