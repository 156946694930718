import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../redux/hook";
import { updateResourceItems } from "../../../../../redux/reducers/createWorksheet";
import TableView from "../../../../../resource-user/components/common/table";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import {
    IPositionTable,
    PositionTable,
    TableAttribute,
} from "../../../../../shared/models/tableAttribute";
import { getMaxMinNumbers } from "../../../../../utils/align";
import { getMaxHeightRowCell } from "../../../../../utils/table";

function ResourceTable({
    resourceItem,
    currentElement,
    setCurrentElement,
}: {
    resourceItem: IResourceItemNew;
    currentElement?: IResourceItemNew;
    setCurrentElement: any;
}) {
    let table = currentElement?.tableAttribute ?? resourceItem.tableAttribute;
    const [isShiftKey, setisShiftKey] = useState(false);
    const dispatch = useAppDispatch();
    const [focus, setFocus] = useState(false);
    const refInput = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (currentElement)
            setCurrentElement({
                ...currentElement,
                tableAttribute: {
                    ...currentElement.tableAttribute,
                    currentPositions: [],
                },
            });
    }, [currentElement?.id]);

    useEffect(() => {
        if (currentElement) {
            setCurrentElement({
                ...currentElement,
                tableAttribute: {
                    ...currentElement.tableAttribute,
                    data: resourceItem.tableAttribute.data,
                    gap: resourceItem.tableAttribute.gap,
                    currentPositions:
                        resourceItem.tableAttribute.currentPositions,
                },
            });
        }
    }, [
        resourceItem?.tableAttribute.data,
        resourceItem?.tableAttribute.gap,
        resourceItem?.tableAttribute?.borderWidth,
        resourceItem?.tableAttribute?.currentPositions,
    ]);

    useEffect(() => {
        window.addEventListener("keydown", listenKeydown);
        window.addEventListener("keyup", listenKeyup);
        return () => {
            window.removeEventListener("keydown", listenKeydown);
            window.removeEventListener("keyup", listenKeyup);
        };
    });

    const listenKeydown = (e: KeyboardEvent) => {
        if (e.shiftKey) {
            setisShiftKey(true);
        }
    };

    const listenKeyup = (e: KeyboardEvent) => {
        setisShiftKey(false);
    };

    const onClickCell = (position: IPositionTable) => {
        const getNumbers = (a: number, b: number) => {
            let array = [];
            if (a > b)
                for (let i = a; i >= b; i--) {
                    array.push(i);
                }
            if (a < b)
                for (let i = a; i <= b; i++) {
                    array.push(i);
                }
            if (a === b) {
                array = [a];
            }
            return array;
        };
        const getColsRows = (
            colMin: number,
            colMax: number,
            rowMin: number,
            rowMax: number
        ) => {
            let cols = getNumbers(colMin, colMax);
            let rows = getNumbers(rowMin, rowMax);
            rows.forEach((row) => {
                cols.forEach((col) => {
                    let cell = table.data[row][col];
                    if (col + cell.colspan - 1 > colMax) {
                        let value = getColsRows(
                            colMin,
                            col + cell.colspan - 1,
                            rowMin,
                            rowMax
                        );
                        cols = value.cols;
                        rows = value.rows;
                    }
                    if (row + cell.rowspan - 1 > rowMax) {
                        let value = getColsRows(
                            colMin,
                            colMax,
                            rowMin,
                            row + cell.rowspan - 1
                        );
                        cols = value.cols;
                        rows = value.rows;
                    }
                    if (cell.positionParent) {
                        let cellParent =
                            table.data[cell.positionParent.row][
                                cell.positionParent.col
                            ];
                        if (cell.positionParent.col > colMax) {
                            let value = getColsRows(
                                colMin,
                                cell.positionParent.col,
                                rowMin,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (cell.positionParent.col < colMin) {
                            let value = getColsRows(
                                cell.positionParent.col,
                                colMax,
                                rowMin,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (cell.positionParent.row > rowMax) {
                            let value = getColsRows(
                                colMin,
                                colMax,
                                rowMin,
                                cell.positionParent.row
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (cell.positionParent.row < rowMin) {
                            let value = getColsRows(
                                colMin,
                                colMax,
                                cell.positionParent.row,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (
                            cell.positionParent.col + cellParent.colspan - 1 >
                            colMax
                        ) {
                            let value = getColsRows(
                                colMin,
                                cell.positionParent.col +
                                    cellParent.colspan -
                                    1,
                                rowMin,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (
                            cell.positionParent.col + cellParent.colspan - 1 <
                            colMin
                        ) {
                            let value = getColsRows(
                                cell.positionParent.col +
                                    cellParent.colspan -
                                    1,
                                colMax,
                                rowMin,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (
                            cell.positionParent.row + cellParent.rowspan - 1 >
                            rowMax
                        ) {
                            let value = getColsRows(
                                colMin,
                                colMax,
                                rowMin,
                                cell.positionParent.row + cellParent.rowspan - 1
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                        if (
                            cell.positionParent.row + cellParent.rowspan - 1 <
                            rowMin
                        ) {
                            let value = getColsRows(
                                colMin,
                                colMax,
                                cell.positionParent.row +
                                    cellParent.rowspan -
                                    1,
                                rowMax
                            );
                            cols = value.cols;
                            rows = value.rows;
                        }
                    }
                });
            });

            return { cols, rows };
        };
        if (currentElement) {
            let positions: IPositionTable[] = [];
            if (isShiftKey) {
                if (table?.currentPositions?.length) {
                    let firstPosition = table?.currentPositions[0];
                    let colValue = getMaxMinNumbers([
                        firstPosition.column,
                        firstPosition.column + (firstPosition.colspan - 1),
                        position.column,
                        position.column + (position.colspan - 1),
                    ]);
                    let rowValue = getMaxMinNumbers([
                        firstPosition.row,
                        firstPosition.row + (firstPosition.rowspan - 1),
                        position.row,
                        position.row + (position.rowspan - 1),
                    ]);
                    let value = getColsRows(
                        colValue.min,
                        colValue.max,
                        rowValue.min,
                        rowValue.max
                    );
                    let cols = value.cols;
                    let rows = value.rows;
                    let selecteds = [];
                    if (cols.length && rows.length) {
                        rows.forEach((row) => {
                            cols.forEach((col) => {
                                let cell = table.data[row][col];
                                selecteds.push(
                                    new PositionTable({
                                        row: row,
                                        column: col,
                                        rowspan: cell.rowspan,
                                        colspan: cell.colspan,
                                    })
                                );
                            });
                        });
                    }
                    let index = selecteds.findIndex(
                        (e) =>
                            e.row === firstPosition.row &&
                            e.column === firstPosition.column
                    );
                    firstPosition = new PositionTable(firstPosition);
                    if (index >= 0) {
                        selecteds.splice(index, 1);
                    }
                    positions = [firstPosition, ...selecteds];
                } else {
                    positions.push(position);
                }
            } else {
                positions.push(position);
            }
            setCurrentElement({
                ...currentElement,
                tableAttribute: {
                    ...table,
                    currentPositions: positions,
                },
            });
        }
    };
    let currentPositions = currentElement?.tableAttribute?.currentPositions;
    const onInput = ({
        row,
        col,
        width,
        height,
        value,
    }: {
        row: number;
        col: number;
        width: number;
        height: number;
        value: string;
    }) => {
        if (!focus) setFocus(true);
        if (
            currentPositions?.length === 1 &&
            currentPositions[0].column === col &&
            currentPositions[0].row === row
        ) {
            let tableAttribute = new TableAttribute(table);
            let cell = tableAttribute.data[row][col];
            cell.textAttribute.content = value;
            if (!cell.originHeight) {
                cell.originHeight = cell.height;
            }
            let maxHeightRow = getMaxHeightRowCell({ tableAttribute, row });
            let minHeight = cell.originHeight;
            minHeight = Math.max(maxHeightRow, minHeight);
            let dheight = 0;

            let textHeight = refInput.current.scrollHeight;

            if (textHeight > height) {
                dheight = textHeight - height;
            }
            if (textHeight < height) {
                if (cell.height + textHeight - height >= minHeight)
                    dheight = textHeight - height;
                if (cell.height >= minHeight) dheight = minHeight - cell.height;
            }
            tableAttribute.data[row].forEach((cell) => {
                cell.height += dheight;
            });

            dispatch(
                updateResourceItems({
                    pageIndex: resourceItem.pageIndex,
                    resourceItems: [
                        {
                            ...currentElement,
                            tableAttribute,
                            height: currentElement.height + dheight,
                        },
                    ],
                })
            );
            setCurrentElement({
                ...currentElement,
                tableAttribute,
                height: currentElement.height + dheight,
            });
        }
    };
    return (
        <div className="resource-table-element">
            <TableView
                ref={refInput}
                tableAttribute={table}
                onClick={onClickCell}
                positions={currentPositions}
                onInput={onInput}
                onBlur={() => setFocus(false)}
            />
        </div>
    );
}

export default ResourceTable;
