import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/common/loading/loading-component";
import "../index.scss";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import PopupFeedBack from "../../components/popup-feedback";

const Contact = () => {
    const Config_FAQ = "faq";
    const [openPopUp, setOpenPopUp] = useState(false);
    const [action, setAction] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    // const handleChoice = (actionChosen) => {
    //     if (actionChosen === Config_FAQ) {
    //         let prefix = getPrefixByProjectName(projectName);
    //     }
    //     setOpenPopUp(true);
    //     setAction(actionChosen);
    // };

    return (
        <>
            <div className="contact-container">
                <PopupFeedBack />
                {/* <div className="icon" onClick={() => handleChoice("rpBug")}>
                    <div className="rp-bug-icon-container">
                        <img
                            src="https://storage.googleapis.com/worksheetzone/test-upload/1687940595308-13422110.png"
                            className="rp-bug-icon"
                        />
                    </div>
                </div> */}

                {/* <div className="icon" onClick={() => handleChoice(Config_FAQ)}>
                    <div className="icon-text">FAQ</div>
                </div>
                {action === "rpBug" && (
                    <ReportBug
                        handleChoice={handleChoice}
                        openPopUp={openPopUp}
                        setOpenPopUp={setOpenPopUp}
                        setIsLoading={setIsLoading}
                    />
                )}
                {action === Config_FAQ && (
                    <FAQ
                        handleChoice={handleChoice}
                        openPopUp={openPopUp}
                        setOpenPopUp={setOpenPopUp}
                    />
                )} */}
            </div>
            {isLoading && <LoadingComponent usingNewLoading={true} />}
        </>
    );
};

export default Contact;
