import React, { useMemo } from 'react'
import './styles.scss';
import { AlignItemsType, FlexDirectionType, FlexWrapType, ITextAttribute, JustifyContentType } from '../../../../../../../shared/models/resourceItemNew';
import { IResourceItemNew } from '../../../../../../../shared/models/resourceItemNew';
import Form from 'react-bootstrap/Form';

type ContainerStyleType = {
    flexDirection: FlexDirectionType,
    justifyContent: JustifyContentType,
    flexWrap: FlexWrapType,
    alignItems: AlignItemsType,
};

const justifyContentModeOptions = [
    {
        value: 'space-between',
        label: 'space-between',
    },
    {
        value: 'space-around',
        label: 'space-around',
    },
    {
        value: 'flex-start',
        label: 'flex-start',
    },
    {
        value: 'flex-end',
        label: 'flex-end',
    },
    {
        value: 'center',
        label: 'center',
    }
]

const alignItemsModeOptions = [
    {
        value: 'stretch',
        label: 'stretch',
    },
    {
        value: 'flex-start',
        label: 'flex-start',
    },
    {
        value: 'flex-end',
        label: 'flex-end',
    },
    {
        value: 'center',
        label: 'center',
    }
]

const flexWrapModeOptions = [
    {
        value: 'nowrap',
        label: 'nowrap',
    },
    {
        value: 'wrap',
        label: 'wrap',
    },
    {
        value: 'wrap-reverse',
        label: 'wrap-reverse',
    }
]

const flexDirectionOptions = [
    {
        value: 'row',
        label: 'row',
    },
    {
        value: 'row-reverse',
        label: 'row-reverse',
    },
    {
        value: 'column',
        label: 'column',
    },
    {
        value: 'column-reverse',
        label: 'column-reverse',
    },
]

export const defaultStyles: ContainerStyleType = {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
};

const ZoneContainerStylesSetting = ({
    currentElement,
    setCurrentElement,
    changeStyleValue,

}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (value: IResourceItemNew) => void;
    changeStyleValue: (fieldName: string, value: string) => void;
}) => {
    const textAttribute: ITextAttribute = useMemo(() => currentElement?.textAttribute, [currentElement]);

    const handleUpdateContainerStyle = (styleName: string, value: string) => {
        if (!value) return;

        const element = { ...currentElement, textAttribute: { ...currentElement.textAttribute, [styleName]: value } };

        setCurrentElement(element);
        changeStyleValue(styleName, value);
    };

    return (
        <>
            <div
                className="function-button"
                style={{
                    marginLeft: "8px",
                    width: '100px',
                }}
            >
                <Form.Select className="select-styles"
                    onChange={(e) => handleUpdateContainerStyle('justifyContent', e.target.value)}
                    value={textAttribute?.justifyContent || 'space-between'}
                >
                    {justifyContentModeOptions.map(({ label, value }, index) => (
                        <option key={index} value={value}>{label}</option>
                    ))}
                </Form.Select>
                <div className="tooltip-content">
                    <div className="arrow"></div>
                    <span className="text-tooltip">Justify Content</span>
                </div>
            </div>
            <div
                className="function-button"
                style={{
                    marginLeft: "8px",
                    width: '100px',
                }}
            >
                <Form.Select className="select-styles"
                    onChange={(e) => handleUpdateContainerStyle('alignItems', e.target.value)}
                    value={textAttribute?.alignItems || 'center'}
                >
                    {alignItemsModeOptions.map(({ label, value }, index) => (
                        <option key={index} value={value}>{label}</option>
                    ))}
                </Form.Select>
                <div className="tooltip-content">
                    <div className="arrow"></div>
                    <span className="text-tooltip">Align Items</span>
                </div>
            </div>
            <div
                className="function-button"
                style={{
                    marginLeft: "8px",
                    width: '100px',
                }}
            >
                <Form.Select className="select-styles"
                    onChange={(e) => handleUpdateContainerStyle('flexWrap', e.target.value)}
                    value={textAttribute?.flexWrap || 'wrap'}
                >
                    {flexWrapModeOptions.map(({ label, value }, index) => (
                        <option key={index} value={value}>{label}</option>
                    ))}
                </Form.Select>
                <div className="tooltip-content">
                    <div className="arrow"></div>
                    <span className="text-tooltip">Flex Wrap</span>
                </div>
            </div>
            <div
                className="function-button"
                style={{
                    marginLeft: "8px",
                    width: '100px',
                }}
            >
                <Form.Select className="select-styles"
                    onChange={(e) => handleUpdateContainerStyle('flexDirection', e.target.value)}
                    value={textAttribute?.flexDirection || 'column'}
                >
                    {flexDirectionOptions.map(({ label, value }, index) => (
                        <option key={index} value={value}>{label}</option>
                    ))}
                </Form.Select>
                <div className="tooltip-content">
                    <div className="arrow"></div>
                    <span className="text-tooltip">Flex Direction</span>
                </div>
            </div>
        </>
    )
};

export default ZoneContainerStylesSetting;