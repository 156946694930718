// import { jsPDF } from "jspdf";
import Axios from "axios";
import { saveAs } from "file-saver";
import { getTypeImageFromUrl, replaceForLink } from "../../../utils";
import ConstantsResource from "../../../utils/ConstantsResource";
let QR_SIZE = 40;
let DEFAULT_QR_SIZE = 400;
let PADDING_RIGHT_LEFT = 20;
let PADDING_BOTTOM = 20;
let BOTTOM_IMAGE_WIDTH = 167;
export const downloadPdfFromImages = async (
    listUrlAndId: {
        url: string;
        wsId?: string;
        originUrl?: string;
    }[],
    fileName: string,
    mapUrlNameWS?: any,
    dispatch?: any
) => {
    // listUrlAndId = listUrlAndId.slice(
    //     listUrlAndId.length - 1,
    //     listUrlAndId.length
    // );
    const downloadImg = async (url: string) => {
        // return POST({
        //     url: window.location.origin + "/api/download-pdf",
        //     params: {
        //         url,
        //     },
        // });

        return new Promise((resolve, reject) => {
            Axios({
                baseURL: window.location.origin,
                url: "/api/download-pdf",
                method: "POST",
                data: { url },
            })
                .then((response) => {
                    if (
                        response.status ===
                        ConstantsResource.HTTP_REQUEST_SUCCESS
                    ) {
                        resolve(response.data);
                    } else {
                        reject(response.status);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
    let allPromise: Promise<any>[] = listUrlAndId.map((urlAndId) =>
        downloadImg(urlAndId.url)
    );
    allPromise.push(
        downloadImg(window.location.origin + "/images/bottom_logo_print.png")
    );
    let allData = await Promise.all(allPromise);
    let imageBottom = allData[allData.length - 1];
    if (listUrlAndId.length < 50) {
        const jsPDF = (await import("jspdf")).default;
        const QRCode = (await import("easyqrcodejs")).default;
        let doc = new jsPDF("p", "pt", "a4");
        doc.deletePage(1);
        let sum = 0;
        for (let i = 0; i < listUrlAndId.length; i++) {
            let base64QrCode;
            let isFromLiveWs = true;
            if (listUrlAndId[i]?.wsId) {
                let options = {
                    text:
                        ConstantsResource.DOMAIN +
                        "redirect-ws-" +
                        listUrlAndId[i].wsId +
                        "?utm_source=downloaded_worksheet",
                    width: DEFAULT_QR_SIZE,
                    height: DEFAULT_QR_SIZE,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                };

                let qr_code: any = new QRCode(
                    document.getElementById("qr_code"),
                    options
                );
                base64QrCode =
                    qr_code._oDrawing._elCanvas.toDataURL("image/png");
                qr_code.clear();
            }
            let imgDownload = document.createElement("img");
            let type = getTypeImageFromUrl(listUrlAndId[i]?.url);
            imgDownload.src = "data:image/" + type + ";base64, " + allData[i];
            imgDownload.onload = function () {
                imgDownload.style.height = "200px";
                imgDownload.style.visibility = "hidden";
                document.body.appendChild(imgDownload);
                let naturalWidth = imgDownload.naturalWidth;
                let naturalHeight = imgDownload.naturalHeight;
                let size = getSizeOfPage(naturalWidth, naturalHeight);
                let width = size.width;
                let height = size.height;
                doc.addPage(
                    [width, height],
                    getPageType(naturalWidth, naturalHeight)
                );
                doc.addImage(
                    allData[i],
                    "JPEG",
                    0,
                    0,
                    width,
                    height - (base64QrCode ? QR_SIZE + 10 : 0),
                    "alias" + i
                );
                if (base64QrCode) {
                    doc.addImage(
                        base64QrCode,
                        "PNG",
                        isFromLiveWs
                            ? PADDING_RIGHT_LEFT
                            : width - QR_SIZE - PADDING_RIGHT_LEFT,
                        height - QR_SIZE - PADDING_BOTTOM,
                        QR_SIZE,
                        QR_SIZE,
                        "alias-qr" + i
                    );
                    doc.addImage(
                        imageBottom,
                        "PNG",
                        isFromLiveWs
                            ? PADDING_RIGHT_LEFT + QR_SIZE
                            : width -
                                  QR_SIZE -
                                  PADDING_RIGHT_LEFT -
                                  BOTTOM_IMAGE_WIDTH,
                        height - QR_SIZE - PADDING_BOTTOM,
                        BOTTOM_IMAGE_WIDTH,
                        QR_SIZE,
                        "alias-image-bottom" + i
                    );
                }
                sum += 1;
                if (sum === allData.length - 1) {
                    if (!fileName.endsWith(".pdf")) {
                        fileName += ".pdf";
                    }
                    doc.save(fileName);
                }
            };
        }
    } else {
        const JSZip = (await import("jszip")).default;
        const zip = new JSZip();
        const img = zip.folder("worksheet-images");
        for (let i = 0; i < listUrlAndId.length; i++) {
            let array = listUrlAndId[i].url.split("/");
            let name = array[array.length - 1];
            if (!!mapUrlNameWS) {
                let wsName = mapUrlNameWS[listUrlAndId[i].url];
                name = replaceForLink(wsName) + "-" + name;
            }
            img.file(name, allData[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, fileName + ".zip");
        });
    }
};

const getPageType = (originWidth: number, originHeight: number) => {
    if (originWidth <= originHeight) {
        return "p";
    } else {
        return "l";
    }
};

const getSizeOfPage = (originWidth: number, originHeight: number) => {
    if (originWidth <= originHeight) {
        return {
            width: 595,
            height: (originHeight * 595) / originWidth + QR_SIZE,
        };
    } else {
        return {
            width: 842,
            height: (originHeight * 842) / originWidth + QR_SIZE,
        };
    }
};
