import ClassroomConfig from "../utils/classroom";
import { IUserInfo } from "./userInfo";

export interface IUserClass {
    id: string;
    userId: string | IUserInfo;
    classId: string;
    createdAt: string;
    updatedAt: string;
    role: number;
    name: string;
    code: string;
    status: number;
    joinDate: string;
}
export class UserClass implements IUserClass {
    id: string;
    userId: string | IUserInfo;
    classId: string;
    createdAt: string;
    updatedAt: string;
    role: number;
    name: string;
    code: string;
    status: number;
    joinDate: string;
    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.userId = object.userId ?? "";
        this.classId = object.classId ?? "";
        this.createdAt = object.createdAt ?? "";
        this.updatedAt = object.updatedAt ?? "";
        this.role = object.role ?? ClassroomConfig.ROLE_STUDENT;
        this.name = object.name ?? "";
        this.code = object.code ?? "";
        this.status = object.status ?? ClassroomConfig.USER_NOT_JOINED;
        this.joinDate = object.joinDate ?? "";
    }
}
