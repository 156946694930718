import { useDispatch, useSelector } from "react-redux";
import { IPosition, IWord } from "../../../shared/models/crossword";
import { IResourceItemNew } from "../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import {
    updateCurrentConfigAction,
    updateUserAnswerAction,
} from "../../redux/action/crossword.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import { genDisplay } from "../../utils";
import "./index.scss";
import { useState } from "react";

const MainCrossword = ({
    resourceItem,
    ratio,
    isPlay,
}: {
    resourceItem: IResourceItemNew;
    ratio?: number;
    isPlay?: boolean;
}) => {
    const words = useSelector((state: ResourceAppState) =>
        state.crosswordState.words.filter((word) => word.onGrid)
    );
    const grid = useSelector(
        (state: ResourceAppState) => state.crosswordState.grid
    );
    const currentDirection = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentDirection
    );
    const currentWordSelected = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentWordSelected
    );
    const currentPosition = useSelector(
        (state: ResourceAppState) => state.crosswordState.currentPosition
    );
    const layout = resourceItem.layout;
    const isEmpty = words.length === 0;
    const DEFAULT_CELL_PX = 34;

    const getSizeOfBox = () => {
        let gridParentContainer = document.querySelector(
            "#container_" + resourceItem.idType
        );
        if (!isPlay) {
            gridParentContainer = gridParentContainer?.parentElement;
        }
        const numRow = grid?.length;
        const numColumn = grid?.[0]?.length ?? 0;
        const PADDING = 40;
        let cellPx;

        if (gridParentContainer) {
            const width = gridParentContainer.clientWidth;
            cellPx = (width - PADDING) / numColumn;

            const maxHeight = [
                ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT,
                ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT,
            ].includes(layout)
                ? ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD_WITH_IMAGE
                : ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD;
            if (cellPx * numRow >= maxHeight - PADDING) {
                cellPx = (maxHeight - PADDING) / numRow;
            }
        }

        return !cellPx || cellPx > DEFAULT_CELL_PX ? DEFAULT_CELL_PX : cellPx;
    };

    const cellPx = getSizeOfBox();

    const isFocus = (indexRow: number, indexCol: number) => {
        if (!currentPosition) return false;

        const { row, column } = currentPosition;
        return row === indexRow && column === indexCol;
    };

    return (
        <div
            className="crossword_gid_container"
            id={"container_" + resourceItem.idType}
        >
            <table
                id="crossword_grid_table"
                cellSpacing="0"
                cellPadding="0"
                className="unselectable puzzle_font_size_small puzzle_font_times_new_roman puzzle_letter_spacing_spacious"
                style={{
                    fontSize: cellPx * 0.5 + "px",
                    transform: `scale(${
                        isPlay && cellPx === DEFAULT_CELL_PX ? ratio : 1
                    })`,
                }}
            >
                <tbody>
                    {grid?.map((row, indexRow) => {
                        return (
                            <tr
                                id={"grid_row" + indexRow}
                                className="grid_row"
                                key={indexRow}
                            >
                                {row.map((col, indexCol) => {
                                    let currentWord: IWord | null;
                                    let index = -1;
                                    let hasValue = col.length > 0;
                                    let isCurrentWordSelected = false;
                                    if (hasValue) {
                                        const wordsOnPosition = words.filter(
                                            (word) => {
                                                const position = word?.position;
                                                if (position) {
                                                    const {
                                                        row: minRow,
                                                        column: minColumn,
                                                    } = position;
                                                    const wordLength =
                                                        word.value.length;
                                                    let maxRow = minRow;
                                                    let maxColumn = minColumn;
                                                    if (
                                                        word.direction ===
                                                        ConstantsTool.DIRECTIONS
                                                            .ACROSS
                                                    ) {
                                                        maxColumn +=
                                                            wordLength - 1;
                                                    } else {
                                                        maxRow +=
                                                            wordLength - 1;
                                                    }

                                                    if (
                                                        minRow <= indexRow &&
                                                        indexRow <= maxRow &&
                                                        minColumn <= indexCol &&
                                                        indexCol <= maxColumn
                                                    ) {
                                                        return true;
                                                    }
                                                }

                                                return false;
                                            }
                                        );
                                        if (wordsOnPosition.length > 0) {
                                            if (wordsOnPosition.length === 2) {
                                                wordsOnPosition.forEach(
                                                    (word) => {
                                                        if (
                                                            word.direction ===
                                                            currentDirection
                                                        ) {
                                                            currentWord = word;
                                                        }

                                                        const position =
                                                            word.position;
                                                        if (
                                                            position.row ===
                                                                indexRow &&
                                                            position.column ===
                                                                indexCol
                                                        ) {
                                                            index =
                                                                word.indexPrefix;
                                                        }
                                                    }
                                                );
                                            } else if (
                                                wordsOnPosition.length === 1
                                            ) {
                                                currentWord =
                                                    wordsOnPosition[0];
                                                const position =
                                                    wordsOnPosition[0].position;
                                                if (
                                                    position.row === indexRow &&
                                                    position.column === indexCol
                                                ) {
                                                    index =
                                                        wordsOnPosition[0]
                                                            .indexPrefix;
                                                }
                                            }

                                            if (currentWordSelected) {
                                                isCurrentWordSelected =
                                                    currentWord?.index ===
                                                    currentWordSelected?.index;
                                            }
                                        }
                                    }

                                    return (
                                        <td
                                            id={
                                                "grid_cell" +
                                                indexRow +
                                                "-" +
                                                indexCol
                                            }
                                            style={{
                                                padding: "0px",
                                            }}
                                            className={
                                                "grid_cell " +
                                                (hasValue
                                                    ? " cell_value "
                                                    : "") +
                                                (isFocus(indexRow, indexCol)
                                                    ? " focus "
                                                    : "") +
                                                (isCurrentWordSelected
                                                    ? " word_selected "
                                                    : "")
                                            }
                                            key={
                                                indexRow +
                                                "-" +
                                                indexCol +
                                                "-" +
                                                col
                                            }
                                        >
                                            <div
                                                id={
                                                    "cell_char" +
                                                    indexRow +
                                                    "-" +
                                                    indexCol
                                                }
                                                style={{
                                                    width: cellPx + "px",
                                                    height: cellPx + "px",
                                                    padding: "0px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                                className={
                                                    "cell_char filled_cell_char "
                                                }
                                            >
                                                {hasValue && (
                                                    // (isEmpty ? (
                                                    //     <></>
                                                    // ) : (
                                                    // ))}
                                                    <>
                                                        <div
                                                            className="index"
                                                            style={{
                                                                fontSize:
                                                                    cellPx *
                                                                        0.33 +
                                                                    "px",
                                                            }}
                                                        >
                                                            {index > -1
                                                                ? index
                                                                : ""}
                                                        </div>
                                                        <Input
                                                            currentWord={
                                                                currentWord
                                                            }
                                                            position={{
                                                                row: indexRow,
                                                                column: indexCol,
                                                            }}
                                                            correctLetter={col}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const Input = ({
    currentWord,
    position,
    correctLetter,
}: {
    correctLetter: string;
    currentWord: IWord | null;
    position: IPosition;
}) => {
    const [temp, setTemp] = useState("");
    const dispatch = useDispatch();
    const showAnswerKey = useSelector(
        (state: ResourceAppState) => state.crosswordState.showAnswerKey
    );
    const { row, column } = position;
    const userAnswerValue = currentWord?.userAnswer;
    const getCurrentIndex = () => {
        if (currentWord) {
            const { row: startRow, column: startColumn } = currentWord.position;
            const index = row + column - startRow - startColumn;

            return index;
        }
        return -1;
    };
    const value =
        userAnswerValue?.[getCurrentIndex()] ?? ConstantsTool.EMPTY_LETTER;
    let inputValue = value === ConstantsTool.EMPTY_LETTER ? "" : value;
    if (!currentWord) {
        inputValue = temp;
    }

    const handleChangeText = (key: string) => {
        const isBackSpaceKey = key === "Backspace";
        let newPosition = position;
        let letter = key.toUpperCase();

        if (letter === value) {
            // Same value
            return;
        }
        if (isBackSpaceKey) {
            letter = ConstantsTool.EMPTY_LETTER;

            if (value === ConstantsTool.EMPTY_LETTER) {
                const wordPosition = currentWord?.position;
                const direction = currentWord?.direction;

                if (
                    wordPosition?.column !== column ||
                    wordPosition?.row !== row
                ) {
                    let newRow = row;
                    let newColumn = column;

                    if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                        newColumn = column - 1;
                    } else {
                        newRow = row - 1;
                    }
                    newPosition = {
                        row: newRow,
                        column: newColumn,
                    };
                }
            }
        }
        if (!currentWord) {
            setTemp(letter);
        }
        dispatch(
            updateUserAnswerAction({
                position: newPosition,
                value: letter,
            })
        );
    };

    const handleBlurInput = () => {
        dispatch(
            updateCurrentConfigAction({
                direction: ConstantsTool.DIRECTIONS.ACROSS,
                wordSelected: null,
                position: null,
            })
        );
    };

    const handleMove = ({
        e,
        moveToNextLetter = false,
        moveToPreviousLetter = false,
    }: {
        e?;
        moveToNextLetter?: boolean;
        moveToPreviousLetter?: boolean;
    }) => {
        if (!currentWord) return false;

        const key = e?.key;
        const direction = currentWord.direction;
        let nextRow = row;
        let nextColumn = column;
        let id: string;

        if (key === "Enter" || moveToNextLetter) {
            const { row, column } = currentWord.position;
            const wordLength = currentWord.value.length;
            if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                if (column + wordLength - 1 >= nextColumn + 1) {
                    nextColumn += 1;
                }
            } else {
                if (row + wordLength - 1 >= nextRow + 1) {
                    nextRow += 1;
                }
            }
            id = `crossword-${nextRow}-${nextColumn}`;
        } else if (
            ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(key)
        ) {
            const move = {
                ArrowDown: [1, 0],
                ArrowUp: [-1, 0],
                ArrowLeft: [0, -1],
                ArrowRight: [0, 1],
            };
            const positionNumber = move[key];

            nextRow += positionNumber[0];
            nextColumn += positionNumber[1];
            id = `crossword-${nextRow}-${nextColumn}`;
        } else if (moveToPreviousLetter) {
            const { row, column } = currentWord.position;
            if (direction === ConstantsTool.DIRECTIONS.ACROSS) {
                if (column <= nextColumn - 1) {
                    nextColumn -= 1;
                }
            } else {
                if (row <= nextRow - 1) {
                    nextRow -= 1;
                }
            }
            id = `crossword-${nextRow}-${nextColumn}`;
        }

        if (id?.length > 0) {
            const nextInput: HTMLInputElement = document.querySelector(
                "#" + id
            );

            if (nextInput) {
                nextInput.focus({ preventScroll: true });
                return true;
            }
        }
        return false;
    };

    const handleFocusInput = () => {
        dispatch(
            updateCurrentConfigAction({
                direction: currentWord?.direction,
                wordSelected: currentWord,
                position,
            })
        );
    };

    const handleKeyDown = (e) => {
        const key = e.key;
        const isBackSpaceKey = key === "Backspace";

        if (/^[a-zA-Z]$/.test(key) || isBackSpaceKey) {
            handleChangeText(key);

            const isEmptyLetter = value === ConstantsTool.EMPTY_LETTER;
            handleMove({
                moveToNextLetter: !isBackSpaceKey,
                moveToPreviousLetter: isEmptyLetter && isBackSpaceKey,
            });
        } else {
            handleMove({ e });
        }
    };

    return (
        <>
            <input
                id={showAnswerKey ? `crossword-${row}-${column}` : ""}
                onFocus={handleFocusInput}
                onBlur={handleBlurInput}
                className="crossword_value"
                style={{
                    display: genDisplay(showAnswerKey),
                }}
                defaultValue={correctLetter}
                readOnly
                autoComplete="off"
            />
            <input
                id={!showAnswerKey ? `crossword-${row}-${column}` : ""}
                className="crossword_user_type"
                type="text"
                value={inputValue}
                onBlur={handleBlurInput}
                onKeyDownCapture={handleKeyDown}
                onFocus={handleFocusInput}
                onChange={() => {}}
                style={{
                    display: genDisplay(!showAnswerKey),
                }}
                autoComplete="off"
            />
        </>
    );
};

export default MainCrossword;
