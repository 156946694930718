import { Answer, IAnswer, IOffset } from "../../../shared/models/answer";
import { IGame, IScreenSize } from "../../../shared/models/game";
import {
    ICoords,
    IDrawData,
    IPracticeWorksheet,
} from "../../../shared/models/practiceWorksheets";
import { IUserAssignment } from "../../../shared/models/userAssignment";
import { IUserInfo } from "../../../shared/models/userInfo";
import { IWorksheet } from "../../../shared/models/worksheet";

export enum GameTypes {
    INIT_PLAY_WS_MODULE = "INIT_PLAY_WS_MODULE",
    GAME_INIT_GAME = "GAME_INIT_GAME",
    GAME_INIT_GAME_SUCCESS = "GAME_INIT_GAME_SUCCESS",
    GET_GAME_INFO = "GET_GAME_INFO",
    GET_GAME_INFO_SUCCESS = "GET_GAME_INFO_SUCCESS",
    GET_GAME_INFO_FAILED = "GET_GAME_INFO_FAILED",
    UPDATE_ANSWER = "UPDATE_ANSWER",
    UPDATE_ANSWER_FOCUS = "UPDATE_ANSWER_FOCUS",

    UPDATE_USER_ANSWER = "UPDATE_USER_ANSWER",
    SUBMIT_ANSWER = "SUBMIT_ANSWER",
    IS_FINISH = "IS_FINISH",
    UPDATE_MATCHINGid_AND_POINTS = "UPDATE_MATCHINGid_AND_POINTS",
    REMOVE_MATCHINGid_AND_POINTS = "REMOVE_MATCHINGid_AND_POINTS",
    REMOVE_ALL_MATCHINGid_AND_POINTS = "REMOVE_ALL_MATCHINGid_AND_POINTS",
    FINISH_GAME_SUCCESS = "FINISH_GAME_SUCCESS",
    RESET_GAME = "RESET_GAME",
    RESET_GAME_SUCCESS = "RESET_GAME_SUCCESS",
    UPDATE_USER_DOWNLOADED_WORKSHEET = "UPDATE_USER_DOWNLOADED_WORKSHEET",
    UPDATE_USER_PRACTICE_WORKSHEET_RESOURCE = "UPDATE_USER_PRACTICE_WORKSHEET_RESOURCE",
    UPDATE_USER_PRACTICE_WORKSHEET_V2 = "UPDATE_USER_PRACTICE_WORKSHEET_V2",

    UPDATE_DISPLAY_POPUP_STATUS = "UPDATE_DISPLAY_POPUP_STATUS",
    PLAY_WORKSHEET = "PLAY_WORKSHEET",
    PAINTING = "PAINTING",
    ERASING = "ERASING",
    SET_DATA_DRAW = "SET_DATA_DRAW",
    SET_SCREEN_SIZE_DRAW = "SET_SCREEN_SIZE_DRAW",
    UNDO_DATA_DRAW = "UNDO_DATA_DRAW",
    REDO_DATA_DRAW = "REDO_DATA_DRAW",

    USER_SEND_RESULT_FOR_TEACHER = "USER_SEND_RESULT_FOR_TEACHER",
    SHOW_ANSWER = "SHOW_ANSWER",

    SET_LOADING_GAME_IMAGE = "SET_LOADING_GAME_IMAGE",
    SET_START_ANSWER = "SET_START_ANSWER",

    SET_ASSIGNMENTid = "SET_ASSIGNMENTid",
    SET_IS_DISPLAY_MODAL_INTERACTIVE = "SET_IS_DISPLAY_MODAL_INTERACTIVE",
    UPDATE_DRAW_OPTION = "UPDATE_DRAW_OPTION",
    UPDATE_IS_DISPLAY_INSTRUCTION = "UPDATE_IS_DISPLAY_INSTRUCTION",
    UPDATE_USER_ASSIGNMENT = "UPDATE_USER_ASSIGNMENT",
    UPDATE_DRAW_BY_CANVAS_RATIO = "UPDATE_DRAW_BY_CANVAS_RATIO",
    UPDATE_TEXT_VALUE = "UPDATE_TEXT_VALUE",
    UPDATE_SELECTED_TEXT_INDEX = "UPDATE_SELECTED_TEXT_INDEX",
    UPDATE_SELECTED_LINE_INDEX = "UPDATE_SELECTED_LINE_INDEX",
    DELETE_TEXT_DRAW = "DELETE_TEXT_DRAW",
    DELETE_LINE_DRAW = "DELETE_LINE_DRAW",
    UPDATE_LINE_DRAW = "UPDATE_LINE_DRAW",
    UPDATE_IS_ON_EDIT_TEXT = "UPDATE_IS_ON_EDIT_TEXT",
    UPDATE_SUBMITED_STATUS = "UPDATE_SUBMITED_STATUS",
    UPDATE_CURRENT_PAGE_INDEX = "UPDATE_CURRENT_PAGE_INDEX",
    CREATE_ANSWER_FROM_RESOURCE_ITEM = "CREATE_ANSWER_FROM_RESOURCE_ITEM",

    INIT_THEMES = "INIT_THEMES",
    SELECT_THEME_ACTION = "SELECT_THEME_ACTION",
    UPDATE_CURRENT_SHAPE_ANSWER = "UPDATE_CURRENT_SHAPE_ANSWER",
}
export interface GameAction {
    type: GameTypes;
    worksheet?: IWorksheet;
    practiceWorksheet?: IPracticeWorksheet;
    workSheetId?: string;
    answers?: IAnswer[];
    game?: IGame;
    error?: any;
    answer?: IAnswer;
    userAnswer?: string;
    isFinish?: any;
    listAnswerId?: string[];
    listPoints?: IOffset[];
    answerId?: number;
    score?: number;
    totalDone?: number;
    token?: string;
    isDisplayPinterestPopup?: boolean;
    workSheetIdList?: string[];
    isPlayWorksheet?: boolean;
    drawData?: IDrawData;
    dataDraw?: IDrawData;

    studentId?: string;
    coords?: {
        color: string;
        strokeWidth: number;
        type: string;
        moveX: number;
        moveY: number;
        x: number;
        y: number;
    };
    coordsArr?: any[];
    screenSize?: IScreenSize;
    coordsDraw?: ICoords[];
    ratio?: number;
    coordsDrawHistory?: ICoords[];
    resetAll?: boolean;
    showAnswer?: boolean;
    loadingGameImage?: boolean;
    startAnswer?: boolean;
    assignmentId?: string;
    userInfo?: IUserInfo;
    role?: number;
    studentClassroomId?: string;
    isDisplayModalInteractive?: boolean;
    drawOption?: any;
    isDisplayInstruction?: boolean;
    playWorksheetInfo?: {
        workSheetId?: string;
        projectName?: string;
        classId?: string;
        studentId?: string;
        role?: number;
    };
    keepWorksheet?: boolean;
    practiceWorksheetId?: string;
    userAssignmentOfStudent?: IUserAssignment;
    worksheetData?: any;
    textData?: string;
    index?: number;
    isOnEditText?: boolean;
    isSubmited?: boolean;
    userPracticeId?: string;
    isSubmitedToTeacher?: boolean;
    currentIndex?: number;
    currentShapeIndex?: number;
}
export const updateUserAssignmentAction = ({
    userAssignmentOfStudent,
}: {
    userAssignmentOfStudent: IUserAssignment;
}) => {
    return {
        type: GameTypes.UPDATE_USER_ASSIGNMENT,
        userAssignmentOfStudent,
    };
};
export const initPlayWSModuleAction = ({
    workSheetId,
    worksheetData = "",
    userInfo = null,
    studentClassroomId = "",
    keepWorksheet = false,
    userPracticeId = "",
}) => {
    return {
        type: GameTypes.INIT_PLAY_WS_MODULE,
        workSheetId,
        worksheetData,
        userInfo,
        studentClassroomId,
        keepWorksheet,
        userPracticeId,
    };
};

export const initGameAction = (
    workSheetId: string,
    worksheet: IWorksheet,
    practiceWorksheet: IPracticeWorksheet,
    isFinish?: boolean
) => {
    return {
        type: GameTypes.GAME_INIT_GAME,
        workSheetId,
        worksheet,
        practiceWorksheet,
        isFinish,
    };
};

export const initGameSuccessAction = ({
    worksheet,
    answers,
    isFinish,
    score,
    practiceWorksheet,
    dataDraw,
    startAnswer,
    assignmentId,
}: {
    worksheet: IWorksheet;
    answers: IAnswer[];
    isFinish?: boolean;
    score?: number;
    practiceWorksheet?: IPracticeWorksheet;
    dataDraw?: IDrawData;
    startAnswer?: boolean;
    assignmentId?: string;
}): GameAction => {
    return {
        type: GameTypes.GAME_INIT_GAME_SUCCESS,
        worksheet,
        answers,
        isFinish,
        score,
        practiceWorksheet,
        dataDraw,
        startAnswer,
        assignmentId,
    };
};

export const getGameInfo = (): GameAction => {
    return { type: GameTypes.GET_GAME_INFO };
};
export const getGameInfoSuccess = (game: IGame): GameAction => {
    return { type: GameTypes.GET_GAME_INFO_SUCCESS, game };
};
export const getGameInfoFailed = (error: any): GameAction => {
    return { type: GameTypes.GET_GAME_INFO_FAILED, error };
};
export const updateUserAnswerAction = (
    answerId: number,
    userAnswer: string
) => {
    return {
        type: GameTypes.UPDATE_USER_ANSWER,
        answerId,
        userAnswer,
    };
};
export const updateUserAnswer = (answer: IAnswer) => {
    return { type: GameTypes.UPDATE_ANSWER, answer };
};
export const updateUserAnswerFocus = (answer: IAnswer) => {
    return { type: GameTypes.UPDATE_ANSWER_FOCUS, answer };
};

export const submitAnswer = (
    answers: IAnswer[],
    role?: number,
    isSubmitedToTeacher?: boolean
) => {
    return {
        type: GameTypes.SUBMIT_ANSWER,
        answers,
        role,
        isSubmitedToTeacher,
    };
};

export const finishGame = (
    answers: IAnswer[],
    isFinish: boolean
): GameAction => {
    return { type: GameTypes.IS_FINISH, answers, isFinish };
};
export const updateMatchingIdAndPointsAction = (
    listAnswerId: string[],
    listPoints: IOffset[],
    ratio: number
) => {
    return {
        type: GameTypes.UPDATE_MATCHINGid_AND_POINTS,
        listAnswerId,
        listPoints,
        ratio,
    };
};
export const finishGameSuccessAction = (
    answers: IAnswer[],
    totalDone: number,
    score: number
): GameAction => {
    return { type: GameTypes.FINISH_GAME_SUCCESS, totalDone, score, answers };
};

export const resetGameAction = (
    workSheetId?: string,
    worksheet?: IWorksheet,
    resetAll?: boolean
): GameAction => {
    return { type: GameTypes.RESET_GAME, worksheet, workSheetId, resetAll };
};
export const resetGameSuccessAction = () => {
    return {
        type: GameTypes.RESET_GAME_SUCCESS,
    };
};

export const updateUserDownloadedWorksheetAction = (
    token: string,
    workSheetIdList: string[]
): GameAction => {
    return {
        type: GameTypes.UPDATE_USER_DOWNLOADED_WORKSHEET,
        token,
        workSheetIdList,
    };
};

export const updateUserPracticeWorksheetAction = (
    // token: string,
    // workSheetId: string,
    // drawData: IDrawData,
    // score: number,
    // answers: IAnswer[],
    // isFinish: boolean
    {
        workSheetId,
        drawData,
        score,
        isFinish,
        worksheet,
        isSubmited,
    }: {
        workSheetId?: string;
        drawData?: IDrawData;
        score?: number;
        isFinish?: boolean;
        worksheet?: IWorksheet;
        isSubmited?: boolean;
    }
): GameAction => {
    return {
        type: GameTypes.UPDATE_USER_PRACTICE_WORKSHEET_RESOURCE,
        workSheetId,
        drawData,
        score,
        isFinish,
        worksheet,
        isSubmited,
    };
};

export const updateUserPracticeWorksheetV2Action = (
    practiceWorksheet: IPracticeWorksheet
): GameAction => {
    return {
        type: GameTypes.UPDATE_USER_PRACTICE_WORKSHEET_V2,
        practiceWorksheet,
    };
};

export const updateDisplayPopupStatusAction = (
    isDisplayPinterestPopup: boolean
): GameAction => {
    return {
        type: GameTypes.UPDATE_DISPLAY_POPUP_STATUS,
        isDisplayPinterestPopup,
    };
};

export const setPlayWorksheetAction = (
    isPlayWorksheet: boolean
): GameAction => {
    return {
        type: GameTypes.PLAY_WORKSHEET,
        isPlayWorksheet,
    };
};

export const setPaintingAction = (coordsArr: any[]): GameAction => {
    return {
        type: GameTypes.PAINTING,
        coordsArr,
    };
};

export const setDataDrawAction = ({
    coordsDraw,
    coordsDrawHistory,
}: {
    coordsDraw: ICoords[];
    coordsDrawHistory: ICoords[];
}): GameAction => {
    return {
        type: GameTypes.SET_DATA_DRAW,
        coordsDraw,
        coordsDrawHistory,
    };
};

export const setScreenSizeDrawAction = (
    screenSize: IScreenSize
): GameAction => {
    return {
        type: GameTypes.SET_SCREEN_SIZE_DRAW,
        screenSize,
    };
};

export const undoDataDrawAction = (): GameAction => {
    return {
        type: GameTypes.UNDO_DATA_DRAW,
    };
};

export const redoDataDrawAction = (): GameAction => {
    return {
        type: GameTypes.REDO_DATA_DRAW,
    };
};

export const userSendResultForTeacherAction = (
    practiceWorksheet: IPracticeWorksheet
): GameAction => {
    return {
        type: GameTypes.USER_SEND_RESULT_FOR_TEACHER,
        practiceWorksheet,
    };
};

export const showAnswerAction = (showAnswer: boolean): GameAction => {
    return {
        type: GameTypes.SHOW_ANSWER,
        showAnswer,
    };
};

export const setLoadingGameImageAction = (
    loadingGameImage: boolean
): GameAction => {
    return {
        type: GameTypes.SET_LOADING_GAME_IMAGE,
        loadingGameImage,
    };
};

export const setStartAnswerAction = (startAnswer: boolean): GameAction => {
    return {
        type: GameTypes.SET_START_ANSWER,
        startAnswer,
    };
};

export const setAssignmentIdAction = (assignmentId: string): GameAction => {
    return {
        type: GameTypes.SET_ASSIGNMENTid,
        assignmentId,
    };
};

export const setIsDisplayModalInteractive = (
    isDisplayModalInteractive: boolean,
    playWorksheetInfo?: any
): GameAction => {
    return {
        type: GameTypes.SET_IS_DISPLAY_MODAL_INTERACTIVE,
        isDisplayModalInteractive,
        playWorksheetInfo,
    };
};

export const updateDrawOptionAction = (drawOption: any): GameAction => {
    return {
        type: GameTypes.UPDATE_DRAW_OPTION,
        drawOption,
    };
};

export const updateIsDisplayInstructionAction = (
    isDisplayInstruction: boolean
): GameAction => {
    return {
        type: GameTypes.UPDATE_IS_DISPLAY_INSTRUCTION,
        isDisplayInstruction,
    };
};

export const updateDrawByCanvasRatio = (ratio: number) => {
    return {
        type: GameTypes.UPDATE_DRAW_BY_CANVAS_RATIO,
        ratio,
    };
};

export const updateTextDrawAction = (index: number, textData: any) => {
    return { type: GameTypes.UPDATE_TEXT_VALUE, index, textData };
};

export const updateSelectedTextIndexAction = (index: number) => {
    return { type: GameTypes.UPDATE_SELECTED_TEXT_INDEX, index };
};

export const deleteTextDrawAction = (currentIndex?: number) => {
    return { type: GameTypes.DELETE_TEXT_DRAW, currentIndex };
};

export const updateIsOnEditTextAction = (isOnEditText: boolean) => {
    return { type: GameTypes.UPDATE_IS_ON_EDIT_TEXT, isOnEditText };
};

export const updateSelectedLineIndexAction = (index: number) => {
    return { type: GameTypes.UPDATE_SELECTED_LINE_INDEX, index };
};

export const deleteLineDrawAction = () => {
    return { type: GameTypes.DELETE_LINE_DRAW };
};

export const updateLineDrawAction = (lineData: any) => {
    return { type: GameTypes.UPDATE_LINE_DRAW, lineData };
};

export const updateSubmitedStatusAction = (isSubmited: boolean) => {
    return { type: GameTypes.UPDATE_SUBMITED_STATUS, isSubmited };
};
export const updateCurrentPageIndexAction = (index: number) => {
    return { type: GameTypes.UPDATE_CURRENT_PAGE_INDEX, index };
};

export const createNewAnswerFromResourceItem = (answer: IAnswer) => {
    return { type: GameTypes.CREATE_ANSWER_FROM_RESOURCE_ITEM, answer };
};

export const removeMatchingIdAndPointsAction = (answerIds: string[]) => {
    return {
        type: GameTypes.REMOVE_MATCHINGid_AND_POINTS,
        answerIds,
    };
};

export const removeDrawLineGameJoin = () => {
    return {
        type: GameTypes.REMOVE_ALL_MATCHINGid_AND_POINTS,
    };
};

export const setInitThemesAction = ({
    listTheme,
    indexSelected,
}: {
    listTheme: string[];
    indexSelected?: number;
}) => {
    return {
        type: GameTypes.INIT_THEMES,
        listTheme,
        indexSelected,
    };
};

export const setActiveThemeAction = ({
    indexSelected,
}: {
    indexSelected: number;
}) => {
    return {
        type: GameTypes.SELECT_THEME_ACTION,
        indexSelected,
    };
};

export const updateCurrentShapeIndex = ({
    currentShapeAnswer,
}: {
    currentShapeAnswer: Answer;
}) => {
    return {
        type: GameTypes.UPDATE_CURRENT_SHAPE_ANSWER,
        currentShapeAnswer,
    };
};
