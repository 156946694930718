export const EditTitleIcon = ({ focus = false }: { focus?: boolean }) => {
    const color = focus ? "#89898A" : "#D1D5DB";
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M5.83301 10.8333H9.99967"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83301 14.1667H13.333"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.667 1.66669H7.50033C3.33366 1.66669 1.66699 3.33335 1.66699 7.50002V12.5C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5V8.33335"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9501 2.15836L12.9251 5.18336C12.8084 5.30002 12.6917 5.52502 12.6751 5.69169L12.5084 6.85002C12.4501 7.26669 12.7417 7.55836 13.1584 7.50002L14.3167 7.33336C14.4751 7.30836 14.7084 7.20002 14.8251 7.08336L17.8501 4.05836C18.3751 3.53336 18.6167 2.93336 17.8501 2.16669C17.0751 1.38336 16.4751 1.63336 15.9501 2.15836Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5166 2.59167C15.7749 3.50834 16.4916 4.22501 17.4083 4.48334"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const UndoIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M14.7488 19.2805H7.20674C6.82021 19.2805 6.49967 18.95 6.49967 18.5514C6.49967 18.1527 6.82021 17.8222 7.20674 17.8222H14.7488C16.9549 17.8222 18.7556 15.9653 18.7556 13.6903C18.7556 11.4153 16.9549 9.55831 14.7488 9.55831H4.37846C3.99193 9.55831 3.67139 9.22775 3.67139 8.82914C3.67139 8.43053 3.99193 8.09998 4.37846 8.09998H14.7488C17.7374 8.09998 20.1697 10.6083 20.1697 13.6903C20.1697 16.7722 17.7374 19.2805 14.7488 19.2805Z"
                fill="#212121"
            />
            <path
                d="M6.54689 11.9889C6.36777 11.9889 6.18864 11.9208 6.04723 11.775L3.63376 9.28608C3.36036 9.00413 3.36036 8.53747 3.63376 8.25552L6.04723 5.76663C6.32063 5.48469 6.77316 5.48469 7.04656 5.76663C7.31996 6.04858 7.31996 6.51524 7.04656 6.79719L5.13275 8.7708L7.04656 10.7444C7.31996 11.0264 7.31996 11.493 7.04656 11.775C6.91457 11.9208 6.72602 11.9889 6.54689 11.9889Z"
                fill="#212121"
            />
        </svg>
    );
};

export const RedoIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M16.3892 19.2805H8.84715C5.85859 19.2805 3.42627 16.7722 3.42627 13.6903C3.42627 10.6083 5.85859 8.09998 8.84715 8.09998H19.2175C19.604 8.09998 19.9246 8.43053 19.9246 8.82914C19.9246 9.22775 19.604 9.55831 19.2175 9.55831H8.84715C6.64108 9.55831 4.84041 11.4153 4.84041 13.6903C4.84041 15.9653 6.64108 17.8222 8.84715 17.8222H16.3892C16.7758 17.8222 17.0963 18.1528 17.0963 18.5514C17.0963 18.95 16.7852 19.2805 16.3892 19.2805Z"
                fill="#212121"
            />
            <path
                d="M17.0492 11.9889C16.8701 11.9889 16.6909 11.9209 16.5495 11.775C16.2761 11.4931 16.2761 11.0264 16.5495 10.7445L18.4633 8.77086L16.5495 6.79725C16.2761 6.51531 16.2761 6.04864 16.5495 5.7667C16.8229 5.48475 17.2755 5.48475 17.5489 5.7667L19.9623 8.25558C20.2357 8.53753 20.2357 9.00419 19.9623 9.28614L17.5489 11.775C17.4074 11.9209 17.2283 11.9889 17.0492 11.9889Z"
                fill="#212121"
            />
        </svg>
    );
};

export const PlusIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H13V8C13 7.45 12.55 7 12 7C11.45 7 11 7.45 11 8V11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V13Z"
                fill="#212121"
                fillOpacity="0.52"
            />
        </svg>
    );
};

export const ExpandIcon = () => {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path
                d="M6.58324 0.999983L3.99657 3.58665L1.4099 0.999983C1.28535 0.875148 1.11625 0.804993 0.939902 0.804993C0.763557 0.804993 0.594457 0.875148 0.469902 0.999983C0.209902 1.25998 0.209902 1.67998 0.469902 1.93998L3.5299 4.99998C3.7899 5.25998 4.2099 5.25998 4.4699 4.99998L7.5299 1.93998C7.7899 1.67998 7.7899 1.25998 7.5299 0.999983C7.2699 0.74665 6.84324 0.739983 6.58324 0.999983Z"
                fill="#212121"
            />
        </svg>
    );
};

export const NotVerifyIcon = ({ size }: { size?: string }) => {
    return (
        <svg
            width={size ?? "17"}
            height={size ?? "17"}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
                fill="#FFB933"
                stroke="white"
                strokeWidth="2"
            />
            <path
                d="M8.49873 9.6375C8.23223 9.6375 8.01123 9.4165 8.01123 9.15V5.7375C8.01123 5.471 8.23223 5.25 8.49873 5.25C8.76523 5.25 8.98623 5.471 8.98623 5.7375V9.15C8.98623 9.4165 8.76523 9.6375 8.49873 9.6375Z"
                fill="#292D32"
            />
            <path
                d="M8.50254 11.913C8.41804 11.913 8.33354 11.8935 8.25554 11.861C8.17104 11.8285 8.10602 11.7831 8.04102 11.7246C7.98252 11.6596 7.93704 11.588 7.89804 11.51C7.86554 11.432 7.85254 11.3475 7.85254 11.263C7.85254 11.094 7.91752 10.925 8.04102 10.8015C8.10602 10.743 8.17104 10.6976 8.25554 10.6651C8.49604 10.5611 8.78205 10.6195 8.96405 10.8015C9.02255 10.8665 9.06803 10.9315 9.10053 11.016C9.13303 11.094 9.15254 11.1785 9.15254 11.263C9.15254 11.3475 9.13303 11.432 9.10053 11.51C9.06803 11.588 9.02255 11.6596 8.96405 11.7246C8.84055 11.8481 8.67804 11.913 8.50254 11.913Z"
                fill="#292D32"
            />
        </svg>
    );
};
