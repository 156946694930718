import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWorksheet } from "../../../../../shared/models/worksheet";
import {
    isModuleApplyForNextJs,
    isModuleForToolV2,
    isResourceCollection,
    stringifyAndParse,
} from "../../../../../shared/utils";
import ClassroomConfig from "../../../../../shared/utils/classroom";
import Config from "../../../../../shared/utils/config";
import * as ga from "../../../../lib/ga";
import {
    selectActionGridItemAction,
    selectGridItemAction,
} from "../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import {
    getParamsForUpdateScore,
    getRatioFromUrl,
    isInteractiveWorksheet,
    urlWorksheet,
} from "../../../../utils";
import Cookies from "../../../../utils/cookies";
import Routes from "../../../../utils/routes";
import useComponentVisible from "../../../common/click-out-side";
import DynamicComponent from "../../../common/dynamic-component";
import { ImageItemWorksheet } from "../../../common/image";
import CheckBoxWsIconSvg from "../../../icons/folder/check-box-icon";
import { ActionWorkSheetItemType } from "../../../worksheets/action-worksheet-item";
import { DrawerWorksheetGridType } from "../drawer";
import TickSelect from "./action/select-ws";
import "./index.scss";
// import IconInteractive from "../../../icons/icon-interactive";
// import DrawerWorksheetGrid, { DrawerWorksheetGridType } from "../drawer";

const WorksheetGrid = ({
    worksheet,
    place,
    clickFc,
    setShowPopupSurveyLogin,
    widthColumn,
    handleAddAssignment,
    handleDeleteWs,
    index,
    selectWorksheetFc,
    onDragResourceItem,
    onDropResourceItem,
}: {
    worksheet: IWorksheet;
    clickFc?: Function;
    place?: string;
    setShowPopupSurveyLogin?: Function;
    widthColumn?: number;
    handleAddAssignment?: Function;
    handleDeleteWs?: Function;
    index?: any;
    selectWorksheetFc?: Function;
    onDragResourceItem?: Function;
    onDropResourceItem?: Function;
}) => {
    let images = worksheet?.game?.images;
    let thumbnail = worksheet?.thumbnail;
    let listImages = [thumbnail];
    const totalImages = images.length;
    if (totalImages > 1) {
        listImages = [...listImages, ...stringifyAndParse(images).slice(1)];
    }
    const intervalRef = useRef(null);
    const sliderRef = useRef(null);
    const isMobile = useMediaQuery("(max-width:768px)");
    const isTablet = useMediaQuery("(max-width:1024px)");
    const dispatch = useDispatch();
    const refWorksheet = useRef(null);
    const urlWS = worksheet?.lock ? "" : urlWorksheet(worksheet);

    const isInteractive = isInteractiveWorksheet(worksheet);
    const collection = useSelector(
        (state: ResourceAppState) => state.resourceState.collectionSelected.data
    );
    const assignments = useSelector(
        (state: any) => state.assignmentReducer?.assignments
    );
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    const classId = useSelector(
        (state: any) => state.classReducer?.classInfo?.id
    );
    const loadingTabInfo = useSelector(
        (state: ResourceAppState) => state.resourceState.loadingTabInfo
    );

    const assignment = assignments?.find(
        (asm) => asm.worksheetId === worksheet?.id
    );
    const listWorksheetSelected = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    let listWorksheet = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    let isCreateCollection =
        collection?.id ==
        Config.COLLECTION_WORKSHEETS_CREATED.WORKSHEETS_CREATED_COLLECTIONid;
    const isDraftWorksheet = worksheet?.statusHide === Config.STATUS_DRAFT;
    let page = typeof window !== "undefined" && window?.location?.pathname;

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [clickRight, setClickRight] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [isCollection, setIsCollection] = useState(false);
    const [isSelectWs, setIsSelectWs] = useState(false);
    const [isHoverTickIcon, setIsHoverTickIcon] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [enableSlider, setEnableSlider] = useState(false);

    const worksheetAction = actionGridItem?.worksheet;
    const isReactApp = isModuleApplyForNextJs();
    const haveAction = worksheetAction?.id && !isReactApp;

    useEffect(() => {
        let collectionPage = page && page?.startsWith("/collection/");
        setIsCollection(collectionPage);
    }, [page]);
    const selectWorksheet = (worksheet) => {
        clickFc && clickFc();
        let divHomeGridColumns =
            document.getElementsByClassName("home-grid-column");
        let numberWsDisplayed = 0;
        for (let index = 0; index < divHomeGridColumns.length; index++) {
            numberWsDisplayed +=
                divHomeGridColumns[index].getElementsByClassName(
                    "image"
                ).length;
        }
        let recentWsIdsArr: string[] = [];
        let recentWsIds: string = Cookies.get(Config.RECENT_WSidS);
        if (recentWsIds) {
            recentWsIdsArr = recentWsIds.split(",");
        }
        //keep fixed length of recentWsIds
        if (recentWsIdsArr.length == Config.LENGTH_RECENT_WSidS) {
            recentWsIdsArr.shift();
        }
        recentWsIdsArr.push(worksheet.id);
        recentWsIds = recentWsIdsArr.toString();
        Cookies.set(Config.RECENT_WSidS, recentWsIds);
        if (userInfo.token) {
            let params = getParamsForUpdateScore(
                worksheet,
                Config.CLICK_WS_SCORE,
                userInfo.token
            );
            // updateCategoryScoreApi(params);
        }
    };
    let idWorksheet = "profile-worksheet-grid-" + worksheet?.id;
    let utcTime = new Date().toUTCString();
    let currentDate = new Date(utcTime).getTime();
    let updatedAt = new Date(worksheet?.updatedAt).getTime();
    let getNewImg =
        currentDate - updatedAt < Config.MILISECOND_PER_MONTH ? true : false;
    const { isComponentVisible } = useComponentVisible(refWorksheet);

    useEffect(() => {
        if (isComponentVisible) setClickRight(false);
    }, [isComponentVisible]);
    useEffect(() => {
        if (assignment?.id) {
            setIsSelectWs(true);
        }
    }, [assignment?.id]);

    useEffect(() => {
        if (totalImages > 1) {
            const slider = sliderRef.current;
            slider.style.transform = `translateX(-${
                currentImageIndex * (widthColumn ? widthColumn : 236)
            }px)`;
        }
    }, [currentImageIndex]);
    useEffect(() => {
        if (enableSlider) {
            intervalRef.current = setInterval(() => {
                setCurrentImageIndex(
                    (prevIndex) => (prevIndex + 1) % totalImages
                );
            }, 1200);
        } else clearInterval(intervalRef.current);
        return () => clearInterval(intervalRef.current);
    }, [enableSlider]);
    const handleAddWS = () => {
        const isValidWSIndex = listWorksheetSelected?.findIndex(
            (ws) => ws.id === worksheet.id
        );
        if (listWorksheetSelected) {
            if (isValidWSIndex == -1) {
                listWorksheetSelected?.push(worksheet);
            } else {
                listWorksheetSelected?.splice(isValidWSIndex, 1);
            }
            dispatch(
                selectGridItemAction({
                    worksheet: worksheet,
                    collection: collection,
                    worksheets: listWorksheetSelected,
                })
            );
        } else {
            let newListWorksheetSelected = [];
            newListWorksheetSelected.push(worksheet);
            dispatch(
                selectGridItemAction({
                    worksheet: worksheet,
                    collection: collection,
                    worksheets: newListWorksheetSelected,
                })
            );
        }
    };

    const addAssignment = () => {
        if (!loadingTabInfo) {
            if (!isMobile || listWorksheet.length == 0) {
                const isReactApp = isModuleApplyForNextJs();
                if (worksheet?.lock) {
                    setShowPopupSurveyLogin && setShowPopupSurveyLogin(true);
                } else if (isReactApp) {
                    setIsSelectWs(!isSelectWs);
                    if (!assignment) {
                        let params = {
                            userId: userInfo.id,
                            worksheetId: worksheet?.id,
                            title: worksheet?.title,
                            description: worksheet?.description,
                            question: worksheet?.title,
                            answers: [],
                            type: ClassroomConfig.ASSIGNMENT_WORKSHEET,
                            classId: classId,
                            thumbnail:
                                worksheet?.thumbnail ?? images?.length
                                    ? images[0]
                                    : "",
                        };

                        handleAddAssignment && handleAddAssignment(params);
                    } else {
                        let params = {
                            userId: userInfo.id,
                            assignmentId: assignment.id,
                            thumbnail:
                                worksheet?.thumbnail ?? images?.length
                                    ? images[0]
                                    : "",
                        };
                        handleDeleteWs && handleDeleteWs(params);
                    }
                } else if (!isDraftWorksheet && !isHoverTickIcon) {
                    let url = window.location.origin + urlWorksheet(worksheet);
                    window.open(url, "_blank");
                }
            } else {
                handleAddWS();
            }
        }
    };

    return (
        <>
            <div
                className={
                    "resource data-grid-collection " +
                    (isDraftWorksheet ? "draft" : "") +
                    (worksheet.lock ? " premium-worksheet" : "")
                }
                id={idWorksheet}
                ref={refWorksheet}
                onClick={(e) => {
                    e.stopPropagation();
                    addAssignment();
                }}
                onMouseEnter={() => {
                    if (totalImages > 1 && !isTablet && !collection) {
                        setEnableSlider(true);
                    }
                }}
                onMouseLeave={() => {
                    if (totalImages > 1 && !isTablet && !collection) {
                        setEnableSlider(false);
                        setCurrentImageIndex(0);
                    }
                }}
            >
                <div
                    style={
                        worksheet?.thumbnail
                            ? {
                                  width: widthColumn ? widthColumn : "236px",
                                  height:
                                      (widthColumn ? widthColumn : 236) /
                                      getRatioFromUrl(worksheet?.thumbnail),
                              }
                            : {}
                    }
                    className={
                        "data-grid-item data-worksheet tick-icon" +
                        (isCollection && collection?.userId == userInfo?.id
                            ? " collection"
                            : "") +
                        (collection?.userId == userInfo?.id ? " " : " user") +
                        (listWorksheetSelected != undefined &&
                        listWorksheetSelected?.findIndex(
                            (ws: IWorksheet) => ws.id == worksheet?.id
                        ) != -1
                            ? " ticked"
                            : "")
                    }
                >
                    {totalImages > 1 && !collection && (
                        <div className="number-of-image">
                            {currentImageIndex + 1} Of {totalImages}
                        </div>
                    )}
                    <a
                        href={urlWS}
                        target="_blank"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            if (!isMobile || listWorksheet?.length == 0) {
                                window.open(urlWS, "_blank");
                            } else {
                                handleAddWS();
                            }
                        }}
                        rel="noreferrer"
                    >
                        <div
                            style={
                                worksheet?.thumbnail
                                    ? {
                                          width: widthColumn
                                              ? widthColumn
                                              : "236px",
                                          height:
                                              (widthColumn
                                                  ? widthColumn
                                                  : 236) /
                                              getRatioFromUrl(
                                                  worksheet?.thumbnail
                                              ),
                                      }
                                    : {}
                            }
                            className={"slider-ws "}
                            ref={sliderRef}
                        >
                            {listImages?.map((imageLink, index) => (
                                <div
                                    style={{
                                        width: widthColumn
                                            ? widthColumn
                                            : "236px",
                                    }}
                                >
                                    <ImageItemWorksheet
                                        key={index}
                                        src={
                                            imageLink +
                                            (getNewImg ? `?t=${updatedAt}` : "")
                                        }
                                        alt={worksheet.title}
                                        style={{
                                            width: widthColumn
                                                ? widthColumn
                                                : "236px",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </a>
                    {collection?.id ==
                    Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid ? (
                        <div className={"practiced-score" + " " + place}>
                            <span>{worksheet.score}/10</span>
                        </div>
                    ) : (
                        <></>
                    )}

                    {/* {isInteractive && !isTablet && <IconInteractive />} */}
                    {isInteractive && !isTablet && (
                        <DynamicComponent
                            path="../../../icons/icon-interactive"
                            component={
                                import("../../../icons/icon-interactive")
                            }
                        />
                    )}

                    {isTablet &&
                        !isResourceCollection({
                            collectionId: collection?.id,
                        }) &&
                        !isReactApp && (
                            // <ActionWorkSheetItem
                            //     worksheet={worksheet}
                            //     selectWorksheet={() => {}}
                            //     clickFc={(event: any) => {}}
                            // />
                            <DynamicComponent<ActionWorkSheetItemType>
                                path="../../../worksheets/action-worksheet-item"
                                component={
                                    import(
                                        "../../../worksheets/action-worksheet-item"
                                    )
                                }
                                worksheet={worksheet}
                            />
                        )}
                    {isReactApp && (
                        <div className="point-event-item selected-ws">
                            <div className="check-box-ws">
                                <CheckBoxWsIconSvg selected={isSelectWs} />
                            </div>
                        </div>
                    )}
                    {!isReactApp && !isModuleForToolV2() ? (
                        <TickSelect
                            setIsHoverTickIcon={(isHoverTickIcon) =>
                                setIsHoverTickIcon(isHoverTickIcon)
                            }
                            handleAddWS={handleAddWS}
                            worksheet={worksheet}
                        />
                    ) : (
                        <></>
                    )}
                    {!isTablet ? (
                        // <ActionWorkSheetItem
                        //     worksheet={worksheet}
                        //     selectWorksheet={selectWorksheet}
                        //     clickFc={(event: any) => {
                        //         event.preventDefault();
                        //     }}
                        //     selectWorksheetFc={selectWorksheetFc}
                        //     onDragResourceItem={onDragResourceItem}
                        //     onDropResourceItem={onDropResourceItem}
                        // />
                        <DynamicComponent<ActionWorkSheetItemType>
                            path="../../../worksheets/action-worksheet-item"
                            component={
                                import(
                                    "../../../worksheets/action-worksheet-item"
                                )
                            }
                            worksheet={worksheet}
                            selectWorksheet={selectWorksheet}
                            clickFc={(event: any) => {
                                event.preventDefault();
                            }}
                            selectWorksheetFc={selectWorksheetFc}
                            onDragResourceItem={onDragResourceItem}
                            onDropResourceItem={onDropResourceItem}
                        />
                    ) : (
                        <></>
                    )}
                </div>

                {clickRight && haveAction && (
                    <PopupActionWorksheet
                        position={position}
                        isCreateCollection={isCreateCollection}
                        worksheet={worksheet}
                    />
                )}
            </div>
            {showAction && (
                // <DrawerWorksheetGrid
                //     setShowAction={(value: boolean) => setShowAction(value)}
                //     showAction={showAction}
                //     worksheet={worksheet}
                // ></DrawerWorksheetGrid>
                <DynamicComponent<DrawerWorksheetGridType>
                    path="../drawer"
                    component={import("../drawer")}
                    setShowAction={(value: boolean) => setShowAction(value)}
                    showAction={showAction}
                    worksheet={worksheet}
                />
            )}
        </>
    );
};
const PopupActionWorksheet = ({
    position,
    worksheet,
    isCreateCollection,
}: {
    position: any;
    worksheet: IWorksheet;
    isCreateCollection: boolean;
}) => {
    const dispatch = useDispatch();
    const selectActionGridAction = ({ action }: { action: string }) => {
        dispatch(selectActionGridItemAction({ action }));
    };
    const collection = useSelector(
        (state: ResourceAppState) => state.resourceState.collectionSelected.data
    );
    let isSaved = collection?.worksheets.find(
        (ws) => ws?.id === worksheet.id
    );
    if (
        collection?.id ==
        Config.COLLECTION_WORKSHEETS_CREATED.WORKSHEETS_CREATED_COLLECTIONid
    ) {
        isSaved = null;
    }
    const isDraftCollection =
        collection?.id == Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid;
    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className={"popup-action-collection worksheet"}
            style={{
                top: position.y,
                left: position.x,
            }}
        >
            {(isCreateCollection || isDraftCollection) && (
                <>
                    <div
                        className="item-action"
                        onClick={() =>
                            window.open(
                                Routes.CUSTOMIZE_WORKSHEET +
                                    "/?worksheetId=" +
                                    worksheet?.id,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }
                    >
                        <img src="/images/icon/edit-icon.svg" alt="" />
                        <span>Edit</span>
                    </div>
                    <div className="split"></div>
                </>
            )}
            {
                <>
                    <div
                        className="item-action"
                        onClick={() =>
                            selectActionGridAction({
                                action: Config.ACTION_GRID.DELETE_WS,
                            })
                        }
                    >
                        <img src="/images/icon/delete-icon.svg" alt="" />
                        <span>Delete</span>
                    </div>
                </>
            }
            {!isCreateCollection && !isDraftCollection && (
                <>
                    <div className="split"></div>
                    <div
                        className="item-action"
                        onClick={() =>
                            selectActionGridAction({
                                action: Config.ACTION_GRID.MOVE_WORKSHEET,
                            })
                        }
                    >
                        <img src="/images/icon/move-icon.svg" alt="" />
                        <span>Move to</span>
                    </div>
                </>
            )}
        </div>
    );
};
export default WorksheetGrid;
