import { useState, useRef } from 'react';
import './styles.scss';
import { SketchPicker } from "react-color";
import Button from 'react-bootstrap/Button';

export interface ICellPixelTemplate {
    x: number,
    y: number,
    w: number,
    h: number,
    color: string,
};

const ImageGrid = ({ cells = [], setCells }) => {
    const imageGridRef = useRef(null);
    const [hoveredCell, setHoveredCell] = useState(null);
    const [pickerColor, setPickerColor] = useState("");
    const [selectedCells, setSelectedCells] = useState<ICellPixelTemplate[]>([]);

    const handleCellHover = (cell: ICellPixelTemplate) => {
        setHoveredCell(cell);
    };

    const handleCellLeave = () => {
        setHoveredCell(null);
    };

    const handleChangeColor = (newColor: string) => {
        const updatedCells = [...cells].map(cell => {
            const isSelected = selectedCells.some(selectedCell => selectedCell.x === cell.x && selectedCell.y === cell.y);
            if (isSelected) {
                return { ...cell, color: newColor };
            }
            return cell;
        });
        setSelectedCells((prev) => prev.map((cell) => ({ ...cell, color: newColor })));
        setCells(updatedCells);
    }

    const handleCellClick = (cell: ICellPixelTemplate) => {
        const defaultPickerColor = '#00000';
        let updatedSelectedCells = [...selectedCells];
        if (updatedSelectedCells.includes(cell)) {
            updatedSelectedCells = updatedSelectedCells.filter(selectedCell => selectedCell !== cell);
        } else {
            updatedSelectedCells = [...selectedCells, cell];
        }
        setSelectedCells(updatedSelectedCells);
        if (checkAllCellsSameColor(updatedSelectedCells)) {
            setPickerColor(selectedCells[0]?.color);
        } else {
            setPickerColor(defaultPickerColor);
        };
    };

    const checkAllCellsSameColor = (inputCells = []) => {
        return inputCells.every(cell => cell.color === cells[0].color);
    };

    return (
        <div>
            <div className="image-grid d-flex justify-content-center align-items-center" ref={imageGridRef}>
                {cells.map((cell, index) => (
                    <div
                        key={index}
                        className="cell"
                        style={{
                            backgroundColor: cell.color,
                            left: cell.x,
                            top: cell.y,
                            width: cell.w,
                            height: cell.h,
                            border: '1px solid black'
                        }}
                        onClick={() => handleCellClick(cell)}
                        onMouseEnter={() => handleCellHover(cell)}
                        onMouseLeave={handleCellLeave}
                    >
                        {(hoveredCell === cell || selectedCells.includes(cell)) && (
                            <div className="overlay"></div>
                        )}
                    </div>
                ))}
                {pickerColor && (
                    <div
                        className="image-grid__pick-color"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SketchPicker
                            color={pickerColor}
                            onChange={(newColor) => {
                                setPickerColor(newColor.hex);
                            }}
                            onChangeComplete={(newColor) => {
                                handleChangeColor(newColor?.hex);
                            }}
                        />
                        <Button onClick={() => {
                            setPickerColor("");
                            setSelectedCells([]);
                        }}>Close</Button>
                    </div>
                )}
            </div>
        </div>

    )
}

export default ImageGrid;