import {
    AddQuestionIcon,
    CloseQuestionIcon,
} from "../../../../../../assets/icon";
import { addQuestionMC } from "../../../../../../redux/async/multipleChoiceThunk";
import { useAppDispatch } from "../../../../../../redux/hook";
import { setMessage } from "../../../../../../redux/reducers/snackbar";
import { IActivity } from "../../../../../../shared/models/WorkSheetCreator";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../shared/models/questionActivity";
import { generateIdFromDateTime } from "../../../../../../utils";
import { checkAbleToAdd } from "../../../util";
import "./index.scss";

const QuestionAction = ({
    activity,
    isAdd,
    index,
    setOpenQuestion,
    setCurrentQuestion,
}: {
    activity: IActivity;
    isAdd: boolean;
    index: number;
    setOpenQuestion?: Function;
    setCurrentQuestion?: Function;
}) => {
    const dispatch = useAppDispatch();

    const defaultAnswer: IAnswerActivity[] = [
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
    ];
    let newQuestion: IQuestionActivity = {
        id: generateIdFromDateTime(),
        question: `${index + 2}.`,
        answers: defaultAnswer,
        indexQuestion: 0,
        multipleChoicesAttribute: {
            numberCol: 4,
            resourceItemIds: [],
        },
    };

    const handleAddNewQuestion = () => {
        let ableToAdd = checkAbleToAdd(activity);
        if (ableToAdd.isAble) {
            dispatch(
                addQuestionMC({
                    question: newQuestion,
                    questionIndex: index + 1,
                })
            );
            setCurrentQuestion(index + 1);
            setOpenQuestion(true);
        } else {
            dispatch(
                setMessage({
                    severity: "error",
                    message: ableToAdd.message,
                })
            );
        }
        setTimeout(() => {
            let listQuesBox = document.getElementById("question-container");
            let prevQues = document.getElementById(`question-${index + 1}`);
            listQuesBox?.scrollTo({
                top: prevQues?.getBoundingClientRect().top - 78,
                behavior: "smooth",
            });
        }, 20);
    };
    return (
        <div
            className="question-action"
            style={{
                display: !isAdd ? "flex" : "",
                backgroundColor: !isAdd ? "#F8F9FB" : "",
            }}
        >
            <div className="action-icon">
                {isAdd ? (
                    <div
                        onClick={() => handleAddNewQuestion()}
                        className="add-ques-icon"
                    >
                        <AddQuestionIcon />
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            setOpenQuestion(false);
                            setCurrentQuestion(-1);
                        }}
                    >
                        <CloseQuestionIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuestionAction;
