import { useDispatch, useSelector } from "react-redux";
import { getPrefixByProjectName } from "../../../../../shared/utils";
import {
    resetSvgAction,
    scramblePuzzleAction,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import "./index.scss";

const ButtonScramble = () => {
    const dispatch = useDispatch();
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    return (
        <div
            className="button-scramble"
            onClick={() => {
                let prefix = getPrefixByProjectName(projectName, "wse_");

                dispatch(scramblePuzzleAction());
                // resetSvgPaint();
                dispatch(resetSvgAction({ resetSvg: true }));
            }}
        >
            <div className="text-scramble">Scramble</div>
        </div>
    );
};

export default ButtonScramble;
