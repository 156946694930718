import { Activity } from "../../../shared/models/WorkSheetCreator";
import CheckBox from "./checkbox";
import MultipleChoiceModule from "./list-questions";

const MultipleChoiceView = ({ activity }: { activity: Activity }) => {
    return (
        <div>
            <MultipleChoiceModule activity={activity} />
            <CheckBox activity={activity} />
        </div>
    );
};

export default MultipleChoiceView;
