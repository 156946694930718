import { Checkbox, Slider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
    Activity,
    IActivity,
} from "../../../../shared/models/WorkSheetCreator";
import Config from "../../../../shared/utils/config";
import { isContainsRegrouping } from "../logic";
import "./index.scss";
import RangeInput from "./input";
import { useAppDispatch } from "../../../../redux/hook";
import {
    changeListStyle,
    changeNumberOfQuestions,
    changeRangeQuestions,
    changeRegrouping,
    changeShowAnswer,
} from "../../../../redux/async/mathActivityThunk";
import { upDateShowAnswerKey } from "../../../../redux/reducers/createWorksheet";
import ConstantsMath from "../../../../shared/utils/ConstantsResourceMath";
import { getPrefixImage } from "../../../../utils";
import { sendEvent } from "../tracking";

const RequiredSection = ({ activity }: { activity: Activity }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(
            upDateShowAnswerKey(activity.showAnswerKey === Config.SHOW_VALUE)
        );
    }, []);
    return (
        <div className="required-section">
            <ListStyle
                listStyle={activity.listStyle}
                activityType={activity.type}
            />
            <SliderNumberOfQuestions
                numberOfQuestions={activity.questions.length}
                handleChange={(value) => {
                    dispatch(
                        changeNumberOfQuestions({ numberOfQuestion: value })
                    );
                }}
                activityType={activity.type}
            />
            <RangeFactor activity={activity} />
            <CheckboxSection
                title="Show The Answer Key"
                checked={activity.showAnswerKey === Config.SHOW_VALUE}
                handleChange={(value) => {
                    dispatch(changeShowAnswer({ showAnswer: value }));

                    sendEvent(
                        activity.type,
                        `${value ? "" : "not_"}show_the_answer_key`
                    );
                }}
            />
        </div>
    );
};

const ListStyle = ({
    listStyle,
    activityType,
}: {
    listStyle: number;
    activityType: string;
}) => {
    const dispatch = useAppDispatch();
    const OPTIONS = [
        {
            id: ConstantsMath.LIST_STYLE.NONE,
            img: "list_style_none.svg",
            action: "none",
        },
        {
            id: ConstantsMath.LIST_STYLE.NUMBER,
            img: "list_style_number.svg",
            action: "number_1",
        },
        {
            id: ConstantsMath.LIST_STYLE.SUPER_NUMBER,
            img: "list_style_super_number.svg",
            action: "number_2",
        },
    ];

    return (
        <div className={`list-style-container border`}>
            <div className="title">List Style</div>
            <div className="list-style">
                {OPTIONS.map(({ id, img }) => (
                    <img
                        onClick={() => {
                            if (listStyle !== id) {
                                dispatch(changeListStyle({ listStyle: id }));

                                let action = OPTIONS.find(
                                    (o) => o.id === id
                                )?.action;
                                if (action) {
                                    action = "list_style_" + action;
                                    sendEvent(activityType, action);
                                }
                            }
                        }}
                        className={`item ${listStyle === id ? " active " : ""}`}
                        key={id}
                        src={`${getPrefixImage()}/images/math-activity/${img}`}
                        alt="list style item"
                    />
                ))}
            </div>
        </div>
    );
};

const SliderNumberOfQuestions = ({
    numberOfQuestions,
    handleChange,
    activityType,
}: {
    numberOfQuestions: number;
    handleChange: Function;
    activityType: string;
}) => {
    const [value, setValue] = useState(numberOfQuestions);
    const MIN = 0;
    const MAX = 30;

    return (
        <div className="slider-container border">
            <div className="header">
                <div className="title">Number Of Questions</div>
                <RangeInput
                    min={MIN}
                    max={MAX}
                    value={value}
                    onChange={(value: number) => {
                        setValue(value);
                    }}
                    onBlur={(e) => {
                        const value = parseInt(e.target.value);
                        sendEvent(activityType, "number_of_question", {
                            value,
                        });
                        handleChange(value);
                    }}
                />
            </div>
            <div className="question-slider-container">
                <Slider
                    className="question-slider"
                    min={MIN}
                    max={MAX}
                    value={value}
                    onChange={(_, value: number) => setValue(value)}
                    onChangeCommitted={(_, _value: number) => {
                        handleChange(_value);
                        sendEvent(activityType, "number_of_question", {
                            value: _value,
                        });
                    }}
                />
            </div>
        </div>
    );
};

const RangeFactor = ({ activity }: { activity: IActivity }) => {
    const dispatch = useAppDispatch();
    const { regrouping, type, range1, range2 } = activity;
    const MIN = 0;
    const MAX =
        type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE ? 1000 : 10000;
    const [factorFrom1, setFactorFrom1] = useState(range1.min);
    const [factorTo1, setFactorTo1] = useState(range1.max);
    const [factorFrom2, setFactorFrom2] = useState(range2.min);
    const [factorTo2, setFactorTo2] = useState(range2.max);

    const checkValueFactor = ({
        isFrom,
        newValue,
        forFactor,
    }: {
        isFrom?: boolean;
        newValue: number;
        forFactor: number;
    }) => {
        let value = newValue;
        if (isFrom) {
            if (forFactor === 1) {
                if (newValue > factorTo1) {
                    value = factorTo1;
                    setFactorFrom1(factorTo1);
                }
            } else {
                if (newValue > factorTo2) {
                    value = factorTo2;
                    setFactorFrom2(factorTo2);
                }
            }
        } else {
            if (forFactor === 1) {
                if (newValue < factorFrom1) {
                    value = factorFrom1;
                    setFactorTo1(factorFrom1);
                }
            } else {
                if (newValue < factorFrom2) {
                    value = factorFrom2;
                    setFactorTo2(factorFrom2);
                }
            }
        }

        dispatch(changeRangeQuestions({ factor: forFactor, isFrom, value }));

        const action = `addition_change_range_of_${
            forFactor === 1 ? "1_st" : "2_nd"
        }_factor`;

        let from = isFrom ? value : forFactor === 1 ? factorFrom1 : factorFrom2;
        let to = !isFrom ? value : forFactor === 1 ? factorTo1 : factorTo2;

        if (typeof from === "string") {
            from = parseInt(from);
        }
        if (typeof to === "string") {
            to = parseInt(to);
        }
        sendEvent(activity.type, action, {
            from,
            to,
        });
    };

    const containRegrouping = useMemo(() => {
        if (activity.type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE) {
            return isContainsRegrouping(
                { min: factorFrom1, max: factorTo1 },
                { min: factorFrom2, max: factorTo2 }
            );
        }
        return true;
    }, [factorFrom1, factorFrom2, factorTo1, factorTo2]);

    return (
        <>
            <div className="range-factor-container border">
                <div className="range-container">
                    <div className="title">Range of 1st factor</div>
                    <div className="group-input">
                        <RangeInput
                            min={MIN}
                            max={MAX}
                            value={factorFrom1}
                            onChange={(value) => setFactorFrom1(value)}
                            onBlur={(e) =>
                                checkValueFactor({
                                    isFrom: true,
                                    newValue: e.target.value,
                                    forFactor: 1,
                                })
                            }
                            usingMinWidth={true}
                        />
                        <div className="title">to</div>
                        <RangeInput
                            min={MIN}
                            max={MAX}
                            value={factorTo1}
                            onChange={(value) => setFactorTo1(value)}
                            onBlur={(e) =>
                                checkValueFactor({
                                    isFrom: false,
                                    newValue: e.target.value,
                                    forFactor: 1,
                                })
                            }
                            usingMinWidth={true}
                        />
                    </div>
                </div>
                <div className="divide"></div>
                <div className="range-container">
                    <div className="title">Range of 2nd factor</div>
                    <div className="group-input">
                        <RangeInput
                            min={
                                activity.type ===
                                Config.ACTIVITY_TYPE.DIVISION.TYPE
                                    ? 1
                                    : MIN
                            }
                            max={MAX}
                            value={factorFrom2}
                            onChange={(value) => setFactorFrom2(value)}
                            onBlur={(e) =>
                                checkValueFactor({
                                    isFrom: true,
                                    newValue: e.target.value,
                                    forFactor: 2,
                                })
                            }
                            usingMinWidth={true}
                        />
                        <div className="title">to</div>
                        <RangeInput
                            min={MIN}
                            max={MAX}
                            value={factorTo2}
                            onChange={(value) => setFactorTo2(value)}
                            onBlur={(e) =>
                                checkValueFactor({
                                    isFrom: false,
                                    newValue: e.target.value,
                                    forFactor: 2,
                                })
                            }
                            usingMinWidth={true}
                        />
                    </div>
                </div>
            </div>
            {activity.type !== Config.ACTIVITY_TYPE.DIVISION.TYPE && (
                <CheckboxSection
                    checked={regrouping === Config.SHOW_VALUE}
                    title="Regrouping"
                    disable={!containRegrouping}
                    handleChange={(value) => {
                        dispatch(changeRegrouping({ regrouping: value }));

                        sendEvent(
                            activity.type,
                            `${value ? "" : "not_"}regrouping`
                        );
                    }}
                />
            )}
        </>
    );
};

const CheckboxSection = ({
    title,
    disable,
    checked,
    handleChange,
}: {
    title: string;
    disable?: boolean;
    checked?: boolean;
    handleChange?: Function;
}) => {
    return (
        <div
            className={`checkbox-container border ${disable ? "disable" : ""}`}
        >
            <div className="title">{title}</div>
            <Checkbox
                checkedIcon={<CheckedIcon />}
                icon={<UncheckedIcon />}
                className="checkbox"
                checked={checked}
                onChange={(_, value) => handleChange(value)}
                disabled={disable}
            />
        </div>
    );
};

const CheckedIcon = () => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="16" height="16" rx="4" fill="#3B82F6" />
            <path
                d="M12.4735 4.80657C12.4115 4.74409 12.3378 4.69449 12.2565 4.66065C12.1753 4.6268 12.0881 4.60938 12.0001 4.60938C11.9121 4.60938 11.825 4.6268 11.7437 4.66065C11.6625 4.69449 11.5888 4.74409 11.5268 4.80657L6.56013 9.77991L4.47346 7.68657C4.40911 7.62441 4.33315 7.57554 4.24992 7.54274C4.16668 7.50993 4.0778 7.49385 3.98834 7.49539C3.89889 7.49694 3.81062 7.51609 3.72857 7.55176C3.64651 7.58742 3.57229 7.63889 3.51013 7.70324C3.44797 7.76759 3.39909 7.84355 3.36629 7.92679C3.33349 8.01002 3.3174 8.09891 3.31895 8.18836C3.3205 8.27781 3.33965 8.36609 3.37531 8.44814C3.41097 8.53019 3.46245 8.60441 3.5268 8.66657L6.0868 11.2266C6.14877 11.2891 6.22251 11.3387 6.30375 11.3725C6.38498 11.4063 6.47212 11.4238 6.56013 11.4238C6.64814 11.4238 6.73527 11.4063 6.81651 11.3725C6.89775 11.3387 6.97149 11.2891 7.03346 11.2266L12.4735 5.78657C12.5411 5.72415 12.5951 5.64838 12.6321 5.56404C12.669 5.47971 12.6881 5.38864 12.6881 5.29657C12.6881 5.20451 12.669 5.11344 12.6321 5.0291C12.5951 4.94477 12.5411 4.869 12.4735 4.80657Z"
                fill="white"
            />
        </svg>
    );
};

const UncheckedIcon = () => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="0.5"
                width="15"
                height="15"
                rx="1.5"
                fill="white"
                stroke="#212121"
            />
        </svg>
    );
};

export default RequiredSection;
