import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setCurrentActivity } from "../../../redux/reducers/createWorksheet";
import { changeTabActiveActivities } from "../../../redux/reducers/leftData";
import { RootState } from "../../../redux/store";
import Config from "../../../shared/utils/config";
import { getListUrlFillTheBlank } from "../../../utils";
import LeftFillInBlankComponent from "../fill-in-blank";
import HandWrittingView from "../handwritting";
import LayoutTabView from "../layout-tab";
import WordSearchGameView from "../word-search-game";
import "./style.scss";
import MultipleChoiceView from "../multiple-choice";
import { getInitTypeFromUrl } from "../../../utils";
import NameTracingView from "../name-tracing";
import Crossword from "../crossword";
import MathView from "../math";
import WordScramble from "../word-scramble";

const getTitleByActivityType = (type: string): string => {
    let result = "";
    for (const activity in Config.ACTIVITY_TYPE) {
        const currentActivity = Config.ACTIVITY_TYPE[activity];
        if (currentActivity?.TYPE === type) {
            result = currentActivity?.TITLE ?? "";
        }
    }

    return result;
};

const TabWordSearchView = ({ isActive }: { isActive?: boolean }) => {
    const dispatch = useAppDispatch();
    const activities = useAppSelector(
        (state: RootState) => state.createWorksheetState.activities
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    if (currentActivity?.type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE) {
        return <div />;
    }
    return (
        <>
            {activities.map((activity, index) => {
                // Prevent hide tab activity if only have 1 activity
                const typeActivity = activity.type;
                const preventHideTab = getInitTypeFromUrl() === typeActivity;
                let active =
                    (isActive && currentActivity.id == activity.id) ||
                    preventHideTab;
                const titleActivity = getTitleByActivityType(typeActivity);

                return (
                    <LayoutTabView
                        key={index}
                        id={typeActivity}
                        classNameTab={"word-search-view" + index}
                        content={() => {
                            switch (typeActivity) {
                                case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
                                    return (
                                        <WordSearchGameView
                                            activity={activity}
                                        />
                                    );
                                case Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE:
                                    return (
                                        <LeftFillInBlankComponent
                                            activity={activity}
                                            isActive={active}
                                        />
                                    );
                                case Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE:
                                    return (
                                        <MultipleChoiceView
                                            activity={activity}
                                        />
                                    );
                                case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
                                    return (
                                        <HandWrittingView activity={activity} />
                                    );
                                case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
                                    return (
                                        <NameTracingView activity={activity} />
                                    );
                                case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
                                    return <Crossword />;
                                case Config.ACTIVITY_TYPE.ADDITION.TYPE:
                                case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
                                case Config.ACTIVITY_TYPE.DIVISION.TYPE:
                                case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
                                    return <MathView activity={activity} />;
                                case Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE:
                                case Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE
                                    .TYPE:
                                    return <WordScramble activity={activity} />;
                                default:
                                    break;
                            }
                        }}
                        isActive={active}
                        setTabActive={() => {
                            if (!getListUrlFillTheBlank()) {
                                if (!active) {
                                    dispatch(
                                        changeTabActiveActivities(typeActivity)
                                    );
                                } else {
                                    dispatch(changeTabActiveActivities(""));
                                }
                                dispatch(
                                    setCurrentActivity({
                                        activity: activity,
                                    })
                                );
                            }
                        }}
                        titleValue={titleActivity}
                        title={() => {
                            return <div>{titleActivity}</div>;
                        }}
                        preventHideTab={preventHideTab}
                        moreActionRender={() => {
                            return <React.Fragment></React.Fragment>;
                        }}
                    />
                );
            })}
        </>
    );
};

export default TabWordSearchView;
