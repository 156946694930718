export enum EventTracking {
    ncw_move_up_page = "ncw_move_up_page",
    ncw_move_down_page = "ncw_move_down_page",
    ncw_duplicate_page = "ncw_duplicate_page",
    ncw_delete_page = "ncw_delete_page",
    ncw_add_page = "ncw_add_page",
    ncw_interactive_element_text_box = "ncw_interactive_element_text_box",
    ncw_element_shape = "ncw_element_shape",
    ncw_element_line = "ncw_element_line",
    ncw_interactive_element_line = "ncw_interactive_element_line",
    fitb_give_an_example = "fitb_give_an_example",
    fitb_type_sentence = "fitb_type_sentence",
    fitb_enter = "fitb_enter",
    ncw_click_undo = "ncw_click_undo",
    ncw_click_redo = "ncw_click_redo",
    ncw_add_table = "ncw_add_table",
    ncw_add_column = "ncw_add_column",
    ncw_delete_column = "ncw_delete_column",
    ncw_add_row = "ncw_add_row",
    ncw_delete_row = "ncw_delete_row",
    ncw_merge_cells = "ncw_merge_cells",
    ncw_unmerge_cells = "ncw_unmerge_cells",
    ncw_move_column_right = "ncw_move_column_right",
    ncw_move_column_left = "ncw_move_column_left",
    ncw_move_row_up = "ncw_move_row_up",
    ncw_move_row_down = "ncw_move_row_down",
    ncw_change_color_border_table = "ncw_change_color_border_table",
    ncw_change_color_cell_on_table = "ncw_change_color_cell_on_table",
}

export const sendEvent = (event: EventTracking | any, params?: any) => {
};
