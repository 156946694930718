import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    IInputWord,
    IPuzzle,
    Puzzle,
} from "../../../../../shared/models/puzzle";
import {
    getPrefixByProjectName,
    getTitleWsFromProjectName,
} from "../../../../../shared/utils";
import Config from "../../../../../shared/utils/config";
import keywordWordSearch from "../../../../data/keywordWordSearch.json";
import {
    changePuzzleConfigAction,
    changeTitlePuzzleAction,
    enterNewWordAction,
    getKeywordGPTAction,
    keepConfigPuzzledAction,
    openPopupAction,
    removeAllWordsAction,
    removeErrorWordAction,
    setErrorWords,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { IErrorWord } from "../../../../redux/reducer/wordSearch.reducer";
import { randomNumber, shuffleArray } from "../../../../utils";
import constants from "../../../../utils/constants";
import PopupCommon from "../../../common/pop-up";
import SearchIcon from "../../../icons/word-search-input/search-icon";
import "./index.scss";

const WordList = () => {
    const dispatch = useDispatch();
    const shapes = constants.PUZZLE_SHAPE_ARRAY;
    let shapeNames = shapes.map((e) => e.shape);
    shapeNames = shapeNames.filter((c, index) => {
        return shapeNames.indexOf(c) === index;
    });
    let inputWords: IInputWord[] = useSelector(
        (state: any) => state.wordSearchState.inputWords
    );
    let tempInputWords: IInputWord[] = useSelector(
        (state: any) => state.wordSearchState.tempInputWords
    );
    const wordsGPT: string[] = useSelector(
        (state: any) => state.wordSearchState.wordsGPT,
        shallowEqual
    );
    const puzzle: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    const errorWords: IErrorWord[] = useSelector(
        (state: any) => state.wordSearchState.errorWords
    );
    const openPopup: boolean = useSelector(
        (state: any) => state.wordSearchState.openPopup
    );
    const autoPasteInputWords: IInputWord[] = useSelector(
        (state: any) => state.wordSearchState.autoPasteInputWords,
        shallowEqual
    );
    let currentWSValue;
    const answers = useSelector(
        (state: any) => state.wordSearchState?.worksheet?.game?.answers
    );
    if (answers) {
        try {
            currentWSValue = answers[0].value;
        } catch (error) { }
    }
    const keywordWordSearchSort = keywordWordSearch.sort(
        (word1: any, word2: any) => {
            if (word1.keyword < word2.keyword) {
                return -1;
            } else if (word1.keyword > word2.keyword) {
                return 1;
            } else {
                return 0;
            }
        }
    );
    // const [previousErrorLength, setPreviousErrorLength] = useState(0);
    const convertInputWords = (inputWordsArr: IInputWord[] | string[]) => {
        if (typeof inputWordsArr[0] === "string") {
            return inputWordsArr.join("\n");
        } else {
            return inputWordsArr.map((e) => e.word).join("\n");
        }
        // let result = "";
        // inputWordsArr.forEach((e) => {
        //     const word = e.word ? e.word : e;
        //     if (word?.trim()?.length > 0) {
        //         result += word + "\n";
        //     }
        // });
        // return result;
    };
    const [showIconSearch, setShowIconSearch] = useState(true);
    const [inputWordStr, setInputWordStr] = useState(
        convertInputWords(inputWords)
    );
    const [invalidWords, setInvalidWords] = useState([]);
    const [value, setValue] = useState(null);
    const [warningInputGPT, setWarningInputGPT] = useState(false);
    useEffect(() => {
        if (inputWords?.length > 0) {
            const currentText = convertInputWords(inputWords);
            setInputWordStr(currentText);
        }
    }, [currentWSValue]);

    const isAlphabeticCharacters = (text: string) => {
        const regex = /^[a-zA-Z]+$/;
        return regex.test(text);
    };
    const convertInputWordsStr = (inputWordsStr: string, upperCase = true) => {
        let inputWordsStrArr = inputWordsStr
            .split("\n")
            .filter((e) => e != "")
            .map((e) => e.replace(/\s/g, ""));
        let invalidWords: any = [];
        let validWords: any = [];
        for (let i = 0; i < inputWordsStrArr.length; i++) {
            if (isAlphabeticCharacters(inputWordsStrArr[i])) {
                validWords.push(inputWordsStrArr[i]);
            } else {
                invalidWords.push(inputWordsStrArr[i]);
                // validWords.push(inputWordsStrArr[i].replace(/[^A-Za-z]/g, ""));
            }
        }
        if (invalidWords.length > 0) {
            setInvalidWords(invalidWords);
            dispatch(openPopupAction({ open: true }));
            // return [];
        }
        return validWords.map((e) => {
            let result = e.trim();
            if (upperCase) {
                return result.toUpperCase();
            }
            return result;
        });
    };
    const handleOnChange = (inputWordsValue: string, autoPaste?: boolean) => {
        setInputWordStr(inputWordsValue);
        const inputs = convertInputWordsStr(inputWordsValue ?? inputWordStr);
        dispatch(enterNewWordAction(inputs, autoPaste));
    };
    const renderActionChangeConfigPuzzlePopUp = () => {
        return (
            <div className="action action-del del-ws">
                <div
                    className="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(keepConfigPuzzledAction());
                        dispatch(openPopupAction({ open: false }));
                    }}
                >
                    Cancel
                </div>
                <div
                    className="confirm"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(removeErrorWordAction());
                        let newInputWords = tempInputWords.filter(
                            (e) => e.onGrid == 1
                        );
                        setInputWordStr(convertInputWords(newInputWords));
                        dispatch(openPopupAction({ open: false }));
                    }}
                >
                    OK
                </div>
            </div>
        );
    };

    const renderActionPopUp = () => {
        return (
            <div className="action action-del del-ws">
                <div
                    className="confirm"
                    onClick={(e) => {
                        e.stopPropagation();
                        let inputWordsStrArr = inputWordStr
                            .split("\n")
                            .filter((e) => e != "");
                        for (let i = 0; i < inputWordsStrArr.length; i++) {
                            inputWordsStrArr[i] = inputWordsStrArr[i].replace(
                                /[^A-Za-z]/g,
                                ""
                            );
                        }
                        setInputWordStr(convertInputWords(inputWordsStrArr));
                        setInvalidWords([]);
                        dispatch(openPopupAction({ open: false }));
                        if (errorWords.length > 0) {
                            dispatch(openPopupAction({ open: true }));
                        }
                    }}
                >
                    OK
                </div>
            </div>
        );
    };

    const renderActionErrorWordPopUp = () => {
        return (
            <div className="action action-del del-ws">
                <div
                    className="confirm"
                    onClick={(e) => {
                        e.stopPropagation();
                        setInputWordStr(convertInputWords(inputWords));
                        dispatch(openPopupAction({ open: false }));
                        dispatch(setErrorWords({ words: [] }));
                    }}
                >
                    OK
                </div>
            </div>
        );
    };

    const getPopupTitle = () => {
        if (invalidWords.length > 0) {
            return "Warning";
        }
        if (errorWords.length > 0) {
            return "Warning";
        }
        return "Confirm";
    };

    const handleLongWord = (word: string) => {
        if (word.length > 30) {
            return word.slice(0, 30) + "...";
        }
        return word;
    };

    const getContentText = () => {
        if (invalidWords.length > 0) {
            return `Only a-z are allowed: ${invalidWords.toString()}`;
        }
        if (errorWords.length > 0) {
            return `Word is too long: ${errorWords
                .map((e) => handleLongWord(e.value))
                .join(",\n")}`;
        }
        let wordsNotOnGrid = tempInputWords
            .filter((e) => e.onGrid == 0)
            .map((e) => handleLongWord(e.word));
        return `Some words may not fit the puzzle: ${wordsNotOnGrid.join(
            ",\n"
        )}`;
    };

    const getRenderAction = () => {
        if (invalidWords.length > 0) {
            return renderActionPopUp;
        }
        if (errorWords.length > 0) {
            return renderActionErrorWordPopUp;
        }
        return renderActionChangeConfigPuzzlePopUp;
    };

    const handleOnChangeAutoComplete = (newValue) => {
        if (typeof newValue === "string") {
            // let invalidWords : any = [];
            // if(!isAlphabeticCharacters(newValue)){
            //     invalidWords.push(newValue);
            // }
            // if(invalidWords.length > 0){
            //     setInvalidWords(invalidWords);
            //     dispatch(openPopupAction({open: true}));
            //     setValue(null)
            //     return
            // }
            setValue({
                keyword: newValue,
            });
            handleGetWord(newValue, []);
        } else if (newValue) {
            handleGetWord(newValue.keyword, newValue.words);
            setValue(newValue);
        }
    };

    const setRandomShape = () => {
        let excludeShape = ["1"];
        shapeNames = shapeNames.filter((name, index) => {
            if (!excludeShape.includes(name)) {
                return name;
            }
        });
        let radomShapeNameindex = randomNumber(0, shapeNames.length - 1);
        let radomShapeName = shapeNames[radomShapeNameindex];
        let randomShape = shapes.filter((e) => {
            return e.shape == radomShapeName && e.size == "Large";
        })[0];

        let puzzleHeight = parseInt(randomShape.height);
        let puzzleWidth = parseInt(randomShape.width);
        // calculate average length each desird word base on width, height
        let aveLength = Math.round((puzzleHeight + puzzleWidth) / 2);
        // calculate number desird word base on available slot in puzzle
        let availableSlot = 0;
        for (let index = 0; index < randomShape.active_string.length; index++) {
            const element = randomShape.active_string[index];
            if (element != "0") {
                availableSlot++;
            }
        }
        // let ratio = 2;
        // let numberDesirdWords = Math.round((availableSlot / aveLength) * ratio);
        // const maxWords = 25;
        // if(numberDesirdWords > maxWords){
        //     numberDesirdWords = maxWords;
        // }
        let numberDesirdWords = 30;
        let newPuzzle: IPuzzle = new Puzzle({
            ...puzzle,
            puzzleWidth: randomShape.width,
            puzzleHeight: randomShape.height,
            puzzleShape: randomShape.shape,
            puzzleSize: randomShape.size,
        });

        dispatch(removeAllWordsAction());
        dispatch(changePuzzleConfigAction(newPuzzle));
        // console.log(aveLength, numberDesirdWords);

        return { aveLength, numberDesirdWords };
    };

    const handleGetWord = (keyword: string, words: string[]) => {
        let { aveLength, numberDesirdWords } = setRandomShape();
        let inputWordStr: string[] = [];
        words = words.filter(
            (value, index, self) => self.indexOf(value) === index
        );
        words = shuffleArray(words);
        const maxChars = 200;
        if (words.length > 0) {
            let count = 0;
            let allChars = 0;
            for (let index = 0; index < words.length; index++) {
                const element = words[index];
                if (
                    element.length < aveLength &&
                    count < numberDesirdWords &&
                    allChars < maxChars
                ) {
                    allChars += element.length;
                    inputWordStr.push(element);
                    count++;
                }
            }
            let inputStr = inputWordStr
                .map((e) => {
                    e = e.trim();
                    e = e.replace(/[^A-Za-z]/g, "");
                    return e;
                })
                .join("\n");
            handleOnChange(inputStr, true);
        } else {
            dispatch(
                getKeywordGPTAction({
                    topic: keyword,
                    limitWord: numberDesirdWords,
                    limitLength: aveLength,
                })
            );
        }
        dispatch(
            changeTitlePuzzleAction(
                getTitleWsFromProjectName(Config.PROJECT_NAME.TOOL_V2)
            )
        );
    };
    const handelGenWordGPT = () => {
        if (value && value.keyword) {
            let prefix = getPrefixByProjectName(projectName, "wse_");
            handleGetWord(value.keyword, []);
        } else {
            setWarningInputGPT(true);
        }
    };

    useEffect(() => {
        if (wordsGPT && wordsGPT.length > 0) {
            let inputStr = wordsGPT.map((e) => e.trim()).join("\n");
            handleOnChange(inputStr, true);
        }
    }, [wordsGPT]);

    const newAutoPasteInputWords = JSON.stringify(
        autoPasteInputWords.map((w) => w.word)
    );

    useEffect(() => {
        if (autoPasteInputWords && autoPasteInputWords.length > 0) {
            setInputWordStr(convertInputWords(autoPasteInputWords));
        }
    }, [newAutoPasteInputWords]);

    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    useEffect(() => {
        setValue("");
    }, []);
    let placeholderText =
        // "Type Or Paste Your List Of Words Here\n\nExample:\nCat\nPig\nDog";
        `Type Or Paste Your List Of Words Here

Example:
Cat
Pig
Dog`;
    return <>
        <div className="word-list">
            <div className="title">Word List</div>
            <div className="auto-complete">
                <Autocomplete
                    value={value}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    clearText={value?.keyword ? "clear" : "search"}
                    options={keywordWordSearchSort}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        return (
                            option.keyword.charAt(0).toUpperCase() +
                            option.keyword.slice(1)
                        );
                    }}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            className={
                                warningInputGPT
                                    ? "text-field-warning"
                                    : "text-field"
                            }
                            placeholder="Topics"
                            onKeyDown={(e) => {
                                if (e.code == "Enter") {
                                    // handelGenWordGPT();
                                }
                            }}
                        />
                    )}
                    sx={{
                        width: "100%",
                    }}
                    freeSolo
                    onChange={(event, newValue) => {
                        let prefix = getPrefixByProjectName(
                            projectName,
                            "wse_"
                        );
                        handleOnChangeAutoComplete(newValue);
                    }}
                    // forcePopupIcon
                    // popupIcon={ <GPTLogoIcon/> }
                    onInputChange={(event, newValue) => {
                        let temp = newValue
                            .trim()
                            .replace(/[^a-zA-Z]/g, "");
                        if (temp && temp.length > 0) {
                            setWarningInputGPT(false);
                        }
                        setValue({
                            keyword: newValue,
                        });
                    }}
                />
                {!value?.keyword ? (
                    <div className="icon-search">
                        <SearchIcon />
                    </div>
                ) : (
                    // <ClearIcon onClick={() => setValue(null)} />
                    <></>
                )}
            </div>
            {/* <LightTooltip
                    title="Auto-Make word search with a keyword"
                    placement="right"
                >
                    <IconButton
                        onClick={() => {
                            handelGenWordGPT();
                        }}
                    >
                        <GPTLogoIcon></GPTLogoIcon>
                    </IconButton>
                </LightTooltip> */}

            <TextField
                id="multi-text"
                className="multi-text"
                placeholder={placeholderText}
                multiline
                rows={6}
                value={inputWordStr}
                onChange={(e) => {
                    handleOnChange(e.target.value);
                }}
                onBlur={() => {
                }}
                error={openPopup}
            // label="Only a-z are allowed"
            // disabled={loading}
            />
        </div>
        {openPopup && (
            <PopupCommon
                open={openPopup}
                setOpen={(value) =>
                    dispatch(openPopupAction({ open: value }))
                }
                title={getPopupTitle()}
                contentText={getContentText()}
                renderAction={getRenderAction()}
                classNameDialog="popup-confirm-delete"
                clickOutside={false}
            />
        )}
    </>;
};

export default WordList;
