import { assignDigitallySaga } from "./assign-digitally.saga";
import { conversationSaga } from "./conversation.saga";
import { crosswordSaga } from "./crossword.saga";
import { gameResourceSaga } from "./game.saga";
import { notificationSaga } from "./notification.saga";
import { quotaResourceUserSaga } from "./quotaResourceUser.saga";
import { resourceSaga } from "./resource.saga";
import { userSaga } from "./user.saga";
import { authSaga } from "./userInfoState.saga";
import { wordSearchSaga } from "./wordsearch.saga";

const rootResourceSaga = [
    ...gameResourceSaga,
    ...userSaga,
    ...resourceSaga,
    ...wordSearchSaga,
    ...assignDigitallySaga,
    ...authSaga,
    ...conversationSaga,
    ...quotaResourceUserSaga,
    ...notificationSaga,
    ...crosswordSaga,
];

export default rootResourceSaga;
