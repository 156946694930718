import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    updateIsOnDrag,
    updateOpenWarningReplaceTemplate,
    updateSelectedWorksheetId,
} from "../../../../../redux/reducers/createWorksheet";
import BreadCrumbsCollectionName from "../../../../../resource-user/components/resource/collection-detail/breadcrumbs";
import DropDownCalendar from "../../../../../resource-user/components/resource/collection-detail/drop-down-calendar/drop-down-calendar";
import NewGridWorksheet from "../../../../../resource-user/components/resource/collection-detail/new-grid-worksheet";
import { updateActionsGaSuccessAction } from "../../../../../resource-user/redux/action/resource.action";
import { ResourceAppState } from "../../../../../resource-user/redux/reducer/root.reducerModule";
import {
    findCategoryByName,
    formatMonth,
    getWorksheetFromToolV2,
} from "../../../../../resource-user/utils";
import ConstantsResource from "../../../../../resource-user/utils/ConstantsResource";
import Constants from "../../../../../resource-user/utils/constants";
import { stringifyAndParse } from "../../../../../shared/utils";
import Config from "../../../../../shared/utils/config";
import NavTabProject from "../nav";
import SearchInProject from "../search-in-project";
import "./style.scss";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import { requestInitPageWs } from "../../../../../redux/async/createWorksheet";
import { toggleShowListActivities } from "../../../../../redux/reducers/leftData";
import Routes from "../../../../../resource-user/utils/routes";
const WIDTH_WS = 136.5;
const WorksheetsTabProject = () => {
    const dispatch = useAppDispatch();
    const screenData = {
        column: 2,
        height: 1,
        width: 1,
    };
    const [activeNav, setActiveNav] = useState(
        ConstantsResource.TAB_PROJECT.YOUR_EDITS
    );
    const [lastKeyword, setLastKeyword] = useState<string>("");
    const [worksheets, setWorksheets] = useState([]);
    const [dateSelected, setDateSelected] = useState(Constants.DEFAULT_DATE);
    const [dateCollectionSelected, setDateCollectionSelected] = useState({});

    let subCollections = useAppSelector(
        (state: ResourceAppState) => state.resourceState?.collections?.data
    );
    const numberRenderResource = useAppSelector(
        (state) => state.createWorksheetState.numberRenderResource
    );
    let collectionSelected = useAppSelector(
        (state: ResourceAppState) =>
            state.resourceState?.collectionSelected?.data
    );
    const loadingLeft = useAppSelector((state) => state.leftDataState.loading);
    const loadingListResource = useAppSelector(
        (state) => state.resourceState?.collections?.loading
    );
    const loadingResource = useAppSelector(
        (state) => state.resourceState?.collectionSelected?.loading
    );
    const dataTab = useAppSelector((state) => state.leftDataState.dataTab);

    let loading =
        activeNav === ConstantsResource.TAB_PROJECT.COLLECTION
            ? collectionSelected?.id &&
              collectionSelected?.id !=
                  Config.COLLECTION_MY_COLLECTIONS.COLLECTION_MY_COLLECTIONSid
                ? loadingResource
                : loadingListResource
            : loadingLeft;
    const onDragResourceItem = (e, resource) => {
        e.dataTransfer.setData("worksheet", JSON.stringify(resource));
        dispatch(updateIsOnDrag(true));
        return;
    };
    const onDropResourceItem = (e) => {
        e.preventDefault();
        dispatch(updateIsOnDrag(false));
    };
    const updateActionsGaSuccessFC = ({ eventsGa }: { eventsGa?: any[] }) => {
        dispatch(
            updateActionsGaSuccessAction({
                eventsGa,
            })
        );
    };
    const getDate = (value, timeUpdateAllWs) => {
        if (value?.typeGrid == Config.TYPE_GRID.TYPE_FOLDER_COLLECTION) {
            return value?.createDate;
        }
        return value?.updatedAt > timeUpdateAllWs
            ? value?.updatedAt
            : value?.createdDate
            ? value?.createdDate
            : value?.createDate;
    };
    const selectWorksheetFc = (e, worksheet) => {
        e.stopPropagation();
        e.preventDefault();

        if (worksheet?.id) {
            const href = window?.location?.pathname;
            if (
                !href.includes(Routes.CUSTOMIZE_WORKSHEET) &&
                !href.includes(Routes.WORKSHEET_MAKER)
            ) {
                if (
                    !href.includes(Routes.WORD_SEARCH_MAKER) &&
                    [
                        Config.USER_CREATED_TOOL_V2_WORD_SEARCH,
                        Config.USER_CREATED_WORD_SEARCH,
                    ].includes(worksheet?.originUrl)
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.HANDWRITING_WORKSHEET) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_HAND_WRITING
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.NAME_TRACING) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_NAME_TRACING
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.FILL_IN_BLANK) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.CROSSWORD) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_CROSSWORD
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.ADDITION) &&
                    worksheet?.originUrl == Config.USER_CREATED_TOOL_V2_ADDITION
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.SUBTRACTION) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_SUBTRACTION
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.MULTIPLICATION) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_MULTIPLICATION
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.DIVISION) &&
                    worksheet?.originUrl == Config.USER_CREATED_TOOL_V2_DIVISION
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                } else if (
                    !href.includes(Routes.CUSTOMIZE_WORKSHEET) &&
                    worksheet?.originUrl ==
                        Config.USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET
                ) {
                    let url =
                        Routes.CUSTOMIZE_WORKSHEET +
                        "?worksheetId=" +
                        worksheet?.id;
                    window.open(url, "_blank");
                    return;
                }
            }
            dispatch(
                updateSelectedWorksheetId({
                    worksheetId: worksheet?.id,
                })
            );
            if (numberRenderResource > ConstantsTool.INIT_NUMBER_ACTION) {
                dispatch(updateOpenWarningReplaceTemplate());
            } else {
                // dispatch(addTemplateToPersist(worksheet));
                dispatch(
                    requestInitPageWs({
                        worksheetId: worksheet.id,
                        isUserAddTemplate: true,
                        sourcePage: Config.SOURCE_USER_BEHAVIOR.PICK_TEMPLATE,
                    })
                );

                dispatch(toggleShowListActivities(true));
            }
            return;
        }
    };
    const checkCondition = ({ ws, condition }: { ws; condition }) => {
        let title = ws?.title ? ws?.title : ws?.name ? ws?.name : "";
        let description = ws?.description
            ? ws?.description
            : ws?.introductionDescription
            ? ws?.introductionDescription
            : "";
        let categoryIds = ws?.categoryIds?.length ? ws?.categoryIds : [];
        if (condition) {
            condition = false;
        }
        if (!!title) {
            condition = title?.toLowerCase()?.includes(lastKeyword);
        }

        if (!!description && !condition) {
            condition = description?.toLowerCase().includes(lastKeyword);
        }

        if (categoryIds?.length && !condition) {
            let result = findCategoryByName({
                name: lastKeyword,
            });
            if (result?.length) {
                let ids = result?.map((c) => c?.id);
                ids?.forEach((id) => {
                    if (categoryIds?.includes(id)) {
                        condition = true;
                    }
                });
            }
        }
        return condition;
    };
    const wsToolV2 = ({ worksheet, condition }: { worksheet; condition }) => {
        return (
            (worksheet?.type == Config.WORKSHEET_TYPE_CREATE_TOOLV2 ||
                getWorksheetFromToolV2(worksheet?.originUrl) ||
                worksheet?.typeGrid ==
                    Config.TYPE_GRID.TYPE_FOLDER_COLLECTION ||
                worksheet?.typeGrid ==
                    Config.TYPE_GRID.TYPE_CREATE_COLLECTION) &&
            condition
        );
    };

    const groupData = ({ data }: { data: any[] }) => {
        let worksheets = stringifyAndParse(data);
        worksheets = worksheets?.filter((ws) => {
            let condition = true;

            if (
                !!lastKeyword &&
                ws?.typeGrid != Config.TYPE_GRID.TYPE_CREATE_COLLECTION
            ) {
                condition = checkCondition({ ws, condition });
                if (
                    ws?.typeGrid == Config.TYPE_GRID.TYPE_FOLDER_COLLECTION &&
                    !condition
                ) {
                    let result = ws?.worksheets
                        ?.map((w, i) => {
                            let result = checkCondition({ ws: w, condition });

                            return wsToolV2({
                                worksheet: w,
                                condition: result,
                            });
                        })
                        ?.filter((w) => w);
                    if (result?.length > 0) {
                        condition = true;
                    }
                }
            }
            return wsToolV2({ worksheet: ws, condition });
        });

        const timeUpdateAllWs = new Date("2023-11-01T04:51:25.614Z");

        worksheets = worksheets?.sort((a, b) => {
            const timeA = getDate(a, timeUpdateAllWs);
            const timeB = getDate(b, timeUpdateAllWs);
            return new Date(timeB).getTime() - new Date(timeA).getTime();
        });
        const groups = worksheets.reduce((groups, worksheet) => {
            let date = getDate(worksheet, timeUpdateAllWs);

            const month = new Date(date).getMonth() + 1;
            const year = new Date(date).getFullYear();

            let key = month + "_" + year;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(worksheet);
            return groups;
        }, {});

        const groupArraysData = Object.keys(groups).map((date) => {
            return {
                date,
                worksheets: groups[date],
            };
        });
        let dataSelect = groupArraysData;
        if (dateSelected != Constants.DEFAULT_DATE) {
            let data = groupArraysData?.filter(
                (item: any) => item.date == dateSelected
            );

            if (data?.length == 0) {
                let object: any = {};
                object = {
                    date: dateSelected,
                    worksheets: [],
                };
                data = new Array(object);
            }
            dataSelect = data;
        }

        return dataSelect;
    };
    useEffect(() => {
        if (!loading) {
            if (
                dataTab?.length &&
                activeNav != ConstantsResource.TAB_PROJECT.COLLECTION
            ) {
                let worksheets = groupData({ data: dataTab });

                setWorksheets(worksheets);
            } else {
                setWorksheets([]);
            }
        } else {
            setWorksheets([]);
        }
    }, [JSON.stringify(dataTab), dateSelected, lastKeyword, loading]);
    useEffect(() => {
        if (loading) {
            setWorksheets([]);
        } else if (activeNav == ConstantsResource.TAB_PROJECT.COLLECTION) {
            let createWorksheet = {
                id: Config.TYPE_GRID.TYPE_CREATE_COLLECTION,
                typeGrid: Config.TYPE_GRID.TYPE_CREATE_COLLECTION,
                createDate: new Date(),
            };
            if (
                collectionSelected?.id &&
                collectionSelected?.id !=
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid
            ) {
                let data = [
                    createWorksheet,
                    ...(collectionSelected?.worksheets ?? []),
                ];
                let worksheets = groupData({
                    data,
                });
                setWorksheets(worksheets);
            } else {
                let data = [createWorksheet, ...(subCollections ?? [])];
                let worksheets = groupData({
                    data,
                });
                setWorksheets(worksheets);
            }
        }
    }, [
        JSON.stringify(subCollections),
        activeNav,
        JSON.stringify(collectionSelected),
        lastKeyword,
        loading,
        dateSelected,
    ]);

    useEffect(() => {
        let valueDate = dateCollectionSelected;
        let id = activeNav;
        if (activeNav == ConstantsResource.TAB_PROJECT.COLLECTION) {
            if (
                collectionSelected?.id &&
                collectionSelected?.id !=
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid
            ) {
                id = collectionSelected?.id;
            } else {
                id =
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid;
            }
        }
        valueDate[id] = dateSelected;
        setDateCollectionSelected(valueDate);
    }, [dateSelected]);

    return (
        <div className="grid-worksheets-project">
            <SearchInProject setLastKeyword={setLastKeyword} />
            <NavTabProject
                activeNav={activeNav}
                setActiveNav={setActiveNav}
                setDateCollectionSelected={(id) => {
                    if (id == ConstantsResource.TAB_PROJECT.COLLECTION) {
                        id =
                            Config.COLLECTION_MY_COLLECTIONS
                                .COLLECTION_MY_COLLECTIONSid;
                    }

                    let currentValueSelected = Constants.DEFAULT_DATE;
                    if (dateCollectionSelected && dateCollectionSelected[id]) {
                        currentValueSelected = dateCollectionSelected[id];
                    }
                    setDateSelected(currentValueSelected);
                }}
            />
            <div>
                {activeNav == ConstantsResource.TAB_PROJECT.COLLECTION &&
                collectionSelected?.id &&
                collectionSelected?.id !==
                    Config.COLLECTION_MY_COLLECTIONS
                        .COLLECTION_MY_COLLECTIONSid ? (
                    <BreadCrumbsCollectionName
                        collection={collectionSelected}
                        selectCollectionFc={({ collection }) => {
                            let id = collection?.id;
                            let currentValueSelected = Constants.DEFAULT_DATE;
                            if (
                                dateCollectionSelected &&
                                dateCollectionSelected[id]
                            ) {
                                currentValueSelected =
                                    dateCollectionSelected[id];
                            }
                            setDateSelected(currentValueSelected);
                        }}
                        fetchData={true}
                    />
                ) : (
                    <></>
                )}
            </div>
            {loading ? (
                <>
                    <div className="loading-container">
                        <CircularProgress></CircularProgress>
                    </div>
                </>
            ) : (
                <>
                    {worksheets?.map((data, index) => {
                        return (
                            <div
                                key={index}
                                className="item-grid-worksheets-project"
                            >
                                <div className="date-and-drop-down">
                                    <div className="date">
                                        {formatMonth({ value: data.date })}
                                    </div>
                                    {index == 0 ? (
                                        <>
                                            <DropDownCalendar
                                                dateSelected={dateSelected}
                                                setDateSelected={
                                                    setDateSelected
                                                }
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {data?.worksheets?.length ? (
                                    <div className="grid-worksheets">
                                        <NewGridWorksheet
                                            index={index}
                                            worksheets={data?.worksheets}
                                            selectWorksheetFc={
                                                selectWorksheetFc
                                            }
                                            selectCollectionFc={({
                                                collection,
                                            }) => {
                                                setDateSelected(
                                                    Constants.DEFAULT_DATE
                                                );
                                            }}
                                            WIDTH_WS={WIDTH_WS}
                                            screenData={screenData}
                                            addGaEventMoreEvent={({
                                                action,
                                            }: {
                                                action;
                                            }) => {
                                                let actionName = "";
                                                if (
                                                    action ==
                                                    Config.ACTION_GRID.EDIT
                                                ) {
                                                    actionName =
                                                        "ncw_project_collection_rename";
                                                }
                                                if (
                                                    action ==
                                                    Config.ACTION_GRID
                                                        .DELETE_COLLECTION
                                                ) {
                                                    actionName =
                                                        "ncw_project_delete_collection";
                                                }
                                                if (
                                                    action ==
                                                    Config.ACTION_GRID
                                                        .MOVE_COLLECTION
                                                ) {
                                                    actionName =
                                                        "ncw_project_move_collection";
                                                }
                                                updateActionsGaSuccessFC({
                                                    eventsGa: [
                                                        {
                                                            action: actionName,
                                                        },
                                                    ],
                                                });
                                            }}
                                            addGaEventCreateCollection={() => {
                                                updateActionsGaSuccessFC({
                                                    eventsGa: [
                                                        {
                                                            action: "ncw_project_add_new_collection",
                                                        },
                                                    ],
                                                });
                                            }}
                                            onDragResourceItem={
                                                onDragResourceItem
                                            }
                                            onDropResourceItem={
                                                onDropResourceItem
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="empty-ws" style={{}}>
                                        Empty
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default WorksheetsTabProject;
