import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Config from '../../../../../shared/utils/config';
import { updateDataAPI } from '../../../../../utils/query';

import './styles.scss';

interface DataObject {
    fieldName: string;
    fieldValue: any;
}

interface UploadLocalResourceProps {
    title: string,
    handleNewResourceData: (data) => Promise<void>,
    typeResource: number,
    acceptExtentions: string[],
    extraDatas?: DataObject[],
}

const UploadLocalResource = ({
    title = 'Upload local font family:',
    handleNewResourceData,
    typeResource = Config.RESOURCE_FONT_FAMILIES,
    acceptExtentions = ['.woff', '.woff2', '.tff', '.otf', '.ttf'],
    extraDatas = [],
}: UploadLocalResourceProps) => {
    const [resourceFile, setResourceFile] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        setResourceFile(file);
    };

    const handleUploadResourceFile = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (resourceFile) {
            const formData = new FormData();
            formData.append('file', resourceFile);
            formData.append('type', typeResource.toString())
            formData.append('extraDatas', JSON.stringify(extraDatas))

            try {
                const url = 'auto-content/upload-new-resource/';
                const res = await updateDataAPI('POST', url, formData);
                if (res.status === 200) {
                    await handleNewResourceData(res.data);
                    window.alert('Uploaded resource successfully.');
                    setResourceFile(null);
                };
            } catch (e) {
                window.alert(`And error occured when uploading font: ${e.message}`);
            }
        };
        setLoading(false);
    };

    return (
        <div className="upload-local-asset">
            <h5>{title}</h5>
            <Row>
                <Col sm="9">
                    <Form.Control
                        type="file"
                        accept={acceptExtentions.join(', ')}
                        onChange={handleFileChange}
                        className="mb-2"
                    />
                </Col>
                <Col sm="3">
                    {!loading ? (
                        <Button
                            variant="primary"
                            onClick={handleUploadResourceFile}
                        >
                            <CloudUpload />
                        </Button>
                    ) : (
                        <span>Uploading</span>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default UploadLocalResource;