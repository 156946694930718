import "./index.scss";
import {
    IResourceItemNew,
} from "../../../../../../shared/models/resourceItemNew";
import { useAppSelector } from "../../../../../../redux/hook";

import {
    Align,
    AlignItem,
    Alignment,
    getAlignHorizontalItems,
    getAlignVerticalItems,
    getSizeResourceItem,
    resourceCanAlign,
} from "../../../../../../utils/align";

function AlignElement({
    pageIndex,
    currentElement,
    activeDrag
}: {
    pageIndex: number;
    currentElement: IResourceItemNew;
    activeDrag: boolean;
}) {
    const resourceItems: IResourceItemNew[] = useAppSelector(
        (state) =>
            state.createWorksheetState.pagesWorksheet[pageIndex].resourceItems
    );
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet[pageIndex]
    );
    const isResize = useAppSelector(
        (state) => state.createWorksheetState.isResize
    );
    const ratio = useAppSelector((state) => state.createWorksheetState.ratio);

    const findAlign = () => {
        let newSize = getSizeResourceItem(
            currentElement.x,
            currentElement.y,
            currentElement.width,
            currentElement.height,
            currentElement.rotate
        );
        let y = newSize.y,
            x = newSize.x,
            width = newSize.width,
            height = newSize.height,
            maxX = x + width,
            maxY = y + height,
            aligns: Align[] = [],
            centerX = pagesWorksheet.width / 2,
            centerY = pagesWorksheet.height / 2;

        if (
            (x + width / 2 > centerX - 1 && x + width / 2 < centerX + 1) ||
            (x > centerX - 1 && x < centerX + 1) ||
            (maxX > centerX - 1 && maxX < centerX + 1)
        ) {
            aligns.push(
                new Align({
                    top: y,
                    left: pagesWorksheet.width / 2,
                    align: Alignment.centerPageV,
                    vertical: true,
                })
            );
        }

        if (
            (y + height / 2 > centerY - 1 && y + height / 2 < centerY + 1) ||
            (y > centerY - 1 && y < centerY + 1) ||
            (maxY > centerY - 1 && maxY < centerY + 1)
        ) {
            aligns.push(
                new Align({
                    top: pagesWorksheet.height / 2,
                    left: x,
                    align: Alignment.centerPageH,
                    vertical: false,
                })
            );
        }
        let alginItem = new AlignItem({
            id: currentElement.id,
            x,
            y,
            width,
            height,
            maxX,
            maxY,
        });
        let horizontalItems: AlignItem[] = [alginItem],
            verticalItems: AlignItem[] = [alginItem];

        resourceItems.forEach((resourceItem) => {
            if (resourceCanAlign(resourceItem, currentElement)) {
                let newSize = getSizeResourceItem(
                    resourceItem.x,
                    resourceItem.y,
                    resourceItem.width,
                    resourceItem.height,
                    resourceItem.rotate
                );
                let xE = newSize.x,
                    yE = newSize.y,
                    widthE = newSize.width,
                    heightE = newSize.height,
                    maxXE = xE + widthE,
                    maxYE = yE + heightE;
                if (
                    x > xE - 1 &&
                    x < xE + 1 &&
                    !aligns.find((e) => e.align === Alignment.left)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE,
                            align: Alignment.left,
                            vertical: true,
                        })
                    );
                }
                if (
                    y > yE - 1 &&
                    y < yE + 1 &&
                    !aligns.find((e) => e.align === Alignment.top)
                ) {
                    aligns.push(
                        new Align({
                            top: yE,
                            left: x,
                            align: Alignment.top,
                            vertical: false,
                        })
                    );
                }
                if (
                    maxX > maxXE - 1 &&
                    maxX < maxXE + 1 &&
                    !aligns.find((e) => e.align === Alignment.right)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: maxXE,
                            align: Alignment.right,
                            vertical: true,
                        })
                    );
                }
                if (
                    maxY > maxYE - 1 &&
                    maxY < maxYE + 1 &&
                    !aligns.find((e) => e.align === Alignment.bottom)
                ) {
                    aligns.push(
                        new Align({
                            top: maxYE,
                            left: x,
                            align: Alignment.bottom,
                            vertical: false,
                        })
                    );
                }
                if (
                    x + width / 2 > xE + widthE / 2 - 1 &&
                    x + width / 2 < xE + widthE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerVertical)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE + widthE / 2,
                            align: Alignment.centerVertical,
                            vertical: true,
                        })
                    );
                }
                if (
                    y + height / 2 > yE + heightE / 2 - 1 &&
                    y + height / 2 < yE + heightE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerHorizontal)
                ) {
                    aligns.push(
                        new Align({
                            top: yE + heightE / 2,
                            left: x,
                            align: Alignment.centerHorizontal,
                            vertical: false,
                        })
                    );
                }
                if (
                    y > maxYE - 1 &&
                    y < maxYE + 1 &&
                    !aligns.find((e) => e.align === Alignment.topBottom)
                ) {
                    aligns.push(
                        new Align({
                            top: maxYE,
                            left: x,
                            align: Alignment.topBottom,
                            vertical: false,
                        })
                    );
                }
                if (
                    maxY > yE - 1 &&
                    maxY < yE + 1 &&
                    !aligns.find((e) => e.align === Alignment.bottomTop)
                ) {
                    aligns.push(
                        new Align({
                            top: yE,
                            left: x,
                            align: Alignment.bottomTop,
                            vertical: false,
                        })
                    );
                }
                if (
                    x > maxXE - 1 &&
                    x < maxXE + 1 &&
                    !aligns.find((e) => e.align === Alignment.lefRight)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: maxXE,
                            align: Alignment.lefRight,
                            vertical: true,
                        })
                    );
                }
                if (
                    maxX > xE - 1 &&
                    maxX < xE + 1 &&
                    !aligns.find((e) => e.align === Alignment.rightLeft)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE,
                            align: Alignment.rightLeft,
                            vertical: true,
                        })
                    );
                }

                if (
                    y + height / 2 > yE - 1 &&
                    y + height / 2 < yE + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerTop)
                ) {
                    aligns.push(
                        new Align({
                            top: yE,
                            left: x,
                            align: Alignment.centerTop,
                            vertical: false,
                        })
                    );
                }

                if (
                    y + height / 2 > maxYE - 1 &&
                    y + height / 2 < maxYE + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerBottom)
                ) {
                    aligns.push(
                        new Align({
                            top: maxYE,
                            left: x,
                            align: Alignment.centerBottom,
                            vertical: false,
                        })
                    );
                }
                if (
                    x + width / 2 > xE - 1 &&
                    x + width / 2 < xE + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerLeft)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE,
                            align: Alignment.centerLeft,
                            vertical: true,
                        })
                    );
                }

                if (
                    x + width / 2 > maxXE - 1 &&
                    x + width / 2 < maxXE + 1 &&
                    !aligns.find((e) => e.align === Alignment.centerRight)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: maxXE,
                            align: Alignment.centerRight,
                            vertical: true,
                        })
                    );
                }
                if (
                    y > yE + heightE / 2 - 1 &&
                    y < yE + heightE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.topCenter)
                ) {
                    aligns.push(
                        new Align({
                            top: yE + heightE / 2,
                            left: x,
                            align: Alignment.centerTop,
                            vertical: false,
                        })
                    );
                }

                if (
                    maxY > yE + heightE / 2 - 1 &&
                    maxY < yE + heightE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.bottomCenter)
                ) {
                    aligns.push(
                        new Align({
                            top: yE + heightE / 2,
                            left: x,
                            align: Alignment.bottomCenter,
                            vertical: false,
                        })
                    );
                }
                if (
                    x > xE + widthE / 2 - 1 &&
                    x < xE + widthE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.leftCenter)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE + widthE / 2,
                            align: Alignment.leftCenter,
                            vertical: true,
                        })
                    );
                }

                if (
                    maxX > xE + widthE / 2 - 1 &&
                    maxX < xE + widthE / 2 + 1 &&
                    !aligns.find((e) => e.align === Alignment.rightCenter)
                ) {
                    aligns.push(
                        new Align({
                            top: y,
                            left: xE + widthE / 2,
                            align: Alignment.rightCenter,
                            vertical: true,
                        })
                    );
                }
                let item = new AlignItem({
                    id: resourceItem.id,
                    x: xE,
                    y: yE,
                    width: widthE,
                    height: heightE,
                    maxX: maxXE,
                    maxY: maxYE,
                });
                if (
                    (y >= yE && y <= maxYE) ||
                    (maxY <= maxYE && maxY >= yE) ||
                    (yE >= y && yE <= maxY) ||
                    (maxYE <= maxY && maxYE >= y)
                ) {
                    horizontalItems.push(item);
                }

                if (
                    (x >= xE && x <= maxXE) ||
                    (maxX > xE && maxX <= maxXE) ||
                    (xE >= x && xE <= maxX) ||
                    (maxXE > x && maxXE <= maxX)
                ) {
                    verticalItems.push(item);
                }
            }
        });

        let finalItemHorizontal = getAlignHorizontalItems(horizontalItems, 0.5);
        if (
            finalItemHorizontal.find((item) =>
                item.find((e) => e.id === currentElement.id)
            )
        ) {
            finalItemHorizontal.forEach((e) => {
                if (
                    !aligns.find(
                        (align) =>
                            align.align === Alignment.alighHorizontalCenter &&
                            align.left === e[0].maxX
                    )
                ) {
                    aligns.push(
                        new Align({
                            top: e[0].y + e[0].height / 2,
                            left: e[0].maxX,
                            align: Alignment.alighHorizontalCenter,
                            vertical: false,
                            width: e[1].x - e[0].maxX,
                        })
                    );
                }

                let dx = e[2].x - e[1].maxX;
                if (
                    !aligns.find(
                        (align) =>
                            align.align === Alignment.alighHorizontalCenter &&
                            align.left === e[2].x - dx
                    )
                ) {
                    aligns.push(
                        new Align({
                            top: e[2].y + e[2].height / 2,
                            left: e[2].x - dx,
                            align: Alignment.alighHorizontalCenter,
                            vertical: false,
                            width: dx,
                        })
                    );
                }
            });
        }
        let finalItemVertiacal = getAlignVerticalItems(verticalItems, 0.5);

        if (
            finalItemVertiacal.find((item) =>
                item.find((e) => e.id === currentElement.id)
            )
        ) {
            finalItemVertiacal.forEach((e) => {
                if (
                    !aligns.find(
                        (align) =>
                            align.align === Alignment.alighVerticalCenter &&
                            align.top === e[0].maxY
                    )
                ) {
                    aligns.push(
                        new Align({
                            top: e[0].maxY,
                            left: e[0].x + e[0].width / 2,
                            align: Alignment.alighVerticalCenter,
                            vertical: true,
                            width: e[1].y - e[0].maxY,
                        })
                    );
                }
                if (
                    !aligns.find(
                        (align) =>
                            align.align === Alignment.alighVerticalCenter &&
                            align.top === e[1].maxY
                    )
                ) {
                    let dy = e[2].y - e[1].maxY;
                    aligns.push(
                        new Align({
                            top: e[1].maxY,
                            left: e[2].x + e[2].width / 2,
                            align: Alignment.alighVerticalCenter,
                            vertical: true,
                            width: dy,
                        })
                    );
                }
            });
        }

        return aligns;
    };

    return (
        <>
            {(activeDrag || isResize) &&
                findAlign().map((align, index) => {
                    if (
                        align.align !== Alignment.centerPageH &&
                        align.align !== Alignment.centerPageV
                    ) {
                        if (align.vertical) {
                            if (align.width === null) {
                                return (
                                    <div
                                        key={index}
                                        className="align-vertical"
                                        style={{ left: align.left * ratio }}
                                    />
                                );
                            } else {
                                let d = Math.round(align.width);
                                return (
                                    <div key={index}>
                                        <div
                                            key={index}
                                            className="divider-vertical"
                                            style={{
                                                top: align.top * ratio,
                                                left: align.left * ratio,
                                                height: align.width * ratio,
                                            }}
                                        />
                                        <div
                                            className="text-padding"
                                            key={index + align.top}
                                            style={{
                                                top:
                                                    align.top * ratio +
                                                    d / 2 -
                                                    (d.toString().length * 14) /
                                                        2,
                                                left: align.left * ratio + 8,
                                            }}
                                        >
                                            {d}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (align.width === null) {
                                return (
                                    <div
                                        key={index}
                                        className="align-horizontal"
                                        style={{ top: align.top * ratio }}
                                    />
                                );
                            } else {
                                let d = Math.round(align.width);
                                return (
                                    <>
                                        <div
                                            key={index}
                                            className="divider-horizontal"
                                            style={{
                                                top: align.top * ratio,
                                                left: align.left * ratio,
                                                width: align.width * ratio,
                                            }}
                                        />
                                        <div
                                            className="text-padding"
                                            key={index + align.top}
                                            style={{
                                                top: align.top * ratio + 8,
                                                left:
                                                    align.left * ratio +
                                                    (align.width * ratio) / 2 -
                                                    (d.toString().length * 10) /
                                                        2,
                                            }}
                                        >
                                            {d}
                                        </div>
                                    </>
                                );
                            }
                        }
                    } else {
                        if (align.align === Alignment.centerPageV) {
                            return (
                                <div
                                    key={index}
                                    className="align-center-vertical"
                                />
                            );
                        }
                        if (align.align === Alignment.centerPageH) {
                            return (
                                <div
                                    key={index}
                                    className="align-center-horizontal"
                                />
                            );
                        }
                    }
                })}
        </>
    );
}

export default AlignElement;
