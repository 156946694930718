import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Collection,
    ICollection,
} from "../../../../../../shared/models/collection";
import {
    IWorksheet,
    Worksheet,
} from "../../../../../../shared/models/worksheet";
import Config from "../../../../../../shared/utils/config";
import {
    selectActionGridItemAction,
    selectGridItemAction,
} from "../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import MoveIcon from "../../../../icons/move-icon";
import "./style.scss";
const MoveAction = ({
    collection,
    worksheet,
    checkFolder,
}: {
    collection: ICollection;
    worksheet?: IWorksheet;
    checkFolder?: boolean;
}) => {
    const [open, setOpen] = useState(false);
    const [showPopupCreateCollection, setShowPopupCreateCollection] =
        useState(false);
    const isMobile = useMediaQuery("(max-width:821px)");
    const dispatch = useDispatch();
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    const openAction = (value: boolean) => {
        if (!value) {
            if (actionGridItem.action) {
                dispatch(
                    selectActionGridItemAction({
                        action: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }
            if (
                actionGridItem?.worksheet?.id ||
                actionGridItem?.collection?.id
            ) {
                dispatch(
                    selectGridItemAction({
                        collection: new Collection(),
                        worksheet: new Worksheet(),
                        toCollection: true,
                    })
                );
            }
        }
    };
    return (
        <>
            <div
                className="move"
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                    dispatch(
                        selectGridItemAction({
                            collection: collection,
                        })
                    );
                    dispatch(
                        selectActionGridItemAction({
                            action: Config.ACTION_GRID.MOVE_COLLECTION,
                        })
                    );
                }}
            >
                <MoveIcon isActive={true} />
                Move
            </div>
            {/* {open && (
                <NewSaveToCollection
                    setShowPopup={(value) => {
                        setOpen(value);
                        openAction(value);
                    }}
                    showPopup={true}
                    titlePopup={"Move to collection"}
                />
            )} */}
        </>
    );
};

export default MoveAction;
