import React, { useEffect, useRef, useState } from "react";
import { requestInitPageWs } from "../../../redux/async/createWorksheet";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
    addResourceItems,
    selectElements,
    updateIsOnDrag,
    updateOpenWarningReplaceTemplate,
    updateSelectedWorksheetId,
} from "../../../redux/reducers/createWorksheet";
import { toggleShowListActivities, updateTabData } from "../../../redux/reducers/leftData";
import { addTemplateToPersist } from "../../../redux/reducers/recentUse";
import ShapeBorder from "../../../resource-user/components/common/shape/shape-border";
import {
    getListCommands,
    resizeShape,
    roundPathCorners,
} from "../../../resource-user/utils/shape";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../shared/models/resourceItemNew";
import { IShapeAttribute } from "../../../shared/models/shapeAttribute";
import { IWorksheet } from "../../../shared/models/worksheet";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import Config from "../../../shared/utils/config";
import {
    convertListColorToObjectColor,
    eventAddResourceElement,
    generateIdFromDateTime,
    getColorsFromXML,
    getPositionResource,
    getResourceItemSize,
    getTypeFromOldResourceItem,
    makeImageAttribute,
} from "../../../utils";
import SliderTemplate from "./tab-template/SliderTemplate";
import { updateDataAPI } from "../../../utils/query";

const ResourceItemComponent = ({
    resourceItem,
    typeResource,
    classNameItem,
}: {
    resourceItem: IResourceItemNew & Partial<IWorksheet>;
    typeResource: number;
    classNameItem: string;
}) => {
    const [svgData, setSvgData] = useState({
        svgContent: "",
        colors: [],
    });
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const pageIndex = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const pageWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet[pageIndex]
    );
    const dataTab = useAppSelector(
        (state) => state.leftDataState.dataTab
    );
    const resourceItems: IResourceItemNew[] =
        pageIndex >= 0
            ? useAppSelector(
                (state) =>
                    state.createWorksheetState.pagesWorksheet[pageIndex]
                        .resourceItems
            )
            : [];

    const userId = useAppSelector(
        (state) => state.userInfoResourceState.data.id
    );

    useEffect(() => {
        if (
            typeResource !== Config.RESOURCE_TEMPLATE &&
            resourceItem?.url?.endsWith(".svg")
        ) {
            fetch(resourceItem.url)
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(new Error("Server Error"));
                    }
                    return response.text();
                })
                .then((svg) => {
                    let indexSvg = svg.indexOf("<svg");
                    let indexSvgEnd = svg.indexOf("</svg>", indexSvg);
                    let svgContent = svg.substring(indexSvg, indexSvgEnd + 7);
                    setSvgData({
                        svgContent: svgContent,
                        colors: getColorsFromXML(svgContent),
                    });
                });
        }
    }, [typeResource, resourceItem]);
    const onDragResourceItem = (e, resource) => {
        if (typeResource === Config.RESOURCE_TEMPLATE) {
            e.dataTransfer.setData("worksheet", JSON.stringify(resource));
            dispatch(updateIsOnDrag(true));
            return;
        }
        let isBackground =
            resource.type === Config.RESOURCE_BACKGROUND ||
            resource.type === Config.RESOURCE_FRAMES;
        let width = isBackground
            ? pageWorksheet.width
            : ref.current.offsetWidth;
        let height = isBackground
            ? pageWorksheet.height
            : ref.current.offsetHeight;
        e.dataTransfer.setDragImage(ref.current, 50, 50);

        let imageAttribute = {
            url: resourceItem.url,
            changeColor: convertListColorToObjectColor(svgData.colors),
        };
        imageAttribute = makeImageAttribute(resourceItem, imageAttribute);
        const size = getResourceItemSize(resourceItem, height);
        width = size?.width ?? width;
        height = size?.height ?? height;
        e.dataTransfer.setData(
            "resourceItem",
            JSON.stringify({
                ...resource,
                width,
                height,
                imageAttribute: imageAttribute,
            })
        );
    };
    const handleOnClick = () => {
        if (typeResource === Config.RESOURCE_TEMPLATE) {
            dispatch(
                updateSelectedWorksheetId({
                    worksheetId: resourceItem.id,
                })
            );
            if (pageWorksheet?.isEdited) {
                dispatch(updateOpenWarningReplaceTemplate());
            } else {
                dispatch(addTemplateToPersist(resourceItem));
                dispatch(
                    requestInitPageWs({
                        worksheetId: resourceItem.id,
                        isUserAddTemplate: true,
                        sourcePage: Config.SOURCE_USER_BEHAVIOR.PICK_TEMPLATE
                    })
                );

                if (resourceItem?.type === Config.TEMPLATE_WORKSHEET) {
                    dispatch(toggleShowListActivities(true));
                } else {
                    // dispatch(
                    //     changeActiveTab(
                    //         Config.RESOURCE_MODULES
                    //     )
                    // );
                    dispatch(toggleShowListActivities(false));
                }
            }
            return;
        }
        let isBackground =
            resourceItem.type === Config.RESOURCE_BACKGROUND ||
            resourceItem.type === Config.RESOURCE_FRAMES;
        let width = isBackground
            ? pageWorksheet.width
            : ref.current.offsetWidth;
        let height = isBackground
            ? pageWorksheet.height
            : ref.current.offsetHeight;

        const type = getTypeFromOldResourceItem(resourceItem);
        let imageAttribute = {
            url: resourceItem.url,
            changeColor: convertListColorToObjectColor(svgData.colors),
        };
        const size = getResourceItemSize(resourceItem, width);
        width = size?.width ?? width;
        height = size?.height ?? height;

        imageAttribute = makeImageAttribute(resourceItem, imageAttribute);

        const position = getPositionResource(
            width,
            height,
            resourceItems.filter((e) => e.type === type),
            { width: pageWorksheet.width, height: pageWorksheet.height }
        );

        const x = isBackground ? 0 : position.x;
        const y = isBackground ? 0 : position.y;

        let newItem;

        if (typeResource === Config.RESOURCE_COMPONENTS && resourceItem['childrenContent'] && resourceItem['childrenContent'].length > 0) {
            const childrenIDs = [];
            for (let i = 0; i < resourceItem['childrenContent'].length; i++) {
                const newID = generateIdFromDateTime();
                dispatch(
                    addResourceItems({
                        pageIndex: pageIndex,
                        resourceItems: [{...resourceItem['childrenContent'][i], id: newID}],
                    })
                );
                childrenIDs.push(newID);
            }
            newItem = new ResourceItemNew({
                ...resourceItem,
                id: generateIdFromDateTime(),
                pageIndex,
                resourceIds: childrenIDs
            });
        } else {
            newItem = new ResourceItemNew({
                ...resourceItem,
                id: generateIdFromDateTime(),
                x,
                y,
                type,
                width,
                height,
                imageAttribute: imageAttribute,
                pageIndex,
            });
        }

        dispatch(
            addResourceItems({
                pageIndex: pageIndex,
                resourceItems: [newItem],
            })
        );
        dispatch(
            selectElements({
                resourceSelected: [newItem],
                pageIndex: pageIndex,
            })
        );
        eventAddResourceElement(type);
    };
    const makeShape = (shape: IShapeAttribute) => {
        let scale = shape.width / shape.height,
            width = scale > 1 ? 60 : 60 * scale,
            height = width / scale,
            path = resizeShape(
                width,
                height,
                shape,
                getListCommands(shape.path)
            );
        path =
            shape.name === "Rounded Square"
                ? roundPathCorners(path, 12, width, height)
                : path;
        return (
            <ShapeBorder
                id={resourceItem.id}
                path={path}
                width={width}
                height={height}
                stroke={
                    resourceItem.type ===
                        ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                        ? "#E5E7EB"
                        : "#000000"
                }
                strokeWidth={1.5}
                strokeDasharray={0}
                position="static"
                fill={
                    resourceItem.type ===
                        ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
                        ? "#E5E7EB"
                        : null
                }
            />
        );
    };

    const getResourceTypeLabel = () => {
        if (typeResource === Config.RESOURCE_FRAMES) {
            return 'resource-frame';
        } else if (typeResource === Config.RESOURCE_COMPONENTS) {
            return 'resource-component';
        }
        return '';
    }

    const editResourceName = async() => {
        let resourceName = window.prompt('Resource Name', resourceItem['name']);
        if (resourceName != null) {
            try {
                await updateDataAPI('POST', 'auto-content/update-resource-name/', {
                    type: typeResource,
                    oldName: resourceItem['name'],
                    newName: resourceName
                });
                const updatedData = [...dataTab].map((item) => {
                    if (item.id === resourceItem?.id) {
                        return { ...item, name: resourceName };
                    };
                    return item;
                })
                dispatch(
                    updateTabData(updatedData)
                );
            } catch (err) {
                window.alert('can not edit resource name');
            }
        }
    }

    const removeResource = async() => {
        if (window.confirm('Are you sure to remove this resource?')) {
            console.log(resourceItem);
            await updateDataAPI('POST', 'auto-content/remove-resources-by-name/?name=' + resourceItem['name'] + `&type=${typeResource}`);
            window.alert('resource removed');
            const updatedData = [...dataTab].filter((item) => item.id != resourceItem?.id);
            dispatch(
                updateTabData(updatedData)
            );
        }
    }

    const deleteIcon = async() => {
        if (window.confirm('Are you sure to remove this icon?')) {
            console.log(resourceItem);
            await updateDataAPI('DELETE', 'resources/icons/' + resourceItem['id'] + '/');
        }
    }

    return (
        <React.Fragment>
            {resourceItem?.url?.endsWith(".svg") ? (
                <div className={classNameItem}>
                    <div
                        className="img-container"
                        draggable="true"
                        ref={ref}
                        onDragStart={(ev) => {
                            onDragResourceItem(ev, resourceItem);
                        }}
                        // onDragEnd={(ev) => onDragEnd(ev)}
                        // onClick={() => handleClick(itemValue)}
                        id={resourceItem.id}
                        onClick={handleOnClick}
                        dangerouslySetInnerHTML={{ __html: svgData.svgContent }}
                        onDragEnd={(event) => {
                            event.preventDefault();
                            dispatch(updateIsOnDrag(false));
                        }}
                    >
                        {/* <img src={resourceItem.url}></img> */}
                    </div>
                </div>
            ) : (
                <div className={classNameItem + ' ' + getResourceTypeLabel()}>
                    <div
                        className="img-container"
                        draggable="true"
                        // onDragStart={(ev) => {
                        //     onDragResourceItem(ev, itemValue);
                        // }}
                        // onDragEnd={(ev) => onDragEnd(ev)}
                        // onClick={(e) => handleClick(resourceItem)}
                        id={resourceItem.id}
                        onClick={handleOnClick}
                        onDragStart={(ev) => {
                            onDragResourceItem(ev, resourceItem);
                        }}
                        onDragEnd={(event) => {
                            event.preventDefault();
                            dispatch(updateIsOnDrag(false));
                        }}
                    >
                        {resourceItem.type ===
                            ConstantsTool.TYPE_RESOURCE_SHAPE ||
                            resourceItem.type ===
                            ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE ? (
                            <div ref={ref}>
                                {makeShape(resourceItem.shapeAttribute)}
                            </div>
                        ) : typeResource === Config.RESOURCE_TEMPLATE ? (
                            <SliderTemplate
                                listImage={resourceItem.urls}
                                thumbnail={resourceItem.url}
                            />
                        ) : (
                            <img ref={ref} src={resourceItem.url}></img>
                        )}
                        {/* <IconButton className="icon-delete" onClick={deleteIcon}>
                            <ClearIcon></ClearIcon>
                        </IconButton> */}
                    </div>
                    {[Config.RESOURCE_COMPONENTS, Config.ZONES_PAGE, Config.RESOURCE_FRAMES].includes(typeResource) && (
                        <div className="resource-name" onClick={editResourceName}>
                            {resourceItem['name']}
                        </div>
                    )}
                    {(typeResource === Config.RESOURCE_COMPONENTS || typeResource === Config.RESOURCE_FRAMES) && (
                        <span className="remove-resource-icon" onClick={removeResource}>
                            x
                        </span>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
export default ResourceItemComponent;
