import { createAsyncThunk } from "@reduxjs/toolkit";
import { pdfjs } from "react-pdf";
import ERROR_CLIENT from "../../i18n/errorClient.json";
import { getTemplateApi, searchTemplateApi, uploadResourceApi, uploadResourcePdf } from "../../repositories/leftData";
import { ResourceItem } from "../../shared/models/resourceItem";
import Config from "../../shared/utils/config";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import { convertFileToBase64 } from "../../utils";
import { setMessage } from "../reducers/snackbar";
import { RootState } from "../store";
import { getWorkSheetByUserAPI } from "../../resource-user/redux/repositories/workSheet.repositories";
import ConstantsResource from "../../resource-user/utils/ConstantsResource";
import {
    getDownloadedWorksheetByUserIdAPI,
    getListCollectionByUserAPI,
} from "../../resource-user/redux/repositories/user.repositories";
import { updateDataAPI } from "../../utils/query";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
export const uploadResourceThunk = createAsyncThunk(
    "leftData/uploadResourceThunk",
    async (
        action: {
            files: File[];
            userId: string;
            listImageBase64?: string[];
            listPreviewImage?: string[];
            isBackgroundSize?: boolean;
            uploadType?: number;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            let resultResource;
            if (action.listImageBase64.length > 0) {
                resultResource = await uploadResourcePdf({
                    userId: action.userId,
                    listImageBase64: action.listImageBase64,
                    isBackgroundSize: action.isBackgroundSize,
                });
            } else {
                if (action.files.length > 0) {
                    resultResource = await uploadResourceApi({
                        files: action.files,
                        userId: action.userId,
                        isBackgroundSize: action.isBackgroundSize,
                    });
                }
            }
            if (resultResource) {
                // need read
                // if (action.isBackgroundSize) {
                //     dispatch(uploadPageAction(resultResource));
                // }
                return resultResource ?? [];
            }
        } catch (error) {
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error);
        }
    }
);
export const dropFileThunk = createAsyncThunk(
    "leftData/dropFileThunk",
    async (
        action: {
            fileList: FileList;
            isBackgroundSize?: boolean;
            uploadType?: number;
        },
        { getState, dispatch, rejectWithValue }
    ) => {
        try {
            const fileList = action.fileList;
            const state = getState() as RootState;
            if (fileList) {
                const userId = state.userInfoResourceState.data.id;
                let listImageBase64 = [];
                let listFileError = Array.from(fileList).filter(
                    (file) =>
                        !file.type.includes("image") &&
                        file.type !== "application/pdf" &&
                        file.size < ConstantsTool.MAX_SIZE_UPLOAD_BYTES
                );
                if (listFileError.length > 0) {
                    dispatch(
                        setMessage({
                            message: ERROR_CLIENT.UPLOAD_WRONG_FILE,
                            severity: "error",
                        })
                    );
                } else {
                    for (let i = 0; i < fileList.length; i++) {
                        let file = fileList[i];
                        console.log('>', file.type);
                        if (file.type === "application/pdf") {
                            const data = await convertFileToBase64(file);
                            let pdf = await pdfjs.getDocument(data).promise;
                            const canvas = document.createElement("canvas");
                            for (let i = 0; i < pdf.numPages; i++) {
                                const page = await pdf.getPage(i + 1);
                                const viewport = page.getViewport({ scale: 1 });
                                const context = canvas.getContext("2d");
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                await page.render({
                                    canvasContext: context,
                                    viewport: viewport,
                                }).promise;
                                listImageBase64.push(canvas.toDataURL());
                            }
                            canvas.remove();
                        } else {
                            let base64 = await convertFileToBase64(fileList[i]);
                            if (typeof base64 === "string") {
                                listImageBase64.push(base64);
                            }
                        }
                    }
                    let listFileFilter = Array.from(fileList).filter(
                        (el) => el.type !== "application/pdf"
                    );
                    dispatch(
                        uploadResourceThunk({
                            files: listFileFilter,
                            userId,
                            listImageBase64: listImageBase64,
                            listPreviewImage: listImageBase64,
                            isBackgroundSize: action.isBackgroundSize,
                            uploadType: action.uploadType,
                        })
                    );
                }
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const requestGetResourceByType = createAsyncThunk(
    "leftData/requestGetResourceByType",
    async (
        action: {
            typeResource: number[];
            userId: string;
            offset: number;
            limit: number;
            orientation?: string;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            if (
                action.typeResource &&
                action.limit &&
                action.offset
            ) {
                let limit = action.limit;
                let offset = action.offset;
                const res = await updateDataAPI('POST', 'auto-content/get-resources-by-type/', {
                    type: action.typeResource,
                    offset: offset,
                    limit: limit,
                    activeResourceUpload: false,
                    orientation: action.orientation,
                });
                let listResource = res.data;
                if (listResource) {
                    return {
                        data: listResource,
                        typeResource: action.typeResource,
                    };
                }
            }
            console.log("error xxx");
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue("Error API");
        } catch (error) {
            console.log("error ", error);

            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const searchResourceByKeyword = createAsyncThunk(
    "leftData/searchResourceByKeyword",
    async (
        action: {
            typeResource: number[];
            offset: number;
            limit: number;
            keyword: string;
            orientation?: string;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            if (
                action.keyword &&
                action.typeResource &&
                action.limit &&
                action.offset
            ) {
                const res = await updateDataAPI('POST', 'auto-content/search-resources-by-keyword/', {
                    typeResource: action.typeResource,
                    offset: action.offset,
                    limit: action.limit,
                    keyword: action.keyword,
                    orientation: action.orientation,
                });
                let listResource = res.data;

                if (listResource) {
                    let listResourceClass = listResource
                        .filter((el) => el.id)
                        .map((e) => new ResourceItem(e));
                    return {
                        data: listResourceClass,
                        typeResource: action.typeResource,
                        keyword: action.keyword,
                    };
                } else {
                    return rejectWithValue("Error API");
                }
            }
        } catch (error) {
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error?.response?.data);
        }
    }
);
export const getTemplate = createAsyncThunk(
    "leftData/getTemplate",
    async (
        action: {
            worksheetType?: number;
            templateType?: string;
            offset: number;
            limit: number;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            let listTemplate = await getTemplateApi({
                worksheetType: action.worksheetType,
                templateType: action.templateType,
                offset: action.offset,
                limit: action.limit,
            });

            if (listTemplate) {
                // let listResourceClass = listResource.map(
                //     (e) => new ResourceItem(e)
                // );
                return {
                    data: listTemplate,
                    typeResource: action.templateType,
                };
            } else {
                return rejectWithValue("Error API");
            }
        } catch (error) {
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error?.response?.data);
        }
    }
);
export const searchTemplateByKeyword = createAsyncThunk(
    "leftData/searchTemplateByKeyword",
    async (
        action: {
            worksheetType?: number;
            templateType?: string;
            offset: number;
            size: number;
            keyword: string;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            let listResource = await searchTemplateApi({
                worksheetType: action.worksheetType,
                templateType: action.templateType,
                offset: action.offset,
                size: action.size,
                keyword: action.keyword,
            });

            if (listResource) {
                return {
                    data: listResource,
                    keyword: action.keyword,
                };
            } else {
                return rejectWithValue("Error API");
            }
        } catch (error) {
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const getResourceProjectData = createAsyncThunk(
    "leftData/getResourceProjectData",
    async (action: { nav }, { rejectWithValue, dispatch, getState }) => {
        try {
            const state = getState() as RootState;
            const userId = state.userInfoResourceState.data.id;
            if (userId) {
                let worksheets: any = [];
                if (action.nav == ConstantsResource.TAB_PROJECT.YOUR_EDITS) {
                    let dataWorksheets = await getWorkSheetByUserAPI({
                        id: userId,
                        worksheetType: Config.WORKSHEET_TYPE_ALL,
                        limit: Config.LIMIT_DATA.LIMIT_WORKSHEET,
                        offset: 0,
                        arrProject: ConstantsResource.PROJECT_WORKSHEET_TOOLV2,
                    });
                    worksheets = dataWorksheets?.worksheets;
                }
                if (action.nav == ConstantsResource.TAB_PROJECT.DOWNLOADS) {
                    worksheets = await getDownloadedWorksheetByUserIdAPI(
                        userId,
                        0,
                        Config.LIMIT_DATA.LIMIT_WORKSHEET,
                        Config.WORKSHEET_TYPE_ALL,
                        ConstantsResource.PROJECT_WORKSHEET_TOOLV2
                    );
                }
                if (action.nav == ConstantsResource.TAB_PROJECT.COLLECTION) {
                    let dataCollection = await getListCollectionByUserAPI({
                        userId,
                        limit: Config.LIMIT_DATA.LIMIT_WORKSHEET,
                        offset: 0,
                        parentIds: [],
                        projectData:
                            ConstantsResource.PROJECT_DATA_COLLECTION_TOOLV2,
                    });
                    worksheets = dataCollection?.collections;
                }
                return {
                    data: worksheets,
                };
            }
        } catch (error) {
            dispatch(
                setMessage({
                    severity: "info",
                    message: ERROR_CLIENT.API_DIE,
                })
            );
            return rejectWithValue(error?.response?.data);
        }
    }
);
