import { useEffect, useRef } from 'react';

const useImageLoader = (imageUrl: string, onImageLoad: () => void = () => { }) => {
    const imageRef = useRef(null);

    useEffect(() => {
        let completeInterval = null;
        let renderedInterval = null;
        let count = 0;

        const checkImageLoad = () => {
            count++;
            const theImg = imageRef.current;
            theImg.src = imageUrl;
            if (theImg.complete || count > 20) {
                clearInterval(completeInterval);
                completeInterval = null;
                count = 0;
                if (count > 20) {
                    console.error('ERROR: Could not load image');
                } else {
                    renderedInterval = setInterval(() => {
                        if (theImg.naturalHeight > 0 && theImg.naturalWidth > 0) {
                            clearInterval(renderedInterval);
                            renderedInterval = null;
                            onImageLoad();
                        }
                    }, 100);
                }
            }
        };

        completeInterval = setInterval(checkImageLoad, 450);

        return () => {
            clearInterval(completeInterval);
            clearInterval(renderedInterval);
        };
    }, []);

    return imageRef;
}

export default useImageLoader;