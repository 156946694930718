import { useState } from "react";
import { useAppSelector } from "../../../../redux/hook";
import { RootState } from "../../../../redux/store";
import { Activity } from "../../../../shared/models/WorkSheetCreator";
import { IQuestionActivity } from "../../../../shared/models/questionActivity";
import AddQuestion from "./AddQuestion";
import ListenQuestion from "./ListenQuestion";
import "./index.scss";
import Question from "./question";

const MultipleChoiceModule = ({ activity }: { activity: Activity }) => {
    const currentActivity = useAppSelector(
        (state: RootState) => state.createWorksheetState.currentActivity
    );
    const listQuestion: IQuestionActivity[] = currentActivity.questions;
    const [currentQuestion, setCurrentQuestion] = useState<number>(-1);
    const [openQuestion, setOpenQuestion] = useState<boolean>(false);
    const [addExample, setAddExample] = useState({
        number: 0,
        isAdd: false,
    });

    const handleExample = () => {
        setAddExample({
            ...addExample,
            isAdd: true,
        });
    };

    return (
        <div id="multiple-choice-container">
            <ListenQuestion
                activity={currentActivity}
                setCurrentQuestion={setCurrentQuestion}
                setOpenQuestion={setOpenQuestion}
            />
            <div className="header-info">
                <p className="header-title">Question</p>
                <p className="give-an-example" onClick={() => handleExample()}>
                    Give an example?
                </p>
            </div>
            <div className="list-question-container" id="question-container">
                <div className="questions-wrapper" id="list-question">
                    {listQuestion.map(
                        (question: IQuestionActivity, index: number) => (
                            <Question
                                activity={currentActivity}
                                key={question.id}
                                index={index}
                                openQuestion={openQuestion}
                                setOpenQuestion={setOpenQuestion}
                                question={question}
                                currentQuestion={currentQuestion}
                                setCurrentQuestion={setCurrentQuestion}
                            />
                        )
                    )}
                </div>
                <AddQuestion
                    addExample={addExample}
                    setAddExample={setAddExample}
                    activity={currentActivity}
                    listQuestion={listQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
            </div>
        </div>
    );
};

export default MultipleChoiceModule;
