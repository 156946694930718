import { POST } from ".";
import { Collection, ICollection } from "../../../shared/models/collection";
import { UserInfo } from "../../../shared/models/userInfo";
import { UserRela } from "../../../shared/models/userRela";
import { IWorksheet, Worksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import { APIConfig } from "../../utils/api-config";

export const getDownloadedWorksheetByUserIdAPI = (
    userId: string,
    offset?: number,
    limit?: number,
    worksheetType?: number,
    arrProject?: any[]
): Promise<IWorksheet[] | null> => {
    return POST({
        url: APIConfig.GET_DOWNLOADED_WORKSHEET_BY_USERid,
        params: {
            userId,
            offset,
            limit,
            worksheetType,
            arrProject,
        },
    }).then((data: any) => {
        return data
            ? data.map((worksheet: any) => new Worksheet(worksheet))
            : null;
    });
};

export const getListCollectionByUserAPI = (args: {
    userId: string;
    limit: number;
    offset: number;
    parentIds: string[];
    projectData?: any;
}): Promise<{ collections: ICollection[]; nbCollections: number }> => {
    try {
        if (!!args.userId) {
            return POST({
                url:
                    APIConfig.GET_COLLECT_BY_USERid_V2 +
                    "?" +
                    args.userId +
                    "&t=" +
                    new Date().getTime(),

                params: args,
            }).then((data: any) => {
                if (data)
                    data.collections.map((collection) => {
                        collection.typeGrid =
                            Config.TYPE_GRID.TYPE_FOLDER_COLLECTION;
                        return new Collection(collection);
                    });
                return data;
            });
        }
    } catch (error) {
        console.log("error getCollectionByUserAPI ", error);
    }
};

export const getAdditionalWorksheetsApi = (args: {
    id: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.GET_ADDITIONAL_WORKSHEET,
        params: args,
    }).then((data: any) => {
        return {
            author: new UserInfo(data.author?.id ? data.author : {}),
            follow: data.follow?.map((item) => {
                return new UserRela(item);
            }),
        };
    });
};

export const reportBugAPI = (args: {
    userId: string;
    source: string;
    title: string;
    description: string;
    urlScreenShot: string;
    urlUpload: string;
}): Promise<any> => {
    try {
        if (!args.urlScreenShot) {
            return;
        }
        return POST({
            url: APIConfig.MAKE_RP_BUG,
            params: args,
        }).then((data: any) => {
            return "Success";
        });
    } catch (err) {
        console.log("Error" + err);
    }
};

export const feedbackAPI = (args: {
    userId: string;
    source: string;
    title: string;
    description: string;
    urlScreenShot: string;
    urlUpload: string;
    urlsFeedback?: string[];
}): Promise<any> => {
    try {
        return POST({
            url: APIConfig.MAKE_RP_BUG,
            params: args,
        }).then((data: any) => {
            return "Success";
        });
    } catch (err) {
        console.log("Error" + err);
    }
};
