import { useAppDispatch } from "../../redux/hook";
import { addPageWorksheet } from "../../redux/reducers/createWorksheet";

const AddPageComponent = () => {
    const dispatch = useAppDispatch();
    return (
        <div
            style={{
                cursor: "pointer",
                padding: "8px 16px",
                color: "black",
                background: "white",
                border: "1px solid white",
                borderRadius: "12px",
                width: "fit-content",
                margin: "16px auto 0px auto",
                pointerEvents: "auto",
            }}
            onClick={() => dispatch(addPageWorksheet())}
        >
            Add page
        </div>
    );
};
export default AddPageComponent;
