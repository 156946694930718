import { pdfjs } from "react-pdf";
import { generateIdFromDateTime, getGapBetweenItem } from ".";
import { IconSVG } from "../assets/icon/icons";
import {
    updateWorksheetByIdAPI,
    uploadBase64ToGoogleCloudAPI,
} from "../resource-user/redux/repositories/workSheet.repositories";
import { Activity } from "../shared/models/WorkSheetCreator";
import { Game } from "../shared/models/game";
import { PageWorksheetNew } from "../shared/models/pageWorksheetNew";
import {
    IQuestionActivity,
    MathAttribute,
    QuestionActivity,
} from "../shared/models/questionActivity";
import { ResourceItemNew } from "../shared/models/resourceItemNew";
import { IWorksheet } from "../shared/models/worksheet";
import ConstantsTool from "../shared/utils/ConstantsTool";
import Config from "../shared/utils/config";
import { generateMathElements } from "./create-math";
import {
    getFontFamily,
    getPositionCrosswordByLayout,
    getResourceItemsDefault,
    updateLocationByIndex,
} from "./draw";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const getResourceWordSearchDefault = (activityId: string) => {
    return [
        new ResourceItemNew({
            id: "2",
            width: ConstantsTool.SMALL_SIZE_MODULE,
            height: ConstantsTool.SMALL_SIZE_MODULE,
            activityId,
            type: ConstantsTool.TYPE_RESOURCE_MODULE,
            x: ConstantsTool.WIDTH_A4 / 2 - ConstantsTool.SMALL_SIZE_MODULE / 2,
            y: ConstantsTool.DEFAULT_Y_MODULE,
        }),
        new ResourceItemNew({
            id: "word_list_title_default",
            idType: "word_list_title_default",
            width: 155,
            height: ConstantsTool.WORD_LIST_TITLE_HEIGHT_DEFAULT,
            type: ConstantsTool.RESOURCE_TEXT_MODULE,
            textAttribute: {
                fontFamily: "Nunito",
                fontSize: 26,
                content: "WORD LIST",
                isBold: true,
            },
            activityId,
            x: 336,
            y: 750,
        }),
        new ResourceItemNew({
            id: "word_direction_default",
            idType: "word_direction_default",
            width: 300,
            height: ConstantsTool.WORD_DIRECTION_HEIGHT_DEFAULT,
            activityId,
            type: ConstantsTool.TYPE_WORD_DIRECTION,
            imageAttribute: {
                svgContent: IconSVG.WORD_DIRECTION_IMAGE_0,
            },
            x: 258.5,
            y: 800,
        }),
    ];
};

const getResourceCrosswordDefault = (activityId: string) => {
    return getPositionCrosswordByLayout(
        ConstantsTool.LAYOUT.DEFAULT,
        activityId
    );
};

const getResourceHandwritingDefault = (
    activityId: string,
    resourceIds: string[],
    questionActivity: IQuestionActivity
) => {
    const heightHW =
        ConstantsTool.LINE_SPACING[
            questionActivity.handwritingAttribute.lineSpacing
        ];
    return [
        new ResourceItemNew({
            id: resourceIds[0],
            idType: resourceIds[0],
            activityId: activityId,
            type: ConstantsTool.TYPE_RESOURCE_HANDWRITING,
            width: ConstantsTool.HANDWRITING_WIDTH,
            height: heightHW + getGapBetweenItem(heightHW),
            textAttribute: {
                fontFamily: getFontFamily({
                    fontName: questionActivity.handwritingAttribute.fontFamily,
                    fontStyle: questionActivity.handwritingAttribute.fontStyle,
                    textStyle: questionActivity.handwritingAttribute.textStyle,
                }),
                content: questionActivity.question,
                color: questionActivity.handwritingAttribute.color,
                fontSize: heightHW,
                autoFill: questionActivity.handwritingAttribute.autoFill,
            },
            x: ConstantsTool.HANDWRITING_X,
            y: ConstantsTool.HANDWRITING_FIRST_PAGE_Y,
        }),
    ];
};

const getResourceNameTracingDefault = (
    activityId: string,
    type: string,
    resourceIds: string[],
    questionActivity: IQuestionActivity
) => {
    const result = [];
    const heightNT =
        ConstantsTool.LINE_SPACING[
            questionActivity.nameTracingAttribute.lineSpacing
        ];
    for (let index = 0; index < resourceIds.length; index++) {
        const resourceId = resourceIds[index];
        result.push(
            new ResourceItemNew({
                id: resourceId,
                idType: resourceId,
                activityId: activityId,
                type: ConstantsTool.TYPE_RESOURCE_NAME_TRACING,
                width: ConstantsTool.NAME_TRACING_WIDTH,
                height: heightNT + getGapBetweenItem(heightNT),
                textAttribute: {
                    fontFamily: getFontFamily({
                        fontName:
                            questionActivity.nameTracingAttribute.fontFamily,
                        fontStyle:
                            questionActivity.nameTracingAttribute.fontStyle,
                        textStyle:
                            questionActivity.nameTracingAttribute.textStyle,
                    }),
                    content: questionActivity.question,
                    color: questionActivity.nameTracingAttribute.color,
                    fontSize: heightNT,
                    autoFill: questionActivity.nameTracingAttribute.autoFill,
                },
                x: ConstantsTool.NAME_TRACING_X,
                y: ConstantsTool.NAME_TRACING_FIRST_PAGE_Y,
            })
        );
    }
    updateLocationByIndex({
        questions: [questionActivity],
        index: 0,
        pagesWorksheet: [{ resourceItems: result }],
        activity: type,
    });
    return result;
};

const getResourceMathDefault = (activity: Activity) => {
    const { resourceItems } = generateMathElements({
        activity,
        regenerate: true,
        numberOfQuestions: 10,
    });
    activity.questions = resourceItems.map(
        (item) =>
            new QuestionActivity({
                type: activity.type,
                mathAttribute: new MathAttribute(item),
            })
    );
    return resourceItems.reduce(
        (pre, item) => [...pre, ...item.resourceItems],
        []
    );
};

const getDefaultResourceFromType = (
    activity: Activity,
    resourceIds?: string[],
    questionActivity?: IQuestionActivity
) => {
    const { type, id: activityId } = activity;
    switch (type) {
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            return getResourceWordSearchDefault(activityId);
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            return getResourceCrosswordDefault(activityId);
        case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
            return getResourceHandwritingDefault(
                activityId,
                resourceIds,
                questionActivity
            );
        case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
            return getResourceNameTracingDefault(
                activityId,
                type,
                resourceIds,
                questionActivity
            );
        case Config.ACTIVITY_TYPE.ADDITION.TYPE:
        case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
        case Config.ACTIVITY_TYPE.DIVISION.TYPE:
        case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
            return getResourceMathDefault(activity);
        default:
            return [];
    }
};
export const genPageDefault = async ({
    activity,
    resourceIds,
    questionActivity,
    worksheet,
    originWorksheetId,
}: {
    activity: Activity;
    resourceIds?: string[];
    questionActivity?: IQuestionActivity;
    worksheet?: IWorksheet;
    originWorksheetId?: string;
}) => {
    if (worksheet?.id) {
        let imgBackground = worksheet.game.images;
        // images in worksheet.game.images contain logo and Qr code.
        // when user save template, the template will be dup logo and Qr
        // Therefore we take data in worksheet.game.original ( file pdf)
        // then convert it into .png and save it to worksheet.game.original for reuse purpose
        if (worksheet.game.original && worksheet.game.original.length > 0) {
            // in the first time, convert process
            if (worksheet.game.original[0].endsWith(".pdf")) {
                imgBackground = await convertPdfToImagesAndUpload(
                    worksheet.game.original[0],
                    false
                );
                let newGame = new Game({
                    ...worksheet.game,
                    original: imgBackground,
                });
                updateWorksheetByIdAPI({
                    worksheetId: originWorksheetId,
                    game: newGame,
                });
            } else {
                // after that, we reuse result in the first time
                imgBackground = worksheet.game.original;
            }
        }
        return imgBackground.map((img) => {
            return new PageWorksheetNew({
                id: generateIdFromDateTime(),
                width: ConstantsTool.WIDTH_A4,
                height: ConstantsTool.HEIGHT_A4,
                resourceItems: [
                    new ResourceItemNew({
                        id: resourceIds[0],
                        idType:
                            "background_ws_pdf_" +
                            new Date().getTime().toString() +
                            Math.floor(Math.random() * 9999),
                        activityId: activity.id,
                        type: ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE,
                        width: ConstantsTool.WIDTH_A4,
                        height: ConstantsTool.HEIGHT_A4,
                        imageAttribute: {
                            url: img,
                        },
                        x: 0,
                        y: 0,
                        url: img,
                    }),
                ],
                sourcePage: Config.SOURCE_USER_BEHAVIOR.CLICK_CUSTOMIZE_WS,
            });
        });
    } else {
        return [
            new PageWorksheetNew({
                id: generateIdFromDateTime(),
                width: ConstantsTool.WIDTH_A4,
                height: ConstantsTool.HEIGHT_A4,
                resourceItems: [
                    ...getResourceItemsDefault(activity.id, activity.type),
                    ...getDefaultResourceFromType(
                        activity,
                        resourceIds,
                        questionActivity
                    ),
                ],
                sourcePage: Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH,
            }),
        ];
    }
};

export const convertPdfToImagesAndUpload = async (
    fileOrUrl: any,
    isAddWatermark?: boolean
) => {
    const images: any = [];
    let pdf;
    if (typeof fileOrUrl == "string") {
        pdf = await pdfjs.getDocument(fileOrUrl).promise;
    } else {
        const readFileData = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e.target.result);
                };
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.readAsDataURL(file);
            });
        };
        const data = await readFileData(fileOrUrl);
        pdf = await pdfjs.getDocument(data).promise;
    }

    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport })
            .promise;
        images.push(canvas.toDataURL());
    }
    canvas.remove();

    let urlImgs: any = await uploadBase64ToGoogleCloudAPI({
        imagesBase64: images,
        isAddWatermark: isAddWatermark,
    });

    return urlImgs;
};
