import React, { ReactNode, useState } from 'react';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { ITextAttribute } from '../../../../../../../shared/models/resourceItemNew';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Image from 'react-bootstrap/Image';
import { getPrefixImage } from '../../../../../../../utils';

export default function TextStroke({
    textAttribute,
    handleChangeTextStroke,
    handleChangeStrokeWidth,
    chooseColorChildren,
}: {
    textAttribute: ITextAttribute;
    handleChangeTextStroke: (value: boolean) => void;
    handleChangeStrokeWidth: (value: number) => void;
    chooseColorChildren: ReactNode,
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [strokeWidth, setStrokeWidth] = useState<number>(textAttribute.strokeWidth);

    const onShowTextStrokeMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseTextStrokeMenu = () => {
        setAnchorEl(null);
    };

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeTextStroke(event.target.checked);
    }

    const onChangeStrokeWidth = (event, newValue: number | number[]) => {
        setStrokeWidth(newValue as number);
        handleChangeStrokeWidth(newValue as number);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Stack direction="row">
                <Box
                    width={24}
                    height={24}
                    mx={1}
                    onClick={onShowTextStrokeMenu}
                >
                    <Image
                        width={"100%"}
                        src={
                            getPrefixImage() +
                            "/images/functional-icon/" +
                            (textAttribute.isStroke ? 'text-stroke-active-icon.png' : 'text-stroke-icon.png')
                        }
                    />
                </Box>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseTextStrokeMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        width={300}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={4}
                        p={2}
                    >
                        <Box
                            display="flex"
                            width={"100%"}
                            alignItems="center"
                        >
                            <Typography fontSize={15}>
                                Active:
                            </Typography>
                            <Checkbox
                                size="medium"
                                checked={textAttribute.isStroke}
                                onChange={handleCheckBoxChange}
                            />
                        </Box>
                        <Box
                            display="flex"
                            width={"100%"}
                            gap={1}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography fontSize={15}>
                                Size:
                            </Typography>
                            <Slider
                                value={strokeWidth}
                                defaultValue={strokeWidth}
                                onChange={onChangeStrokeWidth}
                                aria-label="Default"
                            />
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <input
                                    style={{ textAlign: 'center' }}
                                    name='quantity'
                                    step={1}
                                    min="1"
                                    type="number"
                                    value={strokeWidth}
                                    onChange={(e) => onChangeStrokeWidth(e, parseInt(e.target.value))}
                                    max="100" />
                                <Typography fontSize={15}>
                                    px
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            height={350}
                            width={"100%"}
                            gap={6}
                        >
                            <Typography fontSize={15}>
                                Color:
                            </Typography>
                            {chooseColorChildren}
                        </Box>
                    </Box>
                </Popover>
            </Stack>
        </div>
    );
}