import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GetHintWse from "./get-hint-wse";
import ResetPaintPuzzle from "./reset-paint-puzzle";
import "./style.scss";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
// const GetHintWse = React.lazy(() => import("./get-hint-wse"));
// const ResetPaintPuzzle = React.lazy(() => import("./reset-paint-puzzle"));
const ListActionWordSearch = () => {
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const isFinish = useSelector(
        (state: ResourceAppState) => state.gameResourceState.isFinish
    );

    if (isTablet) {
        if (isFinish) {
            return <></>;
        }
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                    padding: "0 18px",
                }}
            >
                <GetHintWse />
                <ResetPaintPuzzle />
            </div>
        );
    }

    return (
        <div
            className="list-action-word-search"
            style={{ pointerEvents: "auto", zIndex: 1 }}
        >
            <div className="list-action-word-search-fixed">
                <div className="list-action-content">
                    <GetHintWse />
                    <ResetPaintPuzzle />
                </div>
            </div>
        </div>
    );
};

export default ListActionWordSearch;
