import { useEffect, useState } from "react";
import {
    IResourceItemNew,
} from "../../../../../../shared/models/resourceItemNew";
// import "./index.scss";

import ShapeBorder from "../../../../../../resource-user/components/common/shape/shape-border";
import {
    getListCommands,
    resizeShape,
    roundPathCorners,
} from "../../../../../../resource-user/utils/shape";
import { useAppSelector } from '../../../../../../redux/hook';
import ConstantsTool from "../../../../../../shared/utils/ConstantsTool";
import { getZoneLabel } from "../../../../../../utils";
import LoopIcon from '@mui/icons-material/Loop';
import { IShapeAttribute } from '../../../../../../shared/models/shapeAttribute';

export const setPath = (width: number, height: number, shape: IShapeAttribute, commands: string[][]): string => {
    let path = ""
    path = resizeShape(width, height, shape, commands);
    return roundPathCorners(path, shape.radius, width, height);
};

export const makeContent = (
    width: number,
    height: number,
    color: string,
    colorBorder: string,
    resourceItem: IResourceItemNew,
    setCurrentElement: any,
    position: "static" | "relative" | "absolute" | "sticky" | "fixed" = "absolute",
) => {
    const shape = resourceItem.shapeAttribute;
    const [commands, setCommands] = useState([]);

    let path = setPath(width, height, shape, commands);

    const {pageType, templateZoneDisplayMode} = useAppSelector(state => state.createWorksheetState);

    useEffect(() => {
        if (resourceItem != null && commands.length) {
            setCurrentElement({
                ...resourceItem,
                shapeAttribute: resourceItem.shapeAttribute,
            });
        }
        setCommands(getListCommands(resourceItem.shapeAttribute.path));
    }, [resourceItem?.shapeAttribute?.path]);

    return (
        <div style={{ opacity: resourceItem.opacity, width: resourceItem.width, height: resourceItem.height }} className="d-flex justify-content-center align-items-center">
            {shape.strokeWidth >= 0 && (
                <ShapeBorder
                    id={resourceItem.id}
                    path={path}
                    width={width}
                    height={height}
                    stroke={colorBorder}
                    strokeWidth={shape.strokeWidth}
                    strokeDasharray={shape.strokeDasharray}
                    fill={color}
                    position={position}
                />
            )}
            {(Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) || ['thumbnail'].includes(pageType)) &&
            ['zoneOnly'].includes(templateZoneDisplayMode)
                && [ConstantsTool.TYPE_RESOURCE_ZONE, ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE].includes(resourceItem.type)
                && resourceItem.fieldName && (
                <div className="custom-zone-name">
                    <div>
                        {resourceItem.shuffle && (
                            <span className="custom-zone-suffle">
                                <LoopIcon fontSize='medium' color='success' />
                            </span>
                        )}
                    {getZoneLabel(resourceItem)}</div>
                    {resourceItem?.fieldDescription && (
                        <div>{resourceItem.fieldDescription}</div>
                    )}
                </div>
            )}
        </div>
    );
};

function ResourceNormalShapeView({
    resourceItem,
    currentItem,
    setCurrentElement,
    pageIndex,
}: {
    resourceItem: IResourceItemNew;
    currentItem?: IResourceItemNew;
    pageIndex: number;
    setCurrentElement: any;
}) {
    // useEffect(() => {
    //     dispatch(updateResourceItem({
    //         currentElementId: resourceItem.id,
    //         attrValues: [
    //             {
    //                 attr: "textAttribute",
    //                 value: {
    //                     ...resourceItem.textAttribute,
    //                     isAppliedAutoFontSize: false,
    //                 },
    //             },
    //         ],
    //         pageIndex: pageIndex
    //     }));
    // }, [resourceItem?.textAttribute?.isAppliedAutoFontSize === true]);

    return makeContent(
        currentItem?.width ?? resourceItem.width,
        currentItem?.height ?? resourceItem.height,
        currentItem?.imageAttribute?.changeColor["color"] ??
        resourceItem?.imageAttribute?.changeColor["color"] ??
        "#E5E7EB",
        currentItem?.imageAttribute?.changeColor["colorBorder"] ??
        resourceItem?.imageAttribute?.changeColor["colorBorder"] ??
        "#E5E7EB",
        currentItem ?? resourceItem,
        setCurrentElement,
    );
}

export default ResourceNormalShapeView;
