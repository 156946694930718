import { POST } from ".";
import { APIConfig } from "../../utils/api-config";

export const getNumberOfNotificationsAPI = (args: { toUserId: string }) => {
    return POST({
        url: APIConfig.GET_NUMBER_OF_NOTIFICATIONS,
        params: args,
    }).then((response) => {
        // return { nbNotifications: 1, nbUnreadMessages: 1 };
        return response;
    });
};

export const getSubmittedNotificationAPI = (args: {
    toUserId: string;
    lastUpdated: Date;
    limit: number;
}) => {
    return POST({
        url: APIConfig.GET_SUBMITED_NOTIFICATION_OF_USER,
        params: args,
    }).then((response) => response);
};

export const setAllNotificationReadStatusAPI = (args: { toUserId: string }) => {
    return POST({
        url: APIConfig.SET_ALL_NOTIFICATION_READ_STATUS,
        params: args,
    }).then((response) => response);
};

export const setNotificationReadDetailAPI = (args: { id: string }) => {
    return POST({
        url: APIConfig.SET_NOTIFICATION_READ_DETAIL,
        params: args,
    }).then((response) => response);
};

export const getNotificationDetailApi = (args: {
    id: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.GET_DETAIL_SUBMIT_NOTIFICATION,
        params: { id: args.id },
    }).then((data: any) => {
        return data;
    });
};
