import CheckBoxAndButton from "./button";
import InputSection from "./input";
import LayoutDropDown from "./layout";
import "./style.scss";

const Crossword = () => {
    return (
        <div className="cross-word-main">
            <InputSection />
            <div className="cluster-layout-button">
                <LayoutDropDown />
                <CheckBoxAndButton />
            </div>
        </div>
    );
};

export default Crossword;
