import React, { useEffect, useRef, useState } from "react";
import { updateResourceItems } from "../../../../../../redux/reducers/createWorksheet";
import { TableAttribute } from "../../../../../../shared/models/tableAttribute";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import ExtensionTable from "./ extensionTable";
import "./index.scss";
import {
    checkHeightCellTable,
    checkWidthCellTable,
} from "../../../../../../utils/table";

function ResizeTable({
    currentElement,
    setCurrentElement,
    rowAdd,
    setRowAdd,
    colAdd,
    setColAdd,
    activeResize,
    checkAdd,
    setCheckAdd,
}: {
    currentElement?: IResourceItemNew;
    setCurrentElement: any;
    rowAdd: number;
    setRowAdd: any;
    colAdd: number;
    setColAdd: any;
    activeResize: boolean;
    checkAdd: number;
    setCheckAdd: any;
}) {
    let table = currentElement?.tableAttribute;
    const [rowIndex, setRow] = useState(-1);
    const [colIndex, setCol] = useState(-1);
    const [isResize, setIsResize] = useState(false);
    const dispatch = useAppDispatch();
    const ratio = useAppSelector((state) => state.createWorksheetState.ratio);
    const refDivider = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     document.addEventListener("mouseup", onMouseUp);
    //     return () => {
    //         document.removeEventListener("mouseup", onMouseUp);
    //     };
    // });

    const onMouseMove = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.preventDefault();
        if (refDivider?.current) {
            const minD = 30;
            const checkHeight = (row: number, dHeight: number) => {
                let check = true;
                for (let col = 0; col < table.data[row].length; col++) {
                    let cell = table.data[row][col];
                    let height = cell.height + dHeight;
                    check = checkHeightCellTable({
                        tableAttribute: table,
                        row,
                        col,
                        newHeight: height,
                    });
                    if (height < minD && height < cell.height) {
                        check = false;
                    }
                    if (!check) {
                        break;
                    }
                }
                return check;
            };

            const checkWidth = (col: number, dWidth: number) => {
                let check = true;
                for (let i in table.data) {
                    let width = table.data[i][col].width;
                    let newWidth = width + dWidth;
                    if (newWidth < minD && newWidth < width) {
                        check = false;
                        break;
                    }
                }
                return check;
            };

            let d = 0;
            if (isResize) {
                let rect = refDivider.current.getBoundingClientRect();
                let tableAttribute = new TableAttribute(table);
                let dHeight = 0;
                if (rowIndex >= 0) {
                    let dHeight = (event.clientY - rect.y) / ratio;
                    let check1 = checkHeight(rowIndex, -dHeight);
                    let check2 = true;
                    if (rowIndex - 1 >= 0) {
                        check2 = checkHeight(rowIndex - 1, dHeight);
                    }
                    if (check1 && check2) {
                        tableAttribute.data[rowIndex].forEach((cell) => {
                            cell.height -= dHeight;
                            cell.originHeight = cell.height;
                        });
                        if (rowIndex - 1 >= 0) {
                            tableAttribute.data[rowIndex - 1].forEach(
                                (cell) => {
                                    cell.height += dHeight;
                                    cell.originHeight = cell.height;
                                }
                            );
                        }
                    }
                }
                if (colIndex >= 0) {
                    let dWidth = (event.clientX - rect.x) / ratio;
                    let check1 = checkWidth(colIndex, -dWidth);
                    let check2 = true;
                    if (colIndex - 1 >= 0) {
                        check2 = checkWidth(colIndex - 1, dWidth);
                    }
                    if (check1 && check2) {
                        for (let i in table.data) {
                            let cell = tableAttribute.data[i][colIndex];
                            let newWidth = cell.width - dWidth;
                            if (!cell.positionParent && cell.colspan === 1) {
                                let dHeightRow = checkWidthCellTable({
                                    tableAttribute,
                                    row: parseFloat(i),
                                    col: colIndex,
                                    newWidth,
                                });
                                tableAttribute.data[i].forEach((cell) => {
                                    cell.height += dHeightRow;
                                });
                                dHeight += dHeightRow;
                            }
                            tableAttribute.data[i][colIndex].width = newWidth;
                        }
                        if (colIndex - 1 >= 0) {
                            for (let i in table.data) {
                                let cell = tableAttribute.data[i][colIndex - 1];
                                let newWidth = cell.width + dWidth;
                                if (
                                    !cell.positionParent &&
                                    cell.colspan === 1
                                ) {
                                    let dHeightRow = checkWidthCellTable({
                                        tableAttribute,
                                        row: parseFloat(i),
                                        col: colIndex - 1,
                                        newWidth,
                                    });
                                    tableAttribute.data[i].forEach((cell) => {
                                        cell.height += dHeightRow;
                                    });
                                    dHeight += dHeightRow;
                                }
                                tableAttribute.data[i][colIndex - 1].width =
                                    newWidth;
                            }
                        }
                    }
                }

                setCurrentElement({
                    ...currentElement,
                    tableAttribute,
                    height: currentElement.height + dHeight,
                });
            }
        }
    };

    const onMouseUp = () => {
        if (isResize) {
            setIsResize(false);
            setCol(-1);
            setRow(-1);
            if (currentElement)
                dispatch(
                    updateResourceItems({
                        pageIndex: currentElement.pageIndex,
                        resourceItems: [{ ...currentElement }],
                    })
                );
        }
    };
    if (!table) {
        return null;
    }
    return (
        <div
            className={
                "resize-table-element full-size " +
                (isResize ? "can-click" : "not-click") +
                (isResize
                    ? colIndex >= 0
                        ? " active-rize-col"
                        : " active-rize-row"
                    : "")
            }
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
            {currentElement && activeResize && (
                <ExtensionTable
                    ref={refDivider}
                    currentElement={currentElement}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    setRow={setRow}
                    setCol={setCol}
                    setIsResize={setIsResize}
                    isResize={isResize}
                    setCurrentElement={setCurrentElement}
                    rowAdd={rowAdd}
                    setRowAdd={setRowAdd}
                    colAdd={colAdd}
                    setColAdd={setColAdd}
                    checkAdd={checkAdd}
                    setCheckAdd={setCheckAdd}
                />
            )}
        </div>
    );
}

export default ResizeTable;
