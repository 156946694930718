import { Button, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    scrambleCrosswordAction,
    updateShowAnswerKeyAction,
    updateShowWordBankAction,
} from "../../../../resource-user/redux/action/crossword.action";
import "./style.scss";
import { sendEvent } from "../../../../resource-user/utils/event";
import IconUnChecked from "../../../../resource-user/components/icons/check-box-icon/un-checked";
import IconChecked3 from "../../../../resource-user/components/icons/check-box-icon/checked-icon";

const CheckBoxAndButton = () => {
    const dispatch = useAppDispatch();
    const showWordBank = useAppSelector(
        (state) => state.crosswordState.showWordBank
    );
    const showAnswerKey = useAppSelector(
        (state) => state.crosswordState.showAnswerKey
    );
    return (
        <div className="cluster-checkbox-button">
            <div className="group-checkbox">
                <div className="item-checkbox">
                    <div className="title">Show The Word Bank</div>
                    <Checkbox
                        icon={<IconUnChecked />}
                        checkedIcon={<IconChecked3 />}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            dispatch(updateShowWordBankAction(checked));
                            sendEvent(
                                `crw_${checked ? "" : "not_"}show_the_word_bank`
                            );
                        }}
                        checked={showWordBank}
                        size="small"
                        sx={{
                            "&.Mui-checked": {
                                color: "#3B82F6",
                            },
                        }}
                    />
                </div>
                <div className="item-checkbox">
                    <div className="title">Show The Answer Key</div>
                    <Checkbox
                        icon={<IconUnChecked />}
                        checkedIcon={<IconChecked3 />}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            dispatch(updateShowAnswerKeyAction(checked));
                            sendEvent(
                                `crw_${
                                    checked ? "" : "not_"
                                }show_the_answer_key`
                            );
                        }}
                        checked={showAnswerKey}
                        size="small"
                        sx={{
                            "&.Mui-checked": {
                                color: "#3B82F6",
                            },
                        }}
                    />
                </div>
            </div>
            <div
                className="button-scramble-crossword"
                onClick={() => {
                    sendEvent("crw_scramble");
                    dispatch(scrambleCrosswordAction());
                }}
            >
                Scramble
            </div>
        </div>
    );
};

export default CheckBoxAndButton;
