import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import "./index.scss";

const WordBankWordScramble = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    const {
        content,
        fontSize,
        fontFamily,
        align,
        fontColor,
        underline,
        isBold,
        isItalic,
    } = resourceItem.textAttribute;
    const { color } = resourceItem.imageAttribute.changeColor;

    return (
        <div
            className="word-bank-scramble-container"
            style={
                {
                    fontSize,
                    color: fontColor,
                    fontStyle: isItalic ? "italic" : "normal",
                    fontWeight: isBold ? "bold" : "normal",
                    borderColor: color,
                    "--align-wb": align,
                    "--underline-wb": underline ? "underline" : "unset",
                    "--font-family-wb": fontFamily,
                } as any
            }
            dangerouslySetInnerHTML={{ __html: content }}
        ></div>
    );
};

export default WordBankWordScramble;
