import { Collection, ICollection } from "../../../shared/models/collection";
import {
    DownloadWorksheet,
    IDownloadWorksheet,
} from "../../../shared/models/downloadWorksheets";
import {
    IPracticeWorksheet,
    PracticeWorksheet,
} from "../../../shared/models/practiceWorksheets";
import {
    IUserInfo,
    IUserSurvey,
    UserInfo,
} from "../../../shared/models/userInfo";
import { IUserRela, UserRela } from "../../../shared/models/userRela";
import Config from "../../../shared/utils/config";
import { APIConfig } from "../../utils/api-config";
import { DELETE, GET, POST } from "./index";

export const getMoreProfileInfoAPI = (args: {
    userId: string;
    authorId: string;
}) => {
    return POST({
        url: APIConfig.GET_MORE_PROFILE_INFO,
        params: args,
    }).then((data: any) => {
        return data;
    });
};

export const getUserInfoAPI = (args: {
    id: string;
}): Promise<IUserInfo | null> => {
    return GET({
        url: APIConfig.USER_INFO + "/" + args.id,
    }).then((data: any) => {
        return new UserInfo(data);
    });
};
export const updateUserEmailAPI = (args: {
    email: string;
    id: string;
    verifyUrl?: string;
    survey: IUserSurvey;
    loginDate: Date;
    teacherLogin?: boolean;
}): Promise<IUserInfo | null> => {
    return POST({
        url: APIConfig.UPDATE_USER_EMAIL,
        params: args,
    }).then((data: any) => {
        return new UserInfo(data);
    });
};
export const updateUserIsVerifiedAPI = (args: {
    isVerify: boolean;
    id: string;
}): Promise<IUserInfo | null> => {
    return POST({
        url: APIConfig.UPDATE_USER_IS_VERIFIED,
        params: args,
    }).then((data: any) => {
        return new UserInfo(data);
    });
};
export const updateUserSentEmailAPI = (args: {
    sentEmail: boolean;
    id: string;
}): Promise<IUserInfo | null> => {
    return POST({
        url: APIConfig.UPDATE_USER_SENT_EMAIL,
        params: args,
    }).then((data: any) => {
        return new UserInfo(data);
    });
};
export const updateUserSurveyAPI = (args: {
    survey: any;
    id: string;
}): Promise<IUserInfo | null> => {
    return POST({
        url: APIConfig.UPDATE_USER_SURVEY,
        params: args,
    }).then((data: any) => {
        return new UserInfo(data);
    });
};
export const getUserRelaAPI = (args: {
    id: string;
}): Promise<IUserInfo | null> => {
    return GET({
        url: APIConfig.FOLLOW + "/" + args.id,
    }).then((data: any) => {
        return data;
    });
};

export const userFollowAPI = (args: {
    userId: string;
    followerId: string;
}): Promise<IUserRela | null> => {
    return POST({
        url: APIConfig.FOLLOW,
        params: args,
    }).then((data: any) => {
        return new UserRela(data);
    });
};

export const userUnFollowAPI = (args: {
    id: string;
}): Promise<boolean | null> => {
    return DELETE({
        url: APIConfig.FOLLOW + "/" + args.id,
    }).then((data: any) => {
        if (data) {
            return true;
        }
        return false;
    });
};

export const getCollectionByUserAPI = (args: {
    id: string;
    limit: number;
    offset: number;
    parentIds?: any; //[]
}): Promise<ICollection[]> => {
    try {
        if (!!args.id) {
            let parentIds = args?.parentIds ?? [];
            return GET({
                url:
                    APIConfig.GET_COLLECT_BY_USERid +
                    "/" +
                    args.id +
                    "?t=" +
                    new Date().getTime() +
                    "&limit=" +
                    args.limit +
                    "&offset=" +
                    args.offset,
                params: {
                    parentIds,
                },
            }).then((data: any) => {
                if (data)
                    data.collections.map((collection) => {
                        collection.typeGrid =
                            Config.TYPE_GRID.TYPE_FOLDER_COLLECTION;
                        return new Collection(collection);
                    });
                return data;
            });
        }
    } catch (error) {
        console.log("error getCollectionByUserAPI ", error);
    }
};

export const updateUserInfo = (args: {
    userId: string;
    categoryScore?: any;
    doneSurvey?: number;
}): Promise<any | null> => {
    return POST({
        url: APIConfig.UPDATE_USER_INFO,
        params: args,
    }).then((data: any) => {
        return data;
    });
};
export const getDownloadWorksheetsByUser = (args: {
    id: string;
    offset: number;
    worksheetType: number;
}): Promise<IDownloadWorksheet | null> => {
    return POST({
        url: APIConfig.GET_DOWNLOADED_WORKSHEET,
        params: {
            userId: args.id,
            offset: args.offset,
            worksheetType: args.worksheetType,
        },
    }).then((data: any) => {
        return new DownloadWorksheet(data);
    });
};

export const getPracticeWorksheetsByUser = (args: {
    id: string;
    offset: number;
    worksheetType: number;
}): Promise<IPracticeWorksheet | null> => {
    return POST({
        url: APIConfig.GET_PRACTICE_WORKSHEET,
        params: {
            userId: args.id,
            offset: args.offset,
            worksheetType: args.worksheetType,
        },
    }).then((data: any) => {
        return (
            data &&
            data.map((dt: any) => {
                return new PracticeWorksheet(dt);
            })
        );
    });
};

export const getDraftCollectionByUserAPI = (args: {
    id: string;
}): Promise<ICollection[]> => {
    return GET({
        url: APIConfig.GET_COLLECT_BY_USERid + "/" + args.id,
    }).then((data: any) => {
        return data && data.map((collection) => new Collection(collection));
    });
};

export const getNumberWorksheetCreatedAPI = (args: {
    id: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.GET_NUMBER_WORKSHEET_CREATED,
        params: {
            id: args.id,
        },
    }).then((data: any) => {
        return data;
    });
};

export const sendEmailVerifyAPI = (args: { userId: string }): Promise<any> => {
    return POST({
        url: APIConfig.SEND_EMAIL_VERIFY,
        params: {
            userId: args.userId,
            verifyUrl:
                window.location.origin + "/verify-account/" + args.userId,
        },
    }).then((data: any) => {
        return data;
    });
};

export const updateDoneSurveyRating = ({
    userId,
    newListSurvey,
}: {
    userId: string;
    newListSurvey: string[];
}) => {
    return POST({
        url: APIConfig.UPDATE_DONE_SURVEY_RATING,
        params: {
            userId,
            newListSurvey,
        },
    }).then((data: any) => {
        return data;
    });
};

export const updateProfile = (args: {
    userId: string;
    avatar: string;
    name: string;
    gender: string;
    country?: string;
    dateOfBirth?: Date;
    // imageCountry?: string;
}): Promise<any | null> => {
    return POST({
        url: "/update-user-info",
        params: args,
    }).then((data: any) => {
        return data;
    });
};
