import { Fragment, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import Config from "../../../../../shared/utils/config";
import ResourceItemComponent from "../../ResourceItem";
import UploadLocalResource from '../upload-local-resource';
import { requestGetResourceByType } from '../../../../../redux/async/leftData';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';
import Divider from '@mui/material/Divider';
import SelectFrameOrientation from './select-frame-orientation';

function TabFrames({
    activeTab,
    parent,
    dataTab,
    listIdBackgroundUsing,
    currentStore,
}: {
    dataTab: any[];
    activeTab: number;
    parent: HTMLDivElement;
    listIdBackgroundUsing: string[];
    currentStore: number | null;
}) {
    const dispatch = useAppDispatch();

    const listBackgroundUsing = useAppSelector(
        (state) => state.leftDataState.listBackgroundUsing
    );
    const refSearch = useRef<HTMLDivElement>(null);
    const [heightSearchBar, setHeightSearchBar] = useState(0);
    const userId = useAppSelector(
        (state) => state.userInfoResourceState.data.id
    );
    const [newFrameOrientation, setNewFrameOrientation] = useState<string>(ConstantsTool.FRAME_ORIENTATION_CHOICES['vertical']);

    useEffect(() => {
        setHeightSearchBar(refSearch?.current?.offsetHeight);
    }, []);

    const handleUploadFrame = async (data) => {
        dispatch(
            requestGetResourceByType({
                typeResource: [Config.RESOURCE_FRAMES],
                limit: ConstantsTool.LIMIT_RESOURCE,
                userId: userId,
                offset: 1,
            })
        );
    };

    return (
        <Fragment>
            <div
                ref={refSearch}
                style={{
                    position: "fixed",
                    padding: "12px 16px 0 16px",
                    width: parent?.offsetWidth - 16 * 2 - 2, //2: border
                    top: parent?.getBoundingClientRect()?.top + 60,
                    left: parent?.getBoundingClientRect()?.left,
                    zIndex: 4,
                    background: "white",
                }}
            >
            </div>
            <div
                style={{
                    height: heightSearchBar,
                }}
            />
            <UploadLocalResource
                title='Upload local frame:'
                typeResource={Config.RESOURCE_FRAMES}
                handleNewResourceData={handleUploadFrame}
                acceptExtentions={['.png', '.jpeg', '.jpg']}
                extraDatas={[{ 'fieldName': 'orientation', 'fieldValue': newFrameOrientation }]}
            />
            <SelectFrameOrientation 
                currentOrientation={newFrameOrientation}
                setCurrentOrientation={setNewFrameOrientation}
            />
            {listBackgroundUsing.length ?
                (<div className="list-resource-item">
                    {
                        listBackgroundUsing.map((el) => {
                            return (
                                <ResourceItemComponent
                                    key={el.resourceItem.id + "-" + el.pageIndex}
                                    resourceItem={el.resourceItem}
                                    typeResource={activeTab}
                                    classNameItem={
                                        activeTab === Config.RESOURCE_TEMPLATE
                                            ? "template-resource-item"
                                            : "resource-item"
                                    }
                                ></ResourceItemComponent>
                            );
                        })
                    }
                </div>) : (<></>)}
            {currentStore != null && (
                <Fragment>
                    <Divider>CURRENT STORE FRAMES: {currentStore}</Divider>
                    <div className="list-resource-item">
                        {dataTab
                            .filter(
                                (resourceItem) =>
                                    !listIdBackgroundUsing.includes(resourceItem.id)
                            )
                            .filter((resourceItem) => resourceItem.store.includes(currentStore))
                            .map((resourceItem) => {
                                return (
                                    <ResourceItemComponent
                                        key={resourceItem.id}
                                        resourceItem={resourceItem}
                                        typeResource={Config.RESOURCE_FRAMES}
                                        classNameItem={
                                            activeTab === Config.RESOURCE_TEMPLATE
                                                ? "template-resource-item"
                                                : "resource-item"
                                        }
                                    ></ResourceItemComponent>
                                );
                            })}
                    </div>
                </Fragment>
            )}
            <Divider>OTHER FRAMES</Divider>
            <div className="list-resource-item">
                {dataTab
                    .filter(
                        (resourceItem) =>
                            !listIdBackgroundUsing.includes(resourceItem.id)
                    )
                    .filter((resourceItem) => !resourceItem.store.includes(currentStore))
                    .map((resourceItem) => {
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={Config.RESOURCE_FRAMES}
                                classNameItem={
                                    activeTab === Config.RESOURCE_TEMPLATE
                                        ? "template-resource-item"
                                        : "resource-item"
                                }
                            ></ResourceItemComponent>
                        );
                    })}
            </div>
        </Fragment>
    );
}

export default TabFrames;
