import { useEffect } from "react";
import { useAppSelector } from "../../redux/hook";
import NotificationSave from "../../resource-user/components/word-search/right-panel/notification-save/NotificationSave";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";

const Notification = ({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: Function;
}) => {
    const ratio = useAppSelector((state) => state.createWorksheetState.ratio);
    const isSave = useAppSelector((state: any) => state.wordSearchState.isSave);
    let ratioProps = ratio + 0.22;
    if (ratioProps > 1) {
        ratioProps = 1;
    } else if (ratioProps < 0.55) {
        ratioProps = 0.55;
    }

    useEffect(() => {
        if (currentElement && isSave) {
            setCurrentElement(null);
        }
    }, [isSave]);

    return <NotificationSave ratioProps={ratioProps} />;
};

export default Notification;
