import { useDispatch, useSelector } from "react-redux";
import { IParamsQuotaResourceUser } from "../../../../../../../shared/models/quotaResourceuser";
import Config from "../../../../../../../shared/utils/config";
import { updateQuotaUserAction } from "../../../../../../redux/action/quotaResourceUser.action";
import { ResourceAppState } from "../../../../../../redux/reducer/root.reducerModule";
import ItemMethod, { STATUS_BTN } from "../layout-item-method";
const InviteFriendMethod = ({ item, addEventGaFc, clickLabelFc }) => {
    const dispatch = useDispatch();

    const userInfo = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data
    );

    const quotaResourceUserState = useSelector(
        (state: ResourceAppState) => state?.quotaResourceUserState?.data
    );

    const inviteFriend = useSelector(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.inviteFriend
    );

    const handleClaimDownload = () => {
        let params: IParamsQuotaResourceUser = {
            userId: userInfo.id,
        };
        let bonusQuota;

        params = {
            ...params,
            inviteFriend: 0,
        };
        bonusQuota =
            quotaResourceUserState?.inviteFriend *
            Config.BONUS_QUOTA_DOWNLOAD_EACH_INVITE_FRIEND;
        params = {
            ...params,
            bonusQuota,
        };
        addEventGaFc({ item });
        dispatch(updateQuotaUserAction({ params }));
        // dispatch(updateIncreaseQuotaResourceAction(userInfo.id));
    };

    return (
        <ItemMethod
            item={item}
            showBtnClaimMethod={
                inviteFriend >= 1 ? STATUS_BTN.able : STATUS_BTN.non
            }
            handleClaimDownload={handleClaimDownload}
            ableClaim={true}
            numberTime={inviteFriend}
            clickLabelFc={clickLabelFc}
        />
    );
};

export default InviteFriendMethod;
