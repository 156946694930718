import React from "react";
import WordList from "../../../resource-user/components/word-search/left-component/word-list";
import WordSearchShape from "../../../resource-user/components/word-search/left-component/word-search-shape";
import WordSearchSize from "../../../resource-user/components/word-search/left-component/word-search-size";
import WordDirections from "../../../resource-user/components/word-search/left-component/word-directions";
import EndCheckBox from "../../../resource-user/components/word-search/left-component/end-check-box";
import ButtonScramble from "../../../resource-user/components/word-search/right-panel/button-scramble";
import { Activity } from "../../../shared/models/WorkSheetCreator";

const WordSearchGameView = ({ activity }: { activity: Activity }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <WordList />
            <div
                style={{
                    display: "flex",
                    background: "#F9FAFB",
                    padding: "8px",
                    gap: "8px",
                    flexDirection: "column",
                }}
            >
                <WordSearchShape />
                <WordDirections />
                <WordSearchSize />
                <EndCheckBox />
                <ButtonScramble />
            </div>
        </div>
    );
};

export default WordSearchGameView;
