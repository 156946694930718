import { useEffect } from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import CreateWSConfig from "../../../../../utils/create-ws-config";
import "./index.scss";
import { ConstantsNameTracing } from "../../../../ModulesContent/name-tracing/util/constant";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import Config from "../../../../../shared/utils/config";

const ResourceNameTracing = ({
    resourceItem,
    ratio,
}: {
    resourceItem: IResourceItemNew;
    ratio: number;
}) => {
    const color = resourceItem.textAttribute?.color;
    const fontColor = resourceItem.textAttribute?.fontColor;
    const fontSize = resourceItem.textAttribute?.fontSize;
    const autoFill = resourceItem.textAttribute?.autoFill;
    let textContent = resourceItem.textAttribute?.content?.trim() ?? "";
    if (textContent === ConstantsNameTracing.PLACEHOLDER) {
        textContent = ConstantsNameTracing.PLACEHOLDER.replace(
            ConstantsNameTracing.PREFIX,
            ""
        );
    }
    let fontFamily = resourceItem.textAttribute.fontFamily;
    let isOpacityFontFamily = false;
    if (fontFamily.startsWith(CreateWSConfig.OPACITY_FONT_FAMILY)) {
        fontFamily = fontFamily.replace(CreateWSConfig.OPACITY_FONT_FAMILY, "");
        isOpacityFontFamily = true;
    }

    useEffect(() => {
        const divElement = document.getElementById("nt-" + resourceItem.id);
        const mainTextElement = divElement?.querySelector(".main-text");
        const text: any = divElement?.querySelector(`span:first-child`);
        const spanElement = `<span class="canvas-text">${textContent}  </span>`;
        if (divElement && text && mainTextElement) {
            let content = "";
            if (autoFill) {
                const divWidth =
                    ConstantsTool.NAME_TRACING_WIDTH * ratio - ratio * 10;
                const spanWidth = text.getBoundingClientRect().width;
                let timeRepeat = Math.floor(divWidth / spanWidth);

                if (timeRepeat === 0) {
                    timeRepeat = 1;
                }
                for (let index = 0; index < timeRepeat; index++) {
                    content += spanElement;
                }
            } else {
                content = spanElement;
            }
            mainTextElement.innerHTML = content;
        }
    }, [autoFill, fontSize, resourceItem.width]);

    return (
        <div
            id={"nt-" + resourceItem.id}
            className={`resource-name-tracing item-${Config.ACTIVITY_TYPE.NAME_TRACING.TYPE}`}
            style={{
                height: fontSize,
                fontSize: fontSize - fontSize / 5.6,
            }}
        >
            <div
                className="border"
                style={{
                    height: fontSize * 0.94,
                    color,
                    borderColor: color,
                }}
            >
                <div
                    className="line"
                    style={{
                        borderColor: color,
                    }}
                ></div>
            </div>
            <div
                className={`canvas-text main-text`}
                style={{
                    fontFamily: `'${fontFamily}'`,
                    opacity: isOpacityFontFamily ? 0.3 : 1,
                    color: fontColor,
                }}
            >
                <span className="canvas-text">
                    {textContent}
                    {"  "}
                </span>
            </div>
        </div>
    );
};

export default ResourceNameTracing;
