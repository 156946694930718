import React from "react";
import "./index.scss";
import { IUndoRedo } from "..";
import { RedoIcon, UndoIcon } from "../../icons/main-header";

const UndoRedo = ({ undoRedoPayload }: { undoRedoPayload?: IUndoRedo }) => {
    if (!undoRedoPayload) {
        return null;
    }

    const { canUndo, canRedo, undoFunction, redoFunction } = undoRedoPayload;

    return (
        <div className="undo-redo-container">
            <div
                className={`btn-function ${canUndo ? "active" : ""}`}
                onClick={() => {
                    if (!canUndo) {
                        return;
                    }
                    undoFunction();
                }}
            >
                <UndoIcon />
            </div>
            <div
                className={`btn-function ${canRedo ? "active" : ""}`}
                onClick={() => {
                    if (!canRedo) {
                        return;
                    }
                    redoFunction();
                }}
            >
                <RedoIcon />
            </div>
        </div>
    );
};

export default UndoRedo;
