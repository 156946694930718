import { useEffect, useState } from "react";
import { AddIcon } from "../../../../../../assets/icon";
import { useAppDispatch } from "../../../../../../redux/hook";
import {
    addAnswerMC,
    updateAnswersMC,
} from "../../../../../../redux/async/multipleChoiceThunk";
import { setMessage } from "../../../../../../redux/reducers/snackbar";
import { IActivity } from "../../../../../../shared/models/WorkSheetCreator";
import {
    AnswerActivity,
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../shared/models/questionActivity";
import { generateIdFromDateTime } from "../../../../../../utils";
import { listAnswerKey } from "../../../util";

const AddAnswer = ({
    activity,
    question,
    setChosenAnswer,
    setUserChangeCol,
}: {
    activity: IActivity;
    question: IQuestionActivity;
    setChosenAnswer?: Function;
    setUserChangeCol?: Function;
}) => {
    const dispatch = useAppDispatch();
    const listQuestionActivity: IQuestionActivity[] = activity.questions;
    const currentQuestionIndex: number = listQuestionActivity.findIndex(
        (ele) => ele.id == question.id
    );
    const [changeOthers, setChangeOthers] = useState(false);

    useEffect(() => {
        if (changeOthers) {
            const nextQuestion: IQuestionActivity = listQuestionActivity.find(
                (question, index) => index == currentQuestionIndex + 1
            );
            dispatch(
                updateAnswersMC({
                    question: question,
                    nextQuestion: nextQuestion,
                    numberCol: question.multipleChoicesAttribute.numberCol,
                })
            );
        }
        setChangeOthers(false);
    }, [changeOthers]);

    const handleAddNewAnswer = () => {
        if (question.answers.length < listAnswerKey().length) {
            const answer: IAnswerActivity = new AnswerActivity({
                id: generateIdFromDateTime(),
                isCorrect: false,
                text: ``,
                indexWord: 0,
            });
            dispatch(
                addAnswerMC({
                    question: question,
                    answer: answer,
                })
            );
            if (listQuestionActivity[currentQuestionIndex + 1]) {
                setChangeOthers(true);
            }
            setUserChangeCol(true);
            setChosenAnswer(question.answers.length);
        } else {
            setMessage({
                severity: "error",
                message: "Quantity of answers is over !",
            });
        }
    };

    return (
        <div className="add-new-option" onClick={handleAddNewAnswer}>
            <AddIcon />
            Add New Option
        </div>
    );
};

export default AddAnswer;
