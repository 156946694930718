import { useEffect, useState, useSyncExternalStore } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
    addResourceItems,
    deleteResourceItemTmp,
    removeResourceItems,
    selectElements,
    updateResourceItems,
} from "../../../redux/reducers/createWorksheet";
import { IPageWorksheetNew } from "../../../shared/models/pageWorksheetNew";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../shared/models/resourceItemNew";
import { TableAttribute } from "../../../shared/models/tableAttribute";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import Config from "../../../shared/utils/config";
import groupElementStore from "../../../syncExternalStore/groupElementStore";
import {
    canDeleteElement,
    generateIdFromDateTime,
    listResourceCanDuplicate,
} from "../../../utils";
import { groupElements } from "../CreateWorksheet/ElementWorksheet/MoreAction/Group";
import { getMaxHeightRowCell } from "../../../utils/table";

export const resourceCanGroup = (element: IResourceItemNew) => {
    return (
        element != null &&
        listResourceCanDuplicate(element) &&
        canDeleteElement(element)
    );
};

export const makeResourceItemGroup = (dispatch: any, pageIndex: number, setCurrentElement: any) => {
    dispatch(deleteResourceItemTmp());
    let item = groupElementStore.makeResourceItemGroup(false, true);
    if (item) {
        dispatch(
            addResourceItems({
                pageIndex,
                resourceItems: [item],
                isAddHistory: false,
            })
        );
        setCurrentElement({ ...item });
        groupElementStore.updateSelectedId(null);
    }
};

function ListenShortcut({
    currentElement,
    setCurrentElement,
    pagesWorksheet,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    pagesWorksheet: IPageWorksheetNew[];
}) {
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const dispatch = useAppDispatch();
    const [resourceItems, setResourceItems] = useState<IResourceItemNew[]>([]);
    const [isKeepShiftKey, setIsKeepShiftKey] = useState(false);
    const pageIndex = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const [countPaste, setCountPaste] = useState(1);
    const {currentElementFocus} = useAppSelector(state => state.createWorksheetState);

    useEffect(
        () => {
            document.addEventListener("keydown", listenKeyDown);
            return () => {
                document.removeEventListener("keydown", listenKeyDown);
            };
        }
    );

    useEffect(() => {
        document.addEventListener("keyup", listenKeyUp);
        return () => {
            document.removeEventListener("keyup", listenKeyUp);
        };
    });

    useEffect(() => {
        if (
            isKeepShiftKey &&
            currentElement?.id !== groupElementState.resourceGroupTmp?.id
        ) {
            if (
                currentElement === null ||
                currentElement?.type === Config.RESOURCE_BACKGROUND ||
                currentElement?.type === Config.RESOURCE_FRAMES ||
                currentElement?.type ===
                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
            ) {
                dispatch(deleteResourceItemTmp());
                groupElementStore.resetResourceItems();
            }
            addResourceItemsSelectedWhenShift();
        } else if (
            ((groupElementState.resourceGroupTmp &&
                groupElementState.resourceGroupTmp?.id !==
                currentElement?.id) ||
                currentElement === null ||
                currentElement?.type === Config.RESOURCE_BACKGROUND ||
                currentElement?.type === Config.RESOURCE_FRAMES ||
                currentElement?.type ===
                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE) &&
            currentElement?.isGroup !== true
        ) {
            dispatch(deleteResourceItemTmp());
            groupElementStore.resetResourceItems();
        }
    }, [currentElement?.id]);

    const copyResourceItemsToClipboard = (items = resourceItems) => {
        const resourceItemsString = JSON.stringify(items);
        const resourceItemsSource = window.location.href;

        const clipboardData = `${resourceItemsString}\nSource: ${resourceItemsSource}`;

        navigator.clipboard.writeText(clipboardData)
            .then(() => {
                console.log('Resource items and source copied to clipboard');
            })
            .catch((error) => {
                console.error('Failed to copy resource items and source to clipboard:', error);
            });
    };

    const readResourceItemsFromClipboard = async () => {
        let resourceItems = [];
        let resourceItemsSource = '';

        try {
            const clipboardText = await navigator.clipboard.readText();

            const lines = clipboardText.split('\n');

            let resourceItemsString = '';

            for (const line of lines) {
                if (line.startsWith('Source: ')) {
                    resourceItemsSource = line.substring('Source: '.length);
                } else {
                    resourceItemsString += line;
                }
            }

            resourceItems = JSON.parse(resourceItemsString);
        } catch (error) {
            console.error('Failed to read data from clipboard:', error);
        }

        return {
            resourceItems,
            resourceItemsSource
        }
    };

    const resourceCanCopy = () => {
        return (
            currentElement != null &&
            (listResourceCanDuplicate(currentElement) ||
                canDeleteElement(currentElement) ||
                currentElement.type === Config.RESOURCE_FRAMES ||
                currentElement.type ===
                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE)
        );
    };

    const listenKeyUp = (event: KeyboardEvent) => {
        if (groupElementState.resourceItemsSelected.length === 1) {
            groupElementStore.resetResourceItems();
        }

        setIsKeepShiftKey(false);
    };

    const listenKeyDown = (event: KeyboardEvent) => {
        if (currentElement === null || !currentElementFocus) {
            return;
        }
        if (event.ctrlKey || event.metaKey) {
            switch (event.key) {
                case "c":
                    // console.log("coppy");
                    copy(event);
                    break;
                case "x":
                    // console.log("cut");
                    copy(event, true);
                    break;
                case "v":
                    // console.log("paste");
                    event.preventDefault();
                    paste(dispatch);
                    break;
                case "a":
                    event.preventDefault();
                    // console.log("all");
                    chooseAll();
                    break;
                case "g":
                    event.preventDefault();
                    // console.log("group");
                    if (event.shiftKey) {
                        unGroup();
                        return;
                    } else {
                        group();
                    }
                    break;
            }
        }
        if (event.key === "Delete" || event.key === "Backspace") {
            deleteResourceItem();
        }
        if (event.shiftKey) {
            setIsKeepShiftKey(true);
            addResourceItemsSelectedWhenShift();
        }
    };

    const addResourceItemsSelectedWhenShift = () => {
        if (
            currentElement &&
            resourceCanGroup(currentElement) &&
            currentElement.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
        ) {
            if (groupElementState.pageIndex != pageIndex) {
                groupElementStore.updatePageIndex(pageIndex);
            }
            if (
                groupElementState.resourceGroupTmp?.id !==
                currentElement.id &&
                !groupElementState.resourceItemsSelected.find(
                    (resourceItem) => resourceItem.id === currentElement.id
                )
            ) {
                groupElementStore.addResourceItem(
                    currentElement,
                    pagesWorksheet[pageIndex].resourceItems
                );
                if (groupElementState.resourceItemsSelected.length > 1) {
                    makeResourceItemGroup(dispatch, pageIndex, setCurrentElement);
                    console.log('c', currentElement);
                }
            }
        }
    };

    const copy = (event: KeyboardEvent, isCut?: boolean) => {
        event.preventDefault();
        if (currentElement) {
            setCountPaste(1);
            if (groupElementState.resourceItemsSelected.length > 0) {
                let items = groupElementState.resourceItemsSelected;
                if (groupElementState.resourceItemsCurrent.length > 0) {
                    items = groupElementState.resourceItemsCurrent;
                }
                setResourceItems(items);
                copyResourceItemsToClipboard(items);
                if (isCut === true) {
                    deleteResourceItem(true);
                }
            } else if (resourceCanCopy()) {
                const newItems = [
                    new ResourceItemNew({
                        ...currentElement,
                        id: currentElement.id,
                    }),
                ];
                setResourceItems(newItems);
                copyResourceItemsToClipboard(newItems);
                if (isCut === true) {
                    deleteResourceItem(true);
                }
            }
        }
    };

    const checkCopiedSource = (copiedSource = window.location.href) => {
        const currentSource = window.location.href;
        return copiedSource === currentSource;
    };

    const paste = async (dispatch: any) => {
        let pageWorksheet = pagesWorksheet[pageIndex];
        let items = [];
        const makeNewItem = (element: IResourceItemNew, resourceItemsSource: string) => {
            const isPasteFromSameTab = checkCopiedSource(resourceItemsSource);
            let resourceItem = JSON.parse(JSON.stringify(element));
            let isBackground =
                resourceItem.type === Config.RESOURCE_BACKGROUND ||
                resourceItem.type === Config.RESOURCE_FRAMES ||
                resourceItem.type ===
                ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE;

            let positionItem = {
                x: isPasteFromSameTab ? resourceItem.x + 20 * countPaste : resourceItem.x,
                y: isPasteFromSameTab ? resourceItem.y + 20 * countPaste : resourceItem.y,
            };
            const x = isBackground ? 0 : positionItem.x,
                y = isBackground ? 0 : positionItem.y,
                width = isBackground ? pageWorksheet.width : resourceItem.width,
                height = isBackground
                    ? pageWorksheet.height
                    : resourceItem.height;

            return new ResourceItemNew({
                ...resourceItem,
                id: generateIdFromDateTime(),
                x,
                y,
                width,
                height,
                pageIndex,
            });
        };

        let { resourceItems: copiedResourceItems, resourceItemsSource }: { resourceItems: IResourceItemNew[], resourceItemsSource: string } = await readResourceItemsFromClipboard();
        let resourcesItemGroup = copiedResourceItems.filter((item) => item.isGroup);
        let itemItemGroup = copiedResourceItems.find(
            (item) => item.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
        );
        copiedResourceItems.forEach((element) => {
            let newItem = makeNewItem(element, resourceItemsSource);
            if (itemItemGroup) {
                if (!element.isGroup) {
                    items.push(newItem);
                }
                if (
                    element.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    let ids = [];
                    element.resourceIds.forEach((resourceId) => {
                        let item = resourcesItemGroup.find(
                            (resource) => resource.id === resourceId
                        );
                        if (item) {
                            let newItem = makeNewItem(item, resourceItemsSource);
                            items.push(newItem);
                            ids.push(newItem.id);
                        }
                    });
                    newItem.resourceIds = ids;
                }
            } else {
                items.push(newItem);
            }
        });
        dispatch(
            addResourceItems({
                pageIndex: pageIndex,
                resourceItems: items,
            })
        );
        if (copiedResourceItems.length) {
            setCountPaste(countPaste + 1);
        }
        if (items.length > 1) {
            groupElementStore.resetResourceItems;
            groupElementStore.addResourceItems(items);
            makeResourceItemGroup(dispatch, pageIndex, setCurrentElement);
            console.log('d');
        } else {
            dispatch(
                selectElements({
                    resourceSelected: items,
                    pageIndex: pageIndex,
                })
            );
        }
    };

    const deleteResourceTable = (isCust: boolean) => {
        if (!isCust) {
            let tableAttribute = new TableAttribute(
                currentElement.tableAttribute
            );
            if (tableAttribute.currentPositions) {
                let dHeight = 0;
                tableAttribute.currentPositions.forEach((e) => {
                    let cell = tableAttribute.data[e.row][e.column];
                    if (cell.textAttribute.content != "") {
                        cell.textAttribute.content = "";
                        let maxHeightRow = getMaxHeightRowCell({ tableAttribute, row: e.row })
                        let minHeight =
                            tableAttribute.data[e.row][e.column].originHeight;
                        minHeight = Math.max(maxHeightRow, minHeight);
                        let dHeightRow = 0;

                        if (cell.height >= minHeight)
                            dHeightRow = minHeight - cell.height;
                        tableAttribute.data[e.row].forEach((cell) => {
                            cell.height += dHeightRow;
                        });
                        dHeight += dHeightRow;
                    }
                });
                let newResourceItem = {
                    ...currentElement,
                    tableAttribute: tableAttribute,
                    height: currentElement.height + dHeight,
                };
                dispatch(
                    updateResourceItems({
                        pageIndex: currentElement.pageIndex,
                        resourceItems: [newResourceItem],
                    })
                );
                setCurrentElement(newResourceItem);
            }
        } else {
        }
    };

    const deleteResourceItem = (isCut?: boolean) => {
        let resourceItemDelete = [...groupElementState.resourceItemsSelected];
        if (currentElement && canDeleteElement(currentElement)) {
            if (!resourceItemDelete.find((e) => e?.id === currentElement?.id))
                resourceItemDelete.push(currentElement);
            if (
                resourceItemDelete.length === 1 &&
                currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE &&
                currentElement.tableAttribute?.currentPositions?.length
            ) {
                deleteResourceTable(isCut);
                return;
            }
            if (isCut && groupElementState.resourceGroupTmp) {
                resourceItemDelete.push(groupElementState.resourceGroupTmp);
            }
            if (currentElement?.isGroup && isCut !== true) {
                dispatch(
                    removeResourceItems({
                        resourceIds: [currentElement.id],
                        pageIndex: currentElement.pageIndex,
                    })
                );
                groupElementStore.deleteResourceItem(currentElement.id);
                let item = groupElementStore.makeResourceItemGroup(true, true);
                console.log('f', item);
                if (item) {
                    if (item.resourceIds.length === 1) {
                        dispatch(
                            removeResourceItems({
                                resourceIds: [item.id],
                                pageIndex: currentElement.pageIndex,
                                isAddHistory: false,
                            })
                        );
                        dispatch(
                            updateResourceItems({
                                pageIndex: currentElement.pageIndex,
                                attributesResourceItems: [
                                    {
                                        resourceItemId: item.resourceIds[0],
                                        attrValues: [
                                            {
                                                attribute: "isGroup",
                                                value: false,
                                            },
                                        ],
                                    },
                                ],
                                isAddHistory: false,
                            })
                        );
                        setCurrentElement(null);
                    } else {
                        dispatch(
                            updateResourceItems({
                                pageIndex: currentElement.pageIndex,
                                resourceItems: [item],
                                isAddHistory: false,
                            })
                        );
                        setCurrentElement({ ...item });
                    }
                }
            } else {
                dispatch(
                    removeResourceItems({
                        resourceIds: resourceItemDelete.map((e) => e.id),
                        pageIndex: currentElement.pageIndex,
                    })
                );
                groupElementStore.resetResourceItems();
            }
        }
    };

    const chooseAll = () => {
        let resourceItems = pagesWorksheet[pageIndex].resourceItems;
        if (currentElement) {
            resourceItems = resourceItems.filter(
                (resourceItem) => resourceItem.id != currentElement.id
            );
            resourceItems.push(currentElement);
        }
        groupElementStore.addResourceItems(resourceItems);
        makeResourceItemGroup(dispatch, pageIndex, setCurrentElement);
        console.log('g');
    };

    const group = () => {
        if (
            currentElement &&
            groupElementState.resourceGroupTmp &&
            groupElementState.resourceGroupTmp.id?.includes("tmp-")
        ) {
            let item = pagesWorksheet[pageIndex].resourceItems.find(
                (resource) =>
                    resource.id === groupElementState.resourceGroupTmp.id
            );
            groupElements({
                currentElement: currentElement,
                dispatch: dispatch,
            });

            setCurrentElement({
                ...item,
                id: groupElementState.resourceGroupTmp.id,
            });
        }
    };

    const unGroup = () => {
        if (
            currentElement &&
            groupElementState.resourceGroupTmp &&
            !groupElementState.resourceGroupTmp.id?.includes("tmp-")
        ) {
            let item = pagesWorksheet[pageIndex].resourceItems.find(
                (resource) =>
                    resource.id === groupElementState.resourceGroupTmp.id
            );
            groupElements({
                currentElement: currentElement,
                dispatch: dispatch,
            });

            setCurrentElement({
                ...item,
                id: groupElementState.resourceGroupTmp.id,
            });
        }
    };

    return null;
}

export default ListenShortcut;
function getHeightTextInDIV(arg0: { textAttribute: import("../../../shared/models/resourceItemNew").ITextAttribute; width: number; }): any {
    throw new Error("Function not implemented.");
}

