import {
    IResourceItemNew
} from '../../../../../../shared/models/resourceItemNew';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hook';

import './index.scss';
import { updateResourceItem, updateTempTemplateZone } from '../../../../../../redux/reducers/createWorksheet';

function SetZoneGroupAction({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) {
    const dispatch = useAppDispatch();

    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems)
    const {tempTemplateZones} = useAppSelector(state => state.createWorksheetState)

    const applyStylesToGroup = () => {
        const customZone = tempTemplateZones.find(zone => zone.id === currentElement.customZoneID);
        if (customZone === undefined) {
            return;
        }
        if (customZone.fieldName) {
            const zones = tempTemplateZones.filter(item => item.fieldName === customZone.fieldName);

            for (let i = 0; i < zones.length; i++) {
                dispatch(updateTempTemplateZone({
                    zoneID: zones[i].id,
                    zone: {...zones[i], textAttribute: currentElement.textAttribute}
                }));
                const linkedText = resourceItems.find(item => item.customZoneID === zones[i].id);
                if (linkedText) {
                    dispatch(updateResourceItem({
                        currentElementId: linkedText.id,
                        pageIndex: linkedText.pageIndex,
                        attrValues: [
                            {
                                attr: "textAttribute",
                                value: {...currentElement.textAttribute, content: linkedText.textAttribute.content},
                            },
                        ],
                    }));
                }
            }
        }
    }

    return (
        <div className="set-zone-group-action" onClick={applyStylesToGroup}>
            Apply to Group
        </div>
    );
}

export default SetZoneGroupAction;
