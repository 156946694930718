import React from "react";

const CloseIconSvg = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.2997 5.70971C18.1129 5.52246 17.8592 5.41723 17.5947 5.41723C17.3302 5.41723 17.0766 5.52246 16.8897 5.70971L11.9997 10.5897L7.10973 5.69971C6.9229 5.51246 6.66924 5.40723 6.40473 5.40723C6.14021 5.40723 5.88656 5.51246 5.69973 5.69971C5.30973 6.08971 5.30973 6.71971 5.69973 7.10971L10.5897 11.9997L5.69973 16.8897C5.30973 17.2797 5.30973 17.9097 5.69973 18.2997C6.08973 18.6897 6.71973 18.6897 7.10973 18.2997L11.9997 13.4097L16.8897 18.2997C17.2797 18.6897 17.9097 18.6897 18.2997 18.2997C18.6897 17.9097 18.6897 17.2797 18.2997 16.8897L13.4097 11.9997L18.2997 7.10971C18.6797 6.72971 18.6797 6.08971 18.2997 5.70971Z"
                fill="black"
                fillOpacity="0.54"
            />
        </svg>
    );
};

export default CloseIconSvg;
