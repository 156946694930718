import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../redux/hook';
import { updateDataAPI } from '../../../../../../utils/query';
import { IResourceItemNew } from '../../../../../../shared/models/resourceItemNew';
import { ImageComponent } from '..';

export const autoCalculateRowsAndColumns = (totalCells: number) => {
    const parts = Math.floor(Math.sqrt(totalCells))
    const base = Math.floor(totalCells / parts);
    const remainder = totalCells % parts;
    const result = Array(parts).fill(base);

    for (let i = 0; i < remainder; i++) {
        result[i] += 1;
    }

    return result;
};

export const distributeCells = (fieldName: string) => {
    const match = fieldName.match(/season_icon_(\d+)(?:_(\d+)_(\d+))?/);
    const totalCells = match ? parseInt(match[1]) : 1;

    const [providedNbRows, providedNbColumns] = match?.slice(2) || [];

    const hasProvidedNbRowsAndColumns = providedNbRows && providedNbColumns;

    const result = hasProvidedNbRowsAndColumns
        ? Array(parseInt(providedNbRows) || 1).fill(parseInt(providedNbColumns) || 1)
        : autoCalculateRowsAndColumns(totalCells);

    return result;
};

export const SeasonRow = ({
    nbCellsPerRow,
    opacity,
    seasonIconUrl,
    width,
    rowHeight,
    svgBlobURL,
    fieldName,
    resourceItem,
    onImageLoad,
 }: {
    nbCellsPerRow: number,
    opacity: number,
    seasonIconUrl: string,
    width: number,
    rowHeight: number,
    svgBlobURL: string,
    fieldName: string,
    resourceItem: IResourceItemNew,
    onImageLoad: () => void,
 }) => {
    const cellWidth = Math.floor(width / nbCellsPerRow);
    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(${nbCellsPerRow}, 1fr)`,
    };

    return (
        <div style={gridContainerStyle}>
            {Array.from({ length: nbCellsPerRow }, (_, index) => (
                <ImageComponent
                    key={index}
                    divWidth={cellWidth}
                    divHeight={rowHeight}
                    imageUrl={seasonIconUrl}
                    opacity={opacity}
                    svgBlobURL={svgBlobURL}
                    fieldName={fieldName}
                    resourceItem={resourceItem}
                    onImageLoad={onImageLoad}
                />
            ))}
        </div>
    )
}

const ResourceSeasonImages = ({
    width,
    height,
    seasonIconAIResponseValue,
    opacity,
    svgBlobURL,
    fieldName,
    resourceItem,
    onImageLoad,
}: {
    width: number,
    height: number,
    seasonIconAIResponseValue: string,
    opacity: number,
    svgBlobURL: string,
    fieldName: string,
    resourceItem: IResourceItemNew,
    onImageLoad: () => void,
}) => {
    const { ideaTemplate, seasonIconGroups } = useAppSelector(state => state.createWorksheetState);
    const [seasonIconUrl, setSeasonIconUrl] = useState<string>('');

    const cellDistribution = distributeCells(seasonIconAIResponseValue) ?? [1];
    const nbRows = cellDistribution.length;

    const rowHeight = Math.floor(height / nbRows);

    useEffect(() => {
        const getSeasonIconUrl = async () => {
            try {
                if (seasonIconGroups[fieldName] === undefined) {
                    const res = await updateDataAPI('POST', 'auto-content/get-icon-by-season/', {
                        ideaTemplateID: ideaTemplate?.id,
                    });

                    setSeasonIconUrl(res?.data);
                } else {
                    setSeasonIconUrl(seasonIconGroups[fieldName]);
                }
            } catch (err) {
                window.alert('Check if idea has seasonal tag');
            }
        };

        getSeasonIconUrl();
    }, [seasonIconAIResponseValue]);

    return (
        <div className='d-flex flex-column align-items-center'>
            {cellDistribution.map((nbCellsPerRow, index) => (
                <SeasonRow
                    key={index}
                    nbCellsPerRow={nbCellsPerRow}
                    opacity={opacity}
                    seasonIconUrl={seasonIconUrl}
                    width={width}
                    rowHeight={rowHeight}
                    svgBlobURL={svgBlobURL}
                    fieldName={fieldName}
                    resourceItem={resourceItem}
                    onImageLoad={onImageLoad}
                />
            ))}
        </div>
    )
}

export default ResourceSeasonImages;