import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import PopupCommon from "../../../../../resource-user/components/common/pop-up";
import { changeErrorWordsCrosswordAction } from "../../../../../resource-user/redux/action/crossword.action";
import "./index.scss";

const PopupWarning = () => {
    const dispatch = useAppDispatch();
    const errorWords = useAppSelector(
        (state) => state.crosswordState.errorWords
    );

    const changeErrorWords = (errorsValue: []) => {
        dispatch(changeErrorWordsCrosswordAction(errorsValue));
    };

    const renderActionPopUp = () => {
        return (
            <div className="action">
                <div
                    className="confirm"
                    onClick={(e) => {
                        e.stopPropagation();
                        changeErrorWords([]);
                    }}
                >
                    OK
                </div>
            </div>
        );
    };

    const handleOnKeyDown = (e) => {
        e.preventDefault();
        const key = e.key;
        if (errorWords.length > 0 && key === "Enter") {
            changeErrorWords([]);
        }
    };

    const renderContent = () => {
        const invalidWords = [];
        const emptyWords = [];
        errorWords.forEach((word) => {
            const value = word.invalid.trim();

            if (value) {
                invalidWords.push(value);
            } else {
                emptyWords.push(value);
            }
        });
        const errorAZ = invalidWords.length > 0;
        const errorEmpty = emptyWords.length > 0;
        return (
            <div className="content">
                <div>
                    Only
                    {errorAZ && " a-z "}
                    {errorAZ && errorEmpty && " and "}
                    {errorEmpty && " non-empty words "} are allowed:{" "}
                    {invalidWords.join(", ")}
                </div>
            </div>
        );
    };

    return (
        <PopupCommon
            handleOnKeyDown={handleOnKeyDown}
            open={errorWords.length > 0}
            setOpen={(value) => changeErrorWords([])}
            title={"Warning"}
            renderContent={renderContent}
            renderAction={renderActionPopUp}
            classNameDialog="popup-warning-crossword"
            clickOutside={false}
        />
    );
};

export default PopupWarning;
