import React from "react";

const Row4 = () => {
    return (
        <svg
            width="165"
            height="14"
            viewBox="0 0 165 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 6.25C0.585786 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585786 7.75 1 7.75V6.25ZM13.5303 7.53033C13.8232 7.23744 13.8232 6.76256 13.5303 6.46967L8.75736 1.6967C8.46447 1.40381 7.98959 1.40381 7.6967 1.6967C7.40381 1.98959 7.40381 2.46447 7.6967 2.75736L11.9393 7L7.6967 11.2426C7.40381 11.5355 7.40381 12.0104 7.6967 12.3033C7.98959 12.5962 8.46447 12.5962 8.75736 12.3033L13.5303 7.53033ZM1 7.75H13V6.25H1V7.75Z"
                fill="#1F2937"
            />
            <path
                d="M29.75 1C29.75 0.585786 29.4142 0.25 29 0.25C28.5858 0.25 28.25 0.585786 28.25 1L29.75 1ZM28.4697 13.5303C28.7626 13.8232 29.2374 13.8232 29.5303 13.5303L34.3033 8.75736C34.5962 8.46447 34.5962 7.98959 34.3033 7.6967C34.0104 7.40381 33.5355 7.40381 33.2426 7.6967L29 11.9393L24.7574 7.6967C24.4645 7.40381 23.9896 7.40381 23.6967 7.6967C23.4038 7.98959 23.4038 8.46447 23.6967 8.75736L28.4697 13.5303ZM28.25 1L28.25 13L29.75 13L29.75 1L28.25 1Z"
                fill="#1F2937"
            />
            <path
                d="M57 7.75C57.4142 7.75 57.75 7.41421 57.75 7C57.75 6.58579 57.4142 6.25 57 6.25L57 7.75ZM44.4697 6.46967C44.1768 6.76256 44.1768 7.23744 44.4697 7.53033L49.2426 12.3033C49.5355 12.5962 50.0104 12.5962 50.3033 12.3033C50.5962 12.0104 50.5962 11.5355 50.3033 11.2426L46.0607 7L50.3033 2.75736C50.5962 2.46447 50.5962 1.98959 50.3033 1.6967C50.0104 1.40381 49.5355 1.40381 49.2426 1.6967L44.4697 6.46967ZM57 6.25L45 6.25L45 7.75L57 7.75L57 6.25Z"
                fill="#1F2937"
            />
            <path
                d="M72.25 13C72.25 13.4142 72.5858 13.75 73 13.75C73.4142 13.75 73.75 13.4142 73.75 13L72.25 13ZM73.5303 0.46967C73.2374 0.176777 72.7626 0.176777 72.4697 0.46967L67.6967 5.24264C67.4038 5.53553 67.4038 6.01041 67.6967 6.3033C67.9896 6.59619 68.4645 6.59619 68.7574 6.3033L73 2.06066L77.2426 6.3033C77.5355 6.59619 78.0104 6.59619 78.3033 6.3033C78.5962 6.01041 78.5962 5.53553 78.3033 5.24264L73.5303 0.46967ZM73.75 13L73.75 1L72.25 1L72.25 13L73.75 13Z"
                fill="#1F2937"
            />
            <path
                d="M89.5303 2.22748C89.2374 1.93459 88.7626 1.93459 88.4697 2.22748C88.1768 2.52038 88.1768 2.99525 88.4697 3.28814L89.5303 2.22748ZM97.4853 11.9931C97.8995 11.9931 98.2353 11.6573 98.2353 11.2431L98.2353 4.49309C98.2353 4.07888 97.8995 3.74309 97.4853 3.74309C97.0711 3.74309 96.7353 4.07888 96.7353 4.49309V10.4931H90.7353C90.3211 10.4931 89.9853 10.8289 89.9853 11.2431C89.9853 11.6573 90.3211 11.9931 90.7353 11.9931L97.4853 11.9931ZM88.4697 3.28814L96.955 11.7734L98.0156 10.7128L89.5303 2.22748L88.4697 3.28814Z"
                fill="#1F2937"
            />
            <path
                d="M112.835 11.8213C112.628 12.18 112.751 12.6387 113.109 12.8458C113.468 13.0529 113.927 12.93 114.134 12.5713L112.835 11.8213ZM120.209 1.60987C120.102 1.20977 119.69 0.972334 119.29 1.07954L112.77 2.82657C112.37 2.93377 112.133 3.34503 112.24 3.74513C112.347 4.14523 112.758 4.38266 113.158 4.27546L118.954 2.72254L120.507 8.5181C120.614 8.9182 121.025 9.15563 121.426 9.04843C121.826 8.94122 122.063 8.52997 121.956 8.12987L120.209 1.60987ZM114.134 12.5713L120.134 2.17898L118.835 1.42898L112.835 11.8213L114.134 12.5713Z"
                fill="#1F2937"
            />
            <path
                d="M140.835 12.5713C141.042 12.93 141.501 13.0529 141.859 12.8458C142.218 12.6387 142.341 12.18 142.134 11.8213L140.835 12.5713ZM135.678 1.07954C135.278 0.972334 134.867 1.20977 134.76 1.60987L133.013 8.12987C132.906 8.52997 133.143 8.94122 133.543 9.04843C133.943 9.15563 134.355 8.9182 134.462 8.5181L136.015 2.72254L141.81 4.27546C142.21 4.38266 142.622 4.14523 142.729 3.74513C142.836 3.34503 142.599 2.93377 142.198 2.82657L135.678 1.07954ZM142.134 11.8213L136.134 1.42898L134.835 2.17898L140.835 12.5713L142.134 11.8213Z"
                fill="#1F2937"
            />
            <path
                d="M164.134 2.17871C164.341 1.81999 164.218 1.3613 163.859 1.15419C163.501 0.947085 163.042 1.06999 162.835 1.42871L164.134 2.17871ZM156.76 12.3901C156.867 12.7902 157.278 13.0277 157.678 12.9205L164.198 11.1734C164.599 11.0662 164.836 10.655 164.729 10.2549C164.622 9.85477 164.21 9.61734 163.81 9.72454L158.015 11.2775L156.462 5.4819C156.355 5.0818 155.943 4.84437 155.543 4.95157C155.143 5.05878 154.906 5.47003 155.013 5.87013L156.76 12.3901ZM162.835 1.42871L156.835 11.821L158.134 12.571L164.134 2.17871L162.835 1.42871Z"
                fill="#1F2937"
            />
        </svg>
    );
};

export default Row4;
