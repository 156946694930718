import ExpandMore from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAssignment } from "../../../shared/models/assignment";
import { Collection } from "../../../shared/models/collection";
import Config from "../../../shared/utils/config";
import {
    getAssignmentAction,
    getClassesAction,
} from "../../redux/action/assign-digitally.action";
import {
    getDataCollectionAction,
    getListCollectionAction,
    previousActionResourceAction,
    selectActionGridItemAction,
    selectCollectionItemResourceAction,
    updateActionsGaSuccessAction,
} from "../../redux/action/resource.action";
import { removeMessageAction } from "../../redux/action/snackbar.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import LoadingComponent from "../common/loading/loading-component";
import ArrowNext from "../icons/arrow-next";
import CloseIconSvg from "../icons/close-icon";
import HintIconSvg from "../icons/hint";
import NextIconSvg from "../icons/next-icon";
import PlusIcon from "../icons/plus-icon";
import { ContentPopupAssign } from "../main-popup-save-wse/content-worksheet/assign-digitally-popup";
import FooterPopup from "./footer-popup";
import FooterPopupMoveCollection from "./footer-popup-move";
import FooterSaveAndAssign from "./footer-save-resource-assign";
import RowCollection from "./row-collection";
const NewSaveToCollection = ({
    showPopup,
    setShowPopup,
    titlePopup,
}: {
    showPopup: boolean;
    setShowPopup: Function;
    titlePopup: string;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const dispatch = useDispatch();
    let collections: any = useSelector(
        (state: ResourceAppState) => state.resourceState.collections.data
    );
    let userInfo: any = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const [showPopupCreateCollection, setShowPopupCreateCollection] =
        useState(false);
    const [closePopup, setClosePopup] = useState(false);
    const afterAction = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.afterAction
    );
    const eventsGa = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.eventsGaAfterSuccess
    );
    useEffect(() => {
        if (closePopup) {
            // let timeout = setTimeout(() => {
            //     console.log("timout");
            //     setShowPopup(false);
            // }, 5000);
            // setClosePopup(false);
            // return () => {
            //     console.log("unmount");
            //     clearTimeout(timeout);
            // };
            const timeout = setTimeout(() => {
                setShowPopup(false, true);
            }, 500);
            return () => {
                setClosePopup(false);
                clearTimeout(timeout);
            };
        } else {
            return;
        }
    }, [closePopup]);

    const closePopupFc = (showValue, doneAction = false) => {
        if (!doneAction) {
            doneAction = closePopup;
        }
        if (!doneAction) {
            doneAction = afterAction == Config.ACTION_GRID.CLEAR_WSS;
        }
        if (eventsGa?.length > 0) {
            dispatch(updateActionsGaSuccessAction({ eventsGa: [] }));
        }
        dispatch(removeMessageAction());
        setShowPopup(showValue, doneAction);
    };
    useEffect(() => {
        if (collections?.length == 0 && userInfo?.id) {
            dispatch(
                getListCollectionAction({
                    userId: userInfo?.id,
                })
            );
        }
    }, []);
    if (isMobile) {
        return (
            <Drawer
                className="popup-save-to-collection"
                anchor={"bottom"}
                onClose={(e) => {
                    closePopupFc(false);
                }}
                open={showPopup}
                variant="temporary"
                disableEnforceFocus
                sx={{ zIndex: 1400 }}
            >
                <div
                    className="close-button"
                    onClick={() => closePopupFc(false)}
                >
                    <HintIconSvg />
                </div>
                <ContentPopup
                    showPopup={showPopup}
                    setShowPopup={(value, doneAction = false) => {
                        if (!value) {
                            closePopupFc(value, doneAction);
                        } else {
                            setShowPopup(value, doneAction);
                        }
                    }}
                    setShowPopupCreateCollection={(value) =>
                        setShowPopupCreateCollection &&
                        setShowPopupCreateCollection(value)
                    }
                    setClosePopup={setClosePopup}
                    titlePopup={titlePopup}
                />
            </Drawer>
        );
    }

    return (
        <Dialog
            disableEnforceFocus
            className="popup-save-to-collection dialog"
            onClose={(e: any) => {
                e.stopPropagation();
                closePopupFc(false);
            }}
            open={showPopup}
        >
            <ContentPopup
                showPopup={showPopup}
                setShowPopup={(showValue, doneAction = false) => {
                    setShowPopup(showValue, doneAction);
                }}
                setShowPopupCreateCollection={(value) =>
                    setShowPopupCreateCollection &&
                    setShowPopupCreateCollection(value)
                }
                titlePopup={titlePopup}
                setClosePopup={setClosePopup}
            />
        </Dialog>
    );
};
const SECTION = {
    INIT: "INIT",
    SAVE_RESOURCE: "SAVE_RESOURCE",
    SAVE_ASSIGN: "SAVE_ASSIGN",
    OPEN_ALL: "OPEN_ALL",
};
const ContentPopup = ({
    showPopup,
    setShowPopup,
    setShowPopupCreateCollection,
    titlePopup,
    setClosePopup,
}: {
    showPopup: boolean;
    setShowPopup: Function;
    setShowPopupCreateCollection?: Function;
    titlePopup: string;
    setClosePopup: Function;
}) => {
    const dispatch = useDispatch();
    let refCollections = useRef(null);
    const [breadCrumbCollections, setBreadCrumbCollections] = useState([]);
    const [sectionActive, setSectionActive] = useState(SECTION.INIT);
    const [totalValue, setTotalValue] = useState({
        collection: -1,
        classes: -1,
    });

    const [animation, setAnimation] = useState("");

    let userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    let collections: any = useSelector(
        (state: ResourceAppState) => state.resourceState.collections.data
    );
    const loadingCollection = useSelector(
        (state: ResourceAppState) => state.resourceState.collections.loading
    );
    let loadingClasses = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState?.loading
    );
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    let collectionSelected = useSelector(
        (state: ResourceAppState) => state.resourceState.collectionSelected.data
    );
    let numberAllCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.collections.numberCollections
    );
    const classes = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.classes
    );
    const assignmentsDB = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.assignments
    );
    classes.forEach((classInfo, index) => {
        const assignments: IAssignment = assignmentsDB.find(
            (as) => as.classId == classInfo.id
        );
        classInfo.assignments = assignments ? [assignments] : null;
        return classInfo;
    });
    let collection = actionGridItem?.collection;
    let worksheet = actionGridItem?.worksheet;
    let worksheets = actionGridItem?.worksheets;
    let toCollection = actionGridItem.toCollection?.data;
    const eventsGa = actionGridItem?.eventsGaAfterSuccess;

    let numberSubCollections =
        actionGridItem.toCollection?.numberSubCollections;
    let arrayCollection = actionGridItem.toCollection?.arrayCollection;
    let arrayThumbnail = actionGridItem.toCollection?.arrayThumbnail;
    let loadingSelectCollection = actionGridItem.toCollection?.data?.id
        ? actionGridItem.toCollection?.loading
        : loadingCollection;
    let listCollection = actionGridItem.toCollection?.listCollection;
    let afterAction = actionGridItem?.afterAction;

    if (toCollection?.id) {
        collections = arrayCollection;
    } else {
        collections = collections;
    }
    let action = actionGridItem.action;
    const isSaveAndAssign = action == Config.ACTION_GRID.SAVE_RESOURCE_ASSIGN;
    if (toCollection?.id) {
        numberAllCollection = numberSubCollections;
    }
    const finishLoadmore = numberAllCollection <= collections.length;

    let subCollections = collections;

    if (!worksheet?.id) {
        subCollections = subCollections?.filter(
            (c) => c.id != collection?.id
        );
    }
    if (!!collection?.id) {
        subCollections = subCollections?.filter((c) => {
            return (
                c.id != collection?.id &&
                (c?.parentIds?.length
                    ? c?.parentIds[c?.parentIds?.length - 1] != collection.id
                    : true)
            );
        });
    }

    const selectCollectionFc = (id, breadCrumbCollections) => {
        let index = breadCrumbCollections?.findIndex((x) => x.id === id);

        breadCrumbCollections = breadCrumbCollections?.slice(0, index + 1);

        setBreadCrumbCollections(breadCrumbCollections);
        let collection = breadCrumbCollections[index];
        if (collection?.id != toCollection?.id) {
            listCollection = listCollection.filter((c: any) => {
                return c.isChoose;
            });
            listCollection = [...listCollection, collection];
            dispatch(
                getDataCollectionAction({
                    collection: collection,
                    userId: userInfo?.id,
                    loading: true,
                    listCollection,
                })
            );
        }
    };

    useEffect(() => {
        if (toCollection?.id) {
            let thumbnail = toCollection?.worksheets?.map((ws: any) => {
                if (ws?.typeGrid != Config.TYPE_GRID.TYPE_FOLDER_COLLECTION) {
                    return ws?.thumbnail;
                }
                return null;
            });
        }
    }, [toCollection?.id]);
    useEffect(() => {
        if (typeof window != undefined && refCollections?.current) {
            if (
                subCollections?.length == 0 &&
                arrayThumbnail?.length == 0 &&
                SECTION.OPEN_ALL == sectionActive
            ) {
                refCollections.current.style.height = "auto";
            } else {
                if (window.innerWidth < 768) {
                    refCollections.current.style.height = "310px";
                } else {
                    refCollections.current.style.height = "410px";
                }
            }
        }
    }, [subCollections, refCollections, sectionActive]);
    useEffect(() => {
        if (
            totalValue.classes != classes?.length &&
            totalValue.collection != subCollections?.length &&
            !loadingCollection &&
            !loadingClasses
        ) {
            if (
                subCollections?.length == 0 &&
                classes?.length == 0 &&
                sectionActive == SECTION.INIT
            ) {
                if (isSaveAndAssign) {
                    setSectionActive(SECTION.OPEN_ALL);
                }
                setTotalValue({
                    collection: subCollections?.length,
                    classes: classes?.length,
                });
            } else if (sectionActive == SECTION.OPEN_ALL) {
                setSectionActive(SECTION.INIT);
            }
        }
    }, [subCollections, classes, loadingCollection, isSaveAndAssign]);

    return (
        <>
            <div
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                <div
                    className={
                        "popup-content  save-to-collection " +
                        (isSaveAndAssign ? "save-assign" : "")
                    }
                >
                    <div className="title">
                        <p>{titlePopup}</p>
                        <div
                            className="close-icon"
                            onClick={() => {
                                if (eventsGa?.length > 0) {
                                    dispatch(
                                        updateActionsGaSuccessAction({
                                            eventsGa: [],
                                        })
                                    );
                                }
                                dispatch(removeMessageAction());
                                setShowPopup(
                                    false,
                                    afterAction == Config.ACTION_GRID.DELETE_WSS
                                );
                            }}
                        >
                            <CloseIconSvg />
                        </div>
                    </div>

                    <div className={"content " + (animation ?? "")}>
                        <BreadCrumb
                            selectCollectionFc={(id, listCollectionId) =>
                                selectCollectionFc(id, listCollectionId)
                            }
                            breadCrumbCollections={breadCrumbCollections}
                            setBreadCrumbCollections={(value) =>
                                setBreadCrumbCollections(value)
                            }
                            active={sectionActive}
                            setSectionActive={setSectionActive}
                        />
                        {[SECTION.SAVE_RESOURCE, SECTION.OPEN_ALL].includes(
                            sectionActive
                        ) ||
                        action == Config.ACTION_GRID.SAVE ||
                        action == Config.ACTION_GRID.MOVE_COLLECTION ? (
                            <div className="collections" ref={refCollections}>
                                <div
                                    onClick={() => {
                                        dispatch(
                                            previousActionResourceAction({
                                                previousAction:
                                                    actionGridItem.action,
                                            })
                                        );
                                        dispatch(
                                            selectActionGridItemAction({
                                                action: Config.ACTION_GRID
                                                    .CREATE_COLLECTION,
                                            })
                                        );
                                    }}
                                    className="add-new-collection"
                                >
                                    <PlusIcon />
                                    Add New Collection
                                </div>
                                {loadingSelectCollection ? (
                                    <>
                                        <LoadingComponent />
                                    </>
                                ) : subCollections?.length ? (
                                    <div>
                                        {subCollections?.map((c, idx) => {
                                            return (
                                                <RowCollection
                                                    collection={c}
                                                    key={idx}
                                                    setBreadCrumbCollections={(
                                                        value
                                                    ) => {
                                                        setBreadCrumbCollections(
                                                            [
                                                                ...breadCrumbCollections,
                                                                value,
                                                            ]
                                                        );
                                                    }}
                                                    setAnimation={() => {
                                                        setAnimation("left");
                                                    }}
                                                    worksheet={worksheet}
                                                />
                                            );
                                        })}
                                        {!finishLoadmore ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    justifyContent: "center",
                                                    cursor: "pointer",
                                                    padding: "4px 0",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "18px",
                                                        textAlign: "center",
                                                        color: "rgba(33,33,33,0.52)",
                                                        height: "52px",
                                                    }}
                                                    onClick={() => {}}
                                                >
                                                    See more
                                                    <div
                                                        className={
                                                            "loading-more"
                                                        }
                                                    >
                                                        <ArrowNext />
                                                    </div>
                                                </span>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <>
                                    {toCollection?.id ? (
                                        <div className="thumbnail-worksheets">
                                            {arrayThumbnail?.map((ws, idx) => {
                                                if (!ws?.thumbnail) {
                                                    return null;
                                                }
                                                return (
                                                    <div
                                                        className="thumbnail-worksheet"
                                                        key={idx}
                                                        style={{}}
                                                    >
                                                        <img
                                                            src={ws.thumbnail}
                                                            alt={
                                                                "thumbnail " +
                                                                ws.title
                                                            }
                                                            style={{
                                                                width: "100%",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    {isSaveAndAssign ? (
                        <div className="content content-class-container">
                            <ContentBreadcrumb
                                active={sectionActive}
                                child={<p className="text">Your Classrooms</p>}
                                setSectionActive={setSectionActive}
                                section={SECTION.SAVE_ASSIGN}
                            />

                            <div
                                style={
                                    [
                                        SECTION.SAVE_ASSIGN,
                                        SECTION.OPEN_ALL,
                                    ].includes(sectionActive)
                                        ? {}
                                        : { display: "none" }
                                }
                            >
                                <ContentClass />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {(!!worksheet?.id || worksheets?.length) &&
                    action == Config.ACTION_GRID.SAVE ? (
                        <FooterPopup
                            collection={toCollection}
                            worksheet={worksheet}
                            collections={subCollections}
                            setShowPopup={(showValue, doneAction = false) =>
                                setShowPopup(showValue, doneAction)
                            }
                            setShowPopupCreateCollection={(value) =>
                                setShowPopupCreateCollection(value)
                            }
                            titlePopup={titlePopup}
                            setClosePopup={setClosePopup}
                        />
                    ) : (
                        <></>
                    )}
                    {action == Config.ACTION_GRID.MOVE_WORKSHEET &&
                    worksheet?.id ? (
                        <FooterPopupMoveCollection
                            collectionSelected={toCollection}
                            collection={collectionSelected}
                            worksheet={worksheet}
                            setShowPopup={(value) => setShowPopup(value)}
                        />
                    ) : action == Config.ACTION_GRID.MOVE_COLLECTION &&
                      collection?.id ? (
                        <FooterPopupMoveCollection
                            collection={collection}
                            collectionSelected={toCollection}
                            setShowPopup={(value) => setShowPopup(value)}
                        />
                    ) : isSaveAndAssign ? (
                        <>
                            <FooterSaveAndAssign />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

const BreadCrumb = ({
    selectCollectionFc,
    breadCrumbCollections,
    setBreadCrumbCollections,
    active,
    setSectionActive,
}: {
    selectCollectionFc: any;
    breadCrumbCollections: any;
    setBreadCrumbCollections: Function;
    active: string;
    setSectionActive: Function;
}) => {
    const dispatch = useDispatch();
    let collections = useSelector(
        (state: ResourceAppState) => state.resourceState.collections.data
    );

    return (
        <ContentBreadcrumb
            setSectionActive={setSectionActive}
            active={active}
            section={SECTION.SAVE_RESOURCE}
            child={
                breadCrumbCollections?.length ? (
                    <div className="header">
                        <div
                            className="icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                setBreadCrumbCollections([]);
                                dispatch(
                                    selectCollectionItemResourceAction({
                                        collection: new Collection(),
                                        collectionsState: collections,
                                        arrayCollection: collections,
                                        arrayThumbnail: [],
                                        isSelectCollection: new Collection(),
                                        listCollection: [],
                                    })
                                );
                            }}
                        >
                            Your Collections
                        </div>
                        {breadCrumbCollections.map((c, idx) => {
                            return (
                                <div
                                    className="collection-breadcrumb"
                                    key={idx}
                                >
                                    <NextIconSvg />
                                    <p
                                        onClick={() => {
                                            selectCollectionFc(
                                                c.id,
                                                breadCrumbCollections
                                            );
                                        }}
                                    >
                                        {c?.name}
                                    </p>
                                </div>
                            );
                        })}
                        {/* <p className="header-collection-name">
                            {uppercaseFirstLetter(currentCollection.name)}
                        </p> */}
                    </div>
                ) : (
                    <p className="text">Your Collections</p>
                )
            }
        />
    );
};
const ContentBreadcrumb = ({
    child,
    active,
    setSectionActive,
    section,
}: {
    child: any;
    active: string;
    setSectionActive: Function;
    section: string;
}) => {
    const action = useSelector(
        (state: ResourceAppState) => state.resourceState?.actionGridItem?.action
    );

    return (
        <div
            className="bread-crumb"
            onClick={() => {
                if (section == SECTION.SAVE_RESOURCE) {
                    if (active == SECTION.SAVE_RESOURCE) {
                        setSectionActive(SECTION.INIT);
                    } else if (active == SECTION.OPEN_ALL) {
                        setSectionActive(SECTION.SAVE_ASSIGN);
                    } else {
                        setSectionActive(SECTION.SAVE_RESOURCE);
                    }
                } else if (section == SECTION.SAVE_ASSIGN) {
                    if (active == SECTION.SAVE_ASSIGN) {
                        setSectionActive(SECTION.INIT);
                    } else if (active == SECTION.OPEN_ALL) {
                        setSectionActive(SECTION.SAVE_RESOURCE);
                    } else {
                        setSectionActive(SECTION.SAVE_ASSIGN);
                    }
                }
            }}
        >
            <>{child}</>
            {action == Config.ACTION_GRID.SAVE_RESOURCE_ASSIGN ? (
                <div className="btn">
                    <ExpandMore
                        className={
                            "expand-arrow " +
                            (active != section && active != SECTION.OPEN_ALL
                                ? "more"
                                : "less")
                        }
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
const ContentClass = () => {
    let worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    const worksheet = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheet
    );
    const dispatch = useDispatch();
    const handleShowPopup = async () => {
        dispatch(getClassesAction());
        if (worksheets?.length == 1) {
            dispatch(getAssignmentAction(worksheets[0].id));
        }
        if (worksheet?.id && !worksheets?.length) {
            dispatch(getAssignmentAction(worksheet.id));
        }
    };
    useEffect(() => {
        if (worksheets?.length || worksheet?.id) handleShowPopup();
    }, []);

    return (
        <>
            <ContentPopupAssign showPopup={true} setShowPopup={() => {}} />
        </>
    );
};
export default memo(NewSaveToCollection);
