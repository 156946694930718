import { GET, POST } from ".";
import { IResourceItemNew } from "../shared/models/resourceItemNew";
import { IWorkSheetCreator } from "../shared/models/WorkSheetCreator";
import APIConfig from "../utils/APIConfig";

export const savePageWssApi = async (args: {
    worksheetId: string;
    resourceItems: IResourceItemNew[];
    wsCreator: IWorkSheetCreator;
}) => {
    return POST({
        url: APIConfig.CREATE_NEW_WS_FROM_TOOL_V2,
        params: args,
    });
};
export const getPageWsAPI = async (worksheetId: string) => {
    return GET({
        url: APIConfig.GET_WS_FROM_TOOL_V2 + "?worksheetId=" + worksheetId,
    });
};
export const getUserCreateWsAPI = async (worksheetId: string) => {
    return GET({
        url: APIConfig.GET_USER_CREATE_WS + "?worksheetId=" + worksheetId,
    });
};
export const getOldResourceItemAPI = async (worksheetId: string) => {
    return POST({
        url: APIConfig.GET_RESOURCE_BY_WORKSHEETid,
        params: {
            worksheetId,
        },
    });
};
