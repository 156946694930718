import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Collection,
    ICollection,
} from "../../../../../../shared/models/collection";
import { Worksheet } from "../../../../../../shared/models/worksheet";
import Config from "../../../../../../shared/utils/config";
import {
    selectActionGridItemAction,
    selectGridItemAction,
} from "../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import EditCollectionIcon from "../../../../icons/edit-collection";

const EditAction = ({ collection }: { collection: ICollection }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    const openAction = (value: boolean) => {
        if (!value) {
            if (actionGridItem.action) {
                dispatch(
                    selectActionGridItemAction({
                        action: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }
            if (
                actionGridItem?.worksheet?.id ||
                actionGridItem?.collection?.id
            ) {
                dispatch(
                    selectGridItemAction({
                        collection: new Collection(),
                        worksheet: new Worksheet(),
                        toCollection: true,
                    })
                );
            }
        }
    };
    return (
        <>
            <div
                className="edit"
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                    dispatch(
                        selectGridItemAction({
                            collection: collection,
                        })
                    );
                    dispatch(
                        selectActionGridItemAction({
                            action: Config.ACTION_GRID.EDIT,
                        })
                    );
                }}
            >
                <EditCollectionIcon isActive={true} />
                Edit
            </div>
            {/* {open && (
                <PopupEditCollectionResource
                    setShowPopupEditCollection={(value) => {
                        setOpen(value);
                        openAction(value);
                    }}
                    showPopupEditCollection={true}
                    autoComplete={true}
                    collection={actionGridItem?.collection}
                    titlePopup={"Edit this collection"}
                    worksheet={actionGridItem?.worksheet}
                />
            )} */}
        </>
    );
};

export default EditAction;
