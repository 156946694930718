import { ChatAction } from "../../../shared/utils/config";
import { ChatRequestMessageRoleEnum, IMessage } from "../reducer/conversation.reducer";

export enum ConversationTypes {
  ASK_ASSISTANT = "ASK_ASSISTANT",
  ASK_ASSISTANT_FALSE = "ASK_ASSISTANT_FALSE",
  UPDATE_CONVERSATION = "UPDATE_CONVERSATION",
  REGENERATTE_WORD_SEARCH = "REGENERATTE_WORD_SEARCH",
  LOADING_CONVERSATION = "LOADING_CONVERSATION",
  LOADING_HISTORY = "LOADING_HISTORY",
  CLOSE_POPUP = "CLOSE_POPUP",
  SEE_DETAIL_WORD_SEARCH = "SEE_DETAIL_WORD_SEARCH",
  GET_HISTORY_CONVERSATION = "GET_HISTORY_CONVERSATION",
  UPDATE_HISTORY_CONVERSATION = "UPDATE_HISTORY_CONVERSATION",
  LOAD_A_HISTORY_CONVERSATION = "LOAD_A_HISTORY_CONVERSATION",
  UPDATE_CONVERSATIONid = "UPDATE_CONVERSATIONid",
}

export interface IConversationAction {
  type: ConversationTypes;
  conversationId?: string;
  role?: ChatRequestMessageRoleEnum;
  content?: string;
  messages?: IMessage[],
  isLoading?: boolean;
  isLoadingHistory?: boolean;
  openPopup?: boolean;
  puzzleConfig?: any;
  chatAction?: ChatAction;
  historyConversations?: any[];
  showHistoryConversations?: boolean;
}

export const askAssistantAction = (role: ChatRequestMessageRoleEnum, conversationId: string, content: string): IConversationAction => {
  return {
    type: ConversationTypes.ASK_ASSISTANT,
    conversationId,
    role,
    content
  }
}

export const askAssistantFalseAction = (): IConversationAction => {
  return {
    type: ConversationTypes.ASK_ASSISTANT_FALSE
  }
}

export const updateConversationAction = (conversationId: string, messages: IMessage[]): IConversationAction => {
  return {
    type: ConversationTypes.UPDATE_CONVERSATION,
    conversationId,
    messages
  }
}

export const reGenerateWordSearchAction = (): IConversationAction => {
  return {
    type: ConversationTypes.REGENERATTE_WORD_SEARCH
  }
}

export const setLoadingHistoryAction = (isLoadingHistory: boolean): IConversationAction => {
  return {
    type: ConversationTypes.LOADING_HISTORY,
    isLoadingHistory
  }
}

export const setLoadingConversationAction = (isLoading: boolean): IConversationAction => {
  return {
    type: ConversationTypes.LOADING_CONVERSATION,
    isLoading
  }
}

export const closePopupnAction = (openPopup: boolean): IConversationAction => {
  return {
    type: ConversationTypes.CLOSE_POPUP,
    openPopup
  }
}

export const seeDetailWordSearchAction = (puzzleConfig: any): IConversationAction => {
  return {
    type: ConversationTypes.SEE_DETAIL_WORD_SEARCH,
    puzzleConfig
  }
}

export const getHistoryConversationAction = (): IConversationAction => {
  return {
    type: ConversationTypes.GET_HISTORY_CONVERSATION
  }
}

export const updateHistoryConversationAction = (historyConversations: any[], showHistoryConversations: boolean): IConversationAction => {
  return {
    type: ConversationTypes.UPDATE_HISTORY_CONVERSATION,
    historyConversations,
    showHistoryConversations
  }
}

export const loadAHistoryConversationAction = (conversationId: string): IConversationAction => {
  return {
    type: ConversationTypes.LOAD_A_HISTORY_CONVERSATION,
    conversationId
  }
}

export const updateConversationIdAction = (conversationId: string): IConversationAction => {
  return {
    type: ConversationTypes.UPDATE_CONVERSATIONid,
    conversationId
  }
}