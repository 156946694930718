import { useRef, useState } from "react";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import { DirectionTable } from "../../../../../../shared/models/tableAttribute";
import { getPositionMoreTable } from "../../../../../../utils/align";
import MenuEditTable from "./menuEditTable";

const ButtonMoreTable = ({
    ratio,
    currentElement,
    setCurrentElement,
    type,
}: {
    ratio: number;
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    type: DirectionTable;
}) => {
    const [openPopup, setOpenPopup] = useState(false);
    const ref = useRef(null);
    const tableAttribute = currentElement.tableAttribute;

    const width = 20,
        d = 24;
    if (!tableAttribute.currentPositions.length) {
        return null;
    }
    let position = getPositionMoreTable({ tableAttribute });
    let isRight = position.x + position.width / 2 > currentElement.width / 2;
    let isBottom = position.y + position.height / 2 > currentElement.height / 2;
    return (
        <div
            ref={ref}
            style={{
                position: "absolute",
                top:
                    type === DirectionTable.row
                        ? position.top * ratio - width / 2
                        : isBottom
                        ? currentElement.height * ratio + d / 3
                        : -d,
                left:
                    type === DirectionTable.column
                        ? position.left * ratio - width / 2
                        : isRight
                        ? currentElement.width * ratio + d / 3
                        : -d,
                pointerEvents: "auto",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width,
                height:width,
            }}
            onClick={(event) => {
                event.stopPropagation();
                setOpenPopup(true);
                event.preventDefault();
            }}
        >
            <img
                src={svgPath(IconSVG.more_table)}
                width={width - 4}
                height={width - 4}
                style={{
                    transform:
                        type === DirectionTable.column
                            ? `rotate(${90}deg)`
                            : null,
                }}
            />
            <MenuEditTable
                type={type}
                setOpen={setOpenPopup}
                setCurrentElement={setCurrentElement}
                currentElement={currentElement}
                open={openPopup}
                anchorElement={ref.current}
            />
        </div>
    );
};

export default ButtonMoreTable;
