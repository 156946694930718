import React, { useEffect, useRef, useState } from "react";
import {
    IResourceItemNew,
} from "../../../../../../shared/models/resourceItemNew";
import LineView from "../../../../../../resource-user/components/common/line-view";

function ResourceNormalLineView({
    resourceItem,
    currentItem,
}: {
    resourceItem: IResourceItemNew;
    currentItem?: IResourceItemNew;
}) {
    const width = currentItem?.width ?? resourceItem.width,
        height = currentItem?.height ?? resourceItem.height,
        color =
            currentItem?.imageAttribute?.changeColor["color"] ??
            resourceItem.imageAttribute.changeColor["color"]

    return (
        <div className="content-line-interactive">
            <LineView
                width={width}
                height={height}
                line={currentItem?.shapeAttribute?? resourceItem.shapeAttribute}
                color={color}
                opacity={currentItem?.opacity?? resourceItem.opacity}
            />
        </div>
    );
}

export default ResourceNormalLineView;
