import { useEffect, useState } from "react";
import {
    HorizontalBackgroundIcon,
    VerticalBackgroundIcon
} from "../../../../assets/icon";
import { useAppDispatch } from "../../../../redux/hook";
import { changeBackgroundOrientation, updateTabData } from "../../../../redux/reducers/leftData";
import { getPrefixImage } from "../../../../utils";
import "./index.scss";
const OrientationSwitch = ({ openFilter, setOpenFilter, setUseFilter }: { openFilter: boolean, setOpenFilter: Function, setUseFilter: Function }) => {
    const dispatch = useAppDispatch();
    const [tabs, setTabs] = useState([]);
    useEffect(() => {
        setTabs([
            {
                icon: <HorizontalBackgroundIcon />,
                active: true,
                text: "Horizontal",
            },
            {
                icon: <VerticalBackgroundIcon />,
                active: true,
                text: "Vertical",
            },
        ])
    }, []);
    const handleSwitchTab = (index) => {
        setTabs(pre => {
            pre[index].active = !pre[index].active;
            return pre;
        })
        let filterActive = tabs.filter(e => e.active == true);

        switch (filterActive.length) {
            case 0:
                dispatch(changeBackgroundOrientation(""));
                dispatch(updateTabData([{}]));
                setUseFilter(true);
                break;
            case 1:
                let orientation = filterActive[0].text.toLowerCase();
                dispatch(changeBackgroundOrientation(orientation));
                setUseFilter(true);

                break;
            case 2:
            default:
                dispatch(changeBackgroundOrientation("all"));
                setUseFilter(false);
                break;
        }
    };
    return (
        <div style={openFilter ? {} : { visibility: 'hidden' }}>
            <div className="list-orientation">
                {tabs.map((el, index) => (
                    <div
                        className="orientation-item"
                        key={index}
                        onClick={() => handleSwitchTab(index)}
                    >
                        <div className="icon-tick" style={el.active ? {} : { visibility: 'hidden' }}>
                            <img src={getPrefixImage() + "/images/icon/icon-tick.svg"} />
                        </div>
                        <div className="text">{el.text}</div>
                        <div className="icon">{el.icon}</div>
                    </div>
                ))}
            </div>
            {openFilter && (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: "0px",
                        left: "0px",
                        zIndex: 6,
                    }}
                    onClick={() => {
                        setOpenFilter(false);
                    }}
                ></div>
            )}
        </div>
    );
};
export default OrientationSwitch;
