import { Button, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";

import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    addQuestion,
    deleteQuestion,
    updateQuestion,
} from "../../../../redux/reducers/createWorksheet";
import { setResourceItemEditing } from "../../../../redux/reducers/leftData";
import { useDebounce } from "../../../../resource-user/components/common/use-debounce";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../../../shared/models/questionActivity";
import Config from "../../../../shared/utils/config";
import { generateIdFromDateTime } from "../../../../utils";
import { scrollToItemId } from "../util";
import "./index.scss";
import { ConstantsNameTracing } from "../util/constant";
import useResizeHeight from "../../customActivityHook/useResizeHeight";

const InputSection = ({
    questions,
    questionId,
    setQuestionId,
    config,
}: {
    questions: IQuestionActivity[];
    questionId: string;
    setQuestionId: Function;
    config: any;
}) => {
    const dispatch = useAppDispatch();
    useResizeHeight();
    const currentQuestion = questions.find((s) => s.id === questionId);

    const handleClickFirstInput = () => {
        if (questions.length === 0) {
            const inputElement = document.getElementById("add-input");
            if (inputElement) {
                inputElement?.focus();
            }
        }
    };

    const handleClickAutoFill = () => {
        const question = new QuestionActivity({
            ...currentQuestion,
            type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
        });
        question.nameTracingAttribute.autoFill =
            !question.nameTracingAttribute.autoFill;
        dispatch(
            updateQuestion({
                question: question,
            })
        );
    };

    return (
        <div className="input-section">
            <div
                id="input-wrapper"
                className="input-wrapper"
                onClick={handleClickFirstInput}
            >
                <SimpleBar
                    className="bar-questions"
                    style={{
                        maxHeight: "100%",
                        minHeight: "100%",
                    }}
                >
                    {questions.map((question, index) => {
                        const nextQuestionId = questions?.[index + 1]?.id;
                        const focusQuestionIdDelete =
                            questions?.[index - 1]?.id ?? nextQuestionId;
                        return (
                            <Input
                                key={question.id}
                                question={question}
                                index={index}
                                currentQuestionId={questionId}
                                bindQuestionId={(id) => {
                                    setQuestionId(id);
                                }}
                                nextQuestionId={nextQuestionId}
                                focusQuestionIdDelete={focusQuestionIdDelete}
                                canDeleteQuestion={questions.length > 1}
                            />
                        );
                    })}
                    <AddInput
                        currentIndex={questions.length}
                        bindQuestionId={(id) => {
                            setQuestionId(id);
                        }}
                        questionId={questionId}
                        config={config}
                    />
                </SimpleBar>
            </div>
            <div className="auto-fill">
                <Button
                    style={{
                        pointerEvents: currentQuestion?.question
                            ? "auto"
                            : "none",
                    }}
                    disabled={!currentQuestion}
                    onClick={handleClickAutoFill}
                    className={`btn ${
                        currentQuestion?.question ? "show_nt" : "disable_nt"
                    }`}
                    // ${
                    //     currentQuestion?.nameTracingAttribute?.autoFill
                    //         ? "active"
                    //         : "hide"
                    // }
                >
                    Auto Fill
                </Button>
            </div>
        </div>
    );
};

const Input = ({
    question: questionProps,
    index,
    currentQuestionId,
    bindQuestionId,
    nextQuestionId,
    focusQuestionIdDelete,
    canDeleteQuestion,
}: {
    question: IQuestionActivity;
    index: number;
    bindQuestionId: any;
    currentQuestionId: string;
    nextQuestionId: string;
    focusQuestionIdDelete: string;
    canDeleteQuestion: boolean;
}) => {
    const { id, question } = questionProps;
    const resourceItemEditing = useAppSelector(
        (state) => state.leftDataState.resourceItemEditing
    );
    const dispatch = useAppDispatch();
    const inputRef: any = useRef();
    const focus = currentQuestionId === id;
    const [value, setValue] = useState(question);
    const [hoverIconClear, setHoverIconClear] = useState(false);
    const debounceValue = useDebounce(value, 250);
    const shouldShowValueEmpty =
        value === ConstantsNameTracing.PLACEHOLDER && index === 0;
    const PLACEHOLDER = ConstantsNameTracing.PLACEHOLDER.replace(
        ConstantsNameTracing.PREFIX,
        ""
    );

    useEffect(() => {
        if (debounceValue !== question) {
            dispatch(
                updateQuestion({
                    question: {
                        ...questionProps,
                        question: debounceValue,
                    },
                })
            );
        }
    }, [debounceValue]);

    const handleDelete = () => {
        if (currentQuestionId === id) {
            bindQuestionId(focusQuestionIdDelete);
        }
        dispatch(
            deleteQuestion({
                questionId: id,
                activityItemIdSelected: resourceItemEditing,
            })
        );
        dispatch(setResourceItemEditing(""));
    };

    const handleAddNewSentence = (e) => {
        if (e?.key === "Enter") {
            let id = nextQuestionId;
            if (!id) {
                e.preventDefault();
                id = generateIdFromDateTime();
                let newQuestion = new QuestionActivity({
                    question: "",
                    id,
                    type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
                });

                dispatch(addQuestion({ question: newQuestion }));
            }
            if (bindQuestionId) {
                bindQuestionId(id);
            }
            setTimeout(() => {
                scrollToItemId(id);
            }, 0);
        }
    };

    return (
        <div
            className={`input-container ${focus ? "focus" : "un-focus"} ${
                value.length === 0 ? "empty" : "full"
            }`}
            onClick={() => {
                if (inputRef?.current) {
                    inputRef.current?.focus();
                }
            }}
        >
            <span className="index">{index + 1}. </span>
            <input
                onFocus={(e) => {
                    bindQuestionId(id);
                }}
                ref={inputRef}
                className={`input`}
                value={shouldShowValueEmpty ? "" : value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={
                    shouldShowValueEmpty ? PLACEHOLDER : "Enter your sentence"
                }
                id={"id" + id}
                onKeyUp={handleAddNewSentence}
                onBlur={() => {
                }}
            />
            {canDeleteQuestion && (
                <IconButton
                    onMouseDown={(e) => {
                        e.preventDefault();
                        handleDelete();
                    }}
                    className="icon-delete"
                    size="small"
                    onMouseEnter={() => setHoverIconClear(true)}
                    onMouseLeave={() => setHoverIconClear(false)}
                >
                    <img
                        src={
                            hoverIconClear
                                ? svgPath(IconSVG.delete_fitb)
                                : svgPath(IconSVG.delete_fitb_black)
                        }
                        alt=""
                    />
                </IconButton>
            )}
        </div>
    );
};

const AddInput = ({
    currentIndex,
    bindQuestionId,
    config,
    questionId,
}: {
    currentIndex: number;
    bindQuestionId: any;
    config: any;
    questionId: string | undefined;
}) => {
    const dispatch = useAppDispatch();
    const isFirstIndex = currentIndex === 0;
    const [value, setValue] = useState("");

    const handleAddNewSentence = () => {
        const id = generateIdFromDateTime();
        let newQuestion = new QuestionActivity({
            question: "",
            id,
            type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
        });

        if (!questionId) {
            newQuestion = {
                ...newQuestion,
                ...config,
            };
        }

        dispatch(addQuestion({ question: newQuestion }));
        setValue("");
        if (bindQuestionId) {
            bindQuestionId(id);
        }
        setTimeout(() => {
            scrollToItemId(id);
        }, 100);
    };

    return (
        <div
            className={`input-container add-input ${
                value.length === 0 ? "empty" : ""
            } ${isFirstIndex ? "first" : ""}`}
            onClick={() => {
                handleAddNewSentence();
            }}
        >
            {isFirstIndex ? (
                ""
            ) : (
                <span className="index">{`${currentIndex + 1}. `}</span>
            )}
            <input
                disabled={true}
                id="add-input"
                className={`input `}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={
                    isFirstIndex
                        ? "Type or paste letters or words"
                        : "Enter your next sentence"
                }
            />
        </div>
    );
};

export default InputSection;
