import { useDispatch, useSelector } from "react-redux";
import { IParamsQuotaResourceUser } from "../../../../../../../shared/models/quotaResourceuser";
import Config from "../../../../../../../shared/utils/config";
import { updateQuotaUserAction } from "../../../../../../redux/action/quotaResourceUser.action";
import { ResourceAppState } from "../../../../../../redux/reducer/root.reducerModule";
import ItemMethod, { STATUS_BTN } from "../layout-item-method";
const DailyCheckInMethod = ({ item, addEventGaFc }) => {
    const dispatch = useDispatch();

    let userInfo = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data
    );

    const quotaResourceUserState = useSelector(
        (state: ResourceAppState) => state?.quotaResourceUserState?.data
    );
    const dailyTimes = useSelector(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.dailyTimes
    );

    const handleClaimDownload = () => {
        let params: IParamsQuotaResourceUser = {
            userId: userInfo.id,
        };
        let bonusQuota;

        params = {
            ...params,
            dailyTimes: 0,
        };
        bonusQuota =
            quotaResourceUserState?.dailyTimes *
            Config.BONUS_QUOTA_DOWNLOAD_EACH_DAILY_TIME;

        params = {
            ...params,
            bonusQuota,
        };
        addEventGaFc({ item });
        dispatch(updateQuotaUserAction({ params }));
    };

    return (
        <ItemMethod
            item={item}
            showBtnClaimMethod={
                dailyTimes > 0 ? STATUS_BTN.able : STATUS_BTN.non
            }
            handleClaimDownload={handleClaimDownload}
            ableClaim={dailyTimes > 0}
            numberTime={dailyTimes}
        />
    );
};
export default DailyCheckInMethod;
