import React, { useLayoutEffect, useState } from "react";
import "./index.scss";
import { Root, createRoot } from "react-dom/client";

interface AnchorOriginPopup {
    vertical: "top" | "bottom" | "center";
    horizontal: "right" | "left" | "center";
}

const ID_POPUP = "_idPOPUP0.cg22h46h6e4h7";
const ID_PARENT_POPUP = "_idPOPUPPARENT0.cg22h46h6e4h7";

function PopupModal({
    children,
    open,
    anchorElement,
    anchorOrigin = { vertical: "bottom", horizontal: "center" },
    onClose,
    id,
    top = 0,
    left = 0,
    classname,
    isBackdrop = true,
}: {
    children: React.ReactElement<any, any>;
    open: boolean;
    anchorElement: HTMLDivElement;
    anchorOrigin?: AnchorOriginPopup;
    onClose(): void;
    id?: string;
    top?: number;
    left?: number;
    classname?: string;
    isBackdrop?: boolean;
}) {
    id = id ?? ID_POPUP
    const [root, setRoot] = useState<Root>(null);
    let topEl = 0,
        leftEl = 0;
    if (anchorElement) {
        let react = anchorElement.getBoundingClientRect();
        let { x, y, width, height } = react;
        switch (anchorOrigin.horizontal) {
            case "center":
                leftEl = x + width / 2;
                break;
            case "right":
                leftEl = x + width;
                break;
            default:
                leftEl = x;
        }
        switch (anchorOrigin.vertical) {
            case "center":
                topEl = y + height / 2;
                break;
            case "top":
                topEl = y;
                break;
            default:
                topEl = y + height;
        }
    }

    const closePopUp = () => {
        setRoot(null);
        let popup = document.getElementById(ID_PARENT_POPUP);
        if (popup) popup.remove();
    };
    useLayoutEffect(() => {
        if (open && anchorOrigin) {
            let idParent = ID_PARENT_POPUP;
            let rectBody = document.body.getBoundingClientRect();
            const popupDiv = document.createElement("div");
            const parentPopup = document.createElement("div");
            parentPopup.id = idParent;
            parentPopup.onclick = (e: any) => {
                if (e.target?.id === idParent) {
                    onClose();
                    closePopUp();
                }
            };
            parentPopup.className = "back-popup-container";
            popupDiv.className = "custom-popup-container " + (classname ?? "");
            popupDiv.id = id;
            top += topEl;
            left += leftEl;
            popupDiv.style.opacity = "0";
            parentPopup.appendChild(popupDiv);
            if (!isBackdrop) {
                parentPopup.style.pointerEvents = "none";
            }
            document.body.appendChild(parentPopup);
            const root = createRoot(popupDiv);
            root.render(children);
            setRoot(root);

            setTimeout(() => {
                let rectPoup = popupDiv.getBoundingClientRect();
                if (top + rectPoup.height > rectBody.height - 10) {
                    top -= top + rectPoup.height - rectBody.height + 10;
                }
                if (left + rectPoup.width > rectBody.width - 10) {
                    left -= left + rectPoup.width - rectBody.width + 10;
                }
                popupDiv.style.top = top + "px";
                popupDiv.style.left = left + "px";
                popupDiv.style.opacity = "1";
            }, 10);
        } else {
            let popup = document.getElementById(ID_PARENT_POPUP);
            if (popup) popup.remove();
        }
        return closePopUp;
    }, [open]);

    const listenClick = (event: MouseEvent) => {
        let node = document.getElementById(id);
        if (node) {
            let { x, y, width, height } = node.getBoundingClientRect();
            if (
                event.clientX - x <= 0 ||
                event.clientX - x >= width ||
                event.clientY - y <= 0 ||
                event.clientY - y >= height
            ) {
                onClose()
            }
        }
    };

    useLayoutEffect(() => {
        if (root) {
            root.render(children);
        }
    }, [children]);
    return null;
}

export default PopupModal;
