import {
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    getTemplate,
    requestGetResourceByType,
    searchResourceByKeyword,
    searchTemplateByKeyword,
} from "../../../../redux/async/leftData";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import "./index.scss";

import ClearIcon from "@mui/icons-material/Clear";
import { convertCurrentActivityToSearchKey } from "..";
import Config from "../../../../shared/utils/config";
import { getPrefixImage, getTemplateTypebaseUrl } from "../../../../utils";
import OrientationSwitch from "../orientationSwitch";
import ListTag from "./ListTag/ListTag";

const SearchBar = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state) => state.leftDataState.loading);
    const activeTab = useAppSelector((state) => state.leftDataState.activeTab);
    const userId = useAppSelector(
        (state) => state.userInfoResourceState.data.id
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );

    const searchKeywordRedux = useAppSelector(
        (state) => state.leftDataState.searchKeyword
    );
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [showClearIcon, setShowClearIcon] = useState(false);

    const [openFilter, setOpenFilter] = useState(false);
    const [useFilter, setUseFilter] = useState(false);
    const backgroundOrientation = useAppSelector(
        (state) => state.leftDataState.backgroundOrientation
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!loading && searchKeywordRedux !== searchKeyword) {
            switch (activeTab) {
                case Config.RESOURCE_TEMPLATE:
                    dispatch(
                        searchTemplateByKeyword({
                            templateType: getTemplateTypebaseUrl(),
                            offset: 0,
                            size: ConstantsTool.LIMIT_RESOURCE,
                            keyword: searchKeyword,
                        })
                    );
                    break;
                case Config.RESOURCE_BACKGROUND:
                    dispatch(
                        searchResourceByKeyword({
                            typeResource: [Config.RESOURCE_FRAMES],
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            offset: 1,
                            keyword: searchKeyword,
                            orientation: backgroundOrientation,
                        })
                    );
                    break;
                default:
                    dispatch(
                        searchResourceByKeyword({
                            typeResource: [activeTab],
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            offset: 1,
                            keyword: searchKeyword,
                        })
                    );
                    break;
            }
            setShowClearIcon(true);
            let action = "";
            switch (activeTab) {
                case Config.RESOURCE_BACKGROUND:
                    action = "ncw_search_background";
                    break;
                case Config.RESOURCE_ELEMENT:
                    action = "ncw_search_elements";
                    break;
                case Config.RESOURCE_TEMPLATE:
                    action = "ncw_search_template";
                    break;
                default:
                    break;
            }
        }
    };
    const handleClear = () => {
        switch (activeTab) {
            case Config.RESOURCE_TEMPLATE:
                dispatch(
                    getTemplate({
                        templateType: getTemplateTypebaseUrl(),
                        limit: ConstantsTool.LIMIT_RESOURCE,
                        offset: 0,
                    })
                );
                break;
            case Config.RESOURCE_BACKGROUND:
                dispatch(
                    requestGetResourceByType({
                        typeResource: [Config.RESOURCE_FRAMES],
                        limit: ConstantsTool.LIMIT_RESOURCE,
                        userId: userId,
                        offset: 1,
                    })
                );
                break;
            default:
                dispatch(
                    requestGetResourceByType({
                        typeResource: [activeTab],
                        limit: ConstantsTool.LIMIT_RESOURCE,
                        userId: userId,
                        offset: 1,
                    })
                );
                break;
        }
        setSearchKeyword("");
        setShowClearIcon(false);
    };

    useEffect(() => {
        if (
            activeTab === Config.RESOURCE_TEMPLATE &&
            getTemplateTypebaseUrl() === null
        ) {
            setSearchKeyword(
                convertCurrentActivityToSearchKey(currentActivity.type)
            );
            if (currentActivity.type !== Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE)
                setShowClearIcon(true);
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === Config.RESOURCE_BACKGROUND) {
            setSearchKeyword(searchKeywordRedux);
            if (searchKeywordRedux) {
                setShowClearIcon(true);
            }
        }
    }, [activeTab, searchKeywordRedux]);

    return (
        <>
            <div className="search-bar">
                <FormControl component="form" onSubmit={handleSubmit}>
                    <TextField
                        className="input-mui"
                        placeholder="Search"
                        value={searchKeyword}
                        onChange={(e: any) => setSearchKeyword(e.target.value)}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment
                                    sx={{ cursor: "pointer" }}
                                    position="end"
                                >
                                    {showClearIcon && (
                                        <IconButton onClick={handleClear}>
                                            <ClearIcon></ClearIcon>
                                        </IconButton>
                                    )}
                                    {activeTab === Config.RESOURCE_BACKGROUND ? (
                                        <div className={`icon-filter ${openFilter ? "active-icon-filter" : ""}`}>

                                            <IconButton
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setOpenFilter(pre => !pre)
                                                }}
                                            >
                                                {(openFilter || useFilter) ? (
                                                    <img src={getPrefixImage() + "/images/icon/icon-active-filter.svg"} />
                                                ) : (
                                                    <img src={getPrefixImage() + "/images/icon/icon-filter.svg"} />
                                                )}
                                            </IconButton>
                                            <OrientationSwitch openFilter={openFilter} setOpenFilter={setOpenFilter} setUseFilter={setUseFilter} />

                                        </div>

                                    ) : (<></>)}
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment
                                    sx={{ cursor: "pointer" }}
                                    position="start"
                                >
                                    <IconButton onClick={handleSubmit}>
                                        <img src={getPrefixImage() + "/images/icon/icon-search.svg"} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </div>

            {getTemplateTypebaseUrl() === null &&
                activeTab === Config.RESOURCE_TEMPLATE && (
                    <ListTag
                        setSearchKeyword={setSearchKeyword}
                        setShowClearIcon={setShowClearIcon}
                    />
                )}
        </>
    );
};
export default SearchBar;
