import useImageData from '../use-image-data';
import { getOriginalImageURL } from '../../../../../../shared/utils';
import { useEffect, useState } from 'react';

const ResourceImageS3 = ({
    fieldName,
    s3URL,
    itemWidth,
    itemHeight,
    onImageLoad,
}: {
    fieldName: string,
    s3URL: string,
    itemWidth: number,
    itemHeight: number,
    onImageLoad: () => void;
}) => {
    const imageRef = useImageData(getOriginalImageURL(s3URL), itemWidth, itemHeight, onImageLoad);
    const [imageWidth, setImageWidth] = useState(itemWidth);
    const [imageHeight, setImageHeight] = useState(itemHeight);

    useEffect(() => {
        const handleDimenstionsOfResourceImage = () => {
            const img = new Image();
            img.src = s3URL;

            img.onload = () => {
                const { width: originalImageWidth, height: originalImageHeight } = img;

                const ratio = Math.min(itemWidth / originalImageWidth, itemHeight / originalImageHeight);

                setImageWidth(originalImageWidth * ratio);
                setImageHeight(originalImageHeight * ratio);
            };

            setImageHeight(itemHeight);
            setImageWidth(itemWidth);
        }

        handleDimenstionsOfResourceImage();
    }, [s3URL, fieldName, itemHeight, itemWidth]);

    return (
        <img
            className="content-element"
            ref={imageRef}
            style={{
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
            }}
            // onLoad={onImageLoad}
            onError={onImageLoad}
            alt={fieldName}
        ></img>
    )
};

export default ResourceImageS3;