import { isObject } from "../utils";
import Config from "../utils/config";
import { Game, IGame, IQuestion } from "./game";
import { IResourceItem, ResourceItem } from "./resourceItem";
import { ITag } from "./tag";
import { IUserInfo, UserInfo } from "./userInfo";
import { IUserRate } from "./userRate";
import { immerable } from "immer";

export interface IComment {
    authorName: string;
    createDate: Date;
    content: string;
}
export interface IFont {
    id: number;
    name: string;
    url: string;
}
export class Comment {
    authorName: string;
    createDate: Date;
    content: string;

    constructor(object?: IComment) {
        this.authorName = object?.authorName ?? "";
        this.createDate = object?.createDate ?? new Date();
        this.content = object?.content ?? "";
    }
}

export interface IMoreInfo {
    age: string;
    language: string;
    level: string;
    mainContent: string;
    otherContentText: string;
    subject: string;
    newAge: string[];
    newSubject: any;
    newMainContent: any;
    grades: string[];
    languages: string[];
}

export class MoreInfo {
    age: string;
    language: string;
    level: string;
    mainContent: string;
    otherContentText: string;
    subject: string;
    newAge: string[];
    newSubject: any;
    newMainContent: any;
    grades: string[];
    languages: string[];

    constructor(object: any = {}) {
        // this.age = object.age ?? "";
        this.languages = object.languages ?? [];
        // this.level = object.level ?? "";
        // this.mainContent = object.mainContent ?? "";
        // this.otherContentText = object.otherContentText ?? "";
        // this.subject = object.subject ?? "";
        // this.newAge = object.newAge ?? [];
        // this.newSubject = object.newSubject ?? "";
        // this.newMainContent = object.newMainContent ?? "";
        this.grades = object.grades ?? [];
    }
}

export interface ISeoInfo {
    title: string;
    keyword: string;
    description: string;
}

export class SeoInfo implements ISeoInfo {
    title: string;
    keyword: string;
    description: string;

    constructor(object: any = {}) {
        this.title = object.title ?? "";
        this.keyword = object.keyword ?? "";
        this.description = object.description ?? "";
    }
}

export interface IWorksheet {
    id: string;
    title: string;
    description: string;
    originUrl: string;
    oldCategoryIds?: string[];
    categoryIds: string[];
    topicIds: string[];
    commonCoreIds: string[];
    selectedCategoryIds: string[];
    authorId: string;
    createdDate: Date;
    updatedAt: Date;

    seoInfo: ISeoInfo;
    moreInfo: IMoreInfo;
    game: IGame;
    gameSubmitted?: IGame;
    comments: IComment[];
    tagIds: string[];
    ratings: number[];

    userRates?: IUserRate[];
    author?: IUserInfo;
    type: number;
    avgRating?: number;
    tags?: ITag[];
    status?: string;
    lastSubmited?: Date;
    lastIndexed?: Date;
    statusHide?: number;
    slug?: string;
    totalPages?: number;
    fontInfos?: IFont[];
    thumbnail?: string;
    lock?: boolean;
    resourceItems?: IResourceItem[];
    score?: number;
    answerLength?: number;
    level?: string;
    userCreatedFlow?: number;
    isWorkbook?: boolean;
    workbookId?: string;

    dataLike?: any[];
    flagIndex?: number;
    sourceUserBehaviors?: number[];
    useInteractiveElement?: boolean;
}

export class Worksheet implements IWorksheet {
    id: string;
    title: string;
    description: string;
    originUrl: string;
    tagIds: string[];
    categoryIds: string[];
    topicIds: string[];
    commonCoreIds: string[];
    oldCategoryIds?: string[];
    selectedCategoryIds: string[];
    authorId: string;
    createdDate: Date;
    updatedAt: Date;
    seoInfo: ISeoInfo;
    moreInfo: IMoreInfo;
    game: IGame;
    gameSubmitted?: IGame;
    comments: IComment[];
    ratings: number[];
    avgRating?: number;
    userRates?: IUserRate[];
    author?: IUserInfo;
    type: number;
    tags?: ITag[];
    status?: string;
    lastSubmited?: Date;
    lastIndexed?: Date;
    statusHide?: number;
    slug?: string;
    totalPages?: number;
    fontInfos?: IFont[];
    thumbnail?: string;
    lock?: boolean;
    resourceItems?: IResourceItem[];
    score?: number;
    answerLength?: number;
    level?: string;
    userCreatedFlow?: number;
    isWorkbook?: boolean;
    dataLike?: any[];
    flagIndex?: number;
    sourceUserBehaviors?: number[];
    useInteractiveElement?: boolean;
    workbookId?: string;

    constructor(object: any = {}) {
        this[immerable] = true;
        this.id = object.id ?? undefined;
        this.title = object.title ?? "";
        this.description = object.description ?? "";
        this.originUrl = object.originUrl ?? "";
        this.tagIds = object?.tagIds ?? [];
        this.oldCategoryIds = [];
        this.categoryIds = object?.categoryIds ?? [];
        this.topicIds = object?.topicIds ?? [];
        this.commonCoreIds = object?.commonCoreIds ?? [];
        this.selectedCategoryIds = object?.selectedCategoryIds ?? [];
        this.authorId = object.authorId ?? null;
        this.createdDate = object.createdDate ?? null;
        this.updatedAt = object.updatedAt ?? null;

        this.seoInfo = new SeoInfo(object.seoInfo);
        this.moreInfo = new MoreInfo(object.moreInfo);
        this.game = new Game(object.game);
        if (object.gameSubmitted) {
            this.gameSubmitted = new Game(object.gameSubmitted);
        }
        this.comments = [];
        this.tagIds = object.tagIds ?? [];
        this.ratings = object.ratings ?? [];
        this.author = object.author ?? new UserInfo(object.author);
        if (isObject(object.authorId)) {
            this.author = new UserInfo(object.authorId);
            this.authorId = this.author.id;
        } else {
            this.authorId = object.authorId ?? null;
        }
        if (object.userRates) {
            this.userRates = object.userRates ?? [];
        }
        this.type = object.type ?? Config.NORMAL_WORKSHEET;

        // for (let index = 0; index <= 10; index++) {
        //     if (!this.ratings[index]) {
        //         this.ratings[index] = 0;
        //     }
        // }
        this.avgRating = object.avgRating ?? null;
        this.tags = object.tags ?? [];
        this.status = object.status ?? "";
        this.lastSubmited =
            object.lastSubmited && new Date(object.lastSubmited);
        this.lastIndexed = object.lastIndexed && new Date(object.lastIndexed);
        this.statusHide = object.statusHide ?? Config.STATUS_CREATE_SUCCESS;
        this.slug = object.slug ?? "";
        this.totalPages = object.totalPages ?? 1;
        this.fontInfos = object.fontInfos ?? [];
        this.thumbnail = object.thumbnail ?? "";
        this.lock = object.lock ?? false;
        this.resourceItems =
            object.resourceItems?.map(
                (item: IResourceItem) => new ResourceItem(item)
            ) ?? [];
        this.score = object.score ?? 0;
        this.answerLength = object.answerLength ?? 0;
        this.level = object.level ?? Config.LEVEL_LOW;
        this.userCreatedFlow = object.userCreatedFlow ?? 0;
        this.isWorkbook = object.isWorkbook ?? false;
        this.workbookId = object.workbookId ?? null;
        this.dataLike = object.dataLike ?? [];
        this.flagIndex = object.flagIndex ?? Config.NONE_FLAG_INDEX_WS;
        this.sourceUserBehaviors = object.sourceUserBehaviors ?? [];
        this.useInteractiveElement = object.useInteractiveElement;
    }
}

export const getAvgRating = (ratings: number[]) => {
    if (ratings?.length) {
        let total = 0;
        let totalRating = 0;
        for (let index = 1; index <= 10; index++) {
            total += index * ratings[index];
            totalRating += ratings[index];
        }
        const avg = total / totalRating;
        if (avg) {
            return Math.round(avg * 10) / 10;
        }
    }
    return 0;
};

export const getTotalRating = (ratings: number[]) => {
    if (ratings?.length) {
        let total = 0;
        for (let index = 1; index <= 10; index++) {
            total += ratings[index];
        }
        return total;
    }
    return 0;
};

export const getQuestionIds = (worksheet: IWorksheet) => {
    const listQuestionsId: number[] = [];
    if (worksheet.gameSubmitted?.questions?.length) {
        worksheet.gameSubmitted?.questions.forEach((question: IQuestion) => {
            if (question.questionId) {
                listQuestionsId.push(question.questionId);
            }
        });
    }
    return listQuestionsId;
};
