import React, { useState } from "react";
import {
    CellTable,
    IPositionTable,
    ITableAttribute,
} from "../../../../shared/models/tableAttribute";
import { getBoundingTextBox } from "../../../utils";
import {
    getDisplayPathCell,
    getSizeCellTable,
    makePathCell,
    makeStrokeDasharray,
} from "../../../utils/table";
import "./index.scss";

const TableView = React.forwardRef<
    HTMLDivElement,
    {
        tableAttribute: ITableAttribute;
        positions?: IPositionTable[];
        onClick?: (position: IPositionTable) => void;
        onInput?(params: {
            row: number;
            col: number;
            width: number;
            height: number;
            value: string;
        }): void;
        onBlur?(): void;
    }
>(({ tableAttribute, positions, onClick, onInput, onBlur }, ref) => {
    const { data, gap } = tableAttribute;
    const [focus, setFocus] = useState(null);

    const selectText = (id: string) => {
        setTimeout(() => {
            const selection = window.getSelection();
            const range = document.createRange();
            const editableDiv = document.getElementById(id);
            if (editableDiv) {
                let offsetEnd = editableDiv.innerText.length;
                if (offsetEnd === 0) {
                    editableDiv.focus();
                } else {
                    range.setStart(editableDiv.childNodes[0], 0);
                    range.setEnd(editableDiv.childNodes[0], offsetEnd);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }, 10);
    };

    const backgroundCellTable = ({
        tableAttribute,
        row,
        col,
    }: {
        tableAttribute: ITableAttribute;
        row: number;
        col: number;
    }) => {
        const { data } = tableAttribute;
        const { top, left, width, height } = getSizeCellTable({
            tableAttribute,
            row,
            col,
            isRenderBackground: true,
        });
        const cell = data[row][col];
        let {
            colorCell,
            borderWidth,
            strokeDasharray,
            borderWidths,
            strokeDasharrays,
        } = cell;
        const strokeWidth = borderWidth;
        strokeDasharrays = strokeDasharrays?.length
            ? strokeDasharrays
            : [0, 0, 0, 0];
        borderWidths = borderWidths?.length ? borderWidths : [1, 1, 1, 1];
        const {
            colorBorder,
            colorBorderBottom,
            colorBorderLeft,
            colorBorderRight,
            colorBorderTop,
            positions,
        } = colorCell;

        const {
            path,
            pathBottom,
            pathHorizontal,
            pathLeft,
            pathRight,
            pathTop,
            pathVertical,
        } = makePathCell({ width, height, strokeWidth, top, left });

        const pathView = (
            path: string,
            stroke: string,
            strokeDasharray: number,
            strokeWidth: number,
            length: number
        ) => {
            return (
                <path
                    stroke={stroke ?? colorBorder}
                    strokeWidth={strokeWidth}
                    strokeDasharray={
                        length
                            ? makeStrokeDasharray({
                                  strokeWidth,
                                  strokeDasharray,
                                  length,
                              })
                            : null
                    }
                    d={path}
                />
            );
        };
        const renderPath = () => {
            const { all, top, bottom, right, left, vertical, horizontal } =
                getDisplayPathCell({ data, row, col });
            let paths = [];
            let array = positions ?? ["top", "right", "bottom", "left"];
            array.forEach((position) => {
                switch (position) {
                    case "top":
                        paths.push(
                            <>
                                {top &&
                                    pathView(
                                        pathTop,
                                        colorBorderTop,
                                        strokeDasharrays[0],
                                        borderWidths[0],
                                        width
                                    )}
                            </>
                        );
                        break;
                    case "bottom":
                        paths.push(
                            <>
                                {bottom &&
                                    pathView(
                                        pathBottom,
                                        colorBorderBottom,
                                        strokeDasharrays[2],
                                        borderWidths[2],
                                        width
                                    )}
                            </>
                        );
                        break;
                    case "right":
                        paths.push(
                            <>
                                {right &&
                                    pathView(
                                        pathRight,
                                        colorBorderRight,
                                        strokeDasharrays[1],
                                        borderWidths[1],
                                        height
                                    )}
                            </>
                        );
                        break;
                    case "left":
                        paths.push(
                            <>
                                {left &&
                                    pathView(
                                        pathLeft,
                                        colorBorderLeft,
                                        strokeDasharrays[3],
                                        borderWidths[3],
                                        height
                                    )}
                            </>
                        );
                        break;
                }
            });
            return paths;
        };
        return (
            <>
                {gap > 0 ? (
                    <>
                        {pathView(
                            pathHorizontal,
                            colorBorder,
                            strokeDasharray,
                            strokeWidth,
                            width
                        )}
                        {pathView(
                            pathVertical,
                            colorBorder,
                            strokeDasharray,
                            strokeWidth,
                            height
                        )}
                    </>
                ) : (
                    renderPath()
                )}
            </>
        );
    };
    let positionCells: { row: number; col: number; zIndex: number }[] = [];
    data.forEach((values, row) => {
        values.forEach((value, col) => {
            positionCells.push({ row, col, zIndex: value.zIndex });
        });
    });
    positionCells.sort((a, b) => a.zIndex - b.zIndex);
    return (
        <div className="table-element-view">
            {data.map((cells, row) => {
                return (
                    <div key={row}>
                        {cells.map((cell, col) => {
                            const { borderWidth } = cell;
                            const {
                                top,
                                left,
                                width,
                                height,
                                textWidth,
                                textHeight,
                            } = getSizeCellTable({
                                tableAttribute,
                                row,
                                col,
                            });
                            let textAttribute = cell.textAttribute;
                            let active =
                                positions?.length === 1 &&
                                positions[0].row === row &&
                                positions[0].column === col;
                            if (!cell.positionParent)
                                return (
                                    <div
                                        key={`${top}-tableView-${left}`}
                                        className="cell-element"
                                        style={{
                                            width: width,
                                            height: height,
                                            overflow: "hidden",
                                            zIndex: cell.zIndex,
                                            transform: `translate(${left}px,${top}px)`,
                                        }}
                                        onMouseDown={(event) => {
                                            if (onClick) {
                                                onClick({
                                                    row: row,
                                                    column: col,
                                                    colspan: cell.colspan,
                                                    rowspan: cell.rowspan,
                                                });
                                                if (active) {
                                                    setFocus(
                                                        textAttribute.content
                                                    );
                                                    selectText(cell.id);
                                                } else {
                                                    setFocus(null);
                                                }
                                            }
                                        }}
                                    >
                                        <div
                                            className="not-click"
                                            style={{
                                                width: width - borderWidth,
                                                height: height - borderWidth,
                                                background:
                                                    cell?.colorCell?.background,
                                            }}
                                        />
                                        <div
                                            id={cell.id}
                                            ref={active ? ref : null}
                                            className="canvas-text"
                                            contentEditable={
                                                onInput &&
                                                active &&
                                                focus !== null
                                                    ? true
                                                    : false
                                            }
                                            onMouseDown={(e) => {
                                                if (focus) {
                                                    const selection =
                                                        window.getSelection();
                                                    selection.removeAllRanges();
                                                    if (
                                                        cell.textAttribute
                                                            .content !== ""
                                                    ) {
                                                        e.stopPropagation();
                                                    }
                                                }
                                            }}
                                            style={{
                                                left: borderWidth,
                                                position: "absolute",
                                                maxHeight: textHeight,
                                                width: textWidth,
                                                fontSize:
                                                    textAttribute.fontSize,
                                                color:
                                                    textAttribute.fontColor ??
                                                    "#212121",
                                                fontFamily:
                                                    textAttribute.fontFamily,
                                                fontStyle:
                                                    textAttribute.isItalic
                                                        ? "italic"
                                                        : "normal",
                                                fontWeight: textAttribute.isBold
                                                    ? "bold"
                                                    : null,
                                                textAlign: textAttribute.align,
                                                textDecoration:
                                                    textAttribute.underline
                                                        ? "underline"
                                                        : "none",
                                                whiteSpace: "pre-line",
                                                wordBreak: "break-word",
                                                outline: "none",
                                                border: "none",
                                                lineHeight:
                                                    textAttribute.lineHeight,
                                                letterSpacing:
                                                    textAttribute.letterSpacing +
                                                    "em",
                                                paddingLeft:
                                                    focus && active
                                                        ? 0
                                                        : getBoundingTextBox(
                                                              textAttribute
                                                          ).paddingLeft,

                                                transition:
                                                    "padding-left 100ms",
                                            }}
                                            onInput={(event) => {
                                                if (onInput) {
                                                    onInput({
                                                        row,
                                                        col,
                                                        width: textWidth,
                                                        height: textHeight,
                                                        value: event
                                                            .currentTarget
                                                            .innerText,
                                                    });
                                                }
                                            }}
                                            onBlur={() => {
                                                if (focus !== null)
                                                    setFocus(null);
                                                if (onBlur) onBlur();
                                            }}
                                            suppressContentEditableWarning
                                        >
                                            {active && focus !== null
                                                ? focus
                                                : textAttribute.content}
                                        </div>
                                    </div>
                                );
                        })}
                    </div>
                );
            })}
            <svg className="background-table" width={"100%"} height={"100%"}>
                {positionCells.map((position) => {
                    const { row, col } = position;
                    return (
                        <>
                            {backgroundCellTable({
                                tableAttribute,
                                col,
                                row,
                            })}
                        </>
                    );
                })}
            </svg>
        </div>
    );
});

export default TableView;
