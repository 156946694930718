import { useEffect } from "react";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";
import { useAppDispatch } from "../../../../../../redux/hook";
import {
    removeResourceItems,
    updateResourceItems,
} from "../../../../../../redux/reducers/createWorksheet";
import PopupModal from "../../../../../../resource-user/components/common/pop-up/PopupModal";
import {
    EventTracking,
    sendEvent,
} from "../../../../../../resource-user/utils/event";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import {
    DirectionTable,
    TableAttribute,
} from "../../../../../../shared/models/tableAttribute";
import tableStore from "../../../../../../syncExternalStore/tableStore";
import {
    deleteCells,
    mergeCells,
    unMergeCells,
} from "../../../../../../utils/table";
import "./index.scss";
enum ActionTable {
    un_merge_cells = "unmerge_cells",
    merge_cells_row = "Merge cells row",
    add_row = "Add row",
    delete_row = "Delete row",
    move_row_up = "Move row up",
    move_row_down = "Move row down",
    merge_cells_col = "Merge cells col",
    add_column = "Add column",
    delete_column = "Delete column",
    move_column_right = "Move column right",
    move_column_left = "Move column left",
}

const ListActionRow = [
    {
        type: ActionTable.un_merge_cells,
        name: "Unmerge cell",
        icon: IconSVG.un_merge,
        divider: true,
    },
    {
        type: ActionTable.merge_cells_row,
        name: "Merge cells",
        icon: IconSVG.merge_cells,
        divider: true,
    },
    {
        type: ActionTable.add_row,
        name: "Add row",
        icon: IconSVG.add_row,
        divider: false,
    },
    {
        type: ActionTable.delete_row,
        name: "Delete row",
        icon: IconSVG.delete,
        divider: true,
    },
    {
        type: ActionTable.move_row_up,
        name: "Move row up",
        icon: IconSVG.arrowUp,
        divider: false,
    },
    {
        type: ActionTable.move_row_down,
        name: "Move row down",
        icon: IconSVG.arrowDown,
        divider: false,
    },
];
const ListActionColumn = [
    {
        type: ActionTable.un_merge_cells,
        name: "Unmerge cell",
        icon: IconSVG.un_merge,
        divider: true,
    },
    {
        type: ActionTable.merge_cells_col,
        name: "Merge cells",
        icon: IconSVG.merge_cells,
        divider: true,
    },
    {
        type: ActionTable.add_column,
        name: "Add column",
        icon: IconSVG.add_row,
        divider: false,
    },
    {
        type: ActionTable.delete_column,
        name: "Delete column",
        icon: IconSVG.delete,
        divider: true,
    },
    {
        type: ActionTable.move_column_right,
        name: "Move column right",
        icon: IconSVG.arrow_right,
        divider: false,
    },
    {
        type: ActionTable.move_column_left,
        name: "Move column left",
        icon: IconSVG.arrow_left,
        divider: false,
    },
];

const AllAction = [
    {
        type: ActionTable.merge_cells_row,
        name: "Merge cells",
        icon: IconSVG.merge_cells,
        divider: true,
    },
    {
        type: ActionTable.merge_cells_col,
        name: "Merge cells",
        icon: IconSVG.merge_cells,
        divider: true,
    },
    {
        type: ActionTable.un_merge_cells,
        name: "Unmerge cell",
        icon: IconSVG.un_merge,
        divider: true,
    },
    {
        type: ActionTable.add_row,
        name: "Add row",
        icon: IconSVG.add_row,
        divider: false,
    },
    {
        type: ActionTable.delete_row,
        name: "Delete row",
        icon: IconSVG.delete,
        divider: false,
    },
    {
        type: ActionTable.add_column,
        name: "Add column",
        icon: IconSVG.add_row,
        divider: false,
    },
    {
        type: ActionTable.delete_column,
        name: "Delete column",
        icon: IconSVG.delete,
        divider: true,
    },
    {
        type: ActionTable.move_row_up,
        name: "Move row up",
        icon: IconSVG.arrowUp,
        divider: false,
    },
    {
        type: ActionTable.move_row_down,
        name: "Move row down",
        icon: IconSVG.arrowDown,
        divider: false,
    },
    {
        type: ActionTable.move_column_right,
        name: "Move column right",
        icon: IconSVG.arrow_right,
        divider: false,
    },
    {
        type: ActionTable.move_column_left,
        name: "Move column left",
        icon: IconSVG.arrow_left,
        divider: false,
    },
];

function MenuEditTable({
    type = null,
    open,
    setOpen,
    anchorElement,
    setCurrentElement,
    currentElement,
}: {
    type?: DirectionTable;
    open: boolean;
    setOpen: any;
    anchorElement: HTMLDivElement;
    setCurrentElement: any;
    currentElement: IResourceItemNew;
}) {
    const dispatch = useAppDispatch();
    const handleClose = () => {
        setOpen(false);
    };
    const ListAction =
        type === null
            ? AllAction
            : type === DirectionTable.row
            ? ListActionRow
            : ListActionColumn;
    const tableAttribute = new TableAttribute(currentElement.tableAttribute);
    const rows = tableAttribute.getRows();
    const cols = tableAttribute.getColumns();
    const direction = tableAttribute.checkMoveCells();
    const currentPositions = tableAttribute.currentPositions;
    const isUnmergeCell =
        currentPositions &&
        currentPositions.length === 1 &&
        (currentPositions[0].colspan > 1 || currentPositions[0].rowspan > 1);
    const directionTable = tableAttribute.getMergeCells();

    const onActionTable = (actionType: ActionTable) => {
        let directionRow = DirectionTable.row;
        let directionCol = DirectionTable.column;
        let isDeleteTable = false,
            dHeight = 0,
            dWidth = 0;

        switch (actionType) {
            case ActionTable.un_merge_cells:
                dHeight = unMergeCells(tableAttribute).dHeight;
                sendEvent(EventTracking.ncw_unmerge_cells);
                break;
            case ActionTable.merge_cells_row:
                dHeight = mergeCells(directionRow, tableAttribute);
                sendEvent(EventTracking.ncw_merge_cells);
                break;
            case ActionTable.add_row:
                dHeight = tableAttribute.addCells({
                    type: directionRow,
                }).dHeight;
                sendEvent(EventTracking.ncw_add_row);
                break;
            case ActionTable.delete_row:
                let valueR = deleteCells(directionRow, tableAttribute);
                isDeleteTable = valueR.isDeleteTable;
                dHeight = valueR.dHeight;
                sendEvent(EventTracking.ncw_delete_row);
                break;
            case ActionTable.move_row_up:
                tableAttribute.moveCells("up");
                sendEvent(EventTracking.ncw_move_row_up);
                break;
            case ActionTable.move_row_down:
                tableAttribute.moveCells("down");
                sendEvent(EventTracking.ncw_move_row_down);
                break;
            case ActionTable.merge_cells_col:
                mergeCells(directionCol, tableAttribute);
                sendEvent(EventTracking.ncw_merge_cells);
                break;
            case ActionTable.add_column:
                dWidth = tableAttribute.addCells({
                    type: directionCol,
                }).dWidth;
                sendEvent(EventTracking.ncw_add_column);
                break;
            case ActionTable.delete_column:
                let value = deleteCells(directionCol, tableAttribute);
                isDeleteTable = value.isDeleteTable;
                dWidth = value.dWidth;
                sendEvent(EventTracking.ncw_delete_column);
                break;
            case ActionTable.move_column_left:
                tableAttribute.moveCells("left");
                sendEvent(EventTracking.ncw_move_column_left);
                break;
            case ActionTable.move_column_right:
                tableAttribute.moveCells("right");
                sendEvent(EventTracking.ncw_move_column_right);
                break;
        }
        return {
            newResourceItem: {
                ...currentElement,
                tableAttribute,
                height: currentElement.height + dHeight,
                width: currentElement.width + dWidth,
            },
            isDeleteTable,
        };
    };

    const onAction = (action: any) => {
        setOpen(false);
        let { newResourceItem, isDeleteTable } = {
            newResourceItem: { ...currentElement },
            isDeleteTable: false,
        };
        let value = onActionTable(action);
        newResourceItem = value.newResourceItem;
        isDeleteTable = value.isDeleteTable;

        if (isDeleteTable) {
            dispatch(
                removeResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceIds: [currentElement.id],
                })
            );
            setCurrentElement(null);
        } else {
            setCurrentElement(newResourceItem);
            dispatch(
                updateResourceItems({
                    pageIndex: currentElement.pageIndex,
                    resourceItems: [newResourceItem],
                })
            );
        }
        onMouseLeave();
    };

    const onMouseEnter = (typeAction: any) => {
        if (typeAction === ActionTable.delete_row) {
            tableStore.updatePosition([
                { row: rows[0].row, col: 0 },
                {
                    row: rows[rows.length - 1].row,
                    col: tableAttribute.data[0].length - 1,
                },
            ]);
        }
        if (typeAction === ActionTable.delete_column) {
            tableStore.updatePosition([
                { row: 0, col: cols[0].col },
                {
                    row: tableAttribute.data.length - 1,
                    col: cols[cols.length - 1].col,
                },
            ]);
        }
    };

    const onMouseLeave = () => {
        tableStore.resetPosition();
    };

    useEffect(() => {
        if (!open) {
            tableStore.resetPosition();
        }
    }, [open]);

    return (
        <div>
            <PopupModal
                open={open}
                anchorElement={anchorElement}
                onClose={function (): void {
                    handleClose();
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                left={8}
            >
                <div className="popup-edit-table-container">
                    {ListAction.map((action: any, index: any) => {
                        let name = action.name;
                        let disaple = false;
                        switch (action.type) {
                            case ActionTable.un_merge_cells:
                                if (!isUnmergeCell) return null;
                                break;
                            case ActionTable.merge_cells_col:
                                if (
                                    isUnmergeCell ||
                                    !tableAttribute.checkMergeCells(
                                        DirectionTable.column
                                    )
                                )
                                    return null;
                                if (
                                    type === null &&
                                    directionTable === DirectionTable.row
                                )
                                    return null;
                                if (type === null && directionTable === null)
                                    return null;
                                break;
                            case ActionTable.merge_cells_row:
                                if (
                                    isUnmergeCell ||
                                    !tableAttribute.checkMergeCells(
                                        DirectionTable.row
                                    )
                                )
                                    return null;
                                if (
                                    type === null &&
                                    directionTable !== DirectionTable.row
                                )
                                    return null;
                                if (type === null && directionTable === null)
                                    return null;
                                break;
                            case ActionTable.delete_row:
                                if (rows.length > 1) {
                                    name = "Delete " + rows.length + " rows";
                                } else {
                                    name = "Delete row";
                                }
                                if (
                                    tableAttribute.currentPositions.length ===
                                        tableAttribute.data.length *
                                            tableAttribute.data[0].length ||
                                    tableAttribute.data.length === 1 ||
                                    tableAttribute.data.length === rows.length
                                ) {
                                    name = "Delete table";
                                }
                                break;
                            case ActionTable.add_row:
                                if (rows.length > 1) {
                                    name = "Add " + rows.length + " rows";
                                } else {
                                    name = "Add row";
                                }
                                break;
                            case ActionTable.move_row_down:
                                disaple = !direction.down;
                                break;
                            case ActionTable.move_row_up:
                                disaple = !direction.up;
                                break;
                            case ActionTable.delete_column:
                                if (cols.length > 1) {
                                    name = "Delete " + cols.length + " columns";
                                } else {
                                    name = "Delete column";
                                }
                                if (
                                    tableAttribute.currentPositions.length ===
                                        tableAttribute.data.length *
                                            tableAttribute.data[0].length ||
                                    tableAttribute.data[0].length === 1 ||
                                    tableAttribute.data[0].length ===
                                        cols.length
                                ) {
                                    name = "Delete table";
                                }
                                break;
                            case ActionTable.add_column:
                                if (cols.length > 1) {
                                    name = "Add " + cols.length + " columns";
                                } else {
                                    name = "Add column";
                                }
                                break;
                            case ActionTable.move_column_left:
                                disaple = !direction.left;
                                break;
                            case ActionTable.move_column_right:
                                disaple = !direction.right;
                                break;
                        }

                        return (
                            <>
                                <div
                                    key={name}
                                    className="row-item"
                                    onClick={() => {
                                        if (!disaple) onAction(action.type);
                                    }}
                                    onMouseEnter={() =>
                                        onMouseEnter(action.type)
                                    }
                                    onMouseLeave={onMouseLeave}
                                    style={{
                                        opacity: disaple ? 0.5 : 1,
                                        cursor: disaple
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                >
                                    <div className="icon">
                                        <img src={svgPath(action.icon)} />
                                    </div>
                                    <div className="lable">{name}</div>
                                </div>
                                {action.divider && <div className="divider" />}
                            </>
                        );
                    })}
                </div>
            </PopupModal>
        </div>
    );
}

export default MenuEditTable;
