import { useEffect, useState } from "react";

const useResizeHeight = () => {
    const [height, setHeight] = useState(0);
    const getActualHeight = (element: Element) => {
        if (!element) return 0;

        let elementHeight = element.clientHeight; // height with padding
        return elementHeight;
    };

    function waitForElm(selector: any) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                if (getActualHeight(document.querySelector(selector)) > 1) {
                    return resolve(document.querySelector(selector));
                }
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    useEffect(() => {
        const calculateHeight = (debug = false) => {
            const mainLeftContent =
                document.querySelector(".main-left-content");
            const tabContent = document.querySelector(".tab-content-container");
            const backActivity = document.querySelector(
                ".cluster-back-activity"
            );
            const input = document.getElementById("input-wrapper");
            if (mainLeftContent && tabContent && input) {
                const tabContentHeight = getActualHeight(tabContent);
                const leftContentHeight = getActualHeight(mainLeftContent);
                const inputHeight = getActualHeight(input);

                let leftContentHeightWithoutInput =
                    leftContentHeight - inputHeight;

                if (backActivity) {
                    const backActivityHeight = getActualHeight(backActivity);
                    leftContentHeightWithoutInput += backActivityHeight;
                }

                let height = tabContentHeight - leftContentHeightWithoutInput;

                if (height < 114) {
                    // MIN HEIGHT
                    height = 114;
                }

                if (debug) {
                    console.log("tabContentHeight", tabContentHeight);
                    console.log("leftContentHeight", leftContentHeight);
                    console.log("inputHeight", inputHeight);
                    console.log("height", height);
                }

                input.style.height = `${height}px`;
                setHeight(height);
            }
        };

        waitForElm("#input-wrapper")
            .then(() => {
                calculateHeight();
            })
            .catch((e) => console.log(e));
    }, []);

    return height;
};

export default useResizeHeight;
