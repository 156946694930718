import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import { updateNumberRenderResource, setAllImagesLoaded, setAutoResizeDone } from "../../../../../../redux/reducers/createWorksheet";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import Config from "../../../../../../shared/utils/config";
import { isResourceNotClick, isResourceItemImage, isResourceItemText } from "../../../../../../utils";
import ElementWorksheet from "../../../ElementWorksheet";
import ConstantsTool from '../../../../../../shared/utils/ConstantsTool';

const ListElementWorksheet = React.forwardRef<
    HTMLDivElement,
    {
        pageIndex: number;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew;
        canClick: boolean;
        setSpaceToTopLeft: (value: any) => void;
        setActiveDarg: any;
        // setOnDrag: (value: boolean) => void;
        // hoverElement: IResourceItemNew;
        // setHover: (hoverEle: IResourceItemNew | null) => void;
    }
>(
    (
        {
            pageIndex,
            setCurrentElement,
            currentElement,
            canClick,
            setSpaceToTopLeft,
            setActiveDarg,
            // setOnDrag,
            // hoverElement,
            // setHover,
        },
        ref
    ) => {
        const dispatch = useAppDispatch();
        const resourceItems: IResourceItemNew[] = useAppSelector(
            (state) =>
                state.createWorksheetState.pagesWorksheet[pageIndex]
                    .resourceItems
        );
        const currentActivity = useAppSelector(
            (state) => state.createWorksheetState.currentActivity?.type
        );
        const numberRenderResource = useAppSelector(
            (state) => state.createWorksheetState.numberRenderResource
        );
        const loading: boolean = useAppSelector(
            (state) => state.createWorksheetState.loading
        );

        const templateZoneDisplayMode = useAppSelector(
            (state) => state.createWorksheetState.templateZoneDisplayMode
        );

        const [loadedImagesCount, setLoadedImagesCount] = useState<number>(0);

        useEffect(() => {
            const handleAllImagesLoaded = () => {
                dispatch(setAllImagesLoaded(false));
                const imagesCount = resourceItems.filter((item) => isResourceItemImage(item) && item?.imageAttribute?.url).length;
                if (imagesCount <= loadedImagesCount) {
                    dispatch(setAllImagesLoaded(true));
                }
            };

            handleAllImagesLoaded();
        }, [resourceItems, loadedImagesCount]);

        useEffect(() => {
            const handleAutoResizeDone = () => {
                if (templateZoneDisplayMode === 'textOnly') {
                    dispatch(setAutoResizeDone(false));
                    const textItems = resourceItems.filter((item) => isResourceItemText(item) && item.fieldName !== ConstantsTool.WORD_COUNT_ZONE);
                    setTimeout(() => {
                        dispatch(setAutoResizeDone(textItems.every((item: IResourceItemNew) => item.textAttribute.isAutoResizeDone)));
                    }, 500);
                }
            };

            handleAutoResizeDone();
        }, [resourceItems, templateZoneDisplayMode]);

        useEffect(() => {
            if (!loading && resourceItems?.length > 0) {
                if (
                    currentActivity !== Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE &&
                    numberRenderResource == 0
                ) {
                    dispatch(updateNumberRenderResource(2));
                } else {
                    dispatch(updateNumberRenderResource());
                }
            }
        }, [resourceItems, loading]);

        const handleImageLoad = () => {
            setLoadedImagesCount((prev) => (prev + 1));
        }

        return (
            <React.Fragment>
                {resourceItems
                    .filter(
                        (resourceItem) =>
                            resourceItem.isShow && !resourceItem.isGroup
                    )
                    .map((resourceItem, index) => {
                        return (
                            <ElementWorksheet
                                ref={
                                    currentElement?.id === resourceItem.id
                                        ? ref
                                        : null
                                }
                                currentElement={
                                    currentElement?.id === resourceItem.id
                                        ? currentElement
                                        : null
                                }
                                // setOnDrag={setOnDrag}
                                canClick={
                                    isResourceNotClick(resourceItem)
                                        ? false
                                        : canClick
                                }
                                setCurrentElement={setCurrentElement}
                                key={resourceItem.id}
                                resourceId={resourceItem.id}
                                pageIndex={pageIndex}
                                setSpaceToTopLeft={setSpaceToTopLeft}
                                setActiveDarg={setActiveDarg}
                                handleImageLoad={handleImageLoad}
                                // hoverElement={
                                //     hoverElement?.id === resourceItem.id
                                //         ? hoverElement
                                //         : null
                                // }
                                // setHover={setHover}
                            />
                        );
                    })}
            </React.Fragment>
        );
    }
);
export default ListElementWorksheet;
