import { IAssignment } from "../../../shared/models/assignment";
import { Class, IClass } from "../../../shared/models/class";
import { APIConfig } from "../../utils/api-config";
import { POST } from "./index";

import { IUserClass } from "../../../shared/models/userClass";

export const getClassByUserId = (
    userId: string,
    getStudentInfo = false,
    getAssignmentInfo = false,
    limit = -1
): Promise<IUserClass | null> => {
    return POST({
        url: APIConfig.GET_CLASSES_BY_USERID,
        params: {
            userId,
            getStudentInfo,
            getAssignmentInfo,
            limit,
        },
    }).then((data: any) => {
        return data ? data.map((e) => new Class(e)) : [];
    });
};

export const getAssignments = (
    userId: string,
    worksheetId: string
): Promise<IAssignment | null> => {
    return POST({
        url: APIConfig.GET_ASSIGNMENT,
        params: {
            userId,
            worksheetId,
        },
    }).then((data: any) => {
        return data;
    });
};

export const updateAssignment = (
    assignmentObj: IAssignment
): Promise<IAssignment | null> => {
    return POST({
        url: APIConfig.UPDATE_ASSIGNMENT,
        params: assignmentObj,
    }).then((data: any) => {
        return data;
    });
};

export const createClass = (
    teacherIds: string,
    name: string
): Promise<IClass | null> => {
    return POST({
        url: APIConfig.CREATE_CLASS,
        params: {
            teacherIds,
            name,
        },
    }).then((data: any) => {
        return data;
    });
};
