import axios from 'axios';
import ConstantsTool from '../shared/utils/ConstantsTool';

export const getDataFromAPI = async (baseUrl: string, fullUrl = false) => {
    const url = fullUrl ? baseUrl : (ConstantsTool.API_URL + baseUrl);
    const res = await axios.get(url, {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
        }
    });
    return res;
}

export const updateDataAPI = async (method: string, url: string, data = {}) => {
    const options = {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
        }
    };
    let res: any;
    if (method === 'PATCH') {
        res = await axios.patch(ConstantsTool.API_URL + url, data, options);
    } else if (method === 'DELETE') {
        res = await axios.delete(ConstantsTool.API_URL + url, options);
    } else {
        res = await axios.post(ConstantsTool.API_URL + url, data, options);
    }
    return res;
}