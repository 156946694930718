import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    changeClueCrosswordAction,
    changeErrorWordsCrosswordAction,
    changeWordsAction,
} from "../../../../../../resource-user/redux/action/crossword.action";
import { IWord } from "../../../../../../shared/models/crossword";
import { getPrefixImage } from "../../../../../../utils";
import {
    checkWordsError,
    convertStrToWord,
    convertWordToStr,
} from "../../../utils";
import "./index.scss";
import { sendEvent } from "../../../../../../resource-user/utils/event";

const PasteInput = () => {
    const dispatch = useAppDispatch();
    const words: IWord[] = useAppSelector(
        (state: any) => state.crosswordState.words
    );
    const [str, setStr] = useState(convertWordToStr(words));

    useEffect(() => {
        const strState = convertWordToStr(words);
        const _str = convertWordToStr(convertStrToWord(str));
        if (strState !== _str) {
            setStr(strState);
        }
    }, [words]);

    const handleChange = (e) => {
        let value = e.target.value as string;
        if (e.nativeEvent.inputType === "insertFromPaste") {
            value = value.split("\t").join(", ");
        }

        const newWords = convertStrToWord(value);
        const { isChange, index } = isChangeClue(newWords);
        const { error, errorWords, words } = checkWordsError(newWords);

        if (error) {
            value = convertWordToStr(words);
            dispatch(changeErrorWordsCrosswordAction(errorWords));
            dispatch(changeWordsAction(words));
        } else if (isChange) {
            dispatch(
                changeClueCrosswordAction(newWords[index]?.clue ?? "", index)
            );
        } else {
            dispatch(changeWordsAction(words));
        }
        setStr(value);
    };

    const isChangeClue = (newWords: IWord[]) => {
        const result = {
            index: -1,
            isChange: false,
        };

        for (let i = 0; i < newWords.length; i++) {
            const word = words?.[i];
            const _word = newWords?.[i];

            if (word?.clue !== _word?.clue && word?.value === _word?.value) {
                result.index = i;
                result.isChange = true;
            }
        }

        return result;
    };

    return (
        <div className="paste-input">
            <div className="image-example">
                <img
                    src={
                        getPrefixImage() +
                        "/images/crossword-icons/input-icon/example-input.png"
                    }
                    alt="excel input"
                />
            </div>
            <div className="input-excel">
                <TextField
                    autoFocus
                    fullWidth
                    multiline
                    rows={5}
                    id="multi-text"
                    className="multi-text"
                    placeholder={`Type or paste your list of words here\n\nExample:\ndog, man's best friend\ncat, likes to chase mice`}
                    value={str}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                        const words = convertStrToWord(str);
                        sendEvent("crw_type_on_copy_from_excel", {
                            value: words.map((w) => w.value),
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default PasteInput;
