import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
    removeWordList,
    setShowWordList,
    updateDirections,
    updateResourceItem,
    updateSizeModule,
    updateWordList,
    updateWorkSheetCreator,
} from "../../../redux/reducers/createWorksheet";
import { updateCellPxAction } from "../../../resource-user/redux/action/wordsearch.action";
import { IInputWord } from "../../../shared/models/puzzle";
import {
    IResourceItemNew,
    ResourceItemNew,
    WordAnswer,
} from "../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import Config from "../../../shared/utils/config";
import { getSizeFromShape } from "../../../utils";
import { getTextWidthHeightTool } from "../../../utils/draw";
// import { updateCellPxAction } from "../../../resource-user/redux/action/wordsearch.action";

const ListenWordSearch = ({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (value: IResourceItemNew) => void;
}) => {
    const dispatch = useAppDispatch();
    const forceUpdate = useAppSelector(
        (state) => state.createWorksheetState.forceUpdate
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const inputWords = useAppSelector(
        (state) => state.wordSearchState.inputWords
    );

    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const puzzleSizeName = useAppSelector(
        (state) => state.wordSearchState.puzzle.puzzleSize
    );
    const showWordList = useAppSelector(
        (state) => state.wordSearchState.puzzle.showWordList
    );
    const puzzleArrNumber = useAppSelector(
        (state) => state.wordSearchState.puzzle.puzzleArrNumber
    );
    const puzzleShape = useAppSelector(
        (state) => state.wordSearchState.puzzle.puzzleShape
    );
    const showDirections = useAppSelector(
        (state) => state.wordSearchState.puzzle.showWordDirection
    );
    const valueDirection = useAppSelector(
        (state) => state.wordSearchState.puzzle.wordDirections
    );
    const puzzleTitle = useAppSelector(
        (state) => state.wordSearchState.puzzle.title
    );
    const [isStarted, setIsStarted] = useState(false)
    const handleUpdateWordList = (inputWords: IInputWord[]) => {
        let listWordList: WordAnswer[] = inputWords.map((el) => {
            return {
                status: el.status,
                word: el.word,
            };
        });
        const startWidth =
            ConstantsTool.BORDER_X +
            ConstantsTool.PADDING_BORDER +
            ConstantsTool.PADDING_WORD_LIST;
        let elementX = startWidth;
        let heightOneRow = getTextWidthHeightTool({
            textValue: listWordList[0].word.toUpperCase(),
            includePadding: false,
            fontSize: ConstantsTool.DEFAULT_WORD_LIST_FONT_SIZE,
            fontFamily: ConstantsTool.DEFAULT_FONT,
            paddingBottom: false,
        }).height;
        let height = heightOneRow + ConstantsTool.PADDING_WORD_LIST * 2;
        const maxWidth =
            ConstantsTool.BORDER_WIDTH - ConstantsTool.PADDING_WORD_LIST * 2;
        let listRows: WordAnswer[][] = [];
        let rows: WordAnswer[] = [];
        for (let i = 0; i < listWordList.length; i++) {
            let word: string = listWordList[i].word;
            let widthElement = getTextWidthHeightTool({
                textValue: word.toUpperCase(),
                includePadding: false,
                fontSize: ConstantsTool.DEFAULT_WORD_LIST_FONT_SIZE,
                fontFamily: ConstantsTool.DEFAULT_FONT,
            }).width;
            if (elementX + widthElement > maxWidth) {
                height += ConstantsTool.MARGIN_ROW_WORD_LIST + heightOneRow;
                elementX = startWidth;
                listRows.push(rows);
                rows = [];
            }
            rows.push(listWordList[i]);
            elementX = elementX + widthElement + ConstantsTool.MARGIN_WORD_LIST;
        }
        if (rows.length > 0) {
            listRows.push(rows);
        }
        return {
            listRows,
            height,
        };
    };
    useEffect(() => {
        if (pagesWorksheet.length > 0 || forceUpdate) {
            dispatch(
                updateDirections({
                    showDirections: !showWordList
                        ? showWordList
                        : showDirections,
                    valueDirections: valueDirection,
                })
            );
        }
    }, [showDirections, valueDirection, showWordList, forceUpdate]);
    useEffect(() => {
        if (
            (pagesWorksheet.length > 0 || forceUpdate) &&
            currentActivity.type === Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE
        ) {
            let objSize = getSizeFromShape(
                puzzleArrNumber?.length,
                puzzleArrNumber[0]?.length,
                1
            );
            const MAX_HEIGHT = ConstantsTool.MAX_HEIGHT_WORD_SEARCH;
            let newPuzzleCellPx = -1;
            if (objSize.totalHeight > MAX_HEIGHT) {
                newPuzzleCellPx = MAX_HEIGHT / puzzleArrNumber.length;
                objSize = getSizeFromShape(
                    puzzleArrNumber.length,
                    puzzleArrNumber[0].length,
                    1,
                    newPuzzleCellPx
                );
            }
            dispatch(updateCellPxAction(newPuzzleCellPx));
            let { totalWidth, totalHeight } = objSize;
            dispatch(
                updateSizeModule({
                    width: totalWidth,
                    height: totalHeight,
                    size: puzzleSizeName,
                    showWordDirection: showDirections,
                })
            );
            if (currentElement?.type === ConstantsTool.TYPE_RESOURCE_MODULE) {
                setCurrentElement({
                    ...currentElement,
                    width: totalWidth,
                    height: totalHeight,
                    x: ConstantsTool.WIDTH_A4 / 2 - totalWidth / 2,
                    y: puzzleSizeName === "Large" ? 230 : currentElement.y,
                });
            }
        }
    }, [puzzleSizeName, puzzleShape, forceUpdate]);
    useEffect(() => {
        if (
            pagesWorksheet.length > 0 &&
            currentActivity?.type === Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE
        ) {
            if (showWordList && inputWords.length) {
                if (inputWords.length) {
                    let wordListTitle = pagesWorksheet[0].resourceItems.find(
                        (el) => el.idType?.includes("word_list_title_default")
                    );
                    let wordDirections = pagesWorksheet[0].resourceItems.find(
                        (el) => el.idType?.includes("word_direction_default")
                    );
                    let wordListResource = null;
                    for (let i = 0; i < pagesWorksheet.length; i++) {
                        if (!wordListResource) {
                            wordListResource = pagesWorksheet[
                                i
                            ].resourceItems.find(
                                (el) => el.type === ConstantsTool.TYPE_WORD_LIST
                            );
                        }
                    }
                    let elementStartHeight = showDirections
                        ? wordDirections
                        : wordListTitle;
                    let pageIndex = 0;
                    let maxHeight =
                        ConstantsTool.BORDER_Y +
                        ConstantsTool.BORDER_HEIGHT -
                        ConstantsTool.PADDING_BORDER -
                        elementStartHeight?.y -
                        elementStartHeight?.height;
                    let isNeedNewPage = false;
                    let { listRows, height } = handleUpdateWordList(inputWords);
                    if (height > maxHeight) {
                        isNeedNewPage = true;
                        pageIndex += 1;
                    }
                    let newResource = new ResourceItemNew({
                        id: "word_list_" + new Date().getTime().toString(),
                        y: isNeedNewPage
                            ? wordListResource?.y ?? 0
                            : elementStartHeight?.y +
                              elementStartHeight?.height +
                              ConstantsTool.WORD_DIRECTION_MARGIN_TOP,
                        x:
                            ConstantsTool.BORDER_X +
                            ConstantsTool.PADDING_BORDER,
                        width:
                            ConstantsTool.BORDER_WIDTH -
                            ConstantsTool.PADDING_BORDER * 2,
                        height: height,
                        type: ConstantsTool.TYPE_WORD_LIST,
                        textAttribute: {
                            fontSize: ConstantsTool.DEFAULT_WORD_LIST_FONT_SIZE,
                            fontFamily: "Nunito",
                            fontColor:
                                wordListResource?.textAttribute?.fontColor,
                        },
                        pageIndex: pageIndex,
                        wordList: listRows,
                        activityId: currentActivity.id,
                    });
                    let listResourceApply: IResourceItemNew[] = [];
                    if (isNeedNewPage) {
                        let background = pagesWorksheet[0].resourceItems.find(
                            (el) =>
                                el.type === ConstantsTool.RESOURCE_BACKGROUND ||
                                el.type ===
                                    ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                        );
                        if (
                            background &&
                            currentElement?.id !== background?.id
                        ) {
                            setCurrentElement(background);
                        }
                        if (pageIndex >= pagesWorksheet.length) {
                            let newWordListTitle = new ResourceItemNew({
                                ...wordListTitle,
                                id:
                                    pageIndex.toString() +
                                    "_" +
                                    "word_list_title",
                                y:
                                    ConstantsTool.BORDER_Y +
                                    ConstantsTool.PADDING_BORDER,
                                activityId: currentActivity.id,
                                pageIndex: pageIndex,
                                isShow: true,
                            });
                            newResource.y =
                                newWordListTitle.y +
                                newWordListTitle.height +
                                ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                            listResourceApply.push(newWordListTitle);
                            if (showDirections) {
                                let newWordDirection = new ResourceItemNew({
                                    ...wordDirections,
                                    id:
                                        pageIndex.toString() +
                                        "_" +
                                        "word_direction",
                                    activityId: currentActivity.id,
                                    y:
                                        newWordListTitle.y +
                                        newWordListTitle.height +
                                        ConstantsTool.WORD_DIRECTION_MARGIN_TOP,
                                    pageIndex: pageIndex,
                                    isShow: true,
                                });
                                newResource.y =
                                    newWordDirection.y +
                                    newWordDirection.height +
                                    ConstantsTool.WORD_DIRECTION_MARGIN_TOP;
                                listResourceApply.push(newWordDirection);
                            }
                        }
                    }
                    listResourceApply.push(newResource);
                    dispatch(
                        updateWordList({
                            pageIndex: pageIndex,
                            isNeedNewPage: isNeedNewPage,
                            resourceItems: listResourceApply,
                            showDirections: showDirections,
                        })
                    );
                }
            } else {
                dispatch(removeWordList());
            }
            dispatch(setShowWordList(showWordList));
        }
    }, [
        showWordList,
        inputWords,
        currentActivity?.size,
        forceUpdate,
        currentActivity?.type,
        currentActivity?.showWordDirection,
    ]);

    // useEffect(() => {
    //     if (pagesWorksheet.length > 0 || forceUpdate) {
    //         handleUpdateInformation({
    //             field: "description",
    //             content: puzzleDescription,
    //             maxWidth: ConstantsTool.WIDTH_A4 - 80,
    //         });
    //     }
    // }, [puzzleDescription, forceUpdate]);
    // useEffect(() => {
    //     if (pagesWorksheet.length > 0 || forceUpdate) {
    //         dispatch(updateGenerator(creator));
    //     }
    // }, [creator, forceUpdate]);
    // useEffect(() => {
    //     if (pagesWorksheet.length > 0 && studentInfos) {
    //         let studentInfoElement: IResourceItemNew[] =
    //             pagesWorksheet[0].resourceItems.filter((el: IResourceItemNew) =>
    //                 el.idType?.includes("student")
    //             );
    //         let filterLength = studentInfos.filter((el) => el.length > 0);
    //         const newResourceItems = getResourceItemsStudentInfo(
    //             filterLength,
    //             studentInfoElement
    //         );
    //         dispatch(updateStudentInfo(newResourceItems));
    //     }
    // }, [studentInfos, pagesWorksheet.length]);
    useEffect(() => {
        if (pagesWorksheet[0]?.resourceItems?.length > 0 && puzzleTitle) {
            let eleTitleArr = pagesWorksheet[0].resourceItems.filter((e) =>
                e.id?.includes("ws_title_")
            );
            if (eleTitleArr.length > 0) {
                let eleTitle = eleTitleArr[0];
                let isGenerator = eleTitle.idType?.includes("ws_generator");
                let textSize = getTextWidthHeightTool({
                    textValue: puzzleTitle,
                    includePadding: true,
                    fontSize: eleTitle.textAttribute?.fontSize,
                    fontFamily: eleTitle.textAttribute?.fontFamily,
                    maxWidth: isGenerator ? null : eleTitle.width,
                });
                dispatch(
                    updateResourceItem({
                        currentElementId: eleTitle.id,
                        pageIndex: 0,
                        attrValues: [
                            {
                                attr: "textAttribute",
                                value: {
                                    ...eleTitle.textAttribute,
                                    content: puzzleTitle,
                                },
                            },
                            {
                                attr: "height",
                                value: textSize.height,
                            },
                        ],
                        isChangeEidtPage: isStarted,
                        isAddHistory: isStarted,
                    })
                );
            }
        }
        if(!isStarted){
            setIsStarted(true)
        }
    }, [puzzleTitle]);
    return null;
};

export const ListenWordSearchCreator = () => {
    const dispatch = useAppDispatch();

    const showWordList = useAppSelector(
        (state) => state.wordSearchState.puzzle.showWordList
    );
    const showWordDirection = useAppSelector(
        (state) => state.wordSearchState.puzzle.showWordDirection
    );
    const puzzleShape = useAppSelector(
        (state) => state.wordSearchState.puzzle.puzzleShape
    );
    const showAnswerKey = useAppSelector(
        (state) => state.wordSearchState.puzzle.showAnswerKey
    );
    const puzzleSize = useAppSelector(
        (state) => state.wordSearchState.puzzle.puzzleSize
    );
    const puzzleTitle = useAppSelector(
        (state) => state.wordSearchState.puzzle.title
    );
    const puzzleDescription = useAppSelector(
        (state) => state.wordSearchState.puzzle.description
    );
    const creator = useAppSelector(
        (state) => state.wordSearchState.puzzle.creator
    );
    const studentInfos = useAppSelector(
        (state) => state.wordSearchState.puzzle.studentInfos
    );

    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                activity: {
                    showWordDirection: showWordDirection,
                },
            })
        );
    }, [showWordDirection]);

    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                activity: { showWordList: showWordList },
            })
        );
    }, [showWordList]);

    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                activity: { shape: puzzleShape },
            })
        );
    }, [puzzleShape]);

    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                activity: { size: puzzleSize },
            })
        );
    }, [puzzleSize]);
    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                activity: { showAnswerKey: showAnswerKey },
            })
        );
    }, [showAnswerKey]);
    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                worksheetCreator: { title: puzzleTitle },
            })
        );
    }, [puzzleTitle]);
    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                worksheetCreator: {
                    description: puzzleDescription,
                },
            })
        );
    }, [puzzleDescription]);
    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                worksheetCreator: { creator: creator },
            })
        );
    }, [creator]);
    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({
                worksheetCreator: {
                    studentInfos: studentInfos,
                },
            })
        );
    }, [studentInfos]);
    return null;
};

export default ListenWordSearch;
