import { IActivity } from "../shared/models/WorkSheetCreator";
import { IPageWorksheetNew } from "../shared/models/pageWorksheetNew";

export interface IHistoryToolV2 {
    pagesWorksheet: IPageWorksheetNew[];
    currentActivity: IActivity | null;
}

interface IStore {
    past: IHistoryToolV2[],
    present: IHistoryToolV2,
    future: IHistoryToolV2[],
}

let store: IStore = {
    past: [],
    present: { pagesWorksheet: [], currentActivity: null },
    future: []
}

let subscribers: any = new Set()

const undoHistoryStore = {
    read() {
        return store;
    },
    subscribe(callback: any) {
        subscribers.add(callback);
        return () => subscribers.delete(callback);
    },
    undo: (history: IHistoryToolV2) => {
        let { pagesWorksheet, currentActivity } = history
        const previous = store.past[store.past.length - 1]
        const newPast = store.past.slice(0, store.past.length - 1)
        store.past = newPast
        store.future = [history, ...store.future]
        store.present = previous
        notifyListener()
    },
    redo: () => {
        const next = store.future[0]
        const newFuture = store.future.slice(1)
        store.past.push(JSON.parse(JSON.stringify(store.present)))
        store.present = next
        store.future = newFuture
        notifyListener()
    },
    update: (history?: { pagesWorksheet?: IPageWorksheetNew[], currentActivity?: IActivity }) => {
        let { pagesWorksheet, currentActivity } = history
        store.present = {
            pagesWorksheet: pagesWorksheet ?? store.present.pagesWorksheet,
            currentActivity: currentActivity ?? store.present.currentActivity
        }
        store.past.push(JSON.parse(JSON.stringify(store.present)))
        store.future = []
        notifyListener()
    },
    reset: () => {
        if (store.past.length || store.future.length) {
            store.past = []
            store.present = { pagesWorksheet: [], currentActivity: null }
            store.future = []
            notifyListener()
        }
    }
};

function notifyListener() {
    store = { ...store }
    subscribers.forEach((callback: () => any) => {
        callback()
    });
}

export default undoHistoryStore;