import { ICrossword, IPosition, IWord } from "../../../shared/models/crossword";
import { IErrorWords } from "../reducer/crossword.reducer";

export enum CrosswordTypes {
    INIT_CROSSWORD_GAME = "INIT_CROSSWORD_GAME",
    INIT_CROSSWORD_GAME_SUCCESS = "INIT_CROSSWORD_GAME_SUCCESS",
    CHANGE_SINGLE_WORD = "CHANGE_SINGLE_WORD",
    CHANGE_WORDS = "CHANGE_WORDS",
    CHANGE_WORDS_SUCCESS = "CHANGE_WORDS_SUCCESS",
    UPDATE_LAYOUT = "UPDATE_LAYOUT",
    UPDATE_SHOW_WORD_BANK = "UPDATE_SHOW_WORD_BANK",
    UPDATE_SHOW_ANSWER_KEY = "UPDATE_SHOW_ANSWER_KEY",
    UPDATE_CURRENT_CONFIG = "UPDATE_CURRENT_CONFIG",
    UPDATE_CURRENT_CONFIG_SUCCESS = "UPDATE_CURRENT_CONFIG_SUCCESS",
    UPDATE_USER_ANSWER = "UPDATE_USER_ANSWER",
    UPDATE_USER_ANSWER_SUCCESS = "UPDATE_USER_ANSWER_SUCCESS",
    SCRAMBLE_CROSSWORD = "SCRAMBLE_CROSSWORD",
    CHANGE_CLUE_CROSSWORD = "CHANGE_CLUE_CROSSWORD",
    CHANGE_CLUE_CROSSWORD_SUCCESS = "CHANGE_CLUE_CROSSWORD_SUCCESS",
    CHANGE_ERROR_WORDS_CROSSWORD = "CHANGE_ERROR_WORDS_CROSSWORD",
    CHANGE_TITLE_WORDS_CROSSWORD = "CHANGE_TITLE_WORDS_CROSSWORD",
}

export interface ICrosswordAction {
    type: CrosswordTypes;
    config: ICrossword;
    words: IWord[];
    grid: string[][];
    layout: string;
    status: boolean;
    direction: number;
    wordSelected: IWord | null;
    position: IPosition;
    value: string;
    index: number;
    errorWords: IErrorWords[];
    title: string;
}

export const initCrosswordGameAction = (config: ICrossword) => {
    return {
        type: CrosswordTypes.INIT_CROSSWORD_GAME,
        config,
    };
};

export const initCrosswordGameSuccessAction = (config: ICrossword) => {
    return {
        type: CrosswordTypes.INIT_CROSSWORD_GAME_SUCCESS,
        config,
    };
};

export const changeWordsAction = (words?: IWord[]) => {
    return {
        type: CrosswordTypes.CHANGE_WORDS,
        words,
    };
};

export const changeWordsSuccessAciton = (words: IWord[], grid: string[][]) => {
    return {
        type: CrosswordTypes.CHANGE_WORDS_SUCCESS,
        words,
        grid,
    };
};

export const updateLayoutAction = (layout: string) => {
    return {
        type: CrosswordTypes.UPDATE_LAYOUT,
        layout,
    };
};

export const updateShowWordBankAction = (status: boolean) => {
    return {
        type: CrosswordTypes.UPDATE_SHOW_WORD_BANK,
        status,
    };
};

export const updateShowAnswerKeyAction = (status: boolean) => {
    return {
        type: CrosswordTypes.UPDATE_SHOW_ANSWER_KEY,
        status,
    };
};

export const updateCurrentConfigAction = ({
    direction,
    wordSelected,
    position,
}: {
    direction: number;
    wordSelected: IWord | null;
    position: IPosition | null;
}) => {
    return {
        type: CrosswordTypes.UPDATE_CURRENT_CONFIG,
        direction,
        wordSelected,
        position,
    };
};

export const updateCurrentConfigSuccessAction = ({
    direction,
    wordSelected,
    position,
}: {
    direction: number;
    wordSelected: IWord | null;
    position: IPosition | null;
}) => {
    return {
        type: CrosswordTypes.UPDATE_CURRENT_CONFIG_SUCCESS,
        direction,
        wordSelected,
        position,
    };
};

export const updateUserAnswerAction = ({
    position,
    value,
}: {
    position: IPosition;
    value: string;
}) => {
    return {
        type: CrosswordTypes.UPDATE_USER_ANSWER,
        position,
        value,
    };
};

export const updateUserAnswerSuccessAction = (words: IWord[]) => {
    return {
        type: CrosswordTypes.UPDATE_USER_ANSWER_SUCCESS,
        words,
    };
};

export const scrambleCrosswordAction = () => {
    return {
        type: CrosswordTypes.SCRAMBLE_CROSSWORD,
    };
};

export const changeClueCrosswordAction = (value: string, index: number) => {
    return {
        type: CrosswordTypes.CHANGE_CLUE_CROSSWORD,
        value,
        index,
    };
};

export const changeSingleWordAction = (value: string, index: number) => {
    return {
        type: CrosswordTypes.CHANGE_SINGLE_WORD,
        value,
        index,
    };
};

export const changeClueCrosswordSuccessAction = (words: IWord[]) => {
    return {
        type: CrosswordTypes.CHANGE_CLUE_CROSSWORD_SUCCESS,
        words,
    };
};

export const changeErrorWordsCrosswordAction = (errorWords: IErrorWords[]) => {
    return {
        type: CrosswordTypes.CHANGE_ERROR_WORDS_CROSSWORD,
        errorWords,
    };
};

export const changeTitleWordsCrosswordAction = (title: string) => {
    return {
        type: CrosswordTypes.CHANGE_TITLE_WORDS_CROSSWORD,
        title,
    };
};
