import { useEffect, useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import './index.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const PROMPT_TEST =
    "# BACKGROUND You are a #subjects teacher (grades #grades) in the United States who wants to make a worksheet with the following subject #idea_name.";
const SUGGESTED_VALUES = {
    "#subjects": [
        "Math",
        "ELA",
        "Science",
        "Social Studies",
        "History",
        "English",
        "Art",
        "Music",
    ],
};


const strToArr = (str) => {
    const regex = /(#(?:[a-zA-Z_]+))/g;
    return str.split(regex).map((item) => ({
        text: item,
        newText: "",
        isDynamic: regex.test(item),
    }));
};

const TextEditable = (props) => {
    const { value, onChange, suggested, onBlur, onSave, onCancel, isMultiline } = props;
    const [text, setText] = useState(undefined);
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.click();
        setText(value);
    }, []);
    return (
        <>

            {
                isMultiline
                ?
            <textarea
                className="text-editable__textarea"
                autoFocus
                ref={inputRef}
                value={text ?? ""}
                onChange={(event) => setText(event.target.value)}
                onBlur={onBlur}
                rows={5}
            />
            :
            <input
                className="text-editable__input"
                autoFocus
                ref={inputRef}
                type="text"
                value={text ?? ""}
                onChange={(event) => setText(event.target.value)}
                onBlur={onBlur}
            />
            }

            <Overlay
                target={inputRef.current}
                show={true}
                placement="right-start"
            >
                <div className="text-editable__action-list">
                    <div
                        className="text-editable__action-item save"
                        onClick={() => onSave(text)}
                    >
                        Save
                    </div>
                    <div
                        className="text-editable__action-item cancel"
                        onClick={() => onCancel()}
                    >
                        Cancel
                    </div>
                </div>
            </Overlay>
            {Array.isArray(suggested) && (
                <Overlay
                    target={inputRef.current}
                    show={true}
                    placement="bottom"
                >
                    <div className="text-editable__suggest-list">
                        {suggested.map((item) => (
                            <div
                                className="text-editable__suggest-item"
                                onClick={() => {
                                    setText(item);
                                }}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </Overlay>
            )}
        </>
    );
};

const PromptInteractiveBlock = (props) => {
    const { prompt, suggested, onSubmit, isSubmit, init,multilineFields, onlyClickableFields, callbackClickable } = props;
    const [items, setItems] = useState(strToArr(prompt ?? PROMPT_TEST));
    const [suggestedValues, setSuggestedValues] = useState(
        suggested ?? SUGGESTED_VALUES
    );
    const [missIndexes, setMissIndexes] = useState([]);
    const [indexEditable, setIndexEditable] = useState(null);
    const onClickSubmit = () => {
        // check required text dynamic
        // const missIndexDynamicText = items
        //     .map((item, index) =>
        //         item.isDynamic && item.newText.trim() === "" ? index : -1
        //     )
        //     .filter((item) => item !== -1);
        // if (missIndexDynamicText.length > 0) {
        //     setMissIndexes(missIndexDynamicText);
        //     return;
        // }
        // const completePrompt = items
        //     .map((item) => (item.isDynamic ? item.newText : item.text))
        //     .join("");
        // alert(completePrompt);
        // onSubmit(completePrompt);
        let data = {}
        items.forEach(item => {
            if (item.isDynamic) data[item.text] = item.newText
        })
        onSubmit(data)
    };

    useEffect(() => {
        if (isSubmit) {
            onClickSubmit()
        }
    }, [isSubmit])

    useEffect(() => {
        if (init)
        setItems(prev => prev.map(item =>
                (item.isDynamic && init.hasOwnProperty(item.text)) ?
                     {
                        ...item,
                        newText: init[item.text] ?? ''
                    }
                : item
            )
        )
    }, [])

    return (
        <>
            <div className="prompt-interactive">
                {items.map((item, index) => {
                    if (item.isDynamic) {
                        if (index === indexEditable && !onlyClickableFields.includes(item.text)) {
                            return (
                                <TextEditable
                                    value={item.newText}
                                    onSave={(value) => {
                                        const newItems = [...items];
                                        newItems[index].newText = value;
                                        setItems(newItems);
                                        setIndexEditable(null);
                                    }}
                                    onCancel={() => {
                                        setIndexEditable(null);
                                    }}
                                    // onBlur={() => {
                                    //   setIndexEditable(null);
                                    // }}
                                    suggested={suggestedValues[item.text]}
                                    isMultiline={multilineFields.includes(item.text)}
                                />
                            );
                        }
                        return (
                            <OverlayTrigger
                            // placement="right"
                            overlay={<div className="prompt-tooltip-text">

                                {item.text}
                            </div>}
                            >
                                <span
                                className={`prompt-interactive__text ${
                                    missIndexes.includes(index)
                                        ? "prompt-interactive__text--miss"
                                        : "prompt-interactive__text"
                                } ${
                                    item.newText.trim() !== ""
                                        ? "prompt-interactive__text--completed"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (onlyClickableFields.includes(item.text)) {
                                        if (typeof callbackClickable[item.text] === 'function') {
                                            callbackClickable[item.text]()
                                        }
                                        return;
                                    }
                                    setIndexEditable(index);
                                    setMissIndexes([]);
                                }}
                            >
                                {item.newText.trim() !== ""
                                    ? item.newText
                                    : item.text}
                            </span>
                            </OverlayTrigger>
                        )
                        return (
                            <span
                                className={`prompt-interactive__text ${
                                    missIndexes.includes(index)
                                        ? "prompt-interactive__text--miss"
                                        : "prompt-interactive__text"
                                } ${
                                    item.newText.trim() !== ""
                                        ? "prompt-interactive__text--completed"
                                        : ""
                                }`}
                                onClick={() => {
                                    setIndexEditable(index);
                                    setMissIndexes([]);
                                }}
                            >
                                {item.newText.trim() !== ""
                                    ? item.newText
                                    : item.text}
                            </span>
                        );
                    }
                    return <span>{item.text}</span>;
                })}
            </div>
            {/* <Button onClick={() => onClickSubmit()}>Submit</Button> */}
        </>
    );
};

export default PromptInteractiveBlock;