import { Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dashed, Dotted, Solid } from "../../../../assets/stroke-fill-blank";
import { updateWorkSheetCreator } from "../../../../redux/reducers/createWorksheet";
import "./index.scss";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Activity } from "../../../../shared/models/WorkSheetCreator";
import { useAppSelector } from "../../../../redux/hook";

const StrokeStyles = {
    solid: "Solid",
    dashed: "Dashed",
    dotted: "Dotted",
};

const strokeOptions = [
    {
        name: StrokeStyles.solid,
        icon: <Solid></Solid>,
    },
    {
        name: StrokeStyles.dashed,
        icon: <Dashed></Dashed>,
    },
    {
        name: StrokeStyles.dotted,
        icon: <Dotted></Dotted>,
    },
];

const StrokeStyle = ({ activity }: { activity: Activity }) => {
    const listStroke = [
        StrokeStyles.solid,
        StrokeStyles.dashed,
        StrokeStyles.dotted,
    ];
    const dispatch = useDispatch();
    const strokeType = useAppSelector(
        (state) => state.createWorksheetState?.currentActivity?.strokeType
    );
    const [currentStroke, setCurrentStroke] = useState(
        listStroke.includes(strokeType) ? strokeType : "Solid"
    );
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        dispatch(
            updateWorkSheetCreator({ activity: { strokeType: currentStroke } })
        );
    }, [currentStroke]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        console.log("click");
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log("close clicked");
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover-open" : undefined;
    let icon = strokeOptions[0].icon;
    const strokeOption = strokeOptions.find(
        (e) => e.name.toLocaleLowerCase() === currentStroke.toLocaleLowerCase()
    );
    if (strokeOption) icon = strokeOption.icon;
    return (
        <div className="stroke-entry">
            <div className="title">Stroke Style</div>
            <div className="stroke-container" onClick={(e) => handleClick(e)}>
                {icon}
                <span className="select-text">{currentStroke}</span>

                <ExpandMoreRoundedIcon
                    sx={{
                        fontSize: 16,
                        color: "rgba(33, 33, 33, 0.52)",
                    }}
                    style={{
                        transform: open ? "rotate(-180deg)" : "rotate(0)",
                        transition: "all 0.5s",
                    }}
                />
            </div>
            <Popover
                className="dopdown-container-stroke-style"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        marginTop: "8px",
                        borderRadius: "6px",
                        background: " #FFF",
                        boxShadow: "0px 0px 8px 0px rgba(33, 33, 33, 0.12)",
                        marginLeft: "-8px",
                    },
                    "& .MuiPaper-root::-webkit-scrollbar": {
                        width: "10px",
                    },
                    "& .MuiPaper-root::-webkit-scrollbar-track": {
                        background: "white",
                        borderLeft: "1px solid rgba(33, 33, 33, 0.12)",
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                    },
                    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
                        background: "rgba(33, 33, 33, 0.24)",
                        border: "3px solid transparent",
                        backgroundClip: "content-box",
                    },
                }}
            >
                <div className="popup">
                    {strokeOptions.map((strokeOption, index) => (
                        <div
                            className={
                                "menu-stroke-style-item " +
                                (strokeOption.name === currentStroke
                                    ? "selected"
                                    : "")
                            }
                            key={index}
                            onClick={(e) => {
                                setCurrentStroke(strokeOption.name);
                                handleClose();
                            }}
                        >
                            <div className="left-item">
                                <div className="done-icon">
                                    {strokeOption.name === currentStroke && (
                                        <DoneRoundedIcon
                                            sx={{
                                                width: 18,
                                                height: 18,
                                                color: "rgba(59, 130, 246, 1)",
                                            }}
                                        />
                                    )}
                                </div>
                                {strokeOption.icon}
                            </div>
                            <span className="text">{strokeOption.name}</span>
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
};
export default StrokeStyle;
