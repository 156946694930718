import { getResourceProjectData } from "../../../../../redux/async/leftData";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    getListCollectionAction,
    selectCollectionResourceAction,
} from "../../../../../resource-user/redux/action/resource.action";
import { ResourceAppState } from "../../../../../resource-user/redux/reducer/root.reducerModule";
import ConstantsResource from "../../../../../resource-user/utils/ConstantsResource";
import { Collection } from "../../../../../shared/models/collection";
import Config from "../../../../../shared/utils/config";
import "./style.scss";
const LIST_NAV = [
    {
        navName: ConstantsResource.TAB_PROJECT.YOUR_EDITS,
        title: "Your Edits",
        action: "ncw_project_your_edits",
    },
    {
        navName: ConstantsResource.TAB_PROJECT.COLLECTION,
        title: "Collections",
        action: "ncw_project_collections",
    },
    {
        navName: ConstantsResource.TAB_PROJECT.DOWNLOADS,
        title: "Downloads",
        action: "ncw_project_downloads",
    },
];
const NavTabProject = ({
    activeNav,
    setActiveNav,
    setDateCollectionSelected,
}: {
    activeNav: string;
    setActiveNav: Function;
    setDateCollectionSelected: Function;
}) => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const collectionSelected = useAppSelector(
        (state: ResourceAppState) =>
            state.resourceState?.collectionSelected?.data
    );
    return (
        <div className="nav-project">
            {LIST_NAV?.map((nav, index) => {
                return (
                    <div
                        className={
                            "nav-item " +
                            (activeNav === nav.navName ? "active" : "")
                        }
                        key={index}
                        onClick={() => {
                            let id = nav.navName;
                            setDateCollectionSelected(id);
                            if (activeNav !== nav.navName) {
                                setActiveNav(nav.navName);
                                if (
                                    nav.navName ==
                                    ConstantsResource.TAB_PROJECT.COLLECTION
                                ) {
                                    let projectData = {
                                        worksheet: [
                                            "categoryIds",
                                            "description",
                                        ],
                                        collection: [
                                            "categoryIds",
                                            "introductionDescription",
                                        ],
                                    };
                                    dispatch(
                                        getListCollectionAction({
                                            userId: userInfo?.id,
                                            projectData,
                                        })
                                    );

                                    if (collectionSelected?.id) {
                                        let collection = new Collection({
                                            id: Config
                                                .COLLECTION_MY_COLLECTIONS
                                                .COLLECTION_MY_COLLECTIONSid,
                                            userId: userInfo?.id,
                                            name: Config
                                                .COLLECTION_MY_COLLECTIONS
                                                .COLLECTION_MY_COLLECTIONS_NAME,
                                            typeGrid:
                                                Config.TYPE_GRID
                                                    .TYPE_FOLDER_COLLECTION,
                                        });
                                        dispatch(
                                            selectCollectionResourceAction({
                                                collection,
                                                loading: true,
                                                projectData,
                                            })
                                        );
                                    }
                                } else {
                                    dispatch(
                                        getResourceProjectData({
                                            nav: nav.navName,
                                        })
                                    );
                                }
                            } else if (
                                nav.navName ==
                                ConstantsResource.TAB_PROJECT.COLLECTION
                            ) {
                                if (collectionSelected?.id) {
                                    let collection = new Collection({
                                        id: Config.COLLECTION_MY_COLLECTIONS
                                            .COLLECTION_MY_COLLECTIONSid,
                                        userId: userInfo?.id,
                                        name: Config.COLLECTION_MY_COLLECTIONS
                                            .COLLECTION_MY_COLLECTIONS_NAME,
                                        typeGrid:
                                            Config.TYPE_GRID
                                                .TYPE_FOLDER_COLLECTION,
                                    });
                                    dispatch(
                                        selectCollectionResourceAction({
                                            collection,
                                            loading: true,
                                        })
                                    );
                                }
                            }
                        }}
                    >
                        {nav.title}
                    </div>
                );
            })}
        </div>
    );
};

export default NavTabProject;
