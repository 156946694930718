import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useAppSelector } from '../../../../redux/hook';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import ListIcon from '@mui/icons-material/List';

const ListSuggestionsFieldName = ({
    handleChangeSuggestionFieldName,
} : {
    handleChangeSuggestionFieldName: (value: string) => void;
}) => {
    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);

    return (
        <Form.Group>
            <Form.Label></Form.Label>
            <Dropdown onSelect={handleChangeSuggestionFieldName}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                    <ListIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {resourceItems.filter(item => item.fieldName)
                        .reduce((uniqueFields, item) => {
                            if (uniqueFields.indexOf(item.fieldName) === -1) {
                                uniqueFields.push(item.fieldName);
                            }
                            return uniqueFields;
                        }, []).map((fieldName, index) => (
                            <Dropdown.Item key={index} eventKey={fieldName}>{fieldName}</Dropdown.Item>
                        ))}
                    <Dropdown.Divider />
                    {ConstantsTool.DEFAULT_ZONE_FIELD_NAMES.map((fieldName, index) => (
                        <Dropdown.Item key={index} eventKey={fieldName}>{fieldName}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    )
};

export default ListSuggestionsFieldName;