import { IconButton, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { IconSVG, svgPath } from "../../../../../assets/icon/icons";
import { updateWordScramble } from "../../../../../redux/async/wordScrambleThunk";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { useDebounce } from "../../../../../resource-user/components/common/use-debounce";
import { IActivity } from "../../../../../shared/models/WorkSheetCreator";
import { IQuestionActivity } from "../../../../../shared/models/questionActivity";
import useResizeHeight from "../../../customActivityHook/useResizeHeight";
import { event, scrollToItemId } from "../../util";
import "./index.scss";

const genId = (id: string) => "id_" + id;
const getEmptyQuestionsIndex = (questions: IQuestionActivity[]) => {
    return questions.reduce((prev, question, index) => {
        if (question.question.trim().length === 0) {
            prev.push(index);
        }

        return prev;
    }, [] as number[]);
};

const SentenceScrambleInput = ({ activity }: { activity: IActivity }) => {
    useResizeHeight();
    const questions = activity.questions;
    const [error, setError] = useState<number[]>([]);

    useEffect(() => {
        if (questions.length > 0 && error.length > 0) {
            setError([]);
        }
    }, [questions]);

    const checkError = () => {
        const emptyQuestions = getEmptyQuestionsIndex(questions);
        setError(emptyQuestions);

        return emptyQuestions.length > 0;
    };

    const handleClickFirstInput = () => {
        if (questions.length === 0) {
            const inputElement = document.getElementById("add-input");
            if (inputElement) {
                inputElement?.focus();
            }
        }
    };

    return (
        <div className="sentence-input-container">
            <div
                id="input-wrapper"
                className="input-wrapper"
                onClick={handleClickFirstInput}
            >
                <SimpleBar
                    className="bar-questions"
                    style={{
                        maxHeight: "100%",
                        minHeight: "100%",
                    }}
                >
                    {questions.map((question, index) => {
                        const nextQuestionId = questions?.[index + 1]?.id;
                        const isError = error.includes(index);

                        return (
                            <Input
                                key={question.id}
                                question={question}
                                index={index}
                                nextQuestionId={nextQuestionId}
                                canDeleteQuestion={questions.length > 1}
                                checkError={checkError}
                                isError={isError}
                                event={(value: string, params = {}) =>
                                    event(activity.type, value, params)
                                }
                            />
                        );
                    })}
                    <AddInput
                        currentIndex={questions.length}
                        checkError={checkError}
                    />
                    <ListenSelectText questions={questions} />
                </SimpleBar>
            </div>
        </div>
    );
};

const Input = ({
    question: questionProps,
    index,
    nextQuestionId,
    canDeleteQuestion,
    isError,
    checkError,
    event,
}: {
    question: IQuestionActivity;
    index: number;
    nextQuestionId: string;
    canDeleteQuestion: boolean;
    isError: boolean;
    checkError: Function;
    event: Function;
}) => {
    const { id, question } = questionProps;
    const dispatch = useAppDispatch();
    const inputRef: any = useRef();
    const [focus, setFocus] = useState(false);
    const [hoverIconClear, setHoverIconClear] = useState(false);
    const [value, setValue] = useState(question);
    const debounceValue = useDebounce(value, 150);
    useEffect(() => {
        if (debounceValue !== question) {
            dispatch(
                updateWordScramble({
                    updateWord: {
                        index,
                        value: debounceValue,
                    },
                })
            );
        }
    }, [debounceValue]);

    const handleDelete = () => {
        event("delete_sentence", {
            index,
            value: debounceValue,
        });
        dispatch(
            updateWordScramble({
                deleteWordIndex: index,
            })
        );
    };

    const handleAddNewSentence = async (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
            if (checkError()) return;

            const value = e.target.value as string;
            const isContainNewLine = value.indexOf("\n") > -1;
            event("type_sentence", {
                value,
            });
            if (isContainNewLine) {
                const sentences = value
                    .split("\n")
                    .map((s: string) => s.replaceAll("\b", ""))
                    .filter((s) => s.trim().length > 0);
                if (sentences.length > 1) {
                    dispatch(
                        updateWordScramble({
                            addWords: { fromIndex: index, words: sentences },
                        })
                    );
                    setValue(sentences[0] ?? "");
                    return;
                }
            }
            let id = nextQuestionId;
            if (!id) {
                id = await dispatch(
                    updateWordScramble({ addWord: true })
                ).unwrap();
            }
            setTimeout(() => {
                scrollToItemId(genId(id));
            }, 0);
        }
    };

    return (
        <div
            className={`input-container ${focus ? "focus" : "un-focus"} ${
                value.length === 0 ? "empty" : "full"
            } ${isError ? "error" : ""}`}
            onClick={() => {
                if (inputRef?.current) {
                    inputRef.current?.focus();
                }
            }}
        >
            <span className="index">{index + 1}. </span>
            <TextField
                multiline
                ref={inputRef}
                className={`input textfield`}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={"Type or paste complete sentences"}
                id={genId(id)}
                onKeyDownCapture={handleAddNewSentence}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                    setFocus(false);
                    event("edit_sentence", {
                        value,
                    });
                }}
            />
            {canDeleteQuestion && (
                <IconButton
                    onMouseDown={(e) => {
                        e.preventDefault();
                        handleDelete();
                    }}
                    className="icon-delete"
                    size="small"
                    onMouseEnter={() => setHoverIconClear(true)}
                    onMouseLeave={() => setHoverIconClear(false)}
                >
                    <img
                        src={
                            hoverIconClear
                                ? svgPath(IconSVG.delete_fitb)
                                : svgPath(IconSVG.delete_fitb_black)
                        }
                        alt=""
                    />
                </IconButton>
            )}
        </div>
    );
};

const AddInput = ({
    currentIndex,
    checkError,
}: {
    currentIndex: number;
    checkError: Function;
}) => {
    const dispatch = useAppDispatch();
    const isFirstIndex = currentIndex === 0;
    const [value, setValue] = useState("");

    const handleAddNewSentence = async () => {
        if (checkError()) return;

        const id = await dispatch(
            updateWordScramble({ addWord: true })
        ).unwrap();
        setValue("");
        setTimeout(() => {
            scrollToItemId(genId(id));
        }, 100);
    };

    return (
        <div
            className={`input-container add-input ${
                value.length === 0 ? "empty" : ""
            } ${false ? "first" : ""}`}
            onClick={() => {
                handleAddNewSentence();
            }}
        >
            {!isFirstIndex ? (
                ""
            ) : (
                <span className="index">{`${currentIndex + 1}. `}</span>
            )}
            <input
                disabled={true}
                id="add-input"
                className={`input `}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={
                    isFirstIndex
                        ? "Enter your sentence to scramble"
                        : "Type or paste complete sentences"
                }
            />
        </div>
    );
};

const ListenSelectText = ({
    questions,
}: {
    questions: IQuestionActivity[];
}) => {
    const resourceItemEditing = useAppSelector(
        (state) => state.leftDataState?.resourceItemEditing
    );

    useEffect(() => {
        if (resourceItemEditing) {
            const questionIdByItemSelect = questions.find((q) =>
                q.scrambleAttribute.resourceItemIds.includes(
                    resourceItemEditing
                )
            )?.id;

            if (questionIdByItemSelect) {
                scrollToItemId(genId(questionIdByItemSelect), false);
            }
        }
    }, [resourceItemEditing]);
    return null;
};

export default SentenceScrambleInput;
