import Axios from "axios";
import Config from "../shared/utils/config";
import ConstantsTool from "../shared/utils/ConstantsTool";
import Constants from "../shared/utils/ConstantsTool";
export const ENDPOINT =
    process.env.REACT_APP_ENDPOINT ?? Constants.BASE_URL_API;
export class RequestData {
    url: string;
    params: any;
    constructor(url: string, params: any, userId?: string) {
        this.url = url;
        this.params = params;
    }
}

export class UploadSingleFile {
    url: string;
    userId?: string;
    file: File;

    constructor(url: string, file: File, userId?: string) {
        this.url = url;
        this.file = file;
        this.userId = userId ?? "";
    }
}
export class UploadMultiFile {
    url: string;
    userId?: string;
    files: FileList;
    listImageBase64?: string[];
    isBackgroundSize?: boolean;

    constructor(
        url: string,
        files: FileList,
        userId?: string,
        listImageBase64?: string[],
        isBackgroundSize?: boolean
    ) {
        this.url = url;
        this.files = files;
        this.userId = userId ?? "";
        this.listImageBase64 = listImageBase64 ?? [];
        this.isBackgroundSize = isBackgroundSize ?? false;
    }
}

export const POST: any = (data: {
    baseURL?: string;
    url: string;
    params: any;
    timeout?: number;
}) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data.baseURL ?? ENDPOINT,
            timeout: data.timeout ? data.timeout : Config.HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "POST",
            data: data.params,
            headers: data?.params?.userId
                ? { userId: data?.params?.userId }
                : {},
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                    // //
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};
export const PATCH: any = (data: {
    baseURL?: string;
    url: string;
    params: any;
    timeout?: number;
}) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data.baseURL ?? ENDPOINT,
            timeout: data.timeout ? data.timeout : Config.HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "PATCH",
            data: data.params,
            headers: data?.params?.userId
                ? { userId: data?.params?.userId }
                : {},
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                    // //
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};
export const GET: any = (data: {
    url: string;
    baseURL?: string;
    userId?: string;
}) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data.baseURL ?? ENDPOINT,
            timeout: Config.HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "GET",
            headers: data?.userId ? { userId: data?.userId } : {},
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};

export const DELETE: any = (data: RequestData) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: ENDPOINT,
            timeout: Config.HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "DELETE",
            data: data.params,
            headers: data?.params?.userId
                ? { userId: data?.params?.userId }
                : {},
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                    // //
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};
export const UPLOAD_MULTI_FILE: any = (data: UploadMultiFile) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];
            formData.append("files", file);
        }

        if (data.userId) {
            formData.append("userId", data.userId);
        }
        if (data.userId) {
            formData.append("userId", data.userId);
        }
        if (data.isBackgroundSize) {
            formData.append(
                "isBackgroundSize",
                JSON.stringify(data.isBackgroundSize)
            );
        }
        if (data.listImageBase64) {
            for (let i = 0; i < data.listImageBase64.length; i++) {
                let imageBase64 = data.listImageBase64[i];
                formData.append("listImageBase64", imageBase64);
            }
        }
        let url = data.url?.length ? data.url : "/upload-file";
        Axios.post(ENDPOINT + url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};
export const UPLOAD_SINGLE_FILE: any = (data: any) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", data.file);
        Object.keys(data).forEach((el) => {
            if (el !== "url" && el !== "file") {
                formData.append(el, data[el]);
            }
        });
        if (data.userId) {
        }
        let url = data.url?.length ? data.url : "/upload-file";
        Axios.post(ENDPOINT + url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                if (response.status === Config.HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                } else {
                    reject(response.status);
                }
            })
            .catch((e) => reject(e));
    });
};
