import React from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { isResourceLine, listArrayCanResize } from "../../../../../utils";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import AlignElement from "./Align";

const ActionLayer = ({
    borderElement,
    resizeElement,
    currentElement,
    moreActionComponent,
    pageIndex,
    activeDrag,
}: {
    borderElement: React.ReactNode;
    resizeElement: React.ReactNode;
    moreActionComponent?: React.ReactNode;
    currentElement: IResourceItemNew;
    pageIndex: number;
    activeDrag: boolean;
}) => {
    return (
        <React.Fragment>
            {currentElement &&
                currentElement.type !== ConstantsTool.TYPE_RESOURCE_TABLE &&
                !isResourceLine(currentElement) &&
                borderElement}
            {currentElement &&
                !currentElement.isGroup &&
                currentElement.type !== ConstantsTool.TYPE_RESOURCE_BORDER &&
                listArrayCanResize(currentElement) && (
                    <React.Fragment>{resizeElement}</React.Fragment>
                )}
            {currentElement && moreActionComponent && (
                <React.Fragment>{moreActionComponent}</React.Fragment>
            )}
            {currentElement && !currentElement.isGroup && (
                <AlignElement
                    currentElement={currentElement}
                    pageIndex={pageIndex}
                    activeDrag={activeDrag}
                />
            )}
        </React.Fragment>
    );
};
export default ActionLayer;
