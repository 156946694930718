import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPuzzle, Puzzle } from "../../../../../shared/models/puzzle";
import { getPrefixByProjectName } from "../../../../../shared/utils";
import {
    changePuzzleConfigAction,
    resetSvgAction,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { getImageSource } from "../../../../utils";
import constants from "../../../../utils/constants";
import DropdownTool from "../../../icons/drop-down-tool/drop-down-tool";
import PullUpTool from "../../../icons/drop-down-tool/pull-up-tool";
import SearchIcon from "../../../icons/search-icon";
import A from "../../../icons/word-search-shape/a";
import Boat from "../../../icons/word-search-shape/boat";
import Circle from "../../../icons/word-search-shape/circle";
import Clover from "../../../icons/word-search-shape/clover";
import Cross from "../../../icons/word-search-shape/cross";
import Diamond from "../../../icons/word-search-shape/diamond";
import Flower from "../../../icons/word-search-shape/flower";
import Heart from "../../../icons/word-search-shape/heart";
import House from "../../../icons/word-search-shape/house";
import Lightning from "../../../icons/word-search-shape/lightning";
import Moon from "../../../icons/word-search-shape/moon";
import One from "../../../icons/word-search-shape/one";
import Plane from "../../../icons/word-search-shape/plane";
import Pumpkin from "../../../icons/word-search-shape/pumpkin";
import Smiley from "../../../icons/word-search-shape/smiley";
import Snowman from "../../../icons/word-search-shape/snowman";
import Spiral from "../../../icons/word-search-shape/spiral";
import Square from "../../../icons/word-search-shape/square";
import Star from "../../../icons/word-search-shape/star";
import Sun from "../../../icons/word-search-shape/sun";
import tickIcon from "../../../icons/word-search-shape/tick-item-selected.png";
import Tree from "../../../icons/word-search-shape/tree";
import "./index.scss";

const WordSearchShape = () => {
    const refInput = useRef(null);
    const dropDownRef = useRef(null);
    const mainRef = useRef(null);

    const dispatch = useDispatch();
    let puzzleRedux: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    const [valueSearch, setValueSearch] = React.useState(
        puzzleRedux.puzzleShape
    );
    const [showAll, setShowAll] = React.useState(false);
    const [choiceShape, setChoiceShape] = React.useState({
        name: puzzleRedux.puzzleShape,
        shapeSvg: <Square></Square>,
    });
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );

    useEffect(() => {
        setChoiceShape({
            name: puzzleRedux.puzzleShape,
            shapeSvg: getShapeSvg(puzzleRedux.puzzleShape),
        });
        setValueSearch(puzzleRedux.puzzleShape);
    }, [puzzleRedux.puzzleShape]);

    const getShapeSvg = (name: string) => {
        switch (name) {
            case "Square":
                return <Square></Square>;
            case "Pumpkin":
                return <Pumpkin></Pumpkin>;
            case "Diamond":
                return <Diamond></Diamond>;
            case "Star":
                return <Star></Star>;
            case "Smiley":
                return <Smiley></Smiley>;
            case "Heart":
                return <Heart></Heart>;
            case "Tree":
                return <Tree></Tree>;
            case "A":
                return <A></A>;
            case "House":
                return <House></House>;
            case "Plane":
                return <Plane></Plane>;
            case "Sun":
                return <Sun></Sun>;
            case "Boat":
                return <Boat></Boat>;
            case "Spiral":
                return <Spiral></Spiral>;
            case "Snowman":
                return <Snowman></Snowman>;
            case "Moon":
                return <Moon></Moon>;
            case "Lightning":
                return <Lightning></Lightning>;
            case "Cross":
                return <Cross></Cross>;
            case "1":
                return <One></One>;
            case "Flower":
                return <Flower></Flower>;
            case "Circle":
                return <Circle></Circle>;
            case "Clover":
                return <Clover></Clover>;
            default:
                return <Square></Square>;
        }
    };
    let shapeNames = constants.PUZZLE_SHAPE_ARRAY.map((e) => e.shape);
    let uniqueShapeNames = shapeNames.filter((c, index) => {
        return shapeNames.indexOf(c) === index;
    });
    const list = uniqueShapeNames.map((shapeName, index) => {
        return {
            id: index,
            name: shapeName,
            shapeSvg: getShapeSvg(shapeName),
        };
    });

    const onInputChange = (event: any) => {
        let prefix = getPrefixByProjectName(projectName, "wse_");
        const value = event.currentTarget.value;
        setValueSearch(value);
    };

    return (
        <>
            <div className="word-search-shape" ref={mainRef}>
                <div className="title">Word Search Shape</div>
                <div className="entry-feature">
                    <div className="entry-header">
                        <div
                            className={
                                "current-choice " + (showAll ? "show-all" : "")
                            }
                            onClick={() => {
                                setShowAll(!showAll);
                            }}
                        >
                            <div className="shape">
                                <div className="shape-svg">
                                    {choiceShape.shapeSvg}
                                </div>
                                <p>{choiceShape.name}</p>
                            </div>
                            <div className="drop-down-icon">
                                {showAll ? (
                                    <IconButton className="up-icon">
                                        <PullUpTool />
                                    </IconButton>
                                ) : (
                                    <IconButton>
                                        <DropdownTool />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="drop-down"
                        style={
                            showAll
                                ? {
                                      top: "100%",
                                      display: "flex",
                                  }
                                : { display: "none" }
                        }
                    >
                        {showAll && (
                            <>
                                <div
                                    className="search-wse"
                                    onClick={() => {
                                        setValueSearch("");
                                    }}
                                >
                                    <Input
                                        inputRef={refInput}
                                        sx={{ width: "100%" }}
                                        disableUnderline={true}
                                        value={valueSearch}
                                        onChange={onInputChange}
                                    ></Input>
                                    <IconButton
                                        onClick={() => {
                                            setTimeout(() => {
                                                setValueSearch("");
                                                refInput.current.focus();
                                            }, 100);
                                        }}
                                    >
                                        <SearchIcon></SearchIcon>
                                    </IconButton>
                                </div>

                                <div className="group-option">
                                    <div className="show-item-selected">
                                        <>
                                            <div
                                                className="option-after-search"
                                                onClick={() => {
                                                    setValueSearch(
                                                        puzzleRedux.puzzleShape
                                                    );
                                                }}
                                            >
                                                <div className="cluster-svg-text">
                                                    <div className="shape-svg">
                                                        {choiceShape.shapeSvg}
                                                    </div>
                                                    <p>{choiceShape.name}</p>
                                                </div>
                                                <div className="icon-apply-item">
                                                    <img
                                                        src={getImageSource(
                                                            tickIcon
                                                        )}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="under-line"></div>
                                        </>
                                    </div>
                                    <div className="show-list">
                                        {list
                                            .filter(
                                                (el) =>
                                                    el.name
                                                        .toLowerCase()
                                                        .includes(
                                                            valueSearch ===
                                                                choiceShape.name
                                                                ? ""
                                                                : valueSearch.toLowerCase()
                                                        ) &&
                                                    el.name !==
                                                        puzzleRedux.puzzleShape
                                            )
                                            .map((el, index) => {
                                                return (
                                                    <div
                                                        className="option"
                                                        onClick={() => {
                                                            setValueSearch(
                                                                el.name
                                                            );

                                                            setChoiceShape({
                                                                name: el.name,
                                                                shapeSvg:
                                                                    el.shapeSvg,
                                                            });

                                                            let puzzle: IPuzzle =
                                                                new Puzzle(
                                                                    puzzleRedux
                                                                );
                                                            puzzle.puzzleShape =
                                                                el.name;
                                                            dispatch(
                                                                changePuzzleConfigAction(
                                                                    puzzle
                                                                )
                                                            );
                                                            dispatch(
                                                                resetSvgAction({
                                                                    resetSvg:
                                                                        true,
                                                                })
                                                            );

                                                            let prefix =
                                                                getPrefixByProjectName(
                                                                    projectName
                                                                );
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="shape-svg">
                                                            {el.shapeSvg}
                                                        </div>
                                                        <p>{el.name}</p>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showAll && (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: "0px",
                        left: "0px",
                        zIndex: 6,
                    }}
                    onClick={() => {
                        setShowAll(false);
                        setValueSearch(puzzleRedux.puzzleShape);
                    }}
                ></div>
            )}
        </>
    );
};

export default WordSearchShape;
