import { Fragment, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux/hook";
import { getPrefixImage } from "../../../../utils";
import ResourceItemComponent from "../ResourceItem";
import Config from "../../../../shared/utils/config";
import SearchBar from "../search-bar";

function TabTemplatesView({
    activeTab,
    recentUsedTemplate,
    dataTab,
    parent,
}: {
    recentUsedTemplate: any[];
    activeTab: number;
    dataTab: any[];
    parent: HTMLDivElement;
}) {
    const isSearchTemplate = useAppSelector(
        (state) => state.leftDataState.isSearchTemplate
    );
    const [isViewAll, setIsViewAll] = useState(false);
    const [recentTemplateIndex, setRecentTemplateIndex] = useState(0);
    const refSearch = useRef<HTMLDivElement>(null);
    const [heightSearchBar, setHeightSearchBar] = useState(0);

    useEffect(() => {
        setHeightSearchBar(refSearch?.current?.offsetHeight);
    }, []);

    return (
        <Fragment>
            <div
                ref={refSearch}
                style={{
                    position: "fixed",
                    padding: "12px 16px 0 16px",
                    width: parent?.offsetWidth - 16 * 2 - 2, //2: border
                    top: parent?.getBoundingClientRect()?.top,
                    left: parent?.getBoundingClientRect()?.left,
                    zIndex: 4,
                    background: "white",
                }}
            >
                <SearchBar />
            </div>
            <div
                style={{
                    height: heightSearchBar,
                }}
            />
            <div>
                {!isViewAll ? (
                    <>
                        {recentUsedTemplate.length > 0 && (
                            <div
                                className="list-sub-content"
                                style={
                                    isSearchTemplate ? { display: "none" } : {}
                                }
                            >
                                <div className="header-sub-content">
                                    <div className="title-sub-content">
                                        Recently Used
                                    </div>
                                    <div
                                        className="view-all-button"
                                        onClick={() => setIsViewAll(!isViewAll)}
                                    >
                                        See all
                                    </div>
                                </div>
                                <div
                                    className="list-resource-item"
                                    style={{
                                        gridTemplateColumns: "1fr 1fr",
                                    }}
                                >
                                    {recentTemplateIndex > 0 &&
                                        recentUsedTemplate.length > 2 && (
                                            <div className="arrow-container prev-arrow">
                                                <div
                                                    className="circle-arrow"
                                                    onClick={() => {
                                                        setRecentTemplateIndex(
                                                            (pre) => pre - 2
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            getPrefixImage() +
                                                            "/images/prev-icon.png"
                                                        }
                                                    ></img>
                                                </div>
                                            </div>
                                        )}
                                    {recentUsedTemplate
                                        .slice(
                                            recentTemplateIndex,
                                            recentTemplateIndex + 2
                                        )
                                        .map((e: any) => ({
                                            ...e,
                                            url: e?.thumbnail,
                                            urls:
                                                e?.game?.images.length > 1 &&
                                                e?.game?.images,
                                            type: Config.RESOURCE_TEMPLATE,
                                        }))
                                        .map((el) => {
                                            return (
                                                <ResourceItemComponent
                                                    key={el.id + "recent"}
                                                    resourceItem={el}
                                                    typeResource={activeTab}
                                                    classNameItem={
                                                        activeTab ===
                                                            Config.RESOURCE_TEMPLATE
                                                            ? "template-resource-item"
                                                            : "resource-item"
                                                    }
                                                ></ResourceItemComponent>
                                            );
                                        })}
                                    {recentTemplateIndex <
                                        recentUsedTemplate.length - 2 &&
                                        recentUsedTemplate.length > 2 && (
                                            <div className="arrow-container next-arrow">
                                                <div
                                                    className="circle-arrow"
                                                    onClick={() => {
                                                        setRecentTemplateIndex(
                                                            (pre) => pre + 2
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            getPrefixImage() +
                                                            "/images/next-icon.png"
                                                        }
                                                    ></img>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                        <div className="list-sub-content">
                            <div className="header-sub-content">
                                <div className="title-sub-content">
                                    All results
                                </div>
                            </div>
                            <div
                                className="list-resource-item"
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                {dataTab?.length > 0 &&
                                    dataTab
                                        .map((e) => ({
                                            ...e,
                                            url: e?.thumbnail,
                                            urls:
                                                e?.game?.images.length > 1 &&
                                                e?.game?.images,
                                            type: Config.RESOURCE_TEMPLATE,
                                        }))
                                        .map((el) => {
                                            return (
                                                <ResourceItemComponent
                                                    key={el.id + "recent"}
                                                    resourceItem={el}
                                                    typeResource={activeTab}
                                                    classNameItem={
                                                        activeTab ===
                                                            Config.RESOURCE_TEMPLATE
                                                            ? "template-resource-item"
                                                            : "resource-item"
                                                    }
                                                ></ResourceItemComponent>
                                            );
                                        })}
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <div
                            className="top-back-button"
                            onClick={() => setIsViewAll(!isViewAll)}
                        >
                            <img
                                src={
                                    getPrefixImage() +
                                    "/images/functional-icon/back-button.svg"
                                }
                            />
                            <div className="back-title">Back</div>
                        </div>
                        <div
                            className="list-resource-item"
                            style={{
                                gridTemplateColumns: "1fr 1fr",
                            }}
                        >
                            {recentUsedTemplate
                                .map((e: any) => ({
                                    ...e,
                                    url: e?.thumbnail,
                                    urls:
                                        e?.game?.images.length > 1 &&
                                        e?.game?.images,
                                    type: Config.RESOURCE_TEMPLATE,
                                }))
                                .map((el) => {
                                    return (
                                        <ResourceItemComponent
                                            key={el.id}
                                            resourceItem={el}
                                            typeResource={activeTab}
                                            classNameItem={
                                                activeTab ===
                                                    Config.RESOURCE_TEMPLATE
                                                    ? "template-resource-item"
                                                    : "resource-item"
                                            }
                                        ></ResourceItemComponent>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default TabTemplatesView;
