import "./style.scss";
export const classNameToolTip = "tooltip-customize-root";

export enum TypeOfPlacementTooltip {
    top = "top",
    left = "left",
    right = "right",
    bottom = "bottom",
}
export type TYPE_PLACEMENT_TOOLTIP =
    | TypeOfPlacementTooltip.top
    | TypeOfPlacementTooltip.left
    | TypeOfPlacementTooltip.right
    | TypeOfPlacementTooltip.bottom;

const RenderToolTipCustomize = () => {
    return (
        <>
            <div
                id="common-tooltip"
                role="tooltip"
                className={classNameToolTip}
            ></div>
        </>
    );
};

export default RenderToolTipCustomize;
