import { createAsyncThunk } from "@reduxjs/toolkit";
import { ConstantsNameTracing } from "../../components/ModulesContent/name-tracing/util/constant";
import { processImgSvg } from "../../components/RightContent/CreateWorksheet/ElementWorksheet/ResourceImage";
import {
    getOldResourceItemAPI,
    getPageWsAPI,
    getUserCreateWsAPI,
    savePageWssApi,
} from "../../repositories/createWorksheet";
import { initCrosswordGameAction } from "../../resource-user/redux/action/crossword.action";
import {
    initStatusPopupSave,
    initWordSearchGameAction,
    setDefaultSuggestTag,
    setOnEditTitleWsAction,
    setStatusSave,
    updateAutoPasteInputAction,
    updateCellPxAction,
    updateWorksheetAction,
} from "../../resource-user/redux/action/wordsearch.action";
import { getWorkSheetAPI } from "../../resource-user/redux/repositories/workSheet.repositories";
import { getSizePuzzle } from "../../resource-user/redux/services/wordsearch.saga";
import ConstantsResource from "../../resource-user/utils/ConstantsResource";
import { Crossword } from "../../shared/models/crossword";
import {
    IPageWorksheetNew,
    PageWorksheetNew,
} from "../../shared/models/pageWorksheetNew";
import { IPuzzle } from "../../shared/models/puzzle";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../shared/models/questionActivity";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";
import { IWorksheet } from "../../shared/models/worksheet";
import { getTitleWsFromProjectName } from "../../shared/utils";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import Config from "../../shared/utils/config";
import {
    convertPageWs,
    convertPageWsFromOldResourceItem,
    convertQuestionFromOldData,
    convertWsCreatorToWorksheet,
    generateIdFromDateTime,
    getActivityConfigCrossword,
    getConfigCrossword,
    getGapBetweenItem,
    getInitTypeFromUrl,
    isImageSvg,
    resizeElementToSizeA4,
    loadFonts,
} from "../../utils";
import {
    genPageDefault,
    getResourceWordSearchDefault,
} from "../../utils/PageDefault";
import { ImgSvgContent } from "../../utils/create-ws";
import { getResourceItemsOldWordSearch } from "../../utils/draw";
import {
    changeTabActiveActivities,
    setResourceItemEditing,
} from "../reducers/leftData";
import { RootState } from "../store";
import {
    Activity,
    IActivity,
    WorkSheetCreator,
} from "./../../shared/models/WorkSheetCreator";
import { IFont } from '../../shared/models/module';

export const requestInitPageWs = createAsyncThunk(
    "createWorksheet/requestInitPageWs",
    async (
        args: {
            ratio?: number;
            worksheetId?: string;
            isUserAddTemplate?: boolean;
            activityToInit?: string;
            isAddNewPage?: boolean;
            isRelaceAllPage?: boolean;
            sourcePage?: number;
        },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const ratioWS = 1;
            let {
                ratio,
                worksheetId,
                isUserAddTemplate = false,
                activityToInit,
                isAddNewPage = false,
                isRelaceAllPage = null,
                sourcePage = Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH,
            } = args;
            let forceUpdate = false;
            let worksheet: IWorksheet;
            let pageWsData: IPageWorksheetNew[] = [];
            let toolData;
            let activities: Activity[] = [];
            let worksheetCreator;
            let newWs;
            const state = getState() as RootState;
            const worksheetCreatorState =
                state.createWorksheetState.worksheetCreator;
            const pageIndex = state.createWorksheetState.pageIndex;
            const currentActivity = state.createWorksheetState.currentActivity;

            //reset orientation, width, height
            ConstantsTool.changeOrientation(ConstantsTool.ORIENTATION.PORTRAIT);
            // cal ratio must be after ConstantsTool.changeOrientation()
            if (!ratio) {
                ratio = ((window.innerHeight - 90) * 1) / ConstantsTool.HEIGHT_A4; // minus header height * max height expected
            }
            if (worksheetId?.length) {
                worksheet = (await getWorkSheetAPI(
                    worksheetId,
                    true
                )) as IWorksheet;
                if (worksheet?.type !== null && worksheet?.type !== undefined) {
                    // dispatch(initWorksheetInfo(worksheet));
                    // old format data, save font in worksheet, so we need load font here
                    let fonts = worksheet.fontInfos;
                    fonts?.forEach(async (font) => {
                        await loadFonts(font);
                    });
                    forceUpdate = true;
                    if (worksheet?.type === Config.TEMPLATE_WORKSHEET) {
                        let result = await loadOldTemplateWs(worksheet);
                        pageWsData = result.pageWsData;
                        activities = result.activities;
                    } else {
                        toolData = await getPageWsAPI(worksheetId);
                        if (toolData?.wsCreator) {
                            let result = loadTemplateCreatedByToolV2(
                                toolData,
                                worksheet,
                                dispatch
                            );
                            pageWsData = result.pageWsData;
                            activities = result.activities;
                        } else {
                            if (
                                worksheet.originUrl === Config.USER_CREATED_WS
                            ) {
                                let responseGetUserCreateWs =
                                    await getUserCreateWsAPI(worksheetId);
                                let resources =
                                    responseGetUserCreateWs.resources;
                                let modules = responseGetUserCreateWs.modules;
                                if (modules.length == 0) {
                                    let result = await loadOldTemplateWs(
                                        worksheet
                                    );
                                    pageWsData = result.pageWsData;
                                    activities = result.activities;
                                } else {
                                    let isAllHandwritingPage =
                                        checkTemplateContainOnlyHandWriting(
                                            modules
                                        );
                                    if (isAllHandwritingPage) {
                                        let result = loadOldHandWritingTemplate(
                                            resources,
                                            modules
                                        );
                                        pageWsData = result.pageWsData;
                                        activities = result.activities;
                                    }
                                }
                            } else if (
                                worksheet.originUrl ===
                                Config.USER_CREATED_WORD_SEARCH
                            ) {
                                let result = loadOldWordsearch(worksheet);
                                pageWsData = result.pageWsData;
                                activities = result.activities;
                                forceUpdate = true;
                            }
                        }
                    }
                }
                // check author editing this template or not
                // let currentUserId = state.userInfoResourceState.data.id;
                // newWs = {
                //     ...worksheet,
                //     id: state.wordSearchState?.worksheet?.id,
                //     authorId: currentUserId,
                // };
                // delete newWs.author;

                //                 let currentUserId = state.userInfoResourceState.data.id;
                //                 let id = state.wordSearchState?.worksheet?.id;
                //                 const params = new URLSearchParams(window.location.search);
                //                 const isEditBase = params.get("isEditBase");
                //                 if (isEditBase) {
                //                     currentUserId = worksheet.authorId;
                //                     id = worksheet.id;
                //                 }
                //                 newWs = {
                //                     ...worksheet,
                //                     id,
                //                     authorId: currentUserId,
                //                 };
                //                 delete newWs.author;
            }
            dispatch(updateWorksheetAction(worksheet, true));
            dispatch(initStatusPopupSave());

            if (!!activityToInit) {
                dispatch(setStatusSave(false));
            }

            if (
                window.location.href.includes(
                    Config.TOOL_V2_FILL_THE_BLANK_PATH
                )
            ) {
                dispatch(changeTabActiveActivities(""));
            }

            getActivity(activityToInit, activities);

            if (!pageWsData.length) {
                let { resourceIds, questionActivity } = updateDefaultActivity(
                    activities,
                    dispatch
                );

                pageWsData = pageWsData.concat(
                    await genPageDefault({
                        activity: activities[0],
                        resourceIds,
                        questionActivity,
                        worksheet,
                        originWorksheetId: worksheetId,
                    })
                );
            } else {
                pageWsData.forEach((page) => {
                    page.sourcePage = sourcePage;
                });
            }
            // Toggle first activity
            if (activities.length > 0) {
                dispatch(
                    changeTabActiveActivities(
                        activities[0]?.type === null ||
                            activities[0].type ===
                                Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE
                            ? ConstantsResource.TAB_ACTIVE.WORKSHEET_INFO
                            : activities[0].type
                    )
                );
            }
            let checkHaveActivityWordSearch = activities.find(
                (el) => el.type === Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE
            );
            // split 2 if else to get puzzle newest
            if (checkHaveActivityWordSearch) {
                dispatch(
                    initWordSearchGameAction({
                        worksheet,
                        ratio: ratioWS,
                        isAddTemplate: isUserAddTemplate,
                    })
                );
                dispatch(updateAutoPasteInputAction([]));
            }
            dispatch(setDefaultSuggestTag(activities[0]?.type));

            let puzzle = state.wordSearchState.puzzle;
            if (checkHaveActivityWordSearch) {
                let resourceModule = pageWsData[0].resourceItems.find(
                    (e) => e.type == ConstantsTool.TYPE_RESOURCE_MODULE
                );

                if (resourceModule && puzzle.puzzleArrNumber.length) {
                    const objSize = getSizePuzzle(puzzle, ratioWS);
                    let { totalWidth, totalHeight, puzzleCellPx } = objSize;
                    dispatch(updateCellPxAction(puzzleCellPx));
                    resourceModule.width = totalWidth;
                    resourceModule.height = totalHeight;
                }
            }
            isRelaceAllPage = isReplaceAllPage(
                isRelaceAllPage,
                activities,
                currentActivity
            );
            let titleWorksheetCreator = getTitleWorksheetCreator(
                state.wordSearchState.projectName,
                puzzle,
                activities
            );
            worksheetCreator = genWorksheetCreator(
                isAddNewPage,
                isRelaceAllPage,
                worksheetCreatorState,
                toolData?.wsCreator,
                activities,
                titleWorksheetCreator,
                pageIndex,
                worksheet?.id
            );
            // Update title page
            // dispatch(changeTitlePuzzleAction(worksheetCreator.title));
            if (checkHaveActivityWordSearch) {
                dispatch(setOnEditTitleWsAction());
            }

            //some old data, background is cover other element, so we need bring it backword (index = 0 or 1)
            bringBackGroundBackward(pageWsData);
            let promises = [
                loadFontInResourceItems(pageWsData, state.createWorksheetState.fontFamilyList), //with new format data, font saved in resource item.Otherwise, when using dynamic font in font picker, some font in resource item is not loaded
                loadAllImgSvg(pageWsData),
            ];
            await Promise.all(promises);

            if (
                worksheetCreator?.activities?.length &&
                worksheetCreator?.activities[0].type ===
                    Config.MODULE_MAKER_NAME.FILL_IN_THE_BLANK
            ) {
                activities = worksheetCreator.activities;
            }
            return {
                ratio: ratio,
                pageWs: pageWsData,
                activities,
                worksheetCreator,
                forceUpdate: forceUpdate,
                isRelaceAllPage,
                isAddNewPage,
            };
        } catch (error) {
            console.log("error  createWorksheet/requestInitPageWs", error);
            return rejectWithValue(error);
        }
    }
);

const loadFontInResourceItems = async (pageWsData: IPageWorksheetNew[], fontFamilyList: IFont[]) => {
    for (let i = 0; i < pageWsData.length; i++) {
        const resourceItems = pageWsData[i].resourceItems;
        for (let index = 0; index < resourceItems.length; index++) {
            const resourceItem = resourceItems[index];
            if (resourceItem?.textAttribute?.fontFamily) {
                let fontObj = fontFamilyList.find(
                    (e) =>
                        e.name === resourceItem?.textAttribute?.fontFamily
                );
                if (fontObj) {
                    await loadFonts(fontObj);
                }
            }
        }
    }
};

const getActivity = (activityToInit: string, activities: Activity[]) => {
    let type;
    let activityId = generateIdFromDateTime();
    if (activityToInit) {
        type = activityToInit;
    } else {
        type = getInitTypeFromUrl();
    }
    if (activities.length === 0) {
        activities.push(
            new Activity({
                id: activityId,
                type,
            })
        );
    }
};

const updateDefaultActivity = (activities: Activity[], dispatch: any) => {
    let resourceIds = [];
    let questionActivity: IQuestionActivity;
    const currentType = activities[0].type;
    switch (currentType) {
        case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
            resourceIds.push(generateIdFromDateTime());
            questionActivity = new QuestionActivity({
                question: "",
                id: generateIdFromDateTime(),
                handwritingAttribute: {
                    resourceItemIds: resourceIds,
                },
                type: currentType,
            });
            activities[0].questions.push(questionActivity);
            dispatch(setResourceItemEditing(resourceIds[0]));
            break;
        case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
            const NUMBER_OF_LINES = 7;
            for (let index = 0; index < NUMBER_OF_LINES; index++) {
                resourceIds.push(generateIdFromDateTime());
            }

            questionActivity = new QuestionActivity({
                question: ConstantsNameTracing.PLACEHOLDER,
                id: generateIdFromDateTime(),
                nameTracingAttribute: {
                    resourceItemIds: resourceIds,
                    lineSpacing: 9,
                    numberOfLines: NUMBER_OF_LINES,
                },
                type: currentType,
            });
            activities[0].questions.push(questionActivity);
            dispatch(setResourceItemEditing(resourceIds[0]));
            break;
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            dispatch(
                initCrosswordGameAction(
                    new Crossword({
                        words: [],
                        grid: [],
                        showWordBank: true,
                    })
                )
            );
            break;
        default:
            break;
    }

    return {
        resourceIds,
        questionActivity,
    };
};

const loadOldWordsearch = (worksheet: IWorksheet) => {
    let activities = [
        new Activity({
            id: generateIdFromDateTime(),
            type: Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
        }),
    ];
    let pageWsData = [
        new PageWorksheetNew({
            id: generateIdFromDateTime(),
            width: ConstantsTool.WIDTH_A4,
            height: ConstantsTool.HEIGHT_A4,
            resourceItems: [
                ...getResourceItemsOldWordSearch(
                    worksheet,
                    Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE
                ),
                ...getResourceWordSearchDefault(activities[0].id),
            ],
        }),
    ];
    return {
        activities,
        pageWsData,
    };
};

const loadOldTemplateWs = async (worksheet: IWorksheet) => {
    let responseGetOldResource = await getOldResourceItemAPI(worksheet.id);
    let resourceItems = resizeElementToSizeA4({
        worksheet,
        resourceItems: responseGetOldResource.resources,
    });
    let pageWsData = convertPageWsFromOldResourceItem(resourceItems);
    let activityId = generateIdFromDateTime();
    let activities = [
        new Activity({
            id: activityId,
            type: Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE,
        }),
    ];
    return {
        pageWsData,
        activities,
    };
};

const checkTemplateContainOnlyHandWriting = (modules: any) => {
    let isAllHandwritingPage = true;
    for (let index = 0; index < modules.length; index++) {
        const module = modules[index];
        if (module?.name !== "Handwriting") {
            isAllHandwritingPage = false;
            return isAllHandwritingPage;
        }
    }
    return isAllHandwritingPage;
};

const bringBackGroundBackward = (pageWsData: IPageWorksheetNew[]) => {
    //Bring background backward
    pageWsData.forEach((page) => {
        const backgroundResources = [];
        const backgroundResourceDefaultIndex = page.resourceItems.findIndex(
            (el) => el.type === ConstantsTool.RESOURCE_BACKGROUND
        );
        if (backgroundResourceDefaultIndex !== -1) {
            const backgroundDefaultResource = page.resourceItems.splice(
                backgroundResourceDefaultIndex,
                1
            );
            backgroundResources.push(...backgroundDefaultResource);
        }

        const backgroundResourceIndex = page.resourceItems.findIndex(
            (el) => el.type === ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
        );
        if (backgroundResourceIndex !== -1) {
            const backgroundResource = page.resourceItems.splice(
                backgroundResourceIndex,
                1
            );
            backgroundResources.push(...backgroundResource);
        }

        page.resourceItems.unshift(...backgroundResources);
    });
};

const isReplaceAllPage = (
    isRelaceAllPage: boolean,
    activities: Activity[],
    currentActivity: Activity
): boolean => {
    if (isRelaceAllPage != null) {
        return isRelaceAllPage;
    } else {
        if (
            activities.find((element) => {
                return element.type === currentActivity?.type;
            })
        ) {
            return false;
        } else {
            return true;
        }
    }
};

const loadOldHandWritingTemplate = (resources, modules) => {
    let pageWsData = convertPageWsFromOldResourceItem(resources);
    let questions: QuestionActivity[] = [];
    pageWsData.forEach((page) => {
        questions = questions.concat(
            convertQuestionFromOldData(page.resourceItems, modules)
        );
        page.resourceItems.forEach((resourceItem) => {
            if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_HANDWRITING) {
                resourceItem.height =
                    resourceItem.textAttribute.fontSize +
                    getGapBetweenItem(resourceItem.textAttribute.fontSize);
            }
        });
    });
    let activities = [
        new Activity({
            id: generateIdFromDateTime(),
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
            questions: questions,
        }),
    ];
    // updateLocationByIndex({
    //     questions: questions,
    //     index: 0,
    //     pagesWorksheet: pageWsData,
    //     activity:
    //         Config.ACTIVITY_TYPE.HANDWRITING
    //             .TYPE,
    // });
    pageWsData = pageWsData.filter((page) => {
        if (page.resourceItems && page.resourceItems.length > 0) {
            return page;
        }
    });

    return {
        pageWsData,
        activities,
    };
};

const loadTemplateCreatedByToolV2 = (toolData, worksheet, dispatch) => {
    // Change orientation
    let orientation;
    toolData?.wsCreator?.activities?.forEach((activity: IActivity) => {
        if (activity.type === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE) {
            orientation = activity.orientation;
            ConstantsTool.changeOrientation(orientation);
        }
        if (activity.type === Config.ACTIVITY_TYPE.CROSSWORD.TYPE) {
            const config = getConfigCrossword(activity);
            dispatch && dispatch(initCrosswordGameAction(config));
        }
    });

    let activities = toolData?.wsCreator?.activities?.map(
        (el, index) =>
            new Activity({
                ...el,
                showAnswerKey: Config.HIDE_VALUE,
            })
    );
    worksheet.game.wordSearchCreator = convertWsCreatorToWorksheet(
        toolData?.wsCreator,
        activities[0],
        worksheet.game.wordSearchCreator
    );
    let pageWsData = convertPageWs({
        newResourceItems: toolData.resourceItems,
        worksheet: worksheet,
        orientation: orientation,
    });

    return {
        pageWsData,
        activities,
    };
};

const genWorksheetCreator = (
    isAddNewPage: boolean,
    isRelaceAllPage: boolean,
    worksheetCreatorState: WorkSheetCreator,
    worksheetCreatorToolData: WorkSheetCreator,
    activities: Activity[],
    title: string,
    pageIndex: number,
    templateId?: string
) => {
    let newTemplateIds: string[] = [];
    let newActivities: Activity[] = [];
    if (isRelaceAllPage) {
        return new WorkSheetCreator({
            templateIds: templateId ? [templateId] : [],
            activities,
            title,
        });
    }
    if (worksheetCreatorToolData) {
        // need more check when add multi action in a worksheet
        let templateIdsTool: string[] = [];
        if (worksheetCreatorToolData.templateIds) {
            templateIdsTool = worksheetCreatorToolData.templateIds;
        } else {
            templateIdsTool = templateId ? [templateId] : [];
        }
        if (isAddNewPage) {
            (newTemplateIds = [
                ...worksheetCreatorState.templateIds.slice(0, pageIndex),
                ...templateIdsTool,
                ...worksheetCreatorState.templateIds.slice(pageIndex),
            ]),
                (newActivities = [
                    ...worksheetCreatorState.activities.slice(0, pageIndex),
                    ...activities,
                    ...worksheetCreatorState.activities.slice(pageIndex),
                ]);
        } else {
            (newTemplateIds = [
                ...worksheetCreatorState.templateIds.slice(0, pageIndex),
                ...templateIdsTool,
                ...worksheetCreatorState.templateIds.slice(pageIndex + 1),
            ]),
                (newActivities = [
                    ...worksheetCreatorState.activities.slice(0, pageIndex),
                    ...activities,
                    ...worksheetCreatorState.activities.slice(pageIndex + 1),
                ]);
        }

        return new WorkSheetCreator({
            templateIds: newTemplateIds,
            activities: newActivities,
            title,
        });
    }

    if (templateId) {
        let templateIdsState = [...worksheetCreatorState.templateIds];
        if (isAddNewPage) {
            templateIdsState.splice(pageIndex + 1, 0, templateId);
            newActivities = [
                ...worksheetCreatorState.activities.slice(0, pageIndex),
                ...activities,
                ...worksheetCreatorState.activities.slice(pageIndex),
            ];
        } else {
            templateIdsState[pageIndex] = templateId;
            newActivities = [
                ...worksheetCreatorState.activities.slice(0, pageIndex),
                ...activities,
                ...worksheetCreatorState.activities.slice(pageIndex + 1),
            ];
        }
        newTemplateIds = templateIdsState;
    }
    return new WorkSheetCreator({
        templateIds: newTemplateIds,
        activities: newActivities,
        title,
    });
};

const getTitleWorksheetCreator = (
    projectName: string,
    puzzle: IPuzzle,
    activities: IActivity[]
) => {
    let title = puzzle.title
        ? puzzle.title
        : getTitleWsFromProjectName(projectName);
    if (
        activities.length > 0 &&
        activities[0].type === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE
    ) {
        title = "Hello, my name is";
    }
    return title;
};
const loadAllImgSvg = async (pageWsData: IPageWorksheetNew[]) => {
    let allImgSvg: any[] = [];
    pageWsData.forEach((page) => {
        page.resourceItems.forEach((resource) => {
            if (isImageSvg(resource) && resource.imageAttribute.url) {
                allImgSvg.push(
                    fetch(resource.imageAttribute.url).then((response) => {
                        if (!response.ok) {
                            return "";
                        }
                        return response.text();
                    })
                );
            }
        });
    });
    let allResult = await Promise.all(allImgSvg);
    let count = 0;
    pageWsData.forEach((page) => {
        page.resourceItems.forEach((resource) => {
            if (isImageSvg(resource)) {
                let svgText = allResult[count];
                if (svgText) {
                    ImgSvgContent.data[resource.id] = processImgSvg(svgText);
                    count++;
                }
            }
        });
    });
};

export const requestSavePageWs = createAsyncThunk(
    "createWorksheet/requestSavePageWs",
    async (params, { getState, rejectWithValue }) => {
        try {
            const rootState = getState() as RootState;

            const worksheetId = rootState.wordSearchState.worksheet.id;
            let allPages = rootState.createWorksheetState.pagesWorksheet;
            let wsCreator = rootState.createWorksheetState.worksheetCreator;
            let worksheet = rootState.wordSearchState.worksheet;
            let activities = JSON.parse(
                JSON.stringify(rootState.createWorksheetState.activities)
            );

            getActivityConfigCrossword(activities, rootState);

            let allResourceItem: IResourceItemNew[] = [];
            allPages.forEach((el, pageIndex) => {
                let resourceItems = el.resourceItems;

                resourceItems.forEach((resource) => {
                    if (!resource.id?.includes("tmp")) {
                        allResourceItem.push({
                            ...resource,
                            pageIndex: pageIndex,
                        });
                    }
                });
            });
            let result = await savePageWssApi({
                worksheetId: worksheetId,
                resourceItems: allResourceItem,
                wsCreator: {
                    ...wsCreator,
                    activities: activities.map((el) => {
                        return {
                            ...el,
                        };
                    }),
                },
            });
            result["worksheet"] = worksheet;
            return result;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);
