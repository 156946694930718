import IQuotaResourceUser, {
    QuotaResourceUser,
} from "../../../shared/models/quotaResourceuser";
import Config from "../../../shared/utils/config";
import {
    QuotaResourceTypes,
    QuotaResourceUserAction,
} from "../action/quotaResourceUser.action";

export interface QuotaResourceUserState {
    data: IQuotaResourceUser;
    updateSuccess: boolean;
    loading: boolean;
}

const initialState: QuotaResourceUserState = {
    data: new QuotaResourceUser(),
    updateSuccess: false,
    loading: false,
};

function quotaResourceUserState(
    state: QuotaResourceUserState = initialState,
    action: QuotaResourceUserAction
): QuotaResourceUserState {
    switch (action.type) {
        case QuotaResourceTypes.UPDATE_QUOTA_USER_SUCCESS: {
            let params = action.params;

            return {
                ...state,
                data: {
                    ...state.data,
                    dailyTimes: params?.dailyTimes ?? state?.data?.dailyTimes,
                    inviteFriend:
                        params?.inviteFriend ?? state?.data?.inviteFriend,
                    limitDownload:
                        params?.limitDownload ?? state?.data?.limitDownload,
                    quotaDownload:
                        params?.quotaDownload ?? state?.data?.quotaDownload,
                    sharePin: params?.sharePin ?? state?.data?.sharePin,
                    timeNextDownload:
                        params?.timeNextDownload ??
                        state?.data?.timeNextDownload,
                    times: params?.times ?? state?.data?.times,
                    timeClaimed:
                        params?.timeClaimed ?? state?.data?.timeClaimed,
                    verifySharePin:
                        params?.verifySharePin ?? state?.data?.verifySharePin,
                },
                updateSuccess: true,
            };
        }
        case QuotaResourceTypes.UPDATE_LOADING_GET_QUOTA:
            return {
                ...state,
                loading: action.loading,
            };
        case QuotaResourceTypes.UPDATE_QUOTA_USER: {
            return {
                ...state,
                updateSuccess: false,
            };
        }
        case QuotaResourceTypes.UPDATE_QUOTA_DOWNLOAD: {
            return {
                ...state,
                updateSuccess: false,
            };
        }
        case QuotaResourceTypes.CHANGE_UPDATE_QUOTA_SUCCESS: {
            return {
                ...state,
                updateSuccess: false,
            };
        }
        case QuotaResourceTypes.UPDATE_QUOTA_DOWNLOAD_REDUCER:
            let result = {
                ...state,
            };

            if (action.remainQuotaDownLoad > -1) {
                result = {
                    ...result,
                    data: {
                        ...result.data,
                        quotaDownload: action.remainQuotaDownLoad,
                    },
                    updateSuccess: true,
                };
            }
            return result;
        case QuotaResourceTypes.UPDATE_QUOTA_RESOURCE:
            let userId = "";
            if (!action.quotaResourceUser?.userId) {
                userId = action?.userId ?? "";
            } else {
                userId = action.quotaResourceUser?.userId;
            }
            return {
                ...state,
                data: { ...action.quotaResourceUser, userId },
                loading: false,
            };
        case QuotaResourceTypes.UPDATE_INCREASE_QUOTA_RESOURCE:
            return {
                ...state,
                data: {
                    ...state.data,
                    quotaDownload:
                        state.data.quotaDownload +
                        Config.BONUS_QUOTA_DOWNLOAD_EACH_CLAIM,
                    limitDownload:
                        state.data.limitDownload +
                        Config.BONUS_QUOTA_DOWNLOAD_EACH_CLAIM,
                },
            };
        case QuotaResourceTypes.UPDATE_TIME_NEXT_DOWNLOAD:
            return {
                ...state,
                data: {
                    ...state.data,
                    times: state.data.times + 1,
                    timeNextDownload: action.timeNextDownload,
                    timeClaimed: new Date(),
                },
            };

        default:
            return state;
    }
}

export default quotaResourceUserState;
