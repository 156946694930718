import Checkbox from "@mui/material/Checkbox";

import { useDispatch, useSelector } from "react-redux";
import { Puzzle } from "../../../../../shared/models/puzzle";
import { getPrefixByProjectName } from "../../../../../shared/utils";
import Config from "../../../../../shared/utils/config";
import { changePuzzleConfigAction } from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import IconUnChecked from "../../../icons/check-box-icon/un-checked";
import IconChecked3 from "../../../icons/check-box-icon/checked-icon";
import "./index.scss";

const EndCheckBox = () => {
    const dispatch = useDispatch();
    const puzzle = useSelector(
        (state: ResourceAppState) => state.wordSearchState.puzzle
    );
    const showAnswerKey = useSelector(
        (state: ResourceAppState) => state.wordSearchState.puzzle?.showAnswerKey
    );
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    const showWordDirection = useSelector(
        (state: ResourceAppState) =>
            state.wordSearchState.puzzle?.showWordDirection
    );
    let showWordList = useSelector(
        (state: ResourceAppState) => state.wordSearchState.puzzle?.showWordList
    );
    // const checkDisabled = showWordList == Config.HIDE_VALUE;
    return (
        <div className="end-check-box">
            <div className="entry-check-box">
                <div className="title">Show The Answer Key</div>
                <Checkbox
                    icon={<IconUnChecked />}
                    checkedIcon={<IconChecked3 />}
                    onClick={() => {
                        let prefix = getPrefixByProjectName(
                            projectName,
                            "wse_"
                        );
                        dispatch(
                            changePuzzleConfigAction(
                                new Puzzle({
                                    ...puzzle,
                                    showAnswerKey:
                                        showAnswerKey === Config.SHOW_VALUE
                                            ? Config.HIDE_VALUE
                                            : Config.SHOW_VALUE,
                                })
                            )
                        );
                    }}
                    checked={showAnswerKey === Config.SHOW_VALUE}
                    size="medium"
                />
            </div>
            <div className="entry-check-box">
                <div className="title">Show The Word Directions</div>
                <Checkbox
                    icon={<IconUnChecked />}
                    checkedIcon={<IconChecked3 />}
                    onClick={() => {
                        let prefix = getPrefixByProjectName(
                            projectName,
                            "wse_"
                        );
                        dispatch(
                            changePuzzleConfigAction(
                                new Puzzle({
                                    ...puzzle,
                                    showWordDirection:
                                        showWordDirection === Config.SHOW_VALUE
                                            ? Config.HIDE_VALUE
                                            : Config.SHOW_VALUE,
                                })
                            )
                        );
                    }}
                    checked={
                        showWordDirection == Config.SHOW_VALUE &&
                        showWordList == Config.SHOW_VALUE
                    }
                    size="medium"
                    disabled={showWordList == Config.HIDE_VALUE}
                />
            </div>
            <div className="entry-check-box">
                <div className="title">Show The Word List</div>
                <Checkbox
                    icon={<IconUnChecked />}
                    checkedIcon={<IconChecked3 />}
                    onClick={() => {
                        let prefix = getPrefixByProjectName(
                            projectName,
                            "wse_"
                        );
                        dispatch(
                            changePuzzleConfigAction(
                                new Puzzle({
                                    ...puzzle,
                                    showWordList:
                                        showWordList === Config.SHOW_VALUE
                                            ? Config.HIDE_VALUE
                                            : Config.SHOW_VALUE,
                                })
                            )
                        );
                    }}
                    checked={showWordList === Config.SHOW_VALUE}
                    size="medium"
                />
            </div>
        </div>
    );
};

export default EndCheckBox;
