import Backdrop from "@mui/material/Backdrop";

const BackdropLayout = ({
    open,
    handleClose,
    children,
}: {
    open: boolean;
    handleClose: () => void;
    children?: React.ReactNode;
}) => {
    return (
        <>
            {children}
            {open && (
                <Backdrop
                    sx={{
                        zIndex: 1,
                        position: "fixed",
                        top: "-50000px",
                        left: "-50000px",
                        width: "1000000px",
                        height: "1000000px",
                    }}
                    open={open}
                    onClick={(event) => {
                        if (handleClose) {
                            handleClose();
                        }
                    }}
                    invisible={true}
                />
            )}
        </>
    );
};


export default BackdropLayout;