import React from "react";

const CheckBoxIconSvg2 = ({ checked }: { checked: any }) => {
    if (!checked) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M21.74 7.80794V7.81V16.19C21.74 17.8608 21.2449 19.1045 20.4197 19.9297C19.5945 20.7549 18.3508 21.25 16.68 21.25H8.31C6.63917 21.25 5.39575 20.7549 4.57066 19.9288C3.74546 19.1025 3.25 17.8564 3.25 16.18V7.81C3.25 6.13915 3.74514 4.89552 4.57033 4.07033C5.39552 3.24514 6.63915 2.75 8.31 2.75H16.69C18.361 2.75 19.6043 3.24521 20.4281 4.07C21.2517 4.89463 21.7446 6.13757 21.74 7.80794Z"
                    stroke="#3B82F6"
                    strokeWidth="1.5"
                />
            </svg>
        );
    }
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M21.99 7.80863V7.81V16.19C21.99 17.9106 21.4791 19.2238 20.5964 20.1064C19.7138 20.9891 18.4006 21.5 16.68 21.5H8.31C6.58945 21.5 5.27634 20.9891 4.39377 20.1054C3.51114 19.2217 3 17.9059 3 16.18V7.81C3 6.08944 3.51093 4.77618 4.39355 3.89355C5.27618 3.01093 6.58944 2.5 8.31 2.5H16.69C18.4107 2.5 19.7237 3.01097 20.605 3.89333C21.4861 4.77559 21.9947 6.08838 21.99 7.80863Z"
                    fill="#3B82F6"
                    stroke="white"
                />
                <path
                    d="M11.0804 15.5796C10.8804 15.5796 10.6904 15.4996 10.5504 15.3596L7.72043 12.5296C7.43043 12.2396 7.43043 11.7596 7.72043 11.4696C8.01043 11.1796 8.49043 11.1796 8.78043 11.4696L11.0804 13.7696L16.2204 8.62961C16.5104 8.33961 16.9904 8.33961 17.2804 8.62961C17.5704 8.91961 17.5704 9.39961 17.2804 9.68961L11.6104 15.3596C11.4704 15.4996 11.2804 15.5796 11.0804 15.5796Z"
                    fill="white"
                />
            </svg>
        </>
    );
};

export default CheckBoxIconSvg2;
