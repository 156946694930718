import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWorksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import { ConfigWordSearch } from "../../../shared/utils/configWordSearch";
import { addEventGa } from "../../lib/ga";
import { setAlertDownloadWsAction } from "../../redux/action/snackbar.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import ConstantsResource from "../../utils/ConstantsResource";
import Constants from "../../utils/constants";
import { convertImagesToBase64ToPrint } from "../../utils/convertImg";
import ImagesGame from "./images";

const PrintWithBase64 = ({
    worksheet,
    listImage,
    actionAfterPrint,
    setLoading,
    printWithKey,
    onlyDownload,
    handlePrint,
    componentPrintRef,
    otherActionAfterPrint,
    setOpenModal,
}: {
    worksheet: IWorksheet;
    listImage: string[];
    actionAfterPrint: Function;
    setLoading: Function;
    printWithKey?: boolean;
    onlyDownload?: boolean;
    handlePrint: Function;
    componentPrintRef: any;
    otherActionAfterPrint: Function;
    setOpenModal: Function;
}) => {
    const dispatch = useDispatch();
    const indexSelected = useSelector(
        (state: ResourceAppState) =>
            state.gameResourceState?.themes?.indexSelected
    );
    const updateSuccess = useSelector(
        (state: ResourceAppState) => state.quotaResourceUserState.updateSuccess
    );
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const [getBase64, setGetBase64] = useState(false);
    const [regainValueBase64, setRegainValueBase64] = useState(false);
    const [doneLoadImg, setDoneLoadImg] = useState(false);
    const [readyToDown, setReadyToDown] = useState(false);
    const [doneLoadBase64, setDoneLoadBase64] = useState(false);
    const [base64, setBase64] = useState([]);
    useEffect(() => {
        setGetBase64(false);
        return () => {
            setGetBase64(false);
        };
    }, [printWithKey]);

    useEffect(() => {
        if (indexSelected > 0) {
            addEventGa({ action: "download_theme" });
        }
        setLoading(true);
        setGetBase64(false);
        return () => {
            setLoading(false);
            setGetBase64(false);
        };
    }, [worksheet?.id]);
    const handleBeforeDownload = async () => {
        actionAfterPrint();
        setReadyToDown(true);
    };

    const downloadFc = async () => {
        const jsPDF = (await import("jspdf")).default;
        let doc = new jsPDF("p", "pt", "a4");
        let sum = 0;
        doc.deletePage(1);
        function convertBase64ToImage(base64String) {
            return new Promise((resolve) => {
                let imgDownload = new Image();
                imgDownload.src = base64String;
                imgDownload.onload = function () {
                    imgDownload.style.height = "200px";
                    imgDownload.style.visibility = "hidden";
                    resolve(imgDownload);
                };
            });
        }

        // Array to store promises
        const promises = base64.map(convertBase64ToImage);

        // Wait for all promises to resolve
        const images = await Promise.all(promises);

        // Add pages to doc for each image
        images.forEach((image: string, index) => {
            let width = ConfigWordSearch.WIDTH;
            let height = ConfigWordSearch.HEIGHT;
            doc.addPage([width, height]);
            doc.addImage(
                image,
                "JPEG",
                0,
                0,
                width,
                height,
                `alias${index + 1}`
            );
            sum += 1;
            if (sum === base64.length) {
                const fileName = (worksheet?.title ?? "worksheet") + ".pdf";
                otherActionAfterPrint();
                // setOpenModal(false);
                setReadyToDown(false);
                dispatch(
                    setAlertDownloadWsAction({
                        alertDownloadWs: Config.STATUS_ALERT_DOWNLOAD.INIT,
                    })
                );
                doc.save(fileName);

                // dispatch(selectGridItemAction({ worksheets: [] }));
            }
        });
    };
    useEffect(() => {
        if (updateSuccess && readyToDown) {
            downloadFc();
            return () => {
                setReadyToDown(false);
            };
        }
    }, [updateSuccess, readyToDown]);

    const getImg = async () => {
        let img = [];

        img = await getBase();
        if (img?.length) {
            setBase64(img);
        }
    };
    const getBase = async () => {
        let base64 = await convertImagesToBase64ToPrint("#print-id .img");

        return base64;
    };

    useEffect(() => {
        if (getBase64 && doneLoadImg) {
            try {
                let timer = setTimeout(() => {
                    getImg();
                }, 2100);
                return () => clearTimeout(timer);
            } catch (error) {
                setLoading(false);
            }
        }
    }, [getBase64, doneLoadImg]);

    useEffect(() => {
        if (base64?.length && doneLoadBase64) {
            let timer = setTimeout(() => {
                if (isTablet || onlyDownload) {
                    handleBeforeDownload();
                } else {
                    handlePrint();
                }
            }, 1);
            return () => clearTimeout(timer);
        }
    }, [base64, doneLoadBase64]);

    return (
        <div
            style={{
                width: 0,
                maxWidth: 0,
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    opacity: "0",
                    height: "0",
                    minWidth: `calc(${
                        Constants.WIDTH_RIGHT_CONTENT - 40 - 2
                    }px)`,
                }}
            >
                {(worksheet.id || worksheet.title) && (
                    <div
                        style={{
                            textAlign: "center",
                            position: "relative",
                            width: ConstantsResource.SIZE_PRINT.WIDTH + "px",
                            // width: "fit-content",
                        }}
                        id="print-id"
                    >
                        <div>
                            <ImagesGame
                                worksheet={worksheet}
                                images={listImage}
                                setDoneLoadImg={setDoneLoadImg}
                                setGetBase64={setGetBase64}
                                printWithAns={
                                    printWithKey &&
                                    !worksheet?.gameSubmitted?.images?.length
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            {base64?.length ? (
                <>
                    <div
                        style={{
                            opacity: "0",
                            zIndex: "-999",
                            width: 0,
                            height: "0",
                        }}
                    >
                        <div
                            ref={componentPrintRef}
                            style={{
                                margin: "auto",
                                textAlign: "center",
                            }}
                        >
                            {base64?.map((i, index) => {
                                return (
                                    <>
                                        <img
                                            key={index}
                                            src={i}
                                            style={{
                                                maxHeight:
                                                    ConfigWordSearch.HEIGHT *
                                                    0.98,
                                            }}
                                            onLoad={() => {
                                                if (
                                                    index ==
                                                    listImage?.length - 1
                                                ) {
                                                    setDoneLoadBase64(true);
                                                }
                                            }}
                                        />
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PrintWithBase64;
