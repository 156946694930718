import { IWorksheet } from "../../shared/models/worksheet";
import { IOffset, Offset } from "./answer";
import { Assignment, IAssignment } from "./assignment";
import { IUserAssignment, UserAssignment } from "./userAssignment";

export interface ICoords {
    id: string;
    strokeWidth: number;
    color: string;
    type: string;
    index: number;
    coords: {
        x: number;
        y: number;
        moveX: number;
        moveY: number;
    }[];
    textValue?: string;
    isBold?: boolean;
    isItalic?: boolean;
    brushRatio?: number;
}
export interface IDrawData {
    coordsDraw: ICoords[];
    coordsDrawHistory: any[];
    screenSize: any;
    historyIndex: number;
}

export class DrawData implements IDrawData {
    id: any;
    coordsDraw: ICoords[];
    coordsDrawHistory: ICoords[];
    screenSize: any;
    historyIndex: number;
    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.coordsDraw = object.coordsDraw ?? [];
        this.coordsDrawHistory = object.coordsDrawHistory ?? [[]];
        this.screenSize = object.screenSize ?? null;
        this.historyIndex = object.historyIndex ?? 0;
    }
}

export interface IUserAnswer {
    id: string;
    answerId: number;
    userAnswer: string; //fill_blank, select_box, open_answer, speaking
    isSelected: boolean; //multi_choice
    matchingId: number; //drag_drop, join_arrow
    userWorkSearchResult: number[][];
    userAnswerCorrect: boolean;
    point: IOffset; // join_arrow
}

export class UserAnswer implements IUserAnswer {
    id: string;
    answerId: number;
    userAnswer: string; //fill_blank, select_box, open_answer, speaking
    isSelected: boolean; //multi_choice
    matchingId: number; //drag_drop, join_arrow
    userWorkSearchResult: number[][];
    userAnswerCorrect: boolean;
    point: IOffset; // join_arrow
    userWordSearch: number[][];

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.answerId = object.answerId ?? 0;
        this.userAnswer = object.userAnswer ?? "";
        this.isSelected = object.isSelected ?? false;
        this.matchingId = object.matchingId ?? 0;
        this.userWorkSearchResult = object.userWorkSearchResult ?? null;
        this.userAnswerCorrect = object.userAnswerCorrect ?? false;
        this.point = object.point && new Offset(object.point);
        this.userWordSearch = object.userWordSearch ?? null;
    }
}

export interface IPracticeWorksheet {
    id: any;
    userId: string;
    worksheetId: string;
    score: number;
    isFinish: boolean;
    lastUpdated: Date;
    userAnswer: IUserAnswer[];
    worksheets?: IWorksheet;
    drawData?: IDrawData;
    resultForTeacher: IUserAnswer[];
    emailTeacher?: string;
    assignmentId?: string;
    screenSize?: {
        width: number;
        height: number;
    };
    userPracticeId?: string;
    teacherScore?: number;
    assignment?: IAssignment;
    userAssignment?: IUserAssignment;
    isSubmited?: boolean;
}

export class PracticeWorksheet implements IPracticeWorksheet {
    id: any;
    userId: string;
    worksheetId: string;
    score: number;
    isFinish: boolean;
    lastUpdated: Date;
    userAnswer: IUserAnswer[];
    worksheets?: IWorksheet;
    drawData?: IDrawData;
    resultForTeacher: IUserAnswer[];
    emailTeacher?: string;
    screenSize?: {
        width: number;
        height: number;
    };

    assignmentId?: string;
    userPracticeId?: string;
    teacherScore?: number;
    assignment?: IAssignment;
    userAssignment?: IUserAssignment;
    isSubmited?: boolean;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.userId = object.userId ?? null;
        this.worksheetId = object.worksheetId ?? null;
        this.score = object.score ?? null;
        this.isFinish = object.isFinish ?? false;
        this.lastUpdated = object.lastUpdated && new Date(object.lastUpdated);
        this.worksheets = object.worksheets ?? [];
        this.drawData = object.drawData ?? new DrawData(object.drawData);
        this.userAnswer = object.userAnswer?.length
            ? object.userAnswer.map((e: IUserAnswer) => new UserAnswer(e))
            : [];
        this.resultForTeacher = object.resultForTeacher?.length
            ? object.resultForTeacher.map((e: IUserAnswer) => new UserAnswer(e))
            : [];
        this.emailTeacher = object.emailTeacher ?? null;
        this.assignmentId = object.assignmentId ?? null;
        this.screenSize = object.screenSize ?? null;
        this.userPracticeId = object.userPracticeId ?? null;
        this.teacherScore = object.teacherScore ?? null;
        if (object.assignment) {
            this.assignment = new Assignment(object.assignment);
        }
        if (object.userAssignment) {
            this.userAssignment = new UserAssignment(object.userAssignment);
        }
        this.isSubmited = object.isSubmited ?? false;
    }
}
