import { call, fork, put, select, takeLatest } from "@redux-saga/core/effects";
import {
    closePopupnAction,
    ConversationTypes,
    IConversationAction,
    setLoadingConversationAction,
    setLoadingHistoryAction,
    updateConversationAction,
    updateConversationIdAction,
    updateHistoryConversationAction,
} from "../action/assistant.action";
import {
    askAssistant,
    getMosetRecentConversation
} from "../repositories/conversation.repositories";
import {
    ChatRequestMessageRoleEnum,
    IMessage,
    MessageStatusEnum,
} from "../reducer/conversation.reducer";
import constants from "../../utils/constants";
import { randomNumber } from "../../utils";
import {
    changePuzzleConfigAction,
    enterNewWordAction,
    initWordSearchGameAction,
    resetSvgAction,
} from "../action/wordsearch.action";
import { Puzzle } from "../../../shared/models/puzzle";
import Config, { ChatAction } from "../../../shared/utils/config";
import { ResourceAppState } from "../reducer/root.reducerModule";
import { delay } from "redux-saga/effects";
import { convertImgToBase64 } from "../../utils/convertImg";
import {
    changeTitleWordsCrosswordAction, changeWordsSuccessAciton,
    updateLayoutAction
} from "../action/crossword.action";
import { generateCrossword } from "../../components/crossword/logic";
import { formatWords } from "../../components/crossword/logic/utils";
import ConstantsTool from "../../../shared/utils/ConstantsTool";

function* askAssistantSaga(action: IConversationAction) {
    const userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    const isLoading: boolean = yield select(
        (state: ResourceAppState) => state?.conversationState.isLoading
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    // const currentActivityParam = getInitTypeFromUrl(); // voi duong dan tool le, chi dinh currentActivity

    if (isLoading) {
        return;
    }
    yield put(setLoadingConversationAction(true));

    try {
        let response = yield call(askAssistant, {
            userId: userIdState,
            conversationId: action.conversationId ?? conversationIdState,
            role: action.role,
            content: action.content,
            action: ChatAction.Next,
            typeActivity: currentActivity,
        });
        let conversationId = response.conversationId;
        yield put(updateConversationIdAction(conversationId));
        let responseContent = response.content;
        responseContent = responseContent.replace("WorksheetZone:", "");
        if (responseContent.includes("@" + ChatRequestMessageRoleEnum.Human)) {
            messagesClone = messagesClone.filter(
                (e) => e.status != MessageStatusEnum.Typing
            );
            messagesClone.push({
                role: ChatRequestMessageRoleEnum.WorksheetZone,
                message: responseContent
                    .replace("@" + ChatRequestMessageRoleEnum.Human, "")
                    .trim(),
            });
            yield put(updateConversationAction(conversationId, messagesClone));
        } else if (
            responseContent.includes("@" + ChatRequestMessageRoleEnum.Backend)
        ) {
            responseContent = responseContent
                .replace("@" + ChatRequestMessageRoleEnum.Backend, "")
                .trim();
            let configPuzzle = JSON.parse(responseContent.toLowerCase());
            // handle backend
            switch (currentActivity) {
                case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
                    yield handleGenWordSearch(configPuzzle, ChatAction.Next);
                    break;
                case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
                    yield handleGenCrossWord(configPuzzle, ChatAction.Next);
                    break;
            }
        } else {
            yield handleErrorMess("Sorry, something went wrong !");
        }
        yield put(setLoadingConversationAction(false));
    } catch (error) {
        yield handleErrorMess("Sorry, We overload. Try again later !");
    }
}

function* handleGenWordSearch(config: any, action: ChatAction) {
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    try {
        yield preprocessWordSearchShape(config, messagesClone);
        yield proprocessWordSearchWordList(config, messagesClone);
    } catch (err) {
        return;
    }
    preprocessWordSearchSize(config);
    preprocessWordSearchTitle(config);
    const ratio = yield select(
        (state: ResourceAppState) => state?.wordSearchState.ratio
    );
    yield put(initWordSearchGameAction({ worksheetId: "", ratio }));
    yield put(resetSvgAction({ resetSvg: true }));
    let puzzle = new Puzzle({
        puzzleShape: config.shape,
        puzzleSize: config.size,
        showAnswerKey: Config.HIDE_VALUE,
        title: config.title,
    });
    yield put(changePuzzleConfigAction(puzzle));
    yield put(enterNewWordAction(config.wordlist, true));
    yield responseMess(messagesClone, config, action);
}

function preprocessWordSearchSize(config: any) {
    if (config.size) {
        if (constants.SYNONYM_SMALL.includes(config.size.toLowerCase())) {
            config.size = "Small";
        } else if (
            constants.SYNONYM_MEDIUM.includes(config.size.toLowerCase())
        ) {
            config.size = "Medium";
        } else if (
            constants.SYNONYM_LARGE.includes(config.size.toLowerCase())
        ) {
            config.size = "Large";
        } else {
            config.size = "Large";
        }
    } else {
        config.size = "Large";
    }
}

function* preprocessWordSearchShape(config: any, messagesClone: IMessage[]) {
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const shapes = constants.PUZZLE_SHAPE_ARRAY;
    let shapeNames = shapes.map((e) => e.shape);
    //deduplicate
    shapeNames = shapeNames.filter((c, index) => {
        return shapeNames.indexOf(c) === index;
    });
    if (!config.shape) {
        let excludeShape = ["1"];
        shapeNames = shapeNames.filter((name, index) => {
            if (!excludeShape.includes(name)) {
                return name;
            }
        });
        let radomShapeNameindex = randomNumber(0, shapeNames.length - 1);
        let radomShapeName = shapeNames[radomShapeNameindex];
        let randomShape = shapes.filter((e) => {
            return e.shape == radomShapeName && e.size == "Large";
        })[0];
        config.shape = randomShape.shape;
    } else {
        shapeNames = shapeNames.filter((name, index) => {
            if (
                name.toLocaleLowerCase() ==
                config.shape.toLocaleLowerCase().trim()
            ) {
                return name;
            }
        });
        if (shapeNames.length == 0) {
            messagesClone = messagesClone.filter(
                (e) => e.status != MessageStatusEnum.Typing
            );
            messagesClone.push({
                role: ChatRequestMessageRoleEnum.WorksheetZone,
                message:
                    "Sorry, we support limit shape. Try again with shape: square, pumpkin, Diamond, ...",
            });
            yield put(
                updateConversationAction(conversationIdState, messagesClone)
            );
            throw new Error("Dont support shape: ", config.shape);
        } else {
            config.shape = shapeNames[0];
        }
    }
}

function* proprocessWordSearchWordList(
    configPuzzle: any,
    messagesClone: IMessage[]
) {
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    if (!configPuzzle.wordlist || configPuzzle.wordlist.length === 0) {
        // yield askAssistantSaga({
        //   type: ConversationTypes.ASK_ASSISTANT,
        //   role: ChatRequestMessageRoleEnum.Backend,
        //   content: "empty topic"
        // })

        messagesClone = fakeResponseEmpytopic(messagesClone, currentActivity);
        yield put(updateConversationAction(conversationIdState, messagesClone));
        throw new Error("Empy word list");
    } else {
        if (typeof configPuzzle.wordlist === "string") {
            if (configPuzzle.wordlist) {
                let words: string[] = configPuzzle.wordlist
                    .split("|")
                    .map((e) => e.replace(/[^\w]/gi, ""));
                configPuzzle.wordlist = words;
            } else {
                configPuzzle.wordlist = [];
            }
        } else {
            configPuzzle.wordlist = configPuzzle.wordlist.map((e) =>
                e.replace(/[^\w]/gi, "")
            );
        }
    }
}

function preprocessWordSearchTitle(config: any) {
    if (!config.title) {
        if (config.topic) {
            config.title = config.topic + " word search";
        } else {
            config.title = config.wordlist[0] + " word search";
        }
    }
}

function* handleGenCrossWord(config: any, action: ChatAction) {
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );

    if (!config.wordlist || config.wordlist.length === 0) {
        // yield askAssistantSaga({
        //   type: ConversationTypes.ASK_ASSISTANT,
        //   role: ChatRequestMessageRoleEnum.Backend,
        //   content: "empty topic"
        // })

        messagesClone = fakeResponseEmpytopic(messagesClone, currentActivity);
        yield put(updateConversationAction(conversationIdState, messagesClone));
        // yield call(appendLassMessInConversation, {
        //     conversationId: conversationIdState,
        //     role: "",
        //     action: ChatAction.FakeContent,
        //     newContnet: "",
        //     additionContent: radomResponseMess,
        // });
        return;
    }
    if (!config.title && config.topic) {
        config.title = config.topic + " crossword";
    }
    try {
        yield preprocessLayout(config, messagesClone);
    } catch (e) {
        return;
    }
    // yield put(changeWordsAction(config.wordlist));
    const { words: newWords, grid } = generateCrossword(
        formatWords(config.wordlist)
    );
    yield put(changeWordsSuccessAciton(newWords, grid));
    yield put(changeTitleWordsCrosswordAction(config.title));
    config = {
        ...config,
        newWords,
        grid,
    };
    yield responseMess(messagesClone, config, action);
}

function* preprocessLayout(config: any, messagesClone: IMessage[]) {
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    let layout = config.layout;
    if (!config.layout) {
        let randomLayout = getRandomLayout();
        layout = randomLayout;
    } else {
        let availableLayout = ConstantsTool.LAYOUT;
        let valuesLayout = Object.values(availableLayout);
        let isExistLayout = valuesLayout.find(
            (e) => e.toLocaleLowerCase() === config.layout.toLocaleLowerCase()
        );
        layout = isExistLayout;
    }
    if (layout) {
        config.layout = layout;
        yield put(updateLayoutAction(layout));
    } else {
        messagesClone = messagesClone.filter(
            (e) => e.status != MessageStatusEnum.Typing
        );
        messagesClone.push({
            role: ChatRequestMessageRoleEnum.WorksheetZone,
            message:
                "Sorry, we support limit layout. Try again with layouts: Default, Clues by Side, Image lower right, ...",
        });
        yield put(updateConversationAction(conversationIdState, messagesClone));
        throw new Error("Not exist layout.");
    }
}

function getRandomLayout() {
    let availableLayout = ConstantsTool.LAYOUT;
    let keyAlyout = Object.keys(availableLayout);
    let randomIndex = randomNumber(0, keyAlyout.length - 1);
    let randomKey = keyAlyout[randomIndex];
    return availableLayout[randomKey];
}

function* responseMess(
    messagesClone: IMessage[],
    config: any,
    action: ChatAction
) {
    const openPopup: boolean = yield select(
        (state: ResourceAppState) => state?.conversationState.openPopup
    );

    const projectNameTool = yield select(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );

    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    if (!openPopup || projectNameTool != Config.PROJECT_NAME.WORD_SEARCH) {
        yield put(closePopupnAction(false));
    }
    // yield askAssistantSaga({
    //   type: ConversationTypes.ASK_ASSISTANT,
    //   role: ChatRequestMessageRoleEnum.Backend,
    //   content: "oke"
    // })

    yield delay(1000); // wait to gen all pages
    let base64;
    if (projectNameTool == Config.PROJECT_NAME.WORD_SEARCH) {
        base64 = yield convertImgToBase64();
    } else {
        base64 = yield convertImgToBase64("right-content-container-2");
    }
    let res = fakeResponseCreateSuccessPuzzle(messagesClone, currentActivity);
    messagesClone = res.messagesClone;
    switch (action) {
        case ChatAction.Next:
            let newMess = {
                role: ChatRequestMessageRoleEnum.WorksheetZone,
                message: res.radomResponseMess,
                puzzleConfig: [
                    {
                        base64,
                        config,
                    },
                ],
            };
            messagesClone.push(newMess);
            break;
        case ChatAction.ReGenerate:
            let lassMess = messagesClone[messagesClone.length - 1];
            lassMess.message = res.radomResponseMess;
            lassMess.puzzleConfig.push({
                base64,
                config: config,
            });
            break;
        default:
            break;
    }
    yield put(updateConversationAction(conversationIdState, messagesClone));

    // // update for restore history
    // let additionContent = res.radomResponseMess;
    // let newContent = `WorksheetZone: @${
    //     ChatRequestMessageRoleEnum.Backend
    // } ${JSON.stringify(config)}`;
    // yield call(updateMessInConversation, {
    //     conversationId: conversationIdState,
    //     conentId: "",
    //     newContnet: newContent,
    //     additionContent: additionContent,
    // });
}

function* reGenerateWordSearchSaga() {
    yield put(setLoadingConversationAction(true));
    const userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    // const currentActivityParam = getInitTypeFromUrl(); // voi duong dan tool le, chi dinh currentActivity
    let response = yield call(askAssistant, {
        userId: userIdState,
        conversationId: conversationIdState,
        role: ChatRequestMessageRoleEnum.Human,
        content: "change wordlist and not set shape",
        action: ChatAction.ReGenerate,
        typeActivity: currentActivity,
    });
    let responseContent = response.content;
    responseContent = responseContent.replace("WorksheetZone: ", "");
    if (responseContent.includes("@" + ChatRequestMessageRoleEnum.Backend)) {
        responseContent = responseContent
            .replace("@" + ChatRequestMessageRoleEnum.Backend, "")
            .trim();
        let configPuzzle = JSON.parse(responseContent.toLowerCase());

        yield handleGenWordSearch(configPuzzle, ChatAction.ReGenerate);
    } else {
        yield handleErrorMess("Sorry, something went wrong !");
    }
}

function* reGenerateCrossWordSaga() {
    yield put(setLoadingConversationAction(true));
    const userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    // const currentActivityParam = getInitTypeFromUrl(); // voi duong dan tool le, chi dinh currentActivity
    let response = yield call(askAssistant, {
        userId: userIdState,
        conversationId: conversationIdState,
        role: ChatRequestMessageRoleEnum.Human,
        content: yield genMessReGenCrossWord(),
        action: ChatAction.ReGenerate,
        typeActivity: currentActivity,
    });
    let responseContent = response.content;
    responseContent = responseContent.replace("WorksheetZone: ", "");
    if (responseContent.includes("@" + ChatRequestMessageRoleEnum.Backend)) {
        responseContent = responseContent
            .replace("@" + ChatRequestMessageRoleEnum.Backend, "")
            .trim();
        let configPuzzle = JSON.parse(responseContent.toLowerCase());

        yield handleGenCrossWord(configPuzzle, ChatAction.ReGenerate);
    } else {
        yield handleErrorMess("Sorry, something went wrong !");
    }
}

function* reGenerate() {
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    yield addTypingMess();
    switch (currentActivity) {
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            yield reGenerateWordSearchSaga();
            break;
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            yield reGenerateCrossWordSaga();
            break;
    }
}

function* seeDetailWordSearchSaga(puzzleConfig: any) {
    const ratio = yield select(
        (state: ResourceAppState) => state?.wordSearchState.ratio
    );
    yield put(initWordSearchGameAction({ worksheetId: "", ratio }));
    yield put(resetSvgAction({ resetSvg: true }));
    let puzzle = new Puzzle({
        puzzleShape: puzzleConfig.shape,
        puzzleSize: puzzleConfig.size ?? "Large",
        showAnswerKey: Config.HIDE_VALUE,
        title: puzzleConfig.title,
    });
    yield put(changePuzzleConfigAction(puzzle));
    yield put(enterNewWordAction(puzzleConfig.wordlist, true));
}

function* seeDetailCrossWordSaga(puzzleConfig: any) {
    yield put(updateLayoutAction(puzzleConfig.layout));
    // yield put(changeWordsAction(puzzleConfig.wordlist));
    yield put(
        changeWordsSuccessAciton(puzzleConfig.newWords, puzzleConfig.grid)
    );
    yield put(changeTitleWordsCrosswordAction(puzzleConfig.title));
}

function* seeDetail(action: IConversationAction) {
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity.type
    );
    let { puzzleConfig } = action;
    switch (currentActivity) {
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            yield seeDetailWordSearchSaga(puzzleConfig);
            break;
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            yield seeDetailCrossWordSaga(puzzleConfig);
            break;
    }
}

function* genMessReGenCrossWord() {
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    messagesClone = messagesClone.filter(
        (e) => e.status != MessageStatusEnum.Typing
    );
    let mess = "change wordlist";
    // try {
    //     mess =
    //         "make crossword about " +
    //         messagesClone[messagesClone.length - 1].puzzleConfig[0].config
    //             .topic;
    // } catch (err) {}
    return mess;
}

function* handleErrorMess(mess: string) {
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    let messagesClone2 = JSON.parse(JSON.stringify(messagesClone));
    messagesClone2 = messagesClone2.filter(
        (e) => e.status != MessageStatusEnum.Typing
    );
    messagesClone2.push({
        role: ChatRequestMessageRoleEnum.WorksheetZone,
        message: mess,
    });
    yield put(updateConversationAction(conversationIdState, messagesClone2));
}

function fakeResponseMess(
    data: string[],
    messagesClone: IMessage[],
    typeActivity: string
) {
    // fake response
    let radomResponseMessListindex = randomNumber(0, data.length - 1);
    let radomResponseMess = data[radomResponseMessListindex];
    if (typeActivity === Config.ACTIVITY_TYPE.CROSSWORD.TYPE) {
        radomResponseMess = radomResponseMess.replace(
            "word search puzzle",
            "crossword"
        );
    }
    messagesClone = messagesClone.filter(
        (e) => e.status != MessageStatusEnum.Typing
    );
    return { radomResponseMess, messagesClone };
}

function fakeResponseEmpytopic(
    messagesClone: IMessage[],
    typeActivity: string
) {
    const responseEmptyList = constants.RESPONSE_EMPTY_TOPIC;
    let res = fakeResponseMess(responseEmptyList, messagesClone, typeActivity);
    messagesClone = res.messagesClone;
    messagesClone.push({
        role: ChatRequestMessageRoleEnum.WorksheetZone,
        message: res.radomResponseMess,
    });
    return messagesClone;
}

function fakeResponseCreateSuccessPuzzle(
    messagesClone: IMessage[],
    typeActivity: string
) {
    const responseGenWsList = constants.RESPONSE_GEN_WORDSEARCH_SUCCESS;
    return fakeResponseMess(responseGenWsList, messagesClone, typeActivity);
}

function* addTypingMess() {
    const messagesState: IMessage[] = yield select(
        (state: ResourceAppState) => state?.conversationState.messages
    );
    const conversationIdState: string = yield select(
        (state: ResourceAppState) => state?.conversationState.id
    );
    let messagesClone: IMessage[] = JSON.parse(JSON.stringify(messagesState));
    messagesClone.push({
        role: ChatRequestMessageRoleEnum.WorksheetZone,
        message: "typing",
        status: MessageStatusEnum.Typing,
    });
    yield put(updateConversationAction(conversationIdState, messagesClone));
}

function* getMostRecentConversationSaga(action: IConversationAction) {
    let userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    let response = yield call(getMosetRecentConversation, {
        userId: userIdState,
        limit: constants.MOST_RECENT_CONVERSATION_LIMIT,
    });
    let showHistoryConversations = false;
    if (response?.length > 0) {
        showHistoryConversations = true;
    }
    yield put(
        updateHistoryConversationAction(response, showHistoryConversations)
    );
}

function* loadAHistoryConversationSaga(action: IConversationAction) {
    const isLoadingHistory: boolean = yield select(
        (state: ResourceAppState) => state?.conversationState.isLoadingHistory
    );
    if (isLoadingHistory) {
        return;
    }
    yield put(setLoadingHistoryAction(true));
    let historyConversations: any[] = yield select(
        (state: ResourceAppState) =>
            state?.conversationState.historyConversations
    );
    let currentConversation = historyConversations.filter((e) => {
        return e.id == action.conversationId;
    })[0];
    let messages = currentConversation.messages;
    let restoreMess: IMessage[] = [];
    for (let index = 0; index < messages.length; index++) {
        let content = messages[index].content;
        let actionChat = messages[index].action;
        let additionContent = messages[index].additionContent;

        if (
            content.startsWith(ChatRequestMessageRoleEnum.Human + ":") &&
            actionChat == ChatAction.Next
        ) {
            restoreMess.push({
                role: ChatRequestMessageRoleEnum.Human,
                message: content
                    .replace(ChatRequestMessageRoleEnum.Human + ":", "")
                    .trim(),
            });
        } else if (content.includes("@" + ChatRequestMessageRoleEnum.Human)) {
            restoreMess.push({
                role: ChatRequestMessageRoleEnum.WorksheetZone,
                message: content
                    .replace("WorksheetZone:", "")
                    .replace("@" + ChatRequestMessageRoleEnum.Human, "")
                    .trim(),
            });
        } else if (
            content.includes("@" + ChatRequestMessageRoleEnum.Backend) &&
            actionChat == ChatAction.Next
        ) {
            content = content
                .replace("WorksheetZone:", "")
                .replace("@" + ChatRequestMessageRoleEnum.Backend, "")
                .trim();
            let configPuzzle = JSON.parse(content);
            if (typeof configPuzzle.wordlist === "string") {
                if (configPuzzle.wordlist) {
                    let words: string[] = configPuzzle.wordlist.split("|");
                    configPuzzle.wordlist = words;
                } else {
                    configPuzzle.wordlist = [];
                }
            }
            const shapes = constants.PUZZLE_SHAPE_ARRAY;
            let shapeNames = shapes.map((e) => e.shape);
            shapeNames = shapeNames.filter((c, index) => {
                return shapeNames.indexOf(c) === index;
            });
            shapeNames = shapeNames.filter((name, index) => {
                if (
                    name.toLocaleLowerCase() ==
                    configPuzzle.shape.toLocaleLowerCase().trim()
                ) {
                    return name;
                }
            });
            if (configPuzzle?.wordlist?.length > 0 && shapeNames.length > 0) {
                const ratio = yield select(
                    (state: ResourceAppState) => state?.wordSearchState.ratio
                );
                yield put(initWordSearchGameAction({ worksheetId: "", ratio }));
                let puzzle = new Puzzle({
                    puzzleShape: configPuzzle.shape,
                    puzzleSize: configPuzzle.size,
                    showAnswerKey: Config.HIDE_VALUE,
                    title: configPuzzle.title,
                });
                yield put(changePuzzleConfigAction(puzzle));
                yield put(enterNewWordAction(configPuzzle.wordlist, true));
                const projectNameTool = yield select(
                    (state: ResourceAppState) =>
                        state.wordSearchState.projectName
                );

                let base64;
                if (projectNameTool == Config.PROJECT_NAME.WORD_SEARCH) {
                    base64 = yield convertImgToBase64();
                } else {
                    base64 = yield convertImgToBase64(
                        "right-content-container-2"
                    );
                }

                let newMess = {
                    role: ChatRequestMessageRoleEnum.WorksheetZone,
                    message: messages[index].additionContent,
                    puzzleConfig: [
                        {
                            base64,
                            config: configPuzzle,
                        },
                    ],
                };
                restoreMess.push(newMess);
            }
        } else if (
            content.includes("@" + ChatRequestMessageRoleEnum.Backend) &&
            actionChat == ChatAction.ReGenerate
        ) {
            content = content
                .replace("WorksheetZone:", "")
                .replace("@" + ChatRequestMessageRoleEnum.Backend, "")
                .trim();
            let configPuzzle = JSON.parse(content);
            if (typeof configPuzzle.wordlist === "string") {
                if (configPuzzle.wordlist) {
                    let words: string[] = configPuzzle.wordlist.split("|");
                    configPuzzle.wordlist = words;
                } else {
                    configPuzzle.wordlist = [];
                }
            }
            if (configPuzzle?.wordlist?.length > 0) {
                // handle backend
                const ratio = yield select(
                    (state: ResourceAppState) => state?.wordSearchState.ratio
                );
                yield put(initWordSearchGameAction({ worksheetId: "", ratio }));
                let puzzle = new Puzzle({
                    puzzleShape: configPuzzle.shape,
                    puzzleSize: configPuzzle.size ?? "Large",
                    showAnswerKey: Config.HIDE_VALUE,
                    title: configPuzzle.title,
                });
                yield put(changePuzzleConfigAction(puzzle));
                yield put(enterNewWordAction(configPuzzle.wordlist, true));

                const projectNameTool = yield select(
                    (state: ResourceAppState) =>
                        state.wordSearchState.projectName
                );

                let base64;
                if (projectNameTool == Config.PROJECT_NAME.WORD_SEARCH) {
                    base64 = yield convertImgToBase64();
                } else {
                    base64 = yield convertImgToBase64(
                        "right-content-container-2"
                    );
                }
                let lassMess = restoreMess[restoreMess.length - 1];
                lassMess.puzzleConfig.push({
                    base64,
                    config: configPuzzle,
                });
            }
        } else if (actionChat == ChatAction.FakeContent) {
            restoreMess.push({
                role: ChatRequestMessageRoleEnum.WorksheetZone,
                message: additionContent
                    .replace("WorksheetZone:", "")
                    .replace("@" + ChatRequestMessageRoleEnum.Human, "")
                    .trim(),
            });
        }
    }
    yield put(updateConversationAction(action.conversationId, restoreMess));
    yield put(setLoadingHistoryAction(false));
}

function* listenConversation() {
    yield takeLatest(ConversationTypes.ASK_ASSISTANT, askAssistantSaga);
    yield takeLatest(ConversationTypes.REGENERATTE_WORD_SEARCH, reGenerate);
    yield takeLatest(ConversationTypes.SEE_DETAIL_WORD_SEARCH, seeDetail);
    yield takeLatest(
        ConversationTypes.GET_HISTORY_CONVERSATION,
        getMostRecentConversationSaga
    );
    yield takeLatest(
        ConversationTypes.LOAD_A_HISTORY_CONVERSATION,
        loadAHistoryConversationSaga
    );
}

export const conversationSaga = [fork(listenConversation)];
