import { ChatAction } from "../../../shared/utils/config";
import { APIConfig } from "../../utils/api-config";
import { ChatRequestMessageRoleEnum } from "../reducer/conversation.reducer";
import { POST } from "./index";

export const askAssistant = (data: {
    userId: string;
    conversationId: string;
    role: ChatRequestMessageRoleEnum;
    content: string;
    action: ChatAction;
    typeActivity: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.ASSISTANT_CONVERSATION,
        params: data,
    }).then((data: any) => {
        return data;
    });
};

export const getMosetRecentConversation = (data: {
    userId: string;
    limit: number;
}): Promise<any> => {
    return POST({
        url: APIConfig.MOST_RECENT_CONVERSATION,
        params: data,
    }).then((data: any) => {
        return data;
    });
};

export const updateMessInConversation = (data: {
    conversationId: string;
    conentId: string;
    newContnet: string;
    additionContent: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.UPDATE_MESS_CONVERSATION,
        params: data,
    }).then((data: any) => {
        return data;
    });
};

export const appendLassMessInConversation = (data: {
    conversationId: string;
    role: string;
    action: string;
    newContnet: string;
    additionContent: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.APPEND_LASS_MESS_CONVERSATION,
        params: data,
    }).then((data: any) => {
        return data;
    });
};
