import { Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useAppDispatch } from "../../../../../../redux/hook";
import { updateCurrentToResourceItem } from "../../../../../../redux/reducers/createWorksheet";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../../../shared/utils/ConstantsTool";
import {
    getPrefixImage,
    isImageSvg,
    resourceCanEditColorOnTop,
} from "../../../../../../utils";
import "./index.scss";
import {
    ColorCellTbale,
    TableAttribute,
} from "../../../../../../shared/models/tableAttribute";
import {
    EventTracking,
    sendEvent,
} from "../../../../../../resource-user/utils/event";

const ColorButton = ({
    index,
    color,
    tooltip = "",
    onClick,
    colors,
}: {
    index: number;
    color: string;
    tooltip?: string;
    onClick: Function;
    colors?: string[];
}) => {
    return (
        <Tooltip
            title={tooltip}
            placement="top"
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                            color: "common.black",
                        },
                    },
                },
            }}
        >
            <div
                onClick={(event) => {
                    onClick(event);
                }}
            >
                {colors?.length > 1 ? (
                    <div className="list-colors-button">
                        {colors.map((color) => {
                            let width = 25 / colors.length;
                            return (
                                <div
                                    className="item-color"
                                    style={{
                                        background: color,
                                        width,
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div
                        className="color-button"
                        style={{
                            background: color,
                            marginLeft: index === 0 ? 0 : -5,
                        }}
                    ></div>
                )}
            </div>
        </Tooltip>
    );
};

const ChoseColor = ({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement;
}) => {
    const dispatch = useAppDispatch();
    const isImage = isImageSvg(currentElement);
    const otherResource = resourceCanEditColorOnTop(currentElement);
    const isTable = currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE;
    const [pickerColor, setPickerColor] = useState<{
        originColor: string;
        currentColor: string;
    } | null>(null);
    const [isDisplayColorPanel, setIsDisplayColorPanel] =
        useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    let listObjectColor: {
        originColor: any;
        currentColor: any;
        colors?: string[];
    }[] = [];
    if (isImage) {
        let imageAttribute = currentElement.imageAttribute;
        listObjectColor = Object.keys(imageAttribute.changeColor).map((key) => {
            return {
                originColor: key,
                currentColor: imageAttribute.changeColor[key],
            };
        });
    } else if (isTable) {
        let tableAttribute = new TableAttribute(currentElement.tableAttribute);
        let { colorBorders, backgrounds } = tableAttribute.getColorCells();
        listObjectColor.push({
            originColor: "background",
            currentColor: backgrounds[0],
            colors: backgrounds,
        });
        listObjectColor.push({
            originColor: "colorBorder",
            currentColor: colorBorders[0],
            colors: colorBorders,
        });
    } else if (otherResource) {
        listObjectColor.push({
            originColor: "fontColor",
            currentColor: currentElement.textAttribute.fontColor ?? "#212121",
        });
    }

    const open = Boolean(anchorEl);
    const tooltip =
        currentElement.type === ConstantsTool.TYPE_RESOURCE_SHAPE
            ? "Border color"
            : "";

    const onChangePicker = (newColor) => {
        const newHexColor = newColor.hex;
        setPickerColor({
            ...pickerColor,
            currentColor: newHexColor,
        });
        const newCurrentElement = {
            ...currentElement,
        };
        if (isImage) {
            const imageAttribute = currentElement.imageAttribute;
            newCurrentElement.imageAttribute = {
                ...imageAttribute,
                changeColor: {
                    ...imageAttribute.changeColor,
                    [pickerColor.originColor]: newHexColor,
                },
            };
        } else if (isTable) {
            let tableAttribute = new TableAttribute(
                currentElement.tableAttribute
            );
            if (pickerColor.originColor === "background") {
                sendEvent(EventTracking.ncw_change_color_cell_on_table);
            } else {
                sendEvent(EventTracking.ncw_change_color_border_table);
            }
            tableAttribute.changeColorCells({
                colorCell: new ColorCellTbale({
                    background:
                        pickerColor.originColor === "background"
                            ? newHexColor
                            : null,
                    colorBorder:
                        pickerColor.originColor === "colorBorder"
                            ? newHexColor
                            : null,
                }),
            });
            newCurrentElement.tableAttribute = tableAttribute;
        } else if (otherResource) {
            newCurrentElement.textAttribute = {
                ...currentElement.textAttribute,
                color: newHexColor,
                fontColor: newHexColor,
            };
        }
        setCurrentElement(newCurrentElement);
    };

    const onChangeCompletePicker = (newColor) => {
        const newHexColor = newColor.hex;
        const newCurrentElement = {
            ...currentElement,
        };
        if (isImage) {
            const imageAttribute = currentElement.imageAttribute;
            newCurrentElement.imageAttribute = {
                ...imageAttribute,
                changeColor: {
                    ...imageAttribute.changeColor,
                    [pickerColor.originColor]: newHexColor,
                },
            };
        } else if (isTable) {
            let tableAttribute = new TableAttribute(
                currentElement.tableAttribute
            );
            tableAttribute.changeColorCells({
                colorCell: new ColorCellTbale({
                    background:
                        pickerColor.originColor === "background"
                            ? newHexColor
                            : null,
                    colorBorder:
                        pickerColor.originColor === "colorBorder"
                            ? newHexColor
                            : null,
                }),
            });
            newCurrentElement.tableAttribute = tableAttribute;
        } else if (otherResource) {
            newCurrentElement.textAttribute = {
                ...currentElement.textAttribute,
                color: newHexColor,
                [pickerColor.originColor]: newHexColor,
            };
        }

        dispatch(
            updateCurrentToResourceItem({
                currentElement: newCurrentElement,
                pageIndex: currentElement.pageIndex,
                isAddHistory: true,
            })
        );
    };

    return (
        <React.Fragment>
            {(isImage || otherResource || isTable) &&
                listObjectColor.length > 0 && (
                    <div className="chose-color-container">
                        <div className="chose-color-wrapper">
                            {listObjectColor.map((colorObject, index) => {
                                if (index < 3) {
                                    return (
                                        <ColorButton
                                            key={index}
                                            index={index}
                                            color={colorObject.currentColor}
                                            tooltip={tooltip}
                                            colors={colorObject.colors}
                                            onClick={(event) => {
                                                setIsDisplayColorPanel(false);
                                                setPickerColor(colorObject);
                                                setAnchorEl(
                                                    event.currentTarget
                                                );
                                            }}
                                        />
                                    );
                                }
                            })}

                            {listObjectColor.length > 3 && (
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={(event) => {
                                        setPickerColor(null);
                                        setIsDisplayColorPanel(
                                            !isDisplayColorPanel
                                        );
                                    }}
                                >
                                    <img
                                        src={
                                            getPrefixImage() +
                                            "/images/functional-icon/more-color.svg"
                                        }
                                        className="more-color-button"
                                    />
                                    {listObjectColor.length > 3 && (
                                        <div className="more-color">{`+${
                                            listObjectColor.length - 3
                                        }`}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {pickerColor && (
                <div style={{ position: "relative" }}>
                    <Popover
                        id={"simple-popover"}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setCurrentElement({ ...currentElement });
                            setAnchorEl(null);
                        }}
                        anchorOrigin={{
                            vertical: 0,
                            horizontal: -250,
                        }}
                    >
                        <SketchPicker
                            color={pickerColor.currentColor}
                            onChange={onChangePicker}
                            onChangeComplete={onChangeCompletePicker}
                        />
                    </Popover>
                </div>
            )}
            {isDisplayColorPanel && (
                <div
                    className="color-panel"
                    style={{
                        top: `${
                            currentElement.y < 120
                                ? 40
                                : -Math.ceil((listObjectColor.length - 3) / 4) *
                                  36
                        }px`,
                    }}
                >
                    {listObjectColor.map(
                        (color, index) =>
                            index >= 3 && (
                                <ColorButton
                                    key={index}
                                    index={index}
                                    color={color.currentColor}
                                    onClick={(event) => {
                                        setPickerColor(color);
                                        setAnchorEl(event.currentTarget);
                                    }}
                                />
                            )
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default ChoseColor;
