import { ICollection } from "../../../shared/models/collection";
import { IUserInfo } from "../../../shared/models/userInfo";
import { IWorksheet } from "../../../shared/models/worksheet";
import { IUserRela } from "./../../../shared/models/userRela";
import { IDownloadWorksheet } from "../../../shared/models/downloadWorksheets";
import { IPracticeWorksheet } from "../../../shared/models/practiceWorksheets";

export enum UserTypes {
    GET_USER_INFO = "GET_USER_INFO",
    LOGIN_WITH_GOOGLE_SUCCESS = "LOGIN_WITH_GOOGLE_SUCCESS",
    GET_USER_INFO_SUCCES = "GET_USER_INFO_SUCCES",
    SET_USERid = "SET_USERid",
    USER_FOLLOW = "USER_FOLLOW",
    UPDATE_USER_RELA = "UPDATE_USER_RELA",
    UNFOLLOW = "UNFOLLOW",
    UPDATE_FOLLOWING = "UPDATE_FOLLOWING",
    COLLECTIONS_PROFILE = "COLLECTIONS_PROFILE",
    GET_MORE_PROFILE_INFO = "GET_MORE_PROFILE_INFO",
    GET_MORE_PROFILE_INFO_SUCCESS = "GET_MORE_PROFILE_INFO_SUCCESS",
    LOGIN_WITH_POPUP_RESOURCE_SUCCESS = "LOGIN_WITH_POPUP_RESOURCE_SUCCESS",
    UPDATE_PROFILE = "UPDATE_PROFILE",
}
export interface UserAction {
    type: UserTypes;
    id?: string;
    userInfo?: IUserInfo;
    userRelas?: IUserRela[];
    email?: string;
    avatar?: string;
    userRela?: IUserRela;
    userId?: string;
    followerId?: string;
    unFollow?: boolean;
    worksheets?: IWorksheet[];
    collections?: ICollection[];
    loadMore?: boolean;
    numberOfWorksheets?: number;
    totalFollower?: number;
    offset?: number;
    worksheetType?: number;
    downloadWorksheets?: IDownloadWorksheet[];
    practiceWorksheets?: IPracticeWorksheet[];
    isFollowing?: IUserRela;
    nbWorksheetInCollection?: number;
}

export const getMoreProfileInfoAction = (userId: string) => {
    return { type: UserTypes.GET_MORE_PROFILE_INFO, userId };
};

export const getMoreProfileInfoSuccessAction = (args: any): UserAction => {
    return { type: UserTypes.GET_MORE_PROFILE_INFO_SUCCESS, ...args };
};

export const initUserAction = (
    id: string,
    worksheetType: number,
    loadMore?: boolean,
    offset?: number
): UserAction => {
    return {
        type: UserTypes.GET_USER_INFO,
        id,
        worksheetType,
        loadMore,
        offset,
    };
};
export const loginWithGoogleSuccess = ({ email, name, avatar }) => {
    return {
        type: UserTypes.LOGIN_WITH_GOOGLE_SUCCESS,
        email,
        name,
        avatar,
    };
};
export const getCollectionProfileSuccessAction = (
    collections: ICollection[],
    offset: number
) => {
    return {
        type: UserTypes.COLLECTIONS_PROFILE,
        collections: collections,
        offset,
    };
};

export const getUserSuccessAction = (
    userRelas: IUserRela[],
    worksheets?: IWorksheet[],
    userInfo?: IUserInfo,
    numberOfWorksheets?: number,
    downloadWorksheets?: IDownloadWorksheet[],
    practiceWorksheets?: IPracticeWorksheet[]
): UserAction => {
    return {
        type: UserTypes.GET_USER_INFO_SUCCES,
        userRelas,
        worksheets,
        userInfo,
        numberOfWorksheets,
        downloadWorksheets,
        practiceWorksheets,
    };
};

export const followAction = (
    userId: string,
    followerId: string
): UserAction => {
    return { type: UserTypes.USER_FOLLOW, userId, followerId };
};

export const updateUserRelaAction = (
    userRela: IUserRela,
    unFollow?: boolean
): UserAction => {
    return { type: UserTypes.UPDATE_USER_RELA, userRela, unFollow };
};

export const unFollowAction = (id: string): UserAction => {
    return { type: UserTypes.UNFOLLOW, id };
};

export const updateFollowingSuccessAction = (
    isFollowing?: IUserRela
): UserAction => {
    return {
        type: UserTypes.UPDATE_FOLLOWING,
        isFollowing,
    };
};

export const userLoginWithPopupResourceSuccessAction = (
    userInfo: IUserInfo
): UserAction => {
    return {
        type: UserTypes.LOGIN_WITH_POPUP_RESOURCE_SUCCESS,
        userInfo,
    };
};
