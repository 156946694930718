import { useEffect, useState, useSyncExternalStore } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
    changeStatusIsOriginPage,
    deleteResourceItemTmp,
    updateCreateWS,
    updateRatio,
    updateSelectedText,
} from "../../redux/reducers/createWorksheet";
import MainHeader from "../../resource-user/components/main-header";
import {
    initPopupSaveWSAction,
    setLoadingAction,
    setStatusSave,
    updateWorksheetAction,
} from "../../resource-user/redux/action/wordsearch.action";
import Constants from "../../resource-user/utils/constants";
import Config from "../../shared/utils/config";
import { createWorksheet } from "../../utils/create-ws";
import Survey from "../Survey";
import undoHistoryStore from "../../syncExternalStore/undoHistoryStore";
import { EventTracking, sendEvent } from "../../resource-user/utils/event";

const Header = () => {
    const dispatch = useAppDispatch();
    const isSave = useAppSelector((state) => state.wordSearchState.isSave);
    const ratio = useAppSelector((state) => state.createWorksheetState.ratio);
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const userInfo = useAppSelector(
        (state) => state.userInfoResourceState.data
    );
    const [openSurvey, setOpenSurvey] = useState(false);

    const zoomPayload = {
        ratio,
        updateRatio: (value: number) => dispatch(updateRatio(value)),
    };

    const worksheet = useAppSelector(
        (state) => state.wordSearchState.worksheet
    );
    const wordSearchState = useAppSelector((state) => state.wordSearchState);
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState?.pagesWorksheet
    );
    const isFirstSetOriginPage = useAppSelector(
        (state) => state.createWorksheetState.isFirstSetOriginPage
    );

    const isEdited = pagesWorksheet.find((ws) => ws.isEdited === true)
        ? true
        : false;
    const undoHistoryState = useSyncExternalStore(
        undoHistoryStore.subscribe,
        undoHistoryStore.read
    );

    const isUndo =
        currentActivity?.type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;

    const handleSaveWorksheet = async (setOpenPopup?: Function) => {
        // if (setOpenPopup) {
        //     setOpenPopup(true);
        // }
        let ws = createWorksheet(
            worksheet,
            pagesWorksheet,
            "content-layer",
            ratio,
            wordSearchState,
            currentActivity?.type
        );
        if (ws) {
            dispatch(updateWorksheetAction(ws));
        }
        if (isSave) {
            dispatch(setStatusSave(false));
        }
        dispatch(setLoadingAction({ loading: true }));
        dispatch(initPopupSaveWSAction(null, Constants.MODULE_TYPE.TOOL_V2));

        // const typeSurvey = getInitTypeFromUrl();
        // if (
        //     !userInfo?.isDoneSurveyRating?.includes(typeSurvey) &&
        //     !localStorage.getItem("responseSurvey")?.includes(typeSurvey)
        // ) {
        //     setOpenSurvey(true);
        // }
    };

    useEffect(() => {
        if (pagesWorksheet?.length && isFirstSetOriginPage) {
            dispatch(changeStatusIsOriginPage());
        }
    }, [pagesWorksheet]);

    useEffect(() => {
        window.addEventListener("keydown", listenKeyDown);
        return () => {
            window.removeEventListener("keydown", listenKeyDown);
        };
    });

    const listenKeyDown = (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && isUndo) {
            if (e.key === "z") {
                e.preventDefault();
                if (e.shiftKey) {
                    redo();
                } else {
                    undo();
                }
            }
        }
    };
    const undo = () => {
        if (undoHistoryState.past.length > 0) {
            undoHistoryStore.undo({ pagesWorksheet, currentActivity });
            updateUndo();
            dispatch(deleteResourceItemTmp());
        }
    }

    const redo = () => {
        if (undoHistoryState.future.length > 0) {
            undoHistoryStore.redo();
            updateUndo();
        }
    };

    const updateUndo = () => {
        dispatch(
            updateCreateWS({
                pagesWorksheet: undoHistoryState.present.pagesWorksheet,
                currentActivity: undoHistoryState.present.currentActivity,
            })
        );
        dispatch(deleteResourceItemTmp());
        dispatch(updateSelectedText({ startOffset: -1, endOffset: -1 }));
    };

    return (
        <>
            <MainHeader
                height={52}
                zoomPayload={zoomPayload}
                handleSaveWorksheet={handleSaveWorksheet}
                typeActivity={currentActivity?.type}
                isToolV2={true}
                isEdited={isEdited}
                undoRedoPayload={
                    isUndo
                        ? {
                            canRedo: undoHistoryState.future.length > 0,
                            undoFunction: () => {
                                undo();
                                sendEvent(EventTracking.ncw_click_undo);
                            },
                            canUndo: undoHistoryState.past.length > 0,
                            redoFunction: () => {
                                redo();
                                sendEvent(EventTracking.ncw_click_redo);
                            },
                        }
                        : null
                }
            />
            <Survey openSurvey={openSurvey} setOpenSurvey={setOpenSurvey} />
        </>
    );
};

export default Header;
