import React, { useRef } from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { getStyleText } from "../../../../../utils";

const StudentInfoResource = ({
    resourceItem,
}: {
    resourceItem: IResourceItemNew;
}) => {
    const ref = useRef(null);
    return (
        <div
            style={{
                width: `${resourceItem.width}px`,
                height: `${resourceItem.height}px`,
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: `${resourceItem.width}px`,
                    height: `${resourceItem.height}px`,
                    alignItems: "center",
                }}
            >
                <div
                    ref={ref}
                    style={{
                        ...(getStyleText(resourceItem) as React.CSSProperties),
                    }}
                    id={resourceItem.id}
                >
                    {resourceItem.textAttribute.content}
                </div>
                <div
                    style={{
                        flex: 1,
                        borderBottom: "1px solid #212121",
                        marginTop: `${
                            resourceItem.height -
                            resourceItem.textAttribute.fontSize
                        }px`,
                        paddingLeft: "4px",
                    }}
                ></div>
            </div>
        </div>
    );
};
export default StudentInfoResource;
