import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import CreateWSConfig from "../../../../utils/create-ws-config";

export const scrollToItemId = (
    id: string,
    containerId: string = "input-wrapper"
) => {
    const element = document.getElementById("id" + id);
    const container: any = document.querySelector(
        `#${containerId} .simplebar-content-wrapper`
    );
    const listElements: any = document.querySelector(
        `#${containerId} .simplebar-content`
    );

    if (element && container && listElements) {
        let scroll =
            element.getBoundingClientRect().top -
            listElements.getBoundingClientRect()?.top;

        container?.scrollTo({
            top: Math.abs(scroll) - element.getBoundingClientRect().height,
            behavior: "smooth",
        });
        setTimeout(() => {
            element.focus();
        }, 50);
        return true;
    }

    return false;
};

export const getListFontFamilies = (
    currentFontStyle: number,
    currentFont?: string
) => {
    let fontFamilies =
        currentFontStyle === ConstantsTool.FONT_PRINT
            ? [...CreateWSConfig.PRINT_FONT_FAMILY]
            : [...CreateWSConfig.CURSIVE_FONT_FAMILY];

    fontFamilies.sort((a, b) => (a.name < b.name ? -1 : 1));
    const indexFont = fontFamilies.findIndex((f) => f.name === currentFont);
    if (indexFont > 0) {
        const currentFont = fontFamilies.splice(indexFont, 1);
        if (currentFont) {
            fontFamilies = [...currentFont, ...fontFamilies];
        }
    }

    return fontFamilies;
};
