import { POST } from "../../../repositories";
import { APIConfig } from "../../utils/api-config";

export const createSubmitedNotificationApi = (args: {
    fromUserId: string;
    name: string;
    teacherEmail: string;
    worksheetId: string;
    type: number;
    userPracticeId: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.CREATE_SUBMITED_NOTIFICATION,
        params: {
            fromUserId: args.fromUserId,
            name: args.name,
            teacherEmail: args.teacherEmail,
            worksheetId: args.worksheetId,
            type: args.type,
            userPracticeId: args.userPracticeId,
        },
    }).then((data: any) => {
        return data;
    });
};

export const createBonusDownloadNotificationApi = (args: {
    toUserId: string;
}): Promise<any> => {
    return POST({
        url: APIConfig.CREATE_BONUS_DOWNLOAD_NOTIFICATION,
        params: { toUserId: args.toUserId },
    }).then((data: any) => {
        return data;
    });
};
