import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IInputWord, IPuzzle } from "../../../../../shared/models/puzzle";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import Config from "../../../../../shared/utils/config";
import { updateUserAnswer } from "../../../../redux/action/game.action";
import {
    resetSvgAction,
    updateStatusWordPuzzleAction,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import {
    calcWithRatio,
    getEndCellPosition,
    getWidthHeightPuzzleCell,
    getWordsearchDrawRatio,
    randomRgba,
} from "../../../../utils";
import Constants from "../../../../utils/constants";
import "./style.scss";

export const PREFIX_LINEid = "done-";

const SvgPaintComponent = ({
    screenWidth,
    resourceItemData,
    ratioPage,
    answer,
    isCreatedByToolV2,
}: {
    screenWidth?: number;
    resourceItemData?: IResourceItemNew;
    ratioPage?: number;
    answer?: any;
    isCreatedByToolV2: any;
}) => {
    let isTablet = useMediaQuery("(max-width: 1024px)");
    let idDrawing = "draw_wordsearch";
    let idHover = "hover";
    let idSvg = Config.ID_SVG_WSE;
    let [ratio, setRatio] = useState(1);
    const dispatch = useDispatch();
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    const [isDrawing, setIsDrawing] = useState(false);
    let inputWords: IInputWord[] = useSelector(
        (state: ResourceAppState) => state.wordSearchState.inputWords
    );
    let puzzle: IPuzzle = useSelector(
        (state: ResourceAppState) => state.wordSearchState.puzzle
    );
    let resetSvg = useSelector(
        (state: ResourceAppState) => state.wordSearchState.resetSvg
    );
    const isFinish = useSelector(
        (state: ResourceAppState) => state.gameResourceState.isFinish
    );
    const practiceWorksheet = useSelector(
        (state: ResourceAppState) => state.gameResourceState.practiceWorksheet
    );

    const [isFirstTime, setIsFirstTime] = useState(true);
    let svgURI = "http://www.w3.org/2000/svg";
    const getCellPosition = (x, y) => {
        let cellX = 0;
        let cellY = 0;
        const boundingClientRect = document
            ?.querySelector("#puzzle_grid")
            ?.getBoundingClientRect();
        let widthElementTable = boundingClientRect?.width;
        let heightElementTable = boundingClientRect?.height;
        let { widthEle, heightEle } = getWidthHeightPuzzleCell();
        let nbCol = widthElementTable / widthEle;
        let nbRow = heightElementTable / heightEle;
        // console.log('x ', x , 'y ', y);

        // console.log("nbCol ", nbCol, " widthEle ", widthEle);
        // console.log("nbRow ", nbRow);
        for (let i = 0; i < nbCol; i++) {
            if (x >= widthEle * i && x <= widthEle * (i + 1)) {
                cellX = i;
                break;
            }
        }
        for (let i = 0; i < nbRow; i++) {
            if (y >= heightEle * i && y <= heightEle * (i + 1)) {
                cellY = i;
                break;
            }
        }
        // console.log("x ",
        //     cellX,
        //     cellY,
        // );

        return {
            cellX,
            cellY,
        };
    };
    const resizeSvgPaint = () => {
        let puzzleGrid = document.getElementById("puzzle_grid");
        let puzzleGridSVGPaint = document.getElementById(
            "puzzle_grid_svg_paint"
        );
        puzzleGridSVGPaint.setAttribute(
            "width",
            puzzleGrid.offsetWidth.toString()
        );
        puzzleGridSVGPaint.setAttribute(
            "height",
            puzzleGrid.offsetHeight.toString()
        );
    };
    const resetSvgPaint = () => {
        let idSvgPaint = "puzzle_grid_svg_paint";
        let svgPaint = document.getElementById(idSvgPaint);

        if (svgPaint) {
            svgPaint.innerHTML = "";
        }
        dispatch(resetSvgAction({ resetSvg: false }));
    };
    useEffect(() => {
        if (typeof window !== "undefined") {
            resizeSvgPaint();
            inputWords.forEach((word: IInputWord) => {
                if (word.userAnswer) {
                    createLineWithData({ lineData: word.userAnswer });
                }
            });
        }
        setRatio(getWordsearchDrawRatio());
    }, [puzzle, screenWidth]);
    useEffect(() => {
        if (typeof window !== "undefined" && resetSvg) {
            resetSvgPaint();
        }
    }, [resetSvg]);

    useEffect(() => {
        if (
            typeof window !== "undefined" &&
            Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.CLASSROOM &&
            isFirstTime
        ) {
            setTimeout(() => {
                updatePraticteData();
                setIsFirstTime(false);
            }, 1000);
        }
    }, [practiceWorksheet.userAnswer]);

    const updatePraticteData = () => {
        let userAnswer = practiceWorksheet.userAnswer;
        if (userAnswer && userAnswer.length) {
            if (userAnswer[0].userAnswer) {
                let listLineData = JSON.parse(
                    userAnswer[0].userAnswer
                ).listLine;
                for (let line of listLineData) {
                    createLineWithData({ lineData: line });
                }
            }
        }
    };

    const checkStartPoint = (e) => {
        // const x =
        //     e?.clientX -
        //     e.currentTarget.getBoundingClientRect().left -
        //     Math.SQRT2;
        // const y =
        //     e?.clientY -
        //     e.currentTarget.getBoundingClientRect().top -
        //     Math.SQRT2;

        createLineElement({ event: e });
    };
    const checkEndPoint = (e) => {
        // const x =
        //     e?.clientX -
        //     e.currentTarget.getBoundingClientRect().left -
        //     Math.SQRT2;
        // const y =
        //     e?.clientY -
        //     e.currentTarget.getBoundingClientRect().top -
        //     Math.SQRT2;
        onMouseUpHandler(e);
    };

    const onMouseUpHandler = (event: any) => {
        let svgItem = document.querySelector(
            '[id="draw_wordsearch"]'
        ) as HTMLElement;
        const { widthEle } = getWidthHeightPuzzleCell();

        if (svgItem && widthEle) {
            let word = "";
            let exp = false;
            const startCellX = Number(svgItem.getAttribute("startCellX"));
            const startCellY = Number(svgItem.getAttribute("startCellY"));
            const endCellX = Number(svgItem.getAttribute("endCellX"));
            const endCellY = Number(svgItem.getAttribute("endCellY"));
            let id = `${PREFIX_LINEid}${startCellX}-${startCellY}`;
            let findSvgItem = document.querySelector(
                `[id="${id}"]`
            ) as HTMLElement;
            if (!findSvgItem) {
                id = `${PREFIX_LINEid}${endCellX}-${endCellY}`;
                findSvgItem = document.querySelector(
                    `[id="${id}"]`
                ) as HTMLElement;
            }
            for (let inputWord of inputWords) {
                const startCell = inputWord.startCell;
                const wordLength = inputWord.word.length - 1;
                const direction = inputWord.direction;
                const endCellCorrect = getEndCellPosition({
                    startX: startCell[0],
                    startY: startCell[1],
                    direction,
                    wordLength,
                });

                if (
                    // startCell === [startCellX, startCellY] &&
                    // endCell === [endCellX, endCellY]
                    (startCell[0] === startCellX &&
                        startCell[1] === startCellY &&
                        endCellCorrect[0] === endCellX &&
                        endCellCorrect[1] === endCellY) ||
                    (startCell[0] === endCellX &&
                        startCell[1] === endCellY &&
                        endCellCorrect[0] === startCellX &&
                        endCellCorrect[1] === startCellY)
                ) {
                    word = inputWord.word;
                    exp = true;
                    break;
                }
            }

            if (findSvgItem) {
                svgItem?.parentNode?.removeChild(svgItem);
            } else if (exp) {
                const { widthEle, heightEle } = getWidthHeightPuzzleCell();
                const x2 = calcWithRatio(
                    widthEle * endCellX + widthEle / 2,
                    ratioPage
                );
                const y2 = calcWithRatio(
                    heightEle * endCellY + heightEle / 2,
                    ratioPage
                );
                svgItem.setAttribute("x2", x2.toString());
                svgItem.setAttribute("y2", y2.toString());
                svgItem.setAttribute("id", id);
                const newLine = {
                    id: id,
                    x1: parseInt(svgItem.getAttribute("x1")) / ratio,
                    y1: parseInt(svgItem.getAttribute("y1")) / ratio,
                    startCellX: startCellX,
                    startCellY: startCellY,
                    x2: x2 / ratio,
                    y2: y2 / ratio,
                    endCellX: endCellX,
                    endCellY: endCellY,
                    color: svgItem.style.stroke,
                };
                dispatch(
                    updateStatusWordPuzzleAction({
                        inputWord: word,
                        userAnswer: newLine,
                    })
                );
                if (answer) {
                    let newInputWords = JSON.parse(JSON.stringify(inputWords));

                    for (let newInputWord of newInputWords) {
                        if (newInputWord.word === word) {
                            newInputWord.status = Config.STATUS_PUZZLE.FOUND;
                        }
                    }
                    let userAnswerData = practiceWorksheet.userAnswer;
                    let newListLine = [];
                    if (userAnswerData && userAnswerData.length) {
                        let userAnswer = userAnswerData[0].userAnswer;
                        newListLine = userAnswer
                            ? JSON.parse(userAnswer).listLine
                            : [];
                    }
                    newListLine.push(newLine);
                    let newAnswer = JSON.parse(JSON.stringify(answer));
                    newAnswer.userAnswer = JSON.stringify({
                        inputWords: newInputWords,
                        listLine: newListLine,
                    });
                    dispatch(updateUserAnswer(newAnswer));
                }
            } else {
                svgItem.appendChild(document.createElement("div"));
                svgItem.remove();
            }
        }
    };
    const onMouseMoveHandler = (event: any): void => {
        let svgContainer = document.querySelector(`#${idSvg}`);
        let svgItem = document.querySelector('[id="draw_wordsearch"]');

        if (svgContainer && svgItem) {
            let rect = svgContainer.getBoundingClientRect();
            let offsetX;
            let offsetY;
            offsetX = calcWithRatio(event.clientX - rect.left, 1);
            offsetY = calcWithRatio(event.clientY - rect.top, 1);
            let { cellX, cellY } = getCellPosition(offsetX, offsetY);
            svgItem.setAttribute("x2", (offsetX / ratioPage).toString());
            svgItem.setAttribute("y2", (offsetY / ratioPage).toString());
            svgItem.setAttribute("endCellX", cellX.toString());
            svgItem.setAttribute("endCellY", cellY.toString());
        }
    };

    const handleTouchActionWhenSearchWord = () => {
        let svgContainer = document.querySelector(`#${idSvg}`);
        let svgItem = document.querySelector('[id="draw_wordsearch"]');
        let wordSearchByToolContainer: HTMLElement =
            document.querySelector(".game-container");

        if (svgContainer && svgItem) {
            wordSearchByToolContainer.style.overflowY = "hidden";
        } else {
            wordSearchByToolContainer.style.overflowY = "auto";
        }
    };

    const createLineWithData = ({
        lineData,
        color,
    }: {
        lineData: any;
        color?: string;
    }) => {
        let line = document.querySelector(`#${lineData.id}`);
        if (line) {
            return;
        }
        let svgItem = document.createElementNS(svgURI, "line") as HTMLElement;

        color = color ?? randomRgba({ opacity: 0.3 });
        let { widthEle, heightEle } = getWidthHeightPuzzleCell();
        let strokeWidth =
            heightEle - Constants.STROKE.WIDTH * 2 - 2 * Math.SQRT2;
        if (Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.TOOL_V2) {
            strokeWidth = calcWithRatio(strokeWidth, ratioPage);
        }
        svgItem.style.strokeWidth = strokeWidth.toString();
        svgItem.style.strokeLinecap = "round";
        svgItem.style.stroke = lineData.color ? lineData.color : color;
        let newRatio = getWordsearchDrawRatio();
        const x1 = lineData.x1 * newRatio;
        const y1 = lineData.y1 * newRatio;
        svgItem.setAttribute("id", lineData.id);
        svgItem.setAttribute("x1", x1.toString());
        svgItem.setAttribute("y1", y1.toString());
        svgItem.setAttribute("startCellX", lineData.startCellX.toString());
        svgItem.setAttribute("startCellY", lineData.startCellY.toString());
        svgItem.setAttribute("x2", (lineData.x2 * newRatio).toString());
        svgItem.setAttribute("y2", (lineData.y2 * newRatio).toString());
        svgItem.setAttribute("endCellX", lineData.endCellX.toString());
        svgItem.setAttribute("endCellY", lineData.endCellY.toString());
        let svg = document.querySelector(`#${idSvg}`);
        if (svg) {
            svg.appendChild(svgItem);
        }

        return null;
    };

    const createLineElement = ({
        event,
        hoverWord,
        color,
    }: {
        event: any;
        hoverWord?: boolean;
        color?: string;
    }) => {
        if (isFinish) {
            return;
        }
        let svgItem = document.createElementNS(svgURI, "line") as HTMLElement;
        let rect = document.querySelector(`#${idSvg}`)?.getBoundingClientRect();
        if (rect) {
            let offsetX = 0;
            let offsetY = 0;

            offsetX = event.clientX - rect.left;
            offsetY = event.clientY - rect.top;
            let { widthEle, heightEle } = getWidthHeightPuzzleCell();
            let { cellX, cellY } = getCellPosition(offsetX, offsetY);

            try {
                const value = puzzle.puzzleArrNumber[cellY][cellX];
                if (value == 0) {
                    return;
                }
            } catch (e) {
                console.log("Error:", e);
            }

            const id = hoverWord ? idHover : idDrawing;

            let findHoverElement = document.querySelector(
                `#${idHover}`
            ) as HTMLElement;
            if (findHoverElement) {
                if (!hoverWord) {
                    let stoke = findHoverElement?.style?.stroke ?? "";
                    if (stoke) {
                        color = stoke;
                    }
                    findHoverElement.remove();
                }
            }
            color = color ?? randomRgba({ opacity: 0.3 });

            svgItem.style.strokeWidth = calcWithRatio(
                heightEle - Constants.STROKE.WIDTH * 2 - 2 * Math.SQRT2,
                ratioPage
            ).toString();
            svgItem.style.strokeLinecap = "round";
            svgItem.style.stroke = color;
            svgItem.setAttribute("id", id);

            document.querySelector(`#${idSvg}`).appendChild(svgItem);

            const x1 = (widthEle * cellX + widthEle / 2) / ratioPage;
            const y1 = (heightEle * cellY + heightEle / 2) / ratioPage;

            svgItem.setAttribute("x1", x1.toString());
            svgItem.setAttribute("y1", y1.toString());
            svgItem.setAttribute("startCellX", cellX.toString());
            svgItem.setAttribute("startCellY", cellY.toString());

            svgItem.setAttribute("x2", x1.toString());
            svgItem.setAttribute("y2", y1.toString());

            // svgItem.setAttribute("endCellX", endCell.cellX.toString());
            // svgItem.setAttribute("endCellY", endCell.cellY.toString());
        }

        return null;
    };
    const changeCursor = (event: any) => {
        if (isFinish) {
            event.target.style.cursor = "default";
        } else if (event.target.tagName === "svg") {
            event.target.style.cursor =
                "url(https://storage.googleapis.com/worksheetzone/icons/cursor.png),default";
        }
        // let currentCursorPos;
        // let cursorEl: any = document.querySelector("#cursor");
        // let rect = document.querySelector(`#${idSvg}`)?.getBoundingClientRect();
        // let offsetX = event.clientX - rect.left;
        // let offsetY = event.clientY - rect.top;
        // console.log(" event.clientX", offsetX, offsetY);
        // currentCursorPos = { x: event.clientX, y: event.clientY };
        // cursorEl.style.position = `absolute`;
        // cursorEl.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
    };
    const hoverWord = (event: any) => {
        let rect = document.querySelector(`#${idSvg}`)?.getBoundingClientRect();
        if (rect) {
            let offsetX = 0;
            let offsetY = 0;
            offsetX = event.clientX - rect.left;
            offsetY = event.clientY - rect.top;

            const { cellX, cellY } = getCellPosition(offsetX, offsetY);
            let svgItem = document.querySelector(`#${idHover}`) as HTMLElement;
            // let svgItem = document.querySelector(`#${idHover + cellX + cellY}`);
            let color = randomRgba({ opacity: 0.3 });
            if (svgItem) {
                const startCellX = Number(
                    svgItem?.getAttribute("startCellX") ?? 0
                );
                const startCellY = Number(
                    svgItem?.getAttribute("startCellY") ?? 0
                );

                if (startCellX != cellX || startCellY != cellY) {
                    svgItem.parentNode?.removeChild(svgItem);
                }
            } else {
                createLineElement({ event, hoverWord: true, color: color });
            }
        }
    };

    return (
        <svg
            className="svg-puzzle-paint-container"
            style={{
                // cursor: "url(/images/icon/cursor.png),url(/images/icon/cursor.png), default",
                cursor: "none",
                top: isCreatedByToolV2 ? 0 : "default",
                // touchAction: isTablet ? "none" : "auto",
                // zIndex: 20,
                // width: resourceItemData?.width + "px",
                // height: resourceItemData?.height + "px",
            }}
            id={idSvg}
            onMouseMoveCapture={(e) => {
                if (!isDrawing && !isTablet) {
                    hoverWord(e);
                }
            }}
            onMouseEnter={(e) => {
                changeCursor(e);
            }}
            onMouseDown={(e) => {
                setIsDrawing(true);
                checkStartPoint(e);
            }}
            onMouseUp={(e) => {
                checkEndPoint(e);
                setIsDrawing(false);
            }}
            onMouseMove={(e) => {
                onMouseMoveHandler(e);
            }}
            onMouseOut={(e: any) => {
                const element = document.querySelector(
                    `#${idSvg}`
                ) as HTMLElement;
                if (!element.contains(e.relatedTarget)) {
                    let svgItem = document.querySelector(
                        '[id^="draw_wordsearch"]'
                    ) as HTMLElement;
                    svgItem && svgItem.remove();
                    let svgItemHover = document.querySelector(
                        `#${idHover}`
                    ) as HTMLElement;
                    svgItemHover && svgItemHover.remove();
                }
            }}
            onTouchStart={(e) => {
                if (e.touches.length === 1) {
                    setIsDrawing(true);
                    checkStartPoint(e.changedTouches[0]);
                    handleTouchActionWhenSearchWord();
                } else {
                    // let svgItem = document.querySelector(
                    //     '[id="draw_wordsearch"]'
                    // ) as HTMLElement;
                    // if (svgItem) {
                    //     svgItem.remove();
                    // }
                }
            }}
            onTouchEnd={(e) => {
                checkEndPoint(e.changedTouches[0]);
                setIsDrawing(false);
                handleTouchActionWhenSearchWord();
                // let svgItem = document.querySelector(
                //     '[id="draw_wordsearch"]'
                // ) as HTMLElement;
                // console.log(svgItem);

                // if (svgItem) svgItem.remove();
            }}
            onTouchMove={(e) => {
                // e.preventDefault();
                if (e.touches.length === 1) {
                    onMouseMoveHandler(e.changedTouches[0]);
                }
            }}
        ></svg>
    );

    return inputWords.length ? (
        <>
            <>
                <svg
                    className="svg-puzzle-paint-container"
                    style={{
                        // cursor: "url(/images/icon/cursor.png),url(/images/icon/cursor.png), default",
                        cursor: "none",
                        top: isCreatedByToolV2 ? 0 : "default",
                        // touchAction: isTablet ? "none" : "auto",
                        // zIndex: 20,
                        // width: resourceItemData?.width + "px",
                        // height: resourceItemData?.height + "px",
                    }}
                    id={idSvg}
                    onMouseMoveCapture={(e) => {
                        if (!isDrawing && !isTablet) {
                            hoverWord(e);
                        }
                    }}
                    onMouseEnter={(e) => {
                        changeCursor(e);
                    }}
                    onMouseDown={(e) => {
                        setIsDrawing(true);
                        checkStartPoint(e);
                    }}
                    onMouseUp={(e) => {
                        checkEndPoint(e);
                        setIsDrawing(false);
                    }}
                    onMouseMove={(e) => {
                        onMouseMoveHandler(e);
                    }}
                    onMouseOut={(e: any) => {
                        const element = document.querySelector(
                            `#${idSvg}`
                        ) as HTMLElement;
                        if (!element.contains(e.relatedTarget)) {
                            let svgItem = document.querySelector(
                                '[id^="draw_wordsearch"]'
                            ) as HTMLElement;
                            svgItem && svgItem.remove();
                            let svgItemHover = document.querySelector(
                                `#${idHover}`
                            ) as HTMLElement;
                            svgItemHover && svgItemHover.remove();
                        }
                    }}
                    onTouchStart={(e) => {
                        if (e.touches.length === 1) {
                            setIsDrawing(true);
                            checkStartPoint(e.changedTouches[0]);
                            handleTouchActionWhenSearchWord();
                        } else {
                            // let svgItem = document.querySelector(
                            //     '[id="draw_wordsearch"]'
                            // ) as HTMLElement;
                            // if (svgItem) {
                            //     svgItem.remove();
                            // }
                        }
                    }}
                    onTouchEnd={(e) => {
                        checkEndPoint(e.changedTouches[0]);
                        setIsDrawing(false);
                        handleTouchActionWhenSearchWord();
                        // let svgItem = document.querySelector(
                        //     '[id="draw_wordsearch"]'
                        // ) as HTMLElement;
                        // console.log(svgItem);

                        // if (svgItem) svgItem.remove();
                    }}
                    onTouchMove={(e) => {
                        // e.preventDefault();
                        if (e.touches.length === 1) {
                            onMouseMoveHandler(e.changedTouches[0]);
                        }
                    }}
                ></svg>
                {/* <div id="cursor">
                    <img alt="Cursor Arrow" src="/images/icon/cursor.png" />
                </div> */}
            </>
        </>
    ) : (
        <React.Fragment></React.Fragment>
    );
};

export default SvgPaintComponent;
