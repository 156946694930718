import { POST } from "./index";
import { Game, IGame } from "../../../shared/models/game";
import {
    IDrawData,
    IPracticeWorksheet,
} from "../../../shared/models/practiceWorksheets";
import { APIConfig } from "../../utils/api-config";

export const getGameInfoApi = (): Promise<IGame | null> => {
    return POST({
        url: APIConfig.GET_WORKSHEET_INFO,
    }).then((data: any) => {
        return data && new Game(data);
    });
};

export const updateCategoryScore = (args: {
    uuId: string;
    categories: Array<any>;
}) => {
    // return POST({
    //     url: APIConfig.UPDATE_CATEGORY_SCORE,
    //     params: args,
    // }).then((data: any) => {
    //     return data && new Game(data);
    // });
};

export const updateUserDownloadedWorksheetApi = (args: {
    token: string;
    worksheetIdList: string[];
}): Promise<IGame | null> => {
    return POST({
        url: APIConfig.UPDATE_DOWNLOADED_WORKSHEET,
        params: args,
    }).then((data: any) => {
        return data;
    });
};
export const updateUserPracticeWorksheetApi = (args: {
    token: string;
    worksheetId: string;
    drawData: IDrawData;
    score: number;
}): Promise<any | null> => {
    return POST({
        url: APIConfig.UPDATE_PRACTICE_WORKSHEET,
        params: args,
    }).then((data: any) => {
        return data;
    });
};

export const updateUserPracticeWorksheetV2Api = (
    practiceWorksheet: IPracticeWorksheet
): Promise<any | null> => {
    return POST({
        url: APIConfig.UPDATE_PRACTICE_WORKSHEET_V2,
        params: practiceWorksheet,
    }).then((data: any) => {
        return data;
    });
};

export const getPracticeWorksheetByIdApi = (args: {
    userId?: string;
    uuId?: string;
    worksheetId: string;
    assignmentId?: string;
    userPracticeId?: string;
}): Promise<IPracticeWorksheet | null> => {
    return POST({
        url: APIConfig.GET_PRACTICE_WORKSHEET_BYid,
        params: args,
    })
        .then((data: IPracticeWorksheet) => {
            return data;
        })
        .catch((e) => {
            // console.log(e);
        });
};
