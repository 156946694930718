import React from "react";

const CheckBoxWsIconSvg = ({ selected }: { selected?: boolean }) => {
    if (selected)
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="6"
                    fill="rgba(var(--color), 1)"
                />
                <path
                    d="M17.0326 8.4074C16.9629 8.3371 16.8799 8.2813 16.7885 8.24323C16.6971 8.20515 16.5991 8.18555 16.5001 8.18555C16.4011 8.18555 16.303 8.20515 16.2117 8.24323C16.1203 8.2813 16.0373 8.3371 15.9676 8.4074L10.3801 14.0024L8.03258 11.6474C7.96019 11.5775 7.87474 11.5225 7.7811 11.4856C7.68745 11.4487 7.58746 11.4306 7.48683 11.4323C7.38619 11.4341 7.28688 11.4556 7.19457 11.4957C7.10227 11.5358 7.01876 11.5938 6.94883 11.6661C6.87891 11.7385 6.82392 11.824 6.78702 11.9176C6.75011 12.0113 6.73202 12.1113 6.73376 12.2119C6.7355 12.3125 6.75704 12.4118 6.79716 12.5042C6.83728 12.5965 6.89519 12.68 6.96758 12.7499L9.84758 15.6299C9.91731 15.7002 10.0003 15.756 10.0917 15.7941C10.183 15.8321 10.2811 15.8517 10.3801 15.8517C10.4791 15.8517 10.5771 15.8321 10.6685 15.7941C10.7599 15.756 10.8429 15.7002 10.9126 15.6299L17.0326 9.5099C17.1087 9.43966 17.1695 9.35443 17.211 9.25955C17.2526 9.16468 17.274 9.06222 17.274 8.95865C17.274 8.85507 17.2526 8.75262 17.211 8.65774C17.1695 8.56287 17.1087 8.47763 17.0326 8.4074Z"
                    fill="white"
                />
            </svg>
        );
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CheckBoxWsIconSvg;
