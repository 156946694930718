import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  updateResourceItem
} from "../../../redux/reducers/createWorksheet";
import { getTextWidthHeightTool } from "../../../utils/draw";
// import { updateCellPxAction } from "../../../resource-user/redux/action/wordsearch.action";

const ListenCrossWord = () => {
  const dispatch = useAppDispatch();

  const pagesWorksheet = useAppSelector(
    (state) => state.createWorksheetState.pagesWorksheet
  );
  const crossWordTitle = useAppSelector(
    (state) => state.crosswordState.title
  );
  const [isStarted, setIsStarted] = useState(false)

  useEffect(() => {
    if (pagesWorksheet[0]?.resourceItems?.length > 0 && crossWordTitle) {
      let eleTitleArr = pagesWorksheet[0].resourceItems.filter((e) =>
        e.id?.includes("ws_title_")
      );
      if (eleTitleArr.length > 0) {
        let eleTitle = eleTitleArr[0];
        let isGenerator = eleTitle.idType?.includes("ws_generator");
        let textSize = getTextWidthHeightTool({
          textValue: crossWordTitle,
          includePadding: true,
          fontSize: eleTitle.textAttribute?.fontSize,
          fontFamily: eleTitle.textAttribute?.fontFamily,
          maxWidth: isGenerator ? null : eleTitle.width,
        });
        dispatch(
          updateResourceItem({
            currentElementId: eleTitle.id,
            pageIndex: 0,
            attrValues: [
              {
                attr: "textAttribute",
                value: {
                  ...eleTitle.textAttribute,
                  content: crossWordTitle,
                },
              },
              {
                attr: "height",
                value: textSize.height,
              },
            ],
            isChangeEidtPage: isStarted,
            isAddHistory: isStarted,
          })
        );
      }
    }
    if (!isStarted) {
      setIsStarted(true)
    }
  }, [crossWordTitle]);
  return null;
};

export default ListenCrossWord;
