import { checkIsToolV1Project } from "./../../../shared/utils/index";
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    take,
    takeLatest,
} from "redux-saga/effects";
import { Answer, ANSWER_TYPE } from "../../../shared/models/answer";
import { Game, ScreenSize } from "../../../shared/models/game";

import ConstantsResource from "../../../resource-user/utils/ConstantsResource";
import { Collection } from "../../../shared/models/collection";
import {
    IInputWord,
    InputWord,
    IPuzzle,
    Puzzle,
} from "../../../shared/models/puzzle";
import { Worksheet } from "../../../shared/models/worksheet";
import { getTitleWsFromProjectName } from "../../../shared/utils";
import ClassroomConfig from "../../../shared/utils/classroom";
import Config from "../../../shared/utils/config";
import { ConfigWordSearch } from "../../../shared/utils/configWordSearch";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import {
    addRemoveClassListHide,
    changeElementStyle,
    checkHideInitWordSearchAnswer,
    checkIsTester,
    copyTextToClipboard,
    genDisplay,
    getDirection,
    getDirectionRestrictions,
    getEndCellPosition,
    getOriginUrlFromCurrentActivity,
    getSizeFromShape,
    isProdWeb,
    urlWorksheet,
} from "../../utils";
import Constants from "../../utils/constants";
import {
    convertImagesToBase64,
    convertImgToBase64,
} from "../../utils/convertImg";
import { getSizeScreenWordSearch } from "../../utils/wordsearch.utils";
import { updateUserAnswer } from "../action/game.action";
import {
    selectActionGridItemAction,
    selectGridItemAction,
    updateCollectionAction,
} from "../action/resource.action";
import { setMessageAction } from "../action/snackbar.action";
import {
    changePuzzleConfigAction,
    changeStatusAlertSave,
    changeWarningRequired,
    enterNewWordAction,
    getSuggestTagsDataSuccess,
    initWordSearchGameSuccessAction,
    IWordSearchAction,
    nextActionWordSearchAction,
    openPopupAction,
    openSurveyAction,
    resetNextAction,
    saveMoreInfoWorksheet,
    saveWorksheetId,
    saveWorksheetImagesAction,
    setCallbackSharedAction,
    setErrorWords,
    setImageAnswer,
    setImageSource,
    setKeywordGPTAction,
    setLoadingAction,
    setStatusSave,
    updateAutoPasteInputAction,
    updatePuzzleAction,
    updateStatusDownloadImages,
    updateTempPuzzleAction,
    updateWorksheetCreatedByWordSearchAction,
    WordSearchTypes,
} from "../action/wordsearch.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import { IErrorWord, IWordsearchState } from "../reducer/wordSearch.reducer";
import {
    getGPTword,
    getSuggestTagsDataAPI,
    updateWorksheetCreatedByWordSearchAPI,
    uploadImageAPI,
} from "../repositories/workSheet.repositories";
import { IWorksheet } from "./../../../shared/models/worksheet";
import {
    getSourceUserBehaviors,
    getUseInteractiveElement,
    modifyWsBaseOnEditModeFromUrl,
} from "../../utils/worksheet.utils";
const changeStyleWordFound = (moduleType: string, value: string) => {
    if (moduleType === Constants.MODULE_TYPE.TOOL_V2) {
        changeElementStyle({
            prefixId: Config.ELEMENT_WORD_FOUND_TOOL_V2,
            property: "textDecoration",
            value: value,
        });
    } else {
        changeElementStyle({
            className: ConstantsResource.PREFIX_WORD_FOUND,
            property: "textDecoration",
            value: value,
        });
    }
};

const changeStyleCrossword = (showAnswer: boolean, clearStyle?: boolean) => {
    if (clearStyle) {
        changeElementStyle({
            className: ConstantsTool.LINE_THROUGH,
            property: "textDecoration",
            value: "unset",
        });
        changeElementStyle({
            className: "crossword_value",
            value: genDisplay(showAnswer),
        });
        changeElementStyle({
            className: "crossword_user_type",
            value: genDisplay(false),
        });
    } else {
        changeElementStyle({
            className: ConstantsTool.LINE_THROUGH,
            property: "textDecoration",
            value: "text-decoration",
        });
        changeElementStyle({
            className: "crossword_value",
            value: genDisplay(!showAnswer),
        });
        changeElementStyle({
            className: "crossword_user_type",
            value: genDisplay(true),
        });
    }
};

const changeStyleScramble = (showAnswer: boolean, clearStyle?: boolean) => {
    if (clearStyle) {
        changeElementStyle({
            className: "scramble-input",
            value: genDisplay(showAnswer),
        });
        changeElementStyle({
            className: "user-scramble-input",
            value: genDisplay(!showAnswer),
        });
    } else {
        changeElementStyle({
            className: "scramble-input",
            value: genDisplay(!showAnswer),
        });
        changeElementStyle({
            className: "user-scramble-input",
            value: genDisplay(showAnswer),
        });
    }
};

function* initWordSearchSaga(action: IWordSearchAction) {
    let { worksheetId, worksheet, isAddTemplate } = action;
    const ratioAction = action.ratio;
    const projectName = yield select(
        (state: any) => state.gameResourceState.playWorksheetInfo.projectName
    );
    const practiceWorksheet = yield select(
        (state: ResourceAppState) => state.gameResourceState.practiceWorksheet
    );
    const projectNameTool = yield select(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    const isFinish = practiceWorksheet.isFinish;

    let puzzle;
    let inputWords;
    if (!worksheet) {
        worksheet = new Worksheet({
            title: "",
        });
    } else {
        worksheet = new Worksheet(worksheet);
    }
    // let newWorksheet: IWorksheet = new Worksheet({
    //     title: "My Word Search Title",
    // });
    const params = new URLSearchParams(window.location.search);
    const isEditBase = params.get("isEditBase");
    let onEditTitleWs = false;

    const authorId = yield select(
        (state: ResourceAppState) => state?.wordSearchState?.worksheet?.authorId
    );
    if (
        (!isEditBase || isEditBase?.length == 0) &&
        worksheet.authorId !== authorId
    ) {
        delete worksheet?.id;
        delete worksheet?.authorId;
        delete worksheet?.author;
    }

    if (isAddTemplate) {
        // Thêm template thì giữ nguyên worksheetId
        const worksheetId = yield select(
            (state: ResourceAppState) => state?.wordSearchState?.worksheet?.id
        );
        if (worksheetId) {
            worksheet.id = worksheetId;
        }
    }

    if (worksheet?.game?.wordSearchCreator) {
        puzzle = new Puzzle(worksheet.game.wordSearchCreator);
        puzzle = JSON.parse(JSON.stringify(puzzle));
        puzzle.puzzleArrChar =
            worksheet?.game?.answers[0]?.wordSearchValue ?? [];
        if (worksheet.game.wordSearchCreator?.title !== worksheet.title) {
            onEditTitleWs = true;
        }
        if (puzzle.puzzleSize?.includes("_")) {
            let arr = puzzle.puzzleSize.split("_");
            puzzle.puzzleWidth = parseInt(arr[0]);
            puzzle.puzzleHeight = parseInt(arr[1]);
        } else {
            const { puzzleShape, puzzleSize } = puzzle;
            const PUZZLE_SHAPE_ARRAY = Constants.PUZZLE_SHAPE_ARRAY;
            const currentShapeConfig = PUZZLE_SHAPE_ARRAY.find(
                (shape) =>
                    shape.shape === puzzleShape && shape.size === puzzleSize
            );
            if (currentShapeConfig) {
                puzzle.puzzleWidth = parseInt(currentShapeConfig.width);
                puzzle.puzzleHeight = parseInt(currentShapeConfig.height);
            }
        }
        if (worksheet.game.answers[0]?.value) {
            // console.log(
            //     "worksheet.game.answers[0].value ",
            //     worksheet.game.answers[0].value
            // );
            for (let index = 0; index < puzzle.puzzleArrChar.length; index++) {
                puzzle.puzzleArrNumber[index] = [];
                for (let j = 0; j < puzzle.puzzleArrChar[index].length; j++) {
                    if (puzzle.puzzleArrChar[index][j] === "") {
                        puzzle.puzzleArrNumber[index][j] = 0;
                    } else {
                        puzzle.puzzleArrNumber[index][j] = 1;
                    }
                }
            }
            inputWords = JSON.parse(worksheet.game.answers[0].value)
                .map((e) => new InputWord(e))
                .filter((e) => {
                    if (e?.startCell?.length > 0) {
                        return e;
                    }
                });

            inputWords.forEach((inputWord) => {
                if (checkHideInitWordSearchAnswer(projectName)) {
                    let userAnswers = practiceWorksheet.userAnswer;
                    if (
                        userAnswers &&
                        userAnswers.length &&
                        userAnswers[0].userAnswer
                    ) {
                        let inputWordPractice = JSON.parse(
                            userAnswers[0].userAnswer
                        ).inputWords;

                        for (let input of inputWordPractice) {
                            if (input.word === inputWord.word) {
                                inputWord.status = input.status;
                            }
                        }
                    } else {
                        inputWord.status = -1;
                    }
                }
                addInputWord(
                    inputWord,
                    puzzle,
                    inputWord.word,
                    1,
                    inputWord.wordListText,
                    inputWord.direction,
                    inputWord.startCell[0],
                    inputWord.startCell[1]
                );
            });
            // console.log("inputWords ", inputWords);
            //gen array puzzleArrNumber

            // console.log("inputWords ", inputWords);
            //gen array puzzleArrNumber
        }
    }
    if (!puzzle) {
        puzzle = new Puzzle({
            puzzleShape: "Square",
            puzzleSize: "Small",
            wordDirections: 0,
            puzzleFont: "Tahoma",
            letterSpacing: "Normal",
            wordListOrder: "Alphabetized",
            showAnswerKey: Config.HIDE_VALUE,
            showWordDirection: Config.SHOW_VALUE,
            title: getTitleWsFromProjectName(projectNameTool),
        });

        puzzle = genPuzzleShape(puzzle);
        let cellPx = calcCellPx(puzzle);
        puzzle.puzzleCellPx = cellPx;
    }
    // if(!puzzle.puzzleCellPx){
    //     puzzle.puzzleCellPx = ConstantsResource.PUZZLE_CELL_PX
    // }
    if (checkHideInitWordSearchAnswer(projectName) && !isFinish) {
        puzzle.showAnswerKey = 0;
    }
    if (isFinish) {
        puzzle.showAnswerKey = 1;
    }
    const { width, height, ratio } = getSizeScreenWordSearch(
        window.innerHeight
    );
    yield put(
        initWordSearchGameSuccessAction({
            puzzle,
            width,
            height,
            ratio: ratioAction ?? ratio,
            inputWords,
            worksheet,
            onEditTitleWs,
        })
    );
}

export const getSizePuzzle = (puzzle, ratioWS) => {
    let objSize = getSizeFromShape(
        puzzle.puzzleArrNumber.length,
        puzzle.puzzleArrNumber[0].length,
        ratioWS,
        puzzle.puzzleCellPx
    );
    const MAX_HEIGHT = ConstantsTool.MAX_HEIGHT_WORD_SEARCH;
    let puzzleCellPx = ConstantsResource.PUZZLE_CELL_PX;
    if (objSize.totalHeight > MAX_HEIGHT) {
        puzzleCellPx = MAX_HEIGHT / puzzle.puzzleArrNumber.length;
        objSize = getSizeFromShape(
            puzzle.puzzleArrNumber.length,
            puzzle.puzzleArrNumber[0].length,
            ratioWS,
            puzzleCellPx
        );
    }
    return { ...objSize, puzzleCellPx };
};

function* enterNewWordSaga(action: IWordSearchAction) {
    let { words, autoPaste } = action;
    const inputWordsReducer = yield select(
        (state: any) => state.wordSearchState.inputWords
    );
    const puzzleReducer = yield select(
        (state: any) => state.wordSearchState.puzzle
    );
    const inputWords = JSON.parse(JSON.stringify(inputWordsReducer));
    const puzzleTemp = JSON.parse(JSON.stringify(puzzleReducer));
    const puzzle = new Puzzle(puzzleTemp);
    let newInputWords: IInputWord[] = [];
    words = words.map((word) => word?.split(" ")?.join("").toLocaleUpperCase());

    const inputWordsStr = inputWords.map((e) => e.word);
    const intersection = inputWordsStr.filter((value) => words.includes(value));
    const needRemoveWords = inputWordsStr.filter(
        (word) => !intersection.includes(word)
    );
    const needSetWords = words.filter((word) => !intersection.includes(word));

    newInputWords = inputWords.filter((e) => intersection.includes(e.word));
    for (let i = 0; i < needRemoveWords.length; i++) {
        let inputWord = inputWords.filter(
            (e) => e.word == needRemoveWords[i]
        )[0];
        removeWord(inputWord, puzzle);
    }
    let allSpot = getAllSpot(puzzle);
    for (let i = 0; i < needSetWords.length; i++) {
        let inputWord = new InputWord({ word: needSetWords[i] });
        setWord(inputWord, puzzle, allSpot);
        newInputWords.push(inputWord);
    }

    let notOnGridWords = newInputWords.filter((e) => {
        return e.onGrid == 0;
    });
    let onGridWords = newInputWords.filter((e) => {
        return e.onGrid == 1;
    });
    if (notOnGridWords.length > 0) {
        const errorWords: IErrorWord[] = [];
        for (let index = 0; index < newInputWords.length; index++) {
            if (newInputWords[index].onGrid == 0) {
                errorWords.push({
                    index,
                    value: newInputWords[index]?.word ?? "",
                });
            }
        }
        yield put(updatePuzzleAction(puzzle, onGridWords));
        if (autoPaste) {
            yield put(updateAutoPasteInputAction(onGridWords));
        } else {
            // auto change size to fit notOnGridWords
            if (puzzle.puzzleSize == "Small") {
                let newPuzzle = new Puzzle({
                    ...puzzle,
                    puzzleSize: "Medium",
                });
                yield put(changePuzzleConfigAction(newPuzzle));
                yield put(enterNewWordAction(words, autoPaste));
            } else if (puzzle.puzzleSize == "Medium") {
                let newPuzzle = new Puzzle({
                    ...puzzle,
                    puzzleSize: "Large",
                });
                yield put(changePuzzleConfigAction(newPuzzle));
                yield put(enterNewWordAction(words, autoPaste));
            } else {
                yield put(setErrorWords({ words: errorWords }));
                yield put(openPopupAction({ open: true }));
            }
        }
    } else {
        yield put(updatePuzzleAction(puzzle, newInputWords));
        yield put(updateAutoPasteInputAction(newInputWords));
    }
}

function* changePuzzleConfigSaga(action: IWordSearchAction) {
    const { puzzle } = action;
    const inputWords = yield select(
        (state: any) => state.wordSearchState.inputWords
    );
    const puzzleReducer = yield select(
        (state: any) => state.wordSearchState.puzzle
    );
    let newPuzzle: IPuzzle;
    let newInputWords = inputWords;
    if (JSON.stringify(puzzle) === JSON.stringify(puzzleReducer)) {
        return;
    } else if (
        puzzle.puzzleShape !== puzzleReducer.puzzleShape ||
        puzzle.puzzleSize !== puzzleReducer.puzzleSize ||
        puzzle.wordDirections !== puzzleReducer.wordDirections ||
        puzzle.puzzleHeight !== puzzleReducer.puzzleHeight ||
        puzzle.puzzleWidth !== puzzleReducer.puzzleWidth
    ) {
        newPuzzle = genPuzzleShape(puzzle);
        newInputWords = inputWords.map((e) => {
            return new InputWord({ word: e.word });
        });
        const errorWords = [];
        // reset exsistDirections
        newPuzzle.exsistDirections = [];
        let allSpot = getAllSpot(newPuzzle);
        for (let index = 0; index < newInputWords.length; index++) {
            const errorWord = setWord(newInputWords[index], newPuzzle, allSpot);
            if (errorWord) {
                errorWords.push(newInputWords[index]);
            }
        }
    } else {
        newPuzzle = new Puzzle({
            ...puzzleReducer,
            showAnswerKey: puzzle.showAnswerKey,
            showWordDirection: puzzle.showWordDirection,
            showWordList: puzzle.showWordList,
        });
    }
    // check if any inputword not fit puzzle, open popup to warning
    let wordNotOnGrid = newInputWords.filter((w) => w.onGrid == 0);
    if (wordNotOnGrid.length > 0) {
        // newInputWords = newInputWords.filter((w) => w.onGrid == 1);
        yield put(updateTempPuzzleAction(newPuzzle, newInputWords));
        yield put(openPopupAction({ open: true }));
    } else {
        yield put(updatePuzzleAction(newPuzzle, newInputWords));
    }
}

function* scramblePuzzleSaga() {
    const inputWords = yield select(
        (state: any) => state.wordSearchState.inputWords
    );
    const puzzleReducer = yield select(
        (state: any) => state.wordSearchState.puzzle
    );
    let newPuzzle;
    let newInputWords;
    newPuzzle = genPuzzleShape({ ...puzzleReducer });
    newInputWords = inputWords.map((e) => {
        return new InputWord({ word: e.word, status: e.status });
    });
    // reset exsistDirections
    newPuzzle.exsistDirections = [];
    let allSpot = getAllSpot(newPuzzle);
    for (let index = 0; index < newInputWords.length; index++) {
        setWord(newInputWords[index], newPuzzle, allSpot);
    }

    // check if any inputword not fit puzzle, open popup to warning
    let wordNotOnGrid = newInputWords.filter((w) => w.onGrid == 0);
    if (wordNotOnGrid.length > 0) {
        // newInputWords = newInputWords.filter((w) => w.onGrid == 1);
        yield put(updateTempPuzzleAction(newPuzzle, newInputWords));
        yield put(openPopupAction({ open: true }));
    } else {
        yield put(updatePuzzleAction(newPuzzle, newInputWords));
    }
}

const getAllSpot = (puzzle: IPuzzle) => {
    let arrNumber = puzzle.puzzleArrNumber;
    let count = 0;
    for (let index = 0; index < arrNumber.length; index++) {
        let row = arrNumber[index];
        for (let i = 0; i < row.length; i++) {
            if (row[i] != 0) {
                count++;
            }
        }
    }
    return count;
};

const setWord = (inputWord: InputWord, puzzle: IPuzzle, allSpot: number) => {
    try {
        let cantAddWord = false;
        let initDirectionsArray = getDirectionRestrictions(
            puzzle.wordDirections
        );
        let directionsArray = getDirectionRestrictions(puzzle.wordDirections);

        let exsistDirections = puzzle.exsistDirections;

        let desiredWord = inputWord.word;

        let checkedArray = puzzle.puzzleArrNumber; //[...availableCharArray];
        let possibleSpots = 0;
        checkedArray.forEach((row) => {
            row.forEach((e) => {
                if (e !== 0) {
                    possibleSpots++;
                }
            });
        });
        let coverRate = Math.round((possibleSpots / allSpot) * 100);
        const THRESHOLD_COVER_RATE = 60;
        loopspots: for (
            possibleSpots = possibleSpots;
            possibleSpots >= 0;
            possibleSpots--
        ) {
            if (possibleSpots > 0) {
                let selectedSpot = Math.floor(Math.random() * possibleSpots);
                let targetSpot = 0;
                let startX;
                let startY;
                loop1: for (startX = 0; startX < puzzle.puzzleWidth; startX++) {
                    for (startY = 0; startY < puzzle.puzzleHeight; startY++) {
                        if (
                            checkedArray[startY][startX] === 1
                            // || checkedArray[startX][startY] === 2
                        ) {
                            if (targetSpot === selectedSpot) {
                                break loop1;
                            } else {
                                targetSpot++;
                            }
                        }
                    }
                }

                //loop over possible directions
                // chose direction in directionsArray\exsistDirections
                // if direction not in exsistDirections, put it into exsistDirections
                // if exsistDirections = directionsArray, exsistDirections = []
                if (coverRate < THRESHOLD_COVER_RATE) {
                    directionsArray = directionsArray.filter(
                        (e) => !exsistDirections.includes(e)
                    );
                } else {
                    directionsArray = initDirectionsArray;
                }
                let directionsArrayLength = directionsArray.length;
                let startDirection = Math.floor(
                    Math.random() * directionsArrayLength
                );
                for (
                    let selectedDirection = startDirection;
                    selectedDirection < startDirection + directionsArrayLength;
                    selectedDirection++
                ) {
                    let testDirection =
                        directionsArray[
                            selectedDirection % directionsArrayLength
                        ];
                    let { xIncrement, yIncrement } =
                        getDirection(testDirection);

                    if (
                        checkWord(
                            startX,
                            startY,
                            desiredWord,
                            puzzle,
                            xIncrement,
                            yIncrement
                        )
                    ) {
                        if (!exsistDirections.includes(testDirection)) {
                            exsistDirections.push(testDirection);
                            puzzle.exsistDirections = exsistDirections;
                        }
                        if (
                            exsistDirections.length ==
                            initDirectionsArray.length
                        ) {
                            puzzle.exsistDirections = [];
                        }
                        addInputWord(
                            inputWord,
                            puzzle,
                            desiredWord,
                            1,
                            inputWord.wordListText,
                            testDirection,
                            startX,
                            startY
                        );
                        break loopspots;
                    }
                }
            } else {
                cantAddWord = true;
            }
        }
        return cantAddWord;
    } catch (error) {
        console.error("setWord", error);
    }
};

const checkWord = (
    startX: number,
    startY: number,
    desiredWord: string,
    puzzle: IPuzzle,
    xIncrement: number,
    yIncrement: number
) => {
    let testX = startX;
    let testY = startY;
    let inWordArray = [];

    let overlapCheckNeeded = false;

    //check each letter of the word for availability
    for (let i = 0; i < desiredWord.length; i++) {
        //make sure we haven't gone over the edge
        if (
            testX >= 0 &&
            testY >= 0 &&
            testX < puzzle.puzzleWidth &&
            testY < puzzle.puzzleHeight
        ) {
            if (puzzle.puzzleArrNumber[testY][testX] === 0) {
                return false;
            } else if (puzzle.puzzleArrNumber[testY][testX] === 2) {
                return false;
            }
            // else {
            //     overlapCheckNeeded = false;
            // }
            //else 1 is fine
        } else {
            return false;
        }
        testX = testX + xIncrement;
        testY = testY + yIncrement;
    }
    //if it passes the tests above it will return true, unless it has 100% overlap with another word
    if (overlapCheckNeeded && desiredWord.length === 1) {
        return false;
    }
    return true;
};

const addInputWord = (
    inputWord: InputWord,
    puzzle: IPuzzle,
    word,
    onGrid,
    wordListText,
    direction,
    startX,
    startY
) => {
    try {
        inputWord.word = word;
        inputWord.onGrid = onGrid;
        inputWord.wordListText = wordListText;
        inputWord.direction = direction;
        let { xIncrement, yIncrement } = getDirection(direction);
        let startCell = [];
        if (onGrid === 1) {
            //save it to wordArrayClone
            startCell = [startX, startY];
            //if word fits, record it, roll it in, add to word list, and stop checking
            let x = startX;
            let y = startY;

            //roll in the word
            for (let i = 0; i < word.length; i++) {
                puzzle.puzzleArrChar[y][x] = word[i];
                puzzle.puzzleArrNumber[y][x] += 1;

                x = x + xIncrement;
                y = y + yIncrement;
            }
        } else {
            // wordArrayClone[wordIndex]["start_cell"] = [];
        }
        inputWord.startCell = startCell;
    } catch (err) {}
};

const removeWord = (inputWord: IInputWord, puzzle: IPuzzle) => {
    try {
        let { xIncrement, yIncrement } = getDirection(inputWord.direction);
        let originX = inputWord.startCell[0];
        let originY = inputWord.startCell[1];
        let x = inputWord.startCell[0];
        let y = inputWord.startCell[1];
        const possibleCharSet = Constants.POSSIBLE_CHAR_SET;
        for (let i = 0; i < inputWord.word.length; i++) {
            if (puzzle.puzzleArrChar[y]) {
                puzzle.puzzleArrChar[y][x] = possibleCharSet.charAt(
                    Math.floor(Math.random() * possibleCharSet.length)
                );
                puzzle.puzzleArrNumber[y][x] -= 1;
                x = x + xIncrement;
                y = y + yIncrement;
            }
        }
        inputWord.onGrid = 0;
        let id = `${ConstantsResource.PREFIX_LINEid}${originX}-${originY}`;
        let findSvgItem = document.getElementById(id) as HTMLElement;
        if (!findSvgItem) {
            let endCell = getEndCellPosition({
                startX: originX,
                startY: originY,
                direction: inputWord.direction,
                wordLength: inputWord.word.length - 1,
            });
            if (endCell.length > 0) {
                id = `${ConstantsResource.PREFIX_LINEid}${endCell[0]}-${endCell[1]}`;
                findSvgItem = document.getElementById(id) as HTMLElement;
            }
        }
        if (findSvgItem) {
            findSvgItem.remove();
        }
        puzzle.exsistDirections = puzzle.exsistDirections.filter(
            (e) => e != inputWord.direction
        );
    } catch (err) {
        console.error("removeWord", err);
    }
};
const clearPuzzleArr = (puzzle: IPuzzle) => {
    puzzle.puzzleArrChar = [];
    puzzle.puzzleArrNumber = [];
};

export const calcCellPx = (puzzle: IPuzzle) => {
    if (puzzle.puzzleArrNumber?.length) {
        let max = Math.max(
            puzzle.puzzleArrNumber.length,
            puzzle.puzzleArrNumber[0].length
        );
        let cellPx = (ConfigWordSearch.WIDTH - 150) / max;
        if (cellPx > ConstantsResource.PUZZLE_CELL_PX) {
            cellPx = ConstantsResource.PUZZLE_CELL_PX;
        }
        return cellPx;
    }
};

export const genPuzzleShape = (puzzle: IPuzzle) => {
    let puzzleHeight = 0;
    let puzzleWidth = 0;
    let puzzleString = "";
    const puzzleShapeArray = Constants.PUZZLE_SHAPE_ARRAY;
    const possibleCharSet = Constants.POSSIBLE_CHAR_SET;
    clearPuzzleArr(puzzle);
    if (puzzle.puzzleShape === "Rectangle") {
        puzzleHeight =
            puzzle.puzzleHeight === 0
                ? Constants.DEFAULT_PUZZLE_RECTANGRLE_HEIGHT
                : puzzle.puzzleHeight;
        puzzleWidth =
            puzzle.puzzleWidth === 0
                ? Constants.DEFAULT_PUZZLE_RECTANGRLE_WIDTH
                : puzzle.puzzleWidth;
        for (let index = 0; index < puzzleWidth * puzzleHeight; index++) {
            puzzleString = puzzleString + "1";
        }
    } else {
        for (let i = 0; i < puzzleShapeArray.length; i++) {
            if (
                puzzleShapeArray[i]["shape"] === puzzle.puzzleShape &&
                puzzleShapeArray[i]["size"] === puzzle.puzzleSize
            ) {
                puzzleHeight = parseInt(puzzleShapeArray[i]["height"]);
                puzzleWidth = parseInt(puzzleShapeArray[i]["width"]);
                puzzleString = puzzleShapeArray[i]["active_string"];
                // console.log("puzzleString ", puzzleString);

                break;
            }
            if (i === puzzleShapeArray.length - 1) {
                alert(
                    "Oops! We couldn&#8217;t find the selected shape at the selected size.  Please try another combination."
                );
                return null;
            }
        }
    }
    //REPLACE ALL 1 WITH RANDOM CHARACTERS
    //THIS WILL BE DIFFERENT BASED ON CURRENT CHARSET

    for (let i = 0; i < puzzleString.length; i++) {
        if (puzzleString[i] === "1") {
            puzzleString =
                puzzleString.substring(0, i) +
                possibleCharSet.charAt(
                    Math.floor(Math.random() * possibleCharSet.length)
                ) +
                puzzleString.substring(i + 1);
        }
    }
    let i = 0;
    for (let y = 0; y < puzzleHeight; y++) {
        puzzle.puzzleArrChar[y] = [];
        puzzle.puzzleArrNumber[y] = [];
        for (let x = 0; x < puzzleWidth; x++) {
            let thisChar = puzzleString.charAt(i);
            i++;

            if (thisChar === "0") {
                puzzle.puzzleArrNumber[y].push(0);
                puzzle.puzzleArrChar[y].push("");
            } else {
                puzzle.puzzleArrChar[y].push(thisChar);
                puzzle.puzzleArrNumber[y].push(1);
            }
        }
    }
    return new Puzzle({ ...puzzle, puzzleHeight, puzzleWidth });
};

function* getMoreInfoSaga(worksheet) {
    try {
        const wordSearchState = yield select(
            (state: any) => state.wordSearchState
        );
        const { inputWords, puzzle, type } =
            wordSearchState as IWordsearchState;
        const newWorksheet: IWorksheet = JSON.parse(JSON.stringify(worksheet));
        const userId = yield select(
            (state: ResourceAppState) => state.userInfoResourceState.data.id
        );
        const currentActivityToolV2 = yield select(
            (state: any) => state?.createWorksheetState?.currentActivity?.type
        );
        const pageWs: any[] = yield select(
            (state: any) => state?.createWorksheetState?.pagesWorksheet
        );
        // const answer = new Answer({
        //     type: ANSWER_TYPE.WORD_SEARCH_BY_TOOL,
        //     wordSearchValue: puzzle.puzzleArrChar,
        //     value: JSON.stringify(inputWords),
        // });

        let puzzleSize = "";
        if (puzzle.puzzleShape === "Rectangle") {
            puzzleSize = puzzle.puzzleWidth + "_" + puzzle.puzzleHeight;
        } else {
            puzzleSize = puzzle.puzzleSize;
        }

        let screenSize: ScreenSize;
        let screenSizes: ScreenSize[] = [];
        if (pageWs) {
            pageWs.forEach((page) => {
                screenSizes.push(
                    new ScreenSize({
                        height: page.height,
                        width: page.width,
                    })
                );
            });
            screenSize = screenSizes[0];
        } else {
            screenSize = new ScreenSize({
                width:
                    newWorksheet.game.screenSize.width ??
                    ConfigWordSearch.WIDTH,
                height:
                    newWorksheet.game.screenSize.height ??
                    ConfigWordSearch.HEIGHT,
            });
            screenSizes = [screenSize];
        }
        let originUrl = getOriginUrlFromCurrentActivity(currentActivityToolV2);
        // let isWordSearch =
        //     projectName === Config.PROJECT_NAME.WORD_SEARCH ||
        //     originUrl === Config.USER_CREATED_TOOL_V2_WORD_SEARCH;

        newWorksheet.game = new Game({
            screenSize: screenSize,
            screenSizes: screenSizes,
            answers: worksheet.game.answers,
            wordSearchCreator: new Puzzle({
                title: puzzle.title,
                description: puzzle.description,
                creator: puzzle.creator,
                studentInfos: puzzle.studentInfos,
                puzzleShape: puzzle.puzzleShape,
                puzzleSize: puzzleSize,
                wordDirections: puzzle.wordDirections,
                showAnswerKey: puzzle.showAnswerKey,
                showWordDirection: puzzle.showWordDirection,
            }),
        });

        if (newWorksheet.type === Config.WORKSHEET_TYPE_INTERACTIVE) {
            newWorksheet.gameSubmitted = new Game({
                screenSize: screenSize,
                screenSizes: screenSizes,
            });
        }
        newWorksheet.originUrl = originUrl;
        newWorksheet.sourceUserBehaviors = getSourceUserBehaviors(pageWs);
        newWorksheet.useInteractiveElement = getUseInteractiveElement(pageWs);
        modifyWsBaseOnEditModeFromUrl(newWorksheet, userId);
        return newWorksheet;
    } catch (error) {
        console.log("error", error);
    }
}

function* getImageSaga({ showAnswer = false }: { showAnswer?: boolean }) {
    try {
        const showAnswerKey = yield select(
            (state: any) => state.wordSearchState.puzzle.showAnswerKey
        );
        const type = yield select((state: any) => state.wordSearchState.type);

        const newConfig = showAnswer ? Config.SHOW_VALUE : Config.HIDE_VALUE;
        const shouldDispatch = showAnswerKey !== newConfig;
        // Clear style before get Image
        changeElementStyle({ prefixId: ConstantsResource.PREFIX_LINEid });
        changeStyleWordFound(type, "unset");
        const HOVER_ANSWERid = "hover";
        changeElementStyle({ prefixId: HOVER_ANSWERid });
        if (!showAnswer) {
            addRemoveClassListHide(true);
        } else {
            addRemoveClassListHide(true, "show-important");
        }
        changeStyleCrossword(showAnswer, true);
        changeStyleScramble(showAnswer, true);

        if (shouldDispatch) {
            changeElementStyle({
                prefixId: ConstantsResource.ANSWER_PUZZLEid,
                value: genDisplay(showAnswer),
            });
        }

        let wordsearchIdElement = "content-layer";
        const result = yield convertImagesToBase64(wordsearchIdElement);
        if (!showAnswer) {
            addRemoveClassListHide(false);
            yield put(setImageSource(result));
        } else {
            addRemoveClassListHide(false, "show-important");
            yield put(setImageAnswer(result));
        }
        // Restore style after get Image
        if (shouldDispatch) {
            changeElementStyle({
                prefixId: ConstantsResource.ANSWER_PUZZLEid,
                value: genDisplay(!showAnswer),
            });
        }

        changeElementStyle({
            prefixId: ConstantsResource.PREFIX_LINEid,
            value: "unset",
        });
        changeStyleWordFound(type, "line-through");
        changeElementStyle({ prefixId: HOVER_ANSWERid, value: "unset" });
        changeStyleCrossword(showAnswer, false);
        changeStyleScramble(showAnswer, false);
        return result;
    } catch (error) {
        console.log("error", error);
    }
}

function* saveImageSaga({
    normalImages,
    answerImages,
    worksheet,
}: {
    normalImages?: string[];
    answerImages?: string[];
    worksheet?: IWorksheet;
}) {
    try {
        const userId = yield select(
            (state: ResourceAppState) => state.userInfoResourceState?.data?.id
        );
        const type = yield select(
            (state: ResourceAppState) => state.wordSearchState.type
        );
        const projectName = yield select(
            (state: ResourceAppState) => state.wordSearchState.projectName
        );
        let newWorksheet = new Worksheet({
            ...worksheet,
        });
        newWorksheet = yield call(updateWorksheetCreatedByWordSearchAPI, {
            workSheet: newWorksheet,
        });
        if (!newWorksheet?.id) {
            yield put(setMessageAction("Can not upload worksheet!", "warning"));
            throw new Error("Can not upload worksheet");
        }
        // Update worksheet id
        yield put(saveWorksheetId({ id: newWorksheet.id }));

        const promiseImages = {};
        if (normalImages.length > 0) {
            promiseImages["normalImages"] = call(uploadImageToServer, {
                title: newWorksheet.title,
                worksheetId: newWorksheet.id,
                dataUrl: normalImages,
            });
        }
        if (answerImages.length > 0) {
            promiseImages["answerImages"] = call(uploadImageToServer, {
                title: "answer-" + newWorksheet.title,
                worksheetId: newWorksheet.id,
                dataUrl: answerImages,
            });
        }
        const {
            normalImages: normalImagesData,
            answerImages: answerImagesData,
        } = yield all(promiseImages);

        newWorksheet.game.images = [];
        newWorksheet.game.original = [];
        if (newWorksheet.gameSubmitted) {
            newWorksheet.gameSubmitted.images = [];
        } else {
            newWorksheet.gameSubmitted = new Game();
        }
        normalImagesData.forEach((urlArray, index) => {
            urlArray.forEach((u: string) => {
                if (u.includes("-preview")) {
                    newWorksheet.game.images = [...newWorksheet.game.images, u];
                } else if (u.includes("-thumbnail") && index === 0) {
                    newWorksheet.thumbnail = u;
                } else if (u.includes("-original")) {
                    newWorksheet.game.original = [
                        ...newWorksheet.game.original,
                        u,
                    ];
                }
            });
        });
        if (answerImagesData?.length > 0) {
            answerImagesData.forEach((urlArray) => {
                urlArray.forEach((u: string) => {
                    if (u.includes("-preview")) {
                        newWorksheet.gameSubmitted.images = [
                            ...newWorksheet.gameSubmitted?.images,
                            u,
                        ];
                    }
                });
            });
        }
        if (
            [
                Constants.MODULE_TYPE.WORDSEARCH,
                Constants.MODULE_TYPE.TOOL_V2,
            ].includes(type)
        ) {
            yield call(updateWorksheetCreatedByWordSearchAPI, {
                workSheet: newWorksheet,
            });
        }
        yield put(
            saveWorksheetImagesAction(normalImagesData, answerImagesData)
        );
        yield put(updateStatusDownloadImages(true));
        if (
            type === Constants.MODULE_TYPE.TOOL_CREATE_WS_V1 ||
            projectName === Config.PROJECT_NAME.TOOL_V2
        ) {
            yield put(
                nextActionWordSearchAction({
                    nextAction: Config.NEXT_ACTION_SAVE_WORKSHEET,
                })
            );
        }
        const currentActivityToolV2 = yield select(
            (state: any) => state?.createWorksheetState?.currentActivity?.type
        );
        const sourceUserBehaviors =
            newWorksheet?.sourceUserBehaviors?.length > 0
                ? newWorksheet?.sourceUserBehaviors
                : [Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH];

    } catch (error) {
        console.log("error", error);
    }
}

function* saveWorksheetSaga(action: IWordSearchAction) {
    yield put(setLoadingAction({ loading: true }));
    try {
        const userId = yield select(
            (state: ResourceAppState) => state.userInfoResourceState?.data?.id
        );
        const params = new URLSearchParams(window.location.search);
        const isEditBase = params.get("isEditBase");
        const {
            worksheet,
            type,
            imagesBase64,
            isSave,
            isReadyToDownLoadImages,
        } = yield select((state: ResourceAppState) => state.wordSearchState);
        if (isSave) {
            yield put(setStatusSave(false));
        }
        if (isReadyToDownLoadImages) {
            yield put(updateStatusDownloadImages(false));
        }
        let isAuthor = false;
        if (userId === worksheet?.authorId) {
            isAuthor = true;
        }
        let newWorksheet = new Worksheet({
            ...worksheet,
            authorId:
                isEditBase || action.isCustomizeByContent
                    ? worksheet.authorId ?? ""
                    : userId,
            statusHide: Config.STATUS_CREATE_SUCCESS,
        });

        let srcImage = imagesBase64 ?? [];
        let srcAnswer = [];
        if (
            type === Constants.MODULE_TYPE.WORDSEARCH ||
            type === Constants.MODULE_TYPE.TOOL_V2
        ) {
            if (!isAuthor) {
                newWorksheet = yield getMoreInfoSaga(newWorksheet);
            }
            srcImage = yield getImageSaga({ showAnswer: false });

            if (newWorksheet.type === Config.WORKSHEET_TYPE_INTERACTIVE) {
                srcAnswer = yield getImageSaga({ showAnswer: true });
            }
        }
        if (checkIsToolV1Project() && !action.isCustomizeByContent && !isSave) {
            newWorksheet.id = undefined;
        }

        yield put(
            updateWorksheetCreatedByWordSearchAction({
                worksheet: newWorksheet,
            })
        );
        // Show popup
        if (!action.isCustomizeByContent && !checkIsTester()) {
            yield put(setStatusSave(true));
        }
        yield saveImageSaga({
            normalImages: srcImage,
            answerImages: srcAnswer,
            worksheet: newWorksheet,
        });
    } catch (error) {
        console.log("error", error);
    }
    yield put(setLoadingAction({ loading: false }));
}

function* saveWordSearchForToolSaga() {
    try {
        let wordSearchState = yield select(
            (state: any) => state.wordSearchState
        );
        const currentActivityToolV2 = yield select(
            (state: any) => state?.createWorksheetState?.currentActivity?.type
        );
        const { inputWords, puzzle, worksheet, type } =
            wordSearchState as IWordsearchState;
        const projectName = yield select(
            (state: ResourceAppState) => state.wordSearchState.projectName
        );
        const sourceImages = {
            [Config.HIDE_VALUE]: "",
            [Config.SHOW_VALUE]: "",
        };

        yield put(setLoadingAction({ loading: true }));
        const mutilTextElement = document?.getElementById("multi-text");
        let categoryIds = [];
        if (mutilTextElement) {
            try {
                const categoryId = mutilTextElement.getAttribute("categoryid");
                if (categoryId) {
                    categoryIds = categoryId
                        .split(",")
                        .filter((id) => id?.length > 0);
                    mutilTextElement.removeAttribute("categoryid");
                }
            } catch (error) {
                console.log("error", error);
            }
        }
        const userId = "633d2c83a7bb74c1a52075b0"; // userInfo?.id;
        let screenSize = new ScreenSize({
            width: ConfigWordSearch.WIDTH,
            height: ConfigWordSearch.HEIGHT,
        });

        let answer = new Answer({
            type: ANSWER_TYPE.WORD_SEARCH_BY_TOOL,
            wordSearchValue: puzzle.puzzleArrChar,
            value: JSON.stringify(inputWords),
        });
        let originUrl = getOriginUrlFromCurrentActivity(currentActivityToolV2);
        let newWorksheet: any = new Worksheet({
            ...worksheet,
            isWorksheetManagement: true,
            topicIds: categoryIds,
            authorId: userId,
            statusHide: Config.STATUS_CREATE_SUCCESS,
            type: Config.WORKSHEET_TYPE_INTERACTIVE,
            originUrl,
        });
        let isWordSearch =
            projectName === Config.PROJECT_NAME.WORD_SEARCH ||
            originUrl === Config.USER_CREATED_TOOL_V2_WORD_SEARCH;
        let puzzleSize = "";
        if (puzzle.puzzleShape == "Rectangle") {
            puzzleSize = puzzle.puzzleWidth + "_" + puzzle.puzzleHeight;
        } else {
            puzzleSize = puzzle.puzzleSize;
        }
        newWorksheet.game = new Game({
            screenSize: screenSize,
            screenSizes: [screenSize],
            answers: isWordSearch ? [answer] : [],
            wordSearchCreator: new Puzzle({
                creator: puzzle.creator,
                title: puzzle.title,
                description: puzzle.description,
                studentInfos: puzzle.studentInfos,
                puzzleShape: puzzle.puzzleShape,
                puzzleSize: puzzleSize,
                wordDirections: puzzle.wordDirections,
                showAnswerKey: puzzle.showAnswerKey,
                showWordDirection: puzzle.showWordDirection,
                showWordList: puzzle.showWordList,
            }),
        });
        newWorksheet.gameSubmitted = new Game({
            screenSize: screenSize,
            screenSizes: [screenSize],
        });
        newWorksheet.isWorksheetManagement = true;
        let worksheetResult = yield call(
            updateWorksheetCreatedByWordSearchAPI,
            {
                workSheet: newWorksheet,
            }
        );

        // Clear for upload to server
        const { showAnswerKey, showWordDirection } = puzzle;
        const currentConfig = {
            showAnswerKey,
            showWordDirection,
        };
        const reverseAnswerOption =
            showAnswerKey === Config.HIDE_VALUE
                ? Config.SHOW_VALUE
                : Config.HIDE_VALUE;
        changeElementStyle({ prefixId: ConstantsResource.PREFIX_LINEid });
        changeStyleWordFound(type, "unset");

        if (showWordDirection !== Config.SHOW_VALUE) {
            yield put(
                changePuzzleConfigAction({
                    ...puzzle,
                    showWordDirection: Config.SHOW_VALUE,
                })
            );
            yield take(WordSearchTypes.UPDATE_PUZZLE);
            yield delay(1);
        }
        let base64Image: string = yield convertImgToBase64();
        sourceImages[showAnswerKey] = base64Image;

        yield put(
            changePuzzleConfigAction({
                ...puzzle,
                showWordDirection: Config.SHOW_VALUE,
                showAnswerKey: reverseAnswerOption,
            })
        );
        yield take(WordSearchTypes.UPDATE_PUZZLE);
        yield delay(1);
        base64Image = yield convertImgToBase64();
        sourceImages[reverseAnswerOption] = base64Image;
        let params = {
            title: worksheetResult.title,
            worksheetId: worksheetResult?.id,
        };

        const { showAnswerImages, hideAnswerImages } = yield all({
            showAnswerImages: call(uploadImageToServer, {
                ...params,
                title: worksheetResult.title + "-answer",
                dataUrl: [sourceImages[Config.SHOW_VALUE]],
            }),
            hideAnswerImages: call(uploadImageToServer, {
                ...params,
                dataUrl: [sourceImages[Config.HIDE_VALUE]],
            }),
        });

        if (showAnswerImages[0] && hideAnswerImages[0]) {
            hideAnswerImages[0].forEach((u: string) => {
                if (u.includes("-preview")) {
                    newWorksheet.game.images = [u];
                } else if (u.includes("-thumbnail")) {
                    newWorksheet.thumbnail = u;
                }
            });
            showAnswerImages[0].forEach((u: string) => {
                if (u.includes("-preview")) {
                    newWorksheet.gameSubmitted.images = [u];
                }
            });
            if (!newWorksheet.game.images) {
                newWorksheet.game.images = [hideAnswerImages[0][0]];
            }

            if (!newWorksheet.thumbnail) {
                newWorksheet.thumbnail = hideAnswerImages[0][1];
            }

            if (!newWorksheet.gameSubmitted?.images) {
                newWorksheet.gameSubmitted.images = [showAnswerImages[0][0]];
            }
            newWorksheet.id = worksheetResult.id;
            worksheetResult = yield call(
                updateWorksheetCreatedByWordSearchAPI,
                {
                    workSheet: newWorksheet,
                }
            );
        }

        // Restore state after clear for upload
        yield put(
            changePuzzleConfigAction({
                ...puzzle,
                ...currentConfig,
            })
        );
        yield take(WordSearchTypes.UPDATE_PUZZLE);
        yield delay(1);
        changeElementStyle({
            prefixId: ConstantsResource.PREFIX_LINEid,
            value: "unset",
        });
        changeStyleWordFound(type, "line-through");

        if (worksheetResult.id) {
            yield put(
                updateWorksheetCreatedByWordSearchAction({
                    worksheet: worksheetResult,
                })
            );
        }
    } catch (error) {
        console.log("error", error);
    }
    yield put(setLoadingAction({ loading: false }));
}

const handleTrackingFailedNextAction = (
    nextAction: string,
    missField: string[],
    currentActivity: string
) => {
    if (missField?.length > 0) {
        let event = "";
        switch (nextAction) {
            case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_WORD:
                event = "download_on_popup_save";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ANSWER:
                // case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ONLY:
                event = "download_with_answer_key_on_popup_save";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_PINTEREST:
                event = "pinterest";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_TWITTER:
                event = "twitter";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_WHATSAPP:
                event = "whatsapp";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_FACEBOOK:
                event = "facebook";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_MICROSOFT_TEAMS:
                event = "microsoft_teams";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_GOOGLE_CLASSROOM:
                event = "google_classroom";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.COPY_WORKSHEET_LINK:
                event = "copy_link";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.SHARE_EMBED_LINK:
                event = "embed _link";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.ADD_TO_MY_RESOURCES:
                event = "add_to_collection";
                break;
            case Config.NEXT_ACTION_WORD_SEARCH.ASSIGN_DIGITALLY:
                event = "assign_digitally";
                break;
            default:
                break;
        }
    }
};

const checkContentEmpty = (worksheet: any) => {
    const { title, description, moreInfo, selectedCategoryIds } = worksheet;
    const { grades, languages } = moreInfo;
    let listWarning = [];

    const clearWhiteSpaceTitle = title?.trim();
    const clearWhiteSpaceDescription = description?.trim();
    if (!clearWhiteSpaceTitle) {
        listWarning.push(Config.WARNING_REQUIRED.TITLE);
    }
    if (!clearWhiteSpaceDescription) {
        listWarning.push(Config.WARNING_REQUIRED.DESCRIPTION);
    }
    if (languages?.length === 0) {
        listWarning.push(Config.WARNING_REQUIRED.LANGUAGE);
    }
    if (grades?.length === 0) {
        listWarning.push(Config.WARNING_REQUIRED.GRADE);
    }
    if (selectedCategoryIds?.length === 0) {
        listWarning.push(Config.WARNING_REQUIRED.TAG);
    }
    return listWarning;
};

function* handleNextActionSaga(action) {
    const { nextAction } = action;
    const wordSearchState = yield select((state: any) => state.wordSearchState);
    const currentActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity?.type
    );
    const { worksheet, projectName } = wordSearchState as IWordsearchState;
    // yield put(setMessageAction(message + " can not be empty!", "warning"));
    if (nextAction !== Config.NEXT_ACTION_SAVE_WORKSHEET && nextAction) {
        const warningRequired = checkContentEmpty(worksheet);
        yield put(changeWarningRequired({ warningRequired }));
        // handleTrackingMissField(warningRequired);
        handleTrackingFailedNextAction(
            nextAction,
            warningRequired,
            currentActivity
        );
        if (warningRequired.length > 0) {
            yield put(resetNextAction());
            return;
        }
    }
    const share_url = window.location.origin + urlWorksheet(worksheet);
    const image = worksheet.game.images[0];
    let event = "";
    let newEvent = "";
    let utmSource = "";
    const checkOpenSurvey = yield select(
        (state: any) => state.wordSearchState.openSurvey
    );
    if (!checkOpenSurvey) {
        yield put(openSurveyAction({ open: true }));
    }

    switch (nextAction) {
        case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_WORD:
        case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ANSWER:
        case Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ONLY:
            event =
                nextAction === Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_WORD
                    ? "download_on_popup_save"
                    : "download_with_answer_key_on_popup_save";
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_PINTEREST:
            event = "wse_pinterest";
            newEvent = "pinterest";
            utmSource =
                "?utm_source=pinterest&utm_medium=share_from_word_search";
            window.open(
                `https://www.pinterest.com/pin-builder/?description=Test+image&media=${image}&method=button&url=${
                    share_url + utmSource
                }`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_TWITTER:
            event = "wse_twitter";
            newEvent = "twitter";
            utmSource = "?utm_source=twitter&utm_medium=share_from_word_search";
            window.open(
                `https://twitter.com/intent/tweet?url=${share_url + utmSource}`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_WHATSAPP:
            event = "wse_whatsapp";
            newEvent = "whatsapp";
            utmSource =
                "?utm_source=whatsapp&utm_medium=share_from_word_search";
            window.open(
                `https://api.whatsapp.com/send?text=${share_url + utmSource}`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_FACEBOOK:
            event = "wse_fb";
            newEvent = "facebook";
            utmSource =
                "?utm_source=facebook&utm_medium=share_from_word_search";
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${
                    share_url + utmSource
                }`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_MICROSOFT_TEAMS:
            event = "wse_microsoft_team";
            newEvent = "microsoft_teams";
            utmSource =
                "?utm_source=microsoft_teams&utm_medium=share_from_word_search";
            window.open(
                `https://teams.microsoft.com/share?href=${
                    share_url + utmSource
                }`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_GOOGLE_CLASSROOM:
            event = "wse_classroom";
            newEvent = "google_classroom";
            utmSource =
                "?utm_source=google_classroom&utm_medium=share_from_word_search";
            window.open(
                `https://classroom.google.com/share?url=${
                    share_url + utmSource
                }`,
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=730"
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.COPY_WORKSHEET_LINK:
            event = "wse_copy_ws_link";
            newEvent = "copy_link";
            utmSource = "?utm_source=copy_url";
            copyTextToClipboard(share_url + utmSource);
            yield put(setMessageAction("Copied"));
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SHARE_EMBED_LINK:
            event = "wse_embed_link";
            newEvent = "embed _link";
            utmSource = "?play=true&utm_source=embed_link";
            yield put(
                setCallbackSharedAction({
                    callbackShared: Config.STATUS_PUBLIC,
                })
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.ADD_TO_MY_RESOURCES:
            event = "add_to_collection";
            yield put(
                updateCollectionAction({
                    collection: new Collection(),
                    loading: false,
                })
            );
            yield put(selectGridItemAction({ worksheet }));
            yield put(
                selectActionGridItemAction({
                    action: Config.ACTION_GRID.SAVE,
                })
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.ASSIGN_DIGITALLY:
            event = "assign_digitally";
            yield put(selectGridItemAction({ worksheet }));
            yield put(
                selectActionGridItemAction({
                    action: Config.ACTION_GRID.OPEN_ASSIGN_DIGITALLY,
                })
            );
            break;
        case Config.NEXT_ACTION_WORD_SEARCH.SAVE_AND_VIEW:
            window.open(share_url, "_blank");
        case Config.NEXT_ACTION_WORD_SEARCH.SAVE:
            yield put(changeStatusAlertSave(true));
            break;
        default:
            break;
    }

    if (
        ![
            Config.NEXT_ACTION_SAVE_WORKSHEET,
            Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ANSWER,
            Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_WORD,
            Config.NEXT_ACTION_WORD_SEARCH.DOWNLOAD_ONLY,
        ].includes(nextAction) &&
        nextAction
    ) {
        if (projectName !== Config.PROJECT_NAME.TOOL_V2) {
            yield put(setLoadingAction({ loading: false }));
        }
    }
}

const uploadImageToServer = async ({
    title = "My Word Search",
    worksheetId,
    dataUrl,
}: {
    title?: string;
    worksheetId?: string;
    dataUrl?: string[];
}) => {
    // return "https://storage.googleapis.com/worksheetzone/test-upload/1682323719661-26639109.png";
    let base64: any = "";
    if (!dataUrl) {
        base64 = await convertImgToBase64();
    }
    let value =
        isProdWeb() || window.location.href?.includes("staging")
            ? "true"
            : "false";

    return await uploadImageAPI({
        title,
        worksheetId,
        save_prod: value,
        listImageBase64: dataUrl ? dataUrl : [base64],
    });
};

function* removeErrorWordSaga(action) {
    const tempInputWords = yield select(
        (state: any) => state.wordSearchState.tempInputWords
    );
    // only keep inputword on grid (puzzle)
    let newInputWords = tempInputWords.filter((word) => word.onGrid == 1);
    const tempPuzzle = yield select(
        (state: any) => state.wordSearchState.tempPuzzle
    );
    yield put(updatePuzzleAction(tempPuzzle, newInputWords));
    yield put(setErrorWords({ words: [] }));
}

function* keppConfigPuzzleSaga(action) {
    const tempInputWords = yield select(
        (state: any) => state.wordSearchState.inputWords
    );
    const tempPuzzle = yield select(
        (state: any) => state.wordSearchState.puzzle
    );
    yield put(updateTempPuzzleAction(tempPuzzle, tempInputWords));
    yield put(setErrorWords({ words: [] }));
}

function* getWordsGPTSaga(action) {
    let { topic, limitWord, limitLength } = action;
    yield put(setLoadingAction({ loading: true }));
    let wordsGPT = [];
    try {
        wordsGPT = yield call(getGPTword, { topic, limitWord, limitLength });
    } catch (error) {
        console.log("error", error);
    }
    yield put(setKeywordGPTAction({ wordsGPT, loading: false }));
}

function* removeAllWordSaga() {
    const inputWordsReducer = yield select(
        (state: any) => state.wordSearchState.inputWords
    );
    const puzzleReducer = yield select(
        (state: any) => state.wordSearchState.puzzle
    );
    const inputWords = JSON.parse(JSON.stringify(inputWordsReducer));
    const puzzleTemp = JSON.parse(JSON.stringify(puzzleReducer));
    const puzzle = new Puzzle(puzzleTemp);
    let newInputWords: IInputWord[] = [];

    for (let i = 0; i < inputWords.length; i++) {
        removeWord(inputWords[i], puzzle);
    }
    yield put(updatePuzzleAction(puzzle, newInputWords));
}

function* handleSaveInfo(action: IWordSearchAction) {
    try {
        const {
            title,
            description,
            languages,
            grades,
            categoryIds,
            commonCoreIds,
            topicIds,
            selectedCategoryIds,
        } = action;
        const worksheet = yield select(
            (state: ResourceAppState) => state.wordSearchState.worksheet
        );
        const newWorksheet: IWorksheet = JSON.parse(JSON.stringify(worksheet));

        if (!newWorksheet?.id) {
            return;
        }
        let shouldUpdate = false;

        if (typeof title === "string") {
            newWorksheet.title = title;
            shouldUpdate = true;
        }
        if (typeof description === "string") {
            newWorksheet.description = description;
            shouldUpdate = true;
        }
        if (languages) {
            newWorksheet.moreInfo.languages = languages;
            shouldUpdate = true;
        }
        if (grades) {
            newWorksheet.moreInfo.grades = grades;
            shouldUpdate = true;
        }
        if (categoryIds) {
            newWorksheet.categoryIds = categoryIds;
            shouldUpdate = true;
        }
        if (commonCoreIds) {
            newWorksheet.commonCoreIds = commonCoreIds;
            shouldUpdate = true;
        }
        if (topicIds) {
            newWorksheet.topicIds = topicIds;
            shouldUpdate = true;
        }
        if (selectedCategoryIds) {
            newWorksheet.selectedCategoryIds = selectedCategoryIds;
            shouldUpdate = true;
        }
        if (shouldUpdate) {
            yield call(updateWorksheetCreatedByWordSearchAPI, {
                workSheet: newWorksheet,
            });
        }
    } catch (error) {
        console.log("error", error);
    }
}

function* handleResetSvg(action: IWordSearchAction) {
    let playWorksheetInfo = yield select(
        (state: ResourceAppState) => state.gameResourceState.playWorksheetInfo
    );
    if (
        playWorksheetInfo.projectName === Config.PROJECT_CLASSROOM &&
        playWorksheetInfo.role === ClassroomConfig.ROLE_STUDENT &&
        action.resetSvg
    ) {
        let inputWords = yield select(
            (state: ResourceAppState) => state.wordSearchState.inputWords
        );
        let answer = yield select(
            (state: ResourceAppState) => state.gameResourceState.answers[0]
        );

        // let worksheet: IWorksheet = yield select(
        //     (state:ResourceAppState) => state.gameResourceState.worksheet
        // );
        let newInputWords = JSON.parse(JSON.stringify(inputWords));
        let newAnswer: any = JSON.parse(JSON.stringify(answer));
        newAnswer.userAnswer = JSON.stringify({
            inputWords: newInputWords,
            listLine: [],
        });

        for (let newInputWord of newInputWords) {
            newInputWord.status = Config.STATUS_PUZZLE.INIT;
        }
        yield put(updateUserAnswer(newAnswer));
    }
}

function* getSuggestTagsData(action: IWordSearchAction) {
    let typeActivity = yield select(
        (state: any) => state?.createWorksheetState?.currentActivity?.type
    );
    if (typeActivity) {
        const suggestTags = yield call(getSuggestTagsDataAPI, {
            typeActivity,
        });
        yield put(getSuggestTagsDataSuccess(suggestTags));
    }
}

function* handleNextSaveInfoBox(action: IWordSearchAction) {
    const {
        languages,
        grades,
        categoryIds,
        commonCoreIds,
        topicIds,
        selectedCategoryIds,
    } = action;
    let fieldChanged = "";
    let newList = [];
    if (languages) {
        fieldChanged = "languages";
        newList = languages;
    } else if (grades) {
        fieldChanged = "grades";
        newList = grades;
    } else if (categoryIds) {
        fieldChanged = "categoryIds";
        newList = categoryIds;
    } else if (commonCoreIds) {
        fieldChanged = "commonCoreIds";
        newList = commonCoreIds;
    } else if (topicIds) {
        fieldChanged = "topicIds";
        newList = topicIds;
    } else if (selectedCategoryIds) {
        fieldChanged = "selectedCategoryIds";
        newList = selectedCategoryIds;
    }
    if (fieldChanged.length) {
        yield put(
            saveMoreInfoWorksheet({
                [fieldChanged]: newList,
            })
        );
    }
}

function* listenWordSearchSaga() {
    yield takeLatest(WordSearchTypes.INIT_WORD_SEARCH_GAME, initWordSearchSaga);
    yield takeLatest(WordSearchTypes.ENTER_NEW_WORD, enterNewWordSaga);
    yield takeLatest(WordSearchTypes.REMOVE_ERROR_WORD, removeErrorWordSaga);
    yield takeLatest(WordSearchTypes.KEEP_CONFIG_PUZZLE, keppConfigPuzzleSaga);
    yield takeLatest(
        WordSearchTypes.CHANGE_PUZZLE_CONFIG,
        changePuzzleConfigSaga
    );
    yield takeLatest(
        WordSearchTypes.SCRAMBLE_PUZZLE_ACTION,
        scramblePuzzleSaga
    );
    yield takeLatest(WordSearchTypes.SAVE_WORKSHEET, saveWorksheetSaga);
    yield takeLatest(WordSearchTypes.GET_KEYWORD_GPT, getWordsGPTSaga);
    yield takeLatest(WordSearchTypes.REMOVE_ALL_WORDS, removeAllWordSaga);
    yield takeLatest(
        WordSearchTypes.SAVE_WORD_SEARCH_FOR_TOOL,
        saveWordSearchForToolSaga
    );
    yield takeLatest(
        WordSearchTypes.NEXT_ACTION_WORD_SEARCH,
        handleNextActionSaga
    );
    yield takeLatest(WordSearchTypes.SAVE_MORE_INFO, handleSaveInfo);
    yield takeLatest(WordSearchTypes.RESET_SVG, handleResetSvg);
    yield takeLatest(WordSearchTypes.GET_SUGGEST_TAGS_DATA, getSuggestTagsData);
    yield takeLatest(WordSearchTypes.CHANGE_INFO_BOX, handleNextSaveInfoBox);
}

export const wordSearchSaga = [fork(listenWordSearchSaga)];
