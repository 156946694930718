import { useEffect, useRef, useState } from "react";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    upDateOnEditBorder,
    updateResourceItems,
} from "../../../../../../redux/reducers/createWorksheet";
import { updateCurentInfo } from "../../../../../../redux/reducers/leftData";
import ToolTipCustomize from "../../../../../../resource-user/components/common/library/tool-tip";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import groupElementStore from "../../../../../../syncExternalStore/groupElementStore";
import { getShapeAttribute, isResourceLine } from "../../../../../../utils";
import { ConstantsApp } from "../../../../../../utils/Constants";
import SliderInput from "../../../../EditBar/common/sliderInput";
import "./index.scss";
import MakeStrokeDasharrayView from "../../../../EditBar/common/makeStrokeDasharray";

function ShapeEditBar({
    currentElement,
    setCurrentElement,
    resourceItems,
    type,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    resourceItems: IResourceItemNew[];
    type?: "line" | "shape";
}) {
    const dispatch = useAppDispatch();
    const currentInfo = useAppSelector(
        (state) => state.leftDataState.currentInfo
    );
    const [pickerColor, setPickerColor] = useState<{
        originColor: string;
        currentColor: string;
    } | null>(null);
    const [isShow, setIsShow] = useState(false);
    const [radius, setRadius] = useState(0);
    const [stroke, setStroke] = useState("#000000");
    const [strokeWidth, setStrokeWidth] = useState(0);
    const [strokeDasharray, setStrokeDasharray] = useState(0);
    const refPopup: any = useRef();
    const refContent: any = useRef();
    const [isMouseUp, setIsMouseUp] = useState(true);
    const [isLine, setIsLine] = useState(isResourceLine(currentElement));

    const minStrokeWidth = isLine ? 1 : 0;
    const isResize = useAppSelector(
        (state) => state.createWorksheetState.isResize
    );
    let shapeAttribute = currentElement?.shapeAttribute;
    const isGroup = currentElement.resourceIds.length;

    useEffect(() => {
        if (currentInfo !== null) {
            setTimeout(() => {
                setCurrentElement({
                    ...currentInfo,
                });
            }, 10);
        }
    }, [currentInfo?.shapeAttribute]);

    useEffect(() => {
        if (currentInfo && !isResize) {
            dispatch(updateCurentInfo(currentElement));
        }
    }, [shapeAttribute?.input?.position, isResize]);

    useEffect(() => {
        if (isGroup) {
            let value = getShapeAttribute(resourceItems, type);
            setStrokeWidth(value.strokeWidth);
            setStrokeDasharray(value.strokeDasharray);
            setRadius(value.radius);
            setIsLine(value.isLine);
        } else {
            let isLine = isResourceLine(currentElement);
            setIsLine(isLine);
            setRadius(shapeAttribute.radius);
            // setStroke(shapeAttribute.stroke);
            if (isLine) {
                setStrokeWidth(currentElement.height);
            } else {
                setStrokeWidth(shapeAttribute.strokeWidth / 2);
            }
            setStrokeDasharray(shapeAttribute.strokeDasharray);
        }
    }, [currentElement?.id, shapeAttribute?.name]);

    useEffect(() => {
        if (currentInfo) {
            dispatch(updateCurentInfo(currentElement));
        }
    }, [currentElement?.id]);

    useEffect(() => {
        if (isShow) {
            if (isMouseUp) {
                dispatch(upDateOnEditBorder(true));
                setTimeout(() => {
                    dispatch(upDateOnEditBorder(false));
                }, 500);
            }
        }
    }, [stroke, strokeWidth, strokeDasharray, radius]);

    useEffect(() => {
        document.addEventListener("click", listen);
        return () => {
            dispatch(upDateOnEditBorder(false));
            dispatch(updateCurentInfo(null));
            setPickerColor(null);
            document.removeEventListener("click", listen);
        };
    }, [isMouseUp]);

    const listen = (event: MouseEvent) => {
        if (refPopup && refPopup !== null && refContent != null) {
            const cur = refPopup.current;
            const curContent = refContent.current;
            if (
                cur &&
                !cur.contains(event.target) &&
                !curContent.contains(event.target)
            ) {
                if (!isMouseUp) {
                    setIsMouseUp(true);
                } else {
                    setIsShow(false);
                }
            }
        }
    };

    const updateResource = (resourceItems: IResourceItemNew[]) => {
        dispatch(
            updateResourceItems({
                pageIndex: currentElement.pageIndex,
                resourceItems: [...resourceItems],
            })
        );
        if (currentElement && currentInfo && !isGroup) {
            dispatch(updateCurentInfo(currentElement));
        }
    };

    const changeShapeAttribute = (
        radius: number,
        stroke: string,
        strokeWidth: number,
        strokeDasharray: number,
        type?: "strokeDasharray"
    ) => {
        let resourceItems = [];
        if (isGroup) {
            resourceItems = groupElementStore.updateShapeAttribute({
                strokeDasharray:
                    type === "strokeDasharray" ? strokeDasharray : null,
                strokeWidth,
                radius,
                isLine,
            });
        } else {
            resourceItems = [
                {
                    ...currentElement,
                    shapeAttribute: {
                        ...shapeAttribute,
                        radius,
                        stroke,
                        strokeWidth: isLine
                            ? shapeAttribute.strokeWidth
                            : strokeWidth * 2,
                        strokeDasharray,
                    },
                    height: isLine ? strokeWidth : currentElement.height,
                },
            ];
            setCurrentElement(resourceItems[0]);
        }
        return resourceItems;
    };

    const onChangeRadius = (radius?: number, isInput?: boolean) => {
        if (isNaN(radius)) return;
        if (radius >= 0 && radius <= 100) {
            setRadius(radius);
            let resourceItems = changeShapeAttribute(
                radius,
                stroke,
                strokeWidth,
                strokeDasharray
            );
            if (isInput) {
                updateResource(resourceItems);
            }
        }
    };
    const onChangeStrokeWidth = (
        type?: "slider" | "input",
        strokeWidth?: number
    ) => {
        if (isNaN(strokeWidth)) return;
        if (strokeWidth >= minStrokeWidth && strokeWidth <= 100) {
            setStrokeWidth(strokeWidth);
            let resourceItems = changeShapeAttribute(
                radius,
                stroke,
                strokeWidth,
                strokeDasharray
            );
            if (type === "input") {
                updateResource(resourceItems);
            }
        }
    };

    const onChangeStrokeDasharray = (strokeDasharray: number) => {
        let stW = strokeWidth;
        if (strokeWidth === 0) {
            setStrokeWidth(2);
            stW = 2;
        }
        setStrokeDasharray(strokeDasharray);
        let resources = changeShapeAttribute(
            radius,
            stroke,
            stW,
            strokeDasharray,
            "strokeDasharray"
        );
        updateResource(resources);
    };

    // const onChangeStroke = (color: string) => {
    //     setStroke(color);
    //     changeShapeAttribute(radius, stroke, strokeWidth, strokeDasharray);
    // };

    const makeSlider = (
        label: string,
        count: number,
        onChange: (value: number) => void,
        onChangeSlider: (value: number) => void
    ) => {
        return (
            <SliderInput
                label={label}
                value={count}
                min={minStrokeWidth}
                onChangeInput={onChange}
                onChangeSlider={onChangeSlider}
                onMouseDown={() => {
                    setIsMouseUp(false);
                    dispatch(upDateOnEditBorder(true));
                }}
                onChangeCommitted={() => {
                    setTimeout(() => {
                        dispatch(upDateOnEditBorder(false));
                        setIsMouseUp(true);
                    }, 300);
                    updateResource(isGroup ? resourceItems : [currentElement]);
                }}
            />
        );
    };

    return (
        <div className="shape-bar-container" ref={refContent}>
            <div>
                <ToolTipCustomize
                    title={isLine ? "Line style" : "Border style"}
                    style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
                >
                    <div className="button-bar-container" onClick={() => setIsShow(!isShow)}>
                        <img src={svgPath(IconSVG.iconBoder)} />
                    </div>
                </ToolTipCustomize>
                {isShow && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            opacity: 0,
                        }}
                    >
                        <div
                            className="button"
                            onClick={() => setIsShow(!isShow)}
                            style={{ marginLeft: "10px" }}
                        >
                            <img src={svgPath(IconSVG.iconBoder)} />
                        </div>
                    </div>
                )}
            </div>

            {isShow && (
                <div className="popupBorder" ref={refPopup}>
                    <MakeStrokeDasharrayView
                        value={strokeDasharray}
                        onClick={function (value: number): void {
                            onChangeStrokeDasharray(value);
                        }}
                        onClickNone={function (): void {
                            setStrokeDasharray(0);
                            setStrokeWidth(0);
                            onChangeStrokeWidth(null, 0);
                        }}
                        isNone={strokeWidth === 0}
                        isDisplayNone={!isLine}
                    />

                    {makeSlider(
                        "Border Weight",
                        strokeWidth,
                        (value) => onChangeStrokeWidth("input", value),
                        (value) => onChangeStrokeWidth("slider", value)
                    )}

                    {!isLine &&
                        makeSlider(
                            "Corner Rounding",
                            radius,
                            (value) => onChangeRadius(value, true),
                            (value) => onChangeRadius(value)
                        )}
                </div>
            )}

            {/* {pickerColor && currentElement.imageAttribute && (
                <div
                    style={{
                        position: "absolute",
                        top: 50,
                        left: -220,
                        zIndex: 3,
                    }}
                >
                    <SketchPicker
                        color={pickerColor.currentColor}
                        onChange={(newColor) => {
                            setPickerColor({
                                ...pickerColor,
                                currentColor: newColor.hex,
                            });
                            onChangeStroke(newColor.hex)
                        }}
                        onChangeComplete={(newColor) => {
                            // onChangeStroke(newColor.hex)
                        }}
                    />
                </div>
            )} */}
        </div>
    );
}

export default ShapeEditBar;
