import "./index.scss";

import { Checkbox } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
    ListBulletPointStyleIcon,
    ListMakeStyleIcon,
    ListStyleIndexIcon,
} from "../../../../assets/icon";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    addResourceItems,
    removeResourceItems,
    updateManyResourceByType,
    updateResourceItems,
    updateWorkSheetCreator,
} from "../../../../redux/reducers/createWorksheet";
import {
    getResourceItemByActivityAndType,
    getResourceItemsByActivityAndType,
} from "../../../../redux/selector";
import {
    IResourceItemNew, TextAttribute
} from "../../../../shared/models/resourceItemNew";
import {
    Activity,
    ListStyle,
} from "../../../../shared/models/WorkSheetCreator";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import {
    handleCalculateWord,
    makeWorkBank,
    shuffleArray
} from "../../../../utils";
import CreateWSConfig from "../../../../utils/create-ws-config";
import { getSizeBoxText } from "../../../../utils/draw";

const LIST_OPTION_LIST_STYLE = [
    {
        id: ListStyle.NONE,
        icon: null,
    },
    {
        id: ListStyle.INDEX,
        icon: <ListStyleIndexIcon></ListStyleIndexIcon>,
    },
    {
        id: ListStyle.LIST_MARKER,
        icon: <ListMakeStyleIcon></ListMakeStyleIcon>,
    },
    {
        id: ListStyle.BULLET_POINT,
        icon: <ListBulletPointStyleIcon></ListBulletPointStyleIcon>,
    },
];
const ListCheckBox = ({ activity }: { activity: Activity }) => {
    const [showAnswerKey, setShowAnswerKey] = useState(
        activity.showAnswerKey ? true : false
    );
    const [showWordBank, setShowWordBank] = useState(
        activity.showWordBank ? true : false
    );
    const dispatch = useAppDispatch();
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const resourceItemEditing = useAppSelector(
        (state) => state.leftDataState.resourceItemEditing
    );
    const [lineHeightData, setLineHeightData] = useState(
        Math.trunc(ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE * 1.2)
    );

    const resourceFillTheBlank: IResourceItemNew[] = useAppSelector((state) =>
        getResourceItemsByActivityAndType()(state, {
            activityId: currentActivity.id,
            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        })
    );
    const handleChangeListStyle = (value: number) => {
        let name = "";
        switch (value) {
            case ListStyle.NONE:
                name = "none";
                break;
            case ListStyle.LIST_MARKER:
                name = "bullet";
                break;
            case ListStyle.INDEX:
                name = "number";
                break;
            case ListStyle.BULLET_POINT:
                name = "dash";
                break;
        }
        dispatch(
            updateManyResourceByType({
                pagesWs: handleCalculateWord({
                    diffHeight: 0,
                    resourceFillTheBlank: resourceFillTheBlank,
                    listStyle: value,
                    action: CreateWSConfig.LIST_ACTION.ACTION_CHANGE_LIST_STYLE,
                    heightGap: currentActivity.heightGap,
                }),
                type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
            })
        );
        dispatch(
            updateWorkSheetCreator({
                activity: {
                    listStyle: value,
                },
            })
        );
    };
    const onChangeLineSpacing = debounce((event, value: number) => {
        let resourceItem = resourceFillTheBlank.find(
            (e) => e?.id === resourceItemEditing
        );
        if (resourceItem) {
            let textAttribute = new TextAttribute({
                ...resourceItem.textAttribute,
                lineHeight: value,
            });
            let size = getSizeBoxText({
                textAttribute,
                width: resourceItem.width,
                type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                listStyle: currentActivity.listStyle,
            });

            dispatch(
                updateResourceItems({
                    pageIndex: resourceItem.pageIndex,
                    resourceItems: [
                        {
                            ...resourceItem,
                            textAttribute: textAttribute,
                            width: size.width,
                            height: size.height,
                        },
                    ],
                })
            );
        }
    }, 64);
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const wordBank = useAppSelector((state) =>
        getResourceItemByActivityAndType()(state, {
            activityId: currentActivity.id,
            type: ConstantsTool.TYPE_WORD_BANK,
        })
    );
    useEffect(() => {
        if (resourceItemEditing) {
            let resourceFind = resourceFillTheBlank.find(
                (el) => el.id === resourceItemEditing
            );
            if (resourceFind) {
                setLineHeightData(
                    resourceFind.textAttribute.lineHeight > 0
                        ? resourceFind.textAttribute.lineHeight
                        : Math.trunc(
                              ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE *
                                  1.2
                          )
                );
            }
        } else {
            setLineHeightData(0);
        }
    }, [resourceItemEditing, currentActivity?.heightGap]);

    useEffect(() => {
        if (activity.showWordBank && !showWordBank) {
            setShowWordBank(true);
            onShowWordBank({ showWordBank: true, isAddHistory: false });
        }
    }, [activity.showWordBank]);

    useEffect(() => {
        onShowWordBank({ showWordBank, isAddHistory: false });
        onShowAnswerKey({ showAnswerKey, isAddHistory: false });
    }, []);
    const onShowAnswerKey = ({
        showAnswerKey,
        isAddHistory,
    }: {
        showAnswerKey: boolean;
        isAddHistory: boolean;
    }) => {
        dispatch(
            updateWorkSheetCreator({
                activity: { showAnswerKey: showAnswerKey ? 1 : 0 },
                isAddHistory,
            })
        );
    };
    const onShowWordBank = ({
        showWordBank,
        isAddHistory,
    }: {
        showWordBank: boolean;
        isAddHistory: boolean;
    }) => {
        if (pagesWorksheet.length > 0) {
            dispatch(
                updateWorkSheetCreator({
                    activity: { showWordBank: showWordBank ? 1 : 0 },
                    isAddHistory,
                })
            );
            if (showWordBank) {
                if (!wordBank) {
                    let allWords: string[] = [];
                    resourceFillTheBlank.forEach((el) => {
                        allWords = allWords.concat(
                            el.question.answers.map((el) => el.text)
                        );
                    });
                    if (allWords.length > 1) {
                        allWords = shuffleArray(allWords);
                    }
                    dispatch(
                        addResourceItems({
                            pageIndex: 0,
                            isAddHistory: false,
                            resourceItems: [
                                makeWorkBank({
                                    activityId: activity.id,
                                    wordBanks: allWords,
                                }),
                            ],
                        })
                    );
                    // dispatch(
                    //     updateManyResourceByType({
                    //         pagesWs: handleCalculateWord({
                    //             diffHeight:
                    //                 heightWordBank +
                    //                 ConstantsTool.MARGIN_BOTTOM_WORD_BANK,
                    //             resourceFillTheBlank: resourceFillTheBlank,
                    //             listStyle: currentActivity.listStyle,
                    //             action: CreateWSConfig.LIST_ACTION
                    //                 .ACTION_WORD_BANK,
                    //             heightGap: currentActivity.heightGap,
                    //         }),
                    //         type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                    //     })
                    // );
                }
            } else if (wordBank?.id) {
                dispatch(
                    removeResourceItems({
                        pageIndex: 0,
                        resourceIds: [wordBank.id],
                        isAddHistory: false,
                    })
                );
                // dispatch(
                //     updateManyResourceByType({
                //         pagesWs: handleCalculateWord({
                //             diffHeight: -(
                //                 wordBank.height +
                //                 ConstantsTool.MARGIN_BOTTOM_WORD_BANK
                //             ),
                //             resourceFillTheBlank: resourceFillTheBlank,
                //             listStyle: currentActivity.listStyle,
                //             action: CreateWSConfig.LIST_ACTION.ACTION_WORD_BANK,
                //             heightGap: currentActivity.heightGap,
                //         }),
                //         type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                //     })
                // );
            }
        }
    };

    return (
        <div className="check-box-list">
            <div className="entry-check-box">
                <div className="title">List Style</div>
                <div className="list-icon-style">
                    {LIST_OPTION_LIST_STYLE.map((el) => {
                        return (
                            <div
                                className={
                                    "style-icon " +
                                    (currentActivity.listStyle === el.id
                                        ? "active"
                                        : "")
                                }
                                onClick={() => handleChangeListStyle(el.id)}
                                key={el.id}
                            >
                                {el.icon ? (
                                    el.icon
                                ) : (
                                    <div className="none-style">None</div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <div>
                <div
                    className="title-box"
                    style={{
                        opacity: resourceItemEditing ? 1 : 0.7,
                    }}
                >
                    <div className="title" style={{ marginLeft: 12 }}>
                        Line Spacing
                    </div>
                    <div className="value">{lineHeightData}</div>
                </div>
                <div className="line-spacing-box">
                    <Slider
                        size="medium"
                        className="slider-line-spacing"
                        disabled={!resourceItemEditing}
                        defaultValue={Math.trunc(
                            ConstantsTool.DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE *
                                1.2
                        )}
                        onChange={(event, value: number) => {
                            setLineHeightData(value);
                            onChangeLineSpacing(event, value);
                        }}
                        onChangeCommitted={(event, value: number) => {
                            if (!resourceItemEditing) {
                                dispatch(
                                    updateWorkSheetCreator({
                                        activity: { heightGap: value },
                                    })
                                );
                            }
                        }}
                        value={lineHeightData}
                        valueLabelDisplay="auto"
                        sx={{
                            color: "rgba(var(--color), 1)",
                            height: 3,
                        }}
                    />
                </div>
            </div> */}

            <div className="entry-check-box">
                <div className="title">Show The Word Bank</div>
                <Checkbox
                    size="medium"
                    onChange={(e: any) => {
                        setShowWordBank(e.target.checked);
                        onShowWordBank({
                            showWordBank: e.target.checked,
                            isAddHistory: true,
                        });
                    }}
                    checked={showWordBank}
                    icon={<div className="icon-check" />}
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: 20,
                            color: "rgba(var(--color), 1)",
                        },
                    }}
                />
            </div>
            <div className="entry-check-box">
                <div className="title">Show The Answer Key</div>
                <Checkbox
                    size="medium"
                    onChange={(e: any) => {
                        setShowAnswerKey(e.target.checked);
                        onShowAnswerKey({
                            showAnswerKey: e.target.checked,
                            isAddHistory: true,
                        });
                    }}
                    checked={showAnswerKey}
                    icon={<div className="icon-check" />}
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: 20,
                            color: "rgba(var(--color), 1)",
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default ListCheckBox;
