import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import Input from "@mui/material/Input";
import * as React from "react";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWorksheet } from "../../../../../shared/models/worksheet";
import Config from "../../../../../shared/utils/config";
import CloseButtonIcon from "../../../../assets/images/icon-assign-digitally/close_24px.png";
import { createClassAction } from "../../../../redux/action/assign-digitally.action";
import { selectActionGridItemAction } from "../../../../redux/action/resource.action";
import { setMessageAction } from "../../../../redux/action/snackbar.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { getImageSource } from "../../../../utils";
import HintIconSvg from "../../../icons/hint";
import "./popup-create.scss";

const PopupCreateClass = ({
    setShowPopup,
    showPopupCreate,
    worksheet,
    setShowPopupCreate,
    onCreateGroupSuccess,
}: {
    showPopupCreate: boolean;
    worksheet?: any;
    setShowPopup?: Function;
    setShowPopupCreate: Function;
    onCreateGroupSuccess?: Function;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const closePopup = (value) => {
        setShowPopupCreate(value);
    };
    if (isMobile) {
        return (
            <Drawer
                className="popup-create resource"
                anchor={"bottom"}
                onClose={(e) => closePopup(false)}
                open={showPopupCreate}
                variant="temporary"
                style={{
                    zIndex: 1500,
                }}
            >
                <div className="close-button" onClick={(e) => closePopup(e)}>
                    <HintIconSvg />
                </div>
                <ContentPopup
                    setShowPopup={setShowPopup}
                    showPopupCreate={showPopupCreate}
                    setShowPopupCreate={(value) => {
                        if (!value) {
                            closePopup(value);
                        } else {
                            setShowPopupCreate(value);
                        }
                    }}
                    worksheet={worksheet}
                    onCreateGroupSuccess={onCreateGroupSuccess}
                />
            </Drawer>
        );
    }
    return (
        <Dialog
            className="popup-create"
            onClose={(e: any) => {
                e.stopPropagation();
                closePopup(false);
            }}
            open={showPopupCreate}
            disableEnforceFocus
        >
            <ContentPopup
                setShowPopup={setShowPopup}
                showPopupCreate={showPopupCreate}
                setShowPopupCreate={(value) => {
                    if (!value) {
                        closePopup(value);
                    } else {
                        setShowPopupCreate(value);
                    }
                }}
                worksheet={worksheet}
                onCreateGroupSuccess={onCreateGroupSuccess}
            />
        </Dialog>
    );
};

const ContentPopup = ({
    showPopupCreate,
    setShowPopupCreate,
    worksheet,
    setShowPopup,
    onCreateGroupSuccess,
}: {
    showPopupCreate: boolean;
    setShowPopupCreate: Function;
    worksheet?: IWorksheet;
    setShowPopup: Function;
    onCreateGroupSuccess?: Function;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const dispatch = useDispatch();
    const [done, setDone] = React.useState(false);
    const [name, setName] = useState("");
    let worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState?.actionGridItem?.worksheets
    );
    const userInfoState = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    useEffect(() => {
        setName("");
    }, []);
    useEffect(() => {
        if (done) {
            const timeout = setTimeout(() => {
                dispatch(
                    selectActionGridItemAction({
                        action: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            return;
        }
    }, [done]);
    const handleSubmit = async () => {
        if (name && name.trim() !== "") {
            let ws = worksheet?.id ? worksheet : null;
            dispatch(
                createClassAction(
                    name,
                    ws,
                    worksheets?.length ? worksheets : []
                )
            );
            setShowPopupCreate(false);
            setShowPopup(false);
            setDone(true);
            if (onCreateGroupSuccess) {
                onCreateGroupSuccess();
            }
        } else {
            dispatch(
                setMessageAction(`Please enter a name for your group`, "error")
            );
        }
    };
    return (
        <>
            <div className="popup-create resource">
                <div className="header">
                    <p>New group</p>
                    {!isMobile && (
                        <div className="close-icon">
                            <img
                                // src="/images/icon/close_24px.png"
                                src={getImageSource(CloseButtonIcon)}
                                alt="close"
                                onClick={() => setShowPopupCreate(false)}
                            />
                        </div>
                    )}
                </div>
                <div className="content">
                    <div className="field">
                        <div className="label">Name group</div>
                        <Input
                            type="text"
                            placeholder="Enter a name for your group"
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div
                        className="button cancel"
                        onClick={() => setShowPopupCreate(false)}
                    >
                        Cancel
                    </div>
                    <div
                        className="button save save-create-class"
                        onClick={() => handleSubmit()}
                    >
                        Create
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(PopupCreateClass);
