import { IPuzzle, InputWord, Puzzle } from "../../../shared/models/puzzle";
import { IWorksheet, Worksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import { checkIsToolCreate, getListTag } from "../../utils";
import ConstantsResource from "../../utils/ConstantsResource";
import { getCurrentInfo } from "../../utils/categoryUtils";
import Constants from "../../utils/constants";
import {
    IWordSearchAction,
    WordSearchTypes,
} from "../action/wordsearch.action";
export interface IErrorWord {
    index: number;
    value: string;
}

export interface IWordsearchState {
    projectName: string;
    worksheet: IWorksheet;
    inputWords: InputWord[];
    tempInputWords: InputWord[];
    autoPasteInputWords: InputWord[];
    currentInputWord: string;
    indexInputWord: number;
    puzzle: IPuzzle;
    tempPuzzle: IPuzzle;
    width: number;
    height: number;
    ratio: number;
    loading: boolean;
    nextAction: string;
    resetSvg: boolean;
    callbackShared: number;
    errorWords: IErrorWord[];
    openPopup: boolean;
    wordsGPT: string[];
    openSurvey: boolean;
    onEditTitleWs: boolean;
    type: string;
    imagesBase64: string[];
    elementImageId: string;
    openDownloadPopup: boolean;
    isSave: boolean;
    imagesBase64Answer: string[];
    isDownloadAnswer: boolean;
    listIndexImgDownload: number[];
    warningRequired: string[];
    isReadyToDownLoadImages: boolean;
    isEditPopupSave: boolean;
    suggestTagsData: any[];
    isShowAlertSave: boolean;
}

const initialState: IWordsearchState = {
    projectName: "",
    worksheet: new Worksheet(),
    inputWords: [],
    tempInputWords: [],
    autoPasteInputWords: [],
    currentInputWord: "",
    puzzle: new Puzzle(),
    tempPuzzle: new Puzzle(),
    indexInputWord: -1,
    width: 0,
    height: 0,
    ratio: 0,
    loading: false,
    nextAction: Config.NEXT_ACTION_WORD_SEARCH.INIT,
    resetSvg: false,
    callbackShared: Config.STATUS_DELETED,
    errorWords: [],
    openPopup: false,
    wordsGPT: [],
    openSurvey: false,
    onEditTitleWs: false,
    type: Constants.MODULE_TYPE.WORDSEARCH,
    imagesBase64: [],
    elementImageId: "",
    openDownloadPopup: false,
    isSave: false,
    imagesBase64Answer: [],
    isDownloadAnswer: false,
    isReadyToDownLoadImages: false,
    listIndexImgDownload: [],
    warningRequired: [],
    isEditPopupSave: false,
    suggestTagsData: [],
    isShowAlertSave: false,
};

function wordSearchState(
    state: IWordsearchState = initialState,
    action: IWordSearchAction | any
): IWordsearchState {
    switch (action.type) {
        case WordSearchTypes.INIT_WORD_SEARCH_GAME_SUCCESS:
            let newWorksheet = action.worksheet ?? new Worksheet({});
            if (!checkIsToolCreate()) {
                let newSelectedCategoryIds = Array.from(
                    new Set([
                        ...newWorksheet.selectedCategoryIds,
                        ...newWorksheet.categoryIds,
                        ...newWorksheet.topicIds,
                        ...newWorksheet.commonCoreIds,
                    ])
                );
                newWorksheet = {
                    ...newWorksheet,
                    selectedCategoryIds: newSelectedCategoryIds,
                };
            }
            return {
                ...state,
                puzzle: action.puzzle,
                width: action.width ?? state.width,
                height: action.height ?? state.height,
                ratio: action.ratio ?? state.ratio,
                inputWords: action.inputWords ?? [],
                worksheet: newWorksheet,
                onEditTitleWs: action.onEditTitleWs ?? false,
                loading: false,
            };
        case WordSearchTypes.INIT_POPUP_SAVE_WS_SUCCESS:
            return {
                ...state,
                // loading: false,
                worksheet:
                    action.worksheet ??
                    (action.moduleType === Constants.MODULE_TYPE.TOOL_V2
                        ? state.worksheet
                        : new Worksheet({})),
                type: action.moduleType ?? Constants.MODULE_TYPE.WORDSEARCH,
                imagesBase64: action.imagesBase64
                    ? [...action.imagesBase64]
                    : [],
                elementImageId: action.elementImageId ?? "",
                nextAction: "",
            };
        case WordSearchTypes.UPDATE_PUZZLE:
            let shouldChangeIsSave = false;
            if (state.puzzle.showAnswerKey === action.puzzle.showAnswerKey) {
                shouldChangeIsSave = true;
            }
            return {
                ...state,
                puzzle: action.puzzle,
                inputWords: action.inputWords,
                isSave: shouldChangeIsSave ? false : state.isSave,
            };
        case WordSearchTypes.UPDATE_TEMP_PUZZLE:
            return {
                ...state,
                tempPuzzle: action.puzzle,
                tempInputWords: action.inputWords,
            };
        case WordSearchTypes.UPDATE_AUTO_PASTE_INPUT_WORD:
            return {
                ...state,
                autoPasteInputWords: action.inputWords,
            };
        case WordSearchTypes.UPDATE_RATIO:
            return {
                ...state,
                ratio: action.ratio,
            };
        case WordSearchTypes.CHANGE_TITLE:
            return {
                ...state,
                worksheet: {
                    ...state.worksheet,
                    title: action.title,
                },
                onEditTitleWs: true,
            };
        case WordSearchTypes.CHANGE_TITLE_PUZZLE:
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    title: action.title,
                },
                isSave: false,
            };
        case WordSearchTypes.CHANGE_DESCRIPTION:
            return {
                ...state,
                worksheet: {
                    ...state.worksheet,
                    description: action.description,
                },
            };
        case WordSearchTypes.CHANGE_DESCRIPTION_PUZZLE:
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    description: action.description,
                },
                isSave: false,
            };
        case WordSearchTypes.CHANGE_CREATOR:
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    creator: action.creator,
                },
                isSave: false,
            };
        case WordSearchTypes.CHANGE_STUDENT_INFO:
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    studentInfos: action.studentInfo,
                },
                isSave: false,
            };
        case WordSearchTypes.UPDATE_STATUS_WORD_PUZZLE:
            let inputWordsUpdate = state?.inputWords.map(
                (el) => new InputWord(el)
            );
            if (inputWordsUpdate?.length) {
                let found = false;
                inputWordsUpdate = inputWordsUpdate.map((w2) => {
                    const w = new InputWord(w2);
                    if (
                        w.word === action.inputWord &&
                        !found &&
                        w.status == Config.STATUS_PUZZLE.INIT
                    ) {
                        found = true;
                        w.status = Config.STATUS_PUZZLE.FOUND;
                        w.userAnswer = action.userAnswer;
                    }
                    return w;
                });
            }
            return {
                ...state,
                inputWords: inputWordsUpdate,
            };

        case WordSearchTypes.UPDATE_WORKSHEET_CREATED_BY_WORD_SEARCH:
            return {
                ...state,
                worksheet: action.worksheet,
            };
        case WordSearchTypes.NEXT_ACTION_WORD_SEARCH:
            return {
                ...state,
                nextAction: action.nextAction,
            };
        case WordSearchTypes.RESET_NEXT_ACTION:
            return {
                ...state,
                nextAction: "",
            };
        case WordSearchTypes.RESET_SVG:
            let newInputWords = JSON.parse(JSON.stringify(state.inputWords));
            let resetInputWords = newInputWords.map((w) => {
                return new InputWord({
                    ...w,
                    status: Config.STATUS_PUZZLE.INIT,
                });
            });
            return {
                ...state,
                resetSvg: action.resetSvg,
                inputWords: resetInputWords,
            };
        case WordSearchTypes.SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case WordSearchTypes.SET_CALLBACK_SHARED:
            return {
                ...state,
                callbackShared: action.callbackShared,
            };
        case WordSearchTypes.UPDATE_ERROR_WORDS:
            return {
                ...state,
                errorWords: action.errorWords,
            };
        case WordSearchTypes.OPEN_POPUP:
            return {
                ...state,
                openPopup: action.open,
            };
        case WordSearchTypes.SET_KEYWORD_GPT:
            return {
                ...state,
                wordsGPT: action.wordsGPT,
                loading: action.loading,
            };
        case WordSearchTypes.OPEN_SURVEY:
            return {
                ...state,
                openSurvey: action.open,
            };
        case WordSearchTypes.CHANGE_INFO_BOX:
            let newState = {
                ...state,
            };
            if (action?.grades) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        moreInfo: {
                            ...newState.worksheet.moreInfo,
                            grades: action.grades,
                        },
                    },
                };
            } else if (action?.categoryIds) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        categoryIds: action.categoryIds,
                    },
                };
            } else if (action?.commonCoreIds) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        commonCoreIds: action.commonCoreIds,
                    },
                };
            } else if (action?.topicIds) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        topicIds: action.topicIds,
                    },
                };
            } else if (action?.languages) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        moreInfo: {
                            ...newState.worksheet.moreInfo,
                            languages: action.languages,
                        },
                    },
                };
            } else if (action?.selectedCategoryIds) {
                newState = {
                    ...newState,
                    worksheet: {
                        ...newState.worksheet,
                        selectedCategoryIds: action.selectedCategoryIds,
                    },
                };
            }
            return {
                ...newState,
            };
        case WordSearchTypes.SET_ON_EDIT_TITLE_WS:
            return {
                ...state,
                onEditTitleWs: true,
            };
        case WordSearchTypes.UPDATE_CELL_PX:
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    puzzleCellPx:
                        action.puzzleCellPx != -1
                            ? action.puzzleCellPx
                            : ConstantsResource.PUZZLE_CELL_PX,
                },
            };
        case WordSearchTypes.UPDATE_OPEN_DOWNLOAD_POPUP:
            return {
                ...state,
                openDownloadPopup: action.openDownloadPopup,
            };
        case WordSearchTypes.SET_PROJECT_NAME:
            return {
                ...state,
                projectName: action.projectName ?? "",
            };
        case WordSearchTypes.SET_STATUS_SAVE:
            return {
                ...state,
                isSave: action.isSave,
            };
        case WordSearchTypes.UPDATE_WORKSHEET:
            let newWorksheet2 = action.worksheet ?? new Worksheet({});
            if (!checkIsToolCreate() && action.newTemplate) {
                let newSelectedCategoryIds2 = Array.from(
                    new Set([
                        ...newWorksheet2.selectedCategoryIds,
                        ...newWorksheet2.categoryIds,
                        ...newWorksheet2.topicIds,
                        ...newWorksheet2.commonCoreIds,
                    ])
                );
                newWorksheet2 = {
                    ...newWorksheet2,
                    id: state?.worksheet?.id,
                    authorId: state?.worksheet?.authorId,
                    selectedCategoryIds: newSelectedCategoryIds2,
                };
            }
            return {
                ...state,
                worksheet: newWorksheet2,
            };
        case WordSearchTypes.RESET_ACTION:
            return { ...initialState };

        case WordSearchTypes.SET_IMAGES_SOURCE:
            return {
                ...state,
                imagesBase64: [...action.imagesBase64],
            };
        case WordSearchTypes.SET_IMAGES_ANSWER:
            return {
                ...state,
                imagesBase64Answer: [...action.imagesBase64],
            };
        case WordSearchTypes.CHANGE_STATUS_DOWNLOAD_ANSWER:
            return {
                ...state,
                isDownloadAnswer: action.isDownloadAnswer,
            };
        case WordSearchTypes.CHANGE_LIST_INDEX_IMG_DOWNLOAD:
            return {
                ...state,
                listIndexImgDownload: action.listIndexImgDownload,
            };
        case WordSearchTypes.CHANGE_WARNING_REQUIRED:
            if (!action.warningRequired) {
                let cloneList = [...state.warningRequired];
                if (action.addWarning) {
                    const indexExists = cloneList.indexOf(action.addWarning);
                    if (indexExists === -1) {
                        cloneList.push(action.addWarning);
                    }
                } else if (action.removeWarning) {
                    const indexExists = cloneList.indexOf(action.removeWarning);
                    if (indexExists > -1) {
                        cloneList.splice(indexExists, 1);
                    }
                }
                return {
                    ...state,
                    warningRequired: cloneList,
                };
            }
            return {
                ...state,
                warningRequired: action.warningRequired,
            };
        case WordSearchTypes.SAVE_WORKSHEET_IMAGES:
            try {
                const { normalImages, answerImages } = action;

                let images = [];
                let gameSubmittedImages = [];
                let original = [];
                let thumbnail = "";

                normalImages.forEach((urlArray, index) => {
                    urlArray.forEach((u: string) => {
                        if (u.includes("-preview")) {
                            images = [...images, u];
                        } else if (u.includes("-thumbnail") && index === 0) {
                            thumbnail = u;
                        } else if (u.includes("-original")) {
                            original = [...original, u];
                        }
                    });
                });
                if (answerImages?.length > 0) {
                    answerImages.forEach((urlArray) => {
                        urlArray.forEach((u: string) => {
                            if (u.includes("-preview")) {
                                gameSubmittedImages = [
                                    ...gameSubmittedImages,
                                    u,
                                ];
                            }
                        });
                    });
                }

                return {
                    ...state,
                    worksheet: {
                        ...state.worksheet,
                        thumbnail,
                        game: {
                            ...state.worksheet.game,
                            images,
                            original,
                        },
                        gameSubmitted: {
                            ...state.worksheet.gameSubmitted,
                            images: gameSubmittedImages,
                        },
                    },
                };
            } catch (error) {
                console.log("error", error);
            }

            return state;
        case WordSearchTypes.SAVE_WORKSHEETid:
            return {
                ...state,
                worksheet: {
                    ...state.worksheet,
                    id: action.id,
                },
            };
        case WordSearchTypes.UPDATE_STATUS_DOWNLOAD_IMAGES:
            return {
                ...state,
                isReadyToDownLoadImages: action.status,
            };
        case WordSearchTypes.CHANGE_STATUS_EDIT_POPUP_SAVE:
            return {
                ...state,
                isEditPopupSave: action.isEditPopupSave,
            };
        case WordSearchTypes.INIT_STATUS_POPUP_SAVE:
            return {
                ...state,
                isEditPopupSave: false,
                warningRequired: [],
            };
        case WordSearchTypes.GET_SUGGEST_TAGS_DATA_SUCCESS:
            return {
                ...state,
                suggestTagsData: action.suggestTagsData,
            };
        case WordSearchTypes.SET_DEFAULT_SUGGEST_TAG:
            if (
                !checkIsToolCreate() &&
                !state.isEditPopupSave &&
                state.worksheet?.selectedCategoryIds?.length === 0
            ) {
                const newTags = {
                    selectedCategoryIds: [],
                    categoryIds: [],
                    topicIds: [],
                    commonCoreIds: [],
                };
                Config.LIST_ACTIVITY_SUGGEST_TAGS_TOOL_V2.map((activity) => {
                    if (activity.type === action.typeActivity) {
                        newTags.selectedCategoryIds.push(activity.categoryId);
                        const info = getCurrentInfo(
                            activity.categoryId,
                            getListTag()
                        );
                        let parentList = info.parentIds.slice(1);
                        if (info) {
                            newTags[activity.originField] = [
                                ...parentList,
                                info.id,
                            ];
                        }
                    }
                });
                return {
                    ...state,
                    worksheet: {
                        ...state.worksheet,
                        categoryIds: newTags.categoryIds,
                        topicIds: newTags.topicIds,
                        commonCoreIds: newTags.commonCoreIds,
                        selectedCategoryIds: newTags.selectedCategoryIds,
                    },
                };
            }
        case WordSearchTypes.CHANGE_STATUS_ALERT_SAVE:
            return {
                ...state,
                isShowAlertSave: action.isShowAlertSave,
            };
        default:
            return state;
    }
}

export default wordSearchState;
