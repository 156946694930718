import Answer from "./Answer";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../../shared/models/questionActivity";
import { IActivity } from "../../../../../../../shared/models/WorkSheetCreator";
import ListenAnswer from "./ListenAnswer";

const ListAnswer = ({
    activity,
    listAnswer,
    question,
    questionIndex,
    chosenAnswer,
    setChosenAnswer,
    userChangeCol,
    setUserChangeCol,
}: {
    activity: IActivity;
    listAnswer: IAnswerActivity[];
    question: IQuestionActivity;
    questionIndex: number;
    chosenAnswer: number;
    setChosenAnswer?: Function;
    userChangeCol: boolean;
    setUserChangeCol?: Function;
}) => {
    return (
        <>
            <ListenAnswer
                listAnswer={listAnswer}
                setChosenAnswer={setChosenAnswer}
            />
            {listAnswer.map((answer: IAnswerActivity, index: number) => (
                <div key={answer.id}>
                    <Answer
                        activity={activity}
                        answer={answer}
                        index={index}
                        question={question}
                        questionIndex={questionIndex}
                        chosenAnswer={chosenAnswer}
                        setChosenAnswer={setChosenAnswer}
                        userChangeCol={userChangeCol}
                        setUserChangeCol={setUserChangeCol}
                    />
                </div>
            ))}
        </>
    );
};

export default ListAnswer;
