import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Collection,
    WorksheetItem,
} from "../../../../shared/models/collection";
import { IWorksheet, Worksheet } from "../../../../shared/models/worksheet";
import { stringifyAndParse } from "../../../../shared/utils";
import Config from "../../../../shared/utils/config";
import {
    selectActionGridItemAction,
    selectGridItemAction,
    updateCollectionResourceSuccessAction,
} from "../../../redux/action/resource.action";
import { updateAlertSnackBarAction } from "../../../redux/action/snackbar.action";
import { updateAssignment } from "../../../redux/repositories/class";
import { updateItemCollectionByIdAPI } from "../../../redux/repositories/collection.repositories";
import { updateAssignmentAction } from "../../../redux/action/assign-digitally.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";

const FooterSaveAndAssign = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem.action
    );

    const worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    const worksheet = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheet
    );
    const listCollectionSave = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.toCollection?.listCollection
    );
    const saveAssignments = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.saveAssignments
    );
    const classes = useSelector(
        (state: ResourceAppState) => state.assignDigitallyState.classes
    );
    const arrCollection = ({
        collectionUpdated,
        listCollectionUpdate,
        worksheet,
        ableDelWs,
    }: {
        collectionUpdated: any;
        listCollectionUpdate: any[];
        worksheet: IWorksheet;
        ableDelWs?: boolean;
    }) => {
        const worksheetItem = new WorksheetItem({
            worksheetId: worksheet?.id,
            joinedDate: new Date(),
        });
        const checkSaved = collectionUpdated.worksheetItems.find(
            (w) => w?.worksheetId == worksheet?.id
        );

        if (ableDelWs && checkSaved) {
            collectionUpdated.worksheetItems =
                collectionUpdated.worksheetItems.filter(
                    (i) => i?.worksheetId != worksheet.id
                );
            collectionUpdated.worksheets = collectionUpdated.worksheets?.filter(
                (w) => w?.id != worksheet.id
            );
        } else {
            if (!checkSaved) {
                collectionUpdated.worksheetItems.push(worksheetItem);
                collectionUpdated.worksheets.push(worksheet);
            }
        }
        let findWs = false;
        if (listCollectionUpdate?.length) {
            findWs = listCollectionUpdate?.find(
                (item) => item.collection.id == collectionUpdated.id
            );
        }
        if (!findWs) {
            listCollectionUpdate.push({
                collection: collectionUpdated,
                saved: checkSaved,
            });
        } else {
            listCollectionUpdate = listCollectionUpdate?.map((item) => {
                if (item.collection.id == collectionUpdated.id) {
                    item.collection = collectionUpdated;
                    item.saved = checkSaved;
                }
                return item;
            });
        }
    };
    const updateFc = async (item) => {
        let params: any = {};
        let collection = item.collection;
        let saved = item.saved;
        params.worksheetItems = collection.worksheetItems;
        params.collectionId = collection.id;
        let c = await updateItemCollectionByIdAPI(params);

        if (c.id) {
            let listWs = collection.worksheets;
            let newCollection = new Collection({
                ...collection,
                worksheets: [],
            });
            newCollection.worksheets = listWs;
            if (c?.parentIds?.length) {
                newCollection.parentIds = c?.parentIds;
            }

            if (actionGridItem != Config.ACTION_GRID.DEFAULT) {
                dispatch(updateCollectionResourceSuccessAction(newCollection));
            }

            return c;
        }
    };
    const saveToCollectionAndAssign = async () => {
        setLoading(true);
        const listCollectionUpdate = [];
        let success = 0;
        let totalSection = 0;
        if (listCollectionSave?.length) {
            totalSection = +1;
        }
        if (saveAssignments?.length) {
            totalSection = +1;
        }
        stringifyAndParse(listCollectionSave).forEach(
            async (collectionUpdated) => {
                if (worksheets?.length) {
                    worksheets.forEach((ws) => {
                        arrCollection({
                            collectionUpdated,
                            listCollectionUpdate,
                            worksheet: ws,
                            ableDelWs: worksheets?.length == 1 ? true : false,
                        });
                    });
                } else if (worksheet?.id) {
                    arrCollection({
                        collectionUpdated,
                        listCollectionUpdate,
                        worksheet: worksheet,
                        ableDelWs: true,
                    });
                }
            }
        );
        let resultResource = [];
        if (listCollectionUpdate?.length) {
            resultResource = await Promise.all(
                listCollectionUpdate.map(async (item) => {
                    let update = await updateFc(item);
                    return update;
                })
            );
            if (resultResource?.length == listCollectionUpdate?.length) {
                success = success + 1;
            }
        }
        if (saveAssignments?.length) {
            let result = await Promise.all(
                saveAssignments.map((assignment) => {
                    return updateAssignment(assignment);
                })
            );
            if (result?.length == saveAssignments?.length) {
                success = success + 1;
            }
        }
        let message = "";
        let severity = "success";
        let collectionId = "";
        let collection = null;
        let classAssignment = null;
        if (success == 2) {
            message = "All Saved!";
            collectionId = resultResource[0]?.id;
        } else if (success == 1) {
            if (listCollectionSave?.length > 1) {
                message = "Saved To All Selected Collections";
                collectionId = resultResource[0]?.id;
            } else if (listCollectionSave?.length == 1) {
                let collectionUpdate = resultResource[0] ?? new Collection();
                let wsThumbnail = collectionUpdate.worksheets.find(
                    (ws) => ws.thumbnail
                );
                if (!wsThumbnail) {
                    wsThumbnail = collectionUpdate.worksheets.find(
                        (ws) => ws.game?.images[0]
                    );
                }
                collectionUpdate.thumbnail = listCollectionUpdate[0].saved
                    ? !!collectionUpdate.thumbnail
                        ? collectionUpdate.thumbnail
                        : ""
                    : wsThumbnail?.thumbnail
                    ? wsThumbnail?.thumbnail
                    : worksheets[0]?.thumbnail;

                severity =
                    worksheets?.length > 1 || !listCollectionUpdate[0].saved
                        ? "success"
                        : "error";

                collectionId = collectionUpdate?.id;
                collection = collectionUpdate;
            } else if (saveAssignments?.length == 1) {
                const nbCreate = saveAssignments.filter(
                    (as) => as.status == Config.STATUS_PUBLIC
                );
                const nbDelete = saveAssignments.filter(
                    (as) => as.status == Config.STATUS_DELETED
                );

                let nbWorksheets = worksheets.length
                    ? worksheets.length
                    : worksheet?.id
                    ? 1
                    : 0;
                if (nbCreate.length == 0 && nbDelete.length == nbWorksheets) {
                    const nameClass = classes.find(
                        (cls) => cls.id == nbDelete[0].classId
                    );

                    collectionId = nbDelete[0].classId;
                    classAssignment = nameClass;
                    severity = "error";
                } else if (
                    (nbCreate.length == nbWorksheets && nbCreate.length) == 1 &&
                    nbDelete.length == 0
                ) {
                    const nameClass = classes.find(
                        (cls) => cls.id == nbCreate[0].classId
                    );
                    collectionId = nbCreate[0].classId;
                    classAssignment = nameClass;
                    severity = "success";
                }
            } else if (saveAssignments?.length > 1) {
                let success = true;
                const nbCreate = saveAssignments.filter(
                    (as) => as.status == Config.STATUS_PUBLIC
                );
                const nbDelete = saveAssignments.filter(
                    (as) => as.status == Config.STATUS_DELETED
                );

                if (worksheets?.length > 1) {
                    let uniqueArrayCreate = nbCreate
                        ?.map((as) => as.classId)
                        .filter(function (item, pos) {
                            return (
                                nbCreate
                                    ?.map((as) => as.classId)
                                    .indexOf(item) == pos
                            );
                        });
                    let uniqueArrayDelete = nbCreate
                        ?.map((as) => as.classId)
                        .filter(function (item, pos) {
                            return (
                                nbCreate
                                    ?.map((as) => as.classId)
                                    .indexOf(item) == pos
                            );
                        });
                    if (uniqueArrayCreate?.length == 1) {
                        severity = "success";
                    } else if (uniqueArrayDelete?.length == 1) {
                        severity = "false";
                    } else {
                        severity = "true";
                        message = "Saved To All Selected Classes";
                    }
                } else if (worksheets?.length == 1) {
                    if (nbCreate.length > 1 && nbDelete.length == 0) {
                        severity = "true";
                        message = "Saved To All Selected Classes";
                    } else if (nbCreate.length == 0 && nbDelete.length > 1) {
                        severity = "false";
                        message = "Removed From All Selected Classes";
                    }
                }

                const findClass = classes.find(
                    (cls) =>
                        cls.id ==
                        (nbDelete[0]?.classId ??
                            nbCreate[0]?.classId ??
                            saveAssignments[0]?.classId)
                );
                collectionId = findClass?.id;
                classAssignment = findClass;
            }
        }
        dispatch(
            updateAlertSnackBarAction({
                collectionId,
                message,
                severity,
                collection,
                classAssignment,
            })
        );
        setDone(true);
        setLoading(false);
    };
    useEffect(() => {
        if (done) {
            const timeout = setTimeout(() => {
                dispatch(updateAssignmentAction());

                dispatch(
                    selectGridItemAction({
                        collection: new Collection(),
                        worksheet: new Worksheet(),
                        toCollection: true,
                        worksheets: [],
                        afterAction: Config.ACTION_GRID.DEFAULT,
                    })
                );
                dispatch(
                    selectActionGridItemAction({
                        action: Config.ACTION_GRID.DEFAULT,
                    })
                );
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        } else {
            return;
        }
    }, [done]);
    return (
        <div className="footer-save-assign">
            <p
                className={
                    "btn-save-assign" +
                    (saveAssignments?.length || listCollectionSave?.length
                        ? " "
                        : " disable")
                }
                onClick={() => {
                    (saveAssignments?.length || listCollectionSave?.length) &&
                        saveToCollectionAndAssign();
                }}
            >
                {loading ? (
                    <div
                        style={{
                            paddingRight: "8px",
                        }}
                    >
                        <CircularProgress size={14} color={"inherit"} />
                    </div>
                ) : (
                    <></>
                )}{" "}
                Save
            </p>
        </div>
    );
};

export default FooterSaveAndAssign;
