import "./index.scss";
import { useEffect, useState } from "react";
import { IZoom } from "..";
import { ExpandIcon } from "../../icons/main-header";

const DEFAULT_VALUE = [45, 50, 60, 75, 100, 125, 200];

const Zoom = ({ zoomPayload }: { zoomPayload: IZoom }) => {
    let { ratio, updateRatio, defaultValue } = zoomPayload;
    const [showZoomInput, setShowZoomInput] = useState(false);
    const [showZoomDropdown, setShowZoomDropdown] = useState(false);
    const [zoomValue, setZoomValue] = useState(100);

    if (!defaultValue) {
        defaultValue = DEFAULT_VALUE;
    }

    useEffect(() => {
        setZoomValue(Math.floor(ratio * 100));
    }, [ratio]);

    const dispatchEventRatio = (value: number) => {
        if (value >= 10 && value <= 200) {
            updateRatio(value / 100);
        }
    };

    if (!zoomPayload) {
        return;
    }

    return (
        <>
            <div
                className="zoom-btn-container noselect"
                onClick={() => {
                    if (showZoomDropdown) {
                        setShowZoomDropdown(false);
                    }
                }}
            >
                {showZoomInput ? (
                    <input
                        type="number"
                        value={zoomValue}
                        style={{ fontFamily: "Nunito" }}
                        onChange={(e: any) => {
                            setZoomValue(e.target.value);
                            dispatchEventRatio(e.target.value);
                        }}
                        onKeyDown={(
                            event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                            if (showZoomInput) {
                                if (event.key === "Enter") {
                                    setShowZoomInput(false);
                                    dispatchEventRatio(zoomValue);
                                }
                            }
                        }}
                        onBlur={() => {
                            if (!zoomValue) {
                                setZoomValue(30);
                                dispatchEventRatio(30);
                            }
                            setShowZoomInput(false);
                        }}
                        autoFocus
                    />
                ) : (
                    <div
                        className="value"
                        onClick={() => setShowZoomInput(true)}
                    >
                        {zoomValue}
                    </div>
                )}
                <span>%</span>
                <div
                    className="expand-btn-container"
                    onClick={() => setShowZoomDropdown(!showZoomDropdown)}
                >
                    <ExpandIcon />
                </div>
                {showZoomDropdown && (
                    <div className="zoom-dropdown">
                        {defaultValue.map((value) => (
                            <div
                                className="zoom-value-item"
                                key={value}
                                onClick={() => {
                                    setZoomValue(value);
                                    setShowZoomDropdown(false);
                                    dispatchEventRatio(value);
                                }}
                            >
                                {value}%
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {showZoomDropdown && (
                <div
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: "0px",
                        left: "0px",
                        zIndex: 6,
                    }}
                    onClick={() => {
                        setShowZoomDropdown(false);
                    }}
                ></div>
            )}
        </>
    );
};

export default Zoom;
