import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../redux/hook';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';
import { updateDataAPI } from '../../../../../utils/query';
import { getDataFromAccessPath } from '../../../../../utils';

const AnswerKeyModal = ({hideModal}) => {
    const {template, ideaTemplate, pagesWorksheet, content, pageType, tempTemplateZones} = useAppSelector(state => state.createWorksheetState);

    const [answerKey, setAnswerKey] = useState(null);

    useEffect(() => {
        let answerFields = [], templateID = null;
        if (pageType === 'template') {
            answerFields = template.answerFields;
            templateID = template.id;
        } else if (pageType === 'page') {
            answerFields = ideaTemplate.templateData.answerFields;
            templateID = ideaTemplate.templateData.id;
        }
        fetchAnswerAccessPaths(answerFields, templateID);
    }, []);

    const fetchAnswerAccessPaths = async(answerFields, templateID) => {
        const zones = pagesWorksheet[0].resourceItems.filter(item => item.type === ConstantsTool.TYPE_RESOURCE_ZONE && item.fieldName);
        try {
            const res = await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                zones: zones.concat(tempTemplateZones).map(zone => ({
                    id: zone.id,
                    fieldName: zone.fieldName,
                    fieldIndex: zone.fieldIndex,
                    parentField: zone.parentField,
                    parentFieldIndex: zone.parentFieldIndex,
                })),
                answers: answerFields,
                templateID: templateID,
            });
            const answerAccessPaths = res.data['answerAccessPaths'];
            const answerKeyItems = [];
            for (let i = 0; i < answerAccessPaths.length; i++) {
                answerKeyItems.push(answerAccessPaths[i]['fieldName'].charAt(0).toUpperCase() + answerAccessPaths[i]['fieldName'].slice(1).replace('_', ' ') + ': ' + getDataFromAccessPath(content, answerAccessPaths[i]['accessPath']));
            }
            setAnswerKey(answerKeyItems.join('\n'));
        } catch (err) {
            console.log(err);
            window.alert('Error get answer key');
        }
    }

    return (
        <Modal show={true} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Answer Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control value={answerKey} as="textarea" rows={15}/>
            </Modal.Body>
        </Modal>
    )
}

export default AnswerKeyModal;