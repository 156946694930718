import CheckedBox from "../../../../assets/icon/checkedBox";
import UncheckedBox from "../../../../assets/icon/uncheckedBox";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { updateWorkSheetCreator } from "../../../../redux/reducers/createWorksheet";
import { setMessage } from "../../../../redux/reducers/snackbar";
import { IActivity } from "../../../../shared/models/WorkSheetCreator";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../shared/models/questionActivity";
import "./index.scss";

const CheckBox = ({ activity }: { activity: IActivity }) => {
    const dispatch = useAppDispatch();
    const questions: IQuestionActivity[] = activity.questions;
    const showAnswerKey = useAppSelector(
        (state) => state.createWorksheetState.currentActivity.showAnswerKey
    );

    const handleShowAnswerKey = () => {
        let isValidCorrectAnswer = questions.find(
            (question: IQuestionActivity) =>
                question.answers.find(
                    (answer: IAnswerActivity) => answer.isCorrect
                )
        );
        if (isValidCorrectAnswer) {
            dispatch(
                updateWorkSheetCreator({
                    activity: { showAnswerKey: showAnswerKey == 0 ? 1 : 0 },
                })
            );
        } else {
            dispatch(
                setMessage({
                    severity: "error",
                    message: "You need to choose at least one correct answer",
                })
            );
        }
    };

    return (
        <div className="entry-check-box">
            <div className="check-box-container">
                <div className="title">Show The Answer Key</div>
                <div
                    className="check-box"
                    onClick={() => handleShowAnswerKey()}
                >
                    {showAnswerKey !== 1 ? <UncheckedBox /> : <CheckedBox />}
                </div>
            </div>
        </div>
    );
};

export default CheckBox;
