import { Autocomplete, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import SearchIcon from "../../../../../resource-user/components/icons/search-icon";
import "./index.scss";
import { IActivity } from "../../../../../shared/models/WorkSheetCreator";
import PopupWarning from "../popup-warning";
import { useAppDispatch } from "../../../../../redux/hook";
import { updateWordScramble } from "../../../../../redux/async/wordScrambleThunk";
import wordScrambleData from "../../../../../data/wordScrambleData.json";
import { event, shuffleArray } from "../../util";
import { randomNumber } from "../../../../../resource-user/utils";
import useResizeHeight from "../../../customActivityHook/useResizeHeight";

const convertStrToWord = (str: string) => {
    const regex = /[^A-Za-z\n]/g;
    const invalidsSet = new Set();
    const result = str
        .split("\n")
        .map((value) => {
            const invalidCharacters = str.match(regex);
            invalidCharacters?.forEach((character) => {
                invalidsSet.add(character);
            });

            return value.replaceAll(regex, "");
        })
        .filter((value) => value.trim().length > 0);

    return {
        result,
        invalids: Array.from(invalidsSet) as string[],
    };
};

const convertWordToStr = (words: string[]) => {
    return words.join("\n");
};

const WordScrambleInput = ({ activity }: { activity: IActivity }) => {
    const dispatch = useAppDispatch();
    const questions = activity.questions;
    const data = useMemo(
        () =>
            wordScrambleData.map((d) => ({
                ...d,
                keyword: d.keyword.replace(" word scramble", ""),
            })),
        []
    );
    const [keyword, setKeyword] = useState("");
    const [str, setStr] = useState(
        convertWordToStr(questions.map((question) => question.question))
    );
    const [errorValue, setErrorValue] = useState([]);
    const height = useResizeHeight();

    const placeholder = `Type or paste your list of words here                                                                               
                                                                               
                                                                               
Example:                                                                               
Cat                                                                               
Pig                                                                               
Dog`;

    return (
        <div className="word-input-container">
            <div className="auto-complete-input">
                <Autocomplete
                    value={keyword}
                    clearOnBlur
                    handleHomeEndKeys
                    clearText={keyword ? "clear" : "search"}
                    autoComplete
                    includeInputInList
                    freeSolo
                    options={data.map((w) => {
                        return w.keyword
                            .split(" ")
                            .map((_w) => _w[0].toUpperCase() + _w.slice(1))
                            .join(" ");
                    })}
                    renderInput={(params) => (
                        <>
                            <TextField
                                className="text-field canvas-text"
                                placeholder="Topics"
                                {...params}
                                variant="outlined"
                            />
                        </>
                    )}
                    sx={{
                        width: "100%",
                    }}
                    ListboxProps={{
                        sx: {
                            fontFamily: "Nunito",
                        },
                    }}
                    onInputChange={(event, newValue) => {
                        setKeyword(newValue);
                    }}
                    onBlur={() => {
                        event(activity.type, "type_topic", {
                            value: keyword?.trim(),
                        });
                    }}
                    onChange={(e, value) => {
                        event(activity.type, "pick_a_topic", {
                            value: value?.trim(),
                        });
                        let words =
                            data.find(
                                (d) =>
                                    d.keyword.toUpperCase() ===
                                    value?.toUpperCase()
                            )?.words ?? [];
                        words = words.filter((w) => w.length < 16);
                        const max = randomNumber(13, 17);
                        words = Array.from(new Set(words));
                        words = shuffleArray(words)
                            .slice(0, max)
                            .map((w) => w.toUpperCase());
                        setStr(convertWordToStr(words));
                        dispatch(updateWordScramble({ words }));
                    }}
                    className="options-container"
                />
                {!keyword ? (
                    <div className="icon-search">
                        <SearchIcon />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="text-area-input">
                <TextField
                    inputProps={{
                        id: "input-wrapper",
                        style: {
                            height,
                        },
                    }}
                    // autoFocus
                    fullWidth
                    multiline
                    rows={9}
                    className="multi-text"
                    placeholder={placeholder}
                    value={str}
                    onChange={(e) => {
                        let value = e.target.value;
                        const { result, invalids } = convertStrToWord(value);

                        if (invalids.length > 0) {
                            value = convertWordToStr(result);
                            setErrorValue(
                                invalids.filter(
                                    (value) => value.trim().length > 0
                                )
                            );
                        }
                        setStr(value);
                        dispatch(updateWordScramble({ words: result }));
                    }}
                    onBlur={(e) => {
                        const words = convertStrToWord(str);
                        event(activity.type, "type_word_list", {
                            value: words.result,
                        });
                    }}
                />
            </div>
            <PopupWarning
                errorValue={errorValue}
                setErrorValue={setErrorValue}
            />
        </div>
    );
};

export default WordScrambleInput;
