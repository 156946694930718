import React, { MouseEvent } from "react";
import { RotateIcon } from "../../../../../assets/icon";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { isRotateLeft } from "../../../../../utils/draw";
import "./index.scss";
const checkEqual = (
    prevProps: {
        currentElement: IResourceItemNew;
        setActiveRotate: (isRotate: boolean) => void;
    },
    nextProps: {
        currentElement: IResourceItemNew;
        setActiveRotate: (isRotate: boolean) => void;
    }
) => {
    // because only pass set state functions which is static like dispatch
    // if add more props change this code below
    return (
        prevProps.currentElement.rotate === nextProps.currentElement.rotate ||
        prevProps.currentElement === nextProps.currentElement
    );
};
const RotateElement = ({
    setActiveRotate,
    currentElement,
}: {
    setActiveRotate: (value: boolean) => void;
    currentElement: IResourceItemNew;
}) => {
    let isLeft = isRotateLeft(currentElement);
    return (
        <div
            className={
                "rotate-container not-click " +
                (isLeft ? "rotate-left" : "rotate-top")
            }
            // style={{
            //     display: "flex",
            //     padding: "calc(32px - 4px)",
            //     position: "absolute",
            //     top: "100%",
            //     pointerEvents: "none",
            // }}
        >
            <div
                style={{
                    alignItems: "center",
                    cursor: "inherit",
                    display: "flex",
                    height: "32px",
                    justifyContent: "center",
                    margin: "2px",
                    position: "relative",
                    width: "32px",
                    pointerEvents: "none",
                }}
            >
                <div
                    style={{
                        borderRadius: "50%",
                        height: "24px",
                        width: "24px",
                        position: "absolute",
                        pointerEvents: "auto",
                        cursor: "pointer",
                    }}
                    onMouseDown={(event: MouseEvent) => {
                        event.stopPropagation();
                        setActiveRotate(true);
                    }}
                ></div>
                <span
                    style={{
                        background: "#fff",
                        borderRadius: "50%",
                        boxShadow:
                            "0 0 4px 1px rgba(57,76,96,.15), 0 0 0 1px rgba(43,59,74,.3)",
                        height: "24px",
                        width: "24px",
                    }}
                >
                    <RotateIcon></RotateIcon>
                </span>
            </div>
        </div>
    );
};

export default React.memo(RotateElement, checkEqual);
