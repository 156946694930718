import Config from "../utils/config";
export interface IParamsQuotaResourceUser {
    userId: string;
    times?: number;
    timeNextDownload?: Date;
    timeClaimed?: Date;
    inviteFriend?: number;
    sharePin?: number;
    verifySharePin?: number;
    dailyTimes?: number;
    bonusQuota?: number;
    limitDownload?: number;
    quotaDownload?: number;
}
export default interface IQuotaResourceUser {
    id: string;
    userId: string;
    quotaDownload: number;
    limitDownload: number;
    updatedAt: Date;
    createdAt: Date;
    times: number;
    timeNextDownload: Date;
    timeClaimed: Date;
    inviteFriend?: number;
    sharePin?: number;
    verifySharePin?: number;
    dailyTimes?: number;
    bonusQuota?: number;
}

export class QuotaResourceUser implements IQuotaResourceUser {
    id: string;
    userId: string;
    quotaDownload: number;
    limitDownload: number;
    updatedAt: Date;
    createdAt: Date;
    times: number;
    timeNextDownload: Date;
    timeClaimed: Date;

    inviteFriend?: number;
    sharePin?: number;
    verifySharePin?: number;
    dailyTimes?: number;
    bonusQuota?: number;

    constructor(obj: any = {}) {
        this.id = obj.id ?? undefined;
        this.userId = obj.userId ?? "";
        this.quotaDownload = obj.quotaDownload ?? Config.INIT_QUOTA_DOWNLOAD;
        this.limitDownload = obj.limitDownload ?? Config.INIT_QUOTA_DOWNLOAD;
        this.updatedAt = obj.updatedAt ?? new Date();
        this.createdAt = obj.createdAt ?? new Date();
        this.timeNextDownload = obj.timeNextDownload ?? undefined;
        this.times = obj.times ?? Config.INIT_TIMES_DOWNLOAD;
        this.timeClaimed = obj.timeClaimed ?? new Date();
        this.inviteFriend = obj.inviteFriend ?? 0;
        this.sharePin = obj.sharePin ?? 0;
        this.verifySharePin = obj.verifySharePin ?? 0;
        this.dailyTimes = obj.dailyTimes ?? 0;
        this.bonusQuota = obj.bonusQuota ?? 0;
    }
}
