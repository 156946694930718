import "./style.scss";
const CircleProgressCustomize = () => {
    return (
        <div className="loading-component loading-component-circular-progress">
            <span
                className="circular-progress"
                role="progressbar"
                style={{ width: 40, height: 40 }}
            >
                <svg className="circular-progress-svg " viewBox="22 22 44 44">
                    <circle
                        className="circular-progress-circle "
                        cx="44"
                        cy="44"
                        r="20.25"
                        fill="none"
                        strokeWidth="3.5"
                    ></circle>
                </svg>
            </span>
        </div>
    );
};

export default CircleProgressCustomize;
