export interface IUserRela {
    id: any;
    userId: string;
    followerId: string;
    followDate: Date;
}

export class UserRela implements IUserRela {
    id: any;
    userId: string;
    followerId: string;
    followDate: Date;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.userId = object.userId ?? null;
        this.followerId = object.followerId ?? null;
        this.followDate = object.followDate && new Date(object.followDate);
    }
}
