import React from "react";
import PopupCommon from "../../../../../resource-user/components/common/pop-up";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
    updateOpenWarningReplaceTemplate
} from "../../../../../redux/reducers/createWorksheet";
import { addTemplateToPersist } from "../../../../../redux/reducers/recentUse";
import { requestInitPageWs } from "../../../../../redux/async/createWorksheet";
import Config from "../../../../../shared/utils/config";
import { toggleShowListActivities } from "../../../../../redux/reducers/leftData";

function PopupRelaceWorksheet({
    dataTab,
    setCurrentElment,
}: {
    dataTab: any;
    setCurrentElment: any;
}) {
    const openWarningReplaceTemplate = useAppSelector(
        (state) => state.createWorksheetState.openWarningReplaceTemplate
    );
    const dispatch = useAppDispatch();
    const selectedWorksheetId = useAppSelector(
        (state) => state.createWorksheetState.selectedWorksheetId
    );
    const currentTab = useAppSelector((state) => state.leftDataState.activeTab);
    const activityToReplace = useAppSelector(
        (state) => state.leftDataState.activityToReplace
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const selectedTemplate = dataTab.find(
        (el) => el.id === selectedWorksheetId
    );
    const types = [
        Config.USER_CREATED_TOOL_V2_WORD_SEARCH,
        Config.USER_CREATED_TOOL_V2_HAND_WRITING,
        Config.USER_CREATED_TOOL_V2_NAME_TRACING,
        Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK,
        Config.USER_CREATED_WORD_SEARCH,
        Config.USER_CREATED_TOOL_V2_ADDITION,
        Config.USER_CREATED_TOOL_V2_SUBTRACTION,
        Config.USER_CREATED_TOOL_V2_MULTIPLICATION,
        Config.USER_CREATED_TOOL_V2_DIVISION,
    ];

    let isAddNewPage = false;
    if (
        currentActivity.type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE ||
        currentActivity.type === ""
    ) {
        isAddNewPage = !types.includes(selectedTemplate?.originUrl);
        if (
            currentTab === Config.RESOURCE_MODULES &&
            activityToReplace !== Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE &&
            activityToReplace !== ""
        ) {
            isAddNewPage = false;
        }
    }

    let isRelaceAllPage =
        currentActivity.type !== Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE &&
        currentActivity.type !== "";

    const onCancel = () => {
        dispatch(updateOpenWarningReplaceTemplate());
    };

    const onAccept = (isAddNewPage?: boolean) => {
        if (
            currentTab === Config.RESOURCE_TEMPLATE ||
            currentTab === Config.RESOURCE_PROJECT
        ) {
            setCurrentElment(null);
            if (currentTab === Config.RESOURCE_TEMPLATE) {
                dispatch(addTemplateToPersist(selectedTemplate));
            }
            dispatch(
                requestInitPageWs({
                    worksheetId: selectedWorksheetId,
                    isUserAddTemplate: true,
                    isAddNewPage: isAddNewPage,
                    isRelaceAllPage,
                    sourcePage: Config.SOURCE_USER_BEHAVIOR.PICK_TEMPLATE
                })
            );

            if (selectedTemplate?.type === Config.TEMPLATE_WORKSHEET) {
                dispatch(toggleShowListActivities(true));
            } else {
                // dispatch(
                //     changeActiveTab(
                //         Config.RESOURCE_MODULES
                //     )
                // );
                dispatch(toggleShowListActivities(false));
            }
        } else if (currentTab === Config.RESOURCE_MODULES) {
            if (activityToReplace !== Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE) {
                dispatch(toggleShowListActivities(false));
                dispatch(
                    requestInitPageWs({
                        activityToInit: activityToReplace,
                        sourcePage: Config.SOURCE_USER_BEHAVIOR.PICK_TEMPLATE
                    })
                );
            }
            else {
                dispatch(requestInitPageWs({
                    activityToInit: activityToReplace,
                    isAddNewPage: isAddNewPage,
                    isRelaceAllPage,
                    sourcePage: Config.SOURCE_USER_BEHAVIOR.CREATE_FROM_SCRATCH
                }))
            }
        }
        dispatch(updateOpenWarningReplaceTemplate());
    };
    const styleText: React.CSSProperties = isAddNewPage
        ? { padding: "12px 8px", whiteSpace: "nowrap", width: 170 }
        : null;

    const getContentText = () => {
        if (isAddNewPage) {
            if (currentTab === Config.RESOURCE_TEMPLATE) {
                return "Add templates as new page?"
            }
            else {
                return "Add new page?"
            }
        }
        else {
            return "Are you sure want to replace current page?"
        }
    }
    const getContentButtonOk = () => {
        if (isAddNewPage && currentTab === Config.RESOURCE_TEMPLATE) {
            return "Add as new page?"
        }
        else {
            return "OK"
        }
    }
    return (
        <PopupCommon
            classNameDialog="popup-confirm-reset-puzzle"
            classNameTitle="popup-confirm-reset-puzzle__title"
            open={openWarningReplaceTemplate}
            setOpen={(value) => {
                if (!value) {
                    onCancel();
                }
            }}
            title="Confirm"
            contentText={getContentText()}
            renderAction={() => {
                return (
                    <>
                        {/* <div className="popup-confirm-reset-puzzle__btn-action"> */}
                        <p
                            className="btn-action cancel"
                            onClick={() => {
                                if (isAddNewPage) {
                                    onAccept();
                                } else {
                                    onCancel();
                                }
                            }}
                            style={styleText}
                        >
                            {isAddNewPage ? "Replace current page" : "Cancel"}
                        </p>
                        <p
                            className="btn-action oke"
                            onClick={() => {
                                onAccept(isAddNewPage);
                            }}
                            style={styleText}
                        >
                            {getContentButtonOk()}
                        </p>
                        {/* </div> */}
                    </>
                );
            }}
        />
    );
}

export default PopupRelaceWorksheet;
