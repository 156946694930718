export const Solid = () => {
    return (
        <svg
            width="30"
            height="2"
            viewBox="0 0 30 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="8.74228e-08"
                y1="1"
                x2="30"
                y2="1"
                stroke="black"
                strokeWidth="2"
            />
        </svg>
    );
};

export const Dashed = () => {
    return (
        <svg
            width="30"
            height="2"
            viewBox="0 0 30 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="8.74228e-08"
                y1="1"
                x2="30"
                y2="1"
                stroke="black"
                strokeWidth="2"
                strokeDasharray="4 4"
            />
        </svg>
    );
};

export const Dotted = () => {
    return (
        <svg
            width="30"
            height="2"
            viewBox="0 0 30 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="8.74228e-08"
                y1="1"
                x2="30"
                y2="1"
                stroke="black"
                strokeWidth="2"
                strokeDasharray="2 2"
            />
        </svg>
    );
};
