import React from "react";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../shared/models/resourceItemNew";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import "./index.scss";
import TextLeftTabElement from "./text-left-tab-element";
import Equation from "./equation";

const TEXT_ELEMENTS_CONFIG = [
    new ResourceItemNew({
        id: Math.floor(Math.random() * 999999999),
        textAttribute: {
            content: "Add a heading",
            fontFamily: "ComicNeueSansID",
            fontSize: 40,
            align: "center",
        },
        type: ConstantsTool.RESOURCE_TEXT,
    }),
    new ResourceItemNew({
        id: Math.floor(Math.random() * 999999999),
        textAttribute: {
            content: "Add a subheading",
            fontFamily: "ComicNeueSansID",
            fontSize: 30,
            align: "center",
        },
        type: ConstantsTool.RESOURCE_TEXT,
    }),
    new ResourceItemNew({
        id: Math.floor(Math.random() * 999999999),
        textAttribute: {
            content: "Add a little bit of body text",
            fontFamily: "ComicNeueSansID",
            fontSize: 18,
            align: "center",
        },
        type: ConstantsTool.RESOURCE_TEXT,
    }),
];

const TextLeftTabContent = () => {
    return (
        <div className="default-text-container">
            <div className="title-functional">Default text styles</div>
            {TEXT_ELEMENTS_CONFIG.map(
                (item: IResourceItemNew, index: number) => {
                    return (
                        <TextLeftTabElement
                            key={index}
                            item={item}
                            index={index}
                        />
                    );
                }
            )}
            <div className="title-functional">
                <div style={{ marginTop: "32px" }}>Equation</div>
            </div>
            <Equation />
        </div>
    );
};
export default React.memo(TextLeftTabContent);
