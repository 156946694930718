import { useDispatch, useSelector } from "react-redux";
import { IParamsQuotaResourceUser } from "../../../../../shared/models/quotaResourceuser";
import {
    updateQuotaUserAction,
    updateUserSharePinAction,
} from "../../../../redux/action/quotaResourceUser.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { urlWorksheet } from "../../../../utils";

const ShareMultiPinterestResource = ({
    children,
    worksheets,
}: {
    children: any;
    worksheets: any[];
}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data
    );
    const sharePin = useSelector(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.sharePin
    );
    const shareToPinterest = (url) => {
        const pinterestURL = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
            url
        )}`;
        window.open(pinterestURL, "_blank", "width=600,height=400"); // Open Pinterest share dialog for each URL
    };

    const handleShareClick = () => {
        // let groupWs = groupWsFc({ worksheets });
        // const numberWs = Object.keys(groupWs).length;
        const numberWs = worksheets?.length;
        if (numberWs) {
            const linksToShare = worksheets?.map((ws) => {
                let link = urlWorksheet(ws);
                link =
                    window.location.origin +
                    link +
                    "?utm_source=pinterest&utm_medium=share_from_multiple&utmid=" +
                    userInfo.id;
                return link;
            });

            let params: IParamsQuotaResourceUser = {
                userId: userInfo.id,
            };
            params = {
                ...params,
                sharePin: (sharePin ?? 0) + numberWs,
            };
            dispatch(updateQuotaUserAction({ params }));
            dispatch(updateUserSharePinAction({ links: linksToShare }));

            linksToShare.forEach((link) => {
                shareToPinterest(link);
            });
        }
    };
    return (
        <div
            onClick={() => {
                handleShareClick();
            }}
        >
            {children}
        </div>
    );
};

export default ShareMultiPinterestResource;
