import React, { Fragment } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import Config from '../../../../shared/utils/config';
import ResourceItemComponent from '../ResourceItem';
import { Button } from '@mui/material';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import axios from 'axios';
import { requestGetResourceByType } from '../../../../redux/async/leftData';

function TabUploadView({
    activeTab,
    dataTab,
    listIdBackgroundUsing,
}: {
    dataTab: any[];
    activeTab: number;
    listIdBackgroundUsing: string[];
}) {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.userInfoResourceState.data.id);

    const handleUploadFile = async ( event: React.ChangeEvent<HTMLInputElement> ) => {
        if (!event.target.files) {
            return;
        }

        const formData = new FormData();
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
            useWebWorker: true,
        }
        const imageFile = event.target.files[0];

        try {
            formData.append('file', imageFile);

            await axios.post(ConstantsTool.API_URL + 'auto-content/upload-local-image-to-s3/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
                },
            });

            window.alert('Upload successfully');
            dispatch(
                requestGetResourceByType({
                    typeResource: [activeTab],
                    limit: ConstantsTool.LIMIT_RESOURCE + 1000,
                    userId: userId,
                    offset: 1,
                })
            )
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Fragment>
            <div className="button-upload">
                <Button
                    variant="contained"
                    className="button-mui"
                    component="label"
                >
                    File Upload
                    <input
                        hidden
                        accept="image/*,.pdf"
                        type="file"
                        multiple
                        onClick={(event) => {
                            event.currentTarget.value = null;
                        }}
                        onChange={handleUploadFile}
                    />
                </Button>
            </div>
            {!dataTab.length && (
                <div className="no-data">You haven't upload anything!</div>
            )}
            <div className="list-resource-item">
                {dataTab
                    .filter(
                        (resourceItem) =>
                            !listIdBackgroundUsing.includes(resourceItem.id)
                    )
                    .map((resourceItem) => {
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={
                                    activeTab === Config.RESOURCE_TEMPLATE
                                        ? "template-resource-item"
                                        : "resource-item"
                                }
                            ></ResourceItemComponent>
                        );
                    })}
            </div>
        </Fragment>
    );
}

export default TabUploadView;
