import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    moveCollectionResourceAction,
    moveWorksheetResourceSuccessAction,
    removeWorksheetOfCollectionAction,
    updateAlertResourceAction,
    updateNumberWorksheetOfCollectionAction,
    // } from "../../../resource-user/redux/action/resource.action";
} from "../../redux/action/resource.action";
import { ICollection, WorksheetItem } from "../../../shared/models/collection";
import { IWorksheet } from "../../../shared/models/worksheet";
import Config from "../../../shared/utils/config";
import {
    moveWorksheetToCollectionByIdAPI,
    updateItemCollectionByIdAPI,
} from "../../redux/repositories/collection.repositories";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";

const FooterPopupMoveCollection = ({
    collection,
    collectionSelected,
    worksheet,
    setShowPopup,
}: {
    collection?: ICollection;
    collectionSelected: ICollection;
    worksheet?: IWorksheet;
    setShowPopup: Function;
}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const collections = useSelector(
        (state: ResourceAppState) => state.resourceState.collections?.data
    );
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );
    const moveCollection = () => {
        setLoading(true);

        dispatch(moveCollectionResourceAction(collection, collectionSelected));
        setTimeout(() => {
            setLoading(false);
            setShowPopup(false);
        }, 1000);
    };
    const moveWorksheet = async () => {
        if (collectionSelected?.id != collection?.id) {
            setLoading(true);
            let params: any = [];
            let wsItem = new WorksheetItem({
                worksheetId: worksheet.id,
                joinedDate: new Date(),
            });
            params = [
                {
                    collectionId: collection.id,
                    worksheetItems: collection.worksheetItems.filter(
                        (w) => w.worksheetId != worksheet.id
                    ),
                },
                {
                    collectionId: collectionSelected?.id,
                    worksheetItems: [
                        ...collectionSelected.worksheetItems,
                        wsItem,
                    ],
                },
            ];

            let result = await moveWorksheetToCollectionByIdAPI(params);
            if (result.length) {
                dispatch(
                    moveWorksheetResourceSuccessAction({
                        worksheet,
                        collection: collectionSelected,
                    })
                );
                dispatch(
                    updateAlertResourceAction(
                        true,
                        "Move success",
                        Config.SUCCESS,
                        Config.TYPE_ALERT.ALERT_ACTION_WEB
                    )
                );
            }
            setTimeout(() => {
                setLoading(false);
                setShowPopup(false);
            }, 1000);
        } else {
            let params: any = {};
            params.collectionId = collectionSelected.id;
            params.worksheetItems = collectionSelected.worksheetItems.filter(
                (w) => w.worksheetId != worksheet.id
            );
            let result = await updateItemCollectionByIdAPI(params);
            let parentIds = collectionSelected?.parentIds;
            let parentIdCollection = collectionSelected?.id;
            let ids = collections?.map((c) => c.id);
            if (parentIds?.length) {
                parentIdCollection = parentIds?.find((id) => ids?.includes(id));
            }
            dispatch(
                removeWorksheetOfCollectionAction({
                    worksheet: worksheet?.id ? worksheet : null,
                })
            );
            dispatch(
                updateAlertResourceAction(
                    true,
                    "Move success",
                    Config.SUCCESS,
                    Config.TYPE_ALERT.ALERT_ACTION_WEB
                )
            );
            dispatch(
                updateNumberWorksheetOfCollectionAction({
                    numberOfWs: -1,
                    collectionId: parentIdCollection,
                })
            );
            dispatch(
                updateAlertResourceAction(
                    true,
                    "Delete success",
                    Config.ERROR,
                    Config.TYPE_ALERT.ALERT_ACTION_WEB
                )
            );
            setLoading(false);
            setTimeout(() => {
                setShowPopup(false);
            }, 1000);
        }
    };

    return (
        <div className="btn-action-collection move">
            <div
                className="btn create"
                // onClick={() => {
                //     setShowPopupCreateCollection(true);
                //     setShowPopup(false);
                // }}
            >
                {/* <CreateIconCollection />
                New Collection */}
            </div>
            <div
                className={
                    "btn move " +
                    // (collectionSelected?.id &&
                    // collection?.id != collectionSelected?.id
                    //     ? ""
                    //     : " disable")
                    (!collectionSelected?.id &&
                    actionGridItem?.action == Config.ACTION_GRID.MOVE_WORKSHEET
                        ? " disable"
                        : " ")
                }
                onClick={() => {
                    if (worksheet?.id && collectionSelected?.id) {
                        moveWorksheet();
                    }
                    if (collection?.id && !worksheet?.id) {
                        moveCollection();
                    }
                    // setTimeout(() => {
                    //     setShowPopup(false);
                    // }, 1000);
                }}
            >
                {loading && (
                    <CircularProgress
                        style={{
                            paddingRight: "8px",
                        }}
                        size={14}
                        color={"inherit"}
                    />
                )}
                {loading ? "Moving" : " Move"}
            </div>
        </div>
    );
};

export default FooterPopupMoveCollection;
