import { ListStyle } from "../shared/models/WorkSheetCreator";
import {
    AttributesResourceItem,
    IResourceItemNew,
    ResourceItemNew,
    TextAttribute,
} from "../shared/models/resourceItemNew";
import { IShapeAttribute } from "../shared/models/shapeAttribute";
import { TableAttribute } from "../shared/models/tableAttribute";
import ConstantsTool from "../shared/utils/ConstantsTool";
import {
    canDeleteElement,
    canDragElement,
    generateIdFromDateTime,
    getSizeResourceText,
    isResourceBackground,
    isResourceLine,
    isResourceShape,
    makeChangeLine,
    resourceCanEditTextGroup, updateObject
} from "../utils";
import {
    convertPointsToBox,
    findOriginalVertices,
    getPositionBox,
    getSizeResourceItem,
    rotateBox,
} from "../utils/align";
import { changeStyleText } from "../utils/edit-text-attribute";
import { changeFontAttributes } from "../utils/table";

interface IStore {
    resourceItemsSelected: IResourceItemNew[];
    pageIndex: number;
    isOnDragGroup: boolean;
    startPoint: { x: number; y: number };
    endPoint: { x: number; y: number };
    resourceGroupTmp: IResourceItemNew | null;
    resourceItemsCurrent: IResourceItemNew[];
    selectedId: string;
    isChangeLine: boolean | null;
}

let store: IStore = {
    resourceItemsSelected: [],
    pageIndex: 0,
    isOnDragGroup: false,
    startPoint: null,
    endPoint: null,
    resourceGroupTmp: null,
    resourceItemsCurrent: [],
    selectedId: "",
    isChangeLine: null,
};

let subscribers: any = new Set();

const groupElementStore = {
    read() {
        return store;
    },
    subscribe(callback: any) {
        subscribers.add(callback);
        return () => subscribers.delete(callback);
    },
    updateSelectedId: (id: string) => {
        let arrayCheck = store.resourceItemsSelected.filter(
            (item) => item.type !== ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
        );
        if (!arrayCheck.find((item) => !item.isGroup)) {
            store.selectedId = id;
            notifyListener();
        } else {
            store.selectedId = null;
        }
    },
    addResourceItem(
        resourceItem: IResourceItemNew,
        resourceItems: IResourceItemNew[]
    ) {
        store.resourceItemsSelected.map((item) => {
            let findItem = resourceItems.find((e) => e.id === item.id);
            if (findItem) {
                updateObject(item, findItem);
            }
        });
        if (
            !store.resourceItemsSelected.find(
                (element) => element.id === resourceItem.id
            ) &&
            resourceItem.id !== store.resourceGroupTmp?.id
        ) {
            if (resourceItem.isGroup) {
                let findElement = resourceItems.find((e) =>
                    e.resourceIds.includes(resourceItem.id)
                );
                if (findElement) {
                    store.resourceItemsSelected.push(
                        JSON.parse(JSON.stringify(findElement))
                    );
                    findElement.resourceIds.forEach((resourceId) => {
                        let element = resourceItems.find(
                            (element) => element.id === resourceId
                        );
                        if (element) {
                            store.resourceItemsSelected.push(
                                JSON.parse(JSON.stringify(element))
                            );
                        }
                    });
                }
            } else {
                store.resourceItemsSelected.push(
                    JSON.parse(JSON.stringify(resourceItem))
                );
            }
            if (resourceItem.resourceIds.length) {
                resourceItem.resourceIds.forEach((resourceId) => {
                    let element = resourceItems.find(
                        (element) => element.id === resourceId
                    );
                    if (element) {
                        store.resourceItemsSelected.push(
                            JSON.parse(JSON.stringify(element))
                        );
                    }
                });
            }
            if (
                store.resourceGroupTmp?.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT &&
                !store.resourceGroupTmp.id?.includes("tmp-")
            ) {
                let findItem = resourceItems.find(
                    (e) => e.id === store.resourceGroupTmp.id
                );
                if (findItem) {
                    store.resourceItemsSelected.push(
                        JSON.parse(JSON.stringify(findItem))
                    );
                }
            }
            store.resourceItemsCurrent = [];
            notifyListener();
        }
    },
    addResourceItems(resourceItems: IResourceItemNew[]) {
        store.resourceItemsSelected = [];
        resourceItems.forEach((resourceItem) => {
            if (
                !isResourceBackground(resourceItem.type) &&
                canDragElement(resourceItem) &&
                canDeleteElement(resourceItem) &&
                resourceItem.isShow &&
                resourceItem.id !== store.resourceGroupTmp?.id
            ) {
                store.resourceItemsSelected.push(
                    JSON.parse(JSON.stringify(resourceItem))
                );
                if (resourceItem.resourceIds.length) {
                    resourceItem.resourceIds.forEach((resourceId) => {
                        let element = resourceItems.find(
                            (element) => element.id === resourceId
                        );
                        if (element) {
                            store.resourceItemsSelected.push(
                                JSON.parse(JSON.stringify(element))
                            );
                        }
                    });
                }
            }
        });
        store.resourceItemsCurrent = JSON.parse(
            JSON.stringify(store.resourceItemsSelected)
        );
        notifyListener();
    },
    updatePageIndex(pageIndex: number) {
        store.pageIndex = pageIndex;
        store.resourceItemsSelected = [];
        notifyListener();
    },
    resetResourceItems() {
        store.resourceItemsSelected = [];
        store.resourceItemsCurrent = [];
        store.resourceGroupTmp = null;
        notifyListener();
    },
    deleteResourceItem: (id: string) => {
        let index = store.resourceItemsSelected.findIndex(
            (item) => item.id === id
        );
        if (index < store.resourceItemsSelected.length) {
            store.resourceItemsSelected.splice(index, 1);
            notifyListener();
        }
    },
    updateOnDragGroup(isOnDragGroup: boolean) {
        store.isOnDragGroup = isOnDragGroup;
        notifyListener();
    },
    updateStartPoint(x: number, y: number) {
        store.startPoint = { x, y };
        store.endPoint = null;
        notifyListener();
    },
    resetPoints: () => {
        store.endPoint = null;
        store.startPoint = null;
        notifyListener();
    },
    updateEndPoint(
        xend: number,
        yend: number,
        resourceItems: IResourceItemNew[]
    ) {
        store.endPoint = { x: xend, y: yend };
        let { x, y, width, height } = getPositionBox({
            startPoint: store.startPoint,
            endPoint: store.endPoint,
        });
        let maxX = x + width,
            maxY = y + height;
        store.resourceItemsSelected = [];
        resourceItems.forEach((resourceItem) => {
            if (
                !isResourceBackground(resourceItem.type) &&
                canDragElement(resourceItem) &&
                canDeleteElement(resourceItem) &&
                resourceItem.isShow &&
                !resourceItem.isGroup
            ) {
                let xe = resourceItem.x,
                    ye = resourceItem.y,
                    maxXe = xe + resourceItem.width,
                    maxYe = ye + resourceItem.height;
                if (
                    (xe <= maxX && ye <= maxY && xe >= x && ye >= y) ||
                    (maxXe >= x &&
                        maxXe <= maxX &&
                        maxYe >= y &&
                        maxYe <= maxY) ||
                    (maxYe >= y && maxYe <= maxY && xe >= x && xe <= maxX) ||
                    (maxXe >= x && maxXe <= maxX && ye >= y && ye <= maxY) ||
                    (x >= xe && maxX <= maxXe && y >= ye && y <= maxYe) ||
                    (x >= xe && x <= maxXe && y >= ye && maxY <= maxYe) ||
                    (maxX > xe && maxX <= maxXe && y >= ye && maxY <= maxYe) ||
                    (x >= xe && maxX <= maxXe && maxY >= ye && maxY <= maxYe) ||
                    (x >= xe && maxX <= maxXe && ye >= y && maxYe <= maxY) ||
                    (y >= ye && maxY <= maxYe && xe >= x && maxXe <= maxX)
                ) {
                    store.resourceItemsSelected.push(resourceItem);
                    if (resourceItem.resourceIds.length) {
                        resourceItem.resourceIds.forEach((resourceId) => {
                            let element = resourceItems.find(
                                (element) => element.id === resourceId
                            );
                            if (element) {
                                store.resourceItemsSelected.push(element);
                            }
                        });
                    }
                }
            }
        });
        notifyListener();
    },
    makeResourceItemGroup(isUpdate?: boolean, isDelete?: boolean) {
        let result = null;
        if (isDelete != true) {
            let listItemGroup = store.resourceItemsSelected.filter(
                (item) =>
                    item.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
            );
            let arrayCheck = store.resourceItemsSelected.filter(
                (item) =>
                    item.type !== ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
            );
            if (
                !arrayCheck.find((item) => !item.isGroup) &&
                listItemGroup.length === 1
            ) {
                return null;
            }
        }
        store.resourceItemsSelected = store.resourceItemsSelected.map(
            (resourceItem, index) => {
                let item = store.resourceItemsCurrent.find(
                    (e) => e.id === resourceItem.id
                );
                if (item) {
                    return item;
                }
                return resourceItem;
            }
        );

        if (store.resourceItemsSelected.length) {
            let sizes: { x: number; y: number }[] = [];
            store.resourceItemsSelected.forEach((resourceItem) => {
                let size = getSizeResourceItem(
                    resourceItem.x,
                    resourceItem.y,
                    resourceItem.width,
                    resourceItem.height,
                    resourceItem.rotate
                );
                sizes.push({ x: size.x, y: size.y });
                sizes.push({ x: size.x + size.width, y: size.y + size.height });
            });
            let { x, y, width, height } = convertPointsToBox(sizes);
            let id = generateIdFromDateTime();
            if (
                store.resourceItemsSelected.find(
                    (resourceItem) => !resourceItem.isGroup
                )
            ) {
                id = "tmp-group-" + id;
            }
            id = isUpdate ? store.resourceGroupTmp.id : id;
            result = new ResourceItemNew({
                id: id,
                x,
                y,
                width,
                height,
                resourceIds: store.resourceItemsSelected.map(
                    (resourceItem) => resourceItem.id
                ),
                type: ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT,
                pageIndex: store.resourceItemsSelected[0].pageIndex,
            });
        }
        store.startPoint = null;
        store.endPoint = null;
        store.resourceGroupTmp = JSON.parse(JSON.stringify(result));
        store.resourceItemsSelected = JSON.parse(
            JSON.stringify(store.resourceItemsSelected)
        );
        store.resourceItemsCurrent = JSON.parse(
            JSON.stringify(store.resourceItemsSelected)
        );
        return result;
    },
    resizeElements: (params: {
        x: number;
        y: number;
        width: number;
        height: number;
        rotate: number;
    }) => {
        let { x, y, width, height, rotate } = params;
        if (store.resourceGroupTmp) {
            let parentElement = store.resourceGroupTmp;
            let size = getSizeResourceItem(
                parentElement.x,
                parentElement.y,
                parentElement.width,
                parentElement.height,
                rotate
            );
            let dxR = size.points[0].x - store.resourceGroupTmp.x,
                dyR = size.points[0].y - store.resourceGroupTmp.y,
                dr = rotate - store.resourceGroupTmp.rotate,
                dWidth = width - store.resourceGroupTmp.width,
                dHeight = height - store.resourceGroupTmp.height,
                dx = x - store.resourceGroupTmp.x,
                dy = y - store.resourceGroupTmp.y;
            store.resourceItemsCurrent.map((resourceItem, index) => {
                if (index < store.resourceItemsSelected.length) {
                    let element = store.resourceItemsSelected[index];
                    let points = rotateBox(
                        ((element.x - x + dx) * width) / parentElement.width,
                        ((element.y - y + dy) * height) / parentElement.height,
                        element.width,
                        element.height,
                        rotate
                    );
                    points.map((point) => {
                        point.x += dxR;
                        point.y += dyR;
                    });
                    let size = findOriginalVertices(points, rotate);
                    resourceItem.x = size.x + x;
                    resourceItem.y = size.y + y;
                    resourceItem.width =
                        element.width +
                        (dWidth * element.width) / parentElement.width;
                    resourceItem.height =
                        element.height +
                        (dHeight * element.height) / parentElement.height;
                    resourceItem.rotate = element.rotate + dr;
                }
            });
            notifyListener();
        }
    },
    getSizeResourceItems: (): AttributesResourceItem[] => {
        return store.resourceItemsCurrent.map((resourceItem, index) => {
            return new AttributesResourceItem(resourceItem.id, [
                { attribute: "x", value: resourceItem.x },
                { attribute: "y", value: resourceItem.y },
                { attribute: "width", value: resourceItem.width },
                { attribute: "height", value: resourceItem.height },
                { attribute: "rotate", value: resourceItem.rotate },
            ]);
        });
    },
    duplicateElements: (resourceGroupTmp: IResourceItemNew) => {
        let items = [];
        if (
            store.resourceItemsSelected.find(
                (resourceItem) =>
                    resourceItem.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
            )
        ) {
            store.resourceItemsCurrent.forEach((resourceItem) => {
                if (
                    resourceItem.type ===
                    ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                ) {
                    let ids = [];
                    resourceItem.resourceIds.forEach((id) => {
                        let item = store.resourceItemsCurrent.find(
                            (resource) => resource.id === id
                        );
                        if (item) {
                            let id = generateIdFromDateTime();
                            ids.push(id);
                            items.push(
                                new ResourceItemNew({
                                    ...item,
                                    id: id,
                                    x: item.x + 20,
                                    y: item.y + 20,
                                })
                            );
                        }
                    });
                    items.push(
                        new ResourceItemNew({
                            ...resourceItem,
                            id: generateIdFromDateTime(),
                            x: resourceItem.x + 20,
                            y: resourceItem.y + 20,
                            resourceIds: ids,
                        })
                    );
                }
                if (!resourceItem.isGroup) {
                    items.push(
                        new ResourceItemNew({
                            ...resourceItem,
                            id: generateIdFromDateTime(),
                            x: resourceItem.x + 20,
                            y: resourceItem.y + 20,
                        })
                    );
                }
            });
        } else {
            items = store.resourceItemsCurrent.map((item) => {
                return new ResourceItemNew({
                    ...item,
                    id: generateIdFromDateTime(),
                    x: item.x + 20,
                    y: item.y + 20,
                });
            });
        }
        store.resourceItemsSelected = items;
        store.resourceItemsCurrent = JSON.parse(
            JSON.stringify(store.resourceItemsSelected)
        );
        store.resourceGroupTmp = JSON.parse(JSON.stringify(resourceGroupTmp));
        if (!store.resourceGroupTmp.id?.includes("tmp-")) {
            store.resourceGroupTmp.id = generateIdFromDateTime();
        }
        store.resourceGroupTmp.resourceIds = store.resourceItemsSelected.map(
            (resourceItem) => resourceItem.id
        );
        store.resourceGroupTmp.x += 20;
        store.resourceGroupTmp.y += 20;
        notifyListener();
    },
    getResourceItemsGroup: (params?: {
        isleave: boolean;
        resourceItem: IResourceItemNew;
    }) => {
        let isGroup = params?.isleave === true ? false : true;
        let idsRemove: string[] = [];
        let items: AttributesResourceItem[] = [];
        store.resourceItemsSelected.forEach((resourceItem) => {
            if (
                resourceItem.type !== ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
            ) {
                resourceItem.isGroup = isGroup;
            }
        });
        store.resourceItemsCurrent.forEach((resourceItem, index) => {
            if (
                resourceItem.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
            ) {
                idsRemove.push(resourceItem.id);
            } else {
                resourceItem.isGroup = isGroup;
                items.push(
                    new AttributesResourceItem(resourceItem.id, [
                        { attribute: "isGroup", value: isGroup },
                    ])
                );
            }
        });
        let id = generateIdFromDateTime();
        if (!isGroup) {
            id = "tmp-group" + id;
        }
        items.push(
            new AttributesResourceItem(store.resourceGroupTmp.id, [
                { attribute: "id", value: id },
            ])
        );
        store.resourceGroupTmp.id = id;
        return { itemsChange: items, idsRemove };
    },
    updateResourceGroup: (resourceItem: IResourceItemNew) => {
        store.resourceGroupTmp = JSON.parse(JSON.stringify(resourceItem));
        resetResourceItemsSelected(store);
    },
    updateCurrentElement: (currentElement: IResourceItemNew) => {
        let resourceItem = store.resourceItemsCurrent.find(
            (e) => e.id === currentElement.id
        );
        if (resourceItem) {
            updateObject(resourceItem, currentElement);
            notifyListener();
        }
    },
    updatePageIndexResources: (pageIndex: number) => {
        store.resourceItemsSelected.map(
            (resourceItem) => (resourceItem.pageIndex = pageIndex)
        );
        store.resourceItemsCurrent.map(
            (resourceItem) => (resourceItem.pageIndex = pageIndex)
        );
    },
    resizeParentGroup: (resourceItem: IResourceItemNew) => {
        if (store.resourceGroupTmp) {
            let dheight = getDHeightParent(resourceItem, store);
            if (dheight !== 0) {
                store.resourceGroupTmp.height += dheight;
                notifyListener();
                return store.resourceGroupTmp;
            }
        }
    },
    updateIsChangeLine: (isChangeLine: boolean | null) => {
        store.isChangeLine = isChangeLine;
        notifyListener();
    },
    updateResourceItems: (resourceItems: IResourceItemNew[]) => {
        resourceItems.forEach((e) => {
            let resourceItem = store.resourceItemsCurrent.find(
                (ef) => ef.id === e.id
            );
            if (resourceItem) {
                updateObject(resourceItem, e);
            }
        });
        notifyListener();
    },
    updateFontStyle: (
        field: string,
        value: any,
        listStyle: ListStyle,
        currentElement: IResourceItemNew
    ) => {
        if (new TextAttribute()?.hasOwnProperty(field)) {
            console.log(field);
            updateObject(store.resourceGroupTmp, currentElement);
            store.resourceItemsCurrent.forEach((resourceItem) => {
                if (resourceCanEditTextGroup(resourceItem)) {
                    if (
                        resourceItem.type === ConstantsTool.TYPE_RESOURCE_TABLE
                    ) {
                        let tableAttribute = new TableAttribute({
                            ...resourceItem.tableAttribute,
                            currentPositions: [],
                        });
                        let { dHeight } = changeFontAttributes({
                            field,
                            value,
                            tableAttribute,
                        });
                        resourceItem.tableAttribute = tableAttribute;
                        resourceItem.height += dHeight;
                    } else {
                        resourceItem.textAttribute = changeStyleText({
                            textAttribute: resourceItem.textAttribute,
                            field,
                            value,
                        });
                        if (resourceItem.textAttribute.isAppliedAutoFontSize && field === 'fontSize') {
                            resourceItem.textAttribute.isAppliedAutoFontSize = false;
                        }
                        // let size = getSizeResourceText(
                        //     resourceItem.textAttribute,
                        //     resourceItem,
                        //     listStyle
                        // );
                        // resourceItem.width = size.width;
                        // resourceItem.height = size.height;
                    }
                    let dheight = getDHeightParent(resourceItem, store);
                    if (dheight !== 0) {
                        store.resourceGroupTmp.height += dheight;
                    }
                }
            });
            store.resourceItemsSelected = JSON.parse(
                JSON.stringify(store.resourceItemsCurrent)
            );
            resetResourceItemsSelected(store);
            notifyListener();
        }
        return {
            resourceItems: store.resourceItemsCurrent,
            resourceParent: store.resourceGroupTmp,
        };
    },
    updateShapeAttribute: ({
        radius,
        strokeWidth,
        strokeDasharray,
        isLine,
    }: {
        radius?: number;
        strokeWidth?: number;
        strokeDasharray?: number;
        isLine: boolean;
    }) => {
        let resourceItems = store.resourceItemsCurrent.filter((e) =>
            isLine ? isResourceLine(e) : isResourceShape(e)
        );
        resourceItems.forEach((resourceItem) => {
            if (radius || radius === 0)
                resourceItem.shapeAttribute = {
                    ...resourceItem.shapeAttribute,
                    radius,
                };
            if (strokeDasharray || strokeDasharray === 0)
                resourceItem.shapeAttribute = {
                    ...resourceItem.shapeAttribute,
                    strokeDasharray,
                };
            if (strokeWidth || strokeWidth === 0) {
                if (isLine) {
                    resourceItem.height = strokeWidth;
                } else {
                    resourceItem.shapeAttribute = {
                        ...resourceItem.shapeAttribute,
                        strokeWidth: strokeWidth * 2,
                    };
                }
            }
        });
        notifyListener();
        return resourceItems;
    },
    updateLines: (newLine: IShapeAttribute, isStart: boolean) => {
        let resourceItems = store.resourceItemsCurrent.filter((e) =>
            isResourceLine(e)
        );
        resourceItems.forEach((resourceItem) => {
            let line = resourceItem.shapeAttribute;
            resourceItem.shapeAttribute = makeChangeLine(
                newLine,
                line,
                isStart
            );
        });
        notifyListener();
        return resourceItems;
    },
};

function notifyListener() {
    store = { ...store };
    subscribers.forEach((callback: () => any) => {
        callback();
    });
}

const getDHeightParent = (resourceItem: IResourceItemNew, store: IStore) => {
    let maxY = store.resourceGroupTmp.y + store.resourceGroupTmp.height;
    let y = resourceItem.y + resourceItem.height;

    let dheight = y - maxY;

    if (store.resourceItemsCurrent.length) {
        let resourceItems = store.resourceItemsCurrent.slice();
        let index = resourceItems.findIndex((e) => e.id === resourceItem.id);
        resourceItems[index] = resourceItem;
        let resource = resourceItems.reduce((current, next) => {
            if (current.y + current.height > next.y + next.height) {
                return current;
            }
            return next;
        }, store.resourceItemsCurrent[0]);
        let dy = resource.y + resource.height;

        if (dy > y && resource.id !== resourceItem.id) {
            dheight = dy - maxY;
        }
    }
    return dheight;
};

const resetResourceItemsSelected = (store: IStore) => {
    let item = store.resourceGroupTmp;
    let rotate = store.resourceGroupTmp.rotate;
    let size = getSizeResourceItem(
        item.x,
        item.y,
        item.width,
        item.height,
        -rotate
    );
    let dxR = size.points[0].x - store.resourceGroupTmp.x,
        dyR = size.points[0].y - store.resourceGroupTmp.y,
        dr = rotate - store.resourceGroupTmp.rotate,
        x = item.x,
        y = item.y;
    store.resourceItemsSelected.map((resourceItem, index) => {
        let element = resourceItem;
        let points = rotateBox(
            element.x - x,
            element.y - y,
            element.width,
            element.height,
            -rotate
        );
        points.map((point) => {
            point.x += dxR;
            point.y += dyR;
        });
        let size = findOriginalVertices(points, -rotate);
        resourceItem.x = size.x + x;
        resourceItem.y = size.y + y;
    });
};

export default groupElementStore;
