import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/hook";
import { DirectionProps } from "../../../../../shared/models/directionProps";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { DirectionTable } from "../../../../../shared/models/tableAttribute";
import ConstantsTool from "../../../../../shared/utils/ConstantsTool";
import {
    canResizeCorner,
    canRotateElement,
    isResourceLine,
    resourceFixedSize,
} from "../../../../../utils";
import { ConstantsApp } from "../../../../../utils/Constants";
import RotateElement from "../RotateElement";
import ButtonResize from "./ButtonResize";
import ButtonMoreTable from "../ResourceTable/MenuEditTbale/buttonMoreTable";
import ResizeTable from "./ResizeTable";
import DimensionInforTooltip from './DimensionInforTooltip';

const LIST_CORNER = [
    { vertical: "top", horizontal: "left" },
    { vertical: "top", horizontal: "right" },
    { vertical: "bottom", horizontal: "left" },
    { vertical: "bottom", horizontal: "right" },
];
const LIST_BORDER_ELEMENT = [
    { vertical: "top", horizontal: "" },
    { vertical: "bottom", horizontal: "" },
    { vertical: "", horizontal: "left" },
    { vertical: "", horizontal: "right" },
];
const LIST_BORDER_TEXT = [
    { vertical: "", horizontal: "left" },
    { vertical: "", horizontal: "right" },
];

const handleTrackingResize = (currentElement: IResourceItemNew) => {
    let action = "";
    if (currentElement?.type === ConstantsTool.TYPE_RESOURCE_HANDWRITING) {
        action = "hw_resize_line";
    } else if (
        currentElement?.type === ConstantsTool.TYPE_RESOURCE_NAME_TRACING
    ) {
        action = "nt_resize_line";
    }
};

const checkEqual = (
    prevProps: {
        currentElement: IResourceItemNew;
        activeResize: DirectionProps;
        activeRotate: boolean;
        setActiveResize: (value: DirectionProps) => void;
        setActiveRotate: (value: boolean) => void;
        onDrag: boolean;
    },
    nextProps: {
        currentElement: IResourceItemNew;
        activeResize: DirectionProps;
        activeRotate: boolean;
        setActiveResize: (value: DirectionProps) => void;
        setActiveRotate: (value: boolean) => void;
        onDrag: boolean;
    }
) => {
    return (
        prevProps.currentElement === nextProps.currentElement &&
        prevProps.activeResize === nextProps.activeResize &&
        prevProps.activeRotate === nextProps.activeRotate &&
        prevProps.onDrag === nextProps.onDrag
    );
};
const ResizeElement = React.memo(
    React.forwardRef<
        HTMLDivElement,
        {
            currentElement: IResourceItemNew;
            activeResize: DirectionProps;
            activeRotate: boolean;
            setActiveResize: (value: DirectionProps) => void;
            setActiveRotate: (value: boolean) => void;
            onDrag: boolean;
            setCurrentElement: any;
        }
    >(
        (
            {
                currentElement,
                activeResize,
                activeRotate,
                setActiveResize,
                setActiveRotate,
                onDrag,
                setCurrentElement,
            },
            ref
        ) => {
            const isline = isResourceLine(currentElement);
            let listBorder =
                currentElement.type === ConstantsTool.RESOURCE_TEXT ||
                currentElement.type === ConstantsTool.RESOURCE_TEXT_MODULE ||
                currentElement.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA ||
                currentElement.type ===
                    ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
                isline
                    ? LIST_BORDER_TEXT
                    : LIST_BORDER_ELEMENT;

            const ratio = useAppSelector(
                (state) => state.createWorksheetState.ratio
            );
            const [rowAdd, setRowAdd] = useState(-1);
            const [colAdd, setColAdd] = useState(-1);
            const [checkAdd, setCheckAdd] = useState(-1);

            return (
                <div
                    id={ConstantsApp.ID_ACTION_ELEMENT}
                    style={{
                        width: `${currentElement.width * ratio}px`,
                        height: `${currentElement.height * ratio}px`,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: `translate(${currentElement.x * ratio}px, ${
                            currentElement.y * ratio
                        }px) rotate(${currentElement.rotate}deg)`,
                    }}
                    ref={ref}
                    className="not-click"
                >
                    <div
                        style={{
                            outline: "none",
                            transition: "opacity .3s ease",
                        }}
                        className="full-size not-click"
                    >
                        <div
                            className="full-size not-click"
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                position: 'relative'
                            }}
                        >
                            {activeResize && (<DimensionInforTooltip 
                                width={currentElement.width} 
                                height={currentElement.height}
                            />)}
                            {resourceFixedSize(currentElement) ||
                            currentElement.isGroup ? null : (
                                <React.Fragment>
                                    {listBorder.map((el: DirectionProps) => {
                                        return (
                                            <ButtonResize
                                                isDisplay={
                                                    (el === activeResize ||
                                                        activeResize ===
                                                            null) &&
                                                    activeRotate === false &&
                                                    onDrag === false
                                                    // false
                                                }
                                                setActiveResize={(
                                                    value: DirectionProps
                                                ) => {
                                                    setActiveResize(value);
                                                    handleTrackingResize(
                                                        currentElement
                                                    );
                                                }}
                                                key={
                                                    el.vertical +
                                                    "-" +
                                                    el.horizontal
                                                }
                                                anchorOrigin={el}
                                                isCorner={
                                                    el.horizontal.length > 0 &&
                                                    el.vertical.length > 0
                                                }
                                                isLine={isline}
                                                setRowAdd={setRowAdd}
                                                setColAdd={setColAdd}
                                                setCheckAdd={setCheckAdd}
                                                currentElement={currentElement}
                                            ></ButtonResize>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                            {canResizeCorner(currentElement) &&
                                LIST_CORNER.map((el: DirectionProps) => {
                                    return (
                                        <ButtonResize
                                            isDisplay={
                                                (el === activeResize ||
                                                    activeResize === null) &&
                                                activeRotate === false &&
                                                onDrag === false
                                                // false
                                            }
                                            setActiveResize={(
                                                value: DirectionProps
                                            ) => setActiveResize(value)}
                                            key={
                                                el.vertical +
                                                "-" +
                                                el.horizontal
                                            }
                                            anchorOrigin={el}
                                            isCorner={
                                                el.horizontal.length > 0 &&
                                                el.vertical.length > 0
                                            }
                                            setRowAdd={setRowAdd}
                                            setColAdd={setColAdd}
                                            setCheckAdd={setCheckAdd}
                                            currentElement={currentElement}
                                        ></ButtonResize>
                                    );
                                })}
                            {!activeResize &&
                                !onDrag &&
                                canRotateElement(currentElement) && (
                                    <RotateElement
                                        setActiveRotate={(value: boolean) =>
                                            setActiveRotate(value)
                                        }
                                        currentElement={currentElement}
                                    ></RotateElement>
                                )}
                            <ResizeTable
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                                rowAdd={rowAdd}
                                setRowAdd={setRowAdd}
                                colAdd={colAdd}
                                setColAdd={setColAdd}
                                activeResize={activeResize === null}
                                setCheckAdd={setCheckAdd}
                                checkAdd={checkAdd}
                            />
                            {!onDrag &&
                                !activeRotate &&
                                !activeResize &&
                                currentElement?.tableAttribute
                                    ?.currentPositions && (
                                    <ButtonMoreTable
                                        type={DirectionTable.row}
                                        currentElement={currentElement}
                                        ratio={ratio}
                                        setCurrentElement={setCurrentElement}
                                    />
                                )}
                            {!onDrag &&
                                !activeRotate &&
                                !activeResize &&
                                currentElement?.tableAttribute
                                    ?.currentPositions && (
                                    <ButtonMoreTable
                                        type={DirectionTable.column}
                                        currentElement={currentElement}
                                        ratio={ratio}
                                        setCurrentElement={setCurrentElement}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            );
        }
    ),
    checkEqual
);

export default ResizeElement;
