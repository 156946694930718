import {
    Fragment,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import ResourceItemComponent from '../ResourceItem';
import Config from '../../../../shared/utils/config';
import TabShapesNormal from '../tab-shape/normal-shape';
import SearchBar from '../search-bar';
import { useAppSelector } from '../../../../redux/hook';
import axios from 'axios';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';

function TabElementsView({
    dataTab,
    listIdBackgroundUsing,
    activeTab,
    parent,
}: {
    dataTab: any[];
    listIdBackgroundUsing: string[];
    activeTab: number;
    parent: HTMLDivElement;
}) {
    const refSearch = useRef<HTMLDivElement>(null);
    const [heightSearchBar, setHeightSearchBar] = useState(0);
    const [view, setView] = useState<ReactElement | null>(null);

    const searchKeywordRedux = useAppSelector(
        (state) => state.leftDataState.searchKeyword
    );

    useEffect(() => {
        setHeightSearchBar(refSearch?.current?.offsetHeight);
    }, []);

    const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        await axios.post(ConstantsTool.API_URL + 'resources/upload-local-icon-to-s3/?folder=uploads', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
            },
        });

        window.alert('Upload successfully');

    };

    if (view !== null) {
        return view;
    }

    return (
        <Fragment>
            <div
                ref={refSearch}
                style={{
                    position: "fixed",
                    padding: "12px 16px 0 16px",
                    width: parent?.offsetWidth - 16 * 2 - 2, //2: border,
                    top: parent?.getBoundingClientRect()?.top,
                    left: parent?.getBoundingClientRect()?.left,
                    zIndex: 4,
                    background: "white",
                }}
            >
                <SearchBar />
            </div>
            <div
                style={{
                    height: heightSearchBar - 16,
                }}
            />
            {!searchKeywordRedux && <TabShapesNormal activeTab={activeTab} setView={setView} />}
            <h3>Icons</h3>
            <div className="upload-btn">
                <h4>Upload New Icon</h4>
                <input accept="image/*" type="file"
                    onClick={(event) => {
                        event.currentTarget.value = null;
                    }}
                    onChange={handleUploadFile}
                />
            </div>
            <div className="list-resource-item">
                {dataTab
                    .filter(
                        (resourceItem) =>
                            !listIdBackgroundUsing.includes(resourceItem.id)
                    )
                    .map((resourceItem) => {
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={
                                    activeTab === Config.RESOURCE_TEMPLATE
                                        ? "template-resource-item"
                                        : "resource-item"
                                }
                            ></ResourceItemComponent>
                        );
                    })}
            </div>
        </Fragment>
    );
}

export default TabElementsView;
