import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import listKeyWord from "../../../../data/crosswordData.json";
import SearchIcon from "../../../../resource-user/components//icons/word-search-input/search-icon";
import { changeWordsAction } from "../../../../resource-user/redux/action/crossword.action";
import { randomNumber } from "../../../../resource-user/utils";
import { sendEvent } from "../../../../resource-user/utils/event";
import { IWord } from "../../../../shared/models/crossword";
import { shuffleArray } from "../../../../utils";
import "./style.scss";
import GroupInput from "./tab-input";

const formatKeywords = () => {
    return listKeyWord.map((keywordData) => {
        const keyword = keywordData.keyword;
        const arr = keyword
            .split(" ")
            .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1));
        return {
            ...keywordData,
            keyword: arr.join(" "),
        };
    });
};

const EXAMPLE_WORDS = [
    {
        value: "Banana",
        clue: "Yellow fruit with a curved shape",
    },
    {
        value: "Lemon",
        clue: "Citrus fruit that's sour and round",
    },
    {
        value: "Cucumber",
        clue: "Green and often used to make pickles",
    },
    {
        value: "Orange",
        clue: "Orange and a great source of vitamin C",
    },
];

const InputSection = () => {
    const dispatch = useDispatch();
    const keywords = formatKeywords();
    const [keyword, setKeyword] = useState("");

    const getRandomWords = (words: IWord[]) => {
        const result = [];
        let tempWords = JSON.parse(JSON.stringify(words));
        tempWords = shuffleArray(tempWords);
        const MIN_VALUE = 16;
        const MAX_VALUE = 18;
        const MAX_WORD_LONG = 2;
        const LENGTH_WORD_LONG = 12;
        const quantity = randomNumber(MIN_VALUE, MAX_VALUE);
        let count = 0;

        for (let i = 0; i < tempWords.length; i++) {
            const word = tempWords[i];
            const wordLength = word.value.split(" ").join("").length;

            if (wordLength >= LENGTH_WORD_LONG) {
                if (count >= MAX_WORD_LONG) {
                    continue;
                } else {
                    count++;
                }
            }
            result.push(word);

            if (result.length >= quantity) {
                break;
            }
        }

        return result;
    };

    const handleChangeKeyWord = (keyword: string) => {
        setKeyword(keyword);
        const keyWordData = keywords.find((e) => e.keyword === keyword);
        if (keyWordData) {
            const words = getRandomWords(keyWordData.words);

            dispatch(changeWordsAction(words));
        }
    };

    const handleClickExample = () => {
        sendEvent("crw_give_an_example");
        dispatch(changeWordsAction(EXAMPLE_WORDS));
    };

    return (
        <div className="input-crossword">
            <div className="cluster-title-give">
                <div className="title">Words</div>
                <div
                    className="give-an-example"
                    onClick={() => {
                        handleClickExample();
                    }}
                >
                    Give an example?
                </div>
            </div>

            <div className="auto-complete-input">
                <Autocomplete
                    value={keyword}
                    clearOnBlur
                    handleHomeEndKeys
                    clearText={keyword ? "clear" : "search"}
                    autoComplete
                    includeInputInList
                    freeSolo
                    options={keywords.map((item) => item.keyword)}
                    renderInput={(params) => (
                        <>
                            <TextField
                                className="text-field"
                                placeholder="Topics"
                                {...params}
                                variant="outlined"
                            />
                        </>
                    )}
                    sx={{
                        width: "100%",
                    }}
                    onInputChange={(event, newValue) => {
                        handleChangeKeyWord(newValue);
                    }}
                    onBlur={() => {
                        sendEvent("crw_type_topic", {
                            value: keyword?.trim(),
                        });
                    }}
                    onChange={(e, value) => {
                        sendEvent("crw_pick_a_topic", {
                            value: value?.trim(),
                        });
                    }}
                    className="options-container"
                />
                {!keyword ? (
                    <div className="icon-search">
                        <SearchIcon />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <GroupInput />
        </div>
    );
};

export default InputSection;
