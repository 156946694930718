import { ConversationTypes, IConversationAction } from "../action/assistant.action";

export enum  ChatRequestMessageRoleEnum {
    Human = "Human",
    Backend = "Backend",
    WorksheetZone = "WorksheetZone"
};

export enum  MessageStatusEnum {
    Typing = "Typing",
    Done = "Done"
};

export interface IPuzzleConfig {
    base64: any,
    config: any,
  }

export interface IMessage {
  role: ChatRequestMessageRoleEnum,
  status?: MessageStatusEnum,
  message: string,
  puzzleConfig?: IPuzzleConfig[]
}

export interface IConversationState {
    id: string;
    messages: IMessage[];
    historyConversations: any[];
    isLoading: boolean;
    isLoadingHistory: boolean;
    openPopup: boolean;
    openPopup2: boolean;
    showHistoryConversations: boolean;
}

const initialState: IConversationState = {
    id: "",
    messages: [],
    historyConversations: [],
    isLoading: false,
    isLoadingHistory: false,
    openPopup: true,
    openPopup2: true,
    showHistoryConversations: false
};

function conversationState(
    state: IConversationState = initialState,
    action: IConversationAction
): IConversationState {
    switch (action.type) {
        case ConversationTypes.UPDATE_CONVERSATION:
            return {
                ...state,
                id: action.conversationId,
                messages: action.messages,
                isLoading: action.isLoading ?? false,
            };
        case ConversationTypes.LOADING_CONVERSATION:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case ConversationTypes.LOADING_HISTORY:
            return {
                ...state,
                isLoadingHistory: action.isLoadingHistory,
            };
        case ConversationTypes.CLOSE_POPUP:
            return {
                ...state,
                openPopup: action.openPopup,
                openPopup2: !state.openPopup2,
            };
        case ConversationTypes.UPDATE_HISTORY_CONVERSATION:
            return {
                ...state,
                historyConversations: action.historyConversations,
                showHistoryConversations: action.showHistoryConversations
            };
        case ConversationTypes.UPDATE_CONVERSATIONid:
            return {
                ...state,
                id: action.conversationId
            };
        default:
            return state;
    }
}

export default conversationState;
