import React, { useState } from 'react';
import { IResourceItemNew, ITextAttribute } from '../../../../../../../shared/models/resourceItemNew';
import { SelectedText } from '../../../../../../../shared/models/textStyle';
import { useAppDispatch } from '../../../../../../../redux/hook';
import { useAppSelector } from '../../../../../../../redux/hook';
import ConstantsTool from '../../../../../../../shared/utils/ConstantsTool';
import { changeStyleText } from '../../../../../../../utils/edit-text-attribute';
import BackdropLayout from '../backdrop-layout';
import { sendEvent } from '../../../../../../../resource-user/utils/event';
import { syncTemplateLinkedTextToZone, getPrefixImage } from '../../../../../../../utils';
import { SketchPicker } from "react-color";
import { updateResourceItem } from '../../../../../../../redux/reducers/createWorksheet';
import Box from '@mui/material/Box';
import './styles.scss';

const ChooseColor = ({
    currentElement,
    setCurrentElement,
    pageIndex,
    changeStyleTextCells,
    textAttribute,
    selectedText,
    isGroup,
    updateFontStyleGroup,
    field = 'fontColor'
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (currentElement: IResourceItemNew) => void;
    pageIndex: number;
    changeStyleTextCells: any;
    textAttribute: ITextAttribute;
    selectedText: SelectedText;
    isGroup: boolean;
    updateFontStyleGroup: any;
    field?: string;
}) => {
    const dispatch = useAppDispatch();
    const { tempTemplateZones } = useAppSelector(state => state.createWorksheetState);
    const [pickerColor, setPickerColor] = useState("");

    const addEventChangeColor = (currentElement: IResourceItemNew, value: any) => {
        let event = "";

        switch (currentElement?.type) {
            case ConstantsTool.TYPE_RESOURCE_MODULE:
                event = "wse_change_color_puzzle";
                break;
            case ConstantsTool.TYPE_WORD_DIRECTION:
                event = "wse_change_color_words_are_hidden";
                break;
            case ConstantsTool.TYPE_WORD_LIST:
                event = "wse_change_color_word_list";
                break;
            case ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK:
                event = "crw_change_color_word_bank";
                break;
            case ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST:
                const isAcross = currentElement?.idType?.includes("_across");
                event = "crw_change_color_" + (isAcross ? "across" : "down");
                break;
            default:
                break;
        }

        if (event.length > 0) {
            sendEvent(event, {
                value,
            });
        }
    };

    const handleChangeColor = (value) => {
        if (isGroup) {
            updateFontStyleGroup(field, value);
            return;
        }
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            changeStyleTextCells(field, value);
            return;
        }
        let newTextAttribute = changeStyleText({
            textAttribute: currentElement?.textAttribute,
            field,
            value,
            selectedText,
        });
        addEventChangeColor(currentElement, value);
        setCurrentElement({
            ...currentElement,
            textAttribute: {
                ...newTextAttribute,
                color: value,
            },
        });
        if (currentElement.customZoneID) {
            syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, { textAttribute: newTextAttribute });
        }
        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...newTextAttribute,
                        },
                    },
                ],
                pageIndex: pageIndex,
            })
        );
    };

    return (
        <BackdropLayout
            open={pickerColor?.length > 0}
            handleClose={() => setPickerColor("")}
        >
            <div
                className="text-color function-button"
                onClick={() => {
                    if (pickerColor) {
                        setPickerColor("");
                    } else {
                        setPickerColor(
                            textAttribute[field]
                                ? textAttribute[field]
                                : "#212121"
                        );
                    }
                }}
                style={{
                    position: "relative",
                }}
            >
                {field === 'fontColor' && (
                    <Box>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M11 2 5.5 16h2.25l1.12-3h6.25l1.12 3h2.25L13 2h-2zm-1.38 9L12 4.67 14.38 11H9.62z"
                                fill="#212121"
                            ></path>
                        </svg>

                        <div className="colors">
                            {textAttribute.fontColors.length ? (
                                textAttribute.fontColors.map((color) => {
                                    let width =
                                        100 / textAttribute.fontColors.length + "%";
                                    return (
                                        <div
                                            className="color-item"
                                            style={{ background: color, width }}
                                        />
                                    );
                                })
                            ) : (
                                <span
                                    className="color-span"
                                    style={{
                                        backgroundColor: textAttribute.fontColor
                                            ? textAttribute.fontColor
                                            : "#212121",

                                        ...([
                                            "#fff",
                                            "#FFF",
                                            "#ffffff",
                                            "#FFFFFF",
                                        ].includes(textAttribute.fontColor)
                                            ? {
                                                backgroundImage: `url(${getPrefixImage() +
                                                    "/images/header/white-color-temp.webp"
                                                    })`,
                                            }
                                            : {}),
                                    }}
                                />
                            )}
                        </div>

                        <div className="tooltip-content">
                            <div className="arrow"></div>
                            <span className="text-tooltip">Color</span>
                        </div>
                    </Box>
                )}
                {field === 'strokeColor' && (
                    <div className="colors stroke-color">
                        <span
                            className="color-span"
                            style={{
                                backgroundColor: textAttribute[field]
                                    ? textAttribute[field]
                                    : "#212121",

                                ...([
                                    "#fff",
                                    "#FFF",
                                    "#ffffff",
                                    "#FFFFFF",
                                ].includes(textAttribute[field])
                                    ? {
                                        backgroundImage: `url(${getPrefixImage() +
                                            "/images/header/white-color-temp.webp"
                                            })`,
                                    }
                                    : {}),
                            }}
                        />
                    </div>
                )}
                {pickerColor && (
                    <div
                        style={{
                            position: "absolute",
                            top: 40,
                            right: "-100px",
                            zIndex: 2,
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SketchPicker
                            color={pickerColor}
                            onChange={(newColor) => {
                                setPickerColor(newColor.hex);
                            }}
                            onChangeComplete={(newColor) => {
                                handleChangeColor(newColor?.hex);
                            }}
                        />
                    </div>
                )}
            </div>
        </BackdropLayout>
    );
};

export default ChooseColor;