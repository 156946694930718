import { generateIdFromDateTime } from ".";
import generateMathProblems from "../components/ModulesContent/math/logic";
import { Activity, IActivity } from "../shared/models/WorkSheetCreator";
import { IMathAttribute } from "../shared/models/questionActivity";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../shared/models/resourceItemNew";
import ConstantsMath from "../shared/utils/ConstantsResourceMath";
import ConstantsTool from "../shared/utils/ConstantsTool";
import Config from "../shared/utils/config";
import {
    getDataLine,
    getDataShape,
    getResourceItemLine,
    getResourceItemShape,
} from "./canvalData";
import { getTextWidthHeightTool } from "./draw";

interface ICircle {
    x: number;
    y: number;
    d: number;
}

const textAttribute = {
    fontFamily: "Nunito",
    fontSize: 22,
    align: "center",
};

// Function to calculate the distance between two points
function distance(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

// Function to calculate the shortest line between two circles
function shortestLine(circle1: ICircle, circle2: ICircle) {
    // Circle 1 information
    const { x: x1, y: y1, d: d1 } = circle1;
    const r1 = d1 / 2;

    // Circle 2 information
    const { x: x2, y: y2, d: d2 } = circle2;
    const r2 = d2 / 2;

    // Calculate the centers of the circles
    const center1 = { x: x1 + r1, y: y1 + r1 };
    const center2 = { x: x2 + r2, y: y2 + r2 };

    // Calculate the direction from center1 to center2
    const dx = center2.x - center1.x;
    const dy = center2.y - center1.y;
    const angle = Math.atan2(dy, dx);

    // Point on circle 1
    const pointOnCircle1 = {
        x: center1.x + Math.cos(angle) * r1,
        y: center1.y + Math.sin(angle) * r1,
    };

    // Point on circle 2
    const pointOnCircle2 = {
        x: center2.x - Math.cos(angle) * r2,
        y: center2.y - Math.sin(angle) * r2,
    };

    let degree = angle * (180 / Math.PI);
    if (degree > 180) {
        degree -= 360;
    }

    // Calculate the line between the points
    const shortestLine = {
        start_x: pointOnCircle1.x,
        start_y: pointOnCircle1.y,
        end_x: pointOnCircle2.x,
        end_y: pointOnCircle2.y,
        width: distance(
            pointOnCircle1.x,
            pointOnCircle1.y,
            pointOnCircle2.x,
            pointOnCircle2.y
        ), // Width of the line,
        rotate: degree,
    };

    return shortestLine;
}

const randomHidden = () => {
    const minNumber = 1;
    const maxNumber = 3;

    return Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber);
};

const getShapeByType = (type?: number, isAnswer: boolean = true) => {
    const idAnswer = generateIdFromDateTime(
        isAnswer
            ? ConstantsMath.PREFIX_MATH_ANSWER
            : ConstantsMath.PREFIX_MATH_ITEM
    );
    let resourceItem;
    const STROKE_STYLE = ConstantsMath.STROKE_STYLE;

    if (type === STROKE_STYLE.LINE) {
        const dataLine = getDataLine();
        const lineItem = getResourceItemLine(dataLine[0]);
        resourceItem = lineItem;
    } else {
        const dataShape = getDataShape("#ffffff");
        let squareItem = getResourceItemShape({
            ...dataShape[0],
        });

        switch (type) {
            case STROKE_STYLE.NONE:
                squareItem.shapeAttribute.strokeWidth = 0;
            case STROKE_STYLE.SHAPE:
                squareItem.shapeAttribute.radius = 4;
                break;
            case STROKE_STYLE.CIRCLE:
                squareItem = getResourceItemShape({
                    ...dataShape[2],
                });
                break;
            default:
                throw new Error("Stroke type not found!");
        }
        resourceItem = squareItem;
    }

    resourceItem = new ResourceItemNew({
        ...resourceItem,
        id: idAnswer,
        idType: idAnswer,
        textAttribute,
        imageAttribute: {
            ...resourceItem.imageAttribute,
            changeColor: {
                color: "#000000",
            },
        },
    });

    return resourceItem;
};

const getNormalLine = () => {
    const dataLine = getDataLine();

    const resourceItem = getResourceItemLine(
        dataLine[0],
        ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
    );
    resourceItem.height = 3;
    resourceItem.imageAttribute.changeColor.color = "rgba(33, 33, 33, 0.24)";
    return resourceItem;
};

const changeNumberToStr = (content: number) => {
    return (typeof content === "number" ? content.toString() : content) + "o";
};

const getCircleItem = ({
    x,
    y,
    d,
    content,
    activityId,
    isAnswer,
}: {
    x?: number;
    y?: number;
    d: number;
    content: string | number;
    activityId: string;
    isAnswer?: boolean;
}) => {
    const circleItem = getShapeByType(
        ConstantsMath.STROKE_STYLE.CIRCLE,
        isAnswer
    );
    return {
        ...circleItem,
        width: d,
        height: d,
        activityId,
        x,
        y,
        textAttribute: {
            ...circleItem.textAttribute,
            content,
        },
    };
};

const getMathItem = (
    width: number,
    height: number,
    x: number,
    y: number,
    activityId: string,
    content: string,
    id?: string,
    pageIndex?: number,
    fontSize?: number
) => {
    const itemID = generateIdFromDateTime(
        id ? id : ConstantsMath.PREFIX_MATH_ITEM
    );
    const resourceNew = new ResourceItemNew({
        id,
        idType: itemID,
        width,
        height,
        type: ConstantsTool.TYPE_RESOURCE_MATH,
        activityId,
        x,
        y,
        textAttribute: {
            ...textAttribute,
            fontSize: fontSize ? fontSize : textAttribute.fontSize,
            content,
        },
        pageIndex,
    });

    return resourceNew;
};

const getLineItemBetween2Circle = ({
    circle1,
    circle2,
    activityId,
}: {
    circle1: ResourceItemNew;
    circle2: ResourceItemNew;
    activityId: string;
}) => {
    const lineItem = getNormalLine();
    const line = shortestLine(
        {
            x: circle1.x,
            y: circle1.y,
            d: circle1.width,
        },
        {
            x: circle2.x,
            y: circle2.y,
            d: circle2.width,
        }
    );
    const { start_x, start_y, end_x, end_y, width, rotate } = line;

    const centerPosition = {
        x: (start_x + end_x) / 2,
        y: (end_y + start_y) / 2,
    };
    const newPosition = {
        x: centerPosition.x - width / 2,
        y: centerPosition.y,
    };

    return {
        ...lineItem,
        x: newPosition.x,
        y: newPosition.y,
        width: width + 2,
        rotate,
        activityId,
    };
};

const getMaxPosition = (
    resourceItems: ResourceItemNew[],
    direction?: number
) => {
    let result = {
        x: 0,
        y: 0,
    };
    resourceItems.forEach((item) => {
        if (direction === ConstantsMath.DIRECTION.OTHER) {
            if (item.idType?.includes(ConstantsMath.PREFIX_MATH_ANSWER)) return;
        }
        const { y, height, x, width } = item;

        if (y + height > result.y) {
            result.y = y + height;
        }
        if (x + width > result.x) {
            result.x = x + width;
        }
    });
    return result;
};

const getMaxWidthItem = (
    questions: IMathAttribute[],
    findByHidden = true,
    direction?: number
) => {
    let maxWidth = 0;

    if (direction === ConstantsMath.DIRECTION.OTHER) {
        const longDivideOperator =
            '<svg viewBox="67.019 89.4001 156.089 25.1199" width="156.089" height="25.1199" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M 67.8 91.294 C 69.024 91.91 69.998 93.375 71.167 95.115 C 72.336 96.855 72.582 98.63 72.582 101.48 C 72.582 105.244 72.717 106.961 71.408 108.93 C 70.109 110.89 69.638 112.973 67.8 112.973 C 67.369 112.973 67.019 113.319 67.019 113.745 C 67.019 114.174 67.369 114.52 67.8 114.52 C 70.27 114.52 71.789 112.411 73.18 109.797 C 74.468 107.378 74.42 105.711 74.471 101.676 C 74.509 98.654 73.825 97.417 72.926 94.745 C 72.627 93.856 70.647 91.891 69.468 90.76 C 69.338 91.339 222.422 91.494 222.533 91.337 C 222.965 91.337 223.108 90.947 223.108 90.521 C 223.108 90.092 222.758 89.746 222.327 89.746 C 222.441 89.302 67.922 89.268 67.8 89.746 C 67.369 89.746 67.019 90.092 67.019 90.521 C 67.019 90.947 67.415 91.1 67.8 91.294 Z" fill="#212121" style="" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"/></svg>';
        questions.forEach(({ factor1, factor2, result }) => {
            let content = `<div class='flex flex-end'><div class='f-2 align-center pr-14'>${factor2}</div> <div class='flex-1'><div class='align-center f-3'>${result}</div><div class='p-relative'><div id='math-long-divide' class='long-divide-operator'>${longDivideOperator}</div><div class='align-center f-1'>${factor1}</div></div></div></div>`;
            const { width } = getTextWidthHeightTool({
                ...textAttribute,
                textValue: content,
                includePadding: false,
            });

            if (width > maxWidth) maxWidth = width;
        });

        return maxWidth;
    }

    questions.forEach(({ factor1, factor2, result, hiddenNumber }) => {
        const mapNumber = {
            1: factor1,
            2: factor2,
            3: result,
        };
        if (findByHidden) {
            const hiddenItem = mapNumber[hiddenNumber];
            if (hiddenItem) {
                const { width } = getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: changeNumberToStr(hiddenItem),
                    includePadding: false,
                });

                if (width > maxWidth) {
                    maxWidth = width;
                }
            }
        } else {
            for (const key in mapNumber) {
                const value = mapNumber[key];
                const { width } = getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: changeNumberToStr(value),
                    includePadding: false,
                });

                if (width > maxWidth) {
                    maxWidth = width;
                }
            }
        }
    });
    if (maxWidth < 40) {
        maxWidth = 40;
    }
    return maxWidth;
};

const getHeightResources = (resourceItems: ResourceItemNew[]) => {
    let minY = 10000000000;
    let maxY = 0;

    resourceItems.forEach((resource) => {
        const { y, height } = resource;

        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y + height);
    });

    return maxY - minY;
};

const getMinPositionItem = (resourceItems: ResourceItemNew[]) => {
    let min_x = 10000000000;
    let min_y = 10000000000;

    resourceItems.forEach(({ x, y }) => {
        min_x = Math.min(min_x, x);
        min_y = Math.min(min_y, y);
    });

    return {
        x: min_x,
        y: min_y,
    };
};

const generateDefaultLayout = (
    { factor1, factor2, result },
    { currentX, currentY },
    activity: IActivity,
    hiddenNumber: number,
    maxWidthHidden?: number
) => {
    let { type, id, strokeStyle, direction } = activity;
    const resourceItems = [];
    const isLine = strokeStyle === ConstantsMath.STROKE_STYLE.LINE;
    const operator = ConstantsMath.OPERATOR[type];
    const mapHidden = {
        1: factor1.toString(),
        2: factor2.toString(),
        3: result.toString(),
    };
    const hiddenContent = mapHidden[hiddenNumber];

    if (direction === ConstantsMath.DIRECTION.HORIZONTAL) {
        let widthHiddenItem = maxWidthHidden;
        if (hiddenNumber === 1 || hiddenNumber === 3) {
            const isHideFactor1 = hiddenNumber === 1;
            let content = `${operator} ${factor2} =`;
            if (isHideFactor1) {
                content = ` ${content} ${result} `;
            } else {
                content = ` ${factor1} ${content} `;
            }

            const { width, height } = getTextWidthHeightTool({
                ...textAttribute,
                textValue: content,
                includePadding: false,
            });
            const { width: widthHidden, height: heightHidden } =
                getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: changeNumberToStr(hiddenContent),
                    includePadding: false,
                });

            if (activity.hiddenStyle === ConstantsMath.HIDDEN_STYLE.RANDOM) {
                widthHiddenItem = widthHidden;
            }

            const resourceNew = getMathItem(
                width,
                height,
                currentX + (isHideFactor1 ? widthHiddenItem : 0),
                currentY,
                id,
                content
            );

            let boxAnswerItem = getShapeByType(strokeStyle);
            boxAnswerItem = {
                ...boxAnswerItem,
                width: widthHiddenItem,
                height: isLine ? 2 : heightHidden,
                x: currentX + (isHideFactor1 ? 0 : width),
                y: currentY + (isLine ? heightHidden : 0),
                activityId: id,
                textAttribute: {
                    ...boxAnswerItem.textAttribute,
                    content: hiddenContent,
                },
            };

            resourceItems.push(resourceNew, boxAnswerItem);
        } else {
            const leftContent = `${factor1} ${operator} `;
            const rightContent = ` = ${result} `;
            const { width: widthLeft, height: heightLeft } =
                getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: leftContent,
                    includePadding: false,
                });
            const { width: widthRight, height: heightRight } =
                getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: rightContent,
                    includePadding: false,
                });
            const { width: widthHidden, height: heightHidden } =
                getTextWidthHeightTool({
                    ...textAttribute,
                    textValue: changeNumberToStr(factor2),
                    includePadding: false,
                });

            if (activity.hiddenStyle === ConstantsMath.HIDDEN_STYLE.RANDOM) {
                widthHiddenItem = widthHidden;
            }

            const resourceLeft = getMathItem(
                widthLeft,
                heightLeft,
                currentX,
                currentY,
                id,
                leftContent
            );
            const resourceRight = getMathItem(
                widthRight,
                heightRight,
                currentX + widthLeft + widthHiddenItem,
                currentY,
                id,
                rightContent
            );

            let resourceHidden = getShapeByType(strokeStyle);
            resourceHidden = {
                ...resourceHidden,
                width: widthHiddenItem,
                height:
                    strokeStyle === ConstantsMath.STROKE_STYLE.LINE
                        ? 2
                        : heightHidden,
                x: currentX + widthLeft,
                y: currentY + (isLine ? heightHidden : 0),
                activityId: id,
                textAttribute: {
                    ...resourceHidden.textAttribute,
                    content: hiddenContent,
                },
            };

            resourceItems.push(resourceLeft, resourceHidden, resourceRight);
        }
    } else if (direction === ConstantsMath.DIRECTION.VERTICAL) {
        let content = `<span class='operator'>${operator}</span>${factor2}`;
        content = `<div class='align-right'><div class='f-1 pb-4'>${factor1}</div><div class='pb-4 p-relative bb-black'><span class='operator'>${operator}</span><span class='f-2'>${factor2}</span></div><div class='f-3 pt-4'>${result}</div></div>`;
        content = content.replace("f-" + hiddenNumber, "text-transparent");

        const { width, height } = getTextWidthHeightTool({
            ...textAttribute,
            textValue: content,
            includePadding: false,
        });
        const { width: widthHidden, height: heightHidden } =
            getTextWidthHeightTool({
                ...textAttribute,
                textValue: changeNumberToStr(hiddenContent),
                includePadding: false,
            });

        const resourceNew = getMathItem(
            maxWidthHidden + 8,
            height,
            currentX,
            currentY,
            id,
            content
        );
        resourceNew.textAttribute.align = "right";
        const positionHidden = {
            x: currentX + resourceNew.width - maxWidthHidden + 6,
            y: currentY,
        };

        if (hiddenNumber === 2) {
            positionHidden.y = currentY + heightHidden - 4;
        } else if (hiddenNumber === 3) {
            positionHidden.y = currentY + resourceNew.height - heightHidden;
        }

        let boxAnswerItem = getShapeByType(strokeStyle);
        boxAnswerItem = {
            ...boxAnswerItem,
            width: maxWidthHidden - 6,
            height: heightHidden,
            x: positionHidden.x,
            y: positionHidden.y,
            activityId: id,
            textAttribute: {
                ...boxAnswerItem.textAttribute,
                content: hiddenContent,
                align: "right",
            },
        };

        if (isLine) {
            boxAnswerItem.width = widthHidden;
            boxAnswerItem.height = 2;
            boxAnswerItem.x = currentX + resourceNew.width - widthHidden + 3;
            boxAnswerItem.y += heightHidden - 4;
        }

        resourceItems.push(resourceNew, boxAnswerItem);
    } else if (direction === ConstantsMath.DIRECTION.OTHER) {
        const longDivideOperator =
            '<svg viewBox="161.194 94.0601 155.748 25.1196" width="155.748" height="25.1196" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M 161.975 95.954 C 162.713 96.96 162.653 98.034 163.539 99.931 C 164.425 101.828 164.842 103.52 164.954 106.296 C 165.106 110.057 164.461 111.482 163.78 113.746 C 163.163 115.796 163.406 116.48 161.975 117.633 C 161.684 117.868 161.194 117.979 161.194 118.405 C 161.194 118.834 161.409 119.318 161.803 119.143 C 163.668 118.315 164.255 117.318 165.009 114.489 C 165.667 112.018 166.561 110.528 166.185 106.502 C 165.918 103.646 165.814 102.315 164.915 99.643 C 164.616 98.754 164.197 96.923 163.643 95.42 C 163.413 95.605 316.003 94.621 316.411 95.63 C 316.843 95.63 316.942 95.547 316.942 95.121 C 316.942 94.692 316.933 94.406 316.502 94.406 C 316.616 93.962 162.097 93.928 161.975 94.406 C 161.544 94.406 161.194 94.752 161.194 95.181 C 161.194 95.607 161.72 95.606 161.975 95.954 Z" fill="#212121" style="" transform="matrix(1, 0, 0, 1, 0, -7.105427357601002e-15)"/></svg>';
        let content = `<div class='flex flex-end'><div class='f-2 align-center pr-14'>${factor2}</div> <div class='flex-1'><div class='align-center f-3'>${result} <span id='f-3-left'></span></div><div class='p-relative'><div id='math-long-divide' class='long-divide-operator'>${longDivideOperator}</div><div class='align-center f-1'>${factor1}<span id='f-1-left'></span></div></div></div></div>`;
        content = content.replace("f-" + hiddenNumber, "text-transparent");

        const { width, height, childPosition } = getTextWidthHeightTool({
            ...textAttribute,
            textValue: content,
            includePadding: false,
            childQuery: "#math-long-divide",
        });
        const { x: xOperator } = childPosition;
        const { width: widthHidden, height: heightHidden } =
            getTextWidthHeightTool({
                ...textAttribute,
                textValue: hiddenContent,
                includePadding: false,
            });
        const resourceNew = getMathItem(
            width,
            height,
            currentX,
            currentY,
            id,
            content
        );
        resourceNew.textAttribute.align = "right";

        let itemWidthPaddingWidth = widthHidden + (widthHidden < 20 ? 20 : 6);
        let positionHidden = {
            x: 0,
            y: 0,
        };
        if (hiddenNumber === 1) {
            if (resourceNew.width < 50) {
                itemWidthPaddingWidth -= 6;
            }
            positionHidden = {
                x: currentX + resourceNew.width - itemWidthPaddingWidth,
                y: currentY + resourceNew.height - heightHidden + 2,
            };
        } else if (hiddenNumber === 2) {
            positionHidden = {
                x: currentX + xOperator - itemWidthPaddingWidth - 16,
                y: currentY + resourceNew.height - heightHidden - 2,
            };
        } else if (hiddenNumber === 3) {
            positionHidden = {
                x: currentX + resourceNew.width - itemWidthPaddingWidth,
                y: currentY - 4,
            };
        }

        let boxAnswerItem = getShapeByType(strokeStyle);
        boxAnswerItem = {
            ...boxAnswerItem,
            width: itemWidthPaddingWidth,
            height: heightHidden - 4,
            x: positionHidden.x,
            y: positionHidden.y,
            activityId: id,
            textAttribute: {
                ...boxAnswerItem.textAttribute,
                align: hiddenNumber === 2 ? "center" : "right",
                content: hiddenContent,
            },
        };
        if (isLine) {
            boxAnswerItem.height = 2;
            boxAnswerItem.y += heightHidden - 8;
            if (hiddenNumber === 1) {
                boxAnswerItem.x += 4;
                boxAnswerItem.y += 6;
            } else if (hiddenNumber === 2) {
                boxAnswerItem.y += 8;
            }
        }

        resourceItems.push(resourceNew, boxAnswerItem);
    }

    return resourceItems;
};

const generateNumberBondsLayout = (
    { factor1, factor2, result },
    { currentX, currentY },
    activity: Activity,
    hiddenNumber?: number,
    maxWidthHidden?: number
) => {
    const { id: activityId, direction } = activity;
    let d = maxWidthHidden + 30;
    // Box contains 2.2 width length
    const boxLength = d * (maxWidthHidden > 60 ? 2.05 : 2.2);
    let resultItem = getCircleItem({
        d,
        activityId,
        content: result.toString(),
        isAnswer: hiddenNumber === 3,
    });
    let factor1Item = getCircleItem({
        d,
        activityId,
        content: factor1.toString(),
        isAnswer: hiddenNumber === 1,
    });
    let factor2Item = getCircleItem({
        d,
        activityId,
        content: factor2.toString(),
        isAnswer: hiddenNumber === 2,
    });

    switch (direction) {
        case ConstantsMath.DIRECTION.LEFT:
            resultItem.x = currentX;
            resultItem.y = currentY + boxLength - (boxLength + d) / 2;

            factor1Item.x = currentX + boxLength - d;
            factor1Item.y = currentY;

            factor2Item.x = currentX + boxLength - d;
            factor2Item.y = currentY + boxLength - d;
            break;
        case ConstantsMath.DIRECTION.UP:
            resultItem.x = currentX + boxLength - (boxLength + d) / 2;
            resultItem.y = currentY;

            factor1Item.x = currentX;
            factor1Item.y = currentY + boxLength - d;

            factor2Item.x = currentX + boxLength - d;
            factor2Item.y = currentY + boxLength - d;
            break;
        case ConstantsMath.DIRECTION.DOWN:
            resultItem.x = currentX + boxLength - (boxLength + d) / 2;
            resultItem.y = currentY + boxLength - d;

            factor1Item.x = currentX;
            factor1Item.y = currentY;

            factor2Item.x = currentX + boxLength - d;
            factor2Item.y = currentY;
            break;
        case ConstantsMath.DIRECTION.RIGHT:
            resultItem.x = currentX + boxLength - d;
            resultItem.y = currentY + boxLength - (boxLength + d) / 2;

            factor1Item.x = currentX;
            factor1Item.y = currentY;

            factor2Item.x = currentX;
            factor2Item.y = currentY + boxLength - d;
            break;
        default:
            throw new Error("Direction not found");
    }

    const line1 = getLineItemBetween2Circle({
        circle1: resultItem,
        circle2: factor1Item,
        activityId,
    });
    const line2 = getLineItemBetween2Circle({
        circle1: resultItem,
        circle2: factor2Item,
        activityId,
    });

    return [resultItem, factor1Item, factor2Item, line1, line2];
};

export const updateListStyleItems = (
    resourceItems: IResourceItemNew[],
    activity: IActivity
): {
    listItems: IResourceItemNew[];
    maxPageIndex: number;
} => {
    const parseIndex = (id: string) => {
        const regex = new RegExp(`^(\\d+)-${ConstantsMath.PREFIX_MATH_ITEM}`);
        const match = id.match(regex);

        if (match && match[1]) {
            const parseIndex = parseInt(match[1]);

            return parseIndex;
        }

        return -1;
    };
    const { listStyle, id: activityId, direction } = activity;
    const groupMap = {};
    resourceItems.forEach((item) => {
        const idType = item.idType;
        if (idType?.includes(ConstantsMath.PREFIX_MATH_ITEM)) {
            if (
                direction === ConstantsMath.DIRECTION.OTHER &&
                idType?.includes(ConstantsMath.PREFIX_MATH_ANSWER)
            )
                return;

            const id = parseIndex(idType);

            if (id > -1) {
                const groupExist = groupMap[id];

                if (groupExist) {
                    groupExist.push(item);
                } else {
                    groupMap[id] = [item];
                }
            }
        }
    });

    const listItems = [];
    let maxPageIndex = 0;

    for (const index in groupMap) {
        const resourceItems: IResourceItemNew[] = groupMap[index];
        const pageIndex = resourceItems[0].pageIndex;
        if (pageIndex > maxPageIndex) {
            maxPageIndex = pageIndex;
        }
        const { x, y } = getMinPositionItem(resourceItems);
        const content =
            listStyle === ConstantsMath.LIST_STYLE.NUMBER
                ? `${parseInt(index) + 1}.`
                : `${parseInt(index) + 1})`;

        const { width, height } = getTextWidthHeightTool({
            ...textAttribute,
            fontSize: 18,
            textValue: content,
            includePadding: false,
        });

        const item = getMathItem(
            width,
            height,
            x - width - 3,
            y,
            activityId,
            content,
            `${index}-${ConstantsMath.PREFIX_MATH_LIST}`,
            pageIndex,
            18
        );
        item.textAttribute.isBold = true;
        listItems.push(item);
    }

    return {
        listItems,
        maxPageIndex,
    };
};

export const generateMathElement = ({
    activity,
    currentX,
    currentY,
    factor1,
    factor2,
    result,
    hiddenNumber,
    maxWidthHidden,
    index,
}: {
    activity: Activity;
    currentX: number;
    currentY: number;
    factor1: number;
    factor2: number;
    result: number;
    hiddenNumber?: number;
    maxWidthHidden?: number;
    index: number;
}) => {
    let { mainStyle } = activity;
    const resourceItems: IResourceItemNew[] = [];

    if (mainStyle === ConstantsMath.MAIN_STYLE.DEFAULT) {
        const newResourceItems = generateDefaultLayout(
            { factor1, factor2, result },
            { currentX, currentY },
            activity,
            hiddenNumber,
            maxWidthHidden
        );
        resourceItems.push(...newResourceItems);
    } else {
        const newResourceItems = generateNumberBondsLayout(
            { factor1, factor2, result },
            { currentX, currentY },
            activity,
            hiddenNumber,
            maxWidthHidden
        );
        resourceItems.push(...newResourceItems);
    }
    resourceItems.forEach((item) => {
        item.idType = item.idType.replace(
            ConstantsMath.PREFIX_MATH_ITEM,
            `${index}-${ConstantsMath.PREFIX_MATH_ITEM}`
        );
    });
    return resourceItems;
};

export const generateMathElements = ({
    activity,
    regenerate,
    numberOfQuestions,
    keepHidden,
}: {
    activity: Activity;
    regenerate?: boolean;
    numberOfQuestions?: number;
    keepHidden?: boolean;
}) => {
    const { questions, hiddenStyle, mainStyle, direction, strokeStyle } =
        activity;
    const allResourceItems = [];
    let mathQuestions: IMathAttribute[] = questions.map(
        (question) => question.mathAttribute
    );

    if (regenerate) {
        const { range1, range2, regrouping } = activity;
        const result = generateMathProblems({
            ...activity,
            numberOfQuestions: isNaN(numberOfQuestions)
                ? questions.length
                : numberOfQuestions,
            rangeOfFirstFactor: range1,
            rangeOfSecondFactor: range2,
            regrouping: regrouping === Config.SHOW_VALUE,
        });

        mathQuestions = result;
    }

    mathQuestions = mathQuestions.map((question) => {
        const hiddenNumber = question.hiddenNumber;
        const newHiddenNumber =
            hiddenStyle === ConstantsMath.HIDDEN_STYLE.DEFAULT || keepHidden
                ? hiddenNumber
                : randomHidden();
        return {
            ...question,
            hiddenNumber: newHiddenNumber,
        };
    });

    const maxWidthHidden = getMaxWidthItem(
        mathQuestions,
        mainStyle !== ConstantsMath.MAIN_STYLE.BONDS &&
            direction !== ConstantsMath.DIRECTION.VERTICAL,
        direction
    );

    let pageIndex = 0;
    let maxY = 0;

    if (
        direction === ConstantsMath.DIRECTION.VERTICAL ||
        direction === ConstantsMath.DIRECTION.OTHER
    ) {
        const isOther = direction === ConstantsMath.DIRECTION.OTHER;
        const GAP_Y = 40;
        let GAP_X = 67;
        let START_X = 57; // MIN

        if (isOther) {
            GAP_X = 73;
        }

        let result =
            ((ConstantsTool.BORDER_WIDTH -
                (START_X - ConstantsTool.BORDER_X) * 2) /
                (maxWidthHidden + GAP_X)) %
            1;
        result *= maxWidthHidden + GAP_X;
        START_X += result / 2;
        let currentX = START_X;
        let currentY = ConstantsMath.START_Y_FIRST_PAGE;

        for (let i = 0; i < mathQuestions.length; i++) {
            const currentQuestion = mathQuestions[i];
            const payload = {
                activity,
                maxWidthHidden,
                index: i,
                ...currentQuestion,
            };
            const resourceItems = generateMathElement({
                currentX: currentX + (isOther ? 20 : 0),
                currentY,
                ...payload,
            });

            const { x, y } = getMaxPosition(resourceItems, direction);

            if (
                strokeStyle !== ConstantsMath.STROKE_STYLE.LINE &&
                direction === ConstantsMath.DIRECTION.VERTICAL
            ) {
                currentX = x;
            } else {
                currentX = currentX + maxWidthHidden;
            }

            let shouldPushItems = true;
            if (currentX >= ConstantsTool.BORDER_WIDTH) {
                currentX = START_X;
                currentY = y + GAP_Y;

                shouldPushItems = false;
            } else {
                currentX += GAP_X;
            }

            if (
                currentY + getHeightResources(resourceItems) >=
                ConstantsTool.BORDER_Y + ConstantsTool.BORDER_HEIGHT
            ) {
                pageIndex += 1;
                currentY = ConstantsMath.START_Y_OTHER_PAGE;
                shouldPushItems = false;
            }

            if (!shouldPushItems) {
                i--;
                continue;
            }

            maxY = currentY;
            allResourceItems.push({
                resourceItems: resourceItems.map((item) => ({
                    ...item,
                    pageIndex,
                })),
                ...currentQuestion,
            });
        }
    } else {
        let defaultGapX = 350;
        let startX = 140;
        let currentY = ConstantsMath.START_Y_FIRST_PAGE;

        if (mainStyle === ConstantsMath.MAIN_STYLE.BONDS) {
            startX = 130;
            if (maxWidthHidden > 80) {
                defaultGapX = 340;
                startX -= 60;
            } else {
                defaultGapX = 320;
                startX += 20;
            }
        } else if (maxWidthHidden > 100) {
            startX -= 50;
        } else if (maxWidthHidden > 60) {
            startX -= 30;
        }

        for (let i = 0; i < mathQuestions.length; i++) {
            const currentQuestion = mathQuestions[i];
            const payload = {
                activity,
                maxWidthHidden,
                index: i,
                ...currentQuestion,
            };
            const remaining = i % 2;
            const resourceItems = generateMathElement({
                currentX: startX + defaultGapX * remaining,
                currentY,
                ...payload,
            });
            const { x, y } = getMaxPosition(resourceItems);
            if (y >= ConstantsTool.BORDER_Y + ConstantsTool.BORDER_HEIGHT) {
                pageIndex += 1;
                currentY = ConstantsMath.START_Y_OTHER_PAGE;
                i--;
                continue;
            }

            if (remaining === 1) {
                // GAP Y
                currentY = y + 20;
            }
            maxY = currentY;
            allResourceItems.push({
                resourceItems: resourceItems.map((item) => ({
                    ...item,
                    pageIndex,
                })),
                ...currentQuestion,
            });
        }
    }

    return {
        resourceItems: allResourceItems,
        pageIndex,
        maxY,
    };
};
