import Config from "./config";

export default class ConstantsMath {
    static DIRECTION = {
        HORIZONTAL: 1,
        VERTICAL: 2,
        OTHER: 3,
        LEFT: 4,
        UP: 5,
        DOWN: 6,
        RIGHT: 7,
    };

    static STROKE_STYLE = {
        NONE: 1,
        LINE: 2,
        SHAPE: 3,
        CIRCLE: 4,
    };

    static MAIN_STYLE = {
        DEFAULT: 1,
        BONDS: 2,
    };

    static HIDDEN_STYLE = {
        DEFAULT: 1,
        RANDOM: 2,
    };

    static LIST_STYLE = {
        NONE: 1,
        NUMBER: 2,
        SUPER_NUMBER: 3,
    };

    static OPERATOR = {
        [Config.ACTIVITY_TYPE.ADDITION.TYPE]: "+",
        [Config.ACTIVITY_TYPE.SUBTRACTION.TYPE]: "-",
        [Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE]: "x",
        [Config.ACTIVITY_TYPE.DIVISION.TYPE]: "÷",
    };

    static PREFIX_MATH_ANSWER = "a-math-answer-";
    static PREFIX_MATH_LIST = "a-math-list-";
    static PREFIX_MATH_ITEM = "a-math-";

    static START_Y_FIRST_PAGE = 180;
    static START_Y_OTHER_PAGE = 50;
}
