export const scrollToItemId = (id: string, focus = true) => {
    const element = document.getElementById(id);
    const container: any = document.querySelector(
        "#input-wrapper .simplebar-content-wrapper"
    );
    const listElements: any = document.querySelector(
        "#input-wrapper .simplebar-content"
    );

    if (element && container && listElements) {
        let scroll =
            element.getBoundingClientRect().top -
            listElements.getBoundingClientRect()?.top;

        container?.scrollTo({
            top: Math.abs(scroll) - element.getBoundingClientRect().height,
            behavior: "smooth",
        });
        if (focus) {
            setTimeout(() => {
                element.focus();
            }, 50);
        }
        return true;
    }

    return false;
};

export const shuffleArray = <T>(array: T[]) => {
    let curId = array.length;
    // There remain elements to shuffle
    while (0 !== curId) {
        // Pick a remaining element
        let randId = Math.floor(Math.random() * curId);
        curId -= 1;
        // Swap it with the current element.
        let tmp = array[curId];
        array[curId] = array[randId];
        array[randId] = tmp;
    }
    return array;
};

export const event = (activityType: string, event: string, params = {}) => {
};
