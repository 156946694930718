import iconCoins from "../../../../../assets/images/icon/coins.png";
import { checkStrictEquality, getImageSource } from "../../../../../utils";
import "./style.scss";
const CoinQty = ({
    quantity,
    width,
    height,
}: {
    quantity: any;
    width: number;
    height: number;
}) => {
    const isNumber = checkStrictEquality(quantity);
    return (
        <div className="c-totals-coins">
            <img
                // src={"/images/icon/coins.png"}
                src={getImageSource(iconCoins)}
                alt="coins"
                width={width}
                height={height}
            />
            <p className="c-totals-coins-number">
                {isNumber ? quantity * 10 : quantity ?? 0}
            </p>
        </div>
    );
};

export default CoinQty;
