import {
    call,
    delay,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from "@redux-saga/core/effects";
import {
    getCanvasRatioByWorksheetImage,
    getCanvasWidth,
    updateDrawDataByRatio,
} from "./../../utils/index";
import { trackEventSendInBlue } from "../../lib/sendinblue_track";
import {
    getPracticeWorksheetByIdApi,
    updateUserDownloadedWorksheetApi,
    updateUserPracticeWorksheetApi,
    updateUserPracticeWorksheetV2Api,
} from "../repositories/game.repositories";

import { Answer, ANSWER_TYPE, IAnswer } from "../../../shared/models/answer";
import {
    DrawData,
    IDrawData,
    IPracticeWorksheet,
    PracticeWorksheet,
    UserAnswer,
} from "../../../shared/models/practiceWorksheets";
import { IUserInfo } from "../../../shared/models/userInfo";
import { IWorksheet, Worksheet } from "../../../shared/models/worksheet";
import { checkIsWebProject } from "../../../shared/utils";
import ClassroomConfig from "../../../shared/utils/classroom";
import Config from "../../../shared/utils/config";
import { calcScore } from "../../utils/calcGame";
import ConstantsResource from "../../utils/ConstantsResource";
import {
    finishGameSuccessAction,
    GameAction,
    GameTypes,
    initGameAction,
    initGameSuccessAction,
    resetGameSuccessAction,
    setStartAnswerAction,
    updateUserAssignmentAction,
    updateUserPracticeWorksheetAction,
    updateUserPracticeWorksheetV2Action,
} from "../action/game.action";
import { updateUserInfoResourceAction } from "../action/userInfoResource.action";
import { updateStatusWordPuzzleAction } from "../action/wordsearch.action";
import { IGameScore } from "../reducer/game.reducer";
import { ResourceAppState } from "../reducer/root.reducerModule";
import { getWorkSheetByIdsAPI } from "../repositories/workSheet.repositories";
import { IWord } from "../../../shared/models/crossword";

export const checkDefaultWordsearchResult = (wordsearch: any[][]) => {
    let check = true;
    for (let i = 0; i < wordsearch.length; i++) {
        for (let j = 0; j < wordsearch[i].length; j++) {
            if (wordsearch[i][j] == 1) {
                check = false;
            }
        }
    }
    return check;
};

function* initGameSaga(action: GameAction) {
    let assignmentId = "";
    let { isFinish, worksheet, practiceWorksheet } = action;

    if (worksheet?.id) {
        worksheet = new Worksheet(worksheet);
        const projectName = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState?.playWorksheetInfo?.projectName
        );
        const role = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState?.playWorksheetInfo?.role
        );
        if (projectName === Config.PROJECT_CLASSROOM) {
            let assignmentReducer = yield select(
                (state: any) => state.assignmentReducer?.assignments
            );
            assignmentReducer = JSON.parse(JSON.stringify(assignmentReducer));
            if (assignmentReducer && assignmentReducer?.length) {
                const assignment = assignmentReducer.find(
                    (as) => as.worksheetId === worksheet?.id
                );
                if (assignment) {
                    assignmentId = assignment?.id;
                }
            }
        }
        worksheet.game.answers = worksheet?.game?.answers?.map(
            (game: any, index: number) => {
                let answer = new Answer(game);
                if (answer.answerId < 10000) {
                    answer.answerId = index;
                }
                if (answer.type == ANSWER_TYPE.WORD_SEARCH) {
                    const row = answer.wordSearchValue.length;
                    const col = answer.wordSearchValue[0].length;
                    //

                    let arrayRow = [];
                    for (var i = 0; i < row; i++) {
                        let arrayCol = [];
                        for (var j = 0; j < col; j++) {
                            arrayCol.push(0);
                        }
                        arrayRow.push(arrayCol);
                    }
                    answer.userWordSearch = arrayRow;
                }
                answer.userAnswer = "";
                answer.isSelected = false;
                answer.matchingId = -1;
                answer.userAnswerCorrect = false;
                return new Answer(answer);
            }
        );
        let answers = [...worksheet.game.answers];
        let score = null;
        if (isFinish && worksheet.gameSubmitted?.answers?.length) {
            answers.forEach((a, index) => {
                a.isSelected = a.isCorrect;
                if (a.isSelected && worksheet.gameSubmitted) {
                    worksheet.gameSubmitted.answers[index].isSelected = true;
                    worksheet.gameSubmitted.answers[index].userAnswerCorrect =
                        true;
                }
            });
            worksheet.gameSubmitted?.questions.forEach((q) => {
                q.isCorrect = true;
            });
            score = -1;
        }
        let initDrawData: any = new DrawData();
        if (practiceWorksheet?.id?.length > 0) {
            initDrawData = practiceWorksheet.drawData;
            let wordSearchData: any[] = [];
            answers.forEach((a: IAnswer) => {
                let answerPractice: any = practiceWorksheet.userAnswer.find(
                    (answer) => answer.answerId == a.answerId
                );

                if (answerPractice) {
                    a.userAnswer = answerPractice.userAnswer;
                    a.isSelected = answerPractice.isSelected;
                    a.matchingId = answerPractice.matchingId;
                    a.userWorkSearchResult =
                        answerPractice.userWorkSearchResult;
                    a.userAnswerCorrect = answerPractice.userAnswerCorrect;
                    if (answerPractice.point) {
                        a.point = answerPractice.point;
                        // a.realPoint = answerPractice.point;
                    }
                    if (answerPractice.userWordSearch) {
                        a.userWordSearch = answerPractice.userWordSearch;
                    }
                    if (a.type === ANSWER_TYPE.WORD_SEARCH_BY_TOOL) {
                        wordSearchData = answerPractice.userAnswer
                            ? JSON.parse(answerPractice.userAnswer).inputWords
                            : [];
                    }
                }
            });
            for (let inputWord of wordSearchData) {
                if (inputWord.status === Config.STATUS_PUZZLE.FOUND) {
                    yield put(
                        updateStatusWordPuzzleAction({
                            inputWord: inputWord.word,
                        })
                    );
                }
            }
            isFinish = practiceWorksheet.isFinish;
            score = practiceWorksheet.score;
            initDrawData.coordsDraw =
                practiceWorksheet.drawData?.coordsDraw ?? [];
            initDrawData.coordsDrawHistory =
                practiceWorksheet.drawData?.coordsDrawHistory ?? [];
            practiceWorksheet.assignmentId = assignmentId;
        } else {
            isFinish = false;
        }
        if (role == ClassroomConfig.ROLE_TEACHER) {
            isFinish = true;
        }
        yield put(
            initGameSuccessAction({
                worksheet,
                answers: answers,
                isFinish: isFinish,
                score: score,
                practiceWorksheet,
                dataDraw: initDrawData,
                assignmentId,
            })
        );
    }
    // let wordSheeetObject: any = gameObjects();
    // let wordSheetId: string = action.workSheetId ?? "";
    // let data = wordSheeetObject[wordSheetId];
    // if (typeof data == "string") {
    //     data = JSON.parse(data);
    // }
    // let worksheets = new Worksheet();
    // let game = new Game();
    // game.image = data.images[0];
    // game.images = data.images;
    // game.screenSize = new ScreenSize(data.screenSize);
}

function checkStartAnswer(answers: any[]) {
    let checkStartAnswer = false;
    if (answers.length > 0) {
        answers.map((answer: IAnswer) => {
            if (
                (answer.isSelected &&
                    answer.isSelected !==
                        ConstantsResource.DEFAULT_IS_SELECTED) ||
                (answer.matchingId &&
                    answer.matchingId !==
                        ConstantsResource.DEFAULT_MATCHINGid) ||
                (answer.userAnswer &&
                    answer.userAnswer !==
                        ConstantsResource.DEFAULT_USER_ANSWER) ||
                (answer.userWorkSearchResult &&
                    !checkDefaultWordsearchResult(answer.userWorkSearchResult))
            ) {
                checkStartAnswer = true;
            }
        });
    }
    return checkStartAnswer;
}

function* initGameStartAnswerSaga(action: GameAction) {
    let answers = action.answers;
    let startAnswer = yield select(
        (state: ResourceAppState) => state.gameResourceState?.startAnswer
    );
    if (answers && startAnswer) {
        answers = JSON.parse(JSON.stringify(answers));
        startAnswer = JSON.parse(JSON.stringify(startAnswer));
        let check = checkStartAnswer(answers);
        if (check !== startAnswer) {
            yield put(setStartAnswerAction(check));
        }
    }
}

function* listenInitGameSaga() {
    yield takeEvery(GameTypes.GAME_INIT_GAME, initGameSaga);
    yield takeEvery(GameTypes.GAME_INIT_GAME_SUCCESS, initGameStartAnswerSaga);
}

function calcGameFillBlank(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersSelect = answers.filter((w) => w.type == ANSWER_TYPE.FILL_BLANK);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;

    answersSelect.forEach((answer) => {
        let answerList = answer.value.toLowerCase().split("/");
        answerList.push(answer.value.toLowerCase());
        if (answer.value?.length) {
            totalAnswer++;
            if (answer.userAnswer?.length) {
                totalDone++;
                for (let correctAnswer of answerList) {
                    if (correctAnswer === answer.userAnswer.toLowerCase()) {
                        answer.userAnswerCorrect = true;
                        totalCorrect++;
                        break;
                    }
                }
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}
const calcGameTick = (answers: Answer[], gameScore: IGameScore): IGameScore => {
    let answersSelect = answers.filter((w) => w.type == ANSWER_TYPE.TICK);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let totalWrong = 0;
    answersSelect.forEach((answer) => {
        if (answer.value == "tick:yes") {
            totalAnswer++;
            if (answer.isSelected) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
            } else {
                answer.userAnswerCorrect = false;
            }
        } else {
            if (answer.isSelected) {
                totalWrong++;
            }
            answer.userAnswerCorrect = false;
        }
    });
    if (totalWrong == 1) {
        if (totalCorrect === totalAnswer) {
            totalCorrect = totalCorrect - 1;
        }
    }
    if (totalWrong > 1) {
        let minusAnswer = 0;
        if (totalCorrect + totalWrong > totalAnswer) {
            minusAnswer = totalCorrect + totalWrong - totalAnswer;
        }
        if (totalCorrect <= minusAnswer) {
            totalCorrect = 0;
        } else {
            totalCorrect = totalCorrect - minusAnswer;
        }
    }
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
};
function calcGameDropDown(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersDown = answers.filter((w) => w.type == ANSWER_TYPE.DROP_DOWN);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersDown.forEach((answer) => {
        totalAnswer++;
        if (answer.userAnswer) {
            totalDone++;
            if (answer.userAnswer.startsWith("*")) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameJoin(answers: Answer[], gameScore: IGameScore): IGameScore {
    let answersJoin = answers.filter((w) => w.type == ANSWER_TYPE.JOIN);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let mapAnswer = answersJoin.reduce((map: any, item: IAnswer) => {
        if (!map[item.group]) {
            map[item.group] = [];
        }
        map[item.group].push(item);
        return map;
    }, {});
    //

    Object.keys(mapAnswer).forEach((key) => {
        let anss = mapAnswer[key];
        totalAnswer++;

        if (anss.length == 2) {
            if (anss[0].matchingId == anss[1].answerId) {
                totalDone++;
                totalCorrect++;
                anss[0].userAnswerCorrect = true;
                anss[1].userAnswerCorrect = true;
            } else {
                anss[0].userAnswerCorrect = false;
                anss[1].userAnswerCorrect = false;
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameWordSearch(
    answers: Answer[],
    gameScore: IGameScore,
    numberOfGame: number
): IGameScore {
    let answersSelect = answers.filter(
        (w) => w.type == ANSWER_TYPE.WORD_SEARCH
    );
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersSelect.forEach((answer: any) => {
        let userWordSearch = answer.userWordSearch;
        let wordSearchValue = answer.wordSearchValue;
        for (let row = 0; row < userWordSearch.length; row++) {
            for (
                let column = 0;
                column < userWordSearch[row].length;
                column++
            ) {
                if (wordSearchValue[row][column] === "v") {
                    totalAnswer++;
                }
                if (userWordSearch[row][column] === 1) {
                    if (wordSearchValue[row][column] === "v") {
                        totalCorrect++;
                    }
                    totalDone++;
                }
            }
        }
    });
    if (answersSelect.length !== 0) {
        let currentTotalAnswer = gameScore.totalAnswer;
        let newTotalAnswer = totalAnswer > totalDone ? totalAnswer : totalDone;
        let additionTotalAnswer = 0;
        if (numberOfGame > 1) {
            additionTotalAnswer = Math.round(
                currentTotalAnswer / (numberOfGame - 1)
            );
        } else {
            additionTotalAnswer = newTotalAnswer;
        }
        //
        if (newTotalAnswer !== 0) {
            gameScore.totalAnswer += additionTotalAnswer;
            gameScore.totalDone += totalDone;
            gameScore.totalCorrect += Math.round(
                (totalCorrect * additionTotalAnswer) / newTotalAnswer
            );
        }

        //
    }
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameSelect(
    answers: Answer[],
    submitedAnswers: Answer[],
    questions: any[],
    gameScore: IGameScore
): IGameScore {
    let answersSelect = answers.filter((w) => w.type == ANSWER_TYPE.SELECT);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let totalWrong = 0;
    answersSelect.forEach((answer, index) => {
        if (answer.isCorrect) {
            totalAnswer++;
            if (answer.isSelected) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
                if (submitedAnswers.length) {
                    submitedAnswers[index].userAnswerCorrect = true;
                }
            } else {
                answer.userAnswerCorrect = false;
                if (submitedAnswers.length) {
                    submitedAnswers[index].userAnswerCorrect = false;
                }
            }
        } else {
            if (answer.isSelected) {
                totalWrong++;
            }
            answer.userAnswerCorrect = false;
            if (submitedAnswers.length) {
                submitedAnswers[index].userAnswerCorrect = false;
            }
        }
    });
    if (submitedAnswers.length) {
        questions.map((question, questionIndex) => {
            let score = 0;
            answers.map((answer) => {
                if (answer.group === questionIndex) {
                    if (answer.userAnswerCorrect) {
                        score++;
                    } else if (
                        answer.userAnswerCorrect === false &&
                        answer.isSelected
                    ) {
                        score--;
                    }
                }
            });
            if (score > 0) {
                question.isCorrect = true;
            } else {
                question.isCorrect = false;
            }
        });
    }

    if (totalWrong == 1) {
        if (totalCorrect === totalAnswer) {
            totalCorrect = totalCorrect - 1;
        }
    }
    if (totalWrong > 1) {
        let minusAnswer = 0;
        if (totalCorrect + totalWrong > totalAnswer) {
            minusAnswer = totalCorrect + totalWrong - totalAnswer;
        }
        if (totalCorrect <= minusAnswer) {
            totalCorrect = 0;
        } else {
            totalCorrect = totalCorrect - minusAnswer;
        }
    }
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameDragDrop(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersDrapDrop = answers.filter(
        (w) => w.type == ANSWER_TYPE.DRAG || w.type == ANSWER_TYPE.DROP
    );
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    //

    let mapAnswer = answersDrapDrop.reduce((map: any, item: IAnswer) => {
        if (!map[item.group]) {
            map[item.group] = [];
        }
        map[item.group].push(item);
        return map;
    }, {});
    //

    Object.keys(mapAnswer).forEach((key) => {
        let anss = mapAnswer[key];
        if (anss.length >= 2) {
            let answerDrop = anss.filter(
                (w: IAnswer) => w.type == ANSWER_TYPE.DROP
            );
            let answerDrag = anss.filter(
                (w: IAnswer) => w.type == ANSWER_TYPE.DRAG
            );
            totalAnswer += answerDrop.length;
            answerDrop.map((drop: IAnswer) => {
                answerDrag.map((drag: IAnswer) => {
                    if (drop?.matchingId === drag.answerId) {
                        totalDone++;
                        totalCorrect++;
                        drop.userAnswerCorrect = true;
                    }
                });
            });
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameSpeaking(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersSpeak = answers.filter((w) => w.type == ANSWER_TYPE.SPEAK);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersSpeak.forEach((w) => {
        totalAnswer++;
        if (w.userAnswer) {
            let textUserAnswer = w.userAnswer;
            totalDone++;
            let answerStrs = w.value.substring(
                w.value.lastIndexOf("):") + 1,
                w.value.length
            );
            let arr = answerStrs.split("/");
            let isCorrect = arr.some(
                (option: string) => option.localeCompare(textUserAnswer) == 0
            );
            if (isCorrect) {
                totalCorrect++;
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function* submitGameSaga(action: GameAction) {
    let answers: IAnswer[] = yield select(
        (state: ResourceAppState) => state.gameResourceState.answers
    );
    const userInfo: IUserInfo = yield select(
        (state: ResourceAppState) =>
            state.gameResourceState?.playWorksheetInfo.studentInfo
    );
    let worksheet: IWorksheet = yield select(
        (state: ResourceAppState) => state.gameResourceState.worksheet
    );

    const inputWords: any[] = yield select(
        (state: ResourceAppState) => state.wordSearchState.inputWords
    );
    const crosswordWords: IWord[] = yield select(
        (state: ResourceAppState) => state.crosswordState.words
    );
    const projectName = yield select(
        (state: any) => state.gameResourceState.playWorksheetInfo.projectName
    );
    let numberOfGame = worksheet.game.images.length;
    //
    let gameScore: IGameScore = {
        totalDone: 0,
        totalAnswer: 0,
        totalCorrect: 0,
    };
    answers = answers.map((answer) => new Answer(answer));
    calcScore(
        answers,
        gameScore,
        worksheet.gameSubmitted?.answers,
        numberOfGame,
        inputWords,
        crosswordWords
    );
    // let resultFillBlank = calcGameFillBlank(answers, gameScore);
    // let resultJoinArrow = calcGameJoin(answers, gameScore);
    // let resultDragDrop = calcGameDragDrop(answers, gameScore);
    // let resultDropDown = calcGameDropDown(answers, gameScore);
    // let resultSpeaking = calcGameSpeaking(answers, gameScore);
    // let resultSelect = calcGameSelect(
    //     answers,
    //     worksheet.gameSubmitted.answers,
    //     worksheet.gameSubmitted.questions,
    //     gameScore
    // );
    // let resultWorksearch = calcGameWordSearch(answers, gameScore, numberOfGame);
    // let resultTick = calcGameTick(answers, gameScore);
    // resetGa4Function("ws_engagement", "true");
    let score = (gameScore.totalCorrect * 10) / gameScore.totalAnswer;

    if (!score) {
        score = 0;
    }
    if (
        worksheet.type !== Config.WORKSHEET_TYPE_INTERACTIVE &&
        (action.role === ClassroomConfig.ROLE_STUDENT ||
            action.isSubmitedToTeacher)
    ) {
        score = null;
    }
    const initDrawData: IDrawData = new DrawData();

    yield put(
        finishGameSuccessAction(answers, gameScore.totalDone, Math.round(score))
    );
    // set init draw data after submit
    if (
        (action.role === ClassroomConfig.ROLE_STUDENT &&
            projectName === Config.PROJECT_CLASSROOM) ||
        action.isSubmitedToTeacher
    ) {
        yield put(
            updateUserPracticeWorksheetAction(
                {
                    workSheetId: worksheet.id,
                    drawData: initDrawData,
                    isFinish: true,
                    score,
                    worksheet,
                    isSubmited: action.isSubmitedToTeacher,
                }
                // userInfo.token,
                // worksheet?.id,
                // drawData,
                // Math.round(score),
                // answers,
            )
        );
    }

    // send event in sendinblue
    if (userInfo?.email?.length > 0 && worksheet.game?.answers?.length > 0) {
        try {
            let dataLocalStorage = localStorage.getItem(
                ConstantsResource.LOCAL_STORAGE_LIST_WS_PLAYING
            );
            if (dataLocalStorage && dataLocalStorage !== "undefined") {
                let previousData = JSON.parse(dataLocalStorage);
                previousData = previousData?.filter(
                    (el) => el !== window.location.href
                );
                if (previousData?.length === 0) {
                    trackEventSendInBlue("submit_worksheet", {
                        email: userInfo.email,
                    });
                    localStorage.removeItem(
                        ConstantsResource.LOCAL_STORAGE_LIST_WS_PLAYING
                    );
                } else {
                    trackEventSendInBlue(
                        "play_worksheet",
                        {
                            email: userInfo.email,
                        },
                        {
                            id:
                                "play_worksheet:" +
                                new Date().getTime().toString(),
                            data: {
                                urlWorksheet:
                                    previousData[previousData.length - 1],
                            },
                        }
                    );
                    localStorage.setItem(
                        ConstantsResource.LOCAL_STORAGE_LIST_WS_PLAYING,
                        JSON.stringify(previousData)
                    );
                }
            }
        } catch (error) {}
    }
}
function* resetGameSaga(action: GameAction) {
    let practiceWorksheet = yield select(
        (state: ResourceAppState) => state.gameResourceState.practiceWorksheet
    );

    let userInfo = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    let role = yield select(
        (state: ResourceAppState) =>
            state.gameResourceState?.playWorksheetInfo?.role
    );
    let projectName = yield select(
        (state: ResourceAppState) =>
            state.gameResourceState?.playWorksheetInfo?.projectName
    );
    if (action.workSheetId && action.worksheet) {
        const resetAll = action.resetAll;
        if (practiceWorksheet) {
            practiceWorksheet = new PracticeWorksheet({
                worksheetId: practiceWorksheet.worksheetId,
                userId: resetAll ? userInfo?.id : practiceWorksheet.userId,
                id: resetAll ? null : practiceWorksheet.id,
                drawData: new DrawData({
                    ...practiceWorksheet.drawData,
                    coordsDraw: [],
                }),
                resultForTeacher: resetAll
                    ? []
                    : practiceWorksheet.resultForTeacher,
                emailTeacher: resetAll ? "" : practiceWorksheet.emailTeacher,
                isFinish: resetAll ? false : practiceWorksheet?.isFinish,
            });
        } else {
            practiceWorksheet = new PracticeWorksheet();
        }

        let check = false;
        for (let i = 0; i < action.worksheet.game.answers.length; i++) {
            let answer = action.worksheet.game.answers[i];
            if (
                answer.type === ANSWER_TYPE.DROP ||
                answer.type === ANSWER_TYPE.DRAG
            ) {
                check = true;
            }
        }
        if (check) {
            yield delay(500);
        }

        // if (
        //     role !== ClassroomConfig.ROLE_STUDENT &&
        //     projectName !== Config.PROJECT_CLASSROOM
        // ) {
        //     let result: IPracticeWorksheet = yield call(
        //         updateUserPracticeWorksheetV2Api,
        //         practiceWorksheet
        //     );
        // }
        yield put(resetGameSuccessAction());

        yield put(
            initGameAction(
                action.workSheetId,
                action.worksheet,
                practiceWorksheet
            )
        );
    } else {
        yield put(resetGameSuccessAction());
    }
}

function* updateUserDownloadedWorksheetSaga(action: GameAction) {
    if (action.workSheetIdList && action.token) {
        let result = yield call(updateUserDownloadedWorksheetApi, {
            token: action.token ?? "",
            worksheetIdList: action.workSheetIdList ?? [],
        });
    }
}

function* updateUserPracticeWorksheetSaga(action: GameAction) {
    if (action.workSheetId && action.token) {
        let result = yield call(updateUserPracticeWorksheetApi, {
            token: action.token ?? "",
            worksheetId: action.workSheetId ?? "",
            drawData: action.drawData ?? new DrawData(),
            score: action.score,
        });
    }
}

function* updateUserPracticeWorksheetV2Saga(action: GameAction) {
    if (action.workSheetId) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let assignmentId = urlParams.get("assignmentId");
        if (!assignmentId) {
            assignmentId = yield select(
                (state: ResourceAppState) =>
                    state.gameResourceState?.playWorksheetInfo.assignmentId
            );
        }
        if (!assignmentId) {
            assignmentId = yield select(
                (state: ResourceAppState) =>
                    state.gameResourceState?.practiceWorksheet?.assignmentId
            );
        }
        const { isFinish = false, workSheetId, score } = action;
        const drawData: IDrawData = yield select(
            (state: ResourceAppState) => state.gameResourceState.dataDraw
        );
        const userInfo = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState?.playWorksheetInfo.studentInfo
        );
        const userId = yield select(
            (state: ResourceAppState) => state.userInfoResourceState.data.id
        );
        const answers = yield select(
            (state: ResourceAppState) => state.gameResourceState.answers
        );
        let practiceWorksheet: IPracticeWorksheet = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.practiceWorksheet
        );
        const role = yield select(
            (state: ResourceAppState) =>
                state?.gameResourceState?.playWorksheetInfo?.role
        );
        practiceWorksheet = new PracticeWorksheet(practiceWorksheet);

        if (!practiceWorksheet) {
            practiceWorksheet = new PracticeWorksheet();
        }
        if (drawData) {
            practiceWorksheet.drawData = drawData;
        }
        practiceWorksheet.worksheetId = workSheetId;
        practiceWorksheet.score =
            !score && score !== 0 ? null : Math.round(score ?? 0);
        practiceWorksheet.userId = userInfo?.id;
        // role === ClassroomConfig.ROLE_TEACHER ? studentId : userInfo?.id;
        practiceWorksheet.isFinish = isFinish ? true : false;
        practiceWorksheet.assignmentId = assignmentId ? assignmentId : null;
        answers.forEach((answer: IAnswer) => {
            let index = practiceWorksheet.userAnswer.findIndex(
                (a) => a.answerId == answer.answerId
            );
            let currentAnswer = new UserAnswer(answer);
            if (answer.userWordSearch) {
                currentAnswer.userWorkSearchResult = answer.userWordSearch;
            }
            if (currentAnswer.answerId == currentAnswer.matchingId) {
                currentAnswer.matchingId =
                    ConstantsResource.DEFAULT_MATCHINGid;
            }

            currentAnswer.point = {
                ...answer.point,
                // screenWidth: document
                //     .querySelector(".game-worksheets")
                //     .getBoundingClientRect().width,
            };
            if (index == -1) {
                practiceWorksheet.userAnswer.push(currentAnswer);
            } else {
                currentAnswer.id = practiceWorksheet.userAnswer[index].id;
                practiceWorksheet.userAnswer[index] = currentAnswer;
            }
        });

        practiceWorksheet.userAnswer = answers;
        let baseCanvasDraw = JSON.parse(JSON.stringify(drawData));
        let canvasDrawRatio = getCanvasWidth() / Config.BASE_CANVAS_WIDTH;

        if (canvasDrawRatio) {
            baseCanvasDraw = updateDrawDataByRatio(1, baseCanvasDraw);
        }

        if (action.isSubmited && !practiceWorksheet.id) {
            delete practiceWorksheet.id;
        }

        if (!checkIsWebProject() || action.isSubmited) {
            let result: IPracticeWorksheet = yield call(
                updateUserPracticeWorksheetV2Api,
                // practiceWorksheet
                {
                    ...practiceWorksheet,
                    userId: action.isSubmited
                        ? userId
                        : practiceWorksheet.userId,
                    isFinish: action.isSubmited
                        ? true
                        : practiceWorksheet.isFinish,
                    drawData: baseCanvasDraw,
                    isSubmited: action.isSubmited,
                }
            );

            if (result) {
                result.resultForTeacher = result.resultForTeacher;
                result.emailTeacher = result.emailTeacher;
                let newDrawData = result.drawData;
                result.drawData = updateDrawDataByRatio(
                    canvasDrawRatio,
                    newDrawData
                );
                yield put(updateUserPracticeWorksheetV2Action(result));
                if (role === ClassroomConfig.ROLE_STUDENT) {
                    if (result.userAssignment) {
                        yield put(
                            updateUserAssignmentAction({
                                userAssignmentOfStudent: result.userAssignment,
                            })
                        );
                    }
                    // let userAssignmentOfStudent = new UserAssignment();
                    // userAssignmentOfStudent.assignmentId = assignmentId;
                    // userAssignmentOfStudent.userId = userInfo?.id;
                    // userAssignmentOfStudent.worksheet = new Worksheet(
                    //     action?.worksheet
                    // );
                    // userAssignmentOfStudent.worksheet.score = result.score;
                    // userAssignmentOfStudent.score = result.score;
                    // userAssignmentOfStudent.id = result.userPracticeId;
                    // if (result.userPracticeId) {
                    //     console.log("start update user assignment to redux");
                    //     yield put(
                    //         updateUserAssignmentAction({
                    //             userAssignmentOfStudent,
                    //         })
                    //     );
                    // }
                }
            }
        }
    }
}

function* updateAnswerSaga(action: GameAction) {
    try {
        // let userInfo: IUserInfo = yield select(
        //     (state:ResourceAppState) => state.userInfoResourceState?.data
        // );
        let playWorksheetInfo = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.playWorksheetInfo
        );
        // let worksheet: IWorksheet = yield select(
        //     (state:ResourceAppState) => state.gameResourceState.worksheet
        // );
        if (
            playWorksheetInfo.projectName === Config.PROJECT_CLASSROOM &&
            playWorksheetInfo.role === ClassroomConfig.ROLE_STUDENT
        ) {
            yield put(
                updateUserPracticeWorksheetAction({
                    workSheetId: playWorksheetInfo.workSheetId,
                })
            );
        }
    } catch (error) {
        console.log("error", error);
    }
}

function* updateStartAnswerSaga(action: GameAction) {
    let answers = action.practiceWorksheet?.userAnswer;
    let startAnswer = yield select(
        (state: ResourceAppState) => state.gameResourceState.startAnswer
    );
    let check = checkStartAnswer(answers);
    if (check !== startAnswer) {
        yield put(setStartAnswerAction(check));
    }
}

function* updateStartAnswerGameFillBlankSaga(action: GameAction) {
    let answers = [action?.answer];

    let startAnswer = yield select(
        (state: ResourceAppState) => state.gameResourceState.startAnswer
    );

    let check = checkStartAnswer(answers);
    if (check !== startAnswer) {
        yield put(setStartAnswerAction(check));
    }
}

function* updateMatchingPointSaga(action: GameAction) {
    if (action) {
        let startAnswer = yield select(
            (state: ResourceAppState) => state.gameResourceState.startAnswer
        );
        const worksheet = yield select(
            (state: ResourceAppState) => state.gameResourceState.worksheet
        );
        let playWorksheetInfo = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.playWorksheetInfo
        );
        // yield put(
        //     updateUserPracticeWorksheetAction({
        //         workSheetId: worksheet.id,
        //         isFinish: false,
        //         score: 0,
        //     })
        // );
        if (
            playWorksheetInfo.projectName === Config.PROJECT_CLASSROOM &&
            playWorksheetInfo.role === ClassroomConfig.ROLE_STUDENT
        ) {
            yield put(
                updateUserPracticeWorksheetAction({
                    workSheetId: playWorksheetInfo.workSheetId,
                })
            );
        }
        if (!startAnswer) {
            yield put(setStartAnswerAction(true));
        }
    }
}

function* initPlayModuleSaga(action: GameAction) {
    if (action) {
        let { workSheetId, userInfo, studentClassroomId } = action;
        let studentInfo = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.playWorksheetInfo.studentInfo
        );
        let role = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.playWorksheetInfo.role
        );
        let assignmentId = yield select(
            (state: ResourceAppState) =>
                state.gameResourceState.playWorksheetInfo.assignmentId
        );
        let projectName = yield select(
            (state: any) =>
                state.gameResourceState.playWorksheetInfo.projectName
        );
        let params = {
            userId: action.userPracticeId
                ? action.userPracticeId
                : studentInfo?.id,
            worksheetId: workSheetId,
            assignmentId: assignmentId,
            userPracticeId: action.userPracticeId ? action.userPracticeId : "",
        };
        let userPractice = new PracticeWorksheet();
        if (projectName === Config.PROJECT_CLASSROOM || action.userPracticeId) {
            userPractice = yield call(getPracticeWorksheetByIdApi, params);
        }
        let worksheets = [];
        if (workSheetId) {
            worksheets = yield call(getWorkSheetByIdsAPI, [workSheetId]);
        }

        if (studentInfo?.id?.length) {
            if (userPractice) {
                userPractice = new PracticeWorksheet({
                    ...userPractice,
                    userAnswer: userPractice?.userAnswer,
                    // isFinish: true,
                });
            } else {
                userPractice = new PracticeWorksheet();
            }
        }

        if (worksheets && worksheets?.length) {
            if (userPractice.id) {
                let canvasDrawData = userPractice.drawData;

                let canvasDrawRatio = getCanvasRatioByWorksheetImage(
                    worksheets[0].game.images,
                    worksheets[0].game.screenSizes
                );
                userPractice.drawData = updateDrawDataByRatio(
                    1,
                    canvasDrawData
                );
            }
            yield put(
                initGameAction(
                    worksheets[0]?.id,
                    worksheets[0],
                    userPractice,
                    false
                )
            );
            yield put(updateUserInfoResourceAction({ userInfo }));
            // yield put(getUserSuccessAction([], [], userInfo));
        }
    }
}

function* sendResultForTeacherSaga(action: GameAction) {
    if (action) {
        let practiceWorksheet = action.practiceWorksheet;
        yield call(updateUserPracticeWorksheetV2Api, practiceWorksheet);
    }
}
function* displayModalInteractiveSaga(action: GameAction) {
    try {
        let { isDisplayModalInteractive, playWorksheetInfo } = action;
        if (
            isDisplayModalInteractive &&
            playWorksheetInfo?.projectName == Config.PROJECT_WEB
        ) {
            let hash = window.location.hash;
            if (hash != ConstantsResource.IS_PLAY_WORKSHEET) {
                let href = window.location.href;
                href = href + ConstantsResource.IS_PLAY_WORKSHEET;
                // window.location.replace(href);

                window.history.replaceState({}, "", href);
            }
        }
    } catch (error) {
        console.log("error, displayModalInteractiveSaga", error);
    }
}

function* listenSubmitGameSaga() {
    yield takeLatest(GameTypes.SUBMIT_ANSWER, submitGameSaga);
}
function* listenResetGame() {
    yield takeEvery(GameTypes.RESET_GAME, resetGameSaga);
}

function* listenUpdateUserDownloadedWorksheet() {
    yield takeEvery(
        GameTypes.UPDATE_USER_DOWNLOADED_WORKSHEET,
        updateUserDownloadedWorksheetSaga
    );
}

function* listenUpdateUserPracticeWorksheet() {
    yield takeEvery(
        GameTypes.UPDATE_USER_PRACTICE_WORKSHEET_RESOURCE,
        updateUserPracticeWorksheetV2Saga
        // updateUserPracticeWorksheetSaga
    );
}
function* listenUpdateAnswerSaga() {
    yield takeEvery(GameTypes.UPDATE_ANSWER, updateAnswerSaga);
    yield takeEvery(
        GameTypes.UPDATE_ANSWER_FOCUS,
        updateStartAnswerGameFillBlankSaga
    );
    yield takeEvery(
        GameTypes.UPDATE_USER_PRACTICE_WORKSHEET_V2,
        updateStartAnswerSaga
    );
}

function* listenUpdateMatchingPointSaga() {
    yield takeEvery(
        GameTypes.UPDATE_MATCHINGid_AND_POINTS,
        updateMatchingPointSaga
    );
}

function* listenUpdateResultForTeacherSaga() {
    yield takeEvery(
        GameTypes.USER_SEND_RESULT_FOR_TEACHER,
        sendResultForTeacherSaga
    );
}

function* listenInitModuleAction() {
    yield takeEvery(GameTypes.INIT_PLAY_WS_MODULE, initPlayModuleSaga);
}

function* listenIsDisplayModalInteractive() {
    yield takeEvery(
        GameTypes.SET_IS_DISPLAY_MODAL_INTERACTIVE,
        displayModalInteractiveSaga
    );
}

export const gameResourceSaga = [
    // fork(listenGetQuiz),
    fork(listenInitGameSaga),
    fork(listenSubmitGameSaga),
    fork(listenResetGame),
    fork(listenUpdateUserPracticeWorksheet),
    fork(listenUpdateUserDownloadedWorksheet),
    fork(listenUpdateAnswerSaga),
    fork(listenUpdateMatchingPointSaga),
    fork(listenUpdateResultForTeacherSaga),
    fork(listenInitModuleAction),
    fork(listenIsDisplayModalInteractive),
];
