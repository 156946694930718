import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { default as gameState } from "../resource-user/redux/reducer/game.reducer";
import rootReducerResource, {
    ResourceAppState,
} from "../resource-user/redux/reducer/root.reducerModule";
import wordSearchState from "../resource-user/redux/reducer/wordSearch.reducer";
import { isProduction } from "../utils";
import { createWorksheetSlice } from "./reducers/createWorksheet";
import { leftDataSlice } from "./reducers/leftData";
import { snackbarSlice } from "./reducers/snackbar";
import { staffInfoSlice } from './reducers/staffInfo';
import rootSaga from "./rootSaga";
import { recentUseSlice } from "./reducers/recentUse";
import { encryptTransform } from "redux-persist-transform-encrypt";
import Constants from "../resource-user/utils/constants";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
import defaultStorage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import listenCrossword from "./middleware/listenCrossword";

const sagaMiddleware = createSagaMiddleware();

const encryptor = encryptTransform({
    secretKey: Constants.SECRET_KEY_REDUX_PERSIST,
    onError: function (error) {},
});

const persistConfig = {
    key: "root",
    storage: globalThis.indexedDB
        ? createIdbStorage({
              name: "worksheetzone-toolv2",
              storeName: "reduxpersist",
          })
        : defaultStorage,
    stateReconciler: autoMergeLevel2,
    transform: [encryptor],
    whitelist: ["recentUseState"],
};

const rootReducer = combineReducers({
    snackbarState: snackbarSlice.reducer,
    leftDataState: leftDataSlice.reducer,
    createWorksheetState: createWorksheetSlice.reducer,
    recentUseState: recentUseSlice.reducer,
    staffInfoState: staffInfoSlice.reducer,
    ...rootReducerResource,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: isProduction() ? false : true,
    middleware: (gDM) =>
        gDM({
            serializableCheck: false,
        })
            .prepend(sagaMiddleware)
            .prepend(listenCrossword.middleware),
});
sagaMiddleware.run(rootSaga);

export default store;
export const persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

export type AppDispatch = typeof store.dispatch;
type ToolReducer = {
    snackbarState: ReturnType<typeof snackbarSlice.reducer>;
    gameState: ReturnType<typeof gameState>;
    wordSearchState: ReturnType<typeof wordSearchState>;
    leftDataState: ReturnType<typeof leftDataSlice.reducer>;
    createWorksheetState: ReturnType<typeof createWorksheetSlice.reducer>;
    recentUseState: ReturnType<typeof recentUseSlice.reducer>;
    staffInfoState: ReturnType<typeof staffInfoSlice.reducer>
};
export type RootState = ToolReducer & ResourceAppState;
