import { useSelector } from "react-redux";
import Config from "../../../../shared/utils/config";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import DynamicComponent from "../dynamic-component";
// import SnackBarAlert from "./snack-bar-alert";

const AlertComponentResource = () => {
    const status = useSelector(
        (state: ResourceAppState) => state.resourceState.alert.status
    );
    const typeAlert = useSelector(
        (state: ResourceAppState) => state.resourceState.alert.typeAlert
    );

    if (status && typeAlert === Config.TYPE_ALERT.ALERT_ACTION_WEB) {
        return (
            <DynamicComponent
                component={import("./snack-bar-alert")}
                path="./snack-bar-alert"
            />
        );
    }
    return null;

    // return <SnackBarAlert />;
};

export default AlertComponentResource;
