import { getBoundingTextBox } from "../../../../../resource-user/utils";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import "./index.scss";

const ResourceMath = ({
    resourceItem,
    currentElement,
}: {
    resourceItem: IResourceItemNew;
    currentElement: IResourceItemNew;
}) => {
    const textAttribute =
        currentElement?.textAttribute ?? resourceItem.textAttribute;
    const {
        content,
        fontColor,
        fontFamily,
        align,
        isBold,
        fontSize,
        isItalic,
        underline,
        lineHeight,
        letterSpacing,
    } = textAttribute;
    return (
        <div
            className="canvas-text resource-math-container"
            style={
                {
                    "--font-family-math": `"${fontFamily}"`,
                    "--align-math": `"${align}"`,
                } as any
            }
        >
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                    style={{
                        marginTop: getBoundingTextBox(textAttribute).top,
                        fontSize: fontSize,
                        textAlign: align,
                        fontFamily: fontFamily,
                        fontWeight: isBold ? "bold" : "normal",
                        color: fontColor,
                        fontStyle: isItalic ? "italic" : "normal",
                        textDecoration: underline ? "underline" : "none",
                        lineHeight: lineHeight + "em",
                        letterSpacing: letterSpacing + "em",
                    }}
                    className="canvas-text"
                    id="math-element-id"
                ></div>
            </div>
        </div>
    );
};

export default ResourceMath;
