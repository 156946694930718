import Form from 'react-bootstrap/Form';
import { IResourceItemNew } from '../../../../../../../shared/models/resourceItemNew';
import './styles.scss';

const VerticalAlign = ({
    currentElement,
    setCurrentElement,
    changeStyleValue,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
    changeStyleValue: any;
}) => {
    const setVerticalAlign = (value: string) => {
        const element = {...currentElement, textAttribute: {...currentElement.textAttribute, verticalAlign: value}};
        console.log(element);
        setCurrentElement(element);
        changeStyleValue(value);
    }

    return (
        <Form.Select className="vertical-align"
            onChange={e => setVerticalAlign(e.target.value)}
            value={currentElement.textAttribute?.verticalAlign || 'middle'}
        >
            <option value="top">Top</option>
            <option value="middle">Middle</option>
            <option value="bottom">Bottom</option>
        </Form.Select>
    );
}

export default VerticalAlign;