import React from "react";

const EditCollectionIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12"
                cy="12"
                r="12"
                fill={isActive ? "#F3F3F3" : "#9C9C9C"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8057 7.75414C18.0657 8.01414 18.0657 8.43414 17.8057 8.69414L16.5857 9.91414L14.0857 7.41414L15.3057 6.19414C15.4302 6.0693 15.5993 5.99915 15.7757 5.99915C15.952 5.99915 16.1211 6.0693 16.2457 6.19414L17.8057 7.75414ZM5.99902 17.6675V15.6408C5.99902 15.5475 6.03236 15.4675 6.09902 15.4008L13.3724 8.12747L15.8724 10.6275L8.59236 17.9008C8.53236 17.9675 8.44569 18.0008 8.35902 18.0008H6.33236C6.14569 18.0008 5.99902 17.8541 5.99902 17.6675Z"
                fill="#212121"
                fillOpacity="0.54"
            />
        </svg>
    );
};

export default EditCollectionIcon;
