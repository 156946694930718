export class LocalStorage {
    static KEY_COMPUTERid = "di-retupmoc";
    static PREVIOUS_LANGUAGEid = "previous_languageid";
    static NUMBER_OF_DOWNLOAD = "number_of_download";

    static get(key: string) {
        return localStorage.getItem(key);
    }

    static set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    static remove(key: string) {
        localStorage.removeItem(key);
    }

    static addStep(step: string) {
        let exitDownloaded = this.get("exitDownloaded");
        if (!exitDownloaded) {
            let steps: any = this.get("steps");
            let stepsArr = [];
            if (steps) {
                stepsArr = JSON.parse(steps);
            }
            stepsArr.push(step);
            this.set("steps", JSON.stringify(stepsArr));
        }
    }

    static setDoneStepToFirstDownloaded() {
        this.set("exitDownloaded", "true");
        let steps: any = this.get("steps");
        if (steps) {
            let stepsArr = JSON.parse(steps);
            return stepsArr;
        }
    }

    static getAndCreateIfNotExitComputerID(uuId?: string) {
        if (uuId) {
            this.set(LocalStorage.KEY_COMPUTERid, uuId);
            return uuId;
        } else {
            let id: any = this.get(LocalStorage.KEY_COMPUTERid);
            if (!id) {
                id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                    /[xy]/g,
                    function (c) {
                        var r = (Math.random() * 16) | 0,
                            v = c == "x" ? r : (r & 0x3) | 0x8;
                        return v.toString(16);
                    }
                );
                this.set(LocalStorage.KEY_COMPUTERid, id);
            }
            return id;
        }
    }
    static getPreviousLanguage = () => {
        let previousLanguage: any = this.get(LocalStorage.PREVIOUS_LANGUAGEid);
        return previousLanguage;
    };

    static setPreviousLanguage = (languageId: string) => {
        this.set(LocalStorage.PREVIOUS_LANGUAGEid, languageId);
    };

    static setNumberOfDownload = (numberOfDownload: string) => {
        this.set(LocalStorage.NUMBER_OF_DOWNLOAD, numberOfDownload);
    };

    static getNumberOfDownload = () => {
        let numberOfDownload: any = this.get(LocalStorage.NUMBER_OF_DOWNLOAD);
        return numberOfDownload;
    };
    // static setUserHaveActionClaimDownload = () => {
    //     this.set(ConstantsResource.CLAIM_ACTION, "true");
    // };
}
