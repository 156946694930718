import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "../../../../../assets/icon/deleteIcon";
import {
    addQuestionMC,
    deleteQuestionMC,
    updateQuestionMC,
} from "../../../../../redux/async/multipleChoiceThunk";
import { useDebounce } from "../../../../../resource-user/components/common/use-debounce";
import { IActivity } from "../../../../../shared/models/WorkSheetCreator";
import { IQuestionActivity } from "../../../../../shared/models/questionActivity";
import { generateIdFromDateTime } from "../../../../../utils";
import { handleTabIndex } from "../../util";
import { useAppDispatch } from "../../../../../redux/hook";

const QuestionHeader = ({
    isCurrentQuestion,
    activity,
    index,
    openQuestion,
    setOpenQuestion,
    question,
    setCurrentQuestion,
}: {
    isCurrentQuestion: boolean;
    activity: IActivity;
    index: number;
    openQuestion: boolean;
    setOpenQuestion?: Function;
    question: IQuestionActivity;
    setCurrentQuestion?: Function;
}) => {
    const dispatch = useAppDispatch();
    const [scrollEleId, setScrollEleId] = useState("");
    const listQuestionActivity: IQuestionActivity[] = activity.questions;
    const inputRef = useRef(null);
    const defaultAnswer = [
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: "",
            isCorrect: false,
            indexWord: 0,
        },
    ];
    let defaultQuestion: IQuestionActivity = {
        id: generateIdFromDateTime(),
        question: `${1}. `,
        answers: defaultAnswer,
        indexQuestion: 0,
        multipleChoicesAttribute: {
            numberCol: 4,
            resourceItemIds: [],
        },
    };

    const getContent = (input) => {
        if (input?.match(/\.(.*)/)) {
            return input?.match(/\.(.*)/)[0]?.slice(2);
        } else {
            return "";
        }
    };
    const [content, setContent] = useState<string>(
        getContent(question.question).trim()
    );
    const contentDebounced = useDebounce(content, 100);

    useEffect(() => {
        let questionInput = inputRef.current;
        if (questionInput) {
            questionInput.focus();
            const textLength = questionInput.value.length;
            questionInput.setSelectionRange(textLength, textLength);
        }
    }, []);
    useEffect(() => {
        dispatch(
            updateQuestionMC({
                question,
                content: content,
            })
        );
    }, [contentDebounced]);
    useEffect(() => {
        if (scrollEleId) {
            let listQuesBox = document.getElementById("question-container");
            let offset = 0;
            offset = document.getElementById(scrollEleId).offsetTop;
            listQuesBox.scrollTo({
                top: offset,
                behavior: "smooth",
            });
            setScrollEleId("");
        }
    }, [scrollEleId]);

    const handleOpenQuestion = () => {
        if (!isCurrentQuestion) {
            setCurrentQuestion(index);
            setOpenQuestion(true);
            setScrollEleId(`question-${index + 1}`);
        } else {
            setOpenQuestion(!openQuestion);
            if (openQuestion) {
                setCurrentQuestion(undefined);
            } else {
                setCurrentQuestion(index);
            }
        }
    };

    const isBlankQues = (question) => {
        let isBlank = false;
        let blankQues = false;
        let questionContent = getContent(question.question).trim();
        if (!questionContent) {
            blankQues = true;
        }
        let blankAnswer = question.answers.every(
            (answer) => !getContent(answer.text)
        );
        let noCorrectAnswer = question.answers.every(
            (answer) => !answer.isCorrect
        );
        if (noCorrectAnswer && blankAnswer && blankQues) {
            isBlank = true;
        }
        return isBlank;
    };

    const handleDeleteQuestion = (question: IQuestionActivity) => {
        if (
            listQuestionActivity.length == 1 &&
            !isBlankQues(listQuestionActivity[0])
        ) {
            dispatch(
                deleteQuestionMC({
                    question: question,
                })
            );
            dispatch(
                addQuestionMC({
                    question: defaultQuestion,
                    questionIndex: 0,
                })
            );
            setTimeout(() => {
                setCurrentQuestion(0);
                setOpenQuestion(true);
            }, 20);
        } else if (listQuestionActivity.length > 1) {
            dispatch(
                deleteQuestionMC({
                    question: question,
                })
            );
        }
    };

    const handleQuestionContent = (value: string) => {
        setContent(value);
    };

    return (
        <div
            className="question-header"
            id={`question-header-${index + 1}`}
            onClick={() => {
                handleOpenQuestion();
            }}
            style={{
                borderRadius: `${isCurrentQuestion ? "6px" : ""}`,
                borderBottom: `${isCurrentQuestion ? "1px dashed gray" : ""}`,
            }}
        >
            <div className="question-number">{index + 1}.</div>
            <TextField
                sx={{
                    "& fieldset": { border: "none" },
                }}
                multiline
                autoFocus
                id={`question_${index + 1}_input`}
                inputRef={inputRef}
                className="question-content"
                placeholder="Type or paste your question here"
                tabIndex={handleTabIndex(listQuestionActivity, question)}
                value={content}
                onClick={(e) => {
                    if (isCurrentQuestion) {
                        e.stopPropagation();
                    } else {
                        handleOpenQuestion();
                    }
                }}
                onChange={(e) => {
                    handleQuestionContent(e.target.value);
                }}
            />
            <div
                className="remove-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteQuestion(question);
                }}
            >
                <DeleteIcon />
            </div>
        </div>
    );
};

export default QuestionHeader;
