import Axios from "axios";
import ConstantsResource from "../../utils/ConstantsResource";
// export const ENDPOINT = process.env.NEXT_PUBLIC_ENDPOINT;
export const PLAY_WS_MODULE_ENDPOINT =
    process.env.REACT_APP_PLAY_WS_MODULE_ENDPOINT ??
    process.env.NEXT_PUBLIC_ENDPOINT ??
    process.env.REACT_APP_ENDPOINT ??
    ConstantsResource.BASE_URL_API;
const HTTP_REQUEST_TIMEOUT = 300000;
const HTTP_REQUEST_SUCCESS = 200;
export class RequestData {
    url: string;
    params: any;
    baseURL?: any;
    constructor(url: string, params: any, baseURL: any) {
        this.url = url;
        this.params = params;
        this.baseURL = baseURL;
    }
}

export class UploadData {
    url: string;
    file: any;
    userId?: string;

    constructor(url: string, file: any, userId?: string) {
        this.url = url;
        this.file = file;
        this.userId = userId ?? "";
    }
}

export const POST: any = (data: {
    baseURL?: string;
    url: string;
    params: any;
    timeout?: number;
}) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data.baseURL ?? PLAY_WS_MODULE_ENDPOINT,
            timeout: data.timeout ? data.timeout : HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "POST",
            data: data.params,
            headers: data?.params?.userId
                ? { userId: data?.params?.userId }
                : {},
        })
            .then((response: any) => {
                if (response.status === HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                } else {
                    reject(response.status);
                }
            })
            .catch((e: any) => reject(e));
    });
};

export const GET: any = (data: {
    url: string;
    baseURL?: string;
    params?: any;
}) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data.baseURL ?? PLAY_WS_MODULE_ENDPOINT,
            timeout: HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "GET",
            params: data.params,
        })
            .then((response: any) => {
                if (response.status === HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                } else {
                    reject(response.status);
                }
            })
            .catch((e: any) => reject(e));
    });
};

export const DELETE: any = (data: RequestData) => {
    return new Promise((resolve, reject) => {
        Axios({
            baseURL: data?.baseURL ?? PLAY_WS_MODULE_ENDPOINT,
            timeout: HTTP_REQUEST_TIMEOUT,
            url: data.url,
            method: "DELETE",
            data: data.params,
            headers: data?.params?.userId
                ? { userId: data?.params?.userId }
                : {},
        })
            .then((response: any) => {
                if (response.status === HTTP_REQUEST_SUCCESS) {
                    resolve(response.data);
                    // //
                } else {
                    reject(response.status);
                }
            })
            .catch((e: any) => reject(e));
    });
};
export const sortImageUrl = (urls: string[]) => {
    urls.sort((a, b) => {
        let firstUrlData = a.split("-");
        let firstIndexData: string = firstUrlData[firstUrlData.length - 1];
        let secondUrlData = b.split("-");
        let secondIndexData: string = secondUrlData[secondUrlData.length - 1];
        return parseInt(firstIndexData[0]) - parseInt(secondIndexData[0]);
    });
};
