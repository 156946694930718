import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { updateResourceItem } from "../../../../../redux/reducers/createWorksheet";
import { updateShowButtonAnswerKey } from "../../../../../redux/reducers/leftData";
import ShapeBorder from "../../../../../resource-user/components/common/shape/shape-border";
import {
    calculateContentHeight,
    getListCommands,
    resizeShape,
    roundPathCorners,
} from "../../../../../resource-user/utils/shape";
import {
    IResourceItemNew,
    ITextAttribute,
} from "../../../../../shared/models/resourceItemNew";
import ConstantsMath from "../../../../../shared/utils/ConstantsResourceMath";
import "./index.scss";
import Config from "../../../../../shared/utils/config";

function ShapeView({
    resourceItem,
    currentItem,
    pageIndex,
    setCurrentElement,
}: {
    resourceItem: IResourceItemNew;
    currentItem?: IResourceItemNew;
    pageIndex: number;
    setCurrentElement: any;
}) {
    const isShowAnswerKey = useAppSelector(
        (state) => state.createWorksheetState.isShowAnswerKey
    );
    const isHideTextShape = useAppSelector(
        (state) => state.createWorksheetState.isHideTextShape
    );
    const isResize = useAppSelector(
        (state) => state.createWorksheetState.isResize
    );
    const isSave = !useAppSelector((state) => state.wordSearchState.loading);
    const [inputValue, setInputValue] = useState("");
    const [commands, setCommands] = useState([]);
    const [placeholder, setPlaceholder] = useState("");
    const textareaRef = useRef(null);
    const dispatch = useAppDispatch();
    const [fontSize, setFontSize] = useState(
        resourceItem.textAttribute.fontSize
    );
    const [stopChangeFontSize, setstopChangeFontSize] = useState(false);
    const [focus, setFocus] = useState(false);
    const [lineHeight, setlineHeight] = useState(0);
    const [hideShowAskey, setHideShowAskey] = useState(true);
    const shape = currentItem?.shapeAttribute ?? resourceItem.shapeAttribute;
    const isMathActivity = resourceItem.idType?.includes(
        ConstantsMath.PREFIX_MATH_ITEM
    );
    const isMathAnswerActivity = resourceItem.idType?.includes(
        ConstantsMath.PREFIX_MATH_ANSWER
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const isCustomWs =
        currentActivity?.type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;

    useEffect(() => {
        if (!isMathActivity) {
            if (hideShowAskey) {
                setTimeout(() => {
                    if (currentItem) {
                        dispatch(updateShowButtonAnswerKey(false));
                        setHideShowAskey(false);
                    }
                }, 200);
            }
            if (currentItem === null && !hideShowAskey) {
                dispatch(updateShowButtonAnswerKey(true));
                setHideShowAskey(true);
            }
        }
    }, [currentItem]);

    function isNumber(value?: string | number): boolean {
        return (
            value != null && value !== "" && !isNaN(Number(value.toString()))
        );
    }

    useEffect(() => {
        if (isNumber(resourceItem.id.replaceAll("_", ""))) {
            setPlaceholder("Answer");
        }
        return () => {
            if (!isMathActivity) {
                dispatch(updateShowButtonAnswerKey(true));
            }
        };
    }, []);

    useEffect(() => {
        if (textareaRef.current !== null) {
            let computedStyle = window.getComputedStyle(textareaRef.current),
                lineHeightStr = computedStyle.getPropertyValue("line-height"),
                lineHeight = parseInt(lineHeightStr);
            setlineHeight(lineHeight);
        }
    }, [fontSize]);

    useEffect(() => {
        if (fontSize !== resourceItem.textAttribute.fontSize) {
            setstopChangeFontSize(true);
        }
        setFontSize(resourceItem.textAttribute.fontSize);
    }, [resourceItem?.textAttribute?.fontSize]);

    useEffect(() => {
        setInputValue(resourceItem.textAttribute.content);
    }, [resourceItem]);

    useEffect(() => {
        if (
            currentItem &&
            !isResize &&
            !stopChangeFontSize &&
            commands.length
        ) {
            setCurrentElement({
                ...currentItem,
                textAttribute: {
                    ...currentItem.textAttribute,
                    fontSize: fontSize,
                },
            });
            dispatch(
                updateResourceItem({
                    currentElementId: resourceItem.id,
                    pageIndex: pageIndex,
                    attrValues: [
                        {
                            attr: "textAttribute",
                            value: {
                                ...resourceItem.textAttribute,
                                fontSize: fontSize,
                            },
                        },
                    ],
                })
            );
        }
    }, [isResize]);

    useEffect(() => {
        if (currentItem !== null && !stopChangeFontSize && isResize) {
            let fontSize =
                (currentItem.height -
                    currentItem.shapeAttribute.strokeWidth / 2) /
                1.8;
            fontSize = Math.round(fontSize);
            fontSize = fontSize > 8 ? (fontSize > 72 ? 72 : fontSize) : 8;
            let inputHeight =
                (shape.input.height * currentItem.height) / shape.height -
                shape.strokeWidth;
            fontSize = fontSize >= inputHeight ? inputHeight / 1.2 : fontSize;
            setFontSize(fontSize);
        }
    }, [currentItem?.width, currentItem?.height]);

    useEffect(() => {
        if (currentItem && commands.length) {
            setCurrentElement({
                ...currentItem,
                shapeAttribute: resourceItem.shapeAttribute,
            });
        }
        setCommands(getListCommands(resourceItem.shapeAttribute.path));
    }, [resourceItem?.shapeAttribute?.path]);

    useEffect(() => {
        if (
            textareaRef.current !== null &&
            currentItem &&
            !resourceItem.textAttribute.content.trim().length
        ) {
            textareaRef.current.focus();
        }
        if (currentItem) {
            setInputValue(currentItem?.textAttribute?.content);
        }
    }, [currentItem?.textAttribute?.content]);

    useEffect(() => {
        if (currentItem && commands.length) {
            setCurrentElement(null);
            setTimeout(() => {
                textareaRef.current.blur();
            }, 10);
        }
    }, [isShowAnswerKey]);

    const handleInputChange = (event) => {
        if (isHideTextShape && !currentItem) {
            return;
        }
        setCurrentElement({
            ...currentItem,
            textAttribute: {
                ...currentItem.textAttribute,
                content: event.target.value,
            },
        });
        dispatch(
            updateResourceItem({
                currentElementId: resourceItem.id,
                pageIndex: pageIndex,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...resourceItem.textAttribute,
                            content: event.target.value,
                        },
                    },
                ],
                isAddHistory: true,
            })
        );
        setInputValue(event.target.value);
    };

    let calculateHeight = function () {
        let ta = textareaRef.current,
            computedStyle = window.getComputedStyle(textareaRef.current),
            lineHeight = computedStyle.getPropertyValue("line-height"),
            taLineHeight = parseInt(lineHeight, 10),
            taHeight = calculateContentHeight(ta, taLineHeight),
            numberOfLines = Math.ceil(taHeight / taLineHeight);
        return numberOfLines;
    };

    const getLineCount = () => {
        if (inputValue === "") {
            return 1;
        }
        return calculateHeight() - 1;
    };

    const setPath = (width: number, height: number): string => {
        let path = "";
        path = resizeShape(width, height, shape, commands);
        // if (resourceItem.shapeAttribute.name.includes("Square")) {
        //     return path;
        // }
        // console.log
        return roundPathCorners(path, shape.radius, width, height);
    };

    const makeContent = (
        width: number,
        height: number,
        textAttribute: ITextAttribute,
        color: string
    ) => {
        let strokeWidth = shape.strokeWidth,
            inputWidth =
                (shape.input.width * width) / shape.width - strokeWidth,
            inputHeight =
                (shape.input.height * height) / shape.height - strokeWidth,
            top = (height * shape.input.y) / shape.height + strokeWidth / 2,
            left = (width * shape.input.x) / shape.width + strokeWidth / 2,
            paddingTop = inputHeight / 2,
            lineCount = 0;
        if (textareaRef.current !== null) {
            lineCount = getLineCount();
            paddingTop -= lineHeight * lineCount;
            paddingTop += lineHeight / 2.2;
            paddingTop = paddingTop > 0 ? paddingTop : null;
        }
        let path = setPath(width, height);

        let opacity = focus
            ? 0
            : isShowAnswerKey || currentItem || !isSave
            ? 1
            : 0;

        if (!isCustomWs) {
            opacity = focus
                ? 0
                : isShowAnswerKey ||
                  currentItem ||
                  !isSave ||
                  !(isMathActivity && isMathAnswerActivity)
                ? 1
                : 0;
        }
        return (
            <div id={`.item-${resourceItem.type} .main-text`}>
                <div
                    style={{
                        position: "absolute",
                        width,
                        height,
                        opacity: currentItem?.opacity ?? resourceItem.opacity,
                        zIndex: 4,
                    }}
                >
                    <ShapeBorder
                        id={resourceItem.id}
                        path={path}
                        width={width}
                        height={height}
                        stroke={color}
                        strokeWidth={shape.strokeWidth}
                        strokeDasharray={shape.strokeDasharray}
                        fill="#FFFFFF"
                    />
                </div>
                <div
                    id={"text-area-" + currentItem?.id}
                    ref={textareaRef}
                    className={`textInput sentence-text-shape${
                        isMathActivity && !isMathAnswerActivity ? "-math" : ""
                    }`}
                    style={{
                        top: top + paddingTop,
                        left: left,
                        width: inputWidth,
                        height:
                            lineHeight * lineCount < inputHeight
                                ? lineHeight * lineCount
                                : inputHeight,
                        fontSize: fontSize,
                        color: textAttribute.fontColor,
                        fontFamily: textAttribute.fontFamily,
                        fontStyle: textAttribute.isItalic ? "italic" : "normal",
                        fontWeight: textAttribute.isBold ? "bold" : null,
                        textAlign: textAttribute.align,
                        textDecoration: textAttribute.underline
                            ? "underline"
                            : "none",
                        // paddingTop,
                        lineHeight: "1.2",
                        resize: "none",
                        zIndex: 6,
                        opacity,
                        whiteSpace: "pre-line",
                    }}
                >
                    {inputValue}
                </div>
                <textarea
                    id={"text-area-" + currentItem?.id}
                    ref={textareaRef}
                    className="textInput"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={() => {
                        setFocus(false);
                        if (currentItem === null) {
                            setPlaceholder("");
                        }
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    onMouseDown={(e) => {
                        if (focus) {
                            e.currentTarget.setSelectionRange(0, 0);
                            if (inputValue !== "") {
                                e.stopPropagation();
                            }
                        }
                    }}
                    placeholder={placeholder}
                    // style={styleTextera}
                    style={{
                        top: top + paddingTop,
                        left: left,
                        width: inputWidth,
                        height:
                            lineHeight * lineCount < inputHeight
                                ? lineHeight * lineCount
                                : inputHeight,
                        fontSize: fontSize,
                        color: textAttribute.fontColor,
                        fontFamily: textAttribute.fontFamily,
                        fontStyle: textAttribute.isItalic ? "italic" : "normal",
                        fontWeight: textAttribute.isBold ? "bold" : null,
                        textAlign: textAttribute.align,
                        textDecoration: textAttribute.underline
                            ? "underline"
                            : "none",
                        // paddingTop,
                        lineHeight: "1.2",
                        resize: "none",
                        zIndex: 6,
                        opacity: focus ? 1 : 0,
                    }}
                />
            </div>
        );
    };
    return makeContent(
        currentItem?.width ?? resourceItem.width,
        currentItem?.height ?? resourceItem.height,
        currentItem?.textAttribute ?? resourceItem.textAttribute,
        currentItem?.imageAttribute?.changeColor["color"] ??
            resourceItem?.imageAttribute?.changeColor["color"] ??
            "grey"
    );
}

export default ShapeView;
