import { call, fork, put, select, take, takeEvery } from "redux-saga/effects";
import {
    IUserInfo,
    IUserSurvey,
    UserInfo,
    UserSurvey,
} from "../../../shared/models/userInfo";
import { isLogined } from "../../utils";
import { LocalStorage } from "../../utils/LocalStorage";
import Cookies from "../../utils/cookies";
import {
    getQuotaResourceByUserIdAction,
    updateLoadingQuotaAction,
    updateQuotaResourceAction,
} from "../action/quotaResourceUser.action";
import { getUserSuccessAction } from "../action/user.action";
import {
    UserInfoResourceAction,
    UserInfoResourceTypes,
    loginFailed,
    loginSuccess,
    updateUserInfoResourceAction,
} from "../action/userInfoResource.action";
import { ResourceAppState } from "../reducer/root.reducerModule";
import UserRepositoriesWeb from "../repositories/UserRepositories.web";
import { getQuotaResourceByUserIdApi } from "../repositories/quotaResourceUser";
import { updateUserEmailAPI } from "../repositories/user";

function* loginSaga() {
    while (true) {
        let action: UserInfoResourceAction = yield take(
            UserInfoResourceTypes.LOGIN
        );
        if (action && action.email && action.password) {
            try {
                let userInfo: IUserInfo = yield call(
                    new UserRepositoriesWeb().loginAPI,
                    { email: action.email, password: action.password }
                );
                if (isLogined(userInfo)) {
                    yield put(loginSuccess(userInfo));
                    yield put(getUserSuccessAction([], [], userInfo));
                    yield put(updateUserInfoResourceAction({ userInfo }));
                } else {
                    yield put(loginFailed(userInfo, "user id null"));
                }
            } catch (e: any) {
                yield put(loginFailed(new UserInfo(), e));
            }
        }
    }
}
function* updateEmailSaga(action: UserInfoResourceAction) {
    if (action && action.email) {
        try {
            let userInfoResourceState: IUserInfo = yield select(
                (state: ResourceAppState) => state.userInfoResourceState.data
            );
            let userId: string = userInfoResourceState?.id ?? "";
            let survey: IUserSurvey =
                userInfoResourceState?.survey ?? new UserSurvey();

            let userInfo: IUserInfo = yield call(updateUserEmailAPI, {
                email: action.email,
                id: userId,
                verifyUrl: window.location.origin + "/verify-account/" + userId,
                survey,
                loginDate: new Date(),
                teacherLogin: action.teacherLogin,
            });
            if (userInfo) {
                yield put(loginSuccess(userInfo));
                yield put(updateUserInfoResourceAction({ userInfo }));
                yield put(getUserSuccessAction([], [], userInfo));
            }
        } catch (e: any) {
            yield put(loginFailed(new UserInfo(), e));
        }
    }
}
function* loginSuccessSaga() {
    while (true) {
        let action: UserInfoResourceAction = yield take(
            UserInfoResourceTypes.LOGIN_SUCCESS
        );
        // //
        if (
            action &&
            action.userInfo &&
            action.userInfo.token &&
            typeof document != "undefined"
        ) {
            Cookies.setToken(action.userInfo.token);
        }
        if (action?.userInfo?.uuId) {
            LocalStorage.getAndCreateIfNotExitComputerID(action.userInfo.uuId);
        }
        if (action?.reload) {
            window.location.reload();
        }
    }
}

function* getQuotaResourceSaga(action: UserInfoResourceAction) {
    if (action && action?.userInfo?.email) {
        try {
            const loading = yield select(
                (state: ResourceAppState) =>
                    state.quotaResourceUserState.loading
            );
            const quotaResourceUserState = yield select(
                (state: ResourceAppState) => state.quotaResourceUserState.data
            );
            if (loading || quotaResourceUserState?.userId) {
                return;
            }

            yield put(getQuotaResourceByUserIdAction(action.userInfo.id));
        } catch (e: any) {
            yield put(loginFailed(new UserInfo(), e));
        }
    }
}

function* updateUserInfoResourceSaga(action: UserInfoResourceAction) {
    const userInfo = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    if (!userInfo?.id) {
        if (action && action?.userInfo?.id) {
            yield put(
                updateUserInfoResourceAction({ userInfo: action.userInfo })
            );
        }
    } else {
        const quotaResourceUserState = yield select(
            (state: ResourceAppState) => state.quotaResourceUserState.data
        );
        const loading = yield select(
            (state: ResourceAppState) => state.quotaResourceUserState.loading
        );

        if (!loading && !quotaResourceUserState?.userId && userInfo?.email) {
            yield put(updateLoadingQuotaAction({ loading: true }));
            let res = yield call(getQuotaResourceByUserIdApi, {
                userId: userInfo?.id,
            });
            yield put(updateQuotaResourceAction(res, userInfo?.id));
            yield put(updateLoadingQuotaAction({ loading: false }));
        }
    }
}
function* listenGetAppReviewsSaga() {
    yield takeEvery(UserInfoResourceTypes.UPDATE_USER_EMAIL, updateEmailSaga);
    yield takeEvery(
        UserInfoResourceTypes.USER_LOGIN_SUCCESS,
        updateUserInfoResourceSaga
    );
    yield takeEvery(
        UserInfoResourceTypes.UPDATE_USERINFO_RESOURCE,
        getQuotaResourceSaga
    );
}
export const authSaga = [
    loginSaga(),
    loginSuccessSaga(),
    fork(listenGetAppReviewsSaga),
];
