import { IWord, Word } from "../../../../shared/models/crossword";

export const initGrid = (rows: number, columns?: number) => {
    let numRows = rows;
    let numColumns = columns ? columns : rows;

    return Array.from({ length: numRows }, () => Array(numColumns).fill(""));
};

export const getSizeGrid = (words: IWord[]) => {
    const result = {
        numRows: 0,
        numColumns: 0,
    };
    if (words.length === 0) {
        return result;
    }

    let maxLength = 26;

    words.forEach((word) => {
        const value = word.value.trim().length;
        if (value > maxLength) {
            maxLength = value;
        }
    });

    return {
        numRows: maxLength,
        numColumns: Math.ceil((maxLength * 4) / 5),
    };
};

// ! Hàm này in ma trận bé tí
export const getSizeGridByWords = (words: IWord[]) => {
    if (words.length === 0) {
        return {
            numRows: 0,
            numColumns: 0,
        };
    }

    const values: string[] = words
        .filter((word) => word.value.trim().length)
        .map((word) => word.value);
    values.sort((a, b) => b.length - a.length);
    const maxWordLength = values[0]?.length;
    const middleIndex = Math.ceil(values.length / 2);

    // Lấy nửa danh sách chữ có độ dài lớn nhất rồi trừ độ dài danh sách đó và cộng 1
    let result = 0;
    for (let i = 0; i < middleIndex; i++) {
        result += values[i].length;
    }
    result = result - middleIndex + 1;
    result = result < maxWordLength ? maxWordLength : result;

    let numRows = result;
    let numColumns = result;

    const maxGridSize = maxWordLength > 32 ? maxWordLength : 32;

    if (numRows > maxGridSize) {
        numRows = maxGridSize;
        numColumns = Math.ceil((numRows * 4) / 5);
    }

    // giới hạn
    return {
        numRows,
        numColumns,
    };
};

export const removeItemsWithSameKey = (array: any[]) => {
    const unique = new Set();

    const removeItems = [];
    for (const item of array) {
        if (!unique.has(item)) {
            unique.add(item);
        } else {
            removeItems.push(item);
        }
    }

    removeItems.forEach((item) => {
        unique.delete(item);
    });

    return Array.from(unique);
};

export const random = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Format chữ trước khi vào crossword
export const formatWords = (words: IWord[]) => {
    return words.map((word, index) => {
        const value = word.value;
        const formatValue = value
            .trim()
            .split("")
            .filter((l) => l.trim().length > 0)
            .join("")
            .toUpperCase();
        return {
            value: formatValue,
            clue: word.clue,
            originValue: value,
            index: index,
            place: value.trim().length === 0,
        };
    });
};

export const cleanWords = (words: IWord[]) => {
    const rawWords = JSON.parse(JSON.stringify(words));
    rawWords.sort((w, _w) => w.index - _w.index);

    const indexPrefixMap = {};
    let indexPrefix = 1;
    const result: IWord[] = rawWords.map((word: IWord) => {
        const newWord = new Word({
            ...word,
            value: word.originValue,
        });
        delete newWord.place;
        delete newWord.originValue;

        // Gán indexPrefix cho chữ được đặt vào grid và gán đáp án điền của user
        if (newWord.onGrid) {
            if (newWord.indexPrefix === -1) {
                const { row, column } = newWord.position;
                const id = `${row}-${column}`;
                const valueExist = indexPrefixMap[id];

                if (valueExist) {
                    newWord.indexPrefix = valueExist;
                } else {
                    newWord.indexPrefix = indexPrefix;
                    indexPrefixMap[id] = indexPrefix;
                    indexPrefix++;
                }
            }
        }

        return newWord;
    });
    return result;
};

//! Hàm này thay đổi mảng gốc
export const shuffleArray = <T>(array: T[]) => {
    let currentIndex = array.length;
    let randomIndex, temporaryValue;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
};

export const genDefaultGrid = () => {
    const GRIDS = [
        '[["","","","","S","","","","","V","","","","","","","",""],["","","","","U","","","","","O","","","","","","","",""],["","","","","S","","","","","L","","","","","","","",""],["","","","","H","U","","","X","C","V","","","","T","","",""],["","H","I","K","I","N","G","","E","A","","","","","E","","",""],["","","","","","I","P","I","A","N","O","","I","S","L","A","N","D"],["","","","","","V","","X","G","O","","","S","","E","","",""],["C","A","N","D","L","E","","G","L","","","","P","","V","","",""],["","","","","O","R","C","H","E","S","T","R","A","","I","","",""],["","","","","","S","","","","M","","","R","","S","","",""],["","","","","","E","","","G","O","","","R","","I","","",""],["","","","","","","","C","U","O","","R","O","B","O","T","",""],["","","","","","","R","A","I","N","B","O","W","","N","","",""],["","","","","","","","C","T","L","","","","","","","",""],["","","","","","","","T","A","I","","","","","","","",""],["","","","","","","","U","R","G","","","","","","","",""],["","","","","","","","S","","H","","","","","","","",""],["","","","","","","","","","T","","","","","","","",""]]',
        '[["","G","","","","","","","","","","","","","","","",""],["","U","","","","","","","","","","","","C","","","",""],["","I","","","","","","","","","","S","P","A","R","R","O","W"],["","T","","","","","","","P","","","","","N","","","",""],["","A","","T","E","L","E","V","I","S","I","O","N","D","","","",""],["O","R","C","H","E","S","T","R","A","","C","","","L","","","",""],["","","","","","","","","N","E","A","G","L","E","","","",""],["","","","","","U","","V","O","L","C","A","N","O","","","",""],["","","M","O","O","N","L","I","G","H","T","","","H","","","",""],["","","","","","I","","","","S","U","S","H","I","","","",""],["","","","X","C","V","","","","","S","","","K","","","R",""],["","","","G","","E","","","","","","R","A","I","N","B","O","W"],["","","","H","","R","","","","","","","","N","","","B",""],["","","","","I","S","L","A","N","D","","","","G","","","O",""],["","","","","","E","","","","","","","","","","","T",""]]',
        '[["","S","","","","","","","","","","","","","","","","","","","",""],["","U","","","","C","","H","","","","","","","","","","","","","",""],["","S","S","","","A","","I","","","","","","","","","","","C","","",""],["","H","P","","","N","","K","","","","","","O","","","","","A","","",""],["P","I","A","N","O","D","R","I","","","","","","R","E","","","","C","","",""],["","","R","I","S","L","A","N","D","","","","","C","A","","","","T","","",""],["","","R","","","E","I","G","","","X","G","H","H","G","","","","U","","R",""],["","V","O","L","C","A","N","O","","","C","","T","E","L","E","V","I","S","I","O","N"],["","","W","","","","B","U","N","I","V","E","R","S","E","","","","","","B",""],["","","","","","M","O","O","N","L","I","G","H","T","","","","","","","O",""],["","","","","","","W","","G","U","I","T","A","R","","","","","","","T",""],["","","","","","","","","","","","","","A","","","","","","","",""]]',
    ];
    const index = random(0, 2);
    return JSON.parse(GRIDS[index]);
};

export const logCrossword = (a, grid) => {
    console.group();
    const numberPlacedWords = a.filter((w) => w.onGrid).length;
    if (numberPlacedWords === a.length) {
        console.log("Placed: ", `${numberPlacedWords}/${a.length}`);
    } else {
        console.log("Placed all words", numberPlacedWords);
    }
    console.log(a);
    console.table(grid);
    console.groupEnd();
};
