import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
import { IActivity } from "../../shared/models/WorkSheetCreator";
import { IPageWorksheetNew } from "../../shared/models/pageWorksheetNew";
import {
    MathAttribute,
    QuestionActivity,
} from "../../shared/models/questionActivity";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";
import ConstantsMath from "../../shared/utils/ConstantsResourceMath";
import Config from "../../shared/utils/config";
import {
    generateMathElements,
    updateListStyleItems,
} from "../../utils/create-math";
import { newPageResource } from "../../utils/draw";
import {
    addPageWorksheet,
    addResourceItems,
    removeResourceItems,
    removeResourceItemsByActivityId,
    removeUnusedPages,
    setCurrentActivity,
    upDateShowAnswerKey,
    updateCurrentActivityToListActivity,
} from "../reducers/createWorksheet";
import { RootState } from "../store";

type TypeDispatch = ThunkDispatch<unknown, unknown, AnyAction>;

const updateActivity = (newActivity: IActivity, dispatch: TypeDispatch) => {
    dispatch(updateCurrentActivityToListActivity({ activity: newActivity }));
    dispatch(setCurrentActivity({ activity: newActivity }));
};

const updateResourceItems = (
    pages: IPageWorksheetNew[],
    resourceItems: IResourceItemNew[],
    activityId: string,
    maxPageIndex: number,
    dispatch: TypeDispatch
) => {
    if (activityId !== "") {
        dispatch(removeResourceItemsByActivityId({ id: activityId }));
    }

    for (let i = 0; i <= maxPageIndex; i++) {
        const resourcePageIndex = resourceItems.filter(
            (item) => item.pageIndex === i
        );
        const isPageExist = pages[i];

        if (isPageExist) {
            dispatch(
                addResourceItems({
                    resourceItems: resourcePageIndex,
                    pageIndex: i,
                })
            );
        } else {
            dispatch(
                addPageWorksheet({
                    resourceItems: [
                        ...newPageResource(i),
                        ...resourcePageIndex,
                    ],
                })
            );
        }
    }

    dispatch(removeUnusedPages());
};

const getQuestionsAndItems = (items: any[], activityType: string) => {
    return {
        questions: items.map(
            (item) =>
                new QuestionActivity({
                    type: activityType,
                    mathAttribute: new MathAttribute(item),
                })
        ),
        resourceItems: items.reduce(
            (pre, item) => [...pre, ...item.resourceItems],
            []
        ),
    };
};

const updateToStore = (
    pagesWorksheet: IPageWorksheetNew[],
    resourceItems: IResourceItemNew[],
    activity: IActivity,
    pageIndex: number,
    dispatch: TypeDispatch
) => {
    updateResourceItems(
        pagesWorksheet,
        resourceItems,
        activity.id,
        pageIndex,
        dispatch
    );
    updateActivity(activity, dispatch);
    if (activity.listStyle !== ConstantsMath.LIST_STYLE.NONE) {
        dispatch(changeListStyle({ listStyle: activity.listStyle }));
    }
};

export const changeDirection = createAsyncThunk(
    "mathActivity/changeDirection",
    async (
        params: { direction: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const direction = params.direction;
            const newActivity = {
                ...currentActivity,
                direction,
            };
            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
            });
            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeHiddenStyle = createAsyncThunk(
    "mathActivity/changeHiddenStyle",
    async (
        params: { hidden: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const hiddenStyle = params.hidden;
            const newActivity: IActivity = {
                ...currentActivity,
                hiddenStyle,
                questions: currentActivity.questions.map((q) => ({
                    ...q,
                    mathAttribute: {
                        ...q.mathAttribute,
                        ...(hiddenStyle === ConstantsMath.HIDDEN_STYLE.DEFAULT
                            ? { hiddenNumber: 3 }
                            : {}),
                    },
                })),
            };
            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
            });
            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeMainStyle = createAsyncThunk(
    "mathActivity/changeMainStyle",
    async (
        params: { styleId: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const styleId = params.styleId;
            const isDefaultStyle = styleId === ConstantsMath.MAIN_STYLE.DEFAULT;
            const newActivity = {
                ...currentActivity,
                mainStyle: styleId,
                direction: isDefaultStyle
                    ? ConstantsMath.DIRECTION.HORIZONTAL
                    : ConstantsMath.DIRECTION.LEFT,
                strokeStyle: isDefaultStyle
                    ? ConstantsMath.STROKE_STYLE.SHAPE
                    : ConstantsMath.STROKE_STYLE.CIRCLE,
            };
            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
            });

            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);

            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeStrokeStyle = createAsyncThunk(
    "mathActivity/changeStrokeStyle",
    async (
        params: { stroke: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const stroke = params.stroke;
            const newActivity = {
                ...currentActivity,
                strokeStyle: stroke,
            };
            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
                keepHidden: true,
            });
            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);

            newActivity.questions = questions;
            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeNumberOfQuestions = createAsyncThunk(
    "mathActivity/changeNumberOfQuestions",
    async (
        params: { numberOfQuestion: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const numberOfQuestion = params.numberOfQuestion;
            const newActivity = {
                ...currentActivity,
            };

            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
                regenerate: true,
                numberOfQuestions: numberOfQuestion,
            });

            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeRangeQuestions = createAsyncThunk(
    "mathActivity/changeRangeQuestions",
    async (
        params: { factor: number; isFrom: boolean; value: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const { factor, isFrom, value } = params;
            const newActivity: IActivity = JSON.parse(
                JSON.stringify(currentActivity)
            );

            const attrFactor = factor === 1 ? "range1" : "range2";
            const attrRange = isFrom ? "min" : "max";

            newActivity[attrFactor][attrRange] = value;

            const { resourceItems, pageIndex } = generateMathElements({
                regenerate: true,
                activity: newActivity,
            });

            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeRegrouping = createAsyncThunk(
    "mathActivity/changeRegrouping",
    async (
        params: { regrouping: boolean },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const regrouping = params.regrouping;
            const newActivity = {
                ...currentActivity,
                regrouping: regrouping ? Config.SHOW_VALUE : Config.HIDE_VALUE,
            };

            const { resourceItems, pageIndex } = generateMathElements({
                activity: newActivity,
                regenerate: true,
            });

            const { questions, resourceItems: newResourceItems } =
                getQuestionsAndItems(resourceItems, newActivity.type);
            newActivity.questions = questions;

            updateToStore(
                pagesWorksheet,
                newResourceItems,
                newActivity,
                pageIndex,
                dispatch
            );
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeShowAnswer = createAsyncThunk(
    "mathActivity/changeShowAnswer",
    async (
        params: { showAnswer: boolean },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const showAnswer = params.showAnswer;
            const newActivity = {
                ...currentActivity,
                showAnswerKey: showAnswer
                    ? Config.SHOW_VALUE
                    : Config.HIDE_VALUE,
            };
            dispatch(upDateShowAnswerKey(showAnswer));
            updateActivity(newActivity, dispatch);
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);

export const changeListStyle = createAsyncThunk(
    "mathActivity/changeListStyle",
    async (
        params: { listStyle: number },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const { currentActivity, pagesWorksheet } = (
                getState() as RootState
            ).createWorksheetState;
            const listStyle = params.listStyle;
            const newActivity = {
                ...currentActivity,
                listStyle: listStyle,
            };

            for (let i = 0; i < pagesWorksheet.length; i++) {
                const page = pagesWorksheet[i];
                const resourceIds = page.resourceItems.reduce((pre, item) => {
                    const current = pre;
                    if (item.idType?.includes(ConstantsMath.PREFIX_MATH_LIST)) {
                        current.push(item.id);
                    }

                    return current;
                }, []);

                dispatch(removeResourceItems({ resourceIds, pageIndex: i }));
            }

            if (listStyle !== ConstantsMath.LIST_STYLE.NONE) {
                const resourceItems = pagesWorksheet.reduce((pre, page) => {
                    const items = page.resourceItems.filter(
                        (item) =>
                            item.idType?.includes(
                                ConstantsMath.PREFIX_MATH_ITEM
                            ) &&
                            !item.idType?.includes(
                                ConstantsMath.PREFIX_MATH_LIST
                            )
                    );

                    return [...pre, ...items];
                }, []);

                const { listItems, maxPageIndex } = updateListStyleItems(
                    resourceItems,
                    newActivity
                );

                updateResourceItems(
                    pagesWorksheet,
                    listItems,
                    "",
                    maxPageIndex,
                    dispatch
                );
            }

            updateActivity(newActivity, dispatch);
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error);
        }
    }
);
