import { useDispatch, useSelector } from "react-redux";
import ActionGrid from "../../../../resource-user/components/main-collection";
import { selectActionGridItemAction } from "../../../../resource-user/redux/action/resource.action";
import { ResourceAppState } from "../../../../resource-user/redux/reducer/root.reducerModule";
import Config from "../../../../shared/utils/config";
import WorksheetsTabProject from "./worksheets";
import AlertComponentResource from "../../../../resource-user/components/common/alert";

const TabProject = () => {
    const dispatch = useDispatch();
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem
    );

    const action = actionGridItem.action;
    const worksheetAction = actionGridItem?.worksheet;
    const collectionAction = actionGridItem?.collection;
    const worksheetsAction = actionGridItem?.worksheets;
    return (
        <>
            <div className="content-tab-project">
                <WorksheetsTabProject />
            </div>
            {action !== Config.ACTION_GRID.DEFAULT &&
                (worksheetAction?.id ||
                    collectionAction?.id ||
                    worksheetsAction?.length > 0 ||
                    action === Config.ACTION_GRID.CREATE_COLLECTION) && (
                    <>
                        <ActionGrid
                            customClick={() => {
                                dispatch(
                                    selectActionGridItemAction({
                                        action: Config.ACTION_GRID.DEFAULT,
                                    })
                                );
                            }}
                        />
                    </>
                )}
            <AlertComponentResource />
        </>
    );
};

export default TabProject;
