import { useEffect } from "react";
import {
    addQuestionMC,
    deleteQuestionMC,
} from "../../../../redux/async/multipleChoiceThunk";
import { setMessage } from "../../../../redux/reducers/snackbar";
import { IActivity } from "../../../../shared/models/WorkSheetCreator";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../shared/models/questionActivity";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import { generateIdFromDateTime } from "../../../../utils";
import { getContent } from "../../../../utils/multipleChoice";
import { checkAbleToAdd } from "../util";
import { useAppDispatch } from "../../../../redux/hook";

export const getLastY = (listItem: IResourceItemNew[]) => {
    let heighest = 0;
    let height = 0;
    let y = 0;
    listItem.forEach((ele: IResourceItemNew) => {
        if (ele.height + ele.y > heighest) {
            heighest = ele.height + ele.y;
            y = ele.y;
            height = ele.height;
        }
    });
    return {
        height,
        y,
    };
};

const AddQuestion = ({
    addExample,
    setAddExample,
    activity,
    listQuestion,
    setCurrentQuestion,
    setOpenQuestion,
}: {
    addExample: any;
    setAddExample?: Function;
    activity: IActivity;
    listQuestion: IQuestionActivity[];
    setCurrentQuestion?: Function;
    setOpenQuestion?: Function;
}) => {
    const dispatch = useAppDispatch();
    const listQuestionActivity: IQuestionActivity[] = activity.questions;
    const lastQuestion: IQuestionActivity =
        listQuestionActivity[listQuestionActivity?.length - 1];
    const listAnswer: IAnswerActivity[] = lastQuestion?.answers;
    const defaultAnswer = [
        {
            id: generateIdFromDateTime(),
            text: addExample.isAdd ? "Red" : "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: addExample.isAdd ? "Blue" : "",
            isCorrect: addExample.isAdd ? true : false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: addExample.isAdd ? "Green" : "",
            isCorrect: false,
            indexWord: 0,
        },
        {
            id: generateIdFromDateTime(),
            text: addExample.isAdd ? "Yellow" : "",
            isCorrect: false,
            indexWord: 0,
        },
    ];

    const isBlankQues = () => {
        const nonAnswerQues = listQuestionActivity?.findIndex(
            (question: IQuestionActivity) =>
                question.answers.every(
                    (answer: IAnswerActivity) => answer.isCorrect == false
                )
        );
        let isEmptyContent = false;
        let emptyContentQues = listQuestion?.findIndex(
            (question: IQuestionActivity) =>
                !getContent(question.question).trim()
        );
        let emptyContentAnswer = listQuestion?.findIndex(
            (question: IQuestionActivity) =>
                question.answers?.find(
                    (answer: IAnswerActivity) => !answer.text.trim()
                )
        );
        if (emptyContentQues != -1 && emptyContentAnswer != -1) {
            isEmptyContent = true;
        }
        if (nonAnswerQues != -1 && isEmptyContent) {
            if (nonAnswerQues != -1) {
                return {
                    isBlank: true,
                    index: nonAnswerQues,
                };
            } else {
                return {
                    isBlank: true,
                    index:
                        emptyContentQues != -1
                            ? emptyContentQues
                            : emptyContentAnswer,
                };
            }
        }
        return {
            isBlank: false,
            index: listQuestion.length - 1,
        };
    };
    let haveBlankQues = isBlankQues();

    const questionIndex: number = listQuestion.length;
    let question: IQuestionActivity = {
        id: generateIdFromDateTime(),
        question: `${questionIndex + 1}.${
            addExample.isAdd ? " What color is the sky on a clear day?" : ""
        } `,
        answers: defaultAnswer,
        indexQuestion: addExample.isAdd
            ? haveBlankQues.index
            : listQuestion.length - 1,
        multipleChoicesAttribute: {
            numberCol: 4,
            resourceItemIds: [],
        },
    };
    const lastAnswersId: Array<string> = [];
    listAnswer?.map((ele: IAnswerActivity) => lastAnswersId.push(ele.id));

    useEffect(() => {
        if (addExample.isAdd) {
            let isAddNew = true;
            if (haveBlankQues.isBlank) {
                dispatch(
                    deleteQuestionMC({
                        question: listQuestion[haveBlankQues.index],
                    })
                );
                isAddNew = false;
            }
            handleAddNewQuestion(isAddNew);
        }
    }, [addExample]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const worksheetId = params.get("worksheetId");
        if (!worksheetId && listQuestionActivity.length == 0) {
            handleAddNewQuestion(true);
        }
    }, []);

    const handleAddNewQuestion = (isAddNew) => {
        let ableToAdd: any = checkAbleToAdd(activity);
        if (ableToAdd.isAble || listQuestion.length == 0 || addExample.isAdd) {
            dispatch(
                addQuestionMC({
                    question: question,
                    questionIndex: isAddNew
                        ? haveBlankQues.index + 1
                        : haveBlankQues.index,
                })
            );
            setCurrentQuestion(
                isAddNew ? listQuestion.length : listQuestion.length - 1
            );
            setAddExample({
                number: listQuestion.length,
                isAdd: false,
            });
            setOpenQuestion(true);
        } else {
            dispatch(
                setMessage({
                    severity: "error",
                    message: ableToAdd.message,
                })
            );
        }
        if (listQuestion.length >= 1) {
            setTimeout(() => {
                let listQuesBox = document.getElementById("question-container");
                listQuesBox.scrollTo({
                    top: listQuesBox.scrollHeight,
                    behavior: "smooth",
                });
            }, 20);
        }
    };

    return (
        <div className="add-question-container">
            <div
                className="add-new-question"
                onClick={() => {
                    handleAddNewQuestion(true);
                }}
            >
                <span>Add New Question</span>
            </div>
        </div>
    );
};

export default AddQuestion;
