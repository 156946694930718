import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './styles.scss';
import { updateDataAPI } from '../../../../../utils/query';
import { useDispatch } from 'react-redux';
import { setTemplate } from '../../../../../redux/reducers/createWorksheet';

const AddTemplateTagModal = ({ template, hideModal, categories, refreshCategories }) => {
    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [parentTag, setParentTag] = useState(null);

    const handleAddTag = async () => {
        if (selectedCategory && selectedTag) {
            try {
                const res = await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
                    tags: template.tags.concat(selectedTag.value.id)
                });
                dispatch(setTemplate({...template, tags: template.tags.concat(selectedTag.value.id), tagsData: template.tagsData.concat(selectedTag.value)}))

                if (parentTag) {
                    await updateDataAPI('PATCH', 'auto-content/tags/' + selectedTag.value.id + '/', {
                        parent: parentTag.value.id
                    });
                }

                hideModal();
            } catch (error) {
                console.error('Error adding tag:', error);
            }
        }
    };

    const createNewTag = async (newTag) => {
        await updateDataAPI('POST', 'auto-content/tags/', {
            category: selectedCategory.value,
            name: newTag
        });
        await refreshCategories();
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Add Tag Modal"
            className="add-template-tag-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label>Select a Category:</label>
                    <Select
                        options={categories.map(category => ({ value: category.id, label: category.name }))}
                        value={selectedCategory}
                        onChange={val => {
                            setSelectedCategory(val);
                        }}
                    />
                </div>
                {selectedCategory && (
                    <div>
                        <div className="mb-4">
                            <label>Select or Create a Tag:</label>
                            <CreatableSelect
                                options={selectedCategory.value ? categories.find(c => c.id === selectedCategory.value).tags.map(tag => ({ value: tag, label: tag.name })) : []}
                                value={selectedTag}
                                onChange={val => setSelectedTag(val)}
                                onCreateOption={val => createNewTag(val)}
                                isClearable={true}
                            />
                        </div>
                        <div>
                            <label>Parent (optional):</label>
                            <Select
                                options={selectedCategory.value ? categories.find(c => c.id === selectedCategory.value).tags.map(tag => ({ value: tag, label: tag.name })) : []}
                                value={parentTag}
                                onChange={val => setParentTag(val)}
                            />
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAddTag}>Add Tag</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddTemplateTagModal;