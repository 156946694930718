import { call, fork, put, select, takeEvery } from "@redux-saga/core/effects";
import { userUnFollowAPI } from "./../repositories/user";

import { ICollection } from "../../../shared/models/collection";
import { IDownloadWorksheet } from "../../../shared/models/downloadWorksheets";
import { IPracticeWorksheet } from "../../../shared/models/practiceWorksheets";
import { IUserInfo } from "../../../shared/models/userInfo";
import { IUserRela } from "../../../shared/models/userRela";
import { IWorksheet } from "../../../shared/models/worksheet";
import {
    getCollectionProfileSuccessAction,
    getMoreProfileInfoSuccessAction,
    getUserSuccessAction,
    updateFollowingSuccessAction,
    UserAction,
    UserTypes,
} from "../action/user.action";

import Config from "../../../shared/utils/config";
import ConstantsResource from "../../utils/ConstantsResource";
import {
    getCollectionByUserAPI,
    getDownloadWorksheetsByUser,
    getMoreProfileInfoAPI,
    getPracticeWorksheetsByUser,
    getUserInfoAPI,
    getUserRelaAPI,
    userFollowAPI,
} from "../repositories/user";
import { getWorkSheetByUserAPI } from "../repositories/workSheet.repositories";
import { ResourceAppState } from "../reducer/root.reducerModule";

function* getUserInfoSaga(action: UserAction) {
    const { id, worksheetType, loadMore, offset } = action;
    if (id) {
        let userRelas: IUserRela[] = yield call(getUserRelaAPI, { id });
        let worksheetsData: any;
        let worksheets: IWorksheet[] = [];
        let userInfo: IUserInfo | undefined;
        let numberOfWorksheets = 0;
        let downloadWorksheets: IDownloadWorksheet[] = [];
        let practiceWorksheets: IPracticeWorksheet[] = [];

        if (loadMore) {
            // if (myInfo.id == id) {
            let collections: ICollection[] = yield call(
                getCollectionByUserAPI,
                {
                    id,
                    limit: ConstantsResource.LIMIT_GET_COLLECTION_NB,
                    offset: 0,
                }
            );
            collections = collections.map((c) => {
                let nbWorksheets = 0;
                nbWorksheets += c.worksheetItems.length;

                collections.forEach((subc) => {
                    if (subc.parentIds.includes(c.id)) {
                        nbWorksheets += subc.worksheetItems.length;
                    }
                });

                c.numberOfWorksheets += nbWorksheets;
                return c;
            });
            yield put(
                getCollectionProfileSuccessAction(
                    collections,
                    collections.length
                )
            );
            // } else {
            userInfo = yield call(getUserInfoAPI, { id });
            worksheetsData = yield call(getWorkSheetByUserAPI, {
                id,
                worksheetType,
                offset,
                limit: Config.LIMIT_DATA.LIMIT_WORKSHEET,
            });
            worksheets = worksheetsData.worksheets;
            numberOfWorksheets = worksheetsData.numberOfWorksheets;
            // }
            downloadWorksheets = yield call(getDownloadWorksheetsByUser, {
                id,
                offset: 0,
                worksheetType,
            });
            practiceWorksheets = yield call(getPracticeWorksheetsByUser, {
                id,
                offset: 0,
                worksheetType,
            });
        }

        yield put(
            getUserSuccessAction(
                userRelas,
                worksheets,
                userInfo,
                numberOfWorksheets,
                downloadWorksheets,
                practiceWorksheets
            )
        );
    }
}

function* getUserFollowSaga(action: UserAction) {
    const { userId, followerId } = action;

    if (userId && followerId) {
        let userRela: IUserRela = yield call(userFollowAPI, {
            userId,
            followerId,
        });
        if (userRela) {
            yield put(updateFollowingSuccessAction(userRela));
        }
    }
}

function* unFollowSaga(action: UserAction) {
    const id = action.id;

    if (id) {
        let success: boolean = yield call(userUnFollowAPI, { id });
        if (success) {
            const isFollowing = true;
            yield put(updateFollowingSuccessAction());
        }
    }
}

function* getMoreProfileInfoSaga(action: UserAction) {
    const { userId } = action;
    const authorId = yield select(
        (state: ResourceAppState) => state.userInfoResourceState.data.id
    );
    let data: any = yield call(getMoreProfileInfoAPI, {
        userId,
        authorId,
    });
    const {
        totalWSCreated,
        totalFollower,
        userInfo,
        totalDownloadWS,
        totalPracticeWS,
        isFollowing,
        nbWorksheetInCollection,
    } = data;
    const collections = [];
    // if (totalDownloadWS) {
    //     collections.push(
    //         new Collection({
    //             name: "Download Worksheets",
    //             numberOfWorksheets: totalDownloadWS,
    //         })
    //     );
    // }
    // if (totalPracticeWS) {
    //     collections.push(
    //         new Collection({
    //             name: "Worksheets Submitted",
    //             numberOfWorksheets: totalPracticeWS,
    //         })
    //     );
    // }

    yield put(
        getMoreProfileInfoSuccessAction({
            numberOfWorksheets: totalWSCreated,
            totalFollower,
            userInfo,
            collections,
            isFollowing,
            nbWorksheetInCollection,
        })
    );
}

function* listenGetUserInfo() {
    yield takeEvery(UserTypes.GET_USER_INFO, getUserInfoSaga);
}

function* listenUserFollow() {
    yield takeEvery(UserTypes.GET_MORE_PROFILE_INFO, getMoreProfileInfoSaga);

    yield takeEvery(UserTypes.USER_FOLLOW, getUserFollowSaga);
    yield takeEvery(UserTypes.UNFOLLOW, unFollowSaga);
}

export const userSaga = [fork(listenGetUserInfo), fork(listenUserFollow)];
