import {
    useEffect,
    useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook';
import { IFont } from '../../../../../shared/models/module';
import { updateDataAPI } from '../../../../../utils/query';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import './styles.scss';
import { setFontFamilyList, setMissingFontFamilyList } from '../../../../../redux/reducers/createWorksheet';
import { loadFonts } from '../../../../../utils';
import UploadLocalResource from '../upload-local-resource';
import Config from '../../../../../shared/utils/config';
import { sortResourcesByStore } from '../../../../../shared/utils';

export const ABLE_EDIT_FONT_FAMILY_FIELD = [
    {
        id: 1,
        field: 'name',
        type: 'text',
    }
];

function TabFontFamilies({
    currentStore,
} : {
    currentStore: number | null;
}) {
    const dispatch = useAppDispatch();
    const { fontFamilyList, missingFontFamilyList, ideaTemplate, template } = useAppSelector((state) => state.createWorksheetState);
    const [editedCell, setEditedCell] = useState<IFont>(null);

    useEffect(() => {
        const recheckMissingFontFamily = () => {
            const updatedMissingFontFamilyList = missingFontFamilyList.filter((fontName) => !fontFamilyList.some((fontFamily) => fontFamily.name === fontName));
            dispatch(setMissingFontFamilyList(updatedMissingFontFamilyList));
        };

        recheckMissingFontFamily();
    }, [fontFamilyList]);

    const handleCellChange = (event, field: string) => {
        const { value } = event.target;
        setEditedCell((prevCell: IFont) => ({
            ...prevCell,
            [field]: value
        }));
    };

    const handleEditFontFamily = (fontFamily: IFont) => {
        setEditedCell(fontFamily)
    };

    const hanldeUpdateFontFamily = async (fontFamily: IFont) => {
        if (editedCell.name !== '') {
            try {
                const url = `auto-content/font-families/${fontFamily.id}/`;

                const res = await updateDataAPI('PATCH', url, editedCell);

                if (res.data) {
                    const updatedFontFamily = res.data;

                    const updatedFontFamilyList = [...fontFamilyList].map((fontFamily) => {
                        if (fontFamily.id === updatedFontFamily.id) {
                            return updatedFontFamily;
                        };
                        return fontFamily;
                    })
                    dispatch(setFontFamilyList(updatedFontFamilyList));
                    setEditedCell(null);
                    window.alert('Updated!');
                }
            } catch (e) {
                window.alert(`An error occured when updating font family: ${e.message}`);
            };
        };
    };

    const handleDeleteFontFamily = async (fontFamilyID: number) => {
        const confirmed = window.confirm('Are you sure you want to delete this font family?');

        if (confirmed) {

            const url = `auto-content/font-families/${fontFamilyID}/`;

            try {
                const res = await updateDataAPI('DELETE', url);
                dispatch(setFontFamilyList(fontFamilyList.filter((fontFamily) => fontFamily.id !== fontFamilyID)))
                setEditedCell(null);
                window.alert('Deleted!');
            } catch (e) {
                window.alert(`An error occured when deleting: ${e.message}`)
            };
        };
    };

    const hanldeUploadFontFamily = async (data: IFont) => {
        await loadFonts(data);
        dispatch(setFontFamilyList([...fontFamilyList, data]));
    };

    return (
        <div className="table-font-family-list">
            {missingFontFamilyList.length > 0 && (
                <div>
                    <h5>Missing font families:</h5>
                    <ul>
                        {missingFontFamilyList.map((name) => (
                            <li>{name}</li>
                        ))}
                    </ul>
                </div>
            )}
            <UploadLocalResource
                title='Upload local font family:'
                handleNewResourceData={hanldeUploadFontFamily}
                typeResource={Config.RESOURCE_FONT_FAMILIES}
                acceptExtentions={['.woff', '.woff2', '.tff', '.otf', '.ttf']}
            />
            <Table>
                <thead>
                    <tr>
                        <th>Font Name</th>
                        <th>Store</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {[...fontFamilyList].map((fontFamily, index) => (
                        <tr key={fontFamily.id}>
                            <td>
                                {editedCell && editedCell.id === fontFamily.id ? (
                                    <Form.Control
                                        name="name"
                                        value={editedCell?.name}
                                        onChange={(e) => handleCellChange(e, 'name')}
                                        autoFocus
                                    />
                                ) : (
                                    <span style={{ fontFamily: fontFamily['name'], fontSize: '12px' }}>{fontFamily['name']}</span>
                                )}
                            </td>
                            <td>{fontFamily.store.includes(currentStore) ? 'CURRENT' : `${fontFamily.store.length > 0 ? `Store ${fontFamily.store.join(', ')}` : ''}`}</td>
                            <td className="text-center">
                                {(editedCell && editedCell.id === fontFamily.id) && (
                                    <div className="table-font-family-list__actions">
                                        <Save style={{ fontSize: '25px', color: 'green', marginRight: '5px' }} onClick={() => hanldeUpdateFontFamily(fontFamily)} />
                                        <Cancel style={{ fontSize: '25px', color: 'red' }} onClick={() => setEditedCell(null)} />
                                    </div>
                                )}
                                {editedCell === null && (
                                    <div className="table-font-family-list__actions">
                                        <Edit style={{ fontSize: '15px', color: '#0762eb', marginRight: '5px' }} onClick={() => handleEditFontFamily(fontFamily)} />
                                        <Delete style={{ fontSize: '15px', color: 'red' }} onClick={() => handleDeleteFontFamily(fontFamily.id)} />
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row>

            </Row>
        </div>
    );
};

export default TabFontFamilies;