import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SizeActivity } from "../../../../../shared/models/WorkSheetCreator";
import { IPuzzle, Puzzle } from "../../../../../shared/models/puzzle";
import { getPrefixByProjectName } from "../../../../../shared/utils";
import {
    changePuzzleConfigAction,
    resetSvgAction,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import "./index.scss";
import CircleUnChecked from "../../../icons/check-box-icon/CircleUnChecked";
import CircleChecked from "../../../icons/check-box-icon/CircleChecked";

const dataOtherShape = [
    {
        id: "0",
        name: "Small",
    },
    {
        id: "1",
        name: "Medium",
    },
    {
        id: "2",
        name: "Large",
    },
];

const WordSearchSize = () => {
    const dispatch = useDispatch();
    let puzzleRedux: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    const [chooseSize, setChooseSize] = useState("");
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    return (
        <div className="word-search-size">
            <div className="title">Word Search Size</div>
            <div className="other-shape">
                {dataOtherShape.map((data: any, index) => (
                    <div className="checkbox-size" key={index}>
                        <Checkbox
                            icon={<CircleUnChecked />}
                            checkedIcon={<CircleChecked />}
                            onClick={() => {
                                setChooseSize(
                                    chooseSize === data.id ? "" : data.id
                                );
                                let puzzle: IPuzzle = new Puzzle(puzzleRedux);
                                puzzle.puzzleSize = data.name as SizeActivity;
                                dispatch(changePuzzleConfigAction(puzzle));
                                dispatch(
                                    resetSvgAction({
                                        resetSvg: true,
                                    })
                                );
                                let prefix = getPrefixByProjectName(
                                    projectName,
                                    "wse_"
                                );
                            }}
                            checked={data.name === puzzleRedux.puzzleSize}
                            size="small"
                        />
                        <div className="name">{data.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WordSearchSize;
