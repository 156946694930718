import { IActivity } from "../../../../shared/models/WorkSheetCreator";
import Config from "../../../../shared/utils/config";
import "./index.scss";
import SentenceScrambleInput from "./sentence-input";
import WordScrambleInput from "./word-input";
const InputSection = ({ activity }: { activity: IActivity }) => {
    return (
        <div className="word-scramble-input-section">
            {activity.type === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE ? (
                <WordScrambleInput activity={activity} />
            ) : (
                <SentenceScrambleInput activity={activity} />
            )}
        </div>
    );
};

export default InputSection;
