import {
    IMathAttribute,
    MathAttribute,
} from "../../../../shared/models/questionActivity";
import Config from "../../../../shared/utils/config";

interface IRange {
    min: number;
    max: number;
}

interface IParams {
    numberOfQuestions: number;
    rangeOfFirstFactor: IRange;
    rangeOfSecondFactor: IRange;
    regrouping: boolean;
    type: string;
}

const checkTypeHaveRegrouping = (type: string) => {
    return (
        type === Config.ACTIVITY_TYPE.ADDITION.TYPE ||
        type === Config.ACTIVITY_TYPE.SUBTRACTION.TYPE ||
        type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE
    );
};

const defaultParams: IParams = {
    numberOfQuestions: 1,
    rangeOfFirstFactor: {
        min: 1,
        max: 9,
    },
    rangeOfSecondFactor: {
        min: 1,
        max: 9,
    },
    regrouping: false,
    type: Config.ACTIVITY_TYPE.ADDITION.TYPE,
};

const getRangeNumber = ({ min, max }: IRange) => {
    const parseMin = parseInt(min.toString());
    const parseMax = parseInt(max.toString());
    const result = {
        min: parseMin,
        max: parseMax,
    };

    if (parseMin > parseMax) {
        result.max = parseMin;
    }

    return result;
};

const randomNumber = ({ min, max }: IRange): number => {
    const minNumber = Math.ceil(min);
    const maxNumber = Math.floor(max);

    return Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber);
};

const shuffleArray = <T>(array: T[]) => {
    let curId = array.length;
    // There remain elements to shuffle
    while (0 !== curId) {
        // Pick a remaining element
        let randId = Math.floor(Math.random() * curId);
        curId -= 1;
        // Swap it with the current element.
        let tmp = array[curId];
        array[curId] = array[randId];
        array[randId] = tmp;
    }
    return array;
};

const generateRangeArray = ({ min, max }: IRange) => {
    const result: number[] = [];
    let i = min;
    while (i <= max) {
        result.push(i);
        i++;
    }
    return result;
};

const isRegroupingNumber = (
    num1: number,
    num2: number,
    type: string
): boolean => {
    let tempNum1 = num1;
    let tempNum2 = num2;

    switch (type) {
        case Config.ACTIVITY_TYPE.ADDITION.TYPE:
        case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
            while (tempNum1 + tempNum2 > 0) {
                const num1Remainder = tempNum1 % 10;
                const num2Remainder = tempNum2 % 10;

                if (type === Config.ACTIVITY_TYPE.ADDITION.TYPE) {
                    if (num1Remainder + num2Remainder >= 10) return true;
                } else {
                    if (num1Remainder < num2Remainder) return true;
                }

                tempNum1 = Math.floor(tempNum1 / 10);
                tempNum2 = Math.floor(tempNum2 / 10);
            }
            break;
        case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
            while (tempNum2 > 0) {
                const num2Remainder = tempNum2 % 10;
                while (tempNum1 > 0) {
                    const num1Remainder = tempNum1 % 10;
                    if (num1Remainder * num2Remainder >= 10) return true;
                    tempNum1 = Math.floor(tempNum1 / 10);
                }
                tempNum2 = Math.floor(tempNum2 / 10);
            }
            break;
        default:
            throw new Error("this type of math not support");
    }

    return false;
};

const getNumberByOption = (
    arr1: number[],
    arr2: number[],
    type: string,
    regrouping: boolean,
    questions?: IMathAttribute[]
): [number, number] | undefined => {
    const numberArr1 = shuffleArray(arr1);
    const numberArr2 = shuffleArray(arr2);

    let result;
    const notRecommendedResult = new Set();
    const setQuestions = new Set();

    let isPassQuestions = Array.isArray(questions);
    if (isPassQuestions) {
        questions.forEach(({ factor1, factor2 }) => {
            const value = [factor1, factor2];
            setQuestions.add(value);
        });
    }
    const checkIsContains = (numbers: [number, number]) => {
        return setQuestions.has(numbers);
    };
    let shouldBreak = false;
    for (let i = 0; i < numberArr1.length; i++) {
        for (let j = 0; j < numberArr2.length; j++) {
            result = undefined;
            const num1 = numberArr1[i];
            const num2 = numberArr2[j];

            if (type === Config.ACTIVITY_TYPE.DIVISION.TYPE) {
                if (num1 % num2 === 0) {
                    result = [num1, num2];
                }
            } else {
                const isRegrouping = isRegroupingNumber(num1, num2, type);

                if (
                    (isRegrouping && regrouping) ||
                    (!isRegrouping && !regrouping)
                ) {
                    result = [num1, num2];
                }
            }

            if (result) {
                if (isPassQuestions && checkIsContains(result)) {
                    notRecommendedResult.add(result);
                } else {
                    shouldBreak = true;
                    break;
                }
            }
        }
        if (shouldBreak) {
            break;
        }
    }

    if (isPassQuestions && notRecommendedResult.size > 0 && !result) {
        const randomIndex = Math.floor(
            Math.random() * notRecommendedResult.size
        );
        const randomResult = Array.from(notRecommendedResult)[randomIndex];
        result = randomResult;
    }

    return result;
};

export const isContainsRegrouping = (
    rangeOfFirstFactor: IRange,
    rangeOfSecondFactor: IRange,
    type = Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE
) => {
    const parseRange1 = getRangeNumber(rangeOfFirstFactor);
    const parseRange2 = getRangeNumber(rangeOfSecondFactor);
    const listArr1 = generateRangeArray(parseRange1);
    const listArr2 = generateRangeArray(parseRange2);

    const result = getNumberByOption(listArr1, listArr2, type, true);

    return !!result;
};

const generateMathProblems = (
    params: IParams = defaultParams
): IMathAttribute[] => {
    const {
        type,
        numberOfQuestions,
        rangeOfFirstFactor,
        rangeOfSecondFactor,
        regrouping,
    } = params;

    const questions: IMathAttribute[] = [];
    const parseRange1 = getRangeNumber(rangeOfFirstFactor);
    const parseRange2 = getRangeNumber(rangeOfSecondFactor);
    const listArr1 = generateRangeArray(parseRange1);
    const listArr2 = generateRangeArray(parseRange2);

    for (let i = 0; i < numberOfQuestions; i++) {
        let result: number;
        let [firstFactor, secondFactor] = [0, 0];

        const numbers = getNumberByOption(
            listArr1,
            listArr2,
            type,
            regrouping,
            questions
        );
        if (numbers) {
            [firstFactor, secondFactor] = numbers;
        } else {
            [firstFactor, secondFactor] = [
                randomNumber(parseRange1),
                randomNumber(parseRange2),
            ];
        }

        switch (type) {
            case Config.ACTIVITY_TYPE.ADDITION.TYPE:
                result = firstFactor + secondFactor;
                break;
            case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
                result = firstFactor - secondFactor;
                break;
            case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
                result = firstFactor * secondFactor;
                break;
            case Config.ACTIVITY_TYPE.DIVISION.TYPE:
                // Check if the second factor is not zero to avoid division by zero
                result = secondFactor !== 0 ? firstFactor / secondFactor : 0;
                result = parseFloat(result.toFixed(3));
                break;
            default:
                throw new Error("Invalid math type");
        }

        questions.push(
            new MathAttribute({
                factor1: firstFactor,
                factor2: secondFactor,
                result,
            })
        );
    }
    return questions;
};

export default generateMathProblems;
