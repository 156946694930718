import { IPosition, IWord } from "../../../shared/models/crossword";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import { CrosswordTypes, ICrosswordAction } from "../action/crossword.action";

export interface IErrorWords {
    valid: string;
    invalid: string;
}
export interface ICrosswordState {
    showWordBank: boolean;
    showAnswerKey: boolean;
    layout: string;
    words: IWord[];
    grid: string[][];
    currentDirection: number;
    currentWordSelected: IWord | null;
    currentPosition: IPosition | null;
    errorWords: IErrorWords[];
    title: string;
}

const initialState: ICrosswordState = {
    showWordBank: true,
    showAnswerKey: false,
    layout: ConstantsTool.LAYOUT.DEFAULT,
    words: [],
    grid: [],
    currentDirection: ConstantsTool.DIRECTIONS.ACROSS,
    currentWordSelected: null,
    currentPosition: null,
    errorWords: [],
    title: "",
};

const crosswordState = (
    state: ICrosswordState = initialState,
    action: ICrosswordAction
): ICrosswordState => {
    switch (action.type) {
        case CrosswordTypes.INIT_CROSSWORD_GAME_SUCCESS:
            return {
                ...state,
                ...action.config,
            };
        case CrosswordTypes.CHANGE_WORDS_SUCCESS:
            return {
                ...state,
                words: action.words,
                grid: action.grid,
                currentDirection: ConstantsTool.DIRECTIONS.ACROSS,
                currentWordSelected: null,
                currentPosition: null,
            };
        case CrosswordTypes.UPDATE_LAYOUT:
            return {
                ...state,
                layout: action.layout,
            };
        case CrosswordTypes.UPDATE_SHOW_ANSWER_KEY:
            return {
                ...state,
                showAnswerKey: action.status,
            };
        case CrosswordTypes.UPDATE_SHOW_WORD_BANK:
            return {
                ...state,
                showWordBank: action.status,
            };
        case CrosswordTypes.UPDATE_CURRENT_CONFIG_SUCCESS:
            return {
                ...state,
                currentDirection: action.direction,
                currentWordSelected: action.wordSelected,
                currentPosition: action.position,
            };
        case CrosswordTypes.UPDATE_USER_ANSWER_SUCCESS:
            return {
                ...state,
                words: action.words,
            };
        case CrosswordTypes.CHANGE_CLUE_CROSSWORD_SUCCESS:
            return {
                ...state,
                words: action.words,
            };
        case CrosswordTypes.CHANGE_ERROR_WORDS_CROSSWORD:
            return {
                ...state,
                errorWords: action.errorWords,
            };
        case CrosswordTypes.CHANGE_TITLE_WORDS_CROSSWORD:
            return {
                ...state,
                title: action.title,
            };
        default:
            return state;
    }
};

export default crosswordState;
