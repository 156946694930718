import { generateIdFromDateTime } from ".";
import { listAnswerKey } from "../components/ModulesContent/multiple-choice/util";
import { IActivity } from "../shared/models/WorkSheetCreator";
import {
    IPageWorksheetNew,
    PageWorksheetNew,
} from "../shared/models/pageWorksheetNew";
import { IQuestionActivity } from "../shared/models/questionActivity";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../shared/models/resourceItemNew";
import ConstantsTool from "../shared/utils/ConstantsTool";
import { getTextWidthHeightTool, newPageResource } from "./draw";

export const isRelateQuestion = ({
    questionAndAnswerIds,
    resource,
}: {
    questionAndAnswerIds: any[];
    resource: IResourceItemNew;
}) => {
    const isRelate = questionAndAnswerIds.find(
        (questionInfo) =>
            questionInfo.questionId == resource?.id ||
            questionInfo.answerIds.includes(resource?.id)
    );
    return !!isRelate;
};

export const findQuestionPageIndex = (pageWS, question) => {
    const pageIndex = pageWS.findIndex((page) =>
        page.resourceItems.find((resource) => resource.id == question.id)
    );
    return pageIndex;
};

export const getHeighestItem = ({
    listItem,
}: {
    listItem: IResourceItemNew[];
}) => {
    let heighest: number = 0;
    let y: number = 0;
    let ele;
    listItem.forEach((resource) => {
        if (resource?.height > heighest) {
            heighest = resource.height;
            y = resource.y;
            ele = resource;
        }
    });
    return {
        heighest,
        y,
        ele,
    };
};

export const getQuestionAndAnswerIds = (questions) => {
    let Ids = [];
    questions?.forEach((question) => {
        let listIds = {
            questionId: question.id,
            answerIds: [],
        };
        question.answers.forEach((answer) => {
            listIds.answerIds.push(answer.id);
        });
        Ids.push(listIds);
    });
    return Ids;
};

export const getListAnswerHeight = ({
    listAnswer,
    numberCol,
}: {
    listAnswer: IResourceItemNew[];
    numberCol: number;
}) => {
    const rowsInfo: any[] = [];
    const numberRow: number = Math.ceil(listAnswer.length / numberCol);
    for (let i = 0; i < numberRow; i++) {
        let rowInfo: any = [];
        listAnswer.forEach((ele: IResourceItemNew, index: number) => {
            if (index >= i * numberCol && index < (i + 1) * numberCol) {
                rowInfo.push(ele);
            }
        });
        let { heighest, y } = getHeighestItem({ listItem: rowInfo });
        rowsInfo.push({
            row: i + 1,
            height: heighest,
            y: y,
        });
    }
    const firstEle: any = rowsInfo[0];
    const lastHeighestEle: any = rowsInfo[rowsInfo.length - 1];
    const listAnswerHeight: number =
        lastHeighestEle?.y + lastHeighestEle?.height - firstEle?.y;
    return listAnswerHeight;
};

export const getLastYPosition = ({
    listItem,
    questionAndAnswerIds,
}: {
    listItem: IResourceItemNew[];
    questionAndAnswerIds: any[];
}) => {
    if (listItem?.length) {
        let heighestLength: number = 0;
        if (questionAndAnswerIds.length) {
            listItem.forEach((resource: IResourceItemNew) => {
                if (
                    isRelateQuestion({
                        questionAndAnswerIds: questionAndAnswerIds,
                        resource: resource,
                    })
                ) {
                    if (resource.y + resource.height > heighestLength) {
                        heighestLength = resource.y + resource.height;
                    }
                }
            });
        } else {
            listItem.forEach((ele: IResourceItemNew) => {
                if (ele.y + ele.height > heighestLength) {
                    heighestLength = ele.y + ele.height;
                }
            });
        }
        return heighestLength;
    } else {
        return 25; //New page y default
    }
};

export const updateOtherItemPositionMC = ({
    listResourceItem,
    currentElement,
    pageIndex,
    listAnswerIds,
    numberCol,
    yChange,
    questionAndAnswerIds,
    pagesWorksheet,
    activity,
    type,
    action,
}: {
    listResourceItem: IResourceItemNew[];
    currentElement: IResourceItemNew;
    pageIndex: number;
    listAnswerIds: string[];
    numberCol: number;
    yChange: number;
    questionAndAnswerIds: any[];
    pagesWorksheet: IPageWorksheetNew[];
    activity: IActivity;
    type: string;
    action: string;
}) => {
    const elementPage: number = currentElement?.pageIndex;
    const currentElementIndex: number = listResourceItem?.findIndex(
        (ele) => ele?.id == currentElement?.id
    );
    const listQuestionAtPage: IResourceItemNew[] = [];
    questionAndAnswerIds.forEach((ele: any) => {
        const question: IResourceItemNew = listResourceItem.find(
            (resource: IResourceItemNew) => ele.questionId == resource.id
        );
        if (question) {
            listQuestionAtPage.push(question);
        }
    });
    if (type == "answer" && action != "add") {
        let numberRow: number = Math.ceil(listAnswerIds.length / numberCol);
        let rowsInfo: any[] = [];
        for (let i = 0; i < numberRow; i++) {
            let rowInfo: any = [];
            listAnswerIds.forEach((id: string, index: number) => {
                if (index >= i * numberCol && index < (i + 1) * numberCol) {
                    const resource: IResourceItemNew = pagesWorksheet[
                        pageIndex
                    ].resourceItems.find((resource) => resource.id == id);
                    if (
                        isRelateQuestion({
                            questionAndAnswerIds: questionAndAnswerIds,
                            resource: resource,
                        })
                    ) {
                        rowInfo.push(id);
                    }
                }
            });
            rowsInfo.push(rowInfo);
        }
        const currentEleRow: any = rowsInfo?.find((row) =>
            row.includes(currentElement?.id)
        );
        listResourceItem.forEach((ele: IResourceItemNew, index: number) => {
            if (
                pageIndex >= elementPage &&
                index > currentElementIndex &&
                isRelateQuestion({
                    questionAndAnswerIds: questionAndAnswerIds,
                    resource: ele,
                })
            ) {
                let newYPosition: number = currentEleRow?.includes(ele?.id)
                    ? ele.y
                    : ele.y + yChange;
                listResourceItem[index] = {
                    ...ele,
                    y: newYPosition,
                };
            }
        });
    } else if (type == "question" && action != "add last") {
        listResourceItem.forEach(
            (resource: IResourceItemNew, index: number) => {
                if (
                    pageIndex == elementPage &&
                    index > currentElementIndex &&
                    isRelateQuestion({
                        questionAndAnswerIds: questionAndAnswerIds,
                        resource: resource,
                    })
                ) {
                    let newYPosition = resource.y + yChange;
                    listResourceItem[index] = {
                        ...resource,
                        y: newYPosition,
                    };
                }
            }
        );
    }

    for (let page = pageIndex; page < pagesWorksheet.length; page++) {
        let resourceBelow: IResourceItemNew[] = pagesWorksheet[
            page
        ].resourceItems.filter((resource) =>
            isRelateQuestion({
                questionAndAnswerIds: questionAndAnswerIds,
                resource: resource,
            })
        );
        let lastYPosition: number = getLastYPosition({
            listItem: resourceBelow,
            questionAndAnswerIds: questionAndAnswerIds,
        });
        let overflowY: number =
            lastYPosition -
            (ConstantsTool.BORDER_Y + ConstantsTool.BORDER_HEIGHT);

        if (overflowY > 0) {
            const haveQuestionNextPage: IResourceItemNew = pagesWorksheet[
                page + 1
            ]?.resourceItems.find((resource) =>
                isRelateQuestion({
                    questionAndAnswerIds: questionAndAnswerIds,
                    resource: resource,
                })
            );
            if (!haveQuestionNextPage) {
                pagesWorksheet.push(
                    new PageWorksheetNew({
                        id: generateIdFromDateTime(),
                        width: ConstantsTool.WIDTH_A4,
                        height: ConstantsTool.HEIGHT_A4,
                        resourceItems: newPageResource({ pageIndex: page + 1 }),
                    })
                );
            }
            moveItemDown({
                fromPage: pagesWorksheet[page],
                fromPageIndex: page,
                toPage: pagesWorksheet[page + 1],
                overflowY: overflowY,
                questionAndAnswerIds: questionAndAnswerIds,
                activity: activity,
            });
        }

        const checkAbleMoveUp = () => {
            let listCheck = ["CheckBelow", "CheckAbove"];
            let deleteCount = 0;
            for (let i = 0; i < listCheck.length; i++) {
                let direct = listCheck[i];
                let heightRemove = 0;
                let isMoveUp = false;
                let resourcesUpper = pagesWorksheet[
                    page - 1
                ]?.resourceItems.filter((resource: IResourceItemNew) =>
                    isRelateQuestion({
                        questionAndAnswerIds: questionAndAnswerIds,
                        resource: resource,
                    })
                );
                let newLastY =
                    direct == listCheck[0]
                        ? lastYPosition +
                          ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES
                        : getLastYPosition({
                              listItem: resourcesUpper,
                              questionAndAnswerIds: questionAndAnswerIds,
                          }) + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
                if (
                    type == "question" &&
                    action == "delete" &&
                    listQuestionAtPage[listQuestionAtPage.length - 1]?.id ===
                        currentElement?.id &&
                    page == pageIndex &&
                    deleteCount == 0
                ) {
                    newLastY -=
                        currentElement.height +
                        ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
                    deleteCount++;
                }
                let listIndexMoveUp = [];
                let pageCheck = direct == listCheck[0] ? page + 1 : page;
                const firstQuestionIndex = pagesWorksheet[
                    pageCheck
                ]?.resourceItems.findIndex((resource) =>
                    questionAndAnswerIds.find(
                        (ele) => ele.questionId === resource.id
                    )
                );
                let ableSpace =
                    ConstantsTool.BORDER_Y +
                    ConstantsTool.BORDER_HEIGHT -
                    newLastY;
                questionAndAnswerIds.forEach((question: any) => {
                    const questionResource: IResourceItemNew = pagesWorksheet[
                        pageCheck
                    ]?.resourceItems.find(
                        (ele) => ele?.id == question.questionId
                    );
                    if (questionResource) {
                        let questionContainerHeight = 0;
                        const questionNumberCol = activity.questions.find(
                            (ele) => ele.id == questionResource.id
                        ).multipleChoicesAttribute.numberCol;
                        const questionResourceIndex: number = pagesWorksheet[
                            pageCheck
                        ]?.resourceItems.findIndex(
                            (ele) => ele.id == questionResource.id
                        );
                        let listAnswerIndex = [];
                        questionContainerHeight +=
                            questionResource.height +
                            ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
                        let listAnswerResource = [];
                        question.answerIds.forEach((answerId) => {
                            const answerResource = pagesWorksheet[
                                pageCheck
                            ].resourceItems.find((ele) => ele?.id == answerId);
                            listAnswerResource.push(answerResource);
                        });
                        let listAnswerHeight = getListAnswerHeight({
                            listAnswer: listAnswerResource,
                            numberCol: questionNumberCol,
                        });
                        questionContainerHeight += listAnswerHeight;
                        if (
                            questionContainerHeight <= ableSpace &&
                            (questionResourceIndex == firstQuestionIndex ||
                                (questionResourceIndex != firstQuestionIndex &&
                                    listIndexMoveUp.find(
                                        (ele) =>
                                            ele.questionIndex ==
                                            firstQuestionIndex
                                    )))
                        ) {
                            isMoveUp = true;
                            ableSpace -= questionContainerHeight;
                            pagesWorksheet[pageCheck].resourceItems.forEach(
                                (ele: IResourceItemNew, index: number) => {
                                    if (listAnswerResource.includes(ele)) {
                                        listAnswerIndex.push(index);
                                    }
                                }
                            );
                            listIndexMoveUp.push({
                                questionIndex: questionResourceIndex,
                                answerIndexs: listAnswerIndex,
                            });
                            heightRemove += questionContainerHeight;
                        }
                    }
                });
                if (isMoveUp && pagesWorksheet[pageCheck - 1]) {
                    return {
                        relatePage: pagesWorksheet[pageCheck],
                        currentPage: pagesWorksheet[pageCheck - 1],
                        elementPage: pageCheck - 1,
                        lastYPosition: newLastY,
                        isMoveUp: isMoveUp,
                        listIndexMoveUp: listIndexMoveUp,
                        heightRemove: heightRemove,
                    };
                }
            }
        };

        const moveUpInfo: any = checkAbleMoveUp();
        if (moveUpInfo?.isMoveUp && !action.includes("add")) {
            moveItemUp({
                fromPage: moveUpInfo.currentPage,
                fromPageIndex: moveUpInfo.elementPage,
                toPage: moveUpInfo.relatePage,
                questionAndAnswerIds: questionAndAnswerIds,
                lastYPosition: moveUpInfo.lastYPosition,
                activity: activity,
                listIndexMoveUp: moveUpInfo.listIndexMoveUp,
                heightRemove: moveUpInfo.heightRemove,
            });
            let resourcesId: Array<string> = [];
            questionAndAnswerIds.forEach((ele: any) => {
                resourcesId.push(ele.questionId);
                ele.answerIds.forEach((answer: string) => {
                    resourcesId.push(answer);
                });
            });
            let isBlank: boolean = !pagesWorksheet[
                pagesWorksheet.length - 1
            ].resourceItems.find((resource: IResourceItemNew) =>
                resourcesId.includes(resource.id)
            );
            if (isBlank) {
                pagesWorksheet.pop();
            }
        }
    }
};
export const moveItemUp = ({
    fromPage,
    fromPageIndex,
    toPage,
    questionAndAnswerIds,
    lastYPosition,
    activity,
    listIndexMoveUp,
    heightRemove,
}: {
    fromPage: IPageWorksheetNew;
    fromPageIndex: number;
    toPage: IPageWorksheetNew;
    questionAndAnswerIds: any[];
    lastYPosition: number;
    activity: IActivity;
    listIndexMoveUp: any[];
    heightRemove: number;
}) => {
    //Sort resource after delete question
    let questionNumberCol: number = 4;
    toPage?.resourceItems.forEach(
        (resource: IResourceItemNew, resourceIndex: number) => {
            listIndexMoveUp.forEach((ele: any) => {
                if (
                    resourceIndex == ele.questionIndex ||
                    ele.answerIndexs.includes(resourceIndex)
                ) {
                    if (resourceIndex == ele.questionIndex) {
                        resource = {
                            ...resource,
                            y: lastYPosition,
                            pageIndex: fromPageIndex,
                        };
                        lastYPosition += resource?.height;
                        fromPage?.resourceItems.push(resource);
                        questionNumberCol = activity.questions.find(
                            (question: IQuestionActivity) =>
                                question.id == resource.id
                        )?.multipleChoicesAttribute.numberCol;
                    } else if (
                        resourceIndex ==
                        ele.answerIndexs[ele.answerIndexs.length - 1]
                    ) {
                        const listAnswerResource: IResourceItemNew[] =
                            ele.answerIndexs.map(
                                (answerIndex) =>
                                    toPage?.resourceItems[answerIndex]
                            );
                        const listAnswerIds: Array<string> =
                            listAnswerResource.map((resource) => resource.id);
                        listAnswerResource.forEach(
                            (resource: IResourceItemNew, index: number) => {
                                listAnswerResource[index] = {
                                    ...resource,
                                    pageIndex: fromPageIndex,
                                };
                            }
                        );
                        for (let i = 0; i < ele.answerIndexs.length; i++) {
                            fromPage?.resourceItems.push(listAnswerResource[i]);
                        }
                        for (let i = 0; i < listAnswerIds.length; i++) {
                            changeAnswerPosition({
                                listItem: fromPage?.resourceItems,
                                listAnswerIds: listAnswerIds,
                                questionLastY: lastYPosition,
                                numberCol: questionNumberCol,
                            });
                        }
                        const listAnswerHeight: number = getListAnswerHeight({
                            listAnswer: listAnswerResource,
                            numberCol: questionNumberCol,
                        });
                        lastYPosition +=
                            listAnswerHeight +
                            ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 2;
                    }
                }
            });
        }
    );
    // Change other question's position in other next pages
    if (toPage?.resourceItems) {
        toPage?.resourceItems.forEach(
            (resource: IResourceItemNew, index: number) => {
                if (
                    isRelateQuestion({
                        questionAndAnswerIds: questionAndAnswerIds,
                        resource: resource,
                    })
                ) {
                    toPage.resourceItems[index] = {
                        ...resource,
                        y:
                            resource.y -
                            heightRemove -
                            ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
                    };
                }
            }
        );
    }
    const destructListIndexRemove: any = [];
    listIndexMoveUp.forEach((ele: any) => {
        destructListIndexRemove.push(ele.questionIndex);
        ele.answerIndexs.forEach((answerIndex: any) => {
            destructListIndexRemove.push(answerIndex);
        });
    });
    const newPageWSResources: IResourceItemNew[] = toPage?.resourceItems.filter(
        (ele, index: number) => !destructListIndexRemove.includes(index)
    );
    if (toPage?.resourceItems.length) {
        toPage.resourceItems = newPageWSResources;
    }
};
export const moveItemDown = ({
    fromPage,
    fromPageIndex,
    toPage,
    overflowY,
    questionAndAnswerIds,
    activity,
}: {
    fromPage: IPageWorksheetNew;
    fromPageIndex: number;
    toPage: IPageWorksheetNew;
    overflowY: number;
    questionAndAnswerIds: any[];
    activity: IActivity;
}) => {
    //Find valid question move down page
    let listQuestionContainerPage: any = [];
    questionAndAnswerIds.forEach((ele: any) => {
        const questionResource: IResourceItemNew = fromPage.resourceItems.find(
            (resource: IResourceItemNew) => resource.id == ele.questionId
        );
        if (questionResource?.pageIndex == fromPageIndex) {
            let resourcesInfo: any = {
                questionResource: questionResource,
                answersResource: [],
            };
            fromPage.resourceItems.forEach((resource: IResourceItemNew) => {
                if (ele.answerIds.includes(resource.id)) {
                    resourcesInfo.answersResource.push(resource);
                }
            });
            listQuestionContainerPage.push(resourcesInfo);
        }
    });
    let heightMove: number = 0;
    let listRemoveResource: any = [];
    let listRemoveInfo: any = [];
    for (let i = listQuestionContainerPage.length - 1; i >= 0; i--) {
        let questionNumberCol: number = 4;
        if (heightMove < overflowY) {
            questionNumberCol = activity.questions.find(
                (question) =>
                    question.id ==
                    listQuestionContainerPage[i].questionResource?.id
            )?.multipleChoicesAttribute.numberCol;
            heightMove +=
                listQuestionContainerPage[i].questionResource?.height +
                ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
            listRemoveResource.push(
                listQuestionContainerPage[i].questionResource
            );
            let removeInfo: any = {
                questionResource: listQuestionContainerPage[i].questionResource,
                answersResource: [],
            };
            listQuestionContainerPage[i]?.answersResource.forEach(
                (answerResource: IResourceItemNew) => {
                    listRemoveResource.push(answerResource);
                    removeInfo.answersResource.push(answerResource);
                }
            );
            let listAnswerHeight: number = getListAnswerHeight({
                listAnswer: listQuestionContainerPage[i].answersResource,
                numberCol: questionNumberCol,
            });
            heightMove +=
                listAnswerHeight + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
            listRemoveInfo.push(removeInfo);
        }
    }
    // Change remove items position and add to new page
    let firstQuestionNextPageIndex: number = toPage?.resourceItems.findIndex(
        (resource: IResourceItemNew) =>
            isRelateQuestion({
                questionAndAnswerIds: questionAndAnswerIds,
                resource: resource,
            })
    );

    let firstQuestionYPosition: number =
        toPage?.resourceItems[firstQuestionNextPageIndex]?.y;

    let startY: number = 25 + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
    let listItem: any = [];
    let firstIndex: number = firstQuestionNextPageIndex;
    for (let i = listRemoveInfo.length - 1; i >= 0; i--) {
        const questionNumberCol: number = activity.questions.find(
            (activityEle: IQuestionActivity) =>
                activityEle.id == listRemoveInfo[i].questionResource?.id
        )?.multipleChoicesAttribute.numberCol;
        listRemoveInfo[i].questionResource = {
            ...listRemoveInfo[i].questionResource,
            y: startY,
            pageIndex: listRemoveInfo[i].questionResource?.pageIndex + 1,
        };
        listItem.push(listRemoveInfo[i].questionResource);
        if (firstQuestionNextPageIndex != -1) {
            toPage?.resourceItems.splice(
                firstIndex,
                0,
                listRemoveInfo[i].questionResource
            );
        } else {
            toPage?.resourceItems.push(listRemoveInfo[i].questionResource);
        }
        startY += listRemoveInfo[i].questionResource?.height;
        listRemoveInfo[i]?.answersResource.forEach(
            (answer: IResourceItemNew, index: number) => {
                listRemoveInfo[i].answersResource[index] = {
                    ...answer,
                    y: startY + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
                    pageIndex: answer?.pageIndex + 1,
                };
                if (firstQuestionNextPageIndex != -1) {
                    toPage?.resourceItems.splice(
                        firstIndex + index + 1,
                        0,
                        answer
                    );
                } else {
                    toPage?.resourceItems.push(
                        listRemoveInfo[i].answersResource[index]
                    );
                }
            }
        );
        let listAnswerIds = listRemoveInfo[i].answersResource.map(
            (resource: IResourceItemNew) => resource.id
        );
        for (let i = 0; i < listAnswerIds.length; i++) {
            changeAnswerPosition({
                listItem: toPage.resourceItems,
                listAnswerIds: listAnswerIds,
                questionLastY: startY,
                numberCol: questionNumberCol,
            });
        }
        let listAnswerAfterChange: IResourceItemNew[] =
            toPage.resourceItems.filter((resource: IResourceItemNew) =>
                listAnswerIds.find((answerId) => answerId == resource.id)
            );
        listRemoveInfo[i].answersResource.map((answer: IResourceItemNew) => {
            let answerResource: IResourceItemNew = toPage?.resourceItems.find(
                (resource: IResourceItemNew) => resource.id == answer.id
            );
            listItem.push(answerResource);
        });
        let listAnswerHeight: number = getListAnswerHeight({
            listAnswer: listAnswerAfterChange,
            numberCol: questionNumberCol,
        });
        startY +=
            ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 2 + listAnswerHeight;
        if (firstQuestionNextPageIndex != -1) {
            firstIndex += 1 + listRemoveInfo[i].answersResource.length;
        }
    }

    //Sort next page's resources with heightRemove
    if (firstQuestionNextPageIndex != -1) {
        const lastRemoveInfoY: number = getLastYPosition({
            listItem: listItem,
            questionAndAnswerIds: questionAndAnswerIds,
        });
        const space: number =
            lastRemoveInfoY -
            firstQuestionYPosition +
            ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
        toPage.resourceItems.forEach(
            (resource: IResourceItemNew, index: number) => {
                if (
                    isRelateQuestion({
                        questionAndAnswerIds: questionAndAnswerIds,
                        resource: resource,
                    }) &&
                    index >= firstIndex
                ) {
                    toPage.resourceItems[index] = {
                        ...resource,
                        y: resource.y + space,
                    };
                }
            }
        );
    }

    //Remove resources from current page
    let newCurrentPage: IResourceItemNew[] = fromPage.resourceItems.filter(
        (resource: IResourceItemNew) =>
            !listRemoveInfo.find(
                (ele: any) =>
                    ele.questionResource.id == resource.id ||
                    ele.answersResource.find(
                        (answer) => answer.id == resource.id
                    )
            )
    );
    fromPage.resourceItems = newCurrentPage;
};
export const updateAnswersContent = ({
    listResourceItem,
    listAnswerIds,
}: {
    listResourceItem: IResourceItemNew[];
    listAnswerIds: string[];
}) => {
    listAnswerIds.forEach((answerId: string, answerIndex) => {
        listResourceItem.forEach((ele: IResourceItemNew, index: number) => {
            if (answerId == ele?.id) {
                const content: string = getContent(ele.textAttribute.content);
                listResourceItem[index] = {
                    ...ele,
                    textAttribute: {
                        ...ele.textAttribute,
                        content: `${listAnswerKey()[answerIndex]}. ${content}`,
                    },
                };
            }
        });
    });
};
export const changeAnswerDimension = ({
    listItem,
    listAnswerIds,
    numberCol,
}: {
    listItem: IResourceItemNew[];
    listAnswerIds: string[];
    numberCol: number;
}) => {
    listItem.forEach((ele: IResourceItemNew, index: number) => {
        if (listAnswerIds.includes(ele?.id)) {
            const contentHeight: number = getTextWidthHeightTool({
                textValue: ele.textAttribute.content,
                includePadding: false,
                fontSize: 24,
                fontFamily: "Nunito",
                textAlign: "left",
                maxWidth:
                    ConstantsTool.MULTIPLE_CHOICES_WIDTH / numberCol -
                    ConstantsTool.GAP_ELEMENT_FILL_THE_BLANK,
            }).height;
            const newWidth: number =
                ConstantsTool.MULTIPLE_CHOICES_WIDTH / numberCol -
                ConstantsTool.GAP_ELEMENT_FILL_THE_BLANK;
            const numberLine: number = Math.floor(
                contentHeight / ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES
            );
            const newHeight: number =
                numberLine * ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
            listItem[index] = {
                ...ele,
                width: newWidth,
                height: newHeight,
            };
        }
    });
};
export const changeAnswerPosition = ({
    listItem,
    listAnswerIds,
    questionLastY,
    numberCol,
}: {
    listItem: IResourceItemNew[];
    listAnswerIds: string[];
    questionLastY: number;
    numberCol: number;
}) => {
    const xPosition: Array<number> = [];
    for (let i = 0; i < numberCol; i++) {
        xPosition.push(
            ConstantsTool.MULTIPLE_CHOICES_X +
                i * (ConstantsTool.MULTIPLE_CHOICES_WIDTH / numberCol)
        );
    }
    const numberRow: number = Math.ceil(listAnswerIds.length / numberCol);
    let lastYPosition: number =
        questionLastY + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
    let listAnswerResourceIndex: any = [];
    listItem?.forEach((item: IResourceItemNew, index: number) => {
        if (listAnswerIds.includes(item.id)) {
            listAnswerResourceIndex.push(index);
        }
    });
    let rowsInfo: any[] = [];
    for (let i = 0; i < numberRow; i++) {
        let rowInfo: any = [];
        let listIndex: Array<number> = [];
        listAnswerResourceIndex.forEach(
            (resourceIndex: number, index: number) => {
                if (index >= i * numberCol && index < (i + 1) * numberCol) {
                    rowInfo.push(listItem[resourceIndex]);
                    listIndex.push(resourceIndex);
                }
            }
        );
        let { heighest, y } = getHeighestItem({ listItem: rowInfo });
        rowsInfo.push({
            row: i + 1,
            height: heighest,
            y: y,
            indexs: listIndex,
        });
    }

    rowsInfo.forEach((row: any) => {
        row.indexs.forEach((resourceIndex: number, i: number) => {
            listItem[resourceIndex] = {
                ...listItem[resourceIndex],
                x: xPosition[i],
                y: lastYPosition,
            };
        });
        if (numberCol != 1) {
            lastYPosition = row.height + row.y;
        } else {
            lastYPosition += row.height;
        }
    });
};
export const getNumber = (input) => {
    return Number(input?.match(/^[0-9]{1,}/g)[0]);
};
export const getContent = (input) => {
    return input.match(/\.(.*)/)[0].slice(1);
};

export const genNewResource = ({
    id,
    activityId,
    pageIndex,
    width,
    content,
    x,
    y,
}) => {
    return new ResourceItemNew({
        id: id,
        idType: id,
        activityId: activityId,
        pageIndex: pageIndex,
        type: ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES,
        width: width,
        height: ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
        textAttribute: {
            content: content,
            fontFamily: "Nunito",
            fontSize: 24,
            align: "left",
        },
        x: x,
        y: y,
    });
};

export const filterResourceId = (pageResource, listResource) => {
    let arr = pageResource.filter((resource) =>
        listResource.includes(resource.id)
    );
    return arr;
};

export const updateContent = ({ item, isUp, isResource }) => {
    const number = isResource
        ? getNumber(item.textAttribute?.content)
        : getNumber(item?.question);
    const mainContent = isResource
        ? getContent(item.textAttribute?.content)
        : getContent(item?.question);
    const newContent = `${isUp ? number + 1 : number - 1}. ${mainContent}`;
    if (isResource) {
        item = {
            ...item,
            textAttribute: {
                ...item.textAttribute,
                content: newContent,
            },
        };
    } else {
        item = {
            ...item,
            question: newContent,
        };
    }
    return item;
};

export const findSuitableY = ({
    listResource,
    numberCol,
    listAnswerIds,
    question,
}) => {
    const listAnswerResource = filterResourceId(listResource, listAnswerIds);
    const numberRow = Math.ceil(listAnswerIds.length / numberCol);
    const listHeighest = [];
    for (let i = 0; i < numberRow; i++) {
        let resourcesRow = [];
        listAnswerResource.forEach((ele, index) => {
            if (index > i * numberCol - 1 && index < (i + 1) * numberCol) {
                resourcesRow.push(ele);
            }
        });
        const { heighest, y } = getHeighestItem({
            listItem: resourcesRow,
        });
        listHeighest.push({
            row: i + 1,
            height: heighest,
            y: y,
        });
    }
    const questionResource = listResource.find(
        (ele) => ele?.id == question.id
    );
    const lastEle =
        listAnswerIds.length < numberCol
            ? questionResource
            : listAnswerIds.length % numberCol == 0
            ? listHeighest[listHeighest.length - 1]
            : listHeighest[listHeighest.length - 2] ??
              listHeighest[listHeighest.length - 1];
    return lastEle?.height + lastEle?.y;
};
