import { useState } from 'react';
import AddTemplateTagModal from '../add-template-tag-modal';
import { Button } from 'react-bootstrap';

import './styles.scss';
import { updateDataAPI } from '../../../../../utils/query';
import { useDispatch } from 'react-redux';
import { setTemplate } from '../../../../../redux/reducers/createWorksheet';

const TemplateTags = ({ tags, template, categories, refreshCategories }) => {
    const dispatch = useDispatch();

    const [showAddTemplateModal, setShowAddTemplateModal] = useState(null);

    const deleteTag = async (tagID) => {
        const res = await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
            tags: tags.filter(t => t.id !== tagID).map(t => t.id)
        });
        console.log(res.data);
        dispatch(setTemplate({...template, tags: res.data['tags'], tagsData: res.data['tagsData']}));
    }

    return (
        <div className="template-tags">
            {tags.map(tag => (
                <span key={tag.id}>
                    <span>{tag.categoryName}: {tag.name}</span>
                    <span className="delete-btn" onClick={() => deleteTag(tag.id)}>x</span>
                </span>
            ))}
            <Button onClick={() => setShowAddTemplateModal(true)} size="sm" variant="secondary">+</Button>

            {showAddTemplateModal && (
                <AddTemplateTagModal
                    template={template}
                    categories={categories}
                    refreshCategories={refreshCategories}
                    hideModal={() => setShowAddTemplateModal(false)} />
            )}
        </div>
    );
}

export default TemplateTags;