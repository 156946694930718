import { IconButton, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hook";
import DropdownTool from "../../../../resource-user/components/icons/drop-down-tool/drop-down-tool";
import PullUpTool from "../../../../resource-user/components/icons/drop-down-tool/pull-up-tool";
import { updateLayoutAction } from "../../../../resource-user/redux/action/crossword.action";
import { sendEvent } from "../../../../resource-user/utils/event";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import { getPrefixImage } from "../../../../utils";
import "./style.scss";

const LAYOUT_ITEMS = [
    {
        id: ConstantsTool.LAYOUT.DEFAULT,
        name: "Default",
        image: "/images/crossword-icons/default.png",
    },
    {
        id: ConstantsTool.LAYOUT.CLUES_BY_SIDE,
        name: "Clues by Side",
        image: "/images/crossword-icons/clues-by-side.png",
    },
    {
        id: ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT,
        name: "Image lower right",
        image: "/images/crossword-icons/lower-right.png",
    },
    {
        id: ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT,
        name: "Image lower Left",
        image: "/images/crossword-icons/lower-left.png",
    },
    {
        id: ConstantsTool.LAYOUT.IMAGE_UPPER_RIGHT,
        name: "Image Upper right",
        image: "/images/crossword-icons/upper-right.png",
    },
    {
        id: ConstantsTool.LAYOUT.IMAGE_UPPER_LEFT,
        name: "Image Upper Left",
        image: "/images/crossword-icons/upper-left.png",
    },
];

const LayoutDropDown = () => {
    const dispatch = useDispatch();
    const layout = useAppSelector((state) => state.crosswordState.layout);

    const MyCustomIcon = (props) => {
        const open = props.className.indexOf("MuiSelect-iconOpen");
        // const open = props.className.includes("MuiSelect-iconOpen");
        // console.log(props.className.includes("MuiSelect-iconOpen"));
        return (
            <IconButton {...props}>
                {!open ? <PullUpTool /> : <DropdownTool />}
            </IconButton>
        );
    };

    return (
        <div className="layout-crossword">
            <div className="title-layout">Layout</div>
            <div className="dropdown-layout-container">
                <Select
                    value={layout}
                    IconComponent={MyCustomIcon}
                    onChange={(e) => {
                        const value = e.target.value;
                        dispatch(updateLayoutAction(value));
                        sendEvent("crw_layout_" + value.toLowerCase());
                    }}
                    fullWidth
                    className="dropdown-layout"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 234,
                                padding: 0,
                            },
                        },
                        MenuListProps: {
                            style: {
                                padding: 0,
                            },
                        },
                    }}
                    SelectDisplayProps={{
                        style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        },
                    }}
                >
                    {LAYOUT_ITEMS.map((item) => {
                        return (
                            <MenuItem
                                key={item.id}
                                value={item.id}
                                className={"dropdown-item-crossword-layout "}
                            >
                                <img
                                    src={getPrefixImage() + item.image}
                                    alt={item.name}
                                    width="42px"
                                    height="62px"
                                />
                                <div className="name-option">{item.name}</div>
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
        </div>
    );
};

export default LayoutDropDown;
