import { shuffleArray } from "../util";

export const scrambleWord = (
    words: string[],
    isSentenceScramble?: boolean
): { origin: string; shuffleWord: string }[] => {
    return words.reduce((prev, word) => {
        let _word = word.replaceAll("\n", " ");
        let shuffleWord = _word;

        const MAX_TIME_SHUFFLE = 5;
        let count = 0;
        do {
            const split = isSentenceScramble ? " " : "";
            if (isSentenceScramble) {
                if (_word.split(split).length <= 1) break;
            } else {
                if (_word.length <= 1) break;
            }

            shuffleWord = shuffleArray(_word.split(split)).join(split);
            count++;
        } while (shuffleWord === _word && count < MAX_TIME_SHUFFLE);

        return [
            ...prev,
            {
                origin: _word,
                shuffleWord,
            },
        ];
    }, []);
};
