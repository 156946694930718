import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './index.scss';
import { useDispatch } from 'react-redux';
import PromptInteractiveBlock from '../prompt-interactive-block';
import { useAppSelector } from '../../../../../redux/hook';
import { setTemplate, updateResourceItemsAuto } from '../../../../../redux/reducers/createWorksheet';
import ZonesPopup from '../zones-popup';

const PROMPT_TEXT = '# BACKGROUND: \n You are a #subjects teacher (grades #grades) in the United States who wants to make a worksheet with the subject "#title" for the students that Common Core aligned. The content should be a little bit difficult for students so they can practice at their best and the content should be appropriate and engaging to US students.\n\n# REQUEST:\n#activity\n\n#skills\nThe content of the worksheet is about: #theme\n\n# NOTE: \n #note\n\n# RESULT FORMAT: \n Your result should include: #zones.';

const PromptInteractiveModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const template = useAppSelector(state => state.createWorksheetState.template);
    const [openZonesPopup, setOpenZonesPopup] = useState(false)

    const [isSubmit, setIsSubmit] = useState(false);
    const [uniqueFields, setUniqueFields] = useState(null);

    const onApplyPrompt = () => {
        setIsSubmit(true)
    };

    useEffect(() => {
        if (isSubmit) {
            setIsSubmit(false)
        }
    }, [isSubmit])

    const onSubmit = (data) => {
        if (uniqueFields){
            dispatch(
                updateResourceItemsAuto({
                    uniqueFields: uniqueFields,
                    pageIndex: 0
                })
            );
        }
        dispatch(setTemplate({
            ...template,
            testIdeaSubjects: data['#subjects'],
            testIdeaGrades: data['#grades'],
            activity: data['#activity'],
            testIdeaTitle: data['#title'],
            note: data['#note'],
            testSkillDetails: data['#skills'],
            testTheme: data['#theme']
        }));
    }

    // console.log('resourceItems',resourceItems)

    return (
        <>
            <Modal
                show={true} backdrop="static" keyboard={false}
                onHide={onClose}
                contentLabel="Prompt Interactive"
                className={`prompt-interactive-modal ${openZonesPopup ? 'hide' : ''}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Prompt Interactive
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PromptInteractiveBlock
                    prompt={PROMPT_TEXT}
                    isSubmit={isSubmit}
                    init={{
                        '#subjects': template.testIdeaSubjects,
                        '#grades': template.testIdeaGrades,
                        '#activity': template.activity,
                        '#title': template.testIdeaTitle,
                        '#note': template.note ?? '',
                        '#skills': template.testSkillDetails ?? "",
                        '#theme': template.testTheme ?? ''
                    }}
                    multilineFields={[
                        '#activity',
                        '#title',
                        '#note',
                        '#skills',
                        '#theme'
                    ]}
                    onlyClickableFields={[
                        '#zones'
                    ]}
                    onSubmit={data => {
                        onSubmit(data)
                        onClose()
                    }}
                    callbackClickable={{
                        '#zones': () => {
                            setOpenZonesPopup(true)
                        }
                    }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onApplyPrompt}>Apply</Button>
                </Modal.Footer>
            </Modal>
            {
                openZonesPopup && <ZonesPopup
                    onClose={(uniqueFields) => {
                        setOpenZonesPopup(false)
                        setUniqueFields(uniqueFields)
                    }}
                    uniqueFields={Array.isArray(uniqueFields) ? uniqueFields : []}
                />
            }
        </>
    )
}

export default PromptInteractiveModal;