import { StaticMathField } from "abc-react-mathquill";
import { throttle } from "lodash";
import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    useSyncExternalStore,
} from "react";
import "simplebar-react/dist/simplebar.min.css";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
    addResourceItems,
    removeResourceItems,
    setCurrentElementFocus,
    setWordsCountDone,
    upDateResize,
    updateIsOnDrag,
    updateIsOnEditText,
    updatePageIndex,
    updateRatio,
    updateResourceItem,
    updateResourceItems,
    updatecurrentElementId,
} from "../../redux/reducers/createWorksheet";
import { setResourceItemEditing } from "../../redux/reducers/leftData";
import ListActionWordSearch from "../../resource-user/components/worksheet/list-action";
import { DirectionProps } from "../../shared/models/directionProps";
import { IPageWorksheetNew } from "../../shared/models/pageWorksheetNew";
import { IResourceItemNew } from "../../shared/models/resourceItemNew";
import {
    ITableAttribute,
    PositionTable,
    TableAttribute,
} from "../../shared/models/tableAttribute";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import Config from "../../shared/utils/config";
import groupElementStore from "../../syncExternalStore/groupElementStore";
import {
    displayEditPage,
    isResourceBackground,
    resourceFixedSize,
    syncTemplateLinkedTextToZone,
} from "../../utils";
import { ConstantsApp } from "../../utils/Constants";
import {
    checkPositionElement,
    getPositionMoveElement,
} from "../../utils/align";
import {
    IPoint,
    callbackResizeHorizontal,
    getFourPointAndRotateValue,
    getSizeFixedRatio,
    isRotateLeft,
    onTransformBottom,
    onTransformBottomLeft,
    onTransformBottomRight,
    onTransformLeft,
    onTransformRight,
    onTransformTop,
    onTransformTopLeft,
    onTransformTopRight,
} from "../../utils/draw";
import { resizeTable } from "../../utils/table";
import ListenWordSearch, {
    ListenWordSearchCreator,
} from "./CreateWorksheet/ListenWordSearch";
import PageWorksheet from "./CreateWorksheet/PageWorksheet";
import ListenReducer from "./CreateWorksheet/PageWorksheet/ListenReducer";
import EditBar from "./EditBar";
import ListenCrossWord from "./Listen/listenCrossWord";
import ListenShortcut from "./Listen/listenShortcut";
import Notification from "./Notification";
import "./index.scss";

const RightContent = ({
    buttonAddPage,
}: {
    buttonAddPage: React.ReactNode;
}) => {
    const dispatch = useAppDispatch();
    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );
    const loading = useAppSelector(
        (state) => state.createWorksheetState.loading
    );
    const currentActivity = useAppSelector(
        (state) => state.createWorksheetState.currentActivity
    );
    const typeActivity = currentActivity?.type;
    const listStyle = currentActivity?.listStyle;

    const initStaticMathField = useAppSelector(
        (state) => state.createWorksheetState.initStaticMathField
    );

    const staticMathFieldFontSize = useAppSelector(
        (state) => state.createWorksheetState.staticMathFieldFontSize
    );

    const disableAjustZonePositionWithKeyboard = useAppSelector(
        (state) => state.createWorksheetState.disableAjustZonePositionWithKeyboard
    );

    const [currentElement, setCurrentElement] =
        useState<IResourceItemNew | null>(null);

    const currentPageIndex = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );

    const {tempTemplateZones, isIframe, templateZoneDisplayMode } = useAppSelector(
        (state) => state.createWorksheetState
    );
    const [rotate, setRotate] = useState(null);

    const rightContentRef = useRef<HTMLDivElement>(null);
    const rotationPopupRef = useRef<HTMLDivElement>(null);
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    const ratioRedux = useAppSelector(
        (state) => state.createWorksheetState.ratio
    );
    const [ratio, setRatio] = useState(ratioRedux);
    const [activeRotate, setActiveRotate] = useState(false);
    const [activeResize, setActiveResize] = useState<DirectionProps | null>(
        null
    );
    const [activeDarg, setActiveDarg] = useState(false);
    const [moved, setMoved] = useState(false);

    const onDrag = useAppSelector(
        (state) => state.createWorksheetState.isOnDrag
    );
    const isResize = useAppSelector(
        (state) => state.createWorksheetState.isResize
    );

    const [spaceToTopLeft, setSpaceToTopLeft] = useState({
        x: 0,
        y: 0,
        id: "",
    });
    const [originalElement, setOriginalElement] =
        useState<IResourceItemNew>(null);
    const [originalElements, setOriginalElements] = useState<
        IResourceItemNew[]
    >([]);
    const [scrollTop, setScrollTop] = useState(0);
    const dsScorll = useRef(0);
    const dTop = useRef(0);
    const metaKey = useRef(false);
    const dSpacingZoom = displayEditPage(typeActivity) ? 60 : 0;
    const [isScaled, setIsScaled] = useState(false);
    const isStart = useRef(false);

    useEffect(() => {
        // map new id from api to current element
        if (currentElement && pagesWorksheet[currentPageIndex]?.resourceItems) {
            let item = pagesWorksheet[currentPageIndex].resourceItems.find(item => item.id === currentElement.id);
            if (item === undefined) {
                item = pagesWorksheet[currentPageIndex].resourceItems.find(item => item.originalID === currentElement.id);
                if (item) {
                    setCurrentElement({...currentElement, id: item.id});
                }
            }
        }
    }, [pagesWorksheet[currentPageIndex]?.resourceItems]);

    useEffect(() => {
        // Check in pageworksheet has passage and word count zones
        if (!pagesWorksheet[currentPageIndex]?.resourceItems) {
            return;
        }

        if (templateZoneDisplayMode === 'zoneOnly') {
            dispatch(setWordsCountDone(true));
        } else {
            dispatch(setWordsCountDone(false));
            const items = pagesWorksheet[currentPageIndex].resourceItems;

            if (items.length > 0) {
                const hasPassageAndWordCountZones = items.some((zone) => zone.fieldName === ConstantsTool.PASSAGE_ZONE) && items.some((zone) => zone.fieldName === ConstantsTool.WORD_COUNT_ZONE);
                if (!hasPassageAndWordCountZones) {
                    dispatch(setWordsCountDone(true));
                }
            }
        }
    }, [
        pagesWorksheet[currentPageIndex]?.resourceItems,
        templateZoneDisplayMode
    ]);

    useEffect(() => {
        if (currentElement) {
            dispatch(setCurrentElementFocus(true));
        } else {
            dispatch(setCurrentElementFocus(false));
        }
    }, [currentElement]);

    useLayoutEffect(() => {
        if (ratio !== ratioRedux) {
            setRatio(ratioRedux);
            if (!isScaled && isStart.current) setIsScaled(true);
            if (!isStart.current) isStart.current = true;
        }
    }, [ratioRedux]);

    useEffect(() => {
        if (currentElement) {
            let e = pagesWorksheet[currentPageIndex].resourceItems.find(
                (el) => el.id === currentElement?.id
            );
            let es = pagesWorksheet[currentPageIndex].resourceItems.filter(
                (el) => currentElement.resourceIds?.includes(el?.id)
            );
            setOriginalElements(es);
            setOriginalElement(e);
        } else {
            setOriginalElement(null);
            setOriginalElements([]);
        }
    }, [scrollTop, ondrag, activeResize, activeRotate]);

    useEffect(() => {
        if (loading == false) {
            setTimeout(() => {
                scrollToPage(currentPageIndex);
            }, 100);
        }
    }, [loading]);

    useEffect(() => {
        setTimeout(() => {
            scrollToPage(pagesWorksheet.length - 1);
        }, 100);
    }, [pagesWorksheet.length]);

    useEffect(() => {
        dispatch(updatecurrentElementId({ id: currentElement?.id }));
    }, [currentElement?.id]);

    useEffect(() => {
        window.addEventListener("keydown", listenKeydown);
        return () => {
            window.removeEventListener("keydown", listenKeydown);
        };
    });

    const listenKeydown = (e: KeyboardEvent) => {
        if (disableAjustZonePositionWithKeyboard) return;

        if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
            if (currentElement && !currentElement.isLocked) {
                setMoved(true);
                e.preventDefault();
                let changes = [];
                let offset: number;
                if (e.shiftKey) {
                    offset = 10;
                } else if (e.metaKey) {
                    offset = 1;
                } else {
                    offset = 5;
                }
                if (e.key === 'ArrowDown') {
                    changes.push({attr: 'y', value: currentElement.y + offset});
                    syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {y: currentElement.y + offset});
                } else if (e.key === 'ArrowUp') {
                    changes.push({attr: 'y', value: currentElement.y - offset});
                    syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {y: currentElement.y - offset});
                } else if (e.key === 'ArrowLeft') {
                    changes.push({attr: 'x', value: currentElement.x - offset});
                    syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {x: currentElement.x - offset});
                } else {
                    changes.push({attr: 'x', value: currentElement.x + offset});
                    syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {x: currentElement.x + offset});
                }
                dispatch(updateResourceItem({
                    currentElementId: currentElement?.id,
                    attrValues: changes,
                    pageIndex: currentPageIndex,
                }));
            }
        }
    };

    const handleMouseMove = throttle(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            let pageWorksheet = pagesWorksheet[currentPageIndex];
            const pageElement = document.getElementById(
                ConstantsApp.ID_PAGE_ELEMENT + currentPageIndex
            );
            if (!pageElement) return;
            let rect = pageElement.getBoundingClientRect();
            if (groupElementState.isOnDragGroup) {
                if (currentElement) {
                    setCurrentElement(null);
                }
                groupElementStore.updateEndPoint(
                    (event.clientX - rect.x) / ratio,
                    (event.clientY - rect.y) / ratio,
                    pageWorksheet.resourceItems
                );
            }
            const contentRef = document.getElementById(
                ConstantsApp.ID_CURRENT_ELEMENT
            );
            const borderRef = document.getElementById(
                ConstantsApp.ID_BORDER_ELEMENT
            );
            const actionRef = document.getElementById(
                ConstantsApp.ID_ACTION_ELEMENT
            );
            if (currentElement && contentRef && !currentElement.isLocked) {
                if (activeResize && originalElement) {
                    const originWidth = originalElement.width,
                        originHeight = originalElement.height,
                        ratioResize =
                            originalElement.width / originalElement.height;
                    if (!moved) setMoved(true);
                    if (!isResize) {
                        dispatch(upDateResize(true));
                    }
                    let fourPoints = getFourPointAndRotateValue(
                        contentRef,
                        {
                            x: originalElement?.x,
                            y: originalElement?.y,
                        },
                        originalElement?.width,
                        originalElement?.height
                    );
                    let currentPos = {
                        x: (event.clientX - rect.x) / ratio,
                        y: (event.clientY - rect.y) / ratio,
                    };
                    let newSize = {
                        newWidth: currentElement.width,
                        newHeight: currentElement.height,
                        newPosition: {
                            x: currentElement.x,
                            y: currentElement.height,
                        },
                        rotateValue: currentElement.rotate,
                    };

                    if (
                        (event.shiftKey || resourceFixedSize(currentElement)) &&
                        activeResize.horizontal?.length &&
                        activeResize.vertical?.length
                    ) {
                        if (event.shiftKey) {
                            const selection = window.getSelection();
                            if (selection.rangeCount)
                                selection.removeAllRanges();
                        }
                        let result = getSizeFixedRatio({
                            oldPoints: fourPoints,
                            currentPoints: currentPos,
                            ratio: ratioResize,
                            direction: activeResize,
                            originElement: originalElement,
                        });

                        newSize.newWidth = result.width;
                        newSize.newHeight = result.height;
                        newSize.newPosition = result.newPosition;
                    } else {
                        if (
                            activeResize.vertical === "bottom" &&
                            activeResize.horizontal === "right"
                        ) {
                            newSize = onTransformBottomRight(
                                currentPos,
                                fourPoints
                            );
                        }
                        if (
                            activeResize.horizontal === "left" &&
                            activeResize.vertical === "top"
                        ) {
                            newSize = onTransformTopLeft(
                                currentPos,
                                fourPoints
                            );
                        }

                        if (
                            activeResize.vertical === "top" &&
                            activeResize.horizontal === "right"
                        ) {
                            newSize = onTransformTopRight(
                                currentPos,
                                fourPoints
                            );
                        }
                        if (
                            activeResize.vertical === "bottom" &&
                            activeResize.horizontal === "left"
                        ) {
                            newSize = onTransformBottomLeft(
                                currentPos,
                                fourPoints
                            );
                        }
                        if (
                            activeResize.vertical === "" &&
                            activeResize.horizontal === "right"
                        ) {
                            newSize = onTransformRight(
                                currentPos,
                                fourPoints,
                                (
                                    newWidth: number,
                                    newPos: IPoint,
                                    rotateValue: number
                                ) =>
                                    callbackResizeHorizontal(
                                        newWidth,
                                        newPos,
                                        rotateValue,
                                        currentElement,
                                        listStyle
                                    )
                            );
                        }
                        if (
                            activeResize.vertical === "bottom" &&
                            activeResize.horizontal === ""
                        ) {
                            newSize = onTransformBottom(
                                currentPos,
                                fourPoints,
                                currentElement.width
                            );
                        }
                        if (
                            activeResize.vertical === "top" &&
                            activeResize.horizontal === ""
                        ) {
                            newSize = onTransformTop(
                                currentPos,
                                fourPoints,
                                currentElement.width
                            );
                        }
                        if (
                            activeResize.vertical === "" &&
                            activeResize.horizontal === "left"
                        ) {
                            newSize = onTransformLeft(
                                currentPos,
                                fourPoints,
                                (
                                    newWidth: number,
                                    newPos: IPoint,
                                    rotateValue: number
                                ) =>
                                    callbackResizeHorizontal(
                                        newWidth,
                                        newPos,
                                        rotateValue,
                                        currentElement,
                                        listStyle
                                    )
                            );
                        }
                    }
                    let tableAttribute: ITableAttribute = null;
                    let isResizeWidth = true;
                    let isResizeHeight = true;
                    let dHeight = 0;
                    if (currentElement.tableAttribute) {
                        tableAttribute = new TableAttribute(
                            currentElement.tableAttribute
                        );
                        let value = resizeTable({
                            originWidth: currentElement.width,
                            originHeight: currentElement.height,
                            newWidth: newSize.newWidth,
                            newHeight: newSize.newHeight,
                            direction: activeResize,
                            tableAttribute,
                        });
                        isResizeWidth = value.isResizeWidth;
                        isResizeHeight = value.isResizeHeight;
                        dHeight = value.dHeight;
                    }

                    let width = isResizeWidth
                            ? newSize.newWidth
                            : currentElement.width,
                        height = isResizeHeight
                            ? newSize.newHeight + dHeight
                            : currentElement.height + dHeight;
                    if (event.shiftKey) {
                        if (width / height !== ratioResize) {
                            return;
                        }
                    }
                    setCurrentElement({
                        ...currentElement,
                        x: isResizeWidth
                            ? newSize.newPosition.x
                            : currentElement.x,
                        y: isResizeHeight
                            ? newSize.newPosition.y
                            : currentElement.y,
                        width,
                        height,
                        rotate: newSize.rotateValue,
                        tableAttribute,
                    });
                }
                if (activeRotate && actionRef) {
                    if (!moved) setMoved(true);
                    let isLeft = isRotateLeft(currentElement);
                    const centerX = currentElement.x + currentElement.width / 2; // Adjust these values if needed
                    const centerY =
                        currentElement.y + currentElement.height / 2;
                    const mouseX = (event.clientX - rect.x) / ratio;
                    const mouseY = (event.clientY - rect.y) / ratio;
                    const radians = Math.atan2(
                        mouseX - centerX,
                        mouseY - centerY
                    );
                    let newRotationDeg = (-radians * 180) / Math.PI;
                    newRotationDeg = Math.round(newRotationDeg);
                    if (isLeft) {
                        newRotationDeg += 90;
                        if (newRotationDeg > 180) {
                            newRotationDeg -= 360;
                        }
                    }
                    contentRef.style.transform = `translate(${currentElement.x}px, ${currentElement.y}px) rotate(${newRotationDeg}deg)`;
                    if (borderRef) {
                        borderRef.style.transform = `translate(${
                            currentElement.x * ratio
                        }px, ${
                            currentElement.y * ratio
                        }px) rotate(${newRotationDeg}deg)`;
                    }
                    actionRef.style.transform = `translate(${
                        currentElement.x * ratio
                    }px, ${
                        currentElement.y * ratio
                    }px) rotate(${newRotationDeg}deg)`;
                    if (rotationPopupRef.current && rightContentRef) {
                        let bound =
                            rightContentRef.current.getBoundingClientRect();
                        let x = event.clientX - bound.x;
                        let y =
                            event.clientY -
                            bound.y +
                            rightContentRef.current.scrollTop;
                        rotationPopupRef.current.style.transform = `translate(${
                            x - 30
                        }px, ${y + 30}px)`;
                    }
                    if (
                        currentElement.type ===
                        ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
                    ) {
                        const groupElement = document.getElementById(
                            "group-item-" + currentElement.id
                        );
                        if (groupElement) {
                            groupElement.style.transform = `rotate(${-newRotationDeg}deg)`;
                        }
                    }
                    setRotate(newRotationDeg);
                }
                if (activeDarg || onDrag) {
                    if (!moved) setMoved(true);
                    handleDrag(event, pageWorksheet);
                }
            }
        },
        16
    );

    const handleDrag = (e: any, pageWorksheet: IPageWorksheetNew) => {
        e.preventDefault();
        const groupElement = groupElementState.resourceGroupTmp;
        if (
            e.shiftKey &&
            groupElement &&
            groupElement.id !== spaceToTopLeft?.id
        ) {
            let pages = document.getElementsByClassName("not-click");
            let position = pages[0].getBoundingClientRect();
            setSpaceToTopLeft({
                x: e.clientX - groupElement.x * ratio - position.x,
                y: e.clientY - 24 - groupElement.y * ratio,
                id: groupElement.id,
            });
            return;
        }

        if (
            e.shiftKey &&
            groupElement &&
            groupElement.id !== currentElement?.id
        ) {
            setSpaceToTopLeft({
                x:
                    spaceToTopLeft.x +
                    (currentElement.x - groupElement.x) * ratio,
                y:
                    spaceToTopLeft.y +
                    (currentElement.y - groupElement.y) * ratio,
                id: groupElement.id,
            });
            return;
        }
        if (currentElement?.isGroup) {
            if (groupElement) {
                setSpaceToTopLeft({
                    x:
                        spaceToTopLeft.x +
                        (currentElement.x - groupElement.x) * ratio,
                    y:
                        spaceToTopLeft.y +
                        (currentElement.y - groupElement.y) * ratio,
                    id: groupElement.id,
                });
                setCurrentElement({ ...groupElement });
                groupElementStore.updateSelectedId(null);
            }
            return;
        }
        let dtop = scrollTop;
        if (!scrollTop) {
            setScrollTop(rightContentRef.current.scrollTop);
            dtop = rightContentRef.current.scrollTop;
        }
        if (onDrag || activeDarg) {
            let position = getPositionMoveElement(
                e,
                pageWorksheet,
                currentElement,
                spaceToTopLeft,
                ratio,
                rightContentRef.current.scrollTop - dtop
            );
            setCurrentElement({ ...currentElement, ...position });
        }
    };

    const handleDragEnd = (e: any) => {
        let pageIndex = currentPageIndex;
        if (groupElementState.isOnDragGroup) {
            if (groupElementState.resourceItemsSelected.length > 1) {
                let item = groupElementStore.makeResourceItemGroup();
                if (item) {
                    dispatch(
                        addResourceItems({
                            pageIndex,
                            resourceItems: [item],
                        })
                    );
                    setCurrentElement({
                        ...item,
                        id: item.id,
                    });
                }
            } else {
                if (groupElementState.resourceItemsSelected.length == 1) {
                    setCurrentElement({
                        ...groupElementState.resourceItemsSelected[0],
                    });
                    groupElementStore.resetResourceItems();
                }
            }
            groupElementStore.resetPoints();
        }
    };

    const checkPositionElementDragEnd = () => {
        if (currentElement === null) {
            return;
        }
        let pageSizes = pagesWorksheet.map((page) => {
            return {
                width: page.width,
                height: page.height,
            };
        });
        const resoureCheck = checkPositionElement({
            resourceItem: currentElement,
            currentPage: currentPageIndex,
            pageSizes,
            ratio,
        });

        let resourceItems = groupElementState.resourceItemsCurrent.map(
            (resourceItem) => {
                return checkPositionElement({
                    resourceItem: resourceItem,
                    currentPage: currentPageIndex,
                    pageSizes,
                    ratio,
                    newPageIndex: resoureCheck.pageIndex,
                }).resourceItemNew;
            }
        );

        const deleteResource = () => {
            let ids = [currentElement.id];
            if (currentElement.resourceIds.length) {
                ids = ids.concat(currentElement.resourceIds);
            }
            dispatch(
                removeResourceItems({
                    resourceIds: ids,
                    pageIndex: currentPageIndex,
                    isAddHistory: false,
                })
            );
        };

        if (resoureCheck.isOutPage) {
            // deleteResource();
            // setCurrentElement(null);
            let e = originalElement;
            if (e) {
                let items = [...originalElements, e];
                dispatch(
                    updateResourceItems({
                        pageIndex: currentPageIndex,
                        resourceItems: items,
                        isAddHistory: false,
                    })
                );
                setCurrentElement({ ...e });
            }
        } else {
            if (resoureCheck.pageIndex !== currentPageIndex) {
                dispatch(
                    updatePageIndex({ pageIndex: resoureCheck.pageIndex })
                );
                dispatch(
                    addResourceItems({
                        pageIndex: resoureCheck.pageIndex,
                        resourceItems: [
                            ...resourceItems,
                            resoureCheck.resourceItemNew,
                        ],
                        isAddHistory: false,
                    })
                );
                deleteResource();
                groupElementStore.updatePageIndexResources(
                    resoureCheck.pageIndex
                );
                setCurrentElement({ ...resoureCheck.resourceItemNew });
            } else {
                if (isResourceBackground(currentElement.type)) {
                    setCurrentElement({ ...currentElement, x: 0, y: 0 });
                    dispatch(
                        updateResourceItem({
                            currentElementId: currentElement?.id,
                            attrValues: [
                                { attr: "x", value: 0 },
                                { attr: "y", value: 0 },
                                {
                                    attr: "width",
                                    value: pagesWorksheet[currentPageIndex]
                                        .width,
                                },
                                {
                                    attr: "height",
                                    value: pagesWorksheet[currentPageIndex]
                                        .height,
                                },
                            ],
                            pageIndex: currentPageIndex,
                        })
                    );
                }
            }
        }
    };

    const setCurrentPositionsTable = () => {
        if (currentElement?.tableAttribute) {
            let currentPositions = [];
            currentElement.tableAttribute.data.forEach((cells, row) => {
                cells.forEach((cell, column) => {
                    currentPositions.push(
                        new PositionTable({
                            row,
                            column,
                            rowspan: cell.rowspan,
                            colspan: cell.colspan,
                        })
                    );
                });
            });
            setCurrentElement({
                ...currentElement,
                tableAttribute: {
                    ...currentElement.tableAttribute,
                    currentPositions,
                },
            });
        }
    };

    const onMouseUp = (e: any) => {
        e.preventDefault();
        let pageIndex = currentPageIndex;
        if (
            (activeDarg || onDrag || activeResize || rotate !== null) &&
            currentElement &&
            moved
        ) {
            dispatch(
                updateResourceItems({
                    pageIndex,
                    resourceItems: [
                        {
                            ...currentElement,
                            rotate: activeRotate ? rotate : currentElement.rotate,
                        },
                    ],
                })
            );
            // when move linked text, move also corresponding zone
            syncTemplateLinkedTextToZone(
                dispatch,
                tempTemplateZones,
                currentElement.customZoneID,
                {
                    x: currentElement.x,
                    y: currentElement.y,
                    rotate: activeRotate ? rotate : currentElement.rotate,
                }
            );
            dispatch(
                updateResourceItems({
                    pageIndex: pageIndex,
                    attributesResourceItems:
                        groupElementStore.getSizeResourceItems(),
                    isAddHistory: false,
                })
            );
        }
        if (activeResize) {
            if (!moved) {
                setCurrentPositionsTable();
            }
            setActiveResize(null);
            dispatch(upDateResize(false));
        }
        if (activeRotate) {
            setActiveRotate(false);
            if (rotate !== null) {
                setCurrentElement({
                    ...currentElement,
                    rotate: rotate,
                });
            }
        }
        handleDragEnd(e);
        if (rotate) {
            setRotate(null);
        }
        if (groupElementState.isOnDragGroup) {
            groupElementStore.updateOnDragGroup(false);
        }
        if ((activeDarg || onDrag) && !groupElementState.isOnDragGroup) {
            checkPositionElementDragEnd();
        }
        if (scrollTop !== null) setScrollTop(null);

        if (activeDarg) {
            dispatch(updateIsOnDrag(false));
            setActiveDarg(false);
        }
        if (moved) setMoved(false);
    };

    const scrollToPage = (index: number, time?: number) => {
        index = Math.max(index, 0);
        const page = document.getElementById("page-worksheet_" + index);
        if (page) {
            const top = page.offsetTop;
            const parentContainer = document.getElementById(
                "right-content-container"
            );
            parentContainer.scrollTo({
                top: top,
                behavior: "smooth",
            });
        }
    };

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        let node = event.currentTarget;
        if (currentElement && (onDrag || activeDarg) && moved) {
            if (!scrollTop) {
                setScrollTop(node.scrollTop);
            }
            let dTop = node.scrollTop;
            setCurrentElement({
                ...currentElement,
                y: currentElement.y + (dTop - dsScorll.current) / ratio,
            });
            dsScorll.current = dTop;
        }
        if (metaKey.current) {
            let left = (node.scrollWidth - node.offsetWidth) / 2 - dSpacingZoom;
            let top = dTop.current;
            node.scrollTo({ top, left });
        } else {
            dTop.current = node.scrollTop;
            if (metaKey.current) metaKey.current = false;
        }
    };

    const onWheel = (event: React.WheelEvent<HTMLDivElement>) => {
        const node = event.currentTarget;
        if (event.metaKey || event.ctrlKey) {
            if (!metaKey.current) {
                node.onwheel = function () {
                    return false;
                };
                metaKey.current = true;
            }
            let scaleBy = 1;
            const oldScale = ratio;
            let deltaY = Math.abs(event.deltaY);
            scaleBy += deltaY / (200 + oldScale * 200 + deltaY);
            let newScale =
                event.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
            newScale = parseFloat(newScale.toFixed(2));
            if (newScale > 2) {
                newScale = 2;
            }
            if (newScale < 0.45) {
                newScale = 0.45;
            }
            const { top } = event.currentTarget.getBoundingClientRect();
            let { scrollWidth, offsetWidth, scrollTop } = event.currentTarget;
            const mouseY = event.clientY - top + scrollTop;
            let dFixed = 0;
            let startY = ConstantsApp.PADDING_PAGES;
            pagesWorksheet.forEach((e) => {
                if (mouseY > startY + e.height * oldScale) {
                    dFixed += e.height * oldScale;
                }
                if (mouseY < startY + e.height * oldScale && mouseY > startY) {
                    dFixed += mouseY - startY;
                }
                startY += e.height * oldScale + ConstantsApp.PADDING_PAGES;
            });
            if (mouseY < dFixed) {
                dFixed = 0;
            }
            let dh = mouseY - dFixed;
            const dScale = newScale / oldScale;
            let newY = mouseY * dScale - dh * (dScale - 1);
            scrollTop = newY - event.clientY + top;
            dTop.current = scrollTop;
            setRatio(newScale);
            dispatch(updateRatio(newScale));
            let left = (scrollWidth - offsetWidth) / 2 - dSpacingZoom;
            event.currentTarget.scrollTo({
                top: scrollTop,
                left,
            });
        } else {
            if (metaKey.current) {
                node.onwheel = function () {
                    return true;
                };
                metaKey.current = false;
            }
        }
    };

    return (
        <React.Fragment>
            <div
                id="right-content-container"
                style={{
                    maxHeight: "calc(100vh - 52px)",
                    width: "100%",
                    background: "rgb(215, 215, 215)",
                    overflow: "auto auto",
                    position: "relative",
                    height: isIframe ? 'calc(100vh - 52px)' : 'auto'
                }}
                onMouseMove={handleMouseMove}
                onMouseUp={onMouseUp}
                ref={rightContentRef}
                onMouseLeave={(e) => {
                    if (activeResize) onMouseUp(e);
                }}
                onScroll={onScroll}
                onWheel={onWheel}
            >
                {currentElement && (
                    <EditBar
                        currentElement={currentElement}
                        setCurrentElement={(current) => {
                            setCurrentElement(current);
                        }}
                    />
                )}
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        minHeight: "100%",
                        minWidth: "100%",
                        justifyContent: "center",
                    }}
                    className="not-click"
                    id="right-content-container-2"
                >
                    <div className={"right-content-container not-click"}>
                        <div
                            style={{
                                position: "relative",
                                zIndex: 1,
                                paddingBottom: ConstantsApp.PADDING_PAGES,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {pagesWorksheet.map((pageWorksheet, index) => {
                                return (
                                    <PageWorksheet
                                        ratio={ratio}
                                        key={pageWorksheet.id}
                                        showPageIndex={
                                            pagesWorksheet.length > 1
                                        }
                                        currentElement={
                                            currentElement?.pageIndex === index
                                                ? currentElement
                                                : null
                                        }
                                        setCurrentElement={setCurrentElement}
                                        pageIndex={index}
                                        totalPages={pagesWorksheet.length}
                                        rotate={rotate}
                                        setSpaceToTopLeft={setSpaceToTopLeft}
                                        activeResize={activeResize}
                                        setActiveResize={setActiveResize}
                                        activeRotate={activeRotate}
                                        setActiveRotate={setActiveRotate}
                                        setActiveDarg={setActiveDarg}
                                        onDrag={activeDarg}
                                        moved={moved}
                                        isScaled={isScaled}
                                    ></PageWorksheet>
                                );
                            })}
                        </div>
                    </div>
                    {typeActivity == Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE && (
                        <>
                            <ListActionWordSearch />
                            <ListenWordSearch
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                            />

                            <ListenWordSearchCreator />
                        </>
                    )}
                    {typeActivity == Config.ACTIVITY_TYPE.CROSSWORD.TYPE && (
                        <>
                            <ListenCrossWord />
                        </>
                    )}
                    <ListenReducer
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                    ></ListenReducer>
                    <React.Fragment>
                        <div
                            style={{
                                position: "absolute",
                                left: "0px",
                                top: "0px",
                                margin: "0px",
                                padding: "0px",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            <div id="hidden_text"></div>
                            <div
                                id="hidden_math_filed"
                                style={{
                                    fontSize: staticMathFieldFontSize,
                                    visibility: "hidden",
                                }}
                            >
                                <StaticMathField>
                                    {initStaticMathField}
                                </StaticMathField>
                            </div>
                        </div>
                        <div
                            style={{
                                bottom: 0,
                                left: 0,
                                pointerEvents: "auto",
                                right: 0,
                                top: 0,
                                position: "absolute",
                            }}
                            onMouseDown={(event) => {
                                setCurrentElement(null);
                                dispatch(setResourceItemEditing(""));
                                dispatch(updateIsOnEditText(false));
                            }}
                        ></div>
                    </React.Fragment>
                </div>
                <Notification
                    currentElement={currentElement}
                    setCurrentElement={setCurrentElement}
                />
                {pagesWorksheet.length > 0 && (
                    <ListenShortcut
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                        pagesWorksheet={pagesWorksheet}
                    />
                )}
                {activeRotate && (
                    <div
                        ref={rotationPopupRef}
                        className="popup-rotate"
                        style={{ opacity: rotate !== null ? 1 : 0 }}
                    >
                        {rotate}°
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};
export default RightContent;
