import { Button } from "@mui/material";
import { useEffect } from "react";
import { TextDashed, TextRegular, TextStroked } from "../../../../assets/icon";
import { useAppDispatch } from "../../../../redux/hook";
import { updateQuestion } from "../../../../redux/reducers/createWorksheet";
import {
    IQuestionActivity,
    QuestionActivity,
} from "../../../../shared/models/questionActivity";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import CreateWSConfig from "../../../../utils/create-ws-config";
import "./index.scss";
import Config from "../../../../shared/utils/config";
import { getListFontFamilies } from "../util";

const FontTypeSection = ({
    question,
    config,
    setConfig,
}: {
    question: IQuestionActivity;
    config: any;
    setConfig: any;
}) => {
    const dispatch = useAppDispatch();
    const fontFamilies = getListFontFamilies(
        question?.nameTracingAttribute?.fontStyle
    );
    const fontFamily = question?.nameTracingAttribute?.fontFamily;
    const currentFontFamily = fontFamilies?.find((f) => f.name === fontFamily);

    let LIST_TEXT_STYLE = [
        {
            id: ConstantsTool.TEXT_STYLE.REGULAR,
            img: <TextRegular />,
            show: true,
        },
        {
            id: ConstantsTool.TEXT_STYLE.DASHED,
            img: <TextDashed />,
            show: true,
        },
        {
            id: ConstantsTool.TEXT_STYLE.STROKED,
            img: <TextStroked />,
            show: true,
        },
    ];
    if (currentFontFamily) {
        if (currentFontFamily.stroked === CreateWSConfig.EMPTY_FONT) {
            LIST_TEXT_STYLE.forEach((s) => {
                if (s.id === ConstantsTool.TEXT_STYLE.STROKED) {
                    s.show = false;
                }
            });
        }
    }

    useEffect(() => {
        if (!question?.id) {
            setConfig({
                fontStyle: ConstantsTool.FONT_PRINT,
                textStyle: ConstantsTool.TEXT_STYLE.DASHED,
            });
        }
    }, [question]);

    const handleChangeFontStyle = (value) => {
        if (config.fontStyle === value) {
            return;
        }
        setConfig({
            ...config,
            fontStyle: value,
        });
        if (question?.id) {
            const fontFamily =
                value === ConstantsTool.FONT_PRINT
                    ? "Quicksand"
                    : "Standard Cursive";
            const newQuestion = new QuestionActivity({
                ...question,
                type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
            });
            newQuestion.nameTracingAttribute.fontStyle = value;
            newQuestion.nameTracingAttribute.fontFamily = fontFamily;
            dispatch(
                updateQuestion({
                    question: newQuestion,
                })
            );
        }
    };

    const handleChangeTextStyle = (id) => {
        setConfig({
            ...config,
            textStyle: id,
        });
        if (question?.id) {
            const newQuestion = new QuestionActivity({
                ...question,
                type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
            });
            newQuestion.nameTracingAttribute.textStyle = id;
            dispatch(
                updateQuestion({
                    question: newQuestion,
                })
            );
        }
    };

    return (
        <div className="font-type-name-tracing">
            <div className="btn-group">
                <Button
                    className={`btn ${
                        config.fontStyle === ConstantsTool.FONT_PRINT
                            ? "active"
                            : "deactive"
                    }`}
                    fullWidth
                    onClick={() =>
                        handleChangeFontStyle(ConstantsTool.FONT_PRINT)
                    }
                    disableRipple
                >
                    Print
                </Button>
                <Button
                    className={`btn ${
                        config.fontStyle === ConstantsTool.FONT_CURSIVE
                            ? "active"
                            : "deactive"
                    }`}
                    fullWidth
                    onClick={() =>
                        handleChangeFontStyle(ConstantsTool.FONT_CURSIVE)
                    }
                    disableRipple
                >
                    Cursive
                </Button>
            </div>
            <div className="img-group">
                {LIST_TEXT_STYLE.map(({ img, id, show }) => (
                    <div
                        className={`img-btn ${
                            id === config.textStyle ? "active" : ""
                        } ${show ? "" : "hide_nt"}`}
                        key={id}
                        onClick={() => {
                            if (!show) {
                                return;
                            }
                            handleChangeTextStyle(id);
                        }}
                    >
                        <div className={show ? "show_nt" : "hide_nt"}>
                            {img}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FontTypeSection;
