import useMediaQuery from "@mui/material/useMediaQuery";
import DynamicComponent from "../../dynamic-component";
import { TypeOfPlacementTooltip } from "../render-tooltip";
import "./style.scss";
// import ContentToolTipCustomize from "./content-tooltip";

type TYPE_PLACEMENT =
    | TypeOfPlacementTooltip.top
    | TypeOfPlacementTooltip.left
    | TypeOfPlacementTooltip.right
    | TypeOfPlacementTooltip.bottom;
type ToolTipCustomizeProps<T> = {
    [K in keyof T]: T[K];
} & {
    title: string;
    children: any;
    placement?: TYPE_PLACEMENT;
    style?: any;
    arrow?: boolean;
    autoOpen?: boolean;
    keyTitle?: string;
};
function ToolTipCustomize<T>(props: ToolTipCustomizeProps<T>) {
    const isTablet = useMediaQuery("(max-width: 768px)");

    if (isTablet || !props?.title) {
        return <>{props.children}</>;
    }

    return (
        <>
            {/* <ContentToolTipCustomize props={props} /> */}
            <DynamicComponent
                path="./content-tooltip"
                component={import("./content-tooltip")}
                props={props}
            />
        </>
    );
}

export default ToolTipCustomize;
