import React, { useState } from "react";
import "./index.scss";

function WordView({
    word,
    wordIndex,
    selected,
    handleToggleWord,
}: {
    word: string;
    wordIndex: number;
    selected: boolean;
    handleToggleWord: any;
}) {
    const [hover, setHorver] = useState(false);
    return (
        <div
            className={"box-word " + (selected ? "selected" : "")}
            onMouseEnter={() => {
                if (!selected) {
                    setHorver(true);
                }
            }}
            onMouseLeave={() => {
                if (!selected) {
                    setHorver(false);
                }
            }}
        >
            <span
                key={wordIndex}
                className={`highlighted-word`}
                onClick={(e) => {
                    e.stopPropagation()
                    if (!selected) {
                        setHorver(false);
                    }
                    handleToggleWord(word, wordIndex)
                }}
                style={{
                    opacity: hover ? 0 : 1,
                }}
            >
                {word}
            </span>
            <div className="under-line" 
                style={{
                    opacity: (hover || selected) ? 1 : 0,
                    width: hover ? "95%" : "100%",
                    transform: hover ? "translateY(-1px)" : "translateY(0px)"
                }}
            />
        </div>
    );
}

export default WordView;
