import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import PopupCommon from "../../../../../resource-user/components/common/pop-up";
import { changeErrorWordsCrosswordAction } from "../../../../../resource-user/redux/action/crossword.action";
import "./index.scss";

const PopupWarning = ({
    errorValue,
    setErrorValue,
}: {
    errorValue: string[];
    setErrorValue: Function;
}) => {
    const renderActionPopUp = () => {
        return (
            <div className="action">
                <div
                    className="confirm"
                    onClick={(e) => {
                        e.stopPropagation();
                        setErrorValue([]);
                    }}
                >
                    OK
                </div>
            </div>
        );
    };

    const handleOnKeyDown = (e) => {
        e.preventDefault();
        const key = e.key;
        if (errorValue.length > 0 && key === "Enter") {
            setErrorValue([]);
        }
    };

    const renderContent = () => {
        const invalidWords = [];
        const emptyWords = [];
        errorValue.forEach((word) => {
            const value = word.trim();

            if (value) {
                invalidWords.push(value);
            } else {
                emptyWords.push(value);
            }
        });
        const errorAZ = invalidWords.length > 0;
        const errorEmpty = emptyWords.length > 0;
        return (
            <div className="content">
                <div>
                    Only
                    {errorAZ && " a-z, A-Z"}
                    {errorAZ && errorEmpty && " and "}
                    {errorEmpty && " non-empty words "} are allowed:{" "}
                    {invalidWords.join(", ")}
                </div>
            </div>
        );
    };

    return (
        <PopupCommon
            handleOnKeyDown={handleOnKeyDown}
            open={errorValue.length > 0}
            setOpen={(value) => setErrorValue([])}
            title={"Warning"}
            renderContent={renderContent}
            renderAction={renderActionPopUp}
            classNameDialog="popup-warning-word-scramble"
            clickOutside={false}
        />
    );
};

export default PopupWarning;
