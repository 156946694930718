import React from "react";

const CreateIconCollection = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM17.0838 12.9968C17.6354 12.9968 18.0808 12.5513 18.0808 11.9997C18.0738 11.4553 17.6212 11.0027 17.0838 11.0027H12.9967V6.91563C12.9967 6.36409 12.5513 5.91861 11.9997 5.91861C11.4482 5.91861 11.0027 6.36409 11.0027 6.91563V11.0027H6.91562C6.36407 11.0027 5.9186 11.4482 5.9186 11.9997C5.9183 12.2642 6.02325 12.518 6.21029 12.7051C6.39733 12.8921 6.6511 12.9971 6.91562 12.9968L11.0027 12.9968V17.0838C11.0024 17.3483 11.1073 17.6021 11.2944 17.7892C11.4814 17.9762 11.7352 18.0811 11.9997 18.0809C12.5513 18.0809 12.9967 17.6354 12.9967 17.0838V12.9968L17.0838 12.9968Z"
                fill="rgba(var(--color), 1)"
            />
        </svg>
    );
};

export default CreateIconCollection;
