import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    updateResourceItem,
    updateResourceItems,
} from "../../../../../../redux/reducers/createWorksheet";
import ToolTipCustomize from "../../../../../../resource-user/components/common/library/tool-tip";
import LineView from "../../../../../../resource-user/components/common/line-view";
import { removeTooltip } from "../../../../../../resource-user/utils";
import { getListCommands } from "../../../../../../resource-user/utils/shape";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import { IShapeAttribute } from "../../../../../../shared/models/shapeAttribute";
import { ConstantsApp } from "../../../../../../utils/Constants";
import { getDataLine } from "../../../../../../utils/canvalData";
import "./index.scss";
import { isResourceLine, makeChangeLine } from "../../../../../../utils";
import groupElementStore from "../../../../../../syncExternalStore/groupElementStore";

const LINES = getDataLine(),
    DATA_LINE = LINES.filter((line) => line.pathEnd && line.pathStart),
    FIRST_LINE = LINES[0];

function LineEditBar({
    currentItem,
    setCurrentElement,
    resourceItems,
}: {
    currentItem: IResourceItemNew;
    setCurrentElement: any;
    resourceItems: IResourceItemNew[];
}) {
    const refContent = useRef<HTMLDivElement>(null);
    const refPopup: any = useRef(null);
    const dispatch = useAppDispatch();
    const currentInfo = useAppSelector(
        (state) => state.leftDataState.currentInfo
    );
    const [isShow, setIsShow] = useState(null);
    const strokeWidth = 1.5;
    const width = 20;
    const isGroup = currentItem.resourceIds.length;
    const [line, setLine] = useState(
        isGroup ? FIRST_LINE : currentItem?.shapeAttribute
    );

    useEffect(() => {
        if (currentInfo !== null && !isGroup) {
            setLine(currentInfo?.shapeAttribute);
        }
    }, [currentInfo]);

    useEffect(() => {
        if (isGroup) {
            setLine(getShapeAttribute(resourceItems));
        } else {
            setLine(currentItem?.shapeAttribute);
        }
    }, [currentItem?.shapeAttribute]);

    const getShapeAttribute = (resourceItems: IResourceItemNew[]) => {
        let lines = resourceItems.filter((e) => isResourceLine(e));
        let line = lines[0].shapeAttribute;
        let { path, pathEnd, pathStart } = line;
        let changePathEnd = false,
            changePathStart = false;
        lines.forEach((resourceItem) => {
            if (resourceItem.shapeAttribute.path !== path) {
                path = FIRST_LINE.path;
            }
            if (
                resourceItem.shapeAttribute?.pathStart?.fill !==
                    pathStart?.fill ||
                resourceItem.shapeAttribute?.pathStart?.path !== pathStart?.path
            ) {
                pathStart = FIRST_LINE.pathStart;
                changePathStart = true;
            }
            if (
                resourceItem.shapeAttribute?.pathEnd?.path !== pathEnd?.path ||
                resourceItem.shapeAttribute?.pathEnd?.fill !== pathEnd?.fill
            ) {
                pathEnd = FIRST_LINE.pathEnd;
                changePathEnd = true;
            }
        });
        if (changePathStart) {
            line = makeChangeLine(FIRST_LINE, line, true);
        }
        if (changePathEnd) {
            line = makeChangeLine(FIRST_LINE, line, false);
        }
        return { ...line };
    };

    const changePath = (path: string, isStart: boolean) => {
        const commands = getListCommands(path);
        if (isStart) {
            commands[1][1] = "160";
            commands[1][2] = "2";
        } else {
            commands[0][1] = "0";
            commands[0][2] = "2";
        }
        let clipPath = "";
        commands.forEach((command) => {
            command.forEach((element) => {
                clipPath += element + " ";
            });
        });
        return clipPath.trim();
    };

    const changeShapeAttribute = (shape: IShapeAttribute) => {
        setCurrentElement({
            ...currentItem,
            shapeAttribute: {
                ...shape,
            },
        });
        dispatch(
            updateResourceItem({
                currentElementId: currentItem.id,
                pageIndex: currentItem.pageIndex,
                attrValues: [
                    {
                        attr: "shapeAttribute",
                        value: {
                            ...shape,
                        },
                    },
                ],
            })
        );
    };

    useEffect(() => {
        document.addEventListener("click", listen);
        return () => {
            document.removeEventListener("click", listen);
        };
    }, [isShow]);

    const listen = (event: MouseEvent) => {
        if (refContent.current) {
            let { x, y, width, height } =
                refContent.current.getBoundingClientRect();
            if (
                event.clientX - x <= 0 ||
                event.clientX - x >= width ||
                event.clientY - y <= 0 ||
                event.clientY - y >= height
            ) {
                setIsShow(null);
            }
        }
    };

    const onChangeLienGroup = (newLine: IShapeAttribute, isStart: boolean) => {
        let resourceItems = groupElementStore.updateLines(newLine, isStart);
        setLine(getShapeAttribute(resourceItems));
        dispatch(
            updateResourceItems({
                pageIndex: currentItem.pageIndex,
                resourceItems,
            })
        );
    };

    const onChangeLine = (newLine: IShapeAttribute, isStart: boolean) => {
        if (isGroup) {
            onChangeLienGroup(newLine, isStart);
            return;
        }
        let shapeAttribute = makeChangeLine(newLine, line, isStart);
        changeShapeAttribute(shapeAttribute);
    };

    return (
        <div
            className="shape-bar-container"
            ref={refContent}
            style={{ paddingRight: 0, position: "relative" }}
        >
            <ToolTipCustomize
                title={isShow != true ? "Line start" : ""}
                style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
            >
                <div
                    className="button-edit-line"
                    onClick={() => {
                        removeTooltip();

                        setIsShow(true);
                    }}
                >
                    <LineView
                        width={width}
                        height={strokeWidth}
                        line={{
                            ...line,
                            strokeDasharray: 0,
                            pathEnd: null,
                            path: changePath(line.path, true),
                        }}
                        color="#000000"
                        position="static"
                    />
                </div>
            </ToolTipCustomize>
            <ToolTipCustomize
                title={isShow != false ? "Line end" : ""}
                style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
            >
                <div
                    className="button-edit-line end"
                    onClick={() => {
                        removeTooltip();

                        setIsShow(false);
                    }}
                >
                    <LineView
                        width={width}
                        height={strokeWidth}
                        line={{
                            ...line,
                            strokeDasharray: 0,
                            pathStart: null,
                            path: changePath(line.path, false),
                        }}
                        color="#000000"
                        position="static"
                    />
                </div>
            </ToolTipCustomize>
            {isShow === true && (
                <div ref={refPopup} className="popup-line popup-line-start">
                    <div
                        className={`button-change-path ${
                            line.pathStart === null ? "choosed-line" : ""
                        }`}
                        onClick={() => onChangeLine(FIRST_LINE, true)}
                    >
                        <LineView
                            width={width}
                            height={strokeWidth}
                            line={FIRST_LINE}
                            color="#000000"
                            position="static"
                        />
                    </div>
                    {DATA_LINE.map((lineStart) => {
                        return (
                            <div
                                key={lineStart.name}
                                onClick={() => onChangeLine(lineStart, true)}
                                className={`button-change-path ${
                                    lineStart.pathStart.path ===
                                        line?.pathStart?.path &&
                                    lineStart.pathStart.fill ===
                                        line?.pathStart?.fill
                                        ? "choosed-line"
                                        : ""
                                }`}
                            >
                                <LineView
                                    width={width}
                                    height={strokeWidth}
                                    line={{
                                        ...lineStart,
                                        strokeDasharray: 0,
                                        pathEnd: null,
                                        path: changePath(lineStart.path, true),
                                    }}
                                    color="#000000"
                                    position="static"
                                />
                            </div>
                        );
                    })}
                </div>
            )}
            {isShow === false && (
                <div
                    ref={refPopup}
                    className="popup-line popup-line-end"
                    style={{}}
                >
                    <div
                        className={`button-change-path ${
                            line.pathEnd === null ? "choosed-line" : ""
                        }`}
                        onClick={() => onChangeLine(FIRST_LINE, false)}
                    >
                        <LineView
                            width={width}
                            height={strokeWidth}
                            line={FIRST_LINE}
                            color="#000000"
                            position="static"
                        />
                    </div>
                    {DATA_LINE.map((lineEnd) => {
                        return (
                            <div
                                key={lineEnd.name}
                                onClick={() => onChangeLine(lineEnd, false)}
                                className={`button-change-path ${
                                    lineEnd.pathEnd.path ===
                                        line?.pathEnd?.path &&
                                    lineEnd.pathEnd.fill === line?.pathEnd?.fill
                                        ? "choosed-line"
                                        : ""
                                }`}
                            >
                                <LineView
                                    width={width}
                                    height={strokeWidth}
                                    line={{
                                        ...lineEnd,
                                        strokeDasharray: 0,
                                        pathStart: null,
                                        path: changePath(lineEnd.path, false),
                                    }}
                                    color="#000000"
                                    position="static"
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default LineEditBar;
