export const GTMid = "GTM-N2T9Q3P";

export const pageview = (url, userid) => {
    // window.clarity("identify",userid)
    if (window?.dataLayer) {
        window.dataLayer.push({
            event: "pageview",
            page: url,
            userid: userid,
        });
    }
};
export const event = (event, params) => {
    // window.clarity("identify",userid)
    if (window?.dataLayer) {
        window.dataLayer.push({
            event: event,
            ...params,
        });
    }
};
export const changeLayerVariable = (obj) => {
    if (window?.dataLayer) {
        window.dataLayer.push(obj);
    }
};
