import React, { Suspense, useMemo } from "react";
import LoadingComponent from "./loading/loading-component";

export type DynamicComponentProps<T> = {
    [K in keyof T]: T[K];
} & { component: any; loading?: boolean; path: string };

function DynamicComponent<T>(props: DynamicComponentProps<T>) {
    const ENV_CONFIG = process.env.NEXT_PUBLIC_ENV;
    // const { ...restProps } = props;
    const RenderComponent = useMemo(() => {
        try {
            if (ENV_CONFIG) {
                return require("next/dynamic")(() => props.component, {
                    loading: () => {
                        return props.loading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <LoadingComponent />
                            </div>
                        ) : (
                            <></>
                        );
                    },
                });
            } else {
                return React.lazy(() => props.component);
            }
        } catch (error) {
            console.log("e", error);
        }
    }, [ENV_CONFIG, props.path]);
    if (ENV_CONFIG) {
        return <RenderComponent {...props} />;
    }

    return (
        <Suspense fallback={null}>
            <RenderComponent {...props} />
        </Suspense>
    );
}

export type ExtractParamType<T> = T extends (...args: infer P) => any
    ? P[0]
    : never;

export default DynamicComponent;