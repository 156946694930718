import ConstantsTool from "../utils/ConstantsTool";
import { IPosition } from "./module";
import { ITextAttribute, TextAttribute } from "./resourceItemNew";

export enum DirectionTable {
    column,
    row,
}
export interface IPositionTable {
    row: number;
    column: number;
    colspan: number;
    rowspan: number;
}

export class PositionTable implements IPositionTable {
    row: number;
    column: number;
    colspan: number;
    rowspan: number;
    constructor(obj: {
        row: number;
        column: number;
        colspan: number;
        rowspan: number;
    }) {
        this.row = obj.row;
        this.column = obj.column;
        this.colspan = obj.colspan;
        this.rowspan = obj.rowspan;
    }
}
export class ColorCellTbale {
    background?: string;
    colorBorder?: string;
    colorBorderTop?: string;
    colorBorderBottom?: string;
    colorBorderLeft?: string;
    colorBorderRight?: string;
    positions: string[];

    constructor(obj: {
        background?: string;
        colorBorder?: string;
        colorBorderTop?: string;
        colorBorderBottom?: string;
        colorBorderLeft?: string;
        colorBorderRight?: string;
        positions?: string[];
    }) {
        this.background = obj.background;
        this.colorBorder = obj.colorBorder;
        this.colorBorderTop = obj.colorBorderTop;
        this.colorBorderBottom = obj.colorBorderBottom;
        this.colorBorderLeft = obj.colorBorderLeft;
        this.colorBorderRight = obj.colorBorderRight;
        this.positions = obj.positions ?? ["top", "right", "bottom", "left"];
    }
}

export class BorderCell {
    type: string;
}

export class CellTable {
    id: string;
    colspan: number;
    rowspan: number;
    width: number;
    height: number;
    originHeight?: number;
    colorCell: ColorCellTbale;
    textAttribute: ITextAttribute;
    positionParent?: IPosition;
    borderWidth: number;
    borderWidths: number[];
    strokeDasharray: number;
    strokeDasharrays: number[];
    zIndex: number;
    constructor(obj: {
        rowspan: number;
        colspan: number;
        width: number;
        height: number;
        originHeight?: number;
        colorCell: ColorCellTbale;
        textAttribute?: ITextAttribute | null;
        positionParent?: IPosition;
        borderWidth?: number;
        zIndex?: number;
        strokeDasharray?: number;
        strokeDasharrays?: number[];
        borderWidths?: number[];
    }) {
        this.id = Math.random().toString(18);
        this.rowspan = obj.rowspan;
        this.colspan = obj.colspan;
        this.width = obj.width;
        this.height = obj.height;
        this.colorCell = obj.colorCell
            ? new ColorCellTbale(obj.colorCell)
            : new ColorCellTbale({});
        this.textAttribute = obj.textAttribute
            ? new TextAttribute(obj.textAttribute)
            : new TextAttribute({
                  align: "center",
                  fontSize: 16,
                  fontFamily: "Nunito",
              });
        this.positionParent = obj?.positionParent
            ? { ...obj?.positionParent }
            : undefined;
        this.originHeight = obj.originHeight;
        this.borderWidth = obj?.borderWidth ?? 1;
        this.borderWidths = obj.borderWidths ?? [1, 1, 1, 1];
        this.zIndex = obj?.zIndex ?? 0;
        this.strokeDasharrays = obj?.strokeDasharrays ?? [0, 0, 0, 0];
        this.strokeDasharray = obj?.strokeDasharray ?? 0;
    }
}

export interface ITableAttribute {
    gap: number;
    data: CellTable[][];
    currentPositions?: IPositionTable[];
    borderWidth: number;
    addCells(params: { type: DirectionTable; col?: number; row?: number }): {
        dHeight: number;
        dWidth: number;
    };
    getRows(isEmpty?: { isEmpty: boolean }): { row: number; cols: number[] }[];
    getColumns(): { col: number; rows: number[] }[];
    checkMergeCells(type: DirectionTable): boolean;
    changeStyleTextCells(field: string, value: any): void;
    getTextAtribute(): ITextAttribute;
    changeColorCells({ colorCell }: { colorCell: ColorCellTbale }): void;
    getColorCells(): { backgrounds: any[]; colorBorders: any[] };
    moveCells(direction: "up" | "down" | "right" | "left"): void;
    checkMoveCells(): {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
    };
    getMergeCells(): DirectionTable | null;
    getMaxIndex(): number;
    setZIndexCells(): void;
    getTableWidth(): number;
}

export class TableAttribute implements ITableAttribute {
    gap: number;
    data: CellTable[][];
    currentPositions: IPositionTable[];
    borderWidth: number;
    constructor(obj: {
        gap: number;
        data: CellTable[][];
        currentPositions?: IPositionTable[];
        borderWidth?: number;
    }) {
        this.gap = obj.gap;
        this.data = obj.data.map((row) => {
            return row.map((col) => {
                return new CellTable(col);
            });
        });
        this.currentPositions = obj?.currentPositions
            ? obj.currentPositions.map((el) => {
                  return new PositionTable(el);
              })
            : [];
        this.borderWidth = obj?.borderWidth ?? 1;
    }
    setZIndexCells(): void {
        const zIndexs: number[] = [];
        this.data.forEach((cells) => {
            cells.forEach((cell) => {
                if (!zIndexs.includes(cell.zIndex)) zIndexs.push(cell.zIndex);
            });
        });
        zIndexs.sort((a, b) => a - b);
        this.data.forEach((cells, row) => {
            cells.forEach((cell, col) => {
                let zIndex = zIndexs.indexOf(cell.zIndex);
                if (zIndex >= 0) {
                    this.data[row][col].zIndex = zIndex;
                }
            });
        });
    }
    getMaxIndex(): number {
        let maxIndex = this.data[0][0].zIndex;
        this.data.forEach((cells) => {
            cells.forEach((cell) => {
                if (maxIndex < cell.zIndex) {
                    maxIndex = cell.zIndex;
                }
            });
        });
        return maxIndex;
    }
    getMergeCells(): DirectionTable | null {
        let direction = null;
        let rows = this.getRows();
        let columns = this.getColumns();
        if (
            this.currentPositions?.length > 1 &&
            this.currentPositions?.length <=
                this.data.length * this.data[0].length
        ) {
            if (columns.length > 1) {
                return DirectionTable.row;
            }
            if (rows.length > 1) {
                return DirectionTable.column;
            }
        }

        return direction;
    }
    checkMoveCells(): {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
    } {
        let rows = this.getRows();
        let cols = this.getColumns();
        let up = true,
            left = true,
            right = true,
            down = true;
        if (cols.length && rows.length) {
            const maxCol = cols[cols.length - 1].col,
                minCol = cols[0].col,
                maxRow = rows[rows.length - 1].row,
                minRow = rows[0].row;
            cols.forEach((el) => {
                let col = el.col;
                this.data.forEach((_, row) => {
                    let cell = this.data[row][col];
                    if (maxCol < this.data[0].length - 1) {
                        if (
                            col + cell.colspan - 1 > maxCol ||
                            this.data[row][maxCol + 1].colspan > 1 ||
                            (cell.positionParent &&
                                cell.positionParent.col < minCol)
                        ) {
                            right = false;
                        }
                    } else {
                        right = false;
                    }
                    if (minCol > 0) {
                        if (
                            col + cell.colspan - 1 > maxCol ||
                            (this.data[row][minCol - 1].positionParent &&
                                this.data[row][minCol - 1]!.positionParent!
                                    .col <
                                    minCol - 1) ||
                            (cell.positionParent &&
                                cell.positionParent.col < minCol)
                        ) {
                            left = false;
                        }
                    } else {
                        left = false;
                    }
                });
            });
            rows.forEach((el) => {
                let row = el.row;
                this.data[0].forEach((_, col) => {
                    let cell = this.data[row][col];
                    if (maxRow < this.data.length - 1) {
                        if (
                            row + cell.rowspan - 1 > maxRow ||
                            this.data[maxRow + 1][col].rowspan > 1 ||
                            (cell.positionParent &&
                                cell.positionParent.row < minRow)
                        ) {
                            down = false;
                        }
                    } else {
                        down = false;
                    }
                    if (minRow > 0) {
                        if (
                            row + cell.rowspan - 1 > maxRow ||
                            (this.data[minRow - 1][col].positionParent &&
                                this.data[minRow - 1][col]!.positionParent!
                                    .row <
                                    minRow - 1) ||
                            (cell.positionParent &&
                                cell.positionParent.row < minRow)
                        ) {
                            up = false;
                        }
                    } else {
                        up = false;
                    }
                });
            });
        }
        return { up, down, left, right };
    }
    moveCells(direction: "up" | "down" | "right" | "left"): void {
        function swapElements(arr, index1, index2) {
            if (
                index1 < 0 ||
                index1 >= arr.length ||
                index2 < 0 ||
                index2 >= arr.length
            ) {
                console.log("Invalid indices");
                return arr;
            }
            const temp = arr[index1];
            arr[index1] = arr[index2];
            arr[index2] = temp;
            return arr;
        }

        let rows = this.getRows();
        let cols = this.getColumns();
        if (direction === "right") {
            cols = cols.sort((a, b) => b.col - a.col);
            cols.forEach((el) => {
                let col = el.col;
                this.data.forEach((_, row) => {
                    if (this.data[row][col].positionParent) {
                        this.data[row][col]!.positionParent!.col += 1;
                    }
                    if (this.data[row][col + 1].positionParent) {
                        this.data[row][col + 1]!.positionParent!.col -= 1;
                    }
                    swapElements(this.data[row], col, col + 1);
                });
            });
            this.currentPositions.forEach((el) => {
                el.column += 1;
            });
        }
        if (direction === "left") {
            cols.forEach((el) => {
                let col = el.col;
                this.data.forEach((_, row) => {
                    if (this.data[row][col].positionParent) {
                        this.data[row][col]!.positionParent!.col -= 1;
                    }
                    if (this.data[row][col - 1].positionParent) {
                        this.data[row][col - 1]!.positionParent!.col += 1;
                    }

                    swapElements(this.data[row], col, col - 1);
                });
            });
            this.currentPositions.forEach((el) => {
                el.column -= 1;
            });
        }
        if (direction === "down") {
            rows = rows.sort((a, b) => b.row - a.row);
            rows.forEach((el) => {
                let row = el.row;
                this.data[row].forEach((_, col) => {
                    if (this.data[row][col].positionParent) {
                        this.data[row][col]!.positionParent!.row += 1;
                    }
                    if (this.data[row + 1][col].positionParent) {
                        this.data[row + 1][col]!.positionParent!.row -= 1;
                    }
                });
                swapElements(this.data, row, row + 1);
            });
            this.currentPositions.forEach((el) => {
                el.row += 1;
            });
        }
        if (direction === "up") {
            rows.forEach((el) => {
                let row = el.row;
                this.data[row].forEach((_, col) => {
                    if (this.data[row][col].positionParent) {
                        this.data[row][col]!.positionParent!.row -= 1;
                    }
                    if (this.data[row - 1][col].positionParent) {
                        this.data[row - 1][col]!.positionParent!.row += 1;
                    }
                });
                swapElements(this.data, row, row - 1);
            });
            this.currentPositions.forEach((el) => {
                el.row -= 1;
            });
        }
    }
    getColorCells(): { backgrounds: any[]; colorBorders: any[] } {
        let tableAttribute = this;
        let rows = tableAttribute.getRows({ isEmpty: false });
        let color = tableAttribute.data[rows[0].row][rows[0].cols[0]].colorCell;
        let { background, colorBorder } = color;
        let backgrounds = [background];
        let colorBorders = [colorBorder];
        rows.forEach((el) => {
            el.cols.forEach((col) => {
                let colorCell = tableAttribute.data[el.row][col].colorCell;
                if (!backgrounds.includes(colorCell.background)) {
                    backgrounds.push(colorCell.background);
                }
                const {
                    colorBorder,
                    colorBorderBottom,
                    colorBorderLeft,
                    colorBorderRight,
                    colorBorderTop,
                } = colorCell;
                if (!colorBorders.includes(colorBorder)) {
                    colorBorders.push(colorBorder);
                }
                if (this.gap === 0) {
                    if (
                        colorBorderTop &&
                        !colorBorders.includes(colorBorderTop)
                    ) {
                        colorBorders.push(colorBorderTop);
                    }
                    if (
                        colorBorderBottom &&
                        !colorBorders.includes(colorBorderBottom)
                    ) {
                        colorBorders.push(colorBorderBottom);
                    }
                    if (
                        colorBorderRight &&
                        !colorBorders.includes(colorBorderRight)
                    ) {
                        colorBorders.push(colorBorderRight);
                    }
                    if (
                        colorBorderLeft &&
                        !colorBorders.includes(colorBorderLeft)
                    ) {
                        colorBorders.push(colorBorderLeft);
                    }
                }
            });
        });
        return { backgrounds, colorBorders };
    }
    changeColorCells({ colorCell }: { colorCell: ColorCellTbale }): void {
        const changePosition = (
            postion: "top" | "right" | "bottom" | "left",
            cell: CellTable
        ) => {
            let positions = cell.colorCell.positions.slice();
            let index = positions.indexOf(postion);
            if (index >= 0) {
                positions.splice(index, 1);
                positions.push(postion);
                cell.colorCell.positions = positions;
            }
        };
        let rows = this.getRows({ isEmpty: false });
        const maxZindex = this.getMaxIndex() + 1
        rows.forEach((el) => {
            let row = el.row;
            el.cols.forEach((col) => {
                let cell = this.data[row][col];
                let item = cell.colorCell;
                cell.colorCell.background =
                    colorCell?.background ?? item.background;
                cell.colorCell.colorBorder =
                    colorCell?.colorBorder ?? item.colorBorder;
                if (colorCell.colorBorder) {
                    cell.colorCell.colorBorderTop = colorCell.colorBorder;
                    cell.colorCell.colorBorderBottom = colorCell.colorBorder;
                    cell.colorCell.colorBorderRight = colorCell.colorBorder;
                    cell.colorCell.colorBorderLeft = colorCell.colorBorder;
                    cell.zIndex = maxZindex
                    if (row > 0) {
                        this.data[row - 1][col].colorCell.colorBorderBottom =
                            colorCell.colorBorder;
                        changePosition("bottom", this.data[row - 1][col]);
                    }
                    if (row < this.data.length - 1) {
                        this.data[row + 1][col].colorCell.colorBorderTop =
                            colorCell.colorBorder;
                        changePosition("top", this.data[row + 1][col]);
                    }
                    if (col > 0) {
                        this.data[row][col - 1].colorCell.colorBorderRight =
                            colorCell.colorBorder;
                        changePosition("right", this.data[row][col - 1]);
                    }
                    if (col < this.data[0].length - 1) {
                        this.data[row][col + 1].colorCell.colorBorderLeft =
                            colorCell.colorBorder;
                        changePosition("left", this.data[row][col + 1]);
                    }
                }
            });
        });
        this.setZIndexCells()
    }
    getTextAtribute(): ITextAttribute {
        let tableAttribute = this;
        let rows = tableAttribute.getRows({ isEmpty: false });
        let textAttr =
            tableAttribute.data[rows[0].row][rows[0].cols[0]].textAttribute;
        let {
            align,
            fontColor,
            fontFamily,
            isBold,
            isItalic,
            fontSize,
            underline,
            lineHeight,
            letterSpacing,
            fontColors,
        } = textAttr;
        fontColors = [textAttr.fontColor];
        rows.forEach((el) => {
            el.cols.forEach((col) => {
                let text = tableAttribute.data[el.row][col].textAttribute;
                if (!fontColors.includes(text.fontColor))
                    fontColors.push(text.fontColor);
                if (textAttr.align !== text.align) {
                    align = "center";
                }
                if (textAttr.fontColor !== text.fontColor) {
                    fontColor = "#000";
                }
                if (textAttr.fontSize !== text.fontSize) {
                    fontSize = 0;
                }
                if (textAttr.isBold !== text.isBold) {
                    isBold = false;
                }
                if (textAttr.isItalic !== text.isItalic) {
                    isItalic = false;
                }
                if (textAttr.underline !== text.underline) {
                    underline = false;
                }
                if (textAttr.fontFamily !== text.fontFamily) {
                    fontFamily = "";
                }
                if (textAttr.lineHeight !== text.lineHeight)
                    lineHeight = ConstantsTool.LINE_HEIGHT_DEFAULT;
                if (textAttr.letterSpacing !== text.letterSpacing)
                    letterSpacing = 0;
            });
        });
        if (fontColors.length <= 1) fontColors = [];
        return {
            ...new TextAttribute(),
            align,
            fontColor,
            fontFamily,
            isBold,
            isItalic,
            fontSize,
            underline,
            letterSpacing,
            lineHeight,
            fontColors,
        };
    }
    changeStyleTextCells(field: string, value: any): void {
        let rows = this.getRows({ isEmpty: false });
        rows.forEach((el) => {
            el.cols.forEach((col) => {
                this.data[el.row][col].textAttribute[field] = value;
            });
        });
    }
    checkMergeCells(type: DirectionTable): boolean {
        let check = true;
        if (this.currentPositions.length === 1) {
            let position = this.currentPositions[0];
            if (type === DirectionTable.row) {
                this.data[position.row].forEach((cell) => {
                    if (cell.rowspan > 1 || cell.positionParent) {
                        check = false;
                    }
                });
            } else {
                for (let i in this.data) {
                    let cell = this.data[i][position.column];
                    if (cell.colspan > 1 || cell.positionParent) {
                        check = false;
                        break;
                    }
                }
            }
        }
        return check;
    }

    addCells(params: { type: DirectionTable; col?: number; row?: number }): {
        dHeight: number;
        dWidth: number;
    } {
        const { type, col, row } = params;
        let dWidth = 0,
            dHeight = 0;
        if (type === DirectionTable.row) {
            let rows = this.getRows();

            if (row || row === 0) {
                rows = [{ row: row - 1, cols: [] }];
            } else {
                let length = rows.length;
                if (length > 1) {
                    let row = rows[length - 1].row;
                    rows = [];
                    for (let i = 0; i < length; i++) {
                        rows.push({
                            row,
                            cols: [],
                        });
                    }
                }
            }
            // console.log("rows: ", rows);
            // console.log("current: ", this.currentPositions);
            rows.forEach((e) => {
                let row = e.row;
                if (row < 0) {
                    row = 0;
                }
                let cols = this.data[row].map((_, index) => {
                    return index;
                });
                let height = 0;
                cols.forEach((col) => {
                    let cell = this.data[row][col];
                    height = cell.height;
                    if (e.row >= 0) {
                        if (cell.rowspan > 1) {
                            this.data[row][col].rowspan += 1;
                        }
                        if (
                            cell.positionParent &&
                            row - cell.positionParent.row <
                                this.data[cell.positionParent.row][
                                    cell.positionParent.col
                                ].rowspan -
                                    1 &&
                            row !== cell.positionParent.row &&
                            col === cell.positionParent.col
                        ) {
                            this.data[cell.positionParent.row][
                                cell.positionParent.col
                            ].rowspan += 1;
                        }
                    }
                });
                let newRow = this.data[row].map((cell, col) => {
                    let positionParent: IPosition | undefined = undefined;
                    if (e.row >= 0) {
                        if (cell.rowspan > 1) {
                            positionParent = { col: col, row: row };
                        }
                        if (
                            cell.positionParent &&
                            row - cell.positionParent.row <
                                this.data[cell.positionParent.row][
                                    cell.positionParent.col
                                ].rowspan -
                                    1
                        ) {
                            positionParent = { ...cell.positionParent };
                        }
                    }
                    return new CellTable({
                        ...cell,
                        colspan: 1,
                        rowspan: 1,
                        textAttribute: null,
                        positionParent: positionParent,
                    });
                });
                this.data.splice(
                    e.row >= 0 ? rows[rows.length - 1].row + 1 : 0,
                    0,
                    newRow
                );
                if (row + 1 < this.data.length) {
                    let positions: { row: number; col: number }[] = [];
                    for (let i = row + 1; i < this.data.length; i++) {
                        this.data[i].forEach((cell, col) => {
                            if (cell.rowspan > 1 || cell.colspan > 1) {
                                positions.push({ row: i, col });
                            }
                        });
                    }
                    // console.log("add row", positions);
                    positions.forEach((p) => {
                        let cell = this.data[p.row][p.col];
                        for (
                            let row = p.row;
                            row < p.row + cell.rowspan;
                            row++
                        ) {
                            let cellChild = this.data[row][p.col];
                            if (cellChild.positionParent) {
                                cellChild.positionParent!.row += 1;
                            }
                            if (cell.colspan > 1) {
                                for (
                                    let col = p.col + 1;
                                    col < p.col + cell.colspan;
                                    col++
                                ) {
                                    let cellChild = this.data[row][col];
                                    if (cellChild.positionParent) {
                                        cellChild.positionParent!.row += 1;
                                    }
                                }
                            }
                        }
                    });
                }
                dHeight += height;
            });
        } else {
            let cols = this.getColumns();
            if (col || col === 0) {
                cols = [{ col: col - 1, rows: [] }];
            } else {
                let length = cols.length;
                if (length > 1) {
                    let col = cols[length - 1].col;
                    cols = [];
                    for (let i = 0; i < length; i++) {
                        cols.push({
                            col,
                            rows: [],
                        });
                    }
                }
            }
            cols.forEach((e) => {
                let col = e.col;
                if (col < 0) {
                    col = 0;
                }
                let rows = this.data.map((_, index) => {
                    return index;
                });
                let width = 0;
                rows.forEach((row) => {
                    let cell = this.data[row][col];
                    width = cell.width;
                    if (e.col >= 0) {
                        if (cell.colspan > 1) {
                            this.data[row][col].colspan += 1;
                        }
                        if (
                            cell.positionParent &&
                            col - cell.positionParent.col <
                                this.data[cell.positionParent.row][
                                    cell.positionParent.col
                                ].colspan -
                                    1 &&
                            col !== cell.positionParent.col &&
                            row === cell.positionParent.row
                        ) {
                            this.data[cell.positionParent.row][
                                cell.positionParent.col
                            ].colspan += 1;
                        }
                    }
                });
                this.data.forEach((_, row) => {
                    let cell = this.data[row][col];
                    let positionParent: IPosition | undefined = undefined;
                    if (e.col >= 0) {
                        if (cell.colspan > 1) {
                            positionParent = { col: col, row: row };
                        }
                        if (
                            cell.positionParent &&
                            col - cell.positionParent.col <
                                this.data[cell.positionParent.row][
                                    cell.positionParent.col
                                ].colspan -
                                    1
                        ) {
                            positionParent = { ...cell.positionParent };
                        }
                    }
                    let newCell = new CellTable({
                        ...cell,
                        colspan: 1,
                        rowspan: 1,
                        textAttribute: null,
                        positionParent,
                    });

                    this.data[row].splice(
                        e.col >= 0 ? cols[cols.length - 1].col + 1 : 0,
                        0,
                        newCell
                    );
                });
                let maxCol = this.data[0].length;
                if (col + 1 < maxCol) {
                    let positions: { row: number; col: number }[] = [];

                    this.data.forEach((cells, row) => {
                        for (let i = col + 1; i < maxCol; i++) {
                            let cell = cells[i];
                            if (cell.colspan > 1 || cell.rowspan > 1) {
                                positions.push({ row, col: i });
                            }
                        }
                    });
                    // console.log("add col", positions);
                    positions.forEach((p) => {
                        let cell = this.data[p.row][p.col];
                        for (
                            let col = p.col;
                            col < p.col + cell.colspan;
                            col++
                        ) {
                            let cellChild = this.data[p.row][col];
                            if (cellChild.positionParent) {
                                cellChild.positionParent!.col += 1;
                            }
                            if (cell.rowspan > 1) {
                                for (
                                    let row = p.row + 1;
                                    row < p.row + cell.rowspan;
                                    row++
                                ) {
                                    let cellChild = this.data[row][col];
                                    if (cellChild.positionParent) {
                                        cellChild.positionParent!.col += 1;
                                    }
                                }
                            }
                        }
                    });
                }
                dWidth += width;
            });
        }
        this.currentPositions = [];
        return { dWidth, dHeight };
    }

    getRows(isEmpty?: { isEmpty: boolean }): { row: number; cols: number[] }[] {
        let rows: {
            row: number;
            cols: number[];
        }[] = [];
        if (this.currentPositions)
            this.currentPositions.forEach((cell) => {
                let index = rows.findIndex((row) => row.row === cell.row);
                if (index >= 0) {
                    if (!rows[index].cols.includes(cell.column))
                        rows[index].cols.push(cell.column);
                } else {
                    rows.push({
                        row: cell.row,
                        cols: [cell.column],
                    });
                }
                if (cell.rowspan > 1) {
                    for (let i = 1; i < cell.rowspan; i++) {
                        index = rows.findIndex(
                            (row) => row.row === cell.row + i
                        );
                        if (index >= 0) {
                            if (!rows[index].cols.includes(cell.column))
                                rows[index].cols.push(cell.column);
                        } else {
                            rows.push({
                                row: cell.row + i,
                                cols: [cell.column],
                            });
                        }
                    }
                }
            });
        if (isEmpty && isEmpty.isEmpty === false) {
            if (!rows.length) {
                rows = this.data.map((cells, row) => {
                    return {
                        row,
                        cols: cells.map((_, col) => {
                            return col;
                        }),
                    };
                });
            }
        }
        rows = rows.sort((a, b) => a.row - b.row);
        return rows;
    }

    getColumns(): { col: number; rows: number[] }[] {
        let cols: {
            col: number;
            rows: number[];
        }[] = [];
        if (this.currentPositions.length)
            this.currentPositions.forEach((cell) => {
                let index = cols.findIndex((row) => row.col === cell.column);
                if (index >= 0) {
                    if (!cols[index].rows.includes(cell.row))
                        cols[index].rows.push(cell.row);
                } else {
                    cols.push({
                        col: cell.column,
                        rows: [cell.row],
                    });
                }
                if (cell.colspan > 1) {
                    for (let i = 1; i < cell.colspan; i++) {
                        index = cols.findIndex(
                            (row) => row.col === cell.column + i
                        );
                        if (index >= 0) {
                            if (!cols[index].rows.includes(cell.row))
                                cols[index].rows.push(cell.row);
                        } else {
                            cols.push({
                                col: cell.column + i,
                                rows: [cell.row],
                            });
                        }
                    }
                }
            });
        cols = cols.sort((a, b) => a.col - b.col);
        return cols;
    }

    getTableWidth(): number {
        if (this.data[0]) {
            return this.data[0].reduce((acc, cell) => acc + cell.width, 0);
        }
    }
}
