import { INotificationInfo } from "../../../shared/models/NotificationInfo";
import { IUserNotification } from "../../../shared/models/userNotification";

export enum NotificationTypes {
    GET_NUMBER_OF_NOTIFICATIONS = "GET_NUMBER_OF_NOTIFICATIONS",
    GET_NUMBER_OF_NOTIFICATIONS_SUCCESS = "GET_NUMBER_OF_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATION_BY_USER = "GET_NOTIFICATION_BY_USER",
    GET_NOTIFICATION_BY_USER_SUCCESS = "GET_NOTIFICATION_BY_USER_SUCCESS",
    SET_ALL_NOTIFICATION_READ = "SET_ALL_NOTIFICATION_READ",
    SET_NOTIFICATION_READ_DETAIL = "SET_NOTIFICATION_READ_DETAIL",
    CREATE_SUBMITED_NOTIFICATION = "CREATE_SUBMITED_NOTIFICATION",
    CREATE_SUBMITED_NOTIFICATION_SUCCESS = "CREATE_SUBMITED_NOTIFICATION_SUCCESS",
    CREATE_BONUS_DOWNLOAD_NOTIFICATION = "CREATE_BONUS_DOWNLOAD_NOTIFICATION",
    GET_NOTIFICATION_DETAIL = "GET_NOTIFICATION_DETAIL",
    GET_NOTIFICATION_DETAIL_SUCCESS = "GET_NOTIFICATION_DETAIL_SUCCESS",
}

export interface NotificationAction {
    type: NotificationTypes;
    id?: string;
    toUserId?: string;
    notificationInfos?: INotificationInfo[];
    nbUnreadMessages?: number;
    nbNotifications?: number;
    lastNotificationId?: string;
    limit?: number;
    name?: string;
    teacherEmail?: string;
    worksheetId?: string;
    notiType?: number;
    fromUserId?: string;
    notificationData?: IUserNotification;
    lastUpdated?: Date;
}

export const getNumberOfNotificationsAction = (
    toUserId: string
): NotificationAction => {
    return {
        type: NotificationTypes.GET_NUMBER_OF_NOTIFICATIONS,
        toUserId,
    };
};

export const getNumberOfNotificationsSuccessAction = (
    nbNotifications: number,
    nbUnreadMessages: number
): NotificationAction => {
    return {
        type: NotificationTypes.GET_NUMBER_OF_NOTIFICATIONS_SUCCESS,
        nbNotifications,
        nbUnreadMessages,
    };
};

export const getNotificationByUserAction = (
    toUserId: string,
    lastUpdated?: Date,
    limit: number = 11
): NotificationAction => {
    return {
        type: NotificationTypes.GET_NOTIFICATION_BY_USER,
        toUserId,
        lastUpdated,
        limit,
    };
};

export const getNotificationByUserSuccessAction = (
    notificationInfos,
    nbUnreadMessages = 0,
    nbNotifications = 0
): NotificationAction => {
    return {
        type: NotificationTypes.GET_NOTIFICATION_BY_USER_SUCCESS,
        notificationInfos,
        nbUnreadMessages,
        nbNotifications,
    };
};

export const setAllNotificationReadAction = (
    toUserId: string
): NotificationAction => {
    return { type: NotificationTypes.SET_ALL_NOTIFICATION_READ, toUserId };
};

export const setNotificationReadDetailAction = (
    id: string
): NotificationAction => {
    return { type: NotificationTypes.SET_NOTIFICATION_READ_DETAIL, id };
};

export const createSubmitedNotificationAction = (
    name: string,
    teacherEmail: string,
    worksheetId: string,
    notiType: number,
    fromUserId?: string
) => {
    return {
        type: NotificationTypes.CREATE_SUBMITED_NOTIFICATION,
        name,
        teacherEmail,
        worksheetId,
        notiType,
        fromUserId,
    };
};

export const createSubmitedNotificationSuccessAction = () => {
    return {
        type: NotificationTypes.CREATE_SUBMITED_NOTIFICATION_SUCCESS,
    };
};

export const createBonusDownloadNotificationAction = (toUserId: string) => {
    return {
        type: NotificationTypes.CREATE_BONUS_DOWNLOAD_NOTIFICATION,
        toUserId,
    };
};

export const getNotificationDetailAction = (id: string) => {
    return { type: NotificationTypes.GET_NOTIFICATION_DETAIL, id };
};

export const getNotificationDetailSuccessAction = (
    notificationData: IUserNotification
) => {
    return {
        type: NotificationTypes.GET_NOTIFICATION_DETAIL_SUCCESS,
        notificationData,
    };
};
