import { useDispatch } from "react-redux";
import Config from "../../../../../shared/utils/config";
import delIcon from "../../../../assets/images/icon/delete-icon.png";
import editIcon from "../../../../assets/images/icon/edit-icon.png";
import moveIcon from "../../../../assets/images/icon/move-icon.png";
import { selectActionGridItemAction } from "../../../../redux/action/resource.action";
import { getImageSource } from "../../../../utils";
import "./style.scss";

const PopupMoreActionTool = ({
    addGaEventMoreEvent,
}: {
    addGaEventMoreEvent?: Function;
}) => {
    const dispatch = useDispatch();
    const clickActionFc = ({ action }: { action }) => {
        addGaEventMoreEvent &&
            addGaEventMoreEvent({
                action,
            });
        dispatch(
            selectActionGridItemAction({
                action,
            })
        );
    };
    return (
        <>
            <div className="popup-action-project">
                <div
                    className="popup-action-project-item edit"
                    onClick={() => {
                        clickActionFc({ action: Config.ACTION_GRID.EDIT });
                    }}
                >
                    <img src={getImageSource(editIcon)} alt="edit icon" />
                    <p className="name">Edit</p>
                </div>
                <div
                    className="popup-action-project-item del"
                    onClick={() => {
                        clickActionFc({
                            action: Config.ACTION_GRID.DELETE_COLLECTION,
                        });
                    }}
                >
                    <img src={getImageSource(delIcon)} alt="del icon" />
                    <p className="name">Delete</p>
                </div>
                <div
                    className="popup-action-project-item move"
                    onClick={() => {
                        clickActionFc({
                            action: Config.ACTION_GRID.MOVE_COLLECTION,
                        });
                    }}
                >
                    <img src={getImageSource(moveIcon)} alt="move icon" />
                    <p className="name">Move to</p>
                </div>
            </div>
        </>
    );
};

export default PopupMoreActionTool;
