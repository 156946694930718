import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { calculateTimeRemaining } from "../../../../utils";
import "./style.scss";
const CountdownTimer = ({
    setShowButtonClaim,
}: {
    setShowButtonClaim: Function;
}) => {
    const timeNextDownload = useSelector(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.timeNextDownload
    );

    const [timeRemaining, setTimeRemaining] = useState(
        calculateTimeRemaining(timeNextDownload)
    );

    const [isCountDown, setIsCountDown] = useState(false);

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSecond] = useState(0);

    useEffect(() => {
        setMinutes(Math.floor(timeRemaining / 60));
        setSecond(timeRemaining % 60);

        if (isCountDown && timeRemaining === 0) {
            setShowButtonClaim(true);
        }
    }, [timeRemaining]);

    if (!isCountDown) {
        if (timeRemaining > 0) {
            setIsCountDown(true);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(timeNextDownload));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timeNextDownload]);

    return (
        <div className="time">{`${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`}</div>
    );
};

export default CountdownTimer;
