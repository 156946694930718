import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IWorksheet,
    Worksheet,
} from "../../../../../../shared/models/worksheet";
import Config from "../../../../../../shared/utils/config";
import iconWarning from "../../../../../assets/images/icon/warning-icon.svg";
import { addEventGa } from "../../../../../lib/ga";
import { updateUserDownloadedWorksheetAction } from "../../../../../redux/action/game.action";
import { addActionEventDownloadAction } from "../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import { getImageSource, groupWsFc } from "../../../../../utils";
import { isClaimExist } from "../../../../../utils/tracking";
import MainPrint from "../../../../print-file";
import LoadingComponent from "../../../loading/loading-component";
import "./style.scss";

const ButtonDownload = ({
    worksheet,
    worksheets,
    onlyDownload,
    downloadWithAnswer,
    notResetWorksheets,
}: {
    worksheet?: IWorksheet;
    worksheets?: any[];
    onlyDownload?: boolean;
    downloadWithAnswer: boolean;
    notResetWorksheets?: boolean;
}) => {
    const STATUS_OPEN_NOTI = {
        non: -1,
        close: 0,
        open: 1,
    };
    const dispatch = useDispatch();
    const [print, setPrint] = useState(false);
    const [showNoti, setShowNoti] = useState(STATUS_OPEN_NOTI.non);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const quotaDownload = useSelector(
        (state: ResourceAppState) =>
            state.quotaResourceUserState?.data?.quotaDownload
    );
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state?.resourceState?.actionGridItem
    );
    const actionGrid = actionGridItem?.action;

    let groupWs = groupWsFc({ worksheets: worksheets });

    let numberWorksheet = worksheets?.length
        ? Object.keys(groupWs).length
        : worksheet?.id
        ? 1
        : 0;
    const ableDownload = quotaDownload >= numberWorksheet;

    let worksheetDownload = new Worksheet();
    if (worksheets?.length) {
        worksheetDownload = new Worksheet({
            title:
                worksheets?.length == 1
                    ? worksheets[0].title
                    : "Worksheetzone Collection",
        });
        worksheets?.forEach((item) => {
            if (item?.game?.images?.length) {
                // images.push(...item?.game?.images);
                worksheetDownload.game.images = [
                    ...worksheetDownload.game.images,
                    ...item?.game?.images,
                ];
            }
        });
    } else if (worksheet?.id) {
        worksheetDownload = new Worksheet({
            ...worksheet,
        });
        worksheetDownload.game.images = worksheet?.game?.images;
    }

    useEffect(() => {
        if (showNoti == STATUS_OPEN_NOTI.open) {
            let timeout = setTimeout(() => {
                setShowNoti(STATUS_OPEN_NOTI.close);
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [showNoti]);

    useEffect(() => {
        if (ableDownload && showNoti == STATUS_OPEN_NOTI.open) {
            setShowNoti(STATUS_OPEN_NOTI.close);
        }
    }, [ableDownload]);

    return (
        <>
            <div className="claim-download">
                <div
                    className="btn-save-to-device"
                    onClick={() => {
                        if (!loading) {
                            const isMultipleDownload = !!worksheets?.length;
                            const payloadGA = {
                                worksheetId: isMultipleDownload
                                    ? worksheets?.map((ws) => ws?.id) ?? []
                                    : [worksheet?.id],
                            };
                            addEventGa({
                                action: isMultipleDownload
                                    ? "save_dl"
                                    : "save_dl_2",
                                params: payloadGA,
                            });
                            worksheets?.length &&
                                dispatch(
                                    addActionEventDownloadAction({
                                        haveActionPopup: true,
                                    })
                                );
                            // LocalStorage.setUserHaveActionClaimDownload();

                            setDone(false);
                            if (ableDownload) {
                                setPrint(true);
                                setLoading(true);
                                addEventGa({
                                    action: isMultipleDownload
                                        ? "save_dl_success"
                                        : "save_dl_2_success",
                                    params: payloadGA,
                                });
                            } else {
                                setShowNoti(
                                    STATUS_OPEN_NOTI.open == showNoti
                                        ? STATUS_OPEN_NOTI.close
                                        : STATUS_OPEN_NOTI.open
                                );

                                addEventGa({
                                    action: isMultipleDownload
                                        ? "save_dl_out_coin"
                                        : "save_dl_2_out_coin_2",
                                    params: payloadGA,
                                });
                                const claimExist = isClaimExist();
                                addEventGa({
                                    action: isMultipleDownload
                                        ? claimExist
                                            ? "save_dl_out_coin_claim_available"
                                            : "save_dl_out_coin_no_claim"
                                        : claimExist
                                        ? "save_dl_2_out_coin_claim_available"
                                        : "save_dl_2_out_coin_no_claim",
                                    params: payloadGA,
                                });
                            }
                        }
                    }}
                >
                    <div className="btn-save-to-device-text">
                        {done ? <></> : loading ? <LoadingComponent /> : <></>}
                        <p>
                            {actionGrid == Config.ACTION_GRID.PRINT_WSS
                                ? done
                                    ? "Done"
                                    : "Print All"
                                : done
                                ? "Saved to Your Device "
                                : "Save to Your Device "}
                        </p>
                    </div>
                </div>

                {STATUS_OPEN_NOTI.non != showNoti ? (
                    <>
                        <div
                            className={
                                "notification-out-of-dl " +
                                (!showNoti ? "hidden" : "active")
                            }
                        >
                            <img
                                src={getImageSource(iconWarning)}
                                alt="warning-icon"
                                width={16}
                                height={16}
                            />
                            <p>Out of downloads!</p>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {print && (
                    <MainPrint
                        notResetWorksheets={notResetWorksheets}
                        worksheet={worksheetDownload}
                        onlyDownload={onlyDownload}
                        printWithKey={downloadWithAnswer}
                        setLoading={(value) => {
                            if (!value) {
                                setLoading(value);
                            }
                        }}
                        setOpenModal={setPrint}
                        numberWorksheet={numberWorksheet ?? 0}
                        otherAction={() => {
                            let worksheetIds = worksheets?.length
                                ? worksheets?.map((item) => item.id)
                                : [worksheet?.id];
                            dispatch(
                                updateUserDownloadedWorksheetAction(
                                    userInfo.token,
                                    worksheetIds
                                )
                            );
                            setDone(true);
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default ButtonDownload;
