import shape from "../data/shape.json";
import line from "../data/line.json";
import table from "../data/table.json";
import {
    ResourceItemNew,
    TextAttribute,
} from "../shared/models/resourceItemNew";
import {
    IShapeAttribute,
    InputShape,
    ShapeAttribute,
} from "../shared/models/shapeAttribute";
import ConstantsTool from "../shared/utils/ConstantsTool";
import {
    CellTable,
    ColorCellTbale,
    TableAttribute,
} from "../shared/models/tableAttribute";
import { getPrefixImage } from ".";
const PATH_SQUARE_ROUND =
    "M 39.2533 0 L 216.747 0 C 227.157 0 237.142 4.13561 244.503 11.497 C 251.864 18.8585 256 28.8427 256 39.2533 L 256 216.747 C 256 227.157 251.864 237.142 244.503 244.503 C 237.142 251.864 227.157 256 216.747 256 L 39.2533 256 C 28.8427 256 18.8585 251.864 11.497 244.503 C 4.13561 237.142 0 227.157 0 216.747 L 0 39.2533 C 0 28.8427 4.13561 18.8585 11.497 11.497 C 18.8585 4.13561 28.8427 0 39.2533 0";
export const getDataShape = (
    color?: string,
    strokeWidth?: number
): IShapeAttribute[] => {
    const colorShape = color ?? "#E5E7EB";
    let arr: IShapeAttribute[] = [];
    shape.shapes.forEach((shape: any, index) => {
        let obj = createShapeAttribute(shape, strokeWidth, colorShape);
        arr.push(obj);
    });
    return arr;
};

export const createShapeAttribute = (shape: any, strokeWidth: number, colorShape: string) => {
    let name = shape.name,
        width = shape.width,
        height = shape.height,
        path = shape.path,
        input = shape.input,
        strokeWidthShape = strokeWidth ?? 4,
        radius = name === "Rounded Square" ? 12 : 0;
    let url = shape.url;
        // name === "Rounded Square"
        //     ? makeImageFromPath(colorShape, PATH_SQUARE_ROUND, 256, 256)
        //     : makeImageFromPath(colorShape, path, width, height);
    let obj = new ShapeAttribute({
        name,
        url,
        width,
        height,
        path,
        input,
        radius,
        strokeWidth: strokeWidthShape,
    });
    return obj
}

export const getResourceItemShape = (
    shape: ShapeAttribute,
    type?: number
): ResourceItemNew => {
    let textAttribute = new TextAttribute({
        fontSize: 16,
        fontFamily: "Nunito",
        align: "center",
    });
    if (!type) {
        type = ConstantsTool.TYPE_RESOURCE_SHAPE;
    }
    return new ResourceItemNew({
        x: 0.0,
        y: 0.0,
        width: shape.width,
        height: shape.height,
        type: type,
        id: Math.random().toString(18).slice(2),
        activityId: "",
        idType: "",
        pageIndex: 0,
        wordBanks: [],
        isShow: true,
        url: shape.url,
        shapeAttribute: shape,
        textAttribute: textAttribute,
        opacity: 1,
    });
};

const makeImageFromPath = (
    color: string,
    path: string,
    width: number,
    height: number
) => {
    return `data:image/svg+xml,${encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"  viewBox="0 0 ${width} ${height}"
      >
        <path
            d="${path}"
            fill="${color}"
        />
      </svg>`
    )}`;
};

export const getDataLine = (): any[] => {
    let list: ShapeAttribute[] = [];
    line.lines.forEach((element) => {
        list.push(
            new ShapeAttribute({
                name: element.id,
                url: element.url,
                width: element.width,
                height: element.strokeWidth,
                path: element.path,
                radius: 0,
                input: new InputShape(),
                strokeWidth: element.strokeWidth,
                strokeDasharray: element.strokeDasharray,
                pathStart: element.pathStart,
                pathEnd: element.pathEnd,
            })
        );
    });
    return list;
};

export const getResourceItemLine = (
    line: ShapeAttribute,
    type?: number
): ResourceItemNew => {
    let textAttribute = new TextAttribute({
        fontSize: 16,
        fontFamily: "Nunito",
        align: "center",
    });
    if (!type) {
        type = ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE;
    }
    return new ResourceItemNew({
        id: Math.random().toString(18).slice(2),
        x: 0.0,
        y: 0.0,
        width: line.width,
        height: line.strokeWidth,
        type: type,
        activityId: "",
        idType: "",
        pageIndex: 0,
        rotate: 0,
        wordBanks: [],
        isShow: true,
        url: line.url,
        textAttribute: textAttribute,
        shapeAttribute: line,
        opacity: 1,
        moduleId: "",
    });
};

export const getDataTable = () => {
    const width = 540,
        height = 360,
        src = getPrefixImage(),
        rows = 4,
        cols = 3;

    return table.tables.map((element, index) => {
        return new ResourceItemNew({
            id: Math.random().toString(18).slice(2),
            type: ConstantsTool.TYPE_RESOURCE_TABLE,
            width: width,
            height: height,
            url: src + "/images/table-images/table" + index + ".jpg",
            tableAttribute: new TableAttribute({
                gap: element.gap,
                borderWidth: element.borderWidth,
                data: element.data.map((item) => {
                    return item.map((el) => {
                        return new CellTable({
                            rowspan: el.rowspan,
                            colspan: el.colspan,
                            width:
                                width / cols +
                                element.gap / cols,
                                // +
                                // 2 * element.borderWidth / (cols - 1),
                            height:
                                height / rows +
                                element.gap / rows,
                                // +
                                // (2 * element.borderWidth) / (rows - 1),
                            colorCell: new ColorCellTbale(el.colorCell),
                            positionParent: el.positionParent,
                        });
                    });
                }),
            }),
        });
    });
};
