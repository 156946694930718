export class ConstantsNameTracing {
    static PREFIX = "nt_";
    static PLACEHOLDER = this.PREFIX + "Harry Potter";

    static PORTRAIT_CONFIG = {
        LINE_SPACING: 9,
        NUMBER_OF_LINES: 7,
    };

    static LANDSCAPE_CONFIG = {
        LINE_SPACING: 7,
        NUMBER_OF_LINES: 5,
    };
}
