import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPuzzle, Puzzle } from "../../../../../shared/models/puzzle";
import { getPrefixByProjectName } from "../../../../../shared/utils";
import {
    changePuzzleConfigAction,
    resetSvgAction,
} from "../../../../redux/action/wordsearch.action";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import DropdownTool from "../../../icons/drop-down-tool/drop-down-tool";
import PullUpTool from "../../../icons/drop-down-tool/pull-up-tool";
import Row1 from "../../../icons/word-search-directions/row-1";
import Row2 from "../../../icons/word-search-directions/row-2";
import Row3 from "../../../icons/word-search-directions/row-3";
import Row4 from "../../../icons/word-search-directions/row-4";
import "./index.scss";

export const dataDirections = [
    {
        id: 0,
        row: <Row1></Row1>,
    },
    {
        id: 1,
        row: <Row2></Row2>,
    },
    {
        id: 2,
        row: <Row3></Row3>,
    },
    {
        id: 3,
        row: <Row4></Row4>,
    },
];
const WordDirections = () => {
    const refInput = useRef(null);
    const dispatch = useDispatch();
    const puzzleRedux: IPuzzle = useSelector(
        (state: any) => state.wordSearchState.puzzle
    );
    const projectName = useSelector(
        (state: ResourceAppState) => state.wordSearchState.projectName
    );
    const [open, setOpen] = useState(false);

    const handleFocus = () => {
        setOpen(true);
    };

    const handleBlur = () => {
        setOpen(false);
    };

    const MyCustomIcon = () => {
        if (open) {
            return (
                <IconButton>
                    <PullUpTool />
                </IconButton>
            );
        } else {
            return (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        refInput.current.focus();
                    }}
                >
                    <DropdownTool />
                </IconButton>
            );
        }
    };

    return (
        <div className="word-directions">
            <div className="title">Word Directions</div>
            <FormControl className={"form-input"}>
                <Select
                    inputRef={refInput}
                    open={open}
                    onOpen={handleFocus}
                    onClose={handleBlur}
                    IconComponent={MyCustomIcon}
                    value={puzzleRedux.wordDirections}
                    onChange={(e) => {
                        let puzzle: IPuzzle = new Puzzle(puzzleRedux);
                        puzzle.wordDirections = e.target.value as number;
                        let prefix = getPrefixByProjectName(
                            projectName,
                            "wse_"
                        );
                        dispatch(changePuzzleConfigAction(puzzle));
                        dispatch(
                            resetSvgAction({
                                resetSvg: true,
                            })
                        );
                    }}
                    // defaultChecked={puzzleRedux.wordDirections}
                >
                    {dataDirections.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                            {data.row}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default WordDirections;
