export const HappyIconSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="url(#paint0_linear_22608_33890)"
            />
            <path
                d="M6.14759 7.27122C5.93474 7.27122 5.76118 7.09766 5.76118 6.88481V6.65886C5.76118 6.26917 5.44353 5.95153 5.05385 5.95153C4.64124 5.95153 4.3236 6.26917 4.3236 6.65886V6.88481C4.3236 7.09766 4.15005 7.27122 3.93719 7.27122C3.72434 7.27122 3.55078 7.09766 3.55078 6.88481V6.65886C3.55078 5.84183 4.21554 5.17871 5.03093 5.17871C5.86924 5.17871 6.534 5.84347 6.534 6.65886V6.88481C6.534 7.0993 6.36044 7.27122 6.14759 7.27122Z"
                fill="#28434F"
            />
            <path
                d="M12.6144 7.27122C12.4015 7.27122 12.228 7.09766 12.228 6.88481V6.65886C12.228 6.26917 11.9103 5.95153 11.5206 5.95153C11.108 5.95153 10.7904 6.26917 10.7904 6.65886V6.88481C10.7904 7.09766 10.6168 7.27122 10.404 7.27122C10.1911 7.27122 10.0176 7.09766 10.0176 6.88481V6.65886C10.0176 5.84183 10.6823 5.17871 11.4977 5.17871C12.336 5.17871 13.0008 5.84347 13.0008 6.65886V6.88481C13.0008 7.0993 12.8272 7.27122 12.6144 7.27122Z"
                fill="#28434F"
            />
            <path
                d="M4.46981 10.7921C4.63427 10.7921 4.79158 10.8833 4.85951 11.0325C4.89347 11.1055 5.65856 12.7075 8.09506 12.7075C10.5351 12.7075 11.2984 11.1005 11.3306 11.0326C11.4218 10.8335 11.6685 10.739 11.8812 10.8236C12.0957 10.9065 12.1976 11.1337 12.1082 11.3327C12.0689 11.4206 11.1161 13.4902 8.09327 13.4902C5.07045 13.4902 4.11765 11.4206 4.07833 11.3327C3.98895 11.1337 4.09263 10.9049 4.30714 10.8219C4.36256 10.802 4.41618 10.7921 4.46981 10.7921Z"
                fill="#28434F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_22608_33890"
                    x1="2.85988"
                    y1="1.87555"
                    x2="13.1393"
                    y2="14.1261"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2C536" />
                    <stop offset="0.1079" stopColor="#F5C937" />
                    <stop offset="0.4959" stopColor="#FDD338" />
                    <stop offset="1" stopColor="#FFD639" />
                </linearGradient>
            </defs>
        </svg>
    );
};
