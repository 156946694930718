import { Button, Checkbox } from "@mui/material";
import {
    changeShowAnswerKey,
    changeShowTheWordBank,
    scramble,
} from "../../../../redux/async/wordScrambleThunk";
import { useAppDispatch } from "../../../../redux/hook";
import IconChecked3 from "../../../../resource-user/components/icons/check-box-icon/checked-icon";
import IconUnChecked from "../../../../resource-user/components/icons/check-box-icon/un-checked";
import { IActivity } from "../../../../shared/models/WorkSheetCreator";
import Config from "../../../../shared/utils/config";
import "./index.scss";
import { event } from "../util";

const OtherSection = ({ activity }: { activity: IActivity }) => {
    const dispatch = useAppDispatch();
    const type = activity.type;
    const isWordScramble = type === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE;

    return (
        <div className="word-scramble-other-section-container">
            {isWordScramble && (
                <CheckBox
                    title="Show The Word Bank"
                    value={activity.showWordBank === Config.SHOW_VALUE}
                    handleChange={(value) => {
                        dispatch(changeShowTheWordBank({ value }));
                        event(type, `${value ? "" : "not_"}show_the_word_bank`);
                    }}
                />
            )}
            <CheckBox
                title="Show The Answer Key"
                value={activity.showAnswerKey === Config.SHOW_VALUE}
                handleChange={(value) => {
                    dispatch(changeShowAnswerKey({ value }));
                    event(type, `${value ? "" : "not_"}show_the_answer_key`);
                }}
            />
            <Button
                variant="contained"
                className="scramble-button"
                onClick={() => {
                    dispatch(scramble());
                    event(type, "scramble");
                }}
            >
                Scramble
            </Button>
        </div>
    );
};

const CheckBox = ({
    title,
    value,
    handleChange,
}: {
    title: string;
    value: boolean;
    handleChange: Function;
}) => {
    return (
        <div className={`check-box-container ws-container`}>
            <div className="title">{title}</div>
            <Checkbox
                icon={<IconUnChecked />}
                checkedIcon={<IconChecked3 />}
                onChange={(e) => {
                    const checked = e.target.checked;
                    handleChange(checked);
                }}
                checked={value}
                size="small"
                sx={{
                    "&.Mui-checked": {
                        color: "#3B82F6",
                    },
                    padding: 0,
                }}
            />
        </div>
    );
};

export default OtherSection;
