import * as React from 'react';
import Switch from '@mui/material/Switch';
import { IResourceItemNew } from '../../../../../../../shared/models/resourceItemNew';

export default function ControlledAutoFontSize({
    currentElement,
    setCurrentElement,
    handleControlledAutoFontSize
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (currentElement: IResourceItemNew) => void;
    handleControlledAutoFontSize: (value: boolean) => void;
}) {
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleControlledAutoFontSize(event.target.checked);
    };

    return (
        <>
            <Switch
                checked={currentElement?.textAttribute?.isAppliedAutoFontSize}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </>
    );
};
