import { createAsyncThunk } from "@reduxjs/toolkit";
import { listAnswerKey } from "../../components/ModulesContent/multiple-choice/util";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../shared/models/questionActivity";
import { ResourceItemNew } from "../../shared/models/resourceItemNew";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import {
    changeAnswerDimension,
    changeAnswerPosition,
    findQuestionPageIndex,
    getContent,
    getHeighestItem,
    getLastYPosition,
    getListAnswerHeight,
    getNumber,
    updateAnswersContent,
    updateOtherItemPositionMC,
} from "../../utils/multipleChoice";
import {
    filterResourceId,
    findSuitableY,
    updateContent,
} from "../../utils/multipleChoice";
import { getTextWidth, getTextWidthHeightTool } from "../../utils/draw";
import {
    setCurrentActivity,
    updateActivities,
    updatePageWSS,
} from "../reducers/createWorksheet";
import { RootState } from "../store";

const getQuestionAndAnswerIds = (questions) => {
    let Ids = [];
    questions?.forEach((question) => {
        let listIds = {
            questionId: question.id,
            answerIds: [],
        };
        question.answers.forEach((answer) => {
            listIds.answerIds.push(answer.id);
        });
        Ids.push(listIds);
    });
    return Ids;
};

const genNewResource = ({
    id,
    activityId,
    pageIndex,
    width,
    content,
    x,
    y,
}) => {
    return new ResourceItemNew({
        id: id,
        idType: id,
        activityId: activityId,
        pageIndex: pageIndex,
        type: ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES,
        width: width,
        height: ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
        textAttribute: {
            content: content,
            fontFamily: "Nunito",
            fontSize: 24,
            align: "left",
        },
        x: x,
        y: y,
    });
};

const updateActivitiesAndPageWSS = (
    dispatch,
    currentActivity,
    questions,
    activities,
    pageWSS
) => {
    const newActivity = {
        ...currentActivity,
        questions: questions,
    };
    const activitiIndex = activities.findIndex(
        (activity) => activity.id === currentActivity.id
    );
    activities[activitiIndex] = currentActivity;
    dispatch(updateActivities({ activities: activities }));
    dispatch(
        setCurrentActivity({
            activity: newActivity,
        })
    );
    dispatch(updatePageWSS({ pageWSS: pageWSS }));
};

export const addQuestionMC = createAsyncThunk(
    "multipleChoice/addQuestionMC",
    async (
        params: {
            question: IQuestionActivity;
            questionIndex: number;
        },
        { dispatch, getState }
    ) => {
        try {
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const { question, questionIndex } = params;
            let typeAdd = "last";
            let pageIndex = pageWSS.length - 1;
            const questions = currentActivity.questions;
            const prevQues = questions[questionIndex - 1];
            const nextQues = questions[questionIndex];
            let currentPageResource = pageWSS[pageIndex]?.resourceItems;
            let lastYPosition = 0;

            const listQuestionIds = currentActivity.questions.map(
                (question) => question.id
            );
            let listAnswerIdPrevQues = [];
            if (prevQues) {
                pageIndex = findQuestionPageIndex(pageWSS, prevQues);
                listAnswerIdPrevQues = prevQues.answers.map((ele) => ele.id);
                currentPageResource = pageWSS[pageIndex]?.resourceItems;
            }
            const nextQuesResourceIndex = currentPageResource.findIndex(
                (resource) => resource.idType == nextQues?.id
            );
            if (listAnswerIdPrevQues?.length) {
                let listResource = currentPageResource.filter((resource) =>
                    listAnswerIdPrevQues.includes(resource?.id)
                );
                lastYPosition = getLastYPosition({
                    listItem: listResource,
                    questionAndAnswerIds: [],
                });
            }
            const questionResource = genNewResource({
                id: question.id,
                activityId: currentActivity.id,
                pageIndex: pageIndex,
                width: ConstantsTool.MULTIPLE_CHOICES_WIDTH,
                content: `${questionIndex + 1}. ${question.question}`,
                x: ConstantsTool.MULTIPLE_CHOICES_X,
                y: lastYPosition
                    ? lastYPosition + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES
                    : ConstantsTool.MULTIPLE_CHOICES_FIRST_PAGE_Y,
            });
            if (nextQues) {
                typeAdd = "middle";
                currentPageResource.splice(
                    nextQuesResourceIndex,
                    0,
                    questionResource
                );
            } else {
                currentPageResource.push(questionResource);
            }
            question.multipleChoicesAttribute.resourceItemIds = [
                ...question.multipleChoicesAttribute.resourceItemIds,
                question.id,
            ];

            for (let i = 0; i < 4; i++) {
                const answerX =
                    ConstantsTool.MULTIPLE_CHOICES_X +
                    (i * ConstantsTool.MULTIPLE_CHOICES_WIDTH) / 4;
                const answerY = lastYPosition
                    ? lastYPosition +
                      ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 3
                    : ConstantsTool.MULTIPLE_CHOICES_FIRST_PAGE_Y +
                      ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 2;
                const answerResource = genNewResource({
                    id: question.answers[i].id,
                    activityId: currentActivity.id,
                    pageIndex: pageIndex,
                    width: ConstantsTool.DEFAULT_ANSWER_WIDTH,
                    content: `${listAnswerKey()[i]}. ${
                        question.answers[i].text
                    }`,
                    x: answerX,
                    y: answerY,
                });
                if (nextQues) {
                    currentPageResource.splice(
                        nextQuesResourceIndex + i + 1,
                        0,
                        answerResource
                    );
                } else {
                    currentPageResource.push(answerResource);
                }
                question.multipleChoicesAttribute.resourceItemIds.push(
                    question.answers[i].id
                );
            }
            if (typeAdd === "last") {
                questions.push(question);
            } else {
                pageWSS.forEach((pageWs, index) => {
                    pageWs.resourceItems.forEach((resource, resourceIndex) => {
                        if (listQuestionIds.includes(resource.id)) {
                            if (
                                (index == pageIndex &&
                                    resourceIndex > nextQuesResourceIndex) ||
                                index > pageIndex
                            ) {
                                pageWs.resourceItems[resourceIndex] =
                                    updateContent({
                                        item: pageWs.resourceItems[
                                            resourceIndex
                                        ],
                                        isUp: true,
                                        isResource: true,
                                    });
                            }
                        }
                    });
                });
            }

            updateOtherItemPositionMC({
                listResourceItem: currentPageResource,
                currentElement: questionResource,
                pageIndex: pageIndex,
                listAnswerIds: [],
                numberCol: 4,
                yChange: ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 4,
                questionAndAnswerIds: getQuestionAndAnswerIds(questions),
                pagesWorksheet: pageWSS,
                activity: currentActivity,
                type: "question",
                action: "add " + typeAdd,
            });
            if (typeAdd === "middle") {
                questions.splice(questionIndex, 0, question);
                questions.forEach((question, index) => {
                    let questionContent = getContent(question.question);
                    let newContent = `${index + 1}. ${questionContent}`;
                    question.question = newContent;
                });
            }

            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("addQuestionMC: " + err);
        }
    }
);

export const deleteQuestionMC = createAsyncThunk(
    "multipleChoice/deleteQuestionMC",
    async (
        params: {
            question: IQuestionActivity;
        },
        { dispatch, getState }
    ) => {
        try {
            const { question } = params;
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const questions = currentActivity.questions;
            const questionIndex = questions.findIndex(
                (ele) => ele.id == question.id
            );
            const nextQuestion = questions[questionIndex + 1];
            let newQuestions = questions.filter(
                (ele) => ele.id != question.id
            );
            const nextQuestionIndex = newQuestions.findIndex(
                (ele) => ele.id === nextQuestion?.id
            );
            const numberCol = question.multipleChoicesAttribute.numberCol;
            let questionResource;
            let currentPageResource;
            let pageIndex;

            for (let i = 0; i < pageWSS.length; i++) {
                questionResource = pageWSS[i].resourceItems.find(
                    (resource) => resource.id === question.id
                );
                if (questionResource) {
                    pageIndex = questionResource.pageIndex;
                    currentPageResource = pageWSS[pageIndex].resourceItems;
                    break;
                }
            }
            if (nextQuestionIndex != -1) {
                for (let i = nextQuestionIndex; i < newQuestions.length; i++) {
                    const num = getNumber(newQuestions[i]?.question);
                    const content = getContent(newQuestions[i]?.question);
                    newQuestions[i] = {
                        ...newQuestions[i],
                        ...{ question: `${num - 1}. ${content}` },
                    };
                }
            }
            const listAnswerIds = questions[questionIndex]?.answers.map(
                (ele) => ele.id
            );
            let listIdsDelete = [question.id];
            listAnswerIds.forEach((id) => {
                listIdsDelete.push(id);
            });
            //Change in resource items
            const listAnswerDelete = filterResourceId(
                currentPageResource,
                listAnswerIds
            );
            let questionContainerHeight =
                questionResource?.height +
                ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * 2 +
                getListAnswerHeight({
                    listAnswer: listAnswerDelete,
                    numberCol: numberCol,
                });
            const listQuestionIdsActivity = questions.map(
                (question) => question.id
            );
            const questionResourceIndex = currentPageResource.findIndex(
                (ele) => ele?.id == question.id
            );
            pageWSS.forEach((pageWS, page) => {
                if (page >= pageIndex) {
                    pageWS.resourceItems.forEach((resource, index) => {
                        if (
                            (page == pageIndex
                                ? index > questionResourceIndex
                                : true) &&
                            listQuestionIdsActivity.includes(resource.id)
                        ) {
                            pageWSS[page].resourceItems[index] = updateContent({
                                item: pageWSS[page].resourceItems[index],
                                isUp: false,
                                isResource: true,
                            });
                        }
                    });
                }
            });
            updateOtherItemPositionMC({
                listResourceItem: currentPageResource,
                currentElement: questionResource,
                pageIndex: pageIndex,
                listAnswerIds: [],
                numberCol: numberCol,
                yChange: -questionContainerHeight,
                questionAndAnswerIds: getQuestionAndAnswerIds(questions),
                pagesWorksheet: pageWSS,
                activity: currentActivity,
                type: "question",
                action: "delete",
            });
            let newResources = currentPageResource.filter(
                (ele) => !listIdsDelete.includes(ele?.id)
            );
            pageWSS[pageIndex].resourceItems = newResources;
            pageWSS.forEach((pageWS, index) => {
                let isAvailQuestionAtPage = filterResourceId(
                    pageWS.resourceItems,
                    listQuestionIdsActivity
                );
                if (!isAvailQuestionAtPage[0] && index != 0) {
                    pageWSS.splice(index, 1);
                }
            });

            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                newQuestions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("deleteQuestionMC: ", err);
        }
    }
);

export const updateQuestionMC = createAsyncThunk(
    "multipleChoice/updateQuestionMC",
    async (
        params: {
            question: IQuestionActivity;
            content: String;
        },
        { dispatch, getState }
    ) => {
        try {
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const { question, content } = params;
            const numberCol = question.multipleChoicesAttribute.numberCol;
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const questions = currentActivity.questions;
            let pageIndex = findQuestionPageIndex(pageWSS, question);
            const currentPageResource = pageWSS[pageIndex]?.resourceItems;

            let questionActivityIndex = questions.findIndex(
                (ele) => ele.id == question.id
            );
            const contentWidth = getTextWidth({
                text: `${questionActivityIndex + 1}. ${content}`,
                font: "normal 24px Nunito",
            });
            const numberLine = Math.ceil(
                contentWidth / ConstantsTool.MULTIPLE_CHOICES_WIDTH
            );
            const listAnswerIds = question.answers.map((answer) => answer.id);
            const listQuestionAndAnswerId = getQuestionAndAnswerIds(questions);
            let resourceIndex = currentPageResource.findIndex(
                (ele) => ele?.id == question.id
            );
            let currentResource = currentPageResource[resourceIndex];
            let currentNumberLine =
                currentResource?.height /
                ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
            currentPageResource[resourceIndex] = {
                ...currentResource,
                pageIndex: pageIndex,
                height: numberLine * ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
                textAttribute: {
                    ...currentResource?.textAttribute,
                    content: `${questionActivityIndex + 1}. ${content}`,
                },
            };
            if (numberLine != currentNumberLine) {
                updateOtherItemPositionMC({
                    listResourceItem: currentPageResource,
                    currentElement: currentResource,
                    pageIndex: pageIndex,
                    listAnswerIds: listAnswerIds,
                    numberCol: numberCol,
                    yChange:
                        (numberLine - currentNumberLine) *
                        ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES,
                    questionAndAnswerIds: listQuestionAndAnswerId,
                    pagesWorksheet: pageWSS,
                    activity: currentActivity,
                    type: "question",
                    action: "update",
                });
            }

            questions[questionActivityIndex].question = `${
                questionActivityIndex + 1
            }. ${content}`;
            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("updateQuestionMC: ", err);
        }
    }
);

export const addAnswerMC = createAsyncThunk(
    "multipleChoice/addAnswerMC",
    async (
        params: {
            question: IQuestionActivity;
            answer: IAnswerActivity;
        },
        { dispatch, getState }
    ) => {
        try {
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const { question, answer } = params;
            const numberCol = question.multipleChoicesAttribute.numberCol;
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const questions = currentActivity.questions;
            let pageIndex = findQuestionPageIndex(pageWSS, question);
            const currentPageResource = pageWSS[pageIndex];

            const listAnswerIds = question.answers.map((ele) => ele.id);
            const questionResource = currentPageResource.find(
                (ele) => ele?.id == question.id
            );
            const startY = findSuitableY({
                listResource: currentPageResource,
                numberCol: numberCol,
                listAnswerIds: listAnswerIds,
                question: question,
            });
            const answerResource = genNewResource({
                id: answer.id,
                activityId: currentActivity.id,
                pageIndex: pageIndex,
                width: ConstantsTool.MULTIPLE_CHOICES_WIDTH / numberCol,
                content: `${listAnswerKey()[listAnswerIds.length]}. `,
                x:
                    ConstantsTool.MULTIPLE_CHOICES_X +
                    ((listAnswerIds.length % numberCol) *
                        ConstantsTool.MULTIPLE_CHOICES_WIDTH) /
                        numberCol,
                y: startY,
            });
            const prevAnswerResourceIndex = currentPageResource.findIndex(
                (ele) => ele?.id == listAnswerIds[listAnswerIds.length - 1]
            );
            currentPageResource.splice(
                prevAnswerResourceIndex + 1,
                0,
                answerResource
            );
            question.answers.push(answer);
            updateOtherItemPositionMC({
                listResourceItem: pageWSS[pageIndex].resourceItems,
                currentElement: questionResource,
                pageIndex: pageIndex,
                listAnswerIds: listAnswerIds,
                numberCol: numberCol,
                yChange: 0,
                questionAndAnswerIds: getQuestionAndAnswerIds(questions),
                pagesWorksheet: pageWSS,
                activity: currentActivity,
                type: "answer",
                action: "add",
            });

            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("multipleChoice/addAnswerMC ", err);
        }
    }
);

export const updateAnswerMC = createAsyncThunk(
    "multipleChoice/updateAnswerMC",
    async (
        params: {
            answer: IAnswerActivity;
            answerIndex: number;
            questionIndex: number;
            answerContent: string;
            question: IQuestionActivity;
        },
        { dispatch, getState }
    ) => {
        try {
            {
                const rootState = getState() as RootState;
                const state = rootState.createWorksheetState;
                const {
                    answer,
                    answerIndex,
                    questionIndex,
                    answerContent,
                    question,
                } = params;
                const activities = JSON.parse(JSON.stringify(state.activities));
                const pageWSS = JSON.parse(
                    JSON.stringify(state.pagesWorksheet)
                );
                const currentActivity = JSON.parse(
                    JSON.stringify(state.currentActivity)
                );
                const questions = currentActivity.questions;
                let pageIndex = findQuestionPageIndex(pageWSS, question);
                const numberCol = question.multipleChoicesAttribute.numberCol;
                const currentPageResource = pageWSS[pageIndex]?.resourceItems;

                let answerActivityIndex = questions[
                    questionIndex
                ].answers.findIndex((ele) => ele.id == answer.id);
                questions[questionIndex].answers[
                    answerActivityIndex
                ].text = `${answerContent}`;
                let resourceIndex = currentPageResource.findIndex(
                    (ele) => ele?.id == answer.id
                );
                const listAnswerIds = questions[questionIndex].answers.map(
                    (ele) => ele.id
                );
                let contentHeight = getTextWidthHeightTool({
                    textValue: `${
                        listAnswerKey()[answerIndex]
                    }. ${answerContent}`,
                    includePadding: false,
                    fontSize: 24,
                    fontFamily: "Nunito",
                    textAlign: "left",
                    maxWidth:
                        ConstantsTool.MULTIPLE_CHOICES_WIDTH / numberCol -
                        ConstantsTool.GAP_ELEMENT_FILL_THE_BLANK,
                }).height;
                const numberLine = Math.floor(
                    contentHeight / ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES
                );
                let currentResource = currentPageResource[resourceIndex];
                const currentHeight = currentResource?.height;
                const listAnswerResource = filterResourceId(
                    currentPageResource,
                    listAnswerIds
                );
                const { heighest } = getHeighestItem({
                    listItem: listAnswerResource,
                });
                const listQuestionAndAnswerId =
                    getQuestionAndAnswerIds(questions);
                const yChange =
                    currentHeight == heighest || numberCol === 1
                        ? (numberLine -
                              currentResource?.height /
                                  ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES) *
                          ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES
                        : 0;
                currentPageResource[resourceIndex] = {
                    ...currentResource,
                    height:
                        ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES * numberLine,
                    textAttribute: {
                        ...currentResource?.textAttribute,
                        content: `${
                            listAnswerKey()[answerIndex]
                        }. ${answerContent}`,
                    },
                };
                updateOtherItemPositionMC({
                    listResourceItem: currentPageResource,
                    currentElement: currentResource,
                    pageIndex: pageIndex,
                    listAnswerIds: listAnswerIds,
                    numberCol: numberCol,
                    yChange: yChange,
                    questionAndAnswerIds: listQuestionAndAnswerId,
                    pagesWorksheet: pageWSS,
                    activity: currentActivity,
                    type: "answer",
                    action: "update",
                });

                updateActivitiesAndPageWSS(
                    dispatch,
                    currentActivity,
                    questions,
                    activities,
                    pageWSS
                );
            }
        } catch (err) {
            console.log("multipleChoice/updateAnswerMC ", err);
        }
    }
);

export const deleteAnswerMC = createAsyncThunk(
    "multipleChoice/deleteAnswerMC",
    async (
        params: {
            question: IQuestionActivity;
            answer: IAnswerActivity;
            numberCol: number;
        },
        { dispatch, getState }
    ) => {
        try {
            const { question, answer, numberCol } = params;
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const questions = currentActivity.questions;
            let pageIndex = findQuestionPageIndex(pageWSS, question);
            const currentPageResource = pageWSS[pageIndex]?.resourceItems;
            let answerResource;

            for (let i = 0; i < pageWSS.length; i++) {
                answerResource = pageWSS[i].resourceItems.find(
                    (resource) => resource.id == question.id
                );
            }
            const questionActivityIndex = questions.findIndex(
                (ele) => ele.id === question?.id
            );
            const newListAnswers = questions[
                questionActivityIndex
            ].answers.filter((ele) => ele.id != answer.id);
            const questionResource = currentPageResource.find(
                (ele) => ele?.id === question.id
            );
            const answerResourceIndex = currentPageResource.findIndex(
                (ele) => ele?.id === answer.id
            );
            const listAnswerIds = [];
            questions[questionActivityIndex].answers.forEach((ele) => {
                if (ele.id != answer.id) {
                    listAnswerIds.push(ele.id);
                }
            });
            updateAnswersContent({
                listResourceItem: currentPageResource,
                listAnswerIds: listAnswerIds,
            });
            if (answerResourceIndex != -1) {
                currentPageResource.splice(answerResourceIndex, 1);
            }
            questions[questionActivityIndex].answers = newListAnswers;
            for (let i = 0; i < listAnswerIds.length; i++) {
                changeAnswerPosition({
                    listItem: currentPageResource,
                    listAnswerIds: listAnswerIds,
                    questionLastY: questionResource.y + questionResource.height,
                    numberCol: numberCol,
                });
            }
            updateOtherItemPositionMC({
                listResourceItem: currentPageResource,
                currentElement: answerResource,
                pageIndex: pageIndex,
                listAnswerIds: listAnswerIds,
                numberCol: numberCol,
                yChange: 0,
                questionAndAnswerIds: getQuestionAndAnswerIds(questions),
                pagesWorksheet: pageWSS,
                activity: currentActivity,
                type: "answer",
                action: "delete",
            });

            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("multipleChoice/deleteAnswerMC: ", err);
        }
    }
);

export const updateAnswersMC = createAsyncThunk(
    "multipleChoice/updateAnswersMC",
    async (
        params: {
            question: IQuestionActivity;
            nextQuestion: IQuestionActivity;
            numberCol: number;
        },
        { dispatch, getState }
    ) => {
        try {
            const { question, nextQuestion, numberCol } = params;
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const questions = currentActivity.questions;
            let pageIndex = findQuestionPageIndex(pageWSS, question);
            const currentPageResource = pageWSS[pageIndex]?.resourceItems;
            const listAnswerIds = question.answers.map((ele) => ele.id);

            const questionActivityIndex = questions.findIndex(
                (ele) => ele.id === question.id
            );
            changeAnswerDimension({
                listItem: currentPageResource,
                listAnswerIds: listAnswerIds,
                numberCol: numberCol,
            });
            const questionResource = currentPageResource.find(
                (ele) => ele?.id === question.id
            );
            const questionLastY =
                questionResource?.y + questionResource?.height;
            for (let i = 0; i < listAnswerIds.length; i++) {
                changeAnswerPosition({
                    listItem: currentPageResource,
                    listAnswerIds: listAnswerIds,
                    questionLastY: questionLastY,
                    numberCol: numberCol,
                });
            }
            questions[questionActivityIndex].multipleChoicesAttribute = {
                ...question.multipleChoicesAttribute,
                numberCol: numberCol,
            };

            if (nextQuestion) {
                const nextQuesInPage = currentPageResource.find(
                    (resource) => resource.id == nextQuestion?.id
                );
                const listQuestionAndAnswerIds = [];
                questions.forEach((question) => {
                    listQuestionAndAnswerIds.push(question.id);
                    question.answers.forEach((answer) => {
                        listQuestionAndAnswerIds.push(answer.id);
                    });
                });
                const listAnswerResource = filterResourceId(
                    currentPageResource,
                    listAnswerIds
                );
                const nextQuestionResourceIndex = currentPageResource.findIndex(
                    (ele) => ele?.id == nextQuestion?.id
                );
                const lastYPosition = getLastYPosition({
                    listItem: listAnswerResource,
                    questionAndAnswerIds: [],
                });
                const actualNextQuestionYPosition =
                    lastYPosition + ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES;
                const space =
                    actualNextQuestionYPosition -
                    currentPageResource[nextQuestionResourceIndex]?.y;
                if (nextQuesInPage) {
                    currentPageResource.forEach((ele, index) => {
                        if (
                            index >= nextQuestionResourceIndex &&
                            listQuestionAndAnswerIds.includes(ele?.id)
                        ) {
                            currentPageResource[index] = {
                                ...ele,
                                y: ele.y + space,
                            };
                        }
                    });
                }
                updateOtherItemPositionMC({
                    listResourceItem: currentPageResource,
                    currentElement: questionResource,
                    pageIndex: pageIndex,
                    listAnswerIds: listAnswerIds,
                    numberCol: numberCol,
                    yChange: 0,
                    questionAndAnswerIds: getQuestionAndAnswerIds(questions),
                    pagesWorksheet: pageWSS,
                    activity: currentActivity,
                    type: "",
                    action: "update",
                });
            }

            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("multipleChoice/updateAnswersMC: ", err);
        }
    }
);

export const chooseCorrectAnswerMC = createAsyncThunk(
    "multipleChoice/chooseCorrectAnswerMC",
    async (
        params: {
            question: IQuestionActivity;
            answer: IAnswerActivity;
        },
        { dispatch, getState }
    ) => {
        try {
            const { question, answer } = params;
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            const questions = currentActivity.questions;

            const questionIndex = questions.findIndex(
                (ele) => ele.id == question.id
            );
            const answerIndex = question.answers.findIndex(
                (ele) => ele.id === answer.id
            );
            if (answer.isCorrect) {
                questions[questionIndex].answers[answerIndex].isCorrect = false;
            } else {
                questions[questionIndex].answers[answerIndex].isCorrect = true;
            }
            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("multipleChoice/chooseCorrectAnswerMC: ", err);
        }
    }
);

export const swapQuestionMC = createAsyncThunk(
    "multipleChoice/swapQuestionMC",
    async (
        params: {
            swapIndex: number;
            selectIndex: number;
            selectQues: IQuestionActivity;
            swapQues: IQuestionActivity;
        },
        { dispatch, getState }
    ) => {
        try {
            const { swapIndex, selectIndex, selectQues, swapQues } = params;
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            const activities = JSON.parse(JSON.stringify(state.activities));
            const pageWSS = JSON.parse(JSON.stringify(state.pagesWorksheet));
            const currentActivity = JSON.parse(
                JSON.stringify(state.currentActivity)
            );
            let questions = currentActivity.questions;
            let fromPage, toPage;
            pageWSS.forEach((page) => {
                page.resourceItems.forEach((resource) => {
                    if (resource.id == selectQues.id) {
                        fromPage = resource.pageIndex;
                    }
                    if (resource.id == swapQues.id) {
                        toPage = resource.pageIndex;
                    }
                });
            });
            const isSamePage = fromPage === toPage;
            let pageSelectResources = pageWSS[fromPage].resourceItems;
            let pageSwapResources = pageWSS[toPage].resourceItems;
            const selectQuesCol = selectQues.multipleChoicesAttribute.numberCol;
            const selectQuesResource = pageSelectResources.find(
                (resource) => resource.id == selectQues.id
            );
            const answerIdsSelectQues = selectQues.answers.map(
                (answer) => answer.id
            );
            const swapQuesCol = swapQues.multipleChoicesAttribute.numberCol;
            const swapQuesResource = pageSwapResources.find(
                (resource) => resource.id == swapQues.id
            );
            const answerIdsSwapQues = swapQues.answers.map(
                (answer) => answer.id
            );
            const listQuestionAndAnswerId = getQuestionAndAnswerIds(questions);
            //Change in resources
            //Change indexs
            let newResource = [];
            let listSelectResource = pageSelectResources.filter(
                (resource) =>
                    resource.id == selectQues.id ||
                    answerIdsSelectQues.includes(resource.id)
            );
            let listSwapResource = pageSwapResources.filter(
                (resource) =>
                    resource.id == swapQues.id ||
                    answerIdsSwapQues.includes(resource.id)
            );
            let listSelectResourceIds = listSelectResource.map(
                (resource) => resource.id
            );
            let listSwapResourceIds = listSwapResource.map(
                (resource) => resource.id
            );
            if (isSamePage) {
                let moveTo = -1;
                let oldSelectIndex = -1;
                moveTo = pageSelectResources.findIndex(
                    (resource) => resource.id == swapQues.id
                );
                listSelectResource.forEach((resource, index) => {
                    pageSelectResources.splice(moveTo + index, 0, resource);
                });
                newResource = pageSelectResources.filter(
                    (resource) => !listSwapResourceIds.includes(resource.id)
                );
                let listIndexRemove = [];
                if (selectIndex > swapIndex) {
                    newResource.forEach((resource, index) => {
                        if (resource.id == selectQues.id) {
                            oldSelectIndex = index;
                        }
                    });
                } else if (selectIndex < swapIndex) {
                    oldSelectIndex = newResource.findIndex(
                        (resource) => resource.id == selectQues.id
                    );
                }
                newResource.forEach((resource, index) => {
                    if (
                        (selectIndex > swapIndex
                            ? index >= oldSelectIndex
                            : index <=
                              oldSelectIndex + selectQues.answers.length) &&
                        listSelectResourceIds.includes(resource.id)
                    ) {
                        listIndexRemove.push(index);
                    }
                });
                newResource = newResource.filter(
                    (resource, index) => !listIndexRemove.includes(index)
                );
                if (selectIndex > swapIndex) {
                    listSwapResource.forEach((resource, index) => {
                        newResource.splice(oldSelectIndex + index, 0, resource);
                    });
                } else if (selectIndex < swapIndex) {
                    for (let i = listSwapResource.length - 1; i >= 0; i--) {
                        newResource.splice(
                            oldSelectIndex,
                            0,
                            listSwapResource[i]
                        );
                    }
                }
            } else {
                let swapQuesResourceIndex = pageSwapResources.findIndex(
                    (resource) => resource.id == swapQues.id
                );
                let selectQuesResourceIndex = pageSelectResources.findIndex(
                    (resource) => resource.id == selectQues.id
                );
                pageSwapResources = pageSwapResources.filter(
                    (resource) => !listSwapResourceIds.includes(resource.id)
                );
                listSelectResource.forEach((resource, index) => {
                    pageSwapResources.splice(
                        swapQuesResourceIndex + index,
                        0,
                        resource
                    );
                });
                pageSelectResources = pageSelectResources.filter(
                    (resource) => !listSelectResourceIds.includes(resource.id)
                );
                listSwapResource.forEach((resource, index) => {
                    pageSelectResources.splice(
                        selectQuesResourceIndex + index,
                        0,
                        resource
                    );
                });
            }
            //Change question position
            let listAnswerSelectResource, listAnswerSwapResource;
            listAnswerSelectResource = filterResourceId(
                isSamePage ? pageSelectResources : pageSwapResources,
                answerIdsSelectQues
            );
            listAnswerSwapResource = filterResourceId(
                isSamePage ? pageSwapResources : pageSelectResources,
                answerIdsSwapQues
            );
            let answersSelectHeight = getListAnswerHeight({
                listAnswer: listAnswerSelectResource,
                numberCol: selectQues.multipleChoicesAttribute.numberCol,
            });
            let answersSwapHeight = getListAnswerHeight({
                listAnswer: listAnswerSwapResource,
                numberCol: swapQues.multipleChoicesAttribute.numberCol,
            });
            let selectContainerHeight =
                selectQuesResource.height +
                ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES +
                answersSelectHeight;
            let swapContainerHeight =
                swapQuesResource.height +
                ConstantsTool.LINE_HEIGHT_MULTIPLE_CHOICES +
                answersSwapHeight;
            let yChange = selectContainerHeight - swapContainerHeight;

            if (isSamePage) {
                newResource.forEach((resource, index) => {
                    if (
                        listSelectResourceIds.includes(resource.id) ||
                        listSwapResourceIds.includes(resource.id)
                    ) {
                        let space = listSelectResourceIds.includes(resource.id)
                            ? swapQuesResource.y - selectQuesResource.y
                            : selectQuesResource.y - swapQuesResource.y;
                        newResource[index] = {
                            ...resource,
                            y: resource.y + space,
                        };
                    }
                });

                newResource.forEach((resource, index) => {
                    if (
                        resource.id == selectQues.id ||
                        resource.id == swapQues.id
                    ) {
                        const questionContent = getContent(
                            resource.textAttribute.content
                        );
                        newResource[index] = {
                            ...resource,
                            textAttribute: {
                                ...resource.textAttribute,
                                content: `${
                                    resource.id == selectQues.id
                                        ? swapIndex + 1
                                        : selectIndex + 1
                                }.${questionContent}`,
                            },
                        };
                        let numberCol =
                            resource.id == selectQues.id
                                ? selectQuesCol
                                : swapQuesCol;
                        let listAnswer =
                            resource.id == selectQues.id
                                ? listAnswerSelectResource
                                : listAnswerSwapResource;
                        updateOtherItemPositionMC({
                            listResourceItem: newResource,
                            currentElement: listAnswer[listAnswer.length - 1],
                            pageIndex: fromPage,
                            listAnswerIds: [],
                            numberCol: numberCol,
                            yChange:
                                resource.id == selectQues.id
                                    ? yChange
                                    : -yChange,
                            questionAndAnswerIds: listQuestionAndAnswerId,
                            pagesWorksheet: pageWSS,
                            activity: currentActivity,
                            type: "question",
                            action: "swap",
                        });
                    }
                });
            } else {
                [pageSelectResources, pageSwapResources].forEach(
                    (page, pageIndex) => {
                        page.forEach((resource, index) => {
                            let quesId =
                                pageIndex == 0 ? swapQues.id : selectQues.id;
                            if (resource.id === quesId) {
                                const newY =
                                    pageIndex == 0
                                        ? selectQuesResource.y
                                        : swapQuesResource.y;
                                const height =
                                    pageIndex == 0
                                        ? swapQuesResource.height
                                        : selectQuesResource.height;
                                const numberCol =
                                    pageIndex == 0
                                        ? swapQuesCol
                                        : selectQuesCol;
                                const quesNum =
                                    pageIndex == 0
                                        ? selectIndex + 1
                                        : swapIndex + 1;
                                const listAnswerIds =
                                    pageIndex == 0
                                        ? answerIdsSwapQues
                                        : answerIdsSelectQues;
                                const length =
                                    pageIndex == 0
                                        ? answerIdsSwapQues.length
                                        : answerIdsSelectQues.length;
                                const questionContent = getContent(
                                    resource.textAttribute.content
                                );
                                page[index] = {
                                    ...resource,
                                    y: newY,
                                    textAttribute: {
                                        ...resource.textAttribute,
                                        content: `${quesNum}.${questionContent}`,
                                    },
                                };
                                for (let i = 0; i < length; i++) {
                                    changeAnswerPosition({
                                        listItem: page,
                                        listAnswerIds: listAnswerIds,
                                        questionLastY: newY + height,
                                        numberCol: numberCol,
                                    });
                                }
                            }
                        });
                    }
                );
                [fromPage, toPage].forEach((page) => {
                    let numberCol =
                        page == fromPage
                            ? selectQues.multipleChoicesAttribute.numberCol
                            : swapQues.multipleChoicesAttribute.numberCol;
                    let listAnswer =
                        page == fromPage
                            ? listAnswerSelectResource
                            : listAnswerSwapResource;
                    let listResource =
                        page == fromPage
                            ? pageSwapResources
                            : pageSelectResources;
                    updateOtherItemPositionMC({
                        listResourceItem: listResource,
                        currentElement: listAnswer[listAnswer.length - 1],
                        pageIndex: page,
                        listAnswerIds: [],
                        numberCol: numberCol,
                        yChange: page == fromPage ? yChange : -yChange,
                        questionAndAnswerIds: listQuestionAndAnswerId,
                        pagesWorksheet: pageWSS,
                        activity: currentActivity,
                        type: "question",
                        action: "swap",
                    });
                });
                [pageSelectResources, pageSwapResources].forEach((page) => {
                    page.forEach((resource, index) => {
                        if (
                            listSwapResourceIds.includes(resource.id) ||
                            listSelectResourceIds.includes(resource.id)
                        ) {
                            let newPage = listSwapResourceIds.includes(
                                resource.id
                            )
                                ? fromPage
                                : toPage;
                            page[index] = {
                                ...resource,
                                pageIndex: newPage,
                            };
                        }
                    });
                });
            }

            if (isSamePage) {
                pageWSS[fromPage].resourceItems = newResource;
            } else {
                pageWSS[fromPage].resourceItems = pageSelectResources;
                pageWSS[toPage].resourceItems = pageSwapResources;
            }
            let middle = questions[selectIndex];
            questions[selectIndex] = questions[swapIndex];
            questions[swapIndex] = middle;

            questions = [...questions];
            updateActivitiesAndPageWSS(
                dispatch,
                currentActivity,
                questions,
                activities,
                pageWSS
            );
        } catch (err) {
            console.log("multipleChoice/swapQuestionMC: ", err);
        }
    }
);
