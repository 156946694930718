import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../../../shared/utils/config";
// import exclamationIconSvg from "../../../../../assets/images/icon/exclamation-point.svg";
import { useState } from "react";
import {
    previousActionResourceAction,
    selectActionGridItemAction,
} from "../../../../../redux/action/resource.action";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import { getImageSource } from "../../../../../utils";
import ExclamationPoint from "../../../../icons/exclamation-point";
import LoadingComponent from "../../../loading/loading-component";
import ShareMultiPinterestResource from "../../../share/share-pinterest";
import { IDS_METHOD } from "../claim-coins/index";
import CoinQty from "../coin-qty";
import CountdownTimer from "../count-down-timer";
export const STATUS_BTN = {
    non: -1,
    disable: 0,
    able: 1,
};
const ItemMethod = ({
    item,
    showBtnClaimMethod,
    handleClaimDownload,
    setShowButtonClaim,
    ableClaim,
    wssWorkbook,
    worksheet,
    numberTime,
    clickLabelFc,
}: {
    item;
    showBtnClaimMethod;
    handleClaimDownload;
    setShowButtonClaim?: Function;
    ableClaim;
    wssWorkbook?: any[];
    worksheet?: any;
    numberTime?: number;
    clickLabelFc?: Function;
}) => {
    const isGetFreeClaim = item.id == IDS_METHOD.freeClaim;
    const isSharePinterest = item.id == IDS_METHOD.sharePinterest;
    const worksheets = useSelector(
        (state: ResourceAppState) =>
            state?.resourceState?.actionGridItem?.worksheets
    );

    const listWorksheets = worksheets?.length
        ? worksheets
        : worksheet?.isWorkbook
        ? wssWorkbook
        : [worksheet];

    // let groupWs = groupWsFc({ worksheets: listWorksheets });
    // const numberWs = Object.keys(groupWs).length;
    // console.log("numberWs", numberWs);
    return (
        <div className="item-method">
            <div className="item-header">
                {isSharePinterest ? (
                    <ShareMultiPinterestResource worksheets={listWorksheets}>
                        <ItemLabel
                            ableClaim={ableClaim}
                            item={item}
                            clickLabelFc={clickLabelFc}
                        />
                    </ShareMultiPinterestResource>
                ) : (
                    <ItemLabel
                        ableClaim={ableClaim}
                        item={item}
                        clickLabelFc={clickLabelFc}
                    />
                )}
                <div className="item-coins">
                    <CoinQty
                        quantity={item.numberCoins}
                        height={24}
                        width={24}
                    />
                </div>
            </div>
            <div>
                <div
                    className={
                        "item-section  " +
                        (item.id == IDS_METHOD.freeClaim ? "box-item" : "")
                    }
                >
                    <div className="item-description">
                        {item.tooltip ? (
                            <>
                                {/* <ToolTipCustomize
                                    title={item.tooltip}
                                    position={{
                                        left: 22,
                                    }}
                                >
                                    
                                </ToolTipCustomize> */}
                                <ExclamationPoint
                                    tooltip={item.tooltip}
                                    placement={
                                        showBtnClaimMethod != STATUS_BTN.able &&
                                        item.id == IDS_METHOD.sharePinterest
                                            ? "top"
                                            : " bottom"
                                    }
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        <p
                            dangerouslySetInnerHTML={{
                                __html: item.description.replace(
                                    "numberCoins",
                                    item.numberCoins * 10
                                ),
                            }}
                        ></p>
                    </div>
                    {isGetFreeClaim ? (
                        showBtnClaimMethod == STATUS_BTN.able ? (
                            <ButtonClaimDownload
                                handleClaimDownload={handleClaimDownload}
                            />
                        ) : showBtnClaimMethod == STATUS_BTN.disable ? (
                            <CountdownTimer
                                setShowButtonClaim={setShowButtonClaim}
                            />
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {!isGetFreeClaim && showBtnClaimMethod == STATUS_BTN.able ? (
                <div className="claim-action  box-item">
                    <div className="qty">
                        <CoinQty
                            quantity={item.numberCoins}
                            height={24}
                            width={24}
                        />
                        <div className="qty-number">x{numberTime}</div>
                    </div>
                    <ButtonClaimDownload
                        handleClaimDownload={handleClaimDownload}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
const ButtonClaimDownload = ({ handleClaimDownload }) => {
    const [claimed, setClaimed] = useState(false);

    return (
        <>
            <div
                className="btn-claim-download"
                onClick={() => {
                    if (!claimed) {
                        setClaimed(true);
                    }
                    handleClaimDownload();
                }}
            >
                {claimed ? <LoadingComponent /> : "Claim"}
            </div>
        </>
    );
};
const ItemLabel = ({ ableClaim, item, clickLabelFc }) => {
    const dispatch = useDispatch();
    const action = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem.action
    );

    const selectMethod = () => {
        clickLabelFc && clickLabelFc({ item });
        if (item.id == IDS_METHOD.sharePinterest) {
        }
        if (item.id == IDS_METHOD.inviteFriend) {
            dispatch(
                selectActionGridItemAction({
                    action: Config.ACTION_GRID.INVITE_FRIEND,
                })
            );
            if (action) {
                dispatch(
                    previousActionResourceAction({
                        previousAction: action,
                    })
                );
            }
        }
    };
    return (
        <div
            className={"item-label " + (!ableClaim ? "disable" : "able")}
            onClick={() => {
                selectMethod();
            }}
        >
            <img
                className="icon"
                src={getImageSource(item.icon)}
                alt={item.title}
                width={24}
                height={24}
            />
            <p>{item.title}</p>
        </div>
    );
};

export default ItemMethod;
