import { useEffect, useState } from "react";
import "simplebar-react/dist/simplebar.min.css";
import { Activity } from "../../../shared/models/WorkSheetCreator";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import FontTypeSection from "./font-type";
import "./index.scss";
import InputSection from "./input";
import ListenSelectText from "./listenSelect";
import TextStyleSection from "./text-style";
import Orientaion from "./orientation";

const NameTracingView = ({ activity }: { activity: Activity }) => {
    const [questionId, setQuestionId] = useState<string>("");
    const question = activity.questions.find((s) => s.id === questionId);
    const [config, setConfig] = useState({
        fontStyle:
            question?.nameTracingAttribute?.fontStyle ??
            ConstantsTool.FONT_PRINT,
        textStyle:
            question?.nameTracingAttribute?.textStyle ??
            ConstantsTool.TEXT_STYLE.DASHED,
    });

    useEffect(() => {
        if (!questionId && activity.questions) {
            setQuestionId(activity.questions?.[0]?.id);
        }
    }, []);

    useEffect(() => {
        if (question && questionId) {
            setConfig({
                fontStyle: question?.nameTracingAttribute?.fontStyle,
                textStyle: question?.nameTracingAttribute?.textStyle,
            });
        }
    }, [questionId]);

    return (
        <div className="name-tracing-container">
            <FontTypeSection
                question={question}
                config={config}
                setConfig={setConfig}
            />
            <InputSection
                questions={activity.questions}
                questionId={questionId}
                setQuestionId={setQuestionId}
                config={config}
            />
            <div
                className={`${question ? "show_nt" : "hide_nt"}`}
                style={{
                    padding: "8px",
                    background: " #F9FAFB",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <TextStyleSection
                    question={question}
                    config={config}
                    setConfig={setConfig}
                />
                <Orientaion
                    question={question}
                    orientation={activity.orientation}
                />
            </div>
            <ListenSelectText
                questions={activity.questions}
                questionId={questionId}
                setQuestionId={setQuestionId}
            />
        </div>
    );
};

export default NameTracingView;
