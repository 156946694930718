import { useEffect, useState } from "react";
import Config from "../../../shared/utils/config";
import { getBrowser } from "../../../utils";
import CreateWSConfig from "../../../utils/create-ws-config";
import "./style.scss";

const LayoutTabView = ({
    classNameTab,
    isActive,
    setTabActive,
    title,
    content,
    moreActionRender,
    titleValue = "",
    preventHideTab,
    id,
}: {
    classNameTab;
    isActive?: boolean;
    setTabActive: Function;
    title: any;
    content: any;
    moreActionRender?: any;
    titleValue?: string;
    id?: string;
    preventHideTab?: boolean;
}) => {
    const statusClass = {
        init: "",
        show: "show",
        hide: "hide",
    };
    let [className, setClassName] = useState(statusClass.init);
    let [height, setHeight] = useState("");
    let [nameDevice, setNameDevice] = useState("");
    useEffect(() => {
        if (
            getBrowser().name.toLowerCase() == "safari" &&
            Number(getBrowser().version) < 16.5
        ) {
            setNameDevice(getBrowser()?.name?.toLowerCase());
        }
        // setNameDevice(getBrowser()?.name?.toLowerCase());
    }, []);
    useEffect(() => {
        if (!isActive && className == statusClass.show) {
            setClassName(statusClass.hide);
        }
        if (isActive) {
            if (
                className == statusClass.init ||
                className == statusClass.hide
            ) {
                setClassName(statusClass.show);
            }
            let height = "";
            switch (titleValue) {
                case Config.ACTIVITY_TYPE.WORD_SEARCH.TITLE:
                    height =
                        CreateWSConfig.HEIGHT_ACTIVITIES.WORD_SEARCH + "px";
                    break;
                case Config.ACTIVITY_TYPE.FILL_IN_BLANK.TITLE:
                    height =
                        CreateWSConfig.HEIGHT_ACTIVITIES.FILL_IN_BLANK + "px";
                    break;
                case Config.ACTIVITY_TYPE.HANDWRITING.TITLE:
                    height =
                        CreateWSConfig.HEIGHT_ACTIVITIES.HANDWRITING + "px";
                    break;
                case Config.ACTIVITY_TYPE.NAME_TRACING.TITLE:
                    height =
                        CreateWSConfig.HEIGHT_ACTIVITIES.NAME_TRACING + "px";
                    break;
                case Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TITLE:
                    height = CreateWSConfig.HEIGHT_ACTIVITIES.MULTIPLE_CHOICE;
                    break;
                default:
                    height = CreateWSConfig.HEIGHT_ACTIVITIES.WS_INFO + "px";
                    break;
            }
            setHeight(height);
        }
    }, [isActive, id]);

    return (
        <div
            className={"tab-view " + classNameTab}
            // style={
            //     {
            //         "--heightContentView": `${height}px`,
            //     } as any
            // }
            id={id}
        >
            <div
                className={
                    "title tab-view__title " +
                    (isActive ? " active " : " disable") +
                    (preventHideTab ? " prevent-hide-tab " : "")
                }
            >
                {moreActionRender ? <>{moreActionRender()}</> : <></>}
            </div>
            <div
                className={
                    (nameDevice ? nameDevice : " ") +
                    " tab-view__content " +
                    (className ?? " ") +
                    (!className ? (isActive ? " active " : " disable") : "")
                }
            >
                {content && content()}
            </div>
        </div>
    );
};

export default LayoutTabView;
