import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import './styles.scss';

const ResetDesignOptionsModal = ({hideModal, reset}) => {
    const [selectAll, setSelectAll] = useState(false);
    const [resetFrame, setResetFrame] = useState(false);
    const [resetHeadline, setResetHeadline] = useState(false);
    const [resetTexts, setResetTexts] = useState(false);
    const [resetOthers, setResetOthers] = useState(false);
    const [resetPageIndex, setResetPageIndex] = useState(false);
    const [autoResizeText, setAutoResizeText] = useState(false);

    useEffect(() => {
        if (selectAll) {
            setResetFrame(true);
            setResetHeadline(true);
            setResetTexts(true);
            setResetOthers(true);
            setResetPageIndex(true);
        }
    }, [selectAll]);

    return (
        <Modal show={true} onHide={hideModal} id="reset-design-options-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Reset Design Options
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="form-header">
                        <h2>Items to reset</h2>
                        <span className="select-all-btn" onClick={() => setSelectAll(!selectAll)}>Select all</span>
                    </div>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="reset-frame-checkbox">
                                <Form.Check type="checkbox" label="Frame" checked={resetFrame}
                                    onChange={e => setResetFrame(!resetFrame)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="reset-headline-checkbox">
                                <Form.Check type="checkbox" label="Headline" checked={resetHeadline}
                                    onChange={e => setResetHeadline(!resetHeadline)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="reset-texts-checkbox">
                                <Form.Check type="checkbox" label="Texts" checked={resetTexts}
                                    onChange={e => setResetTexts(!resetTexts)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="reset-others-checkbox">
                                <Form.Check type="checkbox" label="Others (Shapes, Images...)" checked={resetOthers}
                                    onChange={e => setResetOthers(!resetOthers)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h2>Others</h2>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="auto-resize-text-checkbox">
                                <Form.Check type="checkbox" label="Auto Resize Text" checked={autoResizeText}
                                    onChange={e => setAutoResizeText(!autoResizeText)} />
                                <Form.Text>Auto set text's font size</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="reset-page-index-checkbox">
                                <Form.Check type="checkbox" label="Page Index" checked={resetPageIndex}
                                    onChange={e => setResetPageIndex(!resetPageIndex)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button id="custom-reset-btn" onClick={() => reset(resetFrame, resetHeadline, resetTexts, resetOthers, resetPageIndex, autoResizeText)}>Reset</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResetDesignOptionsModal;