import { useState } from "react";

const ExclamationPoint = ({ tooltip, placement }) => {
    const [fill, setFill] = useState("#212121");
    const [opacity, setOpacity] = useState("0.24");
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="explain">
            <div
                className="icon"
                onClick={() => {
                    setShowTooltip(true);
                    setFill("#2E3B4B");
                    setOpacity("1");
                }}
                onMouseLeave={() => {
                    setShowTooltip(false);
                    setFill("#212121");
                    setOpacity("0.24");
                }}
                onMouseOver={() => {
                    setShowTooltip(true);
                    setFill("#2E3B4B");
                    setOpacity("1");
                }}
            >
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7 3.5C6.88941 3.50006 6.78005 3.52329 6.67898 3.56819C6.57791 3.61308 6.48736 3.67865 6.41318 3.76067C6.33899 3.84269 6.2828 3.93934 6.24824 4.0444C6.21368 4.14945 6.2015 4.26058 6.2125 4.37063L6.51875 7.43925C6.52904 7.5598 6.5842 7.6721 6.67331 7.75393C6.76243 7.83576 6.87901 7.88117 7 7.88117C7.12099 7.88117 7.23757 7.83576 7.32669 7.75393C7.4158 7.6721 7.47096 7.5598 7.48125 7.43925L7.7875 4.37063C7.7985 4.26058 7.78632 4.14945 7.75176 4.0444C7.7172 3.93934 7.66101 3.84269 7.58682 3.76067C7.51264 3.67865 7.42209 3.61308 7.32102 3.56819C7.21995 3.52329 7.11059 3.50006 7 3.5ZM7.00175 8.75C6.76969 8.75 6.54713 8.84219 6.38303 9.00628C6.21894 9.17038 6.12675 9.39294 6.12675 9.625C6.12675 9.85706 6.21894 10.0796 6.38303 10.2437C6.54713 10.4078 6.76969 10.5 7.00175 10.5C7.23381 10.5 7.45637 10.4078 7.62047 10.2437C7.78456 10.0796 7.87675 9.85706 7.87675 9.625C7.87675 9.39294 7.78456 9.17038 7.62047 9.00628C7.45637 8.84219 7.23381 8.75 7.00175 8.75Z"
                        fill={fill}
                        fillOpacity={opacity}
                    />
                </svg>
            </div>
            {showTooltip ? (
                <div className={"tool-tip " + (placement ?? "")}>
                    <span className="tooltip-arrow"></span>
                    <p>{tooltip}</p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ExclamationPoint;
