import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { IParamsQuotaResourceUser } from "../../../../../../../shared/models/quotaResourceuser";
import Config from "../../../../../../../shared/utils/config";
import { updateQuotaUserAction } from "../../../../../../redux/action/quotaResourceUser.action";
import { ResourceAppState } from "../../../../../../redux/reducer/root.reducerModule";
import ItemMethod, { STATUS_BTN } from "../layout-item-method";
const SharePinterestMethod = ({
    item,
    wssWorkbook,
    worksheet,
    addEventGaFc,
    clickLabelFc,
}) => {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery("(max-width: 1024px)");

    const userInfo = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data
    );

    const verifySharePin = useSelector(
        (state: ResourceAppState) =>
            state?.quotaResourceUserState?.data?.verifySharePin
    );

    const handleClaimDownload = () => {
        let params: IParamsQuotaResourceUser = {
            userId: userInfo.id,
        };
        let bonusQuota;

        params = {
            ...params,
            verifySharePin: 0,
        };
        bonusQuota =
            verifySharePin * Config.BONUS_QUOTA_DOWNLOAD_EACH_SHARE_PIN;

        params = {
            ...params,
            bonusQuota,
        };
        addEventGaFc({ item });
        dispatch(updateQuotaUserAction({ params }));
    };
    if (isTablet) {
        return <></>;
    }
    return (
        <ItemMethod
            item={item}
            ableClaim={true}
            showBtnClaimMethod={
                verifySharePin > 0 ? STATUS_BTN.able : STATUS_BTN.non
            }
            handleClaimDownload={handleClaimDownload}
            wssWorkbook={wssWorkbook}
            worksheet={worksheet}
            numberTime={verifySharePin}
            clickLabelFc={clickLabelFc}
        />
    );
};

export default SharePinterestMethod;
