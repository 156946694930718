import React from "react";

const NextChildCollection = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect width="40" height="40" rx="20" fill="#F8F8F8" />
                <path
                    d="M18 24L22 20L18 16"
                    stroke="#3E3E40"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_4635_31328"
                    x="-4"
                    y="-4"
                    width="48"
                    height="48"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4635_31328"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4635_31328"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default NextChildCollection;
