import useMediaQuery from "@mui/material/useMediaQuery";
import DynamicComponent from "../../common/dynamic-component";

const UnTickWSIcon = () => {
    const isMobile = useMediaQuery("(max-width:1024px)");

    if (isMobile) {
        return (
            <>
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path
                            d="M30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15Z"
                            fill="#212121"
                            fillOpacity="0.24"
                        />
                        <g filter="url(#filter0_d_1205_825)">
                            <path
                                d="M15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25Z"
                                stroke="white"
                                strokeWidth="2"
                            />
                        </g>
                        <path
                            d="M10.3146 14.5737C10.1427 14.4019 9.9096 14.3053 9.6665 14.3053C9.4235 14.3053 9.1903 14.4019 9.0184 14.5737C8.8465 14.7456 8.75 14.9788 8.75 15.2219C8.75 15.4649 8.8465 15.698 9.0184 15.8699L11.631 18.4826C11.778 18.6297 11.9527 18.7464 12.1448 18.826C12.3369 18.9056 12.5429 18.9466 12.7509 18.9466C12.9588 18.9466 13.1648 18.9056 13.3569 18.826C13.549 18.7464 13.7236 18.6297 13.8706 18.4826L20.0386 12.3146C20.2104 12.1427 20.307 11.9096 20.307 11.6665C20.307 11.4235 20.2105 11.1903 20.0387 11.0184C19.8668 10.8465 19.6336 10.75 19.3905 10.75C19.1475 10.75 18.9143 10.8465 18.7424 11.0184L12.7505 17.0096L10.3146 14.5737Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_1205_825"
                            x="-7"
                            y="-7"
                            width="52"
                            height="52"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dx="4" dy="4" />
                            <feGaussianBlur stdDeviation="7.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1205_825"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1205_825"
                                result="shape"
                            />
                        </filter>
                        <clipPath id="clip0_1205_825">
                            <rect width="30" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </>
        );
    }
    return (
        <DynamicComponent
            path="./untick-ws-icon-desktop"
            component={import("./untick-ws-icon-desktop")}
        />
    );
};

export default UnTickWSIcon;
