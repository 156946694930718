import { IUserInfo, IUserSurvey } from "../../../shared/models/userInfo";

export enum UserInfoResourceTypes {
    LOGIN = "LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS_RESOURCE_USER",
    LOGIN_FAILED = "LOGIN_FAILED",
    REGISTER = "REGISTER",
    REGISTER_SUCCESS = "REGISTER_SUCCESS",
    REGISTER_FAILED = "REGISTER_FAILED",
    LOGOUT = "LOGOUT",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    REGISTER_FACEBOOK = "REGISTER_FACEBOOK",
    REGISTER_GOOGLE = "REGISTER_GOOGLE",
    LOGIN_EMAIL = "LOGIN_EMAIL",
    UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL",
    UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS",
    UPDATE_USER_SURVEY = "UPDATE_USER_SURVEY",
    USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
    UPDATE_USERINFO_RESOURCE = "UPDATE_USERINFO_RESOURCE",
    UPDATE_TOTAL_SHARED = "UPDATE_TOTAL_SHARED",
}

export interface UserInfoResourceAction {
    type: UserInfoResourceTypes;
    email?: string;
    password?: string;
    userInfo?: IUserInfo;
    error?: any;
    userSocial?: any;
    payload?: any;
    reload?: boolean;
    survey?: IUserSurvey;
    teacherLogin?: boolean;
}

export function login(email: string, password: string): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGIN,
        email: email,
        password: password,
    };
}
export const updateUserEmailSuccess = (userInfo: IUserInfo) => {
    return {
        type: UserInfoResourceTypes.UPDATE_USER_EMAIL_SUCCESS,
        userInfo: userInfo,
    };
};
export function loginSuccess(
    userInfo: IUserInfo,
    reload = false
): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGIN_SUCCESS,
        userInfo: userInfo,
        reload: reload,
    };
}

export const updateUserEmail = (email, teacherLogin) => {
    return {
        type: UserInfoResourceTypes.UPDATE_USER_EMAIL,
        email,
        teacherLogin,
    };
};
export function loginFailed(
    userInfo: IUserInfo,
    error: any
): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGIN_FAILED,
        userInfo: userInfo,
        error: error,
    };
}

export function register(
    email: string,
    password: string
): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.REGISTER,
        email: email,
        password: password,
    };
}

export function registerFacebook(userSocial: any): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.REGISTER_FACEBOOK,
        userSocial: userSocial,
    };
}

export function registerGoogle(userSocial: any): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.REGISTER_GOOGLE,
        userSocial: userSocial,
    };
}

export function loginEmail(email: string): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGIN_EMAIL,
        email,
    };
}

export function registerSuccess(userInfo: IUserInfo): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.REGISTER_SUCCESS,
        userInfo: userInfo,
    };
}

export function registerFailed(
    userInfo: IUserInfo,
    error: any
): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.REGISTER_FAILED,
        userInfo: userInfo,
        error: error,
    };
}

export function logout(): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGOUT,
    };
}

export function logoutSuccess(): UserInfoResourceAction {
    return {
        type: UserInfoResourceTypes.LOGOUT_SUCCESS,
    };
}

export const updateUserSurveyAction = (
    survey: IUserSurvey
): UserInfoResourceAction => {
    return { type: UserInfoResourceTypes.UPDATE_USER_SURVEY, survey };
};

export const userResourceLoginSuccessAction = (
    userInfo: IUserInfo,
    reload = false
): UserInfoResourceAction => {
    return {
        type: UserInfoResourceTypes.USER_LOGIN_SUCCESS,
        userInfo: userInfo,
        reload: reload,
    };
};

export const updateUserInfoResourceAction = ({
    userInfo,
}: {
    userInfo: IUserInfo;
}) => {
    return { type: UserInfoResourceTypes.UPDATE_USERINFO_RESOURCE, userInfo };
};
