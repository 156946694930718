import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../shared/models/resourceItemNew";
import { RootState } from "../store";
import {
    addPageWorksheet,
    addResourceItems,
    updateResourceItem,
} from "../reducers/createWorksheet";
import { newPageResource } from "../../utils/draw";
import { generateIdFromDateTime } from "../../utils";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import undoHistoryStore from "../../syncExternalStore/undoHistoryStore";

export const updateFillTheBlankSentences = createAsyncThunk(
    "updateFillTheBlankSentences",
    async (
        params: {
            resourceItems: IResourceItemNew[];
            pageIndex: number;
            isAddHistory: boolean;
        },
        { dispatch, getState }
    ) => {
        try {
            const rootState = getState() as RootState;
            const state = rootState.createWorksheetState;
            let { pageIndex, resourceItems, isAddHistory } = params;
            if (isAddHistory)
                undoHistoryStore.update({
                    pagesWorksheet: state.pagesWorksheet,
                    currentActivity: state.currentActivity,
                });
            if (pageIndex >= state.pagesWorksheet.length) {
                let wsGenerator = state.pagesWorksheet[
                    pageIndex - 1
                ].resourceItems.find((el) => el.idType === "ws_generator");
                ConstantsTool.changeOrientation(
                    ConstantsTool.ORIENTATION.PORTRAIT
                );
                dispatch(
                    addPageWorksheet({
                        resourceItems: [
                            ...newPageResource(
                                pageIndex,
                                new ResourceItemNew({
                                    ...wsGenerator,
                                    id: generateIdFromDateTime("ws_generator"),
                                    pageIndex: pageIndex,
                                })
                            ),
                            ...resourceItems,
                        ],
                        isAddHistory: false,
                        pageIndex,
                    })
                );
            } else {
                let resourceItemsExit =
                    state.pagesWorksheet[pageIndex].resourceItems;
                for (let i = 0; i < resourceItems.length; i++) {
                    let resourceItem = resourceItems[i];
                    const resourceIndex = resourceItemsExit.findIndex(
                        (item) => item.id == resourceItem.id
                    );
                    if (resourceIndex > -1) {
                        dispatch(
                            updateResourceItem({
                                currentElementId: resourceItem.id,
                                attrValues: Object.keys(resourceItem).map(
                                    (key) => ({
                                        attr: key,
                                        value: resourceItem[key],
                                    })
                                ),
                                pageIndex,
                                isAddHistory: false,
                            })
                        );
                    } else {
                        dispatch(
                            addResourceItems({
                                pageIndex,
                                resourceItems: [resourceItem],
                                isAddHistory: false,
                            })
                        );
                    }
                }
            }
            return null;
        } catch (err) {
            console.log("updateFillTheBlankSentences: " + err);
        }
    }
);
