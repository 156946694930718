import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../shared/utils/config";
import {
    previousActionResourceAction,
    selectActionGridItemAction,
} from "../../../redux/action/resource.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import ArrowPrev from "../../icons/arrow-prev";
import CloseIconSvg from "../../icons/close-icon";
import FormRefer from "../../refer-friend/form-refer";
import InviteContent from "../../refer-friend/invite-content";
import "./style.scss";
const InviteFriend = () => {
    const dispatch = useDispatch();
    const previousAction = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.previousAction
    );
    return (
        <>
            <div className="header-popup">
                {previousAction ? (
                    <div
                        className="back"
                        onClick={() => {
                            dispatch(
                                selectActionGridItemAction({
                                    action: previousAction,
                                })
                            );
                            dispatch(
                                previousActionResourceAction({
                                    previousAction: Config.ACTION_GRID.DEFAULT,
                                })
                            );
                        }}
                    >
                        <ArrowPrev />
                    </div>
                ) : (
                    <>
                        <div></div>
                    </>
                )}
                <p>Invite friend</p>
                <div>
                    <div
                        className="close"
                        onClick={() => {
                            dispatch(
                                selectActionGridItemAction({
                                    action: Config.ACTION_GRID.DEFAULT,
                                })
                            );
                        }}
                    >
                        <CloseIconSvg />
                    </div>
                </div>
            </div>
            <div className="main-popup-download-invite-friend">
                <InviteContent />
                <FormRefer />
            </div>
        </>
    );
};

export default InviteFriend;
