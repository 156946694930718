import {
    getDataLine,
    getDataShape,
    getResourceItemLine,
    getResourceItemShape,
} from "../../../../utils/canvalData";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    addResourceItems,
    selectElements,
    upDateShowAnswerKey,
} from "../../../../redux/reducers/createWorksheet";
import { IconSVG, svgPath } from "../../../../assets/icon/icons";
import {
    IResourceItemNew,
    ResourceItemNew,
} from "../../../../shared/models/resourceItemNew";
import {
    eventAddResourceElement,
    generateIdFromDateTime,
    getPositionResource,
    getResourceItemSize,
    isResourceInteractive,
} from "../../../../utils";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import Checkbox from "@mui/material/Checkbox";

const DATA_INTERACTIVE_ELEMENTS = [
    {
        icon: IconSVG.iconTextBox,
        name: "Text Box",
        type: ConstantsTool.TYPE_RESOURCE_SHAPE,
    },
    {
        icon: IconSVG.iconLine,
        name: "Line",
        type: ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE,
    },
];

function TabShapes({ activeTab }: { activeTab: number }) {
    const colorShape = "#000000";
    const dataShape = getDataShape("#ffffff");
    const dataLine = getDataLine();
    const isShowAnswerKey = useAppSelector(
        (state) => state.createWorksheetState.isShowAnswerKey
    );
    const isShowButtonAnswerKey = useAppSelector(
        (state) => state.leftDataState.isShowButtonAnswerKey
    );
    const dispatch = useAppDispatch();
    const currentPage = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const pageWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet[currentPage]
    );
    const resourceItems: IResourceItemNew[] =
        currentPage >= 0
            ? useAppSelector(
                (state) =>
                    state.createWorksheetState.pagesWorksheet[currentPage]
                        .resourceItems
            )
            : [];

    const getResourceItem = (type: number) => {
        switch (type) {
            case ConstantsTool.TYPE_RESOURCE_SHAPE:
                return getResourceItemShape(dataShape[0]);
            case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
                return getResourceItemLine(dataLine[0]);
        }
        return getResourceItemShape(dataShape[0]);
    };

    const onAddResource = (type: number) => {
        const item = getResourceItem(type);
        const size = getResourceItemSize(item, 64);
        const width = size?.width ?? 64;
        const height = size?.height ?? 64;
        const position = getPositionResource(
            width,
            height,
            resourceItems.filter((e) => e.type === item.type),
            {width: pageWorksheet.width, height: pageWorksheet.height}
        );
        let x = position.x;
        let y = position.y;

        let imageAttribute = {
            url: item.url,
            changeColor: { color: colorShape },
        };

        let newItem = new ResourceItemNew({
            ...item,
            x,
            y,
            width: width,
            height: height,
            id: generateIdFromDateTime(),
            pageIndex: currentPage,
            imageAttribute,
        });
        dispatch(
            addResourceItems({
                resourceItems: [newItem],
                pageIndex: currentPage,
            })
        );

        dispatch(
            selectElements({
                resourceSelected: [newItem],
                pageIndex: currentPage,
            })
        );
        eventAddResourceElement(type);
    };
    const getShowAnswerKey = () => {
        let checked = false;
        resourceItems.forEach((resource) => {
            if (isResourceInteractive(resource.type)) {
                checked = true;
            }
        });
        return checked;
    };

    return (
        <div className="container-tabshapes">
            <div className="text-note">
                * Use this element for typed-answer questions. Click on them to
                type or paste the correct answer value.
            </div>
            <div className="title">Text Fields</div>
            <div className="list-box-interactive">
                {DATA_INTERACTIVE_ELEMENTS.map((item, index) => {
                    return (
                        <div
                            className="box-interactive"
                            key={index}
                            onClick={() => onAddResource(item.type)}
                        >
                            <div className="box-img">
                                <span className="text-answer">Answer</span>
                                <img src={svgPath(item.icon)} />
                            </div>
                            <span>{item.name}</span>
                        </div>
                    );
                })}
            </div>
            {getShowAnswerKey() && (
                <div className="row">
                    <span className="text">Show The Answer Key</span>
                    <Checkbox
                        checked={isShowAnswerKey}
                        onChange={(e) => {
                            dispatch(upDateShowAnswerKey(e.target.checked));
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ padding: 0 }}
                        disabled={!isShowButtonAnswerKey}
                    />
                </div>
            )}

            {/* <div style={{ padding: "16px 0 0px 0" }}>
                <span className="text">Shapes</span>
            </div>
            <SimpleBar
                style={{
                    maxHeight: "50%",
                    minHeight: "50%",
                }}
            >
                <div className="list-resource-item-shape">
                    {dataShape.map((value) => {
                        let resourceItem = getResourceItemShape(value);
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={"resource-item"}
                            ></ResourceItemComponent>
                        );
                    })}
                </div>
            </SimpleBar>
            <div className="column">
                <span className="text">Lines</span>

                <div className="list-resource-item-line">
                    {dataLine.map((value) => {
                        let resourceItem = getResourceItemLine(value);
                        return (
                            <ResourceItemComponent
                                key={resourceItem.id}
                                resourceItem={resourceItem}
                                typeResource={activeTab}
                                classNameItem={"resource-item"}
                            ></ResourceItemComponent>
                        );
                    })}
                </div>

                <div className="row">
                    <span className="text">Show The Answer Key</span>
                    <Checkbox
                        checked={isShowAnswerKey}
                        onChange={(e) =>
                            dispatch(upDateShowAnswerKey(e.target.checked))
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={!isShowButtonAnswerKey}
                    />
                </div>
            </div> */}
        </div>
    );
}

export default TabShapes;
