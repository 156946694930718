import { ICollection } from "../../../shared/models/collection";
import { IDownloadWorksheet } from "../../../shared/models/downloadWorksheets";
import { IPracticeWorksheet } from "../../../shared/models/practiceWorksheets";
import { IUserInfo, UserInfo } from "../../../shared/models/userInfo";
import { IUserRela } from "../../../shared/models/userRela";
import { IWorksheet } from "../../../shared/models/worksheet";
import { UserTypes } from "../action/user.action";

export interface IProfileResourceState {
    userInfo: IUserInfo;
    userRelas: IUserRela[];
    worksheets: IWorksheet[];
    collections: ICollection[];
    loading: boolean;
    uuId: string;
    numberOfWorksheets: number;
    totalFollower: number;
    downloadWorksheets: IDownloadWorksheet[];
    practiceWorksheets: IPracticeWorksheet[];
    isFollowing: IUserRela;
    nbWorksheetInCollection: number;
}

const initialState: IProfileResourceState = {
    worksheets: [],
    userRelas: [],
    collections: [],
    userInfo: new UserInfo(),
    loading: false,
    uuId: "",
    numberOfWorksheets: 0,
    totalFollower: 0,
    downloadWorksheets: [],
    practiceWorksheets: [],
    isFollowing: null,
    nbWorksheetInCollection: 0,
};

function profileResourceState(
    state: IProfileResourceState = initialState,
    action: any
): IProfileResourceState {
    // let state = JSON.parse(JSON.stringify(initState));
    switch (action.type) {
        case UserTypes.GET_USER_INFO: {
            return { ...state, loading: true };
        }
        case UserTypes.GET_MORE_PROFILE_INFO_SUCCESS: {
            const newState = {
                ...state,
            };
            if (action.numberOfWorksheets) {
                newState.numberOfWorksheets = action.numberOfWorksheets;
            }
            if (action.collections) {
                newState.collections = [
                    ...newState.collections,
                    ...action.collections,
                ];
            }
            if (action.totalFollower) {
                newState.totalFollower = action.totalFollower;
            }
            if (action.userInfo) {
                if (!newState.userInfo.id) {
                    newState.userInfo = action.userInfo;
                }
            }
            if (action.isFollowing) {
                newState.isFollowing = action.isFollowing;
            }
            if (action.nbWorksheetInCollection) {
                newState.nbWorksheetInCollection =
                    action.nbWorksheetInCollection;
            }
            return { ...newState, loading: false };
        }

        case UserTypes.GET_USER_INFO_SUCCES:
            return {
                ...state,
                loading: false,
                userInfo: new UserInfo(action?.userInfo),
            };
        case UserTypes.SET_USERid:
            return { ...state, uuId: action.id };
        case UserTypes.COLLECTIONS_PROFILE:
            let collections = [];
            if (action.collections?.length) {
                let numbWorksheets = 0;
                action.collections.forEach(
                    (c) => (numbWorksheets += c.numberOfWorksheets)
                );
                collections = action.collections;
            }
            return { ...state, collections };
        case UserTypes.UPDATE_USER_RELA:
            let userRelas = [...state.userRelas];
            if (action.unFollow) {
                userRelas = userRelas.filter(
                    (item) => item.id !== action.userRela?.id
                );
            }
            if (action.userRela && !action.unFollow) {
                userRelas = [...userRelas, action.userRela];
            }

            return { ...state, userRelas };
        case UserTypes.UPDATE_FOLLOWING:
            let totalFollower = state.totalFollower;
            if (action?.isFollowing?.id) {
                totalFollower = totalFollower + 1;
            } else {
                totalFollower = totalFollower - 1;
            }
            return {
                ...state,
                isFollowing: action.isFollowing,
                totalFollower,
            };
        case "userInfo/loginSuccess":
            let { userInfo } = action.payload;

            return {
                ...state,
                userInfo: userInfo,
            };
        case UserTypes.LOGIN_WITH_POPUP_RESOURCE_SUCCESS:
            return { ...state, loading: false, userInfo: action.userInfo };
        default:
            return state;
    }
}

export default profileResourceState;
