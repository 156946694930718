import Config from "../../shared/utils/config";
import categories from "../data/category-data.json";
import { getCurrentInfo } from "./categoryUtils";

const getBrowserInfo = (data, appName) => {
    let nAgt = data;
    let browserName = appName;
    let fullVersion = "" + parseFloat(data);
    let majorVersion = parseInt(data, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 4);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
        browserName = "Microsoft Edge";
        fullVersion = nAgt.substring(verOffset + 4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
        (nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))
    ) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = "" + parseFloat(data);
        majorVersion = parseInt(data, 10);
    }

    return {
        browserName,
        fullVersion,
        majorVersion,
    };
};

export const getUserInfo = () => {
    try {
        if (window?.navigator) {
            const { userAgent, appVersion, appName } = window.navigator;
            const data = appVersion ? appVersion : userAgent;

            let OSName = "Unknown OS";
            if (data.indexOf("Win") !== -1) OSName = "Windows";
            if (data.indexOf("Mac") !== -1) OSName = "MacOS";
            if (data.indexOf("X11") !== -1) OSName = "UNIX";
            if (data.indexOf("Linux") !== -1) OSName = "Linux";

            const result = getBrowserInfo(data, appName);

            return {
                OSName,
                innerWidth: window?.innerWidth ?? 0,
                innerHeight: window?.innerHeight ?? 0,
                ...result,
            };
        }
    } catch (error) {
        // console.log('get info error', error);
    }

    return {};
};

export const isLandingSightWords = ({
    router,
    categoryIds = [],
}: {
    router?: any;
    categoryIds?: string[];
}) => {
    let result = false;
    try {
        const SIGHT_WORDS_SLUGS = [
            "english-language-arts-(ela)",
            "sight-words",
        ];

        if (categoryIds?.length > 0) {
            const tempSlugs = [...SIGHT_WORDS_SLUGS];
            try {
                categoryIds?.forEach((categoryId: string) => {
                    const category = getCurrentInfo(
                        categoryId,
                        categories[0].children
                    );
                    const index = tempSlugs.findIndex(
                        (s) => s === category?.slug
                    );
                    if (index > -1) {
                        tempSlugs.splice(index, 1);
                    }
                });
            } catch (error) {}

            if (tempSlugs.length === 0) {
                result = true;
            }
        }

        if (router) {
            const SIGHT_WORDS_PATH = "/english-language-arts-(ela)/sight-words";
            const path = router?.asPath;
            const slugs = router?.query?.slugs;
            if (
                path === SIGHT_WORDS_PATH ||
                (SIGHT_WORDS_SLUGS[0] === slugs?.[0] &&
                    SIGHT_WORDS_SLUGS[1] === slugs?.[1])
            ) {
                result = true;
            }
        }
    } catch (error) {
        console.log("error", error);
    }
    return result;
};

export const checkRoutes = (pathName: string, routes: string) => {
    return pathName.includes(routes);
};

export const isClaimExist = () => {
    const claimElement = document?.querySelector(
        "." + Config.CLASS_DO_NOT_DELETE
    );

    return !!claimElement;
};
