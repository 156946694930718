import { resourceCanEditTextGroup, resourceCanEditTextStyle } from ".";
import { getListTextStyles } from "../resource-user/utils";
import {
    IResourceItemNew,
    ITextAttribute,
    TextAttribute,
} from "../shared/models/resourceItemNew";
import { TableAttribute } from "../shared/models/tableAttribute";
import { ISubText, SelectedText, SubText } from "../shared/models/textStyle";
import ConstantsTool from "../shared/utils/ConstantsTool";

export const checkDuplicateSubtext = (
    previous: ISubText,
    current: ISubText | ITextAttribute
) => {
    return (
        previous.isBold === current.isBold &&
        previous.isItalic === current.isItalic &&
        previous.underline === current.underline &&
        previous.fontColor === current.fontColor &&
        previous.fontSize === current.fontSize &&
        previous.fontFamily === current.fontFamily && 
        previous.isStroke === current.isStroke && 
        previous.strokeWidth === current.strokeWidth
    );
};

export function condenseDuplicatesSubtext(arr: ISubText[]) {
    if (!Array.isArray(arr) || !arr.length) {
        return [];
    }

    const condensedArray = [];
    let currentElement = arr[0];
    let count = 1;
    for (let i = 1; i < arr.length; i++) {
        let element = arr[i];
        let previous = arr[i - 1];
        if (
            checkDuplicateSubtext(previous, element) &&
            previous.endOffset === element.startOffset
        ) {
            count++;
        } else {
            let newElement = currentElement;
            if (count > 1) {
                newElement = { ...newElement, endOffset: previous.endOffset };
            }
            condensedArray.push(newElement);
            currentElement = element;
            count = 1;
        }
    }

    let newElement = currentElement;
    let maxLength = arr.length;
    if (count > 1) {
        newElement = { ...newElement, endOffset: arr[maxLength - 1].endOffset };
    }
    condensedArray.push(newElement);
    return condensedArray;
}

const findListSelection = (subTexts: SubText[], selectedText: SelectedText) => {
    const { startOffset, endOffset } = selectedText;
    let singlePosition = selectedText.startOffset === selectedText.endOffset;
    return subTexts.filter(
        (e) =>
            (e.startOffset >= startOffset ||
                (e.startOffset < startOffset &&
                    (singlePosition
                        ? e.endOffset >= startOffset
                        : e.endOffset > startOffset))) &&
            e.startOffset < endOffset
    );
};

export const changeStyleText = ({
    textAttribute,
    field,
    value,
    selectedText,
}: {
    textAttribute: ITextAttribute;
    field: string;
    value: any;
    selectedText?: SelectedText;
}) => {
    let newTextAttribute = new TextAttribute(textAttribute);
    if (!textAttribute.hasOwnProperty(field)) return newTextAttribute;
    const { subTexts } = newTextAttribute;
    if (!selectedText) selectedText = { startOffset: -1, endOffset: -1 };
    let { startOffset, endOffset } = selectedText;
    const selectedAll =
        selectedText.endOffset - selectedText.startOffset ===
            textAttribute.content.length ||
        (startOffset < 0 && endOffset < 0);
    if (
        selectedText &&
        selectedText.endOffset > selectedText.startOffset &&
        !selectedAll
    ) {
        let listText = getListTextStyles(newTextAttribute);
        let listSelected = listText.filter(
            (e) =>
                (e.startOffset >= startOffset ||
                    (e.startOffset <= startOffset &&
                        e.startOffset + e.text.length - 1 >= startOffset)) &&
                e.startOffset < endOffset
        );
        // console.log("list text :", listText);
        // console.log("list seleed :", listSelected);
        if (listSelected.length) {
            listSelected.forEach((element) => {
                let subText = newTextAttribute.subTexts.find(
                    (e) => e.startOffset === element.startOffset
                );

                if (
                    subText &&
                    subText.endOffset <= endOffset &&
                    subText.startOffset >= startOffset
                ) {
                    subText[field] = value;
                } else {
                    if (subText) {
                        let endOffsetEl = subText.endOffset;
                        let startOffsetEl = subText.startOffset;
                        if (
                            startOffsetEl >= startOffset &&
                            endOffset < endOffsetEl
                        ) {
                            subText.startOffset = endOffset;
                            subText.endOffset = endOffsetEl;
                        } else if (
                            startOffsetEl < startOffset &&
                            endOffsetEl <= endOffset
                        ) {
                            subText.endOffset = startOffset;
                        } else {
                            subText.endOffset = startOffset;
                            let newSubText = new SubText({
                                ...subText,
                                startOffset: endOffset,
                                endOffset: endOffsetEl,
                            });
                            newTextAttribute.subTexts.push(newSubText);
                        }
                        let newSubText = new SubText({
                            ...subText,
                            startOffset: Math.max(
                                startOffset,
                                element.startOffset
                            ),
                            endOffset: Math.min(endOffset, endOffsetEl),
                        });
                        newSubText[field] = value;
                        newTextAttribute.subTexts.push(newSubText);
                    } else {
                        let newSubText = new SubText({
                            ...textAttribute,
                            startOffset: Math.max(
                                startOffset,
                                element.startOffset
                            ),
                            endOffset: Math.min(
                                endOffset,
                                element.startOffset + element.text.length
                            ),
                        });
                        newSubText[field] = value;
                        newTextAttribute.subTexts.push(newSubText);
                    }
                }
            });
        } else {
            let newSubText = new SubText({
                ...textAttribute,
                endOffset: selectedText.endOffset,
                startOffset: selectedText.startOffset,
            });
            newSubText[field] = value;
            newTextAttribute.subTexts.push(newSubText);
        }
    } else {
        // console.log("edit all text attribute");
        let listSelected = [];
        if (!selectedAll)
            listSelected = findListSelection(subTexts, selectedText);
        if (listSelected.length) {
            listSelected.forEach((subText) => {
                subText[field] = value;
            });
        } else {
            newTextAttribute[field] = value;
        }
        let listIndexs: number[] = [];
        if (selectedAll)
            newTextAttribute.subTexts.forEach((subText) => {
                if (!listSelected.length) {
                    subText[field] = value;
                }
                if (checkDuplicateSubtext(subText, newTextAttribute)) {
                    listIndexs.push(subText.startOffset);
                }
            });
        listIndexs.forEach((index) => {
            let findIndex = newTextAttribute.subTexts.findIndex(
                (subText) => subText.startOffset === index
            );
            if (findIndex >= 0) {
                newTextAttribute.subTexts.splice(findIndex, 1);
            }
        });
    }
    newTextAttribute.subTexts.sort((a, b) => a.startOffset - b.startOffset);
    newTextAttribute.subTexts = condenseDuplicatesSubtext(
        newTextAttribute.subTexts
    );
    if (newTextAttribute.subTexts.length === 1) {
        let subText = newTextAttribute.subTexts[0];
        if (checkDuplicateSubtext(subText, textAttribute)) {
            newTextAttribute.subTexts = [];
        }
    }
    return newTextAttribute;
};

export const makeTextAttributeSelectedText = (
    textAttribute: ITextAttribute,
    selectedText: SelectedText
) => {
    let newTextAttribute = new TextAttribute(textAttribute);
    const { subTexts } = newTextAttribute;
    if (subTexts.length) {
        let listSelected = [];
        listSelected = findListSelection(subTexts, selectedText);
        if (listSelected.length) {
            let currentSelected = listSelected[0];
            for (let i = 1; i < listSelected.length; i++) {
                let item = listSelected[i];
                if (currentSelected.fontColor !== item.fontColor) {
                    currentSelected.fontColor = "#212121";
                }
                if (currentSelected.isBold !== item.isBold) {
                    currentSelected.isBold = false;
                }
                if (currentSelected.isItalic !== item.isItalic) {
                    currentSelected.isItalic = false;
                }
                if (currentSelected.fontFamily !== item.fontFamily) {
                    currentSelected.fontFamily = textAttribute.fontFamily;
                }
                if (currentSelected.fontSize !== item.fontSize) {
                    currentSelected.fontSize = textAttribute.fontSize;
                }
            }
            newTextAttribute = { ...newTextAttribute, ...currentSelected };
        }
    }
    return newTextAttribute;
};

export const getTextAttribute = (resourceItems: IResourceItemNew[]) => {
    resourceItems = resourceItems.filter((resourceItem) =>
        resourceCanEditTextGroup(resourceItem)
    );
    let textAttributes = resourceItems.map((resourceItem) => {
        if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            return new TableAttribute(
                resourceItem.tableAttribute
            ).getTextAtribute();
        }
        return resourceItem.textAttribute;
    });
    let textAttribute: ITextAttribute = new TextAttribute();
    if (textAttributes.length) {
        textAttribute = { ...textAttributes[0] };
        let colors = [textAttribute.fontColor];
        textAttributes.forEach((text) => {
            if (!colors.includes(text.fontColor)) colors.push(text.fontColor);
            if (textAttribute.align !== text.align) {
                textAttribute.align = "center";
            }
            if (textAttribute.fontColor !== text.fontColor) {
                textAttribute.fontColor = "#000";
            }
            if (textAttribute.fontSize !== text.fontSize) {
                textAttribute.fontSize = null;
            }
            if (textAttribute.isBold !== text.isBold) {
                textAttribute.isBold = false;
            }
            if (textAttribute.isItalic !== text.isItalic) {
                textAttribute.isItalic = false;
            }
            if (textAttribute.underline !== text.underline) {
                textAttribute.underline = false;
            }
            if (textAttribute.fontFamily !== text.fontFamily) {
                textAttribute.fontFamily = "";
            }
            if (textAttribute.lineHeight !== text.lineHeight)
                textAttribute.lineHeight = ConstantsTool.LINE_HEIGHT_DEFAULT;
            if (textAttribute.letterSpacing !== text.letterSpacing)
                textAttribute.letterSpacing = 0;
        });
        if (colors.length > 1) textAttribute.fontColors = colors;
    }
    return textAttribute;
};
