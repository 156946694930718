import { useEffect, useRef, useSyncExternalStore } from "react";
import ElementWorksheet from "..";
import { useAppSelector } from "../../../../../redux/hook";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import groupElementStore from "../../../../../syncExternalStore/groupElementStore";

function GroupResourceItem({
    resourceItem,
    currentElement,
    setCurrentElement,
    setSpaceToTopLeft,
    setActiveDarg,
    handleImageLoad,
}: {
    resourceItem: IResourceItemNew;
    currentElement?: IResourceItemNew;
    setCurrentElement: any;
    setSpaceToTopLeft: (value: any) => void;
    setActiveDarg: any;
    handleImageLoad: () => void;
}) {
    const resourceItems = useAppSelector(
        (state) =>
            state.createWorksheetState.pagesWorksheet[resourceItem.pageIndex]
                .resourceItems
    );
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );
    let ref = useRef(null);

    let currentItem = currentElement ?? resourceItem;
    let resourceItemsGroup = resourceItem.id?.includes("tmp-")
        ? []
        : resourceItems.filter((item) =>
              resourceItem.resourceIds.includes(item.id)
          );

    useEffect(() => {
        if (resourceItem.resourceIds.includes(groupElementState.selectedId)) {
            groupElementStore.addResourceItems(
                resourceItems.filter((element) =>
                    resourceItem.resourceIds.includes(element.id)
                )
            );
            groupElementStore.updateResourceGroup(resourceItem);
        }
    }, [groupElementState.selectedId]);
    let rotate = currentItem.rotate;
    return (
        <div
            id={
                resourceItemsGroup.length
                    ? "group-item-" + resourceItem.id
                    : null
            }
            style={{
                width: currentItem.width,
                height: currentItem.height,
                background: "transparent",
                transform: `rotate(${-rotate}deg)`,
            }}
        >
            <div
                style={{
                    position: "absolute",
                    width: currentItem.width,
                    height: currentItem.height,
                    background: "transparent",
                    outline: resourceItem.resourceIds.includes(
                        groupElementState.selectedId
                    )
                        ? "4px dashed rgba(33,33,33,0.18)"
                        : "none",
                    transform: `rotate(${rotate}deg)`,
                }}
            ></div>
            {resourceItemsGroup.map((resourceItem, index) => {
                return (
                    <ElementWorksheet
                        ref={
                            groupElementState.selectedId === resourceItem.id
                                ? ref
                                : null
                        }
                        currentElement={
                            groupElementState.selectedId === resourceItem.id
                                ? groupElementState.resourceItemsCurrent.find(
                                      (e) => e.id === resourceItem.id
                                  )
                                : null
                        }
                        // setOnDrag={setOnDrag}
                        canClick={true}
                        setCurrentElement={setCurrentElement}
                        key={resourceItem.id}
                        resourceId={resourceItem.id}
                        pageIndex={currentItem.pageIndex}
                        setSpaceToTopLeft={setSpaceToTopLeft}
                        originPosition={{
                            x: currentItem.x,
                            y: currentItem.y,
                        }}
                        setActiveDarg={setActiveDarg}
                        handleImageLoad={handleImageLoad}
                    />
                );
            })}
        </div>
    );
}

export default GroupResourceItem;
