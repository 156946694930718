import makeStyles from "@mui/styles/makeStyles";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import { RootState } from "../../../../../../redux/store";
import { ListStyle } from "../../../../../../shared/models/WorkSheetCreator";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import Config from "../../../../../../shared/utils/config";
import { getTextEffectStyle, isSafari } from "../../../../../../utils";
import "./index.scss";
import { getBoundingTextBox } from "../../../../../../resource-user/utils";

const convertTextToHtmlFromResource = ({
    content,
    listIndexWord,
    strokeType,
    showAnswerKey,
    listStyle,
    color,
}: {
    content: string;
    listIndexWord: number[];
    strokeType: string;
    showAnswerKey: number;
    listStyle: ListStyle;
    color: string;
}) => {
    let variableStrokeType = (
        !!strokeType ? strokeType : "Solid"
    ).toLowerCase();
    let defaultSpan = `<span class='canvas-text sentence-text ${
        isSafari() ? "isSafari" : ""
    } ${
        showAnswerKey == Config.SHOW_VALUE ? "show" : "hide"
    }'  style='--strokeType: ${variableStrokeType}; --color-style-anwser: ${color}'>#text_replace#</span>`;
    let indexEndPrefix = 0;
    switch (listStyle) {
        case ListStyle.INDEX:
            indexEndPrefix = content.indexOf(".") + 2;
            break;
        case ListStyle.BULLET_POINT:
            indexEndPrefix = content.indexOf("-") + 2;
            break;
        case ListStyle.LIST_MARKER:
            indexEndPrefix = 0;
            break;
        case ListStyle.NONE:
            indexEndPrefix = 0;
            break;
    }
    let prefixContent = content.substring(0, indexEndPrefix);

    let splitContent = content.substring(indexEndPrefix).split(" ");
    listIndexWord.forEach((indexWord) => {
        splitContent[indexWord] = defaultSpan.replace(
            "#text_replace#",
            splitContent[indexWord]
        );
    });
    return prefixContent + splitContent.join(" ");
};
const TextFillTheBlank = ({
    sentenceData,
    currentElement,
    setCurrentElement,
}: {
    sentenceData: IResourceItemNew;
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) => {
    const dispatch = useAppDispatch();
    const textAttribute =
        currentElement?.textAttribute ?? sentenceData.textAttribute;

    useEffect(() => {
        if (currentElement) {
            let check = false;
            Object.keys(textAttribute).forEach((key) => {
                if (textAttribute[key] !== currentElement.textAttribute[key]) {
                    check = true;
                }
            });
            if (check) {
                setCurrentElement({ ...sentenceData });
            }
        }
    }, [textAttribute, sentenceData?.question?.answers]);
    useEffect(() => {
        if (currentElement) {
            setCurrentElement({ ...sentenceData });
        }
    }, [sentenceData?.question?.answers]);

    const color = getTextEffectStyle(sentenceData).fill
        ? getTextEffectStyle(sentenceData).fill
        : textAttribute.fontColor
        ? textAttribute.fontColor
        : "#212121";
    const useStyles = makeStyles((theme) => ({
        listMarker: {
            position: "relative",
            paddingLeft: "1em",
            "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: textAttribute.lineHeight
                    ? (textAttribute.lineHeight * textAttribute.fontSize) / 2
                    : "0.72em",
                transform: "translateY(-50%)",
                width: "0.5em",
                height: "0.5em",
                background: color,
                borderRadius: "50%",
            },
        },
    }));
    const strokeType = useAppSelector(
        (state: RootState) =>
            state.createWorksheetState?.currentActivity?.strokeType
    );
    const showAnswerKey = useAppSelector(
        (state: RootState) =>
            state.createWorksheetState.currentActivity.showAnswerKey
    );
    const listStyle = useAppSelector(
        (state) => state.createWorksheetState.currentActivity?.listStyle
    );

    const classes = useStyles();
    const boinding = getBoundingTextBox(textAttribute);
    return (
        <div className="sentence">
            <div
                className={
                    "canvas-text " +
                    (listStyle === ListStyle.LIST_MARKER
                        ? classes.listMarker
                        : "")
                }
                style={{
                    position: "absolute",
                    top: boinding.top,
                    width: currentElement?.width ?? sentenceData.width,
                    fontSize: textAttribute.fontSize + "px",
                    fontFamily: textAttribute.fontFamily,
                    fontStyle: textAttribute.isItalic ? "italic" : "normal",
                    fontWeight: textAttribute.isBold ? "bold" : "normal",
                    textAlign: textAttribute.align ?? "justify",
                    color: color,
                    wordBreak: "unset",
                    lineHeight: textAttribute.lineHeight,
                    letterSpacing: textAttribute.letterSpacing + "em",
                }}
                dangerouslySetInnerHTML={{
                    __html: sentenceData?.question.answers.length
                        ? convertTextToHtmlFromResource({
                              content: textAttribute.content,
                              listIndexWord: sentenceData.question.answers.map(
                                  (el) => el.indexWord
                              ),
                              showAnswerKey,
                              strokeType,
                              listStyle,
                              color,
                          })
                        : textAttribute.content,
                }}
            ></div>
        </div>
    );
};
export default TextFillTheBlank;
