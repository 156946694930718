import React from "react";

const DeleteIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12"
                cy="12"
                r="12"
                fill={isActive ? "#F3F3F3" : "#9C9C9C"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8597 6.19333L14.333 6.66667H15.9997C16.3663 6.66667 16.6663 6.96667 16.6663 7.33333C16.6663 7.7 16.3663 8 15.9997 8H7.99967C7.63301 8 7.33301 7.7 7.33301 7.33333C7.33301 6.96667 7.63301 6.66667 7.99967 6.66667H9.66634L10.1397 6.19333C10.2597 6.07333 10.433 6 10.6063 6H13.393C13.5663 6 13.7397 6.07333 13.8597 6.19333ZM9.33301 18C8.59967 18 7.99967 17.4 7.99967 16.6667V10C7.99967 9.26667 8.59967 8.66667 9.33301 8.66667H14.6663C15.3997 8.66667 15.9997 9.26667 15.9997 10V16.6667C15.9997 17.4 15.3997 18 14.6663 18H9.33301Z"
                fill={"black"}
                fillOpacity={"0.54"}
            />
        </svg>
    );
};

export default DeleteIcon;
