import { POST } from ".";
import { Collection, ICollection } from "../../../shared/models/collection";
import Config from "../../../shared/utils/config";
import { APIConfig } from "../../utils/api-config";

export const updateItemCollectionByIdAPI = (
    args
): Promise<ICollection | null> => {
    return POST({
        url: APIConfig.UPDATE_ITEM_COLLECTION,
        params: args,
    }).then((data: any) => {
        return (
            data &&
            new Collection({
                ...data,
                typeGrid: Config.TYPE_GRID.TYPE_FOLDER_COLLECTION,
            })
        );
    });
};

export const deleteCollectionByIdResourceAPI = (args: {
    collectionId: string;
}): Promise<any | null> => {
    return POST({
        url: APIConfig.REMOVE_COLLECTION,
        params: args,
    }).then((data: any) => {
        return data;
    });
};
export const getDataCollectionResourceV2API = (args: {
    collectionId: string;
    userId: string;
}) => {
    return POST({
        url: APIConfig.GET_DATA_COLLECTION_V2,
        params: args,
    }).then((data: any) => {
        if (data?.dataCollection?.length) {
            let newDataCollection = data?.dataCollection?.map((c) => {
                c.typeGrid = Config.TYPE_GRID.TYPE_FOLDER_COLLECTION;
                return c;
            });
            return {
                ...data,
                dataCollection: newDataCollection,
            };
        }
        return data;
    });
};

export const moveWorksheetToCollectionByIdAPI = (
    args
): Promise<ICollection[] | null> => {
    return POST({
        url: APIConfig.MOVE_WORKSHEET_TO_COLLECTION,
        params: args,
    }).then((data: any) => {
        return data;
    });
};

export const uploadBase64ToGoogleCloud = async (args: {
    imagesBase64: string[];
}) => {
    try {
        return POST({
            url: APIConfig.UPLOAD_BASE64_TO_GOOGLE_CLOUD,
            params: {
                imagesBase64: args.imagesBase64,
            },
        }).then((data: any) => {
            return data;
        });
    } catch (e) {}
};