import { useEffect, useLayoutEffect, useRef, useState } from "react";
import FontFamilyPicker from "./font-family-picker";
import FontSizePicker from "./font-size-picker";
import {
    AlignCenterIcon,
    AlignJustifyIcon,
    AlignLeftIcon,
    AlignRightIcon,
    UnderLineIcon,
} from "../../../../../../assets/icon";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    updateInitStaticMathField,
    updateResourceItem,
    updateResourceItems,
    updateSelectedText,
    updateStaticMathFieldFontSize
} from "../../../../../../redux/reducers/createWorksheet";
import {
    IResourceItemNew,
    ITextAttribute,
    TextAttribute,
} from "../../../../../../shared/models/resourceItemNew";
import { TableAttribute } from "../../../../../../shared/models/tableAttribute";
import ConstantsTool from "../../../../../../shared/utils/ConstantsTool";
import groupElementStore from "../../../../../../syncExternalStore/groupElementStore";
import {
    canEditSpacingText, canEditVerticalAlign, getPrefixImage,
    getSizeResourceText, canConfigAutoFontSize, syncTemplateLinkedTextToZone,
    canConfigContainerStyles
} from "../../../../../../utils";
import {
    changeStyleText,
    getTextAttribute,
    makeTextAttributeSelectedText,
} from "../../../../../../utils/edit-text-attribute";
import { changeFontAttributes } from "../../../../../../utils/table";
import "./index.scss";
import EditSpacingView from "./spacing";
import ControlledAutoFontSize from './controlled-auto-font-size';
import VerticalAlign from "./vertical-align";
import TextCaseIcon from "./text-case-icon";
import TextStroke from './text-stroke';
import ChooseColor from './choose-color';
import BackdropLayout from './backdrop-layout';
import ZoneContainerStylesSetting from './zone-container-styles-setting';

const TextStyleBar = ({
    currentElement,
    setCurrentElement,
    resourceItems,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: (currentElement: IResourceItemNew) => void;
    resourceItems: IResourceItemNew[];
}) => {
    const dispatch = useAppDispatch();
    const styleBarRef = useRef(null);

    const [isDisplayFontFamilyPanel, setIsDisplayFontFamilyPanel] =
        useState(false);
    const [openFontSize, setOpenFontSize] = useState(false);

    const listStyle = useAppSelector(
        (state) => state.createWorksheetState.currentActivity?.listStyle
    );
    const pageIndex = useAppSelector(
        (state) => state.createWorksheetState.pageIndex
    );
    const [textAttribute, setTextAttribute] = useState(
        currentElement?.textAttribute ?? new TextAttribute()
    );
    const selectedText = useAppSelector(
        (state) => state.createWorksheetState.selectedText
    );
    const { tempTemplateZones } = useAppSelector(
        (state) => state.createWorksheetState
    );
    const isGroup = currentElement.resourceIds?.length > 1;

    useLayoutEffect(() => {
        let textAttribute = currentElement?.textAttribute;
        if (!currentElement?.textAttribute) {
            textAttribute = new TextAttribute();
        }

        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            let tableAttribute = new TableAttribute(
                currentElement.tableAttribute
            );
            textAttribute = tableAttribute.getTextAtribute();
        }
        if (selectedText.endOffset >= 0 && selectedText.startOffset >= 0) {
            textAttribute = makeTextAttributeSelectedText(
                textAttribute,
                selectedText
            );
        }
        if (isGroup) {
            textAttribute = getTextAttribute(resourceItems);
        }
        setTextAttribute(textAttribute);
    }, [currentElement, selectedText, resourceItems]);

    useEffect(() => {
        if (openFontSize) {
            setIsDisplayFontFamilyPanel(false);
        }
    }, [openFontSize]);

    useEffect(() => {
        if (selectedText.endOffset >= 0)
            dispatch(updateSelectedText({ endOffset: -1, startOffset: -1 }));
    }, [currentElement?.id]);

    const updateFontStyleGroup = (field: string, value: any) => {
        let result = groupElementStore.updateFontStyle(
            field,
            value,
            listStyle,
            currentElement
        );
        dispatch(
            updateResourceItems({
                pageIndex,
                resourceItems: [...result.resourceItems, result.resourceParent],
            })
        );
        setCurrentElement({ ...result.resourceParent });
    };

    const updateResource = (resourceItem: IResourceItemNew) => {
        dispatch(
            updateResourceItems({
                pageIndex: currentElement.pageIndex,
                resourceItems: [resourceItem],
            })
        );
        setCurrentElement({
            ...resourceItem,
        });
    };

    const changeFontAttributeTable = (
        field: string,
        value: string | number
    ) => {
        let tableAttribute = new TableAttribute(currentElement.tableAttribute);
        let { dHeight } = changeFontAttributes({
            field,
            value,
            tableAttribute,
        });
        updateResource({
            ...currentElement,
            tableAttribute,
            height: currentElement.height + dHeight,
        });
    };

    const upDateNewResource = (textAttribute: ITextAttribute) => {
        let size = getSizeResourceText(
            textAttribute,
            currentElement,
            listStyle
        );
        let newResource = {
            ...currentElement,
            textAttribute: textAttribute,
            // ...size,
        };

        setCurrentElement(newResource);
        let resourceItems: IResourceItemNew[] = [newResource];
        if (currentElement.isGroup) {
            let resourceItem = groupElementStore.resizeParentGroup(newResource);
            if (resourceItem) {
                resourceItems.push(resourceItem);
            }
        }
        dispatch(
            updateResourceItems({
                pageIndex,
                resourceItems,
            })
        );
        for (let i = 0; i < resourceItems.length; i++) {
            syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, resourceItems[i].customZoneID, { textAttribute })
        }
    };

    const handleChangeFontAttribute = (
        field: string,
        value: any,
    ) => {
        if (isGroup) {
            updateFontStyleGroup(field, value);
            return;
        }
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            changeFontAttributeTable(field, value);
            return;
        }
        let newTextAttribute = changeStyleText({
            textAttribute: currentElement?.textAttribute,
            field,
            value,
            selectedText: selectedText,
        });
        if (field === 'fontSize' && currentElement.textAttribute.isAppliedAutoFontSize) {
            newTextAttribute = changeStyleText({
                textAttribute: newTextAttribute,
                field: 'isAppliedAutoFontSize',
                value: false,
                selectedText: selectedText,
            });
        }
        upDateNewResource(newTextAttribute);
    };

    const changeStyleTextCells = (field: string, value: any) => {
        let tableAttribute = new TableAttribute(currentElement.tableAttribute);
        tableAttribute.changeStyleTextCells(field, value);
        console.log('change color', field, value);
        updateResource({
            ...currentElement,
            tableAttribute,
        });
    };

    const changeStyleValue = (field: string, value: any) => {
        if (isGroup) {
            updateFontStyleGroup(field, value);
            return;
        }
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            changeStyleTextCells(field, value);
            return;
        }
        let newTextAttribute = changeStyleText({
            textAttribute: currentElement?.textAttribute,
            field,
            value,
            selectedText: selectedText,
        });
        upDateNewResource(newTextAttribute);
    };

    const alignValues = ["left", "center", "right", "justify"];

    const getTextAlignIcon = (alignValue, color?: string) => {
        switch (alignValue) {
            case "left":
                return <AlignLeftIcon color={color ?? "#fff"} />;
            case "center":
                return <AlignCenterIcon color={color ?? "#fff"} />;
            case "right":
                return <AlignRightIcon color={color ?? "#fff"} />;
            case "justify":
                return <AlignJustifyIcon color={color ?? "#fff"} />;
            default:
                return <AlignJustifyIcon color={color ?? "#fff"} />;
        }
    };

    const changeAlign = () => {
        let index = alignValues.indexOf(textAttribute.align);
        if (index + 1 <= alignValues.length - 1) {
            index += 1;
        } else {
            index = 0;
        }
        let alignValue = alignValues[index];

        const field = "align";
        if (isGroup) {
            updateFontStyleGroup(field, alignValue);
            return;
        }
        if (currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE) {
            changeStyleTextCells(field, alignValue);
            return;
        }
        setCurrentElement({
            ...currentElement,
            textAttribute: {
                ...textAttribute,
                align: alignValue,
            },
        });
        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...textAttribute,
                            align: alignValue,
                        },
                    },
                ],
                pageIndex: pageIndex,
            })
        );
        syncTemplateLinkedTextToZone(dispatch, tempTemplateZones, currentElement.customZoneID, {
            textAttribute: {
                ...textAttribute,
                align: alignValue,
            }
        });
    };

    const handleChangecurrentMathFieldFontSize = async (value: number) => {
        dispatch(
            updateStaticMathFieldFontSize({ staticMathFieldFontSize: value })
        );
        dispatch(
            updateInitStaticMathField({
                latex: currentElement.textAttribute.content,
            })
        );
        // const { width, height } = await getMathFieldWidthHeightTool();
        dispatch(
            updateStaticMathFieldFontSize({ staticMathFieldFontSize: 24 })
        );
        dispatch(
            updateInitStaticMathField({
                latex: "",
            })
        );

        setCurrentElement({
            ...currentElement,
            textAttribute: {
                ...currentElement.textAttribute,
                isAppliedAutoFontSize: false,
                fontSize: value,
            },
            // height,
            // width,
        });
        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...currentElement.textAttribute,
                            isAppliedAutoFontSize: false,
                            fontSize: value,
                        },
                    },
                    // {
                    //     attr: "height",
                    //     value: height,
                    // },
                    // {
                    //     attr: "width",
                    //     value: width,
                    // },
                ],
                pageIndex: pageIndex,
            })
        );
    };

    return (
        <div
            ref={styleBarRef}
            className="text-style-bar-container"
            onMouseDown={(e) => e.stopPropagation()}
        >
            {currentElement.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA ? (
                <>
                    <BackdropLayout
                        open={openFontSize}
                        handleClose={() => setOpenFontSize(false)}
                    >
                        <FontSizePicker
                            open={openFontSize}
                            setOpen={setOpenFontSize}
                            fontSize={textAttribute?.fontSize}
                            handleChangeFontSize={(value: number) => {
                                handleChangecurrentMathFieldFontSize(value);
                            }}
                        />
                        <ChooseColor
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                            pageIndex={pageIndex}
                            changeStyleTextCells={changeStyleTextCells}
                            textAttribute={textAttribute}
                            selectedText={selectedText}
                            isGroup={isGroup}
                            updateFontStyleGroup={updateFontStyleGroup}
                        />

                        <div
                            className="function-button"
                            style={{ marginLeft: "8px" }}
                            onClick={() => {
                                changeAlign();
                            }}
                        >
                            {getTextAlignIcon(textAttribute.align, "#212121")}
                            <div className="tooltip-content">
                                <div className="arrow"></div>
                                <span className="text-tooltip">Alignment</span>
                            </div>
                        </div>
                        {canEditVerticalAlign(currentElement.type) && (
                            <div
                                className="function-button"
                                style={{ marginLeft: "8px", width: '100px' }}
                            >
                                <VerticalAlign
                                    currentElement={currentElement}
                                    setCurrentElement={setCurrentElement}
                                    changeStyleValue={(value: string) => changeStyleValue('verticalAlign', value)}
                                />
                                <div className="tooltip-content">
                                    <div className="arrow"></div>
                                    <span className="text-tooltip">Vertical Align</span>
                                </div>
                            </div>
                        )}
                    </BackdropLayout>
                </>
            ) : (
                <>
                    <BackdropLayout
                        open={isDisplayFontFamilyPanel}
                        handleClose={() => setIsDisplayFontFamilyPanel(false)}
                    >
                        <FontFamilyPicker
                            fontFamily={textAttribute.fontFamily}
                            isDisplayFontFamilyDropdown={
                                isDisplayFontFamilyPanel
                            }
                            setIsDisplayFontFamilyPanel={(value) => {
                                setIsDisplayFontFamilyPanel(value);
                            }}
                            handleChangeFontFamily={(value: string) =>
                                handleChangeFontAttribute("fontFamily", value)
                            }
                        />
                    </BackdropLayout>
                    <BackdropLayout
                        open={openFontSize}
                        handleClose={() => setOpenFontSize(false)}
                    >
                        <FontSizePicker
                            disableChanged={currentElement?.textAttribute?.isAppliedAutoFontSize}
                            open={openFontSize}
                            setOpen={setOpenFontSize}
                            fontSize={textAttribute?.fontSize}
                            handleChangeFontSize={(value: number) => {
                                handleChangeFontAttribute('fontSize', value);
                            }}
                        />
                    </BackdropLayout>

                    <ChooseColor
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                        pageIndex={pageIndex}
                        changeStyleTextCells={changeStyleTextCells}
                        textAttribute={textAttribute}
                        selectedText={selectedText}
                        isGroup={isGroup}
                        updateFontStyleGroup={updateFontStyleGroup}
                    />

                    <div
                        className={`function-button ${textAttribute.isBold ? "active" : ""
                            }`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            changeStyleValue("isBold", !textAttribute.isBold);
                        }}
                    >
                        <img
                            src={
                                getPrefixImage() +
                                "/images/create-worksheet/item-popover/" +
                                (textAttribute.isBold
                                    ? "bold_icon.svg"
                                    : "default_bold_icon.svg")
                            }
                            style={{ width: "14px", height: "14px" }}
                        />
                        <div className="tooltip-content">
                            <div className="arrow"></div>
                            <span className="text-tooltip">Bold</span>
                        </div>
                    </div>
                    <div
                        className={`function-button ${textAttribute.isItalic ? "active" : ""
                            }`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            changeStyleValue(
                                "isItalic",
                                !textAttribute.isItalic
                            );
                        }}
                    >
                        <img
                            src={
                                getPrefixImage() +
                                "/images/create-worksheet/item-popover/" +
                                (textAttribute.isItalic
                                    ? "italics_icon.svg"
                                    : "default_italic_icon.svg")
                            }
                            style={{ width: "14px", height: "14px" }}
                        />
                        <span className="text-italic">I</span>
                        <div className="tooltip-content">
                            <div className="arrow"></div>
                            <span className="text-tooltip">Italics</span>
                        </div>
                    </div>
                    {currentElement.type !==
                        ConstantsTool.TYPE_TEXT_FILL_THE_BLANK && (
                            <div
                                className={`function-button ${textAttribute.underline ? "active" : ""
                                    }`}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: 5,
                                }}
                                onClick={() => {
                                    changeStyleValue(
                                        "underline",
                                        !textAttribute.underline
                                    );
                                }}
                            >
                                <UnderLineIcon
                                    color={
                                        textAttribute.underline ? "#fff" : "#212121"
                                    }
                                ></UnderLineIcon>
                                <div className="tooltip-content">
                                    <div className="arrow"></div>
                                    <span className="text-tooltip">Underline</span>
                                </div>
                            </div>
                        )}
                    <div
                        className={`function-button ${textAttribute.textCase ? "active" : ""
                            }`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            changeStyleValue(
                                "textCase",
                                textAttribute.textCase === 'Aa' ? 'AA' : textAttribute.textCase === 'AA' ? 'aa' : textAttribute.textCase === 'aa' ? '' : 'Aa'
                            );
                        }}
                    >
                        <TextCaseIcon value={textAttribute.textCase} />
                        <div className="tooltip-content">
                            <div className="arrow"></div>
                            <span className="text-tooltip">Text case</span>
                        </div>
                    </div>
                    <TextStroke
                        textAttribute={textAttribute}
                        handleChangeTextStroke={(value: boolean) => {
                            handleChangeFontAttribute("isStroke", value)
                        }}
                        handleChangeStrokeWidth={(value: number) => {
                            handleChangeFontAttribute('strokeWidth', value);
                        }}
                        chooseColorChildren={
                            <ChooseColor
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                                pageIndex={pageIndex}
                                changeStyleTextCells={changeStyleTextCells}
                                textAttribute={textAttribute}
                                selectedText={selectedText}
                                isGroup={isGroup}
                                updateFontStyleGroup={updateFontStyleGroup}
                                field='strokeColor' />
                        }
                    />
                    <div
                        className="function-button"
                        style={{ marginLeft: "8px" }}
                        onClick={() => {
                            changeAlign();
                        }}
                    >
                        {getTextAlignIcon(textAttribute.align, "#212121")}
                        <div className="tooltip-content">
                            <div className="arrow"></div>
                            <span className="text-tooltip">Alignment</span>
                        </div>
                    </div>

                    {canEditSpacingText(currentElement.type) && (
                        <div
                            className="function-button"
                            style={{ marginLeft: "8px" }}
                        >
                            <EditSpacingView
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                                listStyle={listStyle}
                                updateFontStyleGroup={updateFontStyleGroup}
                                isGroup={isGroup}
                                currentTextAttribute={textAttribute}
                            />
                            <div className="tooltip-content">
                                <div className="arrow"></div>
                                <span className="text-tooltip">Spacing</span>
                            </div>
                        </div>
                    )}

                    {canEditVerticalAlign(currentElement.type) && (
                        <div
                            className="function-button"
                            style={{ marginLeft: "8px", width: '100px' }}
                        >
                            <VerticalAlign
                                currentElement={currentElement}
                                setCurrentElement={setCurrentElement}
                                changeStyleValue={(value: string) => changeStyleValue('verticalAlign', value)}
                            />
                            <div className="tooltip-content">
                                <div className="arrow"></div>
                                <span className="text-tooltip">Vertical Align</span>
                            </div>
                        </div>
                    )}
                    {canConfigContainerStyles(currentElement.type) && (
                        <ZoneContainerStylesSetting
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                            changeStyleValue={changeStyleValue}
                        />
                    )}
                </>
            )}
            {canConfigAutoFontSize(currentElement.type) && (
                <ControlledAutoFontSize
                    currentElement={currentElement}
                    setCurrentElement={setCurrentElement}
                    handleControlledAutoFontSize={(value: boolean) => {
                        handleChangeFontAttribute("isAppliedAutoFontSize", value)
                    }}
                />
            )}
        </div>
    );
};

export default TextStyleBar;
