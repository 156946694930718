export const ScratchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#D7E7F5"
                rx="16"
            ></rect>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#EEF7FE"
                strokeLinecap="round"
                strokeWidth="8"
                rx="16"
            ></rect>
            <rect
                width="1.818"
                height="10"
                x="19.091"
                y="15"
                fill="url(#paint0_linear_2083_46823)"
                rx="0.909"
            ></rect>
            <rect
                width="1.818"
                height="10"
                x="25"
                y="19.091"
                fill="url(#paint1_linear_2083_46823)"
                rx="0.909"
                transform="rotate(90 25 19.09)"
            ></rect>
            <defs>
                <linearGradient
                    id="paint0_linear_2083_46823"
                    x1="20"
                    x2="20"
                    y1="15"
                    y2="25"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#81BAE3"></stop>
                    <stop offset="1" stopColor="#7888DD"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2083_46823"
                    x1="25.909"
                    x2="25.909"
                    y1="19.091"
                    y2="29.091"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#81BAE3"></stop>
                    <stop offset="1" stopColor="#7888DD"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
};
export const InteractiveIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="4" y="4" width="32" height="32" rx="16" fill="#DFECFF" />
            <path
                d="M20 26.6666C23.6819 26.6666 26.6667 23.6819 26.6667 20C26.6667 16.3181 23.6819 13.3333 20 13.3333C16.3181 13.3333 13.3333 16.3181 13.3333 20C13.3333 23.6819 16.3181 26.6666 20 26.6666Z"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.34 21.6733C17.46 21.8733 17.6067 22.06 17.7734 22.2267C19 23.4534 20.9934 23.4534 22.2267 22.2267C22.7267 21.7267 23.0134 21.0933 23.1067 20.4467"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8933 19.5534C16.9867 18.9 17.2733 18.2733 17.7733 17.7733C19 16.5467 20.9933 16.5467 22.2267 17.7733C22.4 17.9467 22.54 18.1333 22.66 18.3267"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.2133 23.4533V21.6733H18.9933"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.7867 16.5467V18.3267H21.0067"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="4"
                y="4"
                width="32"
                height="32"
                rx="16"
                stroke="#F5F9FF"
                strokeWidth="8"
                strokeLinecap="round"
            />
        </svg>
    );
};
export const WordsearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#D5F3C2"
                rx="16"
            ></rect>
            <g fill="#96CB76" clipPath="url(#clip0_2083_46663)">
                <path d="M20.233 26.833h-2.466c-3.46 0-4.933-1.473-4.933-4.933v-3.8c0-3.46 1.473-4.934 4.933-4.934h3.8c3.46 0 4.933 1.474 4.933 4.934v2.466c0 .274-.227.5-.5.5a.504.504 0 01-.5-.5V18.1c0-2.907-1.026-3.934-3.933-3.934h-3.8c-2.907 0-3.933 1.027-3.933 3.934v3.8c0 2.907 1.026 3.933 3.933 3.933h2.466c.274 0 .5.227.5.5 0 .274-.226.5-.5.5z"></path>
                <path d="M20.667 21.987l-1.874-4.22-1.873 4.22-.92-.407 2.333-5.247h.92l2.334 5.247-.92.407z"></path>
                <path d="M19.96 20.533h-2.334a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h2.334c.273 0 .5.227.5.5 0 .274-.227.5-.5.5zM23.96 27.02a2.635 2.635 0 01-2.633-2.633 2.635 2.635 0 012.633-2.634 2.635 2.635 0 012.633 2.634 2.635 2.635 0 01-2.633 2.633zm0-4.267c-.9 0-1.633.733-1.633 1.634 0 .9.733 1.633 1.633 1.633s1.633-.733 1.633-1.633-.733-1.634-1.633-1.634z"></path>
                <path d="M26.494 27.42a.494.494 0 01-.354-.147l-.666-.666a.503.503 0 010-.707.503.503 0 01.706 0l.667.667a.503.503 0 010 .706.524.524 0 01-.353.147z"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#E1F7D4"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_2083_46663">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const HandwritingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#FDE4D9"
                rx="16"
            ></rect>
            <path
                fill="#D68B6C"
                d="M26 15.5h-4.666a.504.504 0 01-.5-.5c0-.273.226-.5.5-.5H26c.273 0 .5.227.5.5s-.227.5-.5.5zM26 18.833h-4.666a.504.504 0 01-.5-.5c0-.273.226-.5.5-.5H26c.273 0 .5.227.5.5 0 .274-.227.5-.5.5zM26 22.167H14a.504.504 0 01-.5-.5c0-.274.227-.5.5-.5h12c.273 0 .5.226.5.5 0 .273-.227.5-.5.5zM26 25.5H14a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h12c.273 0 .5.227.5.5s-.227.5-.5.5z"
            ></path>
            <path
                fill="#D8A995"
                fillOpacity="0.52"
                d="M17.28 19.167h-1.893c-1.027 0-1.553-.52-1.553-1.554V15.72c0-1.027.52-1.553 1.553-1.553h1.9c1.027 0 1.553.52 1.553 1.553v1.9c-.006 1.027-.526 1.547-1.56 1.547zm-1.893-4c-.473 0-.553.08-.553.553v1.9c0 .473.08.553.553.553h1.9c.473 0 .553-.08.553-.553v-1.9c0-.473-.08-.553-.553-.553h-1.9z"
            ></path>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#FFF2ED"
                strokeWidth="8"
                rx="16"
            ></rect>
        </svg>
    );
};
export const NametracingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#F8E8CB"
                rx="16"
            ></rect>
            <g
                stroke="#EACB92"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_2083_46652)"
            >
                <path d="M20.334 26.667H18c-3.333 0-4.666-1.334-4.666-4.667v-4c0-3.333 1.333-4.667 4.666-4.667h4c3.334 0 4.667 1.334 4.667 4.667v2"></path>
                <path d="M24.038 22.748h-.08a1.038 1.038 0 01-1.004-1.04c0-.577.464-1.041 1.04-1.041.577 0 1.041.47 1.041 1.04a1.026 1.026 0 01-.997 1.041zM22.532 24.355c-.71.475-.71 1.248 0 1.723.806.54 2.13.54 2.936 0 .71-.475.71-1.248 0-1.723-.806-.534-2.124-.534-2.936 0zM16.666 16.667H20M16.666 19.333h4.667"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#FEF2DB"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_2083_46652">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const FillInTheBlankIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#EFDCFF"
                rx="16"
            ></rect>
            <g
                stroke="#6350D7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21205)"
            >
                <path d="M22.667 13.333h-5.334c-2.666 0-4 1.334-4 4V26c0 .367.3.667.667.667h8.667c2.666 0 4-1.334 4-4v-5.334c0-2.666-1.334-4-4-4z"></path>
                <path
                    strokeMiterlimit="10"
                    d="M20.607 17.227l-3.46 3.46c-.133.133-.26.393-.287.58l-.186 1.32c-.067.48.266.813.746.746l1.32-.186c.187-.027.447-.154.58-.287l3.46-3.46c.593-.593.88-1.287 0-2.167-.88-.886-1.573-.606-2.173-.006zM20.113 17.72a3.125 3.125 0 002.167 2.167"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F4E8FF"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21205">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const MultipleChoiceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#C7F3FB"
                rx="16"
            ></rect>
            <g
                stroke="#21A4BA"
                strokeLinecap="round"
                strokeLinejoin="round"
                clipPath="url(#clip0_1597_21798)"
            >
                <path
                    strokeOpacity="0.24"
                    strokeWidth="1.5"
                    d="M26.667 17.68v-3.027c0-.94-.427-1.32-1.487-1.32h-2.693c-1.06 0-1.487.38-1.487 1.32v3.02c0 .947.427 1.32 1.487 1.32h2.693c1.06.007 1.487-.373 1.487-1.313z"
                ></path>
                <path
                    strokeWidth="1.5"
                    d="M26.667 25.18v-2.693c0-1.06-.427-1.487-1.487-1.487h-2.693c-1.06 0-1.487.427-1.487 1.487v2.693c0 1.06.427 1.487 1.487 1.487h2.693c1.06 0 1.487-.427 1.487-1.487z"
                ></path>
                <path d="M15.334 24.04l.673.64 1.393-1.347"></path>
                <path
                    strokeWidth="1.5"
                    d="M19 17.68v-3.027c0-.94-.427-1.32-1.486-1.32H14.82c-1.06 0-1.486.38-1.486 1.32v3.02c0 .947.426 1.32 1.486 1.32h2.694C18.573 19 19 18.62 19 17.68z"
                ></path>
                <path d="M22.666 16.04l.674.64 1.393-1.347"></path>
                <path
                    strokeOpacity="0.24"
                    strokeWidth="1.5"
                    d="M19 25.18v-2.693c0-1.06-.427-1.487-1.486-1.487H14.82c-1.06 0-1.486.427-1.486 1.487v2.693c0 1.06.426 1.487 1.486 1.487h2.694c1.06 0 1.486-.427 1.486-1.487z"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#DEFAFF"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21798">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const CrosswordIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#FFD6D6"
                rx="16"
            ></rect>
            <g
                stroke="#DA5D5D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21216)"
            >
                <path d="M26.667 20.667V18c0-3.333-1.334-4.667-4.667-4.667h-4c-3.333 0-4.666 1.334-4.666 4.667v4c0 3.333 1.333 4.667 4.666 4.667h2.667M13.354 17.666h13.313M13.354 22.333H20M17.673 26.66V13.34M22.34 19.993V13.34"></path>
                <path d="M24.133 26.267a2.133 2.133 0 100-4.267 2.133 2.133 0 000 4.267zM26.667 26.667L26 26"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#FFE4E4"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21216">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const ScrambleIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="4" y="4" width="32" height="32" rx="16" fill="#FFDCF5" />
            <g clipPath="url(#clip0_3645_2748)">
                <path
                    d="M20.2466 17.92H23.7466"
                    stroke="#EC86CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2534 17.92L16.7534 18.42L18.2534 16.92"
                    stroke="#EC86CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.2466 22.5867H23.7466"
                    stroke="#EC86CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2534 22.5867L16.7534 23.0867L18.2534 21.5867"
                    stroke="#EC86CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 26.6667H22C25.3333 26.6667 26.6666 25.3333 26.6666 22V18C26.6666 14.6667 25.3333 13.3333 22 13.3333H18C14.6666 13.3333 13.3333 14.6667 13.3333 18V22C13.3333 25.3333 14.6666 26.6667 18 26.6667Z"
                    stroke="#EC86CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <rect
                x="4"
                y="4"
                width="32"
                height="32"
                rx="16"
                stroke="#FFEBF9"
                strokeWidth="8"
            />
            <defs>
                <clipPath id="clip0_3645_2748">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(12 12)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export const MatchingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#DCEAFF"
                rx="16"
            ></rect>
            <g clipPath="url(#clip0_2083_46742)">
                <g
                    stroke="#3B82F6"
                    strokeWidth="1.5"
                    clipPath="url(#clip1_2083_46742)"
                >
                    <path d="M14.667 13.333h2c.733 0 1.333.6 1.333 1.334v2C18 17.4 17.4 18 16.667 18h-2c-.733 0-1.333-.6-1.333-1.333v-2c0-.734.6-1.334 1.333-1.334zM23.313 22h2c.734 0 1.334.6 1.334 1.333v2c0 .734-.6 1.334-1.334 1.334h-2c-.733 0-1.333-.6-1.333-1.334v-2c0-.733.6-1.333 1.333-1.333z"></path>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20 15.333h1.787c1.233 0 1.806 1.527.88 2.34l-5.327 4.66c-.927.807-.353 2.334.873 2.334H20M15.658 15.667h.007M24.324 24.333h.008"
                    ></path>
                </g>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#E9F1FF"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_2083_46742">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
                <clipPath id="clip1_2083_46742">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveScratchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <rect
                width="1.818"
                height="10"
                x="19.091"
                y="15"
                fill="#212121"
                rx="0.909"
            ></rect>
            <rect
                width="1.818"
                height="10"
                x="25"
                y="19.091"
                fill="#212121"
                rx="0.909"
                transform="rotate(90 25 19.09)"
            ></rect>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
        </svg>
    );
};

export const InactiveMakeInterativeIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="4" y="4" width="32" height="32" rx="16" fill="#E8E8E8" />
            <path
                d="M20 26.6667C23.6819 26.6667 26.6667 23.6819 26.6667 20C26.6667 16.3181 23.6819 13.3333 20 13.3333C16.3181 13.3333 13.3333 16.3181 13.3333 20C13.3333 23.6819 16.3181 26.6667 20 26.6667Z"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.34 21.6733C17.46 21.8733 17.6067 22.06 17.7734 22.2267C19 23.4534 20.9934 23.4534 22.2267 22.2267C22.7267 21.7267 23.0134 21.0933 23.1067 20.4467"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8933 19.5533C16.9867 18.9 17.2733 18.2733 17.7733 17.7733C19 16.5466 20.9933 16.5466 22.2267 17.7733C22.4 17.9466 22.54 18.1333 22.66 18.3266"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.2133 23.4533V21.6733H18.9933"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.7867 16.5467V18.3267H21.0067"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="4"
                y="4"
                width="32"
                height="32"
                rx="16"
                stroke="#F5F5F5"
                strokeWidth="8"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const InactiveWordsearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g fill="#212121" clipPath="url(#clip0_1597_21571)">
                <path d="M20.233 26.833h-2.466c-3.46 0-4.933-1.473-4.933-4.933v-3.8c0-3.46 1.473-4.934 4.933-4.934h3.8c3.46 0 4.933 1.474 4.933 4.934v2.466c0 .274-.227.5-.5.5a.504.504 0 01-.5-.5V18.1c0-2.907-1.026-3.934-3.933-3.934h-3.8c-2.907 0-3.933 1.027-3.933 3.934v3.8c0 2.907 1.026 3.933 3.933 3.933h2.466c.274 0 .5.227.5.5 0 .274-.226.5-.5.5z"></path>
                <path d="M20.667 21.987l-1.874-4.22-1.873 4.22-.92-.407 2.333-5.247h.92l2.334 5.247-.92.407z"></path>
                <path d="M19.96 20.533h-2.334a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h2.334c.273 0 .5.227.5.5 0 .274-.227.5-.5.5zM23.96 27.02a2.635 2.635 0 01-2.633-2.633 2.635 2.635 0 012.633-2.634 2.635 2.635 0 012.633 2.634 2.635 2.635 0 01-2.633 2.633zm0-4.267c-.9 0-1.633.733-1.633 1.634 0 .9.733 1.633 1.633 1.633s1.633-.733 1.633-1.633-.733-1.634-1.633-1.634z"></path>
                <path d="M26.494 27.42a.494.494 0 01-.354-.147l-.666-.666a.503.503 0 010-.707.503.503 0 01.706 0l.667.667a.503.503 0 010 .706.524.524 0 01-.353.147z"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21571">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveHandwritingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g clipPath="url(#clip0_1597_21578)">
                <path
                    fill="#212121"
                    d="M27.2 14.6h-5.6a.604.604 0 01-.6-.6c0-.328.272-.6.6-.6h5.6c.328 0 .6.272.6.6 0 .328-.272.6-.6.6zM27.2 18.6h-5.6a.604.604 0 01-.6-.6c0-.328.272-.6.6-.6h5.6c.328 0 .6.272.6.6 0 .328-.272.6-.6.6zM27.2 22.6H12.8a.604.604 0 01-.6-.6c0-.328.272-.6.6-.6h14.4c.328 0 .6.272.6.6 0 .328-.272.6-.6.6zM27.2 26.6H12.8a.604.604 0 01-.6-.6c0-.328.272-.6.6-.6h14.4c.328 0 .6.272.6.6 0 .328-.272.6-.6.6z"
                ></path>
                <path
                    fill="#D8A995"
                    fillOpacity="0.52"
                    d="M16.736 19h-2.272c-1.232 0-1.864-.624-1.864-1.864v-2.272c0-1.232.624-1.864 1.864-1.864h2.28c1.232 0 1.864.624 1.864 1.864v2.28C18.6 18.376 17.976 19 16.736 19zm-2.272-4.8c-.568 0-.664.096-.664.664v2.28c0 .568.096.664.664.664h2.28c.568 0 .664-.096.664-.664v-2.28c0-.568-.096-.664-.664-.664h-2.28z"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21578">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveNametracingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21599)"
            >
                <path
                    strokeOpacity="0.52"
                    d="M20.334 26.667H18c-3.333 0-4.666-1.334-4.666-4.667v-4c0-3.333 1.333-4.667 4.666-4.667h4c3.334 0 4.667 1.334 4.667 4.667v2"
                ></path>
                <path d="M24.038 22.748h-.08a1.038 1.038 0 01-1.004-1.04c0-.577.464-1.041 1.04-1.041.577 0 1.041.47 1.041 1.04a1.026 1.026 0 01-.997 1.041zM22.532 24.355c-.71.475-.71 1.248 0 1.723.806.54 2.13.54 2.936 0 .71-.475.71-1.248 0-1.723-.806-.534-2.124-.534-2.936 0zM16.666 16.667H20M16.666 19.333h4.667"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21599">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveFillInTheBlankIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21585)"
            >
                <path d="M22.667 13.333h-5.334c-2.666 0-4 1.334-4 4V26c0 .367.3.667.667.667h8.667c2.666 0 4-1.334 4-4v-5.334c0-2.666-1.334-4-4-4z"></path>
                <path
                    strokeMiterlimit="10"
                    d="M20.607 17.227l-3.46 3.46c-.133.133-.26.393-.287.58l-.186 1.32c-.067.48.266.813.746.746l1.32-.186c.187-.027.447-.154.58-.287l3.46-3.46c.593-.593.88-1.287 0-2.167-.88-.886-1.573-.606-2.173-.006zM20.113 17.72a3.125 3.125 0 002.167 2.167"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21585">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveMatchingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g
                stroke="#212121"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21613)"
            >
                <path d="M14.667 13.333h2c.733 0 1.333.6 1.333 1.334v2C18 17.4 17.4 18 16.667 18h-2c-.733 0-1.333-.6-1.333-1.333v-2c0-.734.6-1.334 1.333-1.334zM23.313 22h2c.734 0 1.334.6 1.334 1.333v2c0 .734-.6 1.334-1.334 1.334h-2c-.733 0-1.333-.6-1.333-1.334v-2c0-.733.6-1.333 1.333-1.333z"></path>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 15.333h1.787c1.233 0 1.806 1.527.88 2.34l-5.327 4.66c-.927.807-.353 2.334.873 2.334H20M15.658 15.667h.007M24.324 24.333h.008"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21613">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveWordScrambleIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="4" y="4" width="32" height="32" rx="16" fill="#E8E8E8" />
            <g clipPath="url(#clip0_3527_8261)">
                <path
                    d="M20.2466 17.92H23.7466"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2534 17.92L16.7534 18.42L18.2534 16.92"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.2466 22.5867H23.7466"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2534 22.5867L16.7534 23.0867L18.2534 21.5867"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 26.6667H22C25.3333 26.6667 26.6666 25.3333 26.6666 22V18C26.6666 14.6667 25.3333 13.3333 22 13.3333H18C14.6666 13.3333 13.3333 14.6667 13.3333 18V22C13.3333 25.3333 14.6666 26.6667 18 26.6667Z"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <rect
                x="4"
                y="4"
                width="32"
                height="32"
                rx="16"
                stroke="#F5F5F5"
                strokeWidth="8"
            />
            <defs>
                <clipPath id="clip0_3527_8261">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(12 12)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveCrosswordIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_1597_21592)"
            >
                <path d="M26.667 20.667V18c0-3.333-1.334-4.667-4.667-4.667h-4c-3.333 0-4.666 1.334-4.666 4.667v4c0 3.333 1.333 4.667 4.666 4.667h2.667M13.354 17.666h13.313M13.354 22.333H20M17.673 26.66V13.34M22.34 19.993V13.34"></path>
                <path d="M24.133 26.267a2.133 2.133 0 100-4.267 2.133 2.133 0 000 4.267zM26.667 26.667L26 26"></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21592">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const InactiveMultipleChoiceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                fill="#E8E8E8"
                rx="16"
            ></rect>
            <g
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
                clipPath="url(#clip0_1597_21606)"
            >
                <path
                    strokeOpacity="0.24"
                    strokeWidth="1.5"
                    d="M26.667 17.68v-3.027c0-.94-.427-1.32-1.487-1.32h-2.693c-1.06 0-1.487.38-1.487 1.32v3.02c0 .947.427 1.32 1.487 1.32h2.693c1.06.007 1.487-.373 1.487-1.313z"
                ></path>
                <path
                    strokeWidth="1.5"
                    d="M26.667 25.18v-2.693c0-1.06-.427-1.487-1.487-1.487h-2.693c-1.06 0-1.487.427-1.487 1.487v2.693c0 1.06.427 1.487 1.487 1.487h2.693c1.06 0 1.487-.427 1.487-1.487z"
                ></path>
                <path d="M15.334 24.04l.673.64 1.393-1.347"></path>
                <path
                    strokeWidth="1.5"
                    d="M19 17.68v-3.027c0-.94-.427-1.32-1.486-1.32H14.82c-1.06 0-1.486.38-1.486 1.32v3.02c0 .947.426 1.32 1.486 1.32h2.694C18.573 19 19 18.62 19 17.68z"
                ></path>
                <path d="M22.666 16.04l.674.64 1.393-1.347"></path>
                <path
                    strokeOpacity="0.24"
                    strokeWidth="1.5"
                    d="M19 25.18v-2.693c0-1.06-.427-1.487-1.486-1.487H14.82c-1.06 0-1.486.427-1.486 1.487v2.693c0 1.06.426 1.487 1.486 1.487h2.694c1.06 0 1.486-.427 1.486-1.487z"
                ></path>
            </g>
            <rect
                width="32"
                height="32"
                x="4"
                y="4"
                stroke="#F5F5F5"
                strokeWidth="8"
                rx="16"
            ></rect>
            <defs>
                <clipPath id="clip0_1597_21606">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const BackIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <g>
                <path
                    fill="#212121"
                    d="M12.527 7.337H5.081l3.253-3.254a.672.672 0 000-.946.664.664 0 00-.94 0L3.001 7.53c-.26.26-.26.68 0 .94l4.393 4.393a.664.664 0 10.94-.94L5.081 8.67h7.446c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666z"
                ></path>
            </g>
        </svg>
    );
};

export const ComingSoon = () => {
    return (
        <svg
            width="55"
            height="45"
            viewBox="0 0 74 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0H27.0011L74.0011 37.5V60L0 0Z"
                fill="url(#paint0_linear_20744_52996)"
            />
            <path
                d="M19.9088 9.45692C19.2791 8.95272 18.839 8.39362 18.5884 7.77964C18.3482 7.16452 18.2935 6.52887 18.4244 5.87269C18.5598 5.21078 18.8819 4.56213 19.3907 3.92673C19.7712 3.45161 20.1813 3.08015 20.6212 2.81236C21.0657 2.53884 21.5245 2.37069 21.9975 2.30791C22.4751 2.23941 22.9573 2.28257 23.4439 2.4374C23.9409 2.59109 24.4241 2.85587 24.8935 3.23173C25.2083 3.48383 25.4854 3.77142 25.7246 4.09449C25.9638 4.41757 26.1383 4.74519 26.2481 5.07737C26.3087 5.24804 26.3155 5.40381 26.2685 5.54465C26.2261 5.67978 26.1522 5.78969 26.0467 5.87439C25.9459 5.95337 25.8308 5.99741 25.7014 6.00652C25.5777 6.0202 25.4569 5.98923 25.339 5.91359C25.2313 5.8368 25.1466 5.70792 25.0849 5.52694C24.9991 5.27036 24.8738 5.03381 24.709 4.81728C24.5441 4.60075 24.3558 4.40768 24.144 4.23809C23.7376 3.91265 23.3277 3.72063 22.9143 3.66203C22.5009 3.60344 22.0965 3.67426 21.7013 3.87451C21.3061 4.07476 20.9252 4.40385 20.5585 4.8618C20.1964 5.31402 19.9585 5.7577 19.8449 6.19286C19.7314 6.62801 19.7484 7.04089 19.8959 7.43149C20.0435 7.8221 20.3205 8.18012 20.7269 8.50556C20.9444 8.67974 21.1797 8.82587 21.4328 8.94395C21.6904 9.0563 21.9595 9.13086 22.2401 9.16764C22.4256 9.19406 22.5625 9.25196 22.6506 9.34133C22.7491 9.42956 22.8074 9.53266 22.8257 9.65063C22.844 9.76861 22.8256 9.88542 22.7706 10.0011C22.7201 10.111 22.6365 10.2037 22.5196 10.2793C22.4073 10.3491 22.2699 10.3799 22.1073 10.3718C21.7454 10.3545 21.3675 10.2633 20.9736 10.0982C20.59 9.93194 20.235 9.71819 19.9088 9.45692Z"
                fill="white"
            />
            <path
                d="M24.4384 13.084C24.0033 12.7356 23.6966 12.3444 23.5181 11.9102C23.3396 11.4761 23.2923 11.0248 23.3761 10.5564C23.4645 10.0823 23.6875 9.62203 24.045 9.17553C24.3155 8.8378 24.6048 8.57623 24.9129 8.39084C25.2314 8.2043 25.5607 8.09221 25.9009 8.05457C26.2456 8.01121 26.5886 8.04629 26.9299 8.15983C27.2757 8.26764 27.6117 8.45218 27.938 8.71345C28.3731 9.0618 28.6798 9.45305 28.8583 9.88719C29.0368 10.3213 29.0841 10.7726 29.0003 11.241C28.9165 11.7093 28.6958 12.1668 28.3383 12.6133C28.0678 12.951 27.7734 13.2132 27.4549 13.3997C27.1422 13.5908 26.8134 13.7081 26.4687 13.7514C26.1285 13.7891 25.7855 13.754 25.4397 13.6462C25.0984 13.5326 24.7647 13.3452 24.4384 13.084ZM25.0916 12.2682C25.3034 12.4378 25.5307 12.5353 25.7734 12.5606C26.0162 12.5859 26.2608 12.5328 26.5071 12.4012C26.7637 12.2684 27.0134 12.0504 27.2564 11.747C27.6231 11.289 27.7985 10.8705 27.7826 10.4914C27.7713 10.1066 27.6054 9.78585 27.2848 9.52916C27.073 9.35956 26.8458 9.26211 26.603 9.23681C26.3648 9.20578 26.1174 9.25663 25.8608 9.38937C25.6145 9.52097 25.3699 9.73846 25.1269 10.0418C24.7648 10.4941 24.5871 10.9154 24.5938 11.306C24.6051 11.6908 24.771 12.0116 25.0916 12.2682Z"
                fill="white"
            />
            <path
                d="M28.0342 15.9351C27.8624 15.7975 27.7675 15.6463 27.7492 15.4814C27.7355 15.3107 27.7998 15.1367 27.9418 14.9592L30.5408 11.7136C30.6829 11.5361 30.8364 11.4382 31.0013 11.42C31.1662 11.4017 31.3317 11.4591 31.4977 11.592C31.6637 11.7249 31.7559 11.8739 31.7741 12.0388C31.7924 12.2037 31.7304 12.3749 31.5883 12.5524L31.0864 13.1792L31.1982 12.8459C31.5419 12.6514 31.9004 12.5674 32.2738 12.5939C32.6471 12.6204 32.9998 12.7666 33.3318 13.0324C33.6695 13.3029 33.883 13.6053 33.9722 13.9398C34.0614 14.2742 34.016 14.6419 33.836 15.0426L33.7158 14.9464C34.0732 14.7347 34.4523 14.6484 34.8531 14.6875C35.2585 14.7209 35.633 14.8751 35.9764 15.1501C36.297 15.4068 36.5099 15.6806 36.6151 15.9715C36.7261 16.2671 36.73 16.5849 36.6267 16.925C36.5281 17.2594 36.3229 17.6212 36.0112 18.0104L34.3474 20.0884C34.2053 20.2658 34.0466 20.3642 33.8714 20.3836C33.7064 20.4019 33.5381 20.3422 33.3664 20.2047C33.1946 20.0672 33.0997 19.916 33.0814 19.7511C33.0677 19.5804 33.132 19.4064 33.274 19.2289L34.9035 17.1939C35.1602 16.8734 35.2938 16.5951 35.3042 16.3592C35.3203 16.1279 35.2025 15.9114 34.9506 15.7097C34.6701 15.4851 34.3695 15.4088 34.0488 15.4808C33.7327 15.5471 33.4348 15.7548 33.1552 16.104L31.677 17.9501C31.5349 18.1275 31.3791 18.2282 31.2096 18.2522C31.0446 18.2705 30.8763 18.2108 30.7046 18.0733C30.5328 17.9358 30.435 17.7823 30.411 17.6128C30.3973 17.4421 30.4616 17.2681 30.6037 17.0906L32.2331 15.0556C32.4898 14.7351 32.6234 14.4568 32.6338 14.2209C32.6499 13.9896 32.5349 13.7754 32.2888 13.5783C32.0083 13.3537 31.7077 13.2774 31.387 13.3494C31.0709 13.4157 30.773 13.6234 30.4934 13.9726L29.0152 15.8187C28.7218 16.185 28.3948 16.2238 28.0342 15.9351Z"
                fill="white"
            />
            <path
                d="M35.5538 21.9422C35.3821 21.8047 35.2917 21.6478 35.2826 21.4714C35.2735 21.295 35.3423 21.1152 35.489 20.932L38.0123 17.7808C38.1636 17.5919 38.3239 17.4855 38.4935 17.4615C38.6675 17.4318 38.8405 17.4857 39.0122 17.6232C39.1839 17.7607 39.2743 17.9177 39.2834 18.094C39.3028 18.2693 39.2369 18.4513 39.0856 18.6402L36.5623 21.7915C36.4156 21.9747 36.2552 22.0811 36.0811 22.1108C35.907 22.1405 35.7313 22.0843 35.5538 21.9422ZM39.6516 16.8247C39.4513 16.6642 39.3328 16.4895 39.2962 16.3005C39.27 16.1104 39.3279 15.9266 39.47 15.7492C39.6167 15.566 39.7834 15.4693 39.97 15.4591C40.1624 15.4534 40.3588 15.5308 40.5592 15.6913C40.7653 15.8563 40.8837 16.031 40.9146 16.2154C40.9454 16.3998 40.8875 16.5836 40.7408 16.7667C40.5987 16.9442 40.432 17.0409 40.2408 17.0569C40.0541 17.0671 39.8577 16.9897 39.6516 16.8247Z"
                fill="white"
            />
            <path
                d="M37.7355 23.7032C37.5638 23.5657 37.4688 23.4145 37.4505 23.2496C37.4368 23.0789 37.5011 22.9049 37.6432 22.7274L40.2421 19.4817C40.3842 19.3043 40.5377 19.2064 40.7026 19.1882C40.8676 19.1699 41.033 19.2273 41.199 19.3602C41.365 19.4931 41.4572 19.642 41.4754 19.807C41.4937 19.9719 41.4317 20.1431 41.2896 20.3205L40.8221 20.9044L40.8995 20.6141C41.2684 20.4116 41.6464 20.3385 42.0335 20.3948C42.4308 20.4499 42.7984 20.6127 43.1361 20.8831C43.4739 21.1536 43.7011 21.4388 43.8178 21.739C43.9345 22.0391 43.944 22.3615 43.8465 22.7062C43.7536 23.0451 43.5513 23.4092 43.2396 23.7985L41.5758 25.8764C41.4337 26.0539 41.2779 26.1546 41.1083 26.1785C40.9434 26.1968 40.7751 26.1372 40.6034 25.9997C40.4316 25.8621 40.3338 25.7086 40.3098 25.5391C40.2961 25.3685 40.3604 25.1944 40.5024 25.017L42.1251 22.9906C42.3863 22.6643 42.5136 22.3763 42.5068 22.1266C42.5058 21.8815 42.3765 21.6559 42.1189 21.4496C41.804 21.1975 41.472 21.096 41.1226 21.1451C40.7836 21.193 40.4835 21.3801 40.2222 21.7064L38.7165 23.5869C38.4231 23.9532 38.0961 23.992 37.7355 23.7032Z"
                fill="white"
            />
            <path
                d="M42.9322 30.4292C42.6231 30.1817 42.3455 29.9124 42.0994 29.6214C41.859 29.335 41.6696 29.0377 41.5312 28.7296C41.4683 28.5852 41.4489 28.457 41.473 28.3447C41.5028 28.2371 41.5578 28.1449 41.638 28.0682C41.7193 28.0018 41.811 27.9578 41.9129 27.9361C42.0206 27.9189 42.1237 27.931 42.2221 27.9723C42.3206 28.0135 42.3944 28.0915 42.4436 28.206C42.5947 28.5336 42.7681 28.804 42.9638 29.0171C43.1607 29.2405 43.3393 29.4164 43.4996 29.5447C43.8831 29.8518 44.2449 29.9866 44.5851 29.9489C44.9264 29.9216 45.2437 29.7248 45.5371 29.3584L46.1559 28.5856L46.2332 28.6475C45.9135 28.8707 45.5453 28.961 45.1284 28.9184C44.7219 28.8747 44.3469 28.7154 44.0034 28.4403C43.6256 28.1378 43.3652 27.7884 43.2222 27.3921C43.0838 26.99 43.0634 26.5697 43.1609 26.1311C43.2585 25.6925 43.4746 25.2643 43.8092 24.8464C44.0613 24.5316 44.3311 24.2826 44.6186 24.0995C44.9165 23.9152 45.2189 23.7957 45.5259 23.7408C45.8385 23.6906 46.1483 23.7085 46.4552 23.7945C46.7724 23.8794 47.0713 24.0341 47.3518 24.2587C47.7067 24.5429 47.9447 24.8792 48.066 25.2675C48.1975 25.6546 48.1887 26.0234 48.0397 26.3738L47.7819 26.5197L48.2701 25.9101C48.4122 25.7326 48.5657 25.6348 48.7306 25.6165C48.9013 25.6028 49.0696 25.6625 49.2356 25.7954C49.4074 25.9329 49.5023 26.0841 49.5206 26.249C49.5388 26.414 49.4769 26.5852 49.3348 26.7626L46.6878 30.0684C46.1423 30.7496 45.5518 31.1175 44.9161 31.1722C44.2804 31.2269 43.6192 30.9792 42.9322 30.4292ZM44.9485 27.8584C45.1718 28.0372 45.4048 28.1392 45.6476 28.1645C45.8903 28.1898 46.1303 28.1424 46.3674 28.0222C46.6149 27.901 46.844 27.7086 47.0549 27.4453C47.3711 27.0504 47.5213 26.6633 47.5055 26.2842C47.4942 25.8994 47.3225 25.5741 46.9905 25.3082C46.7673 25.1295 46.5343 25.0274 46.2915 25.0021C46.0487 24.9768 45.8059 25.0219 45.563 25.1375C45.3259 25.2577 45.1019 25.4494 44.8911 25.7127C44.5748 26.1077 44.4223 26.4976 44.4336 26.8824C44.4449 27.2672 44.6165 27.5925 44.9485 27.8584Z"
                fill="white"
            />
            <path
                d="M51.9148 35.0853C51.663 34.8837 51.4271 34.6619 51.2074 34.4202C50.983 34.1842 50.789 33.9396 50.6253 33.6864C50.4662 33.4275 50.3438 33.1697 50.258 32.9132C50.21 32.762 50.2049 32.6217 50.2428 32.4923C50.2806 32.3629 50.3419 32.257 50.4267 32.1745C50.5218 32.091 50.6255 32.0378 50.7377 32.0149C50.8603 31.9909 50.9742 32.007 51.0796 32.0632C51.1849 32.1193 51.2645 32.2253 51.3182 32.3811C51.4509 32.7316 51.63 33.0535 51.8555 33.3468C52.081 33.6401 52.3483 33.9105 52.6574 34.158C53.1096 34.5201 53.4983 34.7093 53.8236 34.7254C54.1534 34.7359 54.4146 34.6209 54.6071 34.3805C54.7675 34.1801 54.8192 33.9631 54.762 33.7295C54.7106 33.5004 54.5372 33.2066 54.2419 32.8479L53.4555 31.8942C53.0034 31.3442 52.7505 30.827 52.6969 30.3425C52.6536 29.8569 52.8107 29.3909 53.1682 28.9444C53.3928 28.6639 53.654 28.455 53.9519 28.3177C54.2497 28.1804 54.5681 28.1112 54.9071 28.1102C55.2519 28.1138 55.6017 28.1873 55.9567 28.3306C56.322 28.4728 56.6792 28.6837 57.0284 28.9633C57.3718 29.2383 57.6637 29.5425 57.9041 29.8759C58.1444 30.2093 58.3212 30.5575 58.4345 30.9206C58.4756 31.0569 58.4739 31.1823 58.4291 31.2968C58.3901 31.4159 58.3254 31.5144 58.2349 31.5922C58.149 31.6643 58.0493 31.7067 57.9359 31.7192C57.8225 31.7318 57.7126 31.7048 57.6061 31.6383C57.5053 31.5764 57.4224 31.463 57.3572 31.2981C57.2416 31.0083 57.0957 30.7505 56.9194 30.5248C56.7432 30.2991 56.5291 30.0854 56.2772 29.8837C56.0139 29.6729 55.758 29.5291 55.5095 29.4522C55.261 29.3754 55.0303 29.3644 54.8172 29.4193C54.6145 29.473 54.4398 29.5915 54.2931 29.7746C54.1281 29.9807 54.0638 30.2017 54.1004 30.4377C54.1415 30.6679 54.3006 30.9503 54.5776 31.2848L55.3554 32.2317C55.8258 32.8057 56.0953 33.3315 56.1638 33.8091C56.238 34.2913 56.1033 34.7471 55.7595 35.1764C55.5395 35.4512 55.2828 35.6543 54.9896 35.7859C54.7021 35.9221 54.3882 35.9855 54.0481 35.9762C53.7137 35.9714 53.3661 35.8951 53.0054 35.7472C52.6447 35.5993 52.2812 35.3787 51.9148 35.0853Z"
                fill="white"
            />
            <path
                d="M57.0569 39.2028C56.6219 38.8544 56.3151 38.4632 56.1366 38.0291C55.9582 37.5949 55.9109 37.1437 55.9947 36.6753C56.0831 36.2012 56.306 35.7409 56.6636 35.2944C56.934 34.9566 57.2233 34.6951 57.5315 34.5097C57.8499 34.3231 58.1792 34.2111 58.5194 34.1734C58.8641 34.13 59.2071 34.1651 59.5484 34.2787C59.8942 34.3865 60.2303 34.571 60.5565 34.8323C60.9916 35.1806 61.2984 35.5719 61.4768 36.006C61.6553 36.4402 61.7026 36.8914 61.6188 37.3598C61.535 37.8282 61.3143 38.2856 60.9568 38.7321C60.6864 39.0699 60.3919 39.332 60.0734 39.5185C59.7607 39.7097 59.4319 39.8269 59.0872 39.8703C58.747 39.9079 58.404 39.8728 58.0582 39.765C57.717 39.6515 57.3832 39.4641 57.0569 39.2028ZM57.7101 38.3871C57.9219 38.5567 58.1492 38.6541 58.392 38.6794C58.6348 38.7047 58.8793 38.6516 59.1256 38.52C59.3822 38.3873 59.632 38.1692 59.8749 37.8658C60.2416 37.4079 60.417 36.9893 60.4011 36.6103C60.3899 36.2254 60.2239 35.9047 59.9034 35.648C59.6916 35.4784 59.4643 35.381 59.2215 35.3557C58.9833 35.3246 58.7359 35.3755 58.4793 35.5082C58.233 35.6398 57.9884 35.8573 57.7455 36.1607C57.3833 36.6129 57.2056 37.0343 57.2123 37.4248C57.2236 37.8097 57.3895 38.1304 57.7101 38.3871Z"
                fill="white"
            />
            <path
                d="M62.0042 43.1643C61.5692 42.8159 61.2624 42.4247 61.0839 41.9905C60.9055 41.5564 60.8581 41.1051 60.9419 40.6367C61.0303 40.1626 61.2533 39.7023 61.6108 39.2558C61.8813 38.9181 62.1706 38.6565 62.4787 38.4712C62.7972 38.2846 63.1265 38.1725 63.4667 38.1349C63.8114 38.0915 64.1544 38.1266 64.4957 38.2401C64.8415 38.3479 65.1775 38.5325 65.5038 38.7938C65.9389 39.1421 66.2456 39.5334 66.4241 39.9675C66.6026 40.4016 66.6499 40.8529 66.5661 41.3213C66.4823 41.7897 66.2616 42.2471 65.9041 42.6936C65.6336 43.0313 65.3392 43.2935 65.0207 43.48C64.708 43.6711 64.3792 43.7884 64.0345 43.8317C63.6943 43.8694 63.3513 43.8343 63.0055 43.7265C62.6643 43.6129 62.3305 43.4255 62.0042 43.1643ZM62.6574 42.3486C62.8692 42.5182 63.0965 42.6156 63.3393 42.6409C63.582 42.6662 63.8266 42.6131 64.0729 42.4815C64.3295 42.3487 64.5792 42.1307 64.8222 41.8273C65.1889 41.3693 65.3643 40.9508 65.3484 40.5717C65.3371 40.1869 65.1712 39.8662 64.8507 39.6095C64.6389 39.4399 64.4116 39.3424 64.1688 39.3171C63.9306 39.2861 63.6832 39.3369 63.4266 39.4697C63.1803 39.6013 62.9357 39.8188 62.6927 40.1222C62.3306 40.5744 62.1529 40.9958 62.1596 41.3863C62.1709 41.7711 62.3368 42.0919 62.6574 42.3486Z"
                fill="white"
            />
            <path
                d="M65.5828 46.0016C65.4111 45.8641 65.3161 45.7129 65.2978 45.548C65.2842 45.3773 65.3484 45.2033 65.4905 45.0258L68.0894 41.7801C68.2315 41.6027 68.385 41.5048 68.55 41.4866C68.7149 41.4683 68.8804 41.5256 69.0464 41.6586C69.2124 41.7915 69.3045 41.9404 69.3227 42.1054C69.341 42.2703 69.2791 42.4415 69.137 42.6189L68.6694 43.2028L68.7469 42.9125C69.1157 42.71 69.4937 42.6368 69.8808 42.6931C70.2782 42.7483 70.6457 42.9111 70.9835 43.1815C71.3212 43.4519 71.5484 43.7372 71.6651 44.0373C71.7818 44.3374 71.7914 44.6598 71.6939 45.0045C71.601 45.3435 71.3986 45.7076 71.087 46.0969L69.4231 48.1748C69.281 48.3522 69.1252 48.4529 68.9557 48.4769C68.7908 48.4952 68.6224 48.4355 68.4507 48.298C68.279 48.1605 68.1811 48.007 68.1571 47.8375C68.1435 47.6668 68.2077 47.4928 68.3498 47.3153L69.9724 45.2889C70.2337 44.9626 70.3609 44.6747 70.3542 44.425C70.3531 44.1799 70.2238 43.9542 69.9662 43.748C69.6514 43.4959 69.3193 43.3944 68.97 43.4434C68.6309 43.4914 68.3308 43.6785 68.0695 44.0048L66.5638 45.8852C66.2704 46.2516 65.9434 46.2904 65.5828 46.0016Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_20744_52996"
                    x1="19.474"
                    y1="7.50002"
                    x2="61.9725"
                    y2="11.4613"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3B82F6" />
                    <stop offset="1" stopColor="#4A3BF6" />
                </linearGradient>
            </defs>
        </svg>
    );
};
