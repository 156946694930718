import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import AnswerKeyModal from './answer-key-modal';

const ShowAnswerKeyButton = ({}) => {
    const [showAnswerKeyModal, setShowAnswerKeyModal] = useState(false);

    return (
        <>
            <Button size="sm" onClick={() => setShowAnswerKeyModal(true)}>Show Answers</Button>

            {showAnswerKeyModal && (
                <AnswerKeyModal hideModal={() => setShowAnswerKeyModal(false)} />
            )}
        </>
    )
}

export default ShowAnswerKeyButton;