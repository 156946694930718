import {
    Answer,
    ANSWER_TYPE,
    IAnswer,
    IOffset,
    Offset,
} from "../../shared/models/answer";
import { InputWord } from "../../shared/models/puzzle";
import { IGameScore } from "../redux/reducer/game.reducer";
import { getDefaultAnswerValue } from "../utils";
import {
    removeMatchingIdAndPointsAction,
    updateMatchingIdAndPointsAction,
} from "../redux/action/game.action";
import ConstantFonts from "./constantFont";
import { IWord } from "../../shared/models/crossword";
import ConstantsTool from "../../shared/utils/ConstantsTool";

export const calcScore = (
    answers,
    gameScore,
    answerSubmit,
    numberOfGame = 0,
    inputWords = [],
    crosswordWords = []
) => {
    let resultFillBlank = calcGameFillBlank(answers, gameScore);
    let resultJoinArrow = calcGameJoin(answers, gameScore);
    let resultDragDrop = calcGameDragDrop(answers, gameScore);
    let resultDropDown = calcGameDropDown(answers, gameScore);
    let resultSpeaking = calcGameSpeaking(answers, gameScore);
    let resultSelect = calcGameSelect(
        answers,
        answerSubmit?.answers,
        answerSubmit?.questions,
        gameScore
    );
    let resultWorksearch = calcGameWordSearch(answers, gameScore, numberOfGame);
    let resultTick = calcGameTick(answers, gameScore);
    let resultWordsearchByTool = calcWordsearchByTool(
        answers,
        inputWords,
        gameScore
    );
    let resultCrossword = calcGameCrossword(crosswordWords, gameScore);
    return gameScore;
};

function calcGameCrossword(
    crosswordWords: IWord[],
    gameScore: IGameScore
): IGameScore {
    return crosswordWords.reduce((prev, word) => {
        prev.totalDone +=
            word.status !== ConstantsTool.CROSSWORD_STATUS.INIT ? 1 : 0;
        prev.totalCorrect +=
            word.status === ConstantsTool.CROSSWORD_STATUS.CORRECT ? 1 : 0;
        prev.totalAnswer += 1;
        return prev;
    }, gameScore);
}

function calcGameFillBlank(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersSelect = answers.filter(
        (w) =>
            w.type == ANSWER_TYPE.FILL_BLANK ||
            w.type == ANSWER_TYPE.FILL_SHAPE ||
            w.type == ANSWER_TYPE.FILL_LINE ||
            w.type == ANSWER_TYPE.FILL_IN_THE_BLANK
    );
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;

    answersSelect.forEach((answer) => {
        let answerList = answer.value.toLowerCase().split("/");
        answerList.push(answer.value.toLowerCase());
        if (answer.value?.length) {
            totalAnswer++;
            if (answer.userAnswer?.length) {
                totalDone++;
                for (let correctAnswer of answerList) {
                    if (
                        correctAnswer === answer.userAnswer.toLowerCase().trim()
                    ) {
                        answer.userAnswerCorrect = true;
                        totalCorrect++;
                        break;
                    }
                }
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}
const calcGameTick = (answers: Answer[], gameScore: IGameScore): IGameScore => {
    let answersSelect = answers.filter((w) => w.type == ANSWER_TYPE.TICK);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let totalWrong = 0;
    answersSelect.forEach((answer) => {
        if (answer.value == "tick:yes") {
            totalAnswer++;
            if (answer.isSelected) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
            } else {
                answer.userAnswerCorrect = false;
            }
        } else {
            if (answer.isSelected) {
                totalWrong++;
            }
            answer.userAnswerCorrect = false;
        }
    });
    if (totalAnswer === 0 && answersSelect.length) {
        totalAnswer = 1;
    }
    if (totalWrong == 1) {
        if (totalCorrect === totalAnswer) {
            totalCorrect = totalCorrect - 1;
        }
    }
    if (totalWrong > 1) {
        let minusAnswer = 0;
        if (totalCorrect + totalWrong > totalAnswer) {
            minusAnswer = totalCorrect + totalWrong - totalAnswer;
        }
        if (totalCorrect <= minusAnswer) {
            totalCorrect = 0;
        } else {
            totalCorrect = totalCorrect - minusAnswer;
        }
    }
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
};
function calcGameDropDown(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersDown = answers.filter((w) => w.type == ANSWER_TYPE.DROP_DOWN);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersDown.forEach((answer) => {
        totalAnswer++;
        if (answer.userAnswer) {
            totalDone++;
            if (answer.userAnswer.startsWith("*")) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameJoin(answers: Answer[], gameScore: IGameScore): IGameScore {
    let answersJoin = answers.filter((w) => w.type == ANSWER_TYPE.JOIN);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let mapAnswer = answersJoin.reduce((map: any, item: IAnswer) => {
        if (!map[item.group]) {
            map[item.group] = [];
        }
        map[item.group].push(item);
        return map;
    }, {});
    //
    Object.keys(mapAnswer).forEach((key) => {
        let anss = mapAnswer[key];
        if (key !== "null") {
            totalAnswer++;
        }

        if (anss.length == 2) {
            if (anss[0].matchingId == anss[1].answerId && anss[0].value) {
                totalDone++;
                totalCorrect++;
                anss[0].userAnswerCorrect = true;
                anss[1].userAnswerCorrect = true;
            } else {
                anss[0].userAnswerCorrect = false;
                anss[1].userAnswerCorrect = false;
            }
        } else {
            for (let answer of anss) {
                answer.userAnswerCorrect = false;
            }
        }
    });
    if (answersJoin.length && totalAnswer === 0) {
        totalAnswer = 1;
    }
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameWordSearch(
    answers: Answer[],
    gameScore: IGameScore,
    numberOfGame: number
): IGameScore {
    let answersSelect = answers.filter(
        (w) => w.type == ANSWER_TYPE.WORD_SEARCH
    );
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersSelect.forEach((answer: any) => {
        let userWordSearch = answer.userWordSearch;
        let wordSearchValue = answer.wordSearchValue;
        for (let row = 0; row < userWordSearch.length; row++) {
            for (
                let column = 0;
                column < userWordSearch[row].length;
                column++
            ) {
                if (wordSearchValue[row][column] === "v") {
                    totalAnswer++;
                }
                if (userWordSearch[row][column] === 1) {
                    if (wordSearchValue[row][column] === "v") {
                        totalCorrect++;
                    }
                    totalDone++;
                }
            }
        }
    });
    if (answersSelect.length !== 0) {
        let currentTotalAnswer = gameScore.totalAnswer;
        let newTotalAnswer = totalAnswer > totalDone ? totalAnswer : totalDone;
        let additionTotalAnswer = 0;
        if (numberOfGame > 1) {
            additionTotalAnswer = Math.round(
                currentTotalAnswer / (numberOfGame - 1)
            );
        } else {
            additionTotalAnswer = newTotalAnswer;
        }
        //
        if (newTotalAnswer !== 0) {
            gameScore.totalAnswer += additionTotalAnswer;
            gameScore.totalDone += totalDone;
            gameScore.totalCorrect += Math.round(
                (totalCorrect * additionTotalAnswer) / newTotalAnswer
            );
        }

        //
    }
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameSelect(
    answers: Answer[],
    submitedAnswers: Answer[],
    questions: any[],
    gameScore: IGameScore
): IGameScore {
    let answersSelect = answers.filter((w) => w.type == ANSWER_TYPE.SELECT);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    let totalWrong = 0;
    answersSelect.forEach((answer, index) => {
        if (answer.isCorrect) {
            totalAnswer++;
            if (answer.isSelected) {
                totalCorrect++;
                answer.userAnswerCorrect = true;
                if (submitedAnswers?.length) {
                    submitedAnswers[index].userAnswerCorrect = true;
                }
            } else {
                answer.userAnswerCorrect = false;
                if (submitedAnswers?.length) {
                    submitedAnswers[index].userAnswerCorrect = false;
                }
            }
        } else {
            if (answer.isSelected) {
                totalWrong++;
            }
            answer.userAnswerCorrect = false;
            if (submitedAnswers?.length) {
                submitedAnswers[index].userAnswerCorrect = false;
            }
        }
    });
    if (submitedAnswers?.length) {
        questions.map((question, questionIndex) => {
            let score = 0;
            answers.map((answer) => {
                if (answer.group === questionIndex) {
                    if (answer.userAnswerCorrect) {
                        score++;
                    } else if (
                        answer.userAnswerCorrect === false &&
                        answer.isSelected
                    ) {
                        score--;
                    }
                }
            });
            if (score > 0) {
                question.isCorrect = true;
            } else {
                question.isCorrect = false;
            }
        });
    }
    if (totalAnswer === 0 && answersSelect.length) {
        totalAnswer = 1;
    }

    if (totalWrong == 1) {
        if (totalCorrect === totalAnswer) {
            totalCorrect = totalCorrect - 1;
        }
    }
    if (totalWrong > 1) {
        let minusAnswer = 0;
        if (totalCorrect + totalWrong > totalAnswer) {
            minusAnswer = totalCorrect + totalWrong - totalAnswer;
        }
        if (totalCorrect <= minusAnswer) {
            totalCorrect = 0;
        } else {
            totalCorrect = totalCorrect - minusAnswer;
        }
    }
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameDragDrop(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersDrapDrop = answers.filter(
        (w) => w.type == ANSWER_TYPE.DRAG || w.type == ANSWER_TYPE.DROP
    );
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    //

    let mapAnswer = answersDrapDrop.reduce((map: any, item: IAnswer) => {
        if (!map[item.group]) {
            map[item.group] = [];
        }
        map[item.group].push(item);
        return map;
    }, {});
    //

    Object.keys(mapAnswer).forEach((key) => {
        let anss = mapAnswer[key];
        if (anss.length >= 2) {
            let answerDrop = anss.filter(
                (w: IAnswer) => w.type == ANSWER_TYPE.DROP
            );
            let answerDrag = anss.filter(
                (w: IAnswer) => w.type == ANSWER_TYPE.DRAG
            );
            totalAnswer += answerDrop.length;
            answerDrop.map((drop: IAnswer) => {
                answerDrag.map((drag: IAnswer) => {
                    if (drop?.matchingId === drag.answerId) {
                        totalDone++;
                        totalCorrect++;
                        drop.userAnswerCorrect = true;
                    }
                });
            });
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

function calcGameSpeaking(
    answers: Answer[],
    gameScore: IGameScore
): IGameScore {
    let answersSpeak = answers.filter((w) => w.type == ANSWER_TYPE.SPEAK);
    let totalDone = 0;
    let totalAnswer = 0;
    let totalCorrect = 0;
    answersSpeak.forEach((w) => {
        totalAnswer++;
        if (w.userAnswer) {
            let textUserAnswer = w.userAnswer;
            totalDone++;
            let answerStrs = getDefaultAnswerValue(w.value).answerValue;
            let arr = answerStrs.split("/");

            let isCorrect = arr.some(
                (option: string) =>
                    option.toLowerCase().trim() ===
                    textUserAnswer.toLowerCase().trim()
            );
            if (isCorrect) {
                totalCorrect++;
                w.userAnswerCorrect = true;
            } else {
                w.userAnswerCorrect = false;
            }
        }
    });
    gameScore.totalAnswer += totalAnswer;
    gameScore.totalDone += totalDone;
    gameScore.totalCorrect += totalCorrect;
    return { totalDone, totalAnswer, totalCorrect };
}

const calcWordsearchByTool = (
    answers: Answer[],
    inputWords: any[],
    gameScore: IGameScore
) => {
    let answersWordSearch = answers.filter(
        (w) => w.type == ANSWER_TYPE.WORD_SEARCH_BY_TOOL
    );
    if (answersWordSearch.length) {
        if (!inputWords.length) {
            return gameScore;
        }
        let foundWord = inputWords.filter((inputWord) => {
            return inputWord.status === 1;
        });
        gameScore.totalAnswer += inputWords.length;
        gameScore.totalDone += foundWord.length;
        gameScore.totalCorrect += foundWord.length;
    }

    return gameScore;
};

export const checkFinishAnswer = (
    answers: Answer[],
    inputWordSearch: InputWord[]
): boolean => {
    for (const answer of answers) {
        switch (answer.type) {
            case ANSWER_TYPE.FILL_BLANK:
            case ANSWER_TYPE.FILL_IN_THE_BLANK:
            case ANSWER_TYPE.FILL_LINE:
            case ANSWER_TYPE.FILL_SHAPE:
            case ANSWER_TYPE.DROP_DOWN:
                if (!answer.userAnswer) {
                    return false;
                }
                break;
            case ANSWER_TYPE.CROSS_WORD:
                if (!answer.userAnswer || answer.userAnswer.includes("_")) {
                    return false;
                }
                break;
            case ANSWER_TYPE.JOIN:
                if (answer.matchingId === -1) return false;
                break;
            case ANSWER_TYPE.SELECT:
                let isSelected = answers.some(
                    (answer) =>
                        answer.isSelected === true &&
                        answer.type === ANSWER_TYPE.SELECT
                );
                if (!isSelected) return false;
                break;
            case ANSWER_TYPE.TICK:
                let isTicked = answers.some(
                    (answer) =>
                        answer.isSelected === true &&
                        answer.type === ANSWER_TYPE.TICK
                );
                if (!isTicked) return false;
                break;
            case ANSWER_TYPE.DROP:
                let isDropped = answers.some(
                    (answer) =>
                        answer.matchingId !== -1 &&
                        answer.type === ANSWER_TYPE.DROP
                );
                if (!isDropped) return false;
                break;
            case ANSWER_TYPE.WORD_SEARCH:
                let isSearch = answer.userWordSearch.some((items) =>
                    items.some((item) => item === 1)
                );
                if (!isSearch) return false;
                break;
            case ANSWER_TYPE.WORD_SEARCH_BY_TOOL:
                if (inputWordSearch.length) {
                    let isNotFinishSearchWord = inputWordSearch.some(
                        (word) => word.status === -1
                    );
                    if (isNotFinishSearchWord) return false;
                }
        }
    }
    return true;
};

export const onMouseUpHandler = (
    event: any,
    idGamePanel: string,
    dispatch: any
) => {
    //
    let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
    let svgItemBorder = document.querySelector(
        '[id^="drawing-border"]'
    ) as HTMLElement;
    let gameContainerElement = document.querySelector(
        "." + idGamePanel
    ) as HTMLElement;
    let eventTarget = event.target as HTMLElement;
    let eventTargetClass = eventTarget.getAttribute("class");
    let svgContainer = document.querySelector("#svg-draw");
    if (svgItem && gameContainerElement && svgItemBorder) {
        gameContainerElement.style.cursor = "auto";
        //
        if (eventTargetClass === "join-item") {
            let targetIdItem = eventTarget.getAttribute("id");
            let startIdElement = svgItem.getAttribute("id");
            let pageId = startIdElement?.split("-")[2];
            let eleGame = getElementGame(parseInt(pageId));
            let screenWidth = eleGame?.getBoundingClientRect().width;
            let targetPoint = new Offset({
                offsetX: parseInt(svgItem.getAttribute("x2") ?? ""),
                offsetY: parseInt(svgItem.getAttribute("y2") ?? ""),
                screenWidth,
            });
            let startPoint = new Offset({
                offsetX: parseInt(svgItem.getAttribute("x1") ?? ""),
                offsetY: parseInt(svgItem.getAttribute("y1") ?? ""),
                screenWidth,
            });
            const sizeTarget = eventTarget.getBoundingClientRect();
            let rect = svgContainer.getBoundingClientRect(),
                offsetX = sizeTarget.x - rect.left,
                offsetY = sizeTarget.y - rect.top,
                endPoint = getPositionEndLine(
                    offsetY,
                    offsetX,
                    sizeTarget.width,
                    sizeTarget.height,
                    [startPoint, targetPoint]
                );
            if (endPoint !== null) {
                targetPoint = new Offset({
                    offsetX: endPoint.x,
                    offsetY: endPoint.y,
                    screenWidth,
                });
                svgItem.setAttribute("x2", endPoint.x.toString());
                svgItem.setAttribute("y2", endPoint.y.toString());
            }
            let startIdItem = startIdElement?.split("-")[1];
            if (startIdItem && targetIdItem && startIdItem !== targetIdItem) {
                let jointElement = document.querySelector(".join-item");
                let ratio = 1;
                if (jointElement) {
                    try {
                        ratio = parseFloat(
                            jointElement.getAttribute("data-ratio")
                        );
                    } catch (error) {}
                }

                let listLine = document.querySelectorAll('[id^="done"]');

                listLine.forEach((line) => {
                    let id: string = line.id;
                    let idData: string[] = id.split("-");
                    if (idData.includes(targetIdItem)) {
                        idData = idData.filter((id) => id !== targetIdItem);
                        dispatch(removeMatchingIdAndPointsAction([idData[1]]));
                    }
                });
                dispatch(
                    updateMatchingIdAndPointsAction(
                        [startIdItem, targetIdItem],
                        [startPoint, targetPoint],
                        ratio
                    )
                );
                svgItem.appendChild(document.createElement("div"));
                svgItem.parentNode?.removeChild(svgItem);
                svgItemBorder.appendChild(document.createElement("div"));
                svgItemBorder.parentNode?.removeChild(svgItemBorder);
            } else {
                svgItem.appendChild(document.createElement("div"));
                svgItem.parentNode?.removeChild(svgItem);
                svgItemBorder.appendChild(document.createElement("div"));
                svgItemBorder.parentNode?.removeChild(svgItemBorder);
            }
        } else {
            svgItem.appendChild(document.createElement("div"));
            svgItem.parentNode?.removeChild(svgItem);
            svgItemBorder.appendChild(document.createElement("div"));
            svgItemBorder.parentNode?.removeChild(svgItemBorder);
        }
    }
};

export const onMouseOutHandler = (event: any, idGamePanel: string) => {
    // const element = document.querySelector("." + idGamePanel) as HTMLElement;
    // if (!element.contains(event.relatedTarget)) {
    //     let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
    //     svgItem && svgItem.remove();
    // }
};
export const onMouseMoveHandler = (
    event: any,
    idGamePanel: string,
    isMobile: boolean
): void => {
    // alert("onMouseMoveHandler ");

    let svgContainer = document.querySelector("#svg-draw");
    let svgItem = document.querySelector('[id^="drawing"]');
    let svgItemBorder = document.querySelector('[id^="drawing-border"]');
    let gameContainerElement = document.querySelector(
        "." + idGamePanel
    ) as HTMLElement;
    if (svgContainer && svgItem && gameContainerElement && svgItemBorder) {
        gameContainerElement.style.cursor =
            "url(https://www.liveworksheets.com/lwsmaker/images/pencil.cur),url(https://www.liveworksheets.com/lwsmaker/images/pencil.gif), default";
        let rect = svgContainer.getBoundingClientRect();
        let offsetX;
        let offsetY;
        if (isMobile) {
            offsetX = event.touches[0].clientX - rect.left;
            offsetY = event.touches[0].clientY - rect.top;
        } else {
            offsetX = event.clientX - rect.left;
            offsetY = event.clientY - rect.top;
        }

        svgItem.setAttribute("x2", offsetX.toString());
        svgItem.setAttribute("y2", offsetY.toString());
        svgItemBorder.setAttribute("x2", offsetX.toString());
        svgItemBorder.setAttribute("y2", offsetY.toString());
    }
};

export const getElementGame = (pageId: number) => {
    let eleGame = document.getElementById(`image-game-worksheet-${pageId}`);
    if (!eleGame) {
        eleGame = document.querySelector("#image-overview0");
        if (!eleGame) {
            eleGame = document.querySelector(".game-worksheets");
        }
    }
    return eleGame;
};

const getPositionEndLine = (
    top: number,
    left: number,
    width: number,
    height: number,
    line: IOffset[]
) => {
    let x = left - 2,
        y = top - 2,
        maxX = x + width + 4,
        maxY = y + height + 4;

    const line1Start = { x: line[0].offsetX, y: line[0].offsetY };
    const line1End = { x: line[1].offsetX, y: line[1].offsetY };
    const listLine = [
        [
            { x, y },
            { x: maxX, y },
        ],
        [
            { x: maxX, y },
            { x: maxX, y: maxY },
        ],
        [
            { x: maxX, y: maxY },
            { x, y: maxY },
        ],
        [
            { x, y: maxY },
            { x, y },
        ],
    ];
    let position = null;
    listLine.forEach((e) => {
        const intersection = lineIntersection(line1Start, line1End, e[0], e[1]);
        if (intersection !== null) {
            position = intersection;
        }
    });
    if (position !== null) {
        return getPositionLine(top, left, width, height, position);
    }
    return null;
};

function lineIntersection(
    p1: { x: number; y: number },
    p2: { x: number; y: number },
    p3: { x: number; y: number },
    p4: { x: number; y: number }
): { x: number; y: number } | null {
    const denominator =
        (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
    // Check if the lines are parallel (denominator is zero)
    if (denominator === 0) {
        return null;
    }
    const ua =
        ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) /
        denominator;
    const ub =
        ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) /
        denominator;
    // Check if the intersection point is within the line segments
    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
        return {
            x: p1.x + ua * (p2.x - p1.x),
            y: p1.y + ua * (p2.y - p1.y),
        };
    }
    return null; // No intersection
}

export const getPositionLine = (
    top: number,
    left: number,
    width: number,
    height: number,
    position: { x: number; y: number }
): { x: number; y: number } => {
    let x = left,
        y = top,
        maxX = x + width,
        maxY = y + height;

    //right position
    if (
        (position.x >= maxX && position.y >= y && position.y <= maxY) ||
        lineIntersection(
            { x, y },
            position,
            { x: maxX, y: maxY },
            { x: maxX + 100, y: maxY }
        ) ||
        lineIntersection(
            { x, y: maxY },
            position,
            { x: maxX, y },
            { x: maxX + 100, y }
        )
    ) {
        return { x: maxX + 1, y: maxY - height / 2 + 1 };
    }

    // bottom position
    if (
        (position.y >= maxY && position.x >= x && position.x <= maxX) ||
        lineIntersection(
            { x, y },
            position,
            { x: maxX, y: maxY },
            { x: maxX, y: maxY + 100 }
        ) ||
        lineIntersection(
            { x: maxX, y },
            position,
            { x, y: maxY },
            { x, y: maxY + 100 }
        )
    ) {
        return { x: maxX - width / 2 + 1, y: maxY + 1 };
    }
    //left position
    if (
        (position.x <= x && position.y >= y && position.y <= maxY) ||
        lineIntersection(
            { x: maxX, y: maxY },
            position,
            { x, y },
            { x: x - 100, y }
        ) ||
        lineIntersection(
            { x: maxX, y },
            position,
            { x, y: maxY },
            { x: x - 100, y }
        )
    ) {
        return { x: x - 1, y: maxY - height / 2 - 1 };
    }
    return { x: x + width / 2 + 1, y: y - 1 };
};

export const onTouchEndHandler = (
    event: any,
    idGamePanel: string,
    dispatch: any
) => {
    let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
    let svgItemBorder = document.querySelector('[id^="drawing-border"]');
    let gameContainerElement = document.querySelector(
        "." + idGamePanel
    ) as HTMLElement;
    //
    let targetList: Array<any> = [];
    targetList.push(
        document.elementFromPoint(
            event.changedTouches[0].clientX + 0.00001,
            event.changedTouches[0].clientY + 0.00001
        )
    );
    targetList.push(
        document.elementFromPoint(
            event.changedTouches[0].clientX + 0.00001,
            event.changedTouches[0].clientY - 0.00001
        )
    );
    targetList.push(
        document.elementFromPoint(
            event.changedTouches[0].clientX - 0.00001,
            event.changedTouches[0].clientY + 0.00001
        )
    );
    targetList.push(
        document.elementFromPoint(
            event.changedTouches[0].clientX - 0.00001,
            event.changedTouches[0].clientY + 0.00001
        )
    );

    let eventTarget = event.target as HTMLElement;
    // let eventTargetClass = eventTarget.getAttribute("class");
    let touchEndTarget = getContainerElement(targetList);
    let targetIdItem = eventTarget.getAttribute("id");
    let touchEndTargetClass = touchEndTarget?.getAttribute("class");
    let touchEndTargetId = touchEndTarget?.getAttribute("id");
    let svgContainer = document.querySelector("#svg-draw");

    const findPoint = (id: string, startPoint: any, targetPoint: any) => {
        let targetAnswer = document.getElementById(id);
        const sizeTarget = targetAnswer.getBoundingClientRect();
        let rect = svgContainer.getBoundingClientRect(),
            offsetX = sizeTarget.x - rect.left,
            offsetY = sizeTarget.y - rect.top,
            endPoint = getPositionEndLine(
                offsetY,
                offsetX,
                sizeTarget.width,
                sizeTarget.height,
                [startPoint, targetPoint]
            );
        return endPoint;
    };

    if (svgItem && gameContainerElement && svgItemBorder) {
        gameContainerElement.style.cursor = "auto";
        // //
        if (
            touchEndTargetClass === "join-item" &&
            touchEndTargetId !== targetIdItem
        ) {
            let startIdElement = svgItem.getAttribute("id");
            let pageId = startIdElement?.split("-")[2];
            let eleGame = getElementGame(parseInt(pageId));
            let screenWidth = eleGame?.getBoundingClientRect().width;

            let targetPoint = new Offset({
                offsetX: parseInt(svgItem.getAttribute("x2") ?? ""),
                offsetY: parseInt(svgItem.getAttribute("y2") ?? ""),
                screenWidth,
            });
            let startPointTmp = new Offset({
                offsetX: parseInt(svgItem.getAttribute("x1") ?? ""),
                offsetY: parseInt(svgItem.getAttribute("y1") ?? ""),
                screenWidth,
            });
            let startIdItem = startIdElement?.split("-")[1];

            const startPointFind = findPoint(
                "box-answer-id-" + startIdItem,
                targetPoint,
                startPointTmp
            );
            const startPoint = new Offset({
                offsetX: startPointFind.x,
                offsetY: startPointFind.y,
                screenWidth,
            });
            const endPoint = findPoint(
                "box-answer-id-" + touchEndTargetId,
                startPoint,
                targetPoint
            );
            if (endPoint !== null) {
                targetPoint = new Offset({
                    offsetX: endPoint.x,
                    offsetY: endPoint.y,
                    screenWidth,
                });
                svgItem.setAttribute("x2", endPoint.x.toString());
                svgItem.setAttribute("y2", endPoint.y.toString());
            }
            if (startIdItem && touchEndTargetId) {
                let jointElement = document.querySelector(".join-item");
                let ratio = 1;
                if (jointElement) {
                    try {
                        ratio = parseFloat(
                            jointElement.getAttribute("data-ratio")
                        );
                    } catch (error) {}
                }

                let listLine = document.querySelectorAll('[id^="done-"]');
                let deleteIds = [];
                listLine.forEach((line) => {
                    let id: string = line.id;
                    let idData: string[] = id.split("-");
                    if (idData.includes(touchEndTargetId)) {
                        let idDataChange = idData.filter(
                            (id) => id !== touchEndTargetId
                        );
                        deleteIds.push(idDataChange[1]);
                    }
                    if (idData.includes(startIdItem)) {
                        let idDataChange = idData.filter(
                            (id) => id !== startIdItem
                        );
                        deleteIds.push(idDataChange[1]);
                    }
                });
                console.log(deleteIds);
                dispatch(removeMatchingIdAndPointsAction(deleteIds));

                dispatch(
                    updateMatchingIdAndPointsAction(
                        [startIdItem, touchEndTargetId],
                        [startPoint, targetPoint],
                        ratio
                    )
                );
                svgItem.appendChild(document.createElement("div"));
                svgItem.parentNode?.removeChild(svgItem);
                svgItemBorder.appendChild(document.createElement("div"));
                svgItemBorder.parentNode?.removeChild(svgItemBorder);
            }
        } else {
            //
            svgItem.appendChild(document.createElement("div"));
            svgItem.parentNode?.removeChild(svgItem);
            svgItemBorder.appendChild(document.createElement("div"));
            svgItemBorder.parentNode?.removeChild(svgItemBorder);
        }
    }
};

export const getContainerElement = (targetList: Array<any>) => {
    for (let target of targetList) {
        if (target) {
            if (target.getAttribute("class") === "join-item") {
                return target;
            }
        }
    }
    return targetList[0];
};
export async function loadFonts(fontInfo) {
    if (fontInfo.fontUrl.includes("worksheetzone//")) {
        fontInfo.fontUrl = fontInfo.fontUrl.replace(
            "worksheetzone//",
            "worksheetzone/"
        );
    }
    const font = new FontFace(fontInfo.fontName, `url(${fontInfo.fontUrl})`);
    // wait for font to be loaded
    await font.load();
    // add font to document
    document.fonts.add(font);
    // enable font with CSS class
    document.body.classList.add("fonts-loaded");
}

export const loadFontInfo = (fontFamily: string) => {
    let fontInfos = ConstantFonts.FONT_LIST_INFO;
    for (let font of fontInfos) {
        if (font.fontName == fontFamily) loadFonts(font);
    }
};
