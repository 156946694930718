import { createSelector } from "@reduxjs/toolkit";
import { IPageWorksheetNew } from "../shared/models/pageWorksheetNew";
import { IResourceItemNew } from "../shared/models/resourceItemNew";
import ConstantsTool from "../shared/utils/ConstantsTool";
import { RootState } from "./store";

const pagesWorksheetSelector = (state: RootState) =>
    state.createWorksheetState.pagesWorksheet;

export const getResourceItemByActivityAndType = () =>
    createSelector(
        pagesWorksheetSelector,
        (_, props) => props.activityId,
        (_, props) => props.type,

        (
            pagesWorksheet: IPageWorksheetNew[],
            activityId: string,
            type: number
        ) => {
            for (let i = 0; i < pagesWorksheet.length; i++) {
                const resourceItems = pagesWorksheet[i].resourceItems;
                const foundItem = resourceItems.find(
                    (item) =>
                        item.activityId === activityId && item.type === type
                );
                if (foundItem) {
                    return foundItem;
                }
            }
            return null;
        }
    );
export const getResourceItemById = () =>
    createSelector(
        pagesWorksheetSelector,
        (_, props) => props.resourceItemId,

        (pagesWorksheet: IPageWorksheetNew[], resourceItemId: string) => {
            for (let i = 0; i < pagesWorksheet.length; i++) {
                const resourceItems = pagesWorksheet[i].resourceItems;
                const foundItem = resourceItems.find(
                    (item) => item.id === resourceItemId
                );
                if (foundItem) {
                    return foundItem;
                }
            }
            return null;
        }
    );

export const getResourceItemsByActivityAndType = () =>
    createSelector(
        pagesWorksheetSelector,
        (_, props) => props.activityId,
        (_, props) => props.type,

        (
            pagesWorksheet: IPageWorksheetNew[],
            activityId: string,
            type: number
        ) => {
            let allResourceFound: IResourceItemNew[] = [];
            for (let i = 0; i < pagesWorksheet.length; i++) {
                const resourceItems = pagesWorksheet[i].resourceItems;
                allResourceFound = allResourceFound.concat([
                    ...resourceItems.filter(
                        (el) => el.activityId === activityId && el.type === type
                    ),
                ]);
            }
            if (type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK) {
                return allResourceFound.sort(
                    (a, b) =>
                        a.question.indexQuestion - b.question.indexQuestion
                );
            }
            return allResourceFound;
        }
    );
