import {
    useEffect,
    useRef,
    useState,
    useSyncExternalStore,
} from "react";
import { useAppDispatch } from "../../../../redux/hook";
import {
    updateResourceItem,
    updateResourceItems,
} from "../../../../redux/reducers/createWorksheet";
import ToolTipCustomize from "../../../../resource-user/components/common/library/tool-tip";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import { getPrefixImage, resourceCanEditTransparency } from "../../../../utils";
import { ConstantsApp } from "../../../../utils/Constants";
import PopupModal from "../../../../resource-user/components/common/pop-up/PopupModal";
import SliderInput from "../common/sliderInput";
import groupElementStore from "../../../../syncExternalStore/groupElementStore";

function EditTransparentBar({
    currentElement,
    setCurrentElement,
}: {
    currentElement: IResourceItemNew;
    setCurrentElement: any;
}) {
    const dispatch = useAppDispatch();
    const [opacity, setOpacity] = useState(1);
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const min = 0;
    const max = 100;
    const groupElementState = useSyncExternalStore(
        groupElementStore.subscribe,
        groupElementStore.read
    );

    useEffect(() => {
        if (currentElement.opacity > 1) {
            setOpacity(1);
        } else {
            let resourceItems = groupElementState.resourceItemsCurrent.filter(
                (e) => resourceCanEditTransparency(e)
            );
            if (currentElement.resourceIds.length && resourceItems.length) {
                let opacity = resourceItems[0].opacity;
                for (let i = 1; i < resourceItems.length; i++) {
                    if (opacity !== resourceItems[i].opacity) {
                        opacity = 1;
                        break;
                    }
                }
                setOpacity(opacity);
            } else {
                setOpacity(currentElement.opacity);
            }
        }
    }, [currentElement.id]);

    const onChangeGroup = () => {
        dispatch(
            updateResourceItems({
                pageIndex: currentElement.pageIndex,
                resourceItems: groupElementState.resourceItemsCurrent,
            })
        );
    };

    const onChangeOpacity = (opacity: number, type?: "input") => {
        if (opacity >= 0 && opacity <= 1) {
            setOpacity(opacity);
            if (currentElement.resourceIds.length) {
                let resourceItems = [];
                groupElementState.resourceItemsCurrent.forEach((item) => {
                    if (resourceCanEditTransparency(item))
                        resourceItems.push({ ...item, opacity });
                });
                if (resourceItems.length) {
                    groupElementStore.updateResourceItems(resourceItems);
                }
                if (type === "input") onChangeGroup();
                return;
            }
            setCurrentElement({
                ...currentElement,
                opacity,
            });
            if (type === "input") {
                updateResource({ ...currentElement, opacity });
            }
        }
    };

    const updateResource = (currentElement: IResourceItemNew) => {
        if (currentElement.resourceIds.length) {
            onChangeGroup();
        } else {
            dispatch(
                updateResourceItem({
                    currentElementId: currentElement.id,
                    pageIndex: currentElement.pageIndex,
                    attrValues: [
                        {
                            attr: "opacity",
                            value: opacity,
                        },
                    ],
                })
            );
        }
    };

    return (
        <div>
            <ToolTipCustomize
                title={"Transparency"}
                style={ConstantsApp.CUSTOMIZE_TOOLTIP_PROPS}
            >
                <div
                    ref={ref}
                    onClick={() => {
                        setOpen(true);
                    }}
                    style={{
                        width: 23,
                        height: 23,
                        background: "rgba(33, 33, 33, 0.08)",
                        borderRadius: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${open ? "#212121" : "transparent"}`,
                    }}
                >
                    <img
                        src={getPrefixImage() + "/images/icon/transparent.png"}
                        width={16}
                        height={15}
                    />
                </div>
            </ToolTipCustomize>
            <PopupModal
                open={open}
                anchorElement={ref.current}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                top={4}
                onClose={() => {
                    setOpen(false);
                }}
                classname="edit-spacing-container"
            >
                <div className="popup-edit-bar-container">
                    <SliderInput
                        label={"Transparency"}
                        value={Math.round(opacity * 100)}
                        min={min}
                        max={max}
                        onChangeInput={function (value: number): void {
                            onChangeOpacity(value / 100, "input");
                        }}
                        onChangeSlider={(value) => {
                            onChangeOpacity(value / 100);
                        }}
                        onChangeCommitted={() => updateResource(currentElement)}
                    />
                </div>
            </PopupModal>
        </div>
    );
}

export default EditTransparentBar;
