import ClassroomConfig from "../utils/classroom";
import { IAssignment } from "./assignment";
import { IUserClass, UserClass } from "./userClass";

export interface IClass {
    id: string;
    name: string;
    password: string;
    thumbnail?: string;
    grades?: string[];
    createdAt: string;
    updatedAt: string;
    totalStudents?: number;
    totalAssignments?: number;
    description?: string;
    status?: number;

    assignments?: IAssignment[] | null;
    userClass?: IUserClass | null;
    students?: IUserClass[] | [];
}
export class Class implements IClass {
    id: string;
    name: string;
    password: string;
    thumbnail?: string;
    createdAt: string;
    updatedAt: string;
    totalStudents?: number;
    totalAssignments?: number;
    grades?: string[];
    description?: string;
    userClass?: IUserClass | null;
    assignments?: IAssignment[] | null;
    students?: IUserClass[] | [];

    status?: number;

    constructor(object: any = {}) {
        this.id = object.id ?? undefined;
        this.name = object.name ?? "";
        this.password = object.password ?? "";
        this.thumbnail = object.thumbnail ?? "";
        this.createdAt = object.createdAt ?? "";
        this.updatedAt = object.updatedAt ?? "";
        this.totalStudents = object.totalUser ?? object.totalStudents ?? 0;
        this.grades = object.grades ?? [];
        this.description = object.description ?? "";
        this.userClass = object.userClass
            ? new UserClass(object.userClass)
            : null;
        this.status = object.status ?? ClassroomConfig.CLASS_ACTIVE;
        this.assignments = object.assignments ? object.assignments : null;
        this.totalAssignments = object.totalAssignments ?? 0;
        this.students = object.students ?? [];
    }
}
