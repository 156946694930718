import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ICollection } from "../../../../../shared/models/collection";
import chevronRight from "../../../../assets/images/icon/chevron-right.png";
import calendarIcon from "../../../../assets/images/icon/icon-calendar.png";
import iconTickCircle from "../../../../assets/images/icon/icon-tick-circle.png";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import { formatMonth, getImageSource } from "../../../../utils";
import Constants from "../../../../utils/constants";
import useComponentVisible from "../../../common/click-out-side";
import "./style.scss";
const DropDownCalendar = ({
    dateSelected,
    setDateSelected,
    collection,
}: {
    dateSelected: string;
    setDateSelected: Function;
    collection?: ICollection;
}) => {
    const buttonRef = useRef(null);
    const [activeButton, setActiveButton] = useState(false);
    const [activeSelected, setActiveSelected] = useState("");

    const { isComponentVisible } = useComponentVisible(buttonRef);
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );

    // const dateUser =
    const getMonths = (fromDate, toDate) => {
        const fromYear = fromDate.getFullYear();
        const fromMonth = fromDate.getMonth();
        const toYear = toDate.getFullYear();
        const toMonth = toDate.getMonth();
        const results = [];

        for (let year = fromYear; year <= toYear; year++) {
            let monthNum = year === fromYear ? fromMonth : 0;
            const monthLimit = year === toYear ? toMonth : 11;

            for (; monthNum <= monthLimit; monthNum++) {
                let month = monthNum + 1;
                let key = month + "_" + year;

                results.push(key);
            }
        }
        return results;
    };

    const arrayMonths = getMonths(new Date(userInfo?.createdDate), new Date());

    useEffect(() => {
        if (collection?.id) {
            setDateSelected(Constants.DEFAULT_DATE);
        }
    }, [collection?.id]);
    useEffect(() => {
        if (isComponentVisible) setActiveButton(false);
    }, [isComponentVisible]);
    return (
        <>
            <div className="calendar-container">
                <div
                    className="calendar"
                    ref={buttonRef}
                    onClick={() => {
                        setActiveButton(!activeButton);
                    }}
                >
                    <div className="calendar-item">
                        <div className="img">
                            <img
                                src={getImageSource(calendarIcon)}
                                alt="calendar4-range"
                            />
                        </div>
                        <div className="current-date">
                            {formatMonth({
                                value: dateSelected,
                            })}
                        </div>
                        <div
                            className={"img" + (activeButton ? " active" : "")}
                        >
                            <img
                                src={getImageSource(chevronRight)}
                                // src="/images/icon/chevron-right.png"
                                alt="chevron-right"
                            />
                        </div>
                    </div>
                    <>
                        <div
                            className={
                                "dropdown-list " +
                                (activeButton ? " active" : "")
                            }
                        >
                            <div className="list-created-date">
                                <div className="created-date-all">
                                    <div className="date-all">
                                        <div
                                            onClick={() => {
                                                setDateSelected(
                                                    Constants.DEFAULT_DATE
                                                );
                                            }}
                                        >
                                            {formatMonth({
                                                value: Constants.DEFAULT_DATE,
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-date">
                                    {arrayMonths
                                        ?.reverse()
                                        ?.map((item: any, index: number) => {
                                            const active = dateSelected == item;
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        "date-item " +
                                                        (active
                                                            ? " selected"
                                                            : "")
                                                    }
                                                    onClick={() => {
                                                        setDateSelected(item);
                                                    }}
                                                >
                                                    {active ? (
                                                        <>
                                                            <div className="item-selected">
                                                                <img
                                                                    // src="/images/icon/icon-tick-circle.png"
                                                                    src={getImageSource(
                                                                        iconTickCircle
                                                                    )}
                                                                    alt=" icon-tick-circle"
                                                                />
                                                            </div>
                                                            <div className="selected"></div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {formatMonth({
                                                        value: item,
                                                    })}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};
export default DropDownCalendar;
