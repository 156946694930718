import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table, Toast } from 'react-bootstrap';
import './index.scss';
import { useDispatch } from 'react-redux';
import PromptInteractiveBlock from '../prompt-interactive-block';
import { useAppSelector } from '../../../../../redux/hook';
import { setTemplate } from '../../../../../redux/reducers/createWorksheet';
import { getZoneLabel } from '../../../../../utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
const AddZonePopup = (props) => {
    const {
        init,
        uniqueFields,
        onClose,
        onSave
    } = props;
    const [data, setData] = useState(init ? {
        fieldName: init.fieldName,
        nbFields: init.nbFields,
        fieldDescription: init.fieldDescription,
        parentField: init.parentField,
    } : {
        fieldName: '',
        nbFields: 1,
        fieldDescription: '',
        parentField: ''
    })
    const [errors, setErrors] = useState({
        nbFields: null
    })
    const {fieldName, nbFields, fieldDescription, parentField} = data;
    const onChangeData = (field, value) => {
        setData(prev => ({
            ...prev,
            [field]: value
        }))
        setErrors(prev => ({
            ...prev,
            [field]: null
        }))
    }
    
    const onClickSave = () => {
        if (!(nbFields >= 1)) {
            setErrors({
                nbFields: 'Min = 1;'
            })
            return;
        }
        if (fieldName) onSave(data)
    }

    return (
        <Modal
                show={true}
                onHide={onClose}
                contentLabel="Add zone"
                className="add-zone-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add/Update zone
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Field name</Form.Label>
                                <Form.Control value={fieldName} onChange={e => onChangeData('fieldName', e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Nb of fields</Form.Label>
                                <Form.Control type="number" min={1} step={1} value={nbFields} onChange={e => {
                                    onChangeData('nbFields', parseInt(e.target.value, 10))
                                }} />
                                {errors.nbFields && <Form.Text className="text-danger">{errors.nbFields}</Form.Text>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Child of</Form.Label>
                                <Form.Select  value={parentField} onChange={e => onChangeData('parentField', e.target.value)}>
                                    <option value={null}></option>
                                    {
                                        uniqueFields.filter(item => item.fieldName !== fieldName).map((item, index) => {
                                            return (
                                                <option key={index} value={item.fieldName}>
                                                    {item.fieldName}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-3 mb-3">
                                <Form.Label>Field Description</Form.Label>
                                <Form.Control value={fieldDescription} onChange={e => onChangeData('fieldDescription', e.target.value)}
                                    as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClickSave}>Save</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default AddZonePopup;