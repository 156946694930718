import { IClass } from "../../../shared/models/class";
import { ICollection } from "../../../shared/models/collection";

export enum SnackbarTypes {
    UPDATE_ALERT_SNACK_BAR_RESOURCE = "UPDATE_ALERT_SNACK_BAR_RESOURCE",
    SET_MESSAGE_RESOURCE = "SET_MESSAGE_RESOURCE",
    REMOVE_MESSAGE_RESOURCE = "REMOVE_MESSAGE_RESOURCE",
    SET_COLLECTIONid_RESOURCE = "SET_COLLECTIONid_RESOURCE",
    // SHOW_WARNING_DOWNLOAD = "SHOW_WARNING_DOWNLOAD",
    // SHOW_SAFE_DOWNLOAD = "SHOW_SAFE_DOWNLOAD",
    // CLOSE_ALERT_DOWNLOAD = "CLOSE_ALERT_DOWNLOAD",
    SET_ALERT_DOWNLOAD_WS = "SET_ALERT_DOWNLOAD_WS",
}
export interface ISnackbarAction {
    type: SnackbarTypes;
    message?: string;
    severity?: string;
    collectionId?: string;
    classAssignment?: IClass;
    collection?: ICollection;
    alertDownloadWs?: number;
    sharePinterest?: number;
    anchorOrigin?: any;
}
export const setMessageAction = (
    message: string,
    severity?: any
): ISnackbarAction => {
    //
    return {
        type: SnackbarTypes.SET_MESSAGE_RESOURCE,
        message,
        severity: severity ?? "success",
    };
};
export const removeMessageAction = () => {
    return { type: SnackbarTypes.REMOVE_MESSAGE_RESOURCE };
};

export const setCollectionIdAction = ({
    collectionId,
    collection,
}: {
    collectionId: string;
    collection?: ICollection;
}): ISnackbarAction => {
    return {
        type: SnackbarTypes.SET_COLLECTIONid_RESOURCE,
        collectionId: collectionId,
        collection,
    };
};

export const updateAlertSnackBarAction = ({
    collectionId,
    collection,
    message,
    severity,
    classAssignment,
}: {
    collectionId?: string;
    collection?: ICollection;
    message?: string;
    severity?: string;
    classAssignment?: IClass;
}): ISnackbarAction => {
    return {
        type: SnackbarTypes.UPDATE_ALERT_SNACK_BAR_RESOURCE,
        collectionId: collectionId,
        collection,
        message,
        severity,
        classAssignment,
    };
};

export const setAlertDownloadWsAction = ({
    alertDownloadWs,
}: {
    alertDownloadWs;
}) => ({
    type: SnackbarTypes.SET_ALERT_DOWNLOAD_WS,
    alertDownloadWs,
});
