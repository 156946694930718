import { useSelector } from "react-redux";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import DynamicComponent from "../common/dynamic-component";
import "./index.scss";

const CustomizedSnackbars = () => {
    const snackbarResourceState = useSelector(
        (state: ResourceAppState) => state.snackbarResourceState
    );

    const { message, collectionId } = snackbarResourceState;

    if (collectionId?.length > 0 || message.length > 0) {
        return (
            <>
                <DynamicComponent
                    path="./snackbar-content"
                    component={import("./snackbar-content")}
                />
            </>
        );
        //return  <SnackbarContent />;
    }

    return null;
};
export default CustomizedSnackbars;
