import React, { useState } from 'react';
import Select from 'react-select';
import { generateRandomText, SelectOptionType } from '../SelectExampleText';
import { IResourceItemNew } from '../../../../../../shared/models/resourceItemNew';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hook';
import { updateResourceItem } from '../../../../../../redux/reducers/createWorksheet';

const generateOptions = (nbOptions: number = 5, step: number = 5) => {
    let startLength = 0;
    return Array.from({ length: nbOptions }, (_, index) => {
        startLength += step;
        return ({
            label: `${startLength} items`,
            value: `${startLength}`,
        })
    })
};

const SelectNbLinkedZoneContainer = ({
    currentElement,
}: {
    currentElement: IResourceItemNew
}) => {
    const dispatch = useAppDispatch();
    const { pageIndex } = useAppSelector((state) => state.createWorksheetState);

    const [selectNbLinkedZones, setSelectNbLinkedZones] = useState<SelectOptionType | ''>('');

    const handleUpdateNbLinkedZones = (selectedValue: SelectOptionType) => {
        const { value } = selectedValue;

        let newContent = Array(parseInt(value)).fill(generateRandomText(10)).join('~');
        setSelectNbLinkedZones(selectedValue);

        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                pageIndex,
                attrValues: [
                    {
                        attr: "textAttribute",
                        value: {
                            ...currentElement.textAttribute,
                            content: newContent,
                        },
                    }
                ]
            })
        )
    };

    return (
        <div className="select-example-text m-1">
            <Select
                options={generateOptions(5, 5)}
                value={selectNbLinkedZones}
                onChange={handleUpdateNbLinkedZones}
            />
        </div>
    )
};

export default SelectNbLinkedZoneContainer;