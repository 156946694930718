import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//     getDataCollectionAction,
//     selectCollectionItemResourceAction,
// } from "../../redux/redux/action/resource.action";
import { ICollection } from "../../../shared/models/collection";
import { IWorksheet } from "../../../shared/models/worksheet";
import { uppercaseFirstLetter } from "../../../shared/utils";
import {
    getDataCollectionAction,
    selectCollectionItemResourceAction,
} from "../../redux/action/resource.action";
import NextChildCollection from "../icons/next-child-collection";
import UnSelectItem from "../icons/select/select-worksheet";
import SelectItem from "../icons/select/unselect-worksheet";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";

const RowCollection = ({
    collection,
    setAnimation,
    worksheet,
    setBreadCrumbCollections,
}: {
    collection: ICollection;
    setAnimation: Function;
    worksheet?: IWorksheet;
    setBreadCrumbCollections: Function;
}) => {
    const dispatch = useDispatch();
    // const [icon, setIcon] = useState(saved ? true : false);
    const [icon, setIcon] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    // console.log("firstTime", firstTime);

    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );

    const arrayCollectionState = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.toCollection?.arrayCollection
    );
    const arrayThumbnailState = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.toCollection?.arrayThumbnail
    );
    let collectionsState: any = useSelector(
        (state: ResourceAppState) => state.resourceState.collections.data
    );

    let toCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem.toCollection?.data
    );
    let listCollectionSave = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.toCollection?.listCollection
    );
    let worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    // const collectionSelected = useSelector(
    //     (state:ResourceAppState) =>
    //         state.resourceState.actionGridItem.toCollection.data
    // );

    let arrayCollection = toCollection?.id
        ? arrayCollectionState
        : collectionsState;
    let arrayThumbnail = toCollection?.id ? arrayThumbnailState : [];
    let arrayCollectionSave = JSON.parse(JSON.stringify(arrayCollection));
    arrayCollectionSave =
        arrayCollectionSave?.filter((c) => {
            if (
                c?.worksheetItems?.find(
                    (i) =>
                        i?.worksheetId == worksheet?.id ||
                        (i?.worksheetId == worksheets[0]?.id &&
                            worksheets?.length == 1)
                )
            ) {
                return c.id;
            }
        }) ?? [];
    let listId = arrayCollectionSave?.map((c) => c.id) ?? [];

    useEffect(() => {
        if (listId?.includes(collection.id)) {
            if (listCollectionSave.find((c) => c.id == collection.id)) {
                setIcon(false);
            } else {
                setIcon(true);
            }
        } else {
            if (listCollectionSave.find((c) => c.id == collection.id)) {
                setIcon(true);
            } else {
                setIcon(false);
            }
        }
    }, [listId]);

    return (
        <>
            <div
                className={
                    toCollection?.id == collection?.id
                        ? "item selected"
                        : "item"
                }
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(
                        selectCollectionItemResourceAction({
                            collection: collection,
                            collectionsState: collectionsState,
                            arrayCollection: arrayCollection,
                            arrayThumbnail: arrayThumbnail,
                            isSelectCollection: collection,
                        })
                    );
                    setFirstTime(false);
                    setAnimation();
                }}
            >
                <div className="item-collection">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                        }}
                    >
                        {/* {firstTime ? (
                            <>
                                <UnselectWorksheet
                                    active={!icon}
                                    firstTime={firstTime}
                                    disable={icon}
                                />
                                <SelectWorksheet
                                    active={icon}
                                    firstTime={firstTime}
                                    disable={!icon}
                                />
                            </>
                        ) : !icon ? (
                            <>
                                <>
                                    <UnselectWorksheet
                                        active={!icon}
                                        firstTime={firstTime}
                                    />
                                </>
                            </>
                        ) : (
                            <>
                                <SelectWorksheet
                                    active={icon}
                                    firstTime={firstTime}
                                />
                            </>
                        )} */}
                        <div
                            className={
                                icon
                                    ? firstTime
                                        ? "first-v2"
                                        : "v2"
                                    : firstTime
                                    ? "first-v1"
                                    : "v1"
                            }
                        >
                            {icon ? (
                                <>
                                    <>
                                        {/* <UnselectWorksheet
                                        active={!icon}
                                        firstTime={firstTime}
                                    /> */}
                                        <SelectItem />
                                    </>
                                </>
                            ) : (
                                <>
                                    {/* <SelectWorksheet
                                    active={icon}
                                    firstTime={firstTime}
                                /> */}
                                    <UnSelectItem />
                                </>
                            )}
                        </div>
                        <div className="item-collection-name">
                            {uppercaseFirstLetter(collection?.name)}
                        </div>
                    </div>

                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(
                                getDataCollectionAction({
                                    collection: collection,
                                    userId: userInfo?.id,
                                    loading: true,
                                    isNextLevelCollection: true,
                                })
                            );
                            setBreadCrumbCollections(collection);
                            setAnimation();
                        }}
                    >
                        <NextChildCollection />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RowCollection;
