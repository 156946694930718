import {
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from "@redux-saga/core/effects";
import { all } from "redux-saga/effects";
import { Assignment } from "../../../shared/models/assignment";
import { IClass } from "../../../shared/models/class";
import Config from "../../../shared/utils/config";
import {
    AssignDigitallyTypes,
    IAssignDigitallyAction,
    createClassSuccessAction,
    getAssignmentSuccessAction,
    getClassesSuccessAction,
} from "../action/assign-digitally.action";

import { stringifyAndParse } from "../../../shared/utils";
import { selectActionGridItemAction } from "../action/resource.action";
import { updateAlertSnackBarAction } from "../action/snackbar.action";
import {
    createClass,
    getAssignments,
    getClassByUserId,
    updateAssignment,
} from "../repositories/class";
import { ResourceAppState } from "../reducer/root.reducerModule";

function* getClassesByUserIdSaga() {
    let userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    let classData = yield call(getClassByUserId, userIdState);
    if (classData) {
        yield put(getClassesSuccessAction(stringifyAndParse(classData)));
    }
}

function* getAssignment(action: IAssignDigitallyAction) {
    let userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    let assignments = yield call(
        getAssignments,
        userIdState,
        action.worksheetId
    );
    if (assignments) {
        yield put(getAssignmentSuccessAction(assignments));
    }
}

function* createClassSaga(action: IAssignDigitallyAction) {
    const { name, worksheet, worksheets } = action;
    let userIdState: string = yield select(
        (state: ResourceAppState) => state?.userInfoResourceState?.data?.id
    );
    let newCls: IClass | null = yield call(createClass, userIdState, name);
    let assignment = new Assignment({
        userId: userIdState,
        classId: newCls.id,
        worksheet: worksheet?.id
            ? worksheet?.id
            : worksheets?.length
            ? worksheets[0].id
            : "",
        title: worksheet?.id ? worksheet?.title : "",
        status: Config.STATUS_PUBLIC,
    });
    if (worksheet?.worksheets || worksheets?.length) {
        let wss = worksheet?.id
            ? worksheet.worksheets
            : worksheets?.length
            ? worksheets
            : [];
        yield all(
            wss.map((ws, i) => {
                let newAssignment = new Assignment({
                    userId: userIdState,
                    classId: newCls.id,
                    worksheet: ws.id,
                    title: ws.title,
                    status: Config.STATUS_PUBLIC,
                });
                return call(updateAssignment, newAssignment);
            })
        );
    } else if (worksheet?.id) {
        newCls.assignments = [assignment];
        yield call(updateAssignment, assignment);
    }

    yield put(
        updateAlertSnackBarAction({
            collectionId: newCls.id,
            severity: "success",
            classAssignment: newCls,
        })
    );
    if (newCls) {
        yield put(createClassSuccessAction(newCls, assignment));
        yield delay(500);
        yield put(
            selectActionGridItemAction({
                action: Config.ACTION_GRID.DEFAULT,
            })
        );
    }
}

function* listenGetClassesByuserId() {
    yield takeLatest(AssignDigitallyTypes.GET_CLASSES, getClassesByUserIdSaga);
    yield takeLatest(AssignDigitallyTypes.GET_ASSIGNMENT, getAssignment);
    yield takeLatest(AssignDigitallyTypes.CREATE_CLASS, createClassSaga);
    // yield takeLatest(
    //     AssignDigitallyTypes.UPDATE_ASSIGNMENT,
    //     updateAssignmentSaga
    // );
}

export const assignDigitallySaga = [fork(listenGetClassesByuserId)];
