import { useState } from 'react';
import { Button } from 'react-bootstrap';
import PromptInteractiveModal from './prompt-interactive-modal';
import './index.scss';

const PromptInteractive = ({}) => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className="prompt-interactive-container">
            <Button className="prompt-interactive-btn" onClick={() => setOpenModal(true)}>
                Interactive Prompt
            </Button>
            {openModal && <PromptInteractiveModal
                onClose={() => setOpenModal(false)}
            />}
        </div>
    )
}

export default PromptInteractive;