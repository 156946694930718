import CircleProgressCustomize from "../library/circle-progress";
import "./style.scss";

const LoadingComponent = ({ usingNewLoading = false }) => {
    if (usingNewLoading) {
        return (
            <div className="new-loading-component">
                <CircleProgressCustomize />
            </div>
        );
    }

    return (
        <>
            <div
                className="load-more-component"
                style={{
                    position: "absolute",
                }}
            >
                <svg width="300" height="300">
                    <linearGradient
                        id="linearColors"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="1"
                    >
                        <stop offset="10%" stopColor="#fe2342"></stop>
                        <stop offset="50%" stopColor="#079eff" />
                        <stop offset="80%" stopColor="#ffb933" />
                    </linearGradient>
                </svg>
            </div>
            <CircleProgressCustomize />
        </>
    );
};
export default LoadingComponent;
