import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkIsToolCreate, formatAMPM } from "../../../../utils";
import Routes from "../../../../utils/routes";
import "./index.scss";

const NotificationSave = ({ ratioProps }: { ratioProps?: number }) => {
    const ratioState = useSelector(
        (state: any) => state.wordSearchState.ratio * 1.3
    );
    const isSave = useSelector((state: any) => state.wordSearchState.isSave);
    const ratio = ratioProps ? ratioProps : ratioState;
    const [timeSave, setTimeSave] = useState(-1);

    useEffect(() => {
        if (isSave) {
            setTimeSave(new Date().getTime());
        } else {
            setTimeSave(-1);
        }
    }, [isSave]);

    if (timeSave === -1) {
        return null;
    }

    let ratioValue = ratio;
    if (!ratioValue) {
        ratioValue = 0.9;
    }

    return (
        <div
            className={
                "notification-save-section " +
                (checkIsToolCreate() ? "hidden" : "")
            }
            style={{
                fontSize: 13 * ratioValue,
                top: 7 * ratioValue,
                right: 14 * ratioValue,
            }}
        >
            {checkIsToolCreate() ? "Last saved to" : "Saved to"}{" "}
            <a
                href={"/collection" + Routes.CREATE_WS_COLLECTION}
                target="_blank"
                style={{
                    fontSize: 13 * ratioValue,
                }}
                rel="noreferrer"
            >
                My Created Worksheets
            </a>{" "}
            at {formatAMPM(timeSave)}
        </div>
    );
};

export default NotificationSave;
