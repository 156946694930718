import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IParamsQuotaResourceUser } from "../../../../../../../shared/models/quotaResourceuser";
import Config from "../../../../../../../shared/utils/config";
import { updateQuotaUserAction } from "../../../../../../redux/action/quotaResourceUser.action";
import { ResourceAppState } from "../../../../../../redux/reducer/root.reducerModule";
import {
    calculateTimeDownload,
    calculateTimeRemaining,
} from "../../../../../../utils";
import ItemMethod, { STATUS_BTN } from "../layout-item-method";
const FreeClaimMethod = ({ item, addEventGaFc }) => {
    const [showButtonClaim, setShowButtonClaim] = useState(STATUS_BTN.non); //-1: non, 0: count down, 1: show
    const dispatch = useDispatch();
    const userInfo = useSelector(
        (state: ResourceAppState) => state?.userInfoResourceState?.data
    );

    const timesDownload = useSelector(
        (state: ResourceAppState) => state?.quotaResourceUserState?.data?.times
    );
    const quotaResourceUserState = useSelector(
        (state: ResourceAppState) => state?.quotaResourceUserState?.data
    );
    const timeNextDownload = quotaResourceUserState?.timeNextDownload;
    useEffect(() => {
        const timeRemaining = calculateTimeRemaining(timeNextDownload);
        if (timeRemaining > 0) {
            setShowButtonClaim(STATUS_BTN.disable);
        } else {
            setShowButtonClaim(STATUS_BTN.able);
        }
    }, [timeNextDownload]);
    const handleClaimDownload = () => {
        let params: IParamsQuotaResourceUser = {
            userId: userInfo.id,
        };
        let bonusQuota;
        let nextTime = calculateTimeDownload(timesDownload, new Date());

        params = {
            ...params,
            timeNextDownload: nextTime,
            timeClaimed: new Date(),
            times: timesDownload + 1,
        };
        bonusQuota = Config.BONUS_QUOTA_DOWNLOAD_EACH_CLAIM;
        params = {
            ...params,
            bonusQuota,
        };
        dispatch(updateQuotaUserAction({ params }));
        addEventGaFc({ item });
    };

    return (
        <ItemMethod
            item={item}
            showBtnClaimMethod={showButtonClaim}
            handleClaimDownload={handleClaimDownload}
            setShowButtonClaim={setShowButtonClaim}
            ableClaim={showButtonClaim}
        />
    );
};

export default FreeClaimMethod;
