export class ConstantsApp {
    static TOOLTIP_PROPS = {
        tooltip: {
            sx: {
                bgcolor: "common.black",
                "& .MuiTooltip-arrow": {
                    color: "common.black",
                },
            },
        },
    };
    static CUSTOMIZE_TOOLTIP_PROPS = {
        backgroundColor: "#212121",
        color: "#fff",
    };
    static ID_CURRENT_ELEMENT = "id_current_element";
    static ID_ACTION_ELEMENT = "id_action_element";
    static ID_BORDER_ELEMENT = "id_border_element";
    static ID_PAGE_ELEMENT = "id_page_element";
    static LINK_VIDEO_TUTORIAL_FITB = "https://storage.googleapis.com/worksheetzone/video/fil_in_the_blank.mp4"
    static PADDING_PAGES = 38
}
