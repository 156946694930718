import { Answer, IAnswer } from "./answer";
import { IPuzzle } from "./puzzle";

export interface IScreenSize {
    height: number;
    width: number;
}
export class ScreenSize {
    height: number;
    width: number;
    constructor(object?: IScreenSize) {
        this.height = object?.height ?? 0;
        this.width = object?.width ?? 0;
    }
}

export interface IQuestion {
    id: string;
    top: number;
    left: number;
    isCorrect?: boolean;
    questionId: number;
}

export interface IWordSearchCreator {
    creator: string;
    studentInfos: string[];
    shapeNameId: string;
    size: number;
    direction: number;
    showAnswerKey: number;
    showDirection: number;
}

export interface IGame {
    images: string[];
    answers: IAnswer[];
    screenSize: IScreenSize;
    screenSizes: IScreenSize[];
    pdfs: string[];
    questions?: IQuestion[];
    original: string[];
    wordSearchCreator: IPuzzle;
}
export class Game implements IGame {
    images: string[];
    answers: IAnswer[];
    screenSize: IScreenSize;
    screenSizes: IScreenSize[];
    pdfs: string[];
    questions?: IQuestion[];
    original: string[];
    wordSearchCreator: IPuzzle;

    constructor(object: any = {}) {
        if (object?.answers && object.answers.length > 0) {
            this.answers = object.answers.map((ans: any) => {
                return new Answer(ans);
            });
        } else {
            this.answers = [];
        }
        this.screenSize = new ScreenSize(object.screenSize);
        this.screenSizes =
            object.screenSizes?.map((e: IScreenSize) => new ScreenSize(e)) ??
            [];
        this.images = object.images ?? [];
        this.pdfs = object.pdfs ?? [];
        this.questions = object.questions || [];
        this.original = object.original || [];
        this.wordSearchCreator = object.wordSearchCreator;
        // if (this.images.length > 1) {
        //     this.images = this.images.sort((a: string, b: string) =>
        //         a.localeCompare(b)
        //     );
        // }
    }
}
