import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../shared/utils/config";
import { getListReferralAction } from "../../../redux/action/referral.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import { formatDate } from "../../../utils";
import "./index.scss";
const FormRefer = () => {
    const dispatch = useDispatch();
    const userId = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState?.data?.id
    );
    useEffect(() => {
        if (userId) {
            dispatch(getListReferralAction());
        }
    }, [userId]);
    const referralList = useSelector(
        (state: ResourceAppState) => state.referralState.referralList
    );
    return (
        <>
            <div className="form-refer-container">
                <div className="title-form-refer">
                    <p>From Referrals</p>
                </div>
                <div className="form-refer-table">
                    <div className="title-table-item">
                        <div className="text-item-title">
                            <p>Recipient email</p>
                        </div>
                        <div className="text-item-title">
                            <p>Updated</p>
                        </div>
                        <div className="text-item-title">
                            <p>Status</p>
                        </div>
                    </div>
                    <div className="refer-content-table">
                        {referralList?.map((referral, index) => {
                            return (
                                <>
                                    <UserInvitedForm
                                        key={index}
                                        referral={referral}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
const UserInvitedForm = ({ referral }: { referral }) => {
    const emailText = referral.recipientEmail?.split("@");
    return (
        <div className="user-table-item">
            <div className="text-item-user email">
                <p>{emailText[0]}</p>
                {/* {isTablet ? (
                ) : (
                    <p>{referral.recipientEmail}</p>
                )} */}
            </div>
            <div className="text-item-user">
                <p>{formatDate(referral.updatedAt)}</p>
            </div>
            <ReferralStatus status={referral.status} />
        </div>
    );
};

const ReferralStatus = ({ status }: { status }) => {
    switch (status) {
        case Config.ACCEPTED_REFERR:
            return (
                <>
                    <div className="text-item-user">
                        <div className="img-refer-done">
                            <img
                                src="/images/refer-friend/icon-tick-done.png"
                                alt="refer-friend"
                            />
                        </div>
                        <p className="text-done">Done</p>
                    </div>
                </>
            );
        case Config.SENT_REFERRAL:
            return (
                <>
                    <div className="text-item-user">
                        <div className="img-refer-done">
                            <img
                                src="/images/refer-friend/icon-init-sent.png"
                                alt="refer-friend"
                            />
                        </div>
                        <p>Invitation Sent</p>
                    </div>
                </>
            );
        case Config.ACCEPTED_BY_OTHER_REFERR:
            return (
                <>
                    <div className="text-item-user">
                        <div className="img-refer-done">
                            <img
                                src="/images/refer-friend/accept-another.png"
                                alt="refer-friend"
                            />
                        </div>
                        <p>Accepted another referral</p>
                    </div>
                </>
            );

        default:
            break;
    }
};
export default FormRefer;
