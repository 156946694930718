import React from "react";

const MoveIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="24"
                height="24"
                rx="12"
                fill={isActive ? "#F3F3F3" : "#9C9C9C"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 16.129C10.5 15.7915 10.905 15.6265 11.1375 15.859L12.48 17.2015C12.63 17.3515 12.63 17.584 12.48 17.734L11.1375 19.0765C10.905 19.3165 10.5 19.1515 10.5 18.814V18.2215H7.5C7.0875 18.2215 6.75 17.884 6.75 17.4715C6.75 17.059 7.0875 16.7215 7.5 16.7215H10.5V16.129ZM14.25 16.7215H16.5C16.9125 16.7215 17.25 17.059 17.25 17.4715C17.25 17.884 16.9125 18.2215 16.5 18.2215H14.25C13.8375 18.2215 13.5 17.884 13.5 17.4715C13.5 17.059 13.8375 16.7215 14.25 16.7215Z"
                fill="#474747"
                fillOpacity="0.54"
            />
            <path
                d="M17 9.53497V12.325C17 13.8 15.8 15 14.325 15H9.675C8.2 15 7 13.8 7 12.325V8.71997H16.87C16.945 8.94497 16.985 9.17497 16.995 9.41997C17 9.45497 17 9.49997 17 9.53497Z"
                fill="#474747"
            />
            <path
                opacity="0.4"
                d="M16.87 8.72H7V7.21C7 5.99 7.99 5 9.21 5H10.375C11.19 5 11.445 5.265 11.77 5.7L12.47 6.63C12.625 6.835 12.645 6.865 12.935 6.865H14.33C15.515 6.86 16.525 7.64 16.87 8.72Z"
                fill="#474747"
            />
        </svg>
    );
};

export default MoveIcon;
