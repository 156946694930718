import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Collection,
    ICollection,
    WorksheetItem,
} from "../../../shared/models/collection";
import { IWorksheet } from "../../../shared/models/worksheet";
import { stringifyAndParse } from "../../../shared/utils";
import Config from "../../../shared/utils/config";
import {
    previousActionResourceAction,
    removeWorksheetOfCollectionAction,
    selectActionGridItemAction,
    setAfterActionGridAction,
    updateAlertResourceAction,
    updateCollectionResourceSuccessAction,
} from "../../redux/action/resource.action";
import { updateAlertSnackBarAction } from "../../redux/action/snackbar.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import { updateItemCollectionByIdAPI } from "../../redux/repositories/collection.repositories";
import { resetGa4Function } from "../../utils";
import ConstantsResource from "../../utils/ConstantsResource";
import ClassNameDontDelete from "../../utils/className";
import Routes from "../../utils/routes";
import { checkRoutes } from "../../utils/tracking";
import CreateIconCollection from "../icons/folder/create-icon";
import PopUpStepAction from "../main-login";

const FooterPopup = ({
    collection,
    worksheet,
    collections,
    setShowPopup,
    setShowPopupCreateCollection,
    titlePopup,
    setClosePopup,
}: {
    collection: ICollection;
    worksheet: IWorksheet;
    collections: ICollection[];
    setShowPopup: Function;
    setShowPopupCreateCollection: Function;
    titlePopup?: string;
    setClosePopup: Function;
}) => {
    const dispatch = useDispatch();

    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const collectionSelected = useSelector(
        (state: ResourceAppState) => state.resourceState.collectionSelected.data
    );
    const actionGridItem = useSelector(
        (state: ResourceAppState) => state.resourceState.actionGridItem.action
    );
    const worksheets = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.worksheets
    );
    const toCollection = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.toCollection?.data
    );
    const currentActivity = useSelector(
        (state: any) => state?.createWorksheetState?.currentActivity?.type
    );
    // const listCollection = useSelector(
    //     (state:ResourceAppState) => state.resourceState.collections?.data
    // );
    let listCollectionSave = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.actionGridItem?.toCollection?.listCollection
    );
    const [loading, setLoading] = useState(false);
    const [showPopupSurvey, setShowPopupSurvey] = useState(false);
    const find = toCollection?.worksheetItems.find(
        (i) => i.worksheetId == worksheet?.id
    );
    let saved = find ? true : false;
    let wsThumbnail = worksheets?.find((ws) => ws.thumbnail);
    if (!wsThumbnail) {
        wsThumbnail = worksheets?.find((ws) => ws.game?.images[0]);
    }
    const thumbnail = wsThumbnail?.thumbnail
        ? wsThumbnail?.thumbnail
        : worksheet?.thumbnail
        ? worksheet?.thumbnail
        : "";

    let isDisable = false;
    if (!listCollectionSave?.length) {
        isDisable = true;
    }

    const updateFc = async (item) => {
        let params: any = {};
        let collection = item.collection;
        let saved = item.saved;
        params.worksheetItems = collection.worksheetItems;
        params.collectionId = collection.id;
        let c = await updateItemCollectionByIdAPI(params);

        if (c.id) {
            let listWs = collection.worksheets;
            let newCollection = new Collection({
                ...collection,
                worksheets: [],
            });
            newCollection.worksheets = listWs;
            if (c?.parentIds?.length) {
                newCollection.parentIds = c?.parentIds;
            }

            if (actionGridItem != Config.ACTION_GRID.DEFAULT) {
                dispatch(updateCollectionResourceSuccessAction(newCollection));
            }
            if (saved && collectionSelected?.id == collection.id) {
                dispatch(removeWorksheetOfCollectionAction({ worksheet }));
            }

            return c;
        }
    };
    const arrCollection = ({
        collectionUpdated,
        listCollectionUpdate,
        worksheet,
        ableDelWs,
    }: {
        collectionUpdated: any;
        listCollectionUpdate: any[];
        worksheet: IWorksheet;
        ableDelWs?: boolean;
    }) => {
        const worksheetItem = new WorksheetItem({
            worksheetId: worksheet?.id,
            joinedDate: new Date(),
        });
        const checkSaved = collectionUpdated.worksheetItems.find(
            (w) => w?.worksheetId == worksheet?.id
        );

        if (collectionSelected?.id == collectionUpdated.id) {
            if (ableDelWs && checkSaved) {
                collectionUpdated.worksheetItems =
                    collectionUpdated.worksheetItems.filter(
                        (i) => i?.worksheetId != worksheet.id
                    );
                collectionUpdated.worksheets =
                    collectionUpdated.worksheets?.filter(
                        (w) => w?.id != worksheet.id
                    );
            } else {
                if (!checkSaved) {
                    collectionUpdated.worksheetItems.push(worksheetItem);
                    collectionUpdated.worksheets.push(worksheet);
                }
            }
            let findWs = false;
            if (listCollectionUpdate?.length) {
                findWs = listCollectionUpdate?.find(
                    (item) => item.collection.id == collectionUpdated.id
                );
            }
            if (!findWs) {
                listCollectionUpdate.push({
                    collection: collectionUpdated,
                    saved: checkSaved,
                });
            } else {
                listCollectionUpdate = listCollectionUpdate?.map((item) => {
                    if (item.collection.id == collectionUpdated.id) {
                        item.collection = collectionUpdated;
                        item.saved = checkSaved;
                    }
                    return item;
                });
            }
        } else {
            if (ableDelWs && checkSaved) {
                collectionUpdated.worksheetItems =
                    collectionUpdated.worksheetItems.filter(
                        (i) => i?.worksheetId != worksheet.id
                    );
                collectionUpdated.worksheets =
                    collectionUpdated.worksheets?.filter(
                        (w) => w?.id != worksheet.id
                    );
            } else {
                if (!checkSaved) {
                    collectionUpdated.worksheetItems.push(worksheetItem);
                    collectionUpdated.worksheets.push(worksheet);
                }
            }
            let findWs = false;
            if (listCollectionUpdate?.length) {
                findWs = listCollectionUpdate?.find(
                    (item) => item.collection.id == collectionUpdated.id
                );
            }
            if (!findWs) {
                listCollectionUpdate.push({
                    collection: collectionUpdated,
                    saved: checkSaved,
                });
            } else {
                listCollectionUpdate = listCollectionUpdate?.map((item) => {
                    if (item.collection.id == collectionUpdated.id) {
                        item.collection = collectionUpdated;
                        item.saved = checkSaved;
                    }
                    return item;
                });
            }
        }
    };
    const ListTrackingLanding = [
        {
            routes: Routes.BACK_TO_SCHOOL,
            action: "bts_add_multi_worksheets_to_colllection",
        },
        {
            routes: Routes.HALLOWEEN_WORKSHEETS,
            action: "hlw_add_multi_worksheets_to_collection",
        },
        {
            routes: Routes.CHRISTMAS_WORKSHEETS,
            action: "christmas_add_multi_worksheets_to_collection",
        },
    ];
    const addTrackingLanding = () => {
        ListTrackingLanding.map((item) => {
            if (
                typeof window !== "undefined" &&
                checkRoutes(window.location.pathname, item.routes)
            ) {
            }
        });
    };

    const addToCollection = async () => {
        resetGa4Function("ws_engagement", "true");
        setLoading(true);
        const listCollectionUpdate = [];

        stringifyAndParse(listCollectionSave).forEach(
            async (collectionUpdated) => {
                if (worksheets?.length > 1) {
                    worksheets.forEach((ws) => {
                        arrCollection({
                            collectionUpdated,
                            listCollectionUpdate,
                            worksheet: ws,
                        });
                    });
                } else {
                    let ws =
                        worksheets?.length == 1 ? worksheets[0] : worksheet;
                    arrCollection({
                        collectionUpdated,
                        listCollectionUpdate,
                        worksheet: ws,
                        ableDelWs: true,
                    });
                }
            }
        );

        if (listCollectionUpdate?.length) {
            let result = await Promise.all(
                listCollectionUpdate.map(async (item) => {
                    let update = await updateFc(item);
                    return update;
                })
            );
            if (result.length == listCollectionUpdate.length) {
                let message = "";
                let severity = "success";
                let collectionUpdate = result[0] ?? new Collection();
                if (result.length > 1) {
                    if (
                        result.length ==
                        listCollectionUpdate.filter((i) => i.saved)?.length
                    ) {
                        message = "Removed From All Selected Collections";
                        severity = "error";
                    } else {
                        message = "Saved To All Selected Collections";
                    }
                } else {
                    if (collectionUpdate?.id) {
                        let wsThumbnail = collectionUpdate.worksheets.find(
                            (ws) => ws.thumbnail
                        );
                        if (!wsThumbnail) {
                            wsThumbnail = collectionUpdate.worksheets.find(
                                (ws) => ws.game?.images[0]
                            );
                        }
                        collectionUpdate.thumbnail = listCollectionUpdate[0]
                            .saved
                            ? !!collectionUpdate.thumbnail
                                ? collectionUpdate.thumbnail
                                : thumbnail
                            : wsThumbnail?.thumbnail
                            ? wsThumbnail?.thumbnail
                            : worksheet?.thumbnail;

                        severity = listCollectionUpdate[0].saved
                            ? "error"
                            : "success";
                    }
                }
                dispatch(
                    updateAlertSnackBarAction({
                        message,
                        collectionId: collectionUpdate?.id,
                        collection: collectionUpdate,
                        severity,
                    })
                );
                dispatch(
                    setAfterActionGridAction({
                        afterAction: Config.ACTION_GRID.CLEAR_WSS,
                    })
                );
                setLoading(false);

                setClosePopup(true);
            }
        }
        addTrackingLanding();
    };
    const moveWorksheet = async (saved) => {
        resetGa4Function("ws_engagement", "true");
        setLoading(true);
        const worksheetItem = new WorksheetItem({
            worksheetId: worksheet?.id,
            joinedDate: new Date(),
        });

        let params: any = {};
        params.worksheetItems = saved
            ? collection?.worksheetItems.filter(
                  (ws) => ws.worksheetId != worksheet?.id
              )
            : [...collection?.worksheetItems, worksheetItem];
        params.collectionId = collection.id;
        let c = await updateItemCollectionByIdAPI(params);

        if (c.id) {
            let worksheets = [...collection.worksheets, worksheet];
            worksheets = worksheets.filter((ws) =>
                params.worksheetItems.some(
                    (itemWs) => itemWs.worksheetId == ws.id
                )
            );

            c.worksheets = worksheets;
            dispatch(updateCollectionResourceSuccessAction(c));
            if (!saved) {
                dispatch(
                    updateAlertResourceAction(
                        false,
                        "",
                        Config.SUCCESS,
                        Config.TYPE_ALERT.ALERT_ACTION_WEB
                    )
                );
            }
            // else {
            //     dispatch(
            //         updateAlertAction(true, "Added worksheet successfully")
            //     );
            // }
            setLoading(false);
            setClosePopup(true);
        }
    };

    return (
        <>
            <div className="btn-action-collection footer-resource">
                <div
                    className="btn create footer-popup"
                    onClick={() => {
                        if (actionGridItem == Config.ACTION_GRID.DEFAULT) {
                            setShowPopupCreateCollection(true);
                            setShowPopup(false);
                        } else {
                            dispatch(
                                previousActionResourceAction({
                                    previousAction: actionGridItem,
                                })
                            );
                            dispatch(
                                selectActionGridItemAction({
                                    action: Config.ACTION_GRID
                                        .CREATE_COLLECTION,
                                })
                            );
                        }
                    }}
                >
                    <CreateIconCollection />
                    New Collection
                </div>
                {collections.length || collection?.id ? (
                    <div
                        className={
                            ClassNameDontDelete.TRACKING_DONT_DEL +
                            " btn save " +
                            (isDisable ? " disable " : "") +
                            (saved
                                ? "saved " +
                                  ClassNameDontDelete.PREVIEW_PAGE_BUTTON_UNSAVE
                                : ClassNameDontDelete.SAVE_TO_COLLECTION)
                        }
                        onClick={() => {
                            if (isDisable) {
                                return;
                            }
                            if (userInfo?.email) {
                                const find = collection?.worksheetItems.find(
                                    (i) => i.worksheetId == worksheet?.id
                                );
                                if (collection?.id) {
                                    if (
                                        titlePopup ==
                                        ConstantsResource.SAVE_TO_COLLECTION
                                    ) {
                                        addToCollection();
                                    } else if (
                                        titlePopup ==
                                        ConstantsResource.MOVE_WORKSHEET
                                    ) {
                                        moveWorksheet(find?.id ? true : false);
                                    }
                                }
                                // try {
                                //     ga.gtag_save_to_collection();
                                // } catch (error) {}
                                // else {
                                //     setShowPopup(false);
                                // }
                            } else {
                                setShowPopupSurvey(true);
                            }
                        }}
                    >
                        {loading && (
                            <div
                                style={{
                                    paddingRight: "8px",
                                }}
                            >
                                <CircularProgress size={14} color={"inherit"} />
                            </div>
                        )}
                        {loading ? "Saving" : "Save"}
                    </div>
                ) : null}
            </div>
            {showPopupSurvey && (
                <PopUpStepAction
                    setShowFormPopup={(value: any) => setShowPopupSurvey(value)}
                    isSaveToCollection={true}
                    descriptionThanks={
                        "You’re now ready to save as many worksheets as you wish!"
                    }
                    descriptionLogin={
                        "Log in to seamlessly save and explore all of the resources and features"
                    }
                    saveToCollection={() => addToCollection()}
                />
            )}
        </>
    );
};

export default FooterPopup;
