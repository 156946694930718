import { Button } from "@mui/material";
import { useState } from "react";
import PopupWarning from "../popup-warning";
import "./index.scss";
import ManualInput from "./manual-input";
import PasteInput from "./paste-input";
import { sendEvent } from "../../../../../resource-user/utils/event";

const TABS = [
    { text: "Manual", id: 0 },
    { text: "Copy From Excel", id: 1 },
];

const GroupInput = () => {
    const [tab, setTab] = useState(TABS[0].id);

    return (
        <div className="group-input-crossword">
            <TabTitle
                tab={tab}
                handleChangeTab={(id) => {
                    setTab(id);
                    sendEvent(
                        id === TABS[0].id ? "crw_manual" : "crw_copy_from_excel"
                    );
                }}
            />
            <div className="input-container">
                {tab === 0 ? <ManualInput /> : <PasteInput />}
            </div>
            <PopupWarning />
        </div>
    );
};

const TabTitle = ({
    tab,
    handleChangeTab,
}: {
    tab: number;
    handleChangeTab: (id: number) => void;
}) => {
    return (
        <div className="tab-group-crossword">
            {TABS.map(({ text, id }) => (
                <Button
                    key={id}
                    className={`tab ${id === tab ? "active" : "deactive"}`}
                    fullWidth
                    onClick={() => handleChangeTab(id)}
                    disableRipple
                >
                    {text}
                </Button>
            ))}
        </div>
    );
};

export default GroupInput;
