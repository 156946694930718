import { useEffect } from 'react';
import axios from 'axios';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';
import { fileToBase64 } from '../../../../../shared/utils';
import useImageLoader from './use-image-loaded';

const useImageData = (imageUrl: string, imageWidth?: number, imageHeight?: number, onImageLoad = () => {}) => {
    const imgRef = useImageLoader(imageUrl, onImageLoad);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await axios.get(`${ConstantsTool.API_URL}auto-content/fetch-image-data?url=${imageUrl}`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN),
                    }
                });
                const blobData = response.data as Blob;

                const imgNode = imgRef.current;

                if (imgNode) {
                    imgNode.src = `${await fileToBase64(blobData)}`;
                    imgNode.width = imageWidth;
                    imgNode.height = imageHeight;
                }
            } catch (error) {
                onImageLoad();
                console.error('Error fetching imageData data:', error, imageUrl);
            }
        };

        fetchImageData();
    }, [imageUrl]);

    return imgRef;
};

export default useImageData;
