import { immerable } from "immer";
import ConstantsMath from "../utils/ConstantsResourceMath";
import ConstantsTool from "../utils/ConstantsTool";
import Config from "../utils/config";
import { IQuestionActivity } from "./questionActivity";
import ConstantsWordScramble from "../utils/ConstantsWordScramble";
export interface IWorkSheetCreator {
    id: string;
    worksheetId: string;
    creator: string;
    studentInfos: string[];
    title: string;
    description: string;
    templateIds: string[];
    activities: Activity[];
}
export type SizeActivity = "Small" | "Medium" | "Large";
export class WorkSheetCreator implements IWorkSheetCreator {
    id: string;
    worksheetId: string;
    creator: string;
    studentInfos: string[];
    title: string;
    description: string;
    templateIds: string[];
    activities: Activity[];

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.id = obj.id;
        this.worksheetId = obj.worksheetId;
        this.creator = obj.creator;
        this.studentInfos = obj.studentInfos;
        this.title = obj.title;
        this.activities = obj.activities ?? [];
        this.description = obj.description ?? "";
        this.templateIds = obj.templateIds ?? [];
    }
}
export enum ListStyle {
    NONE = 1,
    INDEX = 2,
    LIST_MARKER = 3,
    BULLET_POINT = 4,
}

export interface IRange {
    min: number;
    max: number;
}

export interface IActivity {
    id: string;
    type: string;
    showWordDirection: number;
    shape: string;
    size: SizeActivity;
    showWordList: number;
    showAnswerKey: number;
    strokeType: string;
    showWordBank: number;
    questions: IQuestionActivity[];
    listStyle?: number;
    heightGap?: number;
    orientation?: number;
    layout?: string;
    grid?: string;
    mainStyle?: number;
    direction?: number;
    strokeStyle?: number;
    hiddenStyle?: number;
    range1?: IRange;
    range2?: IRange;
    regrouping?: number;
    letterStyle?: number;
    separatorStyle?: number;
}

export class Activity implements IActivity {
    id: string;
    type: string;
    showWordDirection: number;
    shape: string;
    size: SizeActivity;
    showWordList: number;
    showAnswerKey: number;
    strokeType: string;
    showWordBank: number;
    questions: IQuestionActivity[];
    listStyle?: number;
    heightGap?: number;
    orientation?: number;
    layout?: string;
    grid?: string;
    mainStyle?: number;
    direction?: number;
    strokeStyle?: number;
    hiddenStyle?: number;
    range1?: IRange;
    range2?: IRange;
    regrouping?: number;
    letterStyle?: number;
    separatorStyle?: number;

    constructor(obj: any = {}) {
        this[immerable] = true;
        this.id = obj.id ?? "";
        this.type = obj.type ?? "";
        this.showWordDirection = obj.showWordDirection ?? Config.SHOW_VALUE;
        this.shape = obj.shape ?? Config.DEFAULT_SHAPE_NAME;
        this.size = obj.size ?? "Small";
        this.showWordList = obj.showWordList ?? Config.SHOW_VALUE;
        this.showAnswerKey = obj.showAnswerKey ?? Config.HIDE_VALUE;
        this.strokeType = obj.strokeType ?? "solid";
        this.showWordBank = obj.showWordBank ?? Config.HIDE_VALUE;
        this.questions = obj.questions ?? [];

        if (obj.type === Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE) {
            this.listStyle = obj.listStyle ?? ListStyle.INDEX;
            this.heightGap =
                obj.heightGap ?? ConstantsTool.GAP_ELEMENT_FILL_THE_BLANK;
        }
        if (obj.type === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE) {
            this.orientation =
                obj.orientation ?? ConstantsTool.ORIENTATION.PORTRAIT;
        }
        if (obj.type === Config.ACTIVITY_TYPE.CROSSWORD.TYPE) {
            this.layout = obj.layout ?? ConstantsTool.LAYOUT.DEFAULT;
            this.grid = obj.grid ?? "";
        }
        if (
            obj.type === Config.ACTIVITY_TYPE.ADDITION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.SUBTRACTION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.DIVISION.TYPE
        ) {
            this.mainStyle = obj.mainStyle ?? ConstantsMath.MAIN_STYLE.DEFAULT;
            if (this.mainStyle === ConstantsMath.MAIN_STYLE.DEFAULT) {
                this.direction =
                    obj.direction ?? ConstantsMath.DIRECTION.HORIZONTAL;
                this.strokeStyle =
                    obj.strokeStyle ?? ConstantsMath.STROKE_STYLE.SHAPE;
            } else {
                this.direction = obj.direction ?? ConstantsMath.DIRECTION.LEFT;
                this.strokeStyle =
                    obj.strokeStyle ?? ConstantsMath.STROKE_STYLE.CIRCLE;
            }
            this.hiddenStyle =
                obj.hiddenStyle ?? ConstantsMath.HIDDEN_STYLE.DEFAULT;
            this.range1 = obj.range1 ?? { min: 1, max: 9 };
            this.range2 = obj.range2 ?? { min: 1, max: 9 };
            this.regrouping = obj.regrouping ?? Config.HIDE_VALUE;
            this.listStyle = obj.listStyle ?? ConstantsMath.LIST_STYLE.NONE;
        }

        if (
            obj.type === Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE ||
            obj.type === Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE
        ) {
            this.listStyle =
                obj.listStyle ?? ConstantsWordScramble.LIST_STYLE.NONE;
            this.strokeStyle =
                obj.strokeStyle ?? ConstantsWordScramble.STROKE_STYLE.SOLID;
            this.letterStyle =
                obj.letterStyle ?? ConstantsWordScramble.LETTER_STYLE.NONE;

            if (obj.type === Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE) {
                this.separatorStyle =
                    obj.separatorStyle ??
                    ConstantsWordScramble.SEPARATOR_STYLE.SLASH;
            }
        }
    }
}
