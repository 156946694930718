const CheckedBox = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2" y="2" width="16" height="16" rx="4" fill="#3B82F6" />
            <path
                d="M14.4735 6.80667C14.4115 6.74418 14.3378 6.69458 14.2565 6.66074C14.1753 6.62689 14.0881 6.60947 14.0001 6.60947C13.9121 6.60947 13.825 6.62689 13.7437 6.66074C13.6625 6.69458 13.5888 6.74418 13.5268 6.80667L8.56013 11.78L6.47346 9.68667C6.40911 9.62451 6.33315 9.57563 6.24992 9.54283C6.16668 9.51003 6.0778 9.49394 5.98834 9.49549C5.89889 9.49703 5.81062 9.51619 5.72857 9.55185C5.64651 9.58751 5.57229 9.63898 5.51013 9.70333C5.44797 9.76768 5.39909 9.84364 5.36629 9.92688C5.33349 10.0101 5.3174 10.099 5.31895 10.1885C5.3205 10.2779 5.33965 10.3662 5.37531 10.4482C5.41097 10.5303 5.46245 10.6045 5.5268 10.6667L8.0868 13.2267C8.14877 13.2892 8.22251 13.3387 8.30375 13.3726C8.38498 13.4064 8.47212 13.4239 8.56013 13.4239C8.64814 13.4239 8.73527 13.4064 8.81651 13.3726C8.89775 13.3387 8.97149 13.2892 9.03346 13.2267L14.4735 7.78667C14.5411 7.72424 14.5951 7.64847 14.6321 7.56414C14.669 7.4798 14.6881 7.38873 14.6881 7.29667C14.6881 7.2046 14.669 7.11353 14.6321 7.02919C14.5951 6.94486 14.5411 6.86909 14.4735 6.80667Z"
                fill="white"
            />
        </svg>
    );
};

export default CheckedBox;
