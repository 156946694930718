import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../../redux/hook";
import { IResourceItemNew } from "../../../../../../shared/models/resourceItemNew";
import "./index.scss";
import Config from "../../../../../../shared/utils/config";
import {
    IAnswerActivity,
    IQuestionActivity,
} from "../../../../../../shared/models/questionActivity";
import { IActivity } from "../../../../../../shared/models/WorkSheetCreator";
import { addRemoveClassListHide } from "../../../../../../resource-user/utils";

const TextMultipleChoice = ({ item }: { item: IResourceItemNew }) => {
    const questions: IQuestionActivity[] = useAppSelector(
        (state) =>
            state.createWorksheetState.activities.find(
                (activity) =>
                    activity.type === Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE
            ).questions
    );
    let isSave = useAppSelector((state) => state.wordSearchState.isSave);
    let allCorrectAnswerwId: Array<string> = [];
    questions.map((question: IQuestionActivity) => {
        const correctAnswers: IAnswerActivity[] = question.answers.filter(
            (answer) => answer.isCorrect === true
        );
        const correctAnswersId: Array<string> = correctAnswers.map(
            (answer: IAnswerActivity) => answer.id
        );
        allCorrectAnswerwId.push(...correctAnswersId);
    });
    const textRef = useRef(null);
    const showAnswerKey = useAppSelector(
        (state) =>
            state.createWorksheetState.activities.find(
                (activity: IActivity) =>
                    activity.type === Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE
            ).showAnswerKey
    );
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        setReload(!reload);
        addRemoveClassListHide(
            showAnswerKey == Config.SHOW_VALUE ? true : false,
            "show-keys"
        );
    }, [questions, isSave]);

    return (
        <>
            <div
                ref={textRef}
                className="item-container"
                style={{
                    fontSize: 24,
                    fontFamily: item.textAttribute?.fontFamily,
                    textAlign: item.textAttribute?.align ?? "justify",
                    color: "#212121",
                }}
            >
                <div
                    className={`${
                        allCorrectAnswerwId.includes(item.id) ? "answer" : ""
                    } ${
                        allCorrectAnswerwId.includes(item.id) &&
                        showAnswerKey == Config.SHOW_VALUE
                            ? "show-keys"
                            : ""
                    }`}
                ></div>
                {item.textAttribute?.content}
            </div>
        </>
    );
};

export default TextMultipleChoice;
