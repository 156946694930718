import { ISnackbarAction, SnackbarTypes } from "../action/snackbar.action";
import { Collection, ICollection } from "../../../shared/models/collection";
import { Class, IClass } from "../../../shared/models/class";
import Config from "../../../shared/utils/config";

export interface SnackbarState {
    message: string;
    severity: string;
    collectionId: string;
    collection: ICollection;
    classAssignment: IClass;
    alertDownloadWs: number;
    styleSnackbar: any;
    anchorOrigin: any;
}

const initialState: SnackbarState = {
    message: "",
    severity: "error",
    collectionId: "",
    collection: new Collection(),
    classAssignment: new Class(),
    alertDownloadWs: Config.STATUS_ALERT_DOWNLOAD.INIT,
    styleSnackbar: Config.STYLE_SNACK_BAR.INIT,
    anchorOrigin: Config.ANCHOR_ORIGIN_SNACK_BAR.INIT,
};

function snackbarState(
    state: SnackbarState = initialState,
    action: ISnackbarAction
): SnackbarState {
    switch (action.type) {
        case SnackbarTypes.SET_MESSAGE_RESOURCE:
            return {
                ...state,
                message: action.message ?? "",
                severity: action.severity ?? "error",
                anchorOrigin:
                    action.message.length > 0
                        ? Config.ANCHOR_ORIGIN_SNACK_BAR.CENTER
                        : Config.ANCHOR_ORIGIN_SNACK_BAR.INIT,
            };
        case SnackbarTypes.REMOVE_MESSAGE_RESOURCE:
            return {
                ...state,
                message: "",
                collectionId: "",
                collection: new Collection(),
                classAssignment: new Class(),
                severity: "",
                anchorOrigin: Config.ANCHOR_ORIGIN_SNACK_BAR.INIT,
            };
        case SnackbarTypes.SET_COLLECTIONid_RESOURCE: {
            return {
                ...state,
                collectionId: action.collectionId,
                collection: action?.collection ?? state.collection,
                classAssignment:
                    action?.classAssignment ?? state.classAssignment,
            };
        }
        case SnackbarTypes.UPDATE_ALERT_SNACK_BAR_RESOURCE: {
            return {
                ...state,
                collectionId: action.collectionId ?? state.collectionId,
                collection: action?.collection ?? state.collection,
                classAssignment:
                    action?.classAssignment ?? state.classAssignment,
                message: action.message ?? "",
                severity: action.severity ?? "error",
                anchorOrigin: Config.ANCHOR_ORIGIN_SNACK_BAR.INIT,
            };
        }
        // case SnackbarTypes.SHOW_WARNING_DOWNLOAD:
        //     return { ...state, showWarningDownload: true };
        // case SnackbarTypes.SHOW_SAFE_DOWNLOAD:
        //     return { ...state, showSafeDownload: true };
        // case SnackbarTypes.CLOSE_ALERT_DOWNLOAD:
        //     return {
        //         ...state,
        //         showSafeDownload: false,
        //         showWarningDownload: false,
        //     };
        case SnackbarTypes.SET_ALERT_DOWNLOAD_WS:
            return {
                ...state,
                alertDownloadWs: action.alertDownloadWs,
            };
        default:
            return state;
    }
}

export default snackbarState;
