import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { formatZoneName } from '../../../../../../../utils';
import { setCurrentElementFocus, updateCurrentToResourceItem } from '../../../../../../../redux/reducers/createWorksheet';
import ConstantsTool from '../../../../../../../shared/utils/ConstantsTool';
import { IResourceItemNew } from '../../../../../../../shared/models/resourceItemNew';
import { useAppSelector } from '../../../../../../../redux/hook';

const GroupModal = ({currentElement, groupItem, hideModal, setCurrentElement} : {
    currentElement: IResourceItemNew,
    groupItem: IResourceItemNew,
    hideModal: any,
    setCurrentElement: any,
}) => {
    const dispatch = useDispatch();

    const {pageType} = useAppSelector(state => state.createWorksheetState);

    const [newFieldName, setNewFieldName] = useState(groupItem.fieldName ?? '');

    useEffect(() => {
        dispatch(setCurrentElementFocus(false));
    }, []);

    const saveFieldName = () => {
        let fieldName = null;
        if (newFieldName && newFieldName.length > 0) {
            fieldName = newFieldName.trim();
        }

        if (fieldName) {
            const formatedFieldName = formatZoneName(fieldName);
            const newCurrentElement = { ...currentElement, 'fieldName': formatedFieldName };
            setCurrentElement(newCurrentElement);
            dispatch(
                updateCurrentToResourceItem({
                    currentElement: newCurrentElement,
                    pageIndex: currentElement.pageIndex,
                    isAddHistory: true,
                })
            );
            hideModal();
        }
    }

    return (
        <Modal show={true} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && (
                        <Row>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control value={newFieldName} onChange={e => setNewFieldName(e.target.value)} />
                            </Form.Group>
                        </Row>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={saveFieldName}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default GroupModal;