import { Slider, Stack } from "@mui/material";
import { updateAnswersMC } from "../../../../../../redux/async/multipleChoiceThunk";
import { setMessage } from "../../../../../../redux/reducers/snackbar";
import { IActivity } from "../../../../../../shared/models/WorkSheetCreator";
import { IQuestionActivity } from "../../../../../../shared/models/questionActivity";
import { useAppDispatch } from "../../../../../../redux/hook";

const NumberCol = ({
    activity,
    question,
    questionIndex,
    setUserChangeCol,
}: {
    activity: IActivity;
    question: IQuestionActivity;
    questionIndex: number;
    setUserChangeCol?: Function;
}) => {
    const dispatch = useAppDispatch();
    const questionNumberCol: number =
        question.multipleChoicesAttribute.numberCol;

    const handleChangeNumberCol = (value: number) => {
        const nextQuestion: IQuestionActivity = activity.questions.find(
            (ele, index) => index == questionIndex + 1
        );
        setUserChangeCol(true);
        dispatch(
            updateAnswersMC({
                question: question,
                nextQuestion: nextQuestion,
                numberCol: value,
            })
        );
    };
    return (
        <div className="number-col-container">
            <div className="number-col-title">
                <p className="number-of-col">Number of Columns</p>
                <p>{questionNumberCol}</p>
            </div>
            <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1, width: "calc(100% - 8px)" }}
                alignItems="center"
            >
                <Slider
                    className="number-col-slider"
                    size="medium"
                    aria-label="Temperature"
                    defaultValue={4}
                    step={1}
                    marks
                    min={1}
                    max={4}
                    value={questionNumberCol}
                    onChange={(e, value: number) => {
                        if (value > 4) {
                            e.preventDefault();
                            dispatch(
                                setMessage({
                                    severity: "error",
                                    message:
                                        "Number of columns can be more than number of options",
                                })
                            );
                        } else {
                            if (value != questionNumberCol) {
                                handleChangeNumberCol(value);
                            }
                        }
                    }}
                    sx={{
                        width: "calc(100%-12px)",
                    }}
                />
            </Stack>
        </div>
    );
};

export default NumberCol;
