import React from 'react';
import Form from 'react-bootstrap/Form';
import './styles.scss';

const SearchFontFamilyBar = ({
    searchKeyword,
    handleKeywordChange,
}: {
    searchKeyword: string,
    handleKeywordChange: (value: string) => void,
}) => {
    return (
        <div className="search-font-family-bar">
            <Form.Control
                placeholder="Search font family"
                value={searchKeyword}
                onChange={(e: any) => handleKeywordChange(e.target.value)}
                type='text'
            />
        </div>
    );
};

export default SearchFontFamilyBar;