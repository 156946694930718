import React from "react";

const SlashIconResources = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.5 10.5L7.5 1.5Z" fill="#9CA3AF" />
            <path
                d="M4.5 10.5L7.5 1.5"
                stroke="#9CA3AF"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default SlashIconResources;
