import { IconButton, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hook";
import {
    changeClueCrosswordAction,
    changeErrorWordsCrosswordAction,
    changeSingleWordAction,
    changeWordsAction,
} from "../../../../../../resource-user/redux/action/crossword.action";
import { IWord } from "../../../../../../shared/models/crossword";
import {
    regexCheckError,
    scrollToItemId,
    setCursorToEnd,
} from "../../../utils";
import "./index.scss";
import { sendEvent } from "../../../../../../resource-user/utils/event";
import { IconSVG, svgPath } from "../../../../../../assets/icon/icons";

const ARROW_KEY_UP_DOWN = ["ArrowDown", "ArrowUp"];
const PREFIXid = "crossword-input-";

const ManualInput = () => {
    const dispatch = useAppDispatch();
    const words: IWord[] = useAppSelector(
        (state) => state.crosswordState.words
    );
    const currentWordSelected = useAppSelector(
        (state) => state.crosswordState.currentWordSelected
    );

    const handleAdd = (e, currentIndex?: number) => {
        if (currentIndex >= words.length - 1) {
            const newWords = [
                ...words,
                {
                    value: "",
                    clue: "",
                },
            ];
            dispatch(changeWordsAction(newWords));
        }
        setTimeout(() => {
            const isLast = currentIndex === words.length;
            const scrollId = `${PREFIXid}${currentIndex + (isLast ? 0 : 1)}`;

            const targetId = e?.target?.id;
            const selectorFocus = `#${scrollId} #${
                targetId === `${PREFIXid}clue` ? targetId : `${PREFIXid}value`
            }`;

            scrollToItemId(scrollId, selectorFocus);
        }, 100);
    };

    const handleDeleteWord = (indexWord: number) => {
        dispatch(
            changeWordsAction(words.filter((_, index) => index !== indexWord))
        );
    };

    const moveElementByArrow = (e, currentIndex?: number) => {
        const key = e.key;
        const value = e.target.value;
        const currentSelection = e.target.selectionStart;

        const targetId = e?.target?.id;
        const focusId =
            targetId === `${PREFIXid}clue`
                ? `${PREFIXid}value`
                : `${PREFIXid}clue`;
        let index = parseInt(currentIndex as any);
        let shouldTrigger = false;

        if (key === "Backspace" && value.length === 0) {
            if (targetId === `${PREFIXid}value`) {
                index = currentIndex - 1;
                handleDeleteWord(currentIndex);
            }
            shouldTrigger = true;
        }

        if (["ArrowUp"].includes(key) && currentSelection === 0) {
            if (targetId === `${PREFIXid}value`) {
                index = currentIndex - 1;
            }

            shouldTrigger = true;
        } else if (key === "ArrowDown" && currentSelection === value.length) {
            if (targetId === `${PREFIXid}clue`) {
                index = currentIndex + 1;
            }
            shouldTrigger = true;
        }

        if (shouldTrigger) {
            e.preventDefault();
            const scrollId = `${PREFIXid}${index}`;
            const selectorFocus = `#${scrollId} #${focusId}`;
            scrollToItemId(scrollId, selectorFocus);
        }
    };

    return (
        <>
            <div className="manual-input-container" id="manual-input-container">
                <SimpleBar
                    className="bar-questions"
                    style={{
                        maxHeight: "100%",
                        minHeight: "100%",
                    }}
                >
                    {words.map((word, index) => (
                        <Input
                            word={word}
                            key={index}
                            index={index}
                            handleDeleteWord={() => handleDeleteWord(index)}
                            handleAdd={(e) => handleAdd(e, index)}
                            moveElementByArrow={(e) =>
                                moveElementByArrow(e, index)
                            }
                            isClickFromRight={
                                currentWordSelected?.index === index
                            }
                        />
                    ))}
                    <Input
                        addInput={true}
                        handleAdd={(e) => handleAdd(e, words.length)}
                        key={words.length}
                    />
                </SimpleBar>
            </div>
            <ListenSelect />
        </>
    );
};

const Input = ({
    word,
    addInput,
    handleAdd,
    handleDeleteWord,
    moveElementByArrow,
    index,
    isClickFromRight,
}: {
    word?: IWord;
    addInput?: boolean;
    handleAdd?: Function;
    handleDeleteWord?: Function;
    moveElementByArrow?: Function;
    index?: number;
    isClickFromRight?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const valueRef: any = useRef();
    const clueRef: any = useRef();
    const [hoverIconClear, setHoverIconClear] = useState(false);

    const handleChangeWord = (value: string) => {
        const { error, valid, invalid } = regexCheckError(value);
        let newValue = value;

        if (error) {
            newValue = valid;
            dispatch(changeErrorWordsCrosswordAction([{ valid, invalid }]));
        }

        dispatch(changeSingleWordAction(newValue, index));
    };
    // const handleForcus = () => {};
    return (
        <div
            id={PREFIXid + index}
            className={
                "input-word-container " +
                (addInput ? " add-input " : "") +
                (isClickFromRight ? " click-from-right " : "")
            }
            onClick={(e) => {
                if (addInput) {
                    handleAdd && handleAdd(e);
                }
            }}
        >
            <div className="input-word-inner-container">
                <IconButton
                    onMouseDown={(e) => {
                        e.preventDefault();
                        handleDeleteWord && handleDeleteWord();
                    }}
                    className="icon-delete"
                    size="small"
                    onMouseEnter={() => setHoverIconClear(true)}
                    onMouseLeave={() => setHoverIconClear(false)}
                >
                    <img
                        src={
                            hoverIconClear
                                ? svgPath(IconSVG.delete_fitb)
                                : svgPath(IconSVG.delete_fitb_black)
                        }
                        alt=""
                    />
                </IconButton>
                <TextField
                    id={PREFIXid + "value"}
                    inputRef={valueRef}
                    value={word?.value ?? ""}
                    onChange={(e) => handleChangeWord(e.target.value)}
                    multiline
                    fullWidth
                    className="input"
                    placeholder="Answer"
                    onKeyDown={(e) => {
                        const key = e.key;
                        if (key === "Enter") {
                            e.preventDefault();
                            clueRef.current.focus();
                            setCursorToEnd(clueRef.current);
                        }
                        if ([...ARROW_KEY_UP_DOWN, "Backspace"].includes(key)) {
                            moveElementByArrow(e);
                        }
                    }}
                    InputProps={{
                        readOnly: addInput,
                    }}
                    onBlur={(e) => {
                        sendEvent("crw_type_answer", {
                            value: e.target.value.trim(),
                        });
                    }}
                />
                <div className="clue-input">
                    <EnterIcon />
                    <TextField
                        id={PREFIXid + "clue"}
                        inputRef={clueRef}
                        value={word?.clue ?? ""}
                        onChange={(e) =>
                            dispatch(
                                changeClueCrosswordAction(e.target.value, index)
                            )
                        }
                        multiline
                        fullWidth
                        className="input clue"
                        placeholder="Clue"
                        InputProps={{
                            readOnly: addInput,
                        }}
                        onKeyDown={(e) => {
                            const key = e.key;
                            if (key === "Enter") {
                                e.preventDefault();
                                handleAdd && handleAdd();
                            }
                            if (
                                [...ARROW_KEY_UP_DOWN, "Backspace"].includes(
                                    key
                                )
                            ) {
                                moveElementByArrow(e);
                            }
                        }}
                        onBlur={(e) => {
                            sendEvent("crw_type_clues", {
                                value: e.target.value.trim(),
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const ListenSelect = () => {
    const currentWordSelected = useAppSelector(
        (state) => state.crosswordState.currentWordSelected
    );
    useEffect(() => {
        if (currentWordSelected) {
            scrollToItemId(PREFIXid + currentWordSelected?.index);
        }
    }, [currentWordSelected]);
    return null;
};

const EnterIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 14 14" fill="none">
            <path
                d="M11.3358 8.83083L8.66417 11.5025C8.43667 11.73 8.06333 11.73 7.83583 11.5025C7.60833 11.275 7.60833 10.9017 7.83583 10.6742L9.51583 9H3.58333C3.2625 9 3 8.7375 3 8.41667V2.58333C3 2.2625 3.2625 2 3.58333 2C3.90417 2 4.16667 2.2625 4.16667 2.58333V7.83333H9.51583L7.83583 6.15917C7.60833 5.93167 7.60833 5.55833 7.83583 5.33083C8.06333 5.10333 8.43667 5.10333 8.66417 5.33083L11.3358 8.0025C11.5633 8.23 11.5633 8.60333 11.3358 8.83083Z"
                fill="#475569"
                fillOpacity="0.52"
            />
        </svg>
    );
};

export default ManualInput;
