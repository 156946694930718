import { useEffect, useRef, useState } from "react";
import "./index.scss";
const SliderTemplate = ({ listImage, thumbnail }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [enableSlider, setEnableSlider] = useState(false);
    const sliderRef = useRef(null);
    const intervalRef = useRef(null);

    const totalImages = listImage.length;

    useEffect(() => {
        if (totalImages > 1) {
            const slider = sliderRef.current;
            slider.style.transform = `translateX(-${
                currentImageIndex * sliderRef.current.offsetWidth
            }px)`;
        }
    }, [currentImageIndex]);

    useEffect(() => {
        if (enableSlider) {
            intervalRef.current = setInterval(() => {
                setCurrentImageIndex(
                    (prevIndex) => (prevIndex + 1) % totalImages
                );
            }, 1200);
        } else clearInterval(intervalRef.current);
        return () => clearInterval(intervalRef.current);
    }, [enableSlider]);

    return (
        <div>
            <div className="template-ws">
                {totalImages > 1 ? (
                    <div>
                        <div className="number-of-image">
                            {`${currentImageIndex + 1} Of ${totalImages}`}
                        </div>
                        <div
                            className={"slider-ws "}
                            ref={sliderRef}
                            onMouseEnter={() => {
                                if (totalImages > 1) {
                                    setEnableSlider(true);
                                }
                            }}
                            onMouseLeave={() => {
                                if (totalImages > 1) {
                                    setEnableSlider(false);
                                    setCurrentImageIndex(0);
                                }
                            }}
                        >
                            {listImage.map((el, index) => (
                                <img key={index} src={el}></img>
                            ))}
                        </div>
                    </div>
                ) : (
                    <img src={thumbnail}></img>
                )}
            </div>
        </div>
    );
};
export default SliderTemplate;
