const CircleUnChecked = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
    >
        <path
            d="M1.6665 7C1.6665 4.05614 4.05598 1.66666 6.99984 1.66666C9.9437 1.66666 12.3332 4.05614 12.3332 7C12.3332 9.94386 9.9437 12.3333 6.99984 12.3333C4.05598 12.3333 1.6665 9.94386 1.6665 7Z"
            stroke="#212121"
            strokeOpacity="0.32"
        />
    </svg>
);
export default CircleUnChecked;
