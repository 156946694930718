import {
    delay,
    floorNumber,
    generateIdFromDateTime,
    getConfigByActivity,
    getGapBetweenItem,
    resourceCanChangeHeight
} from ".";
import { getBoundingTextBox, getSizeDivText } from "../resource-user/utils";
import { ListStyle } from "../shared/models/WorkSheetCreator";
import { DirectionProps } from "../shared/models/directionProps";
import {
    IPageWorksheetNew,
    PageWorksheetNew,
} from "../shared/models/pageWorksheetNew";
import { IQuestionActivity } from "../shared/models/questionActivity";
import {
    IResourceItemNew,
    ITextAttribute,
    ResourceItemNew,
} from "../shared/models/resourceItemNew";
import { PositionInput } from "../shared/models/shapeAttribute";
import { IWorksheet } from "../shared/models/worksheet";
import { getTitleWsFromProjectName } from "../shared/utils";
import ConstantsTool from "../shared/utils/ConstantsTool";
import Config from "../shared/utils/config";
import { getSizeResourceItem } from "./align";
import CreateWSConfig from "./create-ws-config";

export interface IPoint {
    x: number;
    y: number;
}
const calculateNewPosition = (
    rotatePosition: any,
    pivot: any,
    rotateValue: any,
    bonus?: any
) => {
    let newPosition = calculatePosition(
        rotatePosition,
        pivot,
        toRadians(-1 * rotateValue)
    );

    if (bonus) {
        newPosition.x += bonus.x;
        newPosition.y += bonus.y;
    }
    return { x: parseFloat(newPosition.x), y: parseFloat(newPosition.y) };
};
export const getFourPointAndRotateValue = (
    contentRef,
    startPosition: IPoint,
    originWidth: number,
    originHeight: number
) => {
    let rotateStringValue =
        contentRef.style.transform.match(/rotate\((.*)\)/)[1];

    let rotateValue = parseFloat(
        rotateStringValue.substring(0, rotateStringValue.length - 3)
    );

    let fourPointsData = getFourPointsPositionAfterRotate(
        startPosition,
        {
            width: originWidth,
            height: originHeight,
        },
        rotateValue
    );
    return {
        topLeft: fourPointsData.topLeft,
        topRight: fourPointsData.topRight,
        bottomLeft: fourPointsData.bottomLeft,
        bottomRight: fourPointsData.bottomRight,
        rotateValue: rotateValue,
    };
};
export const getFourPointsPositionAfterRotate = (
    position,
    size,
    degree: number
) => {
    let pivot = {
        x: position.x + size.width / 2,
        y: position.y + size.height / 2,
    };
    return {
        topLeft: calculatePosition(
            { x: position.x, y: position.y },
            pivot,
            toRadians(degree)
        ),
        topRight: calculatePosition(
            { x: position.x + size.width, y: position.y },
            pivot,
            toRadians(degree)
        ),
        bottomLeft: calculatePosition(
            { x: position.x, y: position.y + size.height },
            pivot,
            toRadians(degree)
        ),
        bottomRight: calculatePosition(
            { x: position.x + size.width, y: position.y + size.height },
            pivot,
            toRadians(degree)
        ),
    };
};
export const onTransformBottomRight = (currentPos: IPoint, fourPoints: any) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.bottomLeft,
        fourPoints.topLeft
    );
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.topRight,
        fourPoints.topLeft
    );
    let newTopLeft = fourPoints.topLeft;
    let newPivot = {
        x:
            newTopLeft.x > currentPos.x
                ? currentPos.x + (newTopLeft.x - currentPos.x) / 2
                : newTopLeft.x + (currentPos.x - newTopLeft.x) / 2,
        y:
            newTopLeft.y > currentPos.y
                ? currentPos.y + (newTopLeft.y - currentPos.y) / 2
                : newTopLeft.y + (currentPos.y - newTopLeft.y) / 2,
    };
    let newPosition = calculateNewPosition(
        newTopLeft,
        newPivot,
        fourPoints.rotateValue
    );

    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: parseFloat(fourPoints.rotateValue),
    };
};
export const onTransformTopLeft = (currentPos: IPoint, fourPoints: any) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.topRight,
        fourPoints.bottomRight
    );
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.bottomLeft,
        fourPoints.bottomRight
    );
    let newBottomRight = fourPoints.bottomRight;
    let newPivot = {
        x:
            newBottomRight.x > currentPos.x
                ? currentPos.x + (newBottomRight.x - currentPos.x) / 2
                : newBottomRight.x + (currentPos.x - newBottomRight.x) / 2,
        y:
            newBottomRight.y > currentPos.y
                ? currentPos.y + (newBottomRight.y - currentPos.y) / 2
                : newBottomRight.y + (currentPos.y - newBottomRight.y) / 2,
    };
    let newPosition = calculateNewPosition(
        currentPos,
        newPivot,
        fourPoints.rotateValue
    );
    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: fourPoints.rotateValue,
    };
};
export const onTransformBottomLeft = (currentPos: IPoint, fourPoints: any) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.bottomRight,
        fourPoints.topRight
    );
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.topLeft,
        fourPoints.topRight
    );

    let newTopRight = fourPoints.topRight;

    let newPivot = {
        x:
            newTopRight.x > currentPos.x
                ? currentPos.x + (newTopRight.x - currentPos.x) / 2
                : newTopRight.x + (currentPos.x - newTopRight.x) / 2,
        y:
            newTopRight.y > currentPos.y
                ? currentPos.y + (newTopRight.y - currentPos.y) / 2
                : newTopRight.y + (currentPos.y - newTopRight.y) / 2,
    };

    let newPosition = calculateNewPosition(
        newTopRight,
        newPivot,
        fourPoints.rotateValue,
        {
            x: -newWidth,
            y: 0,
        }
    );
    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: fourPoints.rotateValue,
    };
};
export const onTransformTopRight = (currentPos: IPoint, fourPoints: any) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.topLeft,
        fourPoints.bottomLeft
    );
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.bottomRight,
        fourPoints.bottomLeft
    );
    let newBottomLeft = fourPoints.bottomLeft;

    let newPivot = {
        x:
            newBottomLeft.x > currentPos.x
                ? currentPos.x + (newBottomLeft.x - currentPos.x) / 2
                : newBottomLeft.x + (currentPos.x - newBottomLeft.x) / 2,
        y:
            newBottomLeft.y > currentPos.y
                ? currentPos.y + (newBottomLeft.y - currentPos.y) / 2
                : newBottomLeft.y + (currentPos.y - newBottomLeft.y) / 2,
    };
    let newPosition = calculateNewPosition(
        newBottomLeft,
        newPivot,
        fourPoints.rotateValue,
        {
            x: 0,
            y: -newHeight,
        }
    );
    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: fourPoints.rotateValue,
    };
};
export const onTransformRight = (
    currentPos: IPoint,
    fourPoints: any,
    callbackSetCurrentElement: (
        newWidth: number,
        newPos: IPoint,
        rotateValue: number
    ) => {
        newWidth: number;
        newHeight: number;
        newPosition: { x: number; y: number };
        rotateValue: number;
    }
) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.topLeft,
        fourPoints.bottomLeft
    );

    let newTopLeft = fourPoints.topLeft;
    let newBottomLeft = fourPoints.bottomLeft;
    let projectPoint = pointOnLine(newTopLeft, newBottomLeft, currentPos);
    let centerOfSmallShape = getMidPointOfSegmentPosition(
        newTopLeft,
        currentPos
    );
    let currentTopRight = getPointBaseMidPointAndOtherPoint(
        centerOfSmallShape,
        projectPoint
    );

    let newPivot = getMidPointOfSegmentPosition(newBottomLeft, currentTopRight);

    let newPosition = calculateNewPosition(
        newTopLeft,
        newPivot,
        fourPoints.rotateValue
    );
    return callbackSetCurrentElement(
        newWidth,
        newPosition,
        fourPoints.rotateValue
    );
};
export const onTransformBottom = (
    currentPos: IPoint,
    fourPoints: any,
    newWidth: number
) => {
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.topLeft,
        fourPoints.topRight
    );
    let newTopLeft = fourPoints.topLeft;
    let newTopRight = fourPoints.topRight;
    let projectPoint = pointOnLine(newTopLeft, newTopRight, currentPos);

    let centerOfSmallShape = getMidPointOfSegmentPosition(
        newTopLeft,
        currentPos
    );
    let currentBottomLeft = getPointBaseMidPointAndOtherPoint(
        centerOfSmallShape,
        projectPoint
    );

    let newPivot = getMidPointOfSegmentPosition(newTopRight, currentBottomLeft);

    let newPosition = calculateNewPosition(
        newTopLeft,
        newPivot,
        fourPoints.rotateValue
    );
    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: fourPoints.rotateValue,
    };
};
export const onTransformTop = (
    currentPos: IPoint,
    fourPoints: any,
    newWidth: number
) => {
    let newHeight = calculateDistanceToLine(
        currentPos,
        fourPoints.bottomLeft,
        fourPoints.bottomRight
    );
    let newBottomLeft = fourPoints.bottomLeft;
    let newBottomRight = fourPoints.bottomRight;
    let projectPoint = pointOnLine(newBottomLeft, newBottomRight, currentPos);

    let centerOfSmallShape = getMidPointOfSegmentPosition(
        newBottomLeft,
        currentPos
    );
    let currentTopLeft = getPointBaseMidPointAndOtherPoint(
        centerOfSmallShape,
        projectPoint
    );

    let newPivot = getMidPointOfSegmentPosition(newBottomRight, currentTopLeft);

    let newPosition = calculateNewPosition(
        currentTopLeft,
        newPivot,
        fourPoints.rotateValue
    );
    return {
        newWidth,
        newHeight,
        newPosition,
        rotateValue: fourPoints.rotateValue,
    };
};
export const onTransformLeft = (
    currentPos: IPoint,
    fourPoints: any,
    callbackSetCurrentElement: (
        newWidth: number,
        newPos: IPoint,
        rotateValue: number
    ) => {
        newWidth: number;
        newHeight: number;
        newPosition: { x: number; y: number };
        rotateValue: number;
    }
) => {
    let newWidth = calculateDistanceToLine(
        currentPos,
        fourPoints.topRight,
        fourPoints.bottomRight
    );
    let newTopRight = fourPoints.topRight;
    let newBottomRight = fourPoints.bottomRight;
    let projectPoint = pointOnLine(newTopRight, newBottomRight, currentPos);

    let centerOfSmallShape = getMidPointOfSegmentPosition(
        newTopRight,
        currentPos
    );
    let currentTopLeft = getPointBaseMidPointAndOtherPoint(
        centerOfSmallShape,
        projectPoint
    );

    let newPivot = getMidPointOfSegmentPosition(newBottomRight, currentTopLeft);

    let newPosition = calculateNewPosition(
        currentTopLeft,
        newPivot,
        fourPoints.rotateValue
    );
    return callbackSetCurrentElement(
        newWidth,
        newPosition,
        fourPoints.rotateValue
    );
};
export const toRadians = (angleDegrees: number) => {
    return angleDegrees * (Math.PI / 180);
};
export function calculateDistance(startPoint: IPoint, endPoint: IPoint) {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance;
}

export function calculateDistanceToLine(currentPoint, startPoint, endPoint) {
    const a = endPoint.y - startPoint.y;
    const b = startPoint.x - endPoint.x;
    const c = a * startPoint.x + b * startPoint.y;
    const d = a * currentPoint.x + b * currentPoint.y - c;
    const distance = Math.abs(d) / Math.sqrt(a * a + b * b);
    return distance;
}

export const getMidPointOfSegmentPosition = (startPoint, endPoint) => {
    return {
        x:
            startPoint.x > endPoint.x
                ? endPoint.x + (startPoint.x - endPoint.x) / 2
                : startPoint.x + (endPoint.x - startPoint.x) / 2,
        y:
            startPoint.y > endPoint.y
                ? endPoint.y + (startPoint.y - endPoint.y) / 2
                : startPoint.y + (endPoint.y - startPoint.y) / 2,
    };
};

export const getPointBaseMidPointAndOtherPoint = (midpoint, otherPoint) => {
    return {
        x:
            midpoint.x > otherPoint.x
                ? midpoint.x + midpoint.x - otherPoint.x
                : midpoint.x - (otherPoint.x - midpoint.x),
        y:
            midpoint.y > otherPoint.y
                ? midpoint.y + midpoint.y - otherPoint.y
                : midpoint.y - (otherPoint.y - midpoint.y),
    };
};

export function rotateCursor(shape) {
    var angle = shape.style.transform.replace(/[^0-9]/gi, "") * (Math.PI / 180);
    var cursor = "nw-resize";
    if (angle < 90) {
        cursor = "nw-resize";
    } else if (angle < 180) {
        cursor = "ne-resize";
    } else if (angle < 270) {
        cursor = "se-resize";
    } else {
        cursor = "sw-resize";
    }
    shape.style.cursor = cursor;
}

export const pointOnLine = (p0, p1, q) => {
    if (p0.x == p1.x && p0.y == p1.y) p0.x -= 0.00001;

    const Unumer = (q.x - p0.x) * (p1.x - p0.x) + (q.y - p0.y) * (p1.y - p0.y);
    const Udenom = Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2);
    const U = Unumer / Udenom;

    const r = {
        x: p0.x + U * (p1.x - p0.x),
        y: p0.y + U * (p1.y - p0.y),
    };

    return r;
};
export function calculatePosition(point, pivot, rotation) {
    // Get the difference between the point and the pivot.
    const difference = {
        x: point.x - pivot.x,
        y: point.y - pivot.y,
    };

    // Rotate the difference by the specified rotation.
    const rotatedDifference = {
        x:
            difference.x * Math.cos(rotation) -
            difference.y * Math.sin(rotation),
        y:
            difference.x * Math.sin(rotation) +
            difference.y * Math.cos(rotation),
    };

    // Add the rotated difference to the pivot to get the new position of the point.
    const newPosition = {
        x: rotatedDifference.x + pivot.x,
        y: rotatedDifference.y + pivot.y,
    };

    return newPosition;
}
export const resourceBackgroundDefault = () => {
    return new ResourceItemNew({
        id: generateIdFromDateTime(),
        idType: "bg_1",
        width: ConstantsTool.WIDTH_A4,
        height: ConstantsTool.HEIGHT_A4,
        type: ConstantsTool.RESOURCE_BACKGROUND,
        x: 0,
        y: 0,
    });
};
export const resourceBorderDefault = () => {
    return new ResourceItemNew({
        id:
            "border-" +
            new Date().getTime().toString() +
            Math.floor(Math.random() * 9999),
        width: ConstantsTool.BORDER_WIDTH,
        height: ConstantsTool.BORDER_HEIGHT,
        type: ConstantsTool.TYPE_RESOURCE_BORDER,
        x: ConstantsTool.BORDER_X,
        y: ConstantsTool.BORDER_Y,
    });
};

const getTitleConfigByType = (type: string) => {
    let fontSizeTitle = 42;
    let startY = 100;
    let fontFamily = "Nunito";
    let title = getTitleWsFromProjectName(Config.PROJECT_NAME.TOOL_V2);
    if (type === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE) {
        fontSizeTitle = 62;
        startY = 80;
        fontFamily = "Quicksand Regular";
        title = "Hello, my name is";
    } else if (type === Config.ACTIVITY_TYPE.CROSSWORD.TYPE) {
        startY = 80;
    }

    return {
        fontSizeTitle,
        startY,
        fontFamily,
        title,
    };
};

export const getResourceItemsDefault = (activityId: string, type?: string) => {
    let { fontSizeTitle, startY, fontFamily, title } =
        getTitleConfigByType(type);
    return [
        // new ResourceItemNew({
        //     id: "bg_1",
        //     idType: "bg_1",
        //     width: ConstantsTool.WIDTH_A4,
        //     height: ConstantsTool.HEIGHT_A4,
        //     type: ConstantsTool.RESOURCE_BACKGROUND,
        //     x: 0,
        //     y: 0,
        // }),
        // new ResourceItemNew({
        //     id:
        //         "ws_title_" +
        //         new Date().getTime().toString() +
        //         Math.floor(Math.random() * 9999),
        //     idType:
        //         "ws_title_" +
        //         new Date().getTime().toString() +
        //         Math.floor(Math.random() * 9999),
        //     width: ConstantsTool.WIDTH_A4 - 80,
        //     height: fontSizeTitle + 16,
        //     type: ConstantsTool.RESOURCE_TEXT,
        //     textAttribute: {
        //         fontFamily: fontFamily,
        //         fontSize: fontSizeTitle,
        //         content: title,
        //         align: "center",
        //     },
        //     x: 40,
        //     y: startY,
        // }),
        // new ResourceItemNew({
        //     id: "ws_student_value_0" + generateIdFromDateTime(),
        //     idType: "ws_student_value_" + 0,
        //     width: ConstantsTool.STUDENT_INFO_1_WIDTH,
        //     height: 36,
        //     type: Config.RESOURCE_TEXT,
        //     textAttribute: {
        //         fontFamily: ConstantsTool.DEFAULT_FONT,
        //         fontSize: 24,
        //         content: ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.PORTRAIT[0],
        //     },
        //     x: ConstantsTool.STUDENT_INFO_START_X,
        //     y: ConstantsTool.STUDENT_INFO_START_Y,
        // }),
        // new ResourceItemNew({
        //     id: "ws_student_value_1" + generateIdFromDateTime(),
        //     idType: "ws_student_value_" + 1,
        //     width: ConstantsTool.STUDENT_INFO_2_WIDTH,
        //     height: 36,
        //     type: Config.RESOURCE_TEXT,
        //     textAttribute: {
        //         fontFamily: ConstantsTool.DEFAULT_FONT,
        //         fontSize: 24,
        //         content: ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.PORTRAIT[1],
        //     },
        //     x:
        //         ConstantsTool.STUDENT_INFO_1_WIDTH +
        //         ConstantsTool.STUDENT_INFO_START_X,
        //     y: ConstantsTool.STUDENT_INFO_START_Y,
        // }),
        // new ResourceItemNew({
        //     id: "ws_generator",
        //     idType: "ws_generator",
        //     width: 470,
        //     height: 31,
        //     type: ConstantsTool.RESOURCE_TEXT,
        //     textAttribute: {
        //         fontFamily: "Nunito",
        //         fontSize: 20,
        //         content: "Create Your Own Worksheet At Worksheetzone.org",
        //     },
        //     x: ConstantsTool.WIDTH_A4 - 470 - 18, // Minus width and padding
        //     y: ConstantsTool.HEIGHT_A4 - 33,
        // }),
        // new ResourceItemNew({
        //     id: "3",
        //     width: ConstantsTool.BORDER_WIDTH,
        //     height: ConstantsTool.BORDER_HEIGHT,
        //     type: ConstantsTool.TYPE_RESOURCE_BORDER,
        //     x: ConstantsTool.BORDER_X,
        //     y: ConstantsTool.BORDER_Y,
        // }),
        // new ResourceItemNew({
        //     id: "logo",
        //     idType: "logo",
        //     width: ConstantsTool.WIDTH_LOGO,
        //     height: 24,
        //     type: ConstantsTool.TYPE_LOGO,
        //     x: ConstantsTool.BORDER_X,
        //     imageAttribute: {
        //         url: getPrefixImage() + "/images/logo.png",
        //     },
        //     y: ConstantsTool.HEIGHT_A4 - 33,
        // }),
    ];
};

export const getResourceItemsOldWordSearch = (
    worksheet: IWorksheet,
    type?: string
) => {
    let { fontSizeTitle, startY, fontFamily, title } =
        getTitleConfigByType(type);
    if (worksheet?.title) {
        title = worksheet?.title;
    }
    let resources = [
        new ResourceItemNew({
            id: "bg_1",
            idType: "bg_1",
            width: ConstantsTool.WIDTH_A4,
            height: ConstantsTool.HEIGHT_A4,
            type: ConstantsTool.RESOURCE_BACKGROUND,
            x: 0,
            y: 0,
        }),
        new ResourceItemNew({
            id:
                "ws_title_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            idType:
                "ws_title_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            width: ConstantsTool.WIDTH_A4 - 80,
            height: fontSizeTitle + 16,
            type: ConstantsTool.RESOURCE_TEXT,
            textAttribute: {
                fontFamily: fontFamily,
                fontSize: fontSizeTitle,
                content: title,
                align: "center",
            },
            x: 40,
            y: startY,
        }),
        new ResourceItemNew({
            id: "3",
            width: ConstantsTool.BORDER_WIDTH,
            height: ConstantsTool.BORDER_HEIGHT,
            type: ConstantsTool.TYPE_RESOURCE_BORDER,
            x: ConstantsTool.BORDER_X,
            y: ConstantsTool.BORDER_Y,
        }),
    ];
    let studentInfos = worksheet?.game?.wordSearchCreator?.studentInfos;
    if (studentInfos[0]) {
        resources.push(
            new ResourceItemNew({
                id: "ws_student_value_0" + generateIdFromDateTime(),
                idType: "ws_student_value_" + 0,
                width: ConstantsTool.STUDENT_INFO_1_WIDTH,
                height: 36,
                type: Config.RESOURCE_TEXT,
                textAttribute: {
                    fontFamily: ConstantsTool.DEFAULT_FONT,
                    fontSize: 24,
                    content:
                        ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.PORTRAIT[0],
                },
                x: ConstantsTool.STUDENT_INFO_START_X,
                y: ConstantsTool.STUDENT_INFO_START_Y,
            })
        );
    }
    if (studentInfos[1]) {
        resources.push(
            new ResourceItemNew({
                id: "ws_student_value_1" + generateIdFromDateTime(),
                idType: "ws_student_value_" + 1,
                width: ConstantsTool.STUDENT_INFO_2_WIDTH,
                height: 36,
                type: Config.RESOURCE_TEXT,
                textAttribute: {
                    fontFamily: ConstantsTool.DEFAULT_FONT,
                    fontSize: 24,
                    content:
                        ConstantsTool.STUDENT_INFO_DEFAULT_VALUE.PORTRAIT[1],
                },
                x:
                    ConstantsTool.STUDENT_INFO_1_WIDTH +
                    ConstantsTool.STUDENT_INFO_START_X,
                y: ConstantsTool.STUDENT_INFO_START_Y,
            })
        );
    }
    return resources;
};

export const newPageResource = (
    pageIndex,
    resourceGenerator?: IResourceItemNew
) => {
    return [
        new ResourceItemNew({
            id: generateIdFromDateTime(),
            width: ConstantsTool.WIDTH_A4,
            height: ConstantsTool.HEIGHT_A4,
            type: ConstantsTool.RESOURCE_BACKGROUND,
            x: 0,
            y: 0,
            pageIndex,
        }),
        new ResourceItemNew({
            id: generateIdFromDateTime(),
            width: ConstantsTool.BORDER_WIDTH,
            height: ConstantsTool.BORDER_HEIGHT,
            type: ConstantsTool.TYPE_RESOURCE_BORDER,
            x: ConstantsTool.BORDER_X,
            y: ConstantsTool.BORDER_Y,
            pageIndex,
        }),
        // new ResourceItemNew({
        //     id: "logo",
        //     idType: "logo",
        //     width: 145.5,
        //     height: 24,
        //     type: ConstantsTool.TYPE_LOGO,
        //     imageAttribute: {
        //         url: getPrefixImage() + "/images/logo.png",
        //     },
        //     x: ConstantsTool.BORDER_X,
        //     y: ConstantsTool.HEIGHT_A4 - 33,
        //     pageIndex,
        // }),
        // resourceGenerator ??
        //     new ResourceItemNew({
        //         id: generateIdFromDateTime("ws_generator_"),
        //         idType: "ws_generator",
        //         width: 470,
        //         height: 31,
        //         type: ConstantsTool.RESOURCE_TEXT_MODULE,
        //         pageIndex,
        //         textAttribute: {
        //             fontFamily: "Nunito",
        //             fontSize: 20,
        //             content: "Create Your Own Worksheet At worksheetzone.org",
        //         },
        //         x: ConstantsTool.WIDTH_A4 - 470 - 18, // Minus width and padding
        //         y: ConstantsTool.HEIGHT_A4 - 33,
        //     }),
    ];
};
export const getTextWidthHeightTool = ({
    textValue,
    includePadding,
    fontSize,
    fontFamily,
    maxWidth,
    textAlign,
    listStyle,
    textTransform,
    paddingBottom = true,
    lineHeight,
    childQuery,
    isBold,
}: {
    textValue: string;
    includePadding?: boolean;
    fontSize?: number;
    fontFamily?: string;
    maxWidth?: number;
    textAlign?: string;
    listStyle?: number;
    textTransform?: string;
    paddingBottom?: boolean;
    childQuery?: string;
    lineHeight?: number;
    isBold?: boolean;
}) => {
    const hiddenDiv = document.getElementById("hidden_text");
    let width = hiddenDiv.clientWidth;
    let height = hiddenDiv.clientHeight;
    let childPosition = {
        x: 0,
        y: 0,
    };

    if (textValue) {
        hiddenDiv.style.whiteSpace = "pre-wrap";
        hiddenDiv.style.margin = "0px";
        if (
            listStyle === ListStyle.LIST_MARKER ||
            listStyle === ListStyle.BULLET_POINT
        ) {
            let newEle = document.createElement("div");
            newEle.style.paddingLeft = "1em";
            newEle.style.position = "relative";
            newEle.style.margin = "0px";
            newEle.innerHTML = textValue;
            newEle.classList.add("custom-marker");
            hiddenDiv.appendChild(newEle);
        } else {
            hiddenDiv.style.padding = "0px";
            hiddenDiv.innerHTML = textValue;
        }
        if (maxWidth) {
            hiddenDiv.style.maxWidth = maxWidth + "px";
            hiddenDiv.style.wordBreak = "break-all";
        }
        if (fontSize) {
            hiddenDiv.style.fontSize = fontSize + "px";
        }
        if (fontFamily) {
            hiddenDiv.style.setProperty("font-family", fontFamily, "important");
        }
        if (textAlign) {
            hiddenDiv.style.textAlign = textAlign;
        }
        if (textTransform) {
            hiddenDiv.style.textTransform = textTransform;
        }
        if (lineHeight) {
            hiddenDiv.style.lineHeight = `${lineHeight}px`;
        }
        // list style -> fill the blank
        if (listStyle) {
            hiddenDiv.style.wordBreak = "unset";
        }
        if (childQuery) {
            const parentRect = hiddenDiv.getBoundingClientRect();
            const child = hiddenDiv.querySelector(childQuery);
            const childRect = child?.getBoundingClientRect();

            if (childRect) {
                let childTop = childRect.top - parentRect.top;
                let childLeft = childRect.left - parentRect.left;

                childPosition = {
                    x: childLeft,
                    y: childTop,
                };
            }
        }
        if (isBold) {
            hiddenDiv.style.fontWeight = "bold";
        }
        width =
            hiddenDiv.clientWidth +
            (includePadding ? ConstantsTool.PADDING_RIGHT_TEXT : 0);
        height =
            hiddenDiv.clientHeight +
            (paddingBottom ? ConstantsTool.PADDING_BOTTOM_TEXT : 0);
    }
    hiddenDiv.innerHTML = "";
    hiddenDiv.style.margin = "";
    hiddenDiv.style.padding = "";
    hiddenDiv.style.maxWidth = "";
    hiddenDiv.style.wordBreak = "";
    hiddenDiv.style.fontSize = "";
    hiddenDiv.style.fontFamily = "";
    hiddenDiv.style.textAlign = "";
    hiddenDiv.style.textTransform = "";
    hiddenDiv.style.lineHeight = "";
    hiddenDiv.style.whiteSpace = "";

    return {
        width,
        height,
        childPosition,
    };
};

export const getMathFieldWidthHeightTool = async () => {
    await delay(200);
    const hiddenDiv = document.getElementById("hidden_math_filed");
    let width = hiddenDiv.clientWidth + 16; // +16 for pading
    let height = hiddenDiv.clientHeight;
    return {
        width,
        height,
    };
};
export const listResourcePageNewWorksheet = (pageIndex: number) => {
    return [
        ...newPageResource(pageIndex),
        new ResourceItemNew({
            id:
                "ws_title_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            idType:
                "ws_title_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            width: ConstantsTool.WIDTH_A4 - 80,
            height: 58,
            type: ConstantsTool.RESOURCE_TEXT_MODULE,
            textAttribute: {
                fontFamily: "Nunito",
                fontSize: 42,
                content: "My Word Sheet Title",
                align: "center",
            },
            x: 40,
            y: 100,
        }),
        new ResourceItemNew({
            id:
                "ws_description_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            idType:
                "ws_description_" +
                new Date().getTime().toString() +
                Math.floor(Math.random() * 9999),
            width: ConstantsTool.WIDTH_A4 - 80,
            height: 0,
            type: ConstantsTool.RESOURCE_TEXT_MODULE,
            textAttribute: {
                fontFamily: "Nunito",
                fontSize: 20,
                content: "",
                align: "center",
            },
            x: 40,
            y: 160,
        }),
    ];
};

export const callbackResizeHorizontal = (
    newWidth: number,
    newPos: IPoint,
    rotateValue: number,
    currentElement: IResourceItemNew,
    listStyle?: ListStyle
) => {
    let newHeight = currentElement.height;
    if (resourceCanChangeHeight(currentElement.type)) {
        newHeight = getSizeBoxText({
            textAttribute: currentElement.textAttribute,
            width: floorNumber(newWidth),
            type: currentElement.type,
            listStyle: listStyle,
        }).height;
    }

    return {
        newWidth,
        newHeight,
        newPosition: newPos,
        rotateValue,
    };
};
export const isRotateLeft = (currentItem: IResourceItemNew) => {
    return (
        currentItem.y < 120 ||
        (currentItem.rotate > 150 && currentItem.rotate < 180) ||
        (currentItem.rotate > -180 && currentItem.rotate < -150) ||
        (currentItem?.shapeAttribute?.input.position === PositionInput.top &&
            currentItem.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE)
    );
};
export const getSizeWordBank = ({
    listWordsBank,
    pagesWorksheet,
    width,
}: {
    listWordsBank;
    pagesWorksheet;
    width;
}) => {
    let string = `<div style="display: flex; gap: 36px; flex-wrap: wrap;
            ">`;
    listWordsBank &&
        listWordsBank?.forEach((item) => {
            item?.answers?.forEach((answer) => {
                pagesWorksheet.forEach((page) => {
                    page.resourceItems.forEach((rs) => {
                        if (rs.id == item.fillBankId) {
                            let listQuestion =
                                rs?.question?.question?.split(" ");
                            let a = listQuestion[Number(answer.text)];
                            string += `<p style="margin: 0px; font-size: 16px;">${a}</p>`;
                        }
                    });
                });
            });
        });
    string += `</div>`;

    let size = getTextWidthHeightTool({
        textValue: string,
        includePadding: true,
        maxWidth: width,
        fontSize: 16,
        fontFamily: "Nunito",
        textTransform: "capitalize",
    });
    return size;
};

export const getLocationByQuestionIndex = ({
    questions,
    index,
    activity,
}: {
    questions: IQuestionActivity[];
    index?: number;
    activity?: string;
}) => {
    let pageIndex = 0;
    let { attribute, y_first, y_other } = getConfigByActivity(activity);
    let endY = y_first;
    if (isNaN(index) && questions.length !== 0) {
        index = questions.length;
    }
    for (let i = 0; i < index; i++) {
        const question = questions[i];
        const numberLine = question[attribute].numberOfLines;
        const fontSize =
            ConstantsTool.LINE_SPACING[question[attribute].lineSpacing];
        const heightItem = fontSize + getGapBetweenItem(fontSize);
        for (let j = 0; j < numberLine; j++) {
            if (heightItem + endY - 4 > ConstantsTool.BORDER_HEIGHT) {
                endY = y_other;
                pageIndex += 1;
            }
            endY += heightItem;
        }
    }
    return { pageIndex, endY };
};

export const deleteUnusedPages = ({
    pages,
}: {
    pages: IPageWorksheetNew[];
}) => {
    const DEFAULT_ITEMS_CAN_NOT_DELETE = newPageResource(0).length;

    const unusedPageIndexes = pages.reduce((prev, page, index) => {
        const shouldPush =
            page.resourceItems.length === DEFAULT_ITEMS_CAN_NOT_DELETE;

        if (shouldPush) {
            return [index, ...prev];
        }

        return prev;
    }, []);

    unusedPageIndexes.forEach((index) => {
        pages.splice(index, 1);
    });

    return pages.length - 1;
};

export const updateLocationByIndex = ({
    questions,
    index,
    pagesWorksheet,
    activity,
}: {
    questions: IQuestionActivity[];
    index?: number;
    pagesWorksheet?: IPageWorksheetNew[] | any[];
    activity?: string;
}) => {
    let { attribute, y_other, width } = getConfigByActivity(activity);
    // Get start X and page index of current index question
    let { pageIndex, endY } = getLocationByQuestionIndex({
        questions,
        index,
        activity,
    });
    // Update Location of question item after current index question
    for (let i = index; i < questions.length; i++) {
        const question = questions[i];
        const fontSize =
            ConstantsTool.LINE_SPACING[question[attribute].lineSpacing];
        const heightItem = fontSize + getGapBetweenItem(fontSize);
        const resourceItems = [];
        pagesWorksheet.some((page) => {
            const newResourceItems = [];
            page.resourceItems.forEach((r) => {
                if (question[attribute].resourceItemIds.includes(r.id)) {
                    resourceItems.push(r);
                } else {
                    newResourceItems.push(r);
                }
            });
            page.resourceItems = newResourceItems;
        });
        resourceItems.forEach((item: IResourceItemNew) => {
            // Update new height and font size to item
            item.width = width;
            item.height = heightItem;
            item.textAttribute.fontSize =
                ConstantsTool.LINE_SPACING[question[attribute].lineSpacing];
            if (endY + heightItem - 4 > ConstantsTool.BORDER_HEIGHT) {
                // Update page and end Y
                endY = y_other;
                pageIndex += 1;

                // Update item info
                item.pageIndex = pageIndex;
                item.y = endY;
                const nextPage = pagesWorksheet[pageIndex];
                if (!nextPage) {
                    const newPage = new PageWorksheetNew({
                        id: generateIdFromDateTime(),
                        resourceItems: [...newPageResource(pageIndex), item],
                        width: ConstantsTool.WIDTH_A4,
                        height: ConstantsTool.HEIGHT_A4,
                    });
                    pagesWorksheet.push(newPage);
                } else {
                    nextPage.resourceItems.push(item);
                }
            } else {
                // Update item info
                item.pageIndex = pageIndex;
                item.y = endY;
                pagesWorksheet[pageIndex].resourceItems.push(item);
            }
            // Update end Y
            endY += heightItem;
        });
    }
    deleteUnusedPages({ pages: pagesWorksheet });
};

export const getFontFamily = ({
    fontName,
    fontStyle,
    textStyle,
}: {
    fontName: string;
    fontStyle: number;
    textStyle: number;
}) => {
    const key = {
        [ConstantsTool.TEXT_STYLE.REGULAR]: "regular",
        [ConstantsTool.TEXT_STYLE.DASHED]: "dashed",
        [ConstantsTool.TEXT_STYLE.STROKED]: "stroked",
    };
    const keyName = key[textStyle];
    const fontFamilies =
        fontStyle === ConstantsTool.FONT_PRINT
            ? [...CreateWSConfig.PRINT_FONT_FAMILY]
            : [...CreateWSConfig.CURSIVE_FONT_FAMILY];
    const fontFamily = fontFamilies.find((f) => f.name === fontName);

    if (fontFamily && keyName) {
        return fontFamily[keyName];
    }
};

export const substringToFitMaxWidth = (resourceItem: ResourceItemNew) => {
    let text = resourceItem.textAttribute.content;
    let textSize = getTextWidthHeightTool({
        textValue: text + " ...",
        includePadding: true,
        fontSize: resourceItem.textAttribute.fontSize,
        fontFamily: resourceItem.textAttribute?.fontFamily,
        // maxWidth: resourceItem.width,
    });
    if (textSize.width <= ConstantsTool.MAX_WIDTH_GENERATOR) {
        return text;
    }
    let result = "";

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        let textSize = getTextWidthHeightTool({
            textValue: result + " ...",
            includePadding: true,
            fontSize: resourceItem.textAttribute.fontSize,
            fontFamily: resourceItem.textAttribute?.fontFamily,
            // maxWidth: resourceItem.width,
        });
        if (textSize.width <= ConstantsTool.MAX_WIDTH_GENERATOR) {
            result += char;
        } else {
            break;
        }
    }

    return result + " ...";
};

export const getTextWidth = ({
    text,
    font,
}: {
    text: string;
    font: string;
}) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    canvas.remove();
    return metrics.width;
};

export const getPositionCrosswordByLayout = (
    layout: string,
    activityId: string,
    oldCrosswordItems?: ResourceItemNew[]
) => {
    const result = [];
    let grid = new ResourceItemNew({
        id: "crossword_grid" + "_" + generateIdFromDateTime(),
        idType: "crossword_grid",
        activityId,
        type: ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID,
        height: ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD,
        y: ConstantsTool.CROSSWORD_Y,
        layout,
    });
    let clueAcross = new ResourceItemNew({
        id: "crossword_clue_across",
        idType: "crossword_clue_across",
        type: ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST,
        activityId,
    });
    let clueDown = new ResourceItemNew({
        id: "crossword_clue_down",
        idType: "crossword_clue_down",
        type: ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST,
        activityId,
    });
    const image = new ResourceItemNew({
        id: generateIdFromDateTime(),
        idType: "crossword_image",
        pageIndex: 0,
        type: 1,
        imageAttribute: {
            url: "https://storage.googleapis.com/worksheetzone/images/1695802041295-65994131.svg",
            svgContent: "",
            changeColor: {
                "#ffdc00": "#ffdc00",
                "#fec108": "#fec108",
                "#ff9801": "#ff9801",
                "#e91e63": "#e91e63",
                "#000": "#000",
            },
        },
        url: "https://storage.googleapis.com/worksheetzone/images/1695802041295-65994131.svg",
        width: 160,
        height: 167,
    });
    switch (layout) {
        case ConstantsTool.LAYOUT.DEFAULT:
            grid = {
                ...grid,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD,
                x: ConstantsTool.CROSSWORD_X,
            };

            clueAcross = {
                ...clueAcross,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.CROSSWORD_X,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };

            clueDown = {
                ...clueDown,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2 + 70,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };
            break;
        case ConstantsTool.LAYOUT.CLUES_BY_SIDE:
        case ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT:
            grid = {
                ...grid,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.7,
                x:
                    ConstantsTool.CROSSWORD_X +
                    ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 +
                    10,
            };

            clueAcross = {
                ...clueAcross,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 + 20,
                height: 0,
                x: ConstantsTool.CROSSWORD_X - 20,
                y: ConstantsTool.CROSSWORD_Y,
            };

            clueDown = {
                ...clueDown,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 + 20,
                height: 0,
                x: ConstantsTool.CROSSWORD_X - 20,
                y: ConstantsTool.CROSSWORD_Y + 10,
            };

            if (layout === ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT) {
                result.push({
                    ...image,
                    x:
                        ConstantsTool.CROSSWORD_X +
                        ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.4 +
                        10,
                    y: ConstantsTool.CROSSWORD_MAX_Y_WITH_IMAGE + 50,
                });
            }

            break;
        case ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT:
            grid = {
                ...grid,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.7,
                x:
                    ConstantsTool.CROSSWORD_X +
                    ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 +
                    10,
            };

            clueAcross = {
                ...clueAcross,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 + 20,
                height: 0,
                x: ConstantsTool.CROSSWORD_X - 20,
                y: ConstantsTool.CROSSWORD_Y,
            };

            clueDown = {
                ...clueDown,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.5,
                height: 0,
                x:
                    ConstantsTool.CROSSWORD_X +
                    ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.5 +
                    10,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };
            result.push({
                ...image,
                x: ConstantsTool.CROSSWORD_X,
                y: ConstantsTool.CROSSWORD_MAX_Y_WITH_IMAGE + 50,
            });
            break;
        case ConstantsTool.LAYOUT.IMAGE_UPPER_RIGHT:
            grid = {
                ...grid,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.7,
                x: ConstantsTool.CROSSWORD_X,
            };

            clueAcross = {
                ...clueAcross,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.CROSSWORD_X,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };

            clueDown = {
                ...clueDown,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2 + 70,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };
            result.push({
                ...image,
                x:
                    ConstantsTool.CROSSWORD_X +
                    ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.7 +
                    10,
                y: ConstantsTool.CROSSWORD_Y + 50,
            });
            break;
        case ConstantsTool.LAYOUT.IMAGE_UPPER_LEFT:
            grid = {
                ...grid,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.7,
                x:
                    ConstantsTool.CROSSWORD_X +
                    ConstantsTool.MAX_WIDTH_GRID_CROSSWORD * 0.3 +
                    10,
            };

            clueAcross = {
                ...clueAcross,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.CROSSWORD_X,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };

            clueDown = {
                ...clueDown,
                width: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2,
                height: 0,
                x: ConstantsTool.MAX_WIDTH_GRID_CROSSWORD / 2 + 70,
                y:
                    ConstantsTool.CROSSWORD_Y +
                    ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD +
                    10,
            };
            result.push({
                ...image,
                x: ConstantsTool.CROSSWORD_X,
                y: ConstantsTool.CROSSWORD_Y + 50,
            });
            break;
        default:
            break;
    }

    const wordBank = new ResourceItemNew({
        id: "crossword_word_bank",
        idType: "crossword_word_bank",
        width: ConstantsTool.BORDER_WIDTH - 60,
        height: 0,
        activityId,
        type: ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK,
        x: ConstantsTool.CROSSWORD_X,
        y: 0,
    });

    oldCrosswordItems?.forEach((item) => {
        const textAtrribute = item.textAttribute;
        switch (item.idType) {
            case "crossword_clue_across":
                clueAcross.textAttribute = textAtrribute;
                break;
            case "crossword_clue_down":
                clueDown.textAttribute = textAtrribute;
                break;
            case "crossword_word_bank":
                wordBank.textAttribute = textAtrribute;
                break;
            default:
                break;
        }
    });
    result.unshift(grid, clueAcross, clueDown, wordBank);

    return result;
};

export const getCluePositionConfig = (layout: string) => {
    const result = {
        stackClue: false,
        behindGrid: true,
    };

    if (
        [
            ConstantsTool.LAYOUT.CLUES_BY_SIDE,
            ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT,
            ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT,
        ].includes(layout)
    ) {
        result.stackClue = true;
        result.behindGrid = false;
    }

    return result;
};

export const updateCrosswordItemsPosition = ({
    config,
    pages,
    activityId,
    oldCrosswordItems,
    isUpdateImage,
}: {
    config: {
        layout: string;
        showWordBank: boolean;
    };
    pages: IPageWorksheetNew[];
    activityId: string;
    oldCrosswordItems: ResourceItemNew[];
    isUpdateImage?: boolean;
}) => {
    const { layout, showWordBank } = config;
    const crosswordElements = document.querySelectorAll(
        "[id^=container_crossword_]"
    );
    let crosswordItems = getPositionCrosswordByLayout(
        layout,
        activityId,
        oldCrosswordItems
    );
    const GAP = 10;

    if (crosswordElements) {
        const MAX_Y_OF_PAGE =
            ConstantsTool.BORDER_HEIGHT + ConstantsTool.BORDER_Y;
        let maxY = 0;
        let maxPageIndex = 0;
        let grid, clueAcross, clueDown, wordBank;
        const imageItem = crosswordItems.find((item) =>
            item.idType?.includes("crossword_image")
        );

        crosswordElements.forEach((element) => {
            const id = element.getAttribute("id");
            if (id.includes("grid")) {
                grid = element;
            }
            if (id.includes("across")) {
                clueAcross = element;
            }
            if (id.includes("down")) {
                clueDown = element;
            }
            if (id.includes("word_bank")) {
                wordBank = element;
            }
        });

        if (grid) {
            const isImageLower = [
                ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT,
                ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT,
            ].includes(layout);
            const maxGirdY = isImageLower
                ? ConstantsTool.CROSSWORD_MAX_Y_WITH_IMAGE
                : ConstantsTool.CROSSWORD_MAX_Y;
            const PADDING = 20;
            const gridItem = crosswordItems.find(
                (item) =>
                    item.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID
            );
            if (gridItem) {
                let newGridHeight = grid.firstChild.clientHeight;
                if (gridItem.y + newGridHeight + PADDING >= maxGirdY) {
                    const gridHeight = isImageLower
                        ? ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD_WITH_IMAGE
                        : ConstantsTool.MAX_HEIGHT_GRID_CROSSWORD;
                    newGridHeight = gridHeight;
                }
                gridItem.height = newGridHeight + PADDING;
                maxY = gridItem.y + gridItem.height;
            }
        }
        maxY = getMaxYLayoutWithImage(layout, maxY, maxPageIndex, imageItem);

        if (clueAcross && clueDown) {
            const clueAcrossHeight = clueAcross.clientHeight;
            const clueDownHeight = clueDown.clientHeight;
            if (layout === ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT) {
                const clueAcrossItem = crosswordItems.find((item) =>
                    item.idType?.includes("_clue_across")
                );
                const clueDownItem = crosswordItems.find((item) =>
                    item.idType?.includes("_clue_down")
                );
                if (clueAcrossItem) {
                    let startY = ConstantsTool.CROSSWORD_Y;
                    let pageIndex = maxPageIndex;
                    clueAcrossItem.height = clueAcrossHeight;
                    if (
                        startY + clueAcrossHeight + GAP >
                        ConstantsTool.CROSSWORD_MAX_Y
                    ) {
                        startY = ConstantsTool.CROSSWORD_OTHER_Y;
                        pageIndex = maxPageIndex + 1;
                    }
                    clueAcrossItem.y = startY;
                    clueAcrossItem.pageIndex = pageIndex;
                }

                if (clueDownItem) {
                    let startY = maxY + GAP;
                    let pageIndex = maxPageIndex;
                    clueDownItem.height = clueDownHeight;
                    if (startY + clueDownHeight >= MAX_Y_OF_PAGE) {
                        startY = ConstantsTool.CROSSWORD_OTHER_Y;
                        pageIndex = maxPageIndex + 1;
                    }
                    clueDownItem.y = startY;
                    clueDownItem.pageIndex = pageIndex;
                }

                if (clueAcrossItem.pageIndex > clueDownItem.pageIndex) {
                    maxPageIndex = clueAcrossItem.pageIndex;
                    maxY = clueAcrossItem.y + clueAcrossHeight + GAP;
                } else {
                    maxPageIndex = clueDownItem.pageIndex;
                    maxY = clueDownItem.y + clueDownHeight + GAP;
                }
            } else {
                const { stackClue, behindGrid } = getCluePositionConfig(layout);
                let startY = behindGrid
                    ? maxY + GAP
                    : ConstantsTool.CROSSWORD_Y;
                if (stackClue) {
                    let pageIndex = maxPageIndex;
                    crosswordItems.forEach((item) => {
                        if (item.idType?.includes("crossword_clue")) {
                            if (item.idType?.includes("_clue_across")) {
                                item.height = clueAcrossHeight;
                            } else {
                                item.height = clueDownHeight;
                                if (
                                    startY +
                                        clueAcrossHeight +
                                        GAP +
                                        clueDownHeight >=
                                    MAX_Y_OF_PAGE
                                ) {
                                    pageIndex++;
                                    startY = ConstantsTool.CROSSWORD_OTHER_Y;
                                } else {
                                    startY += GAP + clueAcrossHeight;
                                }
                            }
                            item.y = startY;
                            item.pageIndex = pageIndex;
                        }
                    });

                    if (pageIndex > maxPageIndex) {
                        maxY = startY + clueDownHeight;
                    } else {
                        maxY =
                            startY + clueDownHeight > maxY
                                ? startY + clueDownHeight
                                : maxY;
                    }
                    maxPageIndex = pageIndex;
                } else {
                    const maxHeight =
                        clueAcrossHeight > clueDownHeight
                            ? clueAcrossHeight
                            : clueDownHeight;
                    if (startY + maxHeight >= MAX_Y_OF_PAGE) {
                        maxPageIndex += 1;
                        startY = ConstantsTool.CROSSWORD_OTHER_Y;
                    }
                    crosswordItems.forEach((item) => {
                        if (item.idType?.includes("crossword_clue")) {
                            item.height = item.idType?.includes("_across")
                                ? clueAcrossHeight
                                : clueDownHeight;
                            item.pageIndex = maxPageIndex;
                            item.y = startY;
                        }
                    });
                    maxY = startY + maxHeight;
                }
            }
        }

        if (wordBank) {
            const wordBankItem = crosswordItems.find(
                (item) =>
                    item.type ===
                    ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK
            );
            if (wordBankItem) {
                const wordBankHeight = wordBank.clientHeight;
                maxY = getMaxYLayoutWithImage(
                    layout,
                    maxY,
                    maxPageIndex,
                    imageItem,
                    wordBankHeight
                );

                wordBankItem.height = showWordBank ? wordBankHeight : 0;
                wordBankItem.y = maxY + GAP;

                if (wordBankHeight + maxY + GAP >= MAX_Y_OF_PAGE) {
                    maxPageIndex++;
                    wordBankItem.y = ConstantsTool.CROSSWORD_OTHER_Y;
                }
                wordBankItem.pageIndex = maxPageIndex;
            }
        }

        if (!isUpdateImage) {
            crosswordItems = crosswordItems.filter(
                (item) => !item.idType?.includes("_image")
            );
        }

        for (let i = 0; i <= maxPageIndex; i++) {
            if (!pages[i]) {
                let wsGenerator = pages[i - 1].resourceItems.find(
                    (el) => el.idType === "ws_generator"
                );
                const newPage = new PageWorksheetNew({
                    id: generateIdFromDateTime(),
                    resourceItems: [
                        ...newPageResource(
                            i,
                            new ResourceItemNew({
                                ...wsGenerator,
                                pageIndex: i,
                            })
                        ),
                    ],
                });
                pages.push(newPage);
            }
            const crosswordItemsByPageIndex = crosswordItems.filter(
                (item) => item.pageIndex === i
            );
            pages[i].resourceItems.push(...crosswordItemsByPageIndex);
        }

        deleteUnusedPages({ pages });
    }
};

const getMaxYLayoutWithImage = (
    layout: string,
    maxY: number,
    maxPageIndex: number,
    imageItem: IResourceItemNew,
    wordbankHeight?: number
) => {
    let result = maxY;

    if (imageItem && imageItem.pageIndex === maxPageIndex) {
        const imageHeight = imageItem.height;
        const imageY = imageItem.y;
        if (
            [
                ConstantsTool.LAYOUT.IMAGE_LOWER_LEFT,
                ConstantsTool.LAYOUT.IMAGE_LOWER_RIGHT,
            ].includes(layout)
        ) {
            if (maxY + 10 + wordbankHeight >= imageY) {
                if (maxY < imageY + imageHeight) {
                    result = imageY + imageHeight;
                }
            }
        } else if (
            [
                ConstantsTool.LAYOUT.IMAGE_UPPER_LEFT,
                ConstantsTool.LAYOUT.IMAGE_UPPER_RIGHT,
            ].includes(layout)
        ) {
            if (imageY + imageHeight > maxY) {
                result = imageY + imageHeight;
            }
        }
    }

    return result;
};

export const getSizeBoxText = ({
    textAttribute,
    width,
    type,
    listStyle,
}: {
    textAttribute: ITextAttribute;
    width: number;
    type?: number;
    listStyle?: ListStyle;
}) => {
    if (listStyle === ListStyle.LIST_MARKER) width -= textAttribute.fontSize;
    let size = getSizeDivText({ textAttribute, width, test: false });
    let padding = getBoundingTextBox(textAttribute).top;
    size.height += padding * 2;
    if (listStyle === ListStyle.LIST_MARKER)
        size.width += textAttribute.fontSize;
    if (type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK) size.height += 2;
    return size;
};

export const getSizeFixedRatio = ({
    oldPoints,
    currentPoints,
    ratio,
    direction,
    originElement,
}: {
    oldPoints: {
        topLeft: IPoint;
        topRight: IPoint;
        bottomLeft: IPoint;
        bottomRight: IPoint;
        rotateValue: number;
    };
    currentPoints: IPoint;
    ratio: number;
    direction: DirectionProps;
    originElement: IResourceItemNew;
}) => {
    const minWidth = 6;
    const minHeight = 6;
    const originWidth = originElement.width;
    const originHeight = originElement.height;
    const rotate = originElement.rotate;
    let width = originWidth,
        height = originHeight,
        newWidth = width,
        newHeight = height;
    if (direction.vertical === "bottom" && direction.horizontal === "right") {
        newWidth = calculateDistanceToLine(
            currentPoints,
            oldPoints.bottomLeft,
            oldPoints.topLeft
        );
        newHeight = calculateDistanceToLine(
            currentPoints,
            oldPoints.topRight,
            oldPoints.topLeft
        );
    }

    if (direction.vertical === "bottom" && direction.horizontal === "left") {
        newWidth = calculateDistanceToLine(
            currentPoints,
            oldPoints.bottomRight,
            oldPoints.topRight
        );
        newHeight = calculateDistanceToLine(
            currentPoints,
            oldPoints.topRight,
            oldPoints.topLeft
        );
    }

    if (direction.vertical === "top" && direction.horizontal === "right") {
        newWidth = calculateDistanceToLine(
            currentPoints,
            oldPoints.bottomLeft,
            oldPoints.topLeft
        );
        newHeight = calculateDistanceToLine(
            currentPoints,
            oldPoints.bottomLeft,
            oldPoints.bottomRight
        );
    }

    if (direction.vertical === "top" && direction.horizontal === "left") {
        newWidth = calculateDistanceToLine(
            currentPoints,
            oldPoints.topRight,
            oldPoints.bottomRight
        );
        newHeight = calculateDistanceToLine(
            currentPoints,
            oldPoints.bottomLeft,
            oldPoints.bottomRight
        );
    }

    let dW = newWidth - originWidth;
    let dH = newHeight - originHeight;
    let d = dW / ratio - dH;
    if (d > 0) {
        width = newWidth;
        height = width / ratio;
    } else {
        height = newHeight;
        width = height * ratio;
    }
    if (width < minWidth) {
        width = minWidth;
        height = width / ratio;
    }
    if (height < minHeight) {
        height = minHeight;
        width = height * ratio;
    }
    const { x, y } = originElement;
    let newValue = getSizeResourceItem(x, y, width, height, rotate);
    let oldValue = getSizeResourceItem(x, y, originWidth, originHeight, rotate);
    const points = newValue.points;
    const prePoints = oldValue.points;

    let index = 0;
    if (direction.vertical === "bottom" && direction.horizontal === "left") {
        index = 1;
    }
    if (direction.vertical === "top" && direction.horizontal === "left") {
        index = 2;
    }
    if (direction.vertical === "top" && direction.horizontal === "right") {
        index = 3;
    }
    let dltX = prePoints[index].x - points[index].x,
        dltY = prePoints[index].y - points[index].y;
    let newPosition = {
        x: x + dltX,
        y: y + dltY,
    };
    return {
        width,
        height,
        newPosition,
    };
};
