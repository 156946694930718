import { useEffect, useState } from "react";
import "simplebar-react/dist/simplebar.min.css";
import { Activity } from "../../../shared/models/WorkSheetCreator";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import FontTypeSection from "./font-type";
import "./index.scss";
import InputSection from "./input";
import ListenSelectText from "./listenSelect";
import TextStyleSection from "./text-style";

const HandWritingView = ({ activity }: { activity: Activity }) => {
    const [questionId, setQuestionId] = useState<string>("");
    const question = activity.questions.find((s) => s.id === questionId);
    const [config, setConfig] = useState({
        fontStyle:
            question?.handwritingAttribute?.fontStyle ??
            ConstantsTool.FONT_PRINT,
        textStyle:
            question?.handwritingAttribute?.textStyle ??
            ConstantsTool.TEXT_STYLE.DASHED,
    });

    useEffect(() => {
        if (!questionId && activity.questions) {
            setQuestionId(activity.questions?.[0]?.id);
        }
    }, []);

    useEffect(() => {
        if (question && questionId) {
            setConfig({
                fontStyle: question?.handwritingAttribute?.fontStyle,
                textStyle: question?.handwritingAttribute?.textStyle,
            });
        }
    }, [questionId]);

    return (
        <div className="handwritting-container">
            <FontTypeSection
                question={question}
                config={config}
                setConfig={setConfig}
            />
            <InputSection
                questions={activity.questions}
                questionId={questionId}
                setQuestionId={setQuestionId}
                config={config}
            />
            <div
                style={{
                    padding: "8px",
                    background: " #F9FAFB",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <TextStyleSection
                    question={question}
                    config={config}
                    setConfig={setConfig}
                />
                <ListenSelectText
                    questions={activity.questions}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                />
            </div>
        </div>
    );
};

export default HandWritingView;
