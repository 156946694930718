import { Button, Checkbox, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Activity } from "../../../../shared/models/WorkSheetCreator";
import Config from "../../../../shared/utils/config";
import { getPrefixImage } from "../../../../utils";
import "./index.scss";
import ConstantsMath from "../../../../shared/utils/ConstantsResourceMath";
import { useAppDispatch } from "../../../../redux/hook";
import {
    changeDirection,
    changeHiddenStyle,
    changeMainStyle,
    changeStrokeStyle,
} from "../../../../redux/async/mathActivityThunk";
import { sendEvent } from "../tracking";

const TABS = [
    {
        id: ConstantsMath.MAIN_STYLE.DEFAULT,
        name: "Default",
    },
    {
        id: ConstantsMath.MAIN_STYLE.BONDS,
        name: "Number Bonds",
    },
];

const DISPLAY_STYLES = {
    [ConstantsMath.MAIN_STYLE.DEFAULT]: [
        {
            id: ConstantsMath.DIRECTION.HORIZONTAL,
            name: "Horizontal",
            img: "horizontal.svg",
        },
        {
            id: ConstantsMath.DIRECTION.VERTICAL,
            name: "Vertical",
            img: "vertical.svg",
        },
        {
            id: ConstantsMath.DIRECTION.OTHER,
            name: "Other",
            img: "other.svg",
        },
    ],
    [ConstantsMath.MAIN_STYLE.BONDS]: [
        {
            id: ConstantsMath.DIRECTION.LEFT,
            name: "Left",
            img: "left.svg",
        },
        {
            id: ConstantsMath.DIRECTION.UP,
            name: "Up",
            img: "up.svg",
        },
        {
            id: ConstantsMath.DIRECTION.DOWN,
            name: "Down",
            img: "down.svg",
        },
        {
            id: ConstantsMath.DIRECTION.RIGHT,
            name: "Right",
            img: "right.svg",
        },
    ],
};

const STROKE_STYLES = [
    {
        id: ConstantsMath.STROKE_STYLE.NONE,
        name: "None",
        img: "none",
    },
    {
        id: ConstantsMath.STROKE_STYLE.LINE,
        name: "Line",
        img: "line.svg",
    },
    {
        id: ConstantsMath.STROKE_STYLE.SHAPE,
        name: "Shape",
        img: "shape.svg",
    },
];

const ADVANCE_OPTIONS = [
    {
        id: ConstantsMath.HIDDEN_STYLE.DEFAULT,
        name: "Default",
        img: "hide-default.svg",
    },
    {
        id: ConstantsMath.HIDDEN_STYLE.RANDOM,
        name: "Random",
        img: "hide-random.svg",
    },
];

const StyledSection = ({ activity }: { activity: Activity }) => {
    const dispatch = useAppDispatch();
    const currentTab = activity.mainStyle;

    return (
        <div className="styled-section">
            <TabStyle
                currentTab={currentTab}
                setCurrentTab={(id) => {
                    dispatch(changeMainStyle({ styleId: id }));

                    const name = TABS.find((tab) => tab.id === id)?.name;
                    if (name) {
                        const action =
                            "choose_" + name.toLowerCase().split(" ").join("_");
                        sendEvent(activity.type, action);
                    }
                }}
            />
            <div className="main-section">
                <DisplayStyle
                    currentTab={currentTab}
                    activityType={activity.type}
                    direction={activity.direction}
                />
                {currentTab === ConstantsMath.MAIN_STYLE.DEFAULT && (
                    <DropdownStyle
                        title="Stroke Style"
                        items={STROKE_STYLES}
                        pathImg={`${getPrefixImage()}/images/math-activity/`}
                        defaultValue={activity.strokeStyle}
                        handleChange={(value: number) => {
                            dispatch(changeStrokeStyle({ stroke: value }));

                            const name = STROKE_STYLES.find(
                                (stroke) => stroke.id === value
                            )?.name?.toLowerCase();
                            if (name) {
                                const action = "stroke_style_" + name;
                                sendEvent(activity.type, action);
                            }
                        }}
                    />
                )}
                <DropdownStyle
                    title="Advanced Option"
                    items={ADVANCE_OPTIONS}
                    pathImg={`${getPrefixImage()}/images/math-activity/${activity.type.toLowerCase()}/`}
                    defaultValue={activity.hiddenStyle}
                    handleChange={(value: number) => {
                        dispatch(changeHiddenStyle({ hidden: value }));

                        const name = ADVANCE_OPTIONS.find(
                            (stroke) => stroke.id === value
                        )?.name?.toLowerCase();
                        if (name) {
                            const action = "advanced_option_" + name;
                            sendEvent(activity.type, action);
                        }
                    }}
                />
            </div>
        </div>
    );
};

const TabStyle = ({
    currentTab,
    setCurrentTab,
}: {
    currentTab: number;
    setCurrentTab: Function;
}) => {
    return (
        <div className="btn-group">
            {TABS.map(({ name, id }) => {
                return (
                    <Button
                        key={name + id}
                        className={`btn ${
                            id === currentTab ? "active" : "disable"
                        }`}
                        fullWidth
                        onClick={() => {
                            if (id !== currentTab) {
                                setCurrentTab(id);
                            }
                        }}
                        disableRipple
                    >
                        {name}
                    </Button>
                );
            })}
        </div>
    );
};

const DisplayStyle = ({
    currentTab,
    direction,
    activityType,
}: {
    currentTab: number;
    direction: number;
    activityType: string;
}) => {
    const dispatch = useAppDispatch();
    const isDefaultTab = currentTab === ConstantsMath.MAIN_STYLE.DEFAULT;
    const isNotDivisionDefaultType =
        isDefaultTab && activityType !== Config.ACTIVITY_TYPE.DIVISION.TYPE;

    let items = DISPLAY_STYLES[currentTab];
    if (isNotDivisionDefaultType) {
        items = items.filter(
            (item) => item.id !== ConstantsMath.DIRECTION.OTHER
        );
    }

    return (
        <div
            className={`display-style-container ${
                isDefaultTab ? "default" : "bonds"
            }`}
        >
            {items.map(({ id, img }) => {
                const isChecked = direction === id;
                return (
                    <div
                        className={`item ${isChecked && "active"}`}
                        key={currentTab + "-" + id}
                        onClick={() => {
                            if (!isChecked) {
                                dispatch(changeDirection({ direction: id }));

                                const action = `${
                                    isDefaultTab ? "default" : "number_bonds"
                                }_${isDefaultTab ? id : id - 3}`;
                                sendEvent(activityType, action);
                            }
                        }}
                    >
                        {isDefaultTab && (
                            <Checkbox
                                checked={isChecked}
                                className="check-icon"
                                checkedIcon={<CheckedIcon />}
                                icon={<UncheckedIcon />}
                            />
                        )}
                        <img
                            src={`${getPrefixImage()}/images/math-activity/${
                                isDefaultTab
                                    ? activityType.toLowerCase() + "/"
                                    : ""
                            }${img}`}
                            alt="display style item"
                        />
                    </div>
                );
            })}
        </div>
    );
};

const DropdownStyle = ({
    title,
    items,
    pathImg,
    defaultValue,
    handleChange,
}: {
    title: string;
    pathImg: string;
    items: { id: number; name: string; img: string }[];
    defaultValue: number;
    handleChange?: Function;
}) => {
    return (
        <div className="dropdown-container">
            <div className="title">{title}</div>
            <Select
                className="select"
                value={defaultValue}
                onChange={(e) => {
                    const value = e.target.value as number;
                    if (value !== defaultValue) {
                        handleChange && handleChange(value);
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            padding: 0,
                        },
                    },
                    MenuListProps: {
                        style: {
                            padding: 4,
                        },
                    },
                }}
                SelectDisplayProps={{
                    style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    },
                }}
                IconComponent={CustomArrowIcon}
            >
                {items.map(({ id, name, img }) => {
                    const isNoneImg = img === "none";

                    return (
                        <MenuItem
                            key={id}
                            value={id}
                            className="math-dropdown-item"
                        >
                            <div className="left">
                                <div className="tick-icon">
                                    <TickedIcon />
                                </div>
                                {!isNoneImg && (
                                    <img
                                        src={`${pathImg}${img}`}
                                        alt="drop down item"
                                        className="img"
                                    />
                                )}
                            </div>
                            <div className="right">{name}</div>
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

const CustomArrowIcon = ({ classNames, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            className={classNames}
            {...rest}
        >
            <path
                d="M9.26051 5.24996L6.99718 7.5133L4.73385 5.24996C4.62486 5.14073 4.4769 5.07935 4.3226 5.07935C4.1683 5.07935 4.02033 5.14073 3.91135 5.24996C3.68385 5.47746 3.68385 5.84496 3.91135 6.07246L6.58885 8.74996C6.81635 8.97746 7.18385 8.97746 7.41135 8.74996L10.0888 6.07246C10.3163 5.84496 10.3163 5.47746 10.0888 5.24996C9.86135 5.0283 9.48801 5.02246 9.26051 5.24996Z"
                fill="#212121"
                fillOpacity="0.52"
            />
        </svg>
    );
};

const UncheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                d="M2.1665 7.00008C2.1665 4.05622 4.55598 1.66675 7.49984 1.66675C10.4437 1.66675 12.8332 4.05622 12.8332 7.00008C12.8332 9.94394 10.4437 12.3334 7.49984 12.3334C4.55598 12.3334 2.1665 9.94394 2.1665 7.00008Z"
                stroke="#212121"
                strokeOpacity="0.32"
            />
        </svg>
    );
};

const CheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99984 12.8334C10.2215 12.8334 12.8332 10.2217 12.8332 7.00008C12.8332 3.77842 10.2215 1.16675 6.99984 1.16675C3.77818 1.16675 1.1665 3.77842 1.1665 7.00008C1.1665 10.2217 3.77818 12.8334 6.99984 12.8334ZM6.99983 10.0001C8.65668 10.0001 9.99983 8.65693 9.99983 7.00007C9.99983 5.34322 8.65668 4.00007 6.99983 4.00007C5.34297 4.00007 3.99983 5.34322 3.99983 7.00007C3.99983 8.65693 5.34297 10.0001 6.99983 10.0001Z"
                fill="#3B82F6"
            />
        </svg>
    );
};

const TickedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                d="M5.13345 9.27789L3.09178 7.23622C2.98395 7.12705 2.8369 7.06561 2.68345 7.06561C2.53 7.06561 2.38295 7.12705 2.27512 7.23622C2.04762 7.46372 2.04762 7.82539 2.27512 8.05289L4.71928 10.4971C4.94678 10.7246 5.31428 10.7246 5.54178 10.4971L11.7251 4.31956C11.9526 4.09206 11.9526 3.73039 11.7251 3.50289C11.6173 3.39372 11.4702 3.33228 11.3168 3.33228C11.1633 3.33228 11.0163 3.39372 10.9085 3.50289L5.13345 9.27789Z"
                fill="#3B82F6"
            />
        </svg>
    );
};

export default StyledSection;
