import { useState } from "react";
import IconFeedback from "../../assets/images/icon/bubbles-feedback/icon-feedback.svg";
import TopBreak from "../../assets/images/icon/bubbles-feedback/top-break.svg";
import { getImageSource } from "../../utils";
import "./style.scss";
import { IUserInfo } from "../../../shared/models/userInfo";
import { useSelector } from "react-redux";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import DynamicComponent from "../common/dynamic-component";
const PopupFeedBack = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const userInfo: IUserInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    return (
        <div
            style={{
                width: "48px",
                height: "48px",
                margin: "0 12px 9px auto",
            }}
        >
            <div
                className={
                    "popup-feedback-container " +
                    (userInfo.email ? "" : "disabled")
                }
                onClick={() => {
                    setOpenDrawer(true);
                }}
            >
                <div className="button-feedback">
                    <img
                        className="icon-feedback"
                        src={getImageSource(IconFeedback)}
                    />
                    <div className="tooltip-container">Send feedback</div>
                    <img className="top-break" src={getImageSource(TopBreak)} />
                </div>
                {openDrawer && (
                    <DynamicComponent
                        path="./drawer-feedback"
                        component={import("./drawer-feedback")}
                        openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
            </div>
        </div>
    );
};

export default PopupFeedBack;
