import { useSelector } from "react-redux";
import { ResourceAppState } from "../../../../../redux/reducer/root.reducerModule";
import { getAvtUser, getNameUser } from "../../../../../utils";
import CoinQty from "../coin-qty";
import "./style.scss";
const UserInfoClaimCoins = () => {
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const quotaDownload = useSelector(
        (state: ResourceAppState) =>
            state.quotaResourceUserState?.data?.quotaDownload
    );
    const avt = getAvtUser({ avtUser: userInfo?.avatar });

    return (
        <div className="claim-coins-user-info">
            <div className="c-avt">
                <img src={avt} alt="avt" width={32} height={32} />
            </div>
            <div>
                <p className="c-name">
                    {getNameUser({
                        userName: userInfo?.name,
                        email: userInfo?.email,
                    })}
                </p>

                <CoinQty quantity={quotaDownload} width={14} height={14} />
            </div>
        </div>
    );
};

export default UserInfoClaimCoins;
