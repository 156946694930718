import { SurveyWeb } from "survey-common";
import dataSurvey from "../../data/dataSurveyWordSearch.json";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { updateDoneSurveyRating } from "../../resource-user/redux/repositories/user";
import { getInitTypeFromUrl } from "../../utils";

const Survey = ({
    openSurvey,
    setOpenSurvey,
}: {
    openSurvey: boolean;
    setOpenSurvey: any;
}) => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(
        (state) => state.userInfoResourceState.data
    );
    const typeSurvey = getInitTypeFromUrl();

    const handleSuccess = async () => {
        try {
            if (userInfo?.id) {
                const newListSurvey = [
                    ...userInfo.isDoneSurveyRating,
                    typeSurvey,
                ];
                localStorage.setItem(
                    "responseSurvey",
                    JSON.stringify(newListSurvey)
                );

                await updateDoneSurveyRating({
                    userId: userInfo?.id,
                    newListSurvey,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    return (
        <>
            {openSurvey && (
                <SurveyWeb
                    userInfo={userInfo}
                    closeSurveyFc={() => {
                        setOpenSurvey(false);
                    }}
                    dataSurveyWorksheetJson={{
                        ...dataSurvey,
                        type: typeSurvey,
                    }}
                    onSuccess={() => {
                        handleSuccess();
                    }}
                />
            )}
        </>
    );
};

export default Survey;
