import { Activity } from "../../../shared/models/WorkSheetCreator";
import "./index.scss";
import RequiredSection from "./required-section";
import StyledSection from "./styled-section";

const MathView = ({ activity }: { activity: Activity }) => {
    return (
        <div className="math-container" key={activity.type}>
            <StyledSection activity={activity} />
            <RequiredSection activity={activity} />
        </div>
    );
};

export default MathView;
