export default class Constants {
    static PUZZLE_SHAPE_ARRAY = [
        {
            shape: "Square",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "1111111111111111111111111111111111111111111111111",
        },
        {
            shape: "Square",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },
        {
            shape: "Square",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },
        {
            shape: "Square",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },
        {
            shape: "Square",
            size: "Huge",
            width: "30",
            height: "30",
            active_string:
                "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },
        {
            shape: "Square",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },

        {
            shape: "Pumpkin",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0010000000100001111101111111111111101111100011100",
        },
        {
            shape: "Pumpkin",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0001100000000011000000101101000111111110111111111111111111111111111111011111111000111111000001111000",
        },
        {
            shape: "Pumpkin",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000001100000000000000110000000000000011000000000111011011000001111111111100011111111111110011111111111110111111111111111111111111111111111111111111111111111111111111011111111111110011111111111110001111111111100000111011011000",
        },
        {
            shape: "Pumpkin",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000000110000000000000000000110000000000000000000110000000000001111011110011100000111111111111111100011111111111111111100111111111111111111011111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111100111111111111111111000111111111111111100000111111111111110000000111111111111000000000001111100000000",
        },
        {
            shape: "Pumpkin",
            size: "Huge",
            width: "30",
            height: "30",
            active_string:
                "000000000000100000000000000000000000000001110000000000000000000000000011111000000000000000000000000001111100000000000000000000000000111110000000000000000000000000011110000000000000000001111110111111011111000000000111111111111111111111111000001111111111111111111111111100001111111111111111111111111110011111111111111111111111111110011111111111111111111111111110011111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110011111111111111111111111111110011111111111111111111111111110001111111111111111111111111100000111111111111111111111111000000011111111111111111111110000000001111111111111111111100000000000000011111111100000000000000000000001111111000000000000",
        },
        {
            shape: "Pumpkin",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "0000000000000001110000000000000000000000000000000000000111100000000000000000000000000000000000001111000000000000000000000000000000000000011110000000000000000000000000000000000001111000000000000000000000000001111111000111100001111111100000000000001111111110111111101111111111000000000001111111111111111111111111111110000000001111111111111111111111111111111100000000111111111111111111111111111111110000000111111111111111111111111111111111100000111111111111111111111111111111111111000011111111111111111111111111111111111100001111111111111111111111111111111111110001111111111111111111111111111111111111100111111111111111111111111111111111111110011111111111111111111111111111111111111011111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111111111111111111111001111111111111111111111111111111111111100111111111111111111111111111111111111110001111111111111111111111111111111111110000111111111111111111111111111111111111000001111111111111111111111111111111111000000011111111111111111111111111111111100000001111111111111111111111111111111100000000011111111111111111111111111111110000000001111111111111111111111111111110000000000001111101111111111111011111100000000000000000000011111111111000000000000000",
        },
        {
            shape: "Diamond",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0001000001110001111101111111011111000111000001000",
        },
        {
            shape: "Diamond",
            size: "Small",
            width: "11",
            height: "11",
            active_string:
                "0000010000000001110000000111110000011111110001111111110111111111110111111111000111111100000111110000000111000000000100000",
        },
        {
            shape: "Diamond",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000010000000000000111000000000001111100000000011111110000000111111111000001111111111100011111111111110111111111111111011111111111110001111111111100000111111111000000011111110000000001111100000000000111000000000000010000000",
        },
        {
            shape: "Diamond",
            size: "Large",
            width: "21",
            height: "21",
            active_string:
                "000000000010000000000000000000111000000000000000001111100000000000000011111110000000000000111111111000000000001111111111100000000011111111111110000000111111111111111000001111111111111111100011111111111111111110111111111111111111111011111111111111111110001111111111111111100000111111111111111000000011111111111110000000001111111111100000000000111111111000000000000011111110000000000000001111100000000000000000111000000000000000000010000000000",
        },
        {
            shape: "Diamond",
            size: "Huge",
            width: "31",
            height: "31",
            active_string:
                "0000000000000001000000000000000000000000000001110000000000000000000000000001111100000000000000000000000001111111000000000000000000000001111111110000000000000000000001111111111100000000000000000001111111111111000000000000000001111111111111110000000000000001111111111111111100000000000001111111111111111111000000000001111111111111111111110000000001111111111111111111111100000001111111111111111111111111000001111111111111111111111111110001111111111111111111111111111101111111111111111111111111111111011111111111111111111111111111000111111111111111111111111111000001111111111111111111111111000000011111111111111111111111000000000111111111111111111111000000000001111111111111111111000000000000011111111111111111000000000000000111111111111111000000000000000001111111111111000000000000000000011111111111000000000000000000000111111111000000000000000000000001111111000000000000000000000000011111000000000000000000000000000111000000000000000000000000000001000000000000000",
        },
        {
            shape: "Diamond",
            size: "Gigantic",
            width: "39",
            height: "39",
            active_string:
                "000000000000000000010000000000000000000000000000000000000111000000000000000000000000000000000001111100000000000000000000000000000000011111110000000000000000000000000000000111111111000000000000000000000000000001111111111100000000000000000000000000011111111111110000000000000000000000000111111111111111000000000000000000000001111111111111111100000000000000000000011111111111111111110000000000000000000111111111111111111111000000000000000001111111111111111111111100000000000000011111111111111111111111110000000000000111111111111111111111111111000000000001111111111111111111111111111100000000011111111111111111111111111111110000000111111111111111111111111111111111000001111111111111111111111111111111111100011111111111111111111111111111111111110111111111111111111111111111111111111111011111111111111111111111111111111111110001111111111111111111111111111111111100000111111111111111111111111111111111000000011111111111111111111111111111110000000001111111111111111111111111111100000000000111111111111111111111111111000000000000011111111111111111111111110000000000000001111111111111111111111100000000000000000111111111111111111111000000000000000000011111111111111111110000000000000000000001111111111111111100000000000000000000000111111111111111000000000000000000000000011111111111110000000000000000000000000001111111111100000000000000000000000000000111111111000000000000000000000000000000011111110000000000000000000000000000000001111100000000000000000000000000000000000111000000000000000000000000000000000000010000000000000000000",
        },
        {
            shape: "Star",
            size: "Tiny",
            width: "7",
            height: "6",
            active_string: "000100000111001111111011111000111000110110",
        },
        {
            shape: "Star",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0000110000000011000000011110001111111111011111111000011110000011111100001111110001110011100110000110",
        },
        {
            shape: "Star",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000010000000000000111000000000000111000000000000111000000111101111101111011111111111110001111111111100000111111111000000011111110000000011111110000000011111110000000111101111000000111101111000001111000111100001100000001100",
        },
        {
            shape: "Star",
            size: "Large",
            width: "21",
            height: "22",
            active_string:
                "000000000010000000000000000000010000000000000000000111000000000000000000111000000000000000001111100000000000000001111100000000000000011111110000000000000011111110000000111111111111111111111011111111111111111110001111111111111111100000111111111111111000000011111111111110000000001111111111100000000001111111111100000000011111111111110000000011111101111110000000111111000111111000000111110000011111000001111000000000111100001100000000000001100001000000000000000100",
        },
        {
            shape: "Star",
            size: "Huge",
            width: "29",
            height: "30",
            active_string:
                "000000000000001000000000000000000000000000010000000000000000000000000001110000000000000000000000000011100000000000000000000000000111000000000000000000000000011111000000000000000000000000111110000000000000000000000001111100000000000000000000000111111100000000000000000000001111111000000000000000000000011111110000000000011111111111111111111111111111011111111111111111111111111100001111111111111111111111100000001111111111111111111110000000001111111111111111111000000000000111111111111111000000000000000111111111111100000000000000000111111111110000000000000000001111111111100000000000000000011111111111000000000000000001111111111111000000000000000011111111111110000000000000000111111011111100000000000000011111100011111100000000000000111110000011111000000000000001110000000001110000000000000111000000000001110000000000001100000000000001100000000000010000000000000001000000",
        },
        {
            shape: "Star",
            size: "Gigantic",
            width: "39",
            height: "40",
            active_string:
                "000000000000000000010000000000000000000000000000000000000010000000000000000000000000000000000000010000000000000000000000000000000000000111000000000000000000000000000000000000111000000000000000000000000000000000000111000000000000000000000000000000000001111100000000000000000000000000000000001111100000000000000000000000000000000001111100000000000000000000000000000000011111110000000000000000000000000000000011111110000000000000000000000000000000011111110000000000000000000000000000000111111111000000000000000000000000000000111111111000000000000000000000000000000111111111000000000000000111111111111111111111111111111111111111011111111111111111111111111111111111110000111111111111111111111111111111111000000011111111111111111111111111111110000000001111111111111111111111111111100000000000011111111111111111111111110000000000000001111111111111111111111100000000000000000111111111111111111111000000000000000000001111111111111111100000000000000000000000111111111111111000000000000000000000000111111111111111000000000000000000000000111111111111111000000000000000000000000111111111111111000000000000000000000001111111111111111100000000000000000000001111111111111111100000000000000000000001111111111111111100000000000000000000011111111101111111110000000000000000000011111110000111111110000000000000000000011111100000001111110000000000000000000111111000000000111111000000000000000000111110000000000011111000000000000000000111000000000000000111000000000000000001110000000000000000011100000000000000001100000000000000000001100000000000000001000000000000000000000100000000",
        },
        {
            shape: "Circle",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0011100011111011111111111111111111101111100011100",
        },
        {
            shape: "Circle",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0001111000011111111001111111101111111111111111111111111111111111111111011111111001111111100001111000",
        },
        {
            shape: "Circle",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000001111100000000111111111000001111111111100011111111111110011111111111110111111111111111111111111111111111111111111111111111111111111111111111111111011111111111110011111111111110001111111111100000111111111000000001111100000",
        },
        {
            shape: "Circle",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000001111111100000000001111111111110000000111111111111110000011111111111111110001111111111111111110011111111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111100111111111111111111000111111111111111100000111111111111110000000111111111111000000000011111111000000",
        },
        {
            shape: "Circle",
            size: "Huge",
            width: "30",
            height: "30",
            active_string:
                "000000000011111111110000000000000000001111111111111100000000000000111111111111111111000000000001111111111111111111100000000011111111111111111111110000000111111111111111111111111000001111111111111111111111111000001111111111111111111111111100011111111111111111111111111110011111111111111111111111111110111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111111111110011111111111111111111111111110001111111111111111111111111100001111111111111111111111111100000111111111111111111111111000000011111111111111111111110000000001111111111111111111100000000000111111111111111111000000000000001111111111111100000000000000000011111111110000000000",
        },
        {
            shape: "Circle",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "0000000000000001111111111000000000000000000000000000111111111111111100000000000000000000001111111111111111111100000000000000000001111111111111111111111000000000000000011111111111111111111111111000000000000011111111111111111111111111110000000000011111111111111111111111111111100000000011111111111111111111111111111111000000001111111111111111111111111111111100000001111111111111111111111111111111111000001111111111111111111111111111111111110000111111111111111111111111111111111111000111111111111111111111111111111111111110011111111111111111111111111111111111111001111111111111111111111111111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111111111111111111111001111111111111111111111111111111111111100111111111111111111111111111111111111110001111111111111111111111111111111111110000111111111111111111111111111111111111000001111111111111111111111111111111111000000111111111111111111111111111111111100000001111111111111111111111111111111100000000011111111111111111111111111111100000000000111111111111111111111111111100000000000001111111111111111111111111100000000000000011111111111111111111111100000000000000000011111111111111111111000000000000000000000011111111111111110000000000000000000000000001111111111000000000000000",
        },
        {
            shape: "Smiley",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0011100011111011010111111111110101101101100011100",
        },
        {
            shape: "Smiley",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0001111000011111111001101101101110110111111111111111111111111101111011011000011001111111100001111000",
        },
        {
            shape: "Smiley",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000001111100000000111111111000001111111111100011110111011110011110111011110111110111011111111110111011111111111111111111111111111111111111011111110111011101111101110011110000011110001111111111100000111111111000000001111100000",
        },
        {
            shape: "Smiley",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000001111111100000000001111111111110000000111111111111110000011110011110011110001111100111100111110011111001111001111101111110011110011111111111100111100111111111111001111001111111111111111111111111111111111111111111111111111111111111111111110111111111111011111100111111111100111011100111111110011100111100000000001111000111100000000111100000111111111111110000000111111111111000000000011111111000000",
        },
        {
            shape: "Smiley",
            size: "Huge",
            width: "30",
            height: "30",
            active_string:
                "000000000011111111110000000000000000001111111111111100000000000000111111111111111111000000000001111111111111111111100000000011111111111111111111110000000111111000111111000111111000001111111000111111000111111000001111111000111111000111111100011111111000111111000111111110011111111000111111000111111110111111111000111111000111111111111111111000111111000111111111111111111000111111000111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110111111111111111111011111111110011111111111111110011111011110001111111111111110011110011111000000000000000000111110001111100000000000000001111100001111110000000000000011111100000111111111111111111111111000000011111111111111111111110000000001111111111111111111100000000000111111111111111111000000000000001111111111111100000000000000000011111111110000000000",
        },
        {
            shape: "Smiley",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "0000000000000001111111111000000000000000000000000000111111111111111100000000000000000000001111111111111111111100000000000000000001111111111111111111111000000000000000011111111111111111111111111000000000000011111111111111111111111111110000000000011111111111111111111111111111100000000011111111111111111111111111111111000000001111111100001111111100001111111100000001111111110000111111110000111111111000001111111111000011111111000011111111110000111111111100001111111100001111111111000111111111110000111111110000111111111110011111111111000011111111000011111111111001111111111100001111111100001111111111101111111111110000111111110000111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111101111111111111111111111111101111111111110111111111111111111111111110111111011111001111111111111111111111110011111001111100011111111111111111111110001111100111111000000000000000000000000001111110001111100000000000000000000000001111110000111111000000000000000000000001111111000001111110000000000000000000001111111000000111111100000000000000000001111111100000001111111111111111111111111111111100000000011111111111111111111111111111100000000000111111111111111111111111111100000000000001111111111111111111111111100000000000000011111111111111111111111100000000000000000011111111111111111111000000000000000000000011111111111111110000000000000000000000000001111111111000000000000000",
        },

        {
            shape: "Heart",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0110110111111111111111111111011111000111000001000",
        },
        {
            shape: "Heart",
            size: "Small",
            width: "11",
            height: "10",
            active_string:
                "00110001100011110111101111111111111111111111111111111110111111111000111111100000111110000000111000000000100000",
        },
        {
            shape: "Heart",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "001110000011100011111000111110111111101111111111111111111111111111111111111111111111111111111111111111111011111111111110011111111111110001111111111100000111111111000000011111110000000001111100000000000111000000000000010000000",
        },
        {
            shape: "Heart",
            size: "Large",
            width: "21",
            height: "21",
            active_string:
                "000111100000001111000001111110000011111100011111111000111111110111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111110011111111111111111110001111111111111111100001111111111111111100000111111111111111000000011111111111110000000001111111111100000000000111111111000000000000011111110000000000000001111100000000000000000111000000000000000000010000000000",
        },
        {
            shape: "Heart",
            size: "Huge",
            width: "31",
            height: "31",
            active_string:
                "0000111111000000000001111110000000111111110000000001111111100000111111111100000001111111111000111111111111000001111111111110011111111111110001111111111111011111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110111111111111111111111111111110011111111111111111111111111111001111111111111111111111111111100011111111111111111111111111100001111111111111111111111111110000011111111111111111111111110000001111111111111111111111111000000011111111111111111111111000000000111111111111111111111000000000001111111111111111111000000000000111111111111111111100000000000001111111111111111100000000000000011111111111111100000000000000000111111111111100000000000000000001111111111100000000000000000000001111111000000000000000000000000011111000000000000000000000000000111000000000000000000000000000001000000000000000",
        },
        {
            shape: "Heart",
            size: "Gigantic",
            width: "37",
            height: "39",
            active_string:
                "000001111110000000000000001111110000000001111111110000000000011111111100000001111111111100000000011111111111000001111111111111000000011111111111110001111111111111110000011111111111111100111111111111111000001111111111111110111111111111111110001111111111111111111111111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111111111111111111001111111111111111111111111111111111100111111111111111111111111111111111110001111111111111111111111111111111110000111111111111111111111111111111111000001111111111111111111111111111111000000111111111111111111111111111111100000001111111111111111111111111111100000000111111111111111111111111111110000000001111111111111111111111111110000000000011111111111111111111111110000000000001111111111111111111111111000000000000011111111111111111111111000000000000000111111111111111111111000000000000000001111111111111111111000000000000000000011111111111111111000000000000000000000111111111111111000000000000000000000001111111111111000000000000000000000000011111111111000000000000000000000000000111111111000000000000000000000000000001111111000000000000000000000000000000011111000000000000000000000000000000000111000000000000000000000000000000000001000000000000000000",
        },
        {
            shape: "Tree",
            size: "Tiny",
            width: "7",
            height: "8",
            active_string:
                "00010000011100001110001111100111110111111100010000001000",
        },
        {
            shape: "Tree",
            size: "Small",
            width: "7",
            height: "10",
            active_string:
                "0001000001110001111100011100011111011111110111110111111100010000001000",
        },
        {
            shape: "Tree",
            size: "Medium",
            width: "13",
            height: "15",
            active_string:
                "000000100000000000111000000000111110000000111111100000000111000000000111110000000111111100000111111111000001111111000001111111110001111111111101111111111111000001110000000000111000000000011100000",
        },
        {
            shape: "Tree",
            size: "Large",
            width: "19",
            height: "20",
            active_string:
                "00000000010000000000000000011100000000000000011111000000000000011111110000000000011111111100000000000011111000000000000011111110000000000011111111100000000011111111111000000011111111111110000011111111111111100000001111111110000000001111111111100000001111111111111000001111111111111110001111111111111111101111111111111111111000000000100000000000000000010000000000000000001000000000",
        },
        {
            shape: "Tree",
            size: "Huge",
            width: "25",
            height: "28",
            active_string:
                "0000000000001000000000000000000000001110000000000000000000001111100000000000000000001111111000000000000000001111111110000000000000001111111111100000000000001111111111111000000000000000111111100000000000000000111111111000000000000000111111111110000000000000111111111111100000000000111111111111111000000000111111111111111110000000111111111111111111100000111111111111111111111000000001111111111111000000000001111111111111110000000001111111111111111100000001111111111111111111000001111111111111111111110001111111111111111111111101111111111111111111111111000000000001110000000000000000000000111000000000000000000000011100000000000000000000001110000000000000000000000111000000000000000000000011100000000000",
        },
        {
            shape: "Tree",
            size: "Gigantic",
            width: "35",
            height: "36",
            active_string:
                "000000000000000001000000000000000000000000000000000111000000000000000000000000000000011111000000000000000000000000000001111111000000000000000000000000000111111111000000000000000000000000011111111111000000000000000000000001111111111111000000000000000000000111111111111111000000000000000000011111111111111111000000000000000001111111111111111111000000000000000000011111111111110000000000000000000001111111111111110000000000000000000111111111111111110000000000000000011111111111111111110000000000000001111111111111111111110000000000000111111111111111111111110000000000011111111111111111111111110000000001111111111111111111111111110000000111111111111111111111111111110000000000011111111111111111110000000000000001111111111111111111110000000000000111111111111111111111110000000000011111111111111111111111110000000001111111111111111111111111110000000111111111111111111111111111110000011111111111111111111111111111110001111111111111111111111111111111110111111111111111111111111111111111110000000000000000111000000000000000000000000000000001110000000000000000000000000000000011100000000000000000000000000000000111000000000000000000000000000000001110000000000000000000000000000000011100000000000000000000000000000000111000000000000000000000000000000001110000000000000000",
        },
        {
            shape: "Clover",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0110110111011111111110011110111111111101110110110",
        },
        {
            shape: "Clover",
            size: "Small",
            width: "11",
            height: "11",
            active_string:
                "0011101110001111011110111111111111111111111111111111111001111110001111111111111111111111111110111110111101111000111011100",
        },
        {
            shape: "Clover",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "001111000111100001111101111100110111101111011111111101111111111111111111111111111111111111011111111111110000011111110000011111111111110111111111111111111111111111111111111101111111110111101111011001111101111100001111000111100",
        },
        {
            shape: "Clover",
            size: "Large",
            width: "21",
            height: "21",
            active_string:
                "001111111000111111100001111111101111111100110111111101111111011111111111101111111111111111111101111111111111111111101111111111111111111101111111111111111111111111111111111111111111111111111011111111111111111110000000111111111000000011111111111111111110111111111111111111111111111111111111111111111111111111111111111111111111101111111111111111111101111111111111111111101111111111110111111101111111011001111111101111111100001111111000111111100",
        },
        {
            shape: "Clover",
            size: "Huge",
            width: "29",
            height: "29",
            active_string:
                "0000111111110000011111111000000011111111110001111111111000000111111111110111111111110000110111111111101111111111011011110111111111011111111101111111111111111110111111111111111111111111111101111111111111111111111111111011111111111111111111111111110111111111111111111111111111101111111111111111111111111111111111111111111111111111111111111111111111110111111111111111111111111111000111111111111111111111111100000000000011111111100000000000011111111111111111111111110001111111111111111111111111110111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111111111110111111111111111111111111111101111111111111111111111111111011111111111111111111111111110111111111111111111011111111101111111110111101101111111111011111111110110000111111111110111111111110000001111111111000111111111100000001111111100000111111110000",
        },
        {
            shape: "Clover",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "0000000001111110000000000111111000000000000000011111111110000001111111111000000000000011111111111100001111111111110000000000001111111111111001111111111111000000000000111111111111100111111111111100000000000011111111111110011111111111110000000011110111111111111001111111111110111100011111101111111111100111111111110111111001111111111111111110011111111111111111101111111111111111111001111111111111111111111111111111111111100111111111111111111111111111111111111110011111111111111111111111111111111111111001111111111111111111111111111111111111100111111111111111111111111111111111111111111111111111111111110111111111111111111111111111111111111110011111111111111111111111111111111111111000111111111111111111111111111111111111000001111111111111111111111111111111111000000000000000001111111111110000000000000000000000000000111111111111000000000000000001111111111111111111111111111111111000001111111111111111111111111111111111110001111111111111111111111111111111111111100111111111111111111111111111111111111110111111111111111111111111111111111111111111111111111111111110011111111111111111111111111111111111111001111111111111111111111111111111111111100111111111111111111111111111111111111110011111111111111111111111111111111111111001111111111111111111011111111111111111100111111111111111111001111110111111111110011111111111011111000011110111111111111001111111111110111000000000111111111111100111111111111100000000000011111111111110011111111111110000000000001111111111111001111111111111000000000000111111111111000011111111111100000000000001111111111000000111111111100000000000000001111110000000000111111000000000",
        },
        {
            shape: "A",
            size: "Tiny",
            width: "5",
            height: "10",
            active_string: "00100001000101001010100011000111111100011000110001",
        },
        {
            shape: "A",
            size: "Tiny",
            width: "5",
            height: "10",
            active_string: "00100001000101001010100011000111111100011000110001",
        },
        {
            shape: "A",
            size: "Small",
            width: "9",
            height: "13",
            active_string:
                "000010000000111000000111000000111000001101100001101100001101100011000110011111110011111110110000011110000011110000011",
        },
        {
            shape: "A",
            size: "Medium",
            width: "13",
            height: "19",
            active_string:
                "0000001000000000000100000000000111000000000011100000000011011000000001101100000000110110000000110001100000011000110000001100011000001100000110000111111111000011111111100011000000011001100000001100110000000110110000000001111000000000111100000000011",
        },
        {
            shape: "A",
            size: "Large",
            width: "17",
            height: "22",
            active_string:
                "00000000100000000000000011100000000000000111000000000000011011000000000000110110000000000001101100000000000110001100000000001100011000000000011000110000000001100000110000000011000001100000000110000011000000011000000011000000111111111110000001111111111100000110000000001100001100000000011000011000000000110001100000000000110011000000000001100110000000000011011000000000000011",
        },
        {
            shape: "A",
            size: "Huge",
            width: "21",
            height: "28",
            active_string:
                "000000000111000000000000000000111000000000000000001111100000000000000001111100000000000000011101110000000000000011101110000000000000011101110000000000000111000111000000000000111000111000000000000111000111000000000001110000011100000000001110000011100000000001110000011100000000011100000001110000000011100000001110000000011111111111110000000111111111111111000000111111111111111000000111000000000111000001110000000000011100001110000000000011100001110000000000011100011100000000000001110011100000000000001110011100000000000001110111000000000000000111111000000000000000111111000000000000000111",
        },
        {
            shape: "A",
            size: "Gigantic",
            width: "27",
            height: "31",
            active_string:
                "000000000011111110000000000000000000111111111000000000000000000111111111000000000000000000111111111000000000000000001111101111100000000000000001111101111100000000000000001111101111100000000000000011111000111110000000000000011111000111110000000000000011111000111110000000000000111110000011111000000000000111110000011111000000000000111110000011111000000000001111100000001111100000000001111100000001111100000000001111100000001111100000000011111000000000111110000000011111111111111111110000000011111111111111111110000000111111111111111111111000000111111111111111111111000000111110000000000011111000001111100000000000001111100001111100000000000001111100001111100000000000001111100011111000000000000000111110011111000000000000000111110011111000000000000000111110111110000000000000000011111111110000000000000000011111111110000000000000000011111",
        },
        {
            shape: "House",
            size: "Tiny",
            width: "7",
            height: "8",
            active_string:
                "00010000011100011111011111110111110011111001111100111110",
        },
        {
            shape: "House",
            size: "Small",
            width: "9",
            height: "10",
            active_string:
                "000010000000111000001111100011111110111111111011111110011111110011111110011111110011111110",
        },
        {
            shape: "House",
            size: "Medium",
            width: "15",
            height: "18",
            active_string:
                "000000010000000000000111000000000001111100000000011111110000000111111111000001111111111100011111111111110111111111111111001100111001100001100111001100001100111001100001111111111100001111111111100001111000111100001111000111100001111000111100001111000111100001111000111100",
        },
        {
            shape: "House",
            size: "Large",
            width: "21",
            height: "24",
            active_string:
                "000000000000000000000000000000010000000000000000000111000000000000000001111100000000000000011111110000000000000111111111000000000001111111111100000000011111111111110000000111111111111111000001111111111111111100011111111111111111110000111000111000111000000111000111000111000000111000111000111000000111000111000111000000111111111111111000000111111111111111000000111111111111111000000111111000111111000000111111000111111000000111111000111111000000111111000111111000000000000000000000000000000000000000000000",
        },
        {
            shape: "House",
            size: "Huge",
            width: "29",
            height: "35",
            active_string:
                "0000000000000010000000000000000000000000001110000000000000000000000000111110000000000000000000000011111110000000000000000000001111111110000000000000000000111111111110000000000000000011111111111110000000000000001111111111111110000000000000111111111111111110000000000011111111111111111110000000001111111111111111111110000000111111111111111111111110000011111111111111111111111110001111111111111111111111111110111111111111111111111111111110000111100001111100001111000000001111000011111000011110000000011110000111110000111100000000111100001111100001111000000001111000011111000011110000000011111111111111111111100000000111111111111111111111000000001111111111111111111110000000011111111111111111111100000000111100001111111111111000000001111000011111111111110000000011110000111111000011100000000111100001111110000111000000001111000011111100001110000000011111111111111000011100000000111111111111110010111000000001111111111111100001110000000011111111111111000011100000000111111111111110000111000000001111111111111100001110000",
        },
        {
            shape: "House",
            size: "Gigantic",
            width: "37",
            height: "40",
            active_string:
                "0000000000000000001000000000000000000000000000000000001110000000000000000000000000000000001111100000000000000000000000000000001111111000000000000000000000000000001111111110000000000000000000000000001111111111100000000000000000000000001111111111111000000000000000000000001111111111111110000000000000000000001111111111111111100000000000000000001111111111111111111000000000000000001111111111111111111110000000000000001111111111111111111111100000000000001111111111111111111111111000000000001111111111111111111111111110000000001111111111111111111111111111100000001111111111111111111111111111111000001111111111111111111111111111111110001111111111111111111111111111111111101111111111111111111111111111111111111000001111100000011111000000111110000000000111110000001111100000011111000000000011111000000111110000001111100000000001111100000011111000000111110000000000111110000001111100000011111000000000011111000000111110000001111100000000001111111111111111111111111110000000000111111111111111111111111111000000000011111111111111111111111111100000000001111111111111111111111111110000000000111110000001111111111111111000000000011111000000111111100000111100000000001111100000011111110000011110000000000111110000001111111000001111000000000011111000000111111100000111100000000001111100000011111110000011110000000000111111111111111111000101111000000000011111111111111111100000111100000000001111111111111111110000011110000000000111111111111111111000001111000000000011111111111111111100000111100000",
        },
        {
            shape: "Plane",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "1100011011011100111100011100001111011000110100001",
        },
        {
            shape: "Plane",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0000000011111000011101110011100011111100000111100000011110000011111100001100111011000001100100000010",
        },
        {
            shape: "Plane",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000000000111111100000001111011110000011111001111000111110000111101111100000011111111000000001111110000000001111100000000011111110000000111111111000001111100111100000111000011110111010000001110011000000000110001000000000010",
        },
        {
            shape: "Plane",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000000000000000111111111000000000011111011111000000001111110011111000000111111100011111000011111110000011111001111111000000011111111111100000000011111111110000000000011111111000000000000111111100000000000011111111000000000001111111111000000000111111111111000000011111110011111000000011111000011111000000111100000011111000010010000000011110111000000000000011101110000000000000011001100000000000000010",
        },
        {
            shape: "Plane",
            size: "Huge",
            width: "30",
            height: "30",
            active_string:
                "000000000000000000000000011111111111100000000000000000111111011111110000000000000001111111001111111000000000000011111111000111111100000000000111111111000011111110000000001111111110000001111111000000011111111100000000111111100000111111111000000000011111110001111111110000000000001111111011111111100000000000000111111111111111000000000000000011111111111110000000000000000001111111111100000000000000000000111111111000000000000000000001111111110000000000000000000011111111111000000000000000000111111111111100000000000000001111111111111110000000000000011111111101111111000000000000111111111000111111100000000001111111110000011111110000000011111111100000001111111000000000011111000000000111111100000000101110000000000011111110000001001100000000000001111110000010001000000000000000111110111100000000000000000000011110111100000000000000000000001110011100000000000000000000000110001100000000000000000000000010",
        },
        {
            shape: "Plane",
            size: "Gigantic",
            width: "40",
            height: "40",
            active_string:
                "0000000000000000000000000000000001111111111111100000000000000000000000001111111101111111000000000000000000000001111111110011111110000000000000000000001111111111000111111100000000000000000001111111111100001111111000000000000000001111111111110000011111110000000000000001111111111111000000111111100000000000001111111111111000000001111111000000000001111111111111000000000011111110000000001111111111111000000000000111111100000001111111111111000000000000001111111000001111111111111000000000000000011111110001111111111111000000000000000000111111101111111111111000000000000000000001111111111111111111000000000000000000000011111111111111111000000000000000000000000111111111111111000000000000000000000000001111111111111000000000000000000000000001111111111111000000000000000000000000001111111111111000000000000000000000000001111111111111000000000000000000000000001111111111111110000000000000000000000001111111111111111100000000000000000000001111111111111111111000000000000000000001111111111111011111110000000000000000001111111111111000111111100000000000000001111111111111000001111111000000000000001111111111111000000011111110000000000001111111111111000000000111111100000000000000011111111000000000001111111000000000000001111111000000000000011111110000000000001001111000000000000000111111100000000001000111000000000000000001111111001111111000011000000000000000000011111110011111100001000000000000000000000111111000111110000000000000000000000000001111100000111000000000000000000000000000011110000011100000000000000000000000000000111000000110000000000000000000000000000001100000001000000000000000000000000000000010",
        },
        {
            shape: "Boat",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0001000001110001111101111111001000011111110111110",
        },
        {
            shape: "Boat",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0000100000000011000000011110000011111100011111111011111111110000100000111111111101111111100011111100",
        },
        {
            shape: "Boat",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000100000000000000100000000000001110000000000011111000000000011111100000000111111110000001111111111000001111111111100011111111111110111111111111111000000100000000111111111111111011111111111110001111111111100000111111111000",
        },
        {
            shape: "Boat",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000000001000000000000000000010000000000000000000110000000000000000011110000000000000001111110000000000000011111110000000000001111111100000000000111111111100000000011111111111100000001111111111111100000011111111111111100001111111111111111100111111111111111111011111111111111111111000000000100000000000000000001000000000011111111111111111111011111111111111111100011111111111111110000011111111111111000",
        },
        {
            shape: "Boat",
            size: "Huge",
            width: "30",
            height: "29",
            active_string:
                "000000000010000000000000000000000000000111000000000000000000000000000111100000000000000000000000001111110000000000000000000000001111111000000000000000000000011111111100000000000000000000011111111110000000000000000000111111111111000000000000000000111111111111100000000000000001111111111111110000000000000001111111111111111000000000000011111111111111111100000000000011111111111111111110000000000111111111111111111111000000000111111111111111111111100000001111111111111111111111110000001111111111111111111111111000011111111111111111111111111100011111111111111111111111111110111111111111111111111111111111000000000001000000000000000000000000000001000000000000000000000000000001000000000000000000111111111111111111111111111111011111111111111111111111111110011111111111111111111111111110001111111111111111111111111100001111111111111111111111111100000111111111111111111111111000",
        },
        {
            shape: "Boat",
            size: "Gigantic",
            width: "40",
            height: "39",
            active_string:
                "000000000000001000000000000000000000000000000000000001100000000000000000000000000000000000000111000000000000000000000000000000000000111110000000000000000000000000000000000011111100000000000000000000000000000000011111111000000000000000000000000000000011111111110000000000000000000000000000001111111111100000000000000000000000000001111111111111000000000000000000000000000111111111111110000000000000000000000000111111111111111100000000000000000000000011111111111111111000000000000000000000011111111111111111110000000000000000000001111111111111111111100000000000000000001111111111111111111111000000000000000000111111111111111111111110000000000000000111111111111111111111111100000000000000011111111111111111111111111000000000000011111111111111111111111111110000000000001111111111111111111111111111100000000001111111111111111111111111111111000000000111111111111111111111111111111110000000111111111111111111111111111111111100000011111111111111111111111111111111111000011111111111111111111111111111111111110001111111111111111111111111111111111111101111111111111111111111111111111111111111000000000000001000000000000000000000000000000000000000100000000000000000000000000000000000000010000000000000000000000000111111111111111111111111111111111111111101111111111111111111111111111111111111100111111111111111111111111111111111111110001111111111111111111111111111111111110000111111111111111111111111111111111111000001111111111111111111111111111111111000000111111111111111111111111111111111100000001111111111111111111111111111111100000000111111111111111111111111111111110000",
        },
        {
            shape: "Sun",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0001000010101000111001111111001110001010100001000",
        },
        {
            shape: "Sun",
            size: "Small",
            width: "11",
            height: "11",
            active_string:
                "0000100000001001001000001111100000011111000011111111100001111100000011111000001001001000000010000000000000000000000000000",
        },
        {
            shape: "Sun",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000010000000010000010000010001000010000100000101111101000000011111110000000111111111000000111111111000111111111111111000111111111000000111111111000000011111110000000101111101000001000010000100010000010000010000000010000000",
        },
        {
            shape: "Sun",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000000001100000000000000000011000000000011000000110000011000011000001100001100000011001111110110000000011111111111000000000011111111110000000001111111111110000000011111111111100001111111111111111111111111111111111111111000011111111111100000000111111111111000000000111111111100000000001111111111100000000110111111001100000011000011000001100001100000110000001100000000001100000000000000000011000000000",
        },
        {
            shape: "Sun",
            size: "Huge",
            width: "28",
            height: "28",
            active_string:
                "0000000000000110000000000000000000000000011000000000000000000000000001100000000000000011000000000110000000000100000110000001111110000000110000001100011111111111000110000000011111111111111110110000000000111111111111111110000000000111111111111111110000000000011111111111111111100000000011111111111111111110000000001111111111111111111100000000111111111111111111110000111111111111111111111111111111111111111111111111111111110000111111111111111111110000000011111111111111111111000000001111111111111111111000000000011111111111111111100000000001111111111111111100000000000111111111111111110000000000110111111111111101100000000110001111111111100011000000110000000111111000000110000010000000000110000000001100000000000000011000000000000000000000000001100000000000000000000000000110000000000000",
        },
        {
            shape: "Sun",
            size: "Gigantic",
            width: "36",
            height: "36",
            active_string:
                "000000000000000001100000000000000000000000000000000001100000000000000000000000000000000001100000000000000000000000000000000001100000000000000000000100000000000001100000000000001000000110000000001111111100000000011000000011000000111111111111000000110000000001100011111111111111110001100000000000110111111111111111111011000000000000011111111111111111111110000000000000011111111111111111111110000000000000111111111111111111111111000000000000111111111111111111111111000000000001111111111111111111111111100000000001111111111111111111111111100000000001111111111111111111111111100000111111111111111111111111111111111111111111111111111111111111111111111111000001111111111111111111111111100000000001111111111111111111111111100000000001111111111111111111111111100000000000111111111111111111111111000000000000111111111111111111111111000000000000111111111111111111111111000000000000011111111111111111111110000000000000011111111111111111111110000000000000111111111111111111111111000000000001100111111111111111111001100000000011000011111111111111110000110000000110000000111111111111000000011000000100000000001111111100000000001000000000000000000001100000000000000000000000000000000001100000000000000000000000000000000001100000000000000000000000000000000001100000000000000000000000000000000001100000000000000000",
        },
        {
            shape: "1",
            size: "Tiny",
            width: "3",
            height: "7",
            active_string: "010110010010010010111",
        },
        {
            shape: "1",
            size: "Small",
            width: "6",
            height: "11",
            active_string:
                "000100001100011100111100001100001100001100001100001100111111111111",
        },
        {
            shape: "1",
            size: "Medium",
            width: "7",
            height: "15",
            active_string:
                "000010000011000011100011110011011000001100000110000011000001100000110000011000001100000110001111110111111",
        },
        {
            shape: "1",
            size: "Large",
            width: "7",
            height: "15",
            active_string:
                "000010000011000011100011110011011000001100000110000011000001100000110000011000001100000110001111110111111",
        },
        {
            shape: "1",
            size: "Huge",
            width: "16",
            height: "34",
            active_string:
                "0000000000100000000000000110000000000000111000000000000111100000000000111110000000000111111000000000111111100000000111111110000000111111111000000111111111100000111110111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000000000111110000000000011111000000000001111100000011111111111111101111111111111110111111111111111",
        },
        {
            shape: "1",
            size: "Gigantic",
            width: "21",
            height: "40",
            active_string:
                "000000000000010000000000000000000110000000000000000001110000000000000000011110000000000000000111110000000000000001111110000000000000011111110000000000000111111110000000000001111111110000000000011111111110000000000111111111110000000001111111111110000000011111101111110000000111111001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000000000001111110000000011111111111111111111011111111111111111111011111111111111111111011111111111111111111",
        },

        {
            shape: "Snowman",
            size: "Tiny",
            width: "7",
            height: "9",
            active_string:
                "001110001111100111110001110001111101111111111111111111110111110",
        },
        {
            shape: "Snowman",
            size: "Small",
            width: "10",
            height: "11",
            active_string:
                "00011100000011111000011111110001111111000011111000011111110011111111101111111110111111111011111111100111111100",
        },
        {
            shape: "Snowman",
            size: "Medium",
            width: "11",
            height: "15",
            active_string:
                "000111110000011111110001111111110011111111100111111111000111111100000111110000011111110001111111110111111111111111111111111111111111111111111111111111111101111111110",
        },
        {
            shape: "Snowman",
            size: "Large",
            width: "17",
            height: "20",
            active_string:
                "0000001111100000000000111111100000000011111111100000001111111111100000011111111111000000111111111110000001111111111100000001111111110000000001111111000000001111111111100000111111111111100011111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110111111111111111000111111111111100",
        },
        {
            shape: "Snowman",
            size: "Huge",
            width: "17",
            height: "26",
            active_string:
                "0000001111100000000000111111100000000001111111000000000011111110000000000111111100000000000111110000000000111111111000000011111111111000001111111111111000011111111111110000111111111111100001111111111111000011111111111110000011111111111000000011111111100000011111111111110001111111111111110011111111111111101111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110111111111111111000111111111111100",
        },
        {
            shape: "Snowman",
            size: "Gigantic",
            width: "23",
            height: "37",
            active_string:
                "00000000111111100000000000000011111111100000000000001111111111100000000000011111111111000000000000111111111110000000000001111111111100000000000011111111111000000000000011111111100000000000011111111111110000000001111111111111110000000111111111111111110000001111111111111111100000111111111111111111100001111111111111111111000011111111111111111110000111111111111111111100001111111111111111111000001111111111111111100000001111111111111110000000001111111111111000000000111111111111111000000011111111111111111000001111111111111111111000111111111111111111111001111111111111111111110111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111011111111111111111111100111111111111111111111000111111111111111111100000111111111111111110000000111111111111111000000000011111111111000000",
        },
        {
            shape: "Moon",
            size: "Tiny",
            width: "6",
            height: "7",
            active_string: "001111011100111000111000111000011100001111",
        },
        {
            shape: "Moon",
            size: "Small",
            width: "8",
            height: "10",
            active_string:
                "00011111011111001111100011110000111100001111000011110000111110000111110000011111",
        },
        {
            shape: "Moon",
            size: "Medium",
            width: "12",
            height: "15",
            active_string:
                "000011111111001111111000011111100000111111000000111110000000111110000000111110000000111110000000111110000000111110000000111110000000111111000000011111100000001111111000000011111111",
        },
        {
            shape: "Moon",
            size: "Large",
            width: "17",
            height: "20",
            active_string:
                "0000000011111111100000111111111100000111111111000000011111111000000001111111100000000011111111000000001111111110000000011111111000000000111111110000000001111111100000000011111111000000000111111110000000001111111100000000011111111100000000011111111000000000111111110000000000111111110000000000111111111000000000011111111110000000000111111111",
        },
        {
            shape: "Moon",
            size: "Huge",
            width: "27",
            height: "30",
            active_string:
                "000000000000001111111111111000000000011111111111110000000000011111111111110000000000001111111111111000000000000011111111111110000000000000111111111111100000000000001111111111111000000000000001111111111110000000000000011111111111110000000000000011111111111100000000000000111111111111100000000000000111111111111100000000000000111111111111000000000000000111111111111000000000000000111111111111000000000000000111111111111000000000000000111111111111000000000000000111111111111000000000000000111111111111100000000000000111111111111100000000000000011111111111100000000000000011111111111110000000000000001111111111111000000000000001111111111111000000000000000111111111111100000000000000011111111111110000000000000001111111111111100000000000000011111111111111000000000000000011111111111110000000000000000001111111111111",
        },
        {
            shape: "Moon",
            size: "Gigantic",
            width: "25",
            height: "40",
            active_string:
                "0000000000000001111111111000000000000111111111100000000000001111111110000000000000001111111100000000000000011111111100000000000000011111111100000000000000011111111110000000000000011111111111000000000000001111111111000000000000001111111111100000000000001111111111100000000000000111111111110000000000000111111111111000000000000011111111111100000000000001111111111100000000000001111111111110000000000000111111111111000000000000011111111111100000000000001111111111110000000000000111111111111000000000000011111111111100000000000001111111111110000000000000111111111111000000000000011111111111100000000000001111111111110000000000000011111111111000000000000001111111111100000000000000111111111111000000000000001111111111100000000000000111111111110000000000000001111111111100000000000000111111111110000000000000001111111111100000000000000011111111110000000000000000111111111100000000000000001111111111000000000000000011111111100000000000000000011111111100000000000000000011111111110000000000000000001111111111",
        },
        {
            shape: "Flower",
            size: "Tiny",
            width: "9",
            height: "9",
            active_string:
                "000111000000111000010111010111010111111111111010111010001111100011101110011000110",
        },
        {
            shape: "Flower",
            size: "Small",
            width: "13",
            height: "13",
            active_string:
                "0000011100000000011111000000001111100000110111110110111101110111111111111111111111111111111011101110111000001111100000111111111110011110001111001111000111100011000001100",
        },
        {
            shape: "Flower",
            size: "Medium",
            width: "7",
            height: "15",
            active_string:
                "001110000111001101011111111100111000111110110101100010000001000000100000010001111111011111001111100000000",
        },
        {
            shape: "Flower",
            size: "Large",
            width: "9",
            height: "20",
            active_string:
                "000111000000111000010111010111010111111111111010111010001111100011111110011010110000010000000010000000010000000010000000010000000010000111111111111111111111111111011111110011111110",
        },
        {
            shape: "Flower",
            size: "Huge",
            width: "13",
            height: "29",
            active_string:
                "00000111000000000111110000000011111000001101111101101111011101111111111111111111111111111110111011101110000011111000001111111111100111101011110011110101111000110010011000000001000000000000100000000000010000000000001000000000000100000000000010000000000001000000000000100000011111111111110111111111110011111111111001111111111100111111111110011111111111000111111111000011111111100",
        },
        {
            shape: "Flower",
            size: "Gigantic",
            width: "17",
            height: "39",
            active_string:
                "000000011100000000000001111100000000000011111000000000000111110000000111001111100111011111011111011111111111011101111111111111111111111101111111111111110001111111111111000000011111110000000011111111111000001111111111111000111111010111111001111110101111110011111001001111100011100010001110000000000100000000000000001000000000000000010000000000000000100000000000000001000000000000000010000000000000000100000000000000001000000000000000010000000000000000100000000000000001000000000111111111111111001111111111111110001111111111111000011111111111110000111111111111100001111111111111000011111111111110000011111111111000000111111111110000001111111111100000001111111110000",
        },
        {
            shape: "Spiral",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0111100100001000110010100101010100101000100011100",
        },
        {
            shape: "Spiral",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0000000100001111001001000010011001100101101001010110101001011010001001100111001001000001000011111000",
        },
        {
            shape: "Spiral",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000111111110000001000000001000010011111100100100100000010010001001111001001010010000100101010100110010101010101001010101010101010010101010101000100101010100111001001010010000010010001001111100100000100000001000000011111110000",
        },
        {
            shape: "Spiral",
            size: "Large",
            width: "20",
            height: "20",
            active_string:
                "0000011111111110000000001000000000010000000100111111110010000010010000000010010001001001111110010010100100100000010010011010010011110010010110101001000010010101101010100110010101011010101010010101010110101010101001010101101010101000100101011010101001110010010110101001000001001001101001001111100100101001001000000010010001001001111111001000001001000000000100000001001111111110000000001000000000000000",
        },
        {
            shape: "Spiral",
            size: "Huge",
            width: "30",
            height: "29",
            active_string:
                "000000011111111111111001000000000000100000000000000100100000000001001111111111110010010000000010010000000000001001001000000100100111111111100100100100001001001000000000010010010010010010010011111111001001001001100100100100000000100100100101101001001001111110010010010101101010010010000001001001010101101010100100111100100101010101101010101001000010010101010101101010101010011001010101010101101010101010100101010101010101101010101010101001010101010101101010101010100010010101010101101010101010011100100101010101101010101001000001001001010101101010100100111110010010010101101010010010000000100100100101101001001001111111001001001001100100100100000000010010010010010010010011111111100100100100001001001000000000001001001000000100100111111111110010010000000010010000000000000100100000000001001111111111111001000000000000100000000000000010000000000000011111111111111100000000",
        },
        {
            shape: "Spiral",
            size: "Gigantic",
            width: "38",
            height: "39",
            active_string:
                "000000011111111111111111111110000000000000001000000000000000000000010000000000000100111111111111111111110010000000000010010000000000000000000010010000000001001001111111111111111110010110000000100100100000000000000000010010010000010010010011111111111111110010010010001001001001000000000000000010010010010000100100100111111111111110010010010010010010010010000000000000010010010010011001001001001111111111110010010010010110100100100100000000000010010010010101101010010010011111111110010010010101011010101001001000000000010010010101010110101010100100111111110010010101010101101010101010010000000010010101010101011010101010101001111110010101010101010110101010101010100000010101010101010101101010101010101001100101010101010101011010101010101010100101010101010101010110101010101010101010010101010101010101101010101010101010000101010101010101011010101010101010011110010101010101010110101010101010100000001001010101010101101010101010100111111100100101010101011010101010100100000000010010010101010110101010100100111111111001001001010101101010100100100000000000100100100101011010100100100111111111110010010010010110100100100100000000000001001001001001100100100100111111111111100100100100100100100100100000000000000010010010010000100100100111111111111111001001001000000100100100000000000000000100100100000000100100111111111111111110010010000000000100100000000000000000001001000000000000100111111111111111111100100000000000000100000000000000000000010000000000000000111111111111111111111000000000",
        },
        {
            shape: "Lightning",
            size: "Tiny",
            width: "7",
            height: "7",
            active_string: "0001111001111000011100111000001100001000001000000",
        },
        {
            shape: "Lightning",
            size: "Small",
            width: "10",
            height: "10",
            active_string:
                "0000011111000011111000011110000000111100000111000000111000000001110000001100000001100000001000000000",
        },
        {
            shape: "Lightning",
            size: "Medium",
            width: "15",
            height: "15",
            active_string:
                "000000011111111000000011111110000000111111000000001111110000000000111111000000000111110000000001111000000000011110000000000111100000000000011110000000000111000000000001110000000000001000000000000010000000000000100000000000000",
        },
        {
            shape: "Lightning",
            size: "Large",
            width: "13",
            height: "20",
            active_string:
                "00000111111110000011111110000011111110000001111110000001111110000000111111000000001111110000000111110000000111111000000011111000000011111000000001111000000000011110000000011110000000001110000000000110000000000110000000000011000000000001000000000001000000000000",
        },
        {
            shape: "Lightning",
            size: "Huge",
            width: "17",
            height: "30",
            active_string:
                "000000111111111110000001111111111000000011111111100000001111111110000000011111111100000000111111110000000001111111000000000111111110000000001111111000000000000111111110000000011111111000000000111111100000000011111110000000000111111000000000011111110000000000111111000000000011111100000000000001111110000000000011111000000000000111110000000000011111000000000000111100000000000001110000000000000111100000000000001110000000000000011000000000000001100000000000000011000000000000000100000000000000010000000000000000",
        },
        {
            shape: "Lightning",
            size: "Gigantic",
            width: "25",
            height: "40",
            active_string:
                "0000000000111111111111111000000000111111111111111000000000011111111111111000000000001111111111111000000000001111111111111000000000000111111111111000000000000111111111111100000000000011111111111100000000000001111111111100000000000001111111111100000000000000111111111110000000000000111111111110000000000000000001111111111100000000000001111111111100000000000000111111111100000000000000111111111100000000000000011111111100000000000000011111111100000000000000001111111100000000000000001111111100000000000000001111111110000000000000000111111110000000000000000111111110000000000000000000011111111000000000000000001111111000000000000000001111111000000000000000000111111000000000000000000011111000000000000000000011111100000000000000000001111100000000000000000001111100000000000000000000111100000000000000000000011110000000000000000000011110000000000000000000001110000000000000000000000110000000000000000000000110000000000000000000000010000000000000000000000001000000000000000000000001000000000000000000000000",
        },
        {
            shape: "Cross",
            size: "Tiny",
            width: "5",
            height: "10",
            active_string: "00100001001111100100001000010000100001000010000100",
        },
        {
            shape: "Cross",
            size: "Small",
            width: "6",
            height: "10",
            active_string:
                "001100001100111111111111001100001100001100001100001100001100",
        },
        {
            shape: "Cross",
            size: "Medium",
            width: "7",
            height: "15",
            active_string:
                "001110000111001111111111111111111110011100001110000111000011100001110000111000011100001110000111000011100",
        },
        {
            shape: "Cross",
            size: "Large",
            width: "10",
            height: "20",
            active_string:
                "00011110000001111000000111100011111111111111111111111111111111111111110001111000000111100000011110000001111000000111100000011110000001111000000111100000011110000001111000000111100000011110000001111000",
        },
        {
            shape: "Cross",
            size: "Huge",
            width: "15",
            height: "30",
            active_string:
                "000001111100000000001111100000000001111100000000001111100000000001111100000111111111111111111111111111111111111111111111111111111111111111111111111111000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000000001111100000",
        },
        {
            shape: "Cross",
            size: "Gigantic",
            width: "20",
            height: "39",
            active_string:
                "000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000001111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111110000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000000000011111100000000000000111111000000000000001111110000000",
        },
    ];
    static POSSIBLE_CHAR_SET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    static DIRECTIONS_ARRAY = [
        "left", //0
        "down", //1
        "right", //2
        "up", //3
        "left-down", //4
        "left-up", //5
        "right-down", //6
        "right-up", //7
    ];
    static MAX_INPUT_WORDS = 99;

    static WORD_SEARCH_TITLE = 0;
    static WORD_SEARCH_DESCRIPTION = 1;
    static WORD_SEARCH_CREATOR = 2;
    static WORD_SEARCH_ADD = 0;
    static WORD_SEARCH_EDIT = 1;
    static WORD_SEARCH_DELETE = 2;
    static WORD_SEARCH_SORT = 3;
    static DEFAULT_PUZZLE_RECTANGRLE_HEIGHT = 15;
    static DEFAULT_PUZZLE_RECTANGRLE_WIDTH = 15;
    static MAX_CELL = 500;
    static STROKE = {
        WIDTH: 2,
    };
    static DEFAULT_DATE = "All";
    static TESTER_KEY = "TESTER_KEY";
    static TESTER_KEY_SAVE = "TESTER_KEY_SAVE_XXX";

    static GRADE_BOX = "grade";
    static SUBJECT_BOX = "subject";
    static TOPIC_BOX = "topic";
    static CCSS_BOX = "ccss";

    static MODULE_TYPE = {
        WORDSEARCH: "WORDSEARCH",
        TOOL_V2: "TOOL_V2",
        TOOL_CREATE_WS_V1: "TOOL_CREATE_WS_V1",
    };

    static SYNONYM_SMALL = [
        "little",
        "tiny",
        "minuscule",
        "mini",
        "petite",
        "compact",
        "miniature",
        "micro",
        "nano",
        "subcompact",
        "small",
    ];
    static SYNONYM_MEDIUM = [
        "average",
        "intermediate",
        "medium",
        "moderate",
        "mean",
        "median",
        "mid sized",
        "middle",
        "standard",
        "balanced",
    ];
    static SYNONYM_LARGE = [
        "big",
        "huge",
        "large",
        "massive",
        "gigantic",
        "enormous",
        "immense",
        "vast",
        "great",
        "giant",
        "grand",
    ];
    static RESPONSE_EMPTY_TOPIC = [
        "Please provide a topic for the word search puzzle.",
        "I'm sorry, but you need to provide a topic for the word search puzzle.",
        "Could you please provide a topic for the word search puzzle?",
        "I'm sorry, but the topic you provided is empty.",
        "Please provide a topic for the word search puzzle.",
        "Please choose a subject for the word search puzzle.",
        "Would you mind giving the word search problem a topic?",
        "Would you mind giving me the topic for the word search puzzle?",
    ];
    static RESPONSE_GEN_WORDSEARCH_SUCCESS = [
        "Great! Here's your word search puzzle. Enjoy!",
        "Here you go !",
        "Sure !",
        "Sure! Your word search puzzle is ready. Enjoy!",
        "The word search puzzle has been successfully created. Enjoy!",
        "The word search puzzle has been successfully created. You can start solving it now.",
        "Alright, your word search puzzle has been created! Enjoy!",
        "Enjoy!",
    ];
    static MOST_RECENT_CONVERSATION_LIMIT = 3;
    static SECRET_KEY_REDUX_PERSIST = "secret-key-redux-persist";
    static AUDIO_LANGUAGE_LIST = [
        { name: "Afrikaans Male", id: 0, code: "af-M" },
        { name: "Albanian Male", id: 1, code: "sq-M" },
        { name: "Arabic Female", id: 2, code: "ar-F" },
        { name: "Arabic Male", id: 3, code: "ar-M" },
        { name: "Armenian Male", id: 4, code: "hy-M" },
        { name: "Australian Female", id: 5, code: "aus-F" },
        { name: "Australian Male", id: 6, code: "aus-M" },
        { name: "Bosnian Female", id: 7, code: "bs-F" },
        { name: "Bosnian Male", id: 8, code: "bs-M" },
        { name: "Brazilian Portugese Male", id: 9, code: "pt-br-M" },
        { name: "Brazilian Portuguese Female", id: 10, code: "pt-br-F" },
        { name: "Catalan Male", id: 11, code: "ca-M" },
        { name: "Chinese (Hong Kong) Female", id: 12, code: "yue-Hant-HK-F" },
        { name: "Chinese (Hong Kong) Male", id: 13, code: "yue-Hant-HK-M" },
        { name: "Chinese Female", id: 14, code: "zh-F" },
        { name: "Chinese Male", id: 15, code: "zh-M" },
        { name: "Chinese Taiwan Female", id: 16, code: "zh-TW-F" },
        { name: "Chinese Taiwan Male", id: 17, code: "zh-TW-M" },
        { name: "Croatian Female", id: 18, code: "hr-HR-F" },
        { name: "Croatian Male", id: 19, code: "hr-HR-M" },
        { name: "Czech Female", id: 20, code: "cs-CZ-F" },
        { name: "Czeck Male", id: 21, code: "cs-CZ-M" },
        { name: "Danish Female", id: 22, code: "da-DK-F" },
        { name: "Danish Male", id: 23, code: "da-DK-M" },
        { name: "Dutch Female", id: 24, code: "nl-F" },
        { name: "Dutch Male", id: 25, code: "nl-NL-M" },
        { name: "Esperanto Male", id: 26, code: "eo-M" },
        { name: "Finnish Female", id: 27, code: "fi-FI-F" },
        { name: "Finnish Male", id: 28, code: "fi-FI-M" },
        { name: "French Female", id: 29, code: "fr-FR-F" },
        { name: "French Male", id: 30, code: "fr-FR-M" },
        { name: "German Female", id: 31, code: "de-DE-F" },
        { name: "German Male", id: 32, code: "de-DE-M" },
        { name: "Greek Female", id: 33, code: "el-GR-F" },
        { name: "Greek Male", id: 34, code: "el-GR-M" },
        { name: "Hindi Female", id: 35, code: "hi-IN-F" },
        { name: "Hindi Male", id: 36, code: "hi-IN-M" },
        { name: "Hungarian Female", id: 37, code: "hu-HU-F" },
        { name: "Hungarian Male", id: 38, code: "hu-HU-M" },
        { name: "Icelandic Male", id: 39, code: "is-IS-M" },
        { name: "Indonesian Female", id: 40, code: "id-ID-F" },
        { name: "Indonesian Male", id: 41, code: "id-ID-M" },
        { name: "Italian Female", id: 42, code: "it-IT-F" },
        { name: "Italian male", id: 43, code: "it-IT-M" },
        { name: "Japanese Female", id: 44, code: "ja-JP-F" },
        { name: "Japanese Male", id: 45, code: "ja-JP-M" },
        { name: "Korean Female", id: 46, code: "ko-KR-F" },
        { name: "Korean Male", id: 47, code: "ko-KR-M" },
        { name: "Latin Female", id: 48, code: "la-F" },
        { name: "Latin Male", id: 49, code: "la-M" },
        { name: "Latvian Male", id: 50, code: "lv-LV-M" },
        { name: "Macedonian Male", id: 51, code: "mk-MK-M" },
        { name: "Moldavian Male", id: 52, code: "mo-M" },
        { name: "Montenegrin Male", id: 53, code: "cnr-M" },
        { name: "Norwegian Female", id: 54, code: "no-NO-F" },
        { name: "Norwegian male", id: 55, code: "no-NO-M" },
        { name: "Polish Female", id: 56, code: "pl-PL-F" },
        { name: "Polish Male", id: 57, code: "pl-PL-M" },
        { name: "Portugese Male", id: 58, code: "pt-PT-M" },
        { name: "Portuguese Female", id: 59, code: "pt-PT-F" },
        { name: "Romanian Female", id: 60, code: "ro-RO-F" },
        { name: "Romanian Male", id: 61, code: "ro-RO-M" },
        { name: "Russian Female", id: 62, code: "ru-RU-F" },
        { name: "Russian Male", id: 63, code: "ru-RU-M" },
        { name: "Serbian Female", id: 64, code: "sr-RS-F" },
        { name: "Serbian Male", id: 65, code: "sr-RS-M" },
        { name: "Serbo-Croatian Male", id: 66, code: "sh-M" },
        { name: "Slovak Female", id: 67, code: "sk-SK-F" },
        { name: "Slovak Male", id: 68, code: "sk-SK-M" },
        { name: "Spanish Female", id: 69, code: "es-ES-F" },
        { name: "Spanish Latin American Female", id: 70, code: "es-AR-F" },
        { name: "Spanish Latin American Male", id: 71, code: "es-AR-M" },
        { name: "Spanish Male", id: 72, code: "es-ES-M" },
        { name: "Swahili Male", id: 73, code: "sw-M" },
        { name: "Swedish Female", id: 74, code: "sv-SE-F" },
        { name: "Swedish Male", id: 75, code: "sv-SE-M" },
        { name: "Tamil Male", id: 76, code: "ta-IN-M" },
        { name: "Thai Female", id: 77, code: "th-TH-F" },
        { name: "Turkish Female", id: 78, code: "tr-TR-F" },
        { name: "Turkish Male", id: 79, code: "tr-TR-M" },
        { name: "UK English Female", id: 80, code: "en-UK-F" },
        { name: "UK English Male", id: 81, code: "en-UK-M" },
        { name: "US English Female", id: 82, code: "en-US-F" },
        { name: "US English Male", id: 83, code: "en-US-M" },
        { name: "Vietnamese Female", id: 84, code: "vi-VN-F" },
        { name: "Vietnamese Male", id: 85, code: "vi-VN-M" },
        { name: "Welsh Male", id: 86, code: "cy-M" },
    ];
    static ID_SEARCH_CONTAINER = "search-container";
    static USING_CREATE_WS = true;

    static PAGE_TYPE_HOME = 9;
    static PAGE_TYPE_HOME_BROWSING = 0;
    static PAGE_TYPE_CATEGORY = 1;
    static PAGE_TYPE_WORKSHEET = 2;
    static PAGE_TYPE_COLLECTION = 3;
    static PAGE_TYPE_SEARCH = 4;
    static PAGE_TYPE_INTERACTIVE = 5;
    static PAGE_TYPE_MANAGE_ACCOUNT = 6;
    static PAGE_TYPE_REFER_A_FRIEND = 7;
    static PAGE_TYPE_POPUP_WORKSHEET = 8;
    static AUDIO_TYPE_CONVERT_TEXT = 0;
    static AUDIO_TYPE_UPLOAD = 1;
    static ACTIVITY_ACTIVE_TOOLV2 = "activity_active";
    static WIDTH_RIGHT_CONTENT = 340;
    static ID_ELEMENR_GAME_SCREEN = "image-container";
    static AUTO_SEARCH_TEMPLATE = "autoSearchTemplate";
}
