import { IQuestionActivity } from "../../../shared/models/questionActivity";
import { getContent } from "../../../utils/multipleChoice";

export const listAnswerKey = () => {
    const listKey = [];
    for (let i = 65; i <= 90; i++) {
        listKey.push(String.fromCharCode(i));
    }
    return listKey;
};

export const checkAbleToAdd = (currentActivity) => {
    let ableInfo = {
        isAble: true,
        message: "",
    };
    currentActivity.questions.map((question, questionIndex) => {
        let numberIncorrect = 0;
        question.answers.map((answer) => {
            if (!answer.isCorrect) {
                numberIncorrect += 1;
            }
            if (!answer.text || !getContent(question.question).trim()) {
                ableInfo = {
                    isAble: false,
                    message:
                        "You need to fill all the empty input before add one more",
                };
            }
        });
        if (numberIncorrect == question.answers.length) {
            ableInfo = {
                isAble: false,
                message: `Question ${
                    questionIndex + 1
                } must have at least one correct answer`,
            };
        }
    });
    return ableInfo;
};

export const handleTabIndex = (questions: IQuestionActivity[], input) => {
    let startTab = 1;
    let tabIndex = 0;
    questions.map((question) => {
        if (input.id == question.id) {
            tabIndex = startTab;
        } else {
            startTab += 1;
            question.answers.map((answer) => {
                if (input.id == answer.id) {
                    tabIndex = startTab;
                }
                startTab += 1;
            });
        }
    });
    return tabIndex;
};
