export const getCurrentInfoRecursive = (
    chills: any,
    valueCompare: string,
    field: string,
    parentIds = []
) => {
    if (chills?.length) {
        for (let category of chills) {
            let subChills = category?.children;

            let currentParentIds =
                category?.parentId?.length > 0 ? [category?.parentId] : [];

            let subParentIds = parentIds;
            if (!subParentIds?.includes(category?.parentId)) {
                subParentIds = [...subParentIds, category?.parentId];
            }
            subParentIds = [...subParentIds, category.id];
            subParentIds = subParentIds?.filter((i) => !!i);

            let currentCate = category[field];
            if (field === "name") {
                currentCate = currentCate.toLowerCase();
            }
            if (currentCate === valueCompare) {
                category.parentIds = parentIds?.length
                    ? parentIds
                    : currentParentIds;
                return category; // Return the category when the condition is true
            } else if (
                subChills?.length > 0 &&
                getCurrentInfoRecursive(
                    subChills,
                    valueCompare,
                    field,
                    subParentIds
                )
            ) {
                return getCurrentInfoRecursive(
                    subChills,
                    valueCompare,
                    field,
                    subParentIds
                );
            }
        }
        return null;
    }
};

export const getCurrentInfo = (
    valueCompare: string,
    listData: any,
    typeFilterField?: string
) => {
    let field = "id";
    if (typeFilterField) {
        field = typeFilterField;
    }

    return getCurrentInfoRecursive(listData, valueCompare, field);
};
