import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { IScreenData } from "..";
// import LoadingComponent from "../../../../../components/common/loading";
import { ResourceAppState } from "../../../../redux/reducer/root.reducerModule";
import LoadingComponent from "../../../common/loading/loading-component";
import NewColumnWorksheet from "./new-column";
const NewGridWorksheet = ({
    worksheets,
    selectCollectionFc,
    index,
    screenData,
    WIDTH_WS,
    selectWorksheetFc,
    addGaEventMoreEvent,
    addGaEventCreateCollection,
    onDragResourceItem,
    onDropResourceItem,
}: {
    worksheets: any[];
    selectCollectionFc?: Function;
    index: number;
    screenData: IScreenData;
    WIDTH_WS: any;
    selectWorksheetFc?: Function;
    addGaEventMoreEvent?: Function;
    addGaEventCreateCollection?: Function;
    onDragResourceItem?: Function;
    onDropResourceItem?: Function;
}) => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const isTablet = useMediaQuery("(max-width:1024px)");
    const loading = useSelector(
        (state: ResourceAppState) =>
            state.resourceState.collectionSelected.loading
    );

    return (
        <>
            <div
                id="home-grid-id"
                className={"home-grid-column "}
                style={{
                    justifyContent: isMobile
                        ? undefined
                        : screenData.width === 0
                        ? "start"
                        : "center",
                    paddingTop: 24,
                    display: screenData.width === 0 ? "none" : "flex",
                }}
            >
                {worksheets?.length > 0 ? (
                    Array.from(Array(screenData.column), (e, index) => {
                        return (
                            <div
                                className={
                                    !isTablet && index == screenData.column - 1
                                        ? "columns-grid-ws-open"
                                        : ""
                                }
                                key={index}
                                id={index + ""}
                                style={{
                                    width: WIDTH_WS + "px",
                                    // minHeight: isTablet ? "20vh" : "50vh",
                                }}
                            >
                                <NewColumnWorksheet
                                    WIDTH_WS={WIDTH_WS}
                                    ws={worksheets?.filter(
                                        (item, idx) =>
                                            idx % screenData.column == index
                                    )}
                                    selectCollectionFc={selectCollectionFc}
                                    selectWorksheetFc={selectWorksheetFc}
                                    addGaEventMoreEvent={addGaEventMoreEvent}
                                    addGaEventCreateCollection={
                                        addGaEventCreateCollection
                                    }
                                    onDragResourceItem={onDragResourceItem}
                                    onDropResourceItem={onDropResourceItem}
                                />
                            </div>
                        );
                    })
                ) : loading ? (
                    <>
                        <LoadingComponent />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default NewGridWorksheet;
